import { SVGProps } from "react";

export const LineChartLegendSVG = ({
  color = "#FF753C",
  width = 17,
  height = 17,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={10}
    viewBox="0 0 22 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.483 4.367h4.651a1 1 0 0 1 0 2H15.32a4.61 4.61 0 0 1-8.804 0H1.701a1 1 0 1 1 0-2h4.652a4.61 4.61 0 0 1 9.13 0Z"
      fill={color}
    />
  </svg>
);
