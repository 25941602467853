import {
  convertNewValue,
  convertRegionToTree,
  convertValueToTree,
} from "@components/auto-completes/regions/util";
import {
  SHAutocomplete,
  SHCheckbox,
  SHStack,
  SHTextFieldProps,
  SHTypography,
} from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { RegionDTO, RegionTreeDTO } from "@models/public/region";
import { getRegions } from "@services/public/region";
import { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

export interface SelectRegionsProps {
  disabled?: boolean;
  value?: string[]; //Selected Region Id
  onChange?: (value: string[]) => void;
  regionData?: RegionDTO[];
  textFieldProps?: Omit<SHTextFieldProps, "value">;
  onClose?: () => void;
}
export const SelectRegions = ({
  disabled,
  value = [],
  onChange,
  regionData: regionDataProp = [],
  textFieldProps,
  onClose,
}: SelectRegionsProps) => {
  const { notify } = useNotification();

  const [regionData, setRegionData] = useState<RegionDTO[]>(regionDataProp);
  const [defaultTree, setDefaultTree] = useState<RegionTreeDTO | undefined>();
  const [regionsTree, setRegionsTree] = useState<RegionTreeDTO | undefined>();
  const [selectedRegion, setSelectedRegion] = useState<RegionDTO[]>([]);

  useEffectOnce(() => {
    if (regionDataProp.length > 0) {
      setDefaultTree(convertRegionToTree(regionDataProp));
      return;
    }
    getRegions().then(({ isSuccess, data, message }) => {
      if (isSuccess && data) {
        setRegionData(data);
        setDefaultTree(convertRegionToTree(data));
      } else {
        notify(message, {
          variant: "error",
          close: true,
        });
      }
    });
  });

  useEffect(() => {
    const converted = convertValueToTree(value, defaultTree);
    setRegionsTree(converted.tree);
    setSelectedRegion(converted.flatList);
  }, [value, defaultTree]);

  return (
    <SHAutocomplete
      multiple
      value={selectedRegion}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      getOptionLabel={(option: RegionDTO) => option.name}
      textFieldProps={{
        ...textFieldProps,
        InputLabelProps: { shrink: true },
        placeholder: value?.length ? undefined : textFieldProps?.placeholder,
        InputProps: {
          startAdornment:
            value && value.length ? (
              <SHTypography
                disabled={disabled}
                variant="body1"
                sx={{ paddingLeft: "10px" }}
              >
                {`${selectedRegion.length}/${regionData.length} selected`}
              </SHTypography>
            ) : undefined,
        },
      }}
      onChange={(e, v, r, details) => {
        if (!onChange) return;
        onChange(convertNewValue(regionsTree, details?.option));
      }}
      options={regionData}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.id} style={{ padding: "1px 10px" }}>
            <SHStack alignItems={"center"} direction="row" spacing={1}>
              <SHCheckbox checked={selected} />
              <SHTypography
                variant="body3"
                fontWeight={option.isBold ? 700 : 500}
              >
                {option.name}
              </SHTypography>
            </SHStack>
          </li>
        );
      }}
      onClose={onClose}
    />
  );
};
