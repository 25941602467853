import * as Yup from "yup";

export const formTitle = (
  name: string,
  group: "standard" | "feature",
  sectionType?: "group" | "subgroup",
) => {
  if (!name) return generateSectionTitle(group, sectionType)?.blankTitle || "";
  return name;
};

export const generateSectionTitle = (
  group: "standard" | "feature",
  sectionType?: "group" | "subgroup",
) => {
  switch (group) {
    case "standard":
      return {
        blankTitle: "This is a new section",
        sectionInformation: "Section information",
        sectionInformationSub: "Provide information about this feature",
        sectionName: "Section name",
        sectionNameSub:
          "The name should be unique and clearly describe the section",
        sectionNamePlaceholder: "This is a new section",
        sectionDescriptionSub:
          "Describe this section so that it can be better understood by users. This will appear as a tool tip",
        iconSub:
          "Upload an icon. This will appear in the platform analysis and Suitability Reviews",
      };
    case "feature":
      switch (sectionType) {
        case "group":
          return {
            blankTitle: "New feature group",
            sectionInformation: "Group information",
            sectionInformationSub:
              "Provide information about this feature group",
            sectionName: "Feature group name",
            sectionNameSub:
              "The name should be unique and clearly describe the feature",
            sectionNamePlaceholder: "Feature group name",
            sectionDescriptionSub:
              "Describe this feature group so that it can be better understood by users. This will appear as a tool tip",
            iconSub:
              "Upload an icon. This will appear in the platform analysis and Suitability Reviews",
          };
        case "subgroup":
          return {
            blankTitle: "New feature sub-group",
            sectionInformation: "Sub-group information",
            sectionInformationSub:
              "Provide information about this feature sub-group",
            sectionName: "Feature sub-group name",
            sectionNameSub:
              "The name should be unique and clearly describe the feature",
            sectionNamePlaceholder: "Feature sub-group name",
            sectionDescriptionSub:
              "Describe this feature sub-group so that it can be better understood by users. This will appear as a tool tip",
            iconSub: undefined,
          };
      }
  }
  return undefined;
};

export const sectionValidators = Yup.object().shape({
  name: Yup.string()
    .required("Section name is required")
    .max(100, "Section name must be less than 100 characters"),
});

export const standardSectionValidators = Yup.object().shape({
  name: Yup.string()
    .required("Section name is required")
    .max(100, "Section name must be less than 100 characters"),
  sectionDisplayType: Yup.object().shape({
    id: Yup.string().required("Section type is required"),
  }),
});
