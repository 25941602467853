export const generateSubTitle = (isMyPractice?: boolean) => {
  if (isMyPractice)
    return {
      KeyPracticeInfo: "Manage your practice’s SuitabilityHub profile",
      PracticeLogo:
        "This logo will be shown on the Suitability Reviews generated by your team",
      PracticeDetails: 
        "These practice details will appear in any Suitability Reviews you run. They are independent to any billing details, which you can manage in the Billing info tab. You may be eligible to benefits if your practice is linked to a licensee. For more information, contact us on Support@SuitabilityHub.com",  
      PracticeNameDesc:
        "Your practice’s name will be shown on generated Suitability Reports.",
      PracticeNamePlaceholder: "Enter your practice's name",
      PracticeBranchPlaceholder: "Enter your practice branch’s name",
      AFSLPlaceholder: "Enter your practice’s AFSL number",
      ABNPlaceholder: "Enter your practice’s ABN number"
    };
  return {
    KeyPracticeInfo: "Manage this practice's SuitabilityHub profile",
    PracticeLogo:
      "This logo will be shown on the Suitability Reviews created by this practice",
    PracticeDetails:
      "Update key information about this practice. The practice name and AFSL will be shown on the Suitability Reviews created by this practice",
    PracticeNameDesc:
      "Your practice’s name will be shown on generated Suitability Reports.",
    PracticeNamePlaceholder: "Enter your practice's name",
    PracticeBranchPlaceholder: "Enter your practice branch’s name",
    AFSLPlaceholder: "Enter your AFSL number",
    ABNPlaceholder: "Enter your ABN number"
  };
};
