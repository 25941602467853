import { SVGProps } from "react";

export const FilterSVG = ({
  fill = "#515B6F",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.333 11.048c0-.165.057-.322.159-.438a.51.51 0 0 1 .383-.181h3.25a.51.51 0 0 1 .383.18.667.667 0 0 1 .159.439.667.667 0 0 1-.159.437.51.51 0 0 1-.383.182h-3.25a.51.51 0 0 1-.383-.182.667.667 0 0 1-.159-.437ZM3.167 7.333c0-.164.057-.321.158-.437a.51.51 0 0 1 .383-.182h7.584a.51.51 0 0 1 .383.182.666.666 0 0 1 .158.437.667.667 0 0 1-.158.438.51.51 0 0 1-.383.181H3.708a.51.51 0 0 1-.383-.18.667.667 0 0 1-.158-.439ZM1 3.62c0-.164.057-.322.159-.438A.51.51 0 0 1 1.542 3h11.916a.51.51 0 0 1 .383.181.667.667 0 0 1 .159.438.667.667 0 0 1-.159.438.51.51 0 0 1-.383.181H1.542a.51.51 0 0 1-.383-.181A.667.667 0 0 1 1 3.619Z"
      fill={fill}
    />
  </svg>
);
