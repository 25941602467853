import { SVGProps } from "react";

export const ArrowDownSVG = ({
  color = "#25324B",
  width = "15px",
  height = "15px",
  transform,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    transform={transform}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m13 5-5.5 5.5L2 5"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
