import { SHStack, SHTypography } from "@components/design-systems";
import { StatusBadge } from "@components/status-badge";
import { APIExtRoutes, PageRoutes } from "@constants";
import { usePractice } from "@hooks/usePractice";
import { TopBar, TopBarContainer } from "@layouts/top-bar";

import { PlusButton } from "@components/buttons/plus";
import { SHButton, SHDataGrid } from "@components/design-systems";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import { EditSVG } from "@components/svgs";
import { DateFormat } from "@constants/format";
import { useUserPermissions } from "@hooks/userUserPermission";
import { PageMode, ParentState } from "@models/core";
import { AdviserUserODataDTO } from "@models/practice/entities/practiceUser";
import { Avatar, useTheme } from "@mui/material";
import { DefaultAdviserUserStatusFilterValue } from "@pages/practices/constant";
import { StatusFilterOptions } from "@pages/user/constant";
import { RootState } from "@redux/store";
import { createColumnHelper } from "@tanstack/react-table";
import { constructUserName, nameOfFactory } from "@utils";
import { format } from "date-fns";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";
import {
  PracticeAdminPrivilegesOptions,
  PracticeUserTypeMapping,
  PracticeUserTypeOptions,
} from "./constant";
import { PracticeTeamSkeleton } from "./skeleton";

const nameOf = nameOfFactory<AdviserUserODataDTO>();
const columnHelper = createColumnHelper<AdviserUserODataDTO>();
const pickColumns: (keyof AdviserUserODataDTO)[] = [
  "auth0Id",
  "avatarUrl",
  "userType",
  "status",
  "name",
  "lastName",
  "firstName",
  "lastLogin",
  "hasAdminPrivileges",
  "appliedFreeLicence",
];
export const PracticeTeam = () => {
  const {
    practiceId,
    practice,
    ui: { isLoading },
  } = usePractice();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { isAdviserTrial, isAdminGroup } = useUserPermissions();
  const { user } = useSelector((state: RootState) => state.auth);

  const columns = useMemo(() => {
    const expandCol = columnHelper.accessor("appliedFreeLicence", {
      header: "Applied free licence",
      cell: (props) => (props.cell.getValue() ? "Yes" : "No"),
      meta: {
        filterData: PracticeAdminPrivilegesOptions,
        sx: {
          width: "250px",
        },
      },
    });

    let initColumns = [
      columnHelper.accessor((row) => row, {
        id: nameOf("name"),
        header: "Full name",
        enableColumnFilter: false,
        cell: (props) => (
          <SHStack
            spacing={2}
            direction="row"
            alignItems={"center"}
            justifyContent={"start"}
          >
            <Avatar alt="Avatar" src={props.cell.getValue().avatarUrl ?? ""} />
            <SHTypography variant="subtitle2">
              {constructUserName(props.cell.getValue().name)}
            </SHTypography>
          </SHStack>
        ),
        meta: {
          sx: {
            width: "300px",
          },
        },
      }),
      columnHelper.accessor("userType", {
        header: "Account type",
        cell: (props) => PracticeUserTypeMapping[props.cell.getValue()] ?? "",
        meta: {
          filterData: PracticeUserTypeOptions,
          sx: {
            width: "200px",
          },
        },
      }),
      columnHelper.accessor("hasAdminPrivileges", {
        header: "Admin privileges",
        cell: (props) => (props.cell.getValue() ? "Yes" : "No"),
        meta: {
          filterData: PracticeAdminPrivilegesOptions,
          sx: {
            width: "220px",
          },
        },
      }),
      columnHelper.accessor("lastLogin", {
        header: "Last logged in",
        cell: (props) => {
          const date = props.cell.getValue();
          return date ? format(new Date(date), DateFormat) : "";
        },
        enableColumnFilter: false,
        meta: {
          sx: {
            width: "180px",
          },
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => <StatusBadge status={props.cell.getValue()} />,
        meta: {
          filterData: StatusFilterOptions,
          sx: {
            width: "100px",
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "actionCol",
        header: "Action",
        cell: (props) => (
          <SHStack spacing={1} direction="row">
            <SHButton
              startIcon={<EditSVG color={palette.common.white} />}
              variant="contained"
              onClick={() =>
                navigate(
                  generatePath(PageRoutes.practiceUsers, {
                    practiceId,
                    auth0Id: props.cell.getValue().auth0Id || "",
                  }),
                  { state: { pageMode: PageMode.Edit } as ParentState },
                )
              }
            >
              Edit
            </SHButton>
            <SHButton
              variant="outlined"
              onClick={() =>
                navigate(
                  generatePath(PageRoutes.practiceUsers, {
                    practiceId,
                    auth0Id: props.cell.getValue().auth0Id || "",
                  }),
                )
              }
            >
              View
            </SHButton>
          </SHStack>
        ),
        meta: {
          sx: {
            width: "190px",
          },
        },
        enableColumnFilter: false,
        enableSorting: false,
      }),
    ];

    if (isAdminGroup) initColumns.splice(3, 0, expandCol);

    return initColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminGroup]);

  return (
    <SHStack>
      <TopBarContainer>
        <TopBar
          navigate={
            user?.userMetadata?.is_system_admin
              ? { to: PageRoutes.practices }
              : undefined
          }
          title={practice?.name}
          customBadge={
            practice?.status ? (
              <StatusBadge status={practice.status} />
            ) : undefined
          }
        />
      </TopBarContainer>
      {isLoading ? (
        <PracticeTeamSkeleton />
      ) : isAdviserTrial ? (
        <SHStack>
          <SHTypography variant="subtitle1">
            Only users with Administrative Privileges can see this page
          </SHTypography>
          <SHTypography variant="body2">
            Please contact Support@SuitabilityHub.com if you require assistance
          </SHTypography>
        </SHStack>
      ) : (
        <SHDataGrid
          state={{
            ...DefaultDataState,
            columnFilters: [
              {
                id: nameOf("status"),
                value: DefaultAdviserUserStatusFilterValue,
              },
            ],
            sorting: [{ id: nameOf("name"), desc: false }],
          }}
          odata={{
            url: generatePath(APIExtRoutes.odataAdviserUsers, {
              practiceId,
            }),
            pickColumns,
            globalFilterColumns: ["name"],
          }}
          columns={columns as any}
          emptyMessage={
            "This section is empty. Click on “+ New team member” to add new users."
          }
          searchBoxProps={{
            placeholder: "Search team members",
          }}
          rightToolbar={
            <PlusButton
              onClick={() => {
                navigate(
                  generatePath(PageRoutes.practiceUsers, {
                    practiceId,
                    auth0Id: "new",
                  }),
                );
              }}
            >
              New team member
            </PlusButton>
          }
        />
      )}
    </SHStack>
  );
};
