import { BillingDetailDTO } from "@models/practice/entities/practiceBillingInfo";
import {
  createPaymentMethodThunk,
  loadPracticeBillingDetailThunk,
  loadPracticeBillingInfoThunk,
  loadPracticeClientSecretThunk,
  loadPracticePaymentMethodThunk,
  removePaymentMethodThunk,
  resetBillingDetailUIAction,
  setDefaultPaymentMethodThunk,
  setIsDirtyBillingAddressAction,
  setIsDirtyBillingContactAction,
  setIsValidBillingAddressAction,
  setIsValidBillingContactAction,
  setPracticeBillingDetailsAction,
  updateBillingAddressThunk,
} from "@redux/slices/practice";
import { RootState, useAppDispatch } from "@redux/store";
import { getCustomerPortalLink } from "@services/practice/practiceBillingInfoService";
import { useSelector } from "react-redux";
import { useNotification } from "./useNotification";

export const usePracticeBillingInfo = () => {
  const { notify } = useNotification();
  const dispatch = useAppDispatch();

  const {
    ui,
    practiceClientSecret,
    practicePaymentMethod,
    practiceBillingDetail,
    practiceBillingInfo,
  } = useSelector((state: RootState) => state.practice);

  const setPracticeBillingDetails = (billingDetail: BillingDetailDTO) => {
    dispatch(setPracticeBillingDetailsAction(billingDetail));
  };
  const setIsDirtyBillingAddress = (isDirty: boolean) => {
    dispatch(setIsDirtyBillingAddressAction(isDirty));
  };
  const setIsDirtyBillingContact = (isDirty: boolean) => {
    dispatch(setIsDirtyBillingContactAction(isDirty));
  };
  const setIsValidBillingAddress = (isValid: boolean) => {
    dispatch(setIsValidBillingAddressAction(isValid));
  };
  const setIsValidBillingContact = (isValid: boolean) => {
    dispatch(setIsValidBillingContactAction(isValid));
  };
  const resetBillingDetailUI = () => {
    dispatch(resetBillingDetailUIAction());
  };

  const loadClientSecret = async (adviserFirmId: string) => {
    const response = await dispatch(
      loadPracticeClientSecretThunk(adviserFirmId),
    ).unwrap();

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadBillingInfo = async (adviserFirmId: string) => {
    const response = await dispatch(
      loadPracticeBillingInfoThunk(adviserFirmId),
    ).unwrap();

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadPaymentMethod = async (adviserFirmId: string) => {
    const response = await dispatch(
      loadPracticePaymentMethodThunk(adviserFirmId),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadBillingDetail = async (adviserFirmId: string) => {
    const response = await dispatch(
      loadPracticeBillingDetailThunk(adviserFirmId),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadCustomerPortalLink = async (adviserFirmId: string) => {
    const response = await getCustomerPortalLink(adviserFirmId);

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const createPaymentMethod = async (
    adviserFirmId: string,
    paymentMethodId: string,
  ) => {
    const response = await dispatch(
      createPaymentMethodThunk({ adviserFirmId, paymentMethodId }),
    ).unwrap();

    if (!response.isSuccess) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updatePaymentMethodDefault = async (
    adviserFirmId: string,
    paymentMethodId: string,
  ) => {
    const response = await dispatch(
      setDefaultPaymentMethodThunk({ adviserFirmId, paymentMethodId }),
    ).unwrap();

    if (!response.isSuccess) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const removePaymentMethod = async (
    adviserFirmId: string,
    paymentMethodId: string,
  ) => {
    const response = await dispatch(
      removePaymentMethodThunk({ adviserFirmId, paymentMethodId }),
    ).unwrap();

    if (!response.isSuccess) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updateBillingDetail = async (
    adviserFirmId: string,
    billingDetail: BillingDetailDTO,
  ) => {
    const response = await dispatch(
      updateBillingAddressThunk({ adviserFirmId, billingDetail }),
    ).unwrap();

    if (!response.isSuccess) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  return {
    ui,
    setPracticeBillingDetails,
    setIsDirtyBillingAddress,
    setIsDirtyBillingContact,
    setIsValidBillingAddress,
    setIsValidBillingContact,
    practiceClientSecret,
    practiceBillingInfo,
    practiceBillingDetail,
    practicePaymentMethod,
    loadClientSecret,
    loadBillingInfo,
    loadBillingDetail,
    loadPaymentMethod,
    loadCustomerPortalLink,
    createPaymentMethod,
    removePaymentMethod,
    updatePaymentMethodDefault,
    updateBillingDetail,
    resetBillingDetailUI,
  };
};
