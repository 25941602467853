import { PopperPlacementType, SxProps, Theme } from "@mui/material";

export const configTooltipStyle: {
  [key in PopperPlacementType]?: {
    sx?: SxProps<Theme>;
    transform?: string;
  };
} = {
  "top-start": {
    sx: { position: "absolute", bottom: "-11.7px", left: 0 },
  },
  "bottom-start": {
    sx: { position: "absolute", top: "-10px", left: 0 },
    transform: "rotate(180)",
  },
  "top-end": {
    sx: { position: "absolute", bottom: "-11.7px", left: "49%" },
  },
  "top": {
    sx: { position: "absolute", bottom: "-1.7px", left: "48.5%" },
  }
};
