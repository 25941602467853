import { SHStack, SHStackProps } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { useTheme } from "@mui/material";

export const GroupCarouselSkeleton = ({ ...others }: SHStackProps) => {
  const { palette } = useTheme();
  return (
    <SHStack
      spacing={1}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        border: `1px solid ${palette.secondary[100]}`,
        padding: "10px 10px",
      }}
      {...others}
    >
      <SHStack
        width={"100%"}
        spacing={2}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {Array.from(Array(3).keys()).map((index) => (
          <SHSkeleton key={index} height={208} width={"33.3%"} />
        ))}
      </SHStack>
      <SHStack
        width={"100%"}
        spacing={1}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {Array.from(Array(4).keys()).map((index) => (
          <SHSkeleton
            key={index}
            height={"10px"}
            width={"10px"}
            variant="circular"
          />
        ))}
      </SHStack>
    </SHStack>
  );
};
