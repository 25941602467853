import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { FilterOptions } from "@models/news-and-updates/enums/filterOptions";
import { PostPeriodValue } from "@models/news-and-updates/enums/period";
import { PostStatus } from "@models/news-and-updates/enums/status";
import {
  GetPostsDTO,
  PostDetailDTO,
  NewsFeedProductDTO,
  PostFilters,
} from "@models/news-and-updates/entities/post";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { isNil } from "lodash";
import { generatePath } from "react-router";

export async function getPostDetail(
  id: string,
): Promise<APIResponse<GetPostsDTO>> {
  const response = await APIGatewayService.get(
    generatePath(APIExtRoutes.newsfeedPosts),
    { params: { limit: "1", id } },
  );
  return handleErrorProxy(response);
}
export async function getListPost(
  postFilters: PostFilters,
  paginationToken?: string,
  limit?: number,
): Promise<APIResponse<GetPostsDTO>> {
  let { productIds, filterString, monthsOffset, dateRange, statuses } =
    postFilters;
  let params = new URLSearchParams();
  params.append("limit", isNil(limit) ? "25" : limit?.toString());
  if (dateRange !== null && monthsOffset === PostPeriodValue.Custom) {
    params.append("dateRange.start", dateRange?.start ?? "");
    params.append("dateRange.end", dateRange?.end ?? "");
    monthsOffset = "";
  } else if (monthsOffset === PostPeriodValue.All) {
    monthsOffset = "";
  }
  params.append("monthsOffset", monthsOffset ?? "");
  params.append("filterString", filterString ?? "");
  productIds?.forEach(
    (id) =>
      id !== FilterOptions[FilterOptions.All] &&
      params.append("productIds", id),
  );
  statuses?.forEach(
    (statusItem) =>
      statusItem !== PostStatus.All && params.append("statuses", statusItem),
  );
  params.append("paginationToken", decodeURIComponent(paginationToken ?? ""));
  const response = await APIGatewayService.get(
    generatePath(APIExtRoutes.newsfeedPosts),
    { params: params },
  );
  return handleErrorProxy(response);
}
export async function createPost(
  post: PostDetailDTO,
): Promise<APIResponse<boolean>> {
  const response = await APIGatewayService.post<boolean>(
    APIExtRoutes.newsfeedPosts,
    post,
  );
  return handleErrorProxy(response);
}
export async function initializeNotification(): Promise<APIResponse<boolean>> {
  const response = await APIGatewayService.options<boolean>(
    APIExtRoutes.initializeNotifications,
  );
  return handleErrorProxy(response);
}

export async function updatePost(
  post: PostDetailDTO,
): Promise<APIResponse<boolean>> {
  const response = await APIGatewayService.patch<boolean>(
    APIExtRoutes.newsfeedPosts,
    post,
  );
  return handleErrorProxy(response);
}

export async function archivePost(
  postId: string,
): Promise<APIResponse<boolean>> {
  const response = await APIGatewayService.delete<boolean>(
    generatePath(APIExtRoutes.archivePosts, { postId }),
  );

  return handleErrorProxy(response);
}

export async function approvePost(
  postId: string,
): Promise<APIResponse<boolean>> {
  const response = await APIGatewayService.patch<boolean>(
    generatePath(APIExtRoutes.approvePosts, { postId }),
  );

  return handleErrorProxy(response);
}

export async function restorePost(
  postId: string,
): Promise<APIResponse<boolean>> {
  const response = await APIGatewayService.patch<boolean>(
    generatePath(APIExtRoutes.restorePosts, { postId }),
  );

  return handleErrorProxy(response);
}

export async function getListPublishedProduct(): Promise<
  APIResponse<NewsFeedProductDTO[]>
> {
  const response = await APIGatewayService.get(
    generatePath(APIExtRoutes.publishedProducts),
  );
  return handleErrorProxy(response);
}

export async function getListAssociatedProduct(): Promise<
  APIResponse<NewsFeedProductDTO[]>
> {
  const response = await APIGatewayService.get(
    generatePath(APIExtRoutes.associatedProducts),
  );
  return handleErrorProxy(response);
}
