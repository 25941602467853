import { SHContainer, SHStack } from "@components/design-systems";
import {
  SHDividerSkeleton,
  SHTextFieldSkeleton,
} from "@components/skeletons/design-systems";
import { SHCheckBoxGroupSkeleton } from "@components/skeletons/design-systems/SHCheckBoxGroupSkeleton";
import { SHRichTextSkeleton } from "@components/skeletons/design-systems/SHRichTextSkeleton";
import {
  SHFieldGroupSkeleton,
  SHFiledGroupTitleSkeleton,
} from "@components/skeletons/layouts";
import { StepName } from "@layouts/stepper/step-name";

export const AnalysisSetupSkeleton = () => {
  return (
    <SHContainer maxWidth="xl" sx={{ px: { xs: "16px", lg: "200px" } }}>
      <SHStack spacing={"48px"}>
        <StepName isLoading name={"Analysis setup"} stepNumber={0} />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={1} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
              <SHTextFieldSkeleton />
              <SHTextFieldSkeleton />
              <SHCheckBoxGroupSkeleton options={3} />
            </SHStack>
          }
        />
        <SHDividerSkeleton />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={1} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
              <SHTextFieldSkeleton />
              <SHTextFieldSkeleton />
            </SHStack>
          }
        />
        <SHDividerSkeleton />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={1} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
              <SHRichTextSkeleton />
            </SHStack>
          }
        />
      </SHStack>
    </SHContainer>
  );
};
