import { SHTypography } from "@components/design-systems/sh-typography";

interface ToolbarLetterCounterProps {
  count?: number;
  maxLength?: number;
}
export const ToolbarLetterCounter = ({
  count,
  maxLength,
}: ToolbarLetterCounterProps) => (
  <SHTypography variant="body2" colorVariant="disabled">
    {count} / {maxLength}
  </SHTypography>
);
