import { APIExtRoutes } from "@constants/routes";
import { APIResponse } from "@models/core";
import { AnalysisDTO, AnalysisFilterDTO } from "@models/platform-analysis/entities/analysis";
import { AnalysisBannerNameDTO } from "@models/platform-analysis/entities/bannerName";
import {
  PlatformAnalysisCommentDTO,
  PlatformAnalysisCommentParam,
} from "@models/platform-analysis/entities/comment";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import { AnalysisStepDTO } from "@models/platform-analysis/entities/step";
import { AnalysisSetupDTO } from "@models/platform-analysis/entities/steps/setup";
import { AnalysisStatus } from "@models/platform-analysis/enums/status";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { AnalysisSubStep } from "@models/platform-analysis/enums/subStep";
import { ProductLOV } from "@models/product/entities/product";
import { RateCardLOV } from "@models/rate-card/entities/rateCard";
import { UserLOV } from "@models/users/entities/user";
import { compilePlatformAnalysis } from "@services/platform-analysis/util";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { CancelToken } from "axios";
import { generatePath } from "react-router";

export async function getPlatformAnalysisAllSteps(
  platformAnalysisId?: string,
): Promise<APIResponse<AnalysisStepDTO>> {
  const response = await APIGatewayService.get<AnalysisStepDTO>(
    generatePath(APIExtRoutes.platformAnalysisAllSteps, { platformAnalysisId }),
  );

  return handleErrorProxy(response);
}

export async function getPlatformAnalysisProducts(
  supplierId?: string,
  filterByProductName?: string,
  configurationVersion?: number,
  apiPath?: string,
): Promise<APIResponse<ProductLOV[]>> {
  const response = await APIGatewayService.get<ProductLOV[]>(
    apiPath ? apiPath : APIExtRoutes.platformAnalysisProducts,
    {
      params: {
        supplierId,
        filterByProductName,
        configurationVersion,
      },
    },
  );
  return handleErrorProxy(response);
}

export async function checkAnalysisUniqueName(
  supplierId: string,
  name: string,
  ownerId: string,
  id?: string,
): Promise<APIResponse<unknown>> {
  const response = await APIGatewayService.post<unknown>(
    APIExtRoutes.platformAnalysisUniqueName,
    { supplierId, name, ownerId, id },
  );
  return handleErrorProxy(response);
}

export async function getPlatformAnalysisOwners(
  name?: string,
): Promise<APIResponse<UserLOV[]>> {
  const response = await APIGatewayService.get<UserLOV[]>(
    APIExtRoutes.platformAnalysisOwners,
    {
      params: {
        name,
      },
    },
  );
  return handleErrorProxy(response);
}

export async function getPlatformAnalysisBannerName(
  platformAnalysisId: string,
): Promise<APIResponse<AnalysisBannerNameDTO>> {
  const response = await APIGatewayService.get<AnalysisBannerNameDTO>(
    generatePath(APIExtRoutes.platformAnalysisBannerName, {
      platformAnalysisId,
    }),
  );
  return handleErrorProxy(response);
}

export async function getPlatformAnalysisStep(
  platformAnalysisId: string,
  stepId: AnalysisStep,
): Promise<APIResponse<AnalysisDTO>> {
  const response = await APIGatewayService.get<AnalysisDTO>(
    generatePath(APIExtRoutes.platformAnalysisStep, {
      platformAnalysisId,
      stepId,
    }),
  );
  if (response?.data) {
    compilePlatformAnalysis(response.data);
  }

  return handleErrorProxy(response);
}

export async function postPlatformAnalysis(
  setupStep: AnalysisSetupDTO,
): Promise<APIResponse<AnalysisDTO>> {
  const response = await APIGatewayService.post<AnalysisDTO>(
    APIExtRoutes.platformAnalysis,
    setupStep,
  );
  if (response?.data) {
    compilePlatformAnalysis(response.data);
  }
  return handleErrorProxy(response, "Platform analysis has been created.");
}

export async function putPlatformAnalysis(
  platformAnalysis: AnalysisDTO,
): Promise<APIResponse<AnalysisDTO>> {
  const response = await APIGatewayService.put<AnalysisDTO>(
    APIExtRoutes.platformAnalysis,
    platformAnalysis,
  );
  return handleErrorProxy(response, "Changes have been saved.");
}

export async function putPlatformAnalysisComment({
  platformAnalysisId,
  comment,
  productId,
  stepId,
}: PlatformAnalysisCommentParam): Promise<
  APIResponse<PlatformAnalysisCommentDTO>
> {
  const response = await APIGatewayService.put<PlatformAnalysisCommentDTO>(
    generatePath(APIExtRoutes.platformAnalysisComment, {
      platformAnalysisId,
      stepId,
      productId,
    }),
    { comment },
  );

  return handleErrorProxy(response, "Your comment has been saved.");
}

export async function putPlatformAnalysisStatus(
  platformAnalysisId: string,
  status: AnalysisStatus,
): Promise<APIResponse<AnalysisStatus>> {
  const response = await APIGatewayService.put<AnalysisStatus>(
    generatePath(APIExtRoutes.platformAnalysisStatus, {
      platformAnalysisId,
      status,
    }),
  );
  return handleErrorProxy(response, "Your analysis has been completed.");
}

export async function putPlatformAnalysisCurrentStep(
  platformAnalysisId: string,
  currentStep: AnalysisStep,
  currentSubStep?: AnalysisSubStep,
): Promise<APIResponse<unknown>> {
  const response = await APIGatewayService.put<unknown>(
    generatePath(APIExtRoutes.platformAnalysisCurrentStep, {
      platformAnalysisId,
    }),
    {
      currentStep,
      currentSubStep,
    },
  );
  return handleErrorProxy(response, "Your current step has been saved.");
}

export async function getPlatformAnalysisRateCards(
  analysisId: string,
  productIds: string[],
  cancelToken: CancelToken,
  configurationVersion?: number,
): Promise<APIResponse<RateCardLOV[]>> {
  const response = await APIGatewayService.get<RateCardLOV[]>(
    generatePath(APIExtRoutes.platformAnalysisRateCards, { analysisId }),
    {
      params: {
        productIds,
        configurationVersion,
      },
      cancelToken,
    },
  );
  return handleErrorProxy(response);
}

export async function getDisclaimers(
  analysisId: string
): Promise<APIResponse<DisclaimerDTO[]>> {
  const response = await APIGatewayService.get<DisclaimerDTO[]>(
    generatePath(APIExtRoutes.platformAnalysisDisclaimers, { analysisId })
  )

  return handleErrorProxy(response)
}

export async function duplicateAnalysis(
  analysisId: string
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.post<string>(
    generatePath(APIExtRoutes.platformAnalysisDuplication, { analysisId })
  )

  return handleErrorProxy(response, "The platform analysis has been duplicated.")
}

export async function savePlatformAnalysisFilters(
  analysisId: string,
  analysisFilters: AnalysisFilterDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.platformAnalysisFilter, { analysisId }),
    analysisFilters,
  );

  return handleErrorProxy(response);
}