import {
  SHBox,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { HistoryRelease } from "@models/configuration";
import { HistoryCard } from "../history-card";

export interface HistoryListProps extends SHStackProps {
  isEdit?: boolean;
  releases?: HistoryRelease[];
  width?: number | string;
  onClickView?: () => void;
  onClickEdit?: () => void;
}
export const HistoryList = ({
  isEdit,
  releases = [],
  width = "100%",
  onClickView,
  onClickEdit,
  ...others
}: HistoryListProps) => {
  return (
    <SHStack spacing={1} marginTop={3} {...others}>
      {!releases || releases.length === 0 ? (
        <SHBox textAlign="center">
          <SHTypography variant="body2">
            This section does not have any releases.
          </SHTypography>
        </SHBox>
      ) : (
        releases.map((release, index) => (
          <HistoryCard
            key={index}
            isOdd={index % 2 === 0}
            release={release}
            isEdit={isEdit}
            onClickView={onClickView}
            onClickEdit={onClickEdit}
          />
        ))
      )}
    </SHStack>
  );
};
