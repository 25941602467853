import {
  AnalysisFeatureDTO,
  PlatformAnalysisFilter,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import { filter, isEmpty, map, some } from "lodash";
export const filterFeatureAnalysisByValue = (
  featureAnalysisData?: AnalysisFeatureDTO,
  filterValue?: PlatformAnalysisFilter,
) => {
  return {
    ...featureAnalysisData,
    sections: filter(
      map(featureAnalysisData?.sections, (section) => {
        return {
          ...section,
          subSections: filter(
            map(section?.subSections, (subSection) => {
              return {
                ...subSection,
                features: filter(subSection?.features, (feature) => {
                  const isAvailable = some(feature.analysisData, {
                    value: AnalysisDataValue.True,
                  });
                  const isUnavailable = some(feature.analysisData, {
                    value: AnalysisDataValue.False,
                  });
                  const isMissingData = some(feature.analysisData, {
                    value: AnalysisDataValue.MissingData,
                  });

                  return filterValue === PlatformAnalysisFilter.Common
                    ? !isUnavailable && !isMissingData
                    : isAvailable && (isUnavailable || isMissingData);
                }),
              };
            }),
            (subSection) => !isEmpty(subSection?.features),
          ),
        };
      }),
      (section) => !isEmpty(section?.subSections),
    ),
  } as AnalysisFeatureDTO;
};
