import { SHButton } from "@components/design-systems";
import { ApprovePostConfirmationDialog } from "@components/dialogs/approve-post-confirmation";
import { useNewsFeed } from "@hooks/useNewsFeed";
import { useNotification } from "@hooks/useNotification";
import { approvePost } from "@services/newsfeed/postService";
import { useState } from "react";

export interface ApprovePostButtonProps {
  postId: string;
}

export const ApprovePostButton = ({ postId }: ApprovePostButtonProps) => {
  const { notify } = useNotification();
  const { loadPosts } = useNewsFeed();

  const [isApprovingPost, setIsApprovingPost] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState(false);

  const handleOnApprovePost = async () => {
    setIsApprovingPost(true);
    const { isSuccess, message } = await approvePost(postId);
    if (!isSuccess) {
      notify(message, {
        variant: "error",
        close: true,
      });
    } else {
      notify("Post published.", {
        variant: "success",
        close: true,
      });
      loadPosts();
    }
    setIsApprovingPost(false);
    setShowDialog(false);
  };

  return (
    <>
      <SHButton
        variant="outlined"
        onClick={() => {
          setShowDialog(true);
        }}
      >
        Approve
      </SHButton>
      {showDialog && (
        <ApprovePostConfirmationDialog
          open
          isLoading={isApprovingPost}
          onClose={() => setShowDialog(false)}
          onOk={handleOnApprovePost}
        />
      )}
    </>
  );
};
