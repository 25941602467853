import { FilterValue } from "@components/buttons/filter";
import { FilterRadioButton } from "@components/buttons/filter-radio";
import {
  SHBox,
  SHContainer,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { LocalStorage } from "@constants";
import { DateFormat } from "@constants/format";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { useReview } from "@hooks/useReview";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { PlatformCommentDialog } from "@models/platform-analysis/entities/comment";
import { ReviewDecisionStatus } from "@models/reviews/entities/status";
import {
  ReviewSelectedProductDTO,
  ReviewStepFeatureContainerDTO,
  UpdateDecisionMarkingDTO,
} from "@models/reviews/entities/steps/feature";
import { ReviewStep } from "@models/reviews/enums/step";
import { FeatureAnalysisSkeleton } from "@pages/platform-analysis/_id/steps/feature/analysis/skeleton";
import { ChangeRichTextDialog } from "@pages/platform-analysis/_id/steps/summary/components/change-rich-text-dialog";
import { DisqualifyAllPlatformDialog } from "@pages/reviews/_id/steps/feature/analysis/components/dialogs/confirm-select-all";
import { NotifyUnShortedListDialog } from "@pages/reviews/_id/steps/feature/analysis/components/dialogs/notify-un-shorted-list";
import {
  EssentialFeaturesFilter,
  EssentialFeaturesFilterOptions,
  FeatureFilter,
  FeatureFilterOptions,
  GroupByDecisionStatus,
  UpdateDecisionStatus,
} from "@pages/reviews/_id/steps/feature/analysis/config";
import {
  updateCollapseMappingAction,
  updateFeatureReviewRowsAction,
  updateFilterFeatureAction,
} from "@redux/slices/review";
import { RootState } from "@redux/store";
import {
  disqualifyAllPlatform,
  putReviewDecisionMaking,
} from "@services/reviews/reviewService";
import { format } from "date-fns";
import { groupBy, isEmpty, isNil, mapValues } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { SHTableVirtuoso } from "./table-improved";
import { filterFeatureReviewByFeature, flatFeatureReviewData } from "./util";

export interface FeatureAnalysisProps extends StepCompProps {}
export const ReviewFeature: ForwardRefRenderFunction<
  StepCompRef,
  StepCompProps
> = ({ stepNumber }, ref) => {
  const dispatch = useDispatch();
  const { reviewId } = useParams<{ reviewId: string }>();
  const [scrollingTop, setScrollingTop] = useState(false);
  const [loadedFromStore, setLoadedFromStore] = useState(false);
  const [updatingComment, setUpdatingComment] = useState(false);
  const [updatingDecisionStatus, setUpdatingDecisionStatus] = useState<
    UpdateDecisionStatus | undefined
  >(undefined);

  const [featureReview, setFeatureReview] =
    useState<ReviewStepFeatureContainerDTO>();
  const [initialFeatureReview, setInitialFeatureReview] =
    useState<ReviewStepFeatureContainerDTO>();
  const [commentDialog, setCommentDialog] = useState<
    PlatformCommentDialog | undefined
  >();

  const [disqualifyAllDialog, setDisqualifyAllDialog] = useState<
    | {
        callback: (value: boolean | Promise<boolean>) => void;
        productsGrouped: GroupByDecisionStatus;
      }
    | undefined
  >(undefined);

  const [isSubmittingDisqualifyDialog, setIsSubmittingDisqualifyDialog] =
    useState(false);

  const [notifyUnShortedListDialog, setNotifyUnShortedListDialog] =
    useState(false);

  const {
    ui: { isLoading, filterFeature },
    review,
    featureReviewRows,
  } = useSelector((state: RootState) => state.review);

  const { loadReviewStep, updateReviewDecisionMaking } = useReview();
  const { removeLocalStorageItem } = useLocalStorage();

  const selectedProducts =
    featureReview?.selectedProducts as ReviewSelectedProductDTO[];

  const handleOnChangeDecisionStatus = async (
    platformId: string,
    decisionStatus: ReviewDecisionStatus,
  ) => {
    setUpdatingDecisionStatus({ platformId, decisionStatus });

    const { isSuccess, data } = await updateReviewDecisionMaking({
      reviewId: reviewId,
      reviewStep: ReviewStep.Feature,
      productId: platformId,
      decisionStatus:
        decisionStatus !== ReviewDecisionStatus.Undecided
          ? decisionStatus
          : null,
      comment: null,
    } as UpdateDecisionMarkingDTO);

    setUpdatingDecisionStatus(undefined);

    if (isSuccess) {
      const newFeatureReview = {
        ...featureReview,
        selectedProducts: featureReview?.selectedProducts?.map((platform) => {
          if (platform.id !== platformId) return platform;
          return {
            ...platform,
            decisionStatus: data?.decisionStatus,
          };
        }),
      } as ReviewStepFeatureContainerDTO;
      setFeatureReview(newFeatureReview);

      const newInitFeatureReview = {
        ...initialFeatureReview,
        selectedProducts: initialFeatureReview?.selectedProducts?.map(
          (platform) => {
            if (platform.id !== platformId) return platform;
            return {
              ...platform,
              decisionStatus: data?.decisionStatus,
            };
          },
        ),
      } as ReviewStepFeatureContainerDTO;
      setInitialFeatureReview(newInitFeatureReview);
    }
  };

  const handleOnChangeComment = async (comment: string | null) => {
    setUpdatingComment(true);

    const { isSuccess } = await putReviewDecisionMaking({
      reviewId: reviewId,
      reviewStep: ReviewStep.Feature,
      productId: commentDialog?.productId,
      decisionStatus: null,
      comment: comment,
    } as UpdateDecisionMarkingDTO);

    setUpdatingComment(false);
    setCommentDialog(undefined);

    if (isSuccess) {
      const newFeatureReview = {
        ...featureReview,
        selectedProducts: featureReview?.selectedProducts?.map((platform) => {
          if (platform.id !== commentDialog?.productId) return platform;
          return {
            ...platform,
            comment: {
              comment: comment,
            },
          };
        }),
      } as ReviewStepFeatureContainerDTO;
      setFeatureReview(newFeatureReview);

      const newInitFeatureReview = {
        ...initialFeatureReview,
        selectedProducts: initialFeatureReview?.selectedProducts?.map(
          (platform) => {
            if (platform.id !== commentDialog?.productId) return platform;
            return {
              ...platform,
              comment: {
                comment: comment,
              },
            };
          },
        ),
      } as ReviewStepFeatureContainerDTO;
      setInitialFeatureReview(newInitFeatureReview);
    }
  };

  const getDisqualifyAllPlatform = async (
    productsGrouped: GroupByDecisionStatus,
  ): Promise<boolean> => {
    return await new Promise((res) => {
      setDisqualifyAllDialog({
        callback: res,
        productsGrouped,
      });
    });
  };

  const handleOnDisqualifyAllPlatform = async () => {
    if (!reviewId) return false;

    setIsSubmittingDisqualifyDialog(true);
    const { isSuccess } = await disqualifyAllPlatform(
      reviewId,
      ReviewStep.Feature,
    );
    setIsSubmittingDisqualifyDialog(false);

    disqualifyAllDialog?.callback(isSuccess);
  };

  const handleOnSubmit = async () => {
    const groupByDecisionStatus: GroupByDecisionStatus = mapValues(
      groupBy(featureReview?.selectedProducts, "decisionStatus"),
      (group) => group.map((obj) => obj.id),
    );

    const UndecidedPlatformIds =
      groupByDecisionStatus[ReviewDecisionStatus.Undecided];
    const shortedListPlatformIds =
      groupByDecisionStatus[ReviewDecisionStatus.Shortlisted];

    if (isEmpty(shortedListPlatformIds)) {
      setNotifyUnShortedListDialog(true);
      return false;
    }

    if (!isEmpty(UndecidedPlatformIds)) {
      const isYes = await getDisqualifyAllPlatform(groupByDecisionStatus);
      setDisqualifyAllDialog(undefined);
      return isYes;
    }

    return true;
  };

  const loadFeatureReview = async () => {
    if (reviewId) {
      const analysis = await loadReviewStep({
        reviewId: reviewId,
        stepId: ReviewStep.Feature,
      });

      if (analysis && analysis?.feature) {
        setInitialFeatureReview(analysis?.feature.all);
        setFeatureReview(analysis?.feature.all);
      }
    }
    setLoadedFromStore(true);
  };

  useEffectOnce(() => {
    loadFeatureReview();
    removeLocalStorageItem(LocalStorage.scrollingLeft);
    removeLocalStorageItem(LocalStorage.scrollingTop);
  });

  useEffect(() => {
    if (isEmpty(featureReview?.sections))
      dispatch(updateFeatureReviewRowsAction([]));
    else {
      const data = flatFeatureReviewData(featureReview?.sections);
      dispatch(updateFeatureReviewRowsAction(data.rows));
      dispatch(updateCollapseMappingAction(data.collapseMapping));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureReview]);

  const getFilterByFeatureValues = (filters?: FilterValue[]) => {
    if (filters === undefined) {
      return [];
    }

    if (filters?.length > 0) {
      const arrValues: FeatureFilter[] = filters.map((filter) => filter.value);
      return arrValues;
    }

    return [];
  };

  const getFilterByEssentialValues = (filters?: FilterValue[]) => {
    if (filters === undefined) {
      return [];
    }

    if (filters?.length > 0) {
      const arrValues: EssentialFeaturesFilter[] = filters.map(
        (filter) => filter.value,
      );
      return arrValues;
    }

    return [];
  };

  const handleOnMultipleFilter = async () => {
    const rejectFilter =
      isNil(filterFeature) ||
      (isEmpty(filterFeature.Feature) &&
        isEmpty(filterFeature.FeatureEssential));

    if (rejectFilter) {
      setFeatureReview(initialFeatureReview);
      return;
    }
    const featureReviewByFiltered = filterFeatureReviewByFeature(
      initialFeatureReview,
      filterFeature?.Feature?.[0],
      filterFeature?.FeatureEssential?.[0],
    ) as ReviewStepFeatureContainerDTO;
    setFeatureReview(featureReviewByFiltered);
  };

  useEffect(() => {
    handleOnMultipleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFeature, initialFeatureReview]);

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep, isNext) => {
      dispatch(
        updateFilterFeatureAction({
          Feature: [],
          FeatureEssential: [],
        }),
      );
      if (isNext) {
        return await handleOnSubmit();
      }
      return true;
    },
    onChangeOtherStep: async () => {
      dispatch(
        updateFilterFeatureAction({
          Feature: [],
          FeatureEssential: [],
        }),
      );
      return true;
    },
    onSubmit: async () => {
      return await handleOnSubmit();
    },
  }));

  if (isLoading || !loadedFromStore) return <FeatureAnalysisSkeleton />;

  return (
    <SHContainer
      maxWidth="lg2"
      sx={{
        px: {
          xs: "16px",
          lg2: 0,
        },
        py: "25px",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        "::-webkit-scrollbar": {
          boxShadow: "none",
        },
        "::-webkit-scrollbar-thumb": {
          boxShadow: "none",
        },
      }}
    >
      <SHStack
        direction={"row"}
        justifyContent={"space-between"}
        className={`featureReview__headerInfo${scrollingTop ? " sticky" : ""}`}
        sx={{
          transition: "all 0.5s ease",
        }}
      >
        <StepName name={"Feature review"} stepNumber={stepNumber} />
        <SHBox
          sx={{
            display: "inline-flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <SHTypography
            variant="body3"
            colorVariant="third"
            textAlign={"left"}
            lineHeight="120%"
            sx={{
              marginTop: "10px",
            }}
          >
            {review?.databaseVersion
              ? `Data valid: ${format(
                  new Date(review?.databaseVersion),
                  DateFormat,
                )}`
              : ""}
          </SHTypography>
          {(!isNil(featureReview) || !isEmpty(featureReviewRows)) && (
            <SHStack
              sx={{
                alignItems: "flex-end",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <FilterRadioButton
                buttonTitle={"Essential features"}
                filterData={EssentialFeaturesFilterOptions}
                width={"275px"}
                labelAll={"All features"}
                zIndex={10}
                isScrolling={scrollingTop}
                onChangeFilter={(filters) =>
                  dispatch(
                    updateFilterFeatureAction({
                      ...filterFeature,
                      FeatureEssential: getFilterByEssentialValues(filters),
                    }),
                  )
                }
              />
              <FilterRadioButton
                buttonTitle={"Feature filter"}
                filterData={FeatureFilterOptions}
                width={"275px"}
                labelAll={"All features"}
                zIndex={10}
                isScrolling={scrollingTop}
                onChangeFilter={(filters) =>
                  dispatch(
                    updateFilterFeatureAction({
                      ...filterFeature,
                      Feature: getFilterByFeatureValues(filters),
                    }),
                  )
                }
              />
            </SHStack>
          )}
        </SHBox>
      </SHStack>
      <SHTableVirtuoso
        selectedProducts={selectedProducts}
        onScrollingTop={(isScrollingTop: boolean) =>
          setScrollingTop(isScrollingTop)
        }
        onChangeDecisionStatus={handleOnChangeDecisionStatus}
        updatingDecisionStatus={updatingDecisionStatus}
        onModifyComment={(platformId: string, comment?: string) => {
          setCommentDialog({
            comment: comment ?? null,
            productId: platformId,
          });
        }}
      />

      {commentDialog && (
        <ChangeRichTextDialog
          onSubmit={handleOnChangeComment}
          title={
            isEmpty(commentDialog.comment) ? "Add comment" : "Edit comment"
          }
          placeHolder={"Enter your comment"}
          initialContent={commentDialog.comment ?? null}
          isSubmitting={updatingComment}
          onClose={() => {
            setCommentDialog(undefined);
          }}
          maxLength={1500}
          isCommentBox
        />
      )}
      {disqualifyAllDialog && (
        <DisqualifyAllPlatformDialog
          productsGrouped={disqualifyAllDialog.productsGrouped}
          onCancel={() => disqualifyAllDialog.callback(false)}
          onSubmit={handleOnDisqualifyAllPlatform}
          isSubmitting={isSubmittingDisqualifyDialog}
        />
      )}
      {notifyUnShortedListDialog && (
        <NotifyUnShortedListDialog
          onOK={() => setNotifyUnShortedListDialog(false)}
        />
      )}
    </SHContainer>
  );
};
