import { SHButton } from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { useProduct } from "@hooks/useProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { RootState } from "@redux/store";
import { revertToDraft } from "@services/product/productsService";
import { useState } from "react";
import { useSelector } from "react-redux";
import RevertToDraftDialog from "../../dialogs/revert-to-draft";

export interface RevertToDraftButtonProps {
  productId?: string;
}

export const RevertToDraftButton = ({
  productId,
}: RevertToDraftButtonProps) => {
  const { notify } = useNotification();
  const { loadBannerInfo, updateProductBanners } = useProduct();
  const { canRevertToDraftProduct } = useUserPermissions();

  const {
    productUI: { isEditMode },
  } = useSelector((state: RootState) => state.product);

  const [showRevertToDraftDialog, setShowRevertToDraftDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isVisible = isEditMode && canRevertToDraftProduct;

  const handleOnRevertToDraft = async () => {
    if (!productId) return;
    setIsSubmitting(true);
    const { isSuccess, message } = await revertToDraft(productId);
    setIsSubmitting(false);
    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      updateProductBanners(productId, "Revert to draft")
      loadBannerInfo(productId);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <>
      {isVisible && (
        <SHButton
          variant="outlined"
          size="extraMedium"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => setShowRevertToDraftDialog(true)}
        >
          Revert to draft
        </SHButton>
      )}
      {showRevertToDraftDialog && (
        <RevertToDraftDialog
          onClose={() => setShowRevertToDraftDialog(false)}
          onSubmit={handleOnRevertToDraft}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};
