import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Text } from "@react-pdf/renderer";
import { PDFTitleSection } from "../../title-section";

interface PDFPlatformIncludedProps {
  platformDisplayed: string;
}

export const PDFPlatformIncluded = ({
  platformDisplayed,
}: PDFPlatformIncludedProps) => {
  const { typographyStyle } = useStyles();
  return (
    <>
      <PDFTitleSection title="Platforms included in the analysis" />,
      <Text
        style={{
          ...typographyStyle?.body1,
        }}
      >
        {platformDisplayed}
      </Text>
    </>
  );
};
