import mainBgImage from "@assets/images/background/bg_login.jpg";
import { SHBox, SHDialog, SHTypography } from "@components/design-systems";

export const MaintenancePage: React.FC = () => {
  return (
    <SHBox
      sx={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        backgroundImage: `url(${mainBgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      data-testid="sh-box"
    >
      <SHDialog
        maxWidth="sm"
        width={525}
        open
        header={<SHTypography variant="h2">Scheduled maintenance</SHTypography>}
        body={
          <SHTypography variant="body2">
            We’re updating SuitabilityHub and will be back soon!
          </SHTypography>
        }
      />
    </SHBox>
  );
};
