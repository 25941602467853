import { SVGProps } from "react";

interface EssentialStarSVGProps {
  fillPath?: string;
  fillRect?: string;
}
export const EssentialStarSVG = ({
  stroke = "#F3612D",
  fillPath = "#FFF500",
  fillRect = "#FF753C",
  ...others
}: SVGProps<SVGSVGElement> & EssentialStarSVGProps) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_17150_39877)">
      <rect
        x="1.5"
        y="1.5"
        width="23"
        height="23"
        rx="11.5"
        fill="white"
        shapeRendering="crispEdges"
      />
      <rect
        x="1.5"
        y="1.5"
        width="23"
        height="23"
        rx="11.5"
        fill={fillRect}
        fillOpacity="0.1"
        shapeRendering="crispEdges"
      />
      <rect
        x="2"
        y="2"
        width="22"
        height="22"
        rx="11"
        stroke={stroke}
        strokeOpacity="0.2"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M13.9511 6.80902C13.6517 5.8877 12.3483 5.88771 12.0489 6.80902L10.9529 10.1824H7.40592C6.43719 10.1824 6.03442 11.422 6.81813 11.9914L9.68768 14.0762L8.59161 17.4496C8.29226 18.3709 9.34674 19.137 10.1305 18.5676L13 16.4828L15.8695 18.5676C16.6533 19.137 17.7077 18.3709 17.4084 17.4496L16.3123 14.0762L19.1819 11.9914C19.9656 11.422 19.5628 10.1824 18.5941 10.1824L15.0471 10.1824L13.9511 6.80902Z"
      fill={fillPath}
      stroke={stroke}
    />
    <defs>
      <filter
        id="filter0_d_17150_39877"
        x="0.5"
        y="0.5"
        width="27"
        height="27"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.254902 0 0 0 0 0.286275 0 0 0 0 0.34902 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_17150_39877"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_17150_39877"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
