import AmexCardIcon from "@assets/images/card-logo/amex.svg";
import DefaultIcon from "@assets/images/card-logo/default.svg";
import JcbCardIcon from "@assets/images/card-logo/jcb.svg";
import MastercardIcon from "@assets/images/card-logo/mastercard.svg";
import UnionPayCardIcon from "@assets/images/card-logo/unionpay.svg";
import VisaCardIcon from "@assets/images/card-logo/visa.svg";
import {
  SHBox,
  SHButton,
  SHImage,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { PaymentMethodDTO } from "@models/practice/entities/practiceBillingInfo";
import { useTheme } from "@mui/material";
import { isEmpty, startCase } from "lodash";
import { ModifyPaymentMethodButton } from "../buttons";
import { usePractice } from "@hooks/usePractice";

interface PaymentMethodLisProps {
  paymentMethods: PaymentMethodDTO[];
}

export const PaymentMethodList = ({
  paymentMethods,
}: PaymentMethodLisProps) => {
  const { palette } = useTheme();
  const {
    ui: {
      billingInfo: { showRedDot },
    },
  } = usePractice();

  if (isEmpty(paymentMethods))
    return <SHTypography> No payment methods has been created</SHTypography>;

  return (
    <SHStack
      sx={{
        gap: "10px",
      }}
    >
      {paymentMethods?.map((paymentMethod) => {
        const {
          brand,
          cardNumberLast4,
          expireMonth,
          expireYear,
          isDefault,
          paymentMethodId,
        } = paymentMethod;

        return (
          <SHStack flexDirection={"row"} justifyContent={"space-between"}>
            <SHStack flexDirection={"row"} justifyContent={"flex-start"}>
              <SHStack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <SHStack flexDirection={"row"}>
                  {renderCardLogo(brand)}
                  <SHTypography
                    variant="body2"
                    colorVariant="disabled"
                    paddingLeft={"5px"}
                  >
                    {startCase(brand)} **** {cardNumberLast4}
                  </SHTypography>
                </SHStack>
                {isDefault && (
                  <SHBox sx={{ marginTop: "-10px", marginLeft: "5px" }}>
                    <SHTypography
                      variant="body2"
                      colorVariant="disabled"
                      fontSize={"10px"}
                      sx={{
                        backgroundColor: palette.text.darker,
                        color: palette.text.white,
                        borderRadius: "5px",
                        paddingX: "8px",
                        paddingTop: "2px",
                        fontWeight: 600,
                      }}
                    >
                      Default
                    </SHTypography>
                  </SHBox>
                )}
              </SHStack>
            </SHStack>

            <SHStack flexDirection={"row"} alignItems={"center"}>
              {(isDefault && showRedDot) ? (
                <SHTypography
                  variant="body2"
                  paddingLeft={"5px"}
                  marginRight={"10px"}
                  width={"120px"}
                  sx={{
                    color: palette.warning[800]
                  }}
                >
                  Expires {expireMonth}/{expireYear}
                </SHTypography>
              ) : (
                <SHTypography
                  variant="body2"
                  colorVariant="disabled"
                  paddingLeft={"5px"}
                  marginRight={"10px"}
                  width={"120px"}
                >
                  Expires {expireMonth}/{expireYear}
                </SHTypography>
              )}
              {!isDefault ? (
                <ModifyPaymentMethodButton paymentMethodId={paymentMethodId} />
              ) : (
                <SHButton disabled={true}></SHButton>
              )}
            </SHStack>
          </SHStack>
        );
      })}
    </SHStack>
  );
};

export const renderCardLogo = (
  cardBrand: string,
  width?: number | string,
  height?: number | string,
) => {
  let src;
  switch (cardBrand) {
    case "visa":
      src = VisaCardIcon;
      break;
    case "mastercard":
      src = MastercardIcon;
      break;
    case "jcb":
      src = JcbCardIcon;
      break;
    case "unionpay":
      src = UnionPayCardIcon;
      break;
    case "amex":
      src = AmexCardIcon;
      break;
    default:
      src = DefaultIcon;
  }

  return (
    <SHImage
      src={src}
      width={width ?? 30}
      height={height ?? 25}
      style={{ objectFit: "contain" }}
    />
  );
};
