import { AnalysisFeatureIncludeDTO } from "@models/platform-analysis/entities/steps/feature";
import { TABLE_WIDTH } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { PDFEssentialNoticeSection } from "@pages/reviews/components/buttons/export-pdf/sections/essential-notice";
import { Path, Svg, Text, View } from "@react-pdf/renderer";
import { PDFReviewTable } from "../../layouts/tables";
import { PDFReviewTableBody } from "../../layouts/tables/table-body";
import { PDFReviewTableCell } from "../../layouts/tables/table-cell";
import { PDFReviewTableRow } from "../../layouts/tables/table-row";
import { useReviewStyles } from "../../styles";
import { calculateTextHeightInFeatureIncluded } from "../../util";

export interface PDFReviewFeaturesIncludedSectionProps {
  featureInclude?: AnalysisFeatureIncludeDTO[] | null;
}

export const PDFFeaturesIncludedSection = ({
  featureInclude,
}: PDFReviewFeaturesIncludedSectionProps) => {
  const { typographyStyle } = useReviewStyles();
  const groupCellWidth = 70;
  const subGroupCellWidth = 90;
  const featureCellWidth = TABLE_WIDTH - (groupCellWidth + subGroupCellWidth);
  const hasEssentialFeatures = featureInclude?.some(
    (row) => row.features?.some((feature) => feature.isEssential)
  );
  
  return (
    <View>
      <PDFReviewTable>
        <PDFReviewTableRow height={"25px"} borderBottomWidth={"1px"}>
          <PDFReviewTableCell width={groupCellWidth}>
            <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
              Feature group
            </Text>
          </PDFReviewTableCell>
          <PDFReviewTableCell width={subGroupCellWidth}>
            <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
              Feature sub-group
            </Text>
          </PDFReviewTableCell>
          <PDFReviewTableCell width={featureCellWidth}>
            <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
              Features
            </Text>
          </PDFReviewTableCell>
        </PDFReviewTableRow>

        <PDFReviewTableBody>
          {featureInclude?.map((row, index) => {
            let height = calculateTextHeightInFeatureIncluded(row);
            return (
              <PDFReviewTableRow key={index} height={height}>
                <PDFReviewTableCell
                  width={groupCellWidth}
                  alignItems="flex-start"
                >
                  <Text
                    style={{
                      ...typographyStyle.body2,
                    }}
                  >
                    {row.sectionName}
                  </Text>
                </PDFReviewTableCell>
                <PDFReviewTableCell
                  width={subGroupCellWidth}
                  alignItems="flex-start"
                >
                  <Text style={{ ...typographyStyle.body2 }}>
                    {row.subSectionName}
                  </Text>
                </PDFReviewTableCell>
                <PDFReviewTableCell
                  width={featureCellWidth}
                  alignItems="flex-start"
                >
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {row?.features?.map((feature, index) => {
                      return (
                        <>
                          <Text
                            style={{
                              ...typographyStyle.body2,
                              lineHeight: 1.2,
                              marginRight: 1,
                            }}
                          >
                            {feature.featureName}
                          </Text>
                          {feature.isEssential && (
                            <Svg width="7" height="7" viewBox="0 0 13 12">
                              <Path
                                fill="#FFF500"
                                stroke="#F3612D"
                                d="M7.71668 1.30902C7.41733 0.387703 6.11392 0.38771 5.81457 1.30902L4.94301 3.99139H2.1226C1.15387 3.99139 0.751103 5.23101 1.53481 5.80041L3.81658 7.4582L2.94502 10.1406C2.64567 11.0619 3.70015 11.828 4.48386 11.2586L6.76562 9.60081L9.04739 11.2586C9.8311 11.828 10.8856 11.0619 10.5862 10.1406L9.71467 7.4582L11.9964 5.80041C12.7801 5.23101 12.3774 3.99139 11.4087 3.99139H8.58824L7.71668 1.30902Z"
                              ></Path>
                            </Svg>
                          )}
                          {index !== row?.features.length - 1 && (
                            <Text
                              style={{
                                ...typographyStyle.body2,
                                lineHeight: 1.2,
                                marginRight: 1.2,
                              }}
                            >
                              ,
                            </Text>
                          )}
                        </>
                      );
                    })}
                  </View>
                </PDFReviewTableCell>
              </PDFReviewTableRow>
            );
          })}
        </PDFReviewTableBody>
      </PDFReviewTable>
      {hasEssentialFeatures && <PDFEssentialNoticeSection />}
    </View>
  );
};
