export const tranferAuth0Error = (message: string | null) => {
  if (!message) return null;
  switch (message) {
    case "invalid_request":
      return "Invalid Request";
    default:
      return message;
  }
};

export const tranferAuth0ErrorDescription = (message: string | null) => {
  if (!message) return null;
  switch (message) {
    case "Access expired.":
      return "This link has expired";
    case "This URL can be used only once":
      return "This link has expired";
    default:
      return message;
  }
};
