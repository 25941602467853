import { SHContainer, SHStack } from "@components/design-systems";
import {
  SHDividerSkeleton,
  SHTextFieldSkeleton,
} from "@components/skeletons/design-systems";
import { SHRichTextSkeleton } from "@components/skeletons/design-systems/SHRichTextSkeleton";
import {
  SHFieldGroupSkeleton,
  SHFiledGroupTitleSkeleton,
  SHTopBarSkeleton,
} from "@components/skeletons/layouts";
import { FeatureSelectionSkeleton } from "@pages/platform-analysis/components/feature-selection/skeleton";

export const FeatureTemplateSkeleton = () => {
  return (
    <SHContainer maxWidth="xl" sx={{ px: { xs: "16px", lg: "200px" } }}>
      <SHTopBarSkeleton totalButtons={3} />
      <SHStack spacing={3} paddingBottom="25px">
        <SHFieldGroupSkeleton
          sx={{ width: "50%" }}
          leftSide={<SHFiledGroupTitleSkeleton fullWidth subTitleRows={2} />}
        />
        <SHDividerSkeleton />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={2} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
              <SHTextFieldSkeleton />
              <SHRichTextSkeleton hasLabel />
            </SHStack>
          }
        />
        <FeatureSelectionSkeleton />
      </SHStack>
    </SHContainer>
  );
};
