import { configTooltipStyle } from "@components/buttons/information/config";
import {
  SHBox,
  SHPopper,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import {
  CheckSquareSVG,
  CrossSquareSVG,
  InfoSVG,
  TooltipAllowSVG,
  WarningSVG,
} from "@components/svgs";
import { MAAnalysisTooltipDataDTO } from "@models/managed-accounts/entities/step/fee";
import { ManagedAccountFieldType } from "@models/managed-accounts/enums/fieldType";
import { ManagedAccountFieldTypeValue } from "@models/managed-accounts/enums/fieldTypeValue";
import { IconButton, PopperPlacementType, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";

export interface InformationButtonProps {
  margin?: string | number;
  title?: string | React.ReactNode;
  data?: MAAnalysisTooltipDataDTO[];
  specialFormat?: boolean;
  placement?: PopperPlacementType;
  color?: string;
  hoverColor?: string;
  activeColor?: string;
  [key: string]: any;
}
export const MultipleInformationButton = ({
  margin,
  title,
  data,
  specialFormat = false,
  placement = "top",
  color,
  hoverColor,
  activeColor,
  ...others
}: InformationButtonProps) => {
  const { palette, shadows } = useTheme();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const tooltipConfig = configTooltipStyle[placement];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, buttonRef, open]);

  const renderValueByType = (value: any, type: string) => {
    if (value === ManagedAccountFieldTypeValue.NotDisclosed) {
      return (
        <SHStack direction="row" justifyContent="space-between">
          <SHStack
            direction="row"
            spacing={1}
            justifyContent="center"
            mt={"3px"}
          >
            <SHStack mt={"1px"}>
              <WarningSVG backgroundColor="#EB5757" iconColor="white" />
            </SHStack>
            <SHTypography
              variant="body2"
              colorVariant="third"
              lineHeight="120%"
            >
              {ManagedAccountFieldTypeValue.NotDisclosed}
            </SHTypography>
          </SHStack>
        </SHStack>
      );
    }

    if (type === ManagedAccountFieldType.YesNo) {
      if (specialFormat) {
        return (
          <SHTypography variant="body2" zIndex="1">
            {`<12 months old`}
          </SHTypography>
        );
      }
      return value === "True" ? <CheckSquareSVG /> : <CrossSquareSVG />;
    }

    if (type === ManagedAccountFieldType.Percentage) {
      return (
        <SHTypography variant="body2" zIndex="1">
          {value}%
        </SHTypography>
      );
    }

    if (type === ManagedAccountFieldType.DateTime) {
      return (
        <SHTypography variant="body2" zIndex="1">
          {value}
        </SHTypography>
      );
    }

    if (type === ManagedAccountFieldType.Dropdown) {
      return (
        <SHTypography variant="body2" zIndex="1">
          {value}
        </SHTypography>
      );
    }

    if (type === ManagedAccountFieldType.Currency) {
      if (specialFormat) {
        return (
          <SHTypography variant="body2" color={palette.error.main} zIndex="1">
            <NumericFormat
              displayType="text"
              value={Math.abs(value)}
              thousandSeparator=","
              prefix={parseFloat(value) < 0 ? "-$" : "$"}
            />
          </SHTypography>
        );
      }
      return (
        <SHTypography variant="body2" zIndex="1">
          <NumericFormat
            displayType="text"
            value={value}
            thousandSeparator=","
            prefix="$"
          />
        </SHTypography>
      );
    }

    if (type === ManagedAccountFieldType.Warning) {
      return (
        <SHTypography variant="body2" color={palette.error.main} zIndex="1">
          <NumericFormat
            displayType="text"
            value={Math.abs(parseFloat(value))}
            thousandSeparator=","
            prefix={parseFloat(value) < 0 ? "-$" : "$"}
          />
        </SHTypography>
      );
    }

    if (type === ManagedAccountFieldType.Text) {
      return (
        <SHTypography variant="body2" zIndex="1">
          {value}
        </SHTypography>
      );
    }

    return <></>;
  };

  return (
    <>
      <IconButton
        {...others}
        ref={buttonRef}
        onClick={handleClick}
        disableRipple
        sx={{
          width: "16px",
          height: "16px",
          margin: margin ?? "0px 0px 0px 0px !important",
          p: 0,
          borderStyle: "solid",
          borderWidth: "1px",
          backgroundColor: "transparent",
          borderColor: color ? color : palette.text.primary,
          "&:hover": {
            borderColor: hoverColor ? hoverColor : palette.primary.main,
            boxShadow: "none",
            "&>svg>path": {
              fill: hoverColor ? hoverColor: palette.primary.main,
            },
          },
          "&:active": {
            backgroundColor: hoverColor ? hoverColor : palette.primary.main,
            borderColor: hoverColor ? hoverColor : palette.primary.dark,
            "&>svg>path": {
              fill: activeColor ? activeColor : palette.common.white,
            },
          },
          "&:disabled": {
            border: `1px solid ${palette.text.disabled}`,

            "&>svg>path": {
              fill: palette.text.disabled,
            },
          },
        }}
      >
        <InfoSVG color={color} />
      </IconButton>
      <SHPopper
        anchorEl={anchorEl}
        open={open}
        placement={placement}
        popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
        sx={{ zIndex: 1400 }}
      >
        <SHBox pb={1}>
          <SHStack
            ref={wrapperRef}
            sx={{
              p: 2,
              bgcolor: hexToRGBA(palette.common.white, 0.85),
              border: "1px solid #E3E3E3",
              backdropFilter: "blur(2px)",
              borderRadius: "3px",
              boxShadow: shadows[1],
              position: "relative",
            }}
          >
            <SHStack marginBottom={"5px"}>
              {title && typeof title === "string" ? (
                <SHTypography variant="subtitle1" zIndex="1">
                  {title}
                </SHTypography>
              ) : (
                title
              )}
            </SHStack>
            <SHStack
              flexDirection={"column"}
              justifyContent={"space-between"}
              minWidth={"200px"}
            >
              {data?.map((item) => {
                return (
                  <SHStack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <SHTypography
                      variant="body2"
                      zIndex="1"
                      marginRight={"20px"}
                    >
                      {item.name}
                    </SHTypography>
                    {renderValueByType(item.value, item.fieldType)}
                  </SHStack>
                );
              })}
            </SHStack>
          </SHStack>
          <SHBox sx={tooltipConfig?.sx}>
            <TooltipAllowSVG transform={tooltipConfig?.transform} />
          </SHBox>
        </SHBox>
      </SHPopper>
    </>
  );
};
