import mainBgImage from "@assets/images/background/main.svg";
import { SHStack } from "@components/design-systems";
import { NavBar } from "@components/nav-bar";
import { Outlet } from "react-router-dom";

export const MainLayout = (): JSX.Element => {
  return (
    <SHStack
      sx={{
        backgroundAttachment: "fixed",
        width: "100vw",
        height: "100vh",
        overflow: "auto",
        backgroundImage: `url(${mainBgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      direction="column"
    >
      <NavBar />
      <Outlet />
    </SHStack>
  );
};
