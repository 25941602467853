import { StarSVG } from "@components/svgs";
import { IconButton, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { useEffect, useState } from "react";

interface SMAFavouritedButtonProps {
  isFavourite?: boolean;
  onHandleFavoriteStatus?: (favoriteStatus: boolean) => void;
}

export const SMAFavouritedButton = ({
  isFavourite = false,
  onHandleFavoriteStatus,
}: SMAFavouritedButtonProps) => {
  const { shadows, palette } = useTheme();
  const [favoriteStatus, setFavoriteStatus] = useState(isFavourite);

  const onFavoriteButtonClick = () => {
    setFavoriteStatus(!favoriteStatus);

    if (onHandleFavoriteStatus) {
      onHandleFavoriteStatus(!favoriteStatus);
    }
  };

  useEffect(() => {
    setFavoriteStatus(isFavourite);
  }, [isFavourite]);

  return (
    <IconButton
      onClick={onFavoriteButtonClick}
      sx={{
        p: 0,
        width: 24,
        height: 24,
        borderStyle: "solid",
        borderWidth: "1px",
        backgroundColor: favoriteStatus
          ? hexToRGBA(palette.error.main, 0.1)
          : palette.common.white,
        borderColor: palette.error[100],
        boxShadow: shadows[1],
        "&>svg>path": {
          fill: favoriteStatus ? "#FFF500" : palette.common.white,
          stroke: favoriteStatus
            ? palette.warning[800]
            : hexToRGBA(palette.error.main, 0.5),
        },
      }}
    >
      <StarSVG width={20} height={20} />
    </IconButton>
  );
};
