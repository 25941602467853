import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { CreatePortfolioTemplate, UpdatePortfolioTemplate } from "@models/portfolio-template/entities/portfolioTemplate";
import { APIGatewayService, handleErrorProxy } from "@services/shService";

export async function postPortfolioTemplate(
  featureTemplate: CreatePortfolioTemplate,
): Promise<APIResponse<CreatePortfolioTemplate>> {
  const response = await APIGatewayService.post<CreatePortfolioTemplate>(
    `${APIExtRoutes.portfolioTemplates}`,
    featureTemplate,
  );
  return handleErrorProxy(response, "Portfolio template has been created.");
}

export async function putPortfolioTemplate(
    featureTemplate: UpdatePortfolioTemplate,
  ): Promise<APIResponse<UpdatePortfolioTemplate>> {
    const response = await APIGatewayService.put<UpdatePortfolioTemplate>(
      `${APIExtRoutes.portfolioTemplates}`,
      featureTemplate,
    );
    return handleErrorProxy(response, "Portfolio template has been updated.");
  }
