import { ReviewStep } from "@models/reviews/enums/step";
import { AnalysisStep } from "../enums/step";
const { Setup, Feature, BusinessMetric, Fee, Summary } = AnalysisStep;

export class PlatformAnalysisCommentParam {
  stepId: AnalysisStep = Feature;
  platformAnalysisId: string = "";
  productId: string = "";
  comment: string = "";
}
export class PlatformAnalysisCommentDTO {
  id: string = "";
  comment: string = "";
}

export type PlatformCommentDialog = {
  productId: string;
  comment?: string | null;
};

export class AnalysisCommentDTO {
  productId: string = "";
  productAvatar: string | null = null;
  productName: string | null = null;
  feature: string | null = null;
  businessMetric: string | null = null;
  fee: string | null = null;
  summary?: string | null = null;
  inFinalShortlist?: boolean = false;
  removedFromShortlistAtStep?: ReviewStep = ReviewStep.Feature;
  featuresMatch?: number = 0;
}

export const analysisCommentsKeyMapping = {
  [Setup]: "setup",
  [Feature]: "feature",
  [BusinessMetric]: "businessMetric",
  [Fee]: "fee",
  [Summary]: "summary",
};
