import {
  SHBox,
  SHPagination,
  SHPaginationProps,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { ArrowDownSVG } from "@components/svgs";
import {
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { DefaultRowPerPageOptions } from "./constant";
export interface SHDataGridPaginationProps {
  count: number;
  page: number;
  rowsPerPage: number | "all";
  rowsPerPageOptions?: { label: string; value: number | "all" }[];
  sx?: SxProps<Theme>;
  selectProps?: SelectProps;
  paginationProps?: SHPaginationProps;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  onRowsPerPageChange?: SelectInputProps<any>["onChange"];
}
export const SHDataGridPagination = ({
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions = DefaultRowPerPageOptions,
  sx,
  selectProps,
  paginationProps,
  onPageChange,
  onRowsPerPageChange,
}: SHDataGridPaginationProps) => {
  const { palette } = useTheme();
  return (
    <SHStack
      spacing={2}
      direction="row"
      alignItems={"center"}
      justifyContent="space-between"
      sx={sx}
      data-testid="sh-data-grid-pagination"
    >
      <SHStack spacing={1} direction="row" alignItems={"center"}>
        <SHTypography variant="body1">Show</SHTypography>
        <SHBox sx={{ minWidth: 120 }}>
          <Select
            value={rowsPerPage}
            data-testid="sh-data-grid-pagination-select"
            size="small"
            variant="outlined"
            sx={{
              height: "34px",
              width: "69px",
              borderRadius: "25px",
              "&>.MuiSelect-select": {
                fontSize: "16px !important",
                fontWeight: "600 !important",
                color: palette.text.primary,
                paddingTop: "10px",
                paddingRight: "28px !important",
              },
              "&>.MuiSelect-icon": {
                right: "10px",
              },
              "&> .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: 2,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: palette.primary.main,
              },
            }}
            IconComponent={(props) => <ArrowDownSVG {...props} />}
            onChange={onRowsPerPageChange}
            {...selectProps}
          >
            {rowsPerPageOptions.map((option, index) => (
              <MenuItem value={option.value} key={index}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </SHBox>
      </SHStack>
      <SHPagination
        page={page}
        count={count}
        color="primary"
        onChange={onPageChange}
        {...paginationProps}
      />
      <SHBox sx={{ minWidth: 120 }} />
    </SHStack>
  );
};
