import { HighlightType } from "@models/configuration";
import { AnalysisVariable } from "@models/platform-analysis/enums/variableName";
import { ProductListDTO } from "@models/product/entities/product";
import {
  DependFieldsMapping,
  FeatureHighlightTypeMapping,
  SectionHighlightTypeMapping,
  SectionTypeHighlightTypeMapping,
  SubProductDTO,
  SubProductHighlightTypeMapping,
  SubProductNameMapping,
  ValueTypeMapping,
} from "@models/product/entities/subProduct";
import { FeeValueType } from "@models/product/enums/feeValueType";
import {
  showAllowFieldNameMapping,
  valueNameMapping,
} from "@pages/suppliers/_id/_products/_id/edit/components/fees-and-rates/util";
import { isEmpty } from "lodash";

export const getProductNameByProduct = (
  product?: ProductListDTO,
): string | undefined => {
  const overviewGroup = product?.groups?.find(
    (group) => group.name === "Overview",
  );
  if (!overviewGroup) return;
  const bannerSection = overviewGroup.sections?.find(
    (section) => section.name === "Banner",
  );
  if (!bannerSection) return;
  const platformNameFeature = bannerSection.features?.find(
    (feature) => feature.name === "Platform name",
  );
  if (!platformNameFeature) return;
  return platformNameFeature?.productData?.value;
};

export const generateSubProductNameMapping = (
  subProducts?: SubProductDTO[],
): SubProductNameMapping => {
  let subProName: SubProductNameMapping = {};
  subProducts?.forEach(
    (subPro, subProIdx) => (subProName[subProIdx] = subPro?.name),
  );
  return subProName;
};

export const generateFieldMapping = (
  subProduct?: SubProductDTO,
): DependFieldsMapping => {
  let indexMapping: DependFieldsMapping = {};

  subProduct?.standardFeeSections?.forEach((standardFee, sectionIndex) => {
    standardFee?.features?.forEach((feature, featureIndex) => {
      const initDependFiled = `standardFeeSections.${sectionIndex}.features.${featureIndex}`;
      indexMapping[feature.variableNameIDPS as AnalysisVariable] = {
        modifiedField: `${initDependFiled}.isModified`,
        showHideField: `${initDependFiled}.${
          showAllowFieldNameMapping[FeeValueType.IDPS]
        }`,
        valueField: `${initDependFiled}.subProductData.${
          valueNameMapping[FeeValueType.IDPS]
        }`,
      };
      indexMapping[feature.variableNamePension as AnalysisVariable] = {
        modifiedField: `${initDependFiled}.isModified`,
        showHideField: `${initDependFiled}.${
          showAllowFieldNameMapping[FeeValueType.Pension]
        }`,
        valueField: `${initDependFiled}.subProductData.${
          valueNameMapping[FeeValueType.Pension]
        }`,
      };
    });
  });
  subProduct?.otherFeeSections?.forEach((otherFee, sectionIndex) => {
    otherFee?.features?.forEach((feature, featureIndex) => {
      const initDependFiled = `otherFeeSections.${sectionIndex}.features.${featureIndex}`;
      indexMapping[feature.variableNameOther as AnalysisVariable] = {
        modifiedField: `${initDependFiled}.isModified`,
        showHideField: `${initDependFiled}.${
          showAllowFieldNameMapping[FeeValueType.Other]
        }`,
        valueField: `${initDependFiled}.subProductData.${
          valueNameMapping[FeeValueType.Other]
        }`,
      };
    });
  });

  return indexMapping;
};

export const generateSubProductHighlightTypeMapping = (
  subProducts?: SubProductDTO[],
): SubProductHighlightTypeMapping => {
  let subProHighlightType: SubProductHighlightTypeMapping = {};

  subProducts?.forEach((subPro, subProIdx) => {
    if (subPro.highlightType !== HighlightType.Edited) return;
    let sectionTypeMapping: SectionTypeHighlightTypeMapping = {};

    // StandardSectionFees
    let standardSectionMapping: SectionHighlightTypeMapping = {};
    subPro?.standardFeeSections?.forEach((standardFee, standardFeeIdx) => {
      if (standardFee?.highlightType !== HighlightType.Edited) return;
      let featureMapping: FeatureHighlightTypeMapping = {};
      standardFee?.features?.forEach((feature, featureIdx) => {
        if (
          feature.highlightType !== HighlightType.Edited ||
          (feature.subProductData?.idpsValue ===
            feature.subProductData?.publishedIDPSValue &&
            feature.subProductData?.pensionValue ===
              feature.subProductData?.publishedPensionValue)
        )
          return;

        let reachField: ValueTypeMapping = {};
        if (
          feature.subProductData?.idpsValue !==
          feature.subProductData?.publishedIDPSValue
        )
          reachField[FeeValueType.IDPS] = true;

        if (
          feature.subProductData?.pensionValue !==
          feature.subProductData?.publishedPensionValue
        )
          reachField[FeeValueType.Pension] = true;

        featureMapping[featureIdx] = reachField;
      });
      if (isEmpty(featureMapping)) return;
      standardSectionMapping[standardFeeIdx] = featureMapping;
    });
    if (!isEmpty(standardSectionMapping))
      sectionTypeMapping["standardFeeSections"] = standardSectionMapping;

    // OtherSectionFees
    let otherSectionMapping: SectionHighlightTypeMapping = {};
    subPro?.otherFeeSections?.forEach((otherFee, otherFeeIdx) => {
      if (otherFee?.highlightType !== HighlightType.Edited) return;
      let featureMapping: FeatureHighlightTypeMapping = {};

      otherFee?.features?.forEach((feature, featureIdx) => {
        if (
          feature.highlightType !== HighlightType.Edited ||
          feature.subProductData?.otherValue ===
            feature.subProductData?.publishedOtherValue
        )
          return;

        let reachField: ValueTypeMapping = {};
        if (
          feature.subProductData?.otherValue !==
          feature.subProductData?.publishedOtherValue
        )
          reachField[FeeValueType.Other] = true;

        featureMapping[featureIdx] = reachField;
      });
      if (isEmpty(featureMapping)) return;
      otherSectionMapping[otherFeeIdx] = featureMapping;
    });
    if (!isEmpty(otherSectionMapping))
      sectionTypeMapping["otherFeeSections"] = otherSectionMapping;

    if (
      isEmpty(sectionTypeMapping["standardFeeSections"]) &&
      isEmpty(sectionTypeMapping["otherFeeSections"])
    )
      return;
    subProHighlightType[subProIdx] = sectionTypeMapping;
  });

  return subProHighlightType;
};
