export enum UserStatus {
  Active = "Active",
  Disabled = "Disabled",
  Archived = "Archived",
  Pending = "Pending",
}
export enum UserStatusAction {
  Disable = "Disable",
  Enable = "Enable",
  Archive = "Archive",
  Unarchive = "Unarchive",
}
