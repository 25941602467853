import {
  CreateRateCardDTO,
  RateCardDTO,
  RateCardStatus,
  UpdateRateCardDTO,
} from "@models/rate-card/entities/rateCard";
import {
  DependFieldsMapping,
  SubProductHighlightTypeMapping,
} from "@models/product/entities/subProduct";
import { generateRateCardFieldMapping } from "@redux/slices/rate-cards/util";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getRateCard,
  getRateCardLOV,
  postRateCard,
  putRateCard,
  putRateCardStatus,
} from "@services/rate-card/rateCardService";

interface RateCardStore {
  rateCard?: RateCardDTO;
  rateCardUI: {
    isLoading?: boolean;
    isSubmitting?: boolean;
  };
  rateCardDependFieldsMapping?: DependFieldsMapping;
}

const initialState: RateCardStore = {
  rateCard: undefined,
  rateCardUI: {
    isLoading: false,
    isSubmitting: false,
  },
  rateCardDependFieldsMapping: undefined,
};

const rateCardSlice = createSlice({
  name: "rateCard",
  initialState,
  reducers: {
    updateRateCardDependFieldsMapping: (
      state,
      action: PayloadAction<SubProductHighlightTypeMapping | undefined>,
    ) => {
      state.rateCardDependFieldsMapping = action.payload;
    },
  },
  extraReducers(builder) {
    // Load Rate Card detail
    builder.addCase(loadRateCardThunk.pending, (state, action) => {
      state.rateCardUI.isLoading = true;
    });
    builder.addCase(loadRateCardThunk.fulfilled, (state, action) => {
      state.rateCard = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.rateCardUI.isLoading = false;
      if (state.rateCard) {
        state.rateCardDependFieldsMapping = generateRateCardFieldMapping(
          state.rateCard,
        );
      }
    });
    builder.addCase(loadRateCardThunk.rejected, (state, action) => {
      state.rateCardUI.isLoading = false;
    });

    // Load Rate Card LOV
    builder.addCase(loadRateCardLOVThunk.pending, (state, action) => {
      state.rateCardUI.isLoading = true;
    });
    builder.addCase(loadRateCardLOVThunk.fulfilled, (state, action) => {
      state.rateCardUI.isLoading = false;
      state.rateCard = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      if (state.rateCard) {
        state.rateCardDependFieldsMapping = generateRateCardFieldMapping(
          state.rateCard,
        );
      }
    });
    builder.addCase(loadRateCardLOVThunk.rejected, (state, action) => {
      state.rateCardUI.isLoading = false;
    });

    // Create new Rate Card
    builder.addCase(createRateCardThunk.pending, (state, action) => {
      state.rateCardUI.isSubmitting = true;
    });
    builder.addCase(createRateCardThunk.fulfilled, (state, action) => {
      state.rateCardUI.isSubmitting = false;
      state.rateCard = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
    });
    builder.addCase(createRateCardThunk.rejected, (state, action) => {
      state.rateCardUI.isSubmitting = false;
    });

    // Update Rate Card
    builder.addCase(updateRateCardThunk.pending, (state, action) => {
      state.rateCardUI.isSubmitting = true;
    });
    builder.addCase(updateRateCardThunk.fulfilled, (state, action) => {
      state.rateCardUI.isSubmitting = false;
      state.rateCard = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
    });
    builder.addCase(updateRateCardThunk.rejected, (state, action) => {
      state.rateCardUI.isSubmitting = false;
    });

    // Update Rate Card status
    builder.addCase(updateRateCardStatusThunk.pending, (state, action) => {
      state.rateCardUI.isSubmitting = true;
    });
    builder.addCase(updateRateCardStatusThunk.fulfilled, (state, action) => {
      state.rateCardUI.isSubmitting = false;
      if (
        state.rateCard?.status &&
        action.payload.isSuccess &&
        action.payload.data
      ) {
        state.rateCard.status = action.payload.data;
      }
    });
    builder.addCase(updateRateCardStatusThunk.rejected, (state, action) => {
      state.rateCardUI.isSubmitting = false;
    });
  },
});

// Thunk functions
export const loadRateCardThunk = createAsyncThunk(
  `rate-card/load`,
  async (rateCardId: string) => {
    return await getRateCard(rateCardId);
  },
);

export const loadRateCardLOVThunk = createAsyncThunk(
  `rate-card/loadLOV`,
  async () => {
    return await getRateCardLOV();
  },
);

export const createRateCardThunk = createAsyncThunk(
  `rate-card/create`,
  async (rateCard: CreateRateCardDTO) => {
    return await postRateCard(rateCard);
  },
);

export const updateRateCardThunk = createAsyncThunk(
  `rate-card/update`,
  async (rateCard: UpdateRateCardDTO) => {
    return await putRateCard(rateCard);
  },
);

export const updateRateCardStatusThunk = createAsyncThunk(
  `rate-card/updateStatus`,
  async ({
    rateCardId,
    status,
  }: {
    rateCardId: string;
    status: RateCardStatus;
  }) => {
    return await putRateCardStatus(rateCardId, status);
  },
);

export default rateCardSlice.reducer;
