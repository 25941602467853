import { SVGProps } from "react";

export const DuplicateSVG = ({
  color = "#25324B",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg" 
    width="15" 
    height="16" 
    viewBox="0 0 15 16"
    fill="none"
    {...props}
  >
    <path
      d="M1.875 13.8584H8.4375C8.69531 13.8584 8.90625 13.6475 8.90625 13.3896V12.2178C8.90625 11.8294 9.22105 11.5146 9.60938 11.5146C9.9977 11.5146 10.3125 11.8294 10.3125 12.2178V13.3896C10.3125 14.4238 9.47168 15.2646 8.4375 15.2646H1.875C0.839356 15.2646 0 14.4238 0 13.3896V6.82715C0 5.79297 0.839356 4.95215 1.875 4.95215H3.04688C3.4352 4.95215 3.75 5.26695 3.75 5.65527C3.75 6.0436 3.4352 6.3584 3.04688 6.3584H1.875C1.61602 6.3584 1.40625 6.56934 1.40625 6.82715V13.3896C1.40625 13.6475 1.61602 13.8584 1.875 13.8584ZM4.6875 2.13965C4.6875 1.104 5.52832 0.264648 6.5625 0.264648H13.125C14.1592 0.264648 15 1.104 15 2.13965V8.70215C15 9.73633 14.1592 10.5771 13.125 10.5771H6.5625C5.52832 10.5771 4.6875 9.73633 4.6875 8.70215V2.13965ZM6.5625 9.1709H13.125C13.3828 9.1709 13.5938 8.95996 13.5938 8.70215V2.13965C13.5938 1.88066 13.3828 1.6709 13.125 1.6709H6.5625C6.30469 1.6709 6.09375 1.88066 6.09375 2.13965V8.70215C6.09375 8.95996 6.30469 9.1709 6.5625 9.1709Z"
      fill={color}
    />
  </svg>
);
