import { SHButton, SHDialog, SHStack } from "@components/design-systems";
import { DiscardSVG } from "@components/svgs";
import { useConfiguration } from "@hooks/useConfiguration";
import { useNotification } from "@hooks/useNotification";
import { useTheme } from "@mui/system";
import { RootState } from "@redux/store";
import { updateEditMode } from "@redux/slices/configuration";
import { useAppDispatch } from "@redux/store";
import { discardDraft } from "@services/configuration";
import { useState } from "react";
import { useSelector } from "react-redux";

export interface DiscardConfigurationButtonProps {}

export const DiscardConfigurationButton = (
  props: DiscardConfigurationButtonProps,
) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const { notify } = useNotification();
  const {
    configurationUI: { isEditMode },
    configurationDraftStatus,
  } = useSelector((state: RootState) => state.configuration);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { updateDraftStatus, refreshConfigurationGroup } = useConfiguration();

  const handleOnOpenDialog = (): void => {
    setOpenDialog(true);
  };
  const handleOnCloseDialog = (): void => {
    setOpenDialog(false);
  };

  const handleOnDiscard = async () => {
    setIsSubmitting(true);
    const { isSuccess, message } = await discardDraft();
    setIsSubmitting(false);
    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      handleOnCloseDialog();
      updateDraftStatus();
      refreshConfigurationGroup();
      dispatch(updateEditMode(false));
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <>
      {configurationDraftStatus && isEditMode && (
        <SHButton
          variant="outlined"
          size="extraMedium"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={handleOnOpenDialog}
          startIcon={<DiscardSVG />}
          sx={{
            "&:active svg>path": {
              fill: palette.common.white,
            },
          }}
        >
          Discard draft
        </SHButton>
      )}
      <SHDialog
        open={openDialog}
        onClose={handleOnCloseDialog}
        width={460}
        header="Discard your draft?"
        body="You are about to discard all changes made to the Configuration since the last published version. Are you sure?"
        footer={
          <SHStack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={7}
          >
            <SHButton
              color="secondary"
              onClick={handleOnDiscard}
              variant="text"
              size="extraMedium"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Discard
            </SHButton>
            <SHButton
              color="primary"
              variant="contained"
              onClick={handleOnCloseDialog}
              size="extraMedium"
            >
              Keep draft
            </SHButton>
          </SHStack>
        }
      />
    </>
  );
};
