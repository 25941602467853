import { SHButton } from "@components/design-systems";
import { PageRoutes } from "@constants";
import { useNotification } from "@hooks/useNotification";
import { UnlockDialog } from "@pages/platform-analysis/components/dialogs/unlock";
import { duplicateReviewService } from "@services/reviews/reviewService";
import { useState } from "react";
import { generatePath } from "react-router";

export interface UnlockReviewButtonProps {
  reviewId?: string;
  disabled?: boolean;
}

export const UnlockReviewButton = ({
  reviewId,
  disabled,
}: UnlockReviewButtonProps) => {
  const { notify } = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const handleOnUnlock = async () => {
    if (!reviewId) return;
    setIsSubmitting(true);
    const { isSuccess, data, message } = await duplicateReviewService(reviewId);
    setIsSubmitting(false);

    if (isSuccess && data) {
      notify(message, {
        variant: "success",
        close: true,
      });
      setShowDialog(false);
      window.location.assign(
        generatePath(PageRoutes.reviewsDetail, {
          reviewId: data,
        }),
      );
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <>
      <SHButton
        variant="outlined"
        size="extraMedium"
        isLoading={isSubmitting}
        disabled={isSubmitting || disabled}
        onClick={() => setShowDialog(true)}
      >
        Unlock
      </SHButton>
      {showDialog && (
        <UnlockDialog
          isSubmitting={isSubmitting}
          onSubmit={handleOnUnlock}
          onClose={() => setShowDialog(false)}
          isReview
        />
      )}
    </>
  );
};
