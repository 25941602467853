import { SMAFilterValue } from "@models/managed-accounts/entities/filter";
import {
  SMAListCurrentlySelectedFilter,
  SMAListFavouriteFilter,
  SMAListMinimumInvestmentSizeFilter,
} from "@models/managed-accounts/enums/filter";

export const favouriteFilterOptions: SMAFilterValue[] = [
  {
    label: "All",
    value: SMAListFavouriteFilter.All,
  },
  {
    label: "Favourites",
    value: SMAListFavouriteFilter.Favourites,
  },
];

export const currentlySelectedFilterOptions: SMAFilterValue[] = [
  {
    label: "All",
    value: SMAListCurrentlySelectedFilter.All,
  },
  {
    label: "Selected",
    value: SMAListCurrentlySelectedFilter.Selected,
  },
];

export const minimumInvestmentSizeFilterOptions: SMAFilterValue[] = [
  {
    label: "All",
    value: SMAListMinimumInvestmentSizeFilter.All,
  },
  {
    label: "$100,000 or less",
    value: SMAListMinimumInvestmentSizeFilter.OneHundredThousandOrLess,
  },
  {
    label: "$50,000 or less",
    value: SMAListMinimumInvestmentSizeFilter.FiftyThousandOrLess,
  },
  {
    label: "$25,000 or less",
    value: SMAListMinimumInvestmentSizeFilter.TwentyFiveThousandOrLess,
  },
  {
    label: "$0 (No minimum)",
    value: SMAListMinimumInvestmentSizeFilter.Zero,
  },
];
