import * as Yup from "yup";

export const userValidators = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .max(100, "First name must be less than 100 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .max(100, "Last name must be less than 100 characters"),
  email: Yup.string().required("Email is required").email("Email is not valid"),
  userType: Yup.string().required("Accout type is required"),
});

export const generateFormTitle = (firstName?: string, lastName?: string) => {
  if (!firstName && !lastName) {
    return "New administrator";
  }
  const nameArray = [firstName, lastName].filter(Boolean);
  return nameArray.join(" ");
};

export const generateSubTitle = (isMyProfile?: boolean) => {
  if (isMyProfile)
    return {
      Profile: "Manage your SuitabilityHub profile",
      ProfilePhoto: "Help fellow team members recognise your account",
      PersonalDetails: "Set the details of your account",
      AccountType:
        "Permissions can only be changed in the user management module",
      ChangePassword: "Change my password",
    };
  return {
    Profile: "Manage this user’s SuitabilityHub profile",
    ProfilePhoto: "Help fellow team members recognise this account",
    PersonalDetails: "Set the user details of this account",
    AccountType: "Set the permissions and capability of this account",
    ChangePassword: "Send user password reset email",
  };
};
