import { CloseButton } from "@components/buttons/close";
import { PlusButton } from "@components/buttons/plus";
import {
  SHButton,
  SHDialog,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReviewStepSetupDTO } from "@models/reviews/entities/steps/setup";
import { Collapse, DialogContent, DialogTitle, useTheme } from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { useReviewClient } from "@hooks/useReviewClient";
import { RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { TransitionGroup } from "react-transition-group";
import { useEffectOnce } from "react-use";
import { familyGroupMemberSetupValidators } from "./util";
import { CreateFamilyGroupDTO, FamilyGroupEntityDTO } from "@models/family-group/entities/familyGroup";

interface CreateFamilyGroupDialogProps {
  data: ReviewStepSetupDTO;
  onClose: () => void;
}

export const CreateFamilyGroupDialog = ({
  data,
  onClose,
}: CreateFamilyGroupDialogProps) => {
  const { palette } = useTheme();
  const {
    getValues,
    control,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...new CreateFamilyGroupDTO() },
    resolver: yupResolver(familyGroupMemberSetupValidators),
  });

  const { user } = useSelector((state: RootState) => state.auth);

  const {
    ui: { isSubmitting },
  } = useSelector((state: RootState) => state.reviewClient);

  const { createFamilyGroup } = useReviewClient();

  const {
    fields: familyMembersFields,
    append: appendFamilyMember,
    remove: removeFamilyMember,
  } = useFieldArray({
    control,
    name: "familyGroupEntities",
    keyName: "fieldId",
  });

  const handleOnAddFamilyEntity = () => {
    appendFamilyMember(new FamilyGroupEntityDTO());
  };
  const handleOnRemoveFamilyEntity = (index: number) => {
    removeFamilyMember(index);
  };

  const convertOrder = () => {
    getValues("familyGroupEntities")?.map((entity, index) => {
      return (entity.order = index + 1);
    });
  };

  const handleVerifySubmit = async () => {
    convertOrder();
    setValue("adviserFirmId", user?.userMetadata?.adviser_firm_id ?? "");
    setValue("adviserUserId", data.ownerId);
    var response = await createFamilyGroup(getValues());
    if (response?.isSuccess) {
      onClose();
    }
  };

  useEffectOnce(() => {
    appendFamilyMember(new FamilyGroupEntityDTO());
  });

  return (
    <form>
      <SHDialog
        open
        maxWidth={false}
        sx={{
          "& .MuiPaper-root": {
            width: { xs: "100%", md: 656 },
          },
        }}
      >
        <DialogTitle>
          <SHTypography variant="h2" lineHeight={"100%"} mb={"15px"}>
            Create new family group
          </SHTypography>
          <SHTypography
            variant="body2"
            disabled={true}
            sx={{
              fontSize: "16px",
              margin: "0 20px",
            }}
          >
            Fill in the fields below for the new family group.
          </SHTypography>
          <SHTypography
            variant="body2"
            disabled={true}
            sx={{
              fontSize: "16px",
              margin: "0 20px",
            }}
          >
            Note: Maximum of 6 family members/entities supported.
          </SHTypography>
        </DialogTitle>
        <SHStack
          sx={{
            position: "absolute",
            top: "22px",
            right: "25px",
          }}
        >
          <CloseButton width="12px" height="12px" onClick={onClose} />
        </SHStack>
        <DialogContent
          sx={{
            padding: 0,
            overflowY: "var(--overflow-overlay)",
          }}
        >
          <SHStack spacing={"25px"}>
            <SHStack
              spacing={1}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <SHTextField
                    {...field}
                    required
                    hasCounter
                    maxLength={50}
                    sx={{ width: "100%", marginBottom: "15px" }}
                    label={"Family group name"}
                    placeholder={"Enter a name for the family group"}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    InputProps={{
                      sx: {
                        "&>input": {
                          pr: "32px !important",
                        },
                      },
                      endAdornment: (
                        <SHStack
                          sx={{
                            position: "absolute",
                            right: "8px",
                          }}
                        >
                          {}
                        </SHStack>
                      ),
                    }}
                  />
                )}
              />
              <TransitionGroup
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  gap: "7px",
                  width: "100%",
                }}
              >
                {familyMembersFields.map((entity, index) => (
                  <Collapse key={entity.id}>
                    <Controller
                      name={`familyGroupEntities.${index}.name`}
                      control={control}
                      render={({ field }) => (
                        <SHTextField
                          {...field}
                          required
                          type="text"
                          hasCounter
                          maxLength={50}
                          placeholder={`Enter the full name of family member/entity ${
                            index + 1
                          }`}
                          label={
                            <SHStack
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="stretch"
                              width={"100%"}
                            >
                              <SHStack>
                                <SHTypography
                                  variant="subtitle2"
                                  //   disabled={isDisabled}
                                >
                                  Family member/entity {index + 1}
                                </SHTypography>
                              </SHStack>
                            </SHStack>
                          }
                          actionButton={
                            index ? (
                              <SHTypography
                                variant="body2"
                                color={palette.error.main}
                                sx={{
                                  fontWeight: 600,
                                  cursor: "pointer",
                                  pointerEvents: "auto",
                                }}
                                onClick={() =>
                                  handleOnRemoveFamilyEntity(index)
                                }
                              >
                                Remove
                              </SHTypography>
                            ) : (
                              <></>
                            )
                          }
                          sx={{ width: { xs: "100%", md: 520 } }}
                          //   disabled={isDisabled}
                          error={
                            errors.familyGroupEntities &&
                            !!errors.familyGroupEntities[index]
                          }
                          helperText={
                            errors.familyGroupEntities
                              ? errors.familyGroupEntities[index]?.name?.message
                              : ""
                          }
                        />
                      )}
                    />
                  </Collapse>
                ))}
              </TransitionGroup>
            </SHStack>
            <SHStack
              justifyContent="left"
              alignItems="center"
              direction={"row"}
              spacing={7}
              marginTop={"25px"}
              marginBottom={"15px"}
            >
              <PlusButton
                color="primary"
                variant="outlined"
                onClick={handleOnAddFamilyEntity}
                disabled={familyMembersFields?.length > 5}
              >
                Family member/entity
              </PlusButton>
            </SHStack>
            <SHStack
              justifyContent="center"
              alignItems="center"
              direction={"row"}
              spacing={7}
              marginTop={"25px"}
              marginBottom={"15px"}
            >
              <SHButton
                color="primary"
                size="large"
                variant="contained"
                onClick={handleVerifySubmit}
                disabled={isSubmitting || !isValid || !isDirty}
                isLoading={isSubmitting}
                sx={{ width: "410px" }}
              >
                Create family group
              </SHButton>
            </SHStack>
          </SHStack>
        </DialogContent>
      </SHDialog>
    </form>
  );
};
