import { analysisCommentsKeyMapping } from "@models/platform-analysis/entities/comment";
import { ReviewStep, ReviewSteps } from "@models/reviews/enums/step";
import { parseCommentProductLogo } from "@pages/platform-analysis/_id/steps/summary/components/tables/comments/util";
import { PDFCommentRichTextEditor } from "@pages/platform-analysis/components/buttons/export-pdf/components/rich-text-editor";
import { PDFTitleSection } from "@pages/platform-analysis/components/buttons/export-pdf/components/title-section";
import { PDFDefaultPlatformLogoS3 } from "@pages/platform-analysis/components/buttons/export-pdf/config";
import {
  DEFAULT_GAP_3,
  COMMENT_TABLE_HEADER_HEIGHT,
  TABLE_WIDTH,
  APPENDIX_COMMENT_TABLE_HEADER_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { generatePDFImageIconUrl } from "@pages/platform-analysis/components/buttons/export-pdf/util";
import { reviewStepLabelMapping } from "@pages/reviews/_id/steps/summary/components/tables/comments/config";
import { PDFReviewTable } from "@pages/reviews/components/buttons/export-pdf/layouts/tables";
import { PDFReviewTableBody } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-body";
import { PDFReviewTableCell } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-cell";
import { PDFReviewTableRow } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-row";
import { PDFReviewComment } from "@pages/reviews/components/buttons/export-pdf/model";
import { useReviewStyles } from "@pages/reviews/components/buttons/export-pdf/styles";
import { Image, Text, View } from "@react-pdf/renderer";

interface PDFReviewCommentProps {
  comments?: PDFReviewComment[];
  isExpandTable?: boolean;
  isAppendix?: boolean;
}
export const PDFReviewCommentSection = ({
  comments,
  isExpandTable = false,
  isAppendix = false,
  ...others
}: PDFReviewCommentProps) => {
  const { typographyStyle } = useReviewStyles();

  const steps = ReviewSteps?.filter((item) => item !== ReviewStep.Setup);

  const sizes = {
    firstCellWidth: 0.116 * TABLE_WIDTH,
    secondCellWidth: 0.097 * TABLE_WIDTH,
    cellWidth: (!isAppendix ? 0.221 : 0.19675) * TABLE_WIDTH,
  };

  return (
    <View>
      <PDFTitleSection
        title={`${
          !isAppendix
            ? "Platform specific comments"
            : "Platforms that were considered but not selected"
        }${isExpandTable ? " (continued)" : ""}`}
      />

      <View style={{ gap: DEFAULT_GAP_3 }}>
        <PDFReviewTable tableWidth={TABLE_WIDTH}>
          <PDFReviewTableRow
            borderBottomWidth={"1px"}
            height={`${
              isAppendix
                ? APPENDIX_COMMENT_TABLE_HEADER_HEIGHT
                : COMMENT_TABLE_HEADER_HEIGHT
            }px`}
          >
            <PDFReviewTableCell width={sizes?.firstCellWidth}>
              <Text
                style={{
                  ...typographyStyle?.body2,
                  fontWeight: "bold",
                }}
              >
                Platform
              </Text>
            </PDFReviewTableCell>
            {isAppendix && (
              <PDFReviewTableCell width={sizes.secondCellWidth}>
                <Text
                  style={{
                    ...typographyStyle?.body2,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Features match
                </Text>
              </PDFReviewTableCell>
            )}

            {steps?.map((step) => (
              <PDFReviewTableCell key={step} width={sizes?.cellWidth}>
                <Text style={{ ...typographyStyle?.body2, fontWeight: "bold" }}>
                  {reviewStepLabelMapping[step]}
                </Text>
              </PDFReviewTableCell>
            ))}
          </PDFReviewTableRow>
          <PDFReviewTableBody>
            {comments?.map((row, index) => (
              <PDFReviewTableRow
                key={row.productId}
                color={"white"}
                // height={row.height.toFixed(0)}
              >
                <PDFReviewTableCell
                  width={sizes?.firstCellWidth}
                  alignItems="center"
                >
                  <View
                    style={{
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {row?.productAvatar ? (
                      <Image
                        src={generatePDFImageIconUrl(
                          parseCommentProductLogo(row?.productAvatar),
                        )}
                        style={{ width: 20, height: 20 }}
                      />
                    ) : (
                      <Image
                        src={PDFDefaultPlatformLogoS3}
                        style={{ width: 20, height: 20 }}
                      />
                    )}

                    <Text
                      style={{
                        ...typographyStyle?.body2,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {row?.productName}
                    </Text>
                  </View>
                </PDFReviewTableCell>
                {isAppendix && (
                  <PDFReviewTableCell width={sizes?.secondCellWidth}>
                    <Text
                      style={{
                        ...typographyStyle?.body2,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {`${row?.featuresMatch} %`}
                    </Text>
                  </PDFReviewTableCell>
                )}

                {steps.map((step) => {
                  const removedAtStep = row?.removedFromShortlistAtStep;
                  const isShowRemovedLabel =
                    isAppendix && removedAtStep === step;
                  const isShowDashCharacter =
                    step &&
                    removedAtStep &&
                    ReviewSteps.indexOf(step) >
                      ReviewSteps.indexOf(removedAtStep) &&
                    step !== ReviewStep.Summary &&
                    isAppendix;
                  const comment = (row as any)[
                    analysisCommentsKeyMapping[step]
                  ];

                  return (
                    <PDFReviewTableCell
                      key={step}
                      justifyContent={comment ? "flex-start" : "center"}
                      width={sizes?.cellWidth}
                    >
                      <View
                        style={{
                          alignItems: "center",
                          gap: "2px",
                        }}
                      >
                        <Text
                          style={{
                            ...typographyStyle?.body2,
                            maxHeight: "auto",
                            fontSize: 7,
                          }}
                        >
                          {comment ? (
                            <PDFCommentRichTextEditor htmlString={comment} />
                          ) : row?.isContinued ? (
                            ""
                          ) : isShowDashCharacter ? (
                            "-"
                          ) : (
                            "No comment"
                          )}
                        </Text>
                        {isShowRemovedLabel && (
                          <View
                            style={{
                              height: "15px",
                              border: "1px solid #FFAC8A",
                              backgroundColor: "#FFE3D8",
                              borderRadius: "8px",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "90px",
                            }}
                          >
                            <Text
                              style={{
                                ...typographyStyle?.body2,
                                color: "#f01d0a",
                                fontSize: "7px",
                              }}
                            >
                              Removed from shortlist
                            </Text>
                          </View>
                        )}
                      </View>
                    </PDFReviewTableCell>
                  );
                })}
              </PDFReviewTableRow>
            ))}
          </PDFReviewTableBody>
        </PDFReviewTable>
      </View>
    </View>
  );
};
