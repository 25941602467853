import { SHFilterOption } from "@components/design-systems";
import { SupplierStatus } from "@models/supplier/enums/status";

export const StatusSuppliersFilterOptions: SHFilterOption[] = [
  {
    label: "Active",
    value: SupplierStatus.Active,
  },
  {
    label: "Disabled",
    value: SupplierStatus.Disabled,
  },
  {
    label: "Archived",
    value: SupplierStatus.Archived,
  },
];
export const DefaultSuppliersStatusFilterValue = [
  SupplierStatus.Active,
  SupplierStatus.Disabled,
];
