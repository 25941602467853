import { useLayoutEffect, useState } from "react";
import { Router, RouterProps } from "react-router-dom";
import { BrowserHistory, Update } from "history";

export interface CustomRouterProps
  extends Omit<RouterProps, "location" | "navigator"> {
  history: BrowserHistory;
}
export const SHRouter = ({ history, ...others }: CustomRouterProps) => {
  const [state, setState] = useState<Update>({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...others}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
