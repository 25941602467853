import { Components, Theme } from "@mui/material";
export const muiFormControlLabel = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiFormControlLabel"> => ({
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        alignItems: "start",
        "&>.MuiFormControlLabel-label": {
          color: `${theme.palette.text.primary} !important`,
        },
        "&>.Mui-disabled": {
          color: `${theme.palette.text.disabled} !important`,
        },
      },
    },
  },
});
