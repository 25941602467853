import { SHBox, SHTypography } from "@components/design-systems";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import { StatusBadge } from "@components/status-badge";
import { email } from "@constants/email";
import { useAppParams } from "@hooks/useAppParams";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { RootState } from "@redux/store";
import { useSelector } from "react-redux";

export const SupplierInvoices = () => {
  const { supplierData } = useSelector((state: RootState) => state.supplier);
  const { isAdminGroup, isSupplierGroup, canViewSupplierInvoices } =
    useUserPermissions();
  const { isMySupplier } = useAppParams();
  const accessDenied =
    !canViewSupplierInvoices || (isSupplierGroup && !isMySupplier);
  if (accessDenied) return <AccessDeniedDialog />;
  if (!isAdminGroup) {
    return (
      <>
        <TopBarContainer>
          <TopBar
            title={supplierData?.companyName}
            customBadge={
              supplierData?.status ? (
                <StatusBadge status={supplierData?.status} />
              ) : undefined
            }
          />
        </TopBarContainer>
        <SHBox>
          <SHTypography variant="subtitle2">Coming soon!</SHTypography>
          <SHTypography variant="body4">
            {`Please contact ${email.hello} to request your invoices.`}
          </SHTypography>
        </SHBox>
      </>
    );
  }
  return <SHTypography>Invoices</SHTypography>;
};
