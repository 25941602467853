import { InformationButton } from "@components/buttons/information";
import { SHBox, SHStack, SHTypography } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { useMemo } from "react";
import { EssentialButton } from "../essential-button";
import { ReviewConfigurationFeatureDTO } from "@models/reviews/entities/steps/feature";
import { generateFeatureName } from "@utils/text-format";
export interface FeatureItemProps {
  feature?: ReviewConfigurationFeatureDTO;
  isDeselect?: boolean;
  subGroupId?: string;
  handleOnSelectDeselect?: (
    subGroupId?: string,
    featureId?: string,
    isSelectFeature?: boolean,
  ) => void;
  handleOnSelectDeselectEssential?: (
    subGroupId?: string,
    featureId?: string,
    isEssential?: boolean,
  ) => void;
  handleOnBothSelect?: (
    subGroupId?: string,
    featureId?: string,
    isEssential?: boolean,
    isSelectFeature?: boolean,
  ) => void;
}
export const FeatureItem = ({
  feature,
  isDeselect,
  subGroupId,
  handleOnSelectDeselect,
  handleOnSelectDeselectEssential,
  handleOnBothSelect,
  ...others
}: FeatureItemProps) => {
  const { palette } = useTheme();

  const colors = useMemo(() => {
    if (feature?.isSelected)
      return {
        background: palette.success[50],
        border: palette.success[100],
        text: palette.text.primary,
      };
    return {
      background: palette.common.white,
      border: palette.common.black,
      text: palette.text.third,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature?.isSelected]);

  const featureName = generateFeatureName(
    feature?.name ?? "",
    feature?.description,
  );

  return (
    <SHStack
      direction={"row"}
      alignItems={"flex-start"}
      justifyContent={"space-between"}
      sx={{
        gap: "5px",
        padding: "7px 10px",
        boxSizing: "border-box",
        backdropFilter: "blur(2px)",
        background: `${colors?.background}`,
        border: `1px solid ${colors?.border}`,
        cursor: "pointer",
        boxShadow: `3px 3px 4px ${hexToRGBA(palette.common.black, 0.25)}`,
        transition: "all .1s linear",
        "&:hover": {
          border: `1px solid ${palette.primary[800]}`,
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: `inset 3px 3px 4px ${hexToRGBA(
            palette.common.black,
            0.25,
          )}`,
        },
      }}
      onClick={() =>
        typeof handleOnSelectDeselect === "function"
          ? handleOnSelectDeselect(subGroupId, feature?.id, isDeselect)
          : {}
      }
      {...others}
    >
      <SHStack width={"100%"}>
        <SHStack
          direction={"row"}
          width={"97%"}
          sx={{
            gap: "5px",
            wordBreak: "break-word",
          }}
          marginTop={"5px"}
        >
          <SHTypography
            variant="body2"
            lineHeight={"120%"}
            width={"100%"}
            sx={{
              color: colors.text,
              padding: 0,
            }}
          >
            {featureName.first}
            {feature?.description && (
              <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                {featureName.last}
                <InformationButton
                  content={feature.description}
                  margin={"-1px 0px 2px 4px !important"}
                />
              </SHBox>
            )}
          </SHTypography>
        </SHStack>
      </SHStack>
      <EssentialButton
        isEssential={feature?.isEssential}
        onClick={(e) => {
          e.stopPropagation();

          if (feature?.isSelected) {
            typeof handleOnSelectDeselectEssential === "function" &&
              handleOnSelectDeselectEssential(
                subGroupId,
                feature?.id,
                feature?.isEssential,
              );
          } else {
            typeof handleOnBothSelect === "function" &&
              handleOnBothSelect(subGroupId, feature?.id, feature?.isEssential);
          }
        }}
      >
        Essential
      </EssentialButton>
    </SHStack>
  );
};
