import { AdviserUserStatusAction } from "@models/practice/enums/status";
import * as Yup from "yup";

export const practiceValidators = Yup.object().shape({
  name: Yup.string()
    .required("Practice name is required")
    .max(100, "Practice name must be less than 100 characters"),
  afsl: Yup.number().typeError("AFSL number is required"),
  abn: Yup.string()
    .nullable(true)
    .test("abn", "ABN must be 11 digit", (value) => {
      return !value || value.length === 11;
    }),
  contactName: Yup.string()
    .required("Billing contact name is required")
    .max(100, "Billing contact name must be less than 100 characters"),
  contactEmail: Yup.string()
    .required("Billing contact email is required")
    .email("Billing contact email is not valid"),
});

export interface StatusButton {
  title: string;
  action: AdviserUserStatusAction;
  dialog?: {
    header: string;
    body: string;
    noButton: string;
    yesButton: string;
  };
}
export const getButtonsByStatus = (status: {
  isActive: boolean;
  isDisabled: boolean;
  isArchived: boolean;
}): StatusButton[] => {
  if (status.isActive) {
    return [
      {
        title: "Disable",
        action: AdviserUserStatusAction.Disable,
        dialog: {
          header: `Disable the practice?`,
          body: `Disabling a practice will remove access of this practice from all of its users.<br/>Only the Admin can access disabled practices.<br/>Do you want to disable this practice?`,
          noButton: "No, I do not",
          yesButton: "Yes, disable it",
        },
      },
    ];
  }
  if (status.isDisabled) {
    return [
      {
        title: "Enable",
        action: AdviserUserStatusAction.Enable,
      },
      {
        title: "Archive",
        action: AdviserUserStatusAction.Archive,
        dialog: {
          header: `Archive the practice?`,
          body: `Archiving a practice will freeze it from any further changes and hide it from lists by default.<br/>Do you want to archive this practice?`,
          noButton: "No, I do not",
          yesButton: "Yes, archive it",
        },
      },
    ];
  }
  return [
    {
      title: "Unarchive",
      action: AdviserUserStatusAction.Unarchive,
    },
  ];
};
