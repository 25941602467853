import { HtmlTag } from "@pages/platform-analysis/components/buttons/export-pdf/config";
import { DEFAULT_GAP_3 } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Text, View } from "@react-pdf/renderer";
import { isNil } from "lodash";

interface PDFRichTextEditorProps {
  htmlString: string | null;
}

export const PDFRichTextEditor = ({
  htmlString = "",
}: PDFRichTextEditorProps) => {
  const { typographyStyle } = useStyles();
  const textColor = "#000000";

  const convertHtmlToPdf = (htmlString: string): JSX.Element[] => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlString, "text/html");

    return Array.from(parsedHtml.body.childNodes).map((node) =>
      convertNodeToPdf(node),
    );
  };

  const convertNodeToPdf = (node: ChildNode): JSX.Element => {
    if (node.nodeType === Node.TEXT_NODE) {
      return <Text>{node.textContent}</Text>;
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element;
      const tagName = element.tagName.toLowerCase();

      switch (tagName) {
        case HtmlTag.P:
          return (
            <Text
              style={{
                ...typographyStyle.body1,
                color: textColor,
                textAlign: "left",
                lineHeight: 1.2,
                fontSize: 10,
              }}
            >
              {Array.from(element.childNodes).map((childNode) =>
                convertNodeToPdf(childNode),
              )}
            </Text>
          );
        case HtmlTag.STRONG:
          return (
            <Text style={{ fontWeight: "bold" }}>
              {Array.from(element.childNodes).map((childNode) =>
                convertNodeToPdf(childNode),
              )}
            </Text>
          );
        case HtmlTag.EM:
          return (
            <Text style={{ fontStyle: "italic" }}>
              {Array.from(element.childNodes).map((childNode) => {
                return convertNodeToPdf(childNode);
              })}
            </Text>
          );
        case HtmlTag.OL:
          return (
            <View style={{ marginLeft: 15 }}>
              {Array.from(element.children).map((childNode, index) => (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: 5,
                  }}
                  key={index}
                >
                  <Text
                    style={{
                      ...typographyStyle.body1,
                      color: textColor,
                      textAlign: "justify",
                      lineHeight: 1.2,
                      fontSize: 10,
                    }}
                  >
                    {`${index + 1}. `}
                    {Array.from(childNode.childNodes).map((subChildNode) => {
                      return convertNodeToPdf(subChildNode);
                    })}
                  </Text>
                </View>
              ))}
            </View>
          );
        case HtmlTag.UL:
          return (
            <View style={{ marginLeft: 15 }}>
              {Array.from(element.children).map((childNode, index) => (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: 5,
                  }}
                  key={index}
                >
                  <Text
                    style={{
                      ...typographyStyle.body1,
                      color: textColor,
                      textAlign: "justify",
                      lineHeight: 1.2,
                      fontSize: 10,
                    }}
                  >
                    •{" "}
                    {Array.from(childNode.childNodes).map((subChildNode) =>
                      convertNodeToPdf(subChildNode),
                    )}
                  </Text>
                </View>
              ))}
            </View>
          );
        case HtmlTag.BR:
          return <Text style={{ gap: DEFAULT_GAP_3 }}>{`\n`}</Text>;
        case HtmlTag.SPAN:
          return <Text>{node?.textContent}</Text>;
        default:
          return <></>;
      }
    }
    return <></>;
  };

  return <View>{!isNil(htmlString) && convertHtmlToPdf(htmlString)}</View>;
};

export const PDFCommentRichTextEditor = ({
  htmlString = "",
}: PDFRichTextEditorProps) => {
  const { typographyStyle } = useStyles();
  const textColor = "#000000";

  const convertHtmlToPdf = (htmlString: string): JSX.Element[] => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlString, "text/html");

    return Array.from(parsedHtml.body.childNodes).map((node) =>
      convertNodeToPdf(node),
    );
  };

  const convertNodeToPdf = (node: ChildNode): JSX.Element => {
    if (node.nodeType === Node.TEXT_NODE) {
      return <Text>{node.textContent}</Text>;
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element;
      const tagName = element.tagName.toLowerCase();

      switch (tagName) {
        case HtmlTag.P:
          return (
            <Text
              style={{
                ...typographyStyle.body1,
                color: textColor,
                textAlign: "left",
                lineHeight: 1.2,
                fontSize: 7,
              }}
            >
              {Array.from(element.childNodes).map((childNode) =>
                convertNodeToPdf(childNode),
              )}
            </Text>
          );
        case HtmlTag.STRONG:
          return (
            <Text style={{ fontWeight: "bold" }}>
              {Array.from(element.childNodes).map((childNode) =>
                convertNodeToPdf(childNode),
              )}
            </Text>
          );
        case HtmlTag.U:
          return (
            <Text style={{ textDecoration: "underline" }}>
              {Array.from(element.childNodes).map((childNode) =>
                convertNodeToPdf(childNode),
              )}
            </Text>
          );
        case HtmlTag.EM:
          return (
            <Text style={{ fontStyle: "italic" }}>
              {Array.from(element.childNodes).map((childNode) => {
                return convertNodeToPdf(childNode);
              })}
            </Text>
          );
        // TODO: Check & remove OL, UL tag
        case HtmlTag.OL:
          return (
            <View style={{ marginLeft: 15 }}>
              {Array.from(element.children).map((childNode, index) => (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: 5,
                  }}
                  key={index}
                >
                  <Text
                    style={{
                      ...typographyStyle.body1,
                      color: textColor,
                      textAlign: "left",
                      lineHeight: 1.2,
                      fontSize: 7,
                    }}
                  >
                    {`\n`}
                    <Text>{"\u00A0\u00A0\u00A0\u00A0"}</Text>
                    {`${index + 1}. `}
                    {Array.from(childNode.childNodes).map((subChildNode) => {
                      return convertNodeToPdf(subChildNode);
                    })}
                  </Text>
                </View>
              ))}
            </View>
          );
        case HtmlTag.UL:
          return (
            <View style={{ marginLeft: 15 }}>
              {Array.from(element.children).map((childNode, index) => (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: 5,
                  }}
                  key={index}
                >
                  <Text
                    style={{
                      ...typographyStyle.body1,
                      color: textColor,
                      textAlign: "left",
                      lineHeight: 1.2,
                      fontSize: 7,
                    }}
                  >
                    {`\n`}
                    <Text>{"\u00A0\u00A0\u00A0\u00A0"}</Text>•{" "}
                    {Array.from(childNode.childNodes).map((subChildNode) =>
                      convertNodeToPdf(subChildNode),
                    )}
                  </Text>
                </View>
              ))}
            </View>
          );
        case HtmlTag.BR:
          return <Text style={{ gap: DEFAULT_GAP_3 }}>{`\n`}</Text>;
        case HtmlTag.SPAN:
          return <Text>{node?.textContent}</Text>;
        default:
          return <></>;
      }
    }
    return <></>;
  };

  return <View>{!isNil(htmlString) && convertHtmlToPdf(htmlString)}</View>;
};
