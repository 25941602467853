import { SHButton, SHDialog, SHStack } from "@components/design-systems";

interface NoWeightsDialogProps {
  onClose?: () => void;
  onSubmit?: () => void;
}

export const NoWeightsDialog = ({
  onClose,
  onSubmit,
}: NoWeightsDialogProps) => {
  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: { xs: "100%", md: 656 },
        },
      }}
      header={`Investments with no weight will be removed.`}
      body={`Each investment's weight must be greater than 0%. Some investments currently have no weight. These will be removed as part of the fee calculation.`}
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onClose}
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onSubmit}
          >
            OK, continue
          </SHButton>
        </SHStack>
      }
    />
  );
};
