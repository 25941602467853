import { DateFormat } from "@constants/format";
import { PDFSerialNumber } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/pages/components/serial";
import { PDFFooterLogoS3 } from "@pages/platform-analysis/components/buttons/export-pdf/config";
import { FIRST_PAGE_FOOTER_HEIGHT } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { PDFReviewOverview } from "@pages/reviews/components/buttons/export-pdf/model";
import { Image, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import { useReviewStyles } from "../../../../styles";

interface PDFReviewPageFooterProps {
  overviewData?: PDFReviewOverview;
  pageIndex?: number;
  totalPage?: number;
  isFirstPage?: boolean;
}

export const PDFReviewPageFooter = (props: PDFReviewPageFooterProps) => {
  const { isFirstPage, ...otherProps } = props;

  const footerComponent = isFirstPage ? (
    <FirstPageFooter />
  ) : (
    <ReviewPageFooter {...otherProps} />
  );

  return footerComponent;
};

const FirstPageFooter = () => {
  const { pageStyle, typographyStyle } = useReviewStyles();

  return (
    <View
      style={{
        ...pageStyle.footer,
        height: FIRST_PAGE_FOOTER_HEIGHT,
        marginRight: -45,
        marginBottom: 0,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "stretch",
      }}
    >
      <View
        style={{
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          width: 150,
        }}
      >
        <Text
          style={{
            ...typographyStyle.subtitle,
            color: "#FFFFFF",
          }}
          // hyphenationCallback={(word) => {
          //   return [word];
          // }}
        >
          www.suitabilityhub.com
        </Text>
      </View>

      <Image
        src={PDFFooterLogoS3}
        style={{
          width: 330,
          height: 250,

          right: 0,
          bottom: -30,
          position: "absolute",
        }}
      />
    </View>
  );
};

const ReviewPageFooter = ({
  pageIndex,
  totalPage,
  overviewData,
}: PDFReviewPageFooterProps) => {
  const { pageStyle, typographyStyle } = useReviewStyles();

  const {
    adviserName,
    adviserFirmName,
    serial,
    familyGroupName,
    dataValidDate,
  } = overviewData ?? new PDFReviewOverview();

  return (
    <View style={pageStyle.footer}>
      <Text style={typographyStyle.body2}>
        {`This Suitability Review is for: `}
        <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
          {familyGroupName}
        </Text>

        {`. Data valid as at: `}
        <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
          {`${format(new Date(dataValidDate ?? new Date()), DateFormat)}`}
        </Text>
      </Text>

      <Text style={typographyStyle.body2}>
        {`Prepared by: `}
        <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
          {adviserName}
        </Text>
        {` of `}
        <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
          {adviserFirmName}
        </Text>
      </Text>
      <Text style={typographyStyle.body2}>
        Page {pageIndex} of {totalPage}
      </Text>
      <PDFSerialNumber serial={serial} />
    </View>
  );
};
