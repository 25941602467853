import { InformationButton } from "@components/buttons/information";
import {
  SHAutocomplete,
  SHRadio,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { useInvestment } from "@hooks/useInvestment";
import { useManagedAccount } from "@hooks/useManagedAccount";
import {
  SelectedInvestmentDTO,
  SelectedPortfolioTemplateDTO,
} from "@models/managed-accounts/entities/step/setup";
import { RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";

interface PortfolioTemplateProps {
  isDisabled?: boolean;
  portfolioSize: number;
  replaceInvestment: (value: SelectedInvestmentDTO[]) => void;
  onClearSaveAsTemplateWarnings: () => void;
}
export const PortfolioTemplate = ({
  isDisabled,
  portfolioSize,
  replaceInvestment,
  onClearSaveAsTemplateWarnings,
}: PortfolioTemplateProps) => {
  const { portfolioTemplates } = useSelector(
    (state: RootState) => state.managedAccount,
  );

  const {
    originalInvestmentSelections,
    originalInvestmentFavourited,
    setInvestmentSelection,
    setInvestmentFavourited,
  } = useInvestment();

  const {
    managedAccountId,
    loadManagedAccountPortfolioTemplates,
    loadManagedAccountPortfolioTemplateById,
  } = useManagedAccount();

  const loadData = async () => {
    await loadManagedAccountPortfolioTemplates();
  };

  const loadDataPortfolioTemplate = async (portfolioTemplateId: string) => {
    const selectedInvestment = await loadManagedAccountPortfolioTemplateById(
      managedAccountId ?? "",
      portfolioTemplateId,
      portfolioSize,
    );

    replaceInvestment(selectedInvestment ?? []);

    setInvestmentSelection(originalInvestmentSelections);
    setInvestmentFavourited(originalInvestmentFavourited);

    onClearSaveAsTemplateWarnings();
  };

  useEffectOnce(() => {
    loadData();
  });

  return (
    <SHAutocomplete
      disabled={isDisabled}
      textFieldProps={{
        InputLabelProps: { shrink: true },
        label: "Select a template to load",
        placeholder: "Search for a template",
      }}
      options={portfolioTemplates ?? []}
      getOptionLabel={(option: SelectedPortfolioTemplateDTO) =>
        option.name ?? ""
      }
      onChange={async (event, value) => {
        if (value) {
          await loadDataPortfolioTemplate(value?.id ?? "");
        }
      }}
      renderOption={(props, option, { selected }) => {
        const isChecked = selected;
        return (
          <li {...props} key={option.id} style={{ padding: "1px 10px" }}>
            <SHStack alignItems={"center"} direction="row" spacing={1}>
              <SHRadio checked={isChecked} />
              <SHTypography variant="body3" fontWeight={500}>
                {option.name}
              </SHTypography>
              {option.note && <InformationButton content={option.note} />}
            </SHStack>
          </li>
        );
      }}
      sx={{ width: { xs: "100%", md: 520 } }}
    />
  );
};
