import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Text, View } from "@react-pdf/renderer";

interface PDFSerialNumberProps {
  serial?: string;
}
export const PDFSerialNumber = ({ serial }: PDFSerialNumberProps) => {
  const { typographyStyle } = useStyles();

  return (
    <View
      style={{
        right: 0,
        bottom: 0,
        position: "absolute",
        flexDirection: "column",
        alignItems: "flex-end",
        alignContent: "center",
      }}
    >
      <Text style={{ ...typographyStyle.body2, fontSize: 8 }}>{serial}</Text>
    </View>
  );
};
