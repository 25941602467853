import LaunchIcon from "@assets/images/icon/launch.png";
import {
  SHButton,
  SHImage,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { StatusBadge } from "@components/status-badge";
import { PageRoutes } from "@constants";
import { usePractice } from "@hooks/usePractice";
import { usePracticeBillingInfo } from "@hooks/usePracticeBillingInfo";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { RootState } from "@redux/store";
import { useState } from "react";
import { useSelector } from "react-redux";

export const PracticeInvoices = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { practiceId, practice } = usePractice();
  const { loadCustomerPortalLink } = usePracticeBillingInfo();
  const { isAdviserTrial } = useUserPermissions();
  const [isLoading, setIsLoading] = useState(false);

  const handleViewInvoice = async () => {
    if (!practiceId) return;
    setIsLoading(true);
    const link = await loadCustomerPortalLink(practiceId);
    setIsLoading(false);
    if (link) window.location.assign(link);
  };

  return (
    <SHStack>
      <TopBarContainer>
        <TopBar
          navigate={
            user?.userMetadata?.is_system_admin
              ? { to: PageRoutes.practices }
              : undefined
          }
          title={practice?.name}
          customBadge={
            practice?.status ? (
              <StatusBadge status={practice.status} />
            ) : undefined
          }
        />
      </TopBarContainer>
      {isAdviserTrial ? (
        <SHStack>
          <SHTypography variant="subtitle1">
            Only users with Administrative Privileges can see this page
          </SHTypography>
          <SHTypography variant="body2">
            Please contact Support@SuitabilityHub.com if you require assistance
          </SHTypography>
        </SHStack>
      ) : (
        <SHButton
          variant="contained"
          sx={{ width: "109px" }}
          disabled={isLoading}
          isLoading={isLoading}
        >
          <SHStack
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={110}
            alignItems={"center"}
            onClick={handleViewInvoice}
          >
            <SHTypography colorVariant="white" fontSize={13} paddingTop={0}>
              Invoices
            </SHTypography>
            <SHImage
              src={LaunchIcon}
              width={20}
              height={20}
              style={{ marginTop: "-2px" }}
            />
          </SHStack>
        </SHButton>
      )}
    </SHStack>
  );
};
