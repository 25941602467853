import {
  DEFAULT_GAP_3,
  TABLE_WIDTH,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { View } from "@react-pdf/renderer";

interface PDFReviewTableProps {
  children?: React.ReactNode;
  tableWidth?: string | number;
}

export const PDFReviewTable = ({
  children,
  tableWidth = TABLE_WIDTH,
}: PDFReviewTableProps) => {
  return (
    <View
      style={{
        gap: DEFAULT_GAP_3,
        width: tableWidth,
      }}
    >
      {children}
    </View>
  );
};
