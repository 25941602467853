import { useTheme } from "@mui/material";
import {
  PDFHeaderDarkLogoS3,
  PDFHeaderLightLogoS3,
} from "@pages/platform-analysis/components/buttons/export-pdf/config";
import {
  REVIEW_FIRST_PAGE_HEADER_HEIGHT,
  REVIEW_PAGE_HEADER_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { generatePDFImageIconUrl } from "@pages/platform-analysis/components/buttons/export-pdf/util";
import { useReviewStyles } from "@pages/reviews/components/buttons/export-pdf/styles";
import { Image, Text, View } from "@react-pdf/renderer";
import { isEmpty } from "lodash";
interface PDFReviewPageHeaderProps {
  adviserFirmLogo?: string;
  isFirstPage?: boolean;
  isAppendix?: boolean;
}

export const PDFReviewPageHeader = ({
  isFirstPage = false,
  adviserFirmLogo,
  isAppendix,
}: PDFReviewPageHeaderProps) => {
  const { palette } = useTheme();
  const { pageStyle, typographyStyle } = useReviewStyles();
  return (
    <View
      style={
        isFirstPage
          ? { ...pageStyle.header, height: REVIEW_FIRST_PAGE_HEADER_HEIGHT }
          : { ...pageStyle.header, height: REVIEW_PAGE_HEADER_HEIGHT }
      }
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {!isEmpty(adviserFirmLogo) && (
          <Image
            src={generatePDFImageIconUrl(adviserFirmLogo)}
            style={
              isFirstPage
                ? { width: 76, height: 76 }
                : { width: 46, height: 46 }
            }
          />
        )}

        <Image
          src={isFirstPage ? PDFHeaderDarkLogoS3 : PDFHeaderLightLogoS3}
          style={
            isFirstPage
              ? { width: 223, height: 46 }
              : { width: 172, height: 35 }
          }
        />
      </View>
      <Text
        style={{
          ...(isFirstPage ? typographyStyle.h1 : typographyStyle.h2),
          color: isFirstPage ? palette.common.white : palette.common.black,
        }}
      >
        {isFirstPage
          ? "Suitability Review"
          : isAppendix
          ? "Appendix"
          : "Platforms selected"}
      </Text>
    </View>
  );
};
