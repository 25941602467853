import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";

interface UnlockDialogProps {
  onClose?: () => void;
  onSubmit?: () => void;
  isSubmitting?: boolean;
  isReview?: boolean;
}

export const UnlockDialog = ({
  onClose,
  onSubmit,
  isSubmitting = false,
  isReview = false,
}: UnlockDialogProps) => {
  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: { xs: "100%", md: 468 },
        },
      }}
      header={`Create a new copy of ${isReview ? "review" : "analysis"}?`}
      body={
        <SHStack spacing={"25px"}>
          <SHTypography variant="body2" sx={{ fontSize: "16px" }}>
            {`A completed ${
              isReview ? "Suitability Review" : "Platform Analysis"
            } cannot be edited. To unlock the ${
              isReview ? "review" : "analysis"
            }, you must create a copy. This editable copy has the same settings as your original ${
              isReview ? "review" : "analysis"
            }, but refreshes the data to the latest in our database.`}
          </SHTypography>
        </SHStack>
      }
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onSubmit}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Yes, create copy
          </SHButton>
        </SHStack>
      }
    />
  );
};
