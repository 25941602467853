import { SHStack } from "@components/design-systems";
import { StatusBadge } from "@components/status-badge";
import { usePractice } from "@hooks/usePractice";
import { usePracticeBillingInfo } from "@hooks/usePracticeBillingInfo";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { BillingInfoLeftSide } from "@pages/practices/_id/_billing-info/components/left-side";
import { BillingInfoRightSide } from "@pages/practices/_id/_billing-info/components/right-side";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { useEffectOnce } from "react-use";
import { BillingInfoSkeleton } from "./skeleton";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHED_KEY ?? "",
);

export const PracticeBillingInfo = () => {
  const {
    practice,
    practiceId,
    ui: { practiceName },
  } = usePractice();

  const { loadBillingInfo, practiceClientSecret, practiceBillingInfo, ui } =
    usePracticeBillingInfo();

  useEffectOnce(() => {
    if (!practiceId) return;
    loadBillingInfo(practiceId);
  });

  if (ui.billingInfo.isLoading || !practiceBillingInfo || !practiceClientSecret)
    return <BillingInfoSkeleton />;

  return (
    <SHStack>
      <TopBarContainer>
        <TopBar
          title={practiceName}
          customBadge={
            practice?.status ? (
              <StatusBadge status={practice.status} />
            ) : undefined
          }
        />
      </TopBarContainer>

      <SHStack>
        <Elements
          stripe={stripePromise}
          options={
            {
              loader: "always",
              clientSecret: practiceClientSecret,
              locale: "en-GB",
              paymentMethodCreation: "manual",
            } as StripeElementsOptions
          }
        >
          <SHStack
            direction={{ xs: "column", md: "row" }}
            justifyContent={"space-between"}
          >
            <BillingInfoLeftSide />
            <BillingInfoRightSide />
          </SHStack>
        </Elements>
      </SHStack>
    </SHStack>
  );
};
