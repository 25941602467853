import { SHContainer, SHGrid, SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { StepName } from "@layouts/stepper/step-name";

export const AnalysisFeeSkeleton = () => {
  return (
    <SHContainer maxWidth="lg2" sx={{ px: { xs: "16px", lg: 0 }, pb: "25px" }}>
      <SHStack spacing={"25px"}>
        <SHStack direction={"row"} justifyContent={"space-between"}>
          <StepName isLoading name={"Fee analysis"} stepNumber={0} />
          <SHStack alignItems={"flex-end"} justifyContent={"space-between"}>
            <SHSkeleton width={150} height={22} />
            <SHStack direction={"row"} alignItems={"center"} spacing={"25px"}>
              <SHSkeleton width={140} height={30} />
              <SHSkeleton width={140} height={30} />
            </SHStack>
          </SHStack>
        </SHStack>
        <SHStack
          direction={{ xs: "column", lg: "row" }}
          justifyContent={{ xs: "stretch", lg: "space-between" }}
          spacing={"25px"}
          alignItems={"flex-start"}
        >
          <SHGrid container width={{ xs: "100%", lg: "250px" }}>
            {Array.from(Array(4).keys()).map((_, index) => (
              <SHGrid
                xs={6}
                sm={4}
                md={3}
                lg={12}
                key={index}
                sx={{ p: "1px" }}
              >
                <SHSkeleton width={"100%"} height={100} />
              </SHGrid>
            ))}
          </SHGrid>
          <SHStack
            sx={{
              flexGrow: 1,
              width: { xs: "100%", lg: "1px" },
            }}
            spacing={"25px"}
          >
            <SHSkeleton width={"100%"} height={300} />
            <SHSkeleton width={"100%"} height={300} />
          </SHStack>
        </SHStack>
      </SHStack>
    </SHContainer>
  );
};
