import {
  SHAlert,
  SHButton,
  SHCheckbox,
  SHDialog,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import { SHFormControlLabel } from "@components/design-systems/sh-form-control-label";
import { CompleteProfileSupplierUser } from "@components/dialogs/complete-profile-first-step/model";
import { completeProfileFirstStepValidators } from "@components/dialogs/complete-profile-first-step/util";
import { LicenceAgreementDialog } from "@components/dialogs/licence-agreement";
import { PrivacyPolicyDialog } from "@components/dialogs/privacy-policy";
import { WarningSVG } from "@components/svgs/warning";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "@mui/material";
import { theme } from "@themes";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

interface CompleteProfileFirstStepDialogProps {
  onComplete: (userDetails: CompleteProfileSupplierUser) => void;
  isCompleting?: "first" | "last" | null;
  supplierUserInfo?: CompleteProfileSupplierUser;
}

export const CompleteProfileFirstStepDialog = ({
  onComplete,
  isCompleting = null,
  supplierUserInfo,
}: CompleteProfileFirstStepDialogProps): JSX.Element => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showLicenceAgreement, setShowLicenceAgreement] = useState(false);
  const [notification, setNotification] = useState<string | null>(null);

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CompleteProfileSupplierUser>({
    mode: "onChange",
    resolver: yupResolver(completeProfileFirstStepValidators),
    defaultValues: { ...supplierUserInfo },
  });

  const onSubmit = (userInfo: CompleteProfileSupplierUser) => {
    if (!userInfo.isAgree) {
      setNotification(
        "To proceed, you must agree to the EULA and Privacy policy",
      );
      return;
    }
    onComplete(userInfo);
  };

  return (
    <form>
      <SHDialog
        open
        disableEscapeKeyDown
        disableBackdropClick
        maxWidth={false}
        sx={{
          "& .MuiPaper-root": {
            width: { xs: "100%", md: 645 },
          },
        }}
        header={
          <SHStack alignItems="center" textAlign="center" spacing={1}>
            <SHTypography
              variant="h2"
              sx={{
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              Hi {supplierUserInfo?.firstName}, your account is almost ready!
            </SHTypography>
            <SHTypography
              variant="body2"
              colorVariant="disabled"
              sx={{
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              Please review and complete your details below:
            </SHTypography>
          </SHStack>
        }
        body={
          <SHStack alignItems="stretch" spacing={2} sx={{ width: 525 }}>
            <SHStack spacing={3} direction="row">
              <Controller
                name="firstName"
                control={control}
                rules={{ required: "First name required" }}
                render={({ field }) => (
                  <SHTextField
                    {...field}
                    required
                    sx={{ width: { xs: "100%", md: 250 } }}
                    label="First name"
                    error={!!errors.firstName}
                    helperText={
                      errors.firstName ? errors.firstName.message : null
                    }
                    placeholder={"Enter your first name"}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{ required: "Last name required" }}
                render={({ field }) => (
                  <SHTextField
                    {...field}
                    required
                    sx={{ width: { xs: "100%", md: 250 } }}
                    label="Last name"
                    error={!!errors.lastName}
                    helperText={
                      errors.lastName ? errors.lastName.message : null
                    }
                    placeholder={"Enter your last name"}
                  />
                )}
              />
            </SHStack>
            <Controller
              name="workEmail"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  type="email"
                  label={"Work email"}
                  sx={{ width: { xs: "100%", md: 525 } }}
                  error={!!errors.workEmail}
                  helperText={
                    errors.workEmail ? errors.workEmail.message : null
                  }
                  placeholder={"Enter your work email"}
                  disabled
                />
              )}
            />
            <Controller
              name="jobTitle"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  label={"Job title"}
                  sx={{ width: { xs: "100%", md: 525 } }}
                  placeholder={"Enter your job title"}
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  label={"Contact number"}
                  sx={{ width: { xs: "100%", md: 525 } }}
                  placeholder={"Enter your contact number"}
                  helperText={
                    errors.phoneNumber ? errors.phoneNumber.message : null
                  }
                />
              )}
            />
            <Controller
              render={({ field: { ref, ...field } }) => (
                <SHFormControlLabel
                  {...field}
                  sx={{ pointerEvents: "none" }}
                  control={
                    <SHCheckbox
                      checked={getValues("isAgree")}
                      name="isAgree"
                      sx={{ pointerEvents: "auto" }}
                    />
                  }
                  componentsProps={{
                    typography: { align: "left" },
                  }}
                  label={
                    <SHTypography
                      variant="subtitle2"
                      colorVariant="third"
                      sx={{
                        cursor: "default",
                        pointerEvents: "none",
                      }}
                    >
                      I have read and agree to the
                      <Link
                        variant="body2"
                        color={theme.palette.text.secondary}
                        underline="none"
                        sx={{
                          paddingLeft: 0.5,
                          paddingRight: 0.5,
                          fontWeight: 600,
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setShowLicenceAgreement(true);
                        }}
                      >
                        End User Licence Agreement (EULA)
                      </Link>
                      <br />
                      and
                      <Link
                        variant="body2"
                        color={theme.palette.text.secondary}
                        component="span"
                        underline="none"
                        sx={{
                          paddingLeft: 0.5,
                          fontWeight: 600,
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setShowPrivacyPolicy(true);
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </SHTypography>
                  }
                />
              )}
              name="isAgree"
              control={control}
              rules={{ required: true }}
            />

            {notification && (
              <SHStack>
                <SHAlert
                  onClose={() => setNotification(null)}
                  severity="error"
                  width={"525px !important"}
                  textAlign={"left"}
                  icon={<WarningSVG />}
                >
                  To proceed, you must agree to the EULA and Privacy policy
                </SHAlert>
              </SHStack>
            )}
          </SHStack>
        }
        footer={
          <SHStack
            justifyContent="center"
            alignItems="center"
            direction={"row"}
          >
            <SHButton
              color="primary"
              size="large"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={isCompleting === "first" || !isValid}
              isLoading={isCompleting === "first"}
            >
              Complete
            </SHButton>
          </SHStack>
        }
      />
      {showPrivacyPolicy && (
        <PrivacyPolicyDialog
          onClose={() => {
            setShowPrivacyPolicy(false);
          }}
        />
      )}
      {showLicenceAgreement && (
        <LicenceAgreementDialog
          onClose={() => {
            setShowLicenceAgreement(false);
          }}
        />
      )}
    </form>
  );
};
