import {
  SHBox,
  SHStack,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { ImageGalleryDialog } from "@components/image-gallery-dialog";
import { ArrowDownSVG, ImageSVG } from "@components/svgs";
import { ImageDTO } from "@models/platform-analysis/entities/steps/feature";
import {
  ReviewDataDTO,
  ReviewSectionDTO,
  ReviewSelectedProductDTO,
} from "@models/reviews/entities/steps/feature";
import { useTheme } from "@mui/material";
import {
  FeatureReviewTableCell,
  FeatureReviewTableCellProps,
} from "@pages/reviews/_id/steps/feature/analysis/components/table-cell";
import { FeatureReviewTableHeadProps } from "@pages/reviews/_id/steps/feature/analysis/components/table-head";
import { generateReviewColorCell } from "@pages/reviews/_id/steps/feature/analysis/util";
import { isEmpty } from "lodash";
import { useState } from "react";
import { ReactImageGalleryItem } from "react-image-gallery";
interface FeatureReviewSubSectionRowProps {
  subSection?: ReviewSectionDTO;
  isCollapsing?: boolean;
  onCollapse?: () => void;
}

export const FeatureReviewSubSectionRow = ({
  subSection,
  selectedProducts,
  sx: sxProps,
  isCollapsing = false,
  onCollapse = () => {},
  ...others
}: FeatureReviewSubSectionRowProps &
  FeatureReviewTableCellProps &
  SHTableRowProps &
  Pick<FeatureReviewTableHeadProps, "selectedProducts">) => {
  const { palette } = useTheme();
  const [previewImages, setPreviewImages] = useState<ImageDTO[]>([]);

  return (
    <>
      <FeatureReviewTableCell isFirstColumn>
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent="space-between"
          sx={{ cursor: "pointer" }}
          onClick={onCollapse}
        >
          <SHTypography
            variant={"subtitle2"}
            sx={{ paddingTop: 0, fontSize: 13 }}
          >
            {subSection?.name}
          </SHTypography>
          <SHBox
            alignItems={"center"}
            sx={{
              display: "flex",
              transition: "all ease-in-out 0.2s",
              transform: `rotate(${!isCollapsing ? 0 : 180}deg)`,
            }}
          >
            <ArrowDownSVG width={16} height={16} />
          </SHBox>
        </SHStack>
      </FeatureReviewTableCell>
      {subSection?.analysisData?.map((reviewData: ReviewDataDTO) => {
        const platform = selectedProducts?.find(
          (selectedProduct: ReviewSelectedProductDTO) =>
            selectedProduct.id === reviewData.productId,
        );

        return (
          <FeatureReviewTableCell
            key={reviewData?.productId}
            colorCell={generateReviewColorCell(
              platform?.decisionStatus,
              platform?.isMissingEssential,
            )}
          >
            <SHStack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <SHTypography
                variant={"subtitle2"}
                sx={{ fontSize: "13px", fontWeight: 500, lineHeight: "120%" }}
              >
                Sub-group score
              </SHTypography>
              <SHTypography
                variant={"subtitle2"}
                sx={{ fontSize: "13px", fontWeight: 500, lineHeight: "120%" }}
              >
                {`${reviewData?.totalScore}%`}
              </SHTypography>
              {!isEmpty(reviewData.imageGallery) && (
                <SHBox
                  sx={{
                    padding: 0,
                    alignItems: "flex-end",
                    display: "flex",
                    "&:hover": {
                      "& .sh-image-svg": {
                        "& .sh-image-svg-g": {
                          stroke: palette.primary[800],
                        },
                      },
                    },
                  }}
                >
                  <ImageSVG
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPreviewImages(reviewData?.imageGallery ?? [])
                    }
                  />
                </SHBox>
              )}
            </SHStack>
          </FeatureReviewTableCell>
        );
      })}
      <ImageGalleryDialog
        open={!isEmpty(previewImages)}
        title={subSection?.name}
        items={
          previewImages?.map((item: ImageDTO) => {
            return {
              original: `${item.url}`,
            };
          }) as ReactImageGalleryItem[]
        }
        onClose={() => setPreviewImages([])}
      />
    </>
  );
};
