import { theme } from "@themes";
import { SVGProps } from "react";

export const OperationalSVG = ({
  color = theme.palette.common.black,
  ...others
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={24}
    fill="none"
    {...others}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.992 1.823c.104-.517.558-.89 1.085-.89h2.612c.527 0 .982.373 1.085.89l.351 1.757a8.843 8.843 0 0 1 3.178 1.837l1.7-.576c.5-.169 1.05.038 1.314.495l1.306 2.262a1.107 1.107 0 0 1-.228 1.385l-1.349 1.185a8.886 8.886 0 0 1 0 3.664l1.346 1.183c.397.348.492.928.228 1.384l-1.306 2.262a1.107 1.107 0 0 1-1.313.495l-1.699-.574a8.842 8.842 0 0 1-3.177 1.837l-.35 1.756c-.104.517-.559.89-1.086.89H9.077c-.527 0-.981-.373-1.085-.89l-.351-1.756a8.842 8.842 0 0 1-3.174-1.835l-1.698.575c-.5.169-1.049-.038-1.313-.495L.15 16.402a1.106 1.106 0 0 1 .228-1.385l1.344-1.18a8.889 8.889 0 0 1 0-3.674L.375 8.98a1.107 1.107 0 0 1-.228-1.384l1.306-2.262a1.107 1.107 0 0 1 1.313-.495l1.7.575A8.843 8.843 0 0 1 7.64 3.58l.351-1.757Zm2.392 14.603a4.426 4.426 0 1 0 0-8.852 4.426 4.426 0 0 0 0 8.852Z"
      fill={color}
    />
  </svg>
  );
};
