import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import {
  AdviserFirmDTO,
  CreateAdviserFirmDTO,
  UpdateAdviserFirmDTO,
} from "@models/practice/entities/practice";
import {
  AdviserFirmStatus,
  AdviserUserStatusAction,
} from "@models/practice/enums/status";
import { PracticeDTO } from "@models/supplier/entities/practices";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { sleep } from "@utils";
import { generatePath } from "react-router";

export async function getPractice(
  practiceId: string,
): Promise<APIResponse<AdviserFirmDTO>> {
  const response = await APIGatewayService.get<AdviserFirmDTO>(
    generatePath(APIExtRoutes.adviserFirm, { practiceId }),
  );

  return handleErrorProxy(response);
}

export async function postPractice(
  practice: CreateAdviserFirmDTO,
): Promise<APIResponse<AdviserFirmDTO>> {
  const response = await APIGatewayService.post<CreateAdviserFirmDTO>(
    APIExtRoutes.adviserFirms,
    practice,
  );
  return handleErrorProxy(response, "Practice created successfully.");
}

export async function putPractice(
  practice: AdviserFirmDTO,
): Promise<APIResponse<UpdateAdviserFirmDTO>> {
  const response = await APIGatewayService.put<UpdateAdviserFirmDTO>(
    APIExtRoutes.adviserFirms,
    practice,
  );
  return handleErrorProxy(response, "Changes have been saved.");
}

export async function patchPracticeStatus(
  practiceId: string,
  status: AdviserUserStatusAction,
): Promise<APIResponse<AdviserFirmStatus>> {
  await sleep(2000);
  return handleErrorProxy(
    {
      config: {},
      headers: {},
      data: {
        [AdviserUserStatusAction.Disable]: AdviserFirmStatus.Disabled,
        [AdviserUserStatusAction.Archive]: AdviserFirmStatus.Archived,
        [AdviserUserStatusAction.Enable]: AdviserFirmStatus.Active,
        [AdviserUserStatusAction.Unarchive]: AdviserFirmStatus.Disabled,
      }[status],
      status: 200,
      statusText: "OK",
    },
    "Changes have been saved.",
  );
  // const response = await APIGatewayService.patch<PracticeDTO>(
  //   generatePath(APIExtRoutes.updatePracticeStatus, { practiceId , status }),
  //   practice,
  // );
  // return handleErrorProxy(response);
}

export async function getPracticesByAFSL(
  afsl: string,
): Promise<APIResponse<PracticeDTO[]>> {
  const response = await APIGatewayService.get<PracticeDTO[]>(
    generatePath(APIExtRoutes.adviserFirmByAFSL, { afsl })
  );
  return handleErrorProxy(response);
}
