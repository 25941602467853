import { SVGProps } from "react";
export const CrossCircleSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_13058_47437)">
      <g clip-path="url(#clip0_13058_47437)">
        <path
          d="M7.5 13.625C5.87555 13.625 4.31763 12.9797 3.16897 11.831C2.02031 10.6824 1.375 9.12445 1.375 7.5C1.375 5.87555 2.02031 4.31763 3.16897 3.16897C4.31763 2.02031 5.87555 1.375 7.5 1.375C9.12445 1.375 10.6824 2.02031 11.831 3.16897C12.9797 4.31763 13.625 5.87555 13.625 7.5C13.625 9.12445 12.9797 10.6824 11.831 11.831C10.6824 12.9797 9.12445 13.625 7.5 13.625ZM7.5 14.5C9.35652 14.5 11.137 13.7625 12.4497 12.4497C13.7625 11.137 14.5 9.35652 14.5 7.5C14.5 5.64348 13.7625 3.86301 12.4497 2.55025C11.137 1.2375 9.35652 0.5 7.5 0.5C5.64348 0.5 3.86301 1.2375 2.55025 2.55025C1.2375 3.86301 0.5 5.64348 0.5 7.5C0.5 9.35652 1.2375 11.137 2.55025 12.4497C3.86301 13.7625 5.64348 14.5 7.5 14.5Z"
          fill="white"
        />
        <path
          d="M4.56603 4.56603C4.60667 4.52529 4.65495 4.49296 4.7081 4.47091C4.76125 4.44885 4.81824 4.4375 4.87578 4.4375C4.93333 4.4375 4.99031 4.44885 5.04346 4.47091C5.09661 4.49296 5.14489 4.52529 5.18553 4.56603L7.50078 6.88216L9.81603 4.56603C9.85671 4.52535 9.905 4.49309 9.95815 4.47107C10.0113 4.44906 10.0683 4.43773 10.1258 4.43773C10.1833 4.43773 10.2403 4.44906 10.2934 4.47107C10.3466 4.49309 10.3949 4.52535 10.4355 4.56603C10.4762 4.60671 10.5085 4.655 10.5305 4.70815C10.5525 4.76129 10.5638 4.81826 10.5638 4.87578C10.5638 4.93331 10.5525 4.99027 10.5305 5.04342C10.5085 5.09656 10.4762 5.14486 10.4355 5.18553L8.11941 7.50078L10.4355 9.81603C10.4762 9.85671 10.5085 9.905 10.5305 9.95815C10.5525 10.0113 10.5638 10.0683 10.5638 10.1258C10.5638 10.1833 10.5525 10.2403 10.5305 10.2934C10.5085 10.3466 10.4762 10.3949 10.4355 10.4355C10.3949 10.4762 10.3466 10.5085 10.2934 10.5305C10.2403 10.5525 10.1833 10.5638 10.1258 10.5638C10.0683 10.5638 10.0113 10.5525 9.95815 10.5305C9.905 10.5085 9.85671 10.4762 9.81603 10.4355L7.50078 8.11941L5.18553 10.4355C5.14486 10.4762 5.09656 10.5085 5.04342 10.5305C4.99027 10.5525 4.93331 10.5638 4.87578 10.5638C4.81826 10.5638 4.76129 10.5525 4.70815 10.5305C4.655 10.5085 4.60671 10.4762 4.56603 10.4355C4.52535 10.3949 4.49309 10.3466 4.47107 10.2934C4.44906 10.2403 4.43773 10.1833 4.43773 10.1258C4.43773 10.0683 4.44906 10.0113 4.47107 9.95815C4.49309 9.905 4.52535 9.85671 4.56603 9.81603L6.88216 7.50078L4.56603 5.18553C4.52529 5.14489 4.49296 5.09661 4.47091 5.04346C4.44885 4.99031 4.4375 4.93333 4.4375 4.87578C4.4375 4.81824 4.44885 4.76125 4.47091 4.7081C4.49296 4.65495 4.52529 4.60667 4.56603 4.56603Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_b_13058_47437"
        x="-0.5"
        y="-0.5"
        width="16"
        height="16"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_13058_47437"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_13058_47437"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_13058_47437">
        <rect x="0.5" y="0.5" width="14" height="14" rx="7" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
