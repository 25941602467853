export const SubscriptionHelperData: {
  question: string;
  answer: string;
}[] = [
  {
    question: "How many Adviser Licences do I need?",
    answer:
      "Clients and Suitability Reviews can only be linked to advisers, therefore we recommend one licence per client facing adviser in your practice. You do not need to purchase any licences for your support staff, as they get free access when there is at least one paid adviser for your practice.",
  },
  {
    question:
      "What happens if I add additional licences when we already have an active subscription?",
    answer:
      "We will pro rata the charges for the additional licences based on the remaining days in the current billing cycle.",
  },
  {
    question:
      "How do you calculate what I will be charged once my subscription renews?",
    answer:
      "We count the number of licences set to auto-renew and multiply this with the annual cost of the licence type. We will charge the default credit card in our system.",
  },
  {
    question: "How do I change my credit card and other billing details?",
    answer:
      "Navigate to the “Billing Info” tab above this screen. From there you can view and change your current details.",
  },
  {
    question: "What happens if my subscription expires?",
    answer:
      "We will downgrade all users to a Free Trial account with limited functionality. But don't worry, you can access past Suitability Reviews for 12-months without having to upgrade to a paid licence again.",
  },
  {
    question: "How do I cancel my subscription?",
    answer:
      "Simply turn off auto-renewal for all users. At the end of your subscription period, they will be downgraded to a Free Trial account with limited functionality.",
  },
];

export const DefaultAdviserLicenceCostExcGST = 900;
export const DefaultAdviserLicenceCostIncGST = 990;
export const DefaultMaxLicenceQuantity = 99;
export const DefaultMinLicenceQuantity = 0;
export const CENT_UNIT = 100;
