import { SVGProps } from "react";

export const FooterSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="339"
    height="249"
    viewBox="0 0 339 249"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M189.793 2.89813L112.293 38.0409L14.7586 82.2755C5.58157 86.4395 0 95.2488 0 105.565V190.832V276.1C0 286.426 5.58157 295.225 14.7586 299.4L112.293 343.624L113.964 344.377V254.83V152.969V90.185C113.964 81.3235 117.987 73.6964 125.218 68.8524C132.46 64.0188 140.873 63.3074 148.767 66.896L224.597 101.275V88.9714V26.1976C224.597 17.3152 220.573 9.69858 213.342 4.85455C209.156 2.05066 204.562 0.638245 199.918 0.638245C196.536 0.638245 193.124 1.39157 189.793 2.89813Z"
      fill="url(#paint0_linear_1513_74568)"
    />
    <path
      d="M125.216 68.8546C117.984 73.6986 113.961 81.3257 113.961 90.1872V152.972V254.832V344.379L189.791 378.79C197.695 382.368 206.108 381.667 213.339 376.812C220.571 371.979 224.594 364.352 224.594 355.49V292.696V190.835V101.277L148.764 66.8982C145.444 65.3916 142.021 64.6383 138.64 64.6383C133.995 64.6383 129.412 66.0507 125.216 68.8546Z"
      fill="url(#paint1_linear_1513_74568)"
    />
    <path
      d="M224.594 190.831V292.692V355.487C224.594 364.348 220.571 371.975 213.339 376.809C206.108 381.663 197.695 382.364 189.791 378.786L113.961 344.376V356.7V419.495C113.961 428.356 117.984 435.983 125.216 440.807C132.457 445.661 140.86 446.362 148.764 442.773L226.264 407.631L323.789 363.396C332.976 359.232 338.568 350.412 338.568 340.107V254.829V169.561C338.568 159.245 332.986 150.436 323.789 146.272L226.264 102.037L224.594 101.274V190.831Z"
      fill="url(#paint2_linear_1513_74568)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1513_74568"
        x1="-6.90393"
        y1="326.695"
        x2="238.759"
        y2="24.228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00F2FF" />
        <stop offset="0.3" stopColor="#17E2FE" />
        <stop offset="0.86" stopColor="#F544ED" />
        <stop offset="1" stopColor="#FF40EC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1513_74568"
        x1="176.156"
        y1="385.366"
        x2="161.641"
        y2="60.3473"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="#843DDB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1513_74568"
        x1="102.987"
        y1="444.287"
        x2="358.779"
        y2="109.068"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD175" />
        <stop offset="0.48" stopColor="#F19433" />
        <stop offset="1" stopColor="#FF00D0" />
      </linearGradient>
    </defs>
  </svg>
);
