import { SelectProducts } from "@components/auto-completes/products";
import {
  SHAutocomplete,
  SHBooleanField,
  SHBox,
  SHButton,
  SHContainer,
  SHDivider,
  SHIconLoading,
  SHRadio,
  SHRichTextEditor,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import UnsavedDialog from "@components/dialogs/unsaved";
import { StatusBadge } from "@components/status-badge";
import { APIExtRoutes, PageRoutes } from "@constants";
import { DateTimeFormat } from "@constants/format";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsNew } from "@hooks/useIsNew";
import { useProduct } from "@hooks/useProduct";
import { useRateCard } from "@hooks/useRateCard";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar } from "@layouts/top-bar";
import { ExistingFeeScheduleDTO } from "@models/product/entities/product";
import {
  CreateRateCardDTO,
  RateCardStatus,
  RateCardUniqueNameLevel,
  ReviewRateCardDTO,
  UpdateRateCardDTO,
} from "@models/rate-card/entities/rateCard";
import { useTheme } from "@mui/material";
import { OverwriteFeeScheduleDialog } from "@pages/platform-analysis/components/dialogs/overwrite-fee-schedule";
import { FREditFeesTable } from "@pages/suppliers/_id/_products/_id/edit/components/fees-and-rates/components/tables";
import { RootState } from "@redux/store";
import { format } from "date-fns";
import { isEmpty, isNil } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { checkRateCardUniqueName } from "@services/rate-card/rateCardService";
import { CheckSVG, CloseSVG } from "@components/svgs";
import {
  buildRateCate,
  convertDataToSubmit,
  generateRateCardFormTitle,
  rateCardValidators,
} from "@pages/platform-analysis/_rate-cards/_id/util";
import { RateCardSkeleton } from "@pages/platform-analysis/_rate-cards/_id/RateCardSkeleton";
import { useParentState } from "@hooks/useParentState";

export const ReviewRateCardManagePage = () => {
  const isNew = useIsNew();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { rateCardId } = useParams<{ rateCardId: string }>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { rateCardUI, rateCard } = useSelector(
    (state: RootState) => state.rateCard,
  );
  const { loadExistingFeeSchedule, loadSubProduct } = useProduct();
  const {
    loadRateCard,
    loadRateCardLOV,
    createRateCard,
    updateRateCard,
    updateRateCardStatus,
  } = useRateCard();

  const formControls = useForm({
    mode: "onChange",
    defaultValues: {
      ...new ReviewRateCardDTO(),
    },
    resolver: yupResolver(rateCardValidators),
  });

  const {
    watch,
    reset,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, dirtyFields },
  } = formControls;

  const isDirty = !isEmpty(dirtyFields);

  const [isOverriding, setIsOverriding] = useState(false);
  const [showOverrideDialog, setShowOverrideDialog] = useState<
    | {
        feeScheduleId: string;
        isSubProduct?: boolean;
      }
    | undefined
  >(undefined);
  const [isChangingStatus, setIsChangingStatus] =
    useState<RateCardStatus | null>();
  const [feeSchedules, setFeeSchedules] = useState<ExistingFeeScheduleDTO[]>();
  const [selectedFeeSchedule, setSelectedFeeSchedule] =
    useState<ExistingFeeScheduleDTO>();

  const [verifyRateCardName, setVerifyRateCardName] = useState<
    "error" | "success" | "checking" | "none"
  >(isNew ? "none" : "success");
  const [isValidName, setIsValidName] = useState<boolean>(true);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const productId = watch("productId");
  const currentStatus = watch("status");
  const { isEditMode } = useParentState();

  const isDirtyRateCardData =
    !isNil(dirtyFields?.standardFeeSections) ||
    !isNil(dirtyFields?.otherFeeSections);

  const [verifyRateCardNameErrorMessage, setVerifyRateCardNameErrorMessage] =
    useState("");

  const loadRateCardDetails = async () => {
    let rateCard: ReviewRateCardDTO | undefined = undefined;

    if (isNew) {
      rateCard = await loadRateCardLOV();
    } else {
      if (!rateCardId) return;
      rateCard = await loadRateCard(rateCardId);
    }

    if (rateCard)
      reset({
        ...rateCard,
        isAvailableForAnalysis: isNew ? true : rateCard?.isAvailableForAnalysis,
        isShared: watch("isShared"),
      });
  };

  const clearTimer = () => {
    if (timer.current) clearTimeout(timer.current);
  };

  const handleOnChecking = async (name: string | null) => {
    clearTimer();
    setIsValidName(false);
    if (
      !name ||
      name.trim().length === 0 ||
      !user?.userMetadata?.adviser_firm_id
    ) {
      setVerifyRateCardName("none");
      return;
    }
    if (rateCard?.name === name) {
      setVerifyRateCardName("success");
      setIsValidName(true);
      return;
    }
    setVerifyRateCardName("checking");
    timer.current = setTimeout(async () => {
      const { isSuccess, message } = await checkRateCardUniqueName(
        "",
        user?.userMetadata?.adviser_firm_id ?? "",
        name,
        RateCardUniqueNameLevel.User,
        ""
      );
      if (isSuccess) {
        setVerifyRateCardName("success");
        setIsValidName(true);
      } else {
        setVerifyRateCardName("error");
        setVerifyRateCardNameErrorMessage(message!);
      }
    }, 300);
  };

  const renderVerifyRateCardName = () => {
    switch (verifyRateCardName) {
      case "success":
        return <CheckSVG width={18} height={18} />;
      case "error":
        return (
          <SHBox px={"4px"} py={"1px"}>
            <CloseSVG width={10} height={10} />
          </SHBox>
        );
      case "checking":
        return <SHIconLoading size={16} />;
      default:
        return null;
    }
  };

  const loadFeeSchedules = async () => {
    if (!productId) return;
    const response = await loadExistingFeeSchedule(productId);
    if (response)
      setFeeSchedules(response?.filter((item) => item?.id !== rateCardId));
  };

  const handleSubmitRateCard = async (rateCard: ReviewRateCardDTO | any) => {
    if (isNew) {
      await handleOnCreateRateCard(rateCard);
    } else {
      await handleOnUpdateRateCard(rateCard);
    }
  };

  const handleOnCreateRateCard = async (rateCard: ReviewRateCardDTO) => {
    const res = await createRateCard(
      convertDataToSubmit(rateCard) as CreateRateCardDTO,
    );
    if (res?.id) {
      reset(res);
      navigate(
        generatePath(PageRoutes.reviewsRateCardDetail, {
          rateCardId: res?.id,
        }),
        {
          replace: true,
        },
      );
    }
  };

  const handleOnUpdateRateCard = async (rateCard: ReviewRateCardDTO) => {
    const dataSubmit = {
      ...convertDataToSubmit(rateCard),
      isModifiedRateCardData: isDirtyRateCardData,
    } as UpdateRateCardDTO;

    const res = await updateRateCard(dataSubmit);
    if (res) {
      reset(res);
    }
  };

  const handleOnUpdateRateCardStatus = async (status: RateCardStatus) => {
    if (!rateCardId) return;
    setIsChangingStatus(status);
    const res = await updateRateCardStatus(rateCardId, status);
    setIsChangingStatus(null);
    if (res) {
      reset({ ...getValues(), status: res || status });
    }
  };

  const handleOnSelectFeeSchedule = async (
    feeScheduleId: any,
    isSubProduct?: boolean,
  ) => {
    if (!feeScheduleId) return;

    if (isDirtyRateCardData || (!isDirtyRateCardData && !isNew)) {
      setShowOverrideDialog({
        feeScheduleId: feeScheduleId,
        isSubProduct: isSubProduct,
      });
      return;
    }
    handleOnOverrideFeeSchedule(feeScheduleId, isSubProduct);
  };

  const handleOnOverrideFeeSchedule = async (
    feeScheduleId: string,
    isSubProduct?: boolean,
  ) => {
    let response;

    setIsOverriding(true);
    if (isSubProduct) {
      response = await loadSubProduct(productId, feeScheduleId);
    } else {
      response = await loadRateCard(feeScheduleId);
    }
    setIsOverriding(false);
    setShowOverrideDialog(undefined);

    if (response) {
      const newRateCate = buildRateCate(response, getValues(), isSubProduct);
      setValue("standardFeeSections", newRateCate?.standardFeeSections, {
        shouldDirty: true,
      });
      setValue("otherFeeSections", newRateCate?.otherFeeSections, {
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    loadRateCardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, rateCardId]);

  useEffect(() => {
    if (!productId) return;
    loadFeeSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  if (rateCardUI.isLoading) return <RateCardSkeleton />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <FormProvider {...formControls}>
        <form onSubmit={handleSubmit(handleSubmitRateCard)}>
          <SHStack sx={{ paddingY: 3 }}>
            <TopBar
              navigate={{ to: `${PageRoutes.reviews}/rate-card` }}
              title={
                <>
                  <SHTypography
                    style={{ marginLeft: 8, lineHeight: "120%" }}
                    variant="h1"
                  >
                    {generateRateCardFormTitle(watch("name"))}
                  </SHTypography>
                  <SHTypography
                    style={{
                      marginLeft: 8,
                      color: palette.secondary.main,
                      paddingTop: 0,
                      lineHeight: "120%",
                    }}
                    variant="h2"
                  >
                    {`by ${isNew ? user?.name : rateCard?.ownerName}`}
                  </SHTypography>
                </>
              }
              customBadge={
                !isNew && (
                  <StatusBadge status={getValues("status") as RateCardStatus} />
                )
              }
              tools={
                <>
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    disabled={isSubmitting}
                    onClick={() => navigate(`${PageRoutes.reviews}/rate-card`)}
                  >
                    Cancel
                  </SHButton>

                  {isEditMode && (
                    <>
                      {!isNew && currentStatus !== RateCardStatus.Archived && (
                        <SHButton
                          variant="outlined"
                          size="extraMedium"
                          isLoading={
                            isChangingStatus === RateCardStatus.Archived
                          }
                          disabled={!isValid || isSubmitting}
                          onClick={() =>
                            handleOnUpdateRateCardStatus(
                              RateCardStatus.Archived,
                            )
                          }
                        >
                          Archive
                        </SHButton>
                      )}
                      {!isNew && currentStatus === RateCardStatus.Archived && (
                        <SHButton
                          variant="outlined"
                          size="extraMedium"
                          isLoading={isChangingStatus === RateCardStatus.Active}
                          disabled={!isValid || isSubmitting}
                          onClick={() =>
                            handleOnUpdateRateCardStatus(RateCardStatus.Active)
                          }
                        >
                          Unarchive
                        </SHButton>
                      )}

                      <SHButton
                        variant="contained"
                        size="extraMedium"
                        type="submit"
                        disabled={
                          !isValid || isSubmitting || !isDirty || !isValidName
                        }
                        isLoading={isSubmitting}
                      >
                        Save
                      </SHButton>
                    </>
                  )}
                </>
              }
            />
            <FieldGroup
              title="Rate card settings"
              maxWidth="655px"
              subTitle={
                <SHStack
                  sx={{
                    ">p": {
                      fontSize: "13px",
                      fontWeight: 400,
                    },
                    rowGap: "20px",
                  }}
                >
                  <SHTypography>
                    You can use rate cards to show alternative fee arrangements
                    during your platform fee analysis. Rate cards must be
                    selected during the Suitability Review setup stage to be
                    made visible in the fee analysis.
                  </SHTypography>
                  <SHTypography>
                    Rate cards you create will be private to your firm and will
                    not be visible to other advice firms or platform providers.
                    Licensees on SuitabilityHub can share rate cards with linked
                    advice firms. You can include these rate cards in your
                    Suitability Reviews, but not edit them.
                  </SHTypography>

                  {!isNew &&
                    rateCard?.lastModifiedDate &&
                    rateCard?.createdDate && (
                      <SHTypography
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        Last edit: {rateCard?.lastUpdatedUserName} on
                        {format(
                          new Date(rateCard?.lastModifiedDate!),
                          ` ${DateTimeFormat}`,
                        )}
                        <span
                          style={{
                            padding: "0 15px",
                            color: palette.secondary[100],
                          }}
                        >
                          |
                        </span>
                        Created:
                        {format(
                          new Date(rateCard?.createdDate!),
                          ` ${DateTimeFormat}`,
                        )}
                      </SHTypography>
                    )}
                </SHStack>
              }
            />
            <SHDivider />
            <FieldGroup
              title="Rate card details"
              subTitle="Provide details to help identify this rate card in the future"
            >
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <SHTextField
                    {...field}
                    hasCounter
                    maxLength={50}
                    label="Name of rate card"
                    required
                    disabled={isSubmitting || !isEditMode}
                    error={!!errors.name}
                    sx={{
                      width: { xs: "100%", md: 520, marginBottom: "30px" },
                    }}
                    helperText={
                      errors?.name?.message ??
                      (verifyRateCardName === "error"
                        ? verifyRateCardNameErrorMessage
                        : null)
                    }
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      handleOnChecking(event.target.value);
                    }}
                    InputProps={{
                      sx: {
                        "&>input": {
                          pr: "32px !important",
                        },
                      },
                      endAdornment: (
                        <SHStack
                          sx={{
                            position: "absolute",
                            right: "8px",
                          }}
                        >
                          {renderVerifyRateCardName()}
                        </SHStack>
                      ),
                    }}
                  />
                )}
              />

              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <SHRichTextEditor
                    maxLength={3000}
                    label="Notes"
                    placeHolder="Enter notes for this rate card"
                    disabled={!isEditMode}
                    sx={{ width: { xs: "100%", md: 520 } }}
                    readOnly={isSubmitting}
                    {...field}
                  />
                )}
              />
            </FieldGroup>
            <SHDivider />
            <FieldGroup
              title="Set availability"
              subTitle="Specify if this rate card is available for selection in the platform fee analysis. This setting only impacts your account"
            >
              <Controller
                name="isAvailableForAnalysis"
                control={control}
                render={({ field: { ref, ...other } }) => (
                  <SHBooleanField
                    {...other}
                    sx={{ width: { xs: "100%", md: 520 } }}
                    isSwitch
                    isShowClearButton={false}
                    label="Do you want this rate card to be available for inclusion in your fee analysis?"
                    yesLabel="Available"
                    noLabel="Excluded"
                    width="auto"
                    disabled={isSubmitting || !isEditMode}
                  />
                )}
              />
            </FieldGroup>
            <SHDivider />
            <SHDivider />
            <FieldGroup
              title="Link rate card to a platform"
              subTitle="Rate cards must be assigned to a platform"
              required
            >
              <SHStack
                spacing={3}
                direction="column"
                sx={{ width: { xs: "100%", md: 520 } }}
              >
                <Controller
                  name="productId"
                  control={control}
                  render={({ field: { ref, ...other } }) => (
                    <SelectProducts
                      {...other}
                      apiPath={APIExtRoutes.platformAnalysisPublishedProducts}
                      disabled={isSubmitting || !isEditMode}
                      multiple={false}
                      maxLength={1}
                      textFieldProps={{
                        label: "Select one from below",
                        placeholder: "Search for platform",
                        required: true,
                        error: !!errors?.productId,
                        helperText: errors?.productId?.message,
                      }}
                      onChange={(value) => {
                        other.onChange(value);
                        setSelectedFeeSchedule(undefined);
                      }}
                    />
                  )}
                />
              </SHStack>
            </FieldGroup>
            <SHDivider />

            {productId && (
              <>
                <FieldGroup
                  title="Load an existing fee schedule"
                  subTitle="You can either build your rate card from scratch, or you can load the fee schedule of an existing product or a rate card that is linked to the platform you chose"
                >
                  <SHAutocomplete
                    disableCloseOnSelect
                    value={selectedFeeSchedule ?? null}
                    disabled={isSubmitting || !isEditMode}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    getOptionLabel={(option: ExistingFeeScheduleDTO) =>
                      option?.name ?? ""
                    }
                    textFieldProps={{
                      InputLabelProps: { shrink: true },
                      placeholder: selectedFeeSchedule
                        ? undefined
                        : "Search for a product or rate card",
                      label: "Select one from below",
                    }}
                    onChange={(e, newValue, r, details) => {
                      setSelectedFeeSchedule(
                        newValue as ExistingFeeScheduleDTO,
                      );
                      handleOnSelectFeeSchedule(
                        newValue?.id,
                        newValue?.isSubProduct,
                      );
                    }}
                    options={feeSchedules ?? []}
                    renderOption={(props, option, { selected }) => {
                      const isChecked = selected;
                      return (
                        <li
                          {...props}
                          key={option.id}
                          style={{ padding: "1px 10px" }}
                        >
                          <SHStack
                            alignItems={"center"}
                            direction="row"
                            spacing={1}
                          >
                            <SHRadio checked={isChecked} />
                            <SHTypography variant="body3" fontWeight={500}>
                              {option.name}
                            </SHTypography>
                          </SHStack>
                        </li>
                      );
                    }}
                    sx={{ width: { xs: "100%", md: 520 } }}
                  />
                </FieldGroup>
                <SHDivider />
              </>
            )}

            <FieldGroup
              title="Build your rate card"
              subTitle={
                <SHStack
                  sx={{
                    ">p": {
                      fontSize: "13px",
                      fontWeight: 400,
                      textAlign: "justify",
                    },
                    rowGap: "20px",
                  }}
                >
                  <SHTypography>
                    Create your rate card here. The more comprehensive the
                    information you enter, the better the fee calculator will
                    function - hence why you may wish to load an existing fee
                    schedule (see the step above).
                  </SHTypography>
                  <SHTypography>
                    Any fields you leave blank will take the default values
                    assigned to our calculator which is often, but not always,
                    zero.
                  </SHTypography>
                  <SHTypography>
                    Careful: If you change the linked platform or load from a
                    fee schedule, it can cause you to lose any data already
                    entered into this table
                  </SHTypography>
                </SHStack>
              }
            >
              <SHStack
                sx={{ rowGap: "25px", width: { md: "700px", xs: "100%" } }}
              >
                {/* Standard Fees Table */}
                {watch("standardFeeSections")?.map((section, index) => (
                  <FREditFeesTable
                    key={section.id}
                    section={section}
                    sectionIndex={index}
                    isDisabled={isSubmitting || !isEditMode}
                    isRateCard
                    isEditSchedule={() => setSelectedFeeSchedule(undefined)}
                  />
                ))}
                {/* Other Fees Table */}
                {!isNil(watch("otherFeeSections")) && (
                  <>
                    <SHTypography
                      variant={"subtitle2"}
                      textAlign="left"
                      lineHeight={"160%"}
                    >
                      Other product fees and information
                    </SHTypography>
                    {watch("otherFeeSections")?.map((section, index) => (
                      <FREditFeesTable
                        key={section.id}
                        section={section}
                        sectionIndex={index}
                        isDisabled={isSubmitting || !isEditMode}
                        isOtherFee
                        isRateCard
                        isEditSchedule={() => setSelectedFeeSchedule(undefined)}
                      />
                    ))}
                  </>
                )}
              </SHStack>
            </FieldGroup>
          </SHStack>
          <UnsavedDialog isDirty={isDirty && !isSubmitting} />
          {showOverrideDialog && (
            <OverwriteFeeScheduleDialog
              onClose={() => setShowOverrideDialog(undefined)}
              onSubmit={() =>
                handleOnOverrideFeeSchedule(
                  showOverrideDialog?.feeScheduleId,
                  showOverrideDialog?.isSubProduct,
                )
              }
              isSubmitting={isOverriding}
            />
          )}
        </form>
      </FormProvider>
    </SHContainer>
  );
};
