import { APIExtRoutes } from "@constants/routes";
import {
  ConfigurationDraftStatusDTO,
  ConfigurationGroup,
} from "@models/configuration";
import { APIResponse } from "@models/core";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { CancelToken } from "axios";
import { generatePath } from "react-router";
import { compileConfiguration, compileConfigurationGroups } from "./util";

export async function getConfigGroups(): Promise<
  APIResponse<ConfigurationGroup[]>
> {
  const response = await APIGatewayService.get<
    ConfigurationGroup[] | undefined
  >(`${APIExtRoutes.configurationGroups}`);
  if (response.data) {
    response.data = compileConfigurationGroups(response?.data);
  }
  return handleErrorProxy(response);
}

export async function getConfigGroup(
  groupId: string,
): Promise<APIResponse<ConfigurationGroup>> {
  const response = await APIGatewayService.get<ConfigurationGroup>(
    generatePath(APIExtRoutes.configurationGroup, { groupId }),
  );
  if (response?.data) {
    compileConfiguration(response.data);
  }
  return handleErrorProxy(response);
}

export async function getConfigDraftStatus(
  cancelToken?: CancelToken,
): Promise<APIResponse<ConfigurationDraftStatusDTO>> {
  const response = await APIGatewayService.get<ConfigurationDraftStatusDTO>(
    APIExtRoutes.configurationDraftStatus,
    {
      cancelToken,
    },
  );
  return handleErrorProxy(response);
}
export async function discardDraft(
  sectionId?: string,
  featureId?: string,
): Promise<APIResponse> {
  const response = await APIGatewayService.patch(
    APIExtRoutes.configurationDraftDiscard,
    {
      sectionId,
      featureId,
    },
  );
  return handleErrorProxy(
    response,
    `${
      sectionId ? "Section" : featureId ? "Feature" : "Configuration"
    } discarded successfully`,
  );
}

export async function publishChanges(): Promise<APIResponse> {
  const response = await APIGatewayService.patch(
    APIExtRoutes.configurationPublishChanges,
  );
  return handleErrorProxy(response, "Changes are published.");
}
