import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const PracticeUserSubscriptionSkeleton = () => {
  return (
    <SHStack spacing={3}>
      <SHStack flexDirection={"row"} justifyContent={"space-between"}>
        <SHStack spacing={3} width={"60%"}>
          <SHStack spacing={1} width={"100%"} alignItems={"center"}>
            <SHSkeleton height={30} width={"100%"} />
            <SHSkeleton height={150} width={"100%"} />
            <SHSkeleton
              height={50}
              width={"35%"}
              sx={{ borderRadius: "50px" }}
            />
          </SHStack>
          <SHStack spacing={1} width={"100%"} alignItems={"center"}>
            <SHSkeleton height={30} width={"100%"} />
            <SHSkeleton height={250} width={"100%"} />
            <SHSkeleton
              height={50}
              width={"35%"}
              sx={{ borderRadius: "50px" }}
            />
          </SHStack>
        </SHStack>
        <SHSkeleton width={"35%"} height={610} />
      </SHStack>
      <SHStack spacing={1}>
        <SHSkeleton height={18} width={"35%"} />
        <SHSkeleton height={30} width={"75%"} />
      </SHStack>
    </SHStack>
  );
};
