import { TableRow, TableRowProps } from "@mui/material";
import { forwardRef } from "react";

export interface SHTableRowProps extends TableRowProps {
  transparent?: boolean;
}
export const SHTableRow = forwardRef(
  ({ transparent, sx, ...others }: SHTableRowProps, ref: any): JSX.Element => (
    <TableRow
      sx={{
        backgroundColor: transparent ? "transparent !important" : undefined,
        ...sx,
      }}
      {...others}
      ref={ref}
    />
  ),
);
