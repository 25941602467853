import mainBgImage from "@assets/images/background/main.svg";
import { AnalysisNavBars } from "@components/analysis-nav-bar";
import {
  SHContainer,
  SHStack,
  SHTab,
  SHTabs,
} from "@components/design-systems";
import { TopBarPlacement } from "@layouts/top-bar";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";

export const ManagedAccountLayout = (): JSX.Element => {
  return (
    <SHStack
      sx={{
        backgroundAttachment: "fixed",
        width: "100vw",
        height: "100vh",
        overflow: "auto",
        backgroundImage: `url(${mainBgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      direction="column"
    >
      <AnalysisNavBars />
      <Outlet />
    </SHStack>
  );
};

export const ManagedAccountListViewLayout = (): JSX.Element => {
  const tabs: SHTab[] = useMemo(
    () => [
      {
        label: "Analysis",
        linkTo: "",
      },
      {
        label: "SMA List",
        linkTo: "sma-list",
      },
    ],
    [],
  );

  return (
    <SHContainer sx={{ px: { xs: "16px", lg2: 0 } }}>
      <SHStack spacing={2} paddingY={3}>
        <TopBarPlacement />
        <SHTabs tabs={tabs} />
        <Outlet />
      </SHStack>
    </SHContainer>
  );
};
