import mainBgImage from "@assets/images/background/main.svg";
import { CloseButton } from "@components/buttons/close";
import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { FooterSVG } from "@components/svgs";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { theme } from "@themes";

interface PrivacyPolicyDialogProps {
  onClose?: () => void;
}

export const PrivacyPolicyDialog = ({
  onClose,
}: PrivacyPolicyDialogProps): JSX.Element => {
  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          padding: 0,
          overflow: "hidden",
          backgroundImage: `url(${mainBgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: { xs: "100%", md: 1350 },
          border: 0,
        },
      }}
    >
      <DialogTitle
        borderBottom={`1px solid ${theme.palette.common.black}`}
        display={"flex"}
        height={95}
        padding={"0px !important"}
        alignItems="stretch"
        justifyContent="space-between"
        flexDirection="row"
      >
        <SHTypography
          variant="h2"
          fontWeight={600}
          fontSize={20}
          padding={"35px 70px !important"}
          borderRight={`1px solid ${theme.palette.common.black}`}
        >
          PRIVACY POLICY
        </SHTypography>
        <SHStack padding={"44px 84px"}>
          <CloseButton width="8.75px" height="8.75px" onClick={onClose} />
        </SHStack>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px !important" }}>
        <SHStack spacing={2} textAlign={"justify"} marginY={5.5} marginX={9}>
          <SHTypography variant="body2">
            <b>PURPOSE OF THIS PRIVACY POLICY</b> <br />
            This Privacy Policy (Policy) sets out how and why Empower Due
            Diligence Pty Ltd trading as SuitabilityHub ACN 650 236 561
            (“SuitabilityHub”, “we” or “us”) collects, stores, uses and
            discloses your personal information, and how you may access your
            personal information held by us.
            <br />
            If you are accessing our services, website or platform on behalf of
            another person or legal entity, you represent and acknowledge that
            you have the authority to bind that person or legal entity and its
            affiliates, in which case references to “you” and “your” in this
            Policy shall also be a reference to such person, entity and its
            affiliates.
          </SHTypography>
          <SHTypography variant="body2">
            If you are accessing our services, website or platform on behalf of
            another person or legal entity, you represent and acknowledge that
            you have the authority to bind that person or legal entity and its
            affiliates, in which case references to “you” and “your” in this
            Policy shall also be a reference to such person, entity and its
            affiliates.
          </SHTypography>
          <SHTypography variant="body2">
            We collect a variety of information from visitors to our website and
            users of our platform, some of which may be confidential. This
            Policy, among other things, explains the types of information we
            collect and what we do with that information. By visiting our
            website, using our platform or any of our other services or
            otherwise providing us with your personal information (or
            authorising that it be provided to us by a third party), you agree
            to your personal information being handled in accordance with this
            Policy.
          </SHTypography>
          <SHTypography variant="body2">
            We may revise this Policy from time to time so please review it
            periodically for changes. Your continued use of our website,
            platform or services, any request for our assistance or your
            provision of further personal information to us (directly or via an
            authorised third party) after this Policy has been revised will
            constitute your acceptance of the revised Policy.
          </SHTypography>
          <SHTypography variant="body2">
            <b>WHY WE COLLECT PERSONAL INFORMATION</b> <br /> We collect
            personal information wbhere it is reasonably necessary for a
            business purpose, including in order to supply our services and to
            or help us improve our services, or to comply with our legal
            obligations. Personal information includes information or opinions
            you, or from which you can reasonably be identified, such as your
            name, address, date of birth, employment details, qualifications and
            contact details.
          </SHTypography>
          <SHTypography variant="body2">
            By disclosing personal information to us, you warrant that:
            <ul style={{ margin: 0 }}>
              <li>
                you have obtained any necessary consent to make such disclosure;
              </li>
              <li>
                such disclosure does not breach Australian privacy legislation
                (as amended from time to time);
              </li>
              <li>
                such disclosure does not breach of any obligation of
                confidentiality to which you are subject.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            We will not be liable for your breach of Australian privacy
            legislation or any confidentiality obligations to which you are
            subject.
          </SHTypography>
          <SHTypography variant="body2">
            <b>THE KINDS OF INFORMATION WE COLLECT</b>
            <br />
            Personal and sensitive information <br /> We collect personal
            information that is necessary to assist us in providing our website,
            platform and services, and so that you may receive the benefit of
            those things. We collect personal information from and about
            financial product providers, financial advisers (including any of
            their clients) and other financial service providers, including but
            not limited to names, phone numbers, addresses, email addresses,
            ages, and genders.
          </SHTypography>
          <SHTypography variant="body2">
            We collect personal information in a number of ways, including:
            <ul style={{ margin: 0 }}>
              <li>
                User-provided information: When you engage with our services,
                you may provide, and we may collect, personal information.
                Examples of such engagement include when you sign up for a
                subscription to our platform, when you contact us for
                service-related requests, and when you complete our surveys.
              </li>
              <li>
                Automatically collected information: When you use our services,
                we may automatically record certain information from your device
                (such as your computer, mobile phone or tablet) by using various
                types of technology, including cookies, pixels and web beacons.
                This information may include (but may not be limited to) the IP
                address or other device address or ID, information about your
                web browser and/or type of device, the web pages visited just
                before or just after using our services, the webpages or other
                content viewed or interacted with, and the dates and times of
                your visit, access, or use of services. We may also use these
                technologies to collect information regarding your individual
                interactions with our services (for example, to confirm whether
                an email is opened or forwarded), and to collect data on how
                successful our advertising is and the performance of our
                advertising partners. We gather these kinds of information from
                all individuals who interact with our website, platform and
                services.
              </li>
              <li>
                Information from other sources: We may obtain personal
                information from third parties and other publicly available
                sources, such as our partners, regulators and advertisers. If we
                combine or associate information from other sources with
                personal information directly collected through our services, we
                will treat the combined information as personal information in
                accordance with this Policy.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            We do not generally collect sensitive information, however the
            personal information we receive from and/or about you may include
            some ‘sensitive information’ (being information or opinions about
            your health, political affiliations, sexual preferences, religious
            beliefs, racial or ethnic origin, memberships in trade or
            professional associations or trade unions, criminal record and
            biometrics). If we do collect sensitive information, we do so only
            where it is necessary for the purpose for which it is being
            collected and with your consent.
          </SHTypography>
          <SHTypography variant="body2">
            You may refuse to provide personal information to us, however doing
            so will limit your ability to engage with our website, platform and
            services.
          </SHTypography>
          <SHTypography variant="body2">
            General information <br /> We also collect general information that
            is not personal to you. This type of information generally relates
            to your behaviour on our website and/or platform, and includes but
            is not limited to the type of browser you use, your geographic
            location, and how you came to visit the website and/or platform. It
            also includes information that will help us troubleshoot problems,
            analyse our resources and improve our services.
          </SHTypography>
          <SHTypography variant="body2">
            <b>UNSOLICITED INFORMATION </b> <br />
            If we receive personal information that we have not requested
            (unsolicited personal information), within a reasonable period after
            receiving it we will determine whether or not we could have
            collected it if we had sought it. If so, we will treat it in
            accordance with this Policy. If we could not have collected it, if
            it is lawful and reasonable to do so we will, as soon as
            practicable, destroy it or ensure that it is de-identified.
          </SHTypography>
          <SHTypography variant="body2">
            <b>HOW WE USE YOUR PERSONAL INFORMATION</b>
            <br />
            Subject to this Policy, we may use your personal information:
            <ul style={{ margin: 0 }}>
              <li>to verify your identity and credentials;</li>
              <li>to establish, maintain and administer your account;</li>
              <li>
                to tailor the content and information that is shown to you on
                our website and platform;
              </li>
              <li>
                to contact you, including to send you service or promotional
                communications, and offers to participate in our market research
                surveys;
              </li>
              <li>
                to create and/or distribute advertising or marketing material
                that is relevant to you;
              </li>
              <li>
                to aggregate with other users or members for the purposes of
                industry reports or information services;
              </li>
              <li>to otherwise provide you with our services; and</li>
              <li>
                for any other uses identified at the time of collecting your
                personal information.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            From time to time we may also use your personal information to
            identify particular services that we offer that we believe may be of
            interest to you, and contact you to discuss those services and how
            they may benefit you. This kind of direct marketing will generally
            (but not always) take the form of an email. We will always give you
            a choice to opt out of receiving further marketing information.
          </SHTypography>
          <SHTypography variant="body2">
            You consent to us using your personal information in any manner
            reasonably contemplated by this Policy, and the terms and conditions
            of our website, platform and services generally. Where relevant, you
            also consent to us using any photographs, personal information and
            other information you upload to our website, platform or social
            media pages, otherwise provide to us, or that we lawfully collect in
            some other way, for our business purposes and activities.
          </SHTypography>
          <SHTypography variant="body2">
            <b>WHEN WE MIGHT DISCLOSE YOUR PERSONAL INFORMATION</b>
            <br />
            Any personal information collected by us may also be disclosed, if
            appropriate, to other entities in order to facilitate the purpose
            for which the information was collected. Such entities may include:
            <ul style={{ margin: 0 }}>
              <li>
                third party ‘partners’ with whom we have a commercial
                relationship, in order to provide you with the benefit of our
                services or to make available products or opportunities provided
                by such third parties that are considered relevant or likely to
                be of interest to you;
              </li>
              <li>
                third party service providers for the purpose of enabling them
                to provide a service to us or on our behalf such as (but not
                limited to) IT service providers, data storage providers,
                web-hosting and server providers and debt collectors;
              </li>
              <li>any related entity of SuitabilityHub;</li>
              <li>
                any applicable or relevant regulator or third party for the
                purpose of legislative or contractual compliance and/or
                reporting; or
              </li>
              <li>other entities where you have given your express consent.</li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            In addition, if at any time we are required to take payment from
            you, some of your personal information may be provided to a
            third-party payment processor for the completion of that
            transaction.
          </SHTypography>
          <SHTypography variant="body2">
            From time to time, these parties may reside outside Australia. Our
            contracts with these parties generally include an obligation for
            them to comply with Australian privacy legislation and this Policy.
            However, by agreeing to the disclosure of your personal information
            to these entities outside of Australia, you acknowledge that we will
            no longer be required to take reasonable steps to ensure the
            recipient’s compliance with Australian privacy legislation in
            relation to your personal information and we will not be liable for
            any breach of Australian privacy legislation by the recipient.
          </SHTypography>
          <SHTypography variant="body2">
            <b>PERSONAL INFORMATION ABOUT EMPLOYEES OR JOB APPLICANTS</b>
            <br />
            We will collect personal information from you if you apply for a job
            with and/or become employed by us. In these circumstances:
            <ul style={{ margin: 0 }}>
              <li>
                You authorise us to collect any personal information (whether
                written or verbal) from any referee or previous employer
                specified in your application for employment or curriculum vitae
                for our evaluation of your application, and to hold such
                information on your personal file for future evaluation of your
                employment; and
              </li>
              <li>
                You acknowledge that your personal information is collected for
                the purpose of evaluating your application for employment by us
                and, should you accept employment with us, the assessment and
                administration of your continued employment.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            A failure by you to provide requested personal information in these
            circumstances will limit our ability to give your application proper
            consideration.
          </SHTypography>
          <SHTypography variant="body2">
            <b>DEALING WITH US ANONYMOUSLY OR BY PSEUDONYM</b>
            <br />
            Our provision of services to you (including the platform) will not
            generally be possible without your provision of relevant personal
            information. However, where it is lawful and practicable to do so,
            you may deal with us anonymously or by using a pseudonym.
          </SHTypography>
          <SHTypography variant="body2">
            <b>LINKS TO THIRD PARTY WEBSITES</b> <br />
            Our website may contain links to the websites of third parties. If
            you click on those links, you will be transferred to a third party
            website. We have no control over, and are not responsible for, the
            privacy practices of those third parties.
          </SHTypography>
          <SHTypography variant="body2">
            <b>ACCESSING YOUR PERSONAL INFORMATION</b>
            <br />
            You may request to access personal information we hold about you at
            any time. Such request may be refused on certain grounds including,
            for example, because such access would be unlawful or have an
            unreasonable impact upon the privacy of other individuals, or
            because your request is frivolous or vexatious. Where your request
            to access the personal information we hold about you is refused, we
            will provide you with written reasons for doing so.
          </SHTypography>
          <SHTypography variant="body2">
            We do not generally charge for requests to access personal
            information, however where your enquiry is complex or resource
            intensive, a handling fee may apply. We will advise you of any fee
            that becomes payable before processing your request.
          </SHTypography>
          <SHTypography variant="body2">
            We take reasonable steps to ensure that the personal information we
            hold about you is accurate, complete and up-to-date. However, we
            also rely on you to advise us of any changes to your personal
            information. Please let us know as soon as possible where there are
            any changes to your personal information, or if you believe the
            personal information we hold about you is not accurate, complete or
            up-to-date, so that we can update our records accordingly. You can
            update your profile information on our platform, including your
            contact details, via the platform at any time.
          </SHTypography>
          <SHTypography variant="body2">
            If you wish to access the personal information we hold about you or
            request a correction of it, our Privacy Officer can be contacted via
            the details set out below.
          </SHTypography>
          <SHTypography variant="body2">
            <b>STORAGE AND SECURITY</b>
            <br />
            The information we hold about you is stored in a secure electronic
            third-party database.
          </SHTypography>
          <SHTypography variant="body2">
            We will use reasonable endeavours to protect and maintain the
            security of your personal information and to make our website and
            platform secure against misuse, interference, loss and unauthorised
            access. However, despite our reasonable endeavours, we are unable to
            ensure or warrant the security of any information held by us. All
            personal information disclosed by you is at your own risk.
          </SHTypography>
          <SHTypography variant="body2">
            When your personal information is no longer needed for any purpose
            for which it was collected, used or disclosed, it will be destroyed
            or permanently de-identified, subject to our usual document
            retention and disposal practice.
          </SHTypography>
          <SHTypography variant="body2">
            <b>CHANGES TO THIS POLICY</b>
            <br />
            We may change this Policy from time to time, as required. The
            current version of this Policy will be available on our website at
            all times. This Policy was last updated in August 2022.
          </SHTypography>
          <SHTypography variant="body2">
            <b>ENQUIRIES AND COMPLAINTS</b>
            <br />
            If you believe that we have breached your privacy rights in any way
            or you would like to discuss any part of this Policy, please contact
            our Privacy Officer via the details set out below.
          </SHTypography>
          <SHTypography variant="body2">
            If you wish to make a complaint about a breach of this Policy or
            Australian privacy legislation, you can contact us via the details
            set out below. You will need to provide us with sufficient detail of
            your complaint as well as any supporting evidence. Our Privacy
            Officer will investigate your complaint and determine the steps, if
            any, that we will undertake to resolve your complaint. We will
            contact you if we require any additional information about your
            complaint, and will notify you in writing of the outcome of the
            investigation. If you are not satisfied with our determination, you
            can contact us via the details set out below to discuss your
            concerns or contact the Australian Privacy Commissioner.
          </SHTypography>
          <SHTypography variant="body2">
            <b>CONTACT US</b>
            <br />
            Please contact us via the details set out below if you want to:
            <ul style={{ margin: 0 }}>
              <li>access your personal information held by us;</li>
              <li>correct or update your personal information held by us;</li>
              <li>
                obtain further information about the way we manage your personal
                information;
              </li>
              <li>
                raise a concern or make a complaint regarding how we collect or
                handle your personal information;
              </li>
              <li>unsubscribe from our mailing list; or</li>
              <li>
                ask a question about an unsolicited electronic communication
                that you have received.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            Please contact us immediately via the details set out below if you
            become aware of any unauthorised use of your account for the
            platform, or any other breach of security.
          </SHTypography>
          <SHTypography variant="body2">
            Contact Details
            <br />
            Attention: Privacy Officer, Empower Due Diligence Pty Ltd trading as
            SuitabilityHub (ACN 650 236 561)
            <br />
            Postal Address: Privacy Officer c/o SuitabilityHub, WeWork, Level 2,
            383 George Street, Sydney NSW 2000
            <br />
            Contact Email: Privacy@SuitabilityHub.com
            <br />
            Contact Phone: 0410 797 007
          </SHTypography>
          <SHTypography variant="body2">
            <b>FURTHER INFORMATION ON PRIVACY</b> <br />
            For general information regarding privacy in Australia, please visit
            the website of the Office of the Australian Information
            Commissioner.
          </SHTypography>
        </SHStack>
        <FooterSVG style={{ marginBottom: "-5px" }} />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          borderTop: `1px solid ${theme.palette.common.black}`,
          height: 120,
          margin: 0,
        }}
      >
        <SHButton
          color="primary"
          variant="contained"
          onClick={onClose}
          size="large"
        >
          Close
        </SHButton>
      </DialogActions>
    </SHDialog>
  );
};
