import {
  SHTable,
  SHTableBody,
  SHTableCell,
  SHTableCellProps,
  SHTableContainer,
  SHTableRow,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { FeePortfolioDetailDTO } from "@models/platform-analysis/entities/portfolioDetails";
import { FamilyMemberAccountDTO } from "@models/platform-analysis/entities/steps/fee";
import { useTheme } from "@mui/material";
import {
  displayCurrencyNumber,
  displayIntNumber,
  getPortfolioDetails,
} from "@pages/platform-analysis/util";
import { hexToRGBA } from "@utils";
import { chain, replace } from "lodash";

export interface FeePortfolioDetailsTableProps {
  data?: FeePortfolioDetailDTO | null;
  isShowHoldingsNumber?: boolean;
}
export const FeePortfolioDetailsTable = ({
  data,
  isShowHoldingsNumber,
}: FeePortfolioDetailsTableProps) => {
  const { transactionsOutside, transactionsWithin, investments } =
    getPortfolioDetails(data);
  
  const getStringFamilyMemberAccounts = (data: FamilyMemberAccountDTO[]) => {
    return chain(data)
    ?.map(data => `${replace(data.variableName ? data.variableName : "", '_', ' ')}: ${displayCurrencyNumber(data.balance)}`)
    ?.join(', ')
    ?.value();
  }

  return (
    <SHTableContainer>
      <SHTable>
        <SHTableBody>
        {
          data?.familyMembers?.map((familyMember) => {
            return <TableRow>
            <BorderedCell isFirstRow>{familyMember.name}</BorderedCell>
            <BorderedCell colSpan={6}>
              {getStringFamilyMemberAccounts(familyMember.familyMemberAccounts)}
            </BorderedCell>
          </TableRow>
          })
        }
          {/* {data?.idps?.length ? (
            <TableRow>
              <BorderedCell
                isFirstRow
              >{`Value of each IDPS account`}</BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.idps[0])}
              </BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.idps[1])}
              </BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.idps[2])}
              </BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.idps[3])}
              </BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.idps[4])}
              </BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.idps[5])}
              </BorderedCell>
            </TableRow>
          ) : null}
          {data?.super?.length ? (
            <TableRow>
              <BorderedCell isFirstRow>
                {`Value of each super/pension account`}
              </BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.super[0])}
              </BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.super[1])}
              </BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.super[2])}
              </BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.super[3])}
              </BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.super[4])}
              </BorderedCell>
              <BorderedCell>
                {displayCurrencyNumber(data?.super[5])}
              </BorderedCell>
            </TableRow>
          ) : null} */}
          {data?.totalPortfolioValue ? (
            <TableRow>
              <BorderedCell isFirstRow>{`Total portfolio value`}</BorderedCell>
              <BorderedCell colSpan={6}>
                {displayCurrencyNumber(data?.totalPortfolioValue)}
              </BorderedCell>
            </TableRow>
          ) : null}
          {investments.length ? (
            <TableRow>
              <BorderedCell isFirstRow>
                {`Investments and other holdings`}
              </BorderedCell>
              <BorderedCell colSpan={6}>{investments.join(", ")}</BorderedCell>
            </TableRow>
          ) : null}
          {isShowHoldingsNumber && data?.totalDifferentInvestments ? (
            <TableRow>
              <BorderedCell isFirstRow>
                {`Total number of different investments held`}
              </BorderedCell>
              <BorderedCell colSpan={6}>
                {displayIntNumber(data?.totalDifferentInvestments)}
              </BorderedCell>
            </TableRow>
          ) : null}
          {transactionsOutside.length ? (
            <TableRow>
              <BorderedCell isFirstRow>
                <span>
                  Transactions <u>outside</u> managed accounts (Next 12 months)
                </span>
              </BorderedCell>
              <BorderedCell colSpan={6}>
                {transactionsOutside.join(", ")}
              </BorderedCell>
            </TableRow>
          ) : null}
          {transactionsWithin.length ? (
            <TableRow>
              <BorderedCell isFirstRow>
                <span>
                  Transactions <u>within</u> managed accounts (Next 12 months)
                </span>
              </BorderedCell>
              <BorderedCell colSpan={6}>
                {transactionsWithin.join(", ")}
              </BorderedCell>
            </TableRow>
          ) : null}
        </SHTableBody>
      </SHTable>
    </SHTableContainer>
  );
};

const TableRow = (props: SHTableRowProps) => {
  const { palette } = useTheme();
  return (
    <SHTableRow
      {...props}
      sx={{
        borderBottomWidth: 1,
        backgroundColor: `${palette.common.white} !important`,
        ...props.sx,
      }}
    />
  );
};

interface BorderedCellProps extends SHTableCellProps {
  children: React.ReactNode;
  isFirstRow?: boolean;
}
const BorderedCell = ({
  children,
  isFirstRow,
  ...others
}: BorderedCellProps) => {
  const { palette } = useTheme();
  return (
    <SHTableCell
      sx={{
        height: "50px",
        borderLeft: `solid 1px ${hexToRGBA(palette.primary[50], 0.3)}`,
        backgroundColor: isFirstRow ? undefined : palette.secondary[50],
        width: isFirstRow ? "360px" : undefined,
      }}
      {...others}
    >
      <SHTypography
        variant={isFirstRow ? "subtitle2" : "body1"}
        colorVariant={isFirstRow ? "primary" : "third"}
        lineHeight={"120%"}
      >
        {children}
      </SHTypography>
    </SHTableCell>
  );
};
