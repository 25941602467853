// A4 to pixel
export const PAGE_DPI = 72;
export const PAGE_WIDTH = 595;
export const PAGE_HEIGHT = 841.5;

// Layout size
export const MARGIN_VERTICAL = 30;
export const MARGIN_HORIZONTAL = 40;

export const DEFAULT_GAP = 30;
export const DEFAULT_GAP_2 = 20;
export const DEFAULT_GAP_3 = 5;
export const DEFAULT_GAP_4 = 40;
export const DEFAULT_GAP_5 = 10;

export const PAGE_HEADER_HEIGHT = 113;
export const PAGE_FOOTER_HEIGHT = 24;
export const PAGE_BODY_HEIGHT = 607.5;
export const REVIEW_PAGE_HEADER_HEIGHT = 117;
export const REVIEW_PAGE_BODY_HEIGHT = 590.5;

export const FIRST_PAGE_HEADER_HEIGHT = 140;
export const FIRST_PAGE_FOOTER_HEIGHT = 220;
export const FIRST_PAGE_BODY_HEIGHT = 371.5;
export const REVIEW_FIRST_PAGE_HEADER_HEIGHT = 160;
export const REVIEW_FIRST_PAGE_FOOTER_HEIGHT = 220;
export const REVIEW_FIRST_PAGE_BODY_HEIGHT = 351.5;

export const TITLE_HEIGHT = 25;
export const SUB_TITLE_HEIGHT = 45; //TILE + SUB_TITLE
export const SUB_TITLE_WITHOUT_TITLE_HEIGHT = 20;

export const CONTENT_WIDTH = PAGE_WIDTH - MARGIN_HORIZONTAL * 2; //515
export const TABLE_WIDTH = PAGE_WIDTH - MARGIN_HORIZONTAL * 2; //515
export const TABLE_HEIGHT = PAGE_BODY_HEIGHT - TITLE_HEIGHT; //586.5

// Cell width
export const TABLE_CELL_WIDTH = 60.8;
export const TABLE_FIRST_CELL_WIDTH = 150;

// Row height
export const TABLE_ROW_HEADER_LINE = 66.5;
export const TABLE_ROW_SINGLE_LINE = 20;
export const TABLE_ROW_MULTIPLE_LINE = 30;
export const TABLE_ROW_TRIPLE_LINE = 50;
export const TABLE_ROW_QUADRUPLE_LINE = 80;

//Comment Content Height

export const COMMENT_SHORTLISTED_CELL_WIDTH = 0.221 * TABLE_WIDTH - 7;
export const APPENDIX_COMMENT_CELL_WIDTH = 0.19675 * TABLE_WIDTH - 7;
export const MINIMUM_COMMENT_CELL_HEIGHT = 55;
export const COMMENT_TABLE_HEADER_HEIGHT = 25;
export const APPENDIX_COMMENT_TABLE_HEADER_HEIGHT = 40;
export const MARGIN_DEFAULT = 15;

//Offset
export const TABLE_HEIGHT_OFFSET = 20;

//Line chart
export const MIN_HEIGHT_CHART = 250;
export const HEIGHT_LEGEND_CHART = 25;
export const DEVIATION_WEIGHT = 0.485555;
export const MAX_VALUE_FIT_CONTENT = 1000000;
