import { SHDialog, SHStack, SHTypography } from "@components/design-systems";
import { email } from "@constants/email";
import { Link } from "@mui/material";
import { theme } from "@themes";
import { tranferAuth0ErrorDescription } from "@utils/auth0/util";
import { Navigate, useSearchParams } from "react-router-dom";

interface ResetPasswordMessageDialogProps {
  open: boolean;
}
export const ResetPasswordMessageDialog = ({
  open,
}: ResetPasswordMessageDialogProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  if (searchParams.get("success") === "true")
    return <Navigate to="/" replace />;
  return (
    <SHDialog
      maxWidth="sm"
      width={560}
      open={open}
      header={
        <SHStack
          alignItems="center"
          textAlign={"center"}
          width={{ md: "50%", xs: "100%" }}
        >
          <SHTypography variant="h2">
            {tranferAuth0ErrorDescription(searchParams.get("message"))}
          </SHTypography>
        </SHStack>
      }
      body={
        <SHStack alignItems="center" spacing={3} textAlign={"center"}>
          <SHTypography variant="body2">
            To receive a new link, please reset your password again. Otherwise,
            please contact SuitabilityHub
            <Link
              variant="body2"
              color={theme.palette.text.secondary}
              component="a"
              underline="none"
              sx={{
                paddingLeft: 0.5,
                cursor: "pointer",
              }}
              href={`mailto:${email.support}`}
            >
              here
            </Link>
            .
          </SHTypography>
        </SHStack>
      }
    />
  );
};
