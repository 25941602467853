import { SHContainer } from "@components/design-systems/sh-container";
import { SHStack } from "@components/design-systems/sh-stack";
import { SHTypography } from "@components/design-systems/sh-typography";
import { CloseSVG } from "@components/svgs";
import { Slide, useTheme } from "@mui/material";
import {
  TopNotificationItem,
  TopNotificationType,
} from "@components/top-notifications/model";
import { hexToRGBA } from "@utils";
import { useEffect, useMemo } from "react";

interface TopNotificationProps {
  notificationItem: TopNotificationItem;
  onClose: () => void;
}

export const TopNotification = ({
  notificationItem,
  onClose,
}: TopNotificationProps) => {
  const { palette } = useTheme();
  const {
    title,
    message,
    type,
    showCloseButton,
    isAutoClose,
    isAdviserUserSubscription,
  } = notificationItem;

  const colors = useMemo(() => {
    switch (type) {
      case TopNotificationType.Success:
        return {
          title: `${hexToRGBA(palette.success.main, 1)}`,
          icon: isAdviserUserSubscription
            ? palette.success[200]
            : palette.success.main,
          message: isAdviserUserSubscription
            ? palette.success[200]
            : palette.success.main,
          background: palette.success[50],
          border: palette.success[100],
        };
      case TopNotificationType.Warning:
        return {
          title: isAdviserUserSubscription
            ? palette.warning.main
            : `${palette.warning[50]}`,
          message: isAdviserUserSubscription
            ? palette.warning[800]
            : palette.warning.main,
          icon: isAdviserUserSubscription
            ? palette.warning[800]
            : palette.warning.main,
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.warning.main,
            0.1,
          )}, ${hexToRGBA(palette.warning.main, 0.1)}),${palette.common.white}`,
          border: `${hexToRGBA(palette.warning.main, 0.2)}`,
        };
      case TopNotificationType.Error:
        return {
          title: isAdviserUserSubscription
            ? palette.error[400]
            : palette.error[200],
          message: isAdviserUserSubscription
            ? palette.error[500]
            : palette.error[200],
          icon: isAdviserUserSubscription
            ? palette.error[500]
            : palette.error[200],
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.error[200],
            0.25,
          )}, ${hexToRGBA(palette.error[200], 0.25)}),${palette.common.white}`,
          border: `${hexToRGBA(palette.error[200], 0.25)}`,
        };
      default:
        return {
          title: `${hexToRGBA(palette.secondary.main, 0.9)}`,
          message: `${hexToRGBA(palette.secondary.main, 0.9)}`,
          icon: palette.text.secondary,
          border: `${hexToRGBA(palette.secondary.main, 0.15)}`,
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.secondary.main,
            0.15,
          )}, ${hexToRGBA(palette.secondary.main, 0.15)}),${
            palette.common.white
          }`,
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (!isAutoClose) return;
    const timeOut = setTimeout(onClose, 5000);
    return () => clearTimeout(timeOut);
  });

  return (
    <Slide in={!!notificationItem}>
      <SHStack
        sx={{
          zIndex: 0,
          // gap: "8px",
          boxShadow: "none",
          borderWidth: "1px 0px",
          borderStyle: "solid",
          borderColor: colors.border,
          background: colors.background,
          height: isAdviserUserSubscription ? "76px" : "auto",
          justifyContent: "center",
        }}
      >
        <SHContainer
          sx={{
            padding: { xs: "0px 16px", lg: 0 },
          }}
          data-testid="sh-top-notification"
        >
          <SHStack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <SHStack
              direction="column"
              justifyContent={"center"}
              alignItems={"flex-start"}
              padding={"6px 0px"}
            >
              <SHTypography
                variant="h2"
                lineHeight={"120%"}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  fontStyle: "normal",
                  color: colors.title,
                }}
              >
                {title}
              </SHTypography>
              {typeof message === "string" ? (
                <SHTypography
                  variant="body2"
                  sx={{
                    color: colors.message,
                    fontSize: 13,
                  }}
                >
                  {message}
                </SHTypography>
              ) : (
                message
              )}
            </SHStack>
            {showCloseButton && (
              <SHStack>
                <CloseSVG
                  width={isAdviserUserSubscription ? 10 : 7}
                  height={isAdviserUserSubscription ? 10 : 7}
                  color={colors.icon}
                  onClick={onClose}
                  cursor="pointer"
                />
              </SHStack>
            )}
          </SHStack>
        </SHContainer>
      </SHStack>
    </Slide>
  );
};
