import { DeleteButton } from "@components/buttons/delete";
import { PlusButton } from "@components/buttons/plus";
import {
  SHBox,
  SHImage,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { EditSVG, PlusSVG } from "@components/svgs";
import { SelectedProductDTO } from "@models/platform-analysis/entities/steps/feature";
import { useTheme } from "@mui/material";
import { isEmpty } from "lodash";
import { MainProductBadge } from "../main-product-badge";

export interface FeeProductCardProps extends SHStackProps {
  product: SelectedProductDTO;
  isAdviserView?: boolean;
  isShowButtons?: boolean;
  onAddComment?: () => void;
  onDeleteComment?: () => void;
  onAddShortlist?: () => void;
}
export const FeeProductCard = ({
  product,
  isAdviserView,
  isShowButtons = true,
  onAddComment,
  onDeleteComment,
  onAddShortlist,
  ...others
}: FeeProductCardProps) => {
  const { palette } = useTheme();
  return (
    <SHStack
      spacing={"15px"}
      {...others}
      sx={{
        p: "15px",
        backgroundColor: palette.common.white,
        borderColor: palette.secondary[100],
        borderStyle: "solid",
        borderWidth: "1px",
        width: "100%",
        ...others.sx,
      }}
    >
      <SHStack direction={"row"} alignItems={"center"} spacing={"10px"}>
        <SHBox
          sx={{
            width: "33px",
            height: "33px",
            position: "relative",
          }}
        >
          <SHImage src={product.productLogo?.url} width={33} height={33} />
          {product.isProductMain && (
            <MainProductBadge
              sx={{
                position: "absolute",
                top: "-8px",
                right: "-10px",
              }}
            />
          )}
        </SHBox>

        <SHTypography
          variant="subtitle2"
          lineHeight={"120%"}
          sx={{
            wordBreak: "break-word",
          }}
        >
          {product.productName}
        </SHTypography>
      </SHStack>
      {isShowButtons && (
        <SHStack direction={"row"} alignItems={"center"} spacing={"5px"}>
          <PlusButton
            size="medium"
            startIcon={
              isEmpty(product.comment?.comment) ? (
                <PlusSVG color={palette.common.white} />
              ) : (
                <EditSVG color={palette.common.white} />
              )
            }
            onClick={onAddComment}
          >
            Comment
          </PlusButton>
          {isAdviserView && (
            <PlusButton
              size="medium"
              variant="outlined"
              startIcon={<PlusSVG color={palette.text.primary} />}
              onClick={onAddShortlist}
            >
              Shortlist
            </PlusButton>
          )}
          {!isEmpty(product.comment?.comment) && isAdviserView && (
            <DeleteButton
              height={32}
              width={32}
              backgroundColor={"transparent"}
              borderColor={palette.common.black}
              onClick={onDeleteComment}
            />
          )}
        </SHStack>
      )}
    </SHStack>
  );
};
