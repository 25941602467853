import { SHContainer } from "@components/design-systems";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import { APIExtRoutes } from "@constants";
import { useUserPermissions } from "@hooks/userUserPermission";
import { ProductDTO } from "@models/product/entities/product";
import PlatformProfileCardView from "@pages/platform-profiles/components/card-view";
import PlatformProfileListView from "@pages/platform-profiles/components/list-view";
import { nameOfFactory } from "@utils";

const nameOf = nameOfFactory<ProductDTO>();
export const PlatformProfilePage = (): JSX.Element => {
  const { isAdminGroup } = useUserPermissions();

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      {isAdminGroup ? (
        <PlatformProfileListView
          oDataUrl={APIExtRoutes.odataProducts}
          showTitle
          emptyMessage={"No product profiles."}
          defaultState={{
            ...DefaultDataState,
            sorting: [{ id: nameOf("productName"), desc: false }],
          }}
          searchBoxProps={{
            placeholder: "Search products",
          }}
        />
      ) : (
        <PlatformProfileCardView />
      )}
    </SHContainer>
  );
};
