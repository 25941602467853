import { SHFilterOption, SHStack } from "@components/design-systems";

import { RateCardSharedStatus, RateCardStatus } from "@models/rate-card/entities/rateCard";
import { FeatureTemplateStatus } from "@models/feature-template/enums/status";
import { PlatformAnalysisFilter } from "@models/platform-analysis/entities/steps/feature";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import { AnalysisStatus } from "@models/platform-analysis/enums/status";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";

export const PlatformAnalysisFilterOptions = [
  {
    label: "In progress",
    value: AnalysisStatus.InProgress,
  },
  {
    label: "Completed",
    value: AnalysisStatus.Completed,
  },
  {
    label: "Archived",
    value: AnalysisStatus.Archived,
  },
];

export const PlatformAnalysisProductsFilterOptions = [
  {
    label: "Common (Features available on each platform)",
    value: PlatformAnalysisFilter.Common,
  },
  {
    label: "Differentiators (Features not available on each platform)",
    value: PlatformAnalysisFilter.Differentiators,
  },
];

export const PlatformFeeAnalysisFilterOptions = [
  {
    label: "Not missing investment options",
    value: FeeMissingInvestmentsOption.NotMissing,
  },
  {
    label: "Missing investment options",
    value: FeeMissingInvestmentsOption.Missing,
  },
];

export const PlatformFeeAnalysisInvestmentMenuFilterOptions = [
  {
    label: "Full menu",
    value: FeeInvestmentMenuOption.FullMenu,
  },
  {
    label: "Limited menu",
    value: FeeInvestmentMenuOption.LimitedMenu,
  },
]

export const PlatformFeeAnalysisMissingInvestmentFilterOptions = [
  {
    label: "Hide those with missing investments",
    value: FeeMissingInvestmentsOption.NotMissing,
  },
]

export const RateCardFilterOptions: SHFilterOption[] = [
  {
    label: "Active",
    value: RateCardStatus.Active,
  },
  {
    label: "Archived",
    value: RateCardStatus.Archived,
  },
];

export const RateCardFilterYesNoOptions = [
  {
    label: "No",
    value: RateCardSharedStatus.No,
  },
  {
    label: "Internally",
    value: RateCardSharedStatus.Internally,
  },
  {
    label: "All practices",
    value: RateCardSharedStatus.AllPractices,
  },
];

export const FeeAnalysisFilterYesNoOptions = [
  {
    label: (
      <SHStack direction={"row"} spacing={"5px"}>
        <span>$</span>
        <span>Fees</span>
      </SHStack>
    ),
    value: FeesDisplayStyle.Dollar,
  },
  {
    label: (
      <SHStack direction={"row"} spacing={"5px"}>
        <span>%</span>
        <span>Fees</span>
      </SHStack>
    ),
    value: FeesDisplayStyle.Percentage,
  },
];

export const FeeAnalysisFilterMapOptions = [
  {
    label: "Detail",
    value: FeeHeatmapMode.Detail,
  },
  {
    label: "Projection",
    value: FeeHeatmapMode.Projection,
  },
];
export const FeatureTemplateFilterOptions = [
  {
    label: "Active",
    value: FeatureTemplateStatus.Active,
  },
  {
    label: "Archived",
    value: FeatureTemplateStatus.Archived,
  },
];
export const FeeReviewFilterMapOptions = [
  {
    label: "Detail",
    value: FeeHeatmapMode.Detail,
  },
  {
    label: "Projection",
    value: FeeHeatmapMode.Projection,
  },
];
