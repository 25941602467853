import { Components, Theme } from "@mui/material";
export const muiRadioTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiRadio"> => ({
  MuiRadio: {
    styleOverrides: {
      root: {
        color: theme.palette.text.disabled,
      },
    },
    variants: [
      {
        props: {
          size: "small",
        },
        style: {
          padding: "6px",
        },
      },
    ],
  },
});
