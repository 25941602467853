import { PlusButton } from "@components/buttons/plus";
import {
  SHChip,
  SHImage,
  SHStack,
  SHTableHead,
  SHTableHeadProps,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { EditSVG, PlusSVG } from "@components/svgs";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import {
  AnalysisCommentDTO,
  SelectedProductDTO,
} from "@models/platform-analysis/entities/steps/feature";
import {
  FeatureAnalysisTableCell,
  FeatureAnalysisTableCellProps,
} from "@pages/platform-analysis/_id/steps/feature/analysis/components/table-cell";
import { sortAscByOrder } from "@utils";
import { isEmpty } from "lodash";

interface PlatformAnalysisTableHeadProps {
  isFeatureAnalysis?: boolean;
  platformData?: SelectedProductDTO[];
  onClickComment?: (platformId: string, comment?: AnalysisCommentDTO) => void;
  onDeleteComment?: (platformId: string, commentId?: string) => void;
}

export const PlatformAnalysisTableHead = ({
  isFeatureAnalysis = false,
  platformData,
  onClickComment,
  onDeleteComment,
  ...others
}: PlatformAnalysisTableHeadProps &
  FeatureAnalysisTableCellProps &
  SHTableHeadProps) => {
  const { isReadOnly, isShowAnalysisScores } = usePlatformAnalysis();

  const renderTableHead = (platform: SelectedProductDTO) => {
    return (
      <SHStack
        sx={{
          transition: "all 0.5s ease",
          minHeight: "100%",
          justifyContent: "space-between",
        }}
        className="productNameHeaderCell"
        spacing={0.5}
      >
        {platform.isProductMain && (
          <SHChip
            label={"Main"}
            color={"warning"}
            variant={"outlined"}
            sx={{
              top: 5,
              right: 5,
              width: 51,
              height: 22,
              padding: "4px 8px",
              position: "absolute",
              transition: "all 0.5s ease",
              "& >.MuiChip-label": {
                overflow: "unset",
              },
            }}
            className="productNameHeaderCell__Chip"
          />
        )}
        <SHStack
          gap={"10px"}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ transition: "all 0.5s ease" }}
          className="productStack"
        >
          <SHStack
            direction="column"
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ transition: "all 0.5s ease" }}
            className="productStack__productInfo"
          >
            <SHImage
              src={platform.productLogo?.url}
              width={68}
              height={68}
              style={{ transition: "all 0.5s ease" }}
              className="productStack__Image"
            />
            <SHTypography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                fontSize: 15,
                transition: "all 0.5s ease",
                wordBreak: "break-word",
                lineHeight: "110%",
              }}
              className="productStack__productName"
            >
              {platform.productName}
            </SHTypography>
          </SHStack>
          <SHStack
            direction="column"
            alignItems={"center"}
            justifyContent={"center"}
            className="productStack__Overall"
          >
            {isFeatureAnalysis && !isShowAnalysisScores ? (
              <> </>
            ) : (
              <>
                <SHTypography
                  variant="body3"
                  colorVariant="third"
                  style={{ transition: "all 0.5s ease" }}
                  className="overall-text"
                >
                  Overall
                </SHTypography>
                <SHTypography
                  variant="subtitle1"
                  lineHeight={"150%"}
                  sx={{
                    fontWeight: 700,
                    fontSize: 24,
                    transition: "all 0.5s ease",
                  }}
                  className="overall-percent"
                >
                  {`${platform.totalScore} %`}
                </SHTypography>
              </>
            )}
          </SHStack>
        </SHStack>
        <SHStack
          direction="row"
          alignItems={"center"}
          justifyContent={"center"}
          spacing={1}
          className="buttonStack"
        >
          <PlusButton
            variant="outlined"
            size="medium"
            disabled={isReadOnly}
            startIcon={
              isEmpty(platform.comment?.comment) ? <PlusSVG /> : <EditSVG />
            }
            onClick={() => {
              if (onClickComment) {
                onClickComment(platform.id, platform?.comment);
              }
            }}
            className="commentButton"
          >
            Comment
          </PlusButton>
          {/* {!isEmpty(platform.comment) && (
          <DeleteButton
            height={32}
            width={32}
            backgroundColor={"transparent"}
            borderColor={palette.common.black}
            onClick={() =>
              typeof onDeleteComment === "function"
                ? onDeleteComment(platform?.id, platform?.commentId)
                : {}
            }
          />
        )} */}
        </SHStack>
      </SHStack>
    );
  };

  return (
    <SHTableHead
      sx={{
        height: platformData?.length === 0 ? 265 : undefined,
      }}
      {...others}
    >
      <SHTableRow>
        <FeatureAnalysisTableCell isFirstColumn isHeaderCell>
          <SHTypography
            variant="subtitle1"
            lineHeight={"150%"}
            sx={{
              fontWeight: 700,
            }}
          >
            Features
          </SHTypography>
        </FeatureAnalysisTableCell>
        {sortAscByOrder(platformData ?? [])?.map((platform, index) => (
          <FeatureAnalysisTableCell key={index} isHeaderCell>
            {renderTableHead(platform)}
          </FeatureAnalysisTableCell>
        ))}
      </SHTableRow>
    </SHTableHead>
  );
};
