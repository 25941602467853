import { SelectedProductDTO } from "@models/platform-analysis/entities/steps/feature";
import { PDFTitleSection } from "@pages/platform-analysis/components/buttons/export-pdf/components/title-section";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Text, View } from "@react-pdf/renderer";

interface PDFReviewPlatformSelectedProps {
  finalShortlisted: SelectedProductDTO[];
  selectedProductDisplay: string;
  familyGroupName?: string;
  isAppend?: boolean;
}

export const PDFReviewPlatformSelectedSection = ({
  finalShortlisted,
  selectedProductDisplay,
  familyGroupName,
  isAppend,
}: PDFReviewPlatformSelectedProps) => {
  const { typographyStyle } = useStyles();
  const textColor = "#000000";

  return (
    <View>
      <PDFTitleSection
        title={`${
          finalShortlisted && finalShortlisted?.length > 1
            ? "Platforms"
            : "Platform"
        } selected for ${familyGroupName}`}
      />
      <Text
        style={{
          ...typographyStyle.body1,
          color: textColor,
          lineHeight: 1.2,
          textAlign: "justify",
        }}
      >
        {selectedProductDisplay}
      </Text>
    </View>
  );
};
