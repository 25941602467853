import { linkedinURLRegex, phoneRegex } from "@constants";
import * as Yup from "yup";

export const completeProfileLastStepValidators = Yup.object().shape({
  publicEmail: Yup.string().nullable(true).email("Email is not valid"),
  linkedInProfileUrl: Yup.string().nullable(true).matches(linkedinURLRegex, {
    excludeEmptyString: true,
    message: "LinkedIn URL is invalid",
  }),
  publicPhoneNumber: Yup.string().nullable(true).matches(phoneRegex, {
    excludeEmptyString: true,
    message: "Phone number is not valid",
  }),
});
