import {
  SHBox,
  SHImage,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { TooltipAllowSVG } from "@components/svgs";
import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import {
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
  Zoom,
} from "@mui/material";
import { MainProductBadge } from "../main-product-badge";
import { hexToRGBA } from "@utils/color";
import { useState } from "react";
import { DateTimeFormat } from "@constants/format";
import { format } from "date-fns";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    border: "none",
    p: 0,
    m: 0,
  },
}));

export interface FeeSubProductCardProps extends SHStackProps {
  subProduct: FeeSubProductDTO;
  isActive?: boolean;
}
export const FeeSubProductCard = ({
  subProduct,
  isActive,
  ...others
}: FeeSubProductCardProps) => {
  const { palette, shadows } = useTheme();
  const [open, setOpen] = useState(false);
  const subProductName = `${subProduct.name}${
    !subProduct.isLatest && subProduct.outdatedAt
      ? ` (Outdated: ${format(subProduct.outdatedAt, DateTimeFormat)})`
      : ""
  }`;
  return (
    <SHStack spacing={"15px"} {...others}>
      <SHStack direction={"row"} alignItems={"center"} spacing={"10px"}>
        <SHBox
          sx={{
            width: "33px",
            height: "33px",
            position: "relative",
          }}
        >
          <SHImage src={subProduct.productLogo?.url} width={33} height={33} />
          {subProduct.isProductMain && (
            <MainProductBadge
              sx={{
                position: "absolute",
                top: "-8px",
                right: "-10px",
              }}
            />
          )}
        </SHBox>
        <SHStack justifyContent={"space-between"}>
          <SHTypography
            variant="body2"
            lineHeight={"120%"}
            maxWidth={"120px"}
            colorVariant={isActive ? "primary" : "disabled"}
            truncate
          >
            {subProduct.productName}
          </SHTypography>
          <HtmlTooltip
            open={open}
            followCursor
            TransitionComponent={Zoom}
            placement="top-start"
            title={
              <SHStack
                sx={{
                  bgcolor: hexToRGBA(palette.common.white, 0.9),
                  border: "1px solid #E3E3E3",
                  backdropFilter: "blur(5px)",
                  borderRadius: "3px",
                  boxShadow: shadows[1],
                  position: "relative",
                  ml: "-19px",
                  p: 1,
                }}
              >
                <SHTypography variant="body1" lineHeight={"120%"}>
                  {subProductName}
                </SHTypography>
                <SHBox
                  sx={{ position: "absolute", bottom: "-11.7px", left: 0 }}
                >
                  <TooltipAllowSVG />
                </SHBox>
              </SHStack>
            }
            onClose={() => setOpen(false)}
            onOpen={() => {
              if (subProductName?.length > 14) setOpen(true);
            }}
          >
            <SHStack>
              <SHTypography
                variant="subtitle2"
                lineHeight={"120%"}
                maxWidth={"120px"}
                colorVariant={isActive ? "primary" : "disabled"}
                truncate
              >
                {subProductName}
              </SHTypography>
            </SHStack>
          </HtmlTooltip>
        </SHStack>
      </SHStack>
    </SHStack>
  );
};
