import { SHStack } from "@components/design-systems";
import { ProductCardSkeleton } from "@components/product-card/ProductCardSkeleton";

export const ProductListSkeleton = () => {
  return (
    <SHStack
      gap={"10px"}
      direction={"row"}
      sx={{ flexWrap: "wrap", width: "100%" }}
    >
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
    </SHStack>
  );
};
