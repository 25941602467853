import { SHDot, SHDotType, SHStackProps } from "@components/design-systems";

export interface SHDotGroupProps extends SHStackProps {
  redDot?: boolean;
  blueDot?: boolean;
  greenDot?: boolean;
  orangeDot?: boolean;
  disabled?: boolean;
  size?: number | string;
}
export const SHDotGroup = ({
  redDot,
  blueDot,
  greenDot,
  orangeDot,
  disabled,
  size,
  ...others
}: SHDotGroupProps): JSX.Element => {
  return (
    <>
      {redDot && (
        <SHDot
          disabled={disabled}
          type={SHDotType.red}
          size={size}
          {...others}
        />
      )}
      {blueDot && (
        <SHDot
          disabled={disabled}
          type={SHDotType.blue}
          size={size}
          {...others}
        />
      )}
      {greenDot && (
        <SHDot
          disabled={disabled}
          type={SHDotType.green}
          size={size}
          {...others}
        />
      )}
      {orangeDot && (
        <SHDot
          disabled={disabled}
          type={SHDotType.orange}
          size={size}
          {...others}
        />
      )}
    </>
  );
};
