import {
  SHButton,
  SHPopper,
  SHRadio,
  SHStack,
  SHTypography
} from "@components/design-systems";
import { SHFormControlLabel } from "@components/design-systems/sh-form-control-label";
import { FilterClearSVG, FilterSVG } from "@components/svgs";
import { FormGroup, IconButton, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";

export type FilterValue = {
  label: string;
  value: any;
};

export interface FilterRadioButtonProps {
  buttonTitle?: string;
  labelAll?: string;
  width?: string;
  zIndex?: number;
  filterData?: FilterValue[];
  filterValue?: FilterValue[] | undefined;
  onChangeFilter?: (value: FilterValue[] | undefined) => void;
  [key: string]: any;
  isScrolling?: boolean;
}
export const FilterRadioButton = ({
  buttonTitle,
  filterData = [],
  labelAll = "All",
  width,
  zIndex,
  filterValue: filterValueProps,
  onChangeFilter,
  isScrolling = false,
  ...others
}: FilterRadioButtonProps) => {
  const { palette } = useTheme();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const [filterValue, setFilterValue] = useState<FilterValue[] | undefined>(
    filterValueProps,
  );
  const isFiltered =
    filterValue === undefined || filterValue.length === 0 ? false : true;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleOnChangeFilter = (value: FilterValue[] | undefined) => {
    setFilterValue(value);
    if (onChangeFilter) onChangeFilter(value);
  };

  useEffect(() => {
    setOpen(false);
  }, [isScrolling]);

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, buttonRef, open]);

  useEffect(() => {
    if (!isEqual(filterValueProps, filterValue)) {
      setFilterValue(filterValue ?? filterValueProps);
    }
    // eslint-disable-next-line
  }, [filterValueProps]);

  const FilterIcon = () => (
    <SHStack
      width={"16px"}
      height={"16px"}
      alignItems={"center"}
      justifyContent="start"
    >
      {isFiltered ? (
        <FilterClearSVG
          width={"16px"}
          height={"16px"}
          data-testid="filter-clear-icon"
        />
      ) : (
        <FilterSVG
          width={"15px"}
          height={"15px"}
          data-testid="filter-icon"
          fill={palette.primary.main}
        />
      )}
    </SHStack>
  );

  return (
    <>
      {buttonTitle ? (
        <SHButton
          {...others}
          ref={buttonRef}
          onClick={handleClick}
          color="primary"
          variant="text"
          size="extraMedium"
          startIcon={<FilterIcon />}
          data-testid="text-button"
        >
          {buttonTitle}
        </SHButton>
      ) : (
        <IconButton
          data-testid="icon-button"
          {...others}
          ref={buttonRef}
          onClick={handleClick}
          sx={{ padding: "4px" }}
        >
          <FilterIcon />
        </IconButton>
      )}

      <SHPopper
        anchorEl={anchorEl}
        open={open}
        placement="bottom-start"
        popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
        sx={{ width: width, zIndex: zIndex }}
      >
        <SHStack
          ref={wrapperRef}
          sx={{
            boxSizing: "border-box",
            boxShadow: "none",
            borderWidth: 1,
            borderColor: palette.divider,
            borderStyle: "solid",
            borderRadius: 0,
            backgroundColor: hexToRGBA(palette.common.white, 0.95),
            paddingX: 2,
            paddingY: 1,
            minWidth: "170px",
            filter: "drop-shadow(1px 1px 2px rgba(65, 73, 89, 0.25))",
          }}
        >
          <SHStack spacing={1} direction="row" alignItems="center">
            <FilterSVG />
            <SHTypography variant="body1">Filter</SHTypography>
          </SHStack>
          <FormGroup sx={{ padding: "2px" }}>
            <SHFormControlLabel
              size="small"
              control={
                <SHRadio
                  checked={!isFiltered}
                  size={"small"}
                  onClick={() => {
                    handleOnChangeFilter(undefined);
                  }}
                />
              }
              data-testid="filter-all-checkbox"
              label={labelAll}
            />
            {filterData.map((filterItem: FilterValue, index: number) => (
              <SHFormControlLabel
                key={index}
                data-testid={"filter-checkbox-" + index}
                size="small"
                control={
                  <SHRadio
                    checked={filterValue?.[0]?.value === filterItem.value}
                    size={"small"}
                    data-testid="sh-head-filter-option"
                    onClick={() => {
                      handleOnChangeFilter([filterItem]);
                    }}
                  />
                }
                label={filterItem.label}
              />
            ))}
          </FormGroup>
        </SHStack>
      </SHPopper>
    </>
  );
};
