import { SHBox } from "@components/design-systems/sh-box";
import {
  SHTextField,
  SHTextFieldProps,
} from "@components/design-systems/sh-text-field";
import { ArrowDownSVG } from "@components/svgs";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { find, isNil } from "lodash";
import React from "react";
import { SHMenuItem } from "../sh-menu-item";
import { SHStack } from "../sh-stack";
import { SHTypography } from "../sh-typography";

export type SHSelectProps = SHTextFieldProps & {
  data?: any[];
  displayField?: string;
  dataItemKey?: string;
  customSelectItem?: React.ReactNode;
};

export const SHSelect = React.forwardRef<HTMLDivElement, SHSelectProps>(
  (
    {
      data = [],
      SelectProps,
      value,
      placeholder,
      displayField = "value",
      dataItemKey = "key",
      customSelectItem,
      ...others
    },
    ref,
  ) => {
    const { palette } = useTheme();
    return (
      <SHTextField
        {...others}
        ref={ref}
        value={value}
        select
        SelectProps={{
          displayEmpty: true,
          IconComponent: (props: any) => (
            <ArrowDownSVG width={"10px"} height={"15px"} {...props} />
          ),
          renderValue: !isNil(value)
            ? customSelectItem
              ? undefined
              : () => (
                  <SHTypography variant="body3" pt="4px" truncate>
                    {find(data, { [dataItemKey]: value })?.[displayField]}
                  </SHTypography>
                )
            : () => {
                return (
                  <SHBox
                    className="sh-select-placeholder"
                    display={"inline-block"}
                    style={{
                      color: hexToRGBA(palette.text.disabled, 0.75),
                      fontWeight: 300,
                      paddingRight: 10,
                    }}
                  >
                    {placeholder}
                  </SHBox>
                );
              },
          ...SelectProps,
        }}
      >
        {data && data.length > 0 ? (
          customSelectItem ??
          data.map((option) => (
            <SHMenuItem
              divider
              key={option[dataItemKey]}
              value={option[dataItemKey]}
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                paddingLeft: "10px",
              }}
            >
              {option[displayField]}
            </SHMenuItem>
          ))
        ) : (
          <SHStack
            p={2}
            alignItems={"center"}
            justifyContent={"center"}
            color={palette.text.disabled}
          >
            No Data Found.
          </SHStack>
        )}
      </SHTextField>
    );
  },
);
