import { SVGProps } from "react";

export const FilterClearSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={15}
        height={15}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 9.5a.5.5 0 0 1 .5-.5h3a.5.5 0 1 1 0 1h-3a.5.5 0 0 1-.5-.5Zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5Zm11.957 7.25.897.896a.5.5 0 0 1-.708.708l-.896-.897-.896.897a.5.5 0 0 1-.708-.708l.897-.896-.897-.896a.5.5 0 0 1 .708-.708l.896.897.896-.897a.5.5 0 0 1 .708.708l-.897.896Z"
            fill="#332CC2"
        />
    </svg>
);
