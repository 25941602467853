import { PageRoutes } from "@constants/routes";
import { useConfiguration } from "@hooks/useConfiguration";
import { useCurrentPath } from "@hooks/useCurrentPath";
import { useNotification } from "@hooks/useNotification";
import ManageConfiguration from "@pages/configuration/components/ManageConfiguration";
import { ConfigurationFeaturePage } from "@pages/configuration/feature";
import { updateConfigurationGroups } from "@redux/slices/configuration";
import { updateConfigurationNotifications } from "@redux/slices/top-notifications";
import { getConfigGroups } from "@services/configuration";
import axios from "axios";
import { useDispatch } from "react-redux";
import { matchPath } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ConfigurationSectionPage } from "./section";

export const ConfigurationPage = () => {
  const dispatch = useDispatch();
  const { notify } = useNotification();
  const { updateDraftStatus } = useConfiguration();
  const currentPath = useCurrentPath();

  const isStandardFeaturePage = matchPath(
    PageRoutes.configurationFeatureStandard,
    currentPath,
  );
  const isFeatureFeaturePage = matchPath(
    PageRoutes.configurationFeatureFeature,
    currentPath,
  );
  const isStandardSectionPage = matchPath(
    PageRoutes.configurationSectionStandard,
    currentPath,
  );
  const isFeatureGroupSectionPage = matchPath(
    PageRoutes.configurationFeatureSectionGroup,
    currentPath,
  );
  const isFeatureSubGroupSectionPage = matchPath(
    PageRoutes.configurationFeatureSectionSubGroup,
    currentPath,
  );

  useEffectOnce(() => {
    const cancelToken = axios.CancelToken.source();
    loadConfigurationGroups();
    updateDraftStatus(cancelToken.token);
    return () => {
      cancelToken.cancel();
      dispatch(updateConfigurationNotifications([]));
    };
  });

  const loadConfigurationGroups = async () => {
    const { data, message } = await getConfigGroups();
    if (!data) {
      notify(message, {
        variant: "error",
        close: true,
      });
      return;
    }
    dispatch(updateConfigurationGroups(data));
  };

  const renderPages = () => {
    if (isStandardFeaturePage) return <ConfigurationFeaturePage />;
    if (isFeatureFeaturePage)
      return <ConfigurationFeaturePage isFeatureGroup />;
    if (isStandardSectionPage)
      return <ConfigurationSectionPage isStandardSection />;
    if (isFeatureGroupSectionPage) return <ConfigurationSectionPage />;
    if (isFeatureSubGroupSectionPage)
      return <ConfigurationSectionPage isFeatureSubGroupSection />;
    return <ManageConfiguration />;
  };

  return renderPages();
};
