import { FamilyMemberDTO } from "@models/platform-analysis/entities/steps/fee";

export const checkHasEmptyBalance = (
  familyMembers?: FamilyMemberDTO[],
): boolean => {
  if (!familyMembers) return true;
  for (let member of familyMembers) {
    if (member.familyMemberAccounts.length !== 0) {
      if (member.totalBalance <= 0) return true;
      for (let account of member.familyMemberAccounts) {
        if (account.balance <= 0) return true;
      }
    }
  }
  return false;
};
