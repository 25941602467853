import { FeeAnalysisFilterRadioButton } from "@components/buttons/fee-analysis-filter-radio";
import { FilterValue } from "@components/buttons/filter";
import {
  SHButton,
  SHContainer,
  SHGrid,
  SHStack,
  SHToggleButtonOptions,
  SHTypography,
} from "@components/design-systems";
import { EyeIcon } from "@components/svgs/eye";
import { DateFormat } from "@constants/format";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import {
  AnalysisFilterDTO,
  HiddenSubProductDTO,
} from "@models/platform-analysis/entities/analysis";
import { PlatformCommentDialog } from "@models/platform-analysis/entities/comment";
import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { useTheme } from "@mui/material";
import { ExportExcelButton } from "@pages/platform-analysis/components/buttons/export-excel";
import {
  FeeAnalysisFilterMapOptions,
  FeeAnalysisFilterYesNoOptions,
  PlatformFeeAnalysisInvestmentMenuFilterOptions,
  PlatformFeeAnalysisMissingInvestmentFilterOptions,
} from "@pages/platform-analysis/constant";
import { RootState } from "@redux/store";
import { savePlatformAnalysisFilters } from "@services/platform-analysis/platformAnalysisService";
import { format } from "date-fns";
import { chain, find, isEmpty, split } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { ChangeRichTextDialog } from "../../summary/components/change-rich-text-dialog";
import { ChartLegendBox } from "./components/chart-legend-box";
import { FeeHeatmap } from "./components/heatmap";
import { FeeLineChart } from "./components/line-chart";
import { FeeProductCard } from "./components/product-card";
import { getProductCardSx } from "./components/product-card/util";
import { AnalysisFeeSkeleton } from "./skeleton";

export interface FeeAnalysisProps extends StepCompProps {}
export const FeeAnalysis: ForwardRefRenderFunction<
  StepCompRef,
  FeeAnalysisProps
> = ({ stepNumber }: FeeAnalysisProps, ref) => {
  const { palette } = useTheme();
  const {
    ui: { isLoading, isShowHidden },
    isCompleted,
    isReadOnly,
    platformAnalysis,
  } = useSelector((state: RootState) => state.platformAnalysis);
  const { analysisId } = useParams<{ analysisId: string }>();
  const [hiddenIds, setHiddenIds] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<FeesDisplayStyle>(
    FeesDisplayStyle.Dollar,
  );
  const [heatmapMode, setHeatmapMode] = useState<FeeHeatmapMode>(
    FeeHeatmapMode.Detail,
  );
  const [filterInvestmentMenuValue, setFilterInvestmentMenuValue] = useState<
    FilterValue[] | undefined
  >(undefined);
  const [filterMissingInvestmentValue, setFilterMissingInvestmentValue] =
    useState<FilterValue[] | undefined>(undefined);

  const [subProductData, setSubProductData] = useState(
    platformAnalysis?.fee?.subProducts ?? [],
  );
  const [products, setProducts] = useState(
    platformAnalysis?.fee?.selectedProducts ?? [],
  );
  const [databaseVersion, setDatabaseVersion] = useState<Date>();
  const {
    isModifyingComment,
    modifyComment,
    loadPlatformAnalysis,
    setIsShowHidden,
    resetPlatformAnalysisFee,
  } = usePlatformAnalysis();
  const [commentDialog, setCommentDialog] = useState<
    (PlatformCommentDialog & { index: number }) | undefined
  >();
  const isShowFeeDetail = useMemo(
    () => heatmapMode === FeeHeatmapMode.Detail,
    [heatmapMode],
  );

  const filterFeeSubProduct = (
    missingInvestmentFilters: FilterValue[] | undefined,
    investmentMenuFilters: FilterValue[] | undefined,
  ) => {
    if (!missingInvestmentFilters && !investmentMenuFilters) {
      return setSubProductData(platformAnalysis?.fee?.subProducts ?? []);
    }

    const investmentMenuFilter = investmentMenuFilters?.[0].value
      ? investmentMenuFilters?.[0].value
      : FeeInvestmentMenuOption.AllPlatform;
    const missingInvestmentFilter = missingInvestmentFilters?.[0].value
      ? missingInvestmentFilters?.[0].value
      : FeeMissingInvestmentsOption.AllPlatform;

    let subProductFiltered: FeeSubProductDTO[] = Object.assign(
      platformAnalysis?.fee?.subProducts ?? [],
    );

    if (investmentMenuFilter !== FeeInvestmentMenuOption.AllPlatform) {
      subProductFiltered = subProductFiltered?.filter(
        (subProduct) => subProduct.investmentMenu === investmentMenuFilter,
      );
    }

    if (missingInvestmentFilter === FeeMissingInvestmentsOption.AllPlatform) {
      return setSubProductData(subProductFiltered ?? []);
    } else {
      return setSubProductData(
        subProductFiltered?.filter((subProduct) => !subProduct.warning) ?? [],
      );
    }
  };

  const handleOnChangeFilter = (
    missingInvestmentFilters: FilterValue[] | undefined,
    investmentMenuFilters: FilterValue[] | undefined,
  ) => {
    setHiddenIds([]);
    if (!missingInvestmentFilters && !investmentMenuFilters) {
      setFilterMissingInvestmentValue(undefined);
      setFilterInvestmentMenuValue(undefined);
      return;
    }

    const investmentMenuFilter = investmentMenuFilters?.[0].value
      ? investmentMenuFilters?.[0].value
      : FeeInvestmentMenuOption.AllPlatform;
    const missingInvestmentFilter = missingInvestmentFilters?.[0].value
      ? missingInvestmentFilters?.[0].value
      : FeeMissingInvestmentsOption.AllPlatform;

    setFilterInvestmentMenuValue(
      investmentMenuFilter !== FeeInvestmentMenuOption.AllPlatform
        ? [{ label: "", value: investmentMenuFilter }]
        : undefined,
    );
    setFilterMissingInvestmentValue(
      missingInvestmentFilter !== FeeMissingInvestmentsOption.AllPlatform
        ? [{ label: "", value: missingInvestmentFilter }]
        : undefined,
    );
  };

  const handleOnModifyComment = async (html: string | null) => {
    if (!analysisId || !commentDialog?.productId || !commentDialog) return;
    const newComment = await modifyComment(
      analysisId,
      commentDialog.productId,
      AnalysisStep.Fee,
      html,
    );
    if (newComment) {
      setProducts((products) => {
        let clone = [...products];
        clone[commentDialog.index] = {
          ...clone[commentDialog.index],
          comment: newComment,
        };
        return clone;
      });
      setCommentDialog(undefined);
    }
  };
  const loadData = async (analysisId?: string) => {
    if (!analysisId) return;
    const newData = await loadPlatformAnalysis({
      platformAnalysisId: analysisId,
      stepId: AnalysisStep.Fee,
    });
    setProducts(newData?.fee?.selectedProducts ?? []);
    setSubProductData(newData?.fee?.subProducts ?? []);
    setDatabaseVersion(newData?.databaseVersion ?? new Date());
    setHeatmapMode(newData?.fee?.feeTableViewMode ?? FeeHeatmapMode.Detail);
    setViewMode(newData?.fee?.feesDisplayStyle ?? FeesDisplayStyle.Dollar);
    if (
      newData?.fee?.investmentMenu !== undefined &&
      newData?.fee?.investmentMenu !==
        FeeInvestmentMenuOption.AllPlatform
    ) {
      setFilterInvestmentMenuValue([
        {
          label: "",
          value: newData?.fee?.investmentMenu,
        },
      ]);
    }
    if (
      newData?.fee?.feeMissingInvestmentsStyle !== undefined &&
      newData?.fee?.feeMissingInvestmentsStyle ===
        FeeMissingInvestmentsOption.NotMissing
    ) {
      setFilterMissingInvestmentValue([
        {
          label: "",
          value: FeeMissingInvestmentsOption.NotMissing,
        },
      ]);
    }
    setHiddenIds(
      chain(newData?.fee?.subProducts)
        .filter({ isHidden: true })
        .map("id")
        .value(),
    );
  };

  useEffectOnce(() => {
    setIsShowHidden(false);
  });

  useEffect(() => {
    loadData(analysisId);
    // eslint-disable-next-line
  }, [analysisId]);

  useEffect(() => {
    filterFeeSubProduct(
      filterMissingInvestmentValue,
      filterInvestmentMenuValue,
    );
    // eslint-disable-next-line
  }, [filterMissingInvestmentValue, filterInvestmentMenuValue]);

  const subProductsDataForExcel = () => {
    return isShowHidden
      ? subProductData
      : subProductData.filter(
          (subProduct) => !hiddenIds.includes(subProduct.id),
        );
  };

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep, isNext) => {
      if (!isReadOnly) {
        await saveFilter();
      }
      resetPlatformAnalysisFee();
      return true;
    },
    onChangeOtherStep: async () => {
      if (!isReadOnly) {
        await saveFilter();
      }
      resetPlatformAnalysisFee();
      return true;
    },
    onSubmit: async () => {
      if (!isReadOnly) {
        await saveFilter();
      }
      return true;
    },
    onExit: async () => {
      if (!isReadOnly) {
        await saveFilter();
      }
      return true;
    },
  }));

  const saveFilter = async () => {
    let data: AnalysisFilterDTO = {
      feeMissingInvestmentsStyle: filterMissingInvestmentValue?.[0].value
        ? filterMissingInvestmentValue?.[0].value
        : FeeMissingInvestmentsOption.AllPlatform,
      investmentMenu: filterInvestmentMenuValue?.[0]?.value
        ? filterInvestmentMenuValue?.[0]?.value
        : FeeInvestmentMenuOption.AllPlatform,
      feesDisplayStyle: viewMode,
      feeTableViewMode: heatmapMode,
      hiddenSubProducts: generateHiddenSubProducts(
        hiddenIds,
        platformAnalysis?.fee?.subProducts ?? [],
      ),
    };

    await savePlatformAnalysisFilters(platformAnalysis?.id ?? "", data);
  };

  const generateHiddenSubProducts = (
    hiddenIds: string[],
    subProducts: FeeSubProductDTO[],
  ): HiddenSubProductDTO[] => {
    let hiddenSubProducts: HiddenSubProductDTO[] = [];
    hiddenIds.forEach((id) => {
      var subProduct = find(subProducts, { id });
      if (subProduct) {
        let subProductId = subProduct.id;
        if (subProduct.isRateCard) {
          subProductId = split(subProduct.id, "|")[0];
        }

        hiddenSubProducts.push({
          id: subProductId,
          isRateCard: subProduct.isRateCard ?? true,
          rateCardVersion: subProduct.isRateCard
            ? subProduct.rateCardVersion ?? 0
            : 0,
        });
      }
    });
    return hiddenSubProducts;
  };

  if (isLoading) return <AnalysisFeeSkeleton />;

  return (
    <SHContainer maxWidth="lg2" sx={{ px: { xs: "16px", lg: 0 }, py: "25px" }}>
      <SHStack spacing={"25px"}>
        <SHStack
          direction={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <StepName name={"Fee analysis"} stepNumber={stepNumber} />
          <SHStack
            justifyContent={"space-between"}
            pb={"10px"}
            spacing={"25px"}
          >
            <SHTypography
              variant="body3"
              colorVariant="third"
              textAlign={"right"}
              lineHeight="120%"
            >
              {`Data valid: ${format(
                databaseVersion ?? new Date(),
                DateFormat,
              )}`}
            </SHTypography>
            <SHStack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              spacing={"25px"}
            >
              <SHButton
                color="primary"
                variant="text"
                size="extraMedium"
                startIcon={
                  <EyeIcon
                    isActive={true}
                    fill={
                      hiddenIds.length > 0
                        ? palette.primary.main
                        : palette.grey[500]
                    }
                  />
                }
                disabled={hiddenIds.length === 0}
                onClick={() => {
                  setHiddenIds([]);
                  setIsShowHidden(false);
                }}
              >
                Show hidden
              </SHButton>
              <FeeAnalysisFilterRadioButton
                buttonTitle={"Filters"}
                filterInvestmentMenuData={
                  PlatformFeeAnalysisInvestmentMenuFilterOptions
                }
                filterInvestmentMenuValue={filterInvestmentMenuValue}
                filterMissingInvestmentData={
                  PlatformFeeAnalysisMissingInvestmentFilterOptions
                }
                filterMissingInvestmentValue={filterMissingInvestmentValue}
                width={"275px"}
                labelAll={"All platforms"}
                zIndex={10}
                onChangeFilter={handleOnChangeFilter}
              />
              <SHToggleButtonOptions
                options={FeeAnalysisFilterMapOptions}
                value={heatmapMode}
                onChange={(event, value) => {
                  if (!value) return;
                  setHeatmapMode(value);
                }}
              />
              <SHToggleButtonOptions
                options={FeeAnalysisFilterYesNoOptions}
                value={viewMode}
                onChange={(event, value) => {
                  if (!value) return;
                  setViewMode(value);
                }}
              />
            </SHStack>
          </SHStack>
        </SHStack>
        <SHStack
          direction={{ xs: "column", lg: "row" }}
          justifyContent={{ xs: "stretch", lg: "space-between" }}
          spacing={"25px"}
          alignItems={"flex-start"}
        >
          <SHGrid container width={{ xs: "100%", lg: "250px" }}>
            {products.map((product, index) => (
              <SHGrid key={product.id} xs={6} sm={4} md={3} lg={12}>
                <FeeProductCard
                  isShowButtons={!isCompleted && !isReadOnly}
                  product={product}
                  sx={getProductCardSx(index)}
                  onAddComment={() =>
                    setCommentDialog({
                      index,
                      productId: product.id,
                      comment: product.comment?.comment,
                    })
                  }
                />
              </SHGrid>
            ))}
          </SHGrid>
          <SHStack
            sx={{
              display: subProductData.length ? "flex" : "none",
              flexGrow: 1,
              width: { xs: "100%", lg: "1px" },
            }}
            spacing={"25px"}
          >
            <FeeHeatmap
              subProductsInStore={platformAnalysis?.fee?.subProducts ?? []}
              subProducts={subProductData}
              totalPortfolioValue={
                platformAnalysis?.fee?.feePortfolioDetails?.totalPortfolioValue
              }
              mode={viewMode}
              heatmapMode={heatmapMode}
              hiddenIds={hiddenIds}
              onChangeHiddenIds={setHiddenIds}
              isCompleted={isCompleted}
              isShowHidden={isShowHidden}
              titleComp={
                <SHStack
                  direction={"row"}
                  spacing={"5px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <SHTypography variant="subtitle1" fontWeight={700}>
                    Annual platform fees by portfolio value (estimates)
                  </SHTypography>
                  {platformAnalysis?.fee?.subProducts && (
                    <ExportExcelButton
                      mode={viewMode}
                      data={
                        {
                          ...platformAnalysis.fee,
                          subProducts: subProductsDataForExcel(),
                          databaseVersion:
                            platformAnalysis.databaseVersion ?? null,
                          isShowHoldingsNumber:
                            platformAnalysis.fee.isShowHoldingsNumber,
                        } as any
                      }
                    />
                  )}
                </SHStack>
              }
              leftLegend={
                <ChartLegendBox height={"90px"} direction="vertical">
                  Platforms
                </ChartLegendBox>
              }
              bottomLegend={
                <ChartLegendBox>
                  {isShowFeeDetail
                    ? "Fee type"
                    : "Average total portfolio value for the year"}
                </ChartLegendBox>
              }
            />
            <FeeLineChart
              subProducts={subProductData}
              mode={viewMode}
              hiddenIds={hiddenIds}
              onChangeHiddenIds={setHiddenIds}
            />
          </SHStack>
          <SHStack
            sx={{
              display: !subProductData.length ? "flex" : "none",
              flexGrow: 1,
              width: { xs: "100%", lg: "1px" },
              background: palette.common.white,
              border: `1px solid ${palette.secondary[100]}`,
              p: "28px",
              height: 400,
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <SHTypography variant="subtitle1" colorVariant="disabled">
              No data
            </SHTypography>
            <SHTypography variant="body2" colorVariant="disabled">
              No products match the criteria in your filter
            </SHTypography>
          </SHStack>
        </SHStack>
      </SHStack>
      {commentDialog && (
        <ChangeRichTextDialog
          onSubmit={handleOnModifyComment}
          title={
            isEmpty(commentDialog.comment) ? "Add comment" : "Edit comment"
          }
          placeHolder={"Enter your comment"}
          initialContent={commentDialog.comment ?? null}
          isSubmitting={isModifyingComment}
          onClose={() => {
            setCommentDialog(undefined);
          }}
          maxLength={1500}
          isCommentBox
        />
      )}
    </SHContainer>
  );
};
