import mainBgImage from "@assets/images/background/main.svg";
import { CloseButton } from "@components/buttons/close";
import {
  SHButton,
  SHDialog,
  SHHtmlBlock,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { FooterSVG } from "@components/svgs";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";

interface FeeCalculatorDialogProps {
  open: boolean;
  data?: DisclaimerDTO[];
  onClose?: () => void;
  isReview?: boolean;
}

export const FeeCalculatorDialog = ({
  onClose,
  open,
  data,
  isReview,
}: FeeCalculatorDialogProps): JSX.Element => {
  const { palette } = useTheme();

  const renderDisclaimers = () => {
    return (
      !isEmpty(data) && (
        <SHStack>
          <SHTypography
            variant="subtitle2"
            textTransform={"uppercase"}
            mt={"15px"}
          >
            {`Fee ${
              isReview ? "review" : "analysis"
            } assumptions: Product-specific`}
          </SHTypography>
          {data?.map((item, index) => {
            return (
              <SHHtmlBlock
                content={item.messageDisclaimer}
                variant="body2"
                key={index}
                sx={{ pb: "15px" }}
              />
            );
          })}
        </SHStack>
      )
    );
  };

  return (
    <SHDialog
      open={open}
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          padding: 0,
          overflow: "hidden",
          backgroundImage: `url(${mainBgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: { xs: "100%", md: 1350 },
          border: 0,
        },
      }}
    >
      <DialogTitle
        borderBottom={`1px solid ${palette.common.black}`}
        display={"flex"}
        padding={"0px !important"}
        alignItems="stretch"
        justifyContent="space-between"
        flexDirection="row"
      >
        <SHTypography
          variant="h2"
          fontWeight={600}
          fontSize={20}
          padding={"25px 77px !important"}
          borderRight={`1px solid ${palette.common.black}`}
          textAlign={"center"}
        >
          FEE CALCULATOR
        </SHTypography>
        <SHStack padding={"35px 80px"}>
          <CloseButton width="8.75px" height="8.75px" onClick={onClose} />
        </SHStack>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px !important" }}>
        <SHStack textAlign={"justify"} marginY={5.5} marginX={9}>
          <SHTypography variant="subtitle2" textTransform={"uppercase"}>
            {`Fee ${isReview ? "review" : "analysis"} disclaimer`}
          </SHTypography>
          <SHTypography variant="body2">
            Any result provided by SuitabilityHub’s fee calculator is
            illustrative only, and relies upon information that is subject to
            change. Any result should not be relied upon for the purpose of
            making a decision in relation to a particular financial product,
            platform or provider. Any result provided by our calculator is not
            an offer, and financial product providers will undertake their own
            fee calculations. Unless otherwise stated, all amounts are in
            Australian dollars.
          </SHTypography>
          <SHTypography
            variant="subtitle2"
            textTransform={"uppercase"}
            mt={"15px"}
          >
            {`Fee ${isReview ? "review" : "analysis"} assumptions: General`}
          </SHTypography>

          <SHTypography
            variant="body2"
            sx={{
              "&>ul>li::marker": {
                fontSize: "10px",
              },
            }}
          >
            Our fee calculator relies upon various assumptions. Those
            assumptions are as follows, and are current as at 15 December 2023:
            <ul style={{ margin: 0, paddingLeft: "20px" }}>
              <li>
                All balances provided are an average figure for a 12-month
                period;
              </li>
              <li>
                Investments held by a client are also held by all of the
                accounts in that client’s family;
              </li>
              <li>
                Fee estimates are calculated based on all accounts within a
                family investing in the same platform product;
              </li>
              <li>
                For the purpose of calculating transaction fees, investment
                transactions for a given investment class is assumed to be equal
                in size, and this size is the “anticipated average transaction
                size for the next 12 months” entered into the calculator;
              </li>
              <li>
                Some platforms do not charge transaction fees on regular
                investment facilities or plans. We assume all transactions
                specified happen outside of these facilities or plans, on an
                ad-hoc basis;
              </li>
              <li>
                Any calculated expense recovery fee and/or operational risk
                financial requirement fee is the maximum possible fee as set out
                in the relevant financial product’s current product disclosure
                statement;
              </li>
              <li>
                For superannuation accounts with balances less than $6,000,
                total administration, cash and account-related fees are capped
                at 3% of the account balance. Other fees are not included in
                this cap e.g. transaction fees;
              </li>
              <li>Each investment transaction is the same size; </li>
              <li>
                Where fees listed in the PDS vary within a specified range, the
                maximum fee amount is applied;
              </li>
              <li>
                Fee aggregation discounts and fee calculation methodologies will
                be applied in accordance with the relevant financial product’s
                current product disclosure statement;
              </li>
              <li>
                All listed accounts are grouped for fee aggregation purposes;
                and
              </li>
              <li>
                Fee estimates are displayed for total portfolio balances in
                addition to the original total entered into the calculator.
                These estimates are based on proportionally allocating the
                holdings and account balances of the original total portfolio
                value.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2" mt={"15px"}>
            Any result provided by the fee calculator takes into account
            platform administration fees, as well as transaction fees for
            Australian listed securities, international listed securities,
            managed funds and fixed income, and fees associated with holding
            retail insurance (not including the value
            of any insurance premiums). Results do not factor in retail or group
            insurance premiums, foreign currency fees that may apply to
            international investments, buy-sell spreads, and investment fees for
            individual investment options (e.g. performance-related fees); final
            results also do not factor in minimum cash balance and minimum
            transaction sizes.
          </SHTypography>
          {renderDisclaimers()}
        </SHStack>
        <FooterSVG style={{ marginBottom: "-5px" }} />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          borderTop: `1px solid ${palette.common.black}`,
          height: 110,
          margin: 0,
        }}
      >
        <SHButton
          color="primary"
          variant="contained"
          onClick={onClose}
          size="large"
          sx={{ width: "260px" }}
        >
          Close
        </SHButton>
      </DialogActions>
    </SHDialog>
  );
};
