import { RateCardDTO } from "@models/rate-card/entities/rateCard";
import { AnalysisVariable } from "@models/platform-analysis/enums/variableName";
import { DependFieldsMapping } from "@models/product/entities/subProduct";
import {
  showAllowFieldNameMapping,
  valueNameMapping,
} from "@pages/suppliers/_id/_products/_id/edit/components/fees-and-rates/util";
import { FeeValueType } from "@models/product/enums/feeValueType";

export const generateRateCardFieldMapping = (
  rateCard?: RateCardDTO,
): DependFieldsMapping => {
  let indexMapping: DependFieldsMapping = {};

  rateCard?.standardFeeSections?.forEach((standardFee, sectionIndex) => {
    standardFee?.features?.forEach((feature, featureIndex) => {
      const initDependFiled = `standardFeeSections.${sectionIndex}.features.${featureIndex}`;
      indexMapping[feature.variableNameIDPS as AnalysisVariable] = {
        modifiedField: `${initDependFiled}.isModified`,
        showHideField: `${initDependFiled}.${
          showAllowFieldNameMapping[FeeValueType.IDPS]
        }`,
        valueField: `${initDependFiled}.rateCardData.${
          valueNameMapping[FeeValueType.IDPS]
        }`,
      };
      indexMapping[feature.variableNamePension as AnalysisVariable] = {
        modifiedField: `${initDependFiled}.isModified`,
        showHideField: `${initDependFiled}.${
          showAllowFieldNameMapping[FeeValueType.Pension]
        }`,
        valueField: `${initDependFiled}.rateCardData.${
          valueNameMapping[FeeValueType.Pension]
        }`,
      };
    });
  });
  rateCard?.otherFeeSections?.forEach((otherFee, sectionIndex) => {
    otherFee?.features?.forEach((feature, featureIndex) => {
      const initDependFiled = `otherFeeSections.${sectionIndex}.features.${featureIndex}`;
      indexMapping[feature.variableNameOther as AnalysisVariable] = {
        modifiedField: `${initDependFiled}.isModified`,
        showHideField: `${initDependFiled}.${
          showAllowFieldNameMapping[FeeValueType.Other]
        }`,
        valueField: `${initDependFiled}.rateCardData.${
          valueNameMapping[FeeValueType.Other]
        }`,
      };
    });
  });

  return indexMapping;
};
