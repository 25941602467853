import { SVGProps } from "react";

export const RecycleBinSVG = ({
  color = "#FC5746",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={15}
    height={16}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="recycleBinSvg"
    {...props}
  >
    <path
      d="M5.625 12.219a.47.47 0 0 1-.469.469.47.47 0 0 1-.468-.47V6.126a.47.47 0 0 1 .468-.469.47.47 0 0 1 .469.469v6.094Zm2.344 0a.47.47 0 0 1-.469.469.47.47 0 0 1-.469-.47V6.126a.47.47 0 0 1 .469-.469.47.47 0 0 1 .469.469v6.094Zm2.343 0a.47.47 0 0 1-.468.469.47.47 0 0 1-.469-.47V6.126a.47.47 0 0 1 .469-.469.47.47 0 0 1 .469.469v6.094ZM10.24 1.23l1.075 1.613h2.045a.702.702 0 1 1 0 1.406h-.234v8.906a2.343 2.343 0 0 1-2.344 2.344H4.22a2.343 2.343 0 0 1-2.344-2.344V4.25h-.234a.702.702 0 1 1 0-1.406h2.045L4.761 1.23C5.065.774 5.578.5 6.126.5h2.748c.548 0 1.06.274 1.365.73ZM5.376 2.844h4.248l-.557-.833a.235.235 0 0 0-.193-.105H6.126c-.076 0-.176.04-.193.105l-.557.833ZM3.281 13.156c0 .519.42.938.938.938h6.562a.937.937 0 0 0 .938-.938V4.25H3.28v8.906Z"
      fill={color}
    />
  </svg>
);
