import { Components, Palette, Theme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

export const muiTypographyTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiTypography"> => ({
  MuiTypography: {
    styleOverrides: {
      root: {
        lineHeight: "160%",
        paddingTop: "2px",
        color: theme.palette.text.primary,
      },
      h1: {
        fontFamily: "Clash Display",
        fontSize: "2.188rem",
        fontWeight: 700,
      },
      h2: {
        fontFamily: "Clash Display",
        fontSize: "1.25rem",
        fontWeight: 700,
      },
      h3: {
        fontFamily: "Clash Display",
        fontSize: "1rem",
        fontWeight: 500,
      },
      h4: {
        fontFamily: "Clash Display",
        fontSize: "0.875rem",
        fontWeight: 500,
      },
      h5: {
        fontFamily: "Clash Display",
        fontSize: "0.875rem",
        fontWeight: 400,
      },
      h6: {
        fontFamily: "Clash Display",
        fontSize: "0.813rem",
        fontWeight: 400,
      },
      subtitle1: {
        fontWeight: 600,
        fontSize: "1rem",
      },
      subtitle2: {
        fontWeight: 600,
        fontSize: "0.875rem",
      },
      body1: {
        fontWeight: 500,
        fontSize: "0.875rem",
      },
      body2: {
        fontWeight: 400,
        fontSize: "0.875rem",
      },
      caption: {
        fontWeight: 500,
        fontSize: "0.75rem",
      },
    },
  },
});

export const muiTypographyThemeOption = (
  theme: Theme,
): TypographyOptions | ((palette: Palette) => TypographyOptions) => ({
  allVariants: {
    color: theme.palette.text.primary,
  },
  fontFamily: "Epilogue",
  body3: {
    fontWeight: 500,
    fontSize: "0.813rem",
    fontFamily: "Epilogue",
  },
  body4: {
    fontWeight: 400,
    fontSize: "0.813rem",
    fontFamily: "Epilogue",
  },
});
