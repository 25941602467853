import {
  ConfigurationGroup,
  ConfigurationSection,
} from "@models/configuration";
import { sortAscByOrder } from "@utils/common";

export const compileConfiguration = (
  configurationGroup: ConfigurationGroup,
): ConfigurationGroup => {
  if (configurationGroup.sections) {
    //Sort Section
    configurationGroup.sections = sortAscByOrder(configurationGroup.sections);
    configurationGroup.sections.forEach((section) => {
      if (configurationGroup.sectionType === "HasSubSections") {
        //Sort Sub Section
        if (section.subSections)
          section.subSections = sortAscByOrder(section.subSections) as [];
        //Sort feature of sub sections
        section.subSections?.forEach((subSection: ConfigurationSection) => {
          if (subSection.features)
            subSection.features = sortAscByOrder(subSection.features);
        });
      } else {
        //Sort feature of section
        if (section.features)
          section.features = sortAscByOrder(section.features);
      }
    });
  }
  return configurationGroup;
};

export const compileConfigurationGroups = (groups?: ConfigurationGroup[]) => {
  if (!groups) return undefined;
  return sortAscByOrder(groups);
};
