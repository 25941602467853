import { InformationButton } from "@components/buttons/information";
import {
  SHHtmlBlock,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { ConfigurationFeeSectionDTO } from "@models/product/entities/subProduct";
import { FeeFieldType } from "@models/product/enums/feeFieldType";
import { useTheme } from "@mui/material";
import { isEmpty, isNil, some } from "lodash";
import { NumericFormat } from "react-number-format";
import { FRCell } from "../table-cell";

export interface FRViewFeesTableProps {
  fee: ConfigurationFeeSectionDTO;
  isOtherFee?: boolean;
}
export const FRViewFeesTable = ({
  fee,
  isOtherFee = false,
}: FRViewFeesTableProps) => {
  const { palette } = useTheme();
  const renderFeeValue = (
    value?: string | null,
    fieldType?: FeeFieldType,
    lovData?: any[],
  ) => {
    if (isNil(value)) return null;
    if (fieldType === FeeFieldType?.Currency)
      return (
        <NumericFormat
          prefix="$"
          displayType="text"
          value={value}
          thousandSeparator=","
        />
      );
    if (fieldType === FeeFieldType?.Percentage)
      return <NumericFormat suffix="%" displayType="text" value={value} />;
    if (fieldType === FeeFieldType?.YesNo)
      return isEmpty(value) ? null : value === "true" ? "Yes" : "No";
    if (fieldType === FeeFieldType?.Dropdown)
      return lovData?.find((item) => item.id === value)?.name;
    if (fieldType === FeeFieldType?.OpenText) {
      return (
        <SHHtmlBlock variant="body4" textAlign="left" content={value?.replace(/\n/g, "<br/>")} />
      );
    }
    return value;
  };

  const isShowSection = !isOtherFee
    ? some(fee?.features, {
        showAndAllowUpdateIDPS: true,
      }) ||
      some(fee?.features, {
        showAndAllowUpdatePension: true,
      })
    : some(fee?.features, {
        showAndAllowUpdateOther: true,
      });

  return isShowSection ? (
    <SHTableContainer sx={{ overflowY: "hidden" }}>
      <SHTable>
        <SHTableHead>
          <SHTableRow
            sx={{
              borderBottom: "none",
              backgroundColor: `${palette.secondary[50]} !important`,
            }}
          >
            <FRCell isHeader>
              <SHStack direction={"row"} spacing={0.5}>
                <SHTypography variant="body4" fontWeight={600}>
                  {fee?.name}
                </SHTypography>
                {!isEmpty(fee.description) && (
                  <InformationButton content={fee.description} />
                )}
              </SHStack>
            </FRCell>
            <FRCell isHeader>
              {!isOtherFee && (
                <SHTypography variant="body4" fontWeight={600}>
                  IDPS
                </SHTypography>
              )}
            </FRCell>
            <FRCell isHeader>
              {!isOtherFee && (
                <SHTypography variant="body4" fontWeight={600}>
                  Superannuation/Pension
                </SHTypography>
              )}
            </FRCell>
          </SHTableRow>
        </SHTableHead>
        <SHTableBody
          sx={{
            "&.MuiTableBody-root::before": {
              lineHeight: "0px",
              fontSize: "0px",
            },
          }}
        >
          {fee?.features?.map((row) => {
            const {
              showAndAllowUpdateIDPS,
              showAndAllowUpdatePension,
              showAndAllowUpdateOther,
            } = row;
            return (
              (showAndAllowUpdateIDPS ||
                showAndAllowUpdatePension ||
                (showAndAllowUpdateOther && isOtherFee)) && (
                <SHTableRow
                  key={row.id}
                  sx={{
                    borderBottomWidth: 1,
                    backgroundColor: "transparent !important",
                  }}
                >
                  <FRCell>
                    <SHTypography variant="body4">{row?.name}</SHTypography>
                    {!isEmpty(row.description) && (
                      <InformationButton content={row.description} />
                    )}
                  </FRCell>
                  <FRCell>
                    {(showAndAllowUpdateIDPS ||
                      (showAndAllowUpdateOther && isOtherFee)) && (
                      <SHTypography variant="body4">
                        {renderFeeValue(
                          !isOtherFee
                            ? row?.subProductData?.idpsValue
                            : row?.subProductData?.otherValue,
                          row?.fieldType,
                          row?.lovData,
                        )}
                      </SHTypography>
                    )}
                  </FRCell>
                  <FRCell>
                    {!isOtherFee && showAndAllowUpdatePension && (
                      <SHTypography variant="body4">
                        {renderFeeValue(
                          row?.subProductData?.pensionValue,
                          row?.fieldType,
                          row?.lovData,
                        )}
                      </SHTypography>
                    )}
                  </FRCell>
                </SHTableRow>
              )
            );
          })}
        </SHTableBody>
      </SHTable>
    </SHTableContainer>
  ) : (
    <></>
  );
};
