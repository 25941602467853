import DefaultProductLogo from "@assets/images/logo/default-product-logo.png";
import { PlusButton } from "@components/buttons/plus";
import {
  SHAvatar,
  SHStack,
  SHTableHeadProps,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { EditSVG, PlusSVG, WarningSVG } from "@components/svgs";
import { useManagedAccount } from "@hooks/useManagedAccount";
import { SelectedSubProductDTO } from "@models/managed-accounts/entities/step/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { useTheme } from "@mui/material";
import {
  FeeAnalysisTableCell,
  FeeAnalysisTableCellProps,
  RenderNumericFeesValue,
} from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-cell";
import { generateManageAccountColorCell } from "@pages/managed-accounts/_id/steps/fee-analysis/util";
import { sortAscByOrder } from "@utils";
import { isEmpty } from "lodash";
import { MultipleInformationButton } from "../../buttons/multiple-information";

export interface FeeAnalysisTableHeadProps {
  title: string;
  tableViewMode?: FeesDisplayStyle;
  selectedSubProducts?: SelectedSubProductDTO[];
  onModifyComment?: (platformId: string, comment?: string) => void;
}

export const FeeAnalysisTableHead = ({
  title,
  tableViewMode,
  selectedSubProducts,
  onModifyComment,
}: FeeAnalysisTableHeadProps &
  FeeAnalysisTableCellProps &
  SHTableHeadProps) => {
  const { palette } = useTheme();

  const {
    ui: { isReadOnly },
  } = useManagedAccount();

  const renderTableHeadBanner = (platform: SelectedSubProductDTO) => {
    const bannerProps = {
      height: "25px",
      sx: {
        margin: "15px -16px -15px -16px",
      },
      className: "bannerContentStack",
    };

    if (
      platform.isComingSoon ||
      platform.isMissingAllInvestments ||
      platform.isMissingInvestment
    ) {
      return (
        <SHStack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          bgcolor={
            platform.isComingSoon ? palette.warning.main : palette.error.main
          }
          {...bannerProps}
        >
          {platform.isComingSoon ? (
            <SHTypography
              variant="body3"
              colorVariant="white"
              paddingBottom="-2px"
              fontSize="12px"
            >
              Coming soon
            </SHTypography>
          ) : (
            <>
              <WarningSVG />
              <SHTypography
                variant="body3"
                colorVariant="white"
                paddingBottom="-2px"
                fontSize="12px"
              >
                {platform.isMissingAllInvestments
                  ? "Missing all models"
                  : "Missing models"}
              </SHTypography>
              {platform.isMissingInvestment &&
                !platform.isMissingAllInvestments && (
                  <MultipleInformationButton
                    margin={"0px 0px 0px 0px"}
                    title={platform.missingInvestmentsTooltip?.header}
                    data={platform.missingInvestmentsTooltip?.data}
                    color={palette.common.white}
                    hoverColor={palette.grey[200]}
                    activeColor={palette.warning.dark}
                  />
                )}
            </>
          )}
        </SHStack>
      );
    }

    return (
      <SHStack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        {...bannerProps}
      ></SHStack>
    );
  };

  const renderTableHead = (platform: SelectedSubProductDTO) => {
    return (
      <>
        <SHStack
          className={"maProductNameHeaderCell"}
          spacing={0.5}
          sx={{
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <SHStack
            gap={"10px"}
            direction={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              height: "100%",
            }}
            className="productStack"
          >
            <SHStack
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              className="productStack__productInfo"
              spacing={0.5}
            >
              <SHAvatar
                src={
                  platform.productLogoUrl
                    ? platform.productLogoUrl
                    : DefaultProductLogo
                }
                width={68}
                height={68}
                isCircular={false}
                className="productStack__Image"
              />
              <SHTypography
                variant="subtitle1"
                sx={{
                  fontSize: 15,
                  fontWeight: 700,
                  wordBreak: "break-word",
                  lineHeight: "110%",
                }}
                className="productStack__productName"
              >
                {platform.productName}
                <br /> {platform.name}
              </SHTypography>
            </SHStack>
            <SHStack
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              className="productStack__Overall"
            >
              <SHTypography
                variant="body3"
                colorVariant="third"
                className="overall-text"
              >
                Total
              </SHTypography>
              <SHTypography
                variant="body2"
                lineHeight={"150%"}
                sx={{
                  fontWeight: 700,
                  fontSize: 24,
                }}
                className="overall-percent"
              >
                <RenderNumericFeesValue
                  value={platform.totalFees}
                  valuePercentage={platform.totalFeesInPercent}
                  tableViewMode={tableViewMode}
                />
              </SHTypography>
            </SHStack>
          </SHStack>

          <SHStack
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <SHStack
              direction="row"
              alignItems={"center"}
              justifyContent={"center"}
              spacing={1}
              className="buttonStack"
            >
              <PlusButton
                variant="outlined"
                size="medium"
                disabled={isReadOnly}
                startIcon={
                  isEmpty(platform.comment?.comment) ? <PlusSVG /> : <EditSVG />
                }
                onClick={
                  typeof onModifyComment === "function"
                    ? () =>
                        onModifyComment(platform.id, platform?.comment?.comment)
                    : () => {}
                }
                className="commentButton"
              >
                Comment
              </PlusButton>
            </SHStack>
          </SHStack>

          <SHStack marginTop={"0px !important"}>
            {renderTableHeadBanner(platform)}
          </SHStack>
        </SHStack>
      </>
    );
  };

  return (
    <SHTableRow>
      <FeeAnalysisTableCell isFirstColumn colorCell="White">
        <SHStack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <SHTypography
            variant={"subtitle1"}
            lineHeight={"150%"}
            sx={{
              fontWeight: 700,
            }}
          >
            {title}
          </SHTypography>
        </SHStack>
      </FeeAnalysisTableCell>
      {sortAscByOrder(selectedSubProducts ?? [])?.map((platform, index) => (
        <FeeAnalysisTableCell
          key={index}
          colorCell={generateManageAccountColorCell(platform) ?? "White"}
        >
          {renderTableHead(platform)}
        </FeeAnalysisTableCell>
      ))}
    </SHTableRow>
  );
};
