import { useTheme } from "@mui/material";
import {
  PDFHeaderDarkLogoS3,
  PDFHeaderLightLogoS3,
} from "@pages/platform-analysis/components/buttons/export-pdf/config";
import { FIRST_PAGE_HEADER_HEIGHT } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Image, Text, View } from "@react-pdf/renderer";

interface PDFPageHeaderProps {
  isFirstPage?: boolean;
}

export const PDFPageHeader = ({ isFirstPage = false }: PDFPageHeaderProps) => {
  const { palette } = useTheme();
  const { pageStyle, typographyStyle } = useStyles();

  return (
    <View
      style={
        isFirstPage
          ? { ...pageStyle.header, height: FIRST_PAGE_HEADER_HEIGHT }
          : { ...pageStyle.header }
      }
    >
      <Image
        src={isFirstPage ? PDFHeaderDarkLogoS3 : PDFHeaderLightLogoS3}
        style={
          isFirstPage ? { width: 223, height: 46 } : { width: 172, height: 35 }
        }
      />
      <Text
        style={{
          ...(isFirstPage ? typographyStyle.h1 : typographyStyle.h2),
          color: isFirstPage ? palette.common.white : palette.common.black,
        }}
      >
        Platform analysis summary
      </Text>
    </View>
  );
};
