import {
  SHBox,
  SHStack,
  SHTableCell,
  SHTableRow,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import {
  ArrowDownSVG,
  ESGPoliciesSVG,
  FinancialStrengthSVG,
  OperationalSVG,
} from "@components/svgs";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import { BusinessMetricTypeId } from "@models/configuration";
import {
  AnalysisFeatureDTO,
  AnalysisSectionDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { Collapse, useTheme } from "@mui/material";
import { BusinessMetricFeatureTableRow } from "@pages/platform-analysis/_id/steps/business-metric/analysis/components/feature-row";
import {
  FeatureAnalysisTableCell,
  FeatureAnalysisTableCellProps,
} from "@pages/platform-analysis/_id/steps/feature/analysis/components/table-cell";
import { useState } from "react";

interface BusinessAnalysisGroupTableRowProps {
  groupData?: AnalysisSectionDTO;
  totalPlatforms?: number;
}

export const renderBusinessMetricIcon = (id?: BusinessMetricTypeId) => {
  if (id === BusinessMetricTypeId.Esg) return <ESGPoliciesSVG />;
  if (id === BusinessMetricTypeId.Operational) return <OperationalSVG />;
  if (id === BusinessMetricTypeId.FinancialStrength)
    return <FinancialStrengthSVG />;
};

export const BusinessAnalysisGroupTableRow = ({
  groupData,
  totalPlatforms,
  ...others
}: BusinessAnalysisGroupTableRowProps &
  FeatureAnalysisTableCellProps &
  SHTableRowProps) => {
  const { palette } = useTheme();
  const { setTotalCollapsedBusinessMetricRows } = usePlatformAnalysis();

  const [expandFeatures, setExpandFeatures] = useState(true);

  return (
    <>
      <SHTableRow
        sx={{
          background: `${palette.common.white} !important`,
        }}
        {...others}
      >
        <FeatureAnalysisTableCell isFirstColumn>
          <SHStack
            direction={"row"}
            alignItems={"center"}
            justifyContent="space-between"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setExpandFeatures(!expandFeatures);
              const totalSelectedFeature = groupData?.totalSelectedFeature ?? 0;
              setTotalCollapsedBusinessMetricRows(
                expandFeatures ? totalSelectedFeature : -totalSelectedFeature,
              );
            }}
          >
            <SHStack
              direction={"row"}
              alignItems={"center"}
              gap={"5px"}
              height={42}
            >
              {renderBusinessMetricIcon(groupData?.businessMetricTypeId)}
              <SHTypography
                variant="subtitle2"
                paddingTop={"5px"}
                fontSize={13}
              >
                {groupData?.businessMetricTypeId === BusinessMetricTypeId.Esg
                  ? "ESG policies"
                  : groupData?.businessMetricTypeName}
              </SHTypography>
            </SHStack>
            <SHBox
              alignItems={"center"}
              sx={{
                display: "flex",
                transition: "all ease-in-out 0.2s",
                transform: `rotate(${expandFeatures ? 0 : 180}deg)`,
              }}
            >
              <ArrowDownSVG width={16} height={16} />
            </SHBox>
          </SHStack>
        </FeatureAnalysisTableCell>
        {Array.from(Array(totalPlatforms).keys()).map((index) => (
          <FeatureAnalysisTableCell key={index}></FeatureAnalysisTableCell>
        ))}
      </SHTableRow>

      <SHTableRow>
        <SHTableCell
          colSpan={groupData?.analysisData?.length! + 1}
          sx={{ padding: 0, left: 0, borderLeft: "none !important" }}
        >
          <Collapse in={expandFeatures} timeout="auto">
            {groupData?.features?.map((feature: AnalysisFeatureDTO) => (
              <BusinessMetricFeatureTableRow
                key={feature?.id}
                featureData={feature}
              />
            ))}
          </Collapse>
        </SHTableCell>
      </SHTableRow>
    </>
  );
};
