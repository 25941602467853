import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import {
  AdviserUserDTO,
  CreateAdviserUserDTO,
} from "@models/practice/entities/practiceUser";
import { UserStatus } from "@models/users/enums/status";
import { compileAdviserUser } from "@services/practice/util";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getPracticeUser(
  adviserFirmId?: string,
  adviserUserId?: string,
): Promise<APIResponse<AdviserUserDTO>> {
  const response = await APIGatewayService.get<AdviserUserDTO>(
    generatePath(APIExtRoutes.adviserUser, { adviserFirmId, adviserUserId }),
  );

  if (response?.data) {
    compileAdviserUser(response.data);
  }

  return handleErrorProxy(response);
}

export async function postPracticeUser(
  adviserUser: CreateAdviserUserDTO,
): Promise<APIResponse<AdviserUserDTO>> {
  const response = await APIGatewayService.post<CreateAdviserUserDTO>(
    APIExtRoutes.adviserUsers,
    adviserUser,
  );

  if (response?.data) {
    compileAdviserUser(response.data);
  }

  return handleErrorProxy(response, "Practice user created successfully.");
}

export async function putPracticeUser(
  adviserFirmId?: string,
  practiceUser?: AdviserUserDTO,
): Promise<APIResponse<AdviserUserDTO>> {
  const response = await APIGatewayService.put<AdviserUserDTO>(
    generatePath(APIExtRoutes.updateProfileAdviserUser, { adviserFirmId }),
    practiceUser,
  );

  if (response?.data) {
    compileAdviserUser(response.data);
  }
  
  return handleErrorProxy(response, "Changes have been saved.");
}

export async function patchPracticeUserStatus(
  adviserFirmId: string,
  adviserUserId: string,
  status: UserStatus,
): Promise<APIResponse<{ status: UserStatus }>> {
  const response = await APIGatewayService.patch<{ status: UserStatus }>(
    generatePath(APIExtRoutes.updateAdviserUserStatus, {adviserFirmId, adviserUserId , status }),
  );
  return handleErrorProxy(response, "Changes have been saved.");
}

export async function putCompleteProfilePracticeUser(
  adviserFirmId?: string,
  adviserUserId?: string,
  practiceUser?: AdviserUserDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.completeProfilePracticeUser, { adviserFirmId, adviserUserId }),
    practiceUser,
  );
  return handleErrorProxy(response, "Welcome to SuitabilityHub. Happy researching!");
}

export async function sendResetPasswordAdviserUser(
  adviserFirmId?: string,
  adviserUserId?: string
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.post<string>(
    generatePath(APIExtRoutes.adviserUserResetPassword, { adviserFirmId, adviserUserId }),
  );

  return handleErrorProxy(response, "Password reset email sent.");
}

export async function resendInviteEmailAdviserUser(
  adviserFirmId?: string,
  adviserUserId?: string
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.post<string>(
    generatePath(APIExtRoutes.resendInviteEmailAdviserUser, { adviserFirmId, adviserUserId }),
  );

  return handleErrorProxy(response, "Welcome email sent.");
}
