import { SHIconLoading } from "@components/design-systems";
import { DeleteSVG } from "@components/svgs";
import { IconButton, Tooltip, tooltipClasses, useTheme } from "@mui/material";
export interface GrayDeleteButtonProps {
  width?: string;
  height?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
  [key: string]: any;
}
export const GrayDeleteButton = ({
  width = "30px",
  height = "30px",
  isLoading = false,
  disabled = false,
  tooltipTitle,
  ...others
}: any) => {
  const { shadows, palette } = useTheme();
  return (
    <Tooltip
      title={tooltipTitle}
      enterDelay={700}
      enterNextDelay={700}
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              {
                marginTop: "3px",
              },
          },
        },
      }}
    >
      <IconButton
        {...others}
        disableRipple
        disabled={disabled}
        sx={{
          width,
          height,
          p: 0,
          borderStyle: "solid",
          borderWidth: "1px",
          backgroundColor: palette.common.white,
          borderColor: palette.common.black,
          boxShadow: shadows[1],
          "&:hover": {
            backgroundColor: palette.error.light,
            borderColor: palette.error.dark,
            boxShadow: "none",
            "&>svg>path": {
              fill: palette.common.white,
            },
          },
          "&:active": {
            backgroundColor: palette.error.light,
            borderColor: palette.error.dark,
            boxShadow: `inset 0 4px 4px ${palette.error.dark}`,
            "&>svg>path": {
              fill: palette.common.white,
            },
          },
          "&:disabled": {
            border: `1px solid ${palette.text.disabled}`,
            backgroundColor: palette.common.white,
            "&>svg>path": {
              fill: palette.text.disabled,
            },
          },
        }}
      >
        {isLoading ? (
          <SHIconLoading size={15} />
        ) : (
          <DeleteSVG color={palette.common.black} />
        )}
      </IconButton>
    </Tooltip>
  );
};
