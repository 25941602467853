import {
  SHBox,
  SHContainer,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTypography,
} from "@components/design-systems";
import { useReview } from "@hooks/useReview";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { PlatformCommentDialog } from "@models/platform-analysis/entities/comment";
import { ReviewDecisionStatus } from "@models/reviews/entities/status";
import { ReviewStep } from "@models/reviews/enums/step";
import { useTheme } from "@mui/material";
import { ChangeRichTextDialog } from "@pages/platform-analysis/_id/steps/summary/components/change-rich-text-dialog";
import { DisqualifyAllPlatformDialog } from "@pages/reviews/_id/steps/feature/analysis/components/dialogs/confirm-select-all";
import { NotifyUnShortedListDialog } from "@pages/reviews/_id/steps/feature/analysis/components/dialogs/notify-un-shorted-list";
import {
  GroupByDecisionStatus,
  TABLE_ROW_HEIGHT,
  UpdateDecisionStatus,
} from "@pages/reviews/_id/steps/feature/analysis/config";

import { DateFormat } from "@constants/format";
import { ReviewStepBusinessMetricContainerDTO } from "@models/reviews/entities/steps/businessMetric";
import {
  ReviewSectionDTO,
  ReviewSelectedProductDTO,
  UpdateDecisionMarkingDTO,
} from "@models/reviews/entities/steps/feature";
import { RootState } from "@redux/store";
import {
  disqualifyAllPlatform,
  putReviewDecisionMaking,
} from "@services/reviews/reviewService";
import { format } from "date-fns";
import { debounce, groupBy, isEmpty, mapValues } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
  WheelEvent,
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { FeatureAnalysisSkeleton } from "../../feature/analysis/skeleton";
import { ReviewBusinessMetricGroupTableRow } from "./components/section-row";
import { BusinessTableHead } from "./components/table-head";
import "./styles.scss";
import { flatBusinessReviewData } from "./util";

export interface ReviewBusinessMetricAnalysisProps extends StepCompProps {}

export const ReviewBusinessMetricAnalysis: ForwardRefRenderFunction<
  StepCompRef,
  StepCompProps
> = ({ stepNumber }, ref) => {
  const { palette } = useTheme();
  const { reviewId } = useParams<{ reviewId: string }>();

  const [loadedFromStore, setLoadedFromStore] = useState(false);
  const [updatingComment, setUpdatingComment] = useState(false);
  const [updatingDecisionStatus, setUpdatingDecisionStatus] = useState<
    UpdateDecisionStatus | undefined
  >(undefined);
  const [totalRowHeight, setTotalRowHeight] = useState(0);
  const [isExpandHeader, setIsExpandHeader] = useState(true);

  const [businessMetricReview, setBusinessMetricReview] =
    useState<ReviewStepBusinessMetricContainerDTO>();
  const [initialBusinessMetricReview, setInitialBusinessMetricReview] =
    useState<ReviewStepBusinessMetricContainerDTO>();

  const [commentDialog, setCommentDialog] = useState<
    PlatformCommentDialog | undefined
  >();

  const [disqualifyAllDialog, setDisqualifyAllDialog] = useState<
    | {
        callback: (value: boolean | Promise<boolean>) => void;
        productsGrouped: GroupByDecisionStatus;
      }
    | undefined
  >(undefined);

  const [isSubmittingDisqualifyDialog, setIsSubmittingDisqualifyDialog] =
    useState(false);

  const [notifyUnShortedListDialog, setNotifyUnShortedListDialog] =
    useState(false);

  const {
    ui: { isLoading, filterBusinessMetric, totalCollapsedBusinessMetricRows },
    review,
  } = useSelector((state: RootState) => state.review);

  const {
    loadReviewStep,
    setTotalCollapsedBusinessMetricRows,
    updateReviewDecisionMaking,
  } = useReview();

  let timeoutIdScrollDown = -1;
  let isScrollUp = true;
  let isMouseScroll = true;

  const handleScrollTop = debounce(
    () => {
      setIsExpandHeader(true);
    },
    150,
    { leading: false, trailing: true },
  );
  const handleOnWheel = (event: WheelEvent<HTMLDivElement>) => {
    const scrollTop = Math.floor(event.currentTarget.scrollTop);
    let isTouchpadScrollToTop;

    isScrollUp = event.deltaY < 0;
    isMouseScroll = Math.abs(event.deltaY) > 50;
    isTouchpadScrollToTop = scrollTop === 0 && isScrollUp && !isMouseScroll;

    if (isTouchpadScrollToTop) {
      const selectors = [
        ".tableHead",
        ".businessTableContainer",
        ".businessHeadContainer",
      ];
      const elements = selectors.map((selector) =>
        document.querySelector(selector),
      );
      const [tableHead, tableContainer, businessReviewHeader] = elements;

      [tableHead, tableContainer, businessReviewHeader].forEach((element) =>
        element?.classList.remove("sticky"),
      );
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const selectors = [
      ".tableBody",
      ".tableHead",
      ".businessTableContainer",
      ".businessHeadContainer",
    ];

    const elements = selectors.map((selector) =>
      document.querySelector(selector),
    );

    const [tableBody, tableHead, tableContainer, businessReviewHeader] =
      elements;

    const scrollLeft = event.currentTarget.scrollLeft;
    const scrollTop = Math.floor(event.currentTarget.scrollTop);
    const containerHeight = tableContainer?.clientHeight ?? 0;

    if (scrollLeft > 0) {
      tableBody?.classList.add("sticky");
      tableHead?.classList.add("stickyFirstCell");
    } else {
      tableBody?.classList.remove("sticky");
      tableHead?.classList.remove("stickyFirstCell");
    }

    if (scrollTop > 0 && totalRowHeight > containerHeight && !isScrollUp) {
      [tableHead, businessReviewHeader, tableContainer].forEach((element) =>
        element?.classList.add("sticky"),
      );

      if (isExpandHeader) {
        event.currentTarget.scrollTop = 5;
        timeoutIdScrollDown = window.setTimeout(() => {
          setIsExpandHeader(false);
        }, 150);
      }
    } else if (scrollTop === 0 && isScrollUp && isMouseScroll) {
      event.currentTarget.scrollTop = 1;

      if (isExpandHeader) {
        [tableHead, businessReviewHeader, tableContainer].forEach((element) =>
          element?.classList.remove("sticky"),
        );
      }

      handleScrollTop();
    }
  };

  const handleOnChangeDecisionStatus = async (
    platformId: string,
    decisionStatus: ReviewDecisionStatus,
  ) => {
    setUpdatingDecisionStatus({ platformId, decisionStatus });

    const { isSuccess, data } = await updateReviewDecisionMaking({
      reviewId: reviewId,
      reviewStep: ReviewStep.BusinessMetric,
      productId: platformId,
      decisionStatus:
        decisionStatus !== ReviewDecisionStatus.Undecided
          ? decisionStatus
          : null,
      comment: null,
    } as UpdateDecisionMarkingDTO);

    setUpdatingDecisionStatus(undefined);

    if (isSuccess) {
      const newInitBusinessMetricAnalysisReview = {
        ...initialBusinessMetricReview,
        selectedProducts: initialBusinessMetricReview?.selectedProducts?.map(
          (platform) => {
            if (platform.id !== platformId) return platform;
            return {
              ...platform,
              previousDecisionStatus: platform.decisionStatus,
              // decisionStatus:
              //   decisionStatus !== ReviewDecisionStatus.Undecided
              //     ? decisionStatus
              //     : platform.previousDecisionStatus,
              decisionStatus: data?.decisionStatus,
            };
          },
        ),
      } as ReviewStepBusinessMetricContainerDTO;
      setInitialBusinessMetricReview(newInitBusinessMetricAnalysisReview);
    }
  };

  const handleOnChangeComment = async (comment: string | null) => {
    setUpdatingComment(true);

    const { isSuccess } = await putReviewDecisionMaking({
      reviewId: reviewId,
      reviewStep: ReviewStep.BusinessMetric,
      productId: commentDialog?.productId,
      decisionStatus: null,
      comment: comment,
    } as UpdateDecisionMarkingDTO);

    setUpdatingComment(false);
    setCommentDialog(undefined);

    if (isSuccess) {
      const newInitBusinessMetricAnalysisReview = {
        ...initialBusinessMetricReview,
        selectedProducts: initialBusinessMetricReview?.selectedProducts?.map(
          (platform) => {
            if (platform.id !== commentDialog?.productId) return platform;
            return {
              ...platform,
              comment: {
                comment: comment,
              },
            };
          },
        ),
      } as ReviewStepBusinessMetricContainerDTO;
      setInitialBusinessMetricReview(newInitBusinessMetricAnalysisReview);
    }
  };

  const getDisqualifyAllPlatform = async (
    productsGrouped: GroupByDecisionStatus,
  ): Promise<boolean> => {
    return await new Promise((res) => {
      setDisqualifyAllDialog({
        callback: res,
        productsGrouped,
      });
    });
  };

  const handleOnDisqualifyAllPlatform = async () => {
    if (!reviewId) return false;

    setIsSubmittingDisqualifyDialog(true);
    const { isSuccess } = await disqualifyAllPlatform(
      reviewId,
      ReviewStep.BusinessMetric,
    );
    setIsSubmittingDisqualifyDialog(false);

    disqualifyAllDialog?.callback(isSuccess);
  };

  const handleOnSubmit = async () => {
    const groupByDecisionStatus: GroupByDecisionStatus = mapValues(
      groupBy(businessMetricReview?.selectedProducts, "decisionStatus"),
      (group) => group.map((obj) => obj.id),
    );

    const UndecidedPlatformIds =
      groupByDecisionStatus[ReviewDecisionStatus.Undecided];
    const shortedListPlatformIds =
      groupByDecisionStatus[ReviewDecisionStatus.Shortlisted];

    if (isEmpty(shortedListPlatformIds)) {
      setNotifyUnShortedListDialog(true);
      return false;
    }

    if (!isEmpty(UndecidedPlatformIds)) {
      const isYes = await getDisqualifyAllPlatform(groupByDecisionStatus);
      setDisqualifyAllDialog(undefined);
      return isYes;
    }

    return true;
  };

  const loadFeatureReview = async () => {
    if (reviewId) {
      const review = await loadReviewStep({
        reviewId: reviewId,
        stepId: ReviewStep.BusinessMetric,
      });

      if (review && review.businessMetric?.all) {
        setBusinessMetricReview(review?.businessMetric.all);
        setInitialBusinessMetricReview(review?.businessMetric.all);
      }
    }
    setLoadedFromStore(true);
  };

  useEffectOnce(() => {
    loadFeatureReview();
  });

  useEffect(() => {
    if (businessMetricReview && initialBusinessMetricReview) {
      if (filterBusinessMetric === undefined)
        setBusinessMetricReview(initialBusinessMetricReview);
      else {
        setBusinessMetricReview({
          ...businessMetricReview,
          selectedProducts: initialBusinessMetricReview
            ? initialBusinessMetricReview?.selectedProducts.filter(
                (product: ReviewSelectedProductDTO) =>
                  product.decisionStatus === filterBusinessMetric,
              )
            : businessMetricReview.selectedProducts,
        });
      }
    }
    //eslint-disable-next-line
  }, [initialBusinessMetricReview, filterBusinessMetric]);

  useEffect(() => {
    if (
      businessMetricReview?.sections &&
      !isEmpty(businessMetricReview?.sections)
    ) {
      const flatRows =
        flatBusinessReviewData(businessMetricReview?.sections) ?? [];

      let totalRows = flatRows?.length ?? 0;
      if (totalCollapsedBusinessMetricRows > 0)
        totalRows -= totalCollapsedBusinessMetricRows;

      setTotalRowHeight(totalRows * TABLE_ROW_HEIGHT);
    }
  }, [businessMetricReview, totalCollapsedBusinessMetricRows]);

  useEffect(() => window.clearTimeout(timeoutIdScrollDown));

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep, isNext) => {
      setTotalCollapsedBusinessMetricRows(-totalCollapsedBusinessMetricRows);
      if (isNext) {
        return await handleOnSubmit();
      }
      return true;
    },
    onChangeOtherStep: async () => {
      setTotalCollapsedBusinessMetricRows(-totalCollapsedBusinessMetricRows);
      return true;
    },
    onSubmit: async () => {
      return await handleOnSubmit();
    },
  }));

  if (isLoading || !loadedFromStore) return <FeatureAnalysisSkeleton />;

  return (
    <SHContainer
      maxWidth="lg2"
      sx={{
        px: {
          xs: "16px",
          lg2: 0,
        },
        pt: "25px",
        flexGrow: 1,
        height: "1px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SHStack
        direction={"row"}
        justifyContent={"space-between"}
        className="businessHeadContainer"
        sx={{ transition: "all 0.5s ease" }}
      >
        <StepName name={"Business metric review"} stepNumber={stepNumber} />
        <SHBox
          sx={{
            display: "inline-flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <SHTypography
            variant="body3"
            colorVariant="third"
            textAlign={"left"}
            lineHeight="120%"
            sx={{
              marginTop: "10px",
            }}
          >
            {review?.databaseVersion
              ? `Data valid: ${format(
                  new Date(review?.databaseVersion),
                  DateFormat,
                )}`
              : ""}
          </SHTypography>
          {/* TODO: feature for next sprint */}
          {/* {!isNil(businessMetricReview) && (
            <SHStack
              sx={{
                alignItems: "flex-end",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <FilterButton
                buttonTitle={"Qualification"}
                filterData={QualificationFilterOptions}
                width={"275px"}
                labelAll={"All platforms"}
                zIndex={10}
                isMultiple={false}
                onChangeFilter={(filters) => {
                  setFilterBusinessMetric(filters ? filters[0].value : undefined);
                }}
              />
            </SHStack>
          )} */}
        </SHBox>
      </SHStack>
      <SHTableContainer
        sx={{
          marginY: 3,
          flexGrow: 1,
          height: "1px",
          overflow: "overlay",
          maxWidth: "max-content",
          "::-webkit-scrollbar": {
            boxShadow: "none",
          },
          "::-webkit-scrollbar-thumb": {
            boxShadow: "none",
          },
        }}
        onScroll={handleScroll}
        onWheel={handleOnWheel}
        className={"businessTableContainer"}
      >
        <SHTable
          stickyHeader
          className="businessTable"
          sx={{
            th: {
              borderLeft: "none",
              "&:first-child": {
                borderLeft: `1px solid ${palette.secondary[100]}`,
              },
            },
            td: {
              borderTop: "none",
              borderLeft: "none",
            },
          }}
        >
          <BusinessTableHead
            className="tableHead"
            selectedProducts={businessMetricReview?.selectedProducts}
            onChangeDecisionStatus={handleOnChangeDecisionStatus}
            updatingDecisionStatus={updatingDecisionStatus}
            onModifyComment={(platformId: string, comment?: string) => {
              setCommentDialog({
                comment: comment ?? null,
                productId: platformId,
              });
            }}
          />
          <SHTableBody
            sx={{
              "&>tr:first-child td": {
                borderTop: `1px solid ${palette.secondary[100]}`,
              },
              "& tr>td:first-child": {
                borderLeft: `1px solid ${palette.secondary[100]}`,
              },
            }}
            className="tableBody"
          >
            {businessMetricReview?.sections?.map((group: ReviewSectionDTO) => (
              <ReviewBusinessMetricGroupTableRow
                key={group?.id}
                groupData={group}
                selectedProducts={businessMetricReview?.selectedProducts}
              />
            ))}
          </SHTableBody>
        </SHTable>
      </SHTableContainer>
      {commentDialog && (
        <ChangeRichTextDialog
          onSubmit={handleOnChangeComment}
          title={
            isEmpty(commentDialog.comment) ? "Add comment" : "Edit comment"
          }
          placeHolder={"Enter your comment"}
          initialContent={commentDialog.comment ?? null}
          isSubmitting={updatingComment}
          onClose={() => {
            setCommentDialog(undefined);
          }}
          maxLength={1500}
          isCommentBox
        />
      )}

      {disqualifyAllDialog && (
        <DisqualifyAllPlatformDialog
          productsGrouped={disqualifyAllDialog.productsGrouped}
          onCancel={() => disqualifyAllDialog.callback(false)}
          onSubmit={handleOnDisqualifyAllPlatform}
          isSubmitting={isSubmittingDisqualifyDialog}
        />
      )}

      {notifyUnShortedListDialog && (
        <NotifyUnShortedListDialog
          onOK={() => setNotifyUnShortedListDialog(false)}
        />
      )}
    </SHContainer>
  );
};
