import {
  SHBox,
  SHContainer,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTypography,
} from "@components/design-systems";
import { DateFormat } from "@constants/format";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { PlatformCommentDialog } from "@models/platform-analysis/entities/comment";
import { AnalysisStepBusinessMetricDTO } from "@models/platform-analysis/entities/steps/businessMetric";
import {
  AnalysisCommentDTO,
  AnalysisSectionDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { useTheme } from "@mui/material";
import { BusinessAnalysisGroupTableRow } from "@pages/platform-analysis/_id/steps/business-metric/analysis/components/group-row";
import { PlatformAnalysisTableHead } from "@pages/platform-analysis/_id/steps/feature/analysis/components/table-head";
import { FeatureAnalysisSkeleton } from "@pages/platform-analysis/_id/steps/feature/analysis/skeleton";
import { ChangeRichTextDialog } from "@pages/platform-analysis/_id/steps/summary/components/change-rich-text-dialog";
import { flatBusinessReviewData } from "@pages/reviews/_id/steps/business-metric/analysis/util";
import { RootState } from "@redux/store";
import { hexToRGBA } from "@utils";
import { format } from "date-fns";
import { debounce, isEmpty, isNil } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
  WheelEvent,
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import "./styles.scss";
import { TABLE_ROW_HEIGHT } from "@pages/reviews/_id/steps/feature/analysis/config";

export interface BusinessMetricAnalysisProps extends StepCompProps {}

export const BusinessMetricAnalysis: ForwardRefRenderFunction<
  StepCompRef,
  BusinessMetricAnalysisProps
> = ({ stepNumber }: BusinessMetricAnalysisProps, ref) => {
  const { palette } = useTheme();
  const { analysisId } = useParams<{ analysisId: string }>();

  const {
    ui: { isLoading, totalCollapsedBusinessMetricRows },
    platformAnalysis,
  } = useSelector((state: RootState) => state.platformAnalysis);
  const {
    isModifyingComment,
    modifyComment,
    loadPlatformAnalysis,
    setTotalCollapsedBusinessMetricRows,
  } = usePlatformAnalysis();

  const [totalRowHeight, setTotalRowHeight] = useState(0);
  const [loadedFromStore, setLoadedFromStore] = useState(false);
  const [commentDialog, setCommentDialog] = useState<
    PlatformCommentDialog | undefined
  >();
  const [businessMetricAnalysis, setBusinessMetricFeatureAnalysis] =
    useState<AnalysisStepBusinessMetricDTO>();
  const [isExpandHeader, setIsExpandHeader] = useState(true);
  let timeoutIdScrollDown = -1;
  let isScrollUp = true;
  let isMouseScroll = true;

  const handleScrollTop = debounce(
    () => {
      setIsExpandHeader(true);
    },
    150,
    { leading: false, trailing: true },
  );

  const handleOnWheel = (event: WheelEvent<HTMLDivElement>) => {
    const scrollTop = Math.floor(event.currentTarget.scrollTop);
    let isTouchpadScrollToTop;

    isScrollUp = event.deltaY < 0;
    isMouseScroll = Math.abs(event.deltaY) > 50;
    isTouchpadScrollToTop = scrollTop === 0 && isScrollUp && !isMouseScroll;

    if (isTouchpadScrollToTop) {
      const selectors = [
        ".tableHead",
        ".businessTableContainer",
        ".businessHeadContainer",
      ];
      const elements = selectors.map((selector) =>
        document.querySelector(selector),
      );
      const [tableHead, tableContainer, businessReviewHeader] = elements;

      [tableHead, businessReviewHeader, tableContainer].forEach((element) =>
        element?.classList.remove("sticky"),
      );
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const selectors = [
      ".tableBody",
      ".tableHead",
      ".businessTableContainer",
      ".businessHeadContainer",
    ];

    const elements = selectors.map((selector) =>
      document.querySelector(selector),
    );

    const [tableBody, tableHead, tableContainer, businessReviewHeader] =
      elements;

    const scrollLeft = event.currentTarget.scrollLeft;
    const scrollTop = Math.floor(event.currentTarget.scrollTop);
    const containerHeight = tableContainer?.clientHeight ?? 0;

    if (scrollLeft > 0) {
      tableBody?.classList.add("sticky");
      tableHead?.classList.add("stickyFirstCell");
    } else {
      tableBody?.classList.remove("sticky");
      tableHead?.classList.remove("stickyFirstCell");
    }

    if (scrollTop > 0 && totalRowHeight > containerHeight && !isScrollUp) {
      [tableHead, businessReviewHeader, tableContainer].forEach((element) =>
        element?.classList.add("sticky"),
      );

      if (isExpandHeader) {
        event.currentTarget.scrollTop = 5;
        timeoutIdScrollDown = window.setTimeout(() => {
          setIsExpandHeader(false);
        }, 150);
      }
    } else if (scrollTop <= 0 && isScrollUp && isMouseScroll) {
      event.currentTarget.scrollTop = 1;

      if (isExpandHeader) {
        [tableHead, businessReviewHeader, tableContainer].forEach((element) =>
          element?.classList.remove("sticky"),
        );
      }

      handleScrollTop();
    }
  };

  const handleOnModifyComment = async (comment: string | null) => {
    if (!commentDialog || !analysisId || !commentDialog.productId) return;
    const newComment = await modifyComment(
      analysisId,
      commentDialog.productId,
      AnalysisStep.BusinessMetric,
      comment,
    );
    if (newComment) {
      setCommentDialog(undefined);
      setBusinessMetricFeatureAnalysis({
        ...businessMetricAnalysis,
        selectedProducts: businessMetricAnalysis?.selectedProducts?.map(
          (selectedProduct) => {
            if (selectedProduct.id === commentDialog.productId)
              return {
                ...selectedProduct,
                comment: { ...selectedProduct.comment, comment: comment },
              };
            return selectedProduct;
          },
        ),
      } as AnalysisStepBusinessMetricDTO);
    }
  };

  const loadAnalysis = async () => {
    if (analysisId) {
      const analysis = await loadPlatformAnalysis({
        platformAnalysisId: analysisId,
        stepId: AnalysisStep.BusinessMetric,
      });
      if (analysis && analysis?.businessMetric) {
        setBusinessMetricFeatureAnalysis({
          ...analysis?.businessMetric,
        });
      }
    }
    setLoadedFromStore(true);
  };

  useEffectOnce(() => {
    loadAnalysis();
  });

  useEffect(() => {
    if (
      businessMetricAnalysis?.sections &&
      !isEmpty(businessMetricAnalysis?.sections)
    ) {
      const flatRows =
        flatBusinessReviewData(businessMetricAnalysis?.sections) ?? [];

      let totalRows = flatRows?.length ?? 0;
      if (totalCollapsedBusinessMetricRows > 0)
        totalRows -= totalCollapsedBusinessMetricRows;
      setTotalRowHeight(totalRows * TABLE_ROW_HEIGHT);
    }
  }, [businessMetricAnalysis, totalCollapsedBusinessMetricRows]);

  useEffect(() => window.clearTimeout(timeoutIdScrollDown));

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep, isNext) => {
      setTotalCollapsedBusinessMetricRows(-totalCollapsedBusinessMetricRows);
      return true;
    },
    onChangeOtherStep: async () => {
      setTotalCollapsedBusinessMetricRows(-totalCollapsedBusinessMetricRows);
      return true;
    },
    onSubmit: async () => {
      return true;
    },
  }));

  if (isLoading || !loadedFromStore) return <FeatureAnalysisSkeleton />;

  return (
    <SHContainer
      maxWidth="lg2"
      sx={{
        px: { xs: "16px", lg2: 0 },
        pt: "25px",
        flexGrow: 1,
        height: "1px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SHStack
        direction={"row"}
        justifyContent={"space-between"}
        className="businessHeadContainer"
        sx={{ transition: "all 0.5s ease" }}
      >
        <StepName name={"Business metric analysis"} stepNumber={stepNumber} />
        {!isNil(businessMetricAnalysis) && (
          <SHBox
            sx={{
              display: "inline-flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <SHTypography
              variant="body3"
              colorVariant="third"
              lineHeight="120%"
              sx={{
                marginTop: "10px",
              }}
            >
              {platformAnalysis?.databaseVersion
                ? `Data valid: ${format(
                    new Date(platformAnalysis?.databaseVersion),
                    DateFormat,
                  )}`
                : ""}
            </SHTypography>
          </SHBox>
        )}
      </SHStack>
      {!isNil(businessMetricAnalysis) ? (
        <SHTableContainer
          sx={{
            marginY: 3,
            flexGrow: 1,
            height: "1px",
            overflow: "overlay",
            maxWidth: "max-content",
            "::-webkit-scrollbar": {
              boxShadow: "none",
            },
            "::-webkit-scrollbar-thumb": {
              boxShadow: "none",
            },
          }}
          onScroll={handleScroll}
          onWheel={handleOnWheel}
          className="businessTableContainer"
        >
          <SHTable
            stickyHeader
            className="businessTable"
            style={{
              tableLayout: "auto",
              maxWidth: "max-content",
            }}
            sx={{
              "& th": {
                borderLeft: "none",
                "&:first-child": {
                  borderLeft: `1px solid ${palette.secondary[100]}`,
                },
              },
              "& td": {
                borderTop: "none",
                borderLeft: "none",
              },
            }}
          >
            <PlatformAnalysisTableHead
              className="tableHead"
              platformData={businessMetricAnalysis?.selectedProducts}
              onClickComment={(
                platformId: string,
                comment?: AnalysisCommentDTO,
              ) => {
                setCommentDialog({
                  comment: comment?.comment ?? null,
                  productId: platformId,
                });
              }}
              onDeleteComment={(platformId?: string, commentId?: string) => {}}
            />
            <SHTableBody
              sx={{
                "&>tr:first-child td": {
                  borderTop: `1px solid ${palette.secondary[100]}`,
                },
                "& tr>td:first-child": {
                  borderLeft: `1px solid ${palette.secondary[100]}`,
                },
              }}
              className="tableBody"
            >
              {businessMetricAnalysis?.sections?.map(
                (group: AnalysisSectionDTO) => (
                  <BusinessAnalysisGroupTableRow
                    key={group?.id}
                    groupData={group}
                    totalPlatforms={
                      businessMetricAnalysis?.selectedProducts?.length
                    }
                  />
                ),
              )}
            </SHTableBody>
          </SHTable>
        </SHTableContainer>
      ) : (
        <SHStack
          direction={"row"}
          alignItems="center"
          justifyContent={"center"}
          sx={{
            width: "100%",
            display: "inline-flex",
            alignItems: "flex-end",
            marginY: 3,
            padding: "10px 10px",
            backdropFilter: "blur(0.5px)",
            background: `${hexToRGBA("#c5ddfd", 0.1)}`,
            border: `1px solid ${palette.secondary[100]}`,
          }}
        >
          <SHTypography variant="body4">
            No business metric analysis data.
          </SHTypography>
        </SHStack>
      )}

      {commentDialog && (
        <ChangeRichTextDialog
          onSubmit={handleOnModifyComment}
          title={
            isEmpty(commentDialog.comment) ? "Add comment" : "Edit comment"
          }
          placeHolder={"Enter your comment"}
          initialContent={commentDialog.comment ?? ""}
          isSubmitting={isModifyingComment}
          onClose={() => {
            setCommentDialog(undefined);
          }}
          maxLength={1500}
          isCommentBox
        />
      )}
    </SHContainer>
  );
};
