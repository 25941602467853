import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { SHButtonSkeleton } from "@components/skeletons/design-systems";

export const PracticeTeamSkeleton = () => {
  return (
    <SHStack spacing={"24px"}>
      <SHStack direction={"row"} justifyContent={"space-between"}>
        <SHButtonSkeleton width={300} height={37} />
        <SHButtonSkeleton width={190} height={37} />
      </SHStack>
      <SHStack spacing={"15px"}>
        <SHSkeleton width={"100%"} height={55} />
        <SHSkeleton width={"100%"} height={140} />
      </SHStack>
    </SHStack>
  );
};
