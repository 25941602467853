export class ManagedAccountPortfolioSetupDTO {
  name?: string;
  ownerId: string = "";
  ownerName?: string = "";
  ownerAvatarUrl?: string = "";
  firmId: string = "";
  firmName?: string = "";
  portfolioSize: number = 0;
  selectedInvestments: SelectedInvestmentDTO[] = [];
}

export class ManagedAccountPortfolioSetupCreateDTO extends ManagedAccountPortfolioSetupDTO {
  analysisInclusion: string = "";
  includingAnalysisId?: string | null = null;
}

export class ManagedAccountPortfolioSetupUpdateDTO extends ManagedAccountPortfolioSetupDTO {
  hasRefreshData: boolean = false;
}

export class InvestmentSelectionDTO {
  shCode: string = "";
  code?: string | null = null;
  name?: string = "";
  managerName?: string | null = null;
  displayName?: string | null = null;
  isSelected?: boolean = false;
  isFavourited?: boolean = false;
}

export class SelectedInvestmentDTO extends InvestmentSelectionDTO {
  weight: number | null = null;
  value: number | null = null;
}

export class InvestmentQueryOptions {
  managedAccountId: string = "new";
  search?: string = "";
  skip?: number = 0;
  take?: number = 0;
}

export class SelectedPortfolioTemplateDTO {
  id: string = "";
  name: string = "";
  note: string = "";
}
