import {
  SHChip,
  SHImage,
  SHStack,
  SHTableHead,
  SHTableHeadProps,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { SelectedProductDTO } from "@models/platform-analysis/entities/steps/feature";
import { SummaryAnalysisCell } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-cell";
import { hexToRGBA } from "@utils";
import { useTheme } from "@mui/material";
import { useMemo } from "react";

interface SummaryAnalysisHeaderProps extends SHTableHeadProps {
  selectedProducts?: SelectedProductDTO[];
  isFeatureAnalysis?: boolean;
  isDetailMode?: boolean;
}

export const SummaryAnalysisHeader = ({
  selectedProducts,
  isFeatureAnalysis = false,
  isDetailMode = false,
  ...others
}: SummaryAnalysisHeaderProps) => {
  const { palette } = useTheme();

  const cellWidths = useMemo(() => {
    return {
      FirstCell: !isFeatureAnalysis || isDetailMode ? "360px" : "250px",
      TotalSelection: "110px",
      AnalysisValue: isDetailMode ? "173px" : "195px",
    };
  }, [isFeatureAnalysis, isDetailMode]);

  return (
    <SHTableHead {...others}>
      <SHTableRow
        sx={{
          th: { borderLeft: "none" },
          "th:first-child": {
            borderLeft: `1px solid ${hexToRGBA(palette.primary[50], 0.3)}`,
          },
        }}
      >
        <SummaryAnalysisCell cellWidth={cellWidths.FirstCell}>
          <SHTypography
            variant="subtitle1"
            sx={{
              lineHeight: "150%",
              fontWeight: 700,
            }}
          >
            Platform
          </SHTypography>
        </SummaryAnalysisCell>
        {isFeatureAnalysis && (
          <SummaryAnalysisCell cellWidth={cellWidths.TotalSelection}>
            <SHStack>
              <SHTypography
                variant="caption"
                sx={{
                  fontWeight: 700,
                  lineHeight: "150%",
                  textAlign: "center",
                }}
              >
                Selections /<br /># Available
              </SHTypography>
            </SHStack>
          </SummaryAnalysisCell>
        )}
        {selectedProducts?.map((product, index) => (
          <SummaryAnalysisCell cellWidth={cellWidths.AnalysisValue} key={index}>
            <SHStack
              gap={"27px"}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <SHStack
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"15px"}
              >
                {product.isProductMain && (
                  <SHChip
                    label={"Main"}
                    color={"warning"}
                    variant={"outlined"}
                    sx={{
                      top: 5.5,
                      right: 5.5,
                      width: 51,
                      height: 22,
                      padding: "4px 8px",
                      position: "absolute",
                      "& >.MuiChip-label": {
                        overflow: "unset",
                      },
                    }}
                  />
                )}
                <SHStack
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"5px"}
                >
                  <SHImage
                    src={product?.productLogo?.url}
                    width={68}
                    height={68}
                  />
                  <SHTypography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      fontSize: 15,
                    }}
                  >
                    {product?.productName}
                  </SHTypography>
                </SHStack>
              </SHStack>
            </SHStack>
          </SummaryAnalysisCell>
        ))}
      </SHTableRow>
    </SHTableHead>
  );
};
