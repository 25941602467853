import { SVGProps } from "react";

export const ImageSVG = ({
  color = "#25324B",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    className={"sh-image-svg"}
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g className="sh-image-svg-g" stroke={color} strokeWidth={1.5}>
      <path
        d="M16.875 4.375V3.75A1.88 1.88 0 0 0 15 1.875H2.5A1.88 1.88 0 0 0 .625 3.75v10A1.88 1.88 0 0 0 2.5 15.625h.625"
        strokeLinejoin="round"
      />
      <path
        d="M17.578 5H5.546c-.992 0-1.796.804-1.796 1.796v9.532c0 .993.804 1.797 1.796 1.797h12.032c.993 0 1.797-.804 1.797-1.797V6.796c0-.992-.804-1.796-1.797-1.796Z"
        strokeLinejoin="round"
      />
      <path
        d="M14.567 9.773c.664 0 1.202-.534 1.202-1.193 0-.66-.538-1.194-1.202-1.194-.664 0-1.202.535-1.202 1.194 0 .659.538 1.193 1.202 1.193Z"
        strokeMiterlimit={10}
      />
      <path
        d="m13.365 14.538-3.404-3.375a1.208 1.208 0 0 0-1.648-.047L3.75 15.142m6.61 2.983 4.633-4.599a1.21 1.21 0 0 1 1.62-.073l2.762 2.286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
