import { mockProductLov } from "@components/auto-completes/products/mock";
import { InformationButton } from "@components/buttons/information";
import {
  SHAutocomplete,
  SHBox,
  SHCheckbox,
  SHChip,
  SHRadio,
  SHStack,
  SHTextFieldProps,
  SHTypography,
} from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { ProductLOV } from "@models/product/entities/product";
import { useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { getPlatformAnalysisProducts } from "@services/platform-analysis/platformAnalysisService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
export interface SelectProductsProps {
  disabled?: boolean;
  value: string[] | string | null; //Selected products Id
  onChange: (value: string[] | string | null) => void;
  productData?: ProductLOV[];
  textFieldProps?: Omit<SHTextFieldProps, "value">;
  supplierId?: string;
  mainPlatformId?: string | null;
  multiple?: boolean;
  maxLength?: number;
  apiPath?: string;
}
export const SelectProducts = ({
  disabled,
  value = [],
  onChange,
  productData: productDataProp = mockProductLov,
  textFieldProps,
  multiple,
  mainPlatformId,
  maxLength,
  supplierId,
  apiPath,
}: SelectProductsProps) => {
  const { notify } = useNotification();
  const { palette } = useTheme();
  const { platformAnalysis } = useSelector(
    (state: RootState) => state.platformAnalysis,
  );
  const [productData, setProductData] = useState<ProductLOV[]>(productDataProp);
  const selectedProducts = productData.filter(
    (option) =>
      (multiple ? value?.includes(option.id) : value === option.id) ||
      option.id === mainPlatformId,
  );
  useEffect(() => {
    setProductData(productDataProp);
  }, [productDataProp]);

  useEffect(() => {
    getProductData(
      supplierId,
      undefined,
      platformAnalysis?.configurationVersion,
    );
    // eslint-disable-next-line
  }, [supplierId, platformAnalysis?.configurationVersion]);

  const getProductData = async (
    supplierId?: string,
    filterByProductName?: string,
    configurationVersion?: number,
  ) => {
    const { isSuccess, data, message } = await getPlatformAnalysisProducts(
      supplierId,
      filterByProductName,
      configurationVersion,
      apiPath,
    );
    if (isSuccess) {
      setProductData(data ?? []);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };
  const compileValue = multiple ? selectedProducts : selectedProducts?.[0];
  return (
    <SHStack spacing={"16px"} direction="column">
      <SHAutocomplete
        multiple={multiple}
        value={compileValue || null}
        disabled={disabled}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        getOptionLabel={(option: ProductLOV) => option.name}
        textFieldProps={{
          ...textFieldProps,
          InputLabelProps: { shrink: true },
          placeholder:
            value?.length && multiple ? undefined : textFieldProps?.placeholder,
          InputProps: {
            startAdornment:
              selectedProducts?.length && multiple ? (
                <SHTypography
                  disabled={disabled}
                  variant="body1"
                  sx={{ paddingLeft: "10px" }}
                >
                  {`${selectedProducts?.length}/${maxLength} selected`}
                </SHTypography>
              ) : undefined,
          },
        }}
        onChange={(e, newValue, r, details) => {
          const selectedId = details?.option.id;
          if (selectedId && mainPlatformId && selectedId === mainPlatformId)
            return;
          if (!multiple) {
            onChange(!Array.isArray(newValue) ? newValue?.id ?? null : null);
            return;
          }
          if (!Array.isArray(newValue)) {
            onChange([]);
            return;
          }
          if (maxLength && newValue?.length > maxLength) return;
          onChange(
            newValue
              .map((option) => option.id)
              .filter((id) => id !== mainPlatformId),
          );
        }}
        options={productData}
        renderOption={(props, option, { selected }) => {
          const isMainProduct = mainPlatformId === option.id;
          const isChecked = selected || isMainProduct;
          return (
            <li {...props} key={option.id} style={{ padding: "1px 10px" }}>
              <SHStack alignItems={"center"} direction="row" spacing={1}>
                {multiple ? (
                  <SHCheckbox checked={isChecked} disabled={isMainProduct} />
                ) : (
                  <SHRadio disabled={isMainProduct} checked={isChecked} />
                )}
                <SHTypography
                  variant="body3"
                  disabled={isMainProduct}
                  fontWeight={500}
                >
                  {option.name}
                </SHTypography>
                {option.description && (
                  <InformationButton content={option.description} />
                )}
                {isMainProduct && (
                  <SHChip
                    sx={{ ml: "25px !important" }}
                    color="warning"
                    size="small"
                    label="Main"
                    variant="outlined"
                  />
                )}
              </SHStack>
            </li>
          );
        }}
      />
      {multiple && (
        <SHBox
          sx={{
            height: "100px",
            px: "12px",
            py: "10px",
            border: `1px solid ${palette.divider}`,
            overflowY: "auto",
          }}
          p={"12px"}
        >
          <SHTypography variant="body2">
            {selectedProducts?.map((option) => option.name)?.join(", ")}
          </SHTypography>
        </SHBox>
      )}
    </SHStack>
  );
};
