import { ArrowButton } from "@components/buttons/arrow";
import { CloseButton } from "@components/buttons/close";
import { SHDialog, SHStack, SHTypography } from "@components/design-systems";
import { DialogContent, DialogTitle, styled, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { useMemo } from "react";
import ReactImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "./styles.scss";

interface ImageGalleryDialogProps {
  open:boolean;
  title?: string;
  items: ReactImageGalleryItem[];
  startIndex?: number;
  onClose: () => void;
  onImageLoad?: () => void;
}
const ReactImageGalleryStyled = styled(
  ReactImageGallery,
  {},
)(() => ({
  // TODO: ReactImageGalleryStyled
}));

export const ImageGalleryDialog = ({
  title,
  open = false,
  items = [],
  startIndex = 0,
  onClose,
  onImageLoad,
}: ImageGalleryDialogProps) => {
  const { palette } = useTheme();
  const arrowBtnCommonConfig = useMemo(
    () => ({
      borderHoverColor: palette.primary[800],
      boxShadowInset: `inset 3px 4px 2px ${hexToRGBA("#414959", 0.4)}`,
      buttonWidth: "50px",
      buttonHeight: "50px",
      iconHeight: "22.5px",
      iconWidth: "13.3px",
      hidden: false,
      boxShadow: `2px 2px 2px ${hexToRGBA(palette.common.black, 0.4)}`,
    }),
    [palette.common.black, palette.primary],
  );

  return (
    <SHDialog
      open={open}
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          padding: 0,
          overflow: "hidden",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          border: 0,
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.secondary[100],
            0.25,
          )}, ${hexToRGBA(palette.secondary[100], 0.25)}),${
            palette.common.white
          }`,
          width: "70%",
          height: "85%",
        },
      }}
      onClose={onClose}
    >
      <DialogTitle
        borderBottom={`1px solid ${palette.secondary[100]}`}
        display={"flex"}
        height={50}
        padding={"0px !important"}
        alignItems="stretch"
        justifyContent="space-between"
        flexDirection="row"
      >
        <SHTypography
          variant="body1"
          lineHeight="120%"
          textAlign={"center"}
          sx={{
            borderRight: `1px solid ${palette.secondary[100]}`,
            padding: "16.5px 25px !important",
          }}
        >
          {title}
        </SHTypography>
        <SHStack padding={"18.99px 20px !important"}>
          <CloseButton width="12px" height="12px" onClick={onClose} />
        </SHStack>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0px !important",
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <ReactImageGalleryStyled
          onImageLoad={onImageLoad}
          startIndex={startIndex}
          items={items}
          showPlayButton={false}
          showFullscreenButton={false}
          lazyLoad={true}
          // TODO: Add LeftNav & RightNav buttons
          renderLeftNav={(onClick, disabled) => (
            <SHStack
              justifyContent="center"
              alignItems={"flex-start"}
              p={3}
              sx={{
                position: "absolute",
                height: "100%",
                minWidth: "50px",
                top: 0,
                left: 0,
                zIndex: 2,
              }}
            >
              <ArrowButton
                className="btnPrev"
                direction="previous"
                onClick={onClick}
                disabled={disabled}
                aria-label="Previous Slide"
                {...arrowBtnCommonConfig}
              />
            </SHStack>
          )}
          renderRightNav={(onClick, disabled) => (
            <SHStack
              justifyContent="center"
              alignItems={"flex-end"}
              p={3}
              sx={{
                position: "absolute",
                height: "100%",
                minWidth: "50px",
                top: 0,
                right: 0,
                zIndex: 2,
              }}
            >
              <ArrowButton
                className="btnNext"
                direction="next"
                onClick={onClick}
                disabled={disabled}
                aria-label="Next Slide"
                {...arrowBtnCommonConfig}
              />
            </SHStack>
          )}
        />
      </DialogContent>
    </SHDialog>
  );
};
