import {
  SHTable,
  SHTableBody,
  SHTableHead,
  SHTableRow,
} from "@components/design-systems";
import { LocalStorage } from "@constants";
import { useLocalStorage } from "@hooks/useLocalStorage";
import {
  ReviewFeatureDTO,
  ReviewSectionDTO,
} from "@models/reviews/entities/steps/feature";
import { useTheme } from "@mui/material";
import { FeatureReviewSectionRow } from "@pages/reviews/_id/steps/feature/analysis/components/section-row";
import { FeatureReviewSubSectionRow } from "@pages/reviews/_id/steps/feature/analysis/components/sub-section-row";
import {
  FeatureReviewTableHead,
  FeatureReviewTableHeadProps,
} from "@pages/reviews/_id/steps/feature/analysis/components/table-head";
import { updateCollapseMappingAction } from "@redux/slices/review";
import { RootState } from "@redux/store";
import { hexToRGBA } from "@utils";
import { isEmpty, keys, pickBy, debounce } from "lodash";
import React, {forwardRef, useEffect, useState, WheelEvent} from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableVirtuoso } from "react-virtuoso";
import { FeatureReviewFeatureTableRow } from "@pages/reviews/_id/steps/feature/analysis/components/feature-row";
import { ReviewTableRowType } from "@models/reviews/entities/review";
import { TABLE_ROW_HEIGHT } from "@pages/reviews/_id/steps/feature/analysis/config";
import "./styles.scss";

export type CollapseType = {
  [key in string]?: boolean;
};

export const SHTableVirtuoso = ({
  selectedProducts,
  onChangeDecisionStatus,
  onModifyComment,
  updatingDecisionStatus,
  onScrollingTop = () => {},
}: FeatureReviewTableHeadProps & {
  onScrollingTop?: (isHidden: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { featureReviewRows, collapseMapping } = useSelector(
    (state: RootState) => state.review,
  );
  const { setLocalStorageItem, getLocalStorageItem } = useLocalStorage();

  const scrollingTop = getLocalStorageItem(LocalStorage.scrollingTop);
  const scrollingLeft = getLocalStorageItem(LocalStorage.scrollingLeft);
  const collapsedIds = keys(pickBy(collapseMapping, (value) => value === true));
  const [isExpandHeader, setIsExpandHeader] = useState(true);
  let timeoutIdScrollDown = -1;
  let isScrollUp = true;
  let isMouseScroll = true;

  const rowsRendered =
    isEmpty(featureReviewRows) || isEmpty(collapsedIds)
      ? featureReviewRows
      : featureReviewRows?.filter((obj) => {
          return !obj?.collapsedIds?.some((id) => collapsedIds?.includes(id));
        });
  const totalRowHeight = rowsRendered?.length * TABLE_ROW_HEIGHT;

  const handleOnCollapse = (sectionId?: string) => {
    if (!sectionId) return;
    dispatch(
      updateCollapseMappingAction({
        ...collapseMapping,
        [sectionId]: !collapseMapping?.[sectionId],
      }),
    );
  };

  const handleScrollTop = debounce(
    () => {
      setIsExpandHeader(true);
    },
    150,
    { leading: false, trailing: true },
  );

  const handleOnWheel = (event: WheelEvent<HTMLDivElement>) => {
    const scrollTop = Math.floor(event.currentTarget.scrollTop);
    let isTouchpadScrollToTop;

    isScrollUp = event.deltaY < 0;
    isMouseScroll = Math.abs(event.deltaY) > 50;
    isTouchpadScrollToTop = scrollTop === 0 && isScrollUp && !isMouseScroll;

    if (isTouchpadScrollToTop) {
      const selectors = [
        ".virtuoso__tableHead",
        ".virtuoso__tableContainer",
        ".featureReview__headerInfo",
      ];
      const elements = selectors.map((selector) =>
        document.querySelector(selector),
      );
      const [tableHead, tableContainer, featureReviewHeader] = elements;

      [tableHead, featureReviewHeader, tableContainer].forEach((element) =>
        element?.classList.remove("sticky"),
      );
      onScrollingTop(false);
      setLocalStorageItem(LocalStorage.scrollingTop, false);
    }
  };

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const selectors = [
      ".virtuoso__tableBody",
      ".virtuoso__tableHead",
      ".virtuoso__tableContainer",
      ".featureReview__headerInfo",
    ];
    const elements = selectors.map((selector) =>
      document.querySelector(selector),
    );

    const [tableBody, tableHead, tableContainer, featureReviewHeader] =
      elements;
    const scrollLeft = event.currentTarget?.scrollLeft ?? 0;
    const scrollTop = Math.floor(event.currentTarget?.scrollTop) ?? -1;
    const containerHeight = tableContainer?.clientHeight ?? 0;

    if (scrollLeft > 0) {
      tableBody?.classList.add("sticky");
      setLocalStorageItem(LocalStorage.scrollingLeft, true);
    } else {
      tableBody?.classList.remove("sticky");
      setLocalStorageItem(LocalStorage.scrollingLeft, false);
    }

    if (scrollTop > 0 && totalRowHeight > containerHeight && !isScrollUp) {
      setLocalStorageItem(LocalStorage.scrollingTop, true);
      [tableHead, featureReviewHeader, tableContainer].forEach((element) =>
        element?.classList.add("sticky"),
      );

      if (isExpandHeader) {
        event.currentTarget.scrollTop = 5;
        timeoutIdScrollDown = window.setTimeout(() => {
          setIsExpandHeader(false);
        }, 150);
      }
    } else if (scrollTop === 0 && isScrollUp && isMouseScroll) {
      event.currentTarget.scrollTop = 1;

      if (isExpandHeader) {
        onScrollingTop(false);
        setLocalStorageItem(LocalStorage.scrollingTop, false);
        [tableHead, featureReviewHeader, tableContainer].forEach((element) =>
          element?.classList.remove("sticky"),
        );
      }

      handleScrollTop();
    }
  };

  useEffect(() => window.clearTimeout(timeoutIdScrollDown));

  return (
    <TableVirtuoso
      className={`virtuoso__tableContainer`}
      data={rowsRendered}
      onScroll={handleOnScroll}
      onWheel={handleOnWheel}
      components={{
        Table: ({ style, ...others }) => {
          return (
            <SHTable
              className="virtuoso__Table"
              stickyHeader
              style={{
                tableLayout: "auto",
                maxWidth: "max-content",
              }}
              sx={{
                ...style,
                th: {
                  borderLeft: "none",
                  "&:first-child": {
                    borderLeft: `1px solid ${palette.secondary[100]}`,
                  },
                },
                td: {
                  borderTop: "none",
                  borderLeft: "none",
                  "&:first-child": {
                    borderLeft: `1px solid ${palette.secondary[100]}`,
                  },
                },
                tbody: {
                  "&>tr:first-child td": {
                    borderTop: `1px solid ${palette.secondary[100]}`,
                  },
                  "& tr>td:first-child": {
                    borderLeft: `1px solid ${palette.secondary[100]}`,
                  },
                },
              }}
              {...others}
            />
          );
        },
        TableHead: forwardRef(({ style, ...props }, ref) => {
          return (
            <SHTableHead
              className={`virtuoso__tableHead${scrollingTop ? " sticky" : ""}`}
              sx={{
                ...style,
                background: "transparent",
                height: selectedProducts?.length === 0 ? 265 : undefined,
              }}
              ref={ref}
              {...props}
            />
          );
        }),
        TableBody: forwardRef((props, ref) => (
          <SHTableBody
            className={`virtuoso__tableBody${scrollingLeft ? " sticky" : ""}`}
            ref={ref}
            {...props}
          />
        )),
        TableRow: forwardRef(({ style, item, ...others }, ref) => {
          const bgColor =
            item.type === ReviewTableRowType.Section
              ? palette.common.white
              : hexToRGBA(palette.secondary[100], 0.4);
          return (
            <SHTableRow
              ref={ref}
              sx={{
                ...style,
                height: TABLE_ROW_HEIGHT,
                backgroundColor: `${bgColor} !important`,
              }}
              {...others}
            />
          );
        }),
      }}
      style={{
        height: "110%",
      }}
      fixedHeaderContent={() => (
        <FeatureReviewTableHead
          selectedProducts={selectedProducts}
          onChangeDecisionStatus={onChangeDecisionStatus}
          updatingDecisionStatus={updatingDecisionStatus}
          onModifyComment={onModifyComment}
        />
      )}
      itemContent={(index, section) => {
        if (!section.id) return;
        switch (section?.type) {
          case ReviewTableRowType.Section:
            return (
              <FeatureReviewSectionRow
                key={section?.id}
                section={section as unknown as ReviewSectionDTO}
                selectedProducts={selectedProducts}
                isCollapsing={collapseMapping?.[section?.id ?? ""]}
                onCollapse={() => handleOnCollapse(section?.id)}
              />
            );
          case ReviewTableRowType.SubSection:
            return (
              <FeatureReviewSubSectionRow
                key={section?.id}
                subSection={section as unknown as ReviewSectionDTO}
                isCollapsing={collapseMapping?.[section?.id ?? ""]}
                selectedProducts={selectedProducts}
                onCollapse={() => handleOnCollapse(section.id)}
              />
            );
          case ReviewTableRowType.Feature:
            return (
              <FeatureReviewFeatureTableRow
                key={section?.id}
                feature={section as unknown as ReviewFeatureDTO}
                selectedProducts={selectedProducts}
              />
            );
          default:
            <></>;
        }
      }}
    />
  );
};
