import { UserPermission } from "@models/auth";
import { GetUserInfoDTO } from "@models/users/entities/user";
import { RootState } from "@redux/store";
import { getUserRole } from "@utils/users/role";
import { useCallback } from "react";
import { useSelector } from "react-redux";

export const useUserPermissions = (checkUser?: GetUserInfoDTO) => {
  const { user: userStore } = useSelector((state: RootState) => state.auth);
  const user = checkUser ?? userStore;
  const {
    isSuperAdmin,
    isStandardAdmin,
    isSupplierPremiumOwner,
    isSupplierBasic,
    isSupplierPremiumStandard,
    isSupplierBdmSale,
    isAdviserTrial,
    isAdviserAdminTrial,
    isAdviserAdviser,
    isAdviserSupportStaff,
    isAdminGroup,
    isSupplierGroup,
    isAdviserGroup,
    isAdviserAdminGroup,
    isAdviserTrialGroup,
    isAdviserSupportStaffGroup,
    isUser,
  } = getUserRole(user);

  const somePermissions = useCallback(
    (permissions: UserPermission[]) =>
      user?.permissions &&
      user.permissions.some((permission: UserPermission) =>
        permissions.includes(permission),
      ),
    [user?.permissions],
  );

  const everyPermissions = useCallback(
    (permissions: UserPermission[]) =>
      user?.permissions &&
      permissions.every((permission: UserPermission) =>
        user.permissions.includes(permission),
      ),
    [user?.permissions],
  );
  //Auth
  //All user can login,reset password by auth0
  //Administrator management
  const canSelfRequestPasswordChange = isUser;
  const canViewAdminList = isSuperAdmin;
  const canViewAdminProfile = isAdminGroup;
  const canSaveAdminProfile = isAdminGroup;
  const canCreateAdmin = isSuperAdmin;
  const canChangeAdminType = isSuperAdmin;
  const canDisableAdmin = isSuperAdmin;
  const canEnableAdmin = isSuperAdmin;
  const canArchiveAdmin = isSuperAdmin;
  const canUnArchiveAdmin = isSuperAdmin;
  const canResendInviteAdmin = isSuperAdmin;
  const canRequestPasswordChangeAdmin = isSuperAdmin;

  //Configuration management
  const canMangeConfiguration = isSuperAdmin;

  //Platform profiles
  const canViewPlatformProfilesList = isUser;

  //Suppliers
  const canViewSuppliersList = isAdminGroup;
  const canCreateSupplier = isAdminGroup;

  //Suppliers view tab
  //Suppliers - Team
  const canViewSupplierTeam =
    isAdminGroup ||
    isSupplierPremiumOwner ||
    (isSupplierPremiumStandard &&
      everyPermissions([
        UserPermission.ViewSupplierUsers,
        UserPermission.CreateSupplierUsers,
        UserPermission.EditSupplierUsers,
      ]));

  //Suppliers - Product
  const canViewSupplierProductList =
    isAdminGroup ||
    isSupplierPremiumOwner ||
    isSupplierBasic ||
    (isSupplierPremiumStandard &&
      everyPermissions([
        UserPermission.ViewProductProfiles,
        UserPermission.EditProductProfiles,
      ]));
  //Suppliers - Billing | Subscription
  const canViewSupplierBilling =
    isAdminGroup ||
    isSupplierPremiumOwner ||
    (isSupplierPremiumStandard &&
      everyPermissions([
        UserPermission.ViewBillingAndLicensers,
        UserPermission.EditBillingAndLicensers,
        UserPermission.CreateBillingAndLicensers,
      ]));
  //Suppliers - Invoices
  const canViewSupplierInvoices =
    isAdminGroup || isSupplierPremiumOwner || isSupplierPremiumStandard;
  //Suppliers -Overview
  const canViewSupplierOverview =
    isAdminGroup ||
    isSupplierPremiumOwner ||
    isSupplierBasic ||
    (isSupplierPremiumStandard &&
      (canViewSupplierTeam ||
        canViewSupplierBilling ||
        canViewSupplierProductList));

  //Suppliers tab detail
  //Suppliers -Overview
  const canEditSupplierOverview = isAdminGroup || isSupplierPremiumOwner;

  //Suppliers - Team (Supplier user)
  const canViewSupplierUser = canViewSupplierTeam; //And yourself
  const canCreateSupplierUser = canViewSupplierTeam;
  const canSaveSupplierUser = canViewSupplierTeam; //And yourself
  const canChangeSupplierUserType = canViewSupplierTeam;
  const canChangeSupplierAssociatedProducts = canViewSupplierTeam;
  const canChangeSupplierAdditionalPermissions = canViewSupplierTeam;
  const canSupplierUserTransferOwnership =
    isAdminGroup || isSupplierPremiumOwner;
  const canDisableSupplierUser = canViewSupplierTeam;
  const canEnableSupplierUser = canViewSupplierTeam;
  const canArchiveSupplierUser = canViewSupplierTeam;
  const canUnArchiveSupplierUser = canViewSupplierTeam;
  const canResendInviteSupplierUser = canViewSupplierTeam;
  const canRequestPasswordChangeSupplierUser = canViewSupplierTeam;
  const canSupplierUserHavePublishInformation = isSupplierBdmSale;
  const canSupplierUserEditPublishInformation = canViewSupplierTeam;

  //Suppliers - Billing
  const canSaveSupplierBilling = isAdminGroup;

  //Suppliers - Invoices

  //Suppliers - Product
  const canCreateProduct = isSuperAdmin;

  const canViewProduct = isUser;
  const canViewProductOverviewTab = canViewProduct;
  const canViewProductInformationTab = canViewProduct;
  const canViewProductESGTab = canViewProduct;
  const canViewProductFeatureTab = canViewProduct;
  const canViewProductFindBDMTab = canViewProduct;
  const canViewProductFeeAndRateTab = canViewProduct;

  //For supplier user only can manage products of supplier
  const canGoToEditProduct = (productId?: string) => {
    if (productId === "new" && !isSuperAdmin) return false;
    if (!canViewSupplierProductList) return false;
    if (isSupplierPremiumStandard) {
      if (!user?.productIds || !productId) return false;
      return user.productIds.includes(productId);
    }
    return true;
  };
  const canViewProductStatus = canGoToEditProduct;
  const canViewProductHistoryTab = canViewSupplierProductList;

  //Action
  const canDisableProduct = isSuperAdmin; //TODO: Integrate later
  const canSaveDraftProduct = canViewSupplierProductList;
  const canDiscardDraftProduct = canViewSupplierProductList;
  const canSubmitForReviewProduct = canViewSupplierProductList;
  const canRecallSubmissionProduct =
    canViewSupplierProductList && !isSuperAdmin;
  const canRevertToDraftProduct = isAdminGroup;
  const canApproveProduct = isSuperAdmin;

  //News and updates
  const havePermissionWithPost = everyPermissions([
    UserPermission.CreateOwnSupplierNewsfeedPosts,
    UserPermission.EditOwnSupplierNewsfeedPosts,
  ]);
  const isSupplierStandardHavePermission =
    isSupplierPremiumStandard && havePermissionWithPost;

  const haveNewsfeedManagementPermission =
    isSuperAdmin ||
    isStandardAdmin ||
    isSupplierBasic ||
    isSupplierPremiumOwner ||
    isSupplierStandardHavePermission;

  const canViewAllFilterStatus = isAdminGroup;
  const canViewSomeFilterStatus =
    isSupplierBasic ||
    isSupplierPremiumOwner ||
    isSupplierStandardHavePermission;
  const canViewOnlyPublishedFilterStatus =
    isSupplierBdmSale || (isSupplierPremiumStandard && !havePermissionWithPost);
  const canViewArchivedPost = isAdminGroup;
  const canViewAllProduct = isAdminGroup;
  const canCreatePost = haveNewsfeedManagementPermission;
  const canEditPendingPost = haveNewsfeedManagementPermission;
  const canArchivePendingPost = haveNewsfeedManagementPermission;
  const canPublishPost = isSuperAdmin;
  const canEditPublishedPost = isSuperAdmin;
  const canArchivePublishedPost = isSuperAdmin;
  const canRestorePost = isSuperAdmin;
  const isAllPermissionOnPost = isSuperAdmin;

  //Practices

  return {
    user,
    isSuperAdmin,
    isStandardAdmin,
    isSupplierPremiumOwner,
    isSupplierBasic,
    isSupplierPremiumStandard,
    isSupplierBdmSale,
    isAdviserTrial,
    isAdviserAdminTrial,
    isAdviserAdviser,
    isAdviserSupportStaff,
    isAdminGroup,
    isSupplierGroup,
    isAdviserGroup,
    isAdviserAdminGroup,
    isAdviserTrialGroup,
    isAdviserSupportStaffGroup,
    isUser,
    somePermissions,
    everyPermissions,
    canSelfRequestPasswordChange,
    canViewAdminList,
    canViewAdminProfile,
    canSaveAdminProfile,
    canCreateAdmin,
    canChangeAdminType,
    canDisableAdmin,
    canEnableAdmin,
    canArchiveAdmin,
    canUnArchiveAdmin,
    canResendInviteAdmin,
    canRequestPasswordChangeAdmin,
    canMangeConfiguration,
    canViewPlatformProfilesList,
    canViewSuppliersList,
    canCreateSupplier,
    canViewSupplierTeam,
    canViewSupplierProductList,
    canViewSupplierBilling,
    canViewSupplierInvoices,
    canViewSupplierOverview,
    canEditSupplierOverview,
    canViewSupplierUser,
    canCreateSupplierUser,
    canSaveSupplierUser,
    canChangeSupplierUserType,
    canChangeSupplierAssociatedProducts,
    canChangeSupplierAdditionalPermissions,
    canSupplierUserTransferOwnership,
    canDisableSupplierUser,
    canEnableSupplierUser,
    canArchiveSupplierUser,
    canUnArchiveSupplierUser,
    canResendInviteSupplierUser,
    canRequestPasswordChangeSupplierUser,
    canSupplierUserHavePublishInformation,
    canSupplierUserEditPublishInformation,
    canSaveSupplierBilling,
    canCreateProduct,
    canViewProduct,
    canViewProductOverviewTab,
    canViewProductInformationTab,
    canViewProductESGTab,
    canViewProductFeatureTab,
    canViewProductFindBDMTab,
    canViewProductFeeAndRateTab,
    canGoToEditProduct,
    canViewProductStatus,
    canViewProductHistoryTab,
    canDisableProduct,
    canSaveDraftProduct,
    canDiscardDraftProduct,
    canSubmitForReviewProduct,
    canRevertToDraftProduct,
    canApproveProduct,
    canRecallSubmissionProduct,
    canViewAllProduct,
    canCreatePost,
    canEditPendingPost,
    canArchivePendingPost,
    canPublishPost,
    canEditPublishedPost,
    canArchivePublishedPost,
    canRestorePost,
    canViewArchivedPost,
    isAllPermissionOnPost,
    canViewAllFilterStatus,
    canViewSomeFilterStatus,
    canViewOnlyPublishedFilterStatus,
  };
};
