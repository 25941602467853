import { ImageAutoComplete } from "@components/auto-completes/image";
import {
  SHBox,
  SHButton,
  SHDialog,
  SHIconLoading,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { PageRoutes } from "@constants/routes";
import { useAppParams } from "@hooks/useAppParams";
import { useBroadcastChannel } from "@hooks/useBroadcastChannel";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { KickoutType } from "@models/core";
import { ApplicationUser } from "@models/users/entities/appUser";
import {
  getTransferSupplierOwnershipLov,
  transferSupplierOwnership,
} from "@services/suppliers";
import { nameOfFactory } from "@utils";
import { useState } from "react";
import { useParams } from "react-router";
const nameOf = nameOfFactory<ApplicationUser>();

export interface TransferOwnershipProps {
  refreshData: () => void;
  disabled?: boolean;
}
export const TransferOwnership = ({
  refreshData,
  disabled,
}: TransferOwnershipProps): JSX.Element => {
  const { notify } = useNotification();
  const { supplierId, supplierUserId } = useParams();
  const { isMyProfile } = useAppParams();
  const { isAdminGroup, canSupplierUserTransferOwnership } =
    useUserPermissions();

  const [openDialog, setOpenDialog] = useState(false);
  const [newOwner, setNewOwner] = useState<ApplicationUser | null>(null);
  const [users, setUsers] = useState<ApplicationUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { logoutBroadcast } = useBroadcastChannel();

  const handleOnOpenDialog = (): void => {
    setOpenDialog(true);
  };
  const handleOnCloseDialog = (): void => {
    setOpenDialog(false);
  };

  const getUsers = async (supplierId?: string) => {
    if (!supplierId) return;
    setIsLoading(true);
    const { data, message } = await getTransferSupplierOwnershipLov(supplierId);
    setIsLoading(false);
    if (data) {
      setUsers(data.value);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnTransfer = async () => {
    if (!newOwner || !newOwner.auth0Id || !supplierUserId) return;
    setIsSubmitting(true);
    const { isSuccess, message } = await transferSupplierOwnership(
      supplierUserId,
      newOwner.auth0Id,
    );
    setIsSubmitting(false);
    if (isSuccess) {
      if (isAdminGroup) {
        setUsers([]);
        setNewOwner(null);
        notify(message, {
          variant: "success",
          close: true,
        });
        refreshData();
        return;
      }
      if (isMyProfile) {
        localStorage.clear();
        logoutBroadcast(
          `${process.env.REACT_APP_AUTH0_REDIRECT_URL}${PageRoutes.callback}?action=kick-out&type=${KickoutType.RevokeSupplierOwnerPermission}`,
        );
      }
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <>
      <ImageAutoComplete
        textFieldProps={{
          label: "Transfer account ownership",
          placeholder: "Select a team member below",
        }}
        value={newOwner}
        onChange={(event, newValue) => {
          setNewOwner(newValue);
        }}
        onOpen={() => getUsers(supplierId)}
        loading={isLoading}
        loadingText={
          <SHStack alignItems={"center"} direction="row" spacing={1}>
            <SHIconLoading />
            <SHTypography>Looking for eligible standard users</SHTypography>
          </SHStack>
        }
        labelField={nameOf("name")}
        imageField={nameOf("avatarUrl")}
        options={users}
        disabled={disabled}
      />
      <SHBox>
        <SHButton
          disabled={
            !newOwner ||
            isSubmitting ||
            !canSupplierUserTransferOwnership ||
            disabled
          }
          variant="contained"
          size="extraMedium"
          onClick={isAdminGroup ? handleOnTransfer : handleOnOpenDialog}
          isLoading={isSubmitting}
        >
          Transfer
        </SHButton>
      </SHBox>
      <SHDialog
        open={isAdminGroup ? false : openDialog}
        onClose={handleOnCloseDialog}
        width={460}
        header="Do you want to proceed 
        with ownership transfer?  "
        body="You will be logged out once you transfer"
        footer={
          <SHStack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={7}
          >
            <SHButton
              color="secondary"
              onClick={handleOnCloseDialog}
              variant="text"
              size="extraMedium"
            >
              Cancel
            </SHButton>
            <SHButton
              color="primary"
              variant="contained"
              onClick={handleOnTransfer}
              size="extraMedium"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Yes, transfer
            </SHButton>
          </SHStack>
        }
      />
    </>
  );
};
