import {
  FeeDTO,
  FeeSubProductDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { useTheme } from "@mui/material";
import { getYAxisTicks } from "@pages/platform-analysis/_id/steps/fee/analysis/components/line-chart/util";
import { getFeeMinMax } from "@pages/platform-analysis/util";
import { Text, View } from "@react-pdf/renderer";
import {
  formatLargeNumber,
  formatPercentage,
  generateUUID,
  nameOfFactory,
  thousandSeparator,
} from "@utils";
import { useMemo } from "react";
import ReactPDFChart from "react-pdf-charts";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";
import { PDFTitleSection } from "../title-section";

export interface FeeLineChartProps {
  feeSubProducts: FeeSubProductDTO[];
  chartMode?: FeesDisplayStyle;
  colors: Record<string, string>;
  isAppend?: boolean;
  title?: string;
}

const nameOf = nameOfFactory<FeeDTO>();

export const PDFFeeLineChart = ({
  feeSubProducts,
  chartMode = FeesDisplayStyle.Dollar,
  colors,
  isAppend = false,
  title,
}: FeeLineChartProps) => {
  const { palette } = useTheme();

  const isDollarMode = chartMode === FeesDisplayStyle.Dollar ? true : false;
  const subProducts = feeSubProducts;

  const isHiddenAll = feeSubProducts.length === 0;
  const subProductLines =
    isHiddenAll && subProducts.length ? [subProducts[0]] : feeSubProducts;
  const { max } = getFeeMinMax(
    isDollarMode ? "totalCostForDisplay" : "totalCostForDisplayPercentage",
    subProductLines,
  );
  const firstProduct = subProducts[0];

  const config = useMemo(() => {
    if (!firstProduct)
      return {
        tickXCount: 0,
        currentPortfolio: 0,
      };
    return {
      tickXCount: !firstProduct.fees[0]?.avgTotalPortfolio
        ? firstProduct.fees.length - 1
        : firstProduct.fees.length,
      currentPortfolio:
        firstProduct.fees.find((fee) => fee.isCurrentPortfolio)
          ?.avgTotalPortfolio ?? 0,
    };
  }, [firstProduct]);

  const minDomain = firstProduct?.fees?.[0].avgTotalPortfolio || 0;
  const maxDomain =
    firstProduct?.fees?.[firstProduct?.fees?.length - 1]?.avgTotalPortfolio ||
    0;
  const currentPortfolioIndex = firstProduct?.fees?.findIndex(
    (fee: any) => fee.isCurrentPortfolio,
  );

  const getTicks = (): number[] => {
    let ticks: number[] = [];
    firstProduct?.fees?.forEach((fee, index) => {
      ticks.push(fee.avgTotalPortfolio);
    });
    return ticks;
  };

  const portfolioValues = firstProduct?.fees?.map(
    (fee) => fee.avgTotalPortfolio,
  );

  const getIndexXAxisHidden = () => {
    if (config.currentPortfolio === portfolioValues[0]) return 1;

    const leftCurrentPortfolio = portfolioValues[currentPortfolioIndex - 1];
    const rightCurrentPortfolio = portfolioValues[currentPortfolioIndex + 1];

    const leftValue = config.currentPortfolio - leftCurrentPortfolio;
    const rightValue = rightCurrentPortfolio - config.currentPortfolio;

    const stepValue =
      portfolioValues[portfolioValues.length - 1] -
      portfolioValues[portfolioValues.length - 2];

    if (leftValue === rightValue && leftValue === stepValue) return -1;

    return currentPortfolioIndex;
  };

  const hiddenIndex = getIndexXAxisHidden();

  const customizedGroupTick = (props: any) => {
    const { index, x, y } = props;
    const value = portfolioValues[index];

    const displayValue =
      config.currentPortfolio === value
        ? `$${thousandSeparator(+value.toFixed(0))}`
        : `$${formatLargeNumber(value)}`;

    const alignValueXAxis = config.currentPortfolio === value ? 2 : 2.2;

    return (
      <g>
        <g>
          <text
            x={
              index === portfolioValues.length - 1
                ? x - alignValueXAxis * displayValue.length - 6
                : x - alignValueXAxis * displayValue.length
            }
            y={index === hiddenIndex ? y - 172 : y + 7}
            fontFamily={"Epilogue"}
            fontSize={"7px"}
            fontWeight={"normal"}
          >
            {displayValue}
          </text>
        </g>
      </g>
    );
  };

  return (
    <>
      {!isAppend && (
        <View>
          <PDFTitleSection
            title={title}
            subTitle={"Annual platform fees by portfolio value (estimates)"}
          />
        </View>
      )}
      <View
        style={{
          marginTop: hiddenIndex === -1 ? 17 : 25,
        }}
      >
        <ReactPDFChart>
          <LineChart
            height={200}
            width={515}
            margin={{ bottom: 0, top: 10, left: 10, right: 3 }}
          >
            {firstProduct?.fees?.map((fee: any) =>
              fee.isCurrentPortfolio ? null : (
                <ReferenceLine
                  key={fee.avgTotalPortfolio}
                  x={fee.avgTotalPortfolio}
                  stroke={palette.text.darker}
                  strokeWidth={0.5}
                  strokeDasharray={2}
                />
              ),
            )}
            <CartesianGrid
              strokeWidth={0.5}
              strokeDasharray={2}
              stroke={palette.text.darker}
              horizontal
              vertical={false}
            />
            <XAxis
              dataKey={nameOf("avgTotalPortfolio")}
              type="number"
              interval={0}
              ticks={getTicks()}
              domain={[minDomain, maxDomain]}
              tickFormatter={(value) => `$${formatLargeNumber(value)}`}
              tick={customizedGroupTick}
            />
            <YAxis
              dataKey={
                isDollarMode
                  ? nameOf("totalCostForDisplay")
                  : nameOf("totalCostForDisplayPercentage")
              }
              tickFormatter={(value: number) =>
                isDollarMode
                  ? `$${thousandSeparator(+value.toFixed(0))}`
                  : `${formatPercentage(value)}%`
              }
              tick={{
                fontFamily: "Epilogue",
                fontSize: "7px",
                fontWeight: "normal",
                fill: palette.common.black,
              }}
              ticks={getYAxisTicks(max)}
            />
            <ReferenceLine
              x={config.currentPortfolio}
              stroke={palette.text.disabled}
              strokeWidth={1}
              strokeDasharray={3}
            />
            {subProductLines.map((subProduct) => {
              return (
                <Line
                  hide={isHiddenAll}
                  key={generateUUID()}
                  data={subProduct.fees}
                  name={subProduct.name}
                  dataKey={
                    isDollarMode
                      ? nameOf("totalCostForDisplay")
                      : nameOf("totalCostForDisplayPercentage")
                  }
                  type="linear"
                  strokeWidth={1.5}
                  stroke={colors[subProduct.id]}
                  dot={{ fill: colors[subProduct.id], r: 1.5 }}
                  isAnimationActive={false}
                />
              );
            })}
          </LineChart>
        </ReactPDFChart>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 7,
          }}
        >
          <View
            style={{
              transform: "rotate(-90deg)",
              transformOrigin: "0% 0%",
            }}
          >
            <View
              style={{
                backgroundColor: "#e0e2e5",
                width: 150,
                minWidth: 150,
                height: 11,
                alignItems: "center",
                justifyContent: "center",
                border: 0.5,
                borderColor: "#b1b6bf",
                marginLeft: 43,
              }}
            >
              <Text
                style={{
                  fontFamily: "Epilogue",
                  fontWeight: "normal",
                  fontSize: "8px",
                }}
              >
                Platform fees for the year ahead
              </Text>
            </View>
          </View>
          <View
            style={{
              backgroundColor: "#e0e2e5",
              width: 180,
              minWidth: 180,
              height: 11,
              alignItems: "center",
              justifyContent: "center",
              border: 0.5,
              borderColor: "#b1b6bf",
            }}
          >
            <Text
              style={{
                fontFamily: "Epilogue",
                fontWeight: "normal",
                fontSize: "8px",
              }}
            >
              Average total portfolio value for the year
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};
