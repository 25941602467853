import {
  FilterButton,
  FilterButtonProps,
  FilterValue,
} from "@components/buttons/filter";
import { PlusButton } from "@components/buttons/plus";
import {
  SHBox,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { PageRoutes } from "@constants/routes";
import { ConfigurationFeature } from "@models/configuration";
import { generatePath, useNavigate } from "react-router";
import { FeatureCard } from "../feature-card";

export interface FeatureListProps extends SHStackProps {
  hasSubSection?: boolean;
  groupId?: string;
  sectionId?: string;
  subSectionId?: string;
  title?: string;
  subTitle?: string;
  isEdit?: boolean;
  features?: ConfigurationFeature[];
  filterProp?: FilterButtonProps;
  onClickNew?: () => void;
  onChangeFilter?: (value: FilterValue[] | undefined) => void;
  width?: number | string;
}
export const FeatureList = ({
  hasSubSection = false,
  groupId,
  sectionId,
  subSectionId,
  isEdit,
  features = [],
  title = "",
  subTitle = "Counter items",
  filterProp,
  onClickNew,
  onChangeFilter,
  width = "100%",
  ...others
}: FeatureListProps) => {
  const navigate = useNavigate();

  const handleOnClick = (featureId?: string) => {
    if (!hasSubSection) {
      navigate(
        generatePath(PageRoutes.configurationFeatureStandard, {
          groupId: groupId,
          sectionId: sectionId,
          featureId: featureId,
        }),
      );
    } else {
      navigate(
        generatePath(PageRoutes.configurationFeatureFeature, {
          groupId: groupId,
          sectionId: sectionId,
          subSectionId: subSectionId,
          featureId: featureId,
        }),
      );
    }
  };
  return (
    <SHStack width={width} minWidth={100} {...others}>
      <SHStack
        sx={{
          paddingY: "24px",
        }}
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <SHStack justifyContent="start" alignItems={"start"}>
          <SHTypography variant="subtitle1">{title}</SHTypography>
          <SHTypography variant="body4" disabled>
            {subTitle}
          </SHTypography>
        </SHStack>

        {isEdit && (
          <SHStack
            direction="row"
            justifyContent="center"
            alignItems={"center"}
            spacing={2}
          >
            {filterProp && (
              <FilterButton {...filterProp} onChangeFilter={onChangeFilter} />
            )}
            <PlusButton
              variant="outlined"
              size="extraMedium"
              data-testid="new-button"
              onClick={onClickNew}
            >
              New
            </PlusButton>
          </SHStack>
        )}
      </SHStack>
      <SHStack spacing={1}>
        {!features || features.length === 0 ? (
          <SHBox textAlign="center">
            <SHTypography variant="body2">
              This section does not have any features. Click on “+ New” to add
              features.
            </SHTypography>
          </SHBox>
        ) : (
          features.map((feature, index) => (
            <FeatureCard
              key={index}
              feature={feature}
              isEdit={isEdit}
              isOdd={index % 2 === 0}
              onClickView={() => handleOnClick(feature?.id)}
              onClickEdit={() => handleOnClick(feature?.id)}
            />
          ))
        )}
      </SHStack>
    </SHStack>
  );
};
