import {
  SHContainer,
  SHStack,
  SHToggleButtonOptions,
  SHTypography,
} from "@components/design-systems";
import { LocalStorage } from "@constants";
import { DateFormat2 } from "@constants/format";
import { useIsNew } from "@hooks/useIsNew";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { useManagedAccount } from "@hooks/useManagedAccount";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import {
  ManagedAccountDecisionDTO,
  ManagedAccountFilterDTO,
} from "@models/managed-accounts/entities/analysis";
import { ManageAccountCommentDialog } from "@models/managed-accounts/entities/comment";
import { SelectedSubProductDTO } from "@models/managed-accounts/entities/step/fee";
import { ManagedAccountStep } from "@models/managed-accounts/enums/step";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { ManagedAccountFeeAnalysisTable } from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/_index";
import { flatManagedAccountFeeAnalysisData } from "@pages/managed-accounts/_id/steps/fee-analysis/util";
import { ExportMAExcelButton } from "@pages/managed-accounts/components/buttons/export-excel";
import { FeatureAnalysisSkeleton } from "@pages/platform-analysis/_id/steps/feature/analysis/skeleton";
import { ChangeRichTextDialog } from "@pages/platform-analysis/_id/steps/summary/components/change-rich-text-dialog";
import { FeeAnalysisFilterYesNoOptions } from "@pages/platform-analysis/constant";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";

export interface FeatureAnalysisProps extends StepCompProps {}
export const ManagedAccountFeeAnalysis: ForwardRefRenderFunction<
  StepCompRef,
  StepCompProps
> = ({ stepNumber }, ref) => {
  const {
    ui,
    managedAccount,
    setFeeAnalysisRow,
    setFeeAnalysisCollapseRowMapping,
    loadManagedAccountStep,
    updateManagedAccountFilter,
    updateManagedAccountDecision,
  } = useManagedAccount();

  const isNew = useIsNew();
  const { managedAccountId } = useParams<{ managedAccountId: string }>();
  const { removeLocalStorageItem } = useLocalStorage();
  const [scrollingTop, setScrollingTop] = useState(false);
  const [tableViewMode, setTableViewMode] = useState<FeesDisplayStyle>(
    FeesDisplayStyle.Dollar,
  );
  const [updatingComment, setUpdatingComment] = useState(false);
  const [commentDialog, setCommentDialog] = useState<
    ManageAccountCommentDialog | undefined
  >();
  const [selectedSubProducts, setSelectedSubProduct] = useState<
    SelectedSubProductDTO[]
  >([]);

  const loadManageAccountFeeAnalysis = async (managedAccountId?: string) => {
    if (managedAccountId) {
      const data = await loadManagedAccountStep({
        managedAccountId: managedAccountId,
        stepId: ManagedAccountStep.FeeAnalysis,
      });

      if (data && data.feeAnalysis) {
        const { rows, collapseMapping } = flatManagedAccountFeeAnalysisData(
          data.feeAnalysis.all.sections,
        );
        setFeeAnalysisRow(rows);
        setFeeAnalysisCollapseRowMapping(collapseMapping);
        setSelectedSubProduct(data.feeAnalysis.all.selectedSubProducts);
      }

      const tableDisplayType =
        data?.filters?.portfolioFeesByPlatform?.TableDisplayType ===
        FeesDisplayStyle.Percentage
          ? FeesDisplayStyle.Percentage
          : FeesDisplayStyle.Dollar;
      setTableViewMode(tableDisplayType);
    }
  };

  const saveFilter = async () => {
    const filterData: ManagedAccountFilterDTO = {
      portfolioFeesByPlatform: {
        TableDisplayType: tableViewMode,
      },
    };

    await updateManagedAccountFilter(managedAccountId ?? "", filterData);
  };

  const handleOnChangeComment = async (comment: string | null) => {
    setUpdatingComment(true);
    const newDecision: ManagedAccountDecisionDTO = {
      step: ManagedAccountStep.FeeAnalysis,
      subProductId: commentDialog?.subProductId ?? "",
      decisionStatus: null,
      comment: comment,
    };

    const isSuccess = await updateManagedAccountDecision(
      managedAccountId ?? "",
      newDecision,
    );

    setUpdatingComment(false);
    setCommentDialog(undefined);

    if (isSuccess) {
      const newSelectedSubProduct = selectedSubProducts.map((platform) => {
        if (platform.id !== newDecision.subProductId) return platform;
        return {
          ...platform,
          comment: {
            comment: newDecision.comment ?? "",
            id: null,
          },
        };
      });

      setSelectedSubProduct(newSelectedSubProduct);
    }
  };

  useEffectOnce(() => {
    removeLocalStorageItem(LocalStorage.scrollingLeft);
    removeLocalStorageItem(LocalStorage.scrollingTop);
  });

  useEffect(() => {
    if (!isNew) {
      loadManageAccountFeeAnalysis(managedAccountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedAccountId]);

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep, isNext) => {
      saveFilter();
      return true;
    },
    onChangeOtherStep: async () => {
      saveFilter();
      return true;
    },
    onSubmit: async () => {
      return true;
    },
    onExit: async () => {
      saveFilter();
      return true;
    },
  }));

  if (ui.isLoading) return <FeatureAnalysisSkeleton />;

  return (
    <SHContainer
      maxWidth="lg2"
      sx={{
        px: {
          xs: "16px",
          lg2: 0,
        },
        py: "25px",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        "::-webkit-scrollbar": {
          boxShadow: "none",
        },
        "::-webkit-scrollbar-thumb": {
          boxShadow: "none",
        },
      }}
    >
      <SHStack
        className={`managedAccount__headerInfo${scrollingTop ? " sticky" : ""}`}
        sx={{
          transition: "all 0.5s ease",
        }}
      >
        <SHStack flexDirection={"row"} justifyContent={"space-between"}>
          <StepName
            name={"Portfolio fees by platform"}
            stepNumber={stepNumber}
          />

          <SHTypography variant="body2">
            {managedAccount?.dataValidDate
              ? `Data valid: ${format(
                  new Date(managedAccount?.dataValidDate),
                  DateFormat2,
                )}`
              : ""}
          </SHTypography>
        </SHStack>

        <SHStack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <SHTypography
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              paddingTop: "5px",
            }}
          >
            Portfolio size:
            <NumericFormat
              prefix=" $"
              displayType="text"
              value={managedAccount?.feeAnalysis?.all.portfolioSize}
              thousandSeparator=","
            />
          </SHTypography>
          <SHStack flexDirection={"row"} alignItems={"center"} gap={2}>
            <SHToggleButtonOptions
              options={FeeAnalysisFilterYesNoOptions}
              value={tableViewMode}
              onChange={(event, value) => {
                if (!value) return;
                setTableViewMode(value);
              }}
            />
            <ExportMAExcelButton data={managedAccount} mode={tableViewMode} />
          </SHStack>
        </SHStack>
      </SHStack>

      <ManagedAccountFeeAnalysisTable
        title={managedAccount?.feeAnalysis?.all.header ?? ""}
        selectedSubProducts={selectedSubProducts}
        onScrollingTop={(isScrollingTop: boolean) =>
          setScrollingTop(isScrollingTop)
        }
        tableViewMode={tableViewMode}
        onModifyComment={(subProductId: string, comment?: string) => {
          setCommentDialog({
            comment: comment ?? null,
            subProductId: subProductId,
          });
        }}
      />
      {commentDialog && (
        <ChangeRichTextDialog
          onSubmit={handleOnChangeComment}
          title={
            isEmpty(commentDialog.comment) ? "Add comment" : "Edit comment"
          }
          placeHolder={"Enter your comment"}
          initialContent={commentDialog.comment ?? null}
          isSubmitting={updatingComment}
          onClose={() => {
            setCommentDialog(undefined);
          }}
          maxLength={1500}
          isCommentBox
        />
      )}
    </SHContainer>
  );
};
