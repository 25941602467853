import { Components, Theme } from "@mui/material";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
} from "./config";

export const muiDatePickerTheme = (
  theme: Theme,
): Pick<
  Components<Omit<Theme, "components">>,
  "MuiDesktopDatePicker" | "MuiCalendarPicker"
> => ({
  MuiDesktopDatePicker: {
    defaultProps: {
      openTo: "day",
      views: ["year", "month", "day"],
      inputFormat: DEFAULT_DATE_FORMAT,
      minDate: DEFAULT_MIN_DATE,
      maxDate: DEFAULT_MAX_DATE,
      orientation: "portrait",
      InputAdornmentProps: {
        sx: {
          pr: 2,
          "&>.MuiButtonBase-root": {
            p: "5px",
          },
          "&>.MuiButtonBase-root.Mui-disabled": {
            opacity: 0.5,
          },
        },
      },
      PaperProps: {
        sx: {
          boxShadow: theme.shadows[1],
          border: `1px solid ${theme.palette.divider}`,
        },
      },
      PopperProps: {
        placement: "top-end",
        disablePortal: false,
        modifiers: [
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "viewport",
              padding: 8,
            },
          },
          {
            name: "offset",
            options: {
              offset: [40, 5],
            },
          },
        ],
      },
    },
  },
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        "& .MuiDayPicker-header": {
          "& .MuiTypography-root": {
            color: theme.palette.primary.main,
          },
        },
        "& .MuiPickersCalendarHeader-root": {
          "& .MuiPickersCalendarHeader-label": {
            paddingTop: "2px",
          },
          "& .MuiIconButton-root": {
            padding: "2px",
          },
        },
        "& .MuiButtonBase-root": {
          paddingTop: "4px",
        },
        "& .MuiPickersDay-today:not(.Mui-selected)": {
          border: `1px solid ${theme.palette.primary.main}`,
        },
        "& .MuiYearPicker-root": {
          "& .PrivatePickersYear-yearButton": {
            paddingTop: "3px",
          },
        },
      },
    },
  },
});
