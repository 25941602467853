import { ArrowDownSVG, CloseSVG } from "@components/svgs";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from "@mui/material";
import { SHTextField, SHTextFieldProps } from "../sh-text-field";

export interface SHAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    "renderInput"
  > {
  textFieldProps?: Omit<SHTextFieldProps, "value">;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}
export const SHAutocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  textFieldProps,
  disabled,
  renderInput,
  ...others
}: SHAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  return (
    <Autocomplete
      {...others}
      disabled={disabled}
      popupIcon={
        <ArrowDownSVG
          width={"12px"}
          height={"12px"}
          opacity={disabled ? 0.5 : 1}
        />
      }
      clearIcon={<CloseSVG width={"10px"} height={"10px"} />}
      renderInput={
        renderInput
          ? renderInput
          : (params) => (
              <SHTextField
                {...params}
                {...textFieldProps}
                InputProps={{
                  ...params.InputProps,
                  ...(textFieldProps?.InputProps
                    ? textFieldProps.InputProps
                    : {}),
                }}
              />
            )
      }
    />
  );
};
