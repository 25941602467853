import { SHTypography } from "@components/design-systems";
import {
  TopNotificationItem,
  TopNotificationType,
} from "@components/top-notifications/model";
import { PageRoutes } from "@constants";
import { DateFormat } from "@constants/format";
import {
  SubscriptionBannerDTO,
  SubscriptionBannerHidden,
} from "@models/users/entities/subscriptionBanner";
import { SubscriptionBannerType } from "@models/users/enums/bannerType";
import { Link, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { differenceInDays, format } from "date-fns";
import { isNil } from "lodash";
import { generatePath, useNavigate } from "react-router";

export function convertSubscriptionBannersToNotificationItems(
  banners: SubscriptionBannerDTO[],
): TopNotificationItem[] {
  const notificationMapping: {
    [key in SubscriptionBannerType]: TopNotificationType;
  } = {
    [SubscriptionBannerType.SubscriptionUpcomingRenewal]:
      TopNotificationType.Success,
    [SubscriptionBannerType.ExpiringCard]: TopNotificationType.Warning,
    [SubscriptionBannerType.PaymentPending]: TopNotificationType.Warning,
    [SubscriptionBannerType.SubscriptionCancelled]: TopNotificationType.Error,
    [SubscriptionBannerType.PaymentFail]: TopNotificationType.Error,
    [SubscriptionBannerType.SubscriptionExpired]: TopNotificationType.Error,
  };

  const titleMapping: {
    [key in SubscriptionBannerType]: (expiredDate?: string) => string;
  } = {
    [SubscriptionBannerType.SubscriptionUpcomingRenewal]: (
      expiredDate?: string,
    ) => `Your subscription will renew soon`,
    [SubscriptionBannerType.ExpiringCard]: (expiredDate?: string) =>
      `Update your credit card details before your subscription renews`,
    [SubscriptionBannerType.PaymentPending]: (expiredDate?: string) =>
      `Payment pending: Provide your credit card details before ${expiredDate}`,
    [SubscriptionBannerType.SubscriptionCancelled]: (expiredDate?: string) =>
      `Your subscription will expire on ${expiredDate}`,
    [SubscriptionBannerType.PaymentFail]: (expiredDate?: string) =>
      `Payment failed: Your subscription will expire on ${expiredDate}`,
    [SubscriptionBannerType.SubscriptionExpired]: (expiredDate?: string) =>
      `Your subscription expired on ${expiredDate}`,
  };

  return banners?.map((banner, index) => {
    const bannerType = banner.subscriptionBannerType;
    return {
      id: banner.subscriptionBannerType,
      title: titleMapping[bannerType](
        format(banner.subscriptionExpiredDate, DateFormat),
      ),
      message: (
        <SubscriptionBannerMessage
          type={notificationMapping[bannerType]}
          banner={banner}
        />
      ),
      isAutoClose: false,
      showCloseButton: true,
      type: notificationMapping[bannerType],
      isAdviserUserSubscription: true,
      subscriptionExpiredDate: banner.subscriptionExpiredDate,
      testClockDate: banner.testClockDate,
    } as TopNotificationItem;
  }) as TopNotificationItem[];
}

const SubscriptionBannerMessage = ({
  type,
  banner,
}: {
  type: TopNotificationType;
  banner: SubscriptionBannerDTO;
}) => {
  const {
    adviserFirmId,
    numberLicence,
    cardNumberLast4,
    subscriptionBannerType: subScriptionBannerType,
    subscriptionExpiredDate,
  } = banner;
  const { palette } = useTheme();
  const navigate = useNavigate();

  const messageColorMapping: {
    [key in TopNotificationType]: string;
  } = {
    [TopNotificationType.Error]: palette.error[500],
    [TopNotificationType.Success]: palette.success[200],
    [TopNotificationType.Warning]: palette.warning[800],
    [TopNotificationType.Information]: hexToRGBA(palette.secondary.main, 0.9),
  };

  const subscriptionExpiredDateFormatted = format(
    subscriptionExpiredDate,
    DateFormat,
  );
  const messageMapping: {
    [key in SubscriptionBannerType]: string;
  } = {
    [SubscriptionBannerType.SubscriptionUpcomingRenewal]: `Your SuitabilityHub subscription will renew for ${numberLicence} ${
      numberLicence === 1 ? "licence" : "licences"
    } on ${subscriptionExpiredDateFormatted}. You can review your subscription`,
    [SubscriptionBannerType.ExpiringCard]: `Your card ending in ${cardNumberLast4} expired/will expire before your SuitabilityHub subscription renews on ${subscriptionExpiredDateFormatted}. Update your card details`,
    [SubscriptionBannerType.PaymentPending]: `You have not yet paid for your subscription. If you do not do so by ${subscriptionExpiredDateFormatted}, all users will be downgraded to Trial Users. Provide your card details`,
    [SubscriptionBannerType.SubscriptionCancelled]: `You do not have any licences set to renew. All users will be downgraded to Trial Users on ${subscriptionExpiredDateFormatted}. Manage your subscription`,
    [SubscriptionBannerType.PaymentFail]: `We were unable charge your card. All users will be downgraded to Trial Users on ${subscriptionExpiredDateFormatted}. Update your card details`,
    [SubscriptionBannerType.SubscriptionExpired]: `All users were downgraded to Trial Users on ${subscriptionExpiredDateFormatted}. For full access, purchase licences`,
  };

  return (
    <SHTypography
      variant="body2"
      fontSize={13}
      color={messageColorMapping[type]}
    >
      {messageMapping[subScriptionBannerType]}
      <Link
        variant="body2"
        color={messageColorMapping[type]}
        component="a"
        sx={{
          paddingLeft: 0.5,
          cursor: "pointer",
          fontSize: 13,
        }}
        onClick={() => {
          let url = "subscription";
          if (
            subScriptionBannerType === SubscriptionBannerType.ExpiringCard ||
            subScriptionBannerType === SubscriptionBannerType.PaymentPending ||
            subScriptionBannerType === SubscriptionBannerType.PaymentFail
          )
            url = "billingInfo";
          navigate(
            `${generatePath(PageRoutes.practiceOverview, {
              practiceId: adviserFirmId,
            })}/${url}`,
          );
        }}
      >
        here
      </Link>
      .
    </SHTypography>
  );
};

export const compareCurrentBannerWithHiddenBanner = (
  banners: SubscriptionBannerDTO[],
  bannersHidden: SubscriptionBannerHidden[],
  auth0Id: string,
  isNewLogin?: boolean,
): {
  bannersFiltered: SubscriptionBannerDTO[];
  newBannersHidden: SubscriptionBannerHidden[];
} => {
  let bannersFiltered: SubscriptionBannerDTO[] = [];
  let newBannersHidden: SubscriptionBannerHidden[] = bannersHidden;
  banners.forEach((banner) => {
    var bannerHidden = bannersHidden.find(
      (item) =>
        item.id === banner.subscriptionBannerType &&
        item.userId === auth0Id &&
        item.expiredDate.toString() ===
          banner.subscriptionExpiredDate.toISOString(),
    );

    if (!bannerHidden) {
      bannersFiltered.push(banner);
    } else {
      const bannerType = banner.subscriptionBannerType;

      // if has testClockDate will compare with testClockDate else check will currentDate
      const diffDays = differenceInDays(
        new Date(banner.subscriptionExpiredDate),
        new Date(
          !isNil(banner.testClockDate) ? banner.testClockDate : new Date(),
        ),
      );

      switch (bannerType) {
        // 15 - 7 days from expiredDate, do not show again
        // 7 - 0 days left from expiredDate, show on each login
        case SubscriptionBannerType.SubscriptionCancelled:
          if (diffDays <= 7 && isNewLogin) {
            bannersFiltered.push(banner);
            newBannersHidden = newBannersHidden?.filter(
              (item) =>
                item.id !== SubscriptionBannerType.SubscriptionCancelled &&
                item.userId !== auth0Id,
            );
          }
          break;

        // Once this banner is closed, do not show again
        case SubscriptionBannerType.SubscriptionExpired:
        case SubscriptionBannerType.SubscriptionUpcomingRenewal:
          break;

        // 30 - 15 days from expiredDate, do not show again
        // 15 - 0 days left from expiredDate, show on each login
        case SubscriptionBannerType.ExpiringCard:
          if (diffDays <= 15 && isNewLogin) {
            bannersFiltered.push(banner);
            newBannersHidden = newBannersHidden?.filter(
              (item) =>
                item.id !== SubscriptionBannerType.ExpiringCard &&
                item.userId !== auth0Id,
            );
          }

          break;

        // Show each on login
        case SubscriptionBannerType.PaymentFail:
          if (isNewLogin) {
            bannersFiltered.push(banner);
            newBannersHidden = newBannersHidden?.filter(
              (item) =>
                item.id !== SubscriptionBannerType.PaymentFail &&
                item.userId !== auth0Id,
            );
          }
          break;

        case SubscriptionBannerType.PaymentPending:
          if (isNewLogin) {
            bannersFiltered.push(banner);
            newBannersHidden = newBannersHidden?.filter(
              (item) =>
                item.id !== SubscriptionBannerType.PaymentPending &&
                item.userId !== auth0Id,
            );
          }
          break;
      }
    }
  });

  return {
    bannersFiltered: bannersFiltered,
    newBannersHidden: newBannersHidden,
  };
};
