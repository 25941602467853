import { SHContainer, SHStack, SHTabs } from "@components/design-systems";
import { SHTopBarSkeleton } from "@components/skeletons/layouts";
import { useReviewClient } from "@hooks/useReviewClient";
import { TopBarPlacement } from "@layouts/top-bar";
import { Outlet } from "react-router";

export const ManageReviewClient = () => {
  // const isNew = useIsNew();
  const {
    clientUI: { isLoading },
  } = useReviewClient();

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack sx={{ paddingY: 3 }} spacing={3}>
        {isLoading && (
          <SHTopBarSkeleton totalButtons={2} marginY={0} marginTop={3} />
        )}

        <TopBarPlacement sx={{ display: isLoading ? "none" : undefined }} />
        <SHTabs
          isLoading={isLoading}
          tabs={[
            {
              label: "Overview",
              linkTo: "",
            },
            {
              label: "Reviews",
              linkTo: "reviews",
              // disabled: isNew,
              disabled: true, //TODO: Remove when implement it
            },
          ]}
        />
        <Outlet />
      </SHStack>
    </SHContainer>
  );
};
