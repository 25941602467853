import {
  SHInputAdornment,
  SHNumericField,
  SHNumericFieldProps,
} from "@components/design-systems";
import { Dollar2SVG } from "@components/svgs";
import { MaxAccountBalance, MinAccountBalance } from "../../config";

export const FeeCurrencyField = (props: SHNumericFieldProps) => {
  return (
    <SHNumericField
      {...props}
      min={MinAccountBalance}
      max={MaxAccountBalance}
      numericFormatProps={{
        thousandSeparator: true,
      }}
      InputProps={{
        startAdornment: (
          <SHInputAdornment position="start">
            <Dollar2SVG />
          </SHInputAdornment>
        ),

        ...props.InputProps,
      }}
      sx={{
        "& .MuiInput-root": {
          "&::before,&::after": {
            left: "30px",
          },
        },
        ...props.sx,
      }}
    />
  );
};
