import { InvestmentQueryOptions } from "./../models/managed-accounts/entities/step/setup";
import { useNotification } from "@hooks/useNotification";
import { RootState, useAppDispatch } from "@redux/store";
import {
  getInvestmentFavouritedThunk,
  getInvestmentSelectionsThunk,
  putInvestmentFavouritedThunk,
  resetInvestmentStore,
  updateInvestmentFavouritedStore,
  updateInvestmentSelectionStore,
  updateOriginalInvestmentFavouritedStore,
  updateOriginalInvestmentSelectionStore,
  updateSelectedInvestmentCodes,
} from "@redux/slices/investments";
import { useSelector } from "react-redux";
import { InvestmentSelectionDTO } from "@models/managed-accounts/entities/step/setup";

export const useInvestment = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const {
    ui,
    originalInvestmentSelections,
    originalInvestmentFavourited,
    investmentSelections,
    investmentFavourited,
    selectedInvestmentCode,
  } = useSelector((state: RootState) => state.investment);

  // Data states
  const setOriginalInvestmentSelection = (
    investmentSelections?: InvestmentSelectionDTO[],
  ) => {
    dispatch(updateOriginalInvestmentSelectionStore(investmentSelections ?? []));
  };

  const setOriginalInvestmentFavourited = (
    investmentFavourited?: InvestmentSelectionDTO[],
  ) => {
    dispatch(updateOriginalInvestmentFavouritedStore(investmentFavourited ?? []));
  };

  const setInvestmentSelection = (
    investmentSelections?: InvestmentSelectionDTO[],
  ) => {
    dispatch(updateInvestmentSelectionStore(investmentSelections ?? []));
  };

  const setInvestmentFavourited = (
    investmentFavourited?: InvestmentSelectionDTO[],
  ) => {
    dispatch(updateInvestmentFavouritedStore(investmentFavourited ?? []));
  };

  const setSelectedInvestmentCode = (codes?: string[]) => {
    dispatch(updateSelectedInvestmentCodes(codes ?? []));
  };

  // APIs interaction
  const loadInvestmentSelection = async (
    queryOptions: InvestmentQueryOptions,
  ) => {
    const response = await dispatch(
      getInvestmentSelectionsThunk(queryOptions),
    ).unwrap();

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }

    return response.data;
  };

  const loadInvestmentFavourited = async (
    queryOptions: InvestmentQueryOptions,
  ) => {
    const response = await dispatch(
      getInvestmentFavouritedThunk(queryOptions),
    ).unwrap();

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }

    return response.data;
  };

  const putInvestmentFavourited = async (shCodes?: string[]) => {
    const response = await dispatch(
      putInvestmentFavouritedThunk(shCodes ?? []),
    ).unwrap();

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }

    return response.data;
  };

  const resetStore = () => {
    dispatch(resetInvestmentStore());
  };

  return {
    ui,
    originalInvestmentSelections,
    originalInvestmentFavourited,
    investmentSelections,
    investmentFavourited,
    selectedInvestmentCode,
    setOriginalInvestmentSelection,
    setOriginalInvestmentFavourited,
    setInvestmentSelection,
    setInvestmentFavourited,
    setSelectedInvestmentCode,
    loadInvestmentSelection,
    loadInvestmentFavourited,
    putInvestmentFavourited,
    resetStore,
  };
};
