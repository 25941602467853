import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { SHStack } from "@components/design-systems";
import InactivityDialog from "@components/dialogs/inactivity";
import RefreshPageDialog from "@components/dialogs/refresh-page";
import { LocalStorage } from "@constants";
import { PageRoutes } from "@constants/routes";
import { useAccessChecking } from "@hooks/useAccessChecking";
import { useLocalStorage } from "@hooks/useLocalStorage";
import {
  compareCurrentBannerWithHiddenBanner,
  convertSubscriptionBannersToNotificationItems,
} from "@layouts/private-layout/util";
import { SubscriptionBannerHidden } from "@models/users/entities/subscriptionBanner";
import { GetUserInfoDTO } from "@models/users/entities/user";
import { AdviserUsersGroup, SupplierUsersGroup } from "@models/users/group";
import { updateUser } from "@redux/slices/auth";
import { updateShowForceRefreshDialog } from "@redux/slices/global";
import { updateSubscriptionNotifications } from "@redux/slices/top-notifications";
import { RootState } from "@redux/store";
import {
  addAccessTokenInterceptor,
  refreshAccessToken,
} from "@services/shService";
import { activateUser, getUserInfo } from "@services/users/userService";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { shHistory } from "src/App";

const PrivateLayoutComp = (): JSX.Element => {
  const dispatch = useDispatch();
  const [userInfoData, setUserInfoData] = useState<GetUserInfoDTO>();
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useSelector((state: RootState) => state.auth);
  useAccessChecking();

  const {
    globalUI: { isCheckingUserAccess },
  } = useSelector((state: RootState) => state.global);

  const navigate = useNavigate();
  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();

  const handleOnActivateUser = async () => {
    const { isSuccess, data } = await getUserInfo();
    if (!data?.userMetadata) {
      console.log("User info has not Metadata: ", data);
    }
    if (isSuccess) {
      const userInfo = data as GetUserInfoDTO;

      if (userInfo) {
        setUserInfoData(userInfo);
        dispatch(updateUser(userInfo));
        dispatch(updateShowForceRefreshDialog(userInfo.updatingFirm ?? false));
        if (!userInfo.updatingFirm) {
          if (userInfo?.userMetadata?.is_first_login) {
            const { isSuccess } = await activateUser(userInfo?.auth0Id);
            if (isSuccess) refreshAccessToken(getAccessTokenSilently);
          }
          if (
            (SupplierUsersGroup?.includes(userInfo?.userType) ||
              AdviserUsersGroup?.includes(userInfo?.userType)) &&
            !userInfo?.userMetadata?.is_profile_completed
          )
            navigate(
              `${PageRoutes.users}/${userInfo?.auth0Id}/complete-profile`,
            );

          // Handle Subscription Banner
          if (
            AdviserUsersGroup?.includes(userInfo?.userType) &&
            !isEmpty(userInfo?.subscriptionBanner)
          ) {
            const banners = userInfo?.subscriptionBanner ?? [];

            console.log("from API:", banners);

            const bannersHidden: SubscriptionBannerHidden[] =
              getLocalStorageItem(LocalStorage.SubscriptionBannersHidden) ?? [];

            console.log("hidden:", bannersHidden);

            const { bannersFiltered, newBannersHidden } =
              compareCurrentBannerWithHiddenBanner(
                banners,
                bannersHidden,
                userInfo.auth0Id,
                userInfo.isNewLogin,
              );

            console.log("after filtered:", bannersFiltered);

            setLocalStorageItem(
              LocalStorage.SubscriptionBannersHidden,
              newBannersHidden,
            );
            const topNotifications =
              convertSubscriptionBannersToNotificationItems(bannersFiltered);

            console.log("top:", topNotifications);

            dispatch(updateSubscriptionNotifications(topNotifications));
          }
        }
      }
    } else {
      console.log("Error get user info: ", data);
    }
  };

  const navigateCompleteProfilePage = () => {
    if (!user) return;
    if (user.updatingFirm) return;
    if (
      (SupplierUsersGroup?.includes(user?.userType) ||
        AdviserUsersGroup?.includes(user?.userType)) &&
      !user?.userMetadata?.is_profile_completed
    ) {
      navigate(`${PageRoutes.users}/${user?.auth0Id}/complete-profile`);
    }
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!isCheckingUserAccess) navigateCompleteProfilePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckingUserAccess, shHistory.action]);

  useEffectOnce(() => {
    handleOnActivateUser();
  });

  //TODO: Session checking
  if (!userInfoData) {
    return <></>;
  }

  return (
    <SHStack
      sx={{
        width: "100vw",
        height: "100vh",
        display: isCheckingUserAccess ? "none" : undefined,
      }}
      direction="column"
    >
      <Outlet />
      <InactivityDialog />
      <RefreshPageDialog />
    </SHStack>
  );
};

export const PrivateLayout = withAuthenticationRequired(PrivateLayoutComp, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <></>,
});
