import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import {
  CreateRateCardDTO,
  RateCardDTO,
  RateCardStatus,
  UpdateRateCardDTO,
} from "@models/rate-card/entities/rateCard";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getRateCard(
  rateCardId?: string,
): Promise<APIResponse<RateCardDTO>> {
  const response = await APIGatewayService.get<RateCardDTO>(
    `${APIExtRoutes.rateCard}/${rateCardId}`,
  );
  return handleErrorProxy(response);
}

export async function getRateCardLOV(): Promise<APIResponse<RateCardDTO>> {
  const response = await APIGatewayService.get<RateCardDTO>(
    `${APIExtRoutes.rateCard}/new`,
  );
  return handleErrorProxy(response);
}

export async function postRateCard(
  rateCard?: CreateRateCardDTO,
): Promise<APIResponse<RateCardDTO>> {
  const response = await APIGatewayService.post<CreateRateCardDTO>(
    `${APIExtRoutes.rateCard}`,
    rateCard,
  );
  return handleErrorProxy(response, "Rate card has been created.");
}

export async function putRateCard(
  rateCard?: UpdateRateCardDTO,
): Promise<APIResponse<RateCardDTO>> {
  const response = await APIGatewayService.put<UpdateRateCardDTO>(
    `${APIExtRoutes.rateCard}`,
    rateCard,
  );
  return handleErrorProxy(response, "Your change has been updated.");
}

export async function putRateCardStatus(
  rateCardId: string,
  status: RateCardStatus,
): Promise<APIResponse<RateCardStatus>> {
  const response = await APIGatewayService.put<RateCardStatus>(
    generatePath(APIExtRoutes.updateRateCardStatus, {
      rateCardId,
      status,
    }),
  );
  return handleErrorProxy(response, "Your change has been updated.");
}

export async function checkRateCardUniqueName(
  supplierId: string,
  adviserFirmId: string,
  name: string,
  rateCardUniqueNameLevel: string,
  productId: string,
  id?: string,
): Promise<APIResponse<unknown>> {
  const response = await APIGatewayService.post<unknown>(
    APIExtRoutes.rateCardUniqueName,
    { supplierId, adviserFirmId, name, id, rateCardUniqueNameLevel, productId },
  );
  return handleErrorProxy(response);
}
