export const takeDecimalNumber = (num: number, n: number) => {
  let base = 10 ** n;
  let result = Math.round(num * base) / base;
  return result;
};

export const formatNumberFixed = (
  num: number,
  fixedDecimal: number = 2,
): string => {
  // Convert the number to a string
  const numStr: string = takeDecimalNumber(num, fixedDecimal).toString();
  // Check if the last character is a zero
  if (numStr.indexOf(".") === -1 || numStr.slice(-1) !== "0") {
    return numStr;
  }
  // Remove the last character from the string
  const newNumStr: string = numStr.slice(0, -1);
  // Convert the string back to a number
  const newNum: number = parseFloat(newNumStr);
  return newNum.toString();
};

export const formatLargeNumber = (num: number): string => {
  if (num < 999.5) {
    return formatNumberFixed(num, 1);
  }

  if (num < 999500) {
    return formatNumberFixed(num / Math.pow(10, 3), 1) + "k";
  }

  if (num < 999500000) {
    if (num < Math.pow(10, 6)) {
      return formatNumberFixed(num / Math.pow(10, 6), 1) + "m";
    }
    return formatNumberFixed(num / Math.pow(10, 6), 2) + "m";
  }
  if (num < 999500000000) {
    return formatNumberFixed(num / Math.pow(10, 9), 2) + "b";
  }
  if (num < 999500000000) {
    return formatNumberFixed(num / Math.pow(10, 12), 2) + "t";
  }
  return formatNumberFixed(num, 2);
};

export const formatPercentage = (num: number): string => {
  return num.toFixed(2);
};

export const thousandSeparator = (
  num: number,
  separator: string = ",",
): string => {
  const numStr = num.toString();
  const numArr = numStr.split(".");
  const integerPart = numArr[0];
  const decimalPart = numArr.length > 1 ? "." + numArr[1] : "";
  const regexPattern = /\B(?=(\d{3})+(?!\d))/g;
  const formattedIntegerPart = integerPart.replace(regexPattern, separator);
  return formattedIntegerPart + decimalPart;
};
