import { SHStack, SHStackProps } from "@components/design-systems";
import SHSkeleton, {
  SHSkeletonProps,
} from "@components/design-systems/sh-skeleton";
import { SHButtonSkeleton } from "@components/skeletons/design-systems/SHButtonSkeleton";

interface SHTopBarSkeletonProps {
  totalButtons?: number;
}

export const SHTopBarSkeleton = ({
  totalButtons = 1,
  ...others
}: SHTopBarSkeletonProps & SHSkeletonProps & SHStackProps) => {
  return (
    <SHStack
      spacing={3}
      marginY={3}
      justifyContent={"space-between"}
      direction={{ xs: "column", sm: "row" }}
      alignItems={{ xs: "start", sm: "center" }}
      {...others}
    >
      <SHSkeleton height={40} width="40%" />
      <SHStack direction={"row"} spacing={2}>
        {Array.from(Array(totalButtons).keys()).map((index) => (
          <SHButtonSkeleton key={index} />
        ))}
      </SHStack>
    </SHStack>
  );
};
