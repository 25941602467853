import SHSkeleton, {
  SHSkeletonProps,
} from "@components/design-systems/sh-skeleton";

export const SHAvatarSkeleton = ({
  variant = "circular",
  ...others
}: SHSkeletonProps) => {
  return <SHSkeleton height={120} width={120} variant={variant} {...others} />;
};
