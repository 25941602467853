import { Components, Theme } from "@mui/material";
import { hexToRGBA } from "@utils";

export const muiMenuItemTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiMenuItem"> => ({
  MuiMenuItem: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: hexToRGBA(theme.palette.primary.light, 0.1),
          "&:hover": {
            backgroundColor: hexToRGBA(theme.palette.primary.light, 0.15),
          },
        },
        "&:hover": {
          backgroundColor: hexToRGBA(theme.palette.primary.light, 0.07),
        },
      },
    },
  },
});
