import {
  SHDotGroup,
  SHDotGroupProps,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { ReactNode } from "react";

export interface SHLabelProps {
  label?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  postfixLabel?: ReactNode;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
  actionButton?: ReactNode;
}
export const SHLabel = ({
  label,
  disabled,
  required,
  postfixLabel,
  dotGroupProps,
  actionButton,
}: SHLabelProps) => {
  return (
    <SHStack
      direction="row"
      data-testid="sh-label"
      alignItems="center"
      justifyContent="space-between"
    >
      <SHStack direction="row" spacing={1} alignItems="center">
        <SHStack direction="row" spacing={0.5} alignItems="start">
          {typeof label === "string" ? (
            <SHTypography variant="subtitle2" disabled={disabled}>
              {label}
            </SHTypography>
          ) : (
            label
          )}
          <SHDotGroup
            redDot={required}
            disabled={disabled}
            paddingTop={"5px"}
          />
          <SHDotGroup
            {...dotGroupProps}
            disabled={disabled}
            paddingTop={"5px"}
          />
        </SHStack>

        <SHStack direction="row" spacing={1} alignItems="center" pb={"5px"}>
          {postfixLabel && postfixLabel}
        </SHStack>
      </SHStack>
      {actionButton && actionButton}
    </SHStack>
  );
};
