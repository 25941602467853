import {
  SHButton,
  SHChip,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { EditSVG } from "@components/svgs";
import { HamburgerSVG } from "@components/svgs/hamburger";
import { DateFormat } from "@constants/format";
import { ConfigurationFeature } from "@models/configuration";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { format } from "date-fns";
import { useMemo } from "react";

export interface FeatureCardProps extends SHStackProps {
  feature: ConfigurationFeature;
  isEdit?: boolean;
  isOdd?: boolean;
  onClickEdit?: () => void;
  onClickView?: () => void;
  [key: string]: any;
}
export const FeatureCard = ({
  feature,
  isOdd,
  isEdit,
  onClickEdit,
  onClickView,
  ...others
}: FeatureCardProps) => {
  const { palette } = useTheme();
  const colorSx = useMemo(() => {
    if (feature.isArchived)
      return {
        background: `linear-gradient(0deg, ${hexToRGBA(
          palette.error.main,
          0.1,
        )}, ${hexToRGBA(palette.error.main, 0.1)}),${palette.common.white}`,
        border: `1px solid ${palette.error.main}`,
        "& .MuiTypography-root": {
          color: palette.error.main,
        },
        "&:hover": {
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.error.main,
            0.2,
          )}, ${hexToRGBA(palette.error.main, 0.2)}),${palette.common.white}`,
        },
      };
    if (isOdd)
      return {
        bgcolor: palette.common.white,
        "&:hover": {
          bgcolor: hexToRGBA(palette.common.white, 0.1),
        },
      };
    return {
      bgcolor: palette.secondary[50],
      "&:hover": {
        bgcolor: hexToRGBA(palette.secondary[50], 0.1),
      },
    };
  }, [isOdd, feature.isArchived, palette]);

  return (
    <SHStack
      sx={{
        backdropFilter: "blur(0.5px)",
        padding: "12.5px 15px",
        cursor: "pointer",
        border: `1px solid ${palette.secondary[100]}`,
        ...colorSx,
      }}
      direction="row"
      justifyContent="space-between"
      alignItems={"center"}
      spacing={2}
      data-testid="feature-card"
      {...others}
    >
      <SHStack
        direction="row"
        justifyContent="start"
        alignItems={"center"}
        spacing={2}
        width={"60%"}
      >
        {isEdit && <HamburgerSVG />}
        <SHStack justifyContent="start" alignItems={"start"} width="60%">
          <SHTypography variant="subtitle1">{feature.name}</SHTypography>
          <SHTypography variant="body4" colorVariant="third">
            {feature.fieldType?.description}
          </SHTypography>
        </SHStack>
        <SHTypography variant="body2" colorVariant="third" width="40%">
          Created: {format(feature?.createdDate || new Date(), DateFormat)}
        </SHTypography>
      </SHStack>
      <SHStack
        direction="row"
        justifyContent="end"
        alignItems={"center"}
        spacing={2}
        width={"40%"}
      >
        {(feature.isArchived || feature.isBusinessMetric) && (
          <SHChip
            color={feature.isArchived ? "error" : "success"}
            label={feature.isArchived ? "Archived" : "Business metric"}
            variant="outlined"
          />
        )}
        {isEdit ? (
          <SHButton
            startIcon={<EditSVG color={palette.common.white} />}
            variant="contained"
            onClick={onClickEdit}
            data-testid="feature-card--edit-button"
          >
            Edit
          </SHButton>
        ) : (
          <SHButton variant="contained" onClick={onClickView}>
            View
          </SHButton>
        )}
      </SHStack>
    </SHStack>
  );
};
