import { SVGProps } from "react";

export const UserSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={22}
        height={22}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16 19s1.333 0 1.333-1.333c0-1.334-1.333-5.334-6.666-5.334-5.334 0-6.667 4-6.667 5.334C4 19 5.333 19 5.333 19H16ZM5.363 17.667a.365.365 0 0 1-.03-.006c.002-.352.223-1.373 1.014-2.293.736-.863 2.029-1.701 4.32-1.701 2.289 0 3.582.84 4.32 1.701.79.92 1.01 1.943 1.013 2.293l-.01.003a.595.595 0 0 1-.02.003H5.364Zm5.304-8a2.667 2.667 0 1 0 0-5.334 2.667 2.667 0 0 0 0 5.334Zm4-2.667a4 4 0 1 1-8.001 0 4 4 0 0 1 8 0Z"
            fill="#25324B"
        />
    </svg>
);
