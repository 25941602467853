import { SHButton, SHDialog, SHStack } from "@components/design-systems";
import { useMemo } from "react";

interface DiscardStageDialogProps {
  onClose?: () => void;
  onSubmit?: () => void;
  changedStage: number;
}

export const DiscardStageDialog = ({
  onClose,
  onSubmit,
  changedStage,
}: DiscardStageDialogProps) => {
  const message = useMemo(() => {
    return {
      header: changedStage > 1 ? "stages" : "stage",
      body1: changedStage > 1 ? "Some" : "A",
      body2: changedStage > 1 ? "there" : "the",
    };
  }, [changedStage]);

  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: { xs: "100%", md: 656 },
        },
      }}
      header={`Discard ${message.header} specific data?`}
      body={`${message.body1} ${message.header} been removed from the analysis. Discard any existing data relating to ${message.body2} ${message.header}?`}
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onSubmit}
          >
            Discard
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onClose}
          >
            No, go back to setup
          </SHButton>
        </SHStack>
      }
    />
  );
};
