import { DEFAULT_GAP_2 } from "@pages/platform-analysis/components/buttons/export-pdf/constant";

import {
  PDFBackgroundDarkS3,
  PDFBackgroundLightS3,
  PDFDefaultPlatformLogoS3,
} from "@pages/platform-analysis/components/buttons/export-pdf/config";
import { Image, Page, View } from "@react-pdf/renderer";
import React from "react";
import { PDFReviewOverview } from "../../model";
import { useReviewStyles } from "../../styles";
import { PDFReviewPageBody } from "./components/body";
import { PDFReviewPageFooter } from "./components/footer";
import { PDFReviewPageHeader } from "./components/header";
interface PDFReviewPageContainerProps {
  children?: React.ReactNode;
  isFirstPage?: boolean;
  totalPage?: number;
  pageIndex?: number;
  overviewData?: PDFReviewOverview;
  isAppendix?: boolean;
}

export const PDFReviewPageContainer = ({
  children,
  totalPage,
  pageIndex,
  overviewData,
  isFirstPage = false,
  isAppendix = false,
}: PDFReviewPageContainerProps) => {
  const { pageStyle } = useReviewStyles();

  return (
    <Page style={{ ...pageStyle.container }}>
      <View style={{ ...pageStyle.layout }}>
        <PDFReviewPageHeader
          isFirstPage={isFirstPage}
          adviserFirmLogo={
            overviewData?.adviserFirmLogo ?? PDFDefaultPlatformLogoS3
          }
          isAppendix={isAppendix}
        />
        <View style={{ gap: DEFAULT_GAP_2 }}>
          <PDFReviewPageBody isFirstPage={isFirstPage}>
            {children}
          </PDFReviewPageBody>
          <PDFReviewPageFooter
            pageIndex={pageIndex}
            totalPage={totalPage}
            overviewData={overviewData}
            isFirstPage={isFirstPage}
          />
        </View>
      </View>
      {/* Background image */}
      <Image
        src={isFirstPage ? PDFBackgroundDarkS3 : PDFBackgroundLightS3}
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          position: "absolute",
        }}
      />
    </Page>
  );
};
