import { SHButton } from "@components/design-systems";
import { DownloadSVG } from "@components/svgs";
import { useNotification } from "@hooks/useNotification";
import { ManagedAccountDTO } from "@models/managed-accounts/entities/analysis";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { useTheme } from "@mui/material";

interface ExportMAListExcelButtonProps {
  data?: ManagedAccountDTO;
  mode?: FeesDisplayStyle;
  disabled?: boolean;
}
export const ExportMAListExcelButton = ({
  data,
  mode = FeesDisplayStyle.Percentage,
  disabled,
}: ExportMAListExcelButtonProps) => {
  const { palette } = useTheme();
  const { notify } = useNotification();

  const handleOnDownload = () => {
    notify("Coming soon", {
      variant: "error",
      close: true,
    });
  };

  return (
    <SHButton
      onClick={handleOnDownload}
      startIcon={
        <DownloadSVG width={12} height={13} color={palette.text.white} />
      }
      variant="contained"
      disableRipple
      disabled={disabled || !data}
      sx={{
        "&:active svg path": {
          fill: palette.common.white,
        },
      }}
    >
      Excel
    </SHButton>
  );
};
