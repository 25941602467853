import {
  SHTable,
  SHTableBody,
  SHTableHead,
  SHTableRow,
} from "@components/design-systems";
import { LocalStorage } from "@constants";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { FeeAnalysisRowType } from "@models/managed-accounts/entities/analysis";
import {
  MAAnalysisBreakdownDTO,
  MAAnalysisSectionDTO,
} from "@models/managed-accounts/entities/step/fee";
import { useTheme } from "@mui/material";
import {
  FeeAnalysisTableHead,
  FeeAnalysisTableHeadProps,
} from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-head";
import { FeeAnalysisFeatureTableRow } from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-rows/feature-row";
import { FeeAnalysisSectionRow } from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-rows/section-row";
import { FeeAnalysisSubSectionRow } from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-rows/sub-section-row";
import { TABLE_ROW_HEIGHT } from "@pages/reviews/_id/steps/feature/analysis/config";
import { updateCollapseMappingAction } from "@redux/slices/managed-account";
import { RootState } from "@redux/store";
import { hexToRGBA } from "@utils";
import { debounce, isEmpty, keys, pickBy } from "lodash";
import { forwardRef, useEffect, useState, WheelEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableVirtuoso } from "react-virtuoso";
import "./styles.scss";

export const ManagedAccountFeeAnalysisTable = ({
  title,
  tableViewMode,
  selectedSubProducts,
  onModifyComment,
  onScrollingTop = () => {},
  ...others
}: FeeAnalysisTableHeadProps & {
  onScrollingTop?: (isScrolling: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { feeAnalysisRows, collapseMapping } = useSelector(
    (state: RootState) => state.managedAccount,
  );
  const { setLocalStorageItem, getLocalStorageItem } = useLocalStorage();

  const scrollingTop = getLocalStorageItem(LocalStorage.scrollingTop);
  const scrollingLeft = getLocalStorageItem(LocalStorage.scrollingLeft);
  const collapsedIds = keys(pickBy(collapseMapping, (value) => value === true));
  const [isExpandHeader, setIsExpandHeader] = useState(true);
  let timeoutIdScrollDown = -1;
  let isScrollUp = true;
  let isMouseScroll = true;

  const rowsRendered =
    isEmpty(feeAnalysisRows) || isEmpty(collapsedIds)
      ? feeAnalysisRows
      : feeAnalysisRows?.filter((obj) => {
          return !obj?.collapsedIds?.some((id) => collapsedIds?.includes(id));
        });
  const totalRowHeight = rowsRendered?.length * TABLE_ROW_HEIGHT;

  const handleOnCollapse = (sectionId?: string) => {
    if (!sectionId) return;
    dispatch(
      updateCollapseMappingAction({
        ...collapseMapping,
        [sectionId]: !collapseMapping?.[sectionId],
      }),
    );
  };

  const handleScrollTop = debounce(
    () => {
      setIsExpandHeader(true);
    },
    150,
    { leading: false, trailing: true },
  );

  const handleOnWheel = (event: WheelEvent<HTMLDivElement>) => {
    const scrollTop = Math.floor(event.currentTarget.scrollTop);
    let isTouchpadScrollToTop;

    isScrollUp = event.deltaY < 0;
    isMouseScroll = Math.abs(event.deltaY) > 50;
    isTouchpadScrollToTop = scrollTop === 0 && isScrollUp && !isMouseScroll;

    if (isTouchpadScrollToTop) {
      const selectors = [
        ".virtuoso__tableHead",
        ".virtuoso__tableContainer",
        ".managedAccount__headerInfo",
      ];
      const elements = selectors.map((selector) =>
        document.querySelector(selector),
      );
      const [tableHead, tableContainer, managedAccountHeader] = elements;

      onScrollingTop(false);
      setLocalStorageItem(LocalStorage.scrollingTop, false);
      [tableHead, managedAccountHeader, tableContainer].forEach((element) =>
        element?.classList.remove("sticky"),
      );
    }
  };

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const selectors = [
      ".virtuoso__tableBody",
      ".virtuoso__tableHead",
      ".virtuoso__tableContainer",
      ".managedAccount__headerInfo",
    ];

    const elements = selectors.map((selector) =>
      document.querySelector(selector),
    );

    const [tableBody, tableHead, tableContainer, managedAccountHeader] =
      elements;

    const scrollLeft = event.currentTarget.scrollLeft ?? 0;
    const scrollTop = Math.floor(event.currentTarget.scrollTop) ?? -1;
    const containerHeight = tableContainer?.clientHeight ?? 0;

    if (scrollLeft > 0) {
      tableBody?.classList.add("sticky");
      setLocalStorageItem(LocalStorage.scrollingLeft, true);
    } else {
      tableBody?.classList.remove("sticky");
      setLocalStorageItem(LocalStorage.scrollingLeft, false);
    }

    if (scrollTop > 0 && totalRowHeight > containerHeight && !isScrollUp) {
      setLocalStorageItem(LocalStorage.scrollingTop, true);
      [tableHead, managedAccountHeader, tableContainer].forEach((element) =>
        element?.classList.add("sticky"),
      );

      if (isExpandHeader) {
        event.currentTarget.scrollTop = 5;
        timeoutIdScrollDown = window.setTimeout(() => {
          setIsExpandHeader(false);
        }, 150);
      }
    } else if (scrollTop === 0 && isScrollUp && isMouseScroll) {
      event.currentTarget.scrollTop = 1;

      if (isExpandHeader) {
        onScrollingTop(false);
        setLocalStorageItem(LocalStorage.scrollingTop, false);
        [tableHead, managedAccountHeader, tableContainer].forEach((element) =>
          element?.classList.remove("sticky"),
        );
      }

      handleScrollTop();
    }
  };

  useEffect(() => window.clearTimeout(timeoutIdScrollDown));

  return (
    <TableVirtuoso
      className={`virtuoso__tableContainer`}
      data={rowsRendered}
      onScroll={handleOnScroll}
      onWheel={handleOnWheel}
      components={{
        Table: ({ style, ...others }) => {
          return (
            <SHTable
              className="virtuoso__Table"
              stickyHeader
              style={{
                tableLayout: "auto",
                maxWidth: "max-content",
              }}
              sx={{
                ...style,
                th: {
                  borderLeft: "none",
                  "&:first-child": {
                    borderLeft: `1px solid ${palette.secondary[100]}`,
                  },
                },
                td: {
                  borderTop: "none",
                  borderLeft: "none",
                  "&:first-child": {
                    borderLeft: `1px solid ${palette.secondary[100]}`,
                  },
                },
                tbody: {
                  "&>tr:first-child td": {
                    borderTop: `1px solid ${palette.secondary[100]}`,
                  },
                  "& tr>td:first-child": {
                    borderLeft: `1px solid ${palette.secondary[100]}`,
                  },
                },
              }}
              {...others}
            />
          );
        },
        TableHead: forwardRef(({ style, ...props }, ref) => {
          return (
            <SHTableHead
              className={`virtuoso__tableHead${scrollingTop ? " sticky" : ""}`}
              sx={{
                ...style,
                background: "transparent",
                height: selectedSubProducts?.length === 0 ? 265 : undefined,
              }}
              ref={ref}
              {...props}
            />
          );
        }),
        TableBody: forwardRef((props, ref) => (
          <SHTableBody
            className={`virtuoso__tableBody${scrollingLeft ? " sticky" : ""}`}
            ref={ref}
            {...props}
          />
        )),
        TableRow: forwardRef(({ style, item, ...others }, ref) => {
          const bgColor =
            item.type === FeeAnalysisRowType.Section
              ? palette.common.white
              : hexToRGBA(palette.secondary[100], 0.4);
          return (
            <SHTableRow
              ref={ref}
              sx={{
                ...style,
                height: TABLE_ROW_HEIGHT,
                backgroundColor: `${bgColor} !important`,
              }}
              {...others}
            />
          );
        }),
      }}
      style={{
        height: "110%",
      }}
      fixedHeaderContent={() => (
        <FeeAnalysisTableHead
          title={title}
          tableViewMode={tableViewMode}
          selectedSubProducts={selectedSubProducts}
          onModifyComment={onModifyComment}
          {...others}
        />
      )}
      itemContent={(index, section) => {
        switch (section?.type) {
          case FeeAnalysisRowType.Section:
            return (
              <FeeAnalysisSectionRow
                key={section?.variableName}
                tableViewMode={tableViewMode}
                section={section as MAAnalysisSectionDTO}
                selectedSubProducts={selectedSubProducts}
                isCollapsed={collapseMapping?.[section?.variableName ?? ""]}
                onCollapse={() => handleOnCollapse(section?.variableName)}
              />
            );
          case FeeAnalysisRowType.SubSection:
            return (
              <FeeAnalysisSubSectionRow
                key={section?.variableName}
                tableViewMode={tableViewMode}
                selectedSubProducts={selectedSubProducts}
                subSection={section as MAAnalysisSectionDTO}
                isCollapsed={collapseMapping?.[section?.variableName ?? ""]}
                onCollapse={() => handleOnCollapse(section.variableName)}
              />
            );
          case FeeAnalysisRowType.Breakdown:
            return (
              <FeeAnalysisFeatureTableRow
                key={section?.variableName}
                tableViewMode={tableViewMode}
                selectedSubProducts={selectedSubProducts}
                feature={section as MAAnalysisBreakdownDTO}
              />
            );
          default:
            <></>;
        }
      }}
    />
  );
};
