import {
  AdviserFirmDTO,
  UpdateAdviserFirmDTO,
} from "@models/practice/entities/practice";
import {
  BillingDetailDTO,
  BillingInfoDTO,
  PaymentMethodDTO,
} from "@models/practice/entities/practiceBillingInfo";
import { PracticeInvoice } from "@models/practice/entities/practiceInvoices";
import {
  SubscriptionDTO,
  UpdateRenewalDTO,
  UpdateUserSubscriptionDTO,
  UserSubscriptionDTO,
} from "@models/practice/entities/practiceLicences";
import { AdviserUserStatusAction } from "@models/practice/enums/status";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deletePaymentMethod,
  getBillingDetail,
  getBillingInfo,
  getPaymentMethod,
  getStripeClientSecret,
  postPaymentMethod,
  putBillingDetail,
  putPaymentMethodDefault,
} from "@services/practice/practiceBillingInfoService";
import {
  getLicencesAdditionalCost,
  getPracticeLicences,
  getPracticeUserLicences,
  postPracticeUserLicences,
  putLicencesRenewal,
  putPracticeLicences,
  putPracticeUserLicences,
} from "@services/practice/practiceLicencesService";
import {
  getPractice,
  patchPracticeStatus,
  postPractice,
  putPractice,
} from "@services/practice/practiceService";

interface PracticeStore {
  practiceClientSecret?: string;
  practice?: AdviserFirmDTO;
  practiceLicences?: SubscriptionDTO;
  practiceUserLicences?: UserSubscriptionDTO;
  practiceInvoices?: PracticeInvoice[];
  practiceBillingInfo?: BillingInfoDTO;
  practicePaymentMethod?: PaymentMethodDTO[];
  practiceBillingDetail?: BillingDetailDTO;
  ui: {
    isLoading?: boolean;
    isSubmitting?: boolean;
    isModifyingStatus?: AdviserUserStatusAction;
    isLoadingLicences?: boolean;
    isLoadingUserLicences?: boolean;
    isLoadingProceedPayment?: boolean;
    isLoadingUpdateRenewal?: boolean;
    isLoadingAdditionalCost?: boolean;
    isLoadingInvoices?: boolean;
    paymentMethod: {
      isLoading?: boolean;
      isSaving?: boolean;
      isDisable?: boolean;
    };
    billingDetail: {
      isLoading?: boolean;
      billingAddress: {
        isDirty: boolean;
        isValid: boolean;
      };
      billingContact: {
        isDirty: boolean;
        isValid: boolean;
      };
    };
    billingInfo: {
      showRedDot: boolean;
      isLoading: boolean;
      isLoadingClientSecret: boolean;
    };
    subscription: { isDirty: boolean };
    practiceName: string;
  };
}

const initialState: PracticeStore = {
  practice: undefined,
  ui: {
    practiceName: "",
    billingDetail: {
      billingAddress: {
        isDirty: false,
        isValid: true,
      },
      billingContact: {
        isDirty: false,
        isValid: true,
      },
    },
    paymentMethod: {},
    billingInfo: {
      isLoading: false,
      isLoadingClientSecret: false,
      showRedDot: false
    },
    subscription: { isDirty: false },
  },
};

const practiceSlice = createSlice({
  name: "practice",
  initialState,
  reducers: {
    setPracticeAction: (
      state,
      action: PayloadAction<AdviserFirmDTO | undefined>,
    ) => {
      state.practice = action.payload;
      if (state.practice) {
        state.ui.practiceName = state.practice?.name;
      }
    },
    setPracticeNameAction: (state, action: PayloadAction<string>) => {
      if (state.practice) {
        state.ui.practiceName = action.payload;
      }
    },
    setPracticeLicenceAction: (
      state,
      action: PayloadAction<SubscriptionDTO>,
    ) => {
      if (state.practice) {
        state.practiceLicences = action.payload;
      }
    },
    setPracticeBillingDetailsAction: (
      state,
      action: PayloadAction<BillingDetailDTO>,
    ) => {
      if (state.practiceBillingDetail) {
        state.practiceBillingDetail = action.payload;
      }
    },
    setIsDirtySubscriptionAction: (state, action: PayloadAction<boolean>) => {
      if (state.practice) {
        state.ui.subscription.isDirty = action.payload;
      }
    },
    setIsDirtyBillingAddressAction: (state, action: PayloadAction<boolean>) => {
      if (state.practice) {
        state.ui.billingDetail.billingAddress.isDirty = action.payload;
      }
    },
    setIsDirtyBillingContactAction: (state, action: PayloadAction<boolean>) => {
      if (state.practice) {
        state.ui.billingDetail.billingContact.isDirty = action.payload;
      }
    },
    setIsValidBillingAddressAction: (state, action: PayloadAction<boolean>) => {
      if (state.practice) {
        state.ui.billingDetail.billingAddress.isValid = action.payload;
      }
    },
    setIsValidBillingContactAction: (state, action: PayloadAction<boolean>) => {
      if (state.practice) {
        state.ui.billingDetail.billingContact.isValid = action.payload;
      }
    },
    resetBillingDetailUIAction: (state, action: PayloadAction) => {
      if (state.ui.billingDetail) {
        state.ui.billingDetail.billingAddress = {
          isDirty: false,
          isValid: true,
        };
        state.ui.billingDetail.billingContact = {
          isDirty: false,
          isValid: true,
        };
      }
    },
    setBillingInfoShowRedDot: (state, action: PayloadAction<boolean>) => {
      if(state.ui.billingInfo){
        state.ui.billingInfo.showRedDot = action.payload
      }
    },
  },
  extraReducers(builder) {
    // Admin manage Practice
    builder.addCase(loadPracticeThunk.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(loadPracticeThunk.fulfilled, (state, action) => {
      state.practice = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      if (state.practice) {
        state.ui.practiceName = state.practice?.name;
      }
      state.ui.isLoading = false;
    });
    builder.addCase(loadPracticeThunk.rejected, (state, action) => {
      state.ui.isLoading = false;
    });

    builder.addCase(loadPracticeLicencesThunk.pending, (state, action) => {
      state.ui.isLoadingLicences = true;
    });
    builder.addCase(loadPracticeLicencesThunk.fulfilled, (state, action) => {
      state.practiceLicences = action.payload.isSuccess
        ? action.payload?.data
        : undefined;

      state.ui.isLoadingLicences = false;
    });
    builder.addCase(loadPracticeLicencesThunk.rejected, (state, action) => {
      state.ui.isLoadingLicences = false;
    });

    builder.addCase(createPracticeThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(createPracticeThunk.fulfilled, (state, action) => {
      state.practice = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      if (state.practice) {
        state.ui.practiceName = state.practice?.name;
      }
      state.ui.isSubmitting = false;
    });
    builder.addCase(createPracticeThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    builder.addCase(updatePracticeThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(updatePracticeThunk.fulfilled, (state, action) => {
      state.ui.isSubmitting = false;
    });
    builder.addCase(updatePracticeThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    builder.addCase(updatePracticeStatusThunk.pending, (state, action) => {
      state.ui.isModifyingStatus = action.meta.arg.status;
    });
    builder.addCase(updatePracticeStatusThunk.fulfilled, (state, action) => {
      if (
        state.practice?.status &&
        action.payload.isSuccess &&
        action.payload.data
      ) {
        state.practice.status = action.payload.data;
      }
      state.ui.isModifyingStatus = undefined;
    });
    builder.addCase(updatePracticeStatusThunk.rejected, (state, action) => {
      state.ui.isModifyingStatus = undefined;
    });

    builder.addCase(updatePracticeLicencesThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(updatePracticeLicencesThunk.fulfilled, (state, action) => {
      state.ui.isSubmitting = false;
    });
    builder.addCase(updatePracticeLicencesThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    // User manage Practice
    // Manage Subscription
    builder.addCase(loadPracticeUserLicencesThunk.pending, (state, action) => {
      state.ui.isLoadingUserLicences = true;
    });
    builder.addCase(
      loadPracticeUserLicencesThunk.fulfilled,
      (state, action) => {
        state.practiceUserLicences = action.payload.isSuccess
          ? action.payload?.data
          : undefined;
        state.ui.isLoadingUserLicences = false;
      },
    );
    builder.addCase(loadPracticeUserLicencesThunk.rejected, (state, action) => {
      state.ui.isLoadingUserLicences = false;
    });

    builder.addCase(
      createPracticeUserLicencesThunk.pending,
      (state, action) => {
        state.ui.isLoadingProceedPayment = true;
      },
    );
    builder.addCase(
      createPracticeUserLicencesThunk.fulfilled,
      (state, action) => {
        state.ui.isLoadingProceedPayment = false;
      },
    );
    builder.addCase(
      createPracticeUserLicencesThunk.rejected,
      (state, action) => {
        state.ui.isLoadingProceedPayment = false;
      },
    );

    builder.addCase(
      updatePracticeUserLicencesThunk.pending,
      (state, action) => {
        state.ui.isLoadingProceedPayment = true;
      },
    );
    builder.addCase(
      updatePracticeUserLicencesThunk.fulfilled,
      (state, action) => {
        state.ui.isLoadingProceedPayment = false;
      },
    );
    builder.addCase(
      updatePracticeUserLicencesThunk.rejected,
      (state, action) => {
        state.ui.isLoadingProceedPayment = false;
      },
    );

    builder.addCase(
      loadLicencesAdditionalCostThunk.pending,
      (state, action) => {
        state.ui.isLoadingAdditionalCost = true;
      },
    );
    builder.addCase(
      loadLicencesAdditionalCostThunk.fulfilled,
      (state, action) => {
        state.ui.isLoadingAdditionalCost = false;
      },
    );
    builder.addCase(
      loadLicencesAdditionalCostThunk.rejected,
      (state, action) => {
        state.ui.isLoadingAdditionalCost = false;
      },
    );

    builder.addCase(updateLicencesRenewalThunk.pending, (state, action) => {
      state.ui.isLoadingUpdateRenewal = true;
    });
    builder.addCase(updateLicencesRenewalThunk.fulfilled, (state, action) => {
      state.ui.isLoadingUpdateRenewal = false;
    });
    builder.addCase(updateLicencesRenewalThunk.rejected, (state, action) => {
      state.ui.isLoadingUpdateRenewal = false;
    });

    // Manage Billing Info
    builder.addCase(loadPracticeClientSecretThunk.pending, (state, action) => {
      state.ui.billingInfo.isLoadingClientSecret = true;
    });
    builder.addCase(
      loadPracticeClientSecretThunk.fulfilled,
      (state, action) => {
        state.ui.billingInfo.isLoadingClientSecret = false;
        state.practiceClientSecret = action.payload.isSuccess
          ? action.payload?.data
          : undefined;
      },
    );
    builder.addCase(loadPracticeClientSecretThunk.rejected, (state, action) => {
      state.ui.billingInfo.isLoadingClientSecret = true;
    });

    builder.addCase(loadPracticeBillingInfoThunk.pending, (state, action) => {
      state.ui.billingInfo.isLoading = true;
    });
    builder.addCase(loadPracticeBillingInfoThunk.fulfilled, (state, action) => {
      state.ui.billingInfo.isLoading = false;
      state.practiceBillingInfo = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.practicePaymentMethod = action.payload.isSuccess
        ? action.payload?.data?.paymentMethod
        : undefined;
      state.practiceBillingDetail = action.payload.isSuccess
        ? action.payload?.data?.billingDetails
        : undefined;
    });
    builder.addCase(loadPracticeBillingInfoThunk.rejected, (state, action) => {
      state.ui.billingInfo.isLoading = true;
    });

    builder.addCase(loadPracticePaymentMethodThunk.pending, (state, action) => {
      state.ui.paymentMethod.isLoading = true;
    });
    builder.addCase(
      loadPracticePaymentMethodThunk.fulfilled,
      (state, action) => {
        if (action.payload.isSuccess) {
          state.practicePaymentMethod = action.payload?.data;
        }
        state.ui.paymentMethod.isLoading = false;
      },
    );
    builder.addCase(
      loadPracticePaymentMethodThunk.rejected,
      (state, action) => {
        state.ui.paymentMethod.isLoading = false;
      },
    );

    builder.addCase(createPaymentMethodThunk.pending, (state, action) => {
      state.ui.paymentMethod.isSaving = true;
      state.ui.paymentMethod.isDisable = true;
    });
    builder.addCase(createPaymentMethodThunk.fulfilled, (state, action) => {
      if (action.payload.isSuccess) {
        state.practicePaymentMethod = action.payload?.data;
      }
      state.ui.paymentMethod.isSaving = false;
      state.ui.paymentMethod.isDisable = false;
    });
    builder.addCase(createPaymentMethodThunk.rejected, (state, action) => {
      state.ui.paymentMethod.isSaving = false;
      state.ui.paymentMethod.isDisable = false;
    });

    builder.addCase(
      setDefaultPaymentMethodThunk.pending,
      (state, action) => {},
    );
    builder.addCase(setDefaultPaymentMethodThunk.fulfilled, (state, action) => {
      state.practicePaymentMethod = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
    });
    builder.addCase(
      setDefaultPaymentMethodThunk.rejected,
      (state, action) => {},
    );

    builder.addCase(removePaymentMethodThunk.pending, (state, action) => {});
    builder.addCase(removePaymentMethodThunk.fulfilled, (state, action) => {
      if (action.payload.isSuccess) {
        state.practicePaymentMethod = action.payload?.data;
      }
    });
    builder.addCase(removePaymentMethodThunk.rejected, (state, action) => {});

    builder.addCase(loadPracticeBillingDetailThunk.pending, (state, action) => {
      state.ui.billingDetail.isLoading = true;
    });
    builder.addCase(
      loadPracticeBillingDetailThunk.fulfilled,
      (state, action) => {
        state.practiceBillingDetail = action.payload.isSuccess
          ? action.payload?.data
          : undefined;
        state.ui.billingDetail.isLoading = false;
      },
    );
    builder.addCase(
      loadPracticeBillingDetailThunk.rejected,
      (state, action) => {
        state.ui.billingDetail.isLoading = false;
      },
    );

    builder.addCase(updateBillingAddressThunk.pending, (state, action) => {});
    builder.addCase(updateBillingAddressThunk.fulfilled, (state, action) => {
      state.practiceBillingDetail = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
    });
    builder.addCase(updateBillingAddressThunk.rejected, (state, action) => {});
  },
});

export const loadPracticeThunk = createAsyncThunk(
  `practice/load`,
  async (practiceId: string) => {
    return await getPractice(practiceId);
  },
);

export const createPracticeThunk = createAsyncThunk(
  `practice/create`,
  async (practice: AdviserFirmDTO) => {
    return await postPractice(practice);
  },
);

export const updatePracticeThunk = createAsyncThunk(
  `practice/update`,
  async (practice: UpdateAdviserFirmDTO) => {
    return await putPractice(practice);
  },
);

export const updatePracticeStatusThunk = createAsyncThunk(
  `practice/updateStatus`,
  async ({
    practiceId,
    status,
  }: {
    practiceId: string;
    status: AdviserUserStatusAction;
  }) => {
    return await patchPracticeStatus(practiceId, status);
  },
);

export const loadPracticeLicencesThunk = createAsyncThunk(
  `practice/loadLicences`,
  async (practiceId: string) => {
    return await getPracticeLicences(practiceId);
  },
);

export const updatePracticeLicencesThunk = createAsyncThunk(
  `practice/updateLicences`,
  async ({
    practiceId,
    practiceLicences,
  }: {
    practiceId: string;
    practiceLicences: SubscriptionDTO;
  }) => {
    return await putPracticeLicences(practiceId, practiceLicences);
  },
);

export const loadPracticeClientSecretThunk = createAsyncThunk(
  `practice/clientSecret`,
  async (adviserFirmId: string) => {
    return await getStripeClientSecret(adviserFirmId);
  },
);

export const loadPracticeBillingInfoThunk = createAsyncThunk(
  `practice/billingInfo`,
  async (adviserFirmId: string) => {
    return await getBillingInfo(adviserFirmId);
  },
);

export const loadPracticePaymentMethodThunk = createAsyncThunk(
  `practice/paymentMethod`,
  async (adviserFirmId: string) => {
    return await getPaymentMethod(adviserFirmId);
  },
);

export const loadPracticeBillingDetailThunk = createAsyncThunk(
  `practice/billingDetail`,
  async (adviserFirmId: string) => {
    return await getBillingDetail(adviserFirmId);
  },
);

export const createPaymentMethodThunk = createAsyncThunk(
  `practice/addPaymentMethodThunk`,
  async ({
    adviserFirmId,
    paymentMethodId,
  }: {
    adviserFirmId: string;
    paymentMethodId: string;
  }) => {
    return await postPaymentMethod(adviserFirmId, paymentMethodId);
  },
);

export const setDefaultPaymentMethodThunk = createAsyncThunk(
  `practice/setDefaultPaymentMethodThunk`,
  async ({
    adviserFirmId,
    paymentMethodId,
  }: {
    adviserFirmId: string;
    paymentMethodId: string;
  }) => {
    return await putPaymentMethodDefault(adviserFirmId, paymentMethodId);
  },
);

export const removePaymentMethodThunk = createAsyncThunk(
  `practice/removePaymentMethodThunk`,
  async ({
    adviserFirmId,
    paymentMethodId,
  }: {
    adviserFirmId: string;
    paymentMethodId: string;
  }) => {
    return await deletePaymentMethod(adviserFirmId, paymentMethodId);
  },
);

export const updateBillingAddressThunk = createAsyncThunk(
  `practice/updateBillingAddressThunk`,
  async ({
    adviserFirmId,
    billingDetail,
  }: {
    adviserFirmId: string;
    billingDetail: BillingDetailDTO;
  }) => {
    return await putBillingDetail(adviserFirmId, billingDetail);
  },
);

// Adviser User - Subscription
export const loadPracticeUserLicencesThunk = createAsyncThunk(
  `practiceUser/loadLicences`,
  async (practiceId: string) => {
    return await getPracticeUserLicences(practiceId);
  },
);

export const createPracticeUserLicencesThunk = createAsyncThunk(
  `practiceUser/createLicences`,
  async ({
    practiceId,
    quantity,
  }: {
    practiceId: string;
    quantity: number;
  }) => {
    return await postPracticeUserLicences(practiceId, quantity);
  },
);

export const updatePracticeUserLicencesThunk = createAsyncThunk(
  `practiceUser/updateLicences`,
  async ({
    practiceId,
    updateUserSubscription,
  }: {
    practiceId: string;
    updateUserSubscription: UpdateUserSubscriptionDTO;
  }) => {
    return await putPracticeUserLicences(practiceId, updateUserSubscription);
  },
);

export const loadLicencesAdditionalCostThunk = createAsyncThunk(
  `practiceUser/loadLicencesAdditionalCost`,
  async ({
    practiceId,
    quantity,
  }: {
    practiceId: string;
    quantity: number;
  }) => {
    return await getLicencesAdditionalCost(practiceId, quantity);
  },
);

export const updateLicencesRenewalThunk = createAsyncThunk(
  `practiceUser/updateLicencesRenewal`,
  async ({
    practiceId,
    updateRenewalDto,
  }: {
    practiceId: string;
    updateRenewalDto: UpdateRenewalDTO;
  }) => {
    return await putLicencesRenewal(practiceId, updateRenewalDto);
  },
);

export const {
  setPracticeNameAction,
  setPracticeAction,
  setPracticeLicenceAction,
  setPracticeBillingDetailsAction,
  setIsDirtyBillingAddressAction,
  setIsDirtyBillingContactAction,
  setIsValidBillingAddressAction,
  setIsValidBillingContactAction,
  setIsDirtySubscriptionAction,
  resetBillingDetailUIAction,
  setBillingInfoShowRedDot
} = practiceSlice.actions;
export default practiceSlice.reducer;
