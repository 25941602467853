import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { SHDividerSkeleton } from "@components/skeletons/design-systems";
import { GroupCarouselSkeleton } from "@components/skeletons/layouts/platform-analysis/GroupCarouselSkeleton";
import { TransferSelectFeatureListSkeleton } from "@components/skeletons/layouts/platform-analysis/TransferSelectFeatureListSkeleton";

export const FeatureSelectionSkeleton = () => {
  return (
    <SHStack spacing={3}>
      <GroupCarouselSkeleton />
      <SHSkeleton width={"100%"} height={"50px"} />
      <SHDividerSkeleton />
      <TransferSelectFeatureListSkeleton />
    </SHStack>
  );
};
