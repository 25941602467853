import { formatNumberFixed, getHSLLightness, hslToHex, thousandSeparator } from "@utils";
import {
  FeeDetailDTO,
  FeeDTO,
  FeeSubProductDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { FeePortfolioDetailDTO } from "@models/platform-analysis/entities/portfolioDetails";
import { groupBy } from "lodash";
import { DEFAULT_COLOR_TICK, DEFAULT_COLORS, MAX_LIGHTNESS, MIN_LIGHTNESS } from "./_id/steps/fee/analysis/components/line-chart/config";

export const getFeeMinMax = (
  dataKey: keyof Pick<
    FeeDTO,
    "totalCostForDisplay" | "totalCostForDisplayPercentage"
  >,
  subProducts?: FeeSubProductDTO[],
) => {
  let min = Infinity;
  let max = -Infinity;
  if (!subProducts) {
    return { min, max };
  }
  subProducts.forEach((subProduct) => {
    subProduct.fees.forEach((fee) => {
      min = Math.min(min, fee[dataKey]);
      max = Math.max(max, fee[dataKey]);
    });
  });
  return { min, max };
};

export const getFeeDetailMinMax = (
  dataKey: keyof Pick<FeeDetailDTO, "dollar" | "percentage">,
  subProducts?: FeeSubProductDTO[],
) => {
  if (!subProducts) {
    return [];
  }

  return subProducts[0].feeDetails.map((f, index) => {
    let min = Infinity;
    let max = -Infinity;
    subProducts.forEach((subProduct) => {
      const feeDetail = subProduct.feeDetails[index];
      if (feeDetail) {
        min = Math.min(min, feeDetail[dataKey] || 0);
        max = Math.max(max, feeDetail[dataKey] || 0);
      }
    });
    return { min, max };
  });
};

export const displayCurrencyNumber = (num?: number): string => {
  const value = num || 0;
  if (!value) return "";
  return `$${thousandSeparator(
    parseFloat(formatNumberFixed(value, value < Math.pow(10, 6) ? 1 : 2)),
  )}`;
};
export const displayIntNumber = (num?: number): string => {
  const value = num || 0;
  return `${thousandSeparator(parseFloat(formatNumberFixed(value, 0)))}`;
};

const displayText = (message: string, value?: number) => {
  if (!value) return message;
  return `${message}: ${displayCurrencyNumber(value)}`;
};

export const getPortfolioDetails = (data?: FeePortfolioDetailDTO | null) => {
  let transactionsOutside: string[] = [];
  let transactionsWithin: string[] = [];
  let investments: string[] = [];

  if (data?.idpsCashBalance && data.idpsCashBalance > 0) {
    investments.push(
      displayText("Cash in IDPS accounts", data.idpsCashBalance),
    );
  }
  if (data?.superCashBalance && data.superCashBalance > 0) {
    investments.push(
      displayText("Cash in Super/Pension accounts", data.superCashBalance),
    );
  }

  if (data?.isChooseMF) {
    if (data.mfBalanceI) {
      investments.push(displayText("Managed funds", data.mfBalanceI));
    }
    if (data.mfTransactions) {
      transactionsOutside.push(
        `Managed funds: ${displayIntNumber(
          data.mfTransactions,
        )} transactions with an average size of ${displayCurrencyNumber(
          data?.mfAvg,
        )} each`,
      );
    }
  }
  if (data?.isChooseMA) {
    if (data.maBalanceI) {
      investments.push(
        displayText("Managed accounts (SMA or MDA models)", data.maBalanceI),
      );
    }
    if (data.maMfTransactions) {
      transactionsWithin.push(
        `Managed funds: ${displayIntNumber(
          data.maMfTransactions,
        )} transactions with an average size of ${displayCurrencyNumber(
          data?.maMfAvg,
        )} each`,
      );
    }
    if (data?.maAuTransactions) {
      transactionsWithin.push(
        `Australian listed investments: ${displayIntNumber(
          data?.maAuTransactions,
        )} transactions with an average size of ${displayCurrencyNumber(
          data?.maAuAvg,
        )} each`,
      );
    }
    if (data?.maIntlTransactions) {
      transactionsWithin.push(
        `International listed investments: ${displayIntNumber(
          data?.maIntlTransactions,
        )} transactions with an average size of ${displayCurrencyNumber(
          data?.maIntlAvg,
        )} each`,
      );
    }
    if (data?.maBondTransactions) {
      transactionsWithin.push(
        `Unlisted bonds: ${displayIntNumber(
          data?.maBondTransactions,
        )} transactions with an average size of ${displayCurrencyNumber(
          data?.maBondAvg,
        )} each`,
      );
    }
  }
  if (data?.isChooseAU) {
    if (data.auBalanceI) {
      investments.push(
        displayText("Australian listed investments", data.auBalanceI),
      );
    }
    if (data?.auTransactions) {
      transactionsOutside.push(
        `Australian listed investments: ${displayIntNumber(
          data?.auTransactions,
        )} transactions with an average size of ${displayCurrencyNumber(
          data?.auAvg,
        )} each`,
      );
    }
  }
  if (data?.isChooseIntl) {
    if (data.intlBalanceI) {
      investments.push(
        displayText("International listed investments", data.intlBalanceI),
      );
    }
    if (data?.intlTransactions) {
      transactionsOutside.push(
        `International listed investments: ${displayIntNumber(
          data?.intlTransactions,
        )} transactions with an average size of ${displayCurrencyNumber(
          data?.intlAvg,
        )} each`,
      );
    }
  }
  if (data?.isChooseBond) {
    if (data.bondBalanceI) {
      investments.push(displayText("Unlisted bonds", data.bondBalanceI));
    }
    if (data?.bondTransactions) {
      transactionsOutside.push(
        `Unlisted bonds: ${displayIntNumber(
          data?.bondTransactions,
        )} transactions with an average size of ${displayCurrencyNumber(
          data?.bondAvg,
        )} each`,
      );
    }
  }
  if (data?.isChooseRetIns) {
    if (data?.idpsNumRetIns && data?.idpsNumRetIns > 0) {
      investments.push(
        `Retail insurance policies in IDPS: ${displayIntNumber(
          data.idpsNumRetIns,
        )}`,
      );
    }
    if (data?.superNumRetIns && data?.superNumRetIns > 0) {
      investments.push(
        `Retail insurance policies in Super: ${displayIntNumber(
          data.superNumRetIns,
        )}`,
      );
    }
  }

  return {
    transactionsOutside,
    transactionsWithin,
    investments,
  };
};

export const generateSubProductColors = (subProducts?: FeeSubProductDTO[]) => {
  const groups = groupBy(subProducts, (obj) => obj.productId);
  const colors: { [key in string]: string } = {};
  Object.keys(groups).forEach((key, index) => {
    const group = groups[key];
    const colorRoot = DEFAULT_COLORS[index];
    if (group.length === 1) {
      colors[group[0].id] = hslToHex(
        colorRoot.hue,
        colorRoot.saturation,
        colorRoot.lightness,
      );
    } else {
      const tickColor = (MAX_LIGHTNESS - colorRoot.lightness) / group.length;
      const minLightness =
        tickColor >= DEFAULT_COLOR_TICK
          ? colorRoot.lightness
          : Math.max(
              MAX_LIGHTNESS - DEFAULT_COLOR_TICK * group.length,
              MIN_LIGHTNESS,
            );

      let maxLightness = Math.min(
        colorRoot.lightness + DEFAULT_COLOR_TICK * 1.5,
        MAX_LIGHTNESS,
      );

      if (group.length > 5) {
        maxLightness = Math.min(
          colorRoot.lightness + DEFAULT_COLOR_TICK * (group.length - 1),
          MAX_LIGHTNESS,
        );
      }

      group.forEach((item, itemIndex) => {
        const lightness = getHSLLightness(
          1,
          group.length,
          itemIndex + 1,
          minLightness,
          maxLightness,
        );
        colors[item.id] = hslToHex(
          colorRoot.hue,
          colorRoot.saturation,
          lightness,
        );
      });
    }
  });
  return colors;
};
