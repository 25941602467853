import { AnalysisConfigurationFeatureSectionDTO } from "@models/platform-analysis/entities/steps/feature";
import { FeatureTemplateStatus } from "../enums/status";

export class FeatureTemplateDTO {
  id?: string = "";
  name?: string = "";
  notes?: string = "";
  supplierId?: string = "";
  supplierName?: string = "";
  adviserFirmId?: string = "";
  adviserFirmName?: string = "";
  ownerId?: string = "";
  ownerName?: string = "";
  ownerAvatarUrl?: string = "";
  ownerType?: string = "";
  status?: FeatureTemplateStatus = FeatureTemplateStatus.Active;
  createDate?: Date = new Date();
  lastModifiedDate?: Date = new Date();
  lastUpdateUserId?: string = "";
  lastUpdateUserName?: string = "";
  sections?: AnalysisConfigurationFeatureSectionDTO[];
}
export class FeatureTemplateListDTO extends FeatureTemplateDTO {
  selectedFeatures: number = 0;
  totalFeatures: number = 0;
}

export class CreateFeatureTemplateDTO extends FeatureTemplateDTO {
  configurationVersion?: number;
  forSupplier: boolean = false;
  firmId: string = "";
  firmName: string = "";
}

export class FeatureTemplateCheckUniqueNameDTO {
  id: string = "";
  name: string = "";
  supplierId: string = "";
}

export class FeatureTemplateSelectionDTO {
  id?: string = "";
  name?: string = "";
  notes?: string = "";
  selectedFeatures: number = 0;
  totalFeatures: number = 0;
}

export enum TemplateNameValidationStatus {
  ExistsWithArchived = "ExistsWithArchived",
  ExistsWithActive = "ExistsWithActive",
  NotExists = "NotExists",
}
