import { RegionDTO, RegionTreeDTO } from "@models/public/region";

export const convertRegionToTree = (
  regions: RegionDTO[],
): RegionTreeDTO | undefined => {
  if (!regions.length) return undefined;
  let newTree = {} as RegionTreeDTO;

  regions.forEach((region, index) => {
    if (region.isBold) {
      if (!region.parentId) {
        //level 1
        newTree = {
          ...region,
          children: [],
          selected: false,
        };
      } else {
        //level 2
        newTree.children?.push({ ...region, children: [], selected: false });
      }
    } else {
      //level 3
      if (!newTree?.children) return;
      const findParentIndex = newTree.children.findIndex(
        (r) => r.id === region.parentId,
      );
      if (findParentIndex !== -1) {
        newTree.children[findParentIndex].children?.push({
          ...region,
          selected: false,
        });
      }
    }
  });
  return newTree;
};

export const convertValueToTree = (
  value: string[],
  regionTree?: RegionTreeDTO,
): {
  tree: RegionTreeDTO | undefined;
  flatList: RegionDTO[];
} => {
  if (!regionTree)
    return {
      tree: undefined,
      flatList: [],
    };
  let tree = { ...regionTree };
  let flatList: RegionDTO[] = [];

  const getRegion = (node: RegionTreeDTO) => {
    const clone = { ...node };
    delete clone.children;
    delete clone.selected;
    return clone;
  };

  tree.selected = value.includes(tree.id);
  if (tree.selected) flatList.push(getRegion(tree));

  tree.children?.forEach((level2) => {
    level2.selected = value.includes(level2.id) || tree.selected;
    if (level2.selected) flatList.push(getRegion(level2));

    level2.children?.forEach((level3) => {
      level3.selected =
        value.includes(level3.id) || tree.selected || level2.selected;
      if (level3.selected) flatList.push(getRegion(level3));
    });
  });
  return {
    tree,
    flatList,
  };
};

export const convertNewValue = (
  currentTree?: RegionTreeDTO,
  focusRegion?: RegionTreeDTO,
): string[] => {
  if (!currentTree || !focusRegion) return [];
  let tree = { ...currentTree };
  if (focusRegion.id === tree.id) {
    tree.selected = !tree.selected;
    return tree.selected ? [tree.id] : [];
  }
  tree.selected = true;
  tree.children?.forEach((level2) => {
    if (focusRegion.id === level2.id) {
      level2.selected = !level2.selected;
      if (!level2.selected) {
        tree.selected = false;
        level2.children?.forEach((level3) => (level3.selected = false));
      }
      return;
    }
    if (level2.children && level2.children?.length > 0) {
      level2.selected = true;
      level2.children?.forEach((level3) => {
        if (focusRegion.id === level3.id) {
          level3.selected = !level3.selected;
          if (!level3.selected) level2.selected = false;
          return;
        }
        if (!level3.selected) level2.selected = false;
      });
    }

    if (!level2.selected) tree.selected = false;
  });
  if (tree.selected) return [tree.id];
  let ids: string[] = [];
  tree.children?.forEach((level2) => {
    if (level2.selected) {
      ids.push(level2.id);
      return;
    }
    level2.children?.forEach((level3) => {
      if (level3.selected) {
        ids.push(level3.id);
        return;
      }
    });
  });

  return ids;
};
