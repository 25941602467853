import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const MenuBarSkeleton = () => {
  return (
    <SHStack spacing={1}>
      <SHSkeleton height={"68px"} width={"100%"} />
      <SHSkeleton height={"68px"} width={"100%"} />
      <SHSkeleton height={"68px"} width={"100%"} />
    </SHStack>
  );
};
