import { BusinessMetricTypeId } from "@models/configuration";
import { AnalysisCommentDTO } from "@models/platform-analysis/entities/comment";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import { FeePortfolioDetailDTO } from "@models/platform-analysis/entities/portfolioDetails";
import { AnalysisFeatureIncludeDTO } from "@models/platform-analysis/entities/steps/feature";
import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import { TableViewMode } from "@models/platform-analysis/enums/tableViewMode";
import {
  ReviewDataDTO,
  ReviewOverallScoreDTO,
  ReviewSelectedProductDTO,
} from "@models/reviews/entities/steps/feature";
import { ReviewStepFeeContainerDTO } from "@models/reviews/entities/steps/fee";

export class PDFReviewData {
  overview: PDFReviewOverview = new PDFReviewOverview();
  objectives: string | null = null;
  concludingRemarks: string | null = null;
  reviewCommentsShortlisted?: PDFReviewComment[];
  reviewCommentsDisqualified?: PDFReviewComment[];
  featureReviewShortlisted?: PDFFeatureReview;
  businessReviewShortlisted?: PDFBusinessReview;
  feeReviewShortlisted?: PDFFeeReviewDTO;
  feeReviewDisqualified?: PDFFeeReviewDTO;
  hiddenSubProductIds?: string[];
  appendixHiddenSubProductIds?: string[];
  featureIncluded?: PDFFeatureIncluded[];
  businessIncluded?: PDFBusinessIncluded[];
  feePortfolioDetails?: PDFFeePortfolioDetail;
  disclaimers?: PDFReviewDisclaimer[];
  controls?: PDFReviewControl;
  displayModes?: PDFReviewDisplayMode;
}

export class HTMLElement {
  content: string | null = "";
  height: number | null = 0;
}

export class PDFReviewOverview {
  name?: string;
  adviserName?: string;
  adviserARN?: string;
  adviserFirmName?: string;
  adviserFirmLogo?: string;
  adviserFirmAFSL?: string;
  familyGroupName?: string;
  familyMembersNames?: string[];
  lastModifiedDate?: Date;
  dataValidDate?: Date;
  serial?: string;
}
export interface PDFReviewControl {
  showSubSection?: boolean;
  showSelectedFeatures?: boolean;
  showAppendixFeeAnalysis: boolean;
  showHoldingNumber?: boolean;
  showFeeAnalysisGraph?: boolean;
}

export interface PDFReviewDisplayMode {
  feesDisplayMode?: FeesDisplayStyle;
  feeMissingInvestmentsDisplayMode?: FeeMissingInvestmentsOption;
  appendixFeeMissingInvestmentsDisplayMode?: FeeMissingInvestmentsOption;
  featureAnalysisTableViewMode?: TableViewMode;
  investmentMenu?: FeeInvestmentMenuOption;
  appendixInvestmentMenu?: FeeInvestmentMenuOption;
}

export interface PDFReviewComment extends AnalysisCommentDTO {
  height: number;
  isContinued?: boolean;
}

export interface CommentDetail {
  elements?: HTMLElement[];
  totalHeight: number;
}

export interface ReviewCommentSeparated {
  featureDetail?: CommentDetail;
  businessMetricDetail?: CommentDetail;
  feeDetail?: CommentDetail;
  summaryDetail?: CommentDetail;
}

export interface PDFFeatureReview {
  selectedProducts: ReviewSelectedProductDTO[];
  overallScore: ReviewOverallScoreDTO;
  pdfSections: PDFFeatureReviewSectionDTO[];
}
export interface PDFFeatureReviewSectionDTO {
  id?: string;
  name?: string;
  description?: string;
  iconUrl?: string;
  order: number;
  totalFeature: number;
  totalSelectedFeature: number;
  analysisData?: ReviewDataDTO[];
  businessMetricTypeId?: BusinessMetricTypeId;
  businessMetricTypeName?: string;
  isEssential?: boolean;
  type?: "Section" | "SubSection" | "Feature";
}
export interface PDFBusinessReview extends PDFFeatureReview {}

export interface PDFFeeReviewDTO extends ReviewStepFeeContainerDTO {}
export interface PDFFeatureIncluded extends AnalysisFeatureIncludeDTO {}
export interface PDFBusinessIncluded extends AnalysisFeatureIncludeDTO {}
export interface PDFReviewDisclaimer extends DisclaimerDTO {}
export interface PDFFeePortfolioDetail extends FeePortfolioDetailDTO {}

export interface PDFFeeTableData {
  subProduct: FeeSubProductDTO[];
  numberSubProductHidden: number;
  totalPortfolioValue?: number;
  feesDisplayStyle: FeesDisplayStyle;
  showWarningCaptions?: boolean;
  hiddenIds?: string[];
}
