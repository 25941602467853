import { SHStack, SHStackProps } from "@components/design-systems";
import { CircleSVG } from "@components/svgs";
import { useTheme } from "@mui/material";
import { useMemo } from "react";

export enum SHDotType {
  red = "red",
  blue = "blue",
  green = "green",
  orange = "orange",
}
export interface SHDotProps extends SHStackProps {
  disabled?: boolean;
  type?: SHDotType;
  size?: string | number;
}
export const SHDot = ({
  color,
  disabled,
  type = SHDotType.red,
  size = "6px",
  ...others
}: SHDotProps): JSX.Element => {
  const { palette } = useTheme();

  const circleColor = useMemo(() => {
    if (typeof color === "string") return color;
    switch (type) {
      case SHDotType.red:
        return palette.error.light;
      case SHDotType.blue:
        return palette.secondary.light;
      case SHDotType.green:
        return palette.success.main;
      case SHDotType.orange:
        return palette.warning.main;
      default:
        return palette.text.primary;
    }
  }, [color, type, palette]);

  return (
    <SHStack
      data-testid="sh-dot"
      style={{ opacity: disabled ? 0.5 : 1 }}
      alignItems="center"
      justifyContent={"center"}
      {...others}
    >
      <CircleSVG width={size} height={size} color={circleColor} />
    </SHStack>
  );
};
