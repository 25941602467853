import AdviserLicence from "@assets/images/logo/adviser-licence.png";
import {
  SHBox,
  SHHtmlBlock,
  SHImage,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { CheckSquareSVG } from "@components/svgs";
import { SubscriptionPricingInfoDTO } from "@models/practice/entities/practiceLicences";
import { Card, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { NumericFormat } from "react-number-format";

interface SubscriptionRightSideProps {
  pricingInfo?: SubscriptionPricingInfoDTO;
}

export const SubscriptionRightSide = ({
  pricingInfo,
}: SubscriptionRightSideProps) => {
  const { palette } = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        width: { lg: "35%", md: "35%", sm: "55%" },
        border: `1px solid #${palette.secondary[100]}`,
        backgroundColor: hexToRGBA(palette.secondary[200], 0.65),
        boxShadow: `10px 10px 40px 0px ${hexToRGBA("#DDDDDD", 0.4)}`,
        backdropFilter: "blur(0.5px)",
        mt: "0px !important",
        mb: { sm: "25px" },
      }}
    >
      <SHStack gap={"15px"} p={"15px"}>
        <SHStack gap={"10px"}>
          <SHHtmlBlock
            variant="h2"
            textAlign={"left"}
            content={"<p>Adviser Licence:&nbsp&nbspPlatform Research</p>"}
          />

          {pricingInfo?.isAppliedDiscount && (
            <SHTypography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "120%",
              }}
            >
              {`${pricingInfo.licenseeName} Discount: $${pricingInfo.mainPrice}+GST per year (regular price $${pricingInfo.fullPrice}+GST)`}
            </SHTypography>
          )}

          <SHStack
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={"15px"}
          >
            <SHImage src={AdviserLicence} width={195} height={195} />
            <SHStack>
              <SHStack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={"5px"}
              >
                <SHTypography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 700,
                    fontSize: "35px",
                    lineHeight: "160%",
                  }}
                >
                  <NumericFormat
                    displayType="text"
                    value={pricingInfo?.mainPricePlusGST}
                    thousandSeparator=","
                    prefix="$ "
                    decimalScale={
                      (pricingInfo?.mainPricePlusGST || 0) % 1 !== 0 ? 2 : 0
                    }
                    fixedDecimalScale={(pricingInfo?.mainPricePlusGST || 0) % 1 !== 0}
                  />
                </SHTypography>
                <SHTypography
                  marginTop={"-5px"}
                  variant="body2"
                  colorVariant="third"
                  sx={{ fontSize: "13px", lineHeight: "120%" }}
                >
                  per <br /> year
                </SHTypography>
              </SHStack>
              <SHTypography
                variant="subtitle1"
                colorVariant="third"
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "160%",
                }}
              >
                inc GST
              </SHTypography>
            </SHStack>
          </SHStack>
          <SHTypography variant="body2">
            Each Adviser Licence includes 1 Adviser account and unlimited
            accounts for Support Staff.
          </SHTypography>
        </SHStack>
        <SHStack>
          <SHTypography variant="subtitle2">
            This licence grants access to:
          </SHTypography>
          <SHStack
            flexDirection={"row"}
            gap={"5px"}
            justifyContent={"space-between"}
          >
            <SHBox>
              <CheckSquareSVG />
            </SHBox>
            <SHTypography
              variant="body2"
              fontSize={"13px"}
              sx={{
                "&>ul>li::marker": {
                  fontSize: "10px",
                },
              }}
            >
              Platform Provider Directory - view the proposition of each
              platform in detail:
              <ul style={{ margin: 0, paddingLeft: "20px" }}>
                <li>Company overview and financial stability</li>
                <li>Firm resilience (BCP & Data policies)</li>
                <li>
                  Environmental, social & corporate governance initiatives
                </li>
                <li>
                  Product features and functionality available for advisers and
                  clients - across 500+ areas
                </li>
              </ul>
            </SHTypography>
          </SHStack>
          <SHStack
            flexDirection={"row"}
            gap={"5px"}
            justifyContent={"space-between"}
          >
            <SHBox>
              <CheckSquareSVG />
            </SHBox>
            <SHTypography
              variant="body2"
              fontSize={"13px"}
              sx={{
                "&>ul>li::marker": {
                  fontSize: "10px",
                },
              }}
            >
              Suitability Reviews - our guided decision-making tool that
              systematises how you assess the suitability of platforms for your
              clients.
            </SHTypography>
          </SHStack>
          <SHStack
            flexDirection={"row"}
            gap={"5px"}
            justifyContent={"space-between"}
          >
            <SHBox>
              <CheckSquareSVG />
            </SHBox>
            <SHTypography
              variant="body2"
              fontSize={"13px"}
              sx={{
                "&>ul>li::marker": {
                  fontSize: "10px",
                },
              }}
            >
              Platform news and updates - stay across new releases by product
              providers. Our searchable news feed makes it easy to stay on top
              of the constant stream of platform developments and news.
            </SHTypography>
          </SHStack>
        </SHStack>
      </SHStack>
    </Card>
  );
};
