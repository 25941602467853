import { SHToggleButtonOptions } from "@components/design-systems";
import { ToggleButtonOptions } from "@components/design-systems/sh-toggle-button-options/model";
import { TableViewMode } from "@models/platform-analysis/enums/tableViewMode";
import { isNil } from "lodash";

interface TableAnalysisViewModeButtonProps {
  value?: TableViewMode;
  onChange?: (value: TableViewMode) => void;
  options?: ToggleButtonOptions[];
  disabled?: boolean;
}
export const TableAnalysisViewModeButton = ({
  value = TableViewMode.Default,
  options = [],
  onChange,
  disabled,
  ...others
}: TableAnalysisViewModeButtonProps) => {
  return (
    <SHToggleButtonOptions
      disabled={disabled}
      options={options}
      value={value}
      onChange={(event, value) => {
        if (onChange && !isNil(value)) onChange(value);
      }}
    />
  );
};
