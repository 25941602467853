export enum PageMode {
  View = "view",
  Edit = "edit",
}

export enum KickoutType {
  MultiLogin,
  GrantSupplierOwnerPermission,
  RevokeSupplierOwnerPermission,
  RevokeUserPermission,
  DisabledUser,
}

export enum RefreshType {
  UpdatingSubscription,
  CompletedUpdateSubscription,
}

export interface ParentState {
  pageMode?: PageMode;
  notification?: React.ReactNode;
}
