import { InformationButton } from "@components/buttons/information";
import {
  SHImage,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { CheckSVG, CrossSVG } from "@components/svgs";
import {
  AnalysisDataDTO,
  AnalysisFeatureDTO,
  AnalysisSectionDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";

import { useTheme } from "@mui/material";
import { SummaryAnalysisCell } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-cell";
import { SummaryAnalysisHeader } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-header";
import { SummaryAnalysisOverallScore } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-overall-score";
import { hexToRGBA } from "@utils";
import { isEmpty } from "lodash";

export interface FeaturesIncludeTableProps {
  featureAnalysis?: AnalysisFeatureDTO | null;
  showSubgroup?: boolean;
  isDetailMode?: boolean;
  isShowScore?: boolean;
}
export const FeaturesAnalysisTable = ({
  featureAnalysis,
  showSubgroup = true,
  isDetailMode = false,
  isShowScore = false,
}: FeaturesIncludeTableProps) => {
  const { palette } = useTheme();
  const renderPlatformValue = (value?: AnalysisDataValue) => {
    if (value === AnalysisDataValue.True) return <CheckSVG />;
    if (value === AnalysisDataValue.False) return <CrossSVG />;
    if (value === AnalysisDataValue.MissingData || isEmpty(value))
      return (
        <SHTypography variant="body3" colorVariant="third" lineHeight="120%">
          No data
        </SHTypography>
      );
  };

  return (
    <SHTableContainer
      sx={{
        overflow: "overlay",
        maxWidth: "max-content",
      }}
    >
      <SHTable
        style={{
          tableLayout: "auto",
          maxWidth: "max-content",
          borderCollapse: "separate",
        }}
      >
        <SummaryAnalysisHeader
          isFeatureAnalysis
          isDetailMode={isDetailMode}
          selectedProducts={featureAnalysis?.selectedProducts}
        />
        <SHTableBody
          sx={{
            td: {
              borderLeft: "none",
              borderTop: "none",
            },
            "td:first-child": {
              borderLeft: `1px solid ${hexToRGBA(palette.primary[50], 0.3)}`,
            },
            ">tr:first-child td": {
              borderTop: `1px solid ${hexToRGBA(palette.primary[50], 0.3)}}`,
            },
          }}
        >
          <SummaryAnalysisOverallScore
            isFeatureAnalysis
            overallScore={featureAnalysis?.overallScore}
            isDetailMode={isDetailMode}
            isShowScore={isShowScore}
          />
          {featureAnalysis?.sections?.map((section, index) => (
            <>
              <SHTableRow key={index}>
                <SummaryAnalysisCell cellColor={palette.common.white}>
                  <SHStack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <SHStack
                      direction={"row"}
                      alignItems={"center"}
                      gap={"5px"}
                    >
                      {section?.iconUrl && (
                        <SHImage
                          src={section?.iconUrl}
                          width={22}
                          height={22}
                        />
                      )}
                      <SHTypography variant="subtitle2" paddingTop={0}>
                        {section?.name}
                      </SHTypography>
                    </SHStack>
                  </SHStack>
                </SummaryAnalysisCell>
                <SummaryAnalysisCell cellColor={palette.common.white}>
                  <SHTypography
                    variant="subtitle2"
                    sx={{ textAlign: "center" }}
                  >
                    {section?.totalSelectedFeature}/{section?.totalFeature}
                  </SHTypography>
                </SummaryAnalysisCell>

                {section?.analysisData?.map(
                  (product: AnalysisDataDTO, index: number) => (
                    <SummaryAnalysisCell
                      cellColor={palette.common.white}
                      key={index}
                    >
                      {(!isDetailMode || (isDetailMode && isShowScore)) && (
                        <SHTypography
                          variant="subtitle2"
                          sx={{ textAlign: "center" }}
                        >
                          {product?.totalScore} %
                        </SHTypography>
                      )}
                    </SummaryAnalysisCell>
                  ),
                )}
              </SHTableRow>

              {showSubgroup &&
                section?.subSections?.map(
                  (subSection: AnalysisSectionDTO, index: number) => (
                    <>
                      <SHTableRow key={subSection.id}>
                        <SummaryAnalysisCell cellColor={palette.secondary[50]}>
                          <SHStack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent="space-between"
                          >
                            <SHStack
                              direction={"row"}
                              alignItems={"center"}
                              gap={"5px"}
                            >
                              {isDetailMode ? (
                                <SHTypography variant="subtitle2">
                                  {subSection?.name}
                                </SHTypography>
                              ) : (
                                <SHTypography
                                  variant="body1"
                                  sx={{
                                    fontWeight: 500,
                                    color: palette.text.third,
                                  }}
                                >
                                  {subSection?.name}
                                </SHTypography>
                              )}
                            </SHStack>
                          </SHStack>
                        </SummaryAnalysisCell>
                        <SummaryAnalysisCell cellColor={palette.secondary[50]}>
                          <SHTypography
                            variant="body1"
                            sx={{
                              fontWeight: 500,
                              textAlign: "center",
                              color: palette.text.third,
                            }}
                          >
                            {subSection?.totalSelectedFeature}/
                            {subSection?.totalFeature}
                          </SHTypography>
                        </SummaryAnalysisCell>

                        {subSection?.analysisData?.map((product, index) => (
                          <SummaryAnalysisCell
                            cellColor={palette.secondary[50]}
                            key={index}
                          >
                            {!isDetailMode ? (
                              <SHTypography
                                variant="body1"
                                sx={{
                                  fontWeight: 500,
                                  textAlign: "center",
                                  color: palette.text.third,
                                }}
                              >
                                {product?.totalScore} %
                              </SHTypography>
                            ) : (
                              isShowScore && (
                                <SHTypography
                                  variant="subtitle2"
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  {`${product?.totalScore}%`}
                                </SHTypography>
                              )
                            )}
                          </SummaryAnalysisCell>
                        ))}
                      </SHTableRow>
                      {isDetailMode &&
                        subSection?.features?.map(
                          (feature: AnalysisFeatureDTO) => (
                            <SHTableRow>
                              <SummaryAnalysisCell
                                cellColor={palette.secondary[50]}
                              >
                                <SHStack
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent="space-between"
                                >
                                  <SHTypography
                                    variant="body3"
                                    colorVariant="third"
                                    textAlign={"left"}
                                    lineHeight="120%"
                                  >
                                    {feature?.name}
                                  </SHTypography>
                                  {feature?.description && (
                                    <InformationButton
                                      content={feature.description}
                                    />
                                  )}
                                </SHStack>
                              </SummaryAnalysisCell>
                              <SummaryAnalysisCell
                                cellColor={palette.secondary[50]}
                              ></SummaryAnalysisCell>
                              {feature?.analysisData?.map(
                                (analysisData: AnalysisDataDTO) => (
                                  <SummaryAnalysisCell
                                    key={analysisData?.productId}
                                    cellColor={palette.secondary[50]}
                                  >
                                    <SHStack
                                      direction={"row"}
                                      alignItems={"center"}
                                      justifyContent="flex-end"
                                    >
                                      <SHStack
                                        width={
                                          analysisData?.value !== "Missing data"
                                            ? 87
                                            : 104
                                        }
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent="space-between"
                                      >
                                        {renderPlatformValue(
                                          analysisData?.value as AnalysisDataValue,
                                        )}
                                        {analysisData?.description && (
                                          <InformationButton
                                            content={analysisData?.description}
                                          />
                                        )}
                                      </SHStack>
                                    </SHStack>
                                  </SummaryAnalysisCell>
                                ),
                              )}
                            </SHTableRow>
                          ),
                        )}
                    </>
                  ),
                )}
            </>
          ))}
        </SHTableBody>
      </SHTable>
    </SHTableContainer>
  );
};
