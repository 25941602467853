import { GetUserInfoDTO } from "@models/users/entities/user";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resendInvite, resetPassword } from "@services/users/userService";

interface Auth {
  user?: GetUserInfoDTO;
  redirectUrl?: string;
  ui: {
    isResentingInvite?: boolean;
    isResettingPassword?: boolean;
  };
}

const initialState: Auth = {
  user: undefined,
  redirectUrl: undefined,
  ui: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<GetUserInfoDTO>) => ({
      ...state,
      user: action.payload,
    }),
    updateRedirectUrl: (state, action: PayloadAction<string>) => ({
      ...state,
      redirectUrl: action.payload,
    }),
  },
  extraReducers: (builder) => {
    //resentInviteEmailUser
    builder.addCase(resentInviteEmailUserThunk.pending, (state, action) => {
      state.ui.isResentingInvite = true;
    });
    builder.addCase(resentInviteEmailUserThunk.fulfilled, (state, action) => {
      state.ui.isResentingInvite = false;
    });
    builder.addCase(resentInviteEmailUserThunk.rejected, (state, action) => {
      state.ui.isResentingInvite = false;
    });

    //resetPassword
    builder.addCase(resetPasswordUserThunk.pending, (state, action) => {
      state.ui.isResettingPassword = true;
    });
    builder.addCase(resetPasswordUserThunk.fulfilled, (state, action) => {
      state.ui.isResettingPassword = false;
    });
    builder.addCase(resetPasswordUserThunk.rejected, (state, action) => {
      state.ui.isResettingPassword = false;
    });
  },
});

export const resentInviteEmailUserThunk = createAsyncThunk(
  `auth/resentEmailInvite`,
  async (auth0Id: string) => {
    return await resendInvite(auth0Id);
  },
);

export const resetPasswordUserThunk = createAsyncThunk(
  `auth/resetPassword`,
  async (email: string) => {
    return await resetPassword(email);
  },
);

export const { updateUser, updateRedirectUrl } = authSlice.actions;

export default authSlice.reducer;
