import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography
} from "@components/design-systems";
import { email } from "@constants/email";
import { Link } from "@mui/material";
import { theme } from "@themes";

interface WarningLoggedInDialogProps {
  open: boolean;
  onYes?: () => void;
  onCancel?: () => void;
}
export default function WarningLoggedInDialog({
  open,
  onYes,
  onCancel,
}: WarningLoggedInDialogProps): JSX.Element {
  return (
    <SHDialog
      maxWidth="lg"
      width={1005}
      open={open}
      header={
        <SHStack
          alignItems="center"
          textAlign={"center"}
          width={{ md: "50%", xs: "100%" }}
        >
          <SHTypography variant="h2" lineHeight={"120%"}>
            WARNING: Another session is currently logged in with this username
            and password
          </SHTypography>
        </SHStack>
      }
      body={
        <SHStack alignItems="center" textAlign={"center"}>
          <SHTypography variant="body2">
            We only allow one active instance of SuitabilityHub. Your other
            session may be on a different browser or device.
          </SHTypography>
          <SHTypography variant="body2">
            If the work in your other instance is not saved it may be lost
            when you log in on this browser.
          </SHTypography>
          <SHTypography variant="body2">
            Note: If your business requires more licenses, please
            <Link
              variant="body2"
              color={theme.palette.text.secondary}
              component="a"
              underline="none"
              sx={{
                paddingLeft: 0.5,
                cursor: "pointer",
              }}
              href={`mailto:${email.support}`}
            >
              contact SuitabilityHub.
            </Link>
          </SHTypography>
          <SHTypography variant="body2">
            Proceed on this device and log out of the other session?
          </SHTypography>
        </SHStack>
      }
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onCancel}
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onYes}
          >
            Yes
          </SHButton>
        </SHStack>
      }
    />
  );
}
