import { Components, Theme } from "@mui/material";
import { hexToRGBA } from "@utils";

export const muiTableHeadTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiTableHead"> => ({
  MuiTableHead: {
    styleOverrides: {
      root: {
        "& .MuiTableCell-root": {
          fontWeight: 500,
          fontSize: "14px",
          color: theme.palette.text.primary,
          padding: "15px 16px",
        },
        "& .MuiTableRow-root": {
          backgroundColor: hexToRGBA(theme.palette.background.default, 0.25),
          borderWidth: 1,
          borderColor: theme.palette.divider,
        },
      },
    },
  },
});
