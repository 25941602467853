import { PracticeDTO } from "@models/supplier/entities/practices";

export const mockPractices: PracticeDTO[] = [
  {
    id: "4df96e77-c65c-4f6a-8eff-f2aa5c4a99f1",
    name: "Practice 1"
  },
  {
    id: "2c04ea36-d556-45b1-8e8f-f77896159c2b",
    name: "Practice 2"
  },
  {
    id: "eb5cd160-85ae-4a8a-aa2b-b85018b551ff",
    name: "Practice 3"
  },
  {
    id: "5b7b9d38-44df-44a0-96fd-a7a34fa153cc",
    name: "Practice 4"
  },
  {
    id: "e55a55dc-0fcb-46c2-b51f-705ecb3fb478",
    name: "Practice 5"
  },
  {
    id: "9cff2412-e62e-431b-ae09-43a908458a01",
    name: "Practice 6"
  },
  {
    id: "1caaf378-783b-4151-8c2c-1ecaa45e6fe1",
    name: "Practice 7"
  },
];

export async function getPractices(
  supplierId?: string
) {
  return {
    isSuccess: true,
    data: mockPractices,
    message: null
  }
}
