import {
  SHContainer,
  SHStack,
  SHTab,
  SHTabs,
} from "@components/design-systems";
import { TopBarPlacement } from "@layouts/top-bar";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";

export const ReviewsListViewLayout = (): JSX.Element => {
  const tabs: SHTab[] = useMemo(
    () => [
      {
        label: "Reviews",
        linkTo: "",
      },
      {
        label: "Family groups",
        linkTo: "family-groups",
      },
      {
        label: "Feature templates",
        linkTo: "feature-template",
      },
      {
        label: "Rate cards",
        linkTo: "rate-card",
      },
    ],
    [],
  );

  return (
    <SHContainer maxWidth="lg2" sx={{ px: { xs: "16px", lg2: 0 } }}>
      <SHStack spacing={2} paddingY={3}>
        <TopBarPlacement />
        <SHTabs tabs={tabs} />
        <Outlet />
      </SHStack>
    </SHContainer>
  );
};
