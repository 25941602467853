import {
  SHCheckbox,
  SHSearchBox,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableCell,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { ManagedAccountFieldType } from "@models/managed-accounts/enums/fieldType";
import {
  MainHeaderColumn,
  MainSubHeaderMapping,
} from "@models/managed-accounts/enums/mainHeader";
import { SubHeader } from "@models/managed-accounts/enums/subHeader";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { LinearProgress, useTheme } from "@mui/material";
import {
  putInvestmentFavourited,
  updateSmaList,
} from "@services/investment/investmentService";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { isEmpty, isNaN } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  DefaultInvestmentSize,
  initialFilterCalculatedInvestment,
  occupiedSpace,
  overscanDefault,
  overscanFireFox,
  subHeaderCellBorderIds,
} from "../../config";
import {
  applySMAListFilters,
  combineModelName,
  modelSortingFn,
  pdsDateSortingFn,
  platformSortingFn,
} from "../../util";
import { SMAFavouritedButton } from "../buttons/favourited";
import { SMAListFilter, SMAListFiltersButton } from "../buttons/filters";
import { SMAListSetting, SMAListSettingsButton } from "../buttons/settings";
import { SMAListInvestmentInput } from "../inputs/investment";
import { SMAHeadCell } from "./components/head-cell";
import {
  SMAInvestmentCell,
  SMAModelCell,
  SMAPlatformCell,
} from "./components/sma-cell";
import "./index.scss";
import { CalculatedInvestmentDTO } from "./model";
import { hexToRGBA } from "@utils/color";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";

interface SMAListVirtualizedProps {}

const columnHelper = createColumnHelper<CalculatedInvestmentDTO>();

export const SMAListVirtualized = (props: SMAListVirtualizedProps) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { palette } = useTheme();
  const { notify } = useNotification();
  const { filters } = useSelector((state: RootState) => state.smaList);

  const [sorting, setSorting] = useState<SortingState>([]);

  const [currentInvestmentData, setCurrentInvestmentData] = useState<
    CalculatedInvestmentDTO[]
  >([]);
  const [originalInvestmentData, setOriginalInvestmentData] = useState<
    CalculatedInvestmentDTO[]
  >([]);
  const [searchedInvestmentData, setSearchInvestmentData] = useState<
    CalculatedInvestmentDTO[]
  >([]);
  const [filteredInvestmentData, setFilteredInvestmentData] = useState<
    CalculatedInvestmentDTO[]
  >([]);
  const [favoriteShCodes, setFavoriteShCodes] = useState<string[]>([]);
  const [favoriteShCodeStatus, setFavoriteShCodeStatus] = useState({
    shCode: "",
    isFavorite: false,
  });

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [currentSelectedIds, setCurrentSelectedIds] = useState<string[]>([]);
  const [currentFilters, setCurrentFilters] = useState<
    SMAListFilter | undefined
  >(undefined);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [investmentSize, setInvestmentSize] = useState(DefaultInvestmentSize);
  const [displayFeeType, setDisplayFeeType] = useState(
    FeesDisplayStyle.Percentage,
  );

  const customSortingTable = (rowA: any, rowB: any, columnId: any) => {
    const investmentValueA = rowA.original[columnId].value;
    const investmentValueB = rowB.original[columnId].value;
    const fieldType = rowA.original[columnId].fieldTypeId;

    const isDesc = table.getState().sorting[0].desc;

    switch (fieldType) {
      case ManagedAccountFieldType.Percentage:
      case ManagedAccountFieldType.Currency:
      case ManagedAccountFieldType.Text:
        if (investmentValueA === investmentValueB) {
          return 0;
        }

        if (!isNaN(+investmentValueA) && !isNaN(+investmentValueB)) {
          return +investmentValueA - +investmentValueB;
        }

        if (!isDesc) {
          if (investmentValueA === "No data") {
            return 1;
          }

          if (investmentValueB === "No data") {
            return -1;
          }

          if (!isNaN(+investmentValueA)) {
            return -1;
          }

          if (!isNaN(+investmentValueB)) {
            return 1;
          }
        } else {
          if (investmentValueB === "No data") {
            return 1;
          }

          if (investmentValueA === "No data") {
            return -1;
          }

          if (!isNaN(+investmentValueB)) {
            return -1;
          }

          if (!isNaN(+investmentValueA)) {
            return 1;
          }
        }

        return investmentValueA.localeCompare(investmentValueB);
      case ManagedAccountFieldType.YesNo:
        return investmentValueA.toLowerCase() === "true" ? 1 : -1;
      default:
        return 0;
    }
  };

  const columns = useMemo<ColumnDef<CalculatedInvestmentDTO>[]>(() => {
    const cols = [
      columnHelper.group({
        id: "SelectCol",
        header: (info) => (
          <SHTypography
            variant="body1"
            colorVariant="third"
            lineHeight={1.2}
            fontSize={11}
          >
            Showing {info.table.getRowModel().rows.length}
          </SHTypography>
        ),
        columns: [
          columnHelper.accessor((row) => row, {
            id: "Select",
            header: "Select",
            size: 100,
            cell: ({ row }) => {
              const { id, isFavourite, shCode } = row.original;
              const onFavoriteChange = (isFavorite: boolean) => {
                setFavoriteShCodeStatus({
                  shCode: shCode,
                  isFavorite: isFavorite,
                });
              };

              return (
                <SHStack
                  flexDirection={"row"}
                  alignItems={"center"}
                  marginLeft={"-10px"}
                >
                  <SHCheckbox
                    checked={currentSelectedIds.includes(id)}
                    onChange={() => {
                      setCurrentSelectedIds((ids) =>
                        ids.includes(id)
                          ? ids.filter((item) => item !== id)
                          : [...currentSelectedIds, id],
                      );
                    }}
                  />
                  <SMAFavouritedButton
                    onHandleFavoriteStatus={onFavoriteChange}
                    isFavourite={isFavourite}
                  />
                </SHStack>
              );
            },
          }),
        ],
      }),
      columnHelper.group({
        header: "Model",
        id: "MainModel",
        columns: [
          columnHelper.accessor((row) => row, {
            id: SubHeader.Model,
            header: "Model",
            size: 250,
            cell: ({ row }) => {
              const { managerName, name, platformCode } = row.original;
              return (
                <SMAModelCell
                  name={name}
                  platformCode={platformCode}
                  managerName={managerName}
                />
              );
            },
            sortingFn: modelSortingFn,
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.Platform,
            header: "Platform",
            size: 160,
            cell: ({ row }) => {
              const { productLogoUrl, productName, subProductName } =
                row.original;

              return (
                <SMAPlatformCell
                  productName={productName}
                  productLogoUrl={productLogoUrl}
                  subProductName={subProductName}
                />
              );
            },
            sortingFn: platformSortingFn,
          }),
        ],
      }),
      columnHelper.group({
        header: "Total fees",
        id: MainHeaderColumn.TotalFees,
        enableHiding: true,
        columns: [
          columnHelper.accessor((row) => row, {
            id: SubHeader.AnnualTotal,
            header: "Annual total",
            size: 85,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column0}
                feesDisplayStyle={displayFeeType}
              />
            ),
            sortingFn: customSortingTable,
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.PDS,
            header: "PDS",
            size: 77,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column1} />
            ),
            sortingFn: pdsDateSortingFn,
          }),
        ],
      }),
      columnHelper.group({
        header: "Management fees and costs",
        id: MainHeaderColumn.ManagementFeesAndCosts,
        columns: [
          columnHelper.accessor((row) => row, {
            id: SubHeader.TotalManagementFeesAndCosts,
            header: "Total",
            size: 85,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column2}
                feesDisplayStyle={displayFeeType}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.IMFee,
            header: "IM fee",
            size: 85,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column3}
                feesDisplayStyle={displayFeeType}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.UnderlyingManagementFeesAndCosts,
            header: "Underlying (indirect)",
            size: 120,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column4}
                feesDisplayStyle={displayFeeType}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.CashFee,
            header: "Cash fee",
            size: 85,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column5}
                feesDisplayStyle={displayFeeType}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.NetOfRebates,
            header: "Net of rebates",
            size: 95,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column6} />
            ),
          }),
        ],
      }),
      columnHelper.group({
        header: "Performance fees",
        id: MainHeaderColumn.PerformanceFees,
        columns: [
          columnHelper.accessor((row) => row, {
            id: SubHeader.TotalPerformanceFees,
            header: "Total",
            size: 85,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column7}
                feesDisplayStyle={displayFeeType}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.ModelManager,
            header: "Model manager",
            size: 95,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column8}
                feesDisplayStyle={displayFeeType}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.UnderlyingPerformanceFees,
            header: "Underlying (indirect)",
            size: 120,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column9}
                feesDisplayStyle={displayFeeType}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        header: "Transaction costs",
        id: MainHeaderColumn.TransactionCosts,
        columns: [
          columnHelper.accessor((row) => row, {
            id: SubHeader.TotalTransactionCosts,
            header: "Total",

            size: 85,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column10}
                feesDisplayStyle={displayFeeType}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.UnderlyingTransactionCosts,
            header: "Underlying (indirect)",

            size: 120,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column11}
                feesDisplayStyle={displayFeeType}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.BuySellSpreads,
            header: "Buy/sell spreads",
            size: 95,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column12}
                feesDisplayStyle={displayFeeType}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.PlatformPercent,
            header: "Platform %",
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column13}
                feesDisplayStyle={displayFeeType}
              />
            ),
            size: 115,
            sortingFn: customSortingTable,
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.PlatformDollar,
            header: "Platform $",
            size: 115,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column14}
                feesDisplayStyle={displayFeeType}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.Netting,
            header: "Netting",
            size: 90,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column15} />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.ModelTurnover,
            header: "Model turnover",
            size: 100,
            sortingFn: customSortingTable,
            enableMultiSort: false,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column16}
                feesDisplayStyle={FeesDisplayStyle.Percentage}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        header: "Platform fees",
        id: MainHeaderColumn.PlatformFees,
        columns: [
          columnHelper.accessor((row) => row, {
            id: SubHeader.Discount,
            header: "Discount",
            size: 100,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column17} />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.Type,
            header: "Type",
            size: 75,
            enableSorting: false,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column18} />
            ),
          }),
        ],
      }),
      columnHelper.group({
        header: "Model details",
        id: MainHeaderColumn.ModelDetails,
        columns: [
          columnHelper.accessor((row) => row, {
            id: SubHeader.FeeMethod,
            header: "Fee method",
            size: 120,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column19} />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.Incepted,
            header: "Incepted <1yr",
            size: 95,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column20} />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.MinInvestment,
            header: "Min investment",
            size: 110,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column21}
                feesDisplayStyle={FeesDisplayStyle.Dollar}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.PercentCash,
            header: "% Cash",
            size: 80,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column22}
                feesDisplayStyle={FeesDisplayStyle.Percentage}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.PercentIntListed,
            header: "% Int listed",
            size: 80,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column23}
                feesDisplayStyle={FeesDisplayStyle.Percentage}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.Super,
            header: "Super",
            size: 80,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column24} />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.IDPS,
            header: "IDPS",
            size: 80,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column25} />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.PrivateLabel,
            header: "Private label",
            size: 85,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column26} />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.Available,
            header: "Available",
            size: 85,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column27} />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: SubHeader.CustomModel,
            header: "Custom model",
            size: 85,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column28} />
            ),
          }),
        ],
      }),
    ];

    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayFeeType, currentSelectedIds]);

  const table = useReactTable({
    data: currentInvestmentData,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: React.useCallback(() => 45, []),
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan:
      navigator.userAgent.indexOf("Firefox") === -1
        ? overscanDefault
        : overscanFireFox,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const selectors = [
      ".SMAListHead",
      ".SMAListTableContainer",
      ".SMAListTableBody",
      ".SMAListTableHead",
      ".SMAListFilter",
      ".SMAListSetting",
    ];

    const elements = selectors.map((selector) =>
      document.querySelector(selector),
    );

    const [head, tableContainer, tableBody, tableHead, filterEl, settingEl] =
      elements;

    const scrollLeft = event.currentTarget.scrollLeft;
    const scrollTop = Math.floor(event.currentTarget.scrollTop);

    if (scrollLeft > 0) {
      tableBody?.classList.add("sticky");
      tableHead?.classList.add("stickyFirstCell");
    } else {
      tableBody?.classList.remove("sticky");
      tableHead?.classList.remove("stickyFirstCell");
    }

    if (scrollTop > 0) {
      [head, tableContainer, tableHead, filterEl, settingEl].forEach(
        (element) => element?.classList.add("sticky"),
      );
    } else if (scrollTop === 0) {
      [head, tableContainer, tableHead, filterEl, settingEl].forEach(
        (element) => element?.classList.remove("sticky"),
      );
    }
  };

  const handleOnSearch = (value: string | null) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    setIsFetchingData(true);

    searchTimeoutRef.current = setTimeout(() => {
      let searchedData: CalculatedInvestmentDTO[] = [];

      if (isEmpty(currentFilters?.platforms)) {
        setCurrentInvestmentData(searchedData);
        setSearchInvestmentData(searchedData);
        setIsFetchingData(false);

        return;
      }

      if (value) {
        const lowerCaseValue = value.toLowerCase();
        const matchingSHCode = originalInvestmentData
          .find((data) => data.platformCode.toLowerCase() === lowerCaseValue)
          ?.shCode?.toLowerCase();

        searchedData = originalInvestmentData.filter((data) => {
          const dataMatches =
            data.managerName.toLowerCase().includes(lowerCaseValue) ||
            data.name.toLowerCase().includes(lowerCaseValue) ||
            data.platformCode.toLowerCase().includes(lowerCaseValue) ||
            combineModelName(data.managerName, data.name, data.platformCode)
              .toLowerCase()
              .includes(lowerCaseValue);

          const shCodeMatches =
            matchingSHCode && data.shCode.toLowerCase() === matchingSHCode;

          return dataMatches || shCodeMatches;
        });
      }

      if (value && isEmpty(searchedData)) {
        setCurrentInvestmentData([]);
      } else if (isEmpty(searchedData) && isEmpty(filteredInvestmentData)) {
        setCurrentInvestmentData(originalInvestmentData);
      } else if (isEmpty(searchedData) && !isEmpty(filteredInvestmentData)) {
        setCurrentInvestmentData(
          originalInvestmentData.filter((item) => {
            return filteredInvestmentData.some((filterItem) => {
              return filterItem.id === item.id;
            });
          }),
        );
      } else if (!isEmpty(searchedData) && isEmpty(filteredInvestmentData)) {
        setCurrentInvestmentData(searchedData);
      } else if (!isEmpty(searchedData) && !isEmpty(filteredInvestmentData)) {
        setCurrentInvestmentData(
          searchedData.filter((searchItem) => {
            return filteredInvestmentData.some((filterItem) => {
              return filterItem.id === searchItem.id;
            });
          }),
        );
      }

      setSearchInvestmentData(searchedData);
      setIsFetchingData(false);
    }, 600);
  };

  const handleOnChangeFilter = (filters?: SMAListFilter) => {
    if (!filters) return;
    setCurrentFilters(filters);

    let newCalculatedInvestmentData = applySMAListFilters(
      filters,
      currentSelectedIds,
      originalInvestmentData,
    );

    setFilteredInvestmentData(newCalculatedInvestmentData);

    if (!isEmpty(searchedInvestmentData)) {
      newCalculatedInvestmentData = newCalculatedInvestmentData.filter(
        (filterItem) => {
          return searchedInvestmentData.some((searchItem) => {
            return searchItem.id === filterItem.id;
          });
        },
      );
    }

    setCurrentInvestmentData(newCalculatedInvestmentData);
  };

  const handleOnChangeSettings = (settings?: SMAListSetting) => {
    if (!settings) return;

    const showHideColumns: { [key: string]: boolean } = {};

    Object.keys(MainHeaderColumn).forEach((header) => {
      const subHeaders = MainSubHeaderMapping[header];

      if (settings.visibilityColumns?.some((item) => item === header)) {
        subHeaders?.forEach((subHeader) => (showHideColumns[subHeader] = true));
      } else {
        subHeaders?.forEach(
          (subHeader) => (showHideColumns[subHeader] = false),
        );
      }
    });

    setDisplayFeeType(settings.feesDisplayStyle);
    setColumnVisibility(showHideColumns);
  };

  const getSmaListData = async () => {
    setIsFetchingData(true);
    setCurrentInvestmentData([]);

    let filterCalculatedInvestment = {
      ...initialFilterCalculatedInvestment,
      filters: currentFilters ?? filters,
    };

    if (investmentSize !== DefaultInvestmentSize)
      filterCalculatedInvestment.investmentSize = investmentSize;

    const { data, isSuccess, message } = await updateSmaList(
      filterCalculatedInvestment,
    );

    if (!isSuccess || !data) {
      notify(message, {
        variant: "error",
        close: true,
      });

      return;
    }

    setOriginalInvestmentData(data?.calculatedInvestments);
    setCurrentFilters(data?.filters);
    setFavoriteShCodes(
      data?.calculatedInvestments
        .filter((investment) => investment.isFavourite)
        .map((investment) => investment.shCode)
        .filter((shCode, index, shCodes) => shCodes.indexOf(shCode) === index),
    );

    setIsFetchingData(false);
  };
  const addStickyTableBody = () => {
    const tableBody = document.querySelector(".SMAListTableBody");

    if (tableBody && !tableBody.classList.contains("sticky")) {
      tableBody.classList.add("sticky");
    }
  };

  useEffect(() => {
    if (favoriteShCodeStatus.shCode !== "") {
      setFavoriteShCodes((prevShCodes) => {
        if (!prevShCodes.includes(favoriteShCodeStatus.shCode)) {
          return [...prevShCodes, favoriteShCodeStatus.shCode];
        } else if (!favoriteShCodeStatus.isFavorite) {
          return prevShCodes.filter(
            (code) => code !== favoriteShCodeStatus.shCode,
          );
        }

        return prevShCodes;
      });
    }
  }, [favoriteShCodeStatus]);

  useEffect(() => {
    setCurrentInvestmentData((prevInvestmentData) => {
      return prevInvestmentData.map((investment) => {
        return {
          ...investment,
          isFavourite: favoriteShCodes.includes(investment.shCode),
        };
      });
    });
    setOriginalInvestmentData((prevInvestmentData) => {
      return prevInvestmentData.map((investment) => {
        return {
          ...investment,
          isFavourite: favoriteShCodes.includes(investment.shCode),
        };
      });
    });

    if (favoriteShCodeStatus.shCode !== "") {
      putInvestmentFavourited(favoriteShCodes);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteShCodes]);

  useEffect(() => {
    if (!isEmpty(filters.platforms)) {
      getSmaListData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentSize, filters]);

  useEffect(() => {
    handleOnChangeFilter(currentFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalInvestmentData, currentSelectedIds]);

  useEffect(() => {
    addStickyTableBody();
  }, [currentInvestmentData]);

  return (
    <SHStack spacing={3}>
      <SHStack
        className={"SMAListHead"}
        direction={"row"}
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ transition: "all 0.5s ease" }}
      >
        <SHSearchBox
          onChange={handleOnSearch}
          placeholder="Search manager, model and code"
        />
        <SHStack
          flexDirection={"row"}
          alignItems={"center"}
          gap={"10px"}
          maxWidth={560}
        >
          <SMAListInvestmentInput
            onUpdate={(investmentSize) => {
              setInvestmentSize(investmentSize);
            }}
          />
          <SHTypography
            fontSize={25}
            paddingLeft={"5px"}
            colorVariant={"disabled"}
          >
            |
          </SHTypography>
          <SMAListSettingsButton onSettings={handleOnChangeSettings} />
          <SMAListFiltersButton onFilters={handleOnChangeFilter} />
        </SHStack>
      </SHStack>

      <SHTableContainer
        className="SMAListTableContainer"
        ref={tableContainerRef}
        style={{
          position: "relative",
          overflow:
            isFetchingData || isEmpty(currentInvestmentData)
              ? "hidden"
              : "overlay",
          height: "100%",
          maxHeight: `calc(100vh - ${occupiedSpace})`,
        }}
        onScroll={handleOnScroll}
      >
        <SHTable
          style={{ display: "grid" }}
          sx={{
            th: {
              borderLeft: "none",
              "&:first-child": {
                borderLeft: `1px solid ${palette.secondary[100]}`,
              },
            },
            td: {
              borderTop: "none",
              borderLeft: "none",
            },
          }}
        >
          <SHTableHead
            className="SMAListTableHead"
            sx={{
              display: "grid",
              position: "sticky",
              top: 0,
              zIndex: 1,
              paddingTop: "10px important",
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => {
              const isMainHeader = headerGroup.depth === 0;
              return (
                <SHTableRow
                  key={headerGroup.id}
                  sx={{
                    width: "100%",
                    display: "flex",
                    borderBottomWidth: isMainHeader
                      ? "0px !important"
                      : undefined,
                    "&:first-of-type": {
                      backgroundColor: isMainHeader
                        ? hexToRGBA(palette.secondary[100], 0.2)
                        : undefined,
                    },
                  }}
                >
                  {headerGroup.headers.map((header, index) => {
                    return (
                      <SMAHeadCell
                        key={header.id}
                        header={header}
                        isMainHeader={isMainHeader}
                        isLastedColumn={false}
                        cellIndex={index}
                      />
                    );
                  })}
                </SHTableRow>
              );
            })}
          </SHTableHead>
          <SHTableRow
            style={{
              height: "13px",
              border: "none",
              background: "transparent",
            }}
          >
            {isFetchingData && <LinearProgress style={{ height: "2px" }} />}
          </SHTableRow>
          {isEmpty(currentInvestmentData) ? (
            <SHStack
              width={"100%"}
              alignItems="flex-start"
              justifyContent={"flex-start"}
              marginLeft={"15%"}
            >
              <SHTypography variant="body4">
                No results match your criteria.
              </SHTypography>
            </SHStack>
          ) : (
            <SHTableBody
              className={"SMAListTableBody"}
              style={{
                display: "grid",
                height: `${rowVirtualizer.getTotalSize()}px`,
                position: "relative",
                borderBottomWidth: "1px",
              }}
            >
              {virtualRows.map((virtualRow) => {
                const row = rows[
                  virtualRow.index
                ] as Row<CalculatedInvestmentDTO>;
                return (
                  <SHTableRow
                    data-index={virtualRow.index}
                    ref={(node: any) => rowVirtualizer.measureElement(node)}
                    key={row.id}
                    style={{
                      display: "flex",
                      position: "absolute",
                      transform: `translateY(${virtualRow.start}px)`,
                      width: "100%",
                      height: 45,
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const cellId = cell.id.split("_")[1];
                      const isShowBorder =
                        subHeaderCellBorderIds.find(
                          (colId) => colId === cellId,
                        ) !== undefined;

                      return (
                        <SHTableCell
                          key={cell.id}
                          style={{
                            display: "flex",
                            width: cell.column.getSize(),
                            height: 45,
                            alignItems: "center",
                            padding: cell.id.includes("Model") ? "0" : "10px",
                            borderRight: isShowBorder
                              ? `1px solid ${palette.divider}`
                              : undefined,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </SHTableCell>
                      );
                    })}
                  </SHTableRow>
                );
              })}
            </SHTableBody>
          )}
        </SHTable>
      </SHTableContainer>
    </SHStack>
  );
};
