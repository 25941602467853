import { UserType } from "@models/auth";

export const PracticeUserTypeMapping: {
  [key in UserType]?: string;
} = {
  [UserType.AdviserTrial || UserType.AdviserAdminTrial]: "Trial",
  [UserType.AdviserSupportStaff || UserType.AdviserAdminSupportStaff]:
    "Support staff",
  [UserType.AdviserAdviser || UserType.AdviserAdminAdviser]: "Adviser",
};

export const PracticeUserTypeOptions = [
  {
    label: PracticeUserTypeMapping[UserType.AdviserTrial] || "",
    value: UserType.AdviserTrial,
  },
  {
    label: PracticeUserTypeMapping[UserType.AdviserSupportStaff] || "",
    value: UserType.AdviserSupportStaff,
  },
  {
    label: PracticeUserTypeMapping[UserType.AdviserAdviser] || "",
    value: UserType.AdviserAdviser,
  },
];

export const PracticeAdminPrivilegesOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];
