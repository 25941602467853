import { Components, Theme } from "@mui/material";
export const muiDialogTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiDialog"> => ({
  MuiDialog: {
    styleOverrides: {
      root: {
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(5px)",
          background: "rgba(227, 227, 227, 0.5)",
        },
        "& .MuiPaper-root": {
          borderRadius: "0px",
          border: `1px solid ${theme.palette.text.disabled}`,
          padding: "25px 60px",
          textAlign: "center",
          margin: "0px",
          boxShadow: "1px 1px 2px rgba(65, 73, 89, 0.25)",
          boxSizing: "border-box",
        },
      },
    },
  },
});
