import { SHButton } from "@components/design-systems";
import { PageRoutes } from "@constants";
import { useProduct } from "@hooks/useProduct";
import { RootState } from "@redux/store";
import {
  updateReleaseName,
  updateSelectedGroup,
  updateShowViewReleaseLog,
} from "@redux/slices/product";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";

export interface CancelButtonProps {
  supplierId?: string;
  productId?: string;
}

export const CancelButton = ({ supplierId, productId }: CancelButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    productUI: { isEditMode },
    productGroups,
  } = useSelector((state: RootState) => state.product);
  const { updateProductGroups, updateProductData } = useProduct();
  const group0Id = productGroups[0]?.id;
  return (
    <>
      {isEditMode && (
        <SHButton
          variant="outlined"
          size="extraMedium"
          onClick={() => {
            updateProductGroups(productId);
            updateProductData({ productId, groupId: group0Id, mode: "edit" });
            dispatch(updateSelectedGroup(6));
            dispatch(updateShowViewReleaseLog(false));
            dispatch(updateReleaseName(undefined));
            navigate(
              generatePath(PageRoutes.supplierProducts, {
                supplierId,
                productId,
              }),
            );
          }}
        >
          Cancel
        </SHButton>
      )}
    </>
  );
};
