import {
  SHBox,
  SHStack,
  SHTextField,
  SHTextFieldProps,
  SHTypography,
  SHTypographyProps,
} from "@components/design-systems";
import { yupResolver } from "@hookform/resolvers/yup";
import { BillingContactDTO } from "@models/practice/entities/practiceBillingInfo";
import { alpha } from "@mui/material";
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { billingContactValidators } from "../../util";
import { WarningButton } from "@components/buttons/warning";
import { usePracticeBillingInfo } from "@hooks/usePracticeBillingInfo";

export interface BillingContactProps {
  isViewMode?: boolean;
  billingContact: BillingContactDTO;
}

export interface BillingContactPropsRef {
  getValues: () => BillingContactDTO;
  resetValues: (billingContact: BillingContactDTO) => void;
}

const BillingContactComp: ForwardRefRenderFunction<
  BillingContactPropsRef,
  BillingContactProps
> = (props, ref) => {
  const { isViewMode, billingContact } = props;
  const { setIsDirtyBillingContact, setIsValidBillingContact } =
    usePracticeBillingInfo();

  const {
    control,
    getValues,
    reset,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...billingContact,
    },
    resolver: yupResolver(billingContactValidators),
  });

  useImperativeHandle(ref, () => ({
    getValues: () => getValues(),
    resetValues: (billingContact) => reset(billingContact),
  }));

  useEffect(() => {
    setIsDirtyBillingContact(isDirty);
    setIsValidBillingContact(isValid);
    // eslint-disable-next-line
  }, [isDirty, isValid]);

  return (
    <form>
      <SHStack
        spacing={1}
        marginTop={"10px"}
        sx={{ position: "relative", zIndex: "0 !important" }}
      >
        <Controller
          name="contactName"
          control={control}
          render={({ field }) => (
            <SHTextFieldCustomized
              {...field}
              required
              label={
                <SHTypographyCustomized marginBottom={"5px"}>
                  Billing contact name
                </SHTypographyCustomized>
              }
              error={!!errors.contactName}
              helperText={
                errors.contactName ? errors.contactName.message : null
              }
              disabled={isViewMode}
            />
          )}
        />
        <Controller
          name="contactEmail"
          control={control}
          render={({ field }) => (
            <SHTextFieldCustomized
              {...field}
              label={
                <SHTypographyCustomized marginBottom={"5px"}>
                  Billing contact email
                </SHTypographyCustomized>
              }
              required
              error={!!errors.contactEmail}
              helperText={
                errors.contactEmail ? errors.contactEmail.message : null
              }
              disabled={isViewMode}
            />
          )}
        />
        <Controller
          name="contactPhoneNumber"
          control={control}
          render={({ field }) => (
            <SHTextFieldCustomized
              {...field}
              label={
                <SHTypographyCustomized marginBottom={"5px"}>
                  Billing contact phone number
                </SHTypographyCustomized>
              }
              InputProps={{
                startAdornment: (
                  <SHTypographyCustomized> +61 </SHTypographyCustomized>
                ),
              }}
              maxLength={20}
              disabled={isViewMode}
            />
          )}
        />
        <Controller
          name="abn"
          control={control}
          render={({ field }) => (
            <SHTextFieldCustomized
              {...field}
              label={
                <SHStack direction={"row"}>
                  <SHTypographyCustomized marginBottom={"5px"}>
                    Practice Australian Business Number (ABN)
                  </SHTypographyCustomized>
                  {watch("invalidABN") && (
                    <SHBox
                      display={"flex"}
                      flexDirection={"row"}
                      sx={{ marginLeft: "0px", marginTop: "2px" }}
                    >
                      <WarningButton title={"Invalid ABN number"} />
                    </SHBox>
                  )}
                </SHStack>
              }
              maxLength={11}
              placeholder={"Practice Australian Business Number (ABN)"}
              error={!!errors.abn}
              helperText={errors.abn ? errors.abn.message : null}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  field.onChange(e.target.value);
                }
              }}
              disabled={isViewMode}
              hasCounter
            />
          )}
        />
      </SHStack>
    </form>
  );
};

export const BillingContact = forwardRef(BillingContactComp);

const SHTypographyCustomized = (props: SHTypographyProps) => (
  <SHTypography
    variant="body2"
    fontSize={"15px"}
    color={"rgb(48, 49, 61)"}
    fontFamily={[
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(",")}
    {...props}
  />
);

const SHTextFieldCustomized = React.forwardRef<
  HTMLDivElement,
  SHTextFieldProps
>((props, ref) => {
  return (
    <SHTextField
      ref={ref}
      variant="outlined"
      InputLabelProps={{ shrink: false }}
      sx={{
        "& .MuiOutlinedInput-root": {
          height: "45px",
          "&>input": {
            paddingLeft: "12px",
            paddingY: "15px",
          },
          "& fieldset": {
            border: "1px solid #E0E3E7",
          },
          "&.Mui-focused fieldset": {
            boxShadow: `${alpha("#1976d2", 0.25)} 0 0 0 0.2rem`,
            borderWidth: "1px",
            borderColor: "#42a5f5",
          },
          "&:hover fieldset": { border: "1px solid #E0E3E7" },
        },
        "& .MuiInputBase-input": {
          fontSize: 16,
          width: "100%",
          padding: "10px 12px",
          fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
        },
        "& .MuiFormHelperText-root": {
          marginLeft: "1px",
        },
      }}
      {...props}
    />
  );
});
