import { APIExtRoutes } from "@constants";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import {
  ReviewTableRow,
  ReviewBannerInfoDTO,
  ReviewDTO,
} from "@models/reviews/entities/review";
import { ReviewStepDTO } from "@models/reviews/entities/step";
import { ReviewGetBusinessMetricSectionsDTO } from "@models/reviews/entities/steps/businessMetric";
import {
  ReviewConfigurationFeatureSectionDTO,
  ReviewGetFeatureSectionsDTO,
  UpdateDecisionMarkingDTO,
} from "@models/reviews/entities/steps/feature";
import { ReviewGetFeeCalculatorDTO } from "@models/reviews/entities/steps/fee";
import { ReviewStepSetupDTO } from "@models/reviews/entities/steps/setup";
import { ReviewStep } from "@models/reviews/enums/step";
import { ReviewSubStep } from "@models/reviews/enums/subStep";
import { UserLOV } from "@models/users/entities/user";
import { FeatureReviewFilters } from "@pages/reviews/_id/steps/feature/analysis/config";
import { CollapseType } from "@pages/reviews/_id/steps/feature/analysis/table-improved";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getReviewBMSelectionStep,
  putReviewBMSelectionStep,
} from "@services/reviews/businessMetricReviewService";
import {
  getFeatureSelectionStep,
  putFeatureSelectionStep,
} from "@services/reviews/featureReviewService";
import {
  getReviewFeeCalculatorStep,
  putReviewFeeCalculatorStep,
} from "@services/reviews/feeService";
import {
  getReviewBannerInfo,
  getReviewStep,
  getReviewSteps,
  postReview,
  putReview,
  putReviewCurrentStep,
  putReviewDecisionMaking,
} from "@services/reviews/reviewService";
import { updateReviewReadOnlyAndComplete } from "./util";
const { Setup, Feature, BusinessMetric, Fee, Summary } = ReviewStep;
const { Selection, Analysis } = ReviewSubStep;
const stepEnums = [Setup, Feature, BusinessMetric, Fee, Summary];
export interface ReviewStore {
  ui: {
    isValid?: boolean;
    isDirty: boolean;
    isSubmitting?: boolean;
    isUpdatingDecisionStatus?: boolean;
    isAccessDenied?: boolean;
    isLoading?: boolean;
    isLoadingSteps?: boolean;
    isLoadingBanner?: boolean;
    isShirkColumn?: boolean;
    selectedStepIndex?: number;
    selectedSubStepIndex?: number;
    steps: {
      [key in ReviewStep]: {
        isHidden?: boolean;
        isUnHighlighted?: boolean;
        subSteps?: {
          [key in ReviewSubStep]: {
            isHidden?: boolean;
          };
        };
      };
    };
    filterFeature: FeatureReviewFilters;
    filterBusinessMetric?: string;
    collapsedSubGroupIds?: string[];
    reachReviewSetupPage?: "FIRST_TIME" | "SECOND_TIME";
    isShowHidden?: boolean;
    isShowHiddenAppendix?: boolean;
    totalCollapsedBusinessMetricRows: number;
  };
  isCompleted?: boolean;
  isReadOnly?: boolean;
  reviewSteps?: ReviewStepDTO;
  review?: ReviewDTO;
  advisers?: UserLOV[];
  bannerInfo?: ReviewBannerInfoDTO;
  featureSelections?: ReviewGetFeatureSectionsDTO;
  businessMetricSelections?: ReviewGetBusinessMetricSectionsDTO;
  feeCalculator?: ReviewGetFeeCalculatorDTO;
  disclaimers?: DisclaimerDTO[];
  featureReviewRows: ReviewTableRow[];
  collapseMapping: CollapseType;
  lineChartColors?: { [key in string]: string };
  appendixLineChartColors?: { [key in string]: string };
}
const initialState: ReviewStore = {
  ui: {
    isValid: true,
    isDirty: false,
    isSubmitting: false,
    isUpdatingDecisionStatus: false,
    isLoading: true,
    isAccessDenied: undefined,
    isShirkColumn: false,
    selectedStepIndex: 0,
    selectedSubStepIndex: 0,
    steps: {
      [Setup]: {
        isUnHighlighted: false,
      },
      [Feature]: {
        isUnHighlighted: true,
        subSteps: {
          [Selection]: {
            isHidden: false,
          },
          [Analysis]: {
            isHidden: false,
          },
        },
      },
      [BusinessMetric]: {
        isUnHighlighted: true,
        subSteps: {
          [Selection]: {
            isHidden: false,
          },
          [Analysis]: {
            isHidden: false,
          },
        },
      },
      [Fee]: {
        isUnHighlighted: true,
        subSteps: {
          [Selection]: {
            isHidden: false,
          },
          [Analysis]: {
            isHidden: false,
          },
        },
      },
      [Summary]: {
        isUnHighlighted: true,
      },
    },
    filterFeature: {},
    filterBusinessMetric: undefined,
    collapsedSubGroupIds: [],
    reachReviewSetupPage: undefined,
    isShowHidden: false,
    isShowHiddenAppendix: false,
    totalCollapsedBusinessMetricRows: 0,
  },
  isCompleted: false,
  isReadOnly: false,
  featureReviewRows: [],
  collapseMapping: {},
};

const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    updateIsDirtyAction: (state, action: PayloadAction<boolean>) => {
      state.ui.isDirty = action.payload;
    },
    updateIsValidAction: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.ui.isValid = action.payload;
    },
    updateIsCompletedAction: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.isCompleted = action.payload;
    },
    updateIsReadOnlyAction: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.isReadOnly = action.payload;
    },
    updateIsAccessDeniedAction: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.ui.isAccessDenied = action.payload;
    },
    updateIsShowHiddenAction: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.ui.isShowHidden = action.payload;
    },
    updateIsShowHiddenAppendixAction: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.ui.isShowHiddenAppendix = action.payload;
    },
    updateReviewsStepsAction: (
      state,
      action: PayloadAction<ReviewStepDTO | undefined>,
    ) => {
      state.reviewSteps = action.payload;
    },
    updateSelectedStepIndexAction: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.ui.selectedStepIndex = action.payload;
    },
    updateSelectedSubStepIndexAction: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.ui.selectedSubStepIndex = action.payload;
    },
    updateFilterBusinessMetricAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.ui.filterBusinessMetric = action.payload;
    },
    updateStepUnHighlightedAction: (
      state,
      action: PayloadAction<{
        step: ReviewStep;
        isUnHighlighted: boolean | undefined;
      }>,
    ) => {
      state.ui.steps[action.payload.step].isUnHighlighted =
        action.payload.isUnHighlighted;
    },
    updateBannerInfoAction: (state, action: PayloadAction<any>) => {
      state.bannerInfo = { ...state.bannerInfo, ...action.payload };
    },
    updateReviewAction: (
      state,
      action: PayloadAction<ReviewDTO | undefined>,
    ) => {
      state.review = action.payload;
    },
    updateFeatureSelectionAction: (
      state,
      action: PayloadAction<any | undefined>,
    ) => {
      state.featureSelections = action.payload;
    },
    updateFeatureSelectionGroup: (
      state,
      action: PayloadAction<ReviewConfigurationFeatureSectionDTO[]>,
    ) => {
      if (state.featureSelections?.sections) {
        state.featureSelections.sections = action.payload;
      }
    },
    updateBusinessMetricSelectionAction: (
      state,
      action: PayloadAction<ReviewGetBusinessMetricSectionsDTO | undefined>,
    ) => {
      state.businessMetricSelections = action.payload;
    },
    updateFeeCalculatorAction: (
      state,
      action: PayloadAction<ReviewGetFeeCalculatorDTO | undefined>,
    ) => {
      state.feeCalculator = action.payload;
    },
    updateFilterFeatureAction: (
      state,
      action: PayloadAction<FeatureReviewFilters>,
    ) => {
      state.ui.filterFeature = action.payload;
    },
    updateCollapsedSubGroupIdsAction: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.ui.collapsedSubGroupIds = action.payload;
    },
    updateReachAnalysisSetupPageAction: (
      state,
      action: PayloadAction<"FIRST_TIME" | "SECOND_TIME" | undefined>,
    ) => {
      state.ui.reachReviewSetupPage = action.payload;
    },
    updateDisclaimersAction: (
      state,
      action: PayloadAction<DisclaimerDTO[] | undefined>,
    ) => {
      state.disclaimers = action.payload;
    },
    resetReviewStore: (state, action: PayloadAction) => {
      state.reviewSteps = undefined;
      state.bannerInfo = undefined;
      state.review = undefined;
      state.featureSelections = undefined;
      state.businessMetricSelections = undefined;
      state.feeCalculator = undefined;
      state.isCompleted = false;
      state.isReadOnly = false;
      state.featureReviewRows = [];
      state.collapseMapping = {};
      state.ui = initialState.ui;
    },
    resetReviewFeeStore: (state, action: PayloadAction) => {
      if (state.review) {
        state.review.fee = null;
      }
    },
    updateFeatureReviewRowsAction: (
      state,
      action: PayloadAction<ReviewTableRow[]>,
    ) => {
      state.featureReviewRows = action.payload;
    },
    updateCollapseMappingAction: (
      state,
      action: PayloadAction<CollapseType>,
    ) => {
      state.collapseMapping = action.payload;
    },
    updateTotalCollapsedBusinessMetricRowsAction: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.ui.totalCollapsedBusinessMetricRows += action.payload;
    },
    updateLineChartColorsAction: (state, action: PayloadAction<{ [key in string]: string }>) => {
      state.lineChartColors = action.payload;
    },
    updateAppendixLineChartColorsAction: (state, action: PayloadAction<{ [key in string]: string }>) => {
      state.appendixLineChartColors = action.payload;
    },
  },
  extraReducers(builder) {
    //createReview
    builder.addCase(createReviewThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(createReviewThunk.fulfilled, (state, action) => {
      state.review = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.ui.isSubmitting = false;
      state.ui.isLoading = false;
    });
    builder.addCase(createReviewThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
      state.ui.isLoading = false;
    });

    //updateReview
    builder.addCase(updateReviewThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(updateReviewThunk.fulfilled, (state, action) => {
      state.ui.isSubmitting = false;
    });
    builder.addCase(updateReviewThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    //getReviewStep
    builder.addCase(getReviewStepThunk.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(getReviewStepThunk.fulfilled, (state, action) => {
      state.review = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      //updateReadOnlyAndComplete
      let newState = updateReviewReadOnlyAndComplete(state, {
        status: state.review?.status,
        previousStatus: state.review?.previousStatus,
      });
      state.ui = newState.ui;
      state.isCompleted = newState.isCompleted;
      state.isReadOnly = newState.isReadOnly;
      //Set isLoading
      state.ui.isLoading = false;
    });
    builder.addCase(getReviewStepThunk.rejected, (state, action) => {
      state.ui.isLoading = false;
    });

    //getAnalysisBannerName
    builder.addCase(getReviewBannerInfoThunk.pending, (state, action) => {
      state.ui.isLoadingBanner = true;
    });
    builder.addCase(getReviewBannerInfoThunk.fulfilled, (state, action) => {
      state.bannerInfo = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.ui.isLoadingBanner = false;
    });
    builder.addCase(getReviewBannerInfoThunk.rejected, (state, action) => {
      state.ui.isLoadingBanner = false;
    });

    //getReviewSteps
    builder.addCase(getReviewStepsThunk.pending, (state, action) => {
      state.ui.isLoadingSteps = true;
    });
    builder.addCase(getReviewStepsThunk.fulfilled, (state, action) => {
      if (action.payload.isSuccess && action.payload.data) {
        const { steps, currentStep, currentSubStep, savedSteps } =
          action.payload.data;

        let selectedStepIndex = -1;
        stepEnums.forEach((step, index) => {
          if (steps.includes(step)) {
            state.ui.steps[step].isHidden = false;
            selectedStepIndex++;
          } else {
            state.ui.steps[step].isHidden = true;
          }
          if (currentStep === step) {
            state.ui.selectedStepIndex = selectedStepIndex;
          }
          state.ui.steps[step].isUnHighlighted = !savedSteps?.includes(step);
        });

        state.ui.selectedSubStepIndex =
          currentSubStep === ReviewSubStep.Analysis ? 1 : 0;
      }
      state.ui.isLoadingSteps = false;
      state.reviewSteps = action.payload.data;
    });
    builder.addCase(getReviewStepsThunk.rejected, (state, action) => {
      state.ui.isLoadingSteps = false;
    });

    // TODO: Feature, Business Metric, Fee analysis here
    // getReviewFeatureSelections;
    builder.addCase(
      getReviewFeatureSelectionsThunk.pending,
      (state, action) => {
        state.ui.isLoading = true;
      },
    );
    builder.addCase(
      getReviewFeatureSelectionsThunk.fulfilled,
      (state, action) => {
        state.featureSelections = action.payload.isSuccess
          ? action.payload?.data
          : undefined;
        //updateReadOnlyAndComplete
        let newState = updateReviewReadOnlyAndComplete(state, {
          status: action.payload?.data?.status,
        });
        state.ui = newState.ui;
        state.isCompleted = newState.isCompleted;
        state.isReadOnly = newState.isReadOnly;
        state.ui.isLoading = false;
      },
    );
    builder.addCase(
      getReviewFeatureSelectionsThunk.rejected,
      (state, action) => {
        state.ui.isLoading = false;
      },
    );
    // updateReviewFeatureSelections;
    builder.addCase(
      updateReviewFeatureSelectionsThunk.pending,
      (state, action) => {
        state.ui.isSubmitting = true;
      },
    );
    builder.addCase(
      updateReviewFeatureSelectionsThunk.fulfilled,
      (state, action) => {
        state.ui.isSubmitting = false;
      },
    );
    builder.addCase(
      updateReviewFeatureSelectionsThunk.rejected,
      (state, action) => {
        state.ui.isSubmitting = false;
      },
    );
    // getReviewsBusinessMetricSelections;
    builder.addCase(
      getReviewBusinessMetricSelectionThunk.pending,
      (state, action) => {
        state.ui.isLoading = true;
      },
    );
    builder.addCase(
      getReviewBusinessMetricSelectionThunk.fulfilled,
      (state, action) => {
        state.businessMetricSelections = action.payload.isSuccess
          ? action.payload?.data
          : undefined;

        let newState = updateReviewReadOnlyAndComplete(state, {
          status: action.payload?.data?.status,
        });

        state.ui = newState.ui;
        state.isCompleted = newState.isCompleted;
        state.isReadOnly = newState.isReadOnly;
        state.ui.isLoading = false;
      },
    );
    builder.addCase(
      getReviewBusinessMetricSelectionThunk.rejected,
      (state, action) => {
        state.ui.isLoading = false;
      },
    );
    // updateReviewBusinessMetricSelections;
    builder.addCase(
      updateReviewBusinessMetricSelectionThunk.pending,
      (state, action) => {
        state.ui.isSubmitting = true;
      },
    );
    builder.addCase(
      updateReviewBusinessMetricSelectionThunk.fulfilled,
      (state, action) => {
        state.ui.isSubmitting = false;
      },
    );
    builder.addCase(
      updateReviewBusinessMetricSelectionThunk.rejected,
      (state, action) => {
        state.ui.isSubmitting = false;
      },
    );

    // getReviewFeeCalculator;
    builder.addCase(getReviewFeeCalculatorThunk.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(getReviewFeeCalculatorThunk.fulfilled, (state, action) => {
      state.feeCalculator = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      //updateReadOnlyAndComplete
      let newState = updateReviewReadOnlyAndComplete(state, {
        status: action.payload?.data?.status,
      });
      state.ui = newState.ui;
      state.isCompleted = newState.isCompleted;
      state.isReadOnly = newState.isReadOnly;
      state.ui.isLoading = false;
    });
    builder.addCase(getReviewFeeCalculatorThunk.rejected, (state, action) => {
      state.ui.isLoading = false;
    });
    // updateReviewFeeCalculator;
    builder.addCase(updateReviewFeeCalculatorThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(
      updateReviewFeeCalculatorThunk.fulfilled,
      (state, action) => {
        state.ui.isSubmitting = false;
      },
    );
    builder.addCase(
      updateReviewFeeCalculatorThunk.rejected,
      (state, action) => {
        state.ui.isSubmitting = false;
      },
    );

    //updateReviewDecisionMakingStatus
    builder.addCase(
      updateReviewDecisionMakingStatusThunk.pending,
      (state, action) => {
        state.ui.isUpdatingDecisionStatus = true;
      },
    );
    builder.addCase(
      updateReviewDecisionMakingStatusThunk.fulfilled,
      (state, action) => {
        state.ui.isUpdatingDecisionStatus = false;
      },
    );
    builder.addCase(
      updateReviewDecisionMakingStatusThunk.rejected,
      (state, action) => {
        state.ui.isUpdatingDecisionStatus = false;
      },
    );
  },
});

//Review's thunk functions
export const getReviewBannerInfoThunk = createAsyncThunk(
  APIExtRoutes.reviewBannerInfo,
  async (reviewId: string) => {
    return await getReviewBannerInfo(reviewId);
  },
);

export const getReviewStepsThunk = createAsyncThunk(
  `reviews/steps`,
  async (reviewId?: string) => {
    return await getReviewSteps(reviewId);
  },
);

export const getReviewStepThunk = createAsyncThunk(
  APIExtRoutes.reviewStep,
  async ({ reviewId, stepId }: { reviewId: string; stepId: ReviewStep }) => {
    const subStep =
      stepId === Feature || stepId === BusinessMetric || stepId === Fee
        ? Analysis
        : undefined;
    const updateCurrentStep = await putReviewCurrentStep(
      reviewId,
      stepId,
      subStep,
    );
    if (!updateCurrentStep.isSuccess)
      return { ...updateCurrentStep, data: undefined };
    return await getReviewStep(reviewId, stepId);
  },
);

export const createReviewThunk = createAsyncThunk(
  `${APIExtRoutes.reviews}/create`,
  async (setupStep: ReviewStepSetupDTO) => {
    return await postReview(setupStep);
  },
);

export const updateReviewThunk = createAsyncThunk(
  `${APIExtRoutes.reviews}/update`,
  async (review: ReviewDTO) => {
    return await putReview(review);
  },
);

// Review - Feature's thunk functions
export const getReviewFeatureSelectionsThunk = createAsyncThunk(
  APIExtRoutes.reviewFeatureSelections,
  async ({ reviewId }: { reviewId: string }) => {
    const updateCurrentStep = await putReviewCurrentStep(
      reviewId,
      Feature,
      Selection,
    );
    if (!updateCurrentStep.isSuccess)
      return { ...updateCurrentStep, data: undefined };
    return await getFeatureSelectionStep(reviewId);
  },
);

export const updateReviewFeatureSelectionsThunk = createAsyncThunk(
  `${APIExtRoutes.reviewFeatureSelections}/update`,
  async ({
    reviewId,
    featureSelections,
  }: {
    reviewId: string;
    featureSelections: any;
  }) => {
    return putFeatureSelectionStep(reviewId, featureSelections);
  },
);

// Review - BM's thunk functions
export const getReviewBusinessMetricSelectionThunk = createAsyncThunk(
  APIExtRoutes.reviewBusinessMetricSelections,
  async ({ reviewId }: { reviewId: string }) => {
    const updateCurrentStep = await putReviewCurrentStep(
      reviewId,
      BusinessMetric,
      Selection,
    );
    if (!updateCurrentStep.isSuccess)
      return { ...updateCurrentStep, data: undefined };
    return await getReviewBMSelectionStep(reviewId);
  },
);

export const updateReviewBusinessMetricSelectionThunk = createAsyncThunk(
  `${APIExtRoutes.reviewBusinessMetricSelections}/update`,
  async ({
    reviewId,
    businessMetricSelections,
  }: {
    reviewId: string;
    businessMetricSelections: ReviewGetBusinessMetricSectionsDTO;
  }) => {
    return putReviewBMSelectionStep(reviewId, businessMetricSelections);
  },
);

// Review - Fee's thunk functions
export const getReviewFeeCalculatorThunk = createAsyncThunk(
  APIExtRoutes.reviewFeeCalculator,
  async ({ reviewId }: { reviewId: string }) => {
    const updateCurrentStep = await putReviewCurrentStep(
      reviewId,
      Fee,
      Selection,
    );
    if (!updateCurrentStep.isSuccess)
      return { ...updateCurrentStep, data: undefined };
    return await getReviewFeeCalculatorStep(reviewId);
  },
);
export const updateReviewFeeCalculatorThunk = createAsyncThunk(
  `${APIExtRoutes.reviewFeeCalculator}/update`,
  async ({
    reviewId,
    feeCalculators,
  }: {
    reviewId: string;
    feeCalculators: ReviewGetFeeCalculatorDTO;
  }) => {
    return putReviewFeeCalculatorStep(reviewId, feeCalculators);
  },
);

export const updateReviewDecisionMakingStatusThunk = createAsyncThunk(
  `${APIExtRoutes.reviewDecisionMaking}/update`,
  async (updateReviewDecisionMakingDto: UpdateDecisionMarkingDTO) => {
    return putReviewDecisionMaking(updateReviewDecisionMakingDto);
  },
);

export const {
  updateIsValidAction,
  updateIsDirtyAction,
  updateIsCompletedAction,
  updateIsReadOnlyAction,
  updateIsAccessDeniedAction,
  updateIsShowHiddenAction,
  updateIsShowHiddenAppendixAction,
  updateBannerInfoAction,
  updateReviewsStepsAction,
  updateFeatureSelectionAction,
  updateFeatureSelectionGroup,
  updateReviewAction,
  updateStepUnHighlightedAction,
  updateSelectedStepIndexAction,
  updateSelectedSubStepIndexAction,
  updateFilterBusinessMetricAction,
  updateFeeCalculatorAction,
  updateFilterFeatureAction,
  updateBusinessMetricSelectionAction,
  updateCollapsedSubGroupIdsAction,
  resetReviewStore,
  resetReviewFeeStore,
  updateReachAnalysisSetupPageAction,
  updateDisclaimersAction,
  updateFeatureReviewRowsAction,
  updateCollapseMappingAction,
  updateTotalCollapsedBusinessMetricRowsAction,
  updateLineChartColorsAction,
  updateAppendixLineChartColorsAction,
} = reviewSlice.actions;

export default reviewSlice.reducer;
