import { DeleteButton } from "@components/buttons/delete";
import { PlusButton } from "@components/buttons/plus";
import { RollBackButton } from "@components/buttons/rollback";
import {
  SHBox,
  SHImage,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import {
  CheckCircleSVG,
  CrossCircleSVG,
  EditSVG,
  PlusSVG,
} from "@components/svgs";
import { ReviewDecisionStatus } from "@models/reviews/entities/status";
import { ReviewSelectedProductDTO } from "@models/reviews/entities/steps/feature";
import { useTheme } from "@mui/material";
import { UpdateDecisionStatus } from "@pages/reviews/_id/steps/feature/analysis/config";
import { RootState } from "@redux/store";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

export interface FeeReviewProductCardProps extends SHStackProps {
  platform: ReviewSelectedProductDTO;
  isShowButtons?: boolean;
  updatingDecisionStatus?: UpdateDecisionStatus;
  onChangeDecisionStatus?: (
    platformId: string,
    status: ReviewDecisionStatus,
  ) => void;
  onModifyComment?: (platformId: string, comment?: string) => void;
}
export const FeeReviewProductCard = ({
  platform,
  isShowButtons = true,
  updatingDecisionStatus,
  onChangeDecisionStatus,
  onModifyComment,
  ...others
}: FeeReviewProductCardProps) => {
  const { palette } = useTheme();
  const { isReadOnly } = useSelector((state: RootState) => state.review);

  const renderDecisionBanner = (decisionStatus?: ReviewDecisionStatus) => {
    if (decisionStatus === ReviewDecisionStatus.Disqualified)
      return (
        <SHStack
          direction="row"
          alignItems={"center"}
          justifyContent={"center"}
          spacing={1}
          height={"25px"}
          sx={{
            backgroundColor: "#7C8493",
            transition: "all 0.5s ease",
          }}
        >
          <CrossCircleSVG />
          <SHTypography
            variant="body3"
            colorVariant="white"
            paddingBottom={"-2px"}
            fontSize={"12px"}
          >
            Disqualified
          </SHTypography>
        </SHStack>
      );

    if (decisionStatus === ReviewDecisionStatus.Shortlisted)
      return (
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={1}
          height={"25px"}
          sx={{
            backgroundColor: "#0EC48F",
            transition: "all 0.5s ease",
          }}
        >
          <CheckCircleSVG />
          <SHTypography
            variant="body3"
            colorVariant="white"
            paddingBottom={"-2px"}
            fontSize={"12px"}
          >
            Added to shortlist
          </SHTypography>
        </SHStack>
      );
  };

  return (
    <SHStack
      {...others}
      sx={{
        backgroundColor: palette.common.white,
        borderColor: palette.secondary[100],
        borderStyle: "solid",
        borderWidth: "1px",
        width: "100%",
        ...others.sx,
      }}
      className="test"
    >
      <SHStack spacing={"10px"} margin={"15px"}>
        <SHStack direction={"row"} alignItems={"center"} spacing={"10px"}>
          <SHBox
            sx={{
              width: "33px",
              height: "33px",
              position: "relative",
            }}
          >
            <SHImage src={platform.productLogo?.url} width={33} height={33} />
          </SHBox>

          <SHTypography
            variant="subtitle2"
            lineHeight={"120%"}
            sx={{
              wordBreak: "break-word",
            }}
          >
            {platform.productName}
          </SHTypography>
        </SHStack>
        {isShowButtons && (
          <SHStack>
            <SHStack direction={"row"} alignItems={"center"} spacing={"5px"}>
              <>
                {platform.decisionStatus === ReviewDecisionStatus.Undecided ? (
                  <PlusButton
                    variant="outlined"
                    size="medium"
                    disabled={
                      (updatingDecisionStatus &&
                        (updatingDecisionStatus?.platformId !== platform.id ||
                          updatingDecisionStatus.decisionStatus !==
                            ReviewDecisionStatus.Shortlisted)) ||
                      isReadOnly
                    }
                    isLoading={
                      updatingDecisionStatus?.platformId === platform.id &&
                      updatingDecisionStatus.decisionStatus ===
                        ReviewDecisionStatus.Shortlisted
                    }
                    startIcon={<PlusSVG />}
                    onClick={
                      typeof onChangeDecisionStatus === "function"
                        ? () =>
                            onChangeDecisionStatus(
                              platform.id,
                              ReviewDecisionStatus.Shortlisted,
                            )
                        : () => {}
                    }
                  >
                    Shortlist
                  </PlusButton>
                ) : (
                  <PlusButton
                    variant="outlined"
                    size="medium"
                    disabled={!!updatingDecisionStatus}
                    startIcon={
                      isEmpty(platform.comment?.comment) ? (
                        <PlusSVG />
                      ) : (
                        <EditSVG />
                      )
                    }
                    onClick={
                      typeof onModifyComment === "function"
                        ? () =>
                            onModifyComment(
                              platform.id,
                              platform?.comment?.comment,
                            )
                        : () => {}
                    }
                  >
                    Comment
                  </PlusButton>
                )}
                {platform.decisionStatus ===
                ReviewDecisionStatus.Disqualified ? (
                  <RollBackButton
                    backgroundColor={"transparent"}
                    borderColor={palette.common.black}
                    disabled={
                      (updatingDecisionStatus &&
                        (updatingDecisionStatus?.platformId !== platform.id ||
                          updatingDecisionStatus.decisionStatus !==
                            ReviewDecisionStatus.Undecided)) ||
                      isReadOnly
                    }
                    isLoading={
                      updatingDecisionStatus?.platformId === platform.id &&
                      updatingDecisionStatus.decisionStatus ===
                        ReviewDecisionStatus.Undecided
                    }
                    onClick={
                      typeof onChangeDecisionStatus === "function"
                        ? () =>
                            onChangeDecisionStatus(
                              platform.id,
                              ReviewDecisionStatus.Undecided,
                            )
                        : () => {}
                    }
                  />
                ) : (
                  <DeleteButton
                    height={32}
                    width={32}
                    backgroundColor={"transparent"}
                    borderColor={palette.common.black}
                    disabled={
                      (updatingDecisionStatus &&
                        (updatingDecisionStatus?.platformId !== platform.id ||
                          updatingDecisionStatus.decisionStatus !==
                            ReviewDecisionStatus.Disqualified)) ||
                      isReadOnly
                    }
                    isLoading={
                      updatingDecisionStatus?.platformId === platform.id &&
                      updatingDecisionStatus.decisionStatus ===
                        ReviewDecisionStatus.Disqualified
                    }
                    onClick={
                      typeof onChangeDecisionStatus === "function"
                        ? () =>
                            onChangeDecisionStatus(
                              platform.id,
                              ReviewDecisionStatus.Disqualified,
                            )
                        : () => {}
                    }
                  />
                )}
              </>
            </SHStack>
          </SHStack>
        )}
      </SHStack>

      {renderDecisionBanner(platform.decisionStatus)}
    </SHStack>
  );
};
