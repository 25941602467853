import { InformationButton } from "@components/buttons/information";
import {
  SHStack,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { CheckSVG, CrossSVG, WarningSVG } from "@components/svgs";
import {
  MAAnalysisDataDTO,
  MAAnalysisSectionDTO,
  SelectedSubProductDTO,
} from "@models/managed-accounts/entities/step/fee";
import { ManagedAccountFieldType } from "@models/managed-accounts/enums/fieldType";
import { ManagedAccountFieldTypeValue } from "@models/managed-accounts/enums/fieldTypeValue";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { MultipleInformationButton } from "@pages/managed-accounts/_id/steps/fee-analysis/components/buttons/multiple-information";
import {
  FeeAnalysisTableCell,
  FeeAnalysisTableCellProps,
  RenderNumericFeesValue,
} from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-cell";
import { FeeAnalysisTableHeadProps } from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-head";
import { generateManageAccountColorCell } from "@pages/managed-accounts/_id/steps/fee-analysis/util";

interface FeeAnalysisFeatureTableRowProps {
  tableViewMode?: FeesDisplayStyle;
  feature?: MAAnalysisSectionDTO;
}

export const FeeAnalysisFeatureTableRow = ({
  tableViewMode,
  feature,
  selectedSubProducts,
}: FeeAnalysisFeatureTableRowProps &
  FeeAnalysisTableCellProps &
  SHTableRowProps &
  Pick<FeeAnalysisTableHeadProps, "selectedSubProducts">) => {
  const renderAnalysisDataValue = (
    variableName: string,
    analysisData: MAAnalysisDataDTO,
    fieldTypeId?: string,
  ) => {
    if (analysisData?.value === ManagedAccountFieldTypeValue.NotDisclosed) {
      return (
        <SHStack direction="row" justifyContent="space-between" width="100%">
          <SHStack
            direction="row"
            spacing={1}
            justifyContent="center"
            width="100%"
          >
            <SHStack mt={"1px"}>
              <WarningSVG backgroundColor="#EB5757" iconColor="white" />
            </SHStack>
            <SHTypography
              variant="body3"
              colorVariant="third"
              lineHeight="120%"
            >
              {analysisData.value}
            </SHTypography>
          </SHStack>
        </SHStack>
      );
    }

    switch (fieldTypeId) {
      case ManagedAccountFieldType.Currency:
        if (analysisData?.isMultiple && analysisData.tooltip) {
          return (
            <SHStack direction="row" spacing={1} justifyContent="center">
              <SHTypography
                variant="body3"
                colorVariant="third"
                lineHeight="120%"
              >
                Multiple
              </SHTypography>
              <SHStack>
                <MultipleInformationButton
                  title={analysisData.tooltip?.header}
                  data={analysisData.tooltip?.data}
                />
              </SHStack>
            </SHStack>
          );
        }

        return (
          <SHStack direction="row" spacing={1} justifyContent="center">
            <SHTypography
              variant="body3"
              colorVariant="third"
              lineHeight="120%"
            >
              <RenderNumericFeesValue
                value={analysisData?.value}
                valuePercentage={analysisData?.valueInPercent}
                tableViewMode={tableViewMode}
              />
            </SHTypography>
          </SHStack>
        );
      case ManagedAccountFieldType.YesNo:
        switch (analysisData?.value) {
          case ManagedAccountFieldTypeValue.True:
            if (variableName === "Model_inception") {
              //TODO: hard code for a few special format display
              return (
                <SHStack direction="row" spacing={1} justifyContent="center">
                  <SHTypography
                    variant="body3"
                    colorVariant="third"
                    lineHeight="120%"
                  >
                    Yes
                  </SHTypography>
                  <SHStack>
                    <MultipleInformationButton
                      title={analysisData.tooltip?.header}
                      data={analysisData.tooltip?.data}
                      specialFormat={true}
                    />
                  </SHStack>
                </SHStack>
              );
            }
            return <CheckSVG />;
          case ManagedAccountFieldTypeValue.False:
            if (variableName === "Model_inception") {
              //TODO: hard code for a few special format display
              return (
                <SHStack direction="row" spacing={1} justifyContent="center">
                  <SHTypography
                    variant="body3"
                    colorVariant="third"
                    lineHeight="120%"
                  >
                    No
                  </SHTypography>
                  <CheckSVG />
                </SHStack>
              );
            }
            return <CrossSVG />;
          case ManagedAccountFieldType.Multiple:
            return (
              <SHStack direction="row" spacing={1} justifyContent="center">
                <SHTypography
                  variant="body3"
                  colorVariant="third"
                  lineHeight="120%"
                >
                  Multiple
                </SHTypography>
                <SHStack>
                  <MultipleInformationButton
                    title={analysisData.tooltip?.header}
                    data={analysisData.tooltip?.data}
                  />
                </SHStack>
              </SHStack>
            );
          default:
            return (
              <SHTypography
                variant="body3"
                colorVariant="third"
                lineHeight="120%"
              >
                {analysisData?.value}
              </SHTypography>
            );
        }
      case ManagedAccountFieldType.DateTime:
        if (analysisData?.value === ManagedAccountFieldTypeValue.Multiple) {
          return (
            <SHStack direction="row" spacing={1} justifyContent="center">
              <SHTypography
                variant="body3"
                colorVariant="third"
                lineHeight="120%"
              >
                Multiple
              </SHTypography>
              <SHStack>
                <MultipleInformationButton
                  title={analysisData.tooltip?.header}
                  data={analysisData.tooltip?.data}
                />
              </SHStack>
            </SHStack>
          );
        }
        return (
          <SHTypography variant="body3" colorVariant="third" lineHeight="120%">
            {analysisData?.value}
          </SHTypography>
        );
      case ManagedAccountFieldType.Percentage:
        if (analysisData?.isMultiple && analysisData.tooltip) {
          return (
            <SHStack direction="row" spacing={1} justifyContent="center">
              <SHTypography
                variant="body3"
                colorVariant="third"
                lineHeight="120%"
              >
                {analysisData.value === ManagedAccountFieldTypeValue.Multiple
                  ? ManagedAccountFieldTypeValue.Multiple
                  : `${analysisData?.value}%`}
              </SHTypography>
              <SHStack>
                <MultipleInformationButton
                  title={analysisData.tooltip?.header}
                  data={analysisData.tooltip?.data}
                />
              </SHStack>
            </SHStack>
          );
        }

        return (
          <SHTypography variant="body3" colorVariant="third" lineHeight="120%">
            <RenderNumericFeesValue
              valuePercentage={analysisData?.value}
              tableViewMode={FeesDisplayStyle.Percentage}
            />
          </SHTypography>
        );
      case ManagedAccountFieldType.Warning:
        if (analysisData?.value === ManagedAccountFieldTypeValue.NoBreach) {
          return <CheckSVG />;
        }

        if (analysisData?.value === "-") {
          return (
            <SHTypography
              variant="body3"
              colorVariant="third"
              lineHeight="120%"
            >
              {analysisData?.value}
            </SHTypography>
          );
        }

        return (
          <SHStack direction="row" justifyContent="space-between" width="100%">
            <SHStack
              direction="row"
              spacing={1}
              justifyContent="center"
              width="100%"
            >
              <SHStack mt={"1px"}>
                <WarningSVG backgroundColor="#EB5757" iconColor="white" />
              </SHStack>
              <SHTypography
                variant="body3"
                colorVariant="third"
                lineHeight="120%"
              >
                Breach
              </SHTypography>
              <SHStack>
                <MultipleInformationButton
                  title={analysisData.tooltip?.header}
                  data={analysisData.tooltip?.data}
                  specialFormat={true}
                />
              </SHStack>
            </SHStack>
          </SHStack>
        );
      case ManagedAccountFieldType.Dropdown:
        if (analysisData?.value === ManagedAccountFieldTypeValue.Multiple) {
          return (
            <SHStack direction="row" spacing={1} justifyContent="center">
              <SHTypography
                variant="body3"
                colorVariant="third"
                lineHeight="120%"
              >
                {analysisData?.value}
              </SHTypography>
              <SHStack>
                <MultipleInformationButton
                  title={analysisData.tooltip?.header}
                  data={analysisData.tooltip?.data}
                />
              </SHStack>
            </SHStack>
          );
        }
        return (
          <SHTypography variant="body3" colorVariant="third" lineHeight="120%">
            {analysisData?.value}
          </SHTypography>
        );
      case ManagedAccountFieldType.Text:
        if (analysisData?.isMultiple && analysisData.tooltip) {
          return (
            <SHStack direction="row" spacing={1} justifyContent="center">
              <SHTypography
                variant="body3"
                colorVariant="third"
                lineHeight="120%"
              >
                Multiple
              </SHTypography>
              <SHStack>
                <MultipleInformationButton
                  title={analysisData.tooltip?.header}
                  data={analysisData.tooltip?.data}
                />
              </SHStack>
            </SHStack>
          );
        }

        return (
          <SHTypography variant="body3" colorVariant="third" lineHeight="120%">
            {analysisData?.value}
          </SHTypography>
        );
      default:
        return (
          <SHTypography variant="body3" colorVariant="third" lineHeight="120%">
            {analysisData?.value}
          </SHTypography>
        );
    }
  };

  return (
    <>
      <FeeAnalysisTableCell isFirstColumn>
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <SHTypography
            variant="body3"
            colorVariant="third"
            textAlign={"left"}
            lineHeight="120%"
          >
            {feature?.name}
          </SHTypography>
          {feature?.description && (
            <InformationButton content={feature.description} />
          )}
        </SHStack>
      </FeeAnalysisTableCell>
      {feature?.analysisData?.map((analysisData: MAAnalysisDataDTO) => {
        const platform = selectedSubProducts?.find(
          (selectedProduct: SelectedSubProductDTO) =>
            selectedProduct.id === analysisData.subProductId,
        );

        return (
          <FeeAnalysisTableCell
            key={analysisData?.subProductId}
            colorCell={generateManageAccountColorCell(platform)}
          >
            <SHStack direction={"row"} justifyContent="center">
              <SHStack
                direction={"row"}
                alignItems={"center"}
                justifyContent="space-between"
                width="100%"
              >
                <SHStack
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  {renderAnalysisDataValue(
                    feature.variableName,
                    analysisData,
                    analysisData?.fieldTypeId,
                  )}
                </SHStack>
              </SHStack>
            </SHStack>
          </FeeAnalysisTableCell>
        );
      })}
    </>
  );
};
