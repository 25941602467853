import { Components, Theme } from "@mui/material";
export const muiSelectTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiSelect"> => ({
  MuiSelect: {
    styleOverrides: {
      icon: {
        paddingRight: "10px",
      },
      iconOpen: {
        paddingLeft: "10px",
      },
      select: {
        "&.MuiSelect-select": {
          display: "flex",
          height: "80%",
          alignItems: "center",
          gap: "10px",
          color: `${theme.palette.text.primary} !important`,
          fontSize: "13px",
          fontWeight: "500",
          paddingLeft: "10px",
        },
      },
    },
    defaultProps: {
      variant: "standard",
      displayEmpty: true,
      sx: {
        minWidth: "50px",
      },
    },
  },
});
