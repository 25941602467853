import { FieldTypeId } from "@models/configuration";
import { ProductFieldDTO } from "@models/product/entities/product";
import { isEmpty, isNil } from "lodash";

export const toNativeValue = (value: string, fieldType: FieldTypeId) => {
  switch (fieldType) {
    case FieldTypeId.Number:
      return isEmpty(value) ? null : +value;
    case FieldTypeId.Slideshow:
    case FieldTypeId.Image:
      return isEmpty(value) ? null : JSON.parse(value);
    case FieldTypeId.YesNo:
      return isEmpty(value) ? null : value === "true";
    default:
      return value;
  }
};

export const toStringValue = (
  nativeValue: any,
  fieldType: FieldTypeId,
): string => {
  if (isNil(nativeValue)) return "";
  switch (fieldType) {
    case FieldTypeId.Slideshow:
    case FieldTypeId.Image:
      return JSON.stringify(nativeValue);
    default:
      return nativeValue.toString();
  }
};

export const generateTitle = (productName?: string, releaseName?: string) => {
  if (isEmpty(productName)) return "New Product";
  if (!isEmpty(releaseName)) return `${productName} - ${releaseName}`;
  return productName;
};

export const checkHasBeenModified = (
  productData?: ProductFieldDTO,
): boolean => {
  if (!productData) return false;

  const value = productData?.value || "";
  const publishedValue = productData?.publishedValue || "";
  const additionalValue = productData?.additionalValue || "";
  const publishedAdditionalValue = productData?.publishedAdditionalValue || "";

  if (value !== publishedValue) return true;

  if (
    (additionalValue || publishedAdditionalValue) &&
    value === "true" &&
    additionalValue !== publishedAdditionalValue
  )
    return true;

  return false;
};
