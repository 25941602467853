import { DateFormat, TimeFormat } from "@constants/format";
import {
  loadConfigurationGroup,
  loadHistoryRelease,
  loadStatusDraft,
} from "@redux/slices/configuration";
import { updateConfigurationNotifications } from "@redux/slices/top-notifications";
import { RootState, useAppDispatch } from "@redux/store";
import { CancelToken } from "axios";
import { format } from "date-fns";
import { isNil } from "lodash";
import { useSelector } from "react-redux";
import { useNotification } from "./useNotification";

export const useConfiguration = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { configurationGroupData } = useSelector(
    (state: RootState) => state.configuration,
  );

  //@NOTE: Call after do any action in configuration
  const updateDraftStatus = async (cancelToken?: CancelToken) => {
    const response = await dispatch(loadStatusDraft(cancelToken)).unwrap();
    if (response.isSuccess) {
      if (response.data) {
        let { lastModifiedDate, lastUpdatedUserName } = response.data;
        if (isNil(lastModifiedDate)) lastModifiedDate = new Date();
        dispatch(
          updateConfigurationNotifications([
            {
              title: "Draft in progress",
              message: `Last updated by ${
                lastUpdatedUserName ?? ""
              } at ${format(
                lastModifiedDate,
                `${TimeFormat} 'on' ${DateFormat}`,
              )}.`,
            },
          ]),
        );
        return;
      }
      dispatch(updateConfigurationNotifications([]));
      return;
    }
    if (!response.isCancel) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
  };

  const updateConfigurationGroup = async (groupId: string) => {
    const response = await dispatch(loadConfigurationGroup(groupId)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
  };

  const refreshConfigurationGroup = async () => {
    if (configurationGroupData?.id) {
      await updateConfigurationGroup(configurationGroupData?.id);
    }
  };

  const updateHistoryReleases = async () => {
    const response = await dispatch(loadHistoryRelease()).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
  };
  return {
    updateDraftStatus,
    updateConfigurationGroup,
    refreshConfigurationGroup,
    updateHistoryReleases,
  };
};
