import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import {
  TemplateNameValidationStatus,
  CreateFeatureTemplateDTO,
  FeatureTemplateCheckUniqueNameDTO,
  FeatureTemplateDTO,
  FeatureTemplateSelectionDTO,
} from "@models/feature-template/entities/featureTemplate";
import { FeatureTemplateStatus } from "@models/feature-template/enums/status";
import { mockFeatureTemplate } from "@services/feature-template/mock";
import { compileFeatureTemplate } from "@services/feature-template/util";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { sleep } from "@utils";
import { generatePath } from "react-router";

export async function getFeatureTemplate(
  featureTemplateId: string,
): Promise<APIResponse<FeatureTemplateDTO>> {
  // const response = await APIGatewayService.get<FeatureTemplateDTO>(
  //   `${APIExtRoutes.featureTemplate}/${featureTemplateId}`,
  // );
  // return handleErrorProxy(response);

  await sleep(2000);
  return handleErrorProxy({
    config: {},
    headers: {},
    data: mockFeatureTemplate,
    status: 200,
    statusText: "OK",
  });
}

export async function getFeatureTemplateLOV(): Promise<
  APIResponse<FeatureTemplateDTO>
> {
  const response = await APIGatewayService.get<FeatureTemplateDTO>(
    `${APIExtRoutes.featureTemplate}/new`,
  );
  if (response.data) {
    compileFeatureTemplate(response.data);
  }
  return handleErrorProxy(response);
}

export async function postFeatureTemplate(
  featureTemplate?: CreateFeatureTemplateDTO,
): Promise<APIResponse<CreateFeatureTemplateDTO>> {
  const response = await APIGatewayService.post<FeatureTemplateDTO>(
    `${APIExtRoutes.featureTemplate}`,
    featureTemplate,
  );
  return handleErrorProxy(response, "Feature template has been created.");
}

export async function putFeatureTemplate(
  featureTemplate?: FeatureTemplateDTO,
): Promise<APIResponse<FeatureTemplateDTO>> {
  const response = await APIGatewayService.put<FeatureTemplateDTO>(
    `${APIExtRoutes.featureTemplate}`,
    featureTemplate,
  );
  return handleErrorProxy(response, "Template saved!");
}

export async function putFeatureTemplateStatus(
  featureTemplateId: string,
  status: FeatureTemplateStatus,
): Promise<APIResponse<FeatureTemplateStatus>> {
  const response = await APIGatewayService.put<FeatureTemplateStatus>(
    generatePath(APIExtRoutes.updateFeatureTemplateStatus, {
      featureTemplateId,
      status,
    }),
  );
  return handleErrorProxy(response, "Your change has been updated.");
}

export async function getExistingFeatureTemplate(
  filterByFeatureTemplateName?: string,
): Promise<APIResponse<FeatureTemplateSelectionDTO[]>> {
  const response = await APIGatewayService.get<FeatureTemplateSelectionDTO[]>(
    APIExtRoutes.platformAnalysisFeatureTemplates,
    {
      params: {
        filterByFeatureTemplateName,
      },
    },
  );

  return handleErrorProxy(response);
}

export async function checkFeatureTemplateUniqueName(
  ftCheckUniqueName: FeatureTemplateCheckUniqueNameDTO,
): Promise<APIResponse<TemplateNameValidationStatus>> {
  const response = await APIGatewayService.post<TemplateNameValidationStatus>(
    APIExtRoutes.featureTemplateUniqueName,
    ftCheckUniqueName,
  );
  return handleErrorProxy(response);
}

export async function getExistingFeatureTemplateForReview(
  filterByFeatureTemplateName?: string,
): Promise<APIResponse<FeatureTemplateSelectionDTO[]>> {
  const response = await APIGatewayService.get<FeatureTemplateSelectionDTO[]>(
    APIExtRoutes.reviewFeatureTemplates,
    {
      params: {
        filterByFeatureTemplateName,
      },
    },
  );

  return handleErrorProxy(response);
}
