import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { ReviewGetFeeCalculatorDTO } from "@models/reviews/entities/steps/fee";
import { compileFeeCalculator } from "@services/platform-analysis/util";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getReviewFeeCalculatorStep(
  reviewId: string,
): Promise<APIResponse<ReviewGetFeeCalculatorDTO>> {
  const response = await APIGatewayService.get<ReviewGetFeeCalculatorDTO>(
    generatePath(APIExtRoutes.reviewFeeCalculator, {
      reviewId,
    }),
  );
  if (response.data) {
    compileFeeCalculator(response.data);
  }
  return handleErrorProxy(response);
}

export async function putReviewFeeCalculatorStep(
  reviewId: string,
  feeCalculators: ReviewGetFeeCalculatorDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.reviewFeeCalculator, {
      reviewId,
    }),
    feeCalculators,
  );
  return handleErrorProxy(response, "Changes have been saved.");
}
