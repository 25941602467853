import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHDivider,
  SHHtmlBlock,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { SHBooleanIcon } from "@components/design-systems/sh-boolean-icon";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import { ImageGalleryBox } from "@components/image-gallery";
import { ImageSVG } from "@components/svgs";
import { PageRoutes } from "@constants";
import { ProductFeaturesLayout } from "@layouts/products/features";
import { UserType } from "@models/auth";
import { FieldTypeId } from "@models/configuration";
import { Link, useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";
import { toNativeValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { FeatureViewLongText } from "../feature-view-long-text";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { generateFeatureName } from "@utils/text-format";
import { InvestmentProductCollapseMenu } from "./components/investment-collapse-menu";
import SectionTitle from "./components/sub-section-title";
import { FRCell } from "../table-cell";
import { isEmpty } from "lodash";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { DateFormat2, FullDateFormat } from "@constants/format";
import { format, parse } from "date-fns";

interface ViewFeatureProps {
  investmentProductSeriesData?: InvestmentProductSeriesDTO[];
  isSeriesDataLoading?: boolean;
}

export const ViewFeature = ({
  investmentProductSeriesData,
  isSeriesDataLoading
}: ViewFeatureProps) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [selectedIndex, setSelectedIndex] = useState<[number, number]>([0, 0]);
  const selectedSection = useMemo(() => {
    return investmentProductSeriesData?.[selectedIndex[0]]?.sections?.[selectedIndex[1]];
  }, [investmentProductSeriesData, selectedIndex]);
  const textShortFeature = selectedSection?.features?.filter(feature => feature.fieldType?.id === FieldTypeId.TextShort) ?? [];

  const renderInvestmentTableItem = (features: InvestmentProductConfigurationFeature[]) => {
    if (features.length === 0) return;

    features = features.filter(e => e.name !== "Series");

    return (
      <SHTableContainer sx={{ paddingTop: "12px", overflowY: "hidden", maxWidth: "523.5px !important" }}>
        <SHTable>
          <SHTableHead>
            <SHTableRow>
              <FRCell isHeader>
                <SHTypography variant="body4" fontWeight={600}>
                  Series FUM
                </SHTypography>
              </FRCell>
              <FRCell isHeader cellWidth={"180px !important"}>
                <SHTypography variant="body4" fontWeight={600}>
                  Enhanced Strategic
                </SHTypography>
              </FRCell>
            </SHTableRow>
          </SHTableHead>
          <SHTableBody
            sx={{
              "&.MuiTableBody-root::before": {
                lineHeight: "0px",
                fontSize: "0px",
              },
              maxWidth: "523.5px !important"
            }}
          >
            <SHTableRow
              style={{
                height: "13px",
                border: 0,
                borderBottomWidth: 1,
                background: "transparent",
              }}
            ></SHTableRow>
            {features?.map((row, index) => {
              return (
                <SHTableRow
                  key={row.id}
                  sx={{
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    borderTopWidth: index !== 0 ? 0 : 1,
                    backgroundColor: index % 2 === 0 ? `${palette.secondary[50]} !important` : "transparent !important",
                  }}
                >
                  <FRCell>
                    <SHTypography variant="body4">{row?.name}</SHTypography>
                    {!isEmpty(row.description) && (
                      <InformationButton content={row.description} />
                    )}
                  </FRCell>
                  <FRCell cellWidth={"180px !important"}>
                    <SHTypography variant="body4">
                      {row.productData?.value}
                    </SHTypography>
                  </FRCell>
                </SHTableRow>
              );
            })}
          </SHTableBody>
        </SHTable>
      </SHTableContainer>
    );
  }

  const renderFeatureItem = (feature: InvestmentProductConfigurationFeature) => {
    const isSlideShow = feature.fieldType?.id === FieldTypeId.Slideshow;
    const value = toNativeValue(feature.productData?.value || "", isSlideShow ? FieldTypeId.Slideshow : FieldTypeId.YesNo);
    const additionalValue = feature.productData?.additionalValue;
    const hasDescription = isSlideShow || (value === true && additionalValue);

    const renderTextLongFeatureItem = (feature: InvestmentProductConfigurationFeature) => {
      const featureName = generateFeatureName(feature.name ?? "", feature.description);

      const getFeatureValue = () => {
        const value = feature.productData?.value;
        if (!value) {
          return (
            <SHTypography variant="body3" colorVariant="third">
              No data
            </SHTypography>
          );
        }
        return (
          <SHHtmlBlock
            variant="body3"
            color={palette.text.third}
            content={value}
          />
        );
      };

      if (!investmentProductSeriesData) {
        return <></>;
      }

      return (
        <>
          <SHStack
            direction={"column"}
            justifyContent="space-between"
            sx={{
              py: "15px",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              textAlign="left"
              width="100%"
              color="#25324B"
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
            </SHTypography>

            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
          <SHDivider />
        </>
      );
    };

    const renderTextDateTimeFeatureItem = (feature: InvestmentProductConfigurationFeature) => {
      const featureName = generateFeatureName(feature.name ?? "", feature.description);
      const getFeatureValue = () => {
        const value = feature.productData?.value;
        if (!value) {
          return (
            <SHTypography variant="body3" colorVariant="third">
              No data
            </SHTypography>
          );
        }

        const dateValue = parse(value, FullDateFormat, new Date());

        return (
          <SHTypography
            variant="body3"
            color={palette.text.third}
          >
            {format(dateValue, DateFormat2)}
          </SHTypography>
        );
      };

      if (!investmentProductSeriesData) {
        return <></>;
      }

      return (
        <>
          <SHStack
            direction={"column"}
            justifyContent="space-between"
            sx={{
              py: "15px",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              color="#25324B"
              textAlign="left"
              width="45%"
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
            </SHTypography>

            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
          <SHDivider />
        </>
      );
    };

    const renderFeatureValue = () => {
      switch (value) {
        case true:
          return <SHBooleanIcon label={"Available"} isTrue />;
        case false:
          return <SHBooleanIcon label={"Not available"} isTrue={false} />;
        default:
          return (
            <SHTypography
              variant="body2"
              colorVariant="third"
              sx={{
                minWidth: "54px",
              }}
            >
              No data
            </SHTypography>
          );
      }
    };

    const renderFeatureHeading = () => (
      <SHStack
        direction={"row"}
        justifyContent="space-between"
        sx={{ pr: "40px", py: "25px" }}
      >
        <SHStack direction="row" spacing={0.5} alignItems="start">
          <SHTypography color = "#25324B">{feature.name}</SHTypography>
          {feature?.description && (
            <InformationButton content={feature.description} />
          )}
        </SHStack>
        <SHBox width={130}>{renderFeatureValue()}</SHBox>
      </SHStack>
    );

    switch (feature.fieldType?.id) {
      case FieldTypeId.Slideshow: {
        if (!value || value.length === 0) {
          return <></>;
        }
        return (
          <SHCollapse
            sx={{ pt: "25px" }}
            open
            title={
              <SHStack direction="row" spacing={0.5} alignItems="start">
                <ImageSVG />
                <SHTypography>{feature.name}</SHTypography>
              </SHStack>
            }
            children={
              <SHBox sx={{ pt: "25px" }}>
                <ImageGalleryBox
                  title={selectedSection?.name}
                  initialImages={value}
                  width="100%"
                />
              </SHBox>
            }
          />
        );
      }

      case FieldTypeId.TextLong: {
        return renderTextLongFeatureItem(feature);
      }

      case FieldTypeId.TextShort: {
        return;
      }

      case FieldTypeId.DateTime: {
        return renderTextDateTimeFeatureItem(feature);
      }

      default: {
        if (!hasDescription) {
          return (
            <>
              {renderFeatureHeading()}
              <SHDivider />
            </>
          );
        }

        return (
          <>
            <SHCollapse
              title={renderFeatureHeading()}
              children={<FeatureViewLongText content={additionalValue} />}
              fullWidthTitle
            />
            <SHDivider />
          </>
        );
      }
    }
  };

  if (!investmentProductSeriesData) {
    return <></>;
  }

  const showUpgradeToView = () => {
    if (
      user?.userType === UserType.AdviserTrial ||
      user?.userType === UserType.AdviserAdminTrial
    ) {
      return true;
    }
    return false;
  };

  const renderPageUpdated = () => {
    if (!selectedSection || !selectedSection.pageUpdated) return <></>;

    const year = selectedSection.pageUpdated ? selectedSection.pageUpdated.getFullYear() % 100 : "";
    const month = selectedSection.pageUpdated ? (selectedSection.pageUpdated.getUTCMonth() + 1).toString().padStart(2, '0') : "";
    const day = selectedSection.pageUpdated ? selectedSection.pageUpdated.getDay().toString().padStart(2, '0') : "";

    return (
      <SHTypography
        variant="body1"
        colorVariant="third"
        textAlign={"right"}
      >
        Page updated: {`${day}/${month}/${year}`}
      </SHTypography>
    );
  }

  return (
    <SHStack>
      {showUpgradeToView() ? (
        <SHStack
          display={"flex"}
          gridTemplateColumns={"6.6fr 3.25fr"}
          marginTop={"25px"}
          gap={"15px"}
        >
          <SHTypography variant="subtitle2">
            Please upgrade your subscription
            <Link
              color={palette.text.primary}
              component="a"
              underline="always"
              onClick={() => {
                navigate(
                  `${generatePath(PageRoutes.practiceOverview, {
                    practiceId: user?.userMetadata?.adviser_firm_id,
                  })}/subscription`,
                );
              }}
              sx={{
                paddingX: 0.5,
                cursor: "pointer",
                textDecorationColor: palette.text.primary,
              }}
            >
              here
            </Link>
            to view detailed information, ESG policies, and features for this
            platform
          </SHTypography>
        </SHStack>
      ) : (
        <ProductFeaturesLayout
          leftSide={
            <InvestmentProductCollapseMenu
              investmentProductSeriesData={investmentProductSeriesData}
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
              expandedIndexProp={0}
            />
          }
          rightSide={
            <SHStack>
              <SHStack direction={"row"} sx={{ justifyContent: "space-between" }}>
                <SectionTitle groupName={investmentProductSeriesData ? investmentProductSeriesData[selectedIndex[0]].name : ""} sectionName={selectedSection?.name} />
                {renderPageUpdated()}
              </SHStack>
              <SHDivider />
              {renderInvestmentTableItem(textShortFeature)}
              {selectedSection?.features?.map(
                (feature: InvestmentProductConfigurationFeature) => (
                  <React.Fragment key={feature.id}>
                    {renderFeatureItem(feature)}
                  </React.Fragment>
                ),
              )}
            </SHStack>
          }
          isLoading={isSeriesDataLoading}
        />
      )}
    </SHStack>
  );
}