import { SVGProps } from "react";

export const UsersSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={22}
        height={22}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M20 19s1.333 0 1.333-1.333c0-1.334-1.333-5.334-6.666-5.334-5.334 0-6.667 4-6.667 5.334C8 19 9.333 19 9.333 19H20ZM9.363 17.667a.365.365 0 0 1-.03-.006c.002-.352.223-1.373 1.014-2.293.736-.863 2.029-1.701 4.32-1.701 2.289 0 3.582.84 4.32 1.701.79.92 1.01 1.943 1.013 2.293l-.01.003a.595.595 0 0 1-.02.003H9.364Zm5.304-8a2.667 2.667 0 1 0 0-5.334 2.667 2.667 0 0 0 0 5.334Zm4-2.667a4 4 0 1 1-8.001 0 4 4 0 0 1 8 0Zm-9.419 5.707a7.838 7.838 0 0 0-1.64-.33 9.83 9.83 0 0 0-.941-.044c-5.334 0-6.667 4-6.667 5.334C0 18.556.444 19 1.333 19h5.622a2.984 2.984 0 0 1-.288-1.333c0-1.347.502-2.723 1.453-3.872a7.054 7.054 0 0 1 1.128-1.088Zm-2.688.96a7.324 7.324 0 0 0-1.227 4h-4c0-.347.219-1.374 1.014-2.299.726-.848 1.989-1.675 4.213-1.7v-.001Zm-4.56-6a4 4 0 1 1 8 0 4 4 0 0 1-8 0ZM6 5a2.667 2.667 0 1 0 0 5.333A2.667 2.667 0 0 0 6 5Z"
            fill="#25324B"
        />
    </svg>
);
