import { SHStack } from "@components/design-systems";
import { useUserPermissions } from "@hooks/userUserPermission";
import { SHButton } from "../../design-systems/sh-button";
import { SHDialog } from "../../design-systems/sh-dialog";

interface DeletePostConfirmationDialogProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onOk: () => void;
}

export const DeletePostConfirmationDialog = ({
  open,
  isLoading,
  onClose,
  onOk,
}: DeletePostConfirmationDialogProps) => {
  const { isSuperAdmin } = useUserPermissions();

  return (
    <SHDialog
      open={open}
      onClose={onClose}
      width={600}
      header={isSuperAdmin ? "Archive post?" : "Delete your post?"}
      body={
        isSuperAdmin
          ? "You are about to archive the post. Are you sure?"
          : "You are about to delete your post. Do you want to continue?"
      }
      footer={
        <SHStack
          width="100%"
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            onClick={onClose}
            variant="text"
            size="extraMedium"
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onOk}
          >
            Yes, {isSuperAdmin ? "archive" : "delete"} post
          </SHButton>
        </SHStack>
      }
    />
  );
};
