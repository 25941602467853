import { SHStack, SHTypography } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { useManagedAccount } from "@hooks/useManagedAccount";
import { useSupplier } from "@hooks/useSupplier";
import { RootState } from "@redux/store";
import { useSelector } from "react-redux";

export interface ManagedAccountNameProps {}
export const ManagedAccountBannerInfo = () => {
  const {
    bannerInfo,
    ui: { isLoadingBanner },
  } = useManagedAccount();
  const { supplierData } = useSupplier();
  const { user } = useSelector((state: RootState) => state.auth);

  const owner = bannerInfo?.ownerName ?? user?.name;
  const firmName = bannerInfo?.firmName ?? supplierData?.companyName;

  if (isLoadingBanner)
    return (
      <SHStack direction="row" justifyContent="flex-start" width={"100%"}>
        <SHSkeleton height={30} width={"50%"} />
      </SHStack>
    );

  return (
    <SHStack
      sx={{
        flexGrow: 1,
        width: "1px",
      }}
    >
      <SHStack
        direction="row"
        justifyContent="flex-start"
        alignItems={"flex-end"}
        spacing={0.5}
      >
        <>
          <SHTypography
            variant="h2"
            fontSize={"18px"}
            lineHeight={1}
            truncate
            sx={{
              maxWidth: "60%",
            }}
          >
            {owner}
          </SHTypography>

          <SHTypography
            variant="h2"
            lineHeight={1}
            fontSize={"18px"}
            fontWeight={400}
            truncate
            sx={{
              maxWidth: "40%",
            }}
          >
            of {firmName}
          </SHTypography>
        </>
      </SHStack>
      <SHStack
        direction="row"
        justifyContent="flex-start"
        alignItems={"flex-end"}
        spacing={0.5}
      >
        <>
          <SHTypography
            variant="h2"
            lineHeight={1}
            fontSize={"18px"}
            fontWeight={400}
            truncate
            sx={{
              maxWidth: "40%",
            }}
          >
            for internal use only
          </SHTypography>
        </>
      </SHStack>
    </SHStack>
  );
};
