import { Components, Theme } from "@mui/material";
export const muiTextFieldTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiTextField"> => ({
  MuiTextField: {
    styleOverrides: {
      root: {
        "&>.Mui-error": {
          color: `${theme.palette.error.light} !important`,
        },
        "&>.MuiFormLabel-root": {
          color: theme.palette.text.primary,
          transform: "none",
          whiteSpace: "normal",
          textOverflow: "initial",
          maxWidth: "100%",
          position: "relative",
        },
        "&>.MuiInput-root": {
          color: `${theme.palette.text.primary} !important`,
          fontSize: "14px",
          fontWeight: "400",
          "&>input": {
            padding: "10px 10px 8px 10px",
          },
          height: "40px",
        },
        "&>.Mui-error:after": {
          borderBottomColor: `${theme.palette.error.light} !important`,
        },
        "&>.MuiFormHelperText-root": {
          color: `${theme.palette.error.light} !important`,
          fontWeight: "400",
        },
      },
    },
    defaultProps: {
      variant: "standard",
      InputLabelProps: {
        shrink: true,
      },
    },
  },
});
