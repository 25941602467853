import { FeeAnalysisFilterRadioButton } from "@components/buttons/fee-analysis-filter-radio";
import { FilterValue } from "@components/buttons/filter";
import {
  SHButton,
  SHContainer,
  SHGrid,
  SHStack,
  SHToggleButtonOptions,
  SHTypography,
} from "@components/design-systems";
import { EyeIcon } from "@components/svgs/eye";
import { DateFormat } from "@constants/format";
import { useReview } from "@hooks/useReview";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { UserType } from "@models/auth";
import { PlatformCommentDialog } from "@models/platform-analysis/entities/comment";
import { SelectedProductDTO } from "@models/platform-analysis/entities/steps/feature";
import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import {
  HiddenSubProductDto,
  ReviewFilterDTO,
} from "@models/reviews/entities/review";
import { ReviewDecisionStatus } from "@models/reviews/entities/status";
import { UpdateDecisionMarkingDTO } from "@models/reviews/entities/steps/feature";
import { ReviewStep } from "@models/reviews/enums/step";
import { useTheme } from "@mui/material";
import { ChartLegendBox } from "@pages/platform-analysis/_id/steps/fee/analysis/components/chart-legend-box";
import { FeeHeatmap } from "@pages/platform-analysis/_id/steps/fee/analysis/components/heatmap";
import { FeeLineChart } from "@pages/platform-analysis/_id/steps/fee/analysis/components/line-chart";
import { getProductCardSx } from "@pages/platform-analysis/_id/steps/fee/analysis/components/product-card/util";
import { AnalysisFeeSkeleton } from "@pages/platform-analysis/_id/steps/fee/analysis/skeleton";
import { ChangeRichTextDialog } from "@pages/platform-analysis/_id/steps/summary/components/change-rich-text-dialog";
import { ExportExcelButton } from "@pages/platform-analysis/components/buttons/export-excel";
import {
  FeeAnalysisFilterYesNoOptions,
  FeeReviewFilterMapOptions,
  PlatformFeeAnalysisInvestmentMenuFilterOptions,
  PlatformFeeAnalysisMissingInvestmentFilterOptions,
} from "@pages/platform-analysis/constant";
import { RootState } from "@redux/store";
import {
  disqualifyAllPlatform,
  putReviewDecisionMaking,
  saveReviewFilters,
  updateFinalJSONs,
} from "@services/reviews/reviewService";
import { format } from "date-fns";
import { chain, find, groupBy, isEmpty, isNil, mapValues, split } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { DisqualifyAllPlatformDialog } from "../../feature/analysis/components/dialogs/confirm-select-all";
import { NotifyUnShortedListDialog } from "../../feature/analysis/components/dialogs/notify-un-shorted-list";
import {
  GroupByDecisionStatus,
  UpdateDecisionStatus,
} from "../../feature/analysis/config";
import { FeeReviewProductCard } from "./components/product-card";

export interface ReviewFeeAnalysisProps extends StepCompProps {}
export const ReviewFeeAnalysis: ForwardRefRenderFunction<
  StepCompRef,
  ReviewFeeAnalysisProps
> = ({ stepNumber }: ReviewFeeAnalysisProps, ref) => {
  const { palette } = useTheme();
  const { reviewId } = useParams<{ reviewId: string }>();

  const {
    ui: { isLoading, isShowHidden },
    isCompleted,
    isReadOnly,
    review,
  } = useSelector((state: RootState) => state.review);

  const {
    loadReview,
    setIsShowHidden,
    resetReviewFee,
    updateReviewDecisionMaking,
  } = useReview();

  const [hiddenIds, setHiddenIds] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<FeesDisplayStyle>(
    FeesDisplayStyle.Dollar,
  );
  const [heatmapMode, setHeatmapMode] = useState<FeeHeatmapMode>(
    FeeHeatmapMode.Detail,
  );

  const [filterInvestmentMenuValue, setFilterInvestmentMenuValue] = useState<
    FilterValue[] | undefined
  >(undefined);
  const [filterMissingInvestmentValue, setFilterMissingInvestmentValue] =
    useState<FilterValue[] | undefined>(undefined);

  const [subProductData, setSubProductData] = useState(
    review?.fee?.all.subProducts ?? [],
  );
  const [products, setProducts] = useState(
    review?.fee?.all?.selectedProducts ?? [],
  );
  const [databaseVersion, setDatabaseVersion] = useState<Date>();
  const [commentDialog, setCommentDialog] = useState<
    PlatformCommentDialog | undefined
  >();
  const [updatingComment, setUpdatingComment] = useState(false);
  const [updatingDecisionStatus, setUpdatingDecisionStatus] = useState<
    UpdateDecisionStatus | undefined
  >(undefined);

  const [notifyUnShortedListDialog, setNotifyUnShortedListDialog] =
    useState(false);
  const isShowFeeDetail = useMemo(
    () => heatmapMode === FeeHeatmapMode.Detail,
    [heatmapMode],
  );
  const [disqualifyAllDialog, setDisqualifyAllDialog] = useState<
    | {
        callback: (value: boolean | Promise<boolean>) => void;
        productsGrouped: GroupByDecisionStatus;
      }
    | undefined
  >(undefined);
  const [isSubmittingDisqualifyDialog, setIsSubmittingDisqualifyDialog] =
    useState(false);

  const [showMaximumShortlisted, setShowMaximumShortlisted] =
    useState<boolean>(false);

  const filterFeeSubProduct = (
    missingInvestmentFilters: FilterValue[] | undefined,
    investmentMenuFilters: FilterValue[] | undefined,
  ) => {
    if (!missingInvestmentFilters && !investmentMenuFilters) {
      return setSubProductData(review?.fee?.all?.subProducts ?? []);
    }

    const investmentMenuFilter = investmentMenuFilters?.[0].value
      ? investmentMenuFilters?.[0].value
      : FeeInvestmentMenuOption.AllPlatform;
    const missingInvestmentFilter = missingInvestmentFilters?.[0].value
      ? missingInvestmentFilters?.[0].value
      : FeeMissingInvestmentsOption.AllPlatform;

    let subProductFiltered: FeeSubProductDTO[] = Object.assign(
      review?.fee?.all?.subProducts ?? [],
    );

    if (investmentMenuFilter !== FeeInvestmentMenuOption.AllPlatform) {
      subProductFiltered = subProductFiltered?.filter(
        (subProduct) => subProduct.investmentMenu === investmentMenuFilter,
      );
    }

    if (missingInvestmentFilter === FeeMissingInvestmentsOption.AllPlatform) {
      return setSubProductData(subProductFiltered ?? []);
    } else {
      return setSubProductData(
        subProductFiltered?.filter((subProduct) => !subProduct.warning) ?? [],
      );
    }
  };

  const handleOnChangeFilter = (
    missingInvestmentFilters: FilterValue[] | undefined,
    investmentMenuFilters: FilterValue[] | undefined,
  ) => {
    setHiddenIds([]);
    if (!missingInvestmentFilters && !investmentMenuFilters) {
      setFilterMissingInvestmentValue(undefined);
      setFilterInvestmentMenuValue(undefined);
      return;
    }

    const investmentMenuFilter = investmentMenuFilters?.[0].value
      ? investmentMenuFilters?.[0].value
      : FeeInvestmentMenuOption.AllPlatform;
    const missingInvestmentFilter = missingInvestmentFilters?.[0].value
      ? missingInvestmentFilters?.[0].value
      : FeeMissingInvestmentsOption.AllPlatform;

    setFilterInvestmentMenuValue(
      investmentMenuFilter !== FeeInvestmentMenuOption.AllPlatform
        ? [{ label: "", value: investmentMenuFilter }]
        : undefined,
    );
    setFilterMissingInvestmentValue(
      missingInvestmentFilter !== FeeMissingInvestmentsOption.AllPlatform
        ? [{ label: "", value: missingInvestmentFilter }]
        : undefined,
    );
  };

  const handleOnChangeDecisionStatus = async (
    platformId: string,
    decisionStatus: ReviewDecisionStatus,
  ) => {
    setUpdatingDecisionStatus({ platformId, decisionStatus });

    const { isSuccess, data } = await updateReviewDecisionMaking({
      reviewId: reviewId,
      reviewStep: ReviewStep.Fee,
      productId: platformId,
      decisionStatus:
        decisionStatus !== ReviewDecisionStatus.Undecided
          ? decisionStatus
          : null,
      comment: null,
    } as UpdateDecisionMarkingDTO);

    setUpdatingDecisionStatus(undefined);

    if (isSuccess) {
      const newProducts = products?.map((platform) => {
        if (platform.id !== platformId) return platform;
        return {
          ...platform,
          decisionStatus: data?.decisionStatus,
        };
      });
      setProducts(newProducts ?? []);
    }
  };

  const handleOnDisqualifyAllPlatform = async () => {
    if (!reviewId) return false;

    setIsSubmittingDisqualifyDialog(true);
    const { isSuccess } = await disqualifyAllPlatform(reviewId, ReviewStep.Fee);
    setIsSubmittingDisqualifyDialog(false);

    disqualifyAllDialog?.callback(isSuccess);
  };

  const handleOnChangeComment = async (comment: string | null) => {
    setUpdatingComment(true);

    const { isSuccess } = await putReviewDecisionMaking({
      reviewId: reviewId,
      reviewStep: ReviewStep.Fee,
      productId: commentDialog?.productId,
      decisionStatus: null,
      comment: comment,
    } as UpdateDecisionMarkingDTO);

    setUpdatingComment(false);
    setCommentDialog(undefined);

    if (isSuccess) {
      const newProducts = products?.map((platform) => {
        if (platform.id !== commentDialog?.productId) return platform;
        return {
          ...platform,
          comment: {
            comment: comment,
          },
        } as SelectedProductDTO;
      });

      setProducts(newProducts ?? []);
    }
  };

  const getDisqualifyAllPlatform = async (
    productsGrouped: GroupByDecisionStatus,
  ): Promise<boolean> => {
    return await new Promise((res) => {
      setDisqualifyAllDialog({
        callback: res,
        productsGrouped,
      });
    });
  };

  const handleOnSubmit = async () => {
    const groupByDecisionStatus: GroupByDecisionStatus = mapValues(
      groupBy(products, "decisionStatus"),
      (group) => group.map((obj) => obj.id),
    );

    const UndecidedPlatformIds =
      groupByDecisionStatus[ReviewDecisionStatus.Undecided];
    const shortedListPlatformIds =
      groupByDecisionStatus[ReviewDecisionStatus.Shortlisted];

    if (!isNil(shortedListPlatformIds)) {
      if (shortedListPlatformIds?.length <= 5) {
        if (!isEmpty(UndecidedPlatformIds)) {
          const isYes = await getDisqualifyAllPlatform(groupByDecisionStatus);
          setDisqualifyAllDialog(undefined);
          return isYes;
        }
        return true;
      } else {
        setShowMaximumShortlisted(true);
        setNotifyUnShortedListDialog(true);
        return false;
      }
    } else {
      setShowMaximumShortlisted(false);
      setNotifyUnShortedListDialog(true);
      return false;
    }
  };

  const loadFeeReview = async (reviewId?: string) => {
    if (!reviewId) return;
    const newData = await loadReview({
      reviewId: reviewId,
      stepId: ReviewStep.Fee,
    });
    setProducts(newData?.fee?.all?.selectedProducts ?? []);
    setSubProductData(newData?.fee?.all?.subProducts ?? []);
    setDatabaseVersion(newData?.databaseVersion ?? new Date());
    setHeatmapMode(newData?.fee?.feeTableViewMode ?? FeeHeatmapMode.Detail);
    setViewMode(newData?.fee?.feesDisplayStyle ?? FeesDisplayStyle.Dollar);
    if (
      newData?.fee?.investmentMenu !== undefined &&
      newData?.fee?.investmentMenu !==
        FeeInvestmentMenuOption.AllPlatform
    ) {
      setFilterInvestmentMenuValue([
        {
          label: "",
          value: newData?.fee?.investmentMenu,
        },
      ]);
    }
    if (
      newData?.fee?.feeMissingInvestmentsStyle !== undefined &&
      newData?.fee?.feeMissingInvestmentsStyle ===
        FeeMissingInvestmentsOption.NotMissing
    ) {
      setFilterMissingInvestmentValue([
        {
          label: "",
          value: FeeMissingInvestmentsOption.NotMissing,
        },
      ]);
    }
    setHiddenIds(
      chain(newData?.fee?.all?.subProducts)
        .filter({ isHidden: true })
        .map("id")
        .value(),
    );
  };

  useEffectOnce(() => {
    setIsShowHidden(false);
  });

  useEffect(() => {
    loadFeeReview(reviewId);
    // eslint-disable-next-line
  }, [reviewId]);

  useEffect(() => {
    filterFeeSubProduct(
      filterMissingInvestmentValue,
      filterInvestmentMenuValue,
    );
    // eslint-disable-next-line
  }, [filterMissingInvestmentValue, filterInvestmentMenuValue]);

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep, isNext) => {
      if (!isReadOnly) {
        await saveFilter();
      }
      if (isNext) {
        const isSuccess = await handleOnSubmit();
        if (!isReadOnly && isSuccess) {
          await updateFinalJSONs(reviewId ?? "");
        }
        return isSuccess;
      }
      setSubProductData([]);
      resetReviewFee();
      return true;
    },
    onChangeOtherStep: async () => {
      if (!isReadOnly) {
        await saveFilter();
      }
      return true;
    },
    onSubmit: async () => {
      if (!isReadOnly) {
        await saveFilter();
      }
      return await handleOnSubmit();
    },
    onExit: async () => {
      if (!isReadOnly) {
        await saveFilter();
      }
      return true;
    },
  }));

  const saveFilter = async () => {
    let data: ReviewFilterDTO = {
      feeMissingInvestmentsStyle: filterMissingInvestmentValue?.[0].value
        ? filterMissingInvestmentValue?.[0].value
        : FeeMissingInvestmentsOption.AllPlatform,
      investmentMenu: filterInvestmentMenuValue?.[0]?.value
        ? filterInvestmentMenuValue?.[0]?.value
        : FeeInvestmentMenuOption.AllPlatform,
      feesDisplayStyle: viewMode,
      feeTableViewMode: heatmapMode,
      hiddenSubProducts: generateHiddenSubProducts(
        hiddenIds,
        review?.fee?.all.subProducts ?? [],
      ),
    };

    await saveReviewFilters(reviewId ?? "", data);
  };

  const generateHiddenSubProducts = (
    hiddenIds: string[],
    subProducts: FeeSubProductDTO[],
  ): HiddenSubProductDto[] => {
    let hiddenSubProducts: HiddenSubProductDto[] = [];
    hiddenIds.forEach((id) => {
      var subProduct = find(subProducts, { id });
      if (subProduct) {
        let subProductId = subProduct.id;
        if (subProduct.isRateCard) {
          subProductId = split(subProduct.id, "|")[0];
        }

        hiddenSubProducts.push({
          id: subProductId,
          isRateCard: subProduct.isRateCard ?? true,
          rateCardVersion: subProduct.isRateCard
            ? subProduct.rateCardVersion ?? 0
            : 0,
        });
      }
    });
    return hiddenSubProducts;
  };

  if (isLoading) return <AnalysisFeeSkeleton />;

  const subProductsDataForExcel = () => {
    return isShowHidden
      ? subProductData
      : subProductData.filter(
          (subProduct) => !hiddenIds.includes(subProduct.id),
        );
  };

  return (
    <SHContainer maxWidth="lg2" sx={{ px: { xs: "16px", lg: 0 }, py: "25px" }}>
      <SHStack spacing={"25px"}>
        <SHStack
          direction={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <StepName name={"Fee review"} stepNumber={stepNumber} />
          <SHStack
            justifyContent={"space-between"}
            pb={"10px"}
            spacing={"25px"}
          >
            <SHTypography
              variant="body3"
              colorVariant="third"
              textAlign={"right"}
              lineHeight="120%"
            >
              {`Data valid: ${format(
                databaseVersion ?? new Date(),
                DateFormat,
              )}`}
            </SHTypography>
            <SHStack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              spacing={"25px"}
            >
              <SHButton
                color="primary"
                variant="text"
                size="extraMedium"
                startIcon={
                  <EyeIcon
                    isActive={true}
                    fill={
                      hiddenIds.length > 0
                        ? palette.primary.main
                        : palette.grey[500]
                    }
                  />
                }
                disabled={hiddenIds.length > 0 ? false : true}
                onClick={() => {
                  setHiddenIds([]);
                  setIsShowHidden(false);
                }}
              >
                Show hidden
              </SHButton>
              {/* {showFilterButton && (
                <FilterRadioButton
                  buttonTitle={"Missing investments"}
                  filterData={PlatformFeeAnalysisFilterOptions}
                  filterValue={filterMissingInvestmentValue}
                  width={"275px"}
                  labelAll={"All platforms"}
                  zIndex={10}
                  onChangeFilter={handleOnChangeFilter}
                />
              )} */}
              <FeeAnalysisFilterRadioButton
                buttonTitle={"Filters"}
                filterInvestmentMenuData={
                  PlatformFeeAnalysisInvestmentMenuFilterOptions
                }
                filterInvestmentMenuValue={filterInvestmentMenuValue}
                filterMissingInvestmentData={
                  PlatformFeeAnalysisMissingInvestmentFilterOptions
                }
                filterMissingInvestmentValue={filterMissingInvestmentValue}
                width={"275px"}
                labelAll={"All platforms"}
                zIndex={10}
                onChangeFilter={handleOnChangeFilter}
              />
              <SHToggleButtonOptions
                options={FeeReviewFilterMapOptions}
                value={heatmapMode}
                onChange={(event, value) => {
                  if (!value) return;
                  setHeatmapMode(value);
                }}
              />
              <SHToggleButtonOptions
                options={FeeAnalysisFilterYesNoOptions}
                value={viewMode}
                onChange={(event, value) => {
                  if (!value) return;
                  setViewMode(value);
                }}
              />
            </SHStack>
          </SHStack>
        </SHStack>
        <SHStack
          direction={{ xs: "column", lg: "row" }}
          justifyContent={{ xs: "stretch", lg: "space-between" }}
          spacing={"25px"}
          alignItems={"flex-start"}
        >
          <SHGrid container width={{ xs: "100%", lg: "250px" }}>
            {products.map((platform, index) => (
              <SHGrid key={platform.id} xs={6} sm={4} md={3} lg={12}>
                <FeeReviewProductCard
                  isShowButtons={!isCompleted && !isReadOnly}
                  platform={platform}
                  sx={getProductCardSx(index)}
                  onChangeDecisionStatus={handleOnChangeDecisionStatus}
                  updatingDecisionStatus={updatingDecisionStatus}
                  onModifyComment={(platformId: string, comment?: string) => {
                    setCommentDialog({
                      comment: comment ?? null,
                      productId: platformId,
                    });
                  }}
                />
              </SHGrid>
            ))}
          </SHGrid>
          <SHStack
            sx={{
              display: subProductData.length ? "flex" : "none",
              flexGrow: 1,
              width: { xs: "100%", lg: "1px" },
            }}
            spacing={"25px"}
          >
            <FeeHeatmap
              subProductsInStore={review?.fee?.all.subProducts ?? []}
              subProducts={subProductData}
              totalPortfolioValue={
                review?.fee?.feePortfolioDetails?.totalPortfolioValue
              }
              mode={viewMode}
              heatmapMode={heatmapMode}
              hiddenIds={hiddenIds}
              onChangeHiddenIds={setHiddenIds}
              isCompleted={isCompleted}
              isShowHidden={isShowHidden}
              titleComp={
                <SHStack
                  direction={"row"}
                  spacing={"5px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <SHTypography variant="subtitle1" fontWeight={700}>
                    Annual platform fees by portfolio value (estimates)
                  </SHTypography>
                  {review?.fee?.all?.subProducts && (
                    <ExportExcelButton
                      mode={viewMode}
                      data={
                        {
                          ...review?.fee?.all,
                          subProducts: subProductsDataForExcel(),
                          databaseVersion: review.databaseVersion ?? null,
                          isShowHoldingsNumber:
                            review?.fee?.all.isShowHoldingsNumber,
                          name: review?.fee.name,
                          practiceName: review?.fee.adviserFirmName,
                          adviserName: review?.fee.ownerName,
                          ownerType: UserType.AdviserAdviser,
                          feePortfolioDetails: review?.fee.feePortfolioDetails,
                          lastModifiedDate: review?.fee.lastModifiedDate,
                          familyGroupName: review?.fee.familyGroupName,
                          serial: review?.fee.serial,
                        } as any
                      }
                    />
                  )}
                </SHStack>
              }
              leftLegend={
                <ChartLegendBox height={"90px"} direction="vertical">
                  Platforms
                </ChartLegendBox>
              }
              bottomLegend={
                <ChartLegendBox>
                  {isShowFeeDetail
                    ? "Fee type"
                    : "Average total portfolio value for the year"}
                </ChartLegendBox>
              }
            />
            <FeeLineChart
              subProducts={subProductData}
              mode={viewMode}
              hiddenIds={hiddenIds}
              onChangeHiddenIds={setHiddenIds}
            />
          </SHStack>
          <SHStack
            sx={{
              display: !subProductData.length ? "flex" : "none",
              flexGrow: 1,
              width: { xs: "100%", lg: "1px" },
              background: palette.common.white,
              border: `1px solid ${palette.secondary[100]}`,
              p: "28px",
              height: 400,
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <SHTypography variant="subtitle1" colorVariant="disabled">
              No data
            </SHTypography>
            <SHTypography variant="body2" colorVariant="disabled">
              No products match the criteria in your filter
            </SHTypography>
          </SHStack>
        </SHStack>
      </SHStack>
      {commentDialog && (
        <ChangeRichTextDialog
          onSubmit={handleOnChangeComment}
          title={
            isEmpty(commentDialog.comment) ? "Add comment" : "Edit comment"
          }
          placeHolder={"Enter your comment"}
          initialContent={commentDialog.comment ?? null}
          isSubmitting={updatingComment}
          onClose={() => {
            setCommentDialog(undefined);
          }}
          maxLength={1500}
          isCommentBox
        />
      )}
      {notifyUnShortedListDialog && (
        <NotifyUnShortedListDialog
          onOK={() => setNotifyUnShortedListDialog(false)}
          step={ReviewStep.Fee}
          showMaximumShortlisted={showMaximumShortlisted}
        />
      )}

      {disqualifyAllDialog && (
        <DisqualifyAllPlatformDialog
          productsGrouped={disqualifyAllDialog.productsGrouped}
          onCancel={() => disqualifyAllDialog.callback(false)}
          onSubmit={handleOnDisqualifyAllPlatform}
          isSubmitting={isSubmittingDisqualifyDialog}
        />
      )}
    </SHContainer>
  );
};
