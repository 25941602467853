import { APIExtRoutes } from "@constants/routes";
import { APIResponse, S3ObjectDTO } from "@models/core";
import { APIGatewayService, handleErrorProxy } from "@services/shService";

export async function uploadAvatar(
  file?: FormData,
): Promise<APIResponse<S3ObjectDTO>> {
  const response = await APIGatewayService.post<S3ObjectDTO>(
    APIExtRoutes.uploadPublic,
    file,
  );

  return handleErrorProxy(response);
}

export async function deleteAvatar(
  s3Key?: string,
): Promise<APIResponse<APIResponse>> {
  const response = await APIGatewayService.delete<APIResponse>(
    `${APIExtRoutes.deletePublic}/${s3Key}`,
  );

  return handleErrorProxy(response);
}
