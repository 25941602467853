import { SHStack } from "@components/design-systems";
import {
  NewsFeedProductDTO,
  PostDTO,
} from "@models/news-and-updates/entities/post";
import { RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { PostItem } from "../post-item";

interface ViewPostsLayoutProps {
  footer?: React.ReactNode;
  isLoading?: boolean;
  productData: NewsFeedProductDTO[];
}

export const PostList = ({ footer, productData }: ViewPostsLayoutProps) => {
  const { postData } = useSelector((state: RootState) => state.newsFeed);

  return (
    <SHStack sx={{ paddingBottom: 3 }} width={"100%"}>
      <SHStack spacing={2} mb={3}>
        {postData?.map((post: PostDTO, index: number) => {
          return (
            post && (
              <PostItem
                key={index}
                postData={post}
                productData={productData}
                index={index}
              />
            )
          );
        })}
      </SHStack>
      <SHStack alignItems={"center"} justifyContent={"center"}>
        {footer}
      </SHStack>
    </SHStack>
  );
};
