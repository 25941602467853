import { PageMode, ParentState } from "@models/core";
import { useLocation, useNavigate } from "react-router";
import { useIsNew } from "./useIsNew";

export const useParentState = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isNew = useIsNew();
  const { notification, pageMode } = (location.state as ParentState) || {
    notification: null,
    pageMode: PageMode.View,
    isEditMode: false,
  };
  const setEditMode = (isEdit: boolean) => {
    navigate(`${location.pathname}${location.search}`, {
      replace: true,
      state: {
        notification,
        pageMode: isEdit ? PageMode.Edit : PageMode.View,
      },
    });
  };

  return {
    notification,
    pageMode,
    isEditMode: pageMode === PageMode.Edit || isNew,
    setEditMode,
  };
};
