import { FeatureTemplateDTO } from "@models/feature-template/entities/featureTemplate";
import { sortAscByOrder } from "@utils";

export const compileFeatureTemplate = (
  featureTemplate: FeatureTemplateDTO,
): FeatureTemplateDTO => {
  if (featureTemplate.sections) {
    featureTemplate.sections = sortAscByOrder(featureTemplate.sections);
    if (!featureTemplate.sections?.find((section) => section?.isSelected)) {
      featureTemplate.sections[0] = {
        ...featureTemplate.sections[0],
        isSelected: true,
      };
    }
    featureTemplate.sections.forEach((section) => {
      //Sort Sub Section
      if (section.subSections) {
        section.subSections = sortAscByOrder(section.subSections) as [];
        //Sort feature of sub sections
        section.subSections?.forEach((subSection) => {
          if (subSection.features)
            subSection.features = sortAscByOrder(subSection.features);
        });
      }
    });
  }

  return featureTemplate;
};
