export const exportDateTime = {
  t: "d",
  z: "hh:mm DD/MM/YY",
  s: { alignment: { horizontal: "left" } },
};
export const exportDate = {
  t: "d",
  z: "DD/MM/YY",
  s: { alignment: { horizontal: "left" } },
};

export const exportCurrency = {
  t: "n",
  z: "$#,##0;[Red]-$#,##0",
};
export const exportPercentage = {
  t: "n",
  z: "0.00%",
};
export const fillBlue = {
  patternType: "solid",
  fgColor: {
    rgb: "D6DDEB",
  },
};
export const fillGray = {
  patternType: "solid",
  fgColor: {
    rgb: "E7E6E6",
  },
};
export const DefaultCellHeight = 15;
export const CharactersInARow = 120;
