import { SelectRegions } from "@components/auto-completes/regions";
import { SHStack, SHTypography } from "@components/design-systems";
import { UserInformationCard } from "@components/user-information-card";
import { useNotification } from "@hooks/useNotification";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { ProductBDMUserDTO } from "@models/product/entities/product";
import { RegionDTO } from "@models/public/region";
import { FindBDMTabSkeleton } from "@pages/suppliers/_id/_products/_id/edit/components/skeletons/FindBDMTabSkeleton";
import { RootState } from "@redux/store";
import { getProductBDMUsers } from "@services/product/productsService";
import { getRegions } from "@services/public/region";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";

export const FindBDMTab = () => {
  const { productId } = useParams<{ productId: string }>();
  const { notify } = useNotification();
  const {
    productUI: { productName },
  } = useSelector((state: RootState) => state.product);

  const [isLoading, setIsLoading] = useState(false);
  const [regionIds, setRegionIds] = useState<string[]>([]);
  const [bdmUserData, setBDMUserData] = useState<ProductBDMUserDTO[]>([]);
  const [regionData, setRegionData] = useState<RegionDTO[]>([]);

  const loadRegions = async () => {
    const { data, message } = await getRegions();
    if (data) {
      setRegionData(data);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const loadBDMUsers = async (ids?: string[]) => {
    if (!productId) return;
    setIsLoading(true);
    const { data, message } = await getProductBDMUsers(productId, ids ?? []);
    setIsLoading(false);
    if (data) {
      setBDMUserData(data);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  useEffectOnce(() => {
    loadRegions();
  });

  useEffect(() => {
    if (!isEmpty(regionIds)) return;
    loadBDMUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionIds?.length]);

  return (
    <SHStack>
      <TopBarContainer>
        <TopBar
          title={!isEmpty(productName) ? productName : "Product details"}
        />
      </TopBarContainer>
      {isEmpty(bdmUserData) && !regionIds ? (
        <SHStack
          width={"100%"}
          alignItems="center"
          justifyContent={"center"}
          marginTop={2}
        >
          <SHTypography variant="body4">Data not found!</SHTypography>
        </SHStack>
      ) : isLoading ? (
        <FindBDMTabSkeleton />
      ) : (
        <SHStack spacing={2} marginY={2}>
          {!isEmpty(regionData) && (
            <SHStack width={"30%"}>
              <SelectRegions
                regionData={regionData}
                value={regionIds}
                onChange={(value) => {
                  setRegionIds(value);
                }}
                textFieldProps={{
                  placeholder: "Filter by region",
                }}
                onClose={() => loadBDMUsers(regionIds)}
              />
            </SHStack>
          )}

          {/* TODO:Improvement gird UI */}
          <SHStack
            gap={"10px"}
            sx={{ flexWrap: "wrap", width: "100%" }}
            direction={"row"}
          >
            {!isEmpty(bdmUserData) ? (
              bdmUserData?.map((userInfo, index) => (
                <UserInformationCard key={index} userInfo={userInfo} />
              ))
            ) : (
              <SHStack
                width={"100%"}
                alignItems="center"
                justifyContent={"center"}
              >
                <SHTypography variant="body4">
                  No results match your criteria.
                </SHTypography>
              </SHStack>
            )}
          </SHStack>
        </SHStack>
      )}
    </SHStack>
  );
};
