import { SHButton, SHButtonProps } from "@components/design-systems";
import { useTheme } from "@mui/material";

export const ClearButton = ({ sx, ...others }: SHButtonProps) => {
  const { palette } = useTheme();
  return (
    <SHButton
      {...others}
      variant="outlined"
      size="small"
      sx={{
        "&:hover": {
          backgroundColor: palette.error.light,
          borderColor: palette.error.dark,
          boxShadow: "none",
          color: palette.common.white,
        },
        "&:active": {
          backgroundColor: palette.error.light,
          borderColor: palette.error.dark,
          boxShadow: `inset 1.5px 1.5px 1.5px ${palette.error.dark}`,
          color: palette.common.white,
        },
        "&:disabled": {
          border: `1px solid ${palette.text.disabled}`,
          backgroundColor: palette.grey[400],
          color: palette.common.white,
        },
        ...sx,
      }}
    >
      Clear
    </SHButton>
  );
};
