import { SHSnackbar } from "@components/design-systems/sh-snackbar";
import { AlertColor } from "@mui/material";
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from "notistack";

export interface SHSnackbarOptions extends OptionsObject {
  close?: boolean;
  variant?: AlertColor;
  width?: string | number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notify = (
    message: SnackbarMessage,
    optionsSnackbar?: SHSnackbarOptions,
  ): SnackbarKey => {
    const { close = true, variant, width, ...others } = optionsSnackbar ?? {};
    return enqueueSnackbar(message, {
      ...others,
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      content: (key: string | number, message: SnackbarMessage) => (
        <SHSnackbar
          id={key}
          message={message}
          close={close}
          variant={variant}
          width={width}
        />
      ),
    });
  };

  const closeNotify = (key: SnackbarKey) => {
    closeSnackbar(key);
  };

  return { notify, closeNotify };
};
