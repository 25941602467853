import {
  TopNotificationGroup,
  TopNotificationItem,
} from "@components/top-notifications/model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { generateUUID } from "@utils/common";

interface TopNotificationStore {
  globalNotifications: TopNotificationItem[];
  configurationNotifications: TopNotificationItem[];
  platformProfileNotifications: TopNotificationItem[];
  adviserUserNotifications: TopNotificationItem[];
}
const initialState: TopNotificationStore = {
  globalNotifications: [],
  configurationNotifications: [],
  platformProfileNotifications: [],
  adviserUserNotifications: [],
};

const topNotificationSlice = createSlice({
  name: "topNotification",
  initialState,
  reducers: {
    //Global
    updateGlobalNotifications: (
      state,
      action: PayloadAction<TopNotificationItem[]>,
    ) => ({
      ...state,
      globalNotifications: action.payload.map((item: TopNotificationItem) => {
        return {
          ...item,
          id: generateUUID(),
          group: TopNotificationGroup.Global,
        };
      }),
    }),
    removeGlobalNotification: (
      state,
      action: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      globalNotifications: state.globalNotifications?.filter(
        (item: TopNotificationItem) => item.id !== action.payload,
      ),
    }),
    //Configuration
    updateConfigurationNotifications: (
      state,
      action: PayloadAction<TopNotificationItem[]>,
    ) => ({
      ...state,
      configurationNotifications: action.payload.map(
        (item: TopNotificationItem) => {
          return {
            ...item,
            id: generateUUID(),
            group: TopNotificationGroup.Configuration,
          };
        },
      ),
    }),
    removeConfigurationNotification: (
      state,
      action: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      configurationNotifications: state.configurationNotifications?.filter(
        (item: TopNotificationItem) => item.id !== action.payload,
      ),
    }),
    // PlatformProfile
    updatePlatformProfileNotifications: (
      state,
      action: PayloadAction<TopNotificationItem[]>,
    ) => ({
      ...state,
      platformProfileNotifications: action.payload.map(
        (item: TopNotificationItem) => {
          return {
            ...item,
            id: generateUUID(),
            group: TopNotificationGroup.PlatformProfile,
          };
        },
      ),
    }),
    removePlatformProfileNotification: (
      state,
      action: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      platformProfileNotifications: state.platformProfileNotifications?.filter(
        (item: TopNotificationItem) => item.id !== action.payload,
      ),
    }),

    // AdviserUser
    updateSubscriptionNotifications: (
      state,
      action: PayloadAction<TopNotificationItem[]>,
    ) => ({
      ...state,
      adviserUserNotifications: action.payload.map(
        (item: TopNotificationItem) => {
          return {
            ...item,
            group: TopNotificationGroup.AdviserUserSubscription,
          };
        },
      ),
    }),
    removeSubscriptionNotifications: (
      state,
      action: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      adviserUserNotifications: state.adviserUserNotifications?.filter(
        (item: TopNotificationItem) => item.id !== action.payload,
      ),
    }),
  },
});

export const {
  updateGlobalNotifications,
  removeGlobalNotification,
  updateConfigurationNotifications,
  removeConfigurationNotification,
  updatePlatformProfileNotifications,
  removePlatformProfileNotification,
  updateSubscriptionNotifications,
  removeSubscriptionNotifications,
} = topNotificationSlice.actions;
export default topNotificationSlice.reducer;
