import { DuplicateButton } from "@components/buttons/duplicate";
import { GrayDeleteButton } from "@components/buttons/gray-delete";
import { PlusButton } from "@components/buttons/plus";
import { RollBackButton } from "@components/buttons/rollback";
import {
  SHAvatar,
  SHBox,
  SHButton,
  SHDataGrid,
  SHDataGridRef,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { APIExtRoutes, PageRoutes } from "@constants";
import { DateFormat, DateTimeFormat } from "@constants/format";
import { useNotification } from "@hooks/useNotification";
import { useReview } from "@hooks/useReview";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { PageMode, ParentState } from "@models/core";
import { ReviewsODataDTO } from "@models/reviews/entities/review";
import { ReviewStatus } from "@models/reviews/enums/status";
import { Link, useTheme } from "@mui/material";
import { TextOverflowEllipsis } from "@pages/platform-analysis/components/text-overflow-ellipsis";
import { PlatformAnalysisFilterOptions } from "@pages/platform-analysis/constant";
import { RootState } from "@redux/store";
import { TableState, createColumnHelper } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { format } from "date-fns";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";

const nameOf = nameOfFactory<ReviewsODataDTO>();
const columnHelper = createColumnHelper<ReviewsODataDTO>();
const pickColumns: (keyof ReviewsODataDTO)[] = [
  "id",
  "name",
  "familyGroupName",
  "adviserUserName",
  "adviserUserAvatarUrl",
  "dataValidDate",
  "lastModifiedDate",
  "status",
];

export const ReviewList = () => {
  const { palette } = useTheme();
  const { notify, closeNotify } = useNotification();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const ref = useRef<SHDataGridRef | null>(null);

  const { isAdviserTrialGroup } = useUserPermissions();

  const [gridState, setGridState] = useState<Partial<TableState>>({
    ...DefaultDataState,
    sorting: [{ id: nameOf("lastModifiedDate"), desc: true }],
    columnFilters: [
      {
        id: nameOf("status"),
        value: [ReviewStatus.InProgress, ReviewStatus.Completed],
      },
    ],
  });

  const [updatingStatusIds, setUpdatingStatusIds] = useState<string[]>([]);
  const [duplicatingIds, setDuplicatingIds] = useState<string[]>([]);
  const { duplicateReview, updateReviewStatus } = useReview();

  const TrialNotify = (type: "new" | "edit" | "duplicate") => {
    const actionTextMapping = {
      new: "to create Suitability Reviews",
      edit: "to edit Suitability Reviews",
      duplicate: "to duplicate Suitability Reviews",
    };

    return notify(
      <SHTypography color={palette.text.white}>
        Upgrade your subscription
        <Link
          variant="body2"
          component="a"
          underline="always"
          color={palette.text.white}
          onClick={() => {
            navigate(
              `${generatePath(PageRoutes.practiceOverview, {
                practiceId: user?.userMetadata?.adviser_firm_id,
              })}/subscription`,
            );
            closeNotify(type);
          }}
          sx={{
            paddingX: 0.5,
            cursor: "pointer",
            textDecorationColor: palette.text.white,
          }}
        >
          here
        </Link>
        {actionTextMapping[type]}
      </SHTypography>,
      {
        variant: "error",
        close: true,
        width: 580,
        key: type,
      },
    );
  };

  const handelOnChangeStatus = async (
    reviewId: string,
    status: ReviewStatus,
  ) => {
    setUpdatingStatusIds((ids) => [...ids, reviewId]);
    await updateReviewStatus(reviewId, status);
    setUpdatingStatusIds((ids) => ids.filter((id) => id !== reviewId));
    ref.current?.refreshOdata();
  };

  const handleOnDuplicate = async (reviewId: string) => {
    if (isAdviserTrialGroup) {
      TrialNotify("duplicate");
      return;
    }

    setDuplicatingIds((ids) => [...ids, reviewId]);
    await duplicateReview(reviewId);
    setDuplicatingIds((ids) => ids.filter((id) => id !== reviewId));
    ref.current?.refreshOdata();
  };

  const columns = useMemo(
    () => {
      const cols = [
        columnHelper.accessor("name", {
          header: "Name of review",
          cell: (props) => (
            <TextOverflowEllipsis
              fontWeight="bold"
              value={props.cell.getValue()}
            />
          ),
          enableColumnFilter: false,
          meta: {
            sx: {
              minWidth: "200px",
              width: "calc((100% - 592px)*0.6)",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor("familyGroupName", {
          id: nameOf("familyGroupName"),
          header: "Family group",
          cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
          enableColumnFilter: false,
          meta: {
            sx: {
              minWidth: "155px",
              width: "calc((100% - 592px)*0.3)",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          header: "Adviser",
          id: nameOf("adviserUserName"),
          cell: (props) => {
            return (
              <SHStack direction={"row"} alignItems={"center"}>
                <SHAvatar
                  width={"24px"}
                  height={"24px"}
                  src={props.cell.getValue().adviserUserAvatarUrl}
                />
                <TextOverflowEllipsis
                  value={props.cell.getValue().adviserUserName}
                  sx={{
                    marginLeft: "5px",
                    minWidth: "100%",
                  }}
                />
              </SHStack>
            );
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              minWidth: "180px",
              width: "calc((100% - 592px)*0.3)",
              padding: "0 16px !important",
            },
          },
        }),

        columnHelper.accessor("dataValidDate", {
          header: "Data valid",
          cell: (props) => {
            const date = props.cell.getValue();
            return date ? format(new Date(date), DateFormat) : "";
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "140px",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor("lastModifiedDate", {
          header: "Last edit",
          cell: (props) => {
            const date = props.cell.getValue();
            return date ? format(new Date(date), `${DateTimeFormat}`) : "";
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "140px",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor("status", {
          header: "Status",
          cell: (props) => (
            <StatusBadge
              style={{ width: "101px", fontSize: "13px" }}
              status={props.cell.getValue() as ReviewStatus}
            />
          ),
          meta: {
            filterData: PlatformAnalysisFilterOptions,
            sx: {
              width: "120px",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          id: "actionCol",
          header: "Action",
          enableColumnFilter: false,
          enableSorting: false,
          cell: (props) => (
            <SHStack
              direction="row"
              justifyContent={"space-between"}
              spacing={0.5}
            >
              {props.cell.getValue().status === ReviewStatus.InProgress ? (
                <SHButton
                  sx={{ width: "50%", fontSize: "13px" }}
                  startIcon={<EditSVG color={palette.common.white} />}
                  variant="contained"
                  disabled={false}
                  onClick={() => {
                    isAdviserTrialGroup
                      ? TrialNotify("edit")
                      : navigate(
                          generatePath(PageRoutes.reviewsDetail, {
                            reviewId: props.cell.getValue().id,
                          }),
                          { state: { pageMode: PageMode.Edit } as ParentState },
                        );
                  }}
                >
                  Edit
                </SHButton>
              ) : (
                <SHButton
                  sx={{ width: "50%" }}
                  variant="contained"
                  disabled={false}
                  onClick={() => {
                    navigate(
                      generatePath(PageRoutes.reviewsDetail, {
                        reviewId: props.cell.getValue().id,
                      }),
                      { state: { pageMode: PageMode.View } as ParentState },
                    );
                  }}
                >
                  View
                </SHButton>
              )}
              <DuplicateButton
                tooltipTitle={"Duplicate"}
                disabled={duplicatingIds.includes(props.cell.getValue().id)}
                isLoading={duplicatingIds.includes(props.cell.getValue().id)}
                onClick={() => {
                  handleOnDuplicate(props.cell.getValue().id);
                }}
              />
              {props.cell.getValue().status === ReviewStatus.Archived ? (
                <RollBackButton
                  disabled={updatingStatusIds.includes(
                    props.cell.getValue().id,
                  )}
                  isLoading={updatingStatusIds.includes(
                    props.cell.getValue().id,
                  )}
                  onClick={() =>
                    handelOnChangeStatus(
                      props.cell.getValue().id,
                      ReviewStatus.Restore,
                    )
                  }
                />
              ) : (
                <GrayDeleteButton
                  tooltipTitle={"Archive"}
                  disabled={updatingStatusIds.includes(
                    props.cell.getValue().id,
                  )}
                  isLoading={updatingStatusIds.includes(
                    props.cell.getValue().id,
                  )}
                  onClick={() =>
                    handelOnChangeStatus(
                      props.cell.getValue().id,
                      ReviewStatus.Archived,
                    )
                  }
                />
              )}
            </SHStack>
          ),
          meta: {
            sx: {
              width: "192px",
            },
          },
        }),
      ];
      return cols;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [duplicatingIds],
  );

  return (
    <>
      <TopBarContainer>
        <TopBar
          title={"Suitability Reviews"}
          tools={
            <>
              {
                <PlusButton
                  variant="contained"
                  size="extraMedium"
                  onClick={() =>
                    isAdviserTrialGroup
                      ? TrialNotify("new")
                      : navigate(
                          generatePath(PageRoutes.reviewsDetail, {
                            reviewId: "new",
                          }),
                        )
                  }
                >
                  New Suitability Review
                </PlusButton>
              }
            </>
          }
        />
      </TopBarContainer>
      <SHBox sx={{ marginTop: "25px" }}>
        <SHDataGrid
          ref={ref}
          odata={{
            url: APIExtRoutes.odataReviews,
            pickColumns: pickColumns,
            globalFilterColumns: [
              nameOf("name"),
              nameOf("familyGroupName"),
              nameOf("adviserUserName"),
            ],
          }}
          state={gridState}
          onChangeState={(state) => setGridState(state)}
          emptyMessage={"No suitability reviews."}
          columns={columns as any}
          searchBoxProps={{
            placeholder: "Search advisers, family groups and reviews",
            width: "355px",
          }}
        />
      </SHBox>
    </>
  );
};
