import { InformationButton } from "@components/buttons/information";
import {
  SHAutocomplete,
  SHBox,
  SHCheckbox,
  SHIconLoading,
  SHStack,
  SHTextFieldProps,
  SHTypography,
} from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { RateCardLOV } from "@models/rate-card/entities/rateCard";
import { SelectRateCard } from "@models/rate-card/entities/selectRateCard";
import { useTheme } from "@mui/material";
import { getPlatformAnalysisRateCards } from "@services/platform-analysis/platformAnalysisService";
import axios, { CancelTokenSource } from "axios";
import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { DateTimeFormat } from "@constants/format";
export interface SelectRateCardsProps {
  disabled?: boolean;
  value: SelectRateCard[]; //Selected rate cards Id
  maxLength?: number;
  analysisId?: string;
  productIds?: string[];
  configurationVersion?: number;
  textFieldProps?: Omit<SHTextFieldProps, "value">;
  onChange: (value: SelectRateCard[] | null) => void;
}
export const SelectRateCards = ({
  disabled,
  value = [],
  onChange,
  textFieldProps,
  maxLength,
  analysisId,
  configurationVersion,
  productIds = [],
}: SelectRateCardsProps) => {
  const { notify } = useNotification();
  const { palette } = useTheme();
  const [rateCards, setRateCards] = useState<RateCardLOV[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const selectedRateCards = rateCards.filter((option) =>
    value.some(
      (item) =>
        item.id === option.id && item.version === option.rateCardVersion,
    ),
  );
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    if (!productIds.length) {
      onChange([]);
      return;
    }
    getRateCards(analysisId ?? "new", productIds);
    // eslint-disable-next-line
  }, [productIds, analysisId]);

  const filterSelection = (newList: RateCardLOV[]) => {
    let newValues: SelectRateCard[] = [];
    if (Array.isArray(value)) {
      newList.forEach((item) => {
        if (
          value.some(
            (v) => v.id === item.id && v.version === item.rateCardVersion,
          )
        ) {
          newValues.push({ id: item.id, version: item.rateCardVersion });
        }
      });
      if (newValues.length !== value.length) {
        onChange(newValues);
      }
    }
  };

  const getRateCards = async (analysisId: string, productIds: string[]) => {
    if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    cancelTokenSource.current = axios.CancelToken.source();
    setIsLoading(true);
    const { isSuccess, isCancel, data, message } =
      await getPlatformAnalysisRateCards(
        analysisId,
        productIds,
        cancelTokenSource.current.token,
        configurationVersion,
      );

    if (isCancel) return;
    setIsLoading(false);
    if (isSuccess) {
      filterSelection(data ?? []);
      setRateCards(data ?? []);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  return (
    <SHStack spacing={"16px"} direction="column">
      <SHAutocomplete
        multiple
        value={selectedRateCards || null}
        disabled={disabled}
        options={isLoading ? [] : rateCards}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) =>
          option.id === value?.id &&
          option.rateCardVersion === value.rateCardVersion
        }
        getOptionLabel={(option: RateCardLOV) =>
          `${option.productName} | ${option.name}${
            option.isLatest ? "" : " (Outdated)"
          } | Owner: ${option.ownerName}`
        }
        textFieldProps={{
          ...textFieldProps,
          InputLabelProps: { shrink: true },
          placeholder: value?.length ? undefined : textFieldProps?.placeholder,
          InputProps: {
            startAdornment: isLoading ? (
              <SHIconLoading />
            ) : selectedRateCards?.length ? (
              <SHTypography
                disabled={disabled}
                variant="body1"
                sx={{ paddingLeft: "10px" }}
              >
                {`${selectedRateCards?.length}${
                  maxLength !== undefined ? `/${maxLength}` : ""
                } selected`}
              </SHTypography>
            ) : undefined,
          },
        }}
        onChange={(e, newValue, r, details) => {
          if (!Array.isArray(newValue)) {
            onChange([]);
            return;
          }
          if (maxLength && newValue?.length > maxLength) return;
          onChange(
            newValue.map((option) => ({
              id: option.id,
              version: option.rateCardVersion,
            })),
          );
        }}
        renderOption={(props, option, { selected }) => {
          const isChecked = selected;
          return (
            <li {...props} key={option.id} style={{ padding: "1px 10px" }}>
              <SHStack alignItems={"center"} direction="row" spacing={1}>
                <SHCheckbox checked={isChecked} />
                <SHTypography variant="body3" fontWeight={500}>
                  {`${option.productName} | ${option.name}${
                    !option.isLatest && option.outdatedAt
                      ? ` (Outdated: ${format(
                          option.outdatedAt,
                          DateTimeFormat,
                        )})`
                      : ""
                  } | Owner: ${option.ownerName}`}
                </SHTypography>
                {option.note && <InformationButton content={option.note} />}
              </SHStack>
            </li>
          );
        }}
        noOptionsText={isLoading ? "Getting rate cards" : "No rate cards"}
      />
      {
        <SHBox
          sx={{
            height: "100px",
            px: "12px",
            py: "10px",
            border: `1px solid ${palette.divider}`,
            overflowY: "auto",
          }}
          p={"12px"}
        >
          <SHTypography variant="body2">
            {selectedRateCards
              ?.map((option) => `${option.productName} | ${option.name}${
                !option.isLatest && option.outdatedAt
                  ? ` (Outdated: ${format(
                      option.outdatedAt,
                      DateTimeFormat,
                    )})`
                  : ""
              } | Owner: ${option.ownerName}`)
              ?.join(", ")}
          </SHTypography>
        </SHBox>
      }
    </SHStack>
  );
};
