import { analysisCommentsKeyMapping } from "@models/platform-analysis/entities/comment";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { PDFTable } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables";
import { PDFTableBody } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-body";

import { analysisLabel } from "@pages/platform-analysis/_id/config";
import { parseCommentProductLogo } from "@pages/platform-analysis/_id/steps/summary/components/tables/comments/util";
import { PDFCommentRichTextEditor } from "@pages/platform-analysis/components/buttons/export-pdf/components/rich-text-editor";
import { PDFDefaultPlatformLogoS3 } from "@pages/platform-analysis/components/buttons/export-pdf/config";
import {
  COMMENT_TABLE_HEADER_HEIGHT,
  DEFAULT_GAP_3,
  TABLE_WIDTH,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { generatePDFImageIconUrl } from "@pages/platform-analysis/components/buttons/export-pdf/util";
import { PDFReviewTableCell } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-cell";
import { PDFReviewTableRow } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-row";
import { Image, Text, View } from "@react-pdf/renderer";
import { PDFAnalysisComment } from "../../../model";
import { PDFTitleSection } from "../../title-section";

export interface PDFAnalysisCommentSectionProps {
  comments: PDFAnalysisComment[];
  currentSteps: AnalysisStep[];
  isContinued?: boolean;
}

export const PDFAnalysisCommentSection = ({
  comments,
  currentSteps,
  isContinued,
}: PDFAnalysisCommentSectionProps) => {
  const { typographyStyle } = useStyles();

  const sizes = {
    firstCellWidth: 0.116 * TABLE_WIDTH,
    cellWidth: (0.884 / currentSteps?.length) * TABLE_WIDTH,
  };

  return (
    <>
      <PDFTitleSection
        title={`Platform specific comments${isContinued ? " (continued)" : ""}`}
      />
      <View
        style={{
          gap: DEFAULT_GAP_3,
          justifyContent: "flex-start",
        }}
      >
        <PDFTable tableWidth={TABLE_WIDTH}>
          <PDFReviewTableRow
            borderBottomWidth={"1px"}
            height={COMMENT_TABLE_HEADER_HEIGHT}
          >
            <PDFReviewTableCell width={sizes?.firstCellWidth}>
              <Text
                style={{
                  ...typographyStyle?.body2,
                  fontWeight: "bold",
                }}
              >
                Platform
              </Text>
            </PDFReviewTableCell>
            {currentSteps?.map((step) => (
              <PDFReviewTableCell key={step} width={sizes.cellWidth}>
                <Text style={{ ...typographyStyle?.body2, fontWeight: "bold" }}>
                  {analysisLabel[step]}
                </Text>
              </PDFReviewTableCell>
            ))}
          </PDFReviewTableRow>
          <PDFTableBody>
            {comments?.map((row, index) => (
              <PDFReviewTableRow
                key={row.productId}
                color={"white"}
                // height={row.height}
              >
                <PDFReviewTableCell
                  width={sizes?.firstCellWidth}
                  alignItems="center"
                >
                  <View
                    style={{
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {row?.productAvatar ? (
                      <Image
                        src={generatePDFImageIconUrl(
                          parseCommentProductLogo(row?.productAvatar),
                        )}
                        style={{ width: 20, height: 20 }}
                      />
                    ) : (
                      <Image
                        src={PDFDefaultPlatformLogoS3}
                        style={{ width: 20, height: 20 }}
                      />
                    )}

                    <Text
                      style={{
                        ...typographyStyle?.body2,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {row?.productName}
                    </Text>
                  </View>
                </PDFReviewTableCell>
                {currentSteps.map((step) => {
                  const stepHasComment = (row as any)[
                    analysisCommentsKeyMapping[step]
                  ];

                  return (
                    <PDFReviewTableCell
                      key={step}
                      width={sizes.cellWidth}
                      alignItems={stepHasComment ? "flex-start" : "center"}
                      justifyContent={stepHasComment ? "flex-start" : "center"}
                    >
                      <Text
                        style={{
                          ...typographyStyle?.body2,
                        }}
                      >
                        {stepHasComment ? (
                          <PDFCommentRichTextEditor
                            htmlString={stepHasComment}
                          />
                        ) : (
                          "No comment"
                        )}
                      </Text>
                    </PDFReviewTableCell>
                  );
                })}
              </PDFReviewTableRow>
            ))}
          </PDFTableBody>
        </PDFTable>
      </View>
    </>
  );
};
