export const getYAxisTicks = (highestFee: number): number[] => {
  // if the highest fee is 0 or +/-Infinity, return an array containing only 0
  if (highestFee === 0 || highestFee === -Infinity || highestFee === Infinity)
    return [0];
  // calculate the tick interval by dividing the highest fee by 7
  // (instead of 6, as there are 8 ticks)
  const tickInterval = highestFee / 7;
  // calculate the tick value using the `getTickNumber` function
  const tickValue = getTickNumber(tickInterval);
  // initialize the ticks array with 0 as the first tick
  let ticks: number[] = [];
  ticks.push(0);
  let i = 0;
  // generate ticks based on the tick value
  if (tickInterval > 0) {
    do {
      i = i + tickValue;
      ticks.push(i);
    } while (i < highestFee);
  } else {
    do {
      i = i - tickValue;
      ticks.unshift(i);
    } while (i >= highestFee);
  }
  // return the array of ticks
  return ticks;
};

const getTickNumber = (num: number): number => {
  // Convert number to string
  const numStr = num.toString();
  // Split string into integer and fractional parts
  const [intPart, fracPart] = numStr.split(".");
  // Calculate the number of digits in the integer part
  const int = Math.abs(parseInt(intPart, 10));
  // Calculate a tick number suitable for that magnitude
  if (int !== 0) {
    const tickNumber = Math.ceil(
      5 / (100 / Math.pow(10, int.toString().length)),
    );
    return Math.ceil(Math.abs(num) / tickNumber) * tickNumber;
  }
  // If the integer part is 0, count the number of zeros after the decimal point
  let zeroCount = 0;
  for (let i = 0; i < fracPart.length; i++) {
    if (fracPart[i] === "0") {
      zeroCount++;
    } else {
      break;
    }
  }
  // Calculate a temporary divisor based on the number of zeros
  const tempDiv = Math.pow(10, zeroCount + 1);
  // Recursively calculate a tick number using the temporary divisor
  return getTickNumber(num * tempDiv) / tempDiv;
};
