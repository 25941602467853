import { SHDialog, SHStack, SHTypography } from "@components/design-systems";
import { email } from "@constants/email";
import { Link } from "@mui/material";
import { theme } from "@themes";
import {
  tranferAuth0Error,
  tranferAuth0ErrorDescription,
} from "@utils/auth0/util";
import { Navigate, useSearchParams } from "react-router-dom";

interface Auth0ErrorDialogProps {
  open: boolean;
}
export const Auth0ErrorDialog = ({
  open,
}: Auth0ErrorDialogProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  if (searchParams.get("success") === "true")
    return <Navigate to="/" replace />;

  const error = tranferAuth0Error(searchParams.get("error"));
  const errorDescription = tranferAuth0ErrorDescription(searchParams.get("error_description")) ||
  tranferAuth0ErrorDescription(searchParams.get("message"));
  const welcome = searchParams.get("welcome");

  return (
    <SHDialog
      maxWidth="sm"
      width={560}
      open={open}
      header={
        <SHStack
          alignItems="center"
          textAlign={"center"}
          width={{ md: "50%", xs: "100%" }}
        >
          <SHTypography
            variant="h2"
            sx={{
              overflowWrap: "break-word",
              width: "100%",
            }}
          >
            {error ?? errorDescription ?? "Something went wrong"}
          </SHTypography>
        </SHStack>
      }
      body={
        <SHStack
          alignItems="center"
          spacing={3}
          textAlign={"center"}
          sx={{ width: "100%" }}
        >
          {errorDescription && error && (
            <SHTypography
              variant="body2"
              sx={{
                overflowWrap: "break-word",
                width: "100%",
              }}
            >
              {errorDescription}
            </SHTypography>
          )}
          <SHTypography variant="body2">
            { welcome ?
              "To receive a new link, please contact the Owner of your firm’s account. Otherwise, please contact SuitabilityHub" :
              "To receive a new link, please reset your password again. Otherwise, please contact SuitabilityHub"
            }
            <Link
              variant="body2"
              color={theme.palette.text.secondary}
              component="a"
              underline="none"
              sx={{
                paddingLeft: 0.5,
                cursor: "pointer",
              }}
              href={`mailto:${email.hello}`}
            >
              here
            </Link>
            .
          </SHTypography>
        </SHStack>
      }
    />
  );
};
