import { FilterValue } from "@components/buttons/filter";
import { FilterRadioButton } from "@components/buttons/filter-radio";
import {
  SHBox,
  SHContainer,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTypography,
} from "@components/design-systems";
import { DateFormat } from "@constants/format";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { AnalysisFilterDTO } from "@models/platform-analysis/entities/analysis";
import { PlatformCommentDialog } from "@models/platform-analysis/entities/comment";
import {
  AnalysisCommentDTO,
  AnalysisFeatureDTO,
  AnalysisSectionDTO,
  PlatformAnalysisFilter,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { useTheme } from "@mui/material";
import { FeatureAnalysisGroupTableRow } from "@pages/platform-analysis/_id/steps/feature/analysis/components/group-row";
import { PlatformAnalysisTableHead } from "@pages/platform-analysis/_id/steps/feature/analysis/components/table-head";
import { FeatureAnalysisSkeleton } from "@pages/platform-analysis/_id/steps/feature/analysis/skeleton";
import { filterFeatureAnalysisByValue } from "@pages/platform-analysis/_id/steps/feature/analysis/util";
import { ChangeRichTextDialog } from "@pages/platform-analysis/_id/steps/summary/components/change-rich-text-dialog";
import { PlatformAnalysisProductsFilterOptions } from "@pages/platform-analysis/constant";
import { TABLE_ROW_HEIGHT } from "@pages/reviews/_id/steps/feature/analysis/config";
import { flatFeatureReviewData } from "@pages/reviews/_id/steps/feature/analysis/util";
import { updateFilterFeature } from "@redux/slices/platform-analysis";
import { RootState } from "@redux/store";
import { savePlatformAnalysisFilters } from "@services/platform-analysis/platformAnalysisService";
import { hexToRGBA } from "@utils";
import { format } from "date-fns";
import { isEmpty, isNil, debounce } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
  WheelEvent,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { ShowHideButton } from "../../summary/components/show-hide-button";
import "./styles.scss";

export interface FeatureAnalysisProps extends StepCompProps {}

export const FeatureAnalysis: ForwardRefRenderFunction<
  StepCompRef,
  StepCompProps
> = ({ stepNumber }, ref) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { analysisId } = useParams<{ analysisId: string }>();
  const {
    ui: { isLoading, filterFeature, totalCollapsedFeatureAnalysisRows },
    platformAnalysis,
  } = useSelector((state: RootState) => state.platformAnalysis);

  const [loadedFromStore, setLoadedFromStore] = useState(false);
  const [initialFeatureAnalysis, setInitialFeatureAnalysis] =
    useState<AnalysisFeatureDTO>();
  const [featureAnalysis, setFeatureAnalysis] = useState<AnalysisFeatureDTO>();
  const [totalRowHeight, setTotalRowHeight] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isExpandHeader, setIsExpandHeader] = useState(true);
  let timeoutIdScrollDown = -1;
  let isScrollUp = true;
  let isMouseScroll = true;

  const [commentDialog, setCommentDialog] = useState<
    PlatformCommentDialog | undefined
  >();

  const {
    isModifyingComment,
    modifyComment,
    loadPlatformAnalysis,
    setTotalCollapsedFeatureAnalysisRows,
    isShowAnalysisScores,
    setIsShowAnalysisScores,
    isReadOnly,
  } = usePlatformAnalysis();

  const handleOnModifyComment = async (comment: string | null) => {
    if (!commentDialog || !analysisId || !commentDialog.productId) return;
    const newComment = await modifyComment(
      analysisId,
      commentDialog.productId,
      AnalysisStep.Feature,
      comment,
    );
    if (newComment) {
      setCommentDialog(undefined);
      setFeatureAnalysis({
        ...featureAnalysis,
        selectedProducts: featureAnalysis?.selectedProducts?.map(
          (selectedProduct) => {
            if (selectedProduct.id === commentDialog.productId)
              return {
                ...selectedProduct,
                comment: { ...selectedProduct.comment, comment: comment },
              };
            return selectedProduct;
          },
        ),
      } as AnalysisFeatureDTO);
    }
  };

  const getFilterValues = (filters?: FilterValue[]) => {
    if (filters === undefined) {
      return [
        PlatformAnalysisFilter.Common,
        PlatformAnalysisFilter.Differentiators,
      ];
    }

    if (filters.length > 0) {
      const arrValues: PlatformAnalysisFilter[] = filters.map(
        (filter) => filter.value,
      );
      return arrValues;
    }

    return [];
  };
  const handleScrollTop = debounce(
    () => {
      setIsExpandHeader(true);
    },
    150,
    { leading: false, trailing: true },
  );

  const handleOnWheel = (event: WheelEvent<HTMLDivElement>) => {
    const scrollTop = Math.floor(event.currentTarget.scrollTop);
    let isTouchpadScrollToTop;

    isScrollUp = event.deltaY < 0;
    isMouseScroll = Math.abs(event.deltaY) > 50;
    isTouchpadScrollToTop = scrollTop === 0 && isScrollUp && !isMouseScroll;

    if (isTouchpadScrollToTop) {
      const selectors = [
        ".tableHead",
        ".featureTableContainer",
        ".featureHeadContainer",
      ];
      const elements = selectors.map((selector) =>
        document.querySelector(selector),
      );
      const [tableHead, tableContainer, featureHeader] = elements;

      setIsScrolling(false);
      [tableHead, featureHeader, tableContainer].forEach((element) =>
        element?.classList.remove("sticky"),
      );
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const selectors = [
      ".tableBody",
      ".tableHead",
      ".featureTableContainer",
      ".featureHeadContainer",
    ];

    const elements = selectors.map((selector) =>
      document.querySelector(selector),
    );

    const [tableBody, tableHead, tableContainer, featureHeader] = elements;

    const scrollLeft = event.currentTarget.scrollLeft;
    const scrollTop = Math.floor(event.currentTarget.scrollTop);
    const containerHeight = tableContainer?.clientHeight ?? 0;

    if (scrollLeft > 0) {
      tableBody?.classList.add("sticky");
      tableHead?.classList.add("stickyFirstCell");
    } else {
      tableBody?.classList.remove("sticky");
      tableHead?.classList.remove("stickyFirstCell");
    }

    if (scrollTop > 0 && totalRowHeight > containerHeight && !isScrollUp) {
      [tableHead, featureHeader, tableContainer].forEach((element) =>
        element?.classList.add("sticky"),
      );

      if (isExpandHeader) {
        event.currentTarget.scrollTop = 5;
        timeoutIdScrollDown = window.setTimeout(() => {
          setIsExpandHeader(false);
        }, 150);
      }
    } else if (scrollTop === 0 && isScrollUp && isMouseScroll) {
      event.currentTarget.scrollTop = 1;

      if (isExpandHeader) {
        setIsScrolling(false);
        [tableHead, featureHeader, tableContainer].forEach((element) =>
          element?.classList.remove("sticky"),
        );
      }

      handleScrollTop();
    }
  };

  const loadAnalysis = async () => {
    if (analysisId) {
      const analysis = await loadPlatformAnalysis({
        platformAnalysisId: analysisId,
        stepId: AnalysisStep.Feature,
      });
      if (analysis && analysis?.feature) {
        setInitialFeatureAnalysis(analysis?.feature);
        setFeatureAnalysis(analysis?.feature);
        setIsShowAnalysisScores(analysis.isShowAnalysisScore);
      }
    }
    setLoadedFromStore(true);
  };

  const saveFilter = async () => {
    let filters: AnalysisFilterDTO = {
      showAnalysisScore: isShowAnalysisScores,
    };

    await savePlatformAnalysisFilters(platformAnalysis?.id ?? "", filters);
  };

  useEffectOnce(() => {
    loadAnalysis();
  });

  useEffect(() => {
    if (isEmpty(filterFeature) || filterFeature?.length > 1) {
      setFeatureAnalysis(initialFeatureAnalysis);
      return;
    }
    setFeatureAnalysis(
      filterFeatureAnalysisByValue(initialFeatureAnalysis, filterFeature[0]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFeature, initialFeatureAnalysis]);

  useEffect(() => {
    if (featureAnalysis?.sections && !isEmpty(featureAnalysis?.sections)) {
      const flatRows =
        flatFeatureReviewData(featureAnalysis?.sections)?.rows ?? [];

      let totalRows = flatRows?.length ?? 0;
      if (totalCollapsedFeatureAnalysisRows > 0)
        totalRows -= totalCollapsedFeatureAnalysisRows;

      setTotalRowHeight(totalRows * TABLE_ROW_HEIGHT);
    }
  }, [featureAnalysis, totalCollapsedFeatureAnalysisRows]);

  useEffect(() => window.clearTimeout(timeoutIdScrollDown));

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep) => {
      dispatch(
        updateFilterFeature([
          PlatformAnalysisFilter.Common,
          PlatformAnalysisFilter.Differentiators,
        ]),
      );
      setTotalCollapsedFeatureAnalysisRows(-totalCollapsedFeatureAnalysisRows);
      if (!isReadOnly) {
        await saveFilter();
      }
      return true;
    },
    onChangeOtherStep: async () => {
      dispatch(
        updateFilterFeature([
          PlatformAnalysisFilter.Common,
          PlatformAnalysisFilter.Differentiators,
        ]),
      );
      setTotalCollapsedFeatureAnalysisRows(-totalCollapsedFeatureAnalysisRows);
      if (!isReadOnly) {
        await saveFilter();
      }
      return true;
    },
    onSubmit: async () => {
      if (!isReadOnly) {
        await saveFilter();
      }
      return true;
    },
    onExit: async () => {
      if (!isReadOnly) {
        await saveFilter();
      }
      return true;
    },
  }));

  if (isLoading || !loadedFromStore) return <FeatureAnalysisSkeleton />;

  return (
    <SHContainer
      maxWidth="lg2"
      sx={{
        px: {
          xs: "16px",
          lg2: 0,
        },
        pt: "25px",
        flexGrow: 1,
        height: "1px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SHStack
        direction={"row"}
        justifyContent={"space-between"}
        className="featureHeadContainer"
        sx={{ transition: "all 0.5s ease" }}
      >
        <StepName name={"Feature analysis"} stepNumber={stepNumber} />
        {!isNil(featureAnalysis) && (
          <SHBox
            sx={{
              display: "inline-flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <SHTypography
              variant="body3"
              colorVariant="third"
              textAlign={"left"}
              lineHeight="120%"
              sx={{
                marginTop: "10px",
              }}
            >
              {platformAnalysis?.databaseVersion
                ? `Data valid: ${format(
                    new Date(platformAnalysis?.databaseVersion),
                    DateFormat,
                  )}`
                : ""}
            </SHTypography>
            <SHStack flexDirection={"row"} alignItems={"center"} gap={"5px"}>
              <ShowHideButton
                options={[
                  {
                    value: "true",
                    label: "Show scores",
                  },
                  {
                    value: "false",
                    label: "Hide",
                  },
                ]}
                isShow={isShowAnalysisScores}
                onChange={(isShow: boolean) => {
                  setIsShowAnalysisScores(isShow);
                }}
              />
              <FilterRadioButton
                buttonTitle={"Feature filter"}
                filterData={PlatformAnalysisProductsFilterOptions}
                width={"275px"}
                labelAll={"All features"}
                zIndex={10}
                isScrolling={isScrolling}
                onChangeFilter={(filters) =>
                  dispatch(updateFilterFeature(getFilterValues(filters)))
                }
              />
            </SHStack>
          </SHBox>
        )}
      </SHStack>
      {!isNil(featureAnalysis) ? (
        <SHTableContainer
          sx={{
            marginY: 3,
            flexGrow: 1,
            height: "1px",
            overflow: "overlay",
            maxWidth: "max-content",
            "::-webkit-scrollbar": {
              boxShadow: "none",
            },
            "::-webkit-scrollbar-thumb": {
              boxShadow: "none",
            },
          }}
          onScroll={handleScroll}
          onWheel={handleOnWheel}
          className="featureTableContainer"
        >
          <SHTable
            stickyHeader
            style={{
              tableLayout: "auto",
              maxWidth: "max-content",
            }}
            sx={{
              th: {
                borderLeft: "none",
                "&:first-child": {
                  borderLeft: `1px solid ${palette.secondary[100]}`,
                },
              },
              td: {
                borderTop: "none",
                borderLeft: "none",
              },
            }}
            className="featureTable"
          >
            <PlatformAnalysisTableHead
              className="tableHead"
              platformData={featureAnalysis?.selectedProducts}
              onClickComment={(
                platformId: string,
                comment?: AnalysisCommentDTO,
              ) => {
                setCommentDialog({
                  comment: comment?.comment ?? null,
                  productId: platformId,
                });
              }}
              onDeleteComment={(platformId?: string, commentId?: string) => {}}
              isFeatureAnalysis
            />
            <SHTableBody
              sx={{
                "&>tr:first-child td": {
                  borderTop: `1px solid ${palette.secondary[100]}`,
                },
                "& tr>td:first-child": {
                  borderLeft: `1px solid ${palette.secondary[100]}`,
                },
              }}
              className="tableBody"
            >
              {featureAnalysis?.sections?.map((group: AnalysisSectionDTO) => (
                <FeatureAnalysisGroupTableRow
                  key={group?.id}
                  groupData={group}
                />
              ))}
            </SHTableBody>
          </SHTable>
        </SHTableContainer>
      ) : (
        <SHStack
          direction={"row"}
          alignItems="center"
          justifyContent={"center"}
          sx={{
            width: "100%",
            display: "inline-flex",
            alignItems: "flex-end",
            marginY: 3,
            padding: "10px 10px",
            backdropFilter: "blur(0.5px)",
            background: `${hexToRGBA("#c5ddfd", 0.1)}`,
            border: `1px solid ${palette.secondary[100]}`,
          }}
        >
          <SHTypography variant="body4">No feature analysis data.</SHTypography>
        </SHStack>
      )}

      {commentDialog && (
        <ChangeRichTextDialog
          onSubmit={handleOnModifyComment}
          title={
            isEmpty(commentDialog.comment) ? "Add comment" : "Edit comment"
          }
          placeHolder={"Enter your comment"}
          initialContent={commentDialog.comment ?? null}
          isSubmitting={isModifyingComment}
          onClose={() => {
            setCommentDialog(undefined);
          }}
          maxLength={1500}
          isCommentBox
        />
      )}
    </SHContainer>
  );
};
