import { AlertColor } from "@mui/material";
import htmlParser from "html-react-parser";
import { SnackbarContent, SnackbarMessage, useSnackbar } from "notistack";
import React from "react";
import { SHAlert } from "../sh-alert";

export interface SHSnackbarContent {
  message: SnackbarMessage;
  close?: boolean;
  id: string | number;
  variant?: AlertColor;
  width?: string | number;
}

export const SHSnackbar = React.forwardRef<HTMLDivElement, SHSnackbarContent>(
  (props, ref) => {
    const { message, id, close, variant, width } = props;
    const { closeSnackbar } = useSnackbar();
    const closeStack = () => {
      closeSnackbar(id);
    };
    return (
      <SnackbarContent ref={ref} data-testid="sh-snackbar" role="alert">
        <SHAlert
          severity={variant ? variant : "info"}
          onClose={close ? closeStack : undefined}
          width={width}
        >
          {typeof message === "string" ? htmlParser(message) : message}
        </SHAlert>
      </SnackbarContent>
    );
  },
);
