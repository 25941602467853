import { SHStack, SHTypography } from "@components/design-systems";
import { Avatar, useTheme } from "@mui/material";

export interface BusinessMetricFeatureProps {
  title?: string;
  value?: string;
  icon?: React.ReactNode;
}

export default function BusinessMetricFeature({
  title,
  value,
  icon,
}: BusinessMetricFeatureProps) {
  const { palette } = useTheme();
  return (
    <SHStack
      direction={"row"}
      spacing={2}
      data-testid="business-metric-feature"
    >
      <Avatar
        sx={{
          width: 44,
          height: 44,
          background: palette.common.white,
          border: `1px solid ${palette.secondary[100]}`,
        }}
      >
        {icon}
      </Avatar>
      <SHStack>
        <SHTypography
          addPaddingTop={false}
          variant="caption"
          colorVariant="white"
        >
          {title}
        </SHTypography>
        <SHTypography
          addPaddingTop={false}
          variant="subtitle1"
          colorVariant="white"
        >
          {value}
        </SHTypography>
      </SHStack>
    </SHStack>
  );
}
