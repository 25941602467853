import { SVGProps } from "react";

export const CloseSVG = ({
  color = "#EB5757",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 13 13"
    width={13}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.225 1.16a.999.999 0 0 1 1.413 0l4.289 4.293 4.288-4.293a1 1 0 1 1 1.413 1.414L8.339 6.867l4.289 4.293a1 1 0 0 1-1.413 1.414L6.927 8.28l-4.29 4.293a1 1 0 0 1-1.412-1.414l4.289-4.293-4.289-4.293a1 1 0 0 1 0-1.414Z"
      fill={color}
    />
  </svg>
);
