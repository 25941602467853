import { SHButton } from "@components/design-systems";
import { DeletePostConfirmationDialog } from "@components/dialogs/delete-post-confirmation";
import { DeleteSVG } from "@components/svgs";
import { useNewsFeed } from "@hooks/useNewsFeed";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { useTheme } from "@mui/material";
import { archivePost } from "@services/newsfeed/postService";
import { useState } from "react";

export interface ArchivePostButtonProps {
  postStatus: string;
  postId: string;
}

export const ArchivePostButton = ({ postId }: ArchivePostButtonProps) => {
  const { palette } = useTheme();
  const { isSuperAdmin } = useUserPermissions();
  const { notify } = useNotification();
  const { loadPosts } = useNewsFeed();

  const [isDeletingPost, setIsDeletingPost] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState(false);

  const handleOnArchivePost = async () => {
    setIsDeletingPost(true);
    const { isSuccess, message } = await archivePost(postId);
    if (!isSuccess) {
      notify(message, {
        variant: "error",
        close: true,
      });
    } else {
      notify(isSuperAdmin ? "Post archived." : "Your post has been deleted.", {
        variant: "success",
        close: true,
      });
      loadPosts();
    }
    setIsDeletingPost(false);
    setShowDialog(false);
  };
  return (
    <>
      <SHButton
        variant="outlined"
        onClick={() => setShowDialog(true)}
        sx={{
          padding: "5px",
          "&:active": {
            "&>svg>path": {
              fill: palette.common.white,
            },
          },
        }}
      >
        <DeleteSVG color={palette.common.black} />
      </SHButton>
      {showDialog && (
        <DeletePostConfirmationDialog
          open
          isLoading={isDeletingPost}
          onClose={() => setShowDialog(false)}
          onOk={handleOnArchivePost}
        />
      )}
    </>
  );
};
