import { DuplicateButton } from "@components/buttons/duplicate";
import { GrayDeleteButton } from "@components/buttons/gray-delete";
import { PlusButton } from "@components/buttons/plus";
import { RollBackButton } from "@components/buttons/rollback";
import {
  SHAvatar,
  SHBox,
  SHButton,
  SHDataGrid,
  SHDataGridRef,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { APIExtRoutes, PageRoutes } from "@constants";
import { DateFormat, DateTimeFormat } from "@constants/format";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { PageMode, ParentState } from "@models/core";
import { AnalysisListDTO } from "@models/platform-analysis/entities/analysis";
import { AnalysisStatus } from "@models/platform-analysis/enums/status";
import { useTheme } from "@mui/material";
import { PlatformAnalysisFilterOptions } from "@pages/platform-analysis/constant";
import { TableState, createColumnHelper } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { format } from "date-fns";
import { useMemo, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { TextOverflowEllipsis } from "./components/text-overflow-ellipsis";

const nameOf = nameOfFactory<AnalysisListDTO>();
const columnHelper = createColumnHelper<AnalysisListDTO>();
const pickColumns: (keyof AnalysisListDTO)[] = [
  "id",
  "name",
  "ownerName",
  "ownerAvatarUrl",
  "adviserName",
  "includeSteps",
  "dataValidDate",
  "lastModifiedDate",
  "status",
];

export const PlatformAnalysisListView = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { isSupplierBdmSale } = useUserPermissions();
  const { setReachAnalysisSetupPage } = usePlatformAnalysis();
  const ref = useRef<SHDataGridRef | null>(null);
  const fontSize = "13px";
  const [gridState, setGridState] = useState<Partial<TableState>>({
    ...DefaultDataState,
    sorting: [{ id: nameOf("lastModifiedDate"), desc: true }],
    columnFilters: [
      {
        id: nameOf("status"),
        value: [AnalysisStatus.InProgress, AnalysisStatus.Completed],
      },
    ],
  });
  const [submittingIds, setSubmittingIds] = useState<string[]>([]);
  const [duplicatingIds, setDuplicatingIds] = useState<string[]>([]);
  const { updatePlatformAnalysisStatus, duplicatePlatformAnalysis } =
    usePlatformAnalysis();

  const handleOnChangeStatus = async (
    analysisId: string,
    status: AnalysisStatus,
  ) => {
    setSubmittingIds((ids) => [...ids, analysisId]);
    await updatePlatformAnalysisStatus(analysisId, status);
    setSubmittingIds((ids) => ids.filter((id) => id !== analysisId));
    ref.current?.refreshOdata();
  };

  const handleOnDuplicate = async (analysisId: string) => {
    setDuplicatingIds((ids) => [...ids, analysisId]);
    await duplicatePlatformAnalysis(analysisId);
    setDuplicatingIds((ids) => ids.filter((id) => id !== analysisId));
    ref.current?.refreshOdata();
  };

  const columns = useMemo(
    () => {
      const cols = [
        columnHelper.accessor("name", {
          header: "Analysis name",
          cell: (props) => (
            <TextOverflowEllipsis
              fontWeight="bold"
              value={props.cell.getValue()}
            />
          ),
          enableColumnFilter: false,
          meta: {
            sx: {
              width: isSupplierBdmSale ? "18%" : { xs: "18%", md: "32%" },
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          header: "Owner",
          id: nameOf("ownerName"),
          cell: (props) => {
            return (
              <SHStack direction={"row"} alignItems={"center"}>
                <SHAvatar
                  width={"24px"}
                  height={"24px"}
                  src={props.cell.getValue().ownerAvatarUrl}
                />
                <TextOverflowEllipsis
                  value={props.cell.getValue().ownerName}
                  sx={{
                    marginLeft: "5px",
                    minWidth: "100%",
                  }}
                />
              </SHStack>
            );
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: isSupplierBdmSale ? "12%" : "15%",
              padding: "0 16px !important",
            },
          },
        }),

        columnHelper.accessor("includeSteps", {
          header: "What's included",
          cell: (props) => (
            <SHTypography fontSize={fontSize} lineHeight={"120%"}>
              {props.cell.getValue()}
            </SHTypography>
          ),
          enableSorting: false,
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "15%",
              minWidth: { xs: "100px", md: "160px" },
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor("dataValidDate", {
          header: "Data valid",
          cell: (props) => {
            const date = props.cell.getValue();
            return date ? format(new Date(date), DateFormat) : "";
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "11%",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor("lastModifiedDate", {
          header: "Last edit",
          cell: (props) => {
            const date = props.cell.getValue();
            return date ? format(new Date(date), `${DateTimeFormat}`) : "";
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: { xs: "10%", md: "12%" },
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor("status", {
          header: "Status",
          cell: (props) => (
            <StatusBadge
              style={{ width: "101px", fontSize: fontSize }}
              status={props.cell.getValue() as AnalysisStatus}
            />
          ),
          meta: {
            filterData: PlatformAnalysisFilterOptions,
            sx: {
              width: "9%",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          id: "actionCol",
          header: "Action",
          enableColumnFilter: false,
          enableSorting: false,
          cell: (props) => (
            <SHStack
              direction="row"
              justifyContent={"space-between"}
              spacing={0.5}
            >
              {props.cell.getValue().status === AnalysisStatus.InProgress ? (
                <SHButton
                  sx={{ width: "50%", fontSize: fontSize }}
                  startIcon={<EditSVG color={palette.common.white} />}
                  variant="contained"
                  disabled={false}
                  onClick={() => {
                    setReachAnalysisSetupPage("FIRST_TIME");
                    navigate(
                      generatePath(PageRoutes.platformAnalysisDetail, {
                        analysisId: props.cell.getValue().id,
                      }),
                      { state: { pageMode: PageMode.Edit } as ParentState },
                    );
                  }}
                >
                  Edit
                </SHButton>
              ) : (
                <SHButton
                  sx={{ width: "50%" }}
                  variant="contained"
                  disabled={false}
                  onClick={() => {
                    navigate(
                      generatePath(PageRoutes.platformAnalysisDetail, {
                        analysisId: props.cell.getValue().id,
                      }),
                      { state: { pageMode: PageMode.View } as ParentState },
                    );
                  }}
                >
                  View
                </SHButton>
              )}
              <DuplicateButton
                tooltipTitle={"Duplicate"}
                disabled={duplicatingIds.includes(props.cell.getValue().id)}
                isLoading={duplicatingIds.includes(props.cell.getValue().id)}
                onClick={() => {
                  handleOnDuplicate(props.cell.getValue().id);
                }}
              />
              {props.cell.getValue().status === AnalysisStatus.Archived ? (
                <RollBackButton
                  disabled={submittingIds.includes(props.cell.getValue().id)}
                  isLoading={submittingIds.includes(props.cell.getValue().id)}
                  onClick={() =>
                    handleOnChangeStatus(
                      props.cell.getValue().id,
                      AnalysisStatus.Restore,
                    )
                  }
                />
              ) : (
                <GrayDeleteButton
                  tooltipTitle={"Archive"}
                  disabled={submittingIds.includes(props.cell.getValue().id)}
                  isLoading={submittingIds.includes(props.cell.getValue().id)}
                  onClick={() =>
                    handleOnChangeStatus(
                      props.cell.getValue().id,
                      AnalysisStatus.Archived,
                    )
                  }
                />
              )}
            </SHStack>
          ),
          meta: {
            sx: {
              width: "10%",
            },
          },
        }),
      ];
      if (isSupplierBdmSale) {
        const adviserNameCol = columnHelper.accessor("adviserName", {
          id: nameOf("adviserName"),
          header: "Adviser name",
          cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "14%",
            },
          },
        });
        cols.splice(2, 0, adviserNameCol);
      }
      return cols;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [submittingIds, duplicatingIds, isSupplierBdmSale],
  );

  return (
    <>
      <TopBarContainer>
        <TopBar
          title={"Platform analysis"}
          tools={
            <>
              <PlusButton
                variant="contained"
                size="extraMedium"
                onClick={() =>
                  navigate(
                    generatePath(PageRoutes.platformAnalysisDetail, {
                      analysisId: "new",
                    }),
                  )
                }
              >
                New analysis
              </PlusButton>
            </>
          }
        />
      </TopBarContainer>
      <SHBox sx={{ marginTop: "25px" }}>
        <SHDataGrid
          ref={ref}
          odata={{
            url: APIExtRoutes.odataPlatformAnalysis,
            pickColumns: pickColumns,
            globalFilterColumns: [
              nameOf("name"),
              nameOf("ownerName"),
              nameOf("adviserName"),
            ],
          }}
          emptyMessage={"No platform analysis."}
          columns={columns as any}
          state={gridState}
          onChangeState={(state) => setGridState(state)}
          searchBoxProps={{
            placeholder: "Search analysis, owners and advisers",
          }}
        />
      </SHBox>
    </>
  );
};
