import { PracticeRole } from "@models/practice/enums/practiceRole";
import { UserStatusAction } from "@models/users/enums/status";
import * as Yup from "yup";

export const practiceUserValidators = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .max(100, "First name must be less than 100 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .max(100, "Last name must be less than 100 characters"),
  email: Yup.string().required("Email is required").email("Email is not valid"),
  userType: Yup.string().required("Account type is required"),
  practiceRole: Yup.string().required("Practice role is required"),
  arn: Yup.string()
    .nullable(true)
    .when("practiceRole", (practiceRole, schema) => {
      return practiceRole === PracticeRole.Adviser
        ? schema.required("ARN number is required")
        : schema;
    }),
});

export interface StatusButton {
  title: string;
  action: UserStatusAction;
  dialog?: {
    header: string;
    body: string;
    noButton: string;
    yesButton: string;
  };
}
export const getButtonsByStatus = (status: {
  isActive: boolean;
  isDisabled: boolean;
  isArchived: boolean;
  isPending: boolean;
}): StatusButton[] => {
  if (status.isActive || status.isPending) {
    return [
      {
        title: "Disable",
        action: UserStatusAction.Disable,
        dialog: {
          header: `Disable user?`,
          body: `Disabling a user will remove their access and log them out. Do you want to disable them?`,
          noButton: "Cancel",
          yesButton: "Yes, disable",
        },
      },
    ];
  }
  if (status.isDisabled) {
    return [
      {
        title: "Enable",
        action: UserStatusAction.Enable,
      },
      {
        title: "Archive",
        action: UserStatusAction.Archive,
        dialog: {
          header: `Archive user?`,
          body: `Archiving a user will hide them from the list of users that belong to your firm. You can toggle the filter to view them again. Do you want to archive them?`,
          noButton: "Cancel",
          yesButton: "Yes, archive",
        },
      },
    ];
  }
  if (status.isArchived) {
    return [
      {
        title: "Unarchive",
        action: UserStatusAction.Unarchive,
      },
    ];
  }
  return [];
};
