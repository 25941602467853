import { SVGProps } from "react";

export const TooltipAllowSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m5.701 10.75-2.598-4.5A1.5 1.5 0 0 1 4.402 4h5.196a1.5 1.5 0 0 1 1.3 2.25l-2.599 4.5a1.5 1.5 0 0 1-2.598 0Z"
      fill="#fff"
      stroke="#E3E3E3"
    />
    <path fill="#fff" d="M2 3h10v3H2z" />
  </svg>
);
