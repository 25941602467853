import { SHStack } from "@components/design-systems";
import { SHButton } from "../../design-systems/sh-button";
import { SHDialog } from "../../design-systems/sh-dialog";

interface RestorePostConfirmationDialogProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onOk: () => void;
}

export const RestorePostConfirmationDialog = ({
  open,
  isLoading,
  onClose,
  onOk,
}: RestorePostConfirmationDialogProps) => {
  return (
    <SHDialog
      open={open}
      onClose={onClose}
      width={600}
      header="Restore post?"
      body="Are you sure you want to restore this post?"
      footer={
        <SHStack
          width="100%"
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            onClick={onClose}
            variant="text"
            size="extraMedium"
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onOk}
          >
            Yes, restore post
          </SHButton>
        </SHStack>
      }
    />
  );
};
