import { SHTableCell, SHTableCellProps } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

export interface FeatureReviewTableCellProps {
  isFirstColumn?: boolean;
  colorCell?: "Green" | "Gray" | "Orange" | "White";
}

export const FeatureReviewTableCell = React.memo(
  ({
    isFirstColumn,
    colorCell,
    ...others
  }: FeatureReviewTableCellProps & SHTableCellProps) => {
    const { palette } = useTheme();
    const {
      ui: { isShirkColumn },
    } = useSelector((state: RootState) => state.review);

    const cellWidth = useMemo(() => {
      return isFirstColumn
        ? isShirkColumn
          ? 290
          : 316
        : isShirkColumn
        ? 192
        : 202;
    }, [isFirstColumn, isShirkColumn]);

    // TODO: Add to palette
    const colorCellMapping: {
      [key in "Green" | "Gray" | "Orange" | "White"]?: string;
    } = {
      Green: "#EEFAF7",
      Gray: "#E0E5F0",
      Orange: "#FFF5F5",
      White: "#FFFFFF",
    };

    return (
      <SHTableCell
        sx={{
          left: 0,
          width: cellWidth,
          minWidth: cellWidth,
          maxWidth: cellWidth,
          position: "sticky",
          padding: "5px 12px",
          textAlign: "center",
          border: `1px solid ${palette.secondary[100]}`,
          backgroundColor: colorCell && colorCellMapping[colorCell],
        }}
        {...others}
      ></SHTableCell>
    );
  },
);
