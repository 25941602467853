import { FeatureSectionName } from "@models/configuration";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import { ReviewDataDTO } from "@models/reviews/entities/steps/feature";
import { useTheme } from "@mui/material";
import { HorizontalLine } from "@pages/platform-analysis/components/buttons/export-pdf/components/line";
import { PDFSummaryAnalysisHeader } from "@pages/platform-analysis/components/buttons/export-pdf/components/tables/components/summary-analysis-header";
import { PDFSummaryOverallScore } from "@pages/platform-analysis/components/buttons/export-pdf/components/tables/components/summary-overall-score";
import {
  DEFAULT_GAP_3,
  TABLE_CELL_WIDTH,
  TITLE_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { featureSectionIconMapping } from "@pages/platform-analysis/components/buttons/export-pdf/util";
import { PDFReviewTable } from "@pages/reviews/components/buttons/export-pdf/layouts/tables";
import { PDFReviewTableBody } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-body";
import { PDFReviewTableCell } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-cell";
import { PDFReviewTableRow } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-row";
import { PDFFeatureReview } from "@pages/reviews/components/buttons/export-pdf/model";
import { useReviewStyles } from "@pages/reviews/components/buttons/export-pdf/styles";
import { Image, Path, Svg, Text, View } from "@react-pdf/renderer";
import { isEmpty } from "lodash";
import { PDFEssentialNoticeSection } from "../essential-notice";
interface PDFFeatureReviewSectionProps {
  featureReview?: PDFFeatureReview;
  showSubSection?: boolean;
  isTableExpand?: boolean;
  isAppend?: boolean;
  isDetailMode?: boolean;
}

export const PDFFeatureReviewSection = ({
  featureReview,
  showSubSection = false,
  isTableExpand = false,
  isDetailMode = false,
}: PDFFeatureReviewSectionProps) => {
  const { typographyStyle } = useReviewStyles();
  const { palette } = useTheme();

  const sections = featureReview?.pdfSections ?? [];
  const overallScore = featureReview?.overallScore;
  const selectedProducts = featureReview?.selectedProducts ?? [];

  const cellWidth = 60.8;
  let firstCellWidth = 150;
  if (isDetailMode) firstCellWidth += cellWidth;

  const totalSelectionCellWidth = cellWidth;

  let tableWidth = firstCellWidth + selectedProducts?.length! * cellWidth;
  if (!isDetailMode) tableWidth += totalSelectionCellWidth;

  const maxCharactersInOneLine = 48;

  const renderPlatformValue = (value?: AnalysisDataValue) => {
    if (value === AnalysisDataValue.True)
      return (
        <Svg width="12" height="12" viewBox="0 0 19 19">
          <Path
            d="m3.333 9.265 4.5 4.5 7.5-9"
            stroke="#219653"
            strokeWidth="2"
            strokeLineCap="round"
          />
        </Svg>
      );
    if (value === AnalysisDataValue.False)
      return (
        <Text style={{ ...typographyStyle.body1, color: palette.error.main }}>
          x
        </Text>
      );
    if (value === AnalysisDataValue.MissingData || isEmpty(value))
      return (
        <Text style={{ ...typographyStyle.body2, color: palette.text.primary }}>
          No data
        </Text>
      );
  };

  let isExistsMissingEssential = false;
  let hasEssentialFeature = false;

  return (
    <View>
      <View style={{ height: TITLE_HEIGHT }}>
        <Text
          style={{
            ...typographyStyle.title,
            color: palette.common.black,
          }}
        >
          {!isTableExpand ? "Feature review" : "Feature review (continued)"}
        </Text>
        <HorizontalLine color={palette.common.black} />
      </View>

      <View style={{ gap: "5px" }}>
        <PDFReviewTable tableWidth={tableWidth}>
          {/* Header */}
          <PDFSummaryAnalysisHeader
            isFeatureAnalysis
            selectedProducts={selectedProducts}
            isDetailMode={isDetailMode}
          />
          {/* Body */}
          <PDFReviewTableBody>
            {/* Overall Score  */}
            {!isTableExpand && (
              <PDFSummaryOverallScore
                isFeatureAnalysis
                isDetailMode={isDetailMode}
                overallScore={overallScore}
                color={isDetailMode ? "primary" : "dark"}
              />
            )}
            {sections?.map((section, index) => {
              const isSubSectionType = section?.type === "SubSection";
              const isFeatureType = section?.type === "Feature";
              if (section?.isEssential) hasEssentialFeature = true;
              const colorRowDetailMode =
                isSubSectionType && showSubSection
                  ? "gray"
                  : isFeatureType
                  ? "white"
                  : "dark";
              const colorRow =
                !isSubSectionType && showSubSection ? "gray" : "white";
              const maxChar = hasEssentialFeature ? 45 : maxCharactersInOneLine;
              return (
                <>
                  <PDFReviewTableRow
                    height={
                      section?.name?.length! > maxChar
                        ? "28px"
                        : "20px"
                    }
                    color={isDetailMode ? colorRowDetailMode : colorRow}
                    key={index}
                  >
                    <PDFReviewTableCell
                      alignItems="flex-start"
                      width={firstCellWidth}
                    >
                      <View
                        style={{
                          gap: !isFeatureType ? DEFAULT_GAP_3 : 2,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {!isSubSectionType && section?.iconUrl && (
                          <Image
                            style={{ width: 10, height: 10 }}
                            src={
                              featureSectionIconMapping[
                                (section?.name as FeatureSectionName) ??
                                  FeatureSectionName.ProductMenu
                              ]
                            }
                          />
                        )}
                        <Text
                          style={{
                            ...typographyStyle.body2,
                            color: palette.text.primary,
                            fontWeight:
                              isSubSectionType || isFeatureType
                                ? "normal"
                                : "bold",
                            maxWidth:
                              section?.isEssential && isFeatureType
                                ? "188px"
                                : "auto",
                          }}
                        >
                          {section?.name}
                          {isDetailMode &&
                            !isFeatureType &&
                            ` (${section?.totalSelectedFeature}/${section?.totalFeature})`}
                        </Text>
                        {section?.isEssential && isFeatureType && (
                          <Svg width="7" height="7" viewBox="0 0 13 12">
                            <Path
                              fill="#FFF500"
                              stroke="#F3612D"
                              d="M7.71668 1.30902C7.41733 0.387703 6.11392 0.38771 5.81457 1.30902L4.94301 3.99139H2.1226C1.15387 3.99139 0.751103 5.23101 1.53481 5.80041L3.81658 7.4582L2.94502 10.1406C2.64567 11.0619 3.70015 11.828 4.48386 11.2586L6.76562 9.60081L9.04739 11.2586C9.8311 11.828 10.8856 11.0619 10.5862 10.1406L9.71467 7.4582L11.9964 5.80041C12.7801 5.23101 12.3774 3.99139 11.4087 3.99139H8.58824L7.71668 1.30902Z"
                            ></Path>
                          </Svg>
                        )}
                      </View>
                    </PDFReviewTableCell>
                    {!isDetailMode && (
                      <PDFReviewTableCell width={cellWidth}>
                        <Text
                          style={{
                            ...typographyStyle.body2,
                            color: palette.text.primary,
                            fontWeight: isSubSectionType ? "normal" : "bold",
                          }}
                        >
                          {`${section?.totalSelectedFeature}/${section?.totalFeature}`}
                        </Text>
                      </PDFReviewTableCell>
                    )}

                    {section?.analysisData?.map(
                      (product: ReviewDataDTO, index: number) => {
                        if (product?.isMissingEssential === true) {
                          isExistsMissingEssential = true;
                        }

                        return (
                          <PDFReviewTableCell
                            key={index}
                            width={TABLE_CELL_WIDTH}
                            hasPadding={false}
                          >
                            {isFeatureType ? (
                              renderPlatformValue(
                                product.value as AnalysisDataValue,
                              )
                            ) : (
                              <Text
                                style={{
                                  ...typographyStyle.body2,
                                  color: palette.text.primary,
                                  fontWeight:
                                    isSubSectionType || isFeatureType
                                      ? "normal"
                                      : "bold",
                                }}
                              >
                                {product?.totalScore} %
                                {product?.isMissingEssential && !isDetailMode && (
                                  <Text
                                    style={{
                                      ...typographyStyle?.body2,
                                      color: palette.error.main,
                                    }}
                                  >
                                    *
                                  </Text>
                                )}
                              </Text>
                            )}
                          </PDFReviewTableCell>
                        );
                      },
                    )}
                  </PDFReviewTableRow>
                </>
              );
            })}
          </PDFReviewTableBody>
        </PDFReviewTable>
        {isExistsMissingEssential && !isDetailMode && (
          <Text
            style={{ ...typographyStyle?.body2, color: palette?.error?.main }}
          >
            * Missing an essential feature
          </Text>
        )}
        {hasEssentialFeature && isDetailMode && (
          <PDFEssentialNoticeSection alignItems="flex-start" />
        )}
      </View>
    </View>
  );
};
