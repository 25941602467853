import { AnalysisFeatureIncludeDTO } from "@models/platform-analysis/entities/steps/feature";
import { PDFTableCell } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-cell";
import { TABLE_WIDTH } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Text, View } from "@react-pdf/renderer";
import { PDFReviewTable } from "../../layouts/tables";
import { PDFReviewTableRow } from "../../layouts/tables/table-row";
import { PDFReviewTableCell } from "../../layouts/tables/table-cell";
import { PDFReviewTableBody } from "../../layouts/tables/table-body";
import { calculateTextHeightInBusinessIncluded } from "../../util";

export interface PDFBusinessMetricIncludeTableProps {
  featureInclude?: AnalysisFeatureIncludeDTO[] | null;
}
export const PDFBusinessMetricIncludeTable = ({
  featureInclude,
}: PDFBusinessMetricIncludeTableProps) => {
  const { typographyStyle } = useStyles();
  const groupCellWidth = 160;
  const featureCellWidth = TABLE_WIDTH - groupCellWidth;

  return (
    <PDFReviewTable>
      <PDFReviewTableRow height={"25px"} borderBottomWidth={"1px"}>
        <PDFReviewTableCell width={groupCellWidth}>
          <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
            Business metric group
          </Text>
        </PDFReviewTableCell>
        <PDFTableCell width={featureCellWidth}>
          <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
            Business metrics
          </Text>
        </PDFTableCell>
      </PDFReviewTableRow>
      <PDFReviewTableBody>
        {featureInclude?.map((row, index) => {
          let height = calculateTextHeightInBusinessIncluded(row);
          return (
            <PDFReviewTableRow key={index} height={height}>
              <PDFReviewTableCell
                width={groupCellWidth}
                alignItems="flex-start"
              >
                <Text
                  style={{
                    ...typographyStyle.body2,
                  }}
                >
                  {row.sectionName}
                </Text>
              </PDFReviewTableCell>
              <PDFReviewTableCell
                width={featureCellWidth}
                alignItems="flex-start"
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {row?.features?.map((feature, index) => {
                    return (
                      <>
                        <Text
                          style={{
                            ...typographyStyle.body2,
                            lineHeight: 1.2,
                            marginRight: 1,
                          }}
                        >
                          {feature.featureName}
                        </Text>
                        {index !== row?.features.length - 1 ? (
                          <Text
                            style={{
                              ...typographyStyle.body2,
                              lineHeight: 1.2,
                              marginRight: 1.2,
                            }}
                          >
                            ,
                          </Text>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                </View>
              </PDFReviewTableCell>
            </PDFReviewTableRow>
          );
        })}
      </PDFReviewTableBody>
    </PDFReviewTable>
  );
};
