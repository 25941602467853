import { CloseButton } from "@components/buttons/close";
import {
  SHButton,
  SHDialog,
  SHRichTextEditor,
  SHStack,
} from "@components/design-systems";
import { Controller, useForm } from "react-hook-form";

interface ChangeRichTextDialogProps {
  onSubmit: (html: string | null) => void;
  onClose?: () => void;
  initialContent: string | null;
  title?: string;
  placeHolder?: string;
  maxLength?: number;
  isSubmitting?: boolean;
  isCommentBox?: boolean;
}

export const ChangeRichTextDialog = ({
  onSubmit,
  onClose,
  initialContent = "",
  title = "Edit",
  placeHolder = "",
  maxLength = 3000,
  isSubmitting,
  isCommentBox = false,
}: ChangeRichTextDialogProps) => {
  const {
    control,
    getValues,
    formState: { isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      content: initialContent ?? "",
    },
  });

  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          border: 0,
          width: 541,
          paddingTop: "0px",
          paddingBottom: "20px",
        },
      }}
      header={
        <SHStack sx={{ position: "absolute", top: 11, right: 11 }}>
          <CloseButton width="12px" height="12px" onClick={onClose} />
        </SHStack>
      }
      body={
        <Controller
          name="content"
          control={control}
          render={({ field }) => (
            <SHRichTextEditor
              {...field}
              maxLength={maxLength}
              placeHolder={placeHolder}
              height={200}
              label={title}
              sx={{ marginTop: 0 }}
              isCommentBox={isCommentBox}
            />
          )}
        />
      }
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={() => onSubmit(getValues("content"))}
            disabled={isSubmitting || !isDirty}
            isLoading={isSubmitting}
          >
            Save
          </SHButton>
        </SHStack>
      }
    />
  );
};
