import { SHStack } from "@components/design-systems";
import { SHButton } from "../../design-systems/sh-button";
import { SHDialog } from "../../design-systems/sh-dialog";

interface CustomUnsavedDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

function CustomUnsavedDialog({ open, onConfirm, onCancel }: CustomUnsavedDialogProps): JSX.Element {
  return (
    <SHDialog
      disableEscapeKeyDown
      disableBackdropClick
      open={open}
      onClose={onConfirm}
      width={400}
      header="You have unsaved changes"
      body="Would you like to discard them?"
      footer={
        <SHStack
          width="100%"
          direction="row"
          spacing={7}
          alignItems="center"
          justifyContent="center"
        >
          <SHButton
            color="secondary"
            onClick={onConfirm}
            variant="text"
            size="extraMedium"
          >
            Discard
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onCancel}
          >
            Keep editing
          </SHButton>
        </SHStack>
      }
    />
  )
}

export default CustomUnsavedDialog;
