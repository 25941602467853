import { SHStack } from "@components/design-systems";
import { SHSkeletonProps } from "@components/design-systems/sh-skeleton";
import { SHAvatarSkeleton } from "@components/skeletons/design-systems/SHAvatarSkeleton";
import { SHUploadFileSkeleton } from "@components/skeletons/design-systems/SHUploadFileSkeleton";

export const SHUploadImageSkeleton = ({ ...others }: SHSkeletonProps) => {
  return (
    <SHStack direction={"row"} spacing={4} alignItems="center" {...others}>
      <SHAvatarSkeleton />
      <SHUploadFileSkeleton />
    </SHStack>
  );
};
