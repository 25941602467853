import { SHButton } from "@components/design-systems";
import { DownloadSVG } from "@components/svgs";
import { FeeExportDTO } from "@models/platform-analysis/entities/feeExport";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { useTheme } from "@mui/material";
import { useCallback } from "react";
import { utils, writeFile } from "xlsx-js-style";
import { getWorksheet } from "./util";

interface ExportExcelButtonProps {
  data?: FeeExportDTO;
  mode?: FeesDisplayStyle;
  disabled?: boolean;
  isReview?: boolean;
}
export const ExportExcelButton = ({
  data,
  mode = FeesDisplayStyle.Dollar,
  disabled,
  isReview
}: ExportExcelButtonProps) => {
  const { palette } = useTheme();

  const handleOnDownload = useCallback(() => {
    if (!data) return;
    const workbook = utils.book_new();
    const worksheet = getWorksheet(data, mode);
    // Add the worksheet to the workbook
    utils.book_append_sheet(workbook, worksheet, "Analysis");
    // Save the workbook
    writeFile(workbook, `SuitabilityHub Fee ${isReview ? "Review" : "Analysis"} - ${data.name}.xlsx`);
  }, [data, mode, isReview]);

  return (
    <SHButton
      onClick={handleOnDownload}
      startIcon={
        <DownloadSVG width={12} height={13} color={palette.text.primary} />
      }
      variant="outlined"
      disableRipple
      disabled={disabled || !data}
      sx={{
        "&:active svg path": {
          fill: palette.common.white,
        },
      }}
    >
      Excel
    </SHButton>
  );
};
