import { SHFilterOption } from "@components/design-systems";
import { AdviserFirmStatus } from "@models/practice/enums/status";
import { UserStatus } from "@models/users/enums/status";

export const AdviserFirmStatusFilterOptions: SHFilterOption[] = [
  {
    label: "Active",
    value: AdviserFirmStatus.Active,
  },
  {
    label: "Disabled",
    value: AdviserFirmStatus.Disabled,
  },
  {
    label: "Archived",
    value: AdviserFirmStatus.Archived,
  },
];
export const DefaultAdviserFirmStatusFilterValue = [
  AdviserFirmStatus.Active,
  AdviserFirmStatus.Disabled,
];

export const DefaultAdviserUserStatusFilterValue = [
  UserStatus.Active,
  UserStatus.Disabled,
  UserStatus.Pending
]
