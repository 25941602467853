import { SelectedInvestmentDTO } from "@models/managed-accounts/entities/step/setup";
import { filter } from "lodash";

export const checkHasWeightZero = (
  selectedInvestments?: SelectedInvestmentDTO[],
): boolean => {
  const investmentHasWeightZero = filter(selectedInvestments, (item) => {
    return item.weight === 0 || item.weight === null;
  });

  return investmentHasWeightZero.length > 0;
};
