import { PDFSerialNumber } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/pages/components/serial";
import { PDFFooterLogoS3 } from "@pages/platform-analysis/components/buttons/export-pdf/config";
import { FIRST_PAGE_FOOTER_HEIGHT } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { PDFAnalysisOverview } from "@pages/platform-analysis/components/buttons/export-pdf/model";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Image, Text, View } from "@react-pdf/renderer";
import { isEmpty } from "lodash";

interface PDFPageFooterProps {
  data?: PDFAnalysisOverview;
  pageIndex?: number;
  totalPage?: number;
  isFirstPage?: boolean;
}

export const PDFPageFooter = (props: PDFPageFooterProps) => {
  return props?.isFirstPage ? <FirstPageFooter /> : <PageFooter {...props} />;
};

const FirstPageFooter = () => {
  const { pageStyle, typographyStyle } = useStyles();

  return (
    <View
      style={{
        ...pageStyle.footer,
        height: FIRST_PAGE_FOOTER_HEIGHT,
        marginRight: -45,
        marginBottom: 0,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "stretch",
      }}
    >
      <View
        style={{
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          width: 150,
        }}
      >
        <Text
          style={{
            ...typographyStyle.subtitle,
            color: "#FFFFFF",
          }}
          // hyphenationCallback={(word) => {
          //   return [word];
          // }}
        >
          www.suitabilityhub.com
        </Text>
      </View>

      <Image
        src={PDFFooterLogoS3}
        style={{
          width: 330,
          height: 250,

          right: 0,
          bottom: -30,
          position: "absolute",
        }}
      />
    </View>
  );
};

const PageFooter = ({ data, pageIndex, totalPage }: PDFPageFooterProps) => {
  const { pageStyle, typographyStyle } = useStyles();

  const { ownerName, supplierName, adviserName, practiceName, serial } =
    data ?? new PDFAnalysisOverview();

  return (
    <View style={pageStyle.footer}>
      {!isEmpty(adviserName) && !isEmpty(practiceName) ? (
        <Text style={typographyStyle.body2}>
          {`This analysis is for: `}
          <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
            {adviserName}
          </Text>
          {` from `}
          <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
            {practiceName}
          </Text>
        </Text>
      ) : (
        <Text style={typographyStyle.body2}>
          {`This analysis is created for `}
          <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
            {supplierName}
          </Text>
          {` internal use only.`}
        </Text>
      )}

      <Text style={typographyStyle.body2}>
        {`Created by: `}
        <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
          {ownerName}
        </Text>
        {` of `}
        <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
          {supplierName}
        </Text>
      </Text>
      <Text style={typographyStyle.body2}>
        Page {pageIndex} of {totalPage}
      </Text>
      <PDFSerialNumber serial={serial} />
    </View>
  );
};
