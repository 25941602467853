import {
  SHContainer,
  SHIconLoading,
  SHStack,
  SHTab,
  SHTabs,
  SHTypography,
} from "@components/design-systems";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import { PageRoutes, SocketClientEvent } from "@constants";
import { useEditProduct } from "@hooks/useEditProduct";
import { useIsNew } from "@hooks/useIsNew";
import { useProduct } from "@hooks/useProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBarPlacement } from "@layouts/top-bar";
import { HighlightType, SectionType } from "@models/configuration";
import { ProductListDTO } from "@models/product/entities/product";
import { SubProductDTO } from "@models/product/entities/subProduct";
import { ProductDataStatus } from "@models/product/enums/productDataStatus";
import { ProductBeingKickedOutDialog } from "@pages/platform-profiles/components/dialog-being-kick-out";
import { ConfirmKickOutDialog } from "@pages/platform-profiles/components/dialog-confirm-kick-out";
import { ConfirmKickOutAdminDialog } from "@pages/platform-profiles/components/dialog-kick-out-admin";

import {
  updateEditMode,
  updateProductData as updateProductDataStore,
  updateProductName,
  updateReleaseName,
  updateSelectedGroup,
  updateShowViewReleaseLog,
  updateSubProductName,
} from "@redux/slices/product";
import { RootState, useAppDispatch } from "@redux/store";
import { SignalRService } from "@services/signalr/signalRService";
import { isEmpty, some } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ChangeHistoryCard } from "./components/change-history";
import { ProductEditFeesAndRates } from "./components/fees-and-rates";
import { convertDataToSubmit } from "./components/fees-and-rates/util";
import { TopBarSkeleton } from "./components/skeletons/TopBarSkeleton";
import { ProductTabForm, ProductTabFormRef } from "./components/tab-form";
import { ProductGroup } from "@models/product/enums/productGroup";

export const ManageProductEdit = () => {
  const [searchParams] = useSearchParams();
  const {
    productGroups,
    productData,
    productChangeHistory,
    productUI: { selectedGroup, showViewReleaseLog, isLoadingProductGroups },
    productBannerInfo,
    subProHighlightTypeMapping,
  } = useSelector((state: RootState) => state.product);
  const { canViewProductFindBDMTab, canGoToEditProduct } = useUserPermissions();
  const { checkEditProduct, handleOnReleaseLockProductEditing } =
    useEditProduct();

  const dispatch = useAppDispatch();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { productId } = useParams<{ productId: string }>();
  const { supplierData } = useSelector((state: RootState) => state.supplier);
  const {
    createNewProduct,
    updateExtProduct,
    updateProductData,
    loadBannerInfo,
    resetBannerInfo,
    viewProductReleaseLog,
    refreshProductData,
    updateExtSubProducts,
    refreshSubProductData,
  } = useProduct();
  const isApproved = useMemo(() => {
    return some(productBannerInfo, ["status", "Approved"]);
  }, [productBannerInfo]);

  const isNew = useIsNew();
  const navigate = useNavigate();
  const tabFormRef = useRef<ProductTabFormRef | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedGroupId = productGroups[selectedGroup]?.id;
  const version = searchParams?.get("version") ?? undefined;
  const configurationVersion =
    searchParams?.get("configurationVersion") ?? undefined;
  const accessDenied = !canGoToEditProduct(isNew ? "new" : productId);

  useEffect(() => {
    const connection = SignalRService.getHubConnection();
    // In case SignalR service is restarted, check product editing again
    connection?.onreconnected(() => {
      if (productId && !isNew) {
        checkEditProduct(productId);
      }
    });

    return () => {
      connection?.off(SocketClientEvent.onReconnected);
      if (productId && !isNew) {
        handleOnReleaseLockProductEditing(productId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, isNew]);

  useEffect(() => {
    if (tabFormRef.current?.isFeesTab) return;
    if (productId && version && configurationVersion) {
      handleLoadProductDataByVersion(
        productId,
        version,
        configurationVersion,
        selectedGroupId,
      );
    } else {
      updateProductData({
        productId: productId ?? "new",
        groupId: selectedGroupId,
        mode: "edit",
      });
    }
    // eslint-disable-next-line
  }, [selectedGroupId, productId, version]);

  useEffect(() => {
    if (!productId || productId === "new" || !!version) {
      resetBannerInfo();
      dispatch(updateProductDataStore(undefined));
      dispatch(updateProductName(undefined));
    } else {
      loadBannerInfo(productId);
    }
    // eslint-disable-next-line
  }, [productId, version]);

  useEffect(() => {
    //Reset all when unmounted
    return () => {
      resetBannerInfo();
      dispatch(updateProductName(undefined));
      dispatch(updateSelectedGroup(0));
      dispatch(updateReleaseName(undefined));
      dispatch(updateShowViewReleaseLog(false));
      dispatch(updateSubProductName(undefined));
    };
    // eslint-disable-next-line
  }, []);

  const handleOnTabChange = async (value: string) => {
    let dataSubmit;
    dataSubmit = tabFormRef.current?.getValue();

    if (!dataSubmit) {
      return dispatch(updateSelectedGroup(+value));
    }

    setIsSubmitting(true);
    let status;
    if (tabFormRef.current?.isFeesTab) {
      status = await handleSubmitSubProduct(dataSubmit);
    } else {
      status = await handleSubmitProductGroup(dataSubmit);
    }
    setIsSubmitting(false);

    if (status?.isSuccess) {
      dispatch(updateSelectedGroup(+value));
    }
  };

  const handleOnSaveDraft = async (formData: ProductListDTO) => {
    setIsSubmitting(true);
    await handleSubmitProductGroup(formData as ProductListDTO);
    setIsSubmitting(false);
  };

  const handleLoadProductDataByVersion = (
    productId: string,
    version: string,
    configurationVersion: string,
    groupId: string,
  ) => {
    viewProductReleaseLog({
      productId,
      version,
      configurationVersion,
      groupId,
    }).then((data) => {
      if (data && tabFormRef.current) {
        tabFormRef.current.resetForm(data);
        dispatch(updateShowViewReleaseLog(true));
        dispatch(
          updateReleaseName(
            productChangeHistory?.find(
              (item) =>
                item?.productVersion === parseInt(version) && !item.isDraft,
            )?.name,
          ),
        );
      }
    });
  };

  useEffect(() => {
    if (tabFormRef.current?.isFeesTab) return;
    if (tabFormRef.current) {
      tabFormRef.current.resetForm(productData);
    }
  }, [productData]);

  const handleSubmitProductGroup = async (formData: ProductListDTO) => {
    if (formData.id) {
      //Update
      const productData = await updateExtProduct(formData);
      if (productData) {
        let productData: undefined | ProductListDTO = formData;
        if (isApproved) {
          productData = await refreshProductData("edit");
        }
        if (productData) {
          if (tabFormRef.current) tabFormRef.current.resetForm(productData);
          if (productId) loadBannerInfo(productId);
          return {
            isSuccess: true,
            isNew: false,
          };
        }
        return {
          isSuccess: false,
          isNew: false,
        };
      }
      return {
        isSuccess: false,
        isNew: false,
      };
    }
    //Create
    if (!supplierData) return;
    formData.supplierId = supplierId;
    formData.supplierName = supplierData?.companyName;
    formData.dataStatus = ProductDataStatus.MissingData;
    const newProductData = await createNewProduct(formData);
    if (newProductData) {
      if (newProductData.id) {
        checkEditProduct(newProductData.id);
      }
      dispatch(updateEditMode(true));
      navigate(
        generatePath(PageRoutes.supplierProducts, {
          supplierId,
          productId: newProductData.id,
        }),
        {
          replace: true,
        },
      );
      return {
        isSuccess: true,
        isNew: true,
        data: newProductData,
      };
    }
    return {
      isSuccess: false,
      isNew: true,
    };
  };

  const handleSubmitSubProduct = async (formData: SubProductDTO[]) => {
    if (!productId) return;
    const newData = convertDataToSubmit(formData);

    const productData = await updateExtSubProducts(productId, newData);

    if (productData) {
      let productData: undefined | SubProductDTO[] = formData;
      if (isApproved) {
        productData = await refreshSubProductData(productId, "edit");
      }
      if (productData) {
        if (tabFormRef.current) tabFormRef.current.resetForm(productData);
        if (productId) loadBannerInfo(productId);
        return {
          isSuccess: true,
          isNew: false,
        };
      }
      return {
        isSuccess: false,
        isNew: false,
      };
    }
    return {
      isSuccess: false,
      isNew: false,
    };
  };

  const renderTabs = () => {
    if (!productGroups || productGroups.length === 0) {
      return [];
    }
    const otherConfigTabs: SHTab[] = [
      {
        label: "Find BDM",
        component: (
          <SHTypography variant="subtitle2">Coming soon!</SHTypography>
        ),
        hidden: isNew || !canViewProductFindBDMTab,
        disabled: true,
      },
      {
        label: "Change history",
        component: <ChangeHistoryCard />,
        hidden: showViewReleaseLog || isNew,
        disabled: isSubmitting,
      },
    ];

    return productGroups
      .map(
        (group, index) =>
          ({
            label: group.name,
            dotGroupProps: {
              redDot:
                group.highlightType === HighlightType.Edited ||
                (group?.sectionType === SectionType.Fee &&
                  !isEmpty(subProHighlightTypeMapping)),
              blueDot: group.dataStatus === ProductDataStatus.MissingData && group.name !== ProductGroup.FeesAndRates,
            },
            icon:
              isSubmitting && selectedGroup === index ? (
                <SHIconLoading />
              ) : undefined,
            disabled:
              isSubmitting || (isNew && group?.sectionType === SectionType.Fee),
            component:
              group?.sectionType === SectionType.Fee ? (
                <ProductEditFeesAndRates
                  ref={tabFormRef}
                  onSaveDraft={(isSaving) => setIsSubmitting(isSaving)}
                />
              ) : (
                <ProductTabForm
                  ref={tabFormRef}
                  onSubmit={handleOnSaveDraft}
                  key={group.id}
                  group={group}
                  isSubmitting={isSubmitting}
                />
              ),
          } as SHTab),
      )
      .concat(otherConfigTabs);
  };

  if (accessDenied) return <AccessDeniedDialog />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack sx={{ paddingY: 3 }} spacing={3}>
        <TopBarPlacement />
        {isLoadingProductGroups && <TopBarSkeleton />}
        <SHTabs
          value={selectedGroup.toString()}
          onValueChange={handleOnTabChange}
          isHandleByProps
          isLoading={isLoadingProductGroups}
          tabs={renderTabs()}
        />
      </SHStack>
      {/* <PermissionDeniedDialog /> */}
      <ConfirmKickOutAdminDialog
        supplierId={supplierId}
        productId={productId}
      />
      <ConfirmKickOutDialog supplierId={supplierId} productId={productId} />
      <ProductBeingKickedOutDialog />
    </SHContainer>
  );
};
