import { SHStack, SHTypography } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { useIsNew } from "@hooks/useIsNew";
import { usePractice } from "@hooks/usePractice";
import { useReview } from "@hooks/useReview";
import { RootState } from "@redux/store";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";

export interface ReviewNameProps {}
export const ReviewName = () => {
  const isNew = useIsNew();
  const { reviewId } = useParams<{ reviewId: string }>();
  const {
    bannerInfo,
    ui: { isLoadingBanner },
  } = useSelector((state: RootState) => state.review);
  const { user } = useSelector((state: RootState) => state.auth);

  const { loadPractice } = usePractice();
  const { loadReviewBannerInfo } = useReview();

  const [adviserFirmName, setAdviserFirmName] = useState<string>("");

  const getAdviserFirm = async () => {
    const response = await loadPractice(
      user?.userMetadata?.adviser_firm_id ?? "",
    );
    setAdviserFirmName(response?.name ?? "");
  };

  const getBannerInfo = async () => {
    await loadReviewBannerInfo(reviewId ?? "");
  };

  useEffectOnce(() => {
    if (isNew) {
      getAdviserFirm();
    } else {
      getBannerInfo();
    }
  });

  if (isLoadingBanner)
    return (
      <SHStack direction="row" justifyContent="flex-start" width={"100%"}>
        <SHSkeleton height={30} width={"50%"} />
      </SHStack>
    );

  return (
    <SHStack
      sx={{
        flexGrow: 1,
        width: "1px",
      }}
    >
      <SHStack
        direction="row"
        justifyContent="flex-start"
        alignItems={"flex-end"}
        spacing={0.5}
      >
        <>
          <SHTypography
            variant="h2"
            fontSize={"18px"}
            lineHeight={1}
            truncate
            sx={{
              maxWidth: "60%",
            }}
          >
            {isEmpty(bannerInfo?.ownerName)
              ? "Adviser Name"
              : bannerInfo?.ownerName}
          </SHTypography>

          <SHTypography
            variant="h2"
            lineHeight={1}
            fontSize={"18px"}
            fontWeight={400}
            truncate
            sx={{
              maxWidth: "40%",
            }}
          >
            {`of ${
              isEmpty(bannerInfo?.adviserFirmName)
                ? adviserFirmName
                : bannerInfo?.adviserFirmName
            }`}
          </SHTypography>
        </>
      </SHStack>
      <SHStack
        direction="row"
        justifyContent="flex-start"
        alignItems={"flex-end"}
        spacing={0.5}
      >
        <>
          <SHTypography
            variant="h2"
            lineHeight={1}
            fontSize={"18px"}
            fontWeight={400}
            truncate
            sx={{
              maxWidth: "40%",
            }}
          >
            {`for`}
          </SHTypography>
          <SHTypography
            variant="h2"
            fontSize={"18px"}
            lineHeight={1}
            truncate
            sx={{
              maxWidth: "60%",
            }}
          >
            {isEmpty(bannerInfo?.familyGroupName)
              ? "Family Group"
              : bannerInfo?.familyGroupName}
          </SHTypography>
        </>
      </SHStack>
    </SHStack>
  );
};
