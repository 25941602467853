import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { AnalysisGetFeatureSectionsDTO } from "@models/platform-analysis/entities/steps/feature";
import { compileFeatureSelection } from "@services/platform-analysis/util";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getFeatureSelectionStep(
  platformAnalysisId: string,
): Promise<APIResponse<AnalysisGetFeatureSectionsDTO>> {
  const response = await APIGatewayService.get<AnalysisGetFeatureSectionsDTO>(
    generatePath(APIExtRoutes.platformAnalysisFeatureSelections, {
      platformAnalysisId,
    }),
  );
  if (response?.data) {
    compileFeatureSelection(response?.data);
  }

  return handleErrorProxy(response);
}

export async function putFeatureSelectionStep(
  platformAnalysisId: string,
  featureSelections: AnalysisGetFeatureSectionsDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<AnalysisGetFeatureSectionsDTO>(
    generatePath(APIExtRoutes.platformAnalysisFeatureSelections, {
      platformAnalysisId,
    }),
    featureSelections,
  );
  return handleErrorProxy(response, "Changes have been saved.");
}
