import { FeePortfolioDetailDTO } from "@models/platform-analysis/entities/portfolioDetails";
import {
  AnalysisFeeCalculatorDTO,
  AnalysisFeeDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";

export class ReviewGetFeeCalculatorDTO extends AnalysisFeeCalculatorDTO {}

export interface ReviewStepFeeDTO {
  all: ReviewStepFeeContainerDTO;
  shortlisted: ReviewStepFeeContainerDTO;
  disqualified: ReviewStepFeeContainerDTO;
}
export interface ReviewStepFeeContainerDTO extends AnalysisFeeDTO {}

export interface ExportableReviewStepFeeDTO extends ReviewStepFeeDTO {
  name?: string;
  ownerName?: string;
  adviserFirmName?: string;
  familyGroupName?: string;
  serial?: string;
  feesDisplayStyle?: FeesDisplayStyle;
  feeMissingInvestmentsStyle?: FeeMissingInvestmentsOption;
  investmentMenu?: FeeInvestmentMenuOption;
  feeTableViewMode?: FeeHeatmapMode;
  lastModifiedDate?: Date;
  feePortfolioDetails?: FeePortfolioDetailDTO;
}
