import { SHStack, SHStackProps } from "@components/design-systems";
import { FeatureItemProps } from "@layouts/review/transfer-select-feature-list/components/feature-item";
import {
  FeatureList,
  FeatureListProps,
} from "@layouts/review/transfer-select-feature-list/components/feature-list";
import { FieldTitle } from "@layouts/review/transfer-select-feature-list/components/field-title";
import { ReviewConfigurationFeatureDTO } from "@models/reviews/entities/steps/feature";
import { isEmpty } from "lodash";

export interface TransferSelectFeatureListProps extends SHStackProps {
  features?: ReviewConfigurationFeatureDTO[];
  handleOnSelectDeselectAll?: (
    subGroupId?: string,
    totalFeature?: number,
    isDeselect?: boolean,
  ) => void;
}

export const TransferSelectFeatureList = ({
  subGroupId,
  features = [],
  isDeselect = false,
  handleOnSelectDeselect,
  handleOnSelectDeselectEssential,
  handleOnBothSelect,
  handleOnSelectDeselectAll,
  ...others
}: TransferSelectFeatureListProps & FeatureListProps & FeatureItemProps) => {
  const selectFeature = features?.filter((item) => !item.isSelected);
  const selectedFeature = features?.filter((item) => item.isSelected);

  return (
    <SHStack spacing={3} {...others}>
      <SHStack
        direction="row"
        justifyContent="space-between"
        alignItems={"flex-start"}
        gap="25px"
      >
        <FieldTitle
          width={"50%"}
          title={"Select features"}
          actionText={"Select all"}
          subTitle={"Choose which features to include in the review"}
          handleOnClick={() =>
            typeof handleOnSelectDeselectAll === "function"
              ? handleOnSelectDeselectAll(
                  subGroupId,
                  selectFeature?.length,
                  false,
                )
              : {}
          }
          isDisable={isEmpty(selectFeature)}
        />
        <FieldTitle
          width={"50%"}
          title={"Selected features"}
          actionText={"Deselect all"}
          subTitle={
            isEmpty(selectedFeature)
              ? "You haven’t selected any features yet. Click on one of the features in the column to the left."
              : " Features to be included in the review. Click to remove them"
          }
          handleOnClick={() =>
            typeof handleOnSelectDeselectAll === "function"
              ? handleOnSelectDeselectAll(
                  subGroupId,
                  selectedFeature?.length,
                  true,
                )
              : {}
          }
          isDisable={isEmpty(selectedFeature)}
        />
      </SHStack>
      <SHStack
        direction="row"
        justifyContent="space-between"
        alignItems={"flex-start"}
        gap="25px"
      >
        <FeatureList
          subGroupId={subGroupId}
          features={selectFeature}
          handleOnSelectDeselect={handleOnSelectDeselect}
          handleOnSelectDeselectEssential={handleOnSelectDeselectEssential}
          handleOnBothSelect={handleOnBothSelect}
        />
        <FeatureList
          isDeselect
          subGroupId={subGroupId}
          features={selectedFeature}
          handleOnSelectDeselect={handleOnSelectDeselect}
          handleOnSelectDeselectEssential={handleOnSelectDeselectEssential}
          handleOnBothSelect={handleOnBothSelect}
        />
      </SHStack>
    </SHStack>
  );
};
