import { DeleteButton } from "@components/buttons/delete";
import {
  SHBox,
  SHButton,
  SHContainer,
  SHStack,
} from "@components/design-systems";
import { SHAlert } from "@components/design-systems/sh-alert";
import { SHDivider } from "@components/design-systems/sh-divider";
import { SHFormControlLabel } from "@components/design-systems/sh-form-control-label";
import { SHRadio } from "@components/design-systems/sh-radio";
import { SHRadioGroup } from "@components/design-systems/sh-radio-group";
import { SHTextField } from "@components/design-systems/sh-text-field";
import { uploadOptions } from "@components/design-systems/sh-upload-file/config";
import { SHUploadImage } from "@components/design-systems/sh-upload-image";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import ResetPasswordDialog from "@components/dialogs/reset-password";
import UnsavedDialog from "@components/dialogs/unsaved";
import { StatusBadge } from "@components/status-badge";
import { PageRoutes } from "@constants/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppParams } from "@hooks/useAppParams";
import { useIsNew } from "@hooks/useIsNew";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar } from "@layouts/top-bar";
import { UserType } from "@models/auth";
import { PageMode, ParentState } from "@models/core";
import {
  CreateUserDTO,
  GetUserInfoDTO,
  UpdateUserDTO,
} from "@models/users/entities/user";
import { UserStatus } from "@models/users/enums/status";
import { Link } from "@mui/material";
import {
  generateFormTitle,
  generateSubTitle,
  userValidators,
} from "@pages/user/_id/util";
import { RootState } from "@redux/store";
import { updateUser as updateUserAuth } from "@redux/slices/auth";
import {
  deleteAvatar,
  uploadAvatar,
} from "@services/file-storage/fileStorageService";
import {
  createUser,
  getUser,
  resendInvite,
  resetPassword,
  updateUser,
  updateUserStatus,
} from "@services/users/userService";
import { theme } from "@themes";
import { generateGAvatarUrl } from "@utils";
import { renameFile } from "@utils/data-type/file";
import { isEmpty, isNil } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { FileRejection } from "react-dropzone";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ManageUserSkeleton from "./skeleton";

export const ManageUser = () => {
  const isNew = useIsNew();
  const navigate = useNavigate();
  const location = useLocation();
  const { notify } = useNotification();
  const { userId } = useParams<{ userId: string }>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { isMyProfile } = useAppParams();
  const {
    canViewAdminProfile,
    canSaveAdminProfile,
    canCreateAdmin,
    canChangeAdminType,
    canDisableAdmin,
    canEnableAdmin,
    canArchiveAdmin,
    canUnArchiveAdmin,
    canResendInviteAdmin,
  } = useUserPermissions();
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSendingResetPassword, setSendingResetPassword] = useState(false);
  const [uploadMessage, setUploadMessage] = useState<string | null>(null);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
  const [loadingResendInvite, setLoadingResendInvite] = useState(false);
  const [isChangingStatus, setIsChangingStatus] = useState<UserStatus | null>();
  const [isLoadingUser, setIsLoadingUser] = useState(false);

  const {
    watch,
    reset,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<GetUserInfoDTO>({
    mode: "onChange",
    resolver: yupResolver(userValidators),
    defaultValues: { ...new GetUserInfoDTO() },
  });
  const { isDirty } = useFormState({ control });
  const parentState = location.state as ParentState;

  const isDisabled = useMemo(() => {
    return isSubmitting || !isEditMode;
  }, [isSubmitting, isEditMode]);

  const isDisableAccountType = useMemo(() => {
    return isDisabled || !canChangeAdminType;
  }, [isDisabled, canChangeAdminType]);

  const currentStatus = getValues("status") ?? null;

  const subTitleFields = generateSubTitle(isMyProfile);
  const showLinkChangePassword =
    !isNew &&
    isEditMode &&
    !isNil(currentStatus) &&
    ![UserStatus.Disabled, UserStatus.Archived]?.includes(currentStatus);

  useEffect(() => {
    loadUserDetails(userId);
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    setIsEditMode(parentState?.pageMode === PageMode.Edit || isNew);
  }, [parentState?.pageMode, userId, isNew]);

  // CRUD
  const loadUserDetails = async (userId?: string) => {
    if (isNew) return;
    setIsLoadingUser(true);
    const { data, message } = await getUser(userId);
    setIsLoadingUser(false);

    if (data) {
      reset(data);
    } else {
      navigate(`${PageRoutes.users}`, {
        state: { notification: message },
      });
    }
  };

  const handleOnCreateUser = async (userInfo: GetUserInfoDTO) => {
    const newUser: CreateUserDTO = {
      email: userInfo.email,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      userType: userInfo.userType,
      avatarUrl: !isEmpty(userInfo?.avatarUrl)
        ? userInfo?.avatarUrl
        : generateGAvatarUrl(
            userInfo.email,
            userInfo.firstName,
            userInfo.lastName,
          ),
      avatarS3Key: !isEmpty(userInfo?.avatarS3Key)
        ? userInfo?.avatarS3Key
        : null,
    };

    const { data, message } = await createUser(newUser);
    if (data) {
      notify(message, {
        variant: "success",
        close: true,
      });
      reset(data as GetUserInfoDTO);
      setIsEditMode(false);
      navigate(`${PageRoutes.users}/${data?.auth0Id}`, {
        replace: false,
      });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnUpdateUser = async (userInfo: GetUserInfoDTO) => {
    const newUser: UpdateUserDTO = {
      email: userInfo.email,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      userStatus: userInfo?.status,
      userType: userInfo.userType,
      avatarUrl: !isEmpty(userInfo?.avatarUrl)
        ? userInfo?.avatarUrl
        : generateGAvatarUrl(
            userInfo.email,
            userInfo.firstName,
            userInfo.lastName,
          ),
      avatarS3Key: !isEmpty(userInfo?.avatarS3Key)
        ? userInfo?.avatarS3Key
        : null,
    };
    const { isSuccess, message } = await updateUser(userInfo.auth0Id, newUser);

    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });

      if (isMyProfile) {
        dispatch(
          updateUserAuth({
            ...user,
            ...newUser,
            userMetadata: user?.userMetadata,
          } as GetUserInfoDTO),
        );
      }
      reset(getValues());
      setIsEditMode(false);
      navigate(`${PageRoutes.users}/${userId}`, {
        replace: false,
      });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  // Handle file storage functions
  const handleOnUploadAvatar = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (!isEmpty(fileRejections))
      setUploadMessage("Unsupported file format. Please upload another file.");

    if (isEmpty(acceptedFiles)) return;

    const file = new FormData();
    file.append("file", renameFile(acceptedFiles[0]));

    setIsUploading(true);
    const { data, message } = await uploadAvatar(file);
    if (data) {
      setValue("avatarUrl", data?.url, uploadOptions.hookForm);
      setValue("avatarS3Key", data?.key, uploadOptions.hookForm);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setIsUploading(false);
  };

  const handleOnDeleteAvatar = async () => {
    const avatarS3Key = getValues("avatarS3Key");
    setIsDeleting(true);
    const { isSuccess, message } = await deleteAvatar(avatarS3Key);
    setIsDeleting(false);
    if (isSuccess) {
      setValue("avatarS3Key", "", uploadOptions.hookForm);
      setValue(
        "avatarUrl",
        generateGAvatarUrl(
          getValues("email"),
          getValues("firstName"),
          getValues("lastName"),
        ),
        uploadOptions.hookForm,
      );
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  // Handle action buttons
  const handleOnResendInvite = async () => {
    setLoadingResendInvite(true);
    const { isSuccess, message } = await resendInvite(userId);

    setLoadingResendInvite(false);

    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setLoadingResendInvite(false);
  };

  const handleOnResetPassword = async () => {
    setSendingResetPassword(true);
    const { isSuccess, message } = await resetPassword(getValues("email"));
    setSendingResetPassword(false);
    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setSendingResetPassword(false);
    setShowResetPasswordDialog(false);
  };

  const handleOnUpdateStatus = async (status: UserStatus) => {
    setIsChangingStatus(status);
    const { isSuccess, message, data } = await updateUserStatus(userId, status);
    setIsChangingStatus(null);
    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      reset({ ...getValues(), status: data?.status });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setIsChangingStatus(null);
  };

  const onChangeAvatarUrlByField = (
    email?: string,
    firstName?: string,
    lastName?: string,
  ) => {
    if (isEmpty(getValues("avatarS3Key"))) {
      setValue(
        "avatarUrl",
        generateGAvatarUrl(
          email ?? getValues("email"),
          firstName ?? getValues("firstName"),
          lastName ?? getValues("lastName"),
        ),
      );
    }
  };

  if (!canViewAdminProfile || (isNew && !canCreateAdmin))
    return <AccessDeniedDialog />;
  if (isLoadingUser) {
    return <ManageUserSkeleton />;
  }
  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <form>
        <SHStack sx={{ paddingY: 3 }} spacing={3}>
          <TopBar
            title={generateFormTitle(watch("firstName"), watch("lastName"))}
            customBadge={
              currentStatus ? <StatusBadge status={currentStatus} /> : undefined
            }
            tools={
              <>
                {isNew && (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    isLoading={isSubmitting}
                    disabled={!isValid || isSubmitting}
                    onClick={handleSubmit(handleOnCreateUser)}
                  >
                    Create and send welcome
                  </SHButton>
                )}
                {!isEditMode && (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    onClick={() => setIsEditMode(true)}
                  >
                    Edit
                  </SHButton>
                )}
                {!isNew && isEditMode && (
                  <>
                    {!isMyProfile && (
                      <>
                        {(currentStatus === UserStatus.Active ||
                          currentStatus === UserStatus.Pending) &&
                          canDisableAdmin && (
                            <SHButton
                              variant="outlined"
                              size="extraMedium"
                              isLoading={
                                isChangingStatus === UserStatus.Disabled
                              }
                              disabled={
                                isChangingStatus === UserStatus.Disabled
                              }
                              onClick={() =>
                                handleOnUpdateStatus(UserStatus.Disabled)
                              }
                            >
                              Disable
                            </SHButton>
                          )}
                        {currentStatus === UserStatus.Pending &&
                          canResendInviteAdmin && (
                            <SHButton
                              variant="outlined"
                              size="extraMedium"
                              onClick={handleOnResendInvite}
                              isLoading={loadingResendInvite}
                              disabled={loadingResendInvite}
                            >
                              Resend invite
                            </SHButton>
                          )}
                        {currentStatus === UserStatus.Disabled && (
                          <>
                            {canEnableAdmin && (
                              <SHButton
                                variant="outlined"
                                size="extraMedium"
                                isLoading={
                                  isChangingStatus === UserStatus.Active
                                }
                                disabled={
                                  isChangingStatus === UserStatus.Active
                                }
                                onClick={() =>
                                  handleOnUpdateStatus(UserStatus.Active)
                                }
                              >
                                Enable
                              </SHButton>
                            )}
                            {canArchiveAdmin && (
                              <SHButton
                                variant="outlined"
                                size="extraMedium"
                                disabled={
                                  isChangingStatus === UserStatus.Archived
                                }
                                isLoading={
                                  isChangingStatus === UserStatus.Archived
                                }
                                onClick={() =>
                                  handleOnUpdateStatus(UserStatus.Archived)
                                }
                              >
                                Archive
                              </SHButton>
                            )}
                          </>
                        )}
                        {currentStatus === UserStatus.Archived &&
                          canUnArchiveAdmin && (
                            <SHButton
                              variant="outlined"
                              size="extraMedium"
                              isLoading={
                                isChangingStatus === UserStatus.Disabled
                              }
                              disabled={
                                isChangingStatus === UserStatus.Disabled
                              }
                              onClick={() =>
                                handleOnUpdateStatus(UserStatus.Disabled)
                              }
                            >
                              Unarchive
                            </SHButton>
                          )}
                      </>
                    )}
                    {canSaveAdminProfile && (
                      <SHButton
                        variant="contained"
                        size="extraMedium"
                        isLoading={isSubmitting}
                        disabled={!isValid || isSubmitting || !isDirty}
                        onClick={handleSubmit(handleOnUpdateUser)}
                      >
                        Save
                      </SHButton>
                    )}
                  </>
                )}
              </>
            }
          />
          <SHBox>
            <FieldGroup
              firstLine
              title="Profile"
              subTitle={subTitleFields?.Profile}
            />
            <SHDivider />

            <FieldGroup
              title="Profile photo"
              subTitle={subTitleFields?.ProfilePhoto}
            >
              <Controller
                name="avatarUrl"
                control={control}
                render={({ field }) => (
                  <SHUploadImage
                    isUploading={isUploading}
                    src={field.value}
                    actionIcon={
                      !isEmpty(getValues("avatarS3Key")) &&
                      isEditMode && (
                        <DeleteButton
                          isLoading={isDeleting}
                          onClick={handleOnDeleteAvatar}
                        />
                      )
                    }
                    dropzoneOptions={{
                      multiple: false,
                      accept: {
                        "image/*": [".svg", ".png", ".jpg", ".gif"],
                      },
                      onDrop: handleOnUploadAvatar,
                      disabled: isDisabled || isUploading,
                    }}
                  />
                )}
              />
              {uploadMessage && (
                <SHStack
                  direction={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  sx={{ paddingTop: 3 }}
                >
                  <SHAlert
                    onClose={() => setUploadMessage(null)}
                    severity="error"
                    width={490}
                  >
                    {uploadMessage}
                  </SHAlert>
                </SHStack>
              )}
            </FieldGroup>
            <SHDivider />

            <FieldGroup
              title="Personal details"
              subTitle={subTitleFields?.PersonalDetails}
            >
              <SHStack spacing={2} sx={{ paddingY: 1 }} direction="row">
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: "First name required" }}
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      required
                      sx={{ width: { xs: "100%", md: 250 } }}
                      label="First name"
                      disabled={isDisabled}
                      error={!!errors.firstName}
                      helperText={
                        errors.firstName ? errors.firstName.message : null
                      }
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        onChangeAvatarUrlByField(
                          undefined,
                          event.target.value,
                          undefined,
                        );
                      }}
                    />
                  )}
                />
                <Controller
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      required
                      sx={{ width: { xs: "100%", md: 250 } }}
                      label={"Last name"}
                      disabled={isDisabled}
                      error={!!errors.lastName}
                      helperText={
                        errors.lastName ? errors.lastName.message : null
                      }
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        onChangeAvatarUrlByField(
                          undefined,
                          undefined,
                          event.target.value,
                        );
                      }}
                    />
                  )}
                  name="lastName"
                  control={control}
                  rules={{ required: "Last name required" }}
                />
              </SHStack>
              <SHStack
                direction={"row"}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                sx={{ paddingTop: 3 }}
              >
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: "Email is required" }}
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      required
                      type="email"
                      label={"Work email"}
                      sx={{ width: { xs: "100%", md: 520 } }}
                      disabled={isDisabled || !isNew}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : null}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        onChangeAvatarUrlByField(
                          event.target.value,
                          undefined,
                          undefined,
                        );
                      }}
                    />
                  )}
                />
              </SHStack>
              {showLinkChangePassword && (
                <SHStack
                  direction={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  sx={{ paddingTop: 3 }}
                >
                  <Link
                    variant="body2"
                    color={theme.palette.text.secondary}
                    component="label"
                    underline="none"
                    onClick={() => setShowResetPasswordDialog(true)}
                    sx={{
                      paddingRight: 0.5,
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    {subTitleFields?.ChangePassword}
                  </Link>
                </SHStack>
              )}
            </FieldGroup>
            <SHDivider />

            <FieldGroup
              title="Account type"
              subTitle={subTitleFields?.AccountType}
            >
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <SHRadioGroup
                    {...field}
                    sx={{ width: { xs: "100%", md: 520 } }}
                  >
                    <SHFormControlLabel
                      value={UserType.SuperAdmin.toString()}
                      control={<SHRadio />}
                      label="Super administrator"
                      disabled={isDisableAccountType}
                      subLabel="Can do everything an administrator can do. In addition: can publish changes made by Suppliers and Administrators, and can CRUD Administrators"
                    />
                    <SHFormControlLabel
                      value={UserType.StandardAdmin.toString()}
                      label="Standard administrator"
                      disabled={isDisableAccountType}
                      control={<SHRadio />}
                      subLabel="Can review changes from suppliers and make their own changes, but cannot publish. Can CRUD Supplier and Adviser users"
                    />
                  </SHRadioGroup>
                )}
                name="userType"
                control={control}
                rules={{ required: true }}
              />
            </FieldGroup>
          </SHBox>
        </SHStack>

        <UnsavedDialog isDirty={isDirty && !isSubmitting} />

        {showResetPasswordDialog && (
          <ResetPasswordDialog
            onClose={() => setShowResetPasswordDialog(false)}
            onYes={handleOnResetPassword}
            isSending={isSendingResetPassword}
            isMyProfile={isMyProfile}
          />
        )}
      </form>
    </SHContainer>
  );
};
