import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { ReviewGetFeatureSectionsDTO } from "@models/reviews/entities/steps/feature";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";
import { compileFeatureSelection } from "./util";

export async function getFeatureSelectionStep(
  reviewId: string,
): Promise<APIResponse<ReviewGetFeatureSectionsDTO>> {
  const response = await APIGatewayService.get<ReviewGetFeatureSectionsDTO>(
    generatePath(APIExtRoutes.reviewFeatureSelections, {
      reviewId,
    }),
  );
  if (response?.data) {
    compileFeatureSelection(response?.data);
  }

  return handleErrorProxy(response);
}

export async function putFeatureSelectionStep(
  reviewId: string,
  featureSelections: ReviewGetFeatureSectionsDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<ReviewGetFeatureSectionsDTO>(
    generatePath(APIExtRoutes.reviewFeatureSelections, {
      reviewId,
    }),
    featureSelections,
  );
  return handleErrorProxy(response, "Changes have been saved.");
}
