import DefaultProductLogo from "@assets/images/logo/default-product-logo.png";
import { DeleteButton } from "@components/buttons/delete";
import { PlusButton } from "@components/buttons/plus";
import { RollBackButton } from "@components/buttons/rollback";
import {
  SHAvatar,
  SHStack,
  SHTableHead,
  SHTableHeadProps,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import {
  CheckCircleSVG,
  CrossCircleSVG,
  EditSVG,
  PlusSVG,
  WarningSVG,
} from "@components/svgs";
import { ReviewDecisionStatus } from "@models/reviews/entities/status";
import { ReviewSelectedProductDTO } from "@models/reviews/entities/steps/feature";
import { useTheme } from "@mui/material";
import {
  FeatureReviewTableCell,
  FeatureReviewTableCellProps,
} from "@pages/reviews/_id/steps/feature/analysis/components/table-cell";
import { UpdateDecisionStatus } from "@pages/reviews/_id/steps/feature/analysis/config";
import { generateReviewColorCell } from "@pages/reviews/_id/steps/feature/analysis/util";
import { RootState } from "@redux/store";
import { sortAscByOrder } from "@utils";
import { every, isEmpty, some } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
export interface BusinessTableHeadProps {
  selectedProducts?: ReviewSelectedProductDTO[];
  updatingDecisionStatus?: UpdateDecisionStatus;
  onChangeDecisionStatus?: (
    platformId: string,
    status: ReviewDecisionStatus,
  ) => void;
  onModifyComment?: (platformId: string, comment?: string) => void;
}

export const BusinessTableHead = ({
  selectedProducts,
  updatingDecisionStatus,
  onChangeDecisionStatus,
  onModifyComment,
  ...others
}: BusinessTableHeadProps & FeatureReviewTableCellProps & SHTableHeadProps) => {
  const { isReadOnly } = useSelector((state: RootState) => state.review);
  const { palette } = useTheme();
  const hiddenBannerStatus = useMemo(
    () =>
      every(selectedProducts, {
        decisionStatus: ReviewDecisionStatus.Undecided,
      }) &&
      !some(selectedProducts, {
        isMissingEssential: true,
      }),
    [selectedProducts],
  );

  const renderTableHeadBanner = (
    decisionStatus?: ReviewDecisionStatus,
    isMissingEssential?: boolean,
  ) => {
    const bannerProps = {
      height: "25px",
      sx: {
        margin: "15px -16px -15px -16px",
        transition: "all 0.5s ease",
      },
      className: "bannerContentStack",
    };

    if (decisionStatus === ReviewDecisionStatus.Undecided) {
      if (isMissingEssential) {
        return (
          <SHStack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
            bgcolor={palette.error[300]}
            {...bannerProps}
          >
            <WarningSVG />
            <SHTypography
              variant="body3"
              colorVariant="white"
              paddingBottom="-2px"
              fontSize="12px"
            >
              Missing essential features
            </SHTypography>
          </SHStack>
        );
      } else {
        return <SHStack direction="row" {...bannerProps}></SHStack>;
      }
    }

    if (decisionStatus === ReviewDecisionStatus.Disqualified) {
      return (
        <SHStack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          bgcolor={palette.text.disabled}
          {...bannerProps}
        >
          <CrossCircleSVG />
          <SHTypography
            variant="body3"
            colorVariant="white"
            paddingBottom="-2px"
            fontSize="12px"
          >
            Disqualified
          </SHTypography>
        </SHStack>
      );
    }

    if (decisionStatus === ReviewDecisionStatus.Shortlisted) {
      return (
        <SHStack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          bgcolor={palette.success.light}
          {...bannerProps}
        >
          <CheckCircleSVG />
          <SHTypography
            variant="body3"
            colorVariant="white"
            paddingBottom="-2px"
            fontSize="12px"
          >
            Added to shortlist
          </SHTypography>
        </SHStack>
      );
    }

    return null;
  };

  const renderTableHead = (platform: ReviewSelectedProductDTO) => {
    return (
      <SHStack
        className={"productNameHeaderCell"}
        spacing={0.5}
        sx={{
          transition: "all 0.5s ease",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <SHStack
          gap={"10px"}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ transition: "all 0.5s ease", height: "100%" }}
          className="productStack"
        >
          <SHStack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ transition: "all 0.5s ease" }}
            className="productStack__productInfo"
            spacing={0.5}
          >
            <SHAvatar
              src={
                platform.productLogo?.url
                  ? platform.productLogo?.url
                  : DefaultProductLogo
              }
              width={68}
              height={68}
              isCircular={false}
              className="productStack__Image"
            />
            <SHTypography
              variant="subtitle1"
              sx={{
                fontSize: 15,
                fontWeight: 700,
                transition: "all 0.5s ease",
                wordBreak: "break-word",
                lineHeight: "110%",
              }}
              className="productStack__productName"
            >
              {platform.productName}
            </SHTypography>
          </SHStack>
          <SHStack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            className="productStack__Overall"
          >
            <SHTypography
              variant="body3"
              colorVariant="third"
              style={{ transition: "all 0.5s ease" }}
              className="overall-text"
            >
              Overall
            </SHTypography>
            <SHTypography
              variant="subtitle1"
              lineHeight={"150%"}
              sx={{
                fontWeight: 700,
                fontSize: 24,
                transition: "all 0.5s ease",
              }}
              className="overall-percent"
            >
              {`${platform.totalScore} %`}
            </SHTypography>
          </SHStack>
        </SHStack>

        <SHStack
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <SHStack
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
            className="buttonStack"
          >
            <>
              {platform.decisionStatus === ReviewDecisionStatus.Undecided ? (
                <PlusButton
                  variant="outlined"
                  size="medium"
                  disabled={
                    (updatingDecisionStatus &&
                      (updatingDecisionStatus?.platformId !== platform.id ||
                        updatingDecisionStatus.decisionStatus !==
                          ReviewDecisionStatus.Shortlisted)) ||
                    isReadOnly
                  }
                  isLoading={
                    updatingDecisionStatus?.platformId === platform.id &&
                    updatingDecisionStatus.decisionStatus ===
                      ReviewDecisionStatus.Shortlisted
                  }
                  startIcon={<PlusSVG />}
                  onClick={
                    typeof onChangeDecisionStatus === "function"
                      ? () =>
                          onChangeDecisionStatus(
                            platform.id,
                            ReviewDecisionStatus.Shortlisted,
                          )
                      : () => {}
                  }
                  className="shortListButton"
                >
                  Shortlist
                </PlusButton>
              ) : (
                <PlusButton
                  variant="outlined"
                  size="medium"
                  disabled={isReadOnly}
                  startIcon={
                    isEmpty(platform.comment?.comment) ? (
                      <PlusSVG />
                    ) : (
                      <EditSVG />
                    )
                  }
                  onClick={
                    typeof onModifyComment === "function"
                      ? () =>
                          onModifyComment(
                            platform.id,
                            platform?.comment?.comment,
                          )
                      : () => {}
                  }
                  className="commentButton"
                >
                  Comment
                </PlusButton>
              )}
              {platform.decisionStatus === ReviewDecisionStatus.Disqualified ? (
                <RollBackButton
                  backgroundColor={"transparent"}
                  borderColor={palette.common.black}
                  disabled={
                    (updatingDecisionStatus &&
                      (updatingDecisionStatus?.platformId !== platform.id ||
                        updatingDecisionStatus.decisionStatus !==
                          ReviewDecisionStatus.Undecided)) ||
                    isReadOnly
                  }
                  isLoading={
                    updatingDecisionStatus?.platformId === platform.id &&
                    updatingDecisionStatus.decisionStatus ===
                      ReviewDecisionStatus.Undecided
                  }
                  onClick={
                    typeof onChangeDecisionStatus === "function"
                      ? () =>
                          onChangeDecisionStatus(
                            platform.id,
                            ReviewDecisionStatus.Undecided,
                          )
                      : () => {}
                  }
                  className="rollbackButton"
                />
              ) : (
                <DeleteButton
                  height={32}
                  width={32}
                  backgroundColor={"transparent"}
                  borderColor={palette.common.black}
                  disabled={
                    (updatingDecisionStatus &&
                      (updatingDecisionStatus?.platformId !== platform.id ||
                        updatingDecisionStatus.decisionStatus !==
                          ReviewDecisionStatus.Disqualified)) ||
                    isReadOnly
                  }
                  isLoading={
                    updatingDecisionStatus?.platformId === platform.id &&
                    updatingDecisionStatus.decisionStatus ===
                      ReviewDecisionStatus.Disqualified
                  }
                  onClick={
                    typeof onChangeDecisionStatus === "function"
                      ? () =>
                          onChangeDecisionStatus(
                            platform.id,
                            ReviewDecisionStatus.Disqualified,
                          )
                      : () => {}
                  }
                  className="disqualifyButton"
                />
              )}
            </>
          </SHStack>
        </SHStack>
        {!hiddenBannerStatus && (
          <SHStack marginTop={"0px !important"}>
            {renderTableHeadBanner(
              platform.decisionStatus,
              platform.isMissingEssential,
            )}
          </SHStack>
        )}
      </SHStack>
    );
  };

  return (
    <SHTableHead
      sx={{
        height: selectedProducts?.length === 0 ? 265 : undefined,
      }}
      {...others}
    >
      <SHTableRow>
        <FeatureReviewTableCell isFirstColumn colorCell="White">
          <SHTypography
            variant={"subtitle1"}
            lineHeight={"150%"}
            sx={{
              fontWeight: 700,
            }}
          >
            Business metrics
          </SHTypography>
        </FeatureReviewTableCell>
        {sortAscByOrder(selectedProducts ?? [])?.map((platform, index) => (
          <FeatureReviewTableCell
            key={index}
            colorCell={
              generateReviewColorCell(
                platform.decisionStatus,
                platform.isMissingEssential,
              ) ?? "White"
            }
          >
            {renderTableHead(platform)}
          </FeatureReviewTableCell>
        ))}
      </SHTableRow>
    </SHTableHead>
  );
};
