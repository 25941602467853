import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHDivider,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHBooleanIcon } from "@components/design-systems/sh-boolean-icon";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import { ImageGalleryBox } from "@components/image-gallery";
import { ImageSVG } from "@components/svgs";
import { PageRoutes } from "@constants";
import { ProductCollapseMenu } from "@layouts/products/collapse-menu";
import { ProductFeaturesLayout } from "@layouts/products/features";
import { UserType } from "@models/auth";
import { ConfigurationFeature, FieldTypeId } from "@models/configuration";
import { Link, useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";
import { toNativeValue } from "../../edit/components/tab-form/util";
import { FeatureViewLongText } from "./FeatureViewLongText";
import SubSectionTitle from "./SubSectionTitle";

export const ProductViewFeature = () => {
  const {
    productData,
    productUI: { isLoadingProductData },
  } = useSelector((state: RootState) => state.product);
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { palette } = useTheme();

  const [selectedIndex, setSelectedIndex] = useState<[number, number]>([0, 0]);
  const sections = productData?.groups[0].sections;
  const selectedSubSection = useMemo(() => {
    return sections?.[selectedIndex[0]]?.subSections?.[selectedIndex[1]];
  }, [sections, selectedIndex]);

  const renderFeatureItem = (feature: ConfigurationFeature) => {
    const isSlideShow = feature.fieldType?.id === FieldTypeId.Slideshow;

    const value = toNativeValue(
      feature.productData?.value || "",
      isSlideShow ? FieldTypeId.Slideshow : FieldTypeId.YesNo,
    );
    const additionalValue = feature.productData?.additionalValue;
    const hasDescription = isSlideShow || (value === true && additionalValue);

    if (isSlideShow) {
      if (!value || value.length === 0) {
        return <></>;
      }

      return (
        <SHCollapse
          sx={{ pt: "25px" }}
          open
          title={
            <SHStack direction="row" spacing={0.5} alignItems="start">
              <ImageSVG />
              <SHTypography>{feature.name}</SHTypography>
            </SHStack>
          }
          children={
            <SHBox sx={{ pt: "25px" }}>
              <ImageGalleryBox
                title={selectedSubSection.name}
                initialImages={value}
                width="100%"
              />
            </SHBox>
          }
        />
      );
    }

    const renderFeatureValue = () => {
      switch (value) {
        case true:
          return <SHBooleanIcon label={"Available"} isTrue />;
        case false:
          return <SHBooleanIcon label={"Not available"} isTrue={false} />;
        default:
          return (
            <SHTypography
              variant="body2"
              colorVariant="third"
              sx={{
                minWidth: "54px",
              }}
            >
              No data
            </SHTypography>
          );
      }
    };

    const renderFeatureHeading = () => (
      <SHStack
        direction={"row"}
        justifyContent="space-between"
        sx={{ pr: "40px", py: "25px" }}
      >
        <SHStack direction="row" spacing={0.5} alignItems="start">
          <SHTypography>{feature.name}</SHTypography>
          {feature?.description && (
            <InformationButton content={feature.description} />
          )}
        </SHStack>
        <SHBox width={130}>{renderFeatureValue()}</SHBox>
      </SHStack>
    );
    if (!hasDescription) {
      return (
        <>
          {renderFeatureHeading()}
          <SHDivider />
        </>
      );
    }
    return (
      <>
        <SHCollapse
          title={renderFeatureHeading()}
          children={<FeatureViewLongText content={additionalValue} />}
          fullWidthTitle
        />
        <SHDivider />
      </>
    );
  };

  if (productData && !sections) {
    return <></>;
  }

  const showUpgradeToView = () => {
    if (
      user?.userType === UserType.AdviserTrial ||
      user?.userType === UserType.AdviserAdminTrial
    ) {
      return true;
    }
    return false;
  };

  return (
    <SHStack>
      {showUpgradeToView() ? (
        <SHStack
          display={"flex"}
          gridTemplateColumns={"6.6fr 3.25fr"}
          marginTop={"25px"}
          gap={"15px"}
        >
          <SHTypography variant="subtitle2">
            Please upgrade your subscription
            <Link
              color={palette.text.primary}
              component="a"
              underline="always"
              onClick={() => {
                navigate(
                  `${generatePath(PageRoutes.practiceOverview, {
                    practiceId: user?.userMetadata?.adviser_firm_id,
                  })}/subscription`,
                );
              }}
              sx={{
                paddingX: 0.5,
                cursor: "pointer",
                textDecorationColor: palette.text.primary,
              }}
            >
              here
            </Link>
            to view detailed information, ESG policies, and features for this
            platform
          </SHTypography>
          {/* <SHStack alignItems="start">
           <SHButton
             color="primary"
             size="extraMedium"
             variant="contained"
           >
             Upgrade subscription
           </SHButton>
         </SHStack> */}
        </SHStack>
      ) : (
        <ProductFeaturesLayout
          leftSide={
            <ProductCollapseMenu
              sections={sections}
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
            />
          }
          rightSide={
            <SHStack>
              <SubSectionTitle subSection={selectedSubSection} />
              <SHDivider />
              {selectedSubSection?.features?.map(
                (feature: ConfigurationFeature) => (
                  <React.Fragment key={feature.id}>
                    {renderFeatureItem(feature)}
                  </React.Fragment>
                ),
              )}
            </SHStack>
          }
          isLoading={isLoadingProductData}
        />
      )}
    </SHStack>
  );
};
