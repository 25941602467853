import * as Yup from "yup";

const nameSchema = Yup.object().shape({
  name: Yup.string().required("Name of entity is required"),
});

export const reviewClientValidators = Yup.object().shape({
  adviserUserId: Yup.string().required("Adviser is required"),
  name: Yup.string().required("Family group name is required"),
  familyGroupEntities: Yup.array()
    .of(nameSchema)
    .nullable(false)
    .when("name", (name, schema) => {
      return schema.required().min(1).max(6);
    }),
});
