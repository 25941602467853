import { SVGProps } from "react";

export const ChevronSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.776 2.318a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8.765 6.553 2.989a.5.5 0 0 1 .223-.671Z"
      fill="#25324B"
    />
  </svg>
);
