import { Minute, Second } from "@constants/time";

export const millisecondsToMinutesAndSeconds = (
    milliseconds: number,
): string => {
    const minutes = String(Math.floor(milliseconds / Minute)).padStart(2, "0");
    const seconds = String(
        Math.floor((milliseconds % Minute) / Second),
    ).padStart(2, "0");
    return `${minutes}:${seconds}`;
};
