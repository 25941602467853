export interface PermissionChecking {
  roles?: UserType[];
  permissions?: UserPermission[];
  comparePermissionsMode?: "every" | "some";
}

export enum UserType {
  SuperAdmin = "Admin:Super",
  StandardAdmin = "Admin:Standard",
  SupplierBasic = "Supplier:Basic",
  SupplierBdmSale = "Supplier:BDM_Sale",
  SupplierPremiumOwner = "Supplier:Premium_Owner",
  SupplierPremiumStandard = "Supplier:Premium_Standard",

  AdviserTrial = "Adviser:Trial",
  AdviserAdminTrial = "Adviser:Admin_Trial",
  AdviserAdviser = "Adviser:Adviser",
  AdviserAdminAdviser = "Adviser:Admin_Adviser",
  AdviserSupportStaff = "Adviser:Support_Staff",
  AdviserAdminSupportStaff = "Adviser:Admin_Support_Staff",
}

export enum UserPermission {
  CreateStandardAdmin = "create:standard-admin",
  EditStandardAdmin = "edit:standard-admin",
  ViewStandardAdmin = "view:standard-admin",
  CreateSuperAdmin = "create:super-admin",
  EditSuperAdmin = "edit:super-admin",
  ViewSuperAdmin = "view:super-admin",
  CreatePractices = "create:practices",
  EditPractices = "edit:practices",
  ViewPractices = "view:practices",
  CreateAdviserUsers = "create:adviser-users",
  EditAdviserUsers = "edit:adviser-users",
  ViewAdviserUsers = "view:adviser-users",
  CreateSuppliers = "create:suppliers",
  EditSuppliers = "edit:suppliers",
  ViewSuppliers = "view:supplier",
  CreateSupplierUsers = "create:supplier-users",
  EditSupplierUsers = "edit:supplier-users",
  ViewSupplierUsers = "view:supplier-users",
  CreateConfiguration = "create:configuration",
  EditConfiguration = "edit:configuration",
  ViewConfiguration = "view:configuration",
  CreateProductProfiles = "create:product-profiles",
  EditProductProfiles = "edit:product-profiles",
  ViewProductProfiles = "view:product-profiles",
  PublishProductProfiles = "publish:product-profiles",
  CreateNewsfeedPosts = "create:news feed-posts",
  EditNewsfeedPosts = "edit:newsfeed-posts",
  ViewNewsfeedPosts = "view:newsfeed-posts",
  PublishNewsfeedPosts = "publish:newsfeed-posts",
  EditOwnSupplierProduct = "edit:own-supplier-product",
  ViewOwnSupplierProduct = "view:own-supplier-product",
  ViewOtherSupplierProduct = "view:other-supplier-product",
  CreateOwnSupplierNewsfeedPosts = "create:own-supplier-newsfeed-posts",
  EditOwnSupplierNewsfeedPosts = "edit:own-supplier-newsfeed-posts",
  ViewOwnSupplierNewsfeedPosts = "view:own-supplier-newsfeed-posts",
  ViewOtherSupplierNewsfeedPosts = "view:other-supplier-newsfeed-posts",
  CreateBillingAndLicensers = "create:billing-and-licensers",
  EditBillingAndLicensers = "edit:billing-and-licensers",
  ViewBillingAndLicensers = "view:billing-and-licensers",
  CreateProductAnalysis = "create:product-analysis",
  EditProductAnalysis = "edit:product-analysis",
  ViewProductAnalysis = "view:product-analysis",
  RunProductAnalysis = "run:product-analysis",
  MakeSupplierDetailsPublic = "make:supplier-details-public",
}
