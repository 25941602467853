import {
  SHButton,
  SHPopper,
  SHRadio,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHFormControlLabel } from "@components/design-systems/sh-form-control-label";
import { FilterClearSVG, FilterSVG } from "@components/svgs";
import { FormGroup, IconButton, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";

export type FilterValue = {
  label: string;
  value: any;
};

export interface FeeAnalysisFilterRadioButtonProps {
  buttonTitle?: string;
  labelAll?: string;
  width?: string;
  zIndex?: number;
  filterInvestmentMenuData?: FilterValue[];
  filterInvestmentMenuValue?: FilterValue[] | undefined;
  filterMissingInvestmentData?: FilterValue[];
  filterMissingInvestmentValue?: FilterValue[] | undefined;
  onChangeFilter?: (missingValue: FilterValue[] | undefined, menuValue: FilterValue[] | undefined) => void;
  [key: string]: any;
  isScrolling?: boolean;
}
export const FeeAnalysisFilterRadioButton = ({
  buttonTitle,
  filterInvestmentMenuData = [],
  filterMissingInvestmentData = [],
  labelAll = "All",
  width,
  zIndex,
  filterInvestmentMenuValue: filterInvestmentMenuValueProps,
  filterMissingInvestmentValue: filterMissingInvestmentValueProps,
  onChangeFilter,
  isScrolling = false,
  ...others
}: FeeAnalysisFilterRadioButtonProps) => {
  const { palette } = useTheme();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const [filterMissingInvestmentValue, setFilterMissingInvestmentValue] =
    useState<FilterValue[] | undefined>(filterMissingInvestmentValueProps);

  const [filterInvestmentMenuValue, setFilterInvestmentMenuValue] = useState<
    FilterValue[] | undefined
  >(filterInvestmentMenuValueProps);

  const isFilteredMissingInvestment =
    filterMissingInvestmentValue === undefined ||
    filterMissingInvestmentValue.length === 0
      ? false
      : true;

  const isFilteredInvestmentMenu =
    filterInvestmentMenuValue === undefined ||
    filterInvestmentMenuValue.length === 0
      ? false
      : true;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleOnChangeFilterMissingInvestment = (
    value: FilterValue[] | undefined,
  ) => {
    setFilterMissingInvestmentValue(value);
    if (onChangeFilter) onChangeFilter(value, filterInvestmentMenuValue);
  };

  const handleOnChangeFilterInvestmentMenu = (
    value: FilterValue[] | undefined,
  ) => {
    setFilterInvestmentMenuValue(value);
    if (onChangeFilter) onChangeFilter(filterMissingInvestmentValue, value);
  };

  useEffect(() => {
    setOpen(false);
  }, [isScrolling]);

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, buttonRef, open]);

  useEffect(() => {
    if (
      !isEqual(filterMissingInvestmentValueProps, filterMissingInvestmentValue)
    ) {
      setFilterMissingInvestmentValue(
        filterMissingInvestmentValue ?? filterMissingInvestmentValueProps,
      );
    }
    // eslint-disable-next-line
  }, [filterMissingInvestmentValueProps]);

  useEffect(() => {
    if (
      !isEqual(filterInvestmentMenuValueProps, filterInvestmentMenuValue)
    ) {
      setFilterInvestmentMenuValue(
        filterInvestmentMenuValue ?? filterInvestmentMenuValueProps,
      );
    }
    // eslint-disable-next-line
  }, [filterInvestmentMenuValueProps]);

  const FilterIcon = () => (
    <SHStack
      width={"16px"}
      height={"16px"}
      alignItems={"center"}
      justifyContent="start"
    >
      {isFilteredMissingInvestment || isFilteredInvestmentMenu ? (
        <FilterClearSVG
          width={"16px"}
          height={"16px"}
          data-testid="filter-clear-icon"
        />
      ) : (
        <FilterSVG
          width={"15px"}
          height={"15px"}
          data-testid="filter-icon"
          fill={palette.primary.main}
        />
      )}
    </SHStack>
  );

  return (
    <>
      {buttonTitle ? (
        <SHButton
          {...others}
          ref={buttonRef}
          onClick={handleClick}
          color="primary"
          variant="text"
          size="extraMedium"
          startIcon={<FilterIcon />}
          data-testid="text-button"
        >
          {buttonTitle}
        </SHButton>
      ) : (
        <IconButton
          data-testid="icon-button"
          {...others}
          ref={buttonRef}
          onClick={handleClick}
          sx={{ padding: "4px" }}
        >
          <FilterIcon />
        </IconButton>
      )}

      <SHPopper
        anchorEl={anchorEl}
        open={open}
        placement="bottom"
        popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
        sx={{ width: width, zIndex: zIndex }}
      >
        <SHStack
          ref={wrapperRef}
          sx={{
            boxSizing: "border-box",
            boxShadow: "none",
            borderWidth: 1,
            borderColor: palette.divider,
            borderStyle: "solid",
            borderRadius: 0,
            backgroundColor: hexToRGBA(palette.common.white, 0.97),
            paddingX: 2,
            paddingY: 1,
            minWidth: "170px",
            filter: "drop-shadow(1px 1px 2px rgba(65, 73, 89, 0.25))",
            gap: 2,
          }}
        >
          <SHStack>
            <SHStack spacing={1} direction="row" alignItems="center">
              <FilterSVG />
              <SHTypography variant="body1">Investment menu</SHTypography>
            </SHStack>
            <FormGroup sx={{ padding: "2px" }}>
              <SHFormControlLabel
                size="small"
                control={
                  <SHRadio
                    checked={!isFilteredInvestmentMenu}
                    size={"small"}
                    onChange={() => {
                      handleOnChangeFilterInvestmentMenu(undefined);
                    }}
                  />
                }
                data-testid="filter-all-checkbox"
                label={labelAll}
              />
              {filterInvestmentMenuData.map(
                (filterItem: FilterValue, index: number) => (
                  <SHFormControlLabel
                    key={index}
                    data-testid={"filter-checkbox-" + index}
                    size="small"
                    control={
                      <SHRadio
                        checked={
                          filterInvestmentMenuValue?.[0]?.value ===
                          filterItem.value
                        }
                        size={"small"}
                        data-testid="sh-head-filter-option"
                        onChange={() => {
                          handleOnChangeFilterInvestmentMenu([filterItem]);
                        }}
                      />
                    }
                    label={filterItem.label}
                  />
                ),
              )}
            </FormGroup>
          </SHStack>
          <SHStack>
            <SHStack spacing={1} direction="row" alignItems="center">
              <FilterSVG />
              <SHTypography variant="body1">Missing investments</SHTypography>
            </SHStack>
            <FormGroup sx={{ padding: "2px" }}>
              <SHFormControlLabel
                size="small"
                control={
                  <SHRadio
                    checked={!isFilteredMissingInvestment}
                    size={"small"}
                    onChange={() => {
                      handleOnChangeFilterMissingInvestment(undefined);
                    }}
                  />
                }
                data-testid="filter-all-checkbox"
                label={labelAll}
              />
              {filterMissingInvestmentData.map(
                (filterItem: FilterValue, index: number) => (
                  <SHFormControlLabel
                    key={index}
                    data-testid={"filter-checkbox-" + index}
                    size="small"
                    control={
                      <SHRadio
                        checked={
                          filterMissingInvestmentValue?.[0]?.value ===
                          filterItem.value
                        }
                        size={"small"}
                        data-testid="sh-head-filter-option"
                        onChange={() => {
                          handleOnChangeFilterMissingInvestment([filterItem]);
                        }}
                      />
                    }
                    label={filterItem.label}
                  />
                ),
              )}
            </FormGroup>
          </SHStack>
        </SHStack>
      </SHPopper>
    </>
  );
};
