import { SHStack, SHStackProps } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

interface SHFormControlLabelSkeletonProps {
  subTitleRows?: number;
}

export const SHFormControlLabelSkeleton = ({
  subTitleRows = 1,
  ...others
}: SHFormControlLabelSkeletonProps & SHStackProps) => {
  return (
    <SHStack spacing={3} {...others}>
      <SHStack spacing={1} direction="row">
        <SHSkeleton height={20} width={20} variant="circular" />
        <SHSkeleton height={18} width={"50%"} />
      </SHStack>
      <SHStack spacing={1.5} marginLeft={"28px !important"}>
        {Array.from(Array(subTitleRows).keys()).map((index) => (
          <SHSkeleton key={index} height={16} width="100%" />
        ))}
      </SHStack>
    </SHStack>
  );
};
