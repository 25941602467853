export class CompleteProfileSupplierUser {
  userId: string = "";
  firstName: string = "";
  lastName: string = "";
  phoneNumber?: string | null = null;
  jobTitle?: string | null = null;
  workEmail?: string | null = null;
  isAgree?: boolean = false;
  isPublish?: boolean = false;
}

export class CompleteProfileSupplierUserSale extends CompleteProfileSupplierUser {
  publicPhoneNumber?: string | null = null;
  preferredContactHours?: string | null = null;
  publicEmail?: string | null = null;
  linkedInProfileUrl?: string | null = null;
  regions?: string[] | null = null;
}
