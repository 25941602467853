import {
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { EditSVG } from "@components/svgs";
import { HamburgerSVG } from "@components/svgs/hamburger";
import { ConfigurationSection } from "@models/configuration";
import { IconButton, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { useMemo } from "react";

export interface SectionCardProps extends SHStackProps {
  section: ConfigurationSection;
  isEdit?: boolean;
  selected?: boolean;
  onClickEdit?: () => void;
  [key: string]: any;
}
export const SectionCard = ({
  section,
  selected,
  isEdit,
  onClickEdit,
  onClick,
  ...others
}: SectionCardProps) => {
  const { palette } = useTheme();

  const colorSx = useMemo(() => {
    if (section.isArchived)
      return {
        background: `linear-gradient(0deg, ${hexToRGBA(
          palette.error.main,
          0.1,
        )}, ${hexToRGBA(palette.error.main, 0.1)}),${palette.common.white}`,
        border: `1px solid ${palette.error.main}`,
        "& .MuiTypography-root": {
          color: palette.error.main,
        },
        "&:hover": {
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.error.main,
            0.2,
          )}, ${hexToRGBA(palette.error.main, 0.2)}),${palette.common.white}`,
        },
      };

    if (selected)
      return {
        bgcolor: hexToRGBA(palette.primary.light, 0.1),
        borderBottom: `1px solid ${palette.secondary[100]}`,
        "&:hover": {
          bgcolor: hexToRGBA(palette.primary.light, 0.15),
        },
      };

    return {
      bgcolor: hexToRGBA(palette.common.white, 0.25),
      "&:hover": {
        bgcolor: hexToRGBA(palette.common.white, 0.5),
      },
    };
  }, [selected, section.isArchived, palette]);

  return (
    <SHStack
      sx={{
        backdropFilter: "blur(0.5px)",
        paddingX: "10px",
        paddingY: "19px",
        cursor: "pointer",
        ...colorSx,
      }}
      direction="row"
      justifyContent="space-between"
      alignItems={"center"}
      spacing={2}
      onClick={onClick}
      data-testid="section-card"
      {...others}
    >
      <SHStack
        direction="row"
        justifyContent="center"
        alignItems={"center"}
        spacing={2}
      >
        {isEdit && <HamburgerSVG />}
        <SHTypography variant="body1">{section.name}</SHTypography>
      </SHStack>
      {isEdit && (
        <IconButton sx={{ padding: "4px" }} onClick={onClickEdit}>
          <EditSVG color={palette.primary.main} />
        </IconButton>
      )}
    </SHStack>
  );
};
