import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHStack,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { ImageGalleryDialog } from "@components/image-gallery-dialog";
import { ArrowDownSVG } from "@components/svgs";
import {
  MAAnalysisDataDTO,
  MAAnalysisSectionDTO,
  SelectedSubProductDTO,
} from "@models/managed-accounts/entities/step/fee";
import { ImageDTO } from "@models/platform-analysis/entities/steps/feature";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import {
  FeeAnalysisTableCell,
  FeeAnalysisTableCellProps,
  RenderNumericFeesValue,
} from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-cell";
import { FeeAnalysisTableHeadProps } from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-head";
import { isEmpty } from "lodash";
import { useState } from "react";
import { ReactImageGalleryItem } from "react-image-gallery";
import { generateManageAccountColorCell } from "@pages/managed-accounts/_id/steps/fee-analysis/util";

interface FeeAnalysisSubSectionRowProps {
  tableViewMode?: FeesDisplayStyle;
  subSection?: MAAnalysisSectionDTO;
  isCollapsed?: boolean;
  onCollapse?: () => void;
}

export const FeeAnalysisSubSectionRow = ({
  tableViewMode,
  subSection,
  selectedSubProducts,
  isCollapsed = false,
  onCollapse = () => {},
}: FeeAnalysisSubSectionRowProps &
  FeeAnalysisTableCellProps &
  SHTableRowProps &
  Pick<FeeAnalysisTableHeadProps, "selectedSubProducts">) => {
  const [previewImages, setPreviewImages] = useState<ImageDTO[]>([]);

  return (
    <>
      <FeeAnalysisTableCell isFirstColumn>
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent="space-between"
          sx={{ cursor: "pointer" }}
          onClick={onCollapse}
        >
          <SHTypography
            variant={"subtitle2"}
            sx={{ paddingTop: 0, fontSize: 13 }}
          >
            {subSection?.name}
          </SHTypography>
          <SHStack direction="row" alignItems={"center"} spacing={1}>
            {subSection?.description && (
              <InformationButton content={subSection?.description} />
            )}
            <SHBox
              alignItems={"center"}
              sx={{
                display: "flex",
                transition: "all ease-in-out 0.2s",
                transform: `rotate(${!isCollapsed ? 0 : 180}deg)`,
              }}
            >
              <ArrowDownSVG width={16} height={16} />
            </SHBox>
          </SHStack>
        </SHStack>
      </FeeAnalysisTableCell>
      {subSection?.analysisData
        ? subSection?.analysisData?.map((analysisData: MAAnalysisDataDTO) => {
            const platform = selectedSubProducts?.find(
              (selectedProduct: SelectedSubProductDTO) =>
                selectedProduct.id === analysisData.subProductId,
            );

            return (
              <FeeAnalysisTableCell
                key={analysisData?.subProductId}
                colorCell={generateManageAccountColorCell(platform)}
              >
                <SHTypography
                  variant={"subtitle2"}
                  sx={{ fontSize: "13px", fontWeight: 500, lineHeight: "120%" }}
                >
                  <RenderNumericFeesValue
                    value={analysisData?.value}
                    valuePercentage={analysisData?.valueInPercent}
                    tableViewMode={tableViewMode}
                  />
                </SHTypography>
              </FeeAnalysisTableCell>
            );
          })
        : selectedSubProducts?.map((platform, index) => {
            return (
              <FeeAnalysisTableCell
                key={platform.id}
                colorCell={generateManageAccountColorCell(platform)}
              >
                <SHTypography
                  variant={"subtitle2"}
                  sx={{ fontSize: "13px", fontWeight: 500, lineHeight: "120%" }}
                ></SHTypography>
              </FeeAnalysisTableCell>
            );
          })}

      <ImageGalleryDialog
        open={!isEmpty(previewImages)}
        title={subSection?.name}
        items={
          previewImages?.map((item: ImageDTO) => {
            return {
              original: `${item.url}`,
            };
          }) as ReactImageGalleryItem[]
        }
        onClose={() => setPreviewImages([])}
      />
    </>
  );
};
