import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { RootState } from "@redux/store";
import { updateShowBeingKickedDialog } from "@redux/slices/product";
import { useAppDispatch } from "@redux/store";
import { useSelector } from "react-redux";

export interface ProductBeingKickedOutDialogProps {}

export const ProductBeingKickedOutDialog = () => {
  const dispatch = useAppDispatch();
  const {
    productUI: { showBeingKickedDialog, beingKickedBy },
  } = useSelector((state: RootState) => state.product);

  const handleOnClose = () => {
    dispatch(updateShowBeingKickedDialog(false));
  };

  return (
    <>
      <SHDialog
        open={showBeingKickedDialog || false}
        onClose={handleOnClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        width={600}
        header={
          <SHStack alignItems="center" textAlign={"center"} width={{ xs: 590 }}>
            <SHTypography variant="h2">
              You have been kicked out of editing the Product
            </SHTypography>
          </SHStack>
        }
        body={
          <SHStack>
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                width: 430,
              }}
            >
              {beingKickedBy} kicked you out.
            </SHTypography>
          </SHStack>
        }
        footer={
          <SHStack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={7}
          >
            <SHButton
              color="primary"
              variant="contained"
              onClick={handleOnClose}
              size="extraMedium"
            >
              Close
            </SHButton>
          </SHStack>
        }
      />
    </>
  );
};
