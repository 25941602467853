import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { ReviewGetBusinessMetricSectionsDTO } from "@models/reviews/entities/steps/businessMetric";
import { compileBusinessMetricSelection } from "@services/reviews/util";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getReviewBMSelectionStep(
  reviewId: string,
): Promise<APIResponse<ReviewGetBusinessMetricSectionsDTO>> {
  const response =
    await APIGatewayService.get<ReviewGetBusinessMetricSectionsDTO>(
      generatePath(APIExtRoutes.reviewBusinessMetricSelections, {
        reviewId,
      }),
    );
  if (response?.data) {
    compileBusinessMetricSelection(response?.data);
  }

  return handleErrorProxy(response);
}

export async function putReviewBMSelectionStep(
  reviewId: string,
  businessSelections: ReviewGetBusinessMetricSectionsDTO,
): Promise<APIResponse<string>> {
  const response =
    await APIGatewayService.put<ReviewGetBusinessMetricSectionsDTO>(
      generatePath(APIExtRoutes.reviewBusinessMetricSelections, {
        reviewId,
      }),
      businessSelections,
    );
  return handleErrorProxy(response, "Changes have been saved.");
}
