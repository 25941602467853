import {
  TABLE_ROW_HEADER_LINE,
  TABLE_ROW_MULTIPLE_LINE,
  TABLE_ROW_QUADRUPLE_LINE,
  TABLE_ROW_SINGLE_LINE,
  TABLE_ROW_TRIPLE_LINE,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { View } from "@react-pdf/renderer";

interface PDFTableRowProps {
  children: React.ReactNode;
  type?:
    | "SingleLine"
    | "DoubleLine"
    | "TripleLine"
    | "Quadruple"
    | "HeaderLine"
    | "HeaderSingleLine"
    | "CommentLine"
    | "FeatureIncludeLine";
  color?: "white" | "gray" | "dark" | "primary";
}

export const PDFTableRow = ({
  children,
  type,
  color = "white",
}: PDFTableRowProps) => {
  const rowHeightMapping = {
    SingleLine: TABLE_ROW_SINGLE_LINE,
    DoubleLine: TABLE_ROW_MULTIPLE_LINE,
    TripleLine: TABLE_ROW_TRIPLE_LINE,
    Quadruple: TABLE_ROW_QUADRUPLE_LINE,
    HeaderLine: TABLE_ROW_HEADER_LINE,
    HeaderSingleLine: 25,
    CommentLine: 260,
    FeatureIncludeLine: 120,
  };

  const colorMapping = {
    white: "#FFFFFF",
    gray: "#e0e2e5",
    dark: "#b1b6bf",
    primary: "#515B6F",
  };

  return (
    <View
      style={{
        flexDirection: "row",
        height: type ? rowHeightMapping[type] : "auto",
        minHeight: type ? rowHeightMapping[type] : "auto",
        borderColor: "#7C8493",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth:
          type === "HeaderLine" || type === "HeaderSingleLine" ? 1 : 0,
        backgroundColor: color ? colorMapping[color] : "transparent",
      }}
    >
      {children}
    </View>
  );
};
