import { AnalysisVariable } from "@models/platform-analysis/enums/variableName";
import { FeeFieldCondition } from "@models/product/enums/feeFieldCondition";

export const configDependFields: {
  [key in AnalysisVariable]?: {
    [key in AnalysisVariable]?: {
      condition: FeeFieldCondition;
    };
  };
} = {
  // Tiered admin fee type
  [AnalysisVariable.IDPS_Admin_fee_type]: {
    [AnalysisVariable.IDPS_Tier_0]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_1_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_1]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_2_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_2]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_3_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_3]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_4_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_4]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_5_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_5]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_6_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_6]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_7_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_7]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.IDPS_Tier_8_Percent]: {
      condition: FeeFieldCondition.None,
    },
  },
  [AnalysisVariable.Super_Admin_fee_type]: {
    [AnalysisVariable.Super_Tier_0]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_1_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_1]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_2_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_2]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_3_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_3]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_4_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_4]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_5_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_5]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_6_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_6]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_7_Percent]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_7]: {
      condition: FeeFieldCondition.None,
    },
    [AnalysisVariable.Super_Tier_8_Percent]: {
      condition: FeeFieldCondition.None,
    },
  },
  // Australian listed securities
  [AnalysisVariable.IDPS_AU_listed]: {
    [AnalysisVariable.IDPS_AU_bro_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_AU_bro_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_AU_bro_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_AU_settlement_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_AU_admin_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_AU_admin_fee_cap]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.AU_IPO_fee_flat]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.AU_IPO_fee_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.AU_ext_broker]: {
      condition: FeeFieldCondition.Positive,
    },
  },
  [AnalysisVariable.Super_AU_listed]: {
    [AnalysisVariable.Super_AU_bro_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_AU_bro_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_AU_bro_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_AU_settlement_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_AU_admin_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_AU_admin_fee_cap]: {
      condition: FeeFieldCondition.Positive,
    },
  },

  //International listed securities
  [AnalysisVariable.IDPS_Intl_listed]: {
    [AnalysisVariable.IDPS_Intl_bro_flat]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_bro_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_bro_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_bro_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_settlement_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_fixed_admin_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_tier_0]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_tier_1_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_tier_1]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_tier_2_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_tier_2]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_tier_3_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_tier_3]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_tier_4_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_tier_4]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Intl_tier_5_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Intl_IPO_fee_flat]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Intl_IPO_fee_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Intl_ext_broker]: {
      condition: FeeFieldCondition.Positive,
    },
  },
  [AnalysisVariable.Super_Intl_listed]: {
    [AnalysisVariable.Super_Intl_bro_flat]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_bro_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_bro_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_bro_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_settlement_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_fixed_admin_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_tier_0]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_tier_1_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_tier_1]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_tier_2_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_tier_2]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_tier_3_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_tier_3]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_tier_4_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_tier_4]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Intl_tier_5_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
  },

  //Unlisted managed funds
  [AnalysisVariable.IDPS_Managed_funds]: {
    [AnalysisVariable.IDPS_MF_fixed_trans]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MF_trans_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MF_trans_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MF_trans_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MF_admin_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MF_admin_fee_cap]: {
      condition: FeeFieldCondition.Positive,
    },
  },
  [AnalysisVariable.Super_Managed_funds]: {
    [AnalysisVariable.Super_MF_fixed_trans]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MF_trans_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MF_trans_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MF_trans_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MF_admin_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MF_admin_fee_cap]: {
      condition: FeeFieldCondition.Positive,
    },
  },

  //Fixed income
  [AnalysisVariable.IDPS_FI_bond]: {
    [AnalysisVariable.IDPS_Bond_fixed_trans]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Bond_trans_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Bond_trans_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Bond_trans_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Bond_hold_Currency]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Bond_hold_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
  },
  [AnalysisVariable.Super_FI_bond]: {
    [AnalysisVariable.Super_Bond_fixed_trans]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Bond_trans_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Bond_trans_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Bond_trans_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Bond_hold_Currency]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Bond_hold_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
  },

  //Managed accounts
  [AnalysisVariable.IDPS_Managed_accounts]: {
    [AnalysisVariable.IDPS_MA_admin_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_admin_fee_cap]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_MF_trans_flat]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_MF_trans_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_MF_trans_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_MF_trans_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_AU_bro_flat]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_AU_bro_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_AU_bro_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_AU_bro_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_Intl_bro_flat]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_Intl_bro_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_Intl_bro_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_MA_Intl_bro_max]: {
      condition: FeeFieldCondition.Positive,
    },
  },
  [AnalysisVariable.Super_Managed_accounts]: {
    [AnalysisVariable.Super_MA_admin_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_admin_fee_cap]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_MF_trans_flat]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_MF_trans_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_MF_trans_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_MF_trans_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_AU_bro_flat]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_AU_bro_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_AU_bro_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_AU_bro_max]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_Intl_bro_flat]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_Intl_bro_Percent]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_Intl_bro_min]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_MA_Intl_bro_max]: {
      condition: FeeFieldCondition.Positive,
    },
  },

  //Additional fee for retail insurance policies
  [AnalysisVariable.IDPS_Ins_fee]: {
    [AnalysisVariable.IDPS_Ins_Percent_prem]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Max_ins_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Flat_ins_policy_fee]: {
      condition: FeeFieldCondition.Positive,
    },
  },
  [AnalysisVariable.Super_Ins_fee]: {
    [AnalysisVariable.Super_Ins_Percent_prem]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Max_ins_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Flat_ins_policy_fee]: {
      condition: FeeFieldCondition.Positive,
    },
  },

  //Fee grouping
  [AnalysisVariable.Fee_grouping]: {
    [AnalysisVariable.Fee_grouping_both]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Agg_calc_rebate]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Max_entity_per_group]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Max_acc_per_entity]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Total_max_acc]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Admin_fee_max_family]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Admin_fee_min_family]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Fee_discount]: {
      condition: FeeFieldCondition.Positive,
    },
  },
  [AnalysisVariable.Fee_discount]: {
    [AnalysisVariable.Fee_indiv_discount_threshold]: {
      condition: FeeFieldCondition.NA,
    },
    [AnalysisVariable.Fee_discount_threshold]: {
      condition: FeeFieldCondition.NA,
    },
  },
  [AnalysisVariable.Indiv_fee_cap]: {
    [AnalysisVariable.Fixed_entity_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Min_entity_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Min_entity_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.IDPS_Max_entity_fee]: {
      condition: FeeFieldCondition.Positive,
    },
    [AnalysisVariable.Super_Max_entity_fee]: {
      condition: FeeFieldCondition.Positive,
    },
  },
  [AnalysisVariable.AU_ext_broker]: {
    [AnalysisVariable.AU_ext_broker_fee]: {
      condition: FeeFieldCondition.Positive,
    },
  },
  [AnalysisVariable.Intl_ext_broker]: {
    [AnalysisVariable.Intl_ext_broker_fee]: {
      condition: FeeFieldCondition.Positive,
    },
  },

  //Admin_fee_min_family
  [AnalysisVariable.Admin_fee_min_family]: {
    [AnalysisVariable.Account_tiered_min_prio]: {
      condition: FeeFieldCondition.PositiveNumber,
    },
  },
};
