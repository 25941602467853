import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { SubProductDTO } from "@models/product/entities/subProduct";
import { compileSubProducts } from "@services/product/util";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getSubProducts(
  productId?: string,
  mode?: "view" | "edit",
): Promise<APIResponse<SubProductDTO[]>> {
  const response = await APIGatewayService.get<SubProductDTO[]>(
    generatePath(APIExtRoutes.productSubProducts, {
      productId,
      mode,
    }),
  );
  if (response?.data) {
    compileSubProducts(response.data);
  }
  return handleErrorProxy(response);
}

export async function getNewSubProduct(
  productId?: string,
): Promise<APIResponse<SubProductDTO>> {
  const response = await APIGatewayService.get<SubProductDTO>(
    generatePath(APIExtRoutes.productNewSubProduct, {
      productId,
    }),
  );
  if (response?.data) {
    compileSubProducts([response.data]);
  }
  return handleErrorProxy({ ...response, data: response.data });
}

export async function postSubProducts(
  productId?: string,
  subProducts?: SubProductDTO[],
): Promise<APIResponse<SubProductDTO[]>> {
  const response = await APIGatewayService.post<SubProductDTO[]>(
    generatePath(APIExtRoutes.subProducts, {
      productId,
    }),
    subProducts,
  );
  if (response?.data) {
    compileSubProducts(response.data);
  }
  return handleErrorProxy(response, "Sub products has been updated.");
}

export async function getSubProductReleaseLog(
  productId: string,
  version: string,
  configurationVersion: string,
): Promise<APIResponse<SubProductDTO[]>> {
  const response = await APIGatewayService.get<SubProductDTO[]>(
    generatePath(APIExtRoutes.productSubProductReleaseLog, {
      productId,
      version,
      configurationVersion,
    }),
  );
  if (response?.data) {
    compileSubProducts(response.data);
  }

  return handleErrorProxy(response);
}

export async function getSubProduct(
  productId: string,
  subProductId: string,
): Promise<APIResponse<SubProductDTO>> {
  const response = await APIGatewayService.get<SubProductDTO>(
    generatePath(APIExtRoutes.subProduct, {
      productId,
      subProductId,
    }),
  );
  if (response?.data) {
    compileSubProducts([response.data]);
  }
  return handleErrorProxy(response);
}
