import { Button, ButtonProps, useTheme } from "@mui/material";
import React, { forwardRef, useMemo } from "react";
import { muiButtonClassName } from "./theme";
import { SHIconLoading } from "@components/design-systems";

export interface SHButtonProps extends ButtonProps {
  isLoading?: boolean;
}
export const SHButton = forwardRef(
  (
    {
      className: propClassName,
      startIcon,
      endIcon,
      isLoading = false,
      ...buttonProps
    }: SHButtonProps,
    ref: any,
  ): JSX.Element => {
    const { palette } = useTheme();

    const className = useMemo(() => {
      let customClassName = propClassName ? `${propClassName} ` : "";
      if (startIcon || endIcon) {
        customClassName += muiButtonClassName.withIcon;
      }
      return customClassName;
    }, [propClassName, startIcon, endIcon]);

    const iconWithLoading = (icon: React.ReactNode) => {
      if (!isLoading) return icon;
      return (
        <SHIconLoading
          size={18}
          sx={{
            color: palette.text.disabled,
            position: "relative",
          }}
        />
      );
    };

    const centerLoading = useMemo(() => {
      if (!isLoading || endIcon) return null;
      return (
        <SHIconLoading
          size={18}
          sx={{
            color: palette.text.disabled,
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-10px",
            marginLeft: "-10px",
          }}
        />
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endIcon, isLoading]);

    return (
      <Button
        {...buttonProps}
        startIcon={startIcon ? iconWithLoading(startIcon) : centerLoading}
        endIcon={endIcon ? iconWithLoading(endIcon) : null}
        className={className}
        ref={ref}
      />
    );
  },
);
