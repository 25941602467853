import { HttpStatusCodes } from "@constants/http-status-codes";
import { AxiosResponse } from "axios";

export interface SHAxiosResponse<T = any, D = any> extends AxiosResponse<T, D> {
  isCancel?: boolean;
  isNetError?: boolean;
}
export class APIResponse<T = any> {
  isSuccess: boolean = false;
  isCancel?: boolean;
  isForbidden: boolean = false;
  status: HttpStatusCodes = HttpStatusCodes.GATEWAY_TIMEOUT;
  data?: T;
  message?: string;
  error?: string;
}

export type APIError = null | undefined | string | BadRequestError;

export interface BadRequestError {
  validationErrors: {
    [key: string]: string[];
  };
  error: string;
}
