import { SHContainer, SHDivider, SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { StepName } from "@layouts/stepper/step-name";

export const AnalysisSummarySkeleton = () => {
  return (
    <SHStack>
      <SHContainer
        maxWidth="xl"
        sx={{ px: { xs: "16px", lg: "100px" }, pb: "25px" }}
      >
        <StepName name={"Summary"} stepNumber={5} isLoading />
        <SHStack spacing={"25px"} mt="20px">
          <SHStack
            direction={{ xs: "column", lg: "row" }}
            justifyContent={{ xs: "flex-start", lg: "space-between" }}
            alignItems={{ xs: "flex-start", lg: "center" }}
            gap={"25px"}
          >
            <SHStack spacing={"25px"}>
              <SHSkeleton height={"30px"} width="250px" />
              <SHStack direction={"row"} spacing="5px">
                <SHSkeleton height={"24px"} width="24px" variant="circular" />
                <SHSkeleton height={"24px"} width="85px" />
              </SHStack>
              <SHStack direction={"row"} alignItems={"center"} spacing="15px">
                <SHSkeleton height={"24px"} width="160px" />
                <SHDivider orientation="vertical" sx={{ height: "14px" }} />
                <SHSkeleton height={"24px"} width="160px" />
                <SHDivider orientation="vertical" sx={{ height: "14px" }} />
                <SHSkeleton height={"24px"} width="160px" />
              </SHStack>
            </SHStack>
            <SHStack spacing={"25px"}>
              <SHStack spacing={"5px"}>
                <SHSkeleton height={"24px"} width="160px" />
                <SHSkeleton height={"20px"} width="415px" />
              </SHStack>
              <SHStack spacing={"5px"}>
                <SHSkeleton height={"24px"} width="160px" />
                <SHSkeleton height={"20px"} width="300px" />
              </SHStack>
            </SHStack>
          </SHStack>
          <SHStack spacing={"5px"}>
            <SHStack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <SHSkeleton height={"24px"} width="75px" />
              <SHSkeleton height={"32px"} width="77px" />
            </SHStack>
            <SHDivider />
            <SHSkeleton height={"90px"} width="100%" />
          </SHStack>
          <SHStack spacing={"5px"}>
            <SHStack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <SHSkeleton height={"24px"} width="200px" />
              <SHSkeleton height={"30px"} width="127px" />
            </SHStack>
            <SHStack spacing={"10px"}>
              <SHSkeleton height={"55px"} width="100%" />
              <SHSkeleton height={"200px"} width="100%" />
            </SHStack>
          </SHStack>
        </SHStack>
      </SHContainer>
    </SHStack>
  );
};
