import { TABLE_WIDTH } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { Svg, Line } from "@react-pdf/renderer";

interface HorizontalLineProps {
  width?: number;
  strokeWidth?: number;
  color?: string;
}
export const HorizontalLine = ({
  width = TABLE_WIDTH,
  strokeWidth = 1,
  color = "#25324B",
}: HorizontalLineProps) => {
  return (
    <Svg height="10" width={width}>
      <Line
        x1={"0"}
        y1={"5"}
        x2={"100%"}
        y2={"5"}
        strokeWidth={strokeWidth}
        stroke={color}
      />
    </Svg>
  );
};
