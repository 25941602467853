import { CloseButton } from "@components/buttons/close";
import {
  SHAutocomplete,
  SHButton,
  SHDialog,
  SHRichTextEditor,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { yupResolver } from "@hookform/resolvers/yup";
import { DialogContent, DialogTitle, useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { InformationButton } from "@components/buttons/information";
import { usePortfolioTemplate } from "@hooks/usePortfolioTemplate";
import {
  SelectedInvestmentDTO,
  SelectedPortfolioTemplateDTO,
} from "@models/managed-accounts/entities/step/setup";
import {
  CreatePortfolioTemplate,
  PortfolioTemplateDTO,
} from "@models/portfolio-template/entities/portfolioTemplate";
import { OverwriteTemplateDialog } from "@pages/platform-analysis/components/dialogs/overwrite-template";
import { isEmpty, take } from "lodash";
import { portfolioTemplateValidators } from "./util";
import { useManagedAccount } from "@hooks/useManagedAccount";

interface SaveInvestmentAsTemplateDialogProps {
  onClose: () => void;
  onSubmit: () => void;
  selectedInvestment: SelectedInvestmentDTO[];
}

export const SaveInvestmentAsTemplateDialog = ({
  onClose,
  onSubmit,
  selectedInvestment,
}: SaveInvestmentAsTemplateDialogProps) => {
  const { palette } = useTheme();
  const { portfolioTemplates } = useSelector(
    (state: RootState) => state.managedAccount,
  );

  const { ui, createPortfolioTemplate, updatePortfolioTemplate } =
    usePortfolioTemplate();

  const { loadManagedAccountPortfolioTemplates } = useManagedAccount();

  const [optionPortfolioTemplates, setOptionPortfolioTemplates] = useState<
    SelectedPortfolioTemplateDTO[]
  >([]);

  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<PortfolioTemplateDTO>({
    mode: "onChange",
    defaultValues: { ...new PortfolioTemplateDTO() },
    resolver: yupResolver(portfolioTemplateValidators),
  });

  const [overwriteId, setOverwriteId] = useState<string | undefined>(undefined);

  const isSubmitting = ui?.isSubmitting;

  const handleVerifySubmit = () => {
    const { name } = getValues();
    if (!name) return;

    let overwriteId = portfolioTemplates?.find(
      (item) => item?.name.toLowerCase() === name.toLowerCase(),
    )?.id;
    if (overwriteId) {
      setOverwriteId(overwriteId);
      return;
    }
    handleOnSubmit();
  };

  const handleOnSubmit = async () => {
    const selectedInvestments = selectedInvestment.map((item) => {
      return {
        ...item,
        weight: item.weight ?? 0,
        value: item.value ?? 0,
      };
    });

    const { name, note } = getValues();
    if (!overwriteId) {
      const req = {
        name: name,
        note: note,
        selectedInvestments: selectedInvestments,
      } as CreatePortfolioTemplate;
      await createPortfolioTemplate(req);
    } else {
      await updatePortfolioTemplate({
        id: overwriteId,
        name: name,
        note: note,
        selectedInvestments: selectedInvestments,
      });
    }
    setOverwriteId(undefined);
    await loadManagedAccountPortfolioTemplates();
    onClose();
  };

  useEffect(() => {
    setOptionPortfolioTemplates(take(portfolioTemplates, 20) ?? []);
  }, [portfolioTemplates]);

  return (
    <form>
      <SHDialog
        open
        maxWidth={false}
        sx={{
          "& .MuiPaper-root": {
            width: { xs: "100%", md: 642 },
            padding: "40px 60px",
          },
        }}
      >
        <DialogTitle>
          <SHTypography variant="h2" fontWeight={700} lineHeight={"100%"}>
            Save current portfolio as a template?
          </SHTypography>
        </DialogTitle>
        <SHStack
          sx={{
            position: "absolute",
            top: "22px",
            right: "25px",
          }}
        >
          <CloseButton width="12px" height="12px" onClick={onClose} />
        </SHStack>
        <DialogContent
          sx={{
            padding: 0,
            overflowY: "hidden",
          }}
        >
          <SHStack spacing={"25px"}>
            <SHTypography
              variant="body2"
              sx={{
                fontSize: "16px",
                margin: "0 20px",
                color: `${palette.text.disabled}`,
              }}
            >
              Fill these fields to help you find the template in the future. You
              can overwrite an existing template by giving it the same name.
            </SHTypography>
            <SHStack spacing={3} direction="column">
              <SHAutocomplete
                freeSolo
                disableCloseOnSelect
                disabled={isSubmitting}
                options={optionPortfolioTemplates ?? []}
                getOptionLabel={(option) => {
                  if (typeof option === "string") return getValues().name;
                  return (option as any)["name"];
                }}
                onInputChange={(e, newValue) => {
                  setValue("name", newValue, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  if (isEmpty(newValue)) {
                    setOptionPortfolioTemplates(
                      take(portfolioTemplates, 20) ?? [],
                    );
                  } else {
                    setOptionPortfolioTemplates(portfolioTemplates ?? []);
                  }
                }}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      key={option.id}
                      style={{ padding: "10px 10px" }}
                    >
                      <SHStack
                        alignItems={"center"}
                        direction="row"
                        spacing={1}
                      >
                        <SHTypography variant="body3" fontWeight={500}>
                          {option.name}
                        </SHTypography>
                        {option?.note && (
                          <InformationButton content={option?.note} />
                        )}
                      </SHStack>
                    </li>
                  );
                }}
                textFieldProps={{
                  label: "Template name",
                  placeholder: "Enter name of template",
                  error: !!errors.name,
                  helperText: errors?.name?.message,
                  required: true,
                  maxLength: 50,
                  InputLabelProps: { shrink: true },
                }}
                sx={{ width: { xs: "100%", md: "100%" } }}
              />
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <SHRichTextEditor
                    {...field}
                    sx={{ width: "100% important" }}
                    label="Notes (Optional)"
                    placeHolder="Enter notes for this portfolio template"
                    maxLength={3000}
                    height={165}
                  />
                )}
              />
            </SHStack>
          </SHStack>
          <SHStack
            justifyContent="center"
            alignItems="center"
            direction={"row"}
            spacing={7}
            marginTop={"25px"}
            marginBottom={"15px"}
          >
            <SHButton
              color="primary"
              size="large"
              variant="contained"
              onClick={handleVerifySubmit}
              disabled={isSubmitting || !isValid || !isDirty}
              isLoading={isSubmitting}
              sx={{ width: "410px" }}
            >
              Save as template
            </SHButton>
          </SHStack>
        </DialogContent>
      </SHDialog>
      {overwriteId && (
        <OverwriteTemplateDialog
          onClose={() => setOverwriteId(undefined)}
          onSubmit={handleOnSubmit}
          isSubmitting={isSubmitting}
        />
      )}
    </form>
  );
};
