import { Components, Theme } from "@mui/material";

export const muiToggleButtonOptionsTheme = (
  theme: Theme,
): Pick<
  Components<Omit<Theme, "components">>,
  "MuiToggleButtonGroup" | "MuiToggleButton"
> => ({
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        height: 30,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 3px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: theme.palette.primary.main,
        borderRadius: "25px",
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        height: 24,
        padding: "5px 15px",
        border: "0px solid transparent !important",
        borderRadius: "25px !important",
        textTransform: "none",
        paddingTop: "2px",
        paddingBottom: 0,
        "&:disabled>.MuiTypography-root": {
          color: theme.palette.text.disabled,
        },
        "&.Mui-selected": {
          backgroundColor: theme.palette.primary.main,
          boxShadow: theme.shadows[1],
          "&>.MuiTypography-root": {
            color: theme.palette.common.white,
          },
          "&:hover": {
            boxShadow: `none`,
            backgroundColor: theme.palette.primary.main,
          },
          "&:active": {
            boxShadow: `inset 3px 3px 4px ${theme.palette.primary[900]}`,
            backgroundColor: theme.palette.primary.dark,
          },
          "&:disabled": {
            border: `1px solid ${theme.palette.text.disabled} !important`,
            backgroundColor: theme.palette.grey[400],
          },
        },
      },
    },
  },
});
