import { FeeDetailDTO } from "@models/platform-analysis/entities/steps/fee";
import { ReviewDTO } from "@models/reviews/entities/review";
import {
  ReviewGetBusinessMetricSectionsDTO,
  ReviewStepBusinessMetricContainerDTO,
  ReviewStepBusinessMetricDTO,
} from "@models/reviews/entities/steps/businessMetric";
import {
  ReviewGetFeatureSectionsDTO,
  ReviewStepFeatureContainerDTO,
  ReviewStepFeatureDTO,
} from "@models/reviews/entities/steps/feature";
import {
  ReviewStepFeeContainerDTO,
  ReviewStepFeeDTO,
} from "@models/reviews/entities/steps/fee";
import { sortAscByOrder } from "@utils";
import { isNumber } from "lodash";

export const compileReview = (review: ReviewDTO): ReviewDTO => {
  if (review.feature) {
    compileFeatureReview(review.feature);
  }
  if (review.businessMetric) {
    compileBusinessMetricReview(review.businessMetric);
  }
  if (review.fee) {
    compileFeeReview(review.fee);
  }

  if (review.summary) {
    if (review.summary.featureAnalysis) {
      review.summary.featureAnalysis = compileFeatureReview(
        review.summary.featureAnalysis,
      ) as ReviewStepFeatureDTO;
    }
    if (review.summary.businessMetricAnalysis) {
      review.summary.businessMetricAnalysis = compileBusinessMetricReview(
        review.summary.businessMetricAnalysis,
      ) as ReviewStepBusinessMetricDTO;
    }
    if (review.summary.feeAnalysis) {
      compileFeeReview(review.summary.feeAnalysis) as ReviewStepFeeDTO;
    }
  }
  return review;
};

export const compileFeatureSelection = (
  featureSelections: ReviewGetFeatureSectionsDTO,
): ReviewGetFeatureSectionsDTO => {
  if (featureSelections.sections) {
    featureSelections.sections = sortAscByOrder(featureSelections.sections);
    featureSelections.sections.forEach((section) => {
      //Sort Sub Section
      if (section.subSections) {
        section.subSections = sortAscByOrder(section.subSections) as [];
        //Sort feature of sub sections
        section.subSections?.forEach((subSection) => {
          if (subSection.features)
            subSection.features = sortAscByOrder(subSection.features);
        });
      }
    });
  }

  return featureSelections;
};

export const compileFeatureReview = (
  featureReview: ReviewStepFeatureDTO,
): ReviewStepFeatureDTO => {
  if (featureReview?.all) {
    featureReview.all = compileFeatureReviewContainer(featureReview.all);
  }

  if (featureReview?.shortlisted) {
    featureReview.shortlisted = compileFeatureReviewContainer(
      featureReview.shortlisted,
    );
  }

  if (featureReview?.disqualified) {
    featureReview.disqualified = compileFeatureReviewContainer(
      featureReview.disqualified,
    );
  }

  return featureReview;
};

export const compileFeatureReviewContainer = (
  featureReviewContainer: ReviewStepFeatureContainerDTO,
): ReviewStepFeatureContainerDTO => {
  if (
    featureReviewContainer?.overallScore &&
    featureReviewContainer?.overallScore?.analysisData
  ) {
    featureReviewContainer.overallScore.analysisData = sortAscByOrder(
      featureReviewContainer.overallScore.analysisData,
    );
  }

  if (featureReviewContainer?.selectedProducts) {
    featureReviewContainer.selectedProducts = sortAscByOrder(
      featureReviewContainer.selectedProducts,
    );
  }

  if (featureReviewContainer?.sections) {
    featureReviewContainer.sections = sortAscByOrder(
      featureReviewContainer.sections,
    );
    featureReviewContainer.sections?.forEach((section) => {
      if (section.analysisData)
        section.analysisData = sortAscByOrder(section.analysisData) as [];
      if (section.subSections) {
        section.subSections = sortAscByOrder(section.subSections) as [];
        section.subSections?.forEach((subSection: any) => {
          if (subSection.analysisData)
            subSection.analysisData = sortAscByOrder(subSection.analysisData);
          if (subSection.features) {
            subSection.features = sortAscByOrder(subSection.features) as [];
            subSection.features?.forEach((feature: any) => {
              if (feature?.analysisData)
                feature.analysisData = sortAscByOrder(feature.analysisData);
            });
          }
        });
      }
    });
  }

  return featureReviewContainer;
};

export const compileBusinessMetricSelection = (
  businessMetrics: ReviewGetBusinessMetricSectionsDTO,
): ReviewGetBusinessMetricSectionsDTO => {
  if (businessMetrics.financialStrength) {
    businessMetrics.financialStrength = sortAscByOrder(
      businessMetrics.financialStrength,
    );
  }
  if (businessMetrics.esg) {
    businessMetrics.esg = sortAscByOrder(businessMetrics.esg);
  }
  if (businessMetrics.operational) {
    businessMetrics.operational = sortAscByOrder(businessMetrics.operational);
  }
  return businessMetrics;
};

export const compileBusinessMetricReview = (
  businessMetricReview: ReviewStepBusinessMetricDTO,
): ReviewStepBusinessMetricDTO => {
  if (businessMetricReview?.all) {
    businessMetricReview.all = compileBusinessMetricReviewContainer(
      businessMetricReview.all,
    );
  }

  if (businessMetricReview?.shortlisted) {
    businessMetricReview.shortlisted = compileBusinessMetricReviewContainer(
      businessMetricReview.shortlisted,
    );
  }

  if (businessMetricReview?.disqualified) {
    businessMetricReview.disqualified = compileBusinessMetricReviewContainer(
      businessMetricReview.disqualified,
    );
  }

  return businessMetricReview;
};

export const compileBusinessMetricReviewContainer = (
  businessMetricReviewContainer: ReviewStepBusinessMetricContainerDTO,
): ReviewStepBusinessMetricContainerDTO => {
  if (
    businessMetricReviewContainer?.overallScore &&
    businessMetricReviewContainer?.overallScore?.analysisData
  ) {
    businessMetricReviewContainer.overallScore.analysisData = sortAscByOrder(
      businessMetricReviewContainer.overallScore.analysisData,
    );
  }

  if (businessMetricReviewContainer?.selectedProducts) {
    businessMetricReviewContainer.selectedProducts = sortAscByOrder(
      businessMetricReviewContainer.selectedProducts,
    );
  }

  if (businessMetricReviewContainer?.sections) {
    businessMetricReviewContainer.sections = sortAscByOrder(
      businessMetricReviewContainer.sections,
    );
    businessMetricReviewContainer.sections?.forEach((section) => {
      if (section.analysisData)
        section.analysisData = sortAscByOrder(section.analysisData) as [];
      if (section.features) {
        section.features = sortAscByOrder(section.features) as [];
        section.features.forEach((feature) => {
          if (feature.analysisData) {
            feature.analysisData = sortAscByOrder(feature.analysisData) as [];
          }
        });
      }
    });
  }

  return businessMetricReviewContainer;
};

export const compileFeeReview = (
  feeReview: ReviewStepFeeDTO,
): ReviewStepFeeDTO => {
  if (feeReview?.all) {
    feeReview.all = compileFeeReviewContainer(feeReview.all);
  }

  if (feeReview?.shortlisted) {
    feeReview.shortlisted = compileFeeReviewContainer(feeReview.shortlisted);
  }

  if (feeReview?.disqualified) {
    feeReview.disqualified = compileFeeReviewContainer(feeReview.disqualified);
  }

  return feeReview;
};

export const compileFeeReviewContainer = (
  feeReview: ReviewStepFeeContainerDTO,
): ReviewStepFeeContainerDTO => {
  if (feeReview.subProducts) {
    let platformOrderMap = new Map<string, number>();
    if (feeReview.selectedProducts) {
      feeReview.selectedProducts.forEach((product) => {
        platformOrderMap.set(product.id, product.order);
      });
    }

    // Sort sub-product
    feeReview.subProducts = feeReview.subProducts.sort((a, b) => {
      const productOrderA = platformOrderMap.get(a.productId);
      const productOrderB = platformOrderMap.get(b.productId);
      if (productOrderA && productOrderB && productOrderA !== productOrderB) {
        return productOrderA - productOrderB;
      }
      return a?.order - b?.order;
    });
    feeReview.subProducts = feeReview.subProducts.map((subProduct) => {
      let feeDetails: FeeDetailDTO[] = [];
      if (!subProduct.detail) return { ...subProduct, feeDetails: [] };
      const {
        totalFees,
        totalFeesPercentage,
        idpsAdminAndAccFees,
        idpsAdminAndAccFeesPercentage,
        superAdminAndAccFees,
        superAdminAndAccFeesPercentage,
        transactionFees,
        transactionFeesPercentage,
        intlSecuritiesOrBondHoldingFees,
        intlSecuritiesOrBondHoldingFeesPercentage,
        cashFees,
        cashFeesPercentage,
        expenseRecoveryAndOtherFees,
        expenseRecoveryAndOtherFeesPercentage,
        netInterestEarnedOnCash,
        netInterestEarnedOnCashPercentage,
        pysRefund,
        pysRefundPercentage,
        hasCashBalances,
      } = subProduct.detail;
      if (isNumber(totalFees) && isNumber(totalFeesPercentage)) {
        feeDetails.push({
          name: "Total fees",
          dollar: totalFees,
          percentage: totalFeesPercentage,
        });
      }
      if (
        isNumber(idpsAdminAndAccFees) &&
        isNumber(idpsAdminAndAccFeesPercentage)
      ) {
        feeDetails.push({
          name: "IDPS admin & account fees",
          dollar: idpsAdminAndAccFees,
          percentage: idpsAdminAndAccFeesPercentage,
        });
      }
      if (
        isNumber(superAdminAndAccFees) &&
        isNumber(superAdminAndAccFeesPercentage)
      ) {
        feeDetails.push({
          name: "Super admin & account fees",
          dollar: superAdminAndAccFees,
          percentage: superAdminAndAccFeesPercentage,
        });
      }
      if (
        isNumber(intlSecuritiesOrBondHoldingFees) &&
        isNumber(intlSecuritiesOrBondHoldingFeesPercentage)
      ) {
        feeDetails.push({
          name: "International & bond holding fees",
          dollar: intlSecuritiesOrBondHoldingFees,
          percentage: intlSecuritiesOrBondHoldingFeesPercentage,
        });
      }

      if (isNumber(cashFees) && isNumber(cashFeesPercentage)) {
        feeDetails.push({
          name: "Cash fees",
          dollar: cashFees,
          percentage: cashFeesPercentage,
        });
      }

      if (
        isNumber(expenseRecoveryAndOtherFees) &&
        isNumber(expenseRecoveryAndOtherFeesPercentage)
      ) {
        feeDetails.push({
          name: "Expense recovery & other fees",
          dollar: expenseRecoveryAndOtherFees,
          percentage: expenseRecoveryAndOtherFeesPercentage,
        });
      }
      if (isNumber(transactionFees) && isNumber(transactionFeesPercentage)) {
        feeDetails.push({
          name: "Transaction fees",
          dollar: transactionFees,
          percentage: transactionFeesPercentage,
        });
      }
      if (isNumber(pysRefund) && isNumber(pysRefundPercentage)) {
        feeDetails.push({
          name: "PYS refund",
          dollar: pysRefund,
          percentage: pysRefundPercentage,
        });
      }

      if (hasCashBalances) {
        feeDetails.push({
          name: "Net interest earned on cash",
          dollar: netInterestEarnedOnCash ?? null,
          percentage: netInterestEarnedOnCashPercentage ?? null,
        });
      }

      //Create fee details and uuid for rate card
      return {
        ...subProduct,
        id:
          subProduct.id +
          (subProduct.rateCardVersion ? `|${subProduct.rateCardVersion}` : ""),
        feeDetails,
      };
    });
  }
  return feeReview;
};
