import { Components, Theme } from "@mui/material";
export const muiDividerTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiDivider"> => ({
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: theme.palette.secondary[100],
      },
    },
  },
});
