import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { BusinessMetricCategories } from "@models/platform-analysis/entities/steps/businessMetric";
import { compileBusinessMetricSelection } from "@services/platform-analysis/util";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getBusinessMetricSelectionStep(
  platformAnalysisId: string,
): Promise<APIResponse<BusinessMetricCategories>> {
  const response = await APIGatewayService.get<BusinessMetricCategories>(
    generatePath(APIExtRoutes.platformAnalysisBusinessMetricSelections, {
      platformAnalysisId,
    }),
  );
  if (response?.data) {
    compileBusinessMetricSelection(response?.data);
  }

  return handleErrorProxy(response);
}

export async function putBusinessMetricSelectionStep(
  platformAnalysisId: string,
  businessMetricSelections: BusinessMetricCategories,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.platformAnalysisBusinessMetricSelections, {
      platformAnalysisId,
    }),
    businessMetricSelections,
  );
  return handleErrorProxy(response, "Changes have been saved.");
}
