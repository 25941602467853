import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { PageRoutes } from "@constants";
import { useEditProduct } from "@hooks/useEditProduct";
import { useNotification } from "@hooks/useNotification";
import { HubConnectionState } from "@microsoft/signalr";
import { DefaultSocketErrorMessage } from "@models/core/socket";
import { RootState } from "@redux/store";
import {
  updateEditMode,
  updateLoadingEditMode,
  updateShowKickoutDialog,
} from "@redux/slices/product";
import { SignalRService } from "@services/signalr/signalRService";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";

export interface ConfirmKickOutDialogProps {
  supplierId?: string;
  productId?: string;
}

export const ConfirmKickOutDialog = ({
  supplierId,
  productId,
}: ConfirmKickOutDialogProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { handleOnClaimingEditingPermission } = useEditProduct();
  const navigate = useNavigate();
  const { notify } = useNotification();

  const {
    productUI: { showKickoutDialog, currentEditor },
  } = useSelector((state: RootState) => state.product);

  const handleOnClickYes = async () => {
    setIsLoading(true);
    const connection = SignalRService.getHubConnection();
    if (connection && connection.state === HubConnectionState.Connected) {
      await handleOnClaimingEditingPermission(productId || "", () => {
        dispatch(updateEditMode(true));
        navigate(
          generatePath(PageRoutes.supplierProducts, {
            supplierId,
            productId,
          }),
        );
      });
      setIsLoading(false);
      dispatch(updateShowKickoutDialog(false));
    } else {
      ///else show error here
      setIsLoading(false);
      notify(DefaultSocketErrorMessage, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnClose = () => {
    dispatch(updateShowKickoutDialog(false));
    dispatch(updateLoadingEditMode(false));
    dispatch(updateEditMode(false));
    setIsLoading(false);
  };

  return (
    <>
      <SHDialog
        open={showKickoutDialog || false}
        onClose={handleOnClose}
        width={600}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        header={
          <SHStack alignItems="center" textAlign={"center"} width={{ xs: 590 }}>
            <SHTypography variant="h2">
              WARNING: Another user is editing the Profile
            </SHTypography>
          </SHStack>
        }
        body={
          <SHStack>
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                width: 430,
              }}
            >
              {currentEditor} is currently editing the Profile. We only allow
              one user to edit the Profile at one time. If you continue, you
              will kick them out of edit mode, and their unsaved changes will be
              discarded.
            </SHTypography>
            <br />
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              Proceed with kicking out {currentEditor}?
            </SHTypography>
          </SHStack>
        }
        footer={
          <SHStack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={7}
          >
            <SHButton
              color="primary"
              variant="text"
              onClick={handleOnClose}
              size="extraMedium"
            >
              Cancel
            </SHButton>
            <SHButton
              color="primary"
              variant="contained"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={handleOnClickYes}
              size="extraMedium"
            >
              Yes, kick them out
            </SHButton>
          </SHStack>
        }
      />
    </>
  );
};
