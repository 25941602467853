import { isEmpty } from "lodash";

import * as Yup from "yup";

export const standardFeatureValidators = Yup.object().shape({
  name: Yup.string()
    .required("Feature name is required")
    .max(100, "Feature name must be less than 100 characters"),
  fieldType: Yup.object().shape({
    id: Yup.string().required("Field type name is required"),
  }),
  sectionId: Yup.string().required("Section is required"),
});

export const featureFeatureValidators = Yup.object().shape({
  name: Yup.string()
    .required("Feature name is required")
    .max(100, "Feature name must be less than 100 characters"),
  fieldType: Yup.object().shape({
    id: Yup.string().required("Field type name is required"),
  }),
  sectionId: Yup.string().required("Feature group is required"),
  subSectionId: Yup.string().required("Feature sub-group"),
});

export const generateFeatureFormTitle = (featureName?: string) => {
  if (!featureName || isEmpty(featureName)) return "Add new feature";
  return featureName;
};
