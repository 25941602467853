export const DefaultRowPerPageOptions: {
    label: string;
    value: number | "all";
}[] = [
    {
        label: "25",
        value: 25,
    },
    {
        label: "50",
        value: 50,
    },
    {
        label: "100",
        value: 100,
    },
    {
        label: "All",
        value: "all",
    },
];
