import { WarningSVG } from "@components/svgs/warning";
import { ProductListDataStatus } from "@models/product/enums/productListDataStatus";
import { Stack, Typography, useTheme } from "@mui/material";
import { isEmpty } from "lodash";
import { useMemo } from "react";

export interface DataStatusBadgeProps {
  status?: ProductListDataStatus | null;
}
export const DataStatusBadge = ({ status, ...props }: DataStatusBadgeProps) => {
  const { palette } = useTheme();

  const color = useMemo(() => {
    return status === ProductListDataStatus.Missing
      ? palette.secondary.main
      : palette.warning.main;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (isEmpty(status)) return <></>;
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      data-testid="data-status-badge"
    >
      <WarningSVG backgroundColor={color} iconColor={palette.common.white} />
      <Typography
        variant="body1"
        fontSize={13}
        fontWeight={400}
        paddingTop={"4px"}
        color={color}
      >
        {status}
      </Typography>
    </Stack>
  );
};
