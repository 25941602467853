import { SHStack, SHTypography } from "@components/design-systems";
import { FeeDetailDTO } from "@models/platform-analysis/entities/steps/fee";

interface LabelLineDetailProps {
  firstProductFeeDetails: FeeDetailDTO[];
}
export const LabelLineDetail = ({
  firstProductFeeDetails,
}: LabelLineDetailProps) => {
  return (
    <SHStack direction={"row"} flex={1} paddingLeft={"6px"}>
      {firstProductFeeDetails.map((feeDetail, index) => (
        <SHStack
          key={index}
          flex={1}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            mr: index === 0 ? "15px" : "1.5px",
            ml: index === firstProductFeeDetails.length - 1 ? "15px" : "1.5px",
          }}
        >
          <SHTypography
            variant="body4"
            textAlign={"center"}
            lineHeight={1}
            fontWeight={400}
          >
            {feeDetail.name}
          </SHTypography>
        </SHStack>
      ))}
    </SHStack>
  );
};
