import { APIExtRoutes } from "@constants/routes";
import { HistoryRelease } from "@models/configuration";
import { APIResponse } from "@models/core";
import { APIGatewayService, handleErrorProxy } from "@services/shService";

export async function getHistoryRelease(): Promise<
  APIResponse<HistoryRelease[]>
> {
  const response = await APIGatewayService.get<APIResponse<HistoryRelease[]>>(
    APIExtRoutes.configurationHistoryReleases,
  );

  return handleErrorProxy(response);
}
