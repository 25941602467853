import { SHButton, SHDialog, SHStack } from "@components/design-systems";

interface RefreshPlatformDialogProps {
  onClose?: () => void;
  onSubmit?: () => void;
}

export const RefreshPlatformDialog = ({
  onClose,
  onSubmit,
}: RefreshPlatformDialogProps) => {
  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: { xs: "100%", md: 656 },
        },
      }}
      header={`Refresh all platform data to latest version?`}
      body={`The information we hold on platforms may have changed since you created this analysis. If you change the platforms included, we will refresh all platform data to the latest version. If applicable, the current rate card selection will clear. Refresh data?`}
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onClose}
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onSubmit}
          >
            Yes, refresh data
          </SHButton>
        </SHStack>
      }
    />
  );
};
