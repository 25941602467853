import { useAuth0 } from "@auth0/auth0-react";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import { CompleteProfileFirstStepDialog } from "@components/dialogs/complete-profile-first-step";
import {
  CompleteProfileSupplierUser,
  CompleteProfileSupplierUserSale,
} from "@components/dialogs/complete-profile-first-step/model";
import { CompleteProfileLastStepDialog } from "@components/dialogs/complete-profile-last-step";
import { PageRoutes } from "@constants/routes";
import { useNotification } from "@hooks/useNotification";
import { UserType } from "@models/auth";
import {
  SupplierUserDTO,
  SupplierUserSelfUpdateDTO,
} from "@models/supplier/entities/supplierUser";
import { GetUserInfoDTO } from "@models/users/entities/user";
import { SupplierUsersGroup } from "@models/users/group";
import { RootState } from "@redux/store";
import { updateUser } from "@redux/slices/auth";
import { refreshAccessToken } from "@services/shService";
import {
  completeProfileSupplierUser,
  getSupplierUser,
} from "@services/suppliers";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffectOnce } from "react-use";

export const CompleteProfileSupplier: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { notify } = useNotification();
  const { user } = useSelector((state: RootState) => state.auth);
  const [showStep, setShowStep] = useState<"first" | "last" | null>(null);
  const [isCompletingFirstStep, setIsCompletingFirstStep] = useState<
    "first" | "last" | null
  >(null);

  const [supplierUserInfo, setSupplierUserInfo] =
    useState<CompleteProfileSupplierUser>();
  const [supplierUserSaleInfo, setSupplierUserSaleInfo] =
    useState<CompleteProfileSupplierUserSale>(
      new CompleteProfileSupplierUser(),
    );

  const isAccessed =
    user?.userType &&
    SupplierUsersGroup.includes(user.userType) &&
    !user?.userMetadata?.is_profile_completed;

  useEffectOnce(() => {
    if (isAccessed) {
      loadSupplierUser();
    }
  });

  const loadSupplierUser = async () => {
    const { data, message } = await getSupplierUser(
      user?.userMetadata?.supplier_id,
      user?.auth0Id,
    );

    if (data) {
      const supplierUser = data as SupplierUserDTO;
      if (user?.userType === UserType.SupplierBdmSale) {
        setSupplierUserSaleInfo({
          userId: supplierUser?.auth0Id,
          firstName: supplierUser?.firstName,
          lastName: supplierUser?.lastName,
          jobTitle: supplierUser?.jobTitle,
          phoneNumber: supplierUser?.phoneNumber,
          linkedInProfileUrl: supplierUser?.linkedInProfileUrl,
          preferredContactHours: supplierUser?.preferredContactHours,
          publicEmail: supplierUser?.publicEmail,
          publicPhoneNumber: supplierUser?.publicPhoneNumber,
          regions: supplierUser?.regions,
        } as CompleteProfileSupplierUserSale);
      }
      setSupplierUserInfo({
        userId: supplierUser?.auth0Id,
        firstName: supplierUser?.firstName,
        lastName: supplierUser?.lastName,
        jobTitle: supplierUser?.jobTitle,
        phoneNumber: supplierUser?.phoneNumber,
        workEmail: supplierUser?.email,
        isPublish: supplierUser?.isPublic,
      } as CompleteProfileSupplierUser);
      setShowStep("first");
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnCompleteFirstStep = async (
    userInfo: CompleteProfileSupplierUser,
  ) => {
    delete userInfo?.workEmail;
    delete userInfo?.isAgree;

    setIsCompletingFirstStep("first");
    const { isSuccess, message } = await completeProfileSupplierUser(
      user?.auth0Id,
      userInfo,
    );
    setIsCompletingFirstStep(null);

    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      refreshAccessToken(getAccessTokenSilently);
      if (user?.userType !== UserType.SupplierBdmSale || !userInfo?.isPublish) {
        dispatch(
          updateUser({
            ...user,
            userMetadata: { ...user?.userMetadata, is_profile_completed: true },
          } as GetUserInfoDTO),
        );
        navigate(PageRoutes.home);
      } else {
        setSupplierUserSaleInfo({
          ...supplierUserSaleInfo,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          phoneNumber: userInfo.phoneNumber,
          jobTitle: userInfo.jobTitle,
        });
        setShowStep("last");
      }
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnCompleteLastStep = async (
    userInfo: CompleteProfileSupplierUserSale,
  ) => {
    setIsCompletingFirstStep("last");
    const { isSuccess, message } = await completeProfileSupplierUser(
      userInfo.userId,
      userInfo as SupplierUserSelfUpdateDTO,
    );
    setIsCompletingFirstStep(null);

    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      dispatch(
        updateUser({
          ...user,
          userMetadata: { ...user?.userMetadata, is_profile_completed: true },
        } as GetUserInfoDTO),
      );
      navigate(PageRoutes.home);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  if (!isAccessed) return <AccessDeniedDialog />;

  return (
    <>
      {showStep === "first" && (
        <CompleteProfileFirstStepDialog
          onComplete={handleOnCompleteFirstStep}
          isCompleting={isCompletingFirstStep}
          supplierUserInfo={supplierUserInfo}
        />
      )}
      {showStep === "last" && (
        <CompleteProfileLastStepDialog
          onComplete={handleOnCompleteLastStep}
          onSkip={() => {
            dispatch(
              updateUser({
                ...user,
                userMetadata: {
                  ...user?.userMetadata,
                  is_profile_completed: true,
                },
              } as GetUserInfoDTO),
            );
            navigate(PageRoutes.home);
          }}
          supplierUserInfo={supplierUserSaleInfo}
        />
      )}
    </>
  );
};
