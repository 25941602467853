import {
  ConfigurationGroup,
  FieldTypeId,
  HighlightType,
  SectionDisplayTypeId,
  SectionType,
} from "@models/configuration";
import { SubProductDTO } from "@models/product/entities/subProduct";
import { ProductListDTO } from "@models/product/entities/product";
import { FeeFieldType } from "@models/product/enums/feeFieldType";
import { ProductDataStatus } from "@models/product/enums/productDataStatus";
import { ProductApprovalStatus } from "@models/product/enums/status";

export const firstSectionMapping: {
  [key in string]?: {
    title: string;
    description: string;
  };
} = {
  Overview: {
    title: "Overview",
    description:
      "The ‘Overview’ tab of your platform profile provides a general introduction to your platform",
  },
  Information: {
    title: "Information",
    description:
      "The “Information” tab of your platform profile covers key details of your platform’s business and how it operates",
  },
  ESG: {
    title: "Environmental, social and governance (ESG)",
    description:
      "The “ESG” tab of your platform profile highlights your approach to ESG, including policies and initiatives",
  },
};

export const ProductGroupsMock: ConfigurationGroup[] = [
  {
    id: "1ca2d14b-2122-4c5e-8d03-faef5c0fad7b",
    name: "Overview",
    sectionType: SectionType.Standard,
    order: 1,
    createdDate: new Date(),
    dataStatus: ProductDataStatus.MissingData,
    highlightType: HighlightType.Added,
    sections: [],
  },
  {
    id: "1ca2d14b-2122-4c5e-8d03-faef5c0fad8b",
    name: "Information",
    sectionType: SectionType.Standard,
    order: 2,
    createdDate: new Date(),
    dataStatus: ProductDataStatus.MissingData,
    highlightType: HighlightType.Added,
    sections: [],
  },
  {
    id: "1ca2d14b-2122-4c5e-8d03-faef5c0fad9b",
    name: "ESG",
    sectionType: SectionType.Standard,
    order: 2,
    createdDate: new Date(),
    dataStatus: ProductDataStatus.MissingData,
    highlightType: HighlightType.Added,
    sections: [],
  },
  {
    id: "1ca2d14b-2122-4c5e-8d03-faef5c0fad0b",
    name: "Features",
    sectionType: SectionType.HasSubSections,
    order: 2,
    createdDate: new Date(),
    dataStatus: ProductDataStatus.MissingData,
    highlightType: HighlightType.Added,
    sections: [],
  },
];

export const ProductDetailMock: ProductListDTO = {
  id: "015e2acf-209d-4847-8eaa-b6740cfeb323",
  supplierId: "015e2acf-209d-4247-8eaa-b6740cfeb323",
  supplierName: "Supplier",
  status: "Active",
  dataStatus: ProductDataStatus.MissingData,
  createdDate: new Date(),
  configurationVersion: 1,
  productVersion: 0,
  groups: [
    {
      id: "1ca2d14b-2122-4c5e-8d03-faef5c0fad7b",
      name: "Overview",
      sectionType: SectionType.Standard,
      dataStatus: ProductDataStatus.MissingData,
      highlightType: HighlightType.Edited,
      order: 1,
      createdDate: new Date(),
      sections: [
        {
          configurationGroupId: "035e2acf-209d-4847-8eaa-b6740cfeb321",
          description:
            "Number of advised and non-advised clients on the platform",
          sectionDisplayType: {
            id: SectionDisplayTypeId.MainBodyPage,
            description: "Side Panel",
          },
          order: 5,
          iconUrl:
            "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1667794204000mac-dark.jpg",
          iconUrlS3Key: "1667794204000mac-dark.jpg",
          isArchived: false,
          isLocked: false,
          dataStatus: ProductDataStatus.MissingData,
          flagType: "Draft",
          highlightType: HighlightType.Cloned,
          configurationVersion: 1,
          createdDate: new Date(),
          subSections: [],
          features: [
            {
              id: "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
              name: "Number Field",
              description: "",
              order: 2,
              fieldType: {
                id: FieldTypeId.Number,
                description: "Number",
              },
              isBusinessMetric: false,
              businessMetricType: null,
              isPositiveScore: false,
              isArchived: false,
              dataStatus: ProductDataStatus.MissingData,
              flagType: "Draft",
              highlightType: HighlightType.Cloned,
              createdDate: new Date(),
              lastModifiedDated: new Date(),
              publishedUserId: "",
              lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
              lastUpdateUserName: "Ngoc Nguyen",
              configurationFeatureMainId:
                "66c259d6-fe30-4cf1-aec2-57aaa45165cd",
              configurationVersion: 1,
              productData: {
                id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                configurationFeatureId: "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                value: "115052",
                additionalValue: "",
                isPositiveScore: false,
                flagType: "Draft",
                highlightType: HighlightType.Added,
                dataStatus: ProductDataStatus.MissingData,
                approvalStatus: ProductApprovalStatus.Pending,
                configurationVersion: 1,
                productVersion: 0,
                createdDate: new Date(),
              },
            },
            {
              id: "7d4fd13a-8a08-411c-83a5-90dece4fab564",
              name: "Short Text Field",
              description: "",
              order: 3,
              fieldType: {
                id: FieldTypeId.TextShort,
                description: "TextShort",
              },
              isBusinessMetric: false,
              businessMetricType: null,
              isPositiveScore: false,
              isArchived: false,
              dataStatus: ProductDataStatus.MissingData,
              flagType: "Draft",
              highlightType: HighlightType.Cloned,
              createdDate: new Date(),
              lastModifiedDated: new Date(),
              publishedUserId: "",
              lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
              lastUpdateUserName: "Ngoc Nguyen",
              configurationFeatureMainId:
                "66c259d6-fe30-4cf1-aec2-57aaa45165cd",
              configurationVersion: 1,
              productData: {
                id: "085e2acf-209d-3847-8eaa-b6740cfe646",
                productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                configurationFeatureId: "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                value: "random text",
                additionalValue: "",
                isPositiveScore: false,
                flagType: "Draft",
                highlightType: HighlightType.Added,
                dataStatus: ProductDataStatus.MissingData,
                approvalStatus: ProductApprovalStatus.Pending,
                configurationVersion: 1,
                productVersion: 0,
                createdDate: new Date(),
              },
            },
            {
              id: "7d4fd13a-8a08-411c-83a5-90de435ce4fabsds",
              name: "Long Text Field",
              description: "Long Text Field description",
              order: 4,
              fieldType: {
                id: FieldTypeId.TextLong,
                description: "TextLong",
              },
              isBusinessMetric: true,
              businessMetricType: null,
              isPositiveScore: false,
              isArchived: false,
              dataStatus: ProductDataStatus.MissingData,
              flagType: "Draft",
              highlightType: HighlightType.Cloned,
              createdDate: new Date(),
              lastModifiedDated: new Date(),
              publishedUserId: "",
              lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
              lastUpdateUserName: "Ngoc Nguyen",
              configurationFeatureMainId:
                "66c259d6-fe30-4cf1-aec2-57aaa45165cd",
              configurationVersion: 1,
              productData: {
                id: "085e2acf-209d-3847-8456eaa-b6740cfe646",
                productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                configurationFeatureId: "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                value: "<b>Text here</b>",
                additionalValue: "",
                isPositiveScore: false,
                flagType: "Draft",
                highlightType: HighlightType.Added,
                dataStatus: ProductDataStatus.MissingData,
                approvalStatus: ProductApprovalStatus.Pending,
                configurationVersion: 1,
                productVersion: 0,
                createdDate: new Date(),
              },
            },
            {
              id: "7d4fd13a-8a08-411c-83a5-90de4334235ce4fabsds",
              name: "Number Million Field",
              description: "",
              order: 4,
              fieldType: {
                id: FieldTypeId.NumberMillion,
                description: "NumberMillion",
              },
              isBusinessMetric: false,
              businessMetricType: null,
              isPositiveScore: false,
              isArchived: false,
              dataStatus: ProductDataStatus.MissingData,
              flagType: "Draft",
              highlightType: HighlightType.Cloned,
              createdDate: new Date(),
              lastModifiedDated: new Date(),
              publishedUserId: "",
              lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
              lastUpdateUserName: "Ngoc Nguyen",
              configurationFeatureMainId:
                "66c259d6-fe30-4cf1-aec2-57aaa45165cd",
              configurationVersion: 1,
              productData: {
                id: "085e2acf-209d-3847-8456eaa-b3236740cfe646",
                productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                configurationFeatureId: "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                value: "100",
                additionalValue: "",
                isPositiveScore: false,
                flagType: "Draft",
                highlightType: HighlightType.Added,
                dataStatus: ProductDataStatus.MissingData,
                approvalStatus: ProductApprovalStatus.Pending,
                configurationVersion: 1,
                productVersion: 0,
                createdDate: new Date(),
              },
            },
            {
              id: "7d4fd13a-8a08-411c-123-90de4334235cfabsds",
              name: "YesNo Field",
              description: "Description Here",
              order: 4,
              fieldType: {
                id: FieldTypeId.YesNo,
                description: "YesNo",
              },
              isBusinessMetric: true,
              businessMetricType: null,
              isPositiveScore: false,
              isArchived: false,
              dataStatus: ProductDataStatus.MissingData,
              flagType: "Draft",
              highlightType: HighlightType.Cloned,
              createdDate: new Date(),
              lastModifiedDated: new Date(),
              publishedUserId: "",
              lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
              lastUpdateUserName: "Ngoc Nguyen",
              configurationFeatureMainId:
                "66c259d6-fe30-4cf1-aec2-57aaa45165cd",
              configurationVersion: 1,
              productData: {
                id: "085e2acf-209d-2e3-8456eaa-b3236740cfe646",
                productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                configurationFeatureId: "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                value: "true",
                additionalValue: "",
                isPositiveScore: false,
                flagType: "Draft",
                highlightType: HighlightType.Added,
                dataStatus: ProductDataStatus.MissingData,
                approvalStatus: ProductApprovalStatus.Pending,
                configurationVersion: 1,
                productVersion: 0,
                createdDate: new Date(),
              },
            },
          ],
          id: "15deb8d8-78b9-40af-a537-5e35bea53f73",
          name: "Number of clients/members on platform",
        },
        {
          configurationGroupId: "035e2acf-209d-4847-8eaa-b6740cfd2321",
          description: "Section Description",
          sectionDisplayType: {
            id: SectionDisplayTypeId.SidePanel,
            description: "Side Panel",
          },
          order: 5,
          iconUrl: "",
          iconUrlS3Key: "",
          isArchived: false,
          isLocked: false,
          dataStatus: ProductDataStatus.MissingData,
          flagType: "Draft",
          highlightType: HighlightType.Cloned,
          configurationVersion: 1,
          createdDate: new Date(),
          subSections: [],
          features: [
            {
              id: "7d4fd13a-8a08-411c-123-90de4334235ce4fabsds",
              name: "Image field",
              description: "Des",
              order: 5,
              fieldType: {
                id: FieldTypeId.Image,
                description: "Image",
              },
              isBusinessMetric: false,
              businessMetricType: null,
              isPositiveScore: false,
              isArchived: false,
              dataStatus: ProductDataStatus.MissingData,
              flagType: "Draft",
              highlightType: HighlightType.Cloned,
              createdDate: new Date(),
              lastModifiedDated: new Date(),
              publishedUserId: "",
              lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
              lastUpdateUserName: "Ngoc Nguyen",
              configurationFeatureMainId:
                "66c259d6-fe30-4cf1-aec2-57aaa45165cd",
              configurationVersion: 1,
              productData: {
                id: "085e2acf-209d-2e3-s3535-b3236740cfe646",
                productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                configurationFeatureId: "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                value:
                  '{"url":"https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50.jpg","key":"1231313511"}',
                additionalValue: "",
                isPositiveScore: false,
                flagType: "Draft",
                highlightType: HighlightType.Added,
                dataStatus: ProductDataStatus.MissingData,
                approvalStatus: ProductApprovalStatus.Pending,
                configurationVersion: 1,
                productVersion: 0,
                createdDate: new Date(),
              },
            },
            {
              id: "7d4fd13a-8a08-411c-83a5-90dece4fab564",
              name: "Short Text Field",
              description: "",
              order: 3,
              fieldType: {
                id: FieldTypeId.TextShort,
                description: "TextShort",
              },
              isBusinessMetric: false,
              businessMetricType: null,
              isPositiveScore: false,
              isArchived: false,
              dataStatus: ProductDataStatus.MissingData,
              flagType: "Draft",
              highlightType: HighlightType.Cloned,
              createdDate: new Date(),
              lastModifiedDated: new Date(),
              publishedUserId: "",
              lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
              lastUpdateUserName: "Ngoc Nguyen",
              configurationFeatureMainId:
                "66c259d6-fe30-4cf1-aec2-57aaa45165cd",
              configurationVersion: 1,
              productData: {
                id: "085e2acf-209d-3847-8eaa-b6740cfe646",
                productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                configurationFeatureId: "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                value: "random text",
                additionalValue: "",
                isPositiveScore: false,
                flagType: "Draft",
                highlightType: HighlightType.Added,
                dataStatus: ProductDataStatus.MissingData,
                approvalStatus: ProductApprovalStatus.Pending,
                configurationVersion: 1,
                productVersion: 0,
                createdDate: new Date(),
              },
            },
          ],
          id: "15deb8d8-78b9-40af23123-a537-5e35bea53f73",
          name: "Section Name Mock",
        },
        {
          configurationGroupId: "035e2acf-209d-4847s3523-8eaa-b6740cfd2321",
          description: "Section Description 2",
          sectionDisplayType: {
            id: SectionDisplayTypeId.SidePanel,
            description: "Side Panel",
          },
          order: 5,
          iconUrl:
            "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1667794204000mac-dark.jpg",
          iconUrlS3Key: "1667794204000mac-dark.jpg",
          isArchived: false,
          isLocked: false,
          dataStatus: ProductDataStatus.MissingData,
          flagType: "Draft",
          highlightType: HighlightType.Cloned,
          configurationVersion: 1,
          createdDate: new Date(),
          subSections: [],
          features: [],
          id: "15deb8d8-78b9-40af23123-a53dfwer3wr4-5e35bea53f73",
          name: "Section Name Mock 2",
        },
      ],
    },
    {
      id: "10b2264f-bdde-4151-916e-41402e5089b7",
      name: "Features",
      sectionType: SectionType.HasSubSections,
      dataStatus: ProductDataStatus.MissingData,
      order: 4,
      createdDate: new Date(),
      sections: [
        {
          id: "1261f51d-74b5-4ac4-83ed-ff363b076dfe",
          name: "Integration",
          configurationGroupId: "10b2264f-bdde-4151-916e-41402e5089b7",
          description: "Exchanging data and instructions with other systems",
          sectionDisplayType: {
            id: SectionDisplayTypeId.MainBodyPage,
            description: "Main Body Page",
          },
          order: 11,
          iconUrl: "",
          iconUrlS3Key: "",
          isArchived: false,
          isLocked: false,
          dataStatus: ProductDataStatus.MissingData,
          flagType: "Draft",
          highlightType: HighlightType.Edited,
          configurationVersion: 1,
          createdDate: new Date(),
          subSections: [
            {
              id: "bb3f8b7d-353d-4b54-bc88-d3481b0ed0d1",
              name: "SMSF software",
              configurationSectionId: "1261f51d-74b5-4ac4-83ed-ff363b076dfe",
              configurationGroupId: "",
              description: "SMSF software that the platform can send data to",
              sectionDisplayType: {
                id: "Feature",
                description: "Feature",
              },
              order: 7,
              iconUrl: "",
              iconUrlS3Key: "",
              isArchived: false,
              isLocked: false,
              dataStatus: ProductDataStatus.MissingData,
              flagType: "Draft",
              highlightType: "Cloned",
              configurationVersion: 1,
              createdDate: new Date(),
              subSections: undefined,
              features: [
                {
                  id: "1c3055c5-3411-4a0e-9f39-5e899ea35879",
                  name: "BGL Simple Fund 360",
                  description:
                    "Data feed from platform to BGL Simple Fund 360 available",
                  order: 1,
                  fieldType: {
                    id: "YesNo",
                    description: "Yes/No",
                  },
                  isBusinessMetric: true,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "", //true/false/null/abc/123
                    additionalValue: "",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
                {
                  id: "1c3055c5-34114534-43432423a0e-9f39-5e899ea35879",
                  name: "Khan",
                  description: "Tran Vi Khan",
                  order: 1,
                  fieldType: {
                    id: "YesNo",
                    description: "Yes/No",
                  },
                  isBusinessMetric: false,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "false", //true/false/null/abc/123
                    additionalValue: "",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
                {
                  id: "1c3055c5-3411-43434a0e-9f39-5e899ea35879",
                  name: "Phong",
                  description: "Phong available",
                  order: 1,
                  fieldType: {
                    id: "YesNo",
                    description: "Yes/No",
                  },
                  isBusinessMetric: false,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "true", //true/false/null/abc/123
                    additionalValue: "<i>Khan</i>",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
                {
                  id: "1c3055c5-3411-4a0343e-9f39-5e899ea35879",
                  name: "Gallery",
                  description:
                    "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
                  order: 1,
                  fieldType: {
                    id: "Slideshow",
                    description: "Slideshow",
                  },
                  isBusinessMetric: false,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "[]",
                    additionalValue: "",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
              ],
            },
            {
              id: "bb3f8b7d-353d-4b5434534-bc88-d3481b0ed0d1",
              name: "ASDFS software",
              configurationSectionId: "1261f51d-74b5-4ac4-83ed-ff363b076dfe",
              configurationGroupId: "",
              description: "SMSF software that the platform can send data to",
              sectionDisplayType: {
                id: "Feature",
                description: "Feature",
              },
              order: 7,
              iconUrl: "",
              iconUrlS3Key: "",
              isArchived: false,
              isLocked: false,
              dataStatus: ProductDataStatus.MissingData,
              flagType: "Draft",
              highlightType: "Cloned",
              configurationVersion: 1,
              createdDate: new Date(),
              subSections: undefined,
              features: [
                {
                  id: "1c3055c5-3411-4a0e-9f39-5e899ea35879",
                  name: "BGL Simple Fund 360",
                  description:
                    "Data feed from platform to BGL Simple Fund 360 available",
                  order: 1,
                  fieldType: {
                    id: "YesNo",
                    description: "Yes/No",
                  },
                  isBusinessMetric: true,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "", //true/false/null/abc/123
                    additionalValue: "",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
                {
                  id: "1c3055c5-34114534-43432423a0e-9f39-5e899ea35879",
                  name: "Khan",
                  description: "Tran Vi Khan",
                  order: 1,
                  fieldType: {
                    id: "YesNo",
                    description: "Yes/No",
                  },
                  isBusinessMetric: false,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "false", //true/false/null/abc/123
                    additionalValue: "",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
                {
                  id: "1c3055c5-3411-43434a0e-9f39-5e899ea35879",
                  name: "Phong",
                  description: "Phong available",
                  order: 1,
                  fieldType: {
                    id: "YesNo",
                    description: "Yes/No",
                  },
                  isBusinessMetric: false,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "true", //true/false/null/abc/123
                    additionalValue: "<i>Khan</i>",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
                {
                  id: "1c3055c5-3411-4a0343e-9f39-5e899ea35879",
                  name: "Gallery",
                  description:
                    "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
                  order: 1,
                  fieldType: {
                    id: "Slideshow",
                    description: "Slideshow",
                  },
                  isBusinessMetric: false,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "[]",
                    additionalValue: "",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
              ],
            },
          ],
          features: null,
        },
        {
          id: "1263241f51d-74b5-4ac4-83ed-ff363b076dfe",
          name: "Khan",
          configurationGroupId: "10b2264f-bdde-4151-916e-41402e5089b7",
          description: "Khan with other systems",
          sectionDisplayType: {
            id: SectionDisplayTypeId.MainBodyPage,
            description: "Main Body Page",
          },
          order: 11,
          iconUrl: "",
          iconUrlS3Key: "",
          isArchived: false,
          isLocked: false,
          dataStatus: ProductDataStatus.MissingData,
          flagType: "Draft",
          highlightType: HighlightType.Edited,
          configurationVersion: 1,
          createdDate: new Date(),
          subSections: [
            {
              id: "bb3f8b7d-353d-4b54-bc88-d3481b0ed0d1",
              name: "Khan Sau Doi",
              configurationSectionId: "1261f51d-74b5-4ac4-83ed-ff363b076dfe",
              configurationGroupId: "",
              description: "Khan Sau Doi can send data to",
              sectionDisplayType: {
                id: "Feature",
                description: "Feature",
              },
              order: 7,
              iconUrl: "",
              iconUrlS3Key: "",
              isArchived: false,
              isLocked: false,
              dataStatus: ProductDataStatus.MissingData,
              flagType: "Draft",
              highlightType: "Cloned",
              configurationVersion: 1,
              createdDate: new Date(),
              subSections: undefined,
              features: [
                {
                  id: "1c3055c5-3411-4a0e-9f39-5e899ea35879",
                  name: "ASD ASDNASN",
                  description: "asdasd nd 360 available",
                  order: 1,
                  fieldType: {
                    id: "YesNo",
                    description: "Yes/No",
                  },
                  isBusinessMetric: true,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "", //true/false/null/abc/123
                    additionalValue: "",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
                {
                  id: "1c3055c5-34114534-43432423a0e-9f39-5e899ea35879",
                  name: "YGASJBD ASJNDK AS D SAKDK",
                  description: "asnd asdkas dakns dkas dkjajk",
                  order: 1,
                  fieldType: {
                    id: "YesNo",
                    description: "Yes/No",
                  },
                  isBusinessMetric: false,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "false", //true/false/null/abc/123
                    additionalValue: "",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
                {
                  id: "1c3055c5-3411-4a0343e-9f39-5e899ea35879",
                  name: "Gallery",
                  description:
                    "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
                  order: 1,
                  fieldType: {
                    id: "Slideshow",
                    description: "Slideshow",
                  },
                  isBusinessMetric: false,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "[]",
                    additionalValue: "",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
              ],
            },
            {
              id: "bb3f8b7d-353d-4b5434534-bc88-d3481b0ed0d1",
              name: "Tan Sau Doi software",
              configurationSectionId: "1261f51d-74b5-4ac4-83ed-ff363b076dfe",
              configurationGroupId: "",
              description: "Tan Sau Doi can send data to",
              sectionDisplayType: {
                id: "Feature",
                description: "Feature",
              },
              order: 7,
              iconUrl: "",
              iconUrlS3Key: "",
              isArchived: false,
              isLocked: false,
              dataStatus: ProductDataStatus.MissingData,
              flagType: "Draft",
              highlightType: "Cloned",
              configurationVersion: 1,
              createdDate: new Date(),
              subSections: undefined,
              features: [
                {
                  id: "1c3055c5-3411-4a0343e-9f39-5e899ea35879",
                  name: "Gallery",
                  description:
                    "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
                  order: 1,
                  fieldType: {
                    id: "Slideshow",
                    description: "Slideshow",
                  },
                  isBusinessMetric: false,
                  businessMetricType: null,
                  isPositiveScore: false,
                  isArchived: false,
                  dataStatus: ProductDataStatus.MissingData,
                  flagType: "Draft",
                  highlightType: "Cloned",
                  createdDate: new Date(),
                  lastModifiedDated: new Date(),
                  publishedUserId: "",
                  lastUpdateUserId: "auth0|634d05f0ce7238990ee6a55e",
                  lastUpdateUserName: "Ngoc Nguyen",
                  configurationFeatureMainId:
                    "e0ef4cda-54f5-4e95-90ee-ba0880a62077",
                  configurationVersion: 1,
                  productData: {
                    id: "085e2acf-209d-3847-8eaa-b6740cfeb321",
                    productId: "015e2acf-209d-4847-8eaa-b6740cfeb323",
                    configurationFeatureId:
                      "7d4fd13a-8a08-411c-83a5-90dece4fabd7",
                    value: "[]",
                    additionalValue: "",
                    isPositiveScore: false,
                    flagType: "Draft",
                    highlightType: "Edited",
                    dataStatus: ProductDataStatus.MissingData,
                    approvalStatus: "Approved",
                    configurationVersion: 1,
                    productVersion: 0,
                    createdDate: new Date(),
                  },
                },
              ],
            },
          ],
          features: null,
        },
      ],
    },
  ],
};

export const mockFeesAndRates: SubProductDTO[] = [
  {
    id: "fed5419e-a110-11ed-a8fc-0242ac120002",
    productId: "746c9d7a-ccb1-44eb-993a-c732543959c8",
    name: "Compact",
    status: "Active",
    order: 1,
    standardFeeSections: [
      {
        id: "fed5419e-a110-11ed-a8fc-0242ac120001",
        name: "PDS details",
        order: 1,
        features: [
          {
            id: "d1e6a396-a140-11ed-a8fc-0242ac120002",
            name: "Full name",
            order: 1,
            fieldType: FeeFieldType.OpenText,
            subProductData: {
              idpsValue: "BT Panorama Investments - Compact",
              pensionValue: "BT Panorama Super - Compact",
            },
          },
          {
            id: "78d413ce-a14a-11ed-a8fc-0242ac120002",
            name: "PDS date",
            order: 2,
            fieldType: FeeFieldType.OpenText,
            subProductData: {
              idpsValue: "30/09/2022",
              pensionValue: "05/06/2021",
            },
          },
        ],
      },
      {
        id: "d628caf6-a140-11ed-a8fc-0242ac120002",
        name: "Admin fees",
        order: 2,
        features: [
          {
            id: "dd2644b4-a140-11ed-a8fc-0242ac120002",
            name: "Minimum tiered admin fee ($)",
            order: 1,
            fieldType: FeeFieldType.Currency,
          },
          {
            id: "6df6acfa-a14a-11ed-a8fc-0242ac120002",
            name: "Maximum tiered admin fee ($)",
            order: 2,
            fieldType: FeeFieldType.Currency,
          },
          {
            id: "89c4070c-a14a-11ed-a8fc-0242ac120002",
            name: "Minimum admin fee ($)",
            order: 3,
            fieldType: FeeFieldType.Currency,
          },
          {
            id: "a4b75af0-a14a-11ed-a8fc-0242ac120002",
            name: "Maximum admin fee ($)",
            order: 4,
            fieldType: FeeFieldType.Currency,
          },
          {
            id: "4464c5ba-a14b-11ed-a8fc-0242ac120002",
            name: "Fixed admin fee",
            order: 5,
            fieldType: FeeFieldType.Currency,
            subProductData: {
              idpsValue: "180",
              pensionValue: "540",
            },
          },
          {
            id: "ffeb102e-a14a-11ed-a8fc-0242ac120002",
            name: "Cash excluded from admin fee",
            order: 6,
            fieldType: FeeFieldType.YesNo,
            subProductData: {
              idpsValue: "true",
              pensionValue: "true",
            },
          },
          {
            id: "03bb90f2-a14b-11ed-a8fc-0242ac120002",
            name: "Tiered admin fee type",
            order: 7,
            fieldType: FeeFieldType.OpenText,
            subProductData: {
              idpsValue:
                "Tiered (Different % admin fee for each tier increment)",
              pensionValue:
                "Tiered (Different % admin fee for each tier increment)",
            },
          },
          {
            id: "7e1f7476-a14b-11ed-a8fc-0242ac120002",
            name: "Tier 1 upper limit",
            order: 8,
            fieldType: FeeFieldType.Currency,
            subProductData: {
              idpsValue: "1000000",
              pensionValue: "1000000",
            },
          },
          {
            id: "9e7c7840-a14b-11ed-a8fc-0242ac120002",
            name: "Tier 1 fee (%)",
            order: 9,
            fieldType: FeeFieldType.Percentage,
            subProductData: {
              idpsValue: "0.15",
              pensionValue: "0.15",
            },
          },
        ],
      },
      {
        id: "a9d09f66-a144-11ed-a8fc-0242ac120002",
        name: "Other account fees",
        order: 3,
        features: [
          {
            id: "cb654e04-a14b-11ed-a8fc-0242ac120002",
            name: "Cash account fee",
            order: 1,
            fieldType: FeeFieldType.Numeric,
            subProductData: {
              idpsValue: "80.00",
              pensionValue: "80.00",
            },
          },
          {
            id: "e489d47c-a14b-11ed-a8fc-0242ac120002",
            name: "Interest rate earned on cash",
            order: 2,
            fieldType: FeeFieldType.Numeric,
            subProductData: {
              idpsValue: "0",
              pensionValue: "0",
            },
          },
        ],
      },
    ],
    otherFeeSections: [
      {
        id: "1a30997c-a1e6-11ed-a8fc-0242ac120002",
        name: "Fee grouping",
        order: 1,
        features: [
          {
            id: "159cf11c-a1e6-11ed-a8fc-0242ac120002",
            name: "Fee grouping",
            order: 1,
            subProductData: {
              otherValue: "true",
            },
            fieldType: FeeFieldType.YesNo,
          },
          {
            id: "5ba91f50-a1e6-11ed-a8fc-0242ac120002",
            name: "Fee grouping across IDPS and Super",
            order: 2,
            subProductData: {
              otherValue: "true",
            },
            fieldType: FeeFieldType.YesNo,
          },
          {
            id: "682711d8-a1e8-11ed-a8fe-0242ac120002",
            name: "Fee aggregation methodology: Pro rata based on balance of each account versus fees of each account",
            order: 3,
            subProductData: {
              otherValue: "CanTho",
            },
            fieldType: FeeFieldType.Dropdown,
            lovData: [
              { id: "CanTho", name: "Can Tho" },
              { id: "BacLieu", name: "Bac Lieu" },
              { id: "CaMau", name: "Ca Mau" },
            ],
          },
          {
            id: "682711d8-a1e6-11ed-a8fc-0242ac120002",
            name: "Max number of entities per group",
            order: 4,
            subProductData: {
              otherValue: "4",
            },
            fieldType: FeeFieldType.Numeric,
          },
        ],
      },
    ],
  },
  {
    id: "f95cf586-a110-11ed-a8fc-0242ac120002",
    productId: "746c9d7a-ccb1-44eb-993a-c732543959c8",
    name: "Core",
    status: "Active",
    order: 2,
  },
  {
    id: "f98cf586-a110-11ed-a8fc-0242ac120002",
    productId: "746c9d7a-ccb1-44eb-993a-c732543959c8",
    name: "Full",
    status: "Archived",
    order: 3,
  },
];

export const mockNewFeesAndRates: SubProductDTO[] = [
  {
    id: "3527ec54-a5ff-11ed-afa1-0242ac120002",
    productId: "746c9d7a-ccb1-44eb-993a-c732543959c8",
    name: "New Product",
    status: "Active",
    order: 1,
    standardFeeSections: [
      {
        id: "3be5e56e-a5ff-11ed-afa1-0242ac120002",
        name: "PDS features",
        order: 1,
        features: [
          {
            id: "3ff744b8-a5ff-11ed-afa1-0242ac120002",
            name: "Full name",
            order: 1,
            fieldType: FeeFieldType.OpenText,
            subProductData: {
              idpsValue: "",
              pensionValue: "",
            },
          },
          {
            id: "45710c94-a5ff-11ed-afa1-0242ac120002",
            name: "PDS date",
            order: 2,
            fieldType: FeeFieldType.OpenText,
            subProductData: {
              idpsValue: "",
              pensionValue: "",
            },
          },
        ],
      },
      {
        id: "4dd5181c-a5ff-11ed-afa1-0242ac120002",
        name: "Admin fees",
        order: 2,
        features: [
          {
            id: "5243ea0e-a5ff-11ed-afa1-0242ac120002",
            name: "Minimum tiered admin fee ($)",
            order: 1,
            fieldType: FeeFieldType.Currency,
          },
          {
            id: "58266ec4-a5ff-11ed-afa1-0242ac120002",
            name: "Maximum tiered admin fee ($)",
            order: 2,
            fieldType: FeeFieldType.Currency,
          },
          {
            id: "5d90060e-a5ff-11ed-afa1-0242ac120002",
            name: "Minimum admin fee ($)",
            order: 3,
            fieldType: FeeFieldType.Currency,
          },
          {
            id: "63e5861e-a5ff-11ed-afa1-0242ac120002",
            name: "Maximum admin fee ($)",
            order: 4,
            fieldType: FeeFieldType.Currency,
          },
          {
            id: "68e469e6-a5ff-11ed-afa1-0242ac120002",
            name: "Fixed admin fee",
            order: 5,
            fieldType: FeeFieldType.Currency,
          },
          {
            id: "6f57c2be-a5ff-11ed-afa1-0242ac120002",
            name: "Cash excluded from admin fee",
            order: 6,
            fieldType: FeeFieldType.YesNo,
          },
          {
            id: "93f7d410-a5ff-11ed-afa1-0242ac120002",
            name: "Tiered admin fee type",
            order: 7,
            fieldType: FeeFieldType.OpenText,
          },
          {
            id: "9b0099cc-a5ff-11ed-afa1-0242ac120002",
            name: "Tier 1 upper limit",
            order: 8,
            fieldType: FeeFieldType.Currency,
          },
          {
            id: "9f6edbd6-a5ff-11ed-afa1-0242ac120002",
            name: "Tier 1 fee (%)",
            order: 9,
            fieldType: FeeFieldType.Percentage,
          },
        ],
      },
      {
        id: "9f6edbd6-a5ff-11ed-afa1-0242ac120002",
        name: "Other account fees",
        order: 3,
        features: [
          {
            id: "ad60f88c-a5ff-11ed-afa1-0242ac120002",
            name: "Cash account fee",
            order: 1,
            fieldType: FeeFieldType.Numeric,
          },
          {
            id: "b12e163e-a5ff-11ed-afa1-0242ac120002",
            name: "Interest rate earned on cash",
            order: 2,
            fieldType: FeeFieldType.Numeric,
          },
        ],
      },
    ],
    otherFeeSections: [
      {
        id: "b65ce676-a5ff-11ed-afa1-0242ac120002",
        name: "Fee grouping",
        order: 1,
        features: [
          {
            id: "ba34113e-a5ff-11ed-afa1-0242ac120002",
            name: "Fee grouping",
            order: 1,
            fieldType: FeeFieldType.YesNo,
          },
          {
            id: "be3d6046-a5ff-11ed-afa1-0242ac120002",
            name: "Fee grouping across IDPS and Super",
            order: 2,
            fieldType: FeeFieldType.YesNo,
          },
          {
            id: "c441bed8-a5ff-11ed-afa1-0242ac120002",
            name: "Fee aggregation methodology: Pro rata based on balance of each account versus fees of each account",
            order: 3,
            fieldType: FeeFieldType.Dropdown,
            lovData: [
              { id: "CanTho", name: "Can Tho" },
              { id: "BacLieu", name: "Bac Lieu" },
              { id: "CaMau", name: "Ca Mau" },
            ],
          },
          {
            id: "c8113a5c-a5ff-11ed-afa1-0242ac120002",
            name: "Max number of entities per group",
            order: 4,
            fieldType: FeeFieldType.Numeric,
          },
        ],
      },
    ],
  },
];
