import { UserType } from "@models/auth";
import { RegionDTO } from "@models/public/region";
import { UserMetaData } from "@models/users/entities/user";
import { UserStatus } from "@models/users/enums/status";

export class SupplierUserDTO {
  auth0Id: string = "";
  firstName: string = "";
  lastName: string = "";
  name: string = "";
  email: string = "";
  jobTitle?: string | null = null;
  phoneNumber?: string | null = null;
  avatarUrl: string = "";
  avatarS3Key: string = "";
  status: UserStatus | null = null;
  isPublic?: boolean = false;
  publicPhoneNumber?: string | null = null;
  preferredContactHours?: string | null = null;
  publicEmail?: string | null = null;
  linkedInProfileUrl?: string | null = null;
  supplierId?: string | null = null;
  supplierName?: string | null = null;
  supplierLicenseId?: string | null;
  userType: UserType = UserType.SuperAdmin;
  welcomeLink?: string | null;
  regions: string[] = [];
  additionalPermissions: string[] = [];
  preferences: string[] = [];
  associatedProducts: ProductDTO[] = [];
  userMetadata: UserMetaData = new UserMetaData();
}

export class CreateSupplierUserDTO {
  supplierId: string = "";
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  jobTitle?: string | null = null;
  phoneNumber?: string | null = null;
  avatarUrl?: string | null = null;
  avatarS3Key?: string | null = null;
  userType: UserType = UserType.SuperAdmin;
  isPublic?: boolean | null = null;
  publicPhoneNumber?: string | null = null;
  preferredContactHours?: string | null = null;
  publicEmail?: string | null = null;
  linkedInProfileUrl?: string | null = null;
  regions?: string[] | null = null;
  associatedProducts?: ProductDTO[] | null = null;
  additionalPermissions?: string[] | null = null;
  preferences?: string[] | null = null;
}

export interface UpdateSupplierUserDTO {
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  jobTitle?: string | null;
  avatarUrl?: string | null;
  avatarS3Key?: string | null;
  isPublic?: boolean | null;
  publicPhoneNumber?: string | null;
  preferredContactHours?: string | null;
  publicEmail?: string | null;
  linkedInProfileUrl?: string | null;
  regions?: string[] | null;
  userType: UserType;
  associatedProducts?: ProductDTO[] | null;
  additionalPermissions?: string[] | null;
  preferences?: string[] | null;
}

export class SupplierUserSelfUpdateDTO {
  userId: string = "";
  firstName: string = "";
  lastName: string = "";
  phoneNumber?: string | null = null;
  jobTitle?: string | null = null;
  publicPhoneNumber?: string | null = null;
  preferredContactHours?: string | null = null;
  publicEmail?: string | null = null;
  linkedInProfileUrl?: string | null = null;
  regions?: string[] | null = null;
}

export interface SupplierUserLov {
  regions: RegionDTO[];
  preferences: PreferenceDTO[];
  products: ProductDTO[];
  additionalPermissions: AdditionalPermissionDTO[];
}

export interface ProductDTO {
  id: string;
  name: string;
  type: string;
}

export interface PreferenceDTO {
  id: string;
  name: string;
  description: string;
  order?: number;
}
export interface AdditionalPermissionDTO {
  id: string;
  name: string;
  description: string;
  order?: number;
}

export interface LicenseeDiscountDTO {
  id: string;
  value: string;
  priceType: string;
}
