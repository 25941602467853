import { CloseButton } from "@components/buttons/close";
import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHHtmlBlock,
  SHPopper,
  SHStack,
  SHTableRow,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { CheckSVG, CrossSVG, TooltipAllowSVG } from "@components/svgs";
import { FieldTypeId } from "@models/configuration";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import {
  ReviewDataDTO,
  ReviewFeatureDTO,
  ReviewSelectedProductDTO,
} from "@models/reviews/entities/steps/feature";
import { Link, useTheme } from "@mui/material";
import { renderBooleanIcon } from "@pages/platform-analysis/_id/steps/business-metric/analysis/components/feature-row";
import {
  FeatureReviewTableCell,
  FeatureReviewTableCellProps,
} from "@pages/reviews/_id/steps/feature/analysis/components/table-cell";
import { generateReviewColorCell } from "@pages/reviews/_id/steps/feature/analysis/util";
import { hexToRGBA } from "@utils";
import { isNil } from "lodash";
import { useEffect, useRef, useState } from "react";

interface FeatureReviewFeatureTableRowProps {
  featureData: ReviewFeatureDTO;
  selectedProducts?: ReviewSelectedProductDTO[];
}

export const ReviewBusinessFeatureTableRow = ({
  featureData,
  selectedProducts,
  ...others
}: FeatureReviewFeatureTableRowProps &
  FeatureReviewTableCellProps &
  SHTableRowProps) => {
  const { palette } = useTheme();
  const labelRef = useRef<HTMLLabelElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [currLongTextValue, setCurrLongTextValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLLabelElement | null>(null);

  const handleOnShowMore = (event: any, value?: string) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
    setCurrLongTextValue(value ?? "");
  };

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        labelRef.current &&
        !labelRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, labelRef, open]);

  const renderPlatformValue = (reviewData: ReviewDataDTO) => {
    if (reviewData?.value === AnalysisDataValue.MissingData)
      return (
        <SHTypography
          variant="body3"
          zIndex="1"
          colorVariant="third"
          textAlign="center"
        >
          No data
        </SHTypography>
      );
    if (reviewData?.value === AnalysisDataValue.True) return <CheckSVG />;
    if (reviewData?.value === AnalysisDataValue.False) return <CrossSVG />;

    if (reviewData?.fieldTypeId === FieldTypeId.TextShort)
      return (
        <SHStack gap={"5px"} alignItems={"center"} direction="row">
          <SHTypography
            variant="body3"
            zIndex="1"
            colorVariant="third"
            textAlign="left"
          >
            {reviewData?.value}
          </SHTypography>
          <SHBox>
            {!isNil(reviewData?.isPositiveScore) &&
              renderBooleanIcon(reviewData?.isPositiveScore)}
          </SHBox>
        </SHStack>
      );

    if (reviewData?.fieldTypeId === FieldTypeId.Number)
      return (
        <>
          <SHTypography
            variant="body3"
            zIndex="1"
            colorVariant="third"
            textAlign="justify"
          >
            {reviewData?.value}
          </SHTypography>
          {!isNil(reviewData?.isPositiveScore) &&
            renderBooleanIcon(reviewData?.isPositiveScore)}
        </>
      );
    if (reviewData?.fieldTypeId === FieldTypeId.TextLong)
      return (
        <SHStack
          gap={"5px"}
          width={"118px"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Link
            ref={labelRef}
            variant="body1"
            component="label"
            underline="none"
            sx={{
              paddingLeft: "5px",
              fontWeight: 600,
              cursor: "pointer",
              color: palette.text.secondary,
              lineHeight: "120%",
            }}
            onClick={(event: any) => handleOnShowMore(event, reviewData?.value)}
          >
            Click to view
          </Link>
          {!isNil(reviewData?.isPositiveScore) &&
            renderBooleanIcon(reviewData?.isPositiveScore)}
          <SHPopper
            anchorEl={anchorEl}
            open={open}
            placement="top-start"
            popperOptions={{
              modifiers: [{ name: "flip", enabled: false }],
            }}
            sx={{
              zIndex: 1100,
            }}
          >
            <SHStack
              ref={wrapperRef}
              sx={{
                p: "30px 15px 10px 10px",
                maxWidth: "577px",
                maxHeight: "478px",
                bgcolor: hexToRGBA(palette.common.white, 0.85),
                border: "1px solid #E3E3E3",
                backdropFilter: "blur(2px)",
                borderRadius: "2px",
                position: "relative",
              }}
            >
              <SHHtmlBlock
                variant="body2"
                content={currLongTextValue}
                color={palette.text.third}
                sx={{
                  pr: 1,
                  zIndex: 1,
                  overflow: "auto",
                  "::-webkit-scrollbar-thumb": {
                    boxShadow: "none",
                  },
                }}
              />
              <SHBox sx={{ position: "absolute", top: "10px", right: "15px" }}>
                <CloseButton onClick={handleOnShowMore} />
              </SHBox>
              <SHBox sx={{ position: "absolute", bottom: "-11.7px", left: 0 }}>
                <TooltipAllowSVG />
              </SHBox>
            </SHStack>
          </SHPopper>
        </SHStack>
      );
  };

  return (
    <SHTableRow
      sx={{
        height: 54,
        background: `${hexToRGBA(palette.secondary[200], 0.1)} !important`,
      }}
      {...others}
    >
      <FeatureReviewTableCell isFirstColumn>
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <SHTypography
            variant="body3"
            colorVariant="third"
            textAlign={"left"}
            lineHeight="120%"
          >
            {featureData?.name}
          </SHTypography>
          <SHStack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={1}
          >
            {featureData?.description && (
              <InformationButton content={featureData.description} />
            )}
          </SHStack>
        </SHStack>
      </FeatureReviewTableCell>
      {featureData?.analysisData?.map((analysisData: ReviewDataDTO) => {
        const platform = selectedProducts?.find(
          (selectedProduct: ReviewSelectedProductDTO) =>
            selectedProduct.id === analysisData.productId,
        );
        if (platform)
          return (
            <FeatureReviewTableCell
              key={analysisData?.productId}
              colorCell={generateReviewColorCell(
                platform.decisionStatus,
                platform.isMissingEssential,
              )}
            >
              <SHStack
                direction={"row"}
                alignItems={"center"}
                justifyContent="center"
              >
                {renderPlatformValue(analysisData)}
              </SHStack>
            </FeatureReviewTableCell>
          );
        return <></>;
      })}
    </SHTableRow>
  );
};
