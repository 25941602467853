import { useConfiguration } from "@hooks/useConfiguration";
import { HistoryList } from "@layouts/dnd/configuration/history-list";
import { RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";

export const ConfigurationHistoryReleaseTab = () => {
  const { updateHistoryReleases } = useConfiguration();
  const { configurationHistoryReleases } = useSelector(
    (state: RootState) => state.configuration,
  );

  useEffectOnce(() => {
    updateHistoryReleases();
  });

  return <HistoryList releases={configurationHistoryReleases} />;
};
