import { SocketClientEvent, SocketServerEvent } from "@constants/socket";
import { HubConnectionState } from "@microsoft/signalr";
import {
  ConfigurationEditingProcessActions,
  ConfigurationEditingProcessResult,
  SignalRResponse,
} from "@models/core";
import {
  updateBeingKickedBy,
  updateCurrentEditor,
  updateEditMode,
  updateKickOut,
  updateLoadingEditMode,
  updateShowDialog,
} from "@redux/slices/configuration";
import { RootState, useAppDispatch } from "@redux/store";
import { SignalRService } from "@services/signalr/signalRService";
import { useSelector } from "react-redux";
export const useEditConfiguration = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const checkEdit = async () => {
    dispatch(updateLoadingEditMode(true));
    const connection = SignalRService.getHubConnection();
    if (connection && connection.state === HubConnectionState.Connected) {
      connection.invoke(
        SocketServerEvent.onConfigurationEditorProcess,
        ConfigurationEditingProcessActions.ConfigurationEditorChecking,
        null,
      );
      connection.on(
        SocketClientEvent.configurationEditingProcess,
        (result: SignalRResponse<ConfigurationEditingProcessResult>) => {
          switch (result.resultType) {
            case ConfigurationEditingProcessResult.EditingPermissionClaimConfirm:
              // show dialog confirm to kick
              dispatch(updateShowDialog(true));
              dispatch(updateCurrentEditor(result.content));
              break;
            case ConfigurationEditingProcessResult.EditingPermissionAvailable:
              // no one editing, join as an editor
              connection
                .invoke(
                  SocketServerEvent.onConfigurationEditorProcess,
                  ConfigurationEditingProcessActions.ConfigurationEditorClaiming,
                  user?.name,
                )
                .then(
                  (
                    result: SignalRResponse<ConfigurationEditingProcessResult>,
                  ) => {
                    if (
                      result.resultType ===
                      ConfigurationEditingProcessResult.EditingPermissionClaimSuccessful
                    ) {
                      console.log("Product lock editing claim successfully");
                      //success -> can edit now
                      dispatch(updateEditMode(true));
                      console.log(
                        "EditingPermissionClaimSuccessful, listen on management: ",
                        connection.state,
                      );

                      // also, listen on management channel
                      connection.on(
                        SocketServerEvent.onConfigurationEditingSectionManagement,
                        (
                          result: SignalRResponse<ConfigurationEditingProcessResult>,
                        ) => {
                          if (
                            result.resultType ===
                            ConfigurationEditingProcessResult.EditingPermissionDispose
                          ) {
                            console.log(
                              "Received disposed, send ack back to server...",
                            );
                            connection.invoke(
                              SocketServerEvent.onConfigurationEditorProcess,
                              ConfigurationEditingProcessActions.ConfigurationBeingDisposed,
                              null,
                            );

                            dispatch(updateEditMode(false));
                            dispatch(updateKickOut(true));
                            dispatch(updateBeingKickedBy(result.content));

                            // off channel
                            connection.off(
                              SocketServerEvent.onConfigurationEditingSectionManagement,
                            );
                          }
                        },
                      );
                    }
                    dispatch(updateLoadingEditMode(false));
                  },
                );
              break;
          }
          connection.off(SocketClientEvent.configurationEditingProcess);
        },
      );
    } else {
      dispatch(updateLoadingEditMode(false));
    }
    ///else show error here
  };
  return { checkEdit };
};
