import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { SHDividerSkeleton } from "@components/skeletons/design-systems";
import {
  SHFieldGroupSkeleton,
  SHFiledGroupTitleSkeleton,
} from "@components/skeletons/layouts";

export const PracticeSubscriptionSkeleton = () => {
  return (
    <SHStack spacing={3}>
      <SHFieldGroupSkeleton leftSide={<SHFiledGroupTitleSkeleton />} />
      <SHDividerSkeleton />
      <SHStack direction={"row"} spacing={"52px"}>
        <SHSkeleton width={85} height={22} />
        <SHStack spacing={"15px"} alignItems={"flex-end"}>
          <SHSkeleton width={600} height={57} />
          <SHStack direction={"row"} spacing={"15px"}>
            <SHSkeleton width={200} height={114} />
            <SHSkeleton width={600} height={114} />
          </SHStack>
        </SHStack>
      </SHStack>
    </SHStack>
  );
};
