import {
  SHLabel,
  SHLabelProps,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import { theme } from "@themes";
import { isEmpty } from "lodash";
import React, { useRef } from "react";

export type SHStandardTextFieldProps = StandardTextFieldProps;
export type SHFilledTextFieldProps = FilledTextFieldProps;
export type SHOutlinedTextFieldProps = OutlinedTextFieldProps;
export type SHTextFieldProps = (
  | SHStandardTextFieldProps
  | SHFilledTextFieldProps
  | SHOutlinedTextFieldProps
) & {
  hasCounter?: boolean;
  maxLength?: number;
  customCounter?: React.ReactNode;
} & SHLabelProps;

export const SHTextField = React.forwardRef<HTMLDivElement, SHTextFieldProps>(
  (props, ref) => {
    const {
      required,
      postfixLabel,
      label,
      sx,
      disabled,
      helperText,
      hasCounter,
      maxLength,
      dotGroupProps,
      inputProps,
      value,
      customCounter,
      actionButton,
      ...others
    } = props;
    const inputRef: React.Ref<any> = useRef();
    return (
      <TextField
        sx={{
          "&>.MuiInput-root": {
            marginTop: label ? "5px" : 0,
          },
          ...sx,
        }}
        disabled={disabled}
        helperText={
          !isEmpty(helperText) || hasCounter ? (
            <SHStack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              {!isEmpty(helperText) && (
                <SHTypography
                  variant="body2"
                  sx={{
                    padding: 0,
                    fontSize: "0.75rem",
                    color: `${theme.palette.error.light} !important`,
                  }}
                >
                  {helperText}
                </SHTypography>
              )}
              {hasCounter && (
                <SHTypography
                  variant="body4"
                  colorVariant="third"
                  sx={{
                    paddingRight: 1,
                    whiteSpace: "nowrap"
                  }}
                >
                  {`${
                    inputRef.current?.value?.length ??
                    (value as any)?.length ??
                    0
                  } / ${maxLength}`}
                </SHTypography>
              )}
              {customCounter}
            </SHStack>
          ) : undefined
        }
        ref={ref}
        label={
          label ? (
            <SHLabel
              label={label}
              disabled={disabled}
              postfixLabel={postfixLabel}
              required={required}
              dotGroupProps={dotGroupProps}
              actionButton={actionButton}
            />
          ) : undefined
        }
        inputRef={inputRef}
        inputProps={{
          ...inputProps,
          maxLength: maxLength,
        }}
        value={value ?? ""}
        {...others}
      />
    );
  },
);
