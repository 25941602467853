import { useSelector } from "react-redux";
import { ViewFeature } from "../components/view-feature";
import { RootState } from "@redux/store";
import { useEffect } from "react";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { useParams } from "react-router";

export const ViewSeriesTab = () => {
  const {
    investmentProductSeriesData,
    investmentProductUI: { isSeriesDataLoading },
  } = useSelector((state: RootState) => state.investmentProduct);
  const { investmentProductId } = useParams();
  const { loadInvestmentProductSeries } = useInvestmentProduct();

  useEffect(() => {
    loadInvestmentProductSeries(investmentProductId ?? "new", "view");

    // eslint-disable-next-line
  }, [investmentProductId]);

  return (
    <ViewFeature
      investmentProductSeriesData={investmentProductSeriesData}
      isSeriesDataLoading={investmentProductSeriesData !== undefined ? isSeriesDataLoading : true}
    />
  );
};
