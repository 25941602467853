import { SHButton, SHButtonProps, SHStack } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { SVGProps, useMemo } from "react";

export interface EssentialButtonProps extends SHButtonProps {
  isEssential?: boolean;
}

export const EssentialButton = ({isEssential, ...others}: EssentialButtonProps) => {
  const { palette } = useTheme();
  const { children } = others;

  const colorsButton = useMemo(() => {
    //TODO: add new color into global color
    if (isEssential) {
      return {
        backgroundNormal: "#FFEBE2",
        borderNormal: "#FFD3C1",
        star: "#FFF500",
        borderHover: "#FF8D5D",
        backgroundHover: "#FFEBE2",
        text: "#F3612D",
      };
    }
    return {
      backgroundNormal: "#FFFEFD",
      borderNormal: "#FFAC8A",
      star: "",
      borderHover: "#FF753C",
      backgroundHover: "#FFFBF9",
      text: "#FF753C",
    };
  }, [isEssential]);

  return (
    <SHButton
      variant="outlined"
      size="small"
      endIcon={
        <EssentialStarSVG
          fill={colorsButton.star}
          stroke={colorsButton.text}
          width={20}
          height={20}
        />
      }
      {...others}
      sx={{
        minWidth: "88px",
        background: colorsButton.backgroundNormal,
        color: colorsButton.text,
        border: `1px solid ${colorsButton.borderNormal}`,
        "&:hover": {
          background: colorsButton.backgroundHover,
          border: `1px solid ${colorsButton.borderHover}`,
          boxShadow: "none",
        },
        "&:active": {
          color: colorsButton.text,
          background: colorsButton.backgroundHover,
          boxShadow: `inset 3px 3px 4px ${hexToRGBA(
            palette.common.black,
            0.25,
          )}`,
        },
      }}
    >
      {children}
    </SHButton>
  );
};

//TODO: Move to svgs folder
export const EssentialStarSVG = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <SHStack marginLeft={"-5px"}>
    <svg
      width="12"
      height="12"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.45106 1.30902C8.1517 0.387705 6.84829 0.387708 6.54894 1.30902L5.45287 4.68237H1.90592C0.937193 4.68237 0.53442 5.92199 1.31813 6.49139L4.18768 8.57624L3.09161 11.9496C2.79226 12.8709 3.84674 13.637 4.63045 13.0676L7.5 10.9828L10.3695 13.0676C11.1533 13.637 12.2077 12.8709 11.9084 11.9496L10.8123 8.57624L13.6819 6.49139C14.4656 5.92199 14.0628 4.68237 13.0941 4.68237L9.54713 4.68237L8.45106 1.30902Z"
        fill={props.fill}
        stroke={props.stroke}
      />
    </svg>
  </SHStack>
);
