import { AnalysisCommentDTO } from "@models/platform-analysis/entities/comment";
import { FeePortfolioDetailDTO } from "@models/platform-analysis/entities/portfolioDetails";
import { AnalysisFeatureIncludeDTO } from "@models/platform-analysis/entities/steps/feature";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import { ReviewStepBusinessMetricDTO } from "./businessMetric";
import { ReviewStepFeatureDTO } from "./feature";
import { ReviewStepFeeDTO } from "./fee";
import { TableViewMode } from "@models/platform-analysis/enums/tableViewMode";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";

export class ReviewStepSummaryDTO {
  name: string = "";
  ownerName: string = "";
  ownerType: string = "";
  ownerARN: string | null = null;
  familyGroupName: string = "";
  familyMembersNames: string[] = [];
  adviserFirmName: string = "";
  adviserFirmLogo: string | null = null;
  adviserFirmAFSL: string | null = null;
  objectives: string | null = null;
  serial: string | null = null;
  concludingRemarks: string | null = null;
  showSubSection: boolean = true;
  showComments: boolean = true;
  showSelectedFeatures: boolean = true;
  showSelectedBusinessMetrics: boolean = true;
  showAppendixFeatureAnalysis: boolean = true;
  showAppendixBusinessMetricAnalysis: boolean = true;
  showAppendixFeeAnalysis: boolean = true;
  feesDisplayStyle: FeesDisplayStyle = FeesDisplayStyle.Dollar;
  feeMissingInvestmentsStyle: FeeMissingInvestmentsOption =
    FeeMissingInvestmentsOption.AllPlatform;
  investmentMenu: FeeInvestmentMenuOption =
    FeeInvestmentMenuOption.AllPlatform;
  appendixFeeMissingInvestmentsStyle: FeeMissingInvestmentsOption =
    FeeMissingInvestmentsOption.AllPlatform;
  appendixInvestmentMenu: FeeInvestmentMenuOption =
    FeeInvestmentMenuOption.AllPlatform;
  feeTableViewMode: FeeHeatmapMode = FeeHeatmapMode.Detail;
  featureAnalysisTableViewMode: TableViewMode = TableViewMode.Default;
  lastModifiedDate: Date = new Date();
  featureAnalysis: ReviewStepFeatureDTO | null = null;
  businessMetricAnalysis: ReviewStepBusinessMetricDTO | null = null;
  feeAnalysis: ReviewStepFeeDTO | null = null;
  feePortfolioDetails: FeePortfolioDetailDTO | null = null;
  hiddenSubProducts: HiddenSubProductDTO[] | null = null;
  analysisComments: AnalysisCommentDTO[] | null = null;
  selectedFeatures: AnalysisFeatureIncludeDTO[] | null = null;
  selectedBusinessMetrics: AnalysisFeatureIncludeDTO[] | null = null;
  businessMetricSelectedFeatures: AnalysisFeatureIncludeDTO[] | null = null;
  hideFeeAnalysisGraph: boolean = false;
}

export class HiddenSubProductDTO {
  id: string = "";
  isRateCard: boolean = false;
  rateCardVersion: number = 0;
}
