import { APIExtRoutes } from "@constants/routes";
import { APIResponse } from "@models/core";
import {
  CreateUserDTO,
  CreateUserResponseDTO,
  GetUserInfoDTO,
  UpdateUserDTO,
} from "@models/users/entities/user";
import { UserStatus } from "@models/users/enums/status";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getUser(
  userId?: string,
): Promise<APIResponse<GetUserInfoDTO>> {
  const response = await APIGatewayService.get<GetUserInfoDTO>(
    `${APIExtRoutes.user}/${userId}`,
  );

  return handleErrorProxy(response);
}

export async function createUser(
  user?: CreateUserDTO,
): Promise<APIResponse<CreateUserResponseDTO>> {
  const response = await APIGatewayService.post<CreateUserResponseDTO>(
    APIExtRoutes.user,
    user,
  );

  return handleErrorProxy(
    response,
    "New user created! A welcome email has been sent to them.",
  );
}

export async function updateUser(
  userId: string,
  user?: UpdateUserDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.patch<string>(
    `${APIExtRoutes.user}/${userId}`,
    user,
  );

  return handleErrorProxy(response, "Changes have been saved.");
}

export async function updateUserStatus(
  userId?: string,
  status?: UserStatus,
): Promise<APIResponse<{ status: UserStatus }>> {
  const response = await APIGatewayService.patch<{ status: UserStatus }>(
    generatePath(APIExtRoutes.updateUserStatus, {
      userId,
      status,
    }),
  );

  return handleErrorProxy(response, "Changes have been saved.");
}

export async function resendInvite(
  userId?: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.post<string>(
    `${APIExtRoutes.user}/${userId}/welcome-email`,
  );

  return handleErrorProxy(response, "Invite email sent.");
}

export async function resetPassword(
  email?: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.post<string>(
    APIExtRoutes.resetPassword,
    email,
  );

  return handleErrorProxy(response, "Password reset email sent.");
}

export async function getUserInfo(): Promise<APIResponse<GetUserInfoDTO>> {
  const response = await APIGatewayService.get<GetUserInfoDTO>(
    APIExtRoutes.userInfo,
  );

  //@TODO: remove after done
  const devKey = localStorage.getItem("devKey");
  if (devKey && devKey === "xfjHRgsNlC" && response.data) {
    const jsonSrt = localStorage.getItem("userData");
    const overwrite: GetUserInfoDTO = jsonSrt ? JSON.parse(jsonSrt) : {};
    response.data = { ...response.data, ...overwrite };
  }
  return handleErrorProxy(response);
}

export async function activateUser(
  userId: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.patch<string>(
    generatePath(APIExtRoutes.activateUser, {
      userId: userId,
    }),
  );
  return handleErrorProxy(response, "Changes have been saved.");
}
