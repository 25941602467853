//TODO: Separate the route section: coreRoutes, userRouters,...
export const APIExtRoutes = {
  // Core
  notification: "/notification",
  //public
  regions: "api/regions",
  // Users
  user: "/api/users",
  updateUserStatus: "/api/users/:userId/:status",
  userInfo: "/api/users/user-info",
  resetPassword: "/api/users/reset-password",
  getAdminUsers: "/api/users/GetAdminUsers()",
  activateUser: "/api/users/:userId/first-login",
  // File Storage
  uploadPublic: "/api/filestorage/upload-public",
  deletePublic: "/api/filestorage/delete-public",
  // Suppliers
  suppliers: "/api/suppliers",
  supplierUser: "api/aggregate/suppliers/:supplierId/users/:userId",
  createSupplierUser: "/api/supplier-users",
  updateSupplierUser: "/api/supplier-users/:userId",
  updateStatusSupplierUser: "/api/supplier-users/:userId/:status",
  completeProfileSupplierUser: "/api/supplier-users/:userId/complete-profile",
  supplierLicenses: "/api/suppliers/:supplierId/licenses",
  supplier: "/api/suppliers/:supplierId",
  supplierUserLov:
    "/api/aggregate/supplier-users/:supplierId/supplier-user-lists-of-values",
  odataSuppliers: "/api/suppliers/getsuppliersaggregated()",
  odataSupplierUsers:
    "/api/supplier-users/getsupplierteam(supplierId=':supplierId')",
  odataSupplierProducts:
    "/api/aggregate/products/GetProductsBySupplierIdWithStatus(supplierId=':supplierId')",
  transferSupplierUser:
    "/api/supplier-users/:oldOwnerId/transfer-ownership/:newOwnerId",
  supplierUniqueAFSL: "/api/suppliers/check-unique-afsl/:afsl",
  getLicenseeByAFSL: "/api/suppliers/afsl/:afsl",

  // Product
  odataProducts: "/api/aggregate/products/GetProductsList()",
  products: "/api/products",
  productGroups:
    "api/products/:productId/groups?version=:version&configurationVersion=:configurationVersion",
  productGroup: "api/products/:productId/groups/:groupId?mode=:mode",
  productBannerInfo: "/api/products/:productId/banner-info",
  productSubmitForReview: "/api/products/:productId/draft-submit",
  productRevertToDraft: "/api/products/:productId/draft-revert",
  productPublishDraft: "/api/products/:productId/draft-publish",
  productRecallSubmission: "/api/products/:productId/draft-recall",
  productDiscardDraft: "/api/products/:productId/draft-discard",
  productChangeHistory: "/api/products/:productId/change-history",
  productBDMUsers: "/api/products/:productId/bdm-users",
  productReleaseLog:
    "/api/products/:productId/versions/:version/configuration-versions/:configurationVersion/groups/:groupId",
  existingFeeSchedules: "/api/products/:productId/existing-fee-schedules",
  // Product - SubProduct
  subProduct: "/api/products/:productId/sub-products/:subProductId",
  subProducts: "/api/products/:productId/sub-products",
  productSubProducts: "/api/products/:productId/sub-products?mode=:mode",
  productNewSubProduct: "/api/products/:productId/sub-products/new",
  productSubProductReleaseLog:
    "/api/products/:productId/versions/:version/configuration-versions/:configurationVersion/sub-products",
  productBrandingData: "/api/products/:productId/branding-data",

  // Adviser
  odataAdviserFirm: "/api/odata/adviser-firms",
  adviserFirms: "/api/adviser-firms",
  adviserFirm: "/api/adviser-firms/:practiceId",
  updatePracticeStatus: "/api/practices/:practiceId/:status",
  adviserFirmByAFSL: "/api/adviser-firms/afsl/:afsl",

  //Adviser User
  odataAdviserUsers:
    "/api/odata/adviser-users/getadviserteam(adviserFirmId=:practiceId)",
  adviserUsers: "api/adviser-firms/:adviserFirmId/adviser-users",
  adviserUser: "api/adviser-firms/:adviserFirmId/adviser-users/:adviserUserId",
  adviserUserResetPassword:
    "api/adviser-firms/:adviserFirmId/adviser-users/:adviserUserId/reset-password",
  updateAdviserUserStatus:
    "api/adviser-firms/:adviserFirmId/adviser-users/:adviserUserId/:status",
  completeProfilePracticeUser:
    "/api/adviser-firms/:adviserFirmId/adviser-users/:adviserUserId/complete-profile",
  updateProfileAdviserUser: "/api/adviser-firms/:adviserFirmId/adviser-users",
  resendInviteEmailAdviserUser:
    "/api/adviser-firms/:adviserFirmId/adviser-users/:adviserUserId/welcome-email",
  //Adviser subscription
  adviserFirmSubscription: "/api/adviser-firms/:practiceId/subscriptions",
  //Adviser invoices
  practiceInvoices: "/api/practices/:practiceId/invoices",

  // Adviser User - Billing info
  adviserBillingInfo: "/api/adviser-firms/:adviserFirmId/billing-info",
  adviserClientSecret: "/api/adviser-firms/:adviserFirmId/client-secret",
  adviserPaymentMethod: "/api/adviser-firms/:adviserFirmId/payment-methods",
  adviserPaymentMethodDetail:
    "/api/adviser-firms/:adviserFirmId/payment-methods/:paymentMethodId",
  adviserBillingAddress: "/api/adviser-firms/:adviserFirmId/billing-address",
  adviserBillingDetail: "/api/adviser-firms/:adviserFirmId/billing-details",
  adviserCustomerPortalLink:
    "/api/adviser-firms/:adviserFirmId/customer-portal-link",
  adviserOrangeDotBillingInfo:
    "/api/adviser-firms/:adviserFirmId/orange-dot-billing-info",
  // Adviser User - Subscription
  adviserUserSubscription: "/api/adviser-firms/:practiceId/subscriptions/users",
  adviserUserSubscriptionQuantity:
    "/api/adviser-firms/:practiceId/subscriptions/users/:quantity",
  adviserUserUpdateSubscriptionQuantity:
    "/api/adviser-firms/:practiceId/subscriptions/users/update",
  adviserUserSubscriptionRenewal:
    "/api/adviser-firms/:practiceId/subscriptions/users/renewal",
  adviserUserSubscriptionAdditionalCost:
    "/api/adviser-firms/:practiceId/subscriptions/users/additional-cost/:quantity",
  adviserSubscriptionPricingInfo:
    "/api/adviser-firms/:practiceId/pricing-info?productType=:productType",

  //Platform analysis
  odataPlatformAnalysis: "/api/odata/platform-analysis/GetPlatformAnalyses()",
  platformAnalysisAllSteps: "/api/platform-analysis/:platformAnalysisId/steps",
  platformAnalysisStep:
    "/api/platform-analysis/:platformAnalysisId/steps/:stepId",
  platformAnalysis: "/api/platform-analysis",
  platformAnalysisComment:
    "/api/platform-analysis/:platformAnalysisId/steps/:stepId/products/:productId/comment",
  platformAnalysisProducts: "/api/platform-analysis/products",
  platformAnalysisFeatureTemplates: "/api/platform-analysis/feature-templates",
  platformAnalysisOwners: "/api/platform-analysis-owners",
  platformAnalysisBannerName:
    "/api/platform-analysis/:platformAnalysisId/banner-info",
  platformAnalysisUniqueName: "/api/platform-analysis/check-unique-name",
  platformAnalysisFeatureSelections:
    "/api/platform-analysis/:platformAnalysisId/feature-selection",
  platformAnalysisStatus: "/api/platform-analysis/:platformAnalysisId/:status",
  platformAnalysisBusinessMetricSelections:
    "/api/platform-analysis/:platformAnalysisId/business-metric-selection",
  platformAnalysisFeeCalculator:
    "/api/platform-analysis/:platformAnalysisId/fee-calculator",
  platformAnalysisCurrentStep:
    "/api/platform-analysis/:platformAnalysisId/save-current-step",
  platformAnalysisRateCards: "/api/platform-analysis/:analysisId/rate-cards",
  platformAnalysisDisclaimers: "/api/platform-analysis/:analysisId/disclaimers",
  platformAnalysisDuplication: "/api/platform-analysis/:analysisId/duplicate",
  platformAnalysisPublishedProducts: "api/platform-analysis/published-products",
  platformAnalysisFilter: "/api/platform-analysis/:analysisId/filters",

  // Platform Analysis - Rate Cate
  rateCard: "/api/rate-cards",
  rateCardUniqueName: "/api/rate-cards/check-unique-name",
  odataRateCates: "/api/odata/rate-cards",
  updateRateCardStatus: "/api/rate-cards/:rateCardId/:status",

  // Platform Analysis - Feature Template
  featureTemplate: "/api/feature-templates",
  featureTemplateUniqueName: "/api/feature-templates/check-unique-name",
  odataFeatureTemplate: "/api/odata/feature-templates",
  updateFeatureTemplateStatus:
    "/api/feature-templates/:featureTemplateId/:status",

  // Configuration
  configurationGroups: "/api/configurations/groups",
  configurationGroup: "api/configurations/groups/:groupId",
  configurationDraftStatus: "/api/configurations/draft-status",
  configurationDraftDiscard: "/api/configurations/draft-discard",
  configurationPublishChanges: "/api/configurations/publish",
  configurationSectionLov: "/api/configurations/sections/lists-of-values",
  configurationFeatureLov:
    "/api/configurations/features/lists-of-values/:groupId",
  configurationSection:
    "/api/configurations/groups/:configurationGroupId/sections/:configurationSectionId",
  createConfigurationSection:
    "/api/configurations/groups/:configurationGroupId/sections",
  createConfigurationFeature:
    "/api/configurations/groups/:groupId/sections/:sectionId/features",
  updateConfigurationFeature:
    "/api/configurations/groups/:groupId/sections/:sectionId/features/:featureId",
  configurationHistoryReleases: "/api/configurations/change-history",

  // News feed
  newsfeedPosts: "api/newsfeed/posts",
  archivePosts: "api/newsfeed/posts/archive?id=:postId",
  approvePosts: "/api/newsfeed/posts/approve?id=:postId",
  restorePosts: "/api/newsfeed/posts/restore?id=:postId",
  initializeNotifications: "api/newsfeed/posts/initializeNotifications",
  publishedProducts: "api/newsfeed/products/published",
  associatedProducts: "api/newsfeed/products/associated",

  // Suitability Review
  odataReviews: "/api/odata/reviews/GetSuitabilityReviews()",
  reviews: "/api/reviews",
  reviewStatus: "/api/reviews/:reviewId/:status",
  reviewDecisionMaking: "/api/reviews/decision-makings",
  reviewDisqualify: "/api/reviews/:reviewId/disqualify-all-product/:stepId",
  reviewUniqueName: "/api/reviews/check-unique-name",
  reviewAdviserUsers: "/api/reviews/:reviewId/review-advisers",
  reviewFamilyGroups:
    "/api/reviews/:reviewId/review-advisers/:adviserUserId/family-groups",
  reviewFamilyMembers:
    "/api/reviews/:reviewId/family-groups/:familyGroupId/family-members",
  reviewRateCards: "/api/reviews/:reviewId/rate-cards",
  reviewStep: "/api/reviews/:reviewId/steps/:stepId",
  reviewSteps: "/api/reviews/:reviewId/steps",
  reviewSaveCurrentStep: "/api/reviews/:reviewId/save-current-step",
  reviewBannerInfo: "/api/reviews/:reviewId/banner-info",
  reviewDisclaimers: "/api/reviews/:reviewId/disclaimers/:stepId",
  reviewFeatureSelections: "/api/reviews/:reviewId/feature-selection",
  reviewBusinessMetricSelections:
    "/api/reviews/:reviewId/business-metric-selection",
  reviewFeeCalculator: "/api/reviews/:reviewId/fee-calculator",
  reviewFilters: "/api/reviews/:reviewId/filters",
  reviewFinalJSONs: "/api/reviews/:reviewId/final-jsons",
  reviewDuplicate: "api/reviews/:reviewId/duplicate",

  // Review - Feature Templates
  reviewFeatureTemplates: "/api/reviews/feature-templates",

  // Suitability Reviews - Family Group
  familyGroups: "api/family-groups",
  familyGroupStatus: "/api/family-groups/:familyGroupId/:status",
  odataFamilyGroups: "/api/odata/family-groups",

  // Managed accounts - Analysis
  odataManagedAccount: "api/odata/managed-accounts",
  managedAccountSteps: "api/managed-accounts/:managedAccountId/steps",
  managedAccountStep: "api/managed-accounts/:managedAccountId/steps/:stepId",
  managedAccountsBannerInfo:
    "api/managed-accounts/:managedAccountId/banner-info",

  managedAccounts: "api/managed-accounts",
  managedAccountsFilter: "api/managed-accounts/:managedAccountId/filters",
  managedAccountsDecision: "api/managed-accounts/:managedAccountId/decisions",
  managedAccountSaveCurrentStep:
    "api/managed-accounts/:managedAccountId/save-current-step",
  managedAccountPortfolioTemplates: "api/managed-accounts/portfolio-templates",
  managedAccountPortfolioTemplateById:
    "api/managed-accounts/:managedAccountId/portfolio-templates/:portfolioTemplateId",

  // Managed account - Investment
  investmentsSelections: "api/investments/selections",
  investmentsFavourited: "api/investments/favourited",
  investmentsUserFavourited: "api/investments/users/:userId",
  investmentsFiltersPlatforms: "api/investments/filters/platforms",
  investmentsSmaList: "api/investments/sma-list",

  // Managed account - Portfolio Templates
  odataPortfolioTemplates: "",
  portfolioTemplates: "api/portfolio-templates",

  // Managed account - CustomModel
  odataCustomModel: "",

  //Subscription Product
  subscriptionProducts: "/api/subscription-products?productType=:productType",

  // Product profiles
  // Product profiles - Platforms
  // TODO: Need to refactor

  // Product profiles - Managed accounts (Investment Product)
  investmentProducts: "api/investment-products",
  investmentProductsDetail: "api/investment-products/:investmentProductId",
  investmentProductsManager:
    "api/investment-products/:investmentProductId?mode=:mode",
  investmentProductsSeries:
    "api/investment-products/:investmentProductId/series?mode=:mode",
  investmentProductsModels:
    "api/investment-products/:investmentProductId/models?mode=:mode",
  investmentProductsESG:
    "api/investment-products/:investmentProductId/esg?mode=:mode",
  investmentProductGroups:
    "api/investment-products/:productId/groups?version=:version&configurationVersion=:configurationVersion",
  investmentProductsBannerInfo:
    "api/investment-products/:investmentProductId/banner-info",
  investmentProductsBrandingData:
    "api/investment-products/:investmentProductId/branding-data",
  investmentProductsSubmitForReview:
    "api/investment-products/:investmentProductId/draft-submit",
  investmentProductsRevertToDraft:
    "api/investment-products/:investmentProductId/draft-revert",
  investmentProductsPublishDraft:
    "api/investment-products/:investmentProductId/draft-publish",
  investmentProductsRecallSubmission:
    "api/investment-products/:investmentProductId/draft-recall",
  investmentProductsDiscardDraft:
    "api/investment-products/:investmentProductId/draft-discard",
};

export const PageRoutes = {
  // Core
  callback: "/callback",
  logout: "/logout",
  home: "/",
  platformProfiles: "/platform-profiles",

  // Users
  users: "/users",
  newUser: "/users/new",

  // Suppliers
  suppliers: "/suppliers",
  supplierOverview: "/suppliers/:supplierId",
  supplierTeam: "/suppliers/:supplierId/team",
  supplierUser: "/suppliers/:supplierId/team/:supplierUserId",
  supplierProducts: "/suppliers/:supplierId/products/:productId",
  supplierProductsVersion:
    "/suppliers/:supplierId/products/:productId?version=:version&configurationVersion=:configurationVersion",

  // Practices
  practices: "/practices",
  practiceOverview: "/practices/:practiceId",
  practiceTeam: "/practices/:practiceId/team",
  practiceUsers: "/practices/:practiceId/team/:auth0Id",
  practiceSubscription: "/practices/:practiceId/subscription",

  // Configuration
  configuration: "/configuration",
  configurationSectionStandard:
    "/configuration?group=standard&groupId=:groupId&sectionId=:sectionId",
  configurationFeatureSectionGroup:
    "/configuration?group=feature&groupId=:groupId&sectionType=group&sectionId=:sectionId",
  configurationFeatureSectionSubGroup:
    "/configuration?group=feature&groupId=:groupId&sectionType=subgroup&sectionId=:sectionId&subSectionId=:subSectionId",
  configurationFeatureStandard:
    "/configuration?group=standard&groupId=:groupId&sectionId=:sectionId&featureId=:featureId",
  configurationFeatureFeature:
    "/configuration?group=feature&groupId=:groupId&sectionId=:sectionId&subSectionId=:subSectionId&featureId=:featureId",

  // Platform analysis
  platformAnalysis: "/platform-analysis",
  platformAnalysisDetail: "/platform-analysis/:analysisId",
  platformAnalysisRateCard: "/platform-analysis/rate-card/:rateCardId",
  featureTemplateDetail:
    "/platform-analysis/feature-template/:featureTemplateId",

  // News and Updates
  newsAndUpdates: "/news-and-updates",

  // Upgrade
  upgrade: "/upgrade",

  // Suitability reviews
  reviews: "/reviews",
  reviewsDetail: "/reviews/:reviewId",
  reviewsFamilyGroups: "/reviews/family-groups",
  reviewsFamilyGroupDetail: "/reviews/family-groups/:familyGroupId",
  reviewsRateCardDetail: "/reviews/rate-card/:rateCardId",

  // Managed account
  managedAccounts: "/managed-accounts",
  managedAccountsDetail: "/managed-accounts/:managedAccountId",

  // Product profiles
  // Product profiles - Platforms
  // TODO: Need to refactor

  // Product profiles - Managed accounts (Investment Product)
  investmentProducts: "products/investment-products",
  investmentProductsDetail: "/suppliers/:supplierId/products/investment-products/:investmentProductId",
};
