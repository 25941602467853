import { SxProps, Theme } from "@mui/material";

export const getProductCardSx = (
  index: number,
  sx?: SxProps<Theme>,
): SxProps<Theme> => {
  return {
    borderTopWidth: {
      xs: index > 1 ? "0px" : "1px",
      sm: index > 2 ? "0px" : "1px",
      md: index > 3 ? "0px" : "1px",
      lg: index > 0 ? "0px" : "1px",
    },
    borderLeftWidth: {
      xs: (index + 1) % 2 === 1 ? "1px" : "0px",
      sm: (index + 1) % 3 === 1 ? "1px" : "0px",
      md: (index + 1) % 4 === 1 ? "1px" : "0px",
      lg: "1px",
    },
    ...sx,
  };
};
