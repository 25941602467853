export const renameFile = (originalFile: File) => {
  return new File(
    [originalFile],
    originalFile.name.replace(/[^a-zA-Z0-9.]/g, "_"),
    {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    },
  );
};

export const renameFiles = (originalFiles: File[]) => {
  return originalFiles.map((file) => renameFile(file));
};
