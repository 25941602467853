import { SHButton } from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";

export interface ManagedAccountCompleteButtonProps {
  managedAccountId?: string;
  disabled?: boolean;
  onSubmit?: () => Promise<unknown>;
}

export const ManagedAccountCompleteButton = ({
  managedAccountId,
  disabled,
  onSubmit,
}: ManagedAccountCompleteButtonProps) => {
  const { notify } = useNotification();

  return (
    <>
      <SHButton
        variant="contained"
        size="extraMedium"
        onClick={() =>
          notify("Coming soon.", {
            variant: "error",
            close: true,
          })
        }
      >
        Complete
      </SHButton>
    </>
  );
};
