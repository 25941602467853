import { SHButton } from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { useReview } from "@hooks/useReview";
import { ReviewStatus } from "@models/reviews/enums/status";
import { CompleteDialog } from "@pages/platform-analysis/components/dialogs/complete";
import { putReviewStatus } from "@services/reviews/reviewService";
import { useState } from "react";

export interface ReviewCompleteButtonProps {
  reviewId?: string;
  disabled?: boolean;
  onSubmit?: () => Promise<unknown>;
}

export const ReviewCompleteButton = ({
  reviewId,
  disabled,
  onSubmit,
}: ReviewCompleteButtonProps) => {
  const { notify } = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { setIsCompleted, setIsReadOnly } = useReview();

  const handleOnComplete = async () => {
    if (!reviewId || !onSubmit) return;
    setIsSubmitting(true);
    const isSaved = await onSubmit();
    if (!isSaved) {
      setIsSubmitting(false);
      return;
    }
    const { isSuccess, data, message } = await putReviewStatus(
      reviewId,
      ReviewStatus.Completed,
    );
    setIsSubmitting(false);
    if (isSuccess && data) {
      notify(message, {
        variant: "success",
        close: true,
      });
      setIsReadOnly(true);
      setIsCompleted(true);
      setShowDialog(false);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <>
      <SHButton
        variant="contained"
        size="extraMedium"
        isLoading={isSubmitting}
        disabled={isSubmitting || disabled}
        onClick={() => setShowDialog(true)}
      >
        Complete
      </SHButton>
      {showDialog && (
        <CompleteDialog
          isSubmitting={isSubmitting}
          onSubmit={handleOnComplete}
          onClose={() => setShowDialog(false)}
          isReview
        />
      )}
    </>
  );
};
