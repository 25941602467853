import { SVGProps } from "react";
export const CheckSquareSVG = ({
  color = "#348221",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <rect width="22" height="22" rx="2.75" fill="white" />
    </g>
    <rect width="22" height="22" rx="2.75" fill="#219653" fillOpacity="0.1" />
    <path
      d="M15.0837 6.83377C15.2761 6.64302 15.5359 6.53566 15.8068 6.53492C16.0778 6.53417 16.3381 6.64009 16.5316 6.82977C16.7251 7.01946 16.8361 7.27767 16.8408 7.54859C16.8454 7.8195 16.7432 8.08135 16.5563 8.27752L11.0673 15.1388C10.9729 15.2404 10.8591 15.3219 10.7325 15.3785C10.6059 15.4351 10.4693 15.4656 10.3306 15.4682C10.192 15.4708 10.0543 15.4453 9.9257 15.3935C9.79712 15.3416 9.68032 15.2643 9.58229 15.1663L5.94541 11.528C5.84409 11.4336 5.76283 11.3198 5.70646 11.1933C5.6501 11.0668 5.61979 10.9302 5.61735 10.7917C5.6149 10.6533 5.64038 10.5157 5.69224 10.3873C5.74411 10.2589 5.82131 10.1423 5.91923 10.0443C6.01716 9.94642 6.13381 9.86922 6.26221 9.81735C6.39062 9.76548 6.52816 9.74001 6.66663 9.74246C6.8051 9.7449 6.94165 9.77521 7.06815 9.83157C7.19465 9.88793 7.3085 9.9692 7.40291 10.0705L10.2822 12.9484L15.0575 6.86402C15.0661 6.85337 15.0752 6.84326 15.085 6.83377H15.0837Z"
      fill={color}
    />
    <rect
      x="0.5"
      y="0.5"
      width="21"
      height="21"
      rx="2.25"
      stroke="#219653"
      strokeOpacity="0.13"
    />
    <defs>
      <clipPath id="clip0_7421_32364">
        <rect width="22" height="22" rx="2.75" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
