import { PaymentElement } from "@stripe/react-stripe-js";
import { WrapperStripeElement } from "../../../wrapper-stripe-element";

interface PaymentMethodElementProps {
  isViewMode?: boolean;
}

export const PaymentMethodElement = ({
  isViewMode = false,
}: PaymentMethodElementProps) => {
  return (
    <WrapperStripeElement isViewMode={isViewMode}>
      <PaymentElement
        options={{
          layout: "tabs",
          fields: {
            billingDetails: {
              address: "never",
            },
          },
        }}
      />
    </WrapperStripeElement>
  );
};
