import { SHStack, SHTypography } from "@components/design-systems";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import {
  DefaultMaxLicenceQuantity,
  DefaultMinLicenceQuantity,
} from "@pages/practices/_id/_subscription/practice/config";

interface UpdateQuantityLicenceButtonProps {
  licenceQuantity: number;
  onChange: (count: number) => void;
  isDisabled?: boolean;
  width?: string | number;
  max?: number;
  min?: number;
  customText?: React.ReactNode;
}
export const UpdateQuantityLicenceButton = ({
  licenceQuantity,
  onChange,
  isDisabled = false,
  width = "86px",
  max = DefaultMaxLicenceQuantity,
  min = DefaultMinLicenceQuantity,
  customText,
}: UpdateQuantityLicenceButtonProps) => {
  const { palette } = useTheme();

  return (
    <SHStack
      alignItems={"center"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      sx={{
        width: width,
        height: "30px",
        padding: "5px 3px",
        border: `1px solid ${palette.common.black}`,
        borderRadius: "25px",
        boxShadow: `1px 1px 2px 0px ${hexToRGBA("#414959", 0.4)}`,
        ":read-only": isDisabled ? { opacity: 0.5, pointerEvents: "none" } : {},
        "&:active": {
          border: `1px solid ${palette.primary[800]}`,
        },
        "&:hover": {
          boxShadow: "none",
          border: `1px solid ${palette.primary[800]}`,
          "& .sh-increase-icon,& .sh-decrease-icon": {
            color: palette.primary[800],
          },
        },
        "& .sh-increase-icon,& .sh-decrease-icon": {
          width: 18,
          height: 18,
          cursor: "pointer",
          "& path": {
            fontWeight: 700,
            fontSize: "18px",
          },
          "&:active": {
            color: palette.primary[800],
            backgroundColor: hexToRGBA(palette.primary[800], 0.1),
            borderRadius: "50%",
          },
        },
      }}
    >
      <RemoveRoundedIcon
        className="sh-increase-icon"
        onClick={(e) => {
          onChange(Math.max(min, licenceQuantity - 1));
        }}
      />

      {customText ? (
        customText
      ) : (
        <SHTypography variant="subtitle1">{licenceQuantity}</SHTypography>
      )}

      <AddRoundedIcon
        className="sh-decrease-icon"
        onClick={() => {
          onChange(Math.min(max, licenceQuantity + 1));
        }}
      />
    </SHStack>
  );
};
