import { UserType } from "@models/auth";
import { UserStatus } from "../enums/status";
import { PermissionOverride } from "./permissionOverride";
import { Region } from "./region";

export interface ApplicationUser {
  userType: UserType;
  regionId: number | null;
  permissionOverride: PermissionOverride[];
  region: Region | null;
  supplierId: number | null;
  firstName: string;
  lastName: string;
  auth0Id: string | null;
  avatarUrl: string | null;
  name: string | null;
  status: UserStatus | null;
  createdDate: string;
  lastModifiedDate: string | null;
  welcomeLink: string | null;
  welcomeLinkCreatedDate: string | null;
  welcomeLinkExpirationDate: string | null;
}

// TODO: using GetUserTypeFriendlyName
export const userTypeMapping: any = {
  "Admin:Super": "Super administrator",
  "Admin:Standard": "Administrator",
  "Supplier:Basic": "Supplier Basic",
  "Supplier:BDM_Sale": "Supplier BDM Sale",
  "Supplier:Premium_Owner": "Supplier Premium Owner",
  "Supplier:Premium_Standard": "Supplier Premium Standard",
};
