import { FilterValue } from "@components/buttons/filter";
import {
  SHAvatar,
  SHButton,
  SHContainer,
  SHDivider,
  SHHtmlBlock,
  SHStack,
  SHToggleButtonOptions,
  SHTypography,
} from "@components/design-systems";
import { EditSVG } from "@components/svgs";
import { EyeIcon } from "@components/svgs/eye";
import { DateFormat, DateTimeFormat } from "@constants/format";
import { useIsNew } from "@hooks/useIsNew";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { UserType } from "@models/auth";
import {
  AnalysisFilterDTO,
  HiddenSubProductDTO,
} from "@models/platform-analysis/entities/analysis";
import { AnalysisCommentDTO } from "@models/platform-analysis/entities/comment";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import {
  AnalysisFeeDTO,
  FeeSubProductDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { AnalysisSummaryDTO } from "@models/platform-analysis/entities/steps/summary";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { TableViewMode } from "@models/platform-analysis/enums/tableViewMode";
import { Collapse, useTheme } from "@mui/material";
import { ChangeRichTextDialog } from "@pages/platform-analysis/_id/steps/summary/components/change-rich-text-dialog";
import { ShowHideButton } from "@pages/platform-analysis/_id/steps/summary/components/show-hide-button";
import { CommentsTable } from "@pages/platform-analysis/_id/steps/summary/components/tables/comments";
import { FeaturesAnalysisTable } from "@pages/platform-analysis/_id/steps/summary/components/tables/feature-analysis";
import { FeaturesIncludeTable } from "@pages/platform-analysis/_id/steps/summary/components/tables/features-include";
import { AnalysisSummarySkeleton } from "@pages/platform-analysis/_id/steps/summary/skeleton";
import { ExportExcelButton } from "@pages/platform-analysis/components/buttons/export-excel";
import {
  FeeAnalysisFilterMapOptions,
  FeeAnalysisFilterYesNoOptions,
  PlatformFeeAnalysisInvestmentMenuFilterOptions,
  PlatformFeeAnalysisMissingInvestmentFilterOptions,
} from "@pages/platform-analysis/constant";
import { repairUpdateAnalysis } from "@redux/slices/platform-analysis/util";
import { RootState } from "@redux/store";
import { savePlatformAnalysisFilters } from "@services/platform-analysis/platformAnalysisService";
import { hexToRGBA } from "@utils";
import { format } from "date-fns";
import {
  chain,
  find,
  isEmpty,
  isEqual,
  isNil,
  map,
  some,
  sortBy,
  split,
} from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { ChartLegendBox } from "../fee/analysis/components/chart-legend-box";
import { FeeHeatmap } from "../fee/analysis/components/heatmap";
import { TableAnalysisViewModeButton } from "./components/table-view-mode-button";
import { BusinessMetricAnalysisTable } from "./components/tables/business-metric-analysis";
import { FeePortfolioDetailsTable } from "./components/tables/fee-portfolio-details";
import { FeeAnalysisFilterRadioButton } from "@components/buttons/fee-analysis-filter-radio";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";
import { FeeLineChart } from "./components/fee-line-chart";

export interface AnalysisSummaryProps extends StepCompProps {}
export const AnalysisSummary: ForwardRefRenderFunction<
  StepCompRef,
  AnalysisSummaryProps
> = ({ stepNumber }, ref) => {
  const { palette } = useTheme();
  const isNew = useIsNew();

  const { analysisId } = useParams<{ analysisId: string }>();
  const {
    ui: { isLoading, isSubmitting, isShowHidden },
    platformAnalysis,
    isCompleted,
    isReadOnly,
  } = useSelector((state: RootState) => state.platformAnalysis);

  const {
    setIsDirty,
    setIsValid,
    loadPlatformAnalysis,
    loadPlatformAnalysisDisclaimers,
    updateCurrentPlatformAnalysis,
    setAnalysisDisclaimers,
    setPlatformAnalysis,
    setIsShowHidden,
    setLineChartColors,
  } = usePlatformAnalysis();
  const [shouldRendered, setShouldRendered] = useState(false);
  const [showEditObjective, setShowEditObjective] = useState(false);
  const [showEditRemark, setShowEditRemark] = useState(false);
  const [disclaimers, setDisclaimers] = useState<DisclaimerDTO[]>();
  const [hiddenIds, setHiddenIds] = useState<string[]>([]);

  const [comments, setComments] = useState<AnalysisCommentDTO[]>([]);
  const {
    reset,
    watch,
    setValue,
    getValues,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...new AnalysisSummaryDTO() },
  });
  const isDisabled = isSubmitting;
  const isSalesOwner = getValues("ownerType") === UserType.SupplierBdmSale;
  const analysisComments = watch("analysisComments");
  const hasFeatureStep = watch("hasFeatureStep");
  const hasBusinessMetricStep = watch("hasBusinessMetricStep");
  const hasFeeStep = watch("hasFeeStep");
  const showSelectedFeatures = watch("showSelectedFeatures");
  const showComments = watch("showComments");
  const hideFeeAnalysisGraph = watch("hideFeeAnalysisGraph");
  const showAnalysisScore = watch("showAnalysisScore") ?? true;
  const objectives = watch("objectives");
  const concludingRemarks = watch("concludingRemarks");
  const featureAnalysis = watch("featureAnalysis");
  const featureAnalysisTableViewMode =
    watch("featureAnalysisTableViewMode") ?? TableViewMode.Default;
  const feesDisplayStyle = watch("feesDisplayStyle") ?? FeesDisplayStyle.Dollar;
  const feeTableViewMode = watch("feeTableViewMode") ?? FeeHeatmapMode.Detail;
  const feesSubProducts = watch("feeAnalysis")?.subProducts;
  const feePortfolioDetails = watch("feePortfolioDetails");
  const feeMissingInvestmentsStyle =
    watch("feeMissingInvestmentsStyle") ??
    FeeMissingInvestmentsOption.AllPlatform;
  const feeInvestmentMenuStyle =
    watch("investmentMenu") ?? FeeInvestmentMenuOption.AllPlatform;
  const adviserName = watch("adviserName") || "";
  const practiceName = watch("practiceName") || "";
  const selectedProducts = watch("selectedProducts") || [];
  const selectedFeatures = watch("selectedFeatures") || [];
  const isEmptyAdviserName = isEmpty(adviserName.trim());
  const isEmptyPracticeName = isEmpty(practiceName.trim());
  const isShowAdviserName =
    isSalesOwner && !isEmptyAdviserName && !isEmptyPracticeName;
  const isShowHoldingsNumber = watch("feeAnalysis.isShowHoldingsNumber");
  const feeMissingInvestmentFilter: FilterValue[] | undefined =
    feeMissingInvestmentsStyle !== FeeMissingInvestmentsOption.NotMissing
      ? undefined
      : [
          {
            value: feeMissingInvestmentsStyle,
            label: feeMissingInvestmentsStyle,
          },
        ];
  const feeInvestmentMenuFilter: FilterValue[] | undefined =
    feeInvestmentMenuStyle === FeeInvestmentMenuOption.AllPlatform
      ? undefined
      : [
          {
            value: feeInvestmentMenuStyle,
            label: feeInvestmentMenuStyle,
          },
        ];
  const isDisabledAnalysisTableViewMode =
    (featureAnalysis?.overallScore?.totalSelectedFeature ?? 0) > 200;
  const isShowFeatureIncludedSection =
    hasFeatureStep &&
    !isCompleted &&
    featureAnalysisTableViewMode !== TableViewMode.Detail;

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep) => {
      if (!isReadOnly) {
        await saveFilter();
      }
      if (!isDirty || isReadOnly) return true;
      return await handleOnSubmit(true);
    },
    onChangeOtherStep: async () => {
      if (!isReadOnly) {
        await saveFilter();
      }
      if (isDirty) {
        return await handleOnSubmit(true);
      }
      return true;
    },
    onSubmit: async () => {
      return await handleOnSubmit(isDirty);
    },
  }));

  const handleOnSubmit = async (isShowMessage?: boolean) => {
    if (!platformAnalysis) return false;
    const isSuccess = await updateCurrentPlatformAnalysis(
      {
        ...repairUpdateAnalysis(platformAnalysis),
        currentStep: AnalysisStep.Summary,
        summary: {
          ...getValues(),
          selectedProducts: null,
          hiddenSubProducts: generateHiddenSubProducts(
            hiddenIds,
            platformAnalysis?.summary?.feeAnalysis?.subProducts ?? [],
          ),
          analysisComments: null,
          selectedFeatures: null,
          featureAnalysis: null,
          businessMetricAnalysis: null,
          feeAnalysis: null,
        },
      },
      isShowMessage,
    );
    if (isSuccess) {
      reset({
        ...getValues(),
        lastModifiedDate: new Date(),
        analysisComments: comments,
      });

      setPlatformAnalysis({
        ...platformAnalysis,
        summary: {
          ...getValues(),
          lastModifiedDate: new Date(),
          analysisComments: comments,
          feeAnalysis: {
            ...getValues()?.feeAnalysis,
            subProducts: map(getValues()?.feeAnalysis?.subProducts, (item) => {
              if (hiddenIds.includes(item.id)) {
                return { ...item, isHidden: true };
              }
              return item;
            }),
          } as AnalysisFeeDTO,
        },
      });
    }
    return !!isSuccess;
  };

  const loadAnalysis = async () => {
    if (!isNew && analysisId) {
      const analysis = await loadPlatformAnalysis({
        platformAnalysisId: analysisId,
        stepId: AnalysisStep.Summary,
      });
      if (analysis && analysis?.summary) {
        const analysisSummary = analysis?.summary;
        reset(analysis.summary);
        if (
          analysisSummary?.featureAnalysis?.overallScore.totalSelectedFeature! >
            200 &&
          analysisSummary?.featureAnalysisTableViewMode === TableViewMode.Detail
        ) {
          setValue("featureAnalysisTableViewMode", TableViewMode.Default, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      }
      setHiddenIds(
        chain(analysis?.summary?.feeAnalysis?.subProducts)
          .filter({ isHidden: true })
          .map("id")
          .value(),
      );
    }
  };

  useEffect(() => {
    let initHiddenIds = chain(
      platformAnalysis?.summary?.feeAnalysis?.subProducts,
    )
      .filter({ isHidden: true })
      .map("id")
      .value();

    if (!isEqual(sortBy(hiddenIds), sortBy(initHiddenIds))) {
      setValue(
        "hiddenSubProducts",
        generateHiddenSubProducts(
          hiddenIds,
          platformAnalysis?.summary?.feeAnalysis?.subProducts ?? [],
        ),
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
    }
    // eslint-disable-next-line
  }, [hiddenIds]);

  const saveFilter = async () => {
    let data: AnalysisFilterDTO = {
      feeMissingInvestmentsStyle: feeMissingInvestmentFilter?.[0].value
        ? feeMissingInvestmentFilter?.[0].value
        : FeeMissingInvestmentsOption.AllPlatform,
      investmentMenu: feeInvestmentMenuFilter?.[0]?.value
        ? feeInvestmentMenuFilter?.[0]?.value
        : FeeInvestmentMenuOption.AllPlatform,
      feesDisplayStyle: feesDisplayStyle,
      feeTableViewMode: feeTableViewMode,
      hiddenSubProducts: generateHiddenSubProducts(
        hiddenIds,
        platformAnalysis?.summary?.feeAnalysis?.subProducts ?? [],
      ),
      showAnalysisScore: showAnalysisScore,
    };

    await savePlatformAnalysisFilters(platformAnalysis?.id ?? "", data);
  };

  const generateHiddenSubProducts = (
    hiddenIds: string[],
    subProducts: FeeSubProductDTO[],
  ): HiddenSubProductDTO[] => {
    let hiddenSubProducts: HiddenSubProductDTO[] = [];
    hiddenIds.forEach((id) => {
      var subProduct = find(subProducts, { id });
      if (subProduct) {
        let subProductId = subProduct.id;
        if (subProduct.isRateCard) {
          subProductId = split(subProduct.id, "|")[0];
        }

        hiddenSubProducts.push({
          id: subProductId,
          isRateCard: subProduct.isRateCard ?? true,
          rateCardVersion: subProduct.isRateCard
            ? subProduct.rateCardVersion ?? 0
            : 0,
        });
      }
    });
    return hiddenSubProducts;
  };

  const loadDisclaimers = async () => {
    if (!isNew && analysisId) {
      const disclaimers = await loadPlatformAnalysisDisclaimers(analysisId);
      setDisclaimers(disclaimers);
      setAnalysisDisclaimers(disclaimers);
    }
  };

  const renderDisclaimers = () => {
    return (
      !isEmpty(disclaimers) && (
        <SHStack spacing={"10px"}>
          <SHTypography variant="subtitle2">
            Fee analysis assumptions: Product-specific
          </SHTypography>
          {disclaimers?.map((item, index) => {
            return (
              <SHHtmlBlock
                key={index}
                content={item.messageDisclaimer}
                variant="caption"
                sx={{
                  pb: index !== disclaimers?.length - 1 ? "5px" : "0px",
                  fontSize: "10px !important",
                }}
                color={palette.text.third}
              />
            );
          })}
        </SHStack>
      )
    );
  };

  useEffectOnce(() => {
    loadAnalysis();
    loadDisclaimers();
    setIsShowHidden(false);
    const timer = setTimeout(() => {
      setShouldRendered(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
      setIsDirty(false);
    };
  });

  useEffect(() => {
    setIsValid(isValid);
    // eslint-disable-next-line
  }, [isValid]);

  useEffect(() => {
    if (!isReadOnly) setIsDirty(isDirty);
    // eslint-disable-next-line
  }, [isDirty, isReadOnly]);

  useEffect(() => {
    setComments(analysisComments || []);
  }, [analysisComments]);

  const feesSubProductsFiltered = useMemo(() => {
    if (
      feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.AllPlatform &&
      feeInvestmentMenuStyle === FeeInvestmentMenuOption.AllPlatform
    ) {
      return feesSubProducts;
    }

    let feesSubProductsFiltered: FeeSubProductDTO[] = Object.assign(
      feesSubProducts ?? [],
    );

    if (feeInvestmentMenuStyle !== FeeInvestmentMenuOption.AllPlatform) {
      feesSubProductsFiltered = feesSubProductsFiltered?.filter(
        (subProduct) => subProduct.investmentMenu === feeInvestmentMenuStyle,
      );
    }

    if (
      feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.AllPlatform
    ) {
      return feesSubProductsFiltered;
    } else {
      return feesSubProductsFiltered?.filter((subProduct) =>
        feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.Missing
          ? subProduct.warning
          : !subProduct.warning,
      );
    }
  }, [feesSubProducts, feeMissingInvestmentsStyle, feeInvestmentMenuStyle]);

  const showMissingInvestmentFilter = feesSubProducts?.some(
    (subProduct: FeeSubProductDTO) => subProduct.warning,
  );

  if (isLoading || !shouldRendered || !platformAnalysis)
    return <AnalysisSummarySkeleton />;

  const subProductsDataForExcel = () => {
    return isShowHidden
      ? feesSubProductsFiltered
      : feesSubProductsFiltered?.filter(
          (subProduct) => !hiddenIds.includes(subProduct.id),
        );
  };

  return (
    <SHStack>
      <SHContainer
        maxWidth="lg2"
        sx={{ px: { xs: "16px", lg2: 0 }, py: "25px" }}
      >
        <StepName name={"Summary"} stepNumber={stepNumber} />
        {/*  Body */}
        <SHStack spacing={"25px"}>
          {/*  Main information */}
          <SHStack
            direction={{ xs: "column", lg: "row" }}
            justifyContent={{ xs: "flex-start", lg: "space-between" }}
            alignItems={{ xs: "flex-start", lg: "stretch" }}
            gap={"25px"}
          >
            <SHStack spacing={"25px"} sx={{ width: { xs: "100%", lg: "50%" } }}>
              <SHTypography variant="subtitle1" fontSize={"24px"}>
                {watch("name")}
              </SHTypography>
              <SHStack direction={"row"} spacing="5px">
                <SHAvatar
                  width={"24px"}
                  height={"24px"}
                  src={watch("ownerAvatarUrl") || undefined}
                />
                <SHTypography variant="body2" colorVariant="third">
                  {watch("ownerName")}
                </SHTypography>
              </SHStack>
              <SHStack direction={"row"} alignItems={"center"} spacing="15px">
                <SHTypography
                  variant="body2"
                  colorVariant="third"
                >{`Last edit: ${format(
                  watch("lastModifiedDate") || new Date(),
                  `${DateTimeFormat}`,
                )}`}</SHTypography>
                <SHDivider orientation="vertical" sx={{ height: "14px" }} />
                <SHTypography variant="body2" colorVariant="third">
                  {platformAnalysis?.databaseVersion
                    ? `Data valid: ${format(
                        new Date(platformAnalysis?.databaseVersion),
                        DateFormat,
                      )}`
                    : ""}
                </SHTypography>
                <SHDivider orientation="vertical" sx={{ height: "14px" }} />
                <SHTypography variant="body2" colorVariant="third">{`Serial: ${
                  watch("serial") || ""
                }`}</SHTypography>
              </SHStack>
            </SHStack>
            <SHStack spacing={"25px"} sx={{ width: { xs: "100%", lg: "50%" } }}>
              {isShowAdviserName ? (
                <SHStack spacing={"5px"}>
                  <SHTypography variant="subtitle1">
                    This analysis is for
                  </SHTypography>
                  <SHStack direction={"row"} spacing={"4px"}>
                    <SHTypography variant="subtitle2">
                      {watch("adviserName")}
                    </SHTypography>
                    <SHTypography variant="body2" colorVariant="third">
                      from
                    </SHTypography>
                    <SHTypography variant="subtitle2">
                      {watch("practiceName")}
                    </SHTypography>
                  </SHStack>
                </SHStack>
              ) : (
                <SHStack spacing={"5px"}>
                  <SHTypography variant="subtitle1">
                    Not for dissemination
                  </SHTypography>
                  <SHStack direction={"row"} spacing={"2px"}>
                    <SHTypography variant="body2" colorVariant="third">
                      This analysis is created for
                    </SHTypography>
                    <SHTypography variant="body1" colorVariant="third">
                      {watch("supplierName")}
                    </SHTypography>
                    <SHTypography variant="body2" colorVariant="third">
                      internal use only.
                    </SHTypography>
                  </SHStack>
                </SHStack>
              )}
            </SHStack>
          </SHStack>
          {/*  Objectives */}
          {(!isCompleted || objectives) && (
            <SHStack spacing={"5px"}>
              <SHStack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <SHTypography variant="subtitle2">Objectives</SHTypography>
                {!isCompleted && !isReadOnly && (
                  <SHButton
                    startIcon={<EditSVG color={palette.common.white} />}
                    variant="contained"
                    disabled={isReadOnly || isDisabled}
                    onClick={() => setShowEditObjective(true)}
                  >
                    Edit
                  </SHButton>
                )}
              </SHStack>
              <SHDivider />
              <SHHtmlBlock
                content={objectives}
                placeholder={"Insert objectives"}
                color={palette.text.third}
              />
            </SHStack>
          )}
          {/*  Feature analysis */}
          {hasFeatureStep && (
            <SHStack spacing={3}>
              <SHStack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <SHTypography variant="subtitle2">
                  Feature analysis
                </SHTypography>
                {!isCompleted && (
                  <SHStack flexDirection={"row"} gap={"5px"}>
                    {featureAnalysisTableViewMode === TableViewMode.Detail && (
                      <ShowHideButton
                        options={[
                          {
                            value: "true",
                            label: "Show scores",
                          },
                          {
                            value: "false",
                            label: "Hide",
                          },
                        ]}
                        isShow={watch("showAnalysisScore")}
                        onChange={(isShow: boolean) => {
                          setValue("showAnalysisScore", isShow, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }}
                      />
                    )}
                    <TableAnalysisViewModeButton
                      disabled={isDisabled}
                      value={featureAnalysisTableViewMode}
                      onChange={(mode: TableViewMode) =>
                        setValue("featureAnalysisTableViewMode", mode, {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                      options={[
                        {
                          value: TableViewMode.Detail,
                          label: "Detail",
                          disabled: isDisabledAnalysisTableViewMode,
                          tooltip: isDisabledAnalysisTableViewMode
                            ? "Select less than 200 features for detail view"
                            : "",
                        },
                        {
                          value: TableViewMode.Default,
                          label: "Default",
                        },
                        {
                          value: TableViewMode.Summary,
                          label: "Summary",
                        },
                      ]}
                    />
                  </SHStack>
                )}
              </SHStack>
              {!isNil(featureAnalysis) && (
                <FeaturesAnalysisTable
                  showSubgroup={
                    featureAnalysisTableViewMode !== TableViewMode.Summary
                  }
                  featureAnalysis={featureAnalysis as any}
                  isDetailMode={
                    featureAnalysisTableViewMode === TableViewMode.Detail
                  }
                  isShowScore={showAnalysisScore}
                />
              )}
            </SHStack>
          )}
          {/*  Business metric analysis */}
          {hasBusinessMetricStep && (
            <SHStack spacing={3}>
              <SHStack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <SHTypography variant="subtitle2">
                  Business metric analysis
                </SHTypography>
              </SHStack>
              <BusinessMetricAnalysisTable
                businessMetricAnalysis={watch("businessMetricAnalysis") as any}
              />
            </SHStack>
          )}
          {hasFeeStep && (
            <>
              {/*  Portfolio details for fee estimates */}
              <SHStack spacing={3}>
                <SHStack>
                  <SHTypography variant="subtitle2">
                    Portfolio details for fee estimates
                  </SHTypography>
                  <FeePortfolioDetailsTable
                    data={feePortfolioDetails}
                    isShowHoldingsNumber={isShowHoldingsNumber}
                  />
                </SHStack>
              </SHStack>
              {/* Fee estimates */}
              <SHStack spacing={3}>
                <SHStack>
                  <SHStack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <SHTypography variant="subtitle2">
                      Fee estimates
                    </SHTypography>
                    {!isCompleted && (
                      <SHStack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={"25px"}
                      >
                        <SHStack>
                          <SHButton
                            color="primary"
                            variant="text"
                            size="extraMedium"
                            startIcon={
                              <EyeIcon
                                isActive={true}
                                fill={
                                  hiddenIds.length > 0
                                    ? palette.primary.main
                                    : palette.grey[500]
                                }
                              />
                            }
                            disabled={hiddenIds.length === 0}
                            onClick={() => {
                              setHiddenIds([]);
                              setIsShowHidden(false);
                              setValue("hiddenSubProducts", [], {
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                            }}
                          >
                            Show hidden
                          </SHButton>
                        </SHStack>
                        <FeeAnalysisFilterRadioButton
                          buttonTitle={"Filters"}
                          filterInvestmentMenuData={
                            PlatformFeeAnalysisInvestmentMenuFilterOptions
                          }
                          filterInvestmentMenuValue={feeInvestmentMenuFilter}
                          filterMissingInvestmentData={
                            PlatformFeeAnalysisMissingInvestmentFilterOptions
                          }
                          filterMissingInvestmentValue={
                            feeMissingInvestmentFilter
                          }
                          width={"275px"}
                          labelAll={"All platforms"}
                          zIndex={10}
                          onChangeFilter={(missingValues, menuValues) => {
                            const missingValue = missingValues
                              ? missingValues?.[0]?.value
                              : FeeMissingInvestmentsOption.AllPlatform;
                            const menuValue = menuValues
                              ? menuValues?.[0]?.value
                              : FeeInvestmentMenuOption.AllPlatform;
                            setValue(
                              "feeMissingInvestmentsStyle",
                              missingValue,
                              {
                                shouldDirty: true,
                                shouldValidate: true,
                              },
                            );
                            setValue("investmentMenu", menuValue, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          }}
                        />
                        <SHToggleButtonOptions
                          options={FeeAnalysisFilterMapOptions}
                          value={feeTableViewMode}
                          onChange={(event, value) => {
                            if (!value) return;
                            setValue("feeTableViewMode", value, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          }}
                        />
                        <SHToggleButtonOptions
                          options={FeeAnalysisFilterYesNoOptions}
                          value={feesDisplayStyle}
                          onChange={(event, value) => {
                            if (!value) return;
                            setValue("feesDisplayStyle", value, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          }}
                        />
                      </SHStack>
                    )}
                  </SHStack>

                  <FeeHeatmap
                    subProductsInStore={feesSubProducts ?? []}
                    subProducts={feesSubProductsFiltered ?? []}
                    hiddenIds={hiddenIds}
                    onChangeHiddenIds={setHiddenIds}
                    isSummaryStep
                    isCompleted={isCompleted}
                    isShowHidden={isShowHidden}
                    totalPortfolioValue={
                      platformAnalysis?.summary?.feePortfolioDetails
                        ?.totalPortfolioValue
                    }
                    mode={feesDisplayStyle as any}
                    heatmapMode={feeTableViewMode}
                    sx={{
                      backgroundColor: "transparent",
                      pt: "15px",
                    }}
                    titleComp={
                      <SHStack
                        sx={{
                          border: `solid 1px ${hexToRGBA(
                            palette.primary[50],
                            0.3,
                          )}`,
                          p: "10px 15px",
                        }}
                        direction={"row"}
                        spacing={"5px"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <SHTypography variant="subtitle2" fontWeight={700}>
                          Annual platform fees by portfolio value (estimates)
                        </SHTypography>
                        {platformAnalysis?.summary?.feeAnalysis
                          ?.subProducts && (
                          <ExportExcelButton
                            mode={feesDisplayStyle as any}
                            data={
                              {
                                ...platformAnalysis.summary,
                                subProducts: subProductsDataForExcel(),
                                databaseVersion:
                                  platformAnalysis.databaseVersion ?? null,
                                isShowHoldingsNumber: isShowHoldingsNumber,
                              } as any
                            }
                          />
                        )}
                      </SHStack>
                    }
                    leftLegend={
                      <ChartLegendBox height={"90px"} direction="vertical">
                        Platforms
                      </ChartLegendBox>
                    }
                    bottomLegend={
                      <ChartLegendBox>
                        Average total portfolio value for the year
                      </ChartLegendBox>
                    }
                    showWarningCaptions={
                      (!feeMissingInvestmentFilter &&
                        showMissingInvestmentFilter) ||
                      some(feeMissingInvestmentFilter, {
                        value: FeeMissingInvestmentsOption.Missing,
                      })
                    }
                  />
                </SHStack>
                <SHStack
                  direction={"row-reverse"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {(!isCompleted && !isEmpty(feesSubProductsFiltered)) && (
                    <ShowHideButton
                      disabled={isDisabled}
                      isShow={!hideFeeAnalysisGraph}
                      onChange={(isShow: boolean) => {
                        setValue("hideFeeAnalysisGraph", !isShow, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                    />
                  )}
                </SHStack>
                {!isEmpty(feesSubProductsFiltered) && (
                  <Collapse in={!hideFeeAnalysisGraph}>
                    <FeeLineChart
                      subProducts={feesSubProductsFiltered ?? []}
                      mode={feesDisplayStyle}
                      hiddenIds={hiddenIds}
                      isCompleted={isCompleted}
                      onChangeLineChartColors={setLineChartColors}
                      onChangeHiddenIds={setHiddenIds}
                    />
                  </Collapse>
                )}
              </SHStack>
            </>
          )}
          {/*  Comments */}
          {(!isCompleted || showComments) && (
            <SHStack spacing={showComments ? 3 : 0}>
              <SHStack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <SHTypography variant="subtitle2">
                  Platform specific comments
                </SHTypography>
                {!isCompleted && (
                  <ShowHideButton
                    disabled={isDisabled}
                    isShow={showComments}
                    onChange={(isShow: boolean) =>
                      setValue("showComments", isShow, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                  />
                )}
              </SHStack>
              <Collapse in={showComments}>
                <CommentsTable
                  disabled={isReadOnly || isDisabled}
                  isViewMode={isCompleted}
                  comments={comments}
                  onChange={(newComments) => setComments(newComments)}
                  hasFeatureStep={hasFeatureStep}
                  hasBusinessMetricStep={hasBusinessMetricStep}
                  hasFeeStep={hasFeeStep}
                />
              </Collapse>
            </SHStack>
          )}
          {/*  Concluding remarks */}
          {(!isCompleted || concludingRemarks) && (
            <SHStack spacing={3}>
              <SHStack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <SHTypography variant="subtitle2">
                  Concluding remarks
                </SHTypography>
                {!isCompleted && !isReadOnly && (
                  <SHButton
                    startIcon={<EditSVG color={palette.common.white} />}
                    variant="contained"
                    disabled={isReadOnly || isDisabled}
                    onClick={() => setShowEditRemark(true)}
                  >
                    Edit
                  </SHButton>
                )}
              </SHStack>
              <SHStack
                sx={{
                  backgroundColor: hexToRGBA(palette.common.white, 0.25),
                  border: `1px solid ${palette.secondary[100]}`,
                  backdropFilter: "blur(0.5px)",
                  padding: "15px 12px",
                }}
              >
                <SHHtmlBlock
                  content={concludingRemarks}
                  placeholder={"Insert concluding remarks"}
                  color={palette.text.third}
                />
              </SHStack>
            </SHStack>
          )}
          {/*  Platforms included */}
          <SHStack spacing={"5px"}>
            <SHTypography variant="subtitle2">
              Platforms included in the analysis
            </SHTypography>
            <SHTypography variant="body2">
              {selectedProducts?.map((product) => product.name).join(", ")}
            </SHTypography>
          </SHStack>
          {/*  Features included */}
          {isShowFeatureIncludedSection && (
            <SHStack spacing={showSelectedFeatures ? 3 : 0}>
              <SHStack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <SHTypography variant="subtitle2">
                  Features included in the analysis
                </SHTypography>

                {!isCompleted && (
                  <ShowHideButton
                    disabled={isDisabled}
                    isShow={showSelectedFeatures}
                    onChange={(isShow: boolean) =>
                      setValue("showSelectedFeatures", isShow, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                  />
                )}
              </SHStack>
              <Collapse in={showSelectedFeatures}>
                <FeaturesIncludeTable featureInclude={selectedFeatures} />
              </Collapse>
            </SHStack>
          )}
          {/*  Disclaimer */}
          <SHStack spacing={"10px"}>
            <SHTypography variant="subtitle2">Disclaimer</SHTypography>
            <SHTypography variant="body2" fontSize={"10px"}>
              You have requested this report via the SuitabilityHub platform. We
              have not conducted any independent verification of the information
              provided by you. We utilise information sourced from our users and
              other third parties, and you must make your own enquiries as to
              the accuracy and completeness of information contained in this
              report. No representation or warranty (either express or implied)
              is given as to the truth, accuracy or completeness of the
              information in this report. This report is not intended to be
              relied on for the purposes of making a decision in relation to any
              financial product, platform or provider. We do not authorise any
              reliance on this report (or any information within it). In
              providing this report, we provide no financial advice or
              recommendation as to the suitability of any product, platform or
              provider.
            </SHTypography>
            <SHTypography variant="body2" fontSize={"10px"}>
              This report is based on the conditions in existence and
              information received on the “data valid” date stated in this
              report.
            </SHTypography>
          </SHStack>
          {hasFeeStep && (
            <SHStack spacing={"10px"}>
              <SHTypography variant="subtitle2">
                Fee analysis disclaimer
              </SHTypography>
              <SHTypography variant="body2" fontSize={"10px"}>
                Any result provided by SuitabilityHub’s fee calculator is
                illustrative only, and relies upon information that is subject
                to change. Any result should not be relied upon for the purpose
                of making a decision in relation to a particular financial
                product, platform or provider. Any result provided by our
                calculator is not an offer, and financial product providers will
                undertake their own fee calculations. Unless otherwise stated,
                all amounts are in Australian dollars.
              </SHTypography>
              <SHTypography variant="subtitle2">
                Fee analysis assumptions: General
              </SHTypography>
              <SHTypography
                variant="body2"
                fontSize={"10px"}
                sx={{
                  "&>ul>li::marker": {
                    fontSize: "10px",
                  },
                }}
              >
                Our fee calculator relies upon various assumptions. Those
                assumptions are as follows, and are current as at 15 December
                2023:
                <ul style={{ margin: 0, paddingLeft: "20px" }}>
                  <li>
                    All balances provided are an average figure for a 12-month
                    period;
                  </li>
                  <li>
                    Investments held by a client are also held by all of the
                    accounts in that client’s family;
                  </li>
                  <li>
                    Fee estimates are calculated based on all accounts within a
                    family investing in the same platform product;
                  </li>
                  <li>
                    For the purpose of calculating transaction fees, investment
                    transactions for a given investment class is assumed to be
                    equal in size, and this size is the “anticipated average
                    transaction size for the next 12 months” entered into the
                    calculator;
                  </li>
                  <li>
                    Some platforms do not charge transaction fees on regular
                    investment facilities or plans. We assume all transactions
                    specified happen outside of these facilities or plans, on an
                    ad-hoc basis;
                  </li>
                  <li>
                    Any calculated expense recovery fee and/or operational risk
                    financial requirement fee is the maximum possible fee as set
                    out in the relevant financial product’s current product
                    disclosure statement;
                  </li>
                  <li>
                    For superannuation accounts with balances less than $6,000,
                    total administration, cash and account-related fees are
                    capped at 3% of the account balance. Other fees are not
                    included in this cap e.g. transaction fees;
                  </li>
                  <li>Each investment transaction is the same size; </li>
                  <li>
                    Where fees listed in the PDS vary within a specified range,
                    the maximum fee amount is applied;
                  </li>
                  <li>
                    Fee aggregation discounts and fee calculation methodologies
                    will be applied in accordance with the relevant financial
                    product’s current product disclosure statement;
                  </li>
                  <li>
                    All listed accounts are grouped for fee aggregation
                    purposes; and
                  </li>
                  <li>
                    Fee estimates are displayed for total portfolio balances in
                    addition to the original total entered into the calculator.
                    These estimates are based on proportionally allocating the
                    holdings and account balances of the original total
                    portfolio value.
                  </li>
                </ul>
              </SHTypography>
              <SHTypography variant="body2" fontSize={"10px"}>
                Any result provided by the fee calculator takes into account
                platform administration fees, as well as transaction fees for
                Australian listed securities, international listed securities,
                managed funds and fixed income, and fees associated with holding
                retail insurance (not including the value of any insurance
                premiums). Results do not factor in retail or group insurance
                premiums, foreign currency fees that may apply to international
                investments, buy-sell spreads, and investment fees for
                individual investment options (e.g. performance-related fees);
                final results also do not factor in minimum cash balance and
                minimum transaction sizes.
              </SHTypography>
              {renderDisclaimers()}
            </SHStack>
          )}
        </SHStack>
      </SHContainer>
      {/*  Footer */}
      <SHDivider sx={{ borderColor: palette.text.primary }} />
      <SHContainer
        maxWidth="lg2"
        sx={{ px: { xs: "16px", lg2: 0 }, py: "40px" }}
      >
        <SHTypography variant="subtitle1">
          {isShowAdviserName
            ? `This analysis is created for ${watch("adviserName")} of ${watch(
                "practiceName",
              )} use only`
            : `This analysis is created for ${watch(
                "supplierName",
              )} internal use only.`}
        </SHTypography>
      </SHContainer>
      {showEditObjective && (
        <ChangeRichTextDialog
          title={"Edit Objectives"}
          initialContent={objectives}
          placeHolder={"Enter your objectives for this analysis"}
          onSubmit={(value) => {
            setShowEditObjective(false);
            setValue("objectives", value, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
          onClose={() => setShowEditObjective(false)}
        />
      )}
      {showEditRemark && (
        <ChangeRichTextDialog
          title={"Edit concluding remarks"}
          initialContent={concludingRemarks}
          placeHolder={"Enter concluding remarks for this analysis"}
          onSubmit={(value) => {
            setShowEditRemark(false);
            setValue("concludingRemarks", value, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
          onClose={() => setShowEditRemark(false)}
        />
      )}
    </SHStack>
  );
};
