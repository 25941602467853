import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { ReviewStepBusinessMetricContainerDTO } from "@models/reviews/entities/steps/businessMetric";
import { ReviewStepFeatureContainerDTO } from "@models/reviews/entities/steps/feature";
import { HiddenSubProductDTO } from "@models/reviews/entities/steps/summary";
import { chain, chunk, intersection, map } from "lodash";

export const convertToFeatureReviewContainerArray = (
  featureReview: ReviewStepFeatureContainerDTO,
  separateNum: number,
): ReviewStepFeatureContainerDTO[] => {
  return Array.from(
    Array(
      Math.ceil(featureReview.selectedProducts.length / separateNum),
    ).keys(),
  ).map((index) => {
    const selectedProductsChunk = chunk(
      featureReview.selectedProducts,
      separateNum,
    )[index];
    const overallScoreChunk = chunk(
      featureReview.overallScore.analysisData,
      separateNum,
    )[index];
    const sections = featureReview.sections.map((section) => ({
      ...section,
      analysisData: chunk(section.analysisData, separateNum)[index],
      subSections: section.subSections.map((subSection) => ({
        ...subSection,
        analysisData: chunk(subSection.analysisData, separateNum)[index],
        features: subSection.features.map((feature) => ({
          ...feature,
          analysisData: chunk(feature.analysisData, separateNum)[index],
        })),
      })),
    }));

    return {
      ...featureReview,
      selectedProducts: selectedProductsChunk,
      overallScore: {
        ...featureReview.overallScore,
        analysisData: overallScoreChunk,
      },
      sections: sections,
    };
  }) as ReviewStepFeatureContainerDTO[];
};

export const convertToBusinessReviewContainerArray = (
  businessReview: ReviewStepBusinessMetricContainerDTO,
  separateNum: number,
): ReviewStepBusinessMetricContainerDTO[] => {
  return Array.from(
    Array(
      Math.ceil(businessReview.selectedProducts.length / separateNum),
    ).keys(),
  ).map((index) => {
    const selectedProductsChunk = chunk(
      businessReview.selectedProducts,
      separateNum,
    )[index];
    const overallScoreChunk = chunk(
      businessReview.overallScore.analysisData,
      separateNum,
    )[index];
    const sections = businessReview.sections.map((section) => ({
      ...section,
      analysisData: chunk(section.analysisData, separateNum)[index],
      features: section.features.map((feature) => ({
        ...feature,
        analysisData: chunk(feature.analysisData, separateNum)[index],
      })),
    }));

    return {
      ...businessReview,
      selectedProducts: selectedProductsChunk,
      overallScore: {
        ...businessReview.overallScore,
        analysisData: overallScoreChunk,
      },
      sections: sections,
    };
  }) as ReviewStepBusinessMetricContainerDTO[];
};

export const generateHiddenIds = (
  feesSubProducts: FeeSubProductDTO[],
  hiddenSubProducts: HiddenSubProductDTO[],
): string[] => {
  let array1 = map(feesSubProducts, "id");
  let array2 = chain(hiddenSubProducts)
    .map((obj) =>
      obj.isRateCard ? `${obj.id}|${obj.rateCardVersion}` : `${obj.id}`,
    )
    .value();

  return intersection(array1, array2);
};
