import {
  FamilyMemberAccountDTO,
  FamilyMemberDTO,
  FeeCalculatorDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { AnalysisVariable } from "@models/platform-analysis/enums/variableName";
import * as Yup from "yup";

export const feeCalculatorValidators = Yup.object().shape({});

export const calculateTotalMemberAccount = (
  accounts: FamilyMemberAccountDTO[],
) => {
  return accounts.reduce((total, account) => total + (account.balance || 0), 0);
};
export const calculateTotals = (familyMembers?: FamilyMemberDTO[]) => {
  const initialValue = {
    account: 0,
    total: 0,
    idps: 0,
    super: 0,
  };
  if (!familyMembers) return initialValue;

  familyMembers.forEach((member) => {
    initialValue.account += member.familyMemberAccounts.length;

    member.familyMemberAccounts.forEach((account) => {
      if (account.isIDPS) {
        initialValue.idps += account.balance;
      } else {
        initialValue.super += account.balance;
      }
      initialValue.total += account.balance;
    });
  });

  return initialValue;
};

export const getIndexMapping = (
  feeCalculators: FeeCalculatorDTO[],
): Map<AnalysisVariable, number> => {
  let indexMapping = new Map<AnalysisVariable, number>();
  feeCalculators.forEach((feeCalculator, index) => {
    indexMapping.set(feeCalculator.variableName, index);
  });
  return indexMapping;
};

export const checkHasEmptyBalance = (
  familyMembers?: FamilyMemberDTO[],
): boolean => {
  if (!familyMembers) return true;
  for (let member of familyMembers) {
    if (member.totalBalance <= 0) return true;
    for (let account of member.familyMemberAccounts) {
      if (account.balance <= 0) return true;
    }
  }
  return false;
};
