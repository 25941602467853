import {
  deleteAvatar,
  uploadAvatar,
} from "@services/file-storage/fileStorageService";
import { generateUUID } from "@utils";
import { renameFile } from "@utils/data-type/file";
import { isEmpty } from "lodash";
import { FileRejection } from "react-dropzone";
import { useMap } from "./useMap";
import { useNotification } from "./useNotification";

export const useUpload = () => {
  const { notify } = useNotification();
  const [uploadingMap, actionUploading] = useMap<string, boolean>();
  const [deletedingMap, actionDeleteding] = useMap<string, boolean>();

  const uploadOne = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (!isEmpty(fileRejections)) {
      notify("Unsupported file format. Please upload another file.", {
        variant: "error",
        close: true,
      });
      return;
    }
    if (isEmpty(acceptedFiles)) return;
    const file = renameFile(acceptedFiles[0]);
    const fileId = file.name + generateUUID();
    const form = new FormData();
    form.append("file", file);
    actionUploading.set(fileId, true);
    const { data, message } = await uploadAvatar(form);
    if (!data) {
      notify(message, {
        variant: "error",
        close: true,
      });
      return;
    }
    actionUploading.remove(fileId);
    return data;
  };

  const deleteOne = async (s3Key?: string) => {
    if (!s3Key) return false;
    actionDeleteding.set(s3Key, true);
    const { isSuccess, message } = await deleteAvatar(s3Key);
    actionDeleteding.remove(s3Key);
    if (!isSuccess) {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    return isSuccess;
  };
  return {
    isUploading: uploadingMap.size > 0,
    isDeleting: deletedingMap.size > 0,
    uploadingMap,
    deletedingMap,
    uploadOne,
    deleteOne,
  };
};
