import { APIExtRoutes } from "@constants";
import { InvestmentProductBannerInfoDTO } from "@models/product/investment-product/entities/bannerInfo";
import { InvestmentProductBrandingDataDTO } from "@models/product/investment-product/entities/brandingData";
import { InvestmentConfigurationGroup } from "@models/product/investment-product/entities/investmentProductTabs";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInvestmentProductManager, getInvestmentProductSeries, getProductManagedBrandingInfo } from "@services/product/investment-product/investmentProductService";
import { investmentProductTabsData } from "@services/product/investment-product/investmentProductTabData";

interface InvestmentProductStore {
  investmentProductTabs: InvestmentConfigurationGroup[];
  investmentProductUI: {
    isEditMode?: boolean;
    isLoading?: boolean;
    isManagerDataLoading?: boolean;
    isSeriesDataLoading?: boolean;
    isLoadingEditMode?: boolean;
    selectedTab: number;
  };
  investmentProductBannerInfo?: InvestmentProductBannerInfoDTO[];
  investmentProductBrandingData?: InvestmentProductBrandingDataDTO;
  investmentProductManagerData?: InvestmentProductManagerDTO;
  investmentProductSeriesData?: any//InvestmentProductSeriesDTO[];
}

const initialState: InvestmentProductStore = {
  investmentProductTabs: investmentProductTabsData,
  investmentProductUI: {
    isEditMode: false,
    isLoading: undefined,
    isManagerDataLoading: undefined,
    isSeriesDataLoading: undefined,
    isLoadingEditMode: false,
    selectedTab: 0,
  },
  investmentProductBannerInfo: undefined,
  investmentProductBrandingData: undefined,
  investmentProductManagerData: undefined,
  investmentProductSeriesData: undefined,
};

const investmentProductSlice = createSlice({
  name: "investmentProduct",
  initialState,
  reducers: {
    updateSelectedTab: (state, action: PayloadAction<number>) => {
      state.investmentProductUI.selectedTab = action.payload;
    },
    updateInvestmentProductManagerData: (state, action: PayloadAction<InvestmentProductManagerDTO | undefined>) => {
      state.investmentProductManagerData = action.payload;
    },
    updateInvestmentProductSeriesData: (state, action: PayloadAction<InvestmentProductSeriesDTO[] | undefined>) => {
      state.investmentProductSeriesData = action.payload;
    },
  },
  extraReducers(builder) {
    //Load Product Managed Account - Manager
    builder.addCase(
      loadInvestmentProductManagerThunk.pending,
      (state, action) => {
        state.investmentProductUI.isManagerDataLoading = true;
      },
    );
    builder.addCase(
      loadInvestmentProductManagerThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isManagerDataLoading = false;
        state.investmentProductManagerData = action.payload?.isSuccess ? action.payload?.data : undefined;
      },
    );
    builder.addCase(
      loadInvestmentProductManagerThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isManagerDataLoading = false;
      },
    );
    builder.addCase(
      loadInvestmentProductBrandingData.pending,
      (state, action) => {
        state.investmentProductUI.isLoading = true;
      }
    );
    builder.addCase(
      loadInvestmentProductBrandingData.fulfilled,
      (state, action) => {
        state.investmentProductUI.isLoading = false;
        state.investmentProductBrandingData = action.payload?.data;
      }
    );
    builder.addCase(
      loadInvestmentProductBrandingData.rejected,
      (state, action) => {
        state.investmentProductUI.isLoading = false;
      }
    );
    builder.addCase(
      loadInvestmentProductSeriesThunk.pending,
      (state, action) => {
        state.investmentProductUI.isSeriesDataLoading = true;
      }
    );
    builder.addCase(
      loadInvestmentProductSeriesThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isSeriesDataLoading = false;
        state.investmentProductSeriesData = action.payload?.data;
      }
    );
    builder.addCase(
      loadInvestmentProductSeriesThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isSeriesDataLoading = false;
      }
    );
  },
});

export const {
  updateSelectedTab,
  updateInvestmentProductManagerData,
  updateInvestmentProductSeriesData,
} = investmentProductSlice.actions;

//Thunk functions
export const loadInvestmentProductManagerThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsManager,
  async ({
    investmentProductId,
    mode,
  }: {
    investmentProductId?: string;
    mode?: "view" | "edit";
  }) => {
    return await getInvestmentProductManager(investmentProductId, mode);
  },
);

export const loadInvestmentProductBrandingData = createAsyncThunk(
  APIExtRoutes.investmentProductsBrandingData,
  async (investmentProductId: string) => {
    return await getProductManagedBrandingInfo(investmentProductId);
  }
);

export const loadInvestmentProductSeriesThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsSeries,
  async ({
    investmentProductId,
    mode,
  }: {
    investmentProductId?: string;
    mode?: "view" | "edit";
  }) => {
    return await getInvestmentProductSeries(investmentProductId, mode);
  }
);

export default investmentProductSlice.reducer;
