import {
  SHBox,
  SHButton,
  SHContainer,
  SHDivider,
  SHIconLoading,
  SHRichTextEditor,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import { PageRoutes } from "@constants";
import { DateFormat, DateTimeFormat } from "@constants/format";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFeatureTemplate } from "@hooks/useFeatureTemplate";
import { useIsNew } from "@hooks/useIsNew";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar } from "@layouts/top-bar";
import {
  TemplateNameValidationStatus,
  CreateFeatureTemplateDTO,
  FeatureTemplateDTO,
} from "@models/feature-template/entities/featureTemplate";
import { FeatureTemplateStatus } from "@models/feature-template/enums/status";
import { useTheme } from "@mui/material";
import { FeatureTemplateSkeleton } from "@pages/platform-analysis/_feature-templates/_id/skeleton";
import { RootState } from "@redux/store";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router";
import { FeatureSelection } from "../../components/feature-selection";
import { featureTemplateValidators } from "./util";
import { checkFeatureTemplateUniqueName } from "@services/feature-template/featureTemplateService";
import { CheckSVG, CloseSVG } from "@components/svgs";
import { useNotification } from "@hooks/useNotification";

export const FeatureTemplateManagePage = () => {
  const isNew = useIsNew();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { notify } = useNotification();
  const { featureTemplateId } = useParams<{ featureTemplateId: string }>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { supplierData } = useSelector((state: RootState) => state.supplier);
  const { featureTemplateUI, featureTemplate } = useSelector(
    (state: RootState) => state.featureTemplate,
  );
  const timer = useRef<NodeJS.Timeout | null>(null);

  const [verifyFeatureTemplateName, setVerifyFeatureTemplateName] = useState<
    TemplateNameValidationStatus | "none" | "checking"
  >(isNew ? "none" : TemplateNameValidationStatus.NotExists);

  const {
    resetStore,
    setFeatureTemplate,
    loadFeatureTemplate,
    loadFeatureTemplateLOV,
    createFeatureTemplate,
    updateFeatureTemplate,
    updateFeatureTemplateStatus,
  } = useFeatureTemplate();

  const {
    watch,
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<FeatureTemplateDTO>({
    mode: "onChange",
    defaultValues: { ...new FeatureTemplateDTO() },
    resolver: yupResolver(featureTemplateValidators),
  });

  const [isChangingStatus, setIsChangingStatus] =
    useState<FeatureTemplateStatus | null>();
  const currentStatus = watch("status");

  const handleSubmitFeatureTemplate = async (
    formData: FeatureTemplateDTO | any,
  ) => {
    if (isNew) {
      await handleOnCreateFeatureTemplate(formData);
    } else {
      await handleOnUpdateFeatureTemplate(formData);
    }
  };

  const handleOnCreateFeatureTemplate = async (
    featureTemplate: CreateFeatureTemplateDTO,
  ) => {
    const res = await createFeatureTemplate(featureTemplate, true);
    if (res?.id) {
      reset(res);
      setFeatureTemplate(featureTemplate);
      navigate(
        generatePath(PageRoutes.featureTemplateDetail, {
          featureTemplateId: res?.id,
        }),
        {
          replace: true,
        },
      );
    }
  };

  const handleOnUpdateFeatureTemplate = async (
    featureTemplate: FeatureTemplateDTO,
  ) => {
    const res = await updateFeatureTemplate(featureTemplate);
    if (res) {
      reset(res);
      setFeatureTemplate(featureTemplate);
    }
  };

  const handleOnUpdateFeatureTemplateStatus = async (
    status: FeatureTemplateStatus,
  ) => {
    if (!featureTemplateId) return;
    setIsChangingStatus(status);
    const res = await updateFeatureTemplateStatus(featureTemplateId, status);
    setIsChangingStatus(null);
    if (res) {
      reset({ ...getValues(), status: res || status });
    }
  };

  const clearTimer = () => {
    if (timer.current) clearTimeout(timer.current);
  };

  const handleOnVerifyFeatureTemplateName = async (name: string | null) => {
    clearTimer();
    if (!name || name.trim().length === 0 || !user?.userMetadata?.supplier_id) {
      setVerifyFeatureTemplateName("none");
      return;
    }
    if (featureTemplate?.name === name) {
      setVerifyFeatureTemplateName(TemplateNameValidationStatus.NotExists);
      return;
    }
    setVerifyFeatureTemplateName("checking");
    timer.current = setTimeout(async () => {
      const { isSuccess, data, message } = await checkFeatureTemplateUniqueName(
        {
          id: featureTemplateId ?? "new",
          name: name,
          supplierId: user?.userMetadata?.supplier_id ?? "",
        },
      );

      if (!isSuccess || !data) {
        notify(message, {
          variant: "error",
          close: true,
        });
        return;
      }

      setVerifyFeatureTemplateName(data as TemplateNameValidationStatus);
    }, 300);
  };

  const renderVerifyFeatureTemplateName = () => {
    switch (verifyFeatureTemplateName) {
      case TemplateNameValidationStatus.NotExists:
        return <CheckSVG width={18} height={18} />;
      case TemplateNameValidationStatus.ExistsWithActive:
      case TemplateNameValidationStatus.ExistsWithArchived:
        return (
          <SHBox px={"4px"} py={"1px"}>
            <CloseSVG width={10} height={10} />
          </SHBox>
        );
      case "checking":
        return <SHIconLoading size={16} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isNew || !featureTemplateId) {
      loadFeatureTemplateLOV();
    } else {
      loadFeatureTemplate(featureTemplateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, featureTemplateId]);

  useEffect(() => {
    reset({ ...featureTemplate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureTemplate]);

  useEffect(() => {
    if (!supplierData) return;
    setValue("supplierId", supplierData.id);
    setValue("supplierName", supplierData.companyName);
    // eslint-disable-next-line
  }, [supplierData]);

  useEffect(() => {
    return () => {
      resetStore();
    };
    // eslint-disable-next-line
  }, []);

  if (featureTemplateUI?.isLoading) return <FeatureTemplateSkeleton />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <form>
        <SHStack spacing={3} sx={{ paddingY: 3 }}>
          <TopBar
            navigate={{ to: `${PageRoutes.platformAnalysis}/feature-template` }}
            title={
              <>
                <SHTypography variant="h1" sx={{ lineHeight: "120%" }}>
                  {watch("name") || "[Template name]"}
                </SHTypography>
                <SHTypography variant="h2" color={palette.text.secondary}>
                  by {watch("ownerName") || user?.name}
                </SHTypography>
              </>
            }
            tools={
              <SHStack direction={"row"} spacing={1}>
                <SHButton
                  variant="outlined"
                  size="extraMedium"
                  onClick={() =>
                    navigate(`${PageRoutes.platformAnalysis}/feature-template`)
                  }
                >
                  Cancel
                </SHButton>
                {!isNew && currentStatus !== FeatureTemplateStatus.Archived && (
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    isLoading={
                      isChangingStatus === FeatureTemplateStatus.Archived
                    }
                    disabled={!isValid || isSubmitting}
                    onClick={() =>
                      handleOnUpdateFeatureTemplateStatus(
                        FeatureTemplateStatus.Archived,
                      )
                    }
                  >
                    Archive
                  </SHButton>
                )}
                {!isNew && currentStatus === FeatureTemplateStatus.Archived && (
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    isLoading={
                      isChangingStatus === FeatureTemplateStatus.Active
                    }
                    disabled={!isValid || isSubmitting}
                    onClick={() =>
                      handleOnUpdateFeatureTemplateStatus(
                        FeatureTemplateStatus.Active,
                      )
                    }
                  >
                    Unarchive
                  </SHButton>
                )}
                <SHButton
                  variant="contained"
                  size="extraMedium"
                  disabled={!isValid || isSubmitting || !isDirty}
                  isLoading={isSubmitting}
                  onClick={handleSubmit(handleSubmitFeatureTemplate)}
                >
                  Save
                </SHButton>
              </SHStack>
            }
          />
          <SHStack
            spacing={1}
            sx={{ width: { xs: "100%", lg: "50%", margin: "25px 0 20px 0" } }}
          >
            <SHTypography variant="subtitle1">
              Feature template details
            </SHTypography>
            <SHTypography variant="body2">
              You can use feature templates to save time during the feature
              selection step of platform analyses
            </SHTypography>
            <SHStack direction={"row"} alignItems={"center"} spacing="15px">
              <SHTypography variant="body2" colorVariant="third">
                {`Last edit: ${watch("ownerName") || user?.name} on 
                ${format(
                  watch("lastModifiedDate") || new Date(),
                  DateTimeFormat,
                )}`}
              </SHTypography>
              <SHDivider orientation="vertical" sx={{ height: "14px" }} />
              <SHTypography variant="body2" colorVariant="third">
                {` Data valid:
                ${format(watch("createDate") || new Date(), DateFormat)}`}
              </SHTypography>
            </SHStack>
          </SHStack>
          <SHDivider />
          <FieldGroup
            title="Description"
            subTitle="Provide details to help identify this feature template"
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <SHTextField
                    {...field}
                    hasCounter
                    maxLength={50}
                    required
                    label="Name of template"
                    placeholder="Enter name of template"
                    error={!!errors.name}
                    helperText={
                      errors?.name?.message ??
                      (verifyFeatureTemplateName ===
                      TemplateNameValidationStatus.ExistsWithActive
                        ? "This template name already exists. Please enter a new name."
                        : verifyFeatureTemplateName ===
                          TemplateNameValidationStatus.ExistsWithArchived
                        ? "Template name already exists (archived template). Please enter a new name or unarchive the template."
                        : null)
                    }
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      handleOnVerifyFeatureTemplateName(event.target.value);
                    }}
                    InputProps={{
                      sx: {
                        "&>input": {
                          pr: "32px !important",
                        },
                      },
                      endAdornment: (
                        <SHStack
                          sx={{
                            position: "absolute",
                            right: "8px",
                          }}
                        >
                          {renderVerifyFeatureTemplateName()}
                        </SHStack>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <SHRichTextEditor
                    {...field}
                    label="Notes"
                    placeHolder="Enter notes for this feature template"
                    maxLength={3000}
                    height={165}
                  />
                )}
              />
            </SHStack>
          </FieldGroup>
          <Controller
            name="sections"
            control={control}
            render={({ field }) => <FeatureSelection {...field} />}
          />
        </SHStack>
      </form>
    </SHContainer>
  );
};
