import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { RegionDTO } from "@models/public/region";
import { APIGatewayService, handleErrorProxy } from "@services/shService";

export async function getRegions(): Promise<APIResponse<RegionDTO[]>> {
  const response = await APIGatewayService.get<RegionDTO[]>(
    APIExtRoutes.regions,
  );
  return handleErrorProxy(response);
}
