import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { AnalysisFeeCalculatorDTO } from "@models/platform-analysis/entities/steps/fee";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";
import { compileFeeCalculator } from "./util";

export async function getFeeCalculatorStep(
  platformAnalysisId: string,
): Promise<APIResponse<AnalysisFeeCalculatorDTO>> {
  const response = await APIGatewayService.get<AnalysisFeeCalculatorDTO>(
    generatePath(APIExtRoutes.platformAnalysisFeeCalculator, {
      platformAnalysisId,
    }),
  );
  if (response.data) {
    compileFeeCalculator(response.data);
  }
  return handleErrorProxy(response);
}

export async function putFeeCalculatorStep(
  platformAnalysisId: string,
  formData: AnalysisFeeCalculatorDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.platformAnalysisFeeCalculator, {
      platformAnalysisId,
    }),
    formData,
  );
  return handleErrorProxy(response, "Changes have been saved.");
}
