import { HighlightType } from "@models/configuration";
import { RateCardDataDTO } from "@models/rate-card/entities/rateCard";
import { AnalysisVariable } from "@models/platform-analysis/enums/variableName";
import { FeeFieldType } from "../enums/feeFieldType";
import { FeeValueType } from "../enums/feeValueType";
import { ProductDataStatus } from "../enums/productDataStatus";

export class ConfigurationFeeDTO {
  generalFeeSections?: ConfigurationFeeSectionDTO[];
  standardFeeSections?: ConfigurationFeeSectionDTO[];
  otherFeeSections?: ConfigurationFeeSectionDTO[];
}
export class SubProductDTO extends ConfigurationFeeDTO {
  id?: string;
  productId: string = "";
  name?: string;
  publishedName?: string;
  status: "Active" | "Archived" = "Active";
  flagType?: "Draft" | "Current";
  highlightType?: HighlightType;
  dataStatus?: ProductDataStatus;
  order?: number;
  createdDate?: Date;
  lastModifiedDate?: Date;
  lastUpdatedUserId?: string;
  lastUpdatedUserName?: string;
}

export interface ConfigurationFeeSectionDTO {
  id: string;
  name: string;
  description?: string;
  order: number;
  sectionType?: string;
  flagType?: "Draft" | "Current";
  highlightType?: HighlightType;
  dataStatus?: ProductDataStatus;
  features?: ConfigurationFeeFeatureDTO[];
  isModified?: boolean;
}

export interface ConfigurationFeeFeatureDTO {
  id: string;
  name: string;
  description?: string;
  order: number;
  fieldType: FeeFieldType;
  lovData?: LovDTO[];
  flagType?: "Draft" | "Current";
  highlightType?: HighlightType;
  variableNameIDPS?: string;
  variableNamePension?: string;
  variableNameOther?: string;
  variableNameGeneral?: string;
  showAndAllowUpdateIDPS?: boolean;
  showAndAllowUpdatePension?: boolean;
  showAndAllowUpdateOther?: boolean;
  dataStatus?: ProductDataStatus;
  subProductData?: SubProductDataDTO;
  rateCardData?: RateCardDataDTO;
  isModified?: boolean;
}
export interface SubProductDataDTO {
  id?: string;
  subProductId?: string;
  configurationFeeFeatureId?: string;
  flagType?: "Draft" | "Current";
  highlightType?: HighlightType;
  dataStatus?: ProductDataStatus;
  idpsValue?: string | null;
  pensionValue?: string | null;
  otherValue?: string | null;
  publishedIDPSValue?: string | null;
  publishedPensionValue?: string | null;
  publishedOtherValue?: string | null;
}

export interface LovDTO {
  id: string;
  name: string;
  isDefault?: boolean;
}

export type DependFieldsMapping = {
  [key in AnalysisVariable]?: {
    modifiedField?: string;
    valueField?: string;
    showHideField?: string;
  };
};

export type SubProductNameMapping = {
  [key in number]?: string;
};

export type SubProductHighlightTypeMapping = {
  [key in number]?: SectionTypeHighlightTypeMapping;
};

export type SectionTypeHighlightTypeMapping = {
  [key in
    | "standardFeeSections"
    | "otherFeeSections"]?: SectionHighlightTypeMapping;
};

export type SectionHighlightTypeMapping = {
  [key in number]?: FeatureHighlightTypeMapping;
};

export type FeatureHighlightTypeMapping = {
  [key in number]?: ValueTypeMapping;
};

export type ValueTypeMapping = {
  [key in FeeValueType]?: boolean;
};
