import {
  SupplierLicenseDTO,
  SupplierLicenseMassUpdateDTO,
} from "@models/supplier/entities/licenses";

export const toSupplierLicenseMassUpdateDTO = (
  licenses: SupplierLicenseDTO[],
): SupplierLicenseMassUpdateDTO => {
  return {
    supplierLicenses: licenses.map((license) => ({
      supplierLicenseMapId: license.supplierLicenseMapId,
      licenseFreeCount: license.licenseFreeCount,
      licensePaidCount: license.licensePaidCount,
    })),
    subscriptionBegin: licenses?.[0]?.subscriptionBegin,
    subscriptionEnd: licenses?.[0]?.subscriptionEnd,
  };
};
