import { Components, Theme } from "@mui/material";
import { hexToRGBA } from "@utils";

export const muiTableBodyTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiTableBody"> => ({
  MuiTableBody: {
    styleOverrides: {
      root: {
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: hexToRGBA(theme.palette.primary[50], 0.3),
        borderStyle: "solid",
        "&::before": {
          display: "block",
          lineHeight: "13px",
          textIndent: -99999,
          content: '"@"',
        },
      },
    },
  },
});
