import { SHButton, SHDialog, SHStack } from "@components/design-systems";
import { PageRoutes } from "@constants/routes";
import { useNavigate } from "react-router-dom";

const AccessDeniedDialog: React.FC = () => {
  const navigate = useNavigate();

  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      header="Access denied"
      body="You don't have permission to access this page."
      footer={
        <SHStack justifyContent="center" alignItems="center">
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={() => navigate(PageRoutes.home)}
          >
            Go to home
          </SHButton>
        </SHStack>
      }
    />
  );
};

export default AccessDeniedDialog;
