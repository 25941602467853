import { useNotification } from "@hooks/useNotification";
import {
  AdviserUserDTO,
  CreateAdviserUserDTO,
} from "@models/practice/entities/practiceUser";
import { UserStatus, UserStatusAction } from "@models/users/enums/status";

import {
  completeProfilePracticeUserThunk,
  createPracticeUserThunk,
  loadPracticeUserThunk,
  resendInviteEmailAdviserUserThunk,
  resetPasswordAdviserUserThunk,
  setPracticeUserAction,
  updatePracticeUserStatusThunk,
  updatePracticeUserThunk,
} from "@redux/slices/practice-user";

import { RootState, useAppDispatch } from "@redux/store";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

export const usePracticeUser = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { auth0Id } = useParams<{ auth0Id: string }>();
  const { practiceUser, ui } = useSelector(
    (state: RootState) => state.practiceUser,
  );
  const status = useMemo(() => {
    return {
      isActive: practiceUser?.status === UserStatus.Active,
      isDisabled: practiceUser?.status === UserStatus.Disabled,
      isArchived: practiceUser?.status === UserStatus.Archived,
      isPending: practiceUser?.status === UserStatus.Pending,
    };
  }, [practiceUser?.status]);

  const setPracticeUser = (practiceUser?: AdviserUserDTO) => {
    dispatch(setPracticeUserAction(practiceUser));
  };

  const loadPracticeUser = async (adviserFirmId?: string, auth0Id?: string) => {
    const response = await dispatch(
      loadPracticeUserThunk({ adviserFirmId, auth0Id }),
    ).unwrap();

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const createPracticeUser = async (practiceUser: CreateAdviserUserDTO) => {
    const response = await dispatch(
      createPracticeUserThunk(practiceUser),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updatePracticeUser = async ({
    auth0Id,
    practiceUser,
  }: {
    auth0Id: string;
    practiceUser: AdviserUserDTO;
  }) => {
    const response = await dispatch(
      updatePracticeUserThunk({ auth0Id, practiceUser }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updatePracticeUserStatus = async ({
    adviserFirmId,
    auth0Id,
    status,
    statusAction,
  }: {
    adviserFirmId: string;
    auth0Id: string;
    status: UserStatus;
    statusAction: UserStatusAction
  }) => {

    const response = await dispatch(
      updatePracticeUserStatusThunk({adviserFirmId, auth0Id, status, statusAction }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const completeProfilePracticeUser = async ({
    auth0Id,
    practiceUser,
  }: {
    auth0Id: string;
    practiceUser: AdviserUserDTO;
  }) => {
    const response = await dispatch(
      completeProfilePracticeUserThunk({ auth0Id, practiceUser }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const resetPasswordAdviserUser = async (
    adviserFirmId?: string,
    auth0Id?: string,
  ) => {
    const response = await dispatch(
      resetPasswordAdviserUserThunk({adviserFirmId, auth0Id}),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const resendInviteEmailAdviserUser = async(
    adviserFirmId?: string,
    auth0Id?: string
  ) => {
      const response = await dispatch(
        resendInviteEmailAdviserUserThunk({adviserFirmId, auth0Id}),
      ).unwrap();
      if (!response.isSuccess || !response.data) {
        notify(response.message, {
          variant: "error",
          close: true,
        });
        return;
      }
      notify(response.message, {
        variant: "success",
        close: true,
      });
      return response.data;
  }

  return {
    auth0Id,
    practiceUser,
    ui,
    status,
    setPracticeUser,
    loadPracticeUser,
    createPracticeUser,
    updatePracticeUser,
    updatePracticeUserStatus,
    completeProfilePracticeUser,
    resetPasswordAdviserUser,
    resendInviteEmailAdviserUser
  };
};
