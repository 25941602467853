import { SHIconLoading } from "@components/design-systems";
import { RollBackSVG } from "@components/svgs";
import { IconButton, useTheme } from "@mui/material";
export interface RollBackButtonProps {
  width?: string;
  height?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  disabled?: boolean;
  borderColor?: string;
  backgroundColor?: string;
  [key: string]: any;
}
export const RollBackButton = ({
  width = "30px",
  height = "30px",
  isLoading = false,
  disabled = false,
  borderColor,
  backgroundColor,
  ...others
}: any) => {
  const { shadows, palette } = useTheme();
  return (
    <IconButton
      {...others}
      disableRipple
      disabled={disabled}
      sx={{
        width,
        height,
        p: 0,
        borderStyle: "solid",
        borderWidth: "1px",
        backgroundColor: backgroundColor ?? palette.common.white,
        borderColor: borderColor ?? palette.common.black,
        boxShadow: shadows[1],
        "&:hover": {
          borderColor: palette.primary[800],
          boxShadow: "none",
        },
        "&:active": {
          backgroundColor: palette.primary.dark,
          borderColor: palette.common.black,
          boxShadow: `inset 0 4px 4px ${palette.primary[900]}`,
          "&>svg>path": {
            fill: palette.common.white,
          },
        },
        "&:disabled": {
          border: `1px solid ${palette.text.disabled}`,
          backgroundColor: palette.common.white,
          "&>svg>path": {
            fill: palette.text.disabled,
          },
        },
      }}
    >
      {isLoading ? <SHIconLoading size={15} /> : <RollBackSVG />}
    </IconButton>
  );
};
