import { PlusButton } from "@components/buttons/plus";
import { SHStack } from "@components/design-systems";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import { StatusBadge } from "@components/status-badge";
import { APIExtRoutes, PageRoutes } from "@constants/routes";
import { useAppParams } from "@hooks/useAppParams";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { ProductDTO } from "@models/product/entities/product";
import PlatformProfileListView from "@pages/platform-profiles/components/list-view";
import { RootState } from "@redux/store";
import { nameOfFactory } from "@utils";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router";

const nameOf = nameOfFactory<ProductDTO>();
export const SupplierProduct = () => {
  const navigate = useNavigate();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { supplierData } = useSelector((state: RootState) => state.supplier);
  const { canViewSupplierProductList, isSupplierGroup, isSuperAdmin } =
    useUserPermissions();
  const { isMySupplier } = useAppParams();
  const accessDenied =
    !canViewSupplierProductList || (isSupplierGroup && !isMySupplier);
  if (accessDenied) return <AccessDeniedDialog />;
  return (
    <SHStack>
      <TopBarContainer>
        <TopBar
          title={supplierData?.companyName}
          customBadge={
            supplierData?.status ? (
              <StatusBadge status={supplierData?.status} />
            ) : undefined
          }
        />
      </TopBarContainer>

      <PlatformProfileListView
        oDataUrl={generatePath(APIExtRoutes.odataSupplierProducts, {
          supplierId,
        })}
        emptyMessage={"No products. Click on “New product” to add!"}
        defaultState={{
          ...DefaultDataState,
          columnFilters: [{ id: "supplierId", value: [supplierId] }],
          sorting: [{ id: nameOf("productName"), desc: false }],
        }}
        rightToolbar={
          isSuperAdmin && (
            <PlusButton
              onClick={() =>
                navigate(
                  generatePath(PageRoutes.supplierProducts, {
                    supplierId: supplierId,
                    productId: "new",
                  }),
                )
              }
            >
              New product
            </PlusButton>
          )
        }
      />
    </SHStack>
  );
};
