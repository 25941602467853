import { TABLE_CELL_WIDTH } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { View } from "@react-pdf/renderer";

interface PDFReviewTableCellProps {
  children: React.ReactNode;
  color?: "white" | "gray" | "transparent";
  alignItems?: "flex-start" | "center";
  justifyContent?: "flex-start" | "center";
  width?: string | number;
  hasPadding?: boolean;
}
export const PDFReviewTableCell = ({
  children,
  color,
  alignItems = "center",
  justifyContent = "center",
  width = TABLE_CELL_WIDTH,
  hasPadding = true,
}: PDFReviewTableCellProps) => {
  return (
    <View
      style={{
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: hasPadding ? 5 : 0,
        paddingBottom: hasPadding ? 5 : 0,
        width: width,
        maxWidth: width,
        flexDirection: "column",
        justifyContent: justifyContent,
        alignItems: alignItems,
        borderColor: "#7C8493",
        borderRightWidth: 1,
        backgroundColor: color
          ? color === "white"
            ? "#FFFFFF"
            : "#e0e2e5"
          : "transparent",
      }}
    >
      {children}
    </View>
  );
};
