import { SHButton } from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { useProduct } from "@hooks/useProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { ProductListDTO } from "@models/product/entities/product";
import { SubProductDTO } from "@models/product/entities/subProduct";
import PublishDraftDialog from "@pages/suppliers/_id/_products/_id/components/dialogs/publish-draft";
import { RootState } from "@redux/store";
import { publishDraft } from "@services/product/productsService";
import { useState } from "react";
import { useSelector } from "react-redux";

export interface ApproveButtonProps {
  isFeesTab?: boolean;
  productId?: string;
  resetForm: (product: ProductListDTO | SubProductDTO[]) => void;
}

export const ApproveButton = ({
  isFeesTab,
  productId,
  resetForm,
}: ApproveButtonProps) => {
  const { notify } = useNotification();
  const { loadBannerInfo, refreshProductData, refreshSubProductData } =
    useProduct();
  const { canApproveProduct } = useUserPermissions();

  const {
    productUI: { isEditMode },
  } = useSelector((state: RootState) => state.product);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPublishDraftDialog, setShowPublishDraftDialog] = useState(false);

  const isVisible = isEditMode && canApproveProduct;

  const handleOnApprove = async () => {
    if (!productId) return;
    setIsSubmitting(true);
    const { isSuccess, message } = await publishDraft(productId);
    setIsSubmitting(false);
    if (isSuccess) {
      setShowPublishDraftDialog(false);
      notify(message, {
        variant: "success",
        close: true,
      });
      if (isFeesTab) {
        refreshSubProductData(productId, "edit").then((newData) => {
          if (newData) resetForm(newData as SubProductDTO[]);
          loadBannerInfo(productId);
        });
      } else {
        refreshProductData("edit").then((data) => {
          if (data) resetForm(data as ProductListDTO);
          loadBannerInfo(productId);
        });
      }
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <>
      {isVisible && (
        <SHButton
          variant="contained"
          size="extraMedium"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => setShowPublishDraftDialog(true)}
        >
          Approve
        </SHButton>
      )}
      {showPublishDraftDialog && (
        <PublishDraftDialog
          isSubmitting={isSubmitting}
          onSubmit={handleOnApprove}
          onClose={() => setShowPublishDraftDialog(false)}
        />
      )}
    </>
  );
};
