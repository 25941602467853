import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useIsNew = (): boolean => {
  const { pathname } = useLocation();
  return useMemo(() => {
    const routes = pathname.split("/");
    if (routes[routes.length - 1] === "new") return true;
    return false;
  }, [pathname]);
};
