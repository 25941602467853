import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const PostItemSkeleton = () => {
    return (
        <SHStack spacing={2} paddingBottom={"25px"} >
            <SHStack direction="row" spacing={1.5}>
                <SHSkeleton height={46} width={46} />
                <SHStack spacing={1} width={"100%"}>
                    <SHStack direction={"row"} justifyContent="space-between">
                        <SHStack height={46} direction={"row"} alignItems={"center"} marginRight={3}>
                            <SHSkeleton height={27.6} width={400} />
                        </SHStack>
                        <SHStack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={"10px"}
                        >
                            <SHSkeleton height={32} width={64} sx={{ borderRadius: 25 }} />
                            <SHSkeleton height={32} width={87} sx={{ borderRadius: 25 }} />
                            <SHSkeleton height={32} width={78} sx={{ borderRadius: 25 }} />
                        </SHStack>
                    </SHStack>
                    <SHStack
                        direction={"row"}
                        spacing={1}
                        width={"100%"}
                        alignItems={"center"}
                    >
                        <SHSkeleton height={20} width={300} />
                    </SHStack>
                    <SHStack spacing={1}>
                        <SHSkeleton height={100} width={"100%"} />
                    </SHStack>
                </SHStack>
            </SHStack>
        </SHStack>
    );
};