import {
    SHStack,
    SHTable,
    SHTableBody,
    SHTableCell,
    SHTableCellProps,
    SHTableContainer,
    SHTableHead,
    SHTableRow,
    SHTypography,
} from "@components/design-systems";
import { StarSVG } from "@components/svgs";
import {
    AnalysisFeatureIncludeDTO,
    AnalysisFeatureWithUniqueDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import React from "react";
  
  export interface BusinessMetricsIncludeTableProps {
    businessMetricInclude?: AnalysisFeatureIncludeDTO[] | null;
  }
  export const BusinessMetricsIncludeTable = ({
    businessMetricInclude,
  }: BusinessMetricsIncludeTableProps) => {
    return (
      <SHStack alignItems={"flex-end"} spacing={"15px"}>
        <SHTableContainer>
          <SHTable>
            <SHTableHead>
              <SHTableRow>
                <BorderedCell isHeader width={"30%"}>
                  Business metric group
                </BorderedCell>
                <BorderedCell isHeader width={"70%"}>
                  Business metrics
                </BorderedCell>
              </SHTableRow>
            </SHTableHead>
            <SHTableBody>
              {businessMetricInclude?.length ? (
                businessMetricInclude?.map((row, index) => (
                  <SHTableRow key={index} sx={{ borderBottomWidth: 1 }}>
                    <BorderedCell width={"30%"}>
                      <SHTypography variant="body1">
                        {row.sectionName}
                      </SHTypography>
                    </BorderedCell>
                    <BorderedCell width={"70%"}>
                      <SHStack direction={"row"} flexWrap={"wrap"}>
                        {row?.features?.map((feature, index) => (
                          <FeatureWithUnique
                            isLasted={index + 1 === row?.features.length}
                            key={feature.featureId}
                            feature={feature}
                          />
                        ))}
                      </SHStack>
                    </BorderedCell>
                  </SHTableRow>
                ))
              ) : (
                <SHTableRow>
                  <BorderedCell colSpan={3}>
                    <SHTypography
                      variant="body1"
                      textAlign={"center"}
                      colorVariant="third"
                    >
                      Don't have features included
                    </SHTypography>
                  </BorderedCell>
                </SHTableRow>
              )}
            </SHTableBody>
          </SHTable>
        </SHTableContainer>
      </SHStack>
    );
  };
  
  interface BorderedCellProps extends SHTableCellProps {
    children: React.ReactNode;
    isHeader?: boolean;
  }
  const BorderedCell = ({ children, isHeader, ...others }: BorderedCellProps) => {
    const { palette } = useTheme();
    return (
      <SHTableCell
        sx={{
          height: "50px",
          borderLeft: `solid 1px ${
            isHeader ? palette.divider : hexToRGBA(palette.primary[50], 0.3)
          }`,
        }}
        {...others}
      >
        {isHeader ? (
          <SHTypography variant="subtitle1" fontWeight={700} lineHeight={"120%"}>
            {children}
          </SHTypography>
        ) : (
          children
        )}
      </SHTableCell>
    );
  };
  
  interface FeatureWithUniqueProps {
    feature: AnalysisFeatureWithUniqueDTO;
    isLasted?: boolean;
  }
  const FeatureWithUnique = ({ feature, isLasted }: FeatureWithUniqueProps) => {
    return (
      <SHStack flexDirection={"row"} alignItems={"center"}>
        <SHTypography variant="body1" colorVariant="third">
          {feature.featureName}
        </SHTypography>
        {feature.isEssential && (
          <StarSVG
            width={"13px"}
            height={"13px"}
            style={{ marginLeft: "2px", marginBottom: "2px" }}
          />
        )}
        {!isLasted && (
          <SHTypography variant="body1" colorVariant="third" sx={{ mr: "5px" }}>
            ,
          </SHTypography>
        )}
      </SHStack>
    );
  };
  