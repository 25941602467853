import { CheckSquareSVG } from "@components/svgs/check-square";
import { CrossSquareSVG } from "@components/svgs/cross-square";
import { SHStack } from "../sh-stack";
import { SHTypography } from "../sh-typography";
export interface SHBooleanIconProps {
  isTrue: boolean;
  label?: string | number;
}
export const SHBooleanIcon = ({
  label,
  isTrue,
  ...props
}: SHBooleanIconProps) => {
  return (
    <SHStack direction="row" gap={"5px"} alignItems={"center"} {...props}>
      {isTrue ? <CheckSquareSVG /> : <CrossSquareSVG />}
      {label ?? <SHTypography variant="body1">{label}</SHTypography>}
    </SHStack>
  );
};
