import * as Yup from "yup";

export const practiceUserValidators = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .max(100, "First name must be less than 100 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .max(100, "Last name must be less than 100 characters"),
  practiceRole: Yup.string().required("Practice role is required"),
  arnNumber: Yup.number().typeError("ARN number is required"),
});
