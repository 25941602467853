import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { DetailFamilyGroupDTO, FamilyGroupDTO } from "@models/family-group/entities/familyGroup";
import { ClientDTO } from "@models/reviews/entities/client";
import { ClientStatus } from "@models/reviews/enums/status";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getFamilyGroup(
  familyGroupId: string,
): Promise<APIResponse<DetailFamilyGroupDTO>> {
  const response = await APIGatewayService.get<ClientDTO>(
    `${APIExtRoutes.familyGroups}/${familyGroupId}`,
  );
  return handleErrorProxy(response);
}

export async function postFamilyGroup(
  data?: FamilyGroupDTO,
): Promise<APIResponse<FamilyGroupDTO>> {
  const response = await APIGatewayService.post<FamilyGroupDTO>(
    APIExtRoutes.familyGroups,
    data,
  );
  return handleErrorProxy(response, "Family group has been created.");
}

export async function putFamilyGroup(
  data?: FamilyGroupDTO,
): Promise<APIResponse<FamilyGroupDTO>> {
  const response = await APIGatewayService.put<FamilyGroupDTO>(
    APIExtRoutes.familyGroups,
    data,
  );
  return handleErrorProxy(response, "Family group has been updated.");
}

export async function putFamilyGroupStatus(
  familyGroupId: string,
  status: ClientStatus,
): Promise<APIResponse<ClientStatus>> {
  const response = await APIGatewayService.put<ClientStatus>(
    generatePath(APIExtRoutes.familyGroupStatus, {
      familyGroupId,
      status,
    }),
  );

  return handleErrorProxy(response, "Your change has been updated.");
}
