import { SHBox } from "@components/design-systems";
import { generateUUID } from "@utils";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const DEFAULT_PLACEMENT_ID = generateUUID();

export interface StepperPlacementProps {
  id?: string;
}
export interface StepperContainerProps {
  id?: string;
  placementId?: string;
  children?: React.ReactNode;
  isUsePortal?: boolean;
}

export const StepperPlacement = ({
  id = DEFAULT_PLACEMENT_ID,
}: StepperPlacementProps) => (
  <SHBox sx={{ width: "100%", height: "100%" }} id={id} />
);

export const StepperContainer = ({
  id = "analysis-tool-bar-container",
  placementId = DEFAULT_PLACEMENT_ID,
  children,
  isUsePortal = false,
}: StepperContainerProps) => {
  const [placement, setPlacement] = useState(
    document.getElementById(placementId),
  );

  useEffect(() => {
    setPlacement(document.getElementById(placementId));
  }, [placementId]);

  if (!isUsePortal) return <>{children}</>;
  if (!placement) return null;
  return createPortal(
    <SHBox sx={{ width: "100%", height: "100%" }} id={id}>
      {children}
    </SHBox>,
    placement,
  );
};
