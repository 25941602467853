import { SHStack } from "@components/design-systems/sh-stack";
import { FormControlLabel, FormControlLabelProps } from "@mui/material";
import { SHBox } from "../sh-box";
import { SHTypography } from "../sh-typography";

export interface SHFormControlLabelProps extends FormControlLabelProps {
  subLabel?: string;
  postfixLabel?: React.ReactNode;
  size?: "medium" | "small";
}
export const SHFormControlLabel = (
  props: SHFormControlLabelProps,
): JSX.Element => {
  const {
    subLabel,
    postfixLabel,
    label,
    size = "medium",
    disabled,
    ...other
  } = props;
  return (
    <SHBox>
      <FormControlLabel
        label={
          <SHStack
            direction="row"
            spacing={0.5}
            paddingTop={size === "medium" ? "8px" : "2px"}
          >
            {typeof label === "string" ? (
              <SHTypography
                variant={subLabel ? "subtitle2" : "body2"}
                disabled={disabled}
              >
                {label}
              </SHTypography>
            ) : (
              label
            )}
            {postfixLabel && postfixLabel}
          </SHStack>
        }
        style={{ marginTop: 3 }}
        disabled={disabled}
        {...other}
      />
      {subLabel && (
        <SHTypography
          variant="body2"
          data-testid="sh-form-control-sub-label"
          disabled={disabled}
          colorVariant="third"
          sx={{
            paddingLeft: 3.8,
          }}
        >
          {subLabel}
        </SHTypography>
      )}
    </SHBox>
  );
};
