import { CollapseSubGroup } from "@layouts/platform-analysis/collapse-sub-group";
import { GroupSwiperCarousel } from "@layouts/platform-analysis/group-swiper-carousel";
import { TransferSelectFeatureList } from "@layouts/platform-analysis/transfer-select-feature-list";
import { AnalysisConfigurationFeatureSectionDTO } from "@models/platform-analysis/entities/steps/feature";
import { ConfirmSelectAllDialog } from "@pages/platform-analysis/_id/steps/feature/selection/components/dialogs/confirm-select-all";
import { useMemo, useState } from "react";

export interface FeatureSectionProps {
  value?: AnalysisConfigurationFeatureSectionDTO[];
  onChange: (
    features: AnalysisConfigurationFeatureSectionDTO[] | undefined,
  ) => void;
}
export const FeatureSelection = ({ value, onChange }: FeatureSectionProps) => {
  // const groups = value ?? [];
  const [showConfirmSelectAllDialog, setShowConfirmSelectAllDialog] =
    useState(false);

  const groups = useMemo(() => {
    return value ?? [];
  }, [value]);

  const selectedGroup = useMemo(() => {
    return groups?.find((group) => group?.isSelected);
  }, [groups]);

  const subGroups = useMemo(() => {
    return selectedGroup?.subSections;
  }, [selectedGroup]);

  const handleOnSelectGroup = (groupId?: string) => {
    if (!groupId) return;
    const newGroups = groups?.map((group) => {
      return {
        ...group,
        isSelected: group.id === groupId,
      };
    });
    onChange(newGroups);
  };

  // Handle logics
  const handleOnSelectDeselectFeature = (
    subGroupId?: string,
    featureId?: string,
    isDeselect?: boolean,
  ) => {
    const newGroups = groups?.map((group) => {
      if (group?.id !== selectedGroup?.id) return group;
      return {
        ...group,
        subSections: group?.subSections?.map((subGroup) => {
          if (subGroup.id !== subGroupId) return subGroup;
          return {
            ...subGroup,
            features: subGroup?.features?.map((feature) => {
              if (feature.id !== featureId) return feature;
              return {
                ...feature,
                isSelected: !isDeselect,
              };
            }),
          };
        }),
        totalSelectedFeature: isDeselect
          ? group.totalSelectedFeature - 1
          : group.totalSelectedFeature + 1,
      };
    });
    onChange(newGroups);
  };

  const handleOnSelectDeselectAll = (
    subGroupId?: string,
    totalFeatures?: number,
    isDeselect?: boolean,
  ) => {
    const newFeatureGroups = groups?.map((group) => {
      if (group?.id !== selectedGroup?.id) return group;
      return {
        ...group,
        subSections: group?.subSections?.map((subGroup) => {
          if (subGroup.id !== subGroupId) return subGroup;
          return {
            ...subGroup,
            features: subGroup?.features?.map((feature) => {
              return {
                ...feature,
                isSelected: !isDeselect,
              };
            }),
          };
        }),
        totalSelectedFeature: !isDeselect
          ? group.totalSelectedFeature + (totalFeatures ?? 0)
          : group.totalSelectedFeature - (totalFeatures ?? 0),
      };
    });
    onChange(newFeatureGroups);
  };

  const handleOnSelectDeselectAllGroupFeatures = () => {
    if (selectedGroup?.totalSelectedFeature !== selectedGroup?.totalFeature) {
      if (selectedGroup?.totalSelectedFeature !== 0) {
        setShowConfirmSelectAllDialog(true);
      } else {
        handleOnChangeSelectedAllGroupFeature(true);
      }
    } else {
      handleOnChangeSelectedAllGroupFeature(false);
    }
  };

  const handleOnChangeSelectedAllGroupFeature = (isSelected?: boolean) => {
    const newGroups = groups?.map((group) => {
      if (group?.id !== selectedGroup?.id) return group;
      return {
        ...group,
        subSections: group?.subSections?.map((subGroup) => {
          return {
            ...subGroup,
            features: subGroup?.features?.map((feature) => {
              return {
                ...feature,
                isSelected: isSelected,
              };
            }),
          };
        }),
        totalSelectedFeature: isSelected ? group.totalFeature : 0,
      };
    }) as AnalysisConfigurationFeatureSectionDTO[];
    onChange(newGroups);
  };

  // Handle renders
  const renderGroupCarousel = () => {
    return (
      <GroupSwiperCarousel
        groups={groups}
        handleOnSelect={handleOnSelectGroup}
        handleOnSelectDeselectAll={handleOnSelectDeselectAllGroupFeatures}
      />
    );
  };

  const renderSubGroupCollapse = () => {
    return subGroups?.map((subGroup, index) => (
      <CollapseSubGroup key={index} subSection={subGroup} isExpanded={true}>
        <TransferSelectFeatureList
          marginY={3}
          subGroupId={subGroup.id}
          features={subGroup?.features}
          handleOnSelectDeselect={handleOnSelectDeselectFeature}
          handleOnSelectDeselectAll={handleOnSelectDeselectAll}
        />
      </CollapseSubGroup>
    ));
  };

  if (!groups) return <></>;

  return (
    <>
      {renderGroupCarousel()}
      {renderSubGroupCollapse()}
      {showConfirmSelectAllDialog && (
        <ConfirmSelectAllDialog
          totalSelectedFeature={selectedGroup?.totalSelectedFeature}
          onCancel={() => setShowConfirmSelectAllDialog(false)}
          onSelectAll={() => {
            handleOnChangeSelectedAllGroupFeature(true);
            setShowConfirmSelectAllDialog(false);
          }}
        />
      )}
    </>
  );
};
