import AccessDeniedDialog from "@components/dialogs/access-denied";
import { useIsNew } from "@hooks/useIsNew";
import { useProduct } from "@hooks/useProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { useSupplier } from "@hooks/useSupplier";
import { RootState } from "@redux/store";
import {
  resetProduct,
  updateReleaseName,
  updateShowViewReleaseLog,
} from "@redux/slices/product";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ManageProductEdit } from "./edit";
import { ManageProductView } from "./view";

export const ManageProduct = () => {
  const dispatch = useDispatch();
  const {
    productUI: { isEditMode },
  } = useSelector((state: RootState) => state.product);
  const isNew = useIsNew();
  const { productId } = useParams<{ productId: string }>();
  const { supplierId } = useParams<{ supplierId: string }>();
  const [searchParams] = useSearchParams();
  const versionId = searchParams?.get("version") ?? undefined;
  const configurationVersionId = searchParams?.get("configurationVersion") ?? undefined;
  const { updateProductGroups } = useProduct();
  const { loadSupplier } = useSupplier();
  const { user, isSupplierBasic, canViewProduct } = useUserPermissions();
  const accessDenied = isSupplierBasic
    ? user?.userMetadata?.supplier_id !== supplierId
    : !canViewProduct;

  useEffectOnce(() => {
    return () => {
      dispatch(resetProduct());
    };
  });

  useEffect(() => {
    updateProductGroups(productId ?? "new", versionId ?? "0", configurationVersionId ?? "0");
    if (!versionId) {
      dispatch(updateShowViewReleaseLog(false));
      dispatch(updateReleaseName(undefined));
    }
    // eslint-disable-next-line
  }, [productId, versionId, configurationVersionId]);

  useEffect(() => {
    if (supplierId && (isNew || isEditMode)) {
      loadSupplier(supplierId);
    }
    // eslint-disable-next-line
  }, [supplierId]);

  if (accessDenied) return <AccessDeniedDialog />;

  if (isNew || isEditMode) return <ManageProductEdit />;
  return <ManageProductView />;
};
