import { useNotification } from "@hooks/useNotification";
import { GetUserInfoDTO } from "@models/users/entities/user";
import {
  resentInviteEmailUserThunk,
  resetPasswordUserThunk,
  updateUser,
} from "@redux/slices/auth";

import { RootState, useAppDispatch } from "@redux/store";
import { useSelector } from "react-redux";

export const useUser = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { user, ui } = useSelector((state: RootState) => state.auth);

  const setUser = (user: GetUserInfoDTO) => {
    dispatch(updateUser(user));
  };

  const resentInviteEmailUser = async (auth0Id: string) => {
    const response = await dispatch(
      resentInviteEmailUserThunk(auth0Id),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const resetPasswordUser = async (email: string) => {
    const response = await dispatch(resetPasswordUserThunk(email)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  return { user, ui, setUser, resentInviteEmailUser, resetPasswordUser };
};
