import {
  SHButton,
  SHDialog,
  SHHtmlBlock,
  SHStack,
} from "@components/design-systems";

interface ButtonProps {
  title?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}
interface CompleteDialogProps {
  open?: boolean;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  header?: React.ReactNode;
  body?: React.ReactNode;
  leftButton?: ButtonProps;
  rightButton?: ButtonProps;
}

export const ConfirmDialog = ({
  open,
  disableEscapeKeyDown,
  disableBackdropClick,
  header,
  body,
  leftButton,
  rightButton,
}: CompleteDialogProps) => {
  return (
    <SHDialog
      open={!!open}
      disableEscapeKeyDown={disableEscapeKeyDown}
      disableBackdropClick={disableBackdropClick}
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: { xs: "100%", md: 468 },
        },
      }}
      header={header}
      body={
        typeof body === "string" ? (
          <SHHtmlBlock
            textAlign={"center"}
            variant="body2"
            sx={{ fontSize: "16px" }}
            content={body}
          />
        ) : (
          body
        )
      }
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          {leftButton && (
            <SHButton
              color="secondary"
              variant="text"
              size="extraMedium"
              onClick={leftButton.onClick}
              disabled={leftButton.disabled}
              isLoading={leftButton.isLoading}
            >
              {leftButton.title}
            </SHButton>
          )}
          {rightButton && (
            <SHButton
              color="primary"
              size="extraMedium"
              variant="contained"
              onClick={rightButton.onClick}
              disabled={rightButton.disabled}
              isLoading={rightButton.isLoading}
            >
              {rightButton.title}
            </SHButton>
          )}
        </SHStack>
      }
    />
  );
};
