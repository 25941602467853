import { UserType } from "@models/auth";
import {
  AdviserUserDTO,
  CreateAdviserUserDTO,
} from "@models/practice/entities/practiceUser";
import { UserStatus, UserStatusAction } from "@models/users/enums/status";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getPracticeUser,
  patchPracticeUserStatus,
  postPracticeUser,
  putCompleteProfilePracticeUser,
  putPracticeUser,
  resendInviteEmailAdviserUser,
  sendResetPasswordAdviserUser,
} from "@services/practice/practiceUserService";

interface PracticeUserStore {
  practiceUser?: AdviserUserDTO;
  ui: {
    isLoading?: boolean;
    isLoadingLOV?: boolean;
    isSubmitting?: boolean;
    isModifyingStatus?: UserStatusAction;
    isLoadingResetPassword?: boolean;
    isResentingInvite?: boolean;
  };
}

const initialState: PracticeUserStore = {
  practiceUser: undefined,
  ui: {},
};

const practiceUserSlice = createSlice({
  name: "practiceUser",
  initialState,
  reducers: {
    setPracticeUserAction: (
      state,
      action: PayloadAction<AdviserUserDTO | undefined>,
    ) => {
      state.practiceUser = action.payload;
    },
  },
  extraReducers(builder) {
    //loadPracticeUser
    builder.addCase(loadPracticeUserThunk.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(loadPracticeUserThunk.fulfilled, (state, action) => {
      state.practiceUser = action.payload.isSuccess
        ? action.payload?.data
        : undefined;

      state.ui.isLoading = false;
    });
    builder.addCase(loadPracticeUserThunk.rejected, (state, action) => {
      state.ui.isLoading = false;
    });

    //createRPracticeUser
    builder.addCase(createPracticeUserThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(createPracticeUserThunk.fulfilled, (state, action) => {
      if (action.payload.isSuccess) {
        state.practiceUser = action.payload?.data;
      }
      state.ui.isSubmitting = false;
    });
    builder.addCase(createPracticeUserThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    //updatePracticeUser
    builder.addCase(updatePracticeUserThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(updatePracticeUserThunk.fulfilled, (state, action) => {
      state.ui.isSubmitting = false;
    });
    builder.addCase(updatePracticeUserThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    //updatePracticeUserStatus
    builder.addCase(updatePracticeUserStatusThunk.pending, (state, action) => {
      state.ui.isModifyingStatus = action.meta.arg.statusAction;
    });
    builder.addCase(
      updatePracticeUserStatusThunk.fulfilled,
      (state, action) => {
        if (
          state.practiceUser?.status &&
          action.payload.isSuccess &&
          action.payload.data
        ) {
          if (
            state.practiceUser.userType === UserType.AdviserAdviser ||
            state.practiceUser.userType === UserType.AdviserAdminAdviser
          ) {
            if (
              (state.practiceUser.status === UserStatus.Active ||
                state.practiceUser.status === UserStatus.Pending) &&
              action.payload.data.status === UserStatus.Disabled
            ) {
              state.practiceUser.accountTypes?.map((item) => {
                return item.id === UserType.AdviserAdviser
                  ? (item.inUse -= 1)
                  : item.inUse;
              });
            }
            if (
              (action.payload.data.status === UserStatus.Active ||
                action.payload.data.status === UserStatus.Pending) &&
              state.practiceUser.status === UserStatus.Disabled
            ) {
              state.practiceUser.accountTypes?.map((item) => {
                return item.id === UserType.AdviserAdviser
                  ? (item.inUse += 1)
                  : item.inUse;
              });
            }
          }
          state.practiceUser.status = action.payload.data.status;
        }
        state.ui.isModifyingStatus = undefined;
      },
    );
    builder.addCase(updatePracticeUserStatusThunk.rejected, (state, action) => {
      state.ui.isModifyingStatus = undefined;
    });

    //completeProfilePracticeUser
    builder.addCase(
      completeProfilePracticeUserThunk.pending,
      (state, action) => {
        state.ui.isSubmitting = true;
      },
    );
    builder.addCase(
      completeProfilePracticeUserThunk.fulfilled,
      (state, action) => {
        state.ui.isSubmitting = false;
      },
    );
    builder.addCase(
      completeProfilePracticeUserThunk.rejected,
      (state, action) => {
        state.ui.isSubmitting = false;
      },
    );

    //resetPasswordAdviserUser
    builder.addCase(resetPasswordAdviserUserThunk.pending, (state, action) => {
      state.ui.isLoadingResetPassword = true;
    });
    builder.addCase(
      resetPasswordAdviserUserThunk.fulfilled,
      (state, action) => {
        state.ui.isLoadingResetPassword = false;
      },
    );
    builder.addCase(resetPasswordAdviserUserThunk.rejected, (state, action) => {
      state.ui.isLoadingResetPassword = false;
    });

    //resendInviteEmailAdviserUser
    builder.addCase(
      resendInviteEmailAdviserUserThunk.pending,
      (state, action) => {
        state.ui.isResentingInvite = true;
      },
    );
    builder.addCase(
      resendInviteEmailAdviserUserThunk.fulfilled,
      (state, action) => {
        state.ui.isResentingInvite = false;
      },
    );
    builder.addCase(
      resendInviteEmailAdviserUserThunk.rejected,
      (state, action) => {
        state.ui.isResentingInvite = false;
      },
    );
  },
});

export const loadPracticeUserThunk = createAsyncThunk(
  `practiceUser/load`,
  async ({
    adviserFirmId,
    auth0Id,
  }: {
    adviserFirmId?: string;
    auth0Id?: string;
  }) => {
    return await getPracticeUser(adviserFirmId, auth0Id);
  },
);

export const createPracticeUserThunk = createAsyncThunk(
  `practiceUser/create`,
  async (practiceUser: CreateAdviserUserDTO) => {
    return await postPracticeUser(practiceUser);
  },
);

export const updatePracticeUserThunk = createAsyncThunk(
  `practiceUser/update`,
  async ({
    auth0Id,
    practiceUser,
  }: {
    auth0Id: string;
    practiceUser: AdviserUserDTO;
  }) => {
    return await putPracticeUser(practiceUser.adviserFirmId, practiceUser);
  },
);

export const updatePracticeUserStatusThunk = createAsyncThunk(
  `practiceUser/updateStatus`,
  async ({
    adviserFirmId,
    auth0Id,
    status,
    statusAction,
  }: {
    adviserFirmId: string;
    auth0Id: string;
    status: UserStatus;
    statusAction: UserStatusAction;
  }) => {
    return await patchPracticeUserStatus(adviserFirmId, auth0Id, status);
  },
);

export const completeProfilePracticeUserThunk = createAsyncThunk(
  `practiceUser/completeProfile`,
  async ({
    auth0Id,
    practiceUser,
  }: {
    auth0Id: string;
    practiceUser: AdviserUserDTO;
  }) => {
    return await putCompleteProfilePracticeUser(
      practiceUser.adviserFirmId,
      auth0Id,
      practiceUser,
    );
  },
);

export const resetPasswordAdviserUserThunk = createAsyncThunk(
  `practiceUser/resetPassword`,
  async ({
    adviserFirmId,
    auth0Id,
  }: {
    adviserFirmId?: string;
    auth0Id?: string;
  }) => {
    return await sendResetPasswordAdviserUser(adviserFirmId, auth0Id);
  },
);

export const resendInviteEmailAdviserUserThunk = createAsyncThunk(
  `practiceUser/resendInviteEmail`,
  async ({
    adviserFirmId,
    auth0Id,
  }: {
    adviserFirmId?: string;
    auth0Id?: string;
  }) => {
    return await resendInviteEmailAdviserUser(adviserFirmId, auth0Id);
  },
);

export const { setPracticeUserAction } = practiceUserSlice.actions;
export default practiceUserSlice.reducer;
