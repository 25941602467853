import { SHButton } from "@components/design-systems";
import { RestorePostConfirmationDialog } from "@components/dialogs/restore-post-confirmation";
import { RollBackSVG } from "@components/svgs";
import { useNewsFeed } from "@hooks/useNewsFeed";
import { useNotification } from "@hooks/useNotification";
import { useTheme } from "@mui/material";
import { restorePost } from "@services/newsfeed/postService";
import { useState } from "react";

export interface RestorePostButtonProps {
  postId: string;
}

export const RestorePostButton = ({ postId }: RestorePostButtonProps) => {
  const { palette } = useTheme();
  const { notify } = useNotification();
  const { loadPosts } = useNewsFeed();

  const [isRestoringPost, setIsRestoringPost] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState(false);

  const handleOnRestorePost = async () => {
    setIsRestoringPost(true);
    const { isSuccess, message } = await restorePost(postId);
    if (!isSuccess) {
      notify(message, {
        variant: "error",
        close: true,
      });
    } else {
      notify("Post restored.", {
        variant: "success",
        close: true,
      });
      loadPosts();
    }
    setIsRestoringPost(false);
    setShowDialog(false);
  };

  return (
    <>
      <SHButton
        startIcon={<RollBackSVG color={palette.common.black} />}
        variant="outlined"
        sx={{
          "&:active": {
            "&>span>svg>path": {
              fill: palette.common.white,
            },
          },
        }}
        onClick={() => {
          setShowDialog(true);
        }}
      >
        Restore
      </SHButton>
      {showDialog && (
        <RestorePostConfirmationDialog
          open
          isLoading={isRestoringPost}
          onClose={() => setShowDialog(false)}
          onOk={handleOnRestorePost}
        />
      )}
    </>
  );
};
