import { CloseSVG } from "@components/svgs";
import { IconButton, useTheme } from "@mui/material";

export interface CloseButtonProps {
  width?: string;
  height?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  [key: string]: any;
}
export const CloseButton = ({
  width = "10px",
  height = "10px",
  ...others
}: CloseButtonProps) => {
  const { palette } = useTheme();

  return (
    <IconButton
      {...others}
      disableRipple
      sx={{
        width,
        height,
        p: 0,
        backgroundColor: "transparent",
        "&>svg>path": {
          fill: palette.text.primary,
        },
        "&:hover": {
          "&>svg>path": {
            fill: palette.error.main,
          },
        },
        "&:active": {
          "&>svg>path": {
            fill: palette.error.dark,
          },
        },
        "&:disabled": {
          "&>svg>path": {
            fill: palette.text.disabled,
          },
        },
      }}
    >
      <CloseSVG width={width} height={height} />
    </IconButton>
  );
};
