import { SHStack } from "@components/design-systems";
import { PostItemSkeleton } from "./PostItemSkeleton";

export const PostListSkeleton = () => {
    return (
        <SHStack sx={{ paddingBottom: 3 }}>
            <SHStack spacing={2} mb={3}>
                <PostItemSkeleton />
                <PostItemSkeleton />
                <PostItemSkeleton />
            </SHStack>
        </SHStack>
    );
};