import { LoaderSVG } from "@components/svgs";
import { Box, BoxProps } from "@mui/material";

interface ISHIconLoadingProps {
  size?: number;
}

export const SHIconLoading = ({
  size = 16,
  sx,
  ...other
}: ISHIconLoadingProps & BoxProps): JSX.Element => {
  return (
    <>
      <Box
        {...other}
        sx={{
          height: size,
          width: size,
          animation: "rotate 2s linear infinite",
          "@keyframes rotate": {
            from: {
              transform: "rotateZ(0)",
            },
            to: {
              transform: "rotateZ(360deg)",
            },
          },
          ...sx,
          display: "flex",
          flexDirection: "column",
        }}
        data-testid="sh-icon-loading"
      >
        <LoaderSVG width={"100%"} height={"100%"} />
      </Box>
    </>
  );
};
