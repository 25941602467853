import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const BillingInfoSkeleton = () => {
  return (
    <SHStack flexDirection={"row"} justifyContent={"space-between"}>
      <SHStack width={"46%"} spacing={3}>
        <SHStack flexDirection={"row"} justifyContent={"space-between"}>
          <SHSkeleton width={150} height={25} />
          <SHSkeleton width={200} height={40} sx={{ borderRadius: "25px" }} />
        </SHStack>
        <SHSkeleton height={300} />
      </SHStack>
      <SHStack width={"52%"} spacing={3}>
        <SHStack flexDirection={"row"} justifyContent={"space-between"}>
          <SHSkeleton width={150} height={25} />
          <SHSkeleton width={200} height={40} sx={{ borderRadius: "25px" }} />
        </SHStack>
        <SHStack spacing={1}>
          {Array.from(Array(10).keys()).map((index) => (
            <SHSkeleton height={50} />
          ))}
        </SHStack>
      </SHStack>
    </SHStack>
  );
};
