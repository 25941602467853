import { SHContainer, SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import {
  SHButtonSkeleton,
  SHDividerSkeleton,
} from "@components/skeletons/design-systems";
import { SHCheckBoxGroupSkeleton } from "@components/skeletons/design-systems/SHCheckBoxGroupSkeleton";
import {
  SHFieldGroupSkeleton,
  SHFiledGroupTitleSkeleton,
} from "@components/skeletons/layouts";
import { StepName } from "@layouts/stepper/step-name";

export const AnalysisFeeCalculatorSkeleton = () => {
  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack spacing={"48px"}>
        <StepName isLoading name={"Fee calculator"} stepNumber={0} />
        <SHStack spacing={3}>
          <SHSkeleton width={80} height={26} />
          <SHSkeleton width={"100%"} height={26} />
          <SHSkeleton width={"90%"} height={26} />
        </SHStack>
        <SHDividerSkeleton />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={2} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
              <SHSkeleton width={"100%"} height={100} />
              <SHButtonSkeleton width={160} />
              <SHSkeleton width={"100%"} height={54} />
            </SHStack>
          }
        />
        <SHDividerSkeleton />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={2} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
              <SHCheckBoxGroupSkeleton hasLabel={false} options={6} />
            </SHStack>
          }
        />
      </SHStack>
    </SHContainer>
  );
};
