import { SVGProps } from "react";

const FireSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m5.468 7.394-.002.002-.003.002.005-.004Zm9.954-.186a1.24 1.24 0 0 0-.23-.175 1 1 0 0 0-1.4.411 5.783 5.783 0 0 1-1.397 1.778A8.714 8.714 0 0 0 8.128.13a1 1 0 0 0-1.492.805 7.017 7.017 0 0 1-2.471 4.943l-.23.187a8.513 8.513 0 0 0-1.988 1.863 8.984 8.984 0 0 0 3.656 13.908 1 1 0 0 0 1.327-1.238 6.977 6.977 0 0 1-.19-2.581 9.004 9.004 0 0 0 4.313 4.016c.225.101.48.115.715.038a8.996 8.996 0 0 0 3.654-14.863Zm-3.905 12.831a6.965 6.965 0 0 1-3.577-4.402 8.907 8.907 0 0 1-.18-.964 1 1 0 0 0-1.857-.363 8.959 8.959 0 0 0-1.205 4.718A6.986 6.986 0 0 1 3.522 9.16a6.555 6.555 0 0 1 1.562-1.458.744.744 0 0 0 .075-.055s.296-.245.307-.25a8.968 8.968 0 0 0 2.898-4.633 6.736 6.736 0 0 1 1.386 8.088 1 1 0 0 0 1.184 1.418 7.856 7.856 0 0 0 3.862-2.688 7.002 7.002 0 0 1-3.279 10.457Z"
      fill="#25324B"
    />
  </svg>
);

export default FireSVG;
