import { SHStack, SHStackProps } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";

interface TableRateCardCellSkeletonProps {
  isTableHead?: boolean;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
}

const DebounceTime = 300;

export const TableRateCardCellSkeleton = ({
  isTableHead = false,
  isFirstColumn = false,
  isLastColumn = false,
  ...others
}: TableRateCardCellSkeletonProps & SHStackProps) => {
  const { palette } = useTheme();

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, DebounceTime);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isShown && (
        <SHStack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            border: `1px solid ${palette.secondary[100]}`,
            padding: "5px 5px",
            borderRight: !isLastColumn ? "none" : undefined,
            borderBottom: !isTableHead ? "none" : undefined,
          }}
          {...others}
        >
          <SHSkeleton
            variant="rectangular"
            height={54}
            width={200}
            delay={false}
          />
        </SHStack>
      )}
    </>
  );
};
