import { CloseButton } from "@components/buttons/close";
import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHHtmlBlock,
  SHPopper,
  SHStack,
  SHTableRow,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { CheckSVG, CrossSVG, TooltipAllowSVG } from "@components/svgs";
import { FieldTypeId } from "@models/configuration";
import {
  AnalysisDataDTO,
  AnalysisFeatureDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import { Link, useTheme } from "@mui/material";
import {
  FeatureAnalysisTableCell,
  FeatureAnalysisTableCellProps,
} from "@pages/platform-analysis/_id/steps/feature/analysis/components/table-cell";
import { toNativeValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { hexToRGBA } from "@utils";
import { isNil } from "lodash";
import { useEffect, useRef, useState } from "react";

interface BusinessMetricFeatureTableRowProps {
  featureData?: AnalysisFeatureDTO;
}

export const renderBooleanIcon = (value: boolean) => {
  if (value) return <CheckSVG width={18} height={18} />;
  return <CrossSVG width={18} height={18} />;
};

export const BusinessMetricFeatureTableRow = ({
  featureData,
  ...others
}: BusinessMetricFeatureTableRowProps &
  FeatureAnalysisTableCellProps &
  SHTableRowProps) => {
  const { palette } = useTheme();
  const labelRef = useRef<HTMLLabelElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [currLongTextValue, setCurrLongTextValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLLabelElement | null>(null);

  const handleOnShowMore = (event: any, value?: string) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
    setCurrLongTextValue(value ?? "");
  };

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        labelRef.current &&
        !labelRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, labelRef, open]);

  const renderPlatformValue = (analysisData: AnalysisDataDTO) => {
    if (analysisData?.value === AnalysisDataValue.MissingData)
      return (
        <SHTypography
          variant="body3"
          zIndex="1"
          colorVariant="third"
          textAlign="center"
        >
          No data
        </SHTypography>
      );
    if (analysisData?.fieldTypeId === FieldTypeId.YesNo)
      return renderBooleanIcon(
        toNativeValue(analysisData?.value ?? "", FieldTypeId.YesNo),
      );
    if (analysisData?.fieldTypeId === FieldTypeId.TextShort)
      return (
        <SHStack gap={"5px"} alignItems={"center"} direction="row">
          <SHTypography
            variant="body3"
            zIndex="1"
            colorVariant="third"
            textAlign="left"
          >
            {analysisData?.value}
          </SHTypography>
          <SHBox>
            {!isNil(analysisData?.isPositiveScore) &&
              renderBooleanIcon(analysisData?.isPositiveScore)}
          </SHBox>
        </SHStack>
      );
    if (analysisData?.fieldTypeId === FieldTypeId.Number)
      return (
        <>
          <SHTypography
            variant="body3"
            zIndex="1"
            colorVariant="third"
            textAlign="justify"
          >
            {analysisData?.value}
          </SHTypography>
          {!isNil(analysisData?.isPositiveScore) &&
            renderBooleanIcon(analysisData?.isPositiveScore)}
        </>
      );
    if (analysisData?.fieldTypeId === FieldTypeId.TextLong)
      return (
        <SHStack
          gap={"5px"}
          width={"118px"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Link
            ref={labelRef}
            variant="body1"
            component="label"
            underline="none"
            sx={{
              paddingLeft: "5px",
              fontWeight: 600,
              cursor: "pointer",
              color: palette.text.secondary,
              lineHeight: "120%",
            }}
            onClick={(event: any) =>
              handleOnShowMore(event, analysisData?.value)
            }
          >
            Click to view
          </Link>
          {!isNil(analysisData?.isPositiveScore) &&
            renderBooleanIcon(analysisData?.isPositiveScore)}
          <SHPopper
            anchorEl={anchorEl}
            open={open}
            placement="top-start"
            popperOptions={{
              modifiers: [{ name: "flip", enabled: false }],
            }}
            sx={{
              zIndex: 1100,
            }}
          >
            <SHStack
              ref={wrapperRef}
              sx={{
                p: "30px 15px 10px 10px",
                maxWidth: "577px",
                maxHeight: "478px",
                bgcolor: hexToRGBA(palette.common.white, 0.85),
                border: "1px solid #E3E3E3",
                backdropFilter: "blur(2px)",
                borderRadius: "2px",
                position: "relative",
              }}
            >
              <SHHtmlBlock
                variant="body2"
                content={currLongTextValue}
                color={palette.text.third}
                sx={{
                  pr: 1,
                  zIndex: 1,
                  overflow: "auto",
                  "::-webkit-scrollbar-thumb": {
                    boxShadow: "none",
                  },
                }}
              />
              <SHBox sx={{ position: "absolute", top: "10px", right: "15px" }}>
                <CloseButton onClick={handleOnShowMore} />
              </SHBox>
              <SHBox sx={{ position: "absolute", bottom: "-11.7px", left: 0 }}>
                <TooltipAllowSVG />
              </SHBox>
            </SHStack>
          </SHPopper>
        </SHStack>
      );
  };

  return (
    <SHTableRow
      sx={{ height: 54, background: `${hexToRGBA("#c5ddfd", 0.1)} !important` }}
      {...others}
    >
      <FeatureAnalysisTableCell isFirstColumn>
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent="space-between"
        >
          <SHTypography
            variant="body3"
            colorVariant="third"
            textAlign="left"
            lineHeight="120%"
          >
            {featureData?.name}
          </SHTypography>
          {featureData?.description && (
            <InformationButton content={featureData.description} />
          )}
        </SHStack>
      </FeatureAnalysisTableCell>
      {featureData?.analysisData?.map((analysisData: any) => (
        <FeatureAnalysisTableCell key={analysisData?.platformId}>
          <SHStack
            direction={"row"}
            alignItems={"center"}
            justifyContent="center"
          >
            {renderPlatformValue(analysisData)}
          </SHStack>
        </FeatureAnalysisTableCell>
      ))}
    </SHTableRow>
  );
};
