export enum SubHeader {
  Model = "Model",
  Platform = "Platform",
  AnnualTotal = "column0",
  PDS = "column1",
  TotalManagementFeesAndCosts = "column2",
  IMFee = "column3",
  UnderlyingManagementFeesAndCosts = "column4",
  CashFee = "column5",
  NetOfRebates = "column6",
  TotalPerformanceFees = "column7",
  ModelManager = "column8",
  UnderlyingPerformanceFees = "column9",
  TotalTransactionCosts = "column10",
  UnderlyingTransactionCosts = "column11",
  BuySellSpreads = "column12",
  PlatformPercent = "column13",
  PlatformDollar = "column14",
  Netting = "column15",
  ModelTurnover = "column16",
  Discount = "column17",
  Type = "column18",
  FeeMethod = "column19",
  Incepted = "column20",
  MinInvestment = "column21",
  PercentCash = "column22",
  PercentIntListed = "column23",
  Super = "column24",
  IDPS = "column25",
  PrivateLabel = "column26",
  Available = "column27",
  CustomModel = "column28",
}
