import { AnalysisVariable } from "@models/platform-analysis/enums/variableName";

export const MinAccountBalance = 0;
export const MaxAccountBalance = 99999999;
export const MaxTotalAccount = 6;

export const ChooseOptions = [
  AnalysisVariable.Choose_MF,
  AnalysisVariable.Choose_AU,
  AnalysisVariable.Choose_Intl,
  AnalysisVariable.Choose_MA,
  AnalysisVariable.Choose_ret_ins,
  AnalysisVariable.Choose_bond,
];

export const clearDataConditions = new Map<
  AnalysisVariable,
  AnalysisVariable[]
>()
  .set(AnalysisVariable.Choose_MF, [
    AnalysisVariable.MF_balance_i,
    AnalysisVariable.MF_transactions,
    AnalysisVariable.MF_avg,
  ])
  .set(AnalysisVariable.Choose_AU, [
    AnalysisVariable.AU_balance_i,
    AnalysisVariable.AU_transactions,
    AnalysisVariable.AU_avg,
  ])

  .set(AnalysisVariable.Choose_Intl, [
    AnalysisVariable.Intl_balance_i,
    AnalysisVariable.Intl_transactions,
    AnalysisVariable.Intl_avg,
  ])

  .set(AnalysisVariable.Choose_MA, [
    AnalysisVariable.MA_balance_i,
    AnalysisVariable.MA_MF_transactions,
    AnalysisVariable.MA_AU_transactions,
    AnalysisVariable.MA_Intl_transactions,
    AnalysisVariable.MA_MF_avg,
    AnalysisVariable.MA_AU_avg,
    AnalysisVariable.MA_Intl_avg,
  ])
  .set(AnalysisVariable.Choose_bond, [
    AnalysisVariable.Bond_balance_i,
    AnalysisVariable.Bond_transactions,
    AnalysisVariable.Bond_avg,
  ])
  .set(AnalysisVariable.Choose_ret_ins, [
    AnalysisVariable.IDPS_num_ret_Ins,
    AnalysisVariable.Super_num_ret_Ins,
    AnalysisVariable.Premium_avg,
  ]);
