import { SHContainer, SHStack } from "@components/design-systems";
import {
  SHDividerSkeleton,
  SHTextFieldSkeleton,
  SHUploadImageSkeleton,
} from "@components/skeletons/design-systems";
import { SHTabSkeleton } from "@components/skeletons/design-systems/SHTabSkeleton";
import {
  SHFieldGroupSkeleton,
  SHFiledGroupTitleSkeleton,
  SHTopBarSkeleton,
} from "@components/skeletons/layouts";

export default function ManageSupplierSkeleton() {
  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack sx={{ paddingY: 3 }} spacing={3}>
        <SHTopBarSkeleton totalButtons={2} />
        <SHTabSkeleton tabNumber={5} />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton fullWidth />}
        />
        <SHDividerSkeleton />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={2} />}
          rightSide={<SHUploadImageSkeleton />}
        />
        <SHDividerSkeleton />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={1} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
              <SHTextFieldSkeleton />
              <SHTextFieldSkeleton />
              <SHTextFieldSkeleton />
              <SHTextFieldSkeleton />
              <SHTextFieldSkeleton />
            </SHStack>
          }
        />
      </SHStack>
    </SHContainer>
  );
}
