import {
  SHButton,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { HamburgerSVG } from "@components/svgs/hamburger";
import { DateTimeFormat } from "@constants/format";
import { HistoryRelease } from "@models/configuration";
import { ProductHistoryDTO } from "@models/product/entities/history";
import { useTheme } from "@mui/material";
import { DiscardDraftButton } from "@pages/suppliers/_id/_products/_id/components/buttons/discard-draft";
import { hexToRGBA } from "@utils";
import { format } from "date-fns";
import { useMemo } from "react";
export interface HistoryCardProps extends SHStackProps {
  release?: HistoryRelease;
  releaseProduct?: ProductHistoryDTO;
  isEdit?: boolean;
  isOdd?: boolean;
  onClickView?: () => void;
  isChangeHistory?: boolean;
  isFirstDraft?: boolean;
  productId?: string;
  [key: string]: any;
}
export const HistoryCard = ({
  release,
  releaseProduct,
  isEdit,
  isOdd,
  onClickView,
  isChangeHistory,
  isFirstDraft,
  productId,
  ...others
}: HistoryCardProps) => {
  const isDraftCard = useMemo(
    () => releaseProduct?.isDraft || release?.isDraft,
    [releaseProduct?.isDraft, release?.isDraft],
  );

  const releaseFormattedDate = useMemo(() => {
    if (isDraftCard && release?.lastModifiedDate) {
      return format(
        release.lastModifiedDate,
        `'Last updated' ${DateTimeFormat}`,
      );
    }
    if (isDraftCard && releaseProduct?.lastModifiedDate) {
      return format(
        releaseProduct.lastModifiedDate,
        `'Last updated' ${DateTimeFormat}`,
      );
    }

    if (release?.approvalDate) {
      return format(release.approvalDate, `'Release' ${DateTimeFormat}`);
    }
    if (releaseProduct?.approvalDate) {
      return format(
        releaseProduct?.approvalDate,
        `'Release' ${DateTimeFormat}`,
      );
    }
    return "";
  }, [
    isDraftCard,
    releaseProduct?.lastModifiedDate,
    release?.approvalDate,
    release?.lastModifiedDate,
    releaseProduct?.approvalDate,
  ]);

  const { palette } = useTheme();
  const colorSx = useMemo(() => {
    if (isOdd)
      return {
        bgcolor: palette.common.white,
        "&:hover": {
          bgcolor: hexToRGBA(palette.common.white, 0.1),
        },
      };
    return {
      bgcolor: palette.secondary[50],
      "&:hover": {
        bgcolor: hexToRGBA(palette.secondary[50], 0.1),
      },
    };
  }, [palette, isOdd]);
  const renderUpdateBy = () => {
    if (isChangeHistory) {
      if (isDraftCard) {
        return "Last updated by";
      } else {
        return "Approved by";
      }
    } else {
      if (isDraftCard) {
        return "Last updated by";
      } else {
        return "Approver";
      }
    }
  };
  return (
    <SHStack
      sx={{
        backdropFilter: "blur(0.5px)",
        padding: "12.5px 15px",
        cursor: "pointer",
        border: `1px solid ${palette.secondary[100]}`,
        ...colorSx,
      }}
      direction="row"
      justifyContent="space-between"
      alignItems={"center"}
      spacing={2}
      data-testid="history-card"
      {...others}
    >
      <SHStack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
        spacing={2}
        width={"60%"}
      >
        {isDraftCard && isEdit && <HamburgerSVG />}
        <SHStack justifyContent="start" alignItems={"start"} width="70%">
          <SHTypography variant="h2" sx={{ fontWeight: 500 }}>
            {releaseProduct?.name || release?.name}
          </SHTypography>
          <SHTypography variant="body4" colorVariant="third">
            {releaseFormattedDate}
          </SHTypography>
        </SHStack>
        <SHStack justifyContent="start" alignItems={"start"} width="30%">
          <SHTypography variant="body4" sx={{ fontWeight: 600 }}>
            {renderUpdateBy()}
          </SHTypography>
          <SHTypography
            variant="caption"
            sx={{ fontWeight: 400 }}
            colorVariant="third"
          >
            {isDraftCard
              ? releaseProduct?.lastUpdatedUserName
              : release?.approvalUserName || releaseProduct?.approvalUserName}
          </SHTypography>
        </SHStack>
      </SHStack>
      <SHStack
        direction="row"
        justifyContent="end"
        alignItems={"center"}
        spacing={2}
        width={"40%"}
      >
        {isDraftCard ? (
          <DiscardDraftButton
            productId={productId}
            isFirstDraft={isFirstDraft}
          />
        ) : (
          <SHButton
            variant="contained"
            onClick={onClickView}
            disabled={!isChangeHistory}
          >
            View release log
          </SHButton>
        )}
      </SHStack>
    </SHStack>
  );
};
