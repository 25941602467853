import { SHTableCell, SHTableCellProps } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { useMemo } from "react";

export interface FeatureAnalysisTableCellProps {
  isFirstColumn?: boolean;
  isHeaderCell?: boolean;
}

export const FeatureAnalysisTableCell = ({
  isFirstColumn,
  isHeaderCell,
  ...others
}: FeatureAnalysisTableCellProps & SHTableCellProps) => {
  const { palette } = useTheme();

  const cellWidth = useMemo(() => {
    if (isFirstColumn) return 316;
    return 202;
  }, [isFirstColumn]);

  return (
    <SHTableCell
      sx={{
        width: cellWidth,
        minWidth: cellWidth,
        maxWidth: cellWidth,
        backgroundColor: isHeaderCell ? palette.common.white : "transparent",
        left: -1,
        position: "sticky",
        padding: "5px 12px",
        textAlign: "center",
        border: `1px solid ${palette.secondary[100]}`,
      }}
      {...others}
    ></SHTableCell>
  );
};
