import {
  SHButton,
  SHPopper,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { LocalStorage } from "@constants";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { usePractice } from "@hooks/usePractice";
import { usePracticeBillingInfo } from "@hooks/usePracticeBillingInfo";
import { SubscriptionBannerHidden } from "@models/users/entities/subscriptionBanner";
import { SubscriptionBannerType } from "@models/users/enums/bannerType";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { hexToRGBA } from "@utils";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

export type ModifyPaymentMethodsButtonProps = {
  paymentMethodId: string;
};

export const ModifyPaymentMethodButton = ({
  paymentMethodId,
}: ModifyPaymentMethodsButtonProps) => {
  const { palette } = useTheme();
  const { user } = useSelector((state: RootState) => state.auth);
  const { practiceId, loadOrangeDotBillingInfo } = usePractice();
  const { updatePaymentMethodDefault, removePaymentMethod } =
    usePracticeBillingInfo();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [open, setOpen] = useState(false);

  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleSetDefault = async () => {
    setOpen((prev) => !prev);
    if (practiceId) {
      await updatePaymentMethodDefault(practiceId, paymentMethodId);

      //TODO: need refactor
      const currentBannersHidden: SubscriptionBannerHidden[] =
        getLocalStorageItem(LocalStorage.SubscriptionBannersHidden) ?? [];

      if (!isEmpty(currentBannersHidden))
        setLocalStorageItem(
          LocalStorage.SubscriptionBannersHidden,
          currentBannersHidden?.filter(
            (item) => item.id !== SubscriptionBannerType.ExpiringCard && item.userId !== user?.auth0Id,
          ),
        );

      const debounceTime = 1500;
      setTimeout( () => {
        loadOrangeDotBillingInfo(practiceId).then((hasForceRefresh) => {
          if(hasForceRefresh){
            window.location.reload();
          }
        });
      }, debounceTime)
    }
  };

  const handleRemoveCard = async () => {
    setOpen((prev) => !prev);
    if (practiceId) {
      await removePaymentMethod(practiceId, paymentMethodId);
    }
  };

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, buttonRef, open]);

  return (
    <>
      <SHButton
        ref={buttonRef}
        onClick={handleClick}
        sx={{ border: "none !important" }}
      >
        <MoreHorizIcon sx={{ color: palette.text.disabled }} />
      </SHButton>
      <SHPopper
        anchorEl={anchorEl}
        open={open}
        placement="bottom-end"
        popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
        sx={{ zIndex: 1500 }}
      >
        <SHStack
          ref={wrapperRef}
          sx={{
            boxSizing: "border-box",
            boxShadow: "none",
            borderWidth: 1,
            borderColor: palette.divider,
            borderStyle: "solid",
            borderRadius: 0,
            backgroundColor: hexToRGBA(palette.common.white, 0.95),
            minWidth: "170px",
            filter: "drop-shadow(1px 1px 2px rgba(65, 73, 89, 0.25))",
            cursor: "pointer",
          }}
        >
          <SHStack direction="column" alignItems="flex-start">
            <SHStack
              paddingX={2}
              paddingY={1}
              sx={{
                width: "100%",
                "&:hover": {
                  backgroundColor: hexToRGBA(palette.primary.dark, 0.05),
                },
              }}
              onClick={handleSetDefault}
            >
              <SHTypography variant="body1">Set default</SHTypography>
            </SHStack>

            <SHStack
              paddingX={2}
              paddingY={1}
              sx={{
                width: "100%",
                "&:hover": {
                  backgroundColor: hexToRGBA(palette.primary.dark, 0.07),
                },
              }}
              onClick={handleRemoveCard}
            >
              <SHTypography variant="body1" sx={{ color: palette.error.main }}>
                Remove card
              </SHTypography>
            </SHStack>
          </SHStack>
        </SHStack>
      </SHPopper>
    </>
  );
};
