import React from "react";

export interface TopNotificationItem {
  id?: string;
  title?: string | React.ReactNode;
  message?: string | React.ReactNode;
  type?: TopNotificationType;
  showCloseButton?: boolean;
  isAutoClose?: boolean;
  group?: string;
  productId?: string;
  productBannerType?: ProductBannerType;
  show?: boolean;
  lastModifiedDate?: number;
  isAdviserUserSubscription?: boolean;
  subscriptionExpiredDate?: Date;
  testClockDate?: Date;
}

export enum TopNotificationType {
  Success = "Success",
  Error = "Error",
  Warning = "Warning",
  Information = "Information",
}

export enum TopNotificationGroup {
  Global = "Global",
  Supplier = "Supplier",
  PlatformProfile = "PlatformProfile",
  Configuration = "Configuration",
  AdviserUserSubscription = "AdviserUserSubscription",
}

export enum ProductBannerType {
  Draft = "Draft",
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
  MissingData = "MissingData",
}
