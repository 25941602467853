import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import {
  AdditionalCostDTO,
  SubscriptionDTO,
  SubscriptionPricingInfoDTO,
  UpdateRenewalDTO,
  UpdateUserSubscriptionDTO,
  UserSubscriptionDTO,
} from "@models/practice/entities/practiceLicences";
import { SubscriptionProductType } from "@models/practice/enums/subscription";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getPracticeLicences(
  practiceId: string,
): Promise<APIResponse<SubscriptionDTO>> {
  const response = await APIGatewayService.get<SubscriptionDTO>(
    generatePath(APIExtRoutes.adviserFirmSubscription, { practiceId }),
  );

  return handleErrorProxy(response);
}

export async function putPracticeLicences(
  practiceId: string,
  practiceLicences: SubscriptionDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<SubscriptionDTO>(
    generatePath(APIExtRoutes.adviserFirmSubscription, { practiceId }),
    practiceLicences,
  );
  return handleErrorProxy(response, "Changes have been saved.");
}

// Adviser User - Subscription
export async function getPracticeUserLicences(
  practiceId: string,
): Promise<APIResponse<UserSubscriptionDTO>> {
  const response = await APIGatewayService.get<UserSubscriptionDTO>(
    generatePath(APIExtRoutes.adviserUserSubscription, { practiceId }),
  );
  return handleErrorProxy(response);
}

export async function postPracticeUserLicences(
  practiceId: string,
  quantity: number,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.post(
    generatePath(APIExtRoutes.adviserUserSubscriptionQuantity, {
      practiceId,
      quantity: quantity.toString(),
    }),
  );
  return handleErrorProxy(response, "Subscription created successfully.");
}

export async function putPracticeUserLicences(
  practiceId: string,
  updateUserSubscription: UpdateUserSubscriptionDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put(
    generatePath(APIExtRoutes.adviserUserUpdateSubscriptionQuantity, {
      practiceId,
    }),
    updateUserSubscription,
  );

  return handleErrorProxy(response, "Your purchase was successful.");
}

export async function getLicencesAdditionalCost(
  practiceId: string,
  quantity: number,
): Promise<APIResponse<AdditionalCostDTO>> {
  const response = await APIGatewayService.get<AdditionalCostDTO>(
    generatePath(`${APIExtRoutes.adviserUserSubscriptionAdditionalCost}`, {
      practiceId,
      quantity: quantity.toString(),
    }),
  );

  return handleErrorProxy(response);
}

export async function getSubscriptionPricingInfo(
  practiceId: string,
  productType: SubscriptionProductType,
): Promise<APIResponse<SubscriptionPricingInfoDTO>> {
  const response = await APIGatewayService.get<SubscriptionPricingInfoDTO>(
    generatePath(`${APIExtRoutes.adviserSubscriptionPricingInfo}`, {
      practiceId,
      productType
    }),
  );

  return handleErrorProxy(response);
}

export async function putLicencesRenewal(
  practiceId: string,
  updateRenewalDTO: UpdateRenewalDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put(
    generatePath(`${APIExtRoutes.adviserUserSubscription}/renewal`, {
      practiceId,
    }),
    updateRenewalDTO,
  );
  return handleErrorProxy(response, "Changes have been saved.");
}
