import { SHStack, SHStackProps } from "@components/design-systems";
import { LineChartLegendSVG } from "@components/svgs";
import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { FeeSubProductCard } from "../sub-product-card";

export interface LineChartLegendProps extends SHStackProps {
  subProduct: FeeSubProductDTO;
  color?: string;
  isActive?: boolean;
}
export const LineChartLegend = ({
  subProduct,
  color,
  isActive = true,
  onClick,
  ...others
}: LineChartLegendProps) => {
  return (
    <SHStack
      {...others}
      direction="row"
      alignItems={"center"}
      spacing={"10px"}
      onClick={onClick}
      sx={{
        transition: "all ease-in-out 0.2s",
        cursor: "pointer",
        "&:hover": {
          opacity: 0.8,
        },
        webkitFilter: isActive ? undefined : "grayscale(100%)",
        filter: isActive ? undefined : "grayscale(100%)",
      }}
    >
      {<LineChartLegendSVG color={color} />}
      <FeeSubProductCard
        isActive={isActive}
        subProduct={subProduct}
        sx={{
          transition: "all ease-in-out 0.2s",
        }}
      />
    </SHStack>
  );
};
