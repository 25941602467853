import { SHButton, SHDialog, SHStack } from "@components/design-systems";

interface NoBalancesDialogProps {
  onClose?: () => void;
  onSubmit?: () => void;
}

export const NoBalancesDialog = ({
  onClose,
  onSubmit,
}: NoBalancesDialogProps) => {
  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: { xs: "100%", md: 656 },
        },
      }}
      header={`Accounts with no balances  will be removed.`}
      body={`Each account’s balance must be greater than $0. Some accounts currently have no balance. These accounts will be removed as part of the fee calculation.`}
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onClose}
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onSubmit}
          >
            OK, continue
          </SHButton>
        </SHStack>
      }
    />
  );
};
