import {
  AnyAction,
  configureStore,
  PreloadedState,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers";

const store = configureStore({ reducer: rootReducer });
export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: [thunkMiddleware],
  });
}
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;

export const useAppDispatch = () =>
  useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

export default store;
