export enum AnalysisVariable {
  Display_name = "Display_name",
  Platform = "Platform",
  Sub_product_internal = "Sub_product_internal",

  IDPS_IDPS = "IDPS_IDPS",
  IDPS_H_PDS = "IDPS_H_PDS",
  IDPS_Product_name = "IDPS_Product_name",
  IDPS_Offer_name = "IDPS_Offer_name",
  IDPS_PDS_date = "IDPS_PDS_date",

  IDPS_H_admin_fee = "IDPS_H_admin_fee",
  IDPS_Tiered_fee_cap_min = "IDPS_Tiered_fee_cap_min",
  IDPS_Tiered_fee_cap_max = "IDPS_Tiered_fee_cap_max",
  IDPS_Admin_fee_cap_min = "IDPS_Admin_fee_cap_min",
  IDPS_Admin_fee_cap_max = "IDPS_Admin_fee_cap_max",
  IDPS_Fixed_admin_fee = "IDPS_Fixed_admin_fee",
  IDPS_No_admin_cash = "IDPS_No_admin_cash",
  IDPS_Admin_fee_type = "IDPS_Admin_fee_type",
  IDPS_Tier_0 = "IDPS_Tier_0",
  IDPS_Tier_1 = "IDPS_Tier_1",
  IDPS_Tier_1_Percent = "IDPS_Tier_1_%",
  IDPS_Tier_2 = "IDPS_Tier_2",
  IDPS_Tier_2_Percent = "IDPS_Tier_2_%",
  IDPS_Tier_3 = "IDPS_Tier_3",
  IDPS_Tier_3_Percent = "IDPS_Tier_3_%",
  IDPS_Tier_4 = "IDPS_Tier_4",
  IDPS_Tier_4_Percent = "IDPS_Tier_4_%",
  IDPS_Tier_5 = "IDPS_Tier_5",
  IDPS_Tier_5_Percent = "IDPS_Tier_5_%",
  IDPS_Tier_6 = "IDPS_Tier_6",
  IDPS_Tier_6_Percent = "IDPS_Tier_6_%",
  IDPS_Tier_7 = "IDPS_Tier_7",
  IDPS_Tier_7_Percent = "IDPS_Tier_7_%",
  IDPS_Tier_8_Percent = "IDPS_Tier_8_%",

  IDPS_H_Cash = "IDPS_H_Cash",
  IDPS_Cash_account_fee = "IDPS_Cash_account_fee",
  IDPS_Interest_rate = "IDPS_Interest_rate",
  IDPS_Min_cash_bal = "IDPS_Min_cash_bal",

  IDPS_H_other_acc_fee = "IDPS_H_other_acc_fee",
  IDPS_ER_flat = "IDPS_ER_flat",
  IDPS_ER_Percent = "IDPS_ER_%",
  IDPS_ER_max = "IDPS_ER_max",
  IDPS_High_bal_fee = "IDPS_High_bal_fee",
  IDPS_High_bal_thresh = "IDPS_High_bal_thresh",
  IDPS_Other_fee_flat = "IDPS_Other_fee_flat",
  IDPS_Other_fee_Percent = "IDPS_Other_fee_%",
  IDPS_Other_fee_max = "IDPS_Other_fee_max",
  IDPS_Other_fee_text = "IDPS_Other_fee_text",

  IDPS_H_AU_fees = "IDPS_H_AU_fees",
  IDPS_AU_listed = "IDPS_AU_listed",
  IDPS_AU_bro_Percent = "IDPS_AU_bro_%",
  IDPS_AU_bro_min = "IDPS_AU_bro_min",
  IDPS_AU_bro_max = "IDPS_AU_bro_max",
  IDPS_AU_settlement_fee = "IDPS_AU_settlement_fee",
  IDPS_AU_admin_fee = "IDPS_AU_admin_fee",
  IDPS_AU_admin_fee_cap = "IDPS_AU_admin_fee_cap",

  IDPS_H_Intl_trans_fees = "IDPS_H_Intl_trans_fees",
  IDPS_Intl_listed = "IDPS_Intl_listed",
  IDPS_Intl_bro_flat = "IDPS_Intl_bro_flat",
  IDPS_Intl_bro_Percent = "IDPS_Intl_bro_%",
  IDPS_Intl_bro_min = "IDPS_Intl_bro_min",
  IDPS_Intl_bro_max = "IDPS_Intl_bro_max",
  IDPS_Intl_settlement_fee = "IDPS_Intl_settlement_fee",

  IDPS_H_Intl_admin_fees = "IDPS_H_Intl_admin_fees",
  IDPS_Intl_fixed_admin_fee = "IDPS_Intl_fixed_admin_fee",
  IDPS_Intl_tier_0 = "IDPS_Intl_tier_0",
  IDPS_Intl_tier_1 = "IDPS_Intl_tier_1",
  IDPS_Intl_tier_1_Percent = "IDPS_Intl_tier_1_%",
  IDPS_Intl_tier_2 = "IDPS_Intl_tier_2",
  IDPS_Intl_tier_2_Percent = "IDPS_Intl_tier_2_%",
  IDPS_Intl_tier_3 = "IDPS_Intl_tier_3",
  IDPS_Intl_tier_3_Percent = "IDPS_Intl_tier_3_%",
  IDPS_Intl_tier_4 = "IDPS_Intl_tier_4",
  IDPS_Intl_tier_4_Percent = "IDPS_Intl_tier_4_%",
  IDPS_Intl_tier_5_Percent = "IDPS_Intl_tier_5_%",

  IDPS_H_MF_fees = "IDPS_H_MF_fees",
  IDPS_Managed_funds = "IDPS_Managed_funds",
  IDPS_MF_fixed_trans = "IDPS_MF_fixed_trans",
  IDPS_MF_trans_Percent = "IDPS_MF_trans_%",
  IDPS_MF_trans_min = "IDPS_MF_trans_min",
  IDPS_MF_trans_max = "IDPS_MF_trans_max",
  IDPS_MF_admin_fee = "IDPS_MF_admin_fee",
  IDPS_MF_admin_fee_cap = "IDPS_MF_admin_fee_cap",

  IDPS_H_bond_fees = "IDPS_H_bond_fees",
  IDPS_FI_bond = "IDPS_FI_bond",
  IDPS_Bond_fixed_trans = "IDPS_Bond_fixed_trans",
  IDPS_Bond_trans_Percent = "IDPS_Bond_trans_%",
  IDPS_Bond_trans_min = "IDPS_Bond_trans_min",
  IDPS_Bond_trans_max = "IDPS_Bond_trans_max",
  IDPS_Bond_hold_Currency = "IDPS_Bond_hold_$",
  IDPS_Bond_hold_Percent = "IDPS_Bond_hold_%",

  IDPS_H_MA_fees = "IDPS_H_MA_fees",
  IDPS_Managed_accounts = "IDPS_Managed_accounts",
  IDPS_MA_admin_fee = "IDPS_MA_admin_fee",
  IDPS_MA_admin_fee_cap = "IDPS_MA_admin_fee_cap",
  IDPS_MA_MF_trans_flat = "IDPS_MA_MF_trans_flat",
  IDPS_MA_MF_trans_Percent = "IDPS_MA_MF_trans_%",
  IDPS_MA_MF_trans_min = "IDPS_MA_MF_trans_min",
  IDPS_MA_MF_trans_max = "IDPS_MA_MF_trans_max",
  IDPS_MA_AU_bro_flat = "IDPS_MA_AU_bro_flat",
  IDPS_MA_AU_bro_Percent = "IDPS_MA_AU_bro_%",
  IDPS_MA_AU_bro_min = "IDPS_MA_AU_bro_min",
  IDPS_MA_AU_bro_max = "IDPS_MA_AU_bro_max",
  IDPS_MA_Intl_bro_flat = "IDPS_MA_Intl_bro_flat",
  IDPS_MA_Intl_bro_Percent = "IDPS_MA_Intl_bro_%",
  IDPS_MA_Intl_bro_min = "IDPS_MA_Intl_bro_min",
  IDPS_MA_Intl_bro_max = "IDPS_MA_Intl_bro_max",

  IDPS_H_offline_fees = "IDPS_H_offline_fees",
  IDPS_Offline_fees = "IDPS_Offline_fees",

  IDPS_H_ins_fees = "IDPS_H_ins_fees",
  IDPS_Ins_fee = "IDPS_Ins_fee",
  IDPS_Ins_Percent_prem = "IDPS_Ins_%_prem",
  IDPS_Max_ins_fee = "IDPS_Max_ins_fee",
  IDPS_Flat_ins_policy_fee = "IDPS_Flat_ins_policy_fee",

  Super_Super = "Super_Super",
  Super_H_PDS = "Super_H_PDS",
  Super_Product_name = "Super_Product_name",
  Super_Offer_name = "Super_Offer_name",
  Super_PDS_date = "Super_PDS_date",

  Super_H_admin_fee = "Super_H_admin_fee",
  Super_Tiered_fee_cap_min = "Super_Tiered_fee_cap_min",
  Super_Tiered_fee_cap_max = "Super_Tiered_fee_cap_max",
  Super_Admin_fee_cap_min = "Super_Admin_fee_cap_min",
  Super_Admin_fee_cap_max = "Super_Admin_fee_cap_max",
  Super_Fixed_admin_fee = "Super_Fixed_admin_fee",
  Super_No_admin_cash = "Super_No_admin_cash",
  Super_Admin_fee_type = "Super_Admin_fee_type",
  Super_Tier_0 = "Super_Tier_0",
  Super_Tier_1 = "Super_Tier_1",
  Super_Tier_1_Percent = "Super_Tier_1_%",
  Super_Tier_2 = "Super_Tier_2",
  Super_Tier_2_Percent = "Super_Tier_2_%",
  Super_Tier_3 = "Super_Tier_3",
  Super_Tier_3_Percent = "Super_Tier_3_%",
  Super_Tier_4 = "Super_Tier_4",
  Super_Tier_4_Percent = "Super_Tier_4_%",
  Super_Tier_5 = "Super_Tier_5",
  Super_Tier_5_Percent = "Super_Tier_5_%",
  Super_Tier_6 = "Super_Tier_6",
  Super_Tier_6_Percent = "Super_Tier_6_%",
  Super_Tier_7 = "Super_Tier_7",
  Super_Tier_7_Percent = "Super_Tier_7_%",
  Super_Tier_8_Percent = "Super_Tier_8_%",

  Super_H_Cash = "Super_H_Cash",
  Super_Cash_account_fee = "Super_Cash_account_fee",
  Super_Interest_rate = "Super_Interest_rate",
  Super_Min_cash_bal = "Super_Min_cash_bal",

  Super_H_other_acc_fee = "Super_H_other_acc_fee",
  Super_ER_flat = "Super_ER_flat",
  Super_ER_Percent = "Super_ER_%",
  Super_ER_max = "Super_ER_max",
  Super_ORFR_Percent = "Super_ORFR_%",
  Super_ORFR_max = "Super_ORFR_max",
  Super_Other_fee_flat = "Super_Other_fee_flat",
  Super_Other_fee_Percent = "Super_Other_fee_%",
  Super_Other_fee_max = "Super_Other_fee_max",
  Super_Other_fee_text = "Super_Other_fee_text",

  Super_H_AU_fees = "Super_H_AU_fees",
  Super_AU_listed = "Super_AU_listed",
  Super_AU_bro_Percent = "Super_AU_bro_%",
  Super_AU_bro_min = "Super_AU_bro_min",
  Super_AU_bro_max = "Super_AU_bro_max",
  Super_AU_settlement_fee = "Super_AU_settlement_fee",
  Super_AU_admin_fee = "Super_AU_admin_fee",
  Super_AU_admin_fee_cap = "Super_AU_admin_fee_cap",

  Super_H_Intl_trans_fees = "Super_H_Intl_trans_fees",
  Super_Intl_listed = "Super_Intl_listed",
  Super_Intl_bro_flat = "Super_Intl_bro_flat",
  Super_Intl_bro_Percent = "Super_Intl_bro_%",
  Super_Intl_bro_min = "Super_Intl_bro_min",
  Super_Intl_bro_max = "Super_Intl_bro_max",
  Super_Intl_settlement_fee = "Super_Intl_settlement_fee",

  Super_H_Intl_admin_fees = "Super_H_Intl_admin_fees",
  Super_Intl_fixed_admin_fee = "Super_Intl_fixed_admin_fee",
  Super_Intl_tier_0 = "Super_Intl_tier_0",
  Super_Intl_tier_1 = "Super_Intl_tier_1",
  Super_Intl_tier_1_Percent = "Super_Intl_tier_1_%",
  Super_Intl_tier_2 = "Super_Intl_tier_2",
  Super_Intl_tier_2_Percent = "Super_Intl_tier_2_%",
  Super_Intl_tier_3 = "Super_Intl_tier_3",
  Super_Intl_tier_3_Percent = "Super_Intl_tier_3_%",
  Super_Intl_tier_4 = "Super_Intl_tier_4",
  Super_Intl_tier_4_Percent = "Super_Intl_tier_4_%",
  Super_Intl_tier_5_Percent = "Super_Intl_tier_5_%",

  Super_H_MF_fees = "Super_H_MF_fees",
  Super_Managed_funds = "Super_Managed_funds",
  Super_MF_fixed_trans = "Super_MF_fixed_trans",
  Super_MF_trans_Percent = "Super_MF_trans_%",
  Super_MF_trans_min = "Super_MF_trans_min",
  Super_MF_trans_max = "Super_MF_trans_max",
  Super_MF_admin_fee = "Super_MF_admin_fee",
  Super_MF_admin_fee_cap = "Super_MF_admin_fee_cap",

  Super_H_bond_fees = "Super_H_bond_fees",
  Super_FI_bond = "Super_FI_bond",
  Super_Bond_fixed_trans = "Super_Bond_fixed_trans",
  Super_Bond_trans_Percent = "Super_Bond_trans_%",
  Super_Bond_trans_min = "Super_Bond_trans_min",
  Super_Bond_trans_max = "Super_Bond_trans_max",
  Super_Bond_hold_Currency = "Super_Bond_hold_$",
  Super_Bond_hold_Percent = "Super_Bond_hold_%",

  Super_H_MA_fees = "Super_H_MA_fees",
  Super_Managed_accounts = "Super_Managed_accounts",
  Super_MA_admin_fee = "Super_MA_admin_fee",
  Super_MA_admin_fee_cap = "Super_MA_admin_fee_cap",
  Super_MA_MF_trans_flat = "Super_MA_MF_trans_flat",
  Super_MA_MF_trans_Percent = "Super_MA_MF_trans_%",
  Super_MA_MF_trans_min = "Super_MA_MF_trans_min",
  Super_MA_MF_trans_max = "Super_MA_MF_trans_max",
  Super_MA_AU_bro_flat = "Super_MA_AU_bro_flat",
  Super_MA_AU_bro_Percent = "Super_MA_AU_bro_%",
  Super_MA_AU_bro_min = "Super_MA_AU_bro_min",
  Super_MA_AU_bro_max = "Super_MA_AU_bro_max",
  Super_MA_Intl_bro_flat = "Super_MA_Intl_bro_flat",
  Super_MA_Intl_bro_Percent = "Super_MA_Intl_bro_%",
  Super_MA_Intl_bro_min = "Super_MA_Intl_bro_min",
  Super_MA_Intl_bro_max = "Super_MA_Intl_bro_max",

  Super_H_offline_fees = "Super_H_offline_fees",
  Super_Offline_fees = "Super_Offline_fees",

  Super_H_ins_fees = "Super_H_ins_fees",
  Super_Ins_fee = "Super_Ins_fee",
  Super_Ins_Percent_prem = "Super_Ins_%_prem",
  Super_Max_ins_fee = "Super_Max_ins_fee",
  Super_Flat_ins_policy_fee = "Super_Flat_ins_policy_fee",

  H_other_fees_info = "H_other_fees_info",
  H_Minimums = "H_Minimums",
  IDPS_Min_initial_depo = "IDPS_Min_initial_depo",
  IDPS_Min_add_depo = "IDPS_Min_add_depo",

  H_fee_grouping = "H_fee_grouping",
  Fee_grouping = "Fee_grouping",
  Fee_grouping_both = "Fee_grouping_both",
  Agg_calc_rebate = "Agg_calc_rebate",
  Max_entity_per_group = "Max_entity_per_group",
  Max_acc_per_entity = "Max_acc_per_entity",
  Total_max_acc = "Total_max_acc",
  Admin_fee_max_family = "Admin_fee_max_family",
  Admin_fee_min_family = "Admin_fee_min_family",
  Account_tiered_min_prio = "Account_tiered_min_prio",
  Fee_discount = "Fee_discount",
  Fee_indiv_discount_threshold = "Fee_indiv_discount_threshold",
  Fee_discount_threshold = "Fee_discount_threshold",
  Indiv_fee_cap = "Indiv_fee_cap",
  Fixed_entity_fee = "Fixed_entity_fee",
  IDPS_Min_entity_fee = "IDPS_Min_entity_fee",
  Super_Min_entity_fee = "Super_Min_entity_fee",
  IDPS_Max_entity_fee = "IDPS_Max_entity_fee",
  Super_Max_entity_fee = "Super_Max_entity_fee",

  H_FX_fees = "H_FX_fees",
  FX_fee = "FX_fee",
  FX_spread = "FX_spread",

  H_CA_fees = "H_CA_fees",
  AU_IPO_fee_flat = "AU_IPO_fee_flat",
  AU_IPO_fee_Percent = "AU_IPO_fee_%",
  Intl_IPO_fee_flat = "Intl_IPO_fee_flat",
  Intl_IPO_fee_Percent = "Intl_IPO_fee_%",

  H_ext_bro = "H_ext_bro",
  AU_ext_broker = "AU_ext_broker",
  AU_ext_broker_fee = "AU_ext_broker_fee",
  Intl_ext_broker = "Intl_ext_broker",
  Intl_ext_broker_fee = "Intl_ext_broker_fee",

  H_asset_transfer = "H_asset_transfer",
  Transfer_out_fee = "Transfer_out_fee",
  Transfer_in_fee = "Transfer_in_fee",

  H_other_fees = "H_other_fees",
  Other_text = "Other_text",

  IDPS_1 = "IDPS_1",
  IDPS_2 = "IDPS_2",
  IDPS_3 = "IDPS_3",
  IDPS_4 = "IDPS_4",
  IDPS_5 = "IDPS_5",
  IDPS_6 = "IDPS_6",
  Super_1 = "Super_1",
  Super_2 = "Super_2",
  Super_3 = "Super_3",
  Super_4 = "Super_4",
  Super_5 = "Super_5",
  Super_6 = "Super_6",
  Entity_1 = "Entity_1",
  Entity_2 = "Entity_2",
  Entity_3 = "Entity_3",
  Entity_4 = "Entity_4",
  Entity_5 = "Entity_5",
  Entity_6 = "Entity_6",

  Choose_MF = "Choose_MF",
  Choose_AU = "Choose_AU",
  Choose_Intl = "Choose_Intl",
  Choose_MA = "Choose_MA",
  Choose_ret_ins = "Choose_ret_ins",
  Choose_bond = "Choose_bond",

  IDPS_Cash_balance = "IDPS_Cash_balance",
  Super_Cash_balance = "Super_Cash_balance",
  MF_balance_i = "MF_balance_i",
  AU_balance_i = "AU_balance_i",
  Intl_balance_i = "Intl_balance_i",
  MA_balance_i = "MA_balance_i",
  Bond_balance_i = "Bond_balance_i",

  Holdings_number = "Holdings_number",

  MF_transactions = "MF_transactions",
  AU_transactions = "AU_transactions",
  Intl_transactions = "Intl_transactions",
  Bond_transactions = "Bond_transactions",
  MF_avg = "MF_avg",
  AU_avg = "AU_avg",
  Intl_avg = "Intl_avg",
  Bond_avg = "Bond_avg",

  MA_MF_transactions = "MA_MF_transactions",
  MA_AU_transactions = "MA_AU_transactions",
  MA_Intl_transactions = "MA_Intl_transactions",
  MA_MF_avg = "MA_MF_avg",
  MA_AU_avg = "MA_AU_avg",
  MA_Intl_avg = "MA_Intl_avg",

  IDPS_num_ret_Ins = "IDPS_num_ret_Ins",
  Super_num_ret_Ins = "Super_num_ret_Ins",
  Premium_avg = "Premium_avg",
}
