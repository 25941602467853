import { SHButton, SHDialog, SHStack } from "@components/design-systems";

interface PublishDraftDialogProps {
  onClose?: () => void;
  onSubmit?: () => void;
  isSubmitting?: boolean;
}

const PublishDraftDialog = ({
  onClose,
  onSubmit,
  isSubmitting = false,
}: PublishDraftDialogProps) => {
  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: { xs: "100%", md: 468 },
        },
      }}
      header="Publish the changes?"
      body="The changes to the profile will be made visible to all users."
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onClose}
            disabled={isSubmitting}
          >
            No, don’t do anything
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onSubmit}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Yes, publish
          </SHButton>
        </SHStack>
      }
    />
  );
};

export default PublishDraftDialog;
