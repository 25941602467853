import {
  AnalysisDataDTO,
  SelectedProductDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { ReviewStatus } from "../enums/status";
import { ReviewStep } from "../enums/step";
import { ReviewSubStep } from "../enums/subStep";
import { ReviewStepBusinessMetricDTO } from "./steps/businessMetric";
import { ReviewStepFeatureDTO } from "./steps/feature";
import { ExportableReviewStepFeeDTO } from "./steps/fee";
import { ReviewStepSetupDTO } from "./steps/setup";
import { ReviewStepSummaryDTO } from "./steps/summary";
import { BusinessMetricTypeId } from "@models/configuration";

export class ReviewDTO {
  id: string = "";
  configurationVersion: number = 0;
  currentStep: ReviewStep = ReviewStep.Setup;
  currentSubStep?: ReviewSubStep;
  databaseVersion: Date = new Date();
  status: ReviewStatus = ReviewStatus.InProgress;
  previousStatus: string | null = null;
  isCompleted: boolean = false;
  setup: ReviewStepSetupDTO | null = new ReviewStepSetupDTO();
  feature: ReviewStepFeatureDTO | null = null;
  businessMetric: ReviewStepBusinessMetricDTO | null = null;
  fee: ExportableReviewStepFeeDTO | null = null;
  summary: ReviewStepSummaryDTO | null = null;
}

export class ReviewsODataDTO {
  id: string = "";
  name: string = "";
  familyGroupName: string = "";
  adviserUserName: string = "";
  adviserUserAvatarUrl: string = "";
  dataValidDate: Date = new Date();
  lastModifiedDate: Date = new Date();
  status: ReviewStatus = ReviewStatus.InProgress;
}

export class ReviewBannerInfoDTO {
  ownerName?: string = "";
  adviserFirmName?: string = "";
  familyGroupName?: string = "";
}

export class ReviewSelectionDTO {
  isCompleted: boolean = false;
  status: ReviewStatus = ReviewStatus.InProgress;
  hasRecalculate: boolean = false;
}

export interface ReviewFilterDTO {
  feeMissingInvestmentsStyle: any;
  investmentMenu?: any; 
  feeTableViewMode: any;
  feesDisplayStyle: any;
  appendixFeeMissingInvestmentsStyle?: any;
  showSubSection?: boolean;
  showSelectedFeatures?: boolean;
  showAppendixFeatureAnalysis?: boolean;
  showAppendixBusinessMetricAnalysis?: boolean;
  showAppendixFeeAnalysis?: boolean;
  hiddenSubProducts: HiddenSubProductDto[];
}

export interface HiddenSubProductDto {
  id: string;
  isRateCard: boolean;
  rateCardVersion: number;
}

export enum ReviewTableRowType {
  Section = "Section",
  SubSection = "SubSection",
  Feature = "Feature",
}

export class ReviewTableRow {
  id?: string;
  name?: string;
  iconUrl?: string;
  description?: string;
  order?: number;
  isEssential?: boolean;
  totalFeature?: number;
  totalSelectedFeature?: number;
  analysisData?: AnalysisDataDTO[];
  selectedProducts?: SelectedProductDTO[];
  collapsedIds?: string[];
  businessMetricTypeId?: BusinessMetricTypeId;
  businessMetricTypeName?: string;
  type: ReviewTableRowType = ReviewTableRowType.Section;
}
