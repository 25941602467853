import { SHButton, SHDialog, SHStack } from "@components/design-systems";
import { ReviewStep } from "@models/reviews/enums/step";
import { useMemo } from "react";

interface NotifyUnShortedListDialogProps {
  onOK?: () => void;
  step?: ReviewStep;
  showMaximumShortlisted?: boolean;
}

export const NotifyUnShortedListDialog = ({
  onOK,
  step,
  showMaximumShortlisted,
}: NotifyUnShortedListDialogProps) => {
  const textMessage = useMemo(() => {
    return step === ReviewStep.Fee && showMaximumShortlisted
      ? {
          title: "Maximum five platforms can be shortlisted",
          content:
            "In order to proceed to the final report, please reduce your shortlist of platforms to a maximum of 5.",
        }
      : {
          title: "At least one platform must be shortlisted",
          content:
            "In order to proceed to the next stage, please shortlist at least one platform.",
        };
    // eslint-disable-next-line
  }, [step]);
  return (
    <SHDialog
      open
      maxWidth={false}
      width={step === ReviewStep.Fee ? 600 : 550}
      disableEscapeKeyDown
      disableBackdropClick
      header={textMessage.title}
      body={textMessage.content}
      footer={
        <SHStack justifyContent="center" alignItems="center" direction={"row"}>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onOK}
            sx={{ width: "50%" }}
          >
            OK
          </SHButton>
        </SHStack>
      }
    />
  );
};
