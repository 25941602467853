import { Text } from "@react-pdf/renderer";
import { useStyles } from "../../../../styles";

export const PDFMainBadge = () => {
  const { typographyStyle } = useStyles();

  return (
    <Text
      style={{
        ...typographyStyle.body2,
        alignItems: "center",
        borderRadius: 10,
        borderWidth: 0.5,
        borderColor: "#FFAC8A",
        backgroundColor: "#FFE3D8",
        fontWeight: "bold",
        color: "#E84D1E",
        position: "absolute",
        top: -3,
        right: -3,
        fontSize: 6,
        paddingTop: 2,
        paddingRight: 1,
        paddingLeft: 2,
      }}
    >
      Main
    </Text>
  );
};
