import { PlusButton } from "@components/buttons/plus";
import {
  SHBox,
  SHButton,
  SHHtmlBlock,
  SHPopper,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { usePractice } from "@hooks/usePractice";
import { usePracticeBillingInfo } from "@hooks/usePracticeBillingInfo";
import { Collapse, useTheme } from "@mui/material";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useRef, useState } from "react";
import { PaymentMethodElement } from "./components/payment-method";
import { PaymentMethodList } from "./components/payment-method-list";
import { TooltipAllowSVG } from "@components/svgs";
import { hexToRGBA } from "@utils";
import { configTooltipStyle } from "@components/buttons/information/config";
import { isNil } from "lodash";

export type BillingInfoLeftSideProps = {};

export const BillingInfoLeftSide = (props: BillingInfoLeftSideProps) => {
  const { practiceId } = usePractice();
  const { notify } = useNotification();

  const { palette, shadows } = useTheme();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [hasBillingAddress, setHasBillingAddress] = useState(false);
  const tooltipConfig = configTooltipStyle["top-start"];

  const stripe = useStripe();
  const elements = useElements();

  const {
    ui,
    createPaymentMethod,
    practicePaymentMethod,
    practiceBillingDetail,
  } = usePracticeBillingInfo();

  const [isDisabled, setIsDisabled] = useState(true);
  const [showPaymentMethodElement, setShowPaymentMethodElement] =
    useState(false);

  useEffect(() => {
    if (stripe && elements) {
      const paymentElement = elements.getElement("payment");
      if (paymentElement) {
        paymentElement.on("change", (event) => {
          setIsDisabled(!event.complete);
        });
      }
    }
  });

  useEffect(() => {
    setHasBillingAddress(
      !(
        practiceBillingDetail?.billingAddress?.line1 === "" ||
        isNil(practiceBillingDetail?.billingAddress?.line1)
      ),
    );
  }, [practiceBillingDetail]);

  const handleHover = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!hasBillingAddress) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setOpen((prev) => !prev);
    }
  };

  const handleOnCancel = () => {
    if (!stripe || !elements) return;

    const paymentElement = elements.getElement("payment");
    if (paymentElement) paymentElement.clear();

    setShowPaymentMethodElement(false);
  };

  const handleOnCreatePaymentMethod = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    setIsDisabled(true);
    e.preventDefault();

    if (!stripe || !elements || !practiceId) return;

    elements.submit();

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      elements,
    });

    if (!error) {
      await createPaymentMethod(practiceId, paymentMethod?.id ?? "");
      handleOnCancel();
    } else {
      notify(error.message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <SHStack
      width={{ sx: "100%", md: "48%" }}
      minWidth={"500px"}
      marginBottom={4}
    >
      <form onSubmit={(e) => handleOnCreatePaymentMethod(e)}>
        <SHStack spacing={2}>
          <SHStack
            flexDirection={"row"}
            justifyContent={"space-between"}
            mb={1}
            sx={{ height: "30px" }}
          >
            <SHTypography>Payment method</SHTypography>
            {!showPaymentMethodElement ? (
              <>
                <SHBox onMouseOver={handleHover} onMouseLeave={handleHover}>
                  <PlusButton
                    size="medium"
                    onClick={() => setShowPaymentMethodElement(true)}
                    disabled={!hasBillingAddress}
                    sx={{
                      "&:disabled.sh-btn-with-icon": {
                        fill: palette.text.disabled,
                        border: `1px solid ${hexToRGBA(
                          palette.primary.dark,
                          0.3,
                        )}`,
                        backgroundColor: palette.primary[50],
                        color: palette.common.white,
                      },
                    }}
                  >
                    Add payment method
                  </PlusButton>
                </SHBox>
                <SHPopper
                  anchorEl={anchorEl}
                  open={open}
                  placement={"top-start"}
                  popperOptions={{
                    modifiers: [{ name: "flip", enabled: false }],
                  }}
                  sx={{ zIndex: 1400 }}
                >
                  <SHBox pb={1}>
                    <SHStack
                      ref={wrapperRef}
                      sx={{
                        p: 1.5,
                        ml: "130px",
                        maxWidth: "356px",
                        minHeight: "20px",
                        bgcolor: hexToRGBA(palette.common.white, 0.85),
                        border: "1px solid #E3E3E3",
                        backdropFilter: "blur(2px)",
                        borderRadius: "3px",
                        boxShadow: shadows[1],
                        position: "relative",
                      }}
                    >
                      <SHHtmlBlock
                        variant="body3"
                        zIndex={1}
                        color={palette.text.disabled}
                        content={
                          "Before adding a Credit Card, please add a billing address under Billing Details to the right."
                        }
                      />
                      <SHBox sx={tooltipConfig?.sx}>
                        <TooltipAllowSVG transform={tooltipConfig?.transform} />
                      </SHBox>
                    </SHStack>
                  </SHBox>
                </SHPopper>
              </>
            ) : (
              <SHStack direction={"row"} spacing={1}>
                <SHButton
                  color="primary"
                  variant="outlined"
                  onClick={handleOnCancel}
                >
                  Cancel
                </SHButton>
                <SHButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isDisabled}
                  isLoading={ui.paymentMethod.isSaving}
                >
                  Save
                </SHButton>
              </SHStack>
            )}
          </SHStack>

          <PaymentMethodList paymentMethods={practicePaymentMethod ?? []} />

          <Collapse in={showPaymentMethodElement}>
            <PaymentMethodElement isViewMode={ui.paymentMethod.isSaving} />
          </Collapse>
        </SHStack>
      </form>
    </SHStack>
  );
};
