import { PostFilters } from "@models/news-and-updates/entities/post";
import { RootState } from "@redux/store";
import {
  getPosts,
  updatePaginationToken,
  updatePostData,
  updatePromiseLoading,
} from "@redux/slices/news-feed";
import { useAppDispatch } from "@redux/store";
import { useSelector } from "react-redux";
import { useNotification } from "./useNotification";

export const useNewsFeed = () => {
  const { notify } = useNotification();
  const { loadPostsPromise, postFilters } = useSelector(
    (state: RootState) => state.newsFeed,
  );
  const dispatch = useAppDispatch();
  const loadPosts = async (filter?: PostFilters) => {
    const filterValues = filter ?? postFilters;
    if (loadPostsPromise) {
      loadPostsPromise?.abort();
    }
    if (
      filterValues?.productIds?.length !== 0 &&
      filterValues?.statuses?.length !== 0
    ) {
      const promise = dispatch(getPosts({ postFilters: filterValues }));
      dispatch(updatePromiseLoading(promise));
      const response = await promise.unwrap();
      if (!response.isSuccess) {
        notify(response.message, {
          variant: "error",
          close: true,
        });
      }
    } else {
      dispatch(updatePostData([]));
      dispatch(updatePaginationToken(""));
    }
  };

  return {
    loadPosts,
  };
};
