import { SHChip, SHChipProps } from "@components/design-systems";

interface BetaBadgeProps {}
export const BetaBadge = (props: BetaBadgeProps & SHChipProps) => {
  return (
    <SHChip
      label={"Beta"}
      variant="outlined"
      size="small"
      color={"info"}
      {...props}
    />
  );
};
