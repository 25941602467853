import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { FamilyGroupDTO } from "@models/family-group/entities/familyGroup";
import { RateCardLOV } from "@models/rate-card/entities/rateCard";
import {
  ReviewBannerInfoDTO,
  ReviewDTO,
  ReviewFilterDTO,
} from "@models/reviews/entities/review";
import {
  ReviewSaveCurrentStepDTO,
  ReviewStepDTO,
} from "@models/reviews/entities/step";
import { UpdateDecisionMarkingDTO } from "@models/reviews/entities/steps/feature";
import {
  ReviewStepSetupDTO,
  SelectedFamilyMemberDTO,
} from "@models/reviews/entities/steps/setup";
import { ReviewStep } from "@models/reviews/enums/step";
import { ReviewSubStep } from "@models/reviews/enums/subStep";
import { UserLOV } from "@models/users/entities/user";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { CancelToken } from "axios";
import { generatePath } from "react-router";
import { compileReview } from "./util";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import { ReviewStatus } from "@models/reviews/enums/status";

export async function getAdviserUsersLOVForReview(
  reviewId: string,
): Promise<APIResponse<UserLOV[]>> {
  const response = await APIGatewayService.get<UserLOV[]>(
    generatePath(APIExtRoutes.reviewAdviserUsers, { reviewId }),
  );
  return handleErrorProxy(response);
}

export async function getFamilyGroupsLOVForReview(
  reviewId: string,
  adviserUserId: string,
): Promise<APIResponse<FamilyGroupDTO[]>> {
  const response = await APIGatewayService.get<FamilyGroupDTO[]>(
    generatePath(APIExtRoutes.reviewFamilyGroups, { reviewId, adviserUserId }),
  );
  return handleErrorProxy(response);
}

export async function getFamilyMembersLOVForReview(
  reviewId: string,
  familyGroupId: string,
): Promise<APIResponse<SelectedFamilyMemberDTO[]>> {
  const response = await APIGatewayService.get<SelectedFamilyMemberDTO[]>(
    generatePath(APIExtRoutes.reviewFamilyMembers, { reviewId, familyGroupId }),
  );
  return handleErrorProxy(response);
}

export async function getRateCardsLOVForReview(
  reviewId: string,
  cancelToken: CancelToken,
  configurationVersion?: number,
): Promise<APIResponse<RateCardLOV[]>> {
  const response = await APIGatewayService.get<RateCardLOV[]>(
    generatePath(APIExtRoutes.reviewRateCards, { reviewId }),
    {
      params: {
        configurationVersion,
      },
      cancelToken,
    },
  );
  return handleErrorProxy(response);
}

export async function checkReviewUniqueName(
  id: string,
  adviserFirmId: string,
  ownerId: string,
  name?: string,
): Promise<APIResponse<unknown>> {
  const response = await APIGatewayService.post<unknown>(
    APIExtRoutes.reviewUniqueName,
    { id, adviserFirmId, ownerId, name },
  );
  return handleErrorProxy(response);
}

export async function postReview(
  setupStep: ReviewStepSetupDTO,
): Promise<APIResponse<ReviewDTO>> {
  const response = await APIGatewayService.post<ReviewDTO>(
    APIExtRoutes.reviews,
    setupStep,
  );
  return handleErrorProxy(response, "Review has been created.");
}

export async function getReviewSteps(
  reviewId?: string,
): Promise<APIResponse<ReviewStepDTO>> {
  const response = await APIGatewayService.get<ReviewStepDTO>(
    generatePath(APIExtRoutes.reviewSteps, { reviewId }),
  );

  return handleErrorProxy(response);
}

export async function putReviewCurrentStep(
  reviewId: string,
  currentStep: ReviewStep,
  currentSubStep?: ReviewSubStep,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.reviewSaveCurrentStep, {
      reviewId,
    }),
    {
      currentStep,
      currentSubStep,
    } as ReviewSaveCurrentStepDTO,
  );
  return handleErrorProxy(response, "Your current step has been saved.");
}

export async function getReviewBannerInfo(
  reviewId: string,
): Promise<APIResponse<ReviewBannerInfoDTO>> {
  const response = await APIGatewayService.get<ReviewBannerInfoDTO>(
    generatePath(APIExtRoutes.reviewBannerInfo, {
      reviewId,
    }),
  );
  return handleErrorProxy(response);
}

export async function getReviewDisclaimers(
  reviewId: string,
  stepId?: ReviewStep,
): Promise<APIResponse<DisclaimerDTO[]>> {
  const response = await APIGatewayService.get<DisclaimerDTO[]>(
    generatePath(APIExtRoutes.reviewDisclaimers, {
      reviewId,
      stepId,
    }),
  );
  return handleErrorProxy(response);
}
// Review's CUR APIs
export async function getReviewStep(
  reviewId: string,
  stepId: ReviewStep,
): Promise<APIResponse<ReviewDTO>> {
  const response = await APIGatewayService.get<ReviewDTO>(
    generatePath(APIExtRoutes.reviewStep, {
      reviewId,
      stepId,
    }),
  );

  if (response.data) {
    compileReview(response.data);
  }

  return handleErrorProxy(response);
}

export async function putReview(
  review: ReviewDTO,
): Promise<APIResponse<ReviewDTO>> {
  const response = await APIGatewayService.put<ReviewDTO>(
    APIExtRoutes.reviews,
    review,
  );
  return handleErrorProxy(response, "Changes have been saved.");
}

export async function putReviewDecisionMaking(
  decisionMarking: UpdateDecisionMarkingDTO,
): Promise<APIResponse<UpdateDecisionMarkingDTO>> {
  const response = await APIGatewayService.put<UpdateDecisionMarkingDTO>(
    APIExtRoutes.reviewDecisionMaking,
    decisionMarking,
  );
  return handleErrorProxy(response);
}

export async function disqualifyAllPlatform(
  reviewId: string,
  stepId: ReviewStep,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.reviewDisqualify, { reviewId, stepId }),
  );

  return handleErrorProxy(response);
}

export async function saveReviewFilters(
  reviewId: string,
  reviewFilters: ReviewFilterDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.reviewFilters, { reviewId }),
    reviewFilters,
  );

  return handleErrorProxy(response);
}

export async function updateFinalJSONs(
  reviewId: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.reviewFinalJSONs, { reviewId }),
  );

  return handleErrorProxy(response);
}

export async function putReviewStatus(reviewId: string, status: ReviewStatus) {
  const response = await APIGatewayService.put<ReviewStatus>(
    generatePath(APIExtRoutes.reviewStatus, {
      reviewId,
      status,
    }),
  );

  let message = "The review has been updated.";

  switch (response?.data) {
    case ReviewStatus.Archived:
      message = "The review has been archived.";
      break;
    case ReviewStatus.Restore:
      message = "The review has been restored.";
      break;
    case ReviewStatus.Completed:
      if (status === ReviewStatus.Restore) {
        message = "The review has been restored.";
      }
      if (status === ReviewStatus.Completed) {
        message = "Your review has been completed.";
      }
      break;
    default:
      break;
  }

  return handleErrorProxy(response, message);
}

export async function duplicateReviewService(
  reviewId: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.post<string>(
    generatePath(APIExtRoutes.reviewDuplicate, { reviewId }),
  );

  return handleErrorProxy(response, "The review has been duplicated.");
}
