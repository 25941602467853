import { SVGProps } from "react";

export const CalendarCheckedSVG = ({
  color = "#25324B",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    data-testid="calendar-checked-svg"
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="#332CC2">
      <path d="M11.187 8.91a.5.5 0 0 1 0 .709l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708l1.146 1.147L10.48 8.91a.5.5 0 0 1 .708 0Z" />
      <path d="M3.833.765a.5.5 0 0 1 .5.5v.5h8v-.5a.5.5 0 1 1 1 0v.5h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2h1v-.5a.5.5 0 0 1 .5-.5Zm-1.5 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-11a1 1 0 0 0-1-1h-12Z" />
      <path d="M2.833 4.765a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-1Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.333 .765)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
