import { OverallScoreDTO } from "@models/platform-analysis/entities/steps/feature";
import { PDFTableCell } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-cell";
import { PDFTableRow } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-row";
import {
  TABLE_CELL_WIDTH,
  TABLE_FIRST_CELL_WIDTH,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Text } from "@react-pdf/renderer";

interface PDFSummaryOverallScoreProps {
  overallScore?: OverallScoreDTO;
  isFeatureAnalysis?: boolean;
  color?: "dark" | "primary";
  isDetailMode?: boolean;
  isShowScore?: boolean;
}

export const PDFSummaryOverallScore = ({
  overallScore,
  isFeatureAnalysis,
  color = "dark",
  isDetailMode = false,
  isShowScore = false,
}: PDFSummaryOverallScoreProps) => {
  const { typographyStyle } = useStyles();
  const textColor = color === "primary" ? "#FFFFFF" : "#000000";
  let firstCellWidth = TABLE_FIRST_CELL_WIDTH;
  if (!isFeatureAnalysis || (isFeatureAnalysis && isDetailMode))
    firstCellWidth += TABLE_CELL_WIDTH;

  const isShowAnalysisScore =
    !isFeatureAnalysis ||
    (isFeatureAnalysis && (!isDetailMode || (isDetailMode && isShowScore)));

  return (
    <PDFTableRow
      type={isFeatureAnalysis ? "SingleLine" : "DoubleLine"}
      color={isDetailMode ? "primary" : "dark"}
    >
      <PDFTableCell alignItems="flex-start" width={firstCellWidth}>
        <Text
          style={{
            ...typographyStyle.body2,
            fontWeight: "bold",
            color: textColor,
          }}
        >
          Overall score
          {isDetailMode &&
            ` (${overallScore?.totalSelectedFeature}/${overallScore?.totalFeature})`}
        </Text>
      </PDFTableCell>

      {isFeatureAnalysis && !isDetailMode && (
        <PDFTableCell width={TABLE_CELL_WIDTH}>
          <Text
            style={{
              ...typographyStyle.body2,
              fontWeight: "bold",
              color: textColor,
            }}
          >
            {`${overallScore?.totalSelectedFeature}/${overallScore?.totalFeature}`}
          </Text>
        </PDFTableCell>
      )}

      {overallScore?.analysisData?.map((product, index) => (
        <PDFTableCell key={index} width={TABLE_CELL_WIDTH}>
          {isShowAnalysisScore && (
            <Text
              style={{
                ...typographyStyle.body2,
                fontWeight: "bold",
                color: textColor,
              }}
            >{`${product?.totalScore} %`}</Text>
          )}
        </PDFTableCell>
      ))}
    </PDFTableRow>
  );
};
