import { SHChip, SHChipProps } from "@components/design-systems";

export const MainProductBadge = (props: SHChipProps) => {
  return (
    <SHChip
      label={"Main"}
      color={"warning"}
      variant={"outlined"}
      {...props}
      sx={{
        fontSize: "10px",
        fontWeight: 500,
        padding: "2px",
        "&>.MuiChip-label": {
          padding: "0px 4px",
          textOverflow: "initial",
        },
        height: "auto",
        maxWidth: "none",
        ...props.sx,
      }}
    />
  );
};
