import { SVGProps } from "react";

export const DownloadSVG = ({
  color = "#fff",
  ...others
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={15}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 .65a.75.75 0 0 1 .75.75v6.62l1.184-1.36a.75.75 0 1 1 1.132.984l-2.5 2.873a.75.75 0 0 1-1.132 0l-2.5-2.873a.75.75 0 0 1 1.132-.984L6.25 8.02V1.4A.75.75 0 0 1 7 .65Zm-5.5 9.82a.75.75 0 0 1 .75.75v.403a1.25 1.25 0 0 0 1.246 1.25l7 .02a1.25 1.25 0 0 0 1.254-1.25v-.408a.75.75 0 0 1 1.5 0v.408a2.75 2.75 0 0 1-2.758 2.75l-7-.02a2.75 2.75 0 0 1-2.742-2.75v-.404a.75.75 0 0 1 .75-.75Z"
      fill={color}
    />
  </svg>
);
