export const MIN_LIGHTNESS = 20;
export const MAX_LIGHTNESS = 90;
export const DEFAULT_COLOR_TICK = 12;
export const DEFAULT_COLORS = [
  //#007bff
  {
    hue: 211,
    saturation: 100,
    lightness: 50,
  },
  //#6b38ca
  {
    hue: 261,
    saturation: 58,
    lightness: 51,
  },
  //#e84d1e
  {
    hue: 14,
    saturation: 81,
    lightness: 51,
  },
  //#007f58
  {
    hue: 162,
    saturation: 100,
    lightness: 25,
  },
  //#ef9116
  {
    hue: 34,
    saturation: 87,
    lightness: 51,
  },
  //#ea2787
  {
    hue: 330,
    saturation: 82,
    lightness: 54,
  },
  //#d32a2a
  {
    hue: 0,
    saturation: 67,
    lightness: 50,
  },
  //#211da2
  {
    hue: 242,
    saturation: 70,
    lightness: 37,
  },
  //#25324b
  {
    hue: 219,
    saturation: 34,
    lightness: 22,
  },
  //#e500c1
  {
    hue: 309,
    saturation: 100,
    lightness: 45,
  },
  //Repeat color
  //#007bff
  {
    hue: 211,
    saturation: 100,
    lightness: 50,
  },
  //#6b38ca
  {
    hue: 261,
    saturation: 58,
    lightness: 51,
  },
  //#e84d1e
  {
    hue: 14,
    saturation: 81,
    lightness: 51,
  },
  //#007f58
  {
    hue: 162,
    saturation: 100,
    lightness: 25,
  },
  //#ef9116
  {
    hue: 34,
    saturation: 87,
    lightness: 51,
  },
  //#ea2787
  {
    hue: 330,
    saturation: 82,
    lightness: 54,
  },
  //#d32a2a
  {
    hue: 0,
    saturation: 67,
    lightness: 50,
  },
  //#211da2
  {
    hue: 242,
    saturation: 70,
    lightness: 37,
  },
  //#25324b
  {
    hue: 219,
    saturation: 34,
    lightness: 22,
  },
  //#e500c1
  {
    hue: 309,
    saturation: 100,
    lightness: 45,
  },
  //Repeat color
  //#007bff
  {
    hue: 211,
    saturation: 100,
    lightness: 50,
  },
  //#6b38ca
  {
    hue: 261,
    saturation: 58,
    lightness: 51,
  },
  //#e84d1e
  {
    hue: 14,
    saturation: 81,
    lightness: 51,
  },
  //#007f58
  {
    hue: 162,
    saturation: 100,
    lightness: 25,
  },
  //#ef9116
  {
    hue: 34,
    saturation: 87,
    lightness: 51,
  },
  //#ea2787
  {
    hue: 330,
    saturation: 82,
    lightness: 54,
  },
  //#d32a2a
  {
    hue: 0,
    saturation: 67,
    lightness: 50,
  },
  //#211da2
  {
    hue: 242,
    saturation: 70,
    lightness: 37,
  },
  //#25324b
  {
    hue: 219,
    saturation: 34,
    lightness: 22,
  },
  //#e500c1
  {
    hue: 309,
    saturation: 100,
    lightness: 45,
  },
];
