import { SHDialog } from "@components/design-systems/sh-dialog";
import mainBgImage from "@assets/images/background/main.svg";
import { CloseButton } from "@components/buttons/close";
import { SHTypography, SHStack, SHButton } from "@components/design-systems";
import { FooterSVG } from "@components/svgs";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";

export interface MAFeeDisclaimerDialogProps {
  onClose?: () => void;
}

export const MAFeeDisclaimerDialog = ({
  onClose,
}: MAFeeDisclaimerDialogProps) => {
  const { palette } = useTheme();

  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          padding: 0,
          overflow: "hidden",
          backgroundImage: `url(${mainBgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: { xs: "100%", md: 700 },
          border: 0,
        },
      }}
    >
      <DialogTitle
        display={"flex"}
        flexDirection="row"
        alignItems="stretch"
        justifyContent="space-between"
        padding={"0px !important"}
        borderBottom={`1px solid ${palette.common.black}`}
      >
        <SHTypography
          variant="h2"
          fontWeight={600}
          fontSize={20}
          padding={"10px 40px !important"}
          borderRight={`1px solid ${palette.common.black}`}
          textAlign={"center"}
          textTransform={"uppercase"}
        >
          Fee calculator
        </SHTypography>
        <SHStack padding={"21px 31px"}>
          <CloseButton width="8.75px" height="8.75px" onClick={onClose} />
        </SHStack>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px !important", overflow: "hidden" }}>
        <SHStack textAlign={"justify"} marginY={"44px"} ml={"40px"} mr={"20px"}>
          <SHTypography variant="subtitle2" textTransform={"uppercase"}>
            {`Fee analysis disclaimer`}
          </SHTypography>
          <SHTypography variant="body2" mt="15px">
            Prototype data provided for feedback purposes only. Do not use
            calculator output for any decision making. Do not use output
            externally, especially not with advisers or their clients.
          </SHTypography>
          <SHTypography variant="body2" mt="15px">
            Fees displayed represent estimated fees for holding each model for 12 months.
            Past fees are not an indicator of future fees.
            The fees and costs breakdown are primarily based on a backward-looking allocation
            and performance of the model, and hence are likely to vary in the year ahead.
            Fee data was sourced directly from platform providers.
          </SHTypography>
        </SHStack>
        <FooterSVG style={{ marginBottom: "-5px" }} />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          borderTop: `1px solid ${palette.common.black}`,
          height: 110,
          margin: 0,
        }}
      >
        <SHButton
          color="primary"
          variant="contained"
          onClick={onClose}
          size="large"
          sx={{ width: "260px" }}
        >
          Close
        </SHButton>
      </DialogActions>
    </SHDialog>
  );
};
