import { SVGProps } from "react";

export const LoaderSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="400"
    height="400"
    viewBox="0 0 400 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_b_3371_38926)">
      <path
        d="M200 0C89.5431 0 0 89.5431 0 200C0 310.457 89.5431 400 200 400L200 366.667C107.953 366.667 33.3333 292.047 33.3333 200C33.3333 107.953 107.953 33.3333 200 33.3333L200 0Z"
        fill="url(#paint0_linear_3371_38926)"
      />
      <path
        d="M200 400C310.457 400 400 310.457 400 200C400 89.5431 310.457 0 200 0L200 33.3333C292.047 33.3333 366.667 107.953 366.667 200C366.667 292.047 292.047 366.667 200 366.667L200 400Z"
        fill="url(#paint1_linear_3371_38926)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.012 20.3064C158.834 11.1773 165.28 2.82192 174.409 1.6441C182.895 0.549222 191.443 6.10352e-05 200 6.10352e-05C209.205 6.10352e-05 216.667 7.46199 216.667 16.6667C216.667 25.8715 209.205 33.3334 200 33.3334C192.869 33.3334 185.746 33.791 178.674 34.7034C169.545 35.8812 161.189 29.4355 160.012 20.3064Z"
        fill="#332CC2"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_3371_38926"
        x="-1"
        y="-1"
        width="402"
        height="402"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_3371_38926"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_3371_38926"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_3371_38926"
        x1="200"
        y1="400"
        x2="200"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#332CC2" stopOpacity="0.5" />
        <stop offset="1" stopColor="#332CC2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3371_38926"
        x1="200"
        y1="400"
        x2="200"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#332CC2" stopOpacity="0.5" />
        <stop offset="1" stopColor="#332CC2" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
