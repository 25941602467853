import {
  SHBox,
  SHStack,
  SHTableCell,
  SHTableRow,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { ImageGalleryDialog } from "@components/image-gallery-dialog";
import { ArrowDownSVG, ImageSVG } from "@components/svgs";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import {
  AnalysisDataDTO,
  AnalysisFeatureDTO,
  AnalysisSectionDTO,
  ImageDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { Collapse, useTheme } from "@mui/material";
import { FeatureAnalysisFeatureTableRow } from "@pages/platform-analysis/_id/steps/feature/analysis/components/feature-row";
import {
  FeatureAnalysisTableCell,
  FeatureAnalysisTableCellProps,
} from "@pages/platform-analysis/_id/steps/feature/analysis/components/table-cell";
import { hexToRGBA } from "@utils";
import { isEmpty } from "lodash";
import { useState } from "react";
import { ReactImageGalleryItem } from "react-image-gallery";

interface FeatureAnalysisSubGroupTableRowProps {
  subGroupData?: AnalysisSectionDTO;
}

export const FeatureAnalysisSubGroupTableRow = ({
  subGroupData,
  ...others
}: FeatureAnalysisSubGroupTableRowProps &
  FeatureAnalysisTableCellProps &
  SHTableRowProps) => {
  const { palette } = useTheme();
  const { setTotalCollapsedFeatureAnalysisRows, isShowAnalysisScores } =
    usePlatformAnalysis();

  const [expandFeatures, setExpandFeatures] = useState(true);
  const [previewImages, setPreviewImages] = useState<ImageDTO[]>([]);

  return (
    <>
      <SHTableRow
        sx={{
          height: 54,
          background: `${hexToRGBA("#c5ddfd", 0.1)} !important`,
          transition: "all 0.5s ease",
        }}
        {...others}
      >
        <FeatureAnalysisTableCell isFirstColumn>
          <SHStack
            direction={"row"}
            alignItems={"center"}
            justifyContent="space-between"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setExpandFeatures(!expandFeatures);

              const totalSelectedFeature =
                subGroupData?.totalSelectedFeature ?? 0;
              setTotalCollapsedFeatureAnalysisRows(
                expandFeatures ? totalSelectedFeature : -totalSelectedFeature,
              );
            }}
          >
            <SHTypography variant="subtitle2" paddingTop={0} fontSize={13}>
              {subGroupData?.name}
            </SHTypography>
            <SHBox
              alignItems={"center"}
              sx={{
                display: "flex",
                transition: "all ease-in-out 0.2s",
                transform: `rotate(${expandFeatures ? 0 : 180}deg)`,
              }}
            >
              <ArrowDownSVG width={16} height={16} />
            </SHBox>
          </SHStack>
        </FeatureAnalysisTableCell>
        {subGroupData?.analysisData?.map((analysisData: AnalysisDataDTO) => (
          <FeatureAnalysisTableCell key={analysisData?.productId}>
            <SHStack
              direction={"row"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              {isShowAnalysisScores && (
                <>
                  <SHTypography
                    variant="subtitle2"
                    sx={{ lineHeight: "120%", fontWeight: 500, fontSize: 13 }}
                  >
                    Sub-group score
                  </SHTypography>
                  <SHTypography
                    variant="subtitle2"
                    sx={{ lineHeight: "120%", fontWeight: 500, fontSize: 13 }}
                  >
                    {`${analysisData?.totalScore}%`}
                  </SHTypography>{" "}
                </>
              )}

              {!isEmpty(analysisData.imageGallery) && (
                <SHBox
                  sx={{
                    padding: 0,
                    pl: isShowAnalysisScores ? 0 : "80px",
                    alignItems: "flex-end",
                    display: "flex",
                    "&:hover": {
                      "& .sh-image-svg": {
                        "& .sh-image-svg-g": {
                          stroke: palette.primary[800],
                        },
                      },
                    },
                  }}
                >
                  <ImageSVG
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPreviewImages(analysisData?.imageGallery ?? [])
                    }
                  />
                </SHBox>
              )}
            </SHStack>
          </FeatureAnalysisTableCell>
        ))}
      </SHTableRow>
      <SHTableRow>
        <SHTableCell
          colSpan={subGroupData?.analysisData?.length! + 1}
          sx={{ padding: 0, borderLeft: "none !important" }}
        >
          <Collapse in={expandFeatures} timeout="auto">
            {subGroupData?.features?.map((feature: AnalysisFeatureDTO) => (
              <FeatureAnalysisFeatureTableRow
                key={feature?.id}
                featureData={feature}
              />
            ))}
          </Collapse>
        </SHTableCell>
      </SHTableRow>

      <ImageGalleryDialog
        open={!isEmpty(previewImages)}
        title={subGroupData?.name}
        items={
          previewImages?.map((item: ImageDTO) => {
            return {
              original: `${item.url}`,
            };
          }) as ReactImageGalleryItem[]
        }
        onClose={() => setPreviewImages([])}
      />
    </>
  );
};
