import { SHStack, SHTypography } from "@components/design-systems";
import UnsavedDialog from "@components/dialogs/unsaved";
import { StatusBadge } from "@components/status-badge";
import { usePractice } from "@hooks/usePractice";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import {
  SubscriptionPricingInfoDTO,
  UserSubscriptionDTO,
} from "@models/practice/entities/practiceLicences";
import { SubscriptionHelper } from "@pages/practices/_id/_subscription/practice/components/helper";
import { SubscriptionLeftSide } from "@pages/practices/_id/_subscription/practice/components/left-side";
import { SubscriptionRightSide } from "@pages/practices/_id/_subscription/practice/components/right-side";
import { SubscriptionHelperData } from "@pages/practices/_id/_subscription/practice/config";
import { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { PracticeUserSubscriptionSkeleton } from "./skeleton";
import { SubscriptionProductType } from "@models/practice/enums/subscription";

export const PracticeUserSubscription = () => {
  const {
    practice,
    practiceId,
    loadPracticeUserLicences,
    loadPracticePricingInfo,
    setIsDirtySubscription,
    ui: {
      isLoadingUserLicences,
      isLoadingProceedPayment,
      isLoadingUpdateRenewal,
      subscription: { isDirty },
    },
  } = usePractice();

  const [userSubscriptions, setUserSubscriptions] =
    useState<UserSubscriptionDTO>();
  const [initialUserSubscriptions, setInitialUserSubscriptions] =
    useState<UserSubscriptionDTO>();
  const [pricingInfo, setPricingInfo] =
    useState<SubscriptionPricingInfoDTO>();

  const loadInitialData = async () => {
    if (!practiceId) return;
    const responseData = await loadPracticeUserLicences(practiceId);
    if (responseData) {
      setUserSubscriptions(responseData);
      setInitialUserSubscriptions(responseData);
    }

    const pricingInfoData = await loadPracticePricingInfo(
      practiceId,
      SubscriptionProductType.PlatformResearch,
    );

    if (pricingInfoData) {
      setPricingInfo(pricingInfoData);
    }
  };

  useEffectOnce(() => {
    loadInitialData();
    // eslint-disable-next-line
  });

  useEffect(() => {
    if (!userSubscriptions || !initialUserSubscriptions) return;
    const changedUserSubscriptions =
      JSON.stringify(userSubscriptions) !==
      JSON.stringify(initialUserSubscriptions);

    setIsDirtySubscription(changedUserSubscriptions);
    // eslint-disable-next-line
  }, [userSubscriptions, initialUserSubscriptions]);

  if (isLoadingUserLicences || !userSubscriptions || !initialUserSubscriptions)
    return <PracticeUserSubscriptionSkeleton />;

  return (
    <SHStack>
      <TopBarContainer>
        <TopBar
          title={practice?.name}
          customBadge={
            practice?.status ? (
              <StatusBadge status={practice.status} />
            ) : undefined
          }
        />
      </TopBarContainer>
      <SHStack spacing={"30px"}>
        <SHStack
          justifyContent={"space-between"}
          flexDirection={{ lg: "row", md: "row", sm: "column-reverse" }}
          alignItems={{ lg: "flex-start", md: "flex-start", sm: "center" }}
        >
          <SubscriptionLeftSide
            pricingInfo={pricingInfo}
            initialUserSubscription={initialUserSubscriptions}
            userSubscription={userSubscriptions}
            onChangeSubscription={setUserSubscriptions}
            onChangeInitialSubscription={setInitialUserSubscriptions}
          />
          <SubscriptionRightSide pricingInfo={pricingInfo} />
        </SHStack>
        <SHStack gap={"25px"}>
          <SHTypography variant="subtitle1"> FAQ</SHTypography>
          {SubscriptionHelperData?.map((item, index) => (
            <SubscriptionHelper key={index} helper={item} />
          ))}
        </SHStack>
        <UnsavedDialog
          isDirty={
            isDirty && !isLoadingUpdateRenewal && !isLoadingProceedPayment
          }
        />
      </SHStack>
    </SHStack>
  );
};
