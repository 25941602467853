import { APIExtRoutes } from "@constants/routes";
import {
  ConfigurationFeature,
  ConfigurationFeatureCreateDTO,
  ConfigurationFeatureUpdateDTO,
  ConfigurationGroup,
  ConfigurationSection,
  FeatureListOfValueDTO,
} from "@models/configuration";
import { APIResponse } from "@models/core";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getFeatureLov(
  groupId?: string,
): Promise<APIResponse<FeatureListOfValueDTO>> {
  const response = await APIGatewayService.get<
    APIResponse<FeatureListOfValueDTO>
  >(
    generatePath(APIExtRoutes.configurationFeatureLov, {
      groupId,
    }),
  );
  return handleErrorProxy(response);
}

// CURD
export async function getFeature(
  groupId: string,
  sectionId?: string,
  subSectionId?: string,
  featureId?: string,
) {
  const response = await APIGatewayService.get<APIResponse<ConfigurationGroup>>(
    generatePath(APIExtRoutes.configurationGroup, { groupId }),
  );

  let result: APIResponse = handleErrorProxy(response);

  if (result.isSuccess && result.data) {
    let feature = {};
    if (subSectionId) {
      feature = result.data?.sections
        ?.find((item: ConfigurationSection) => item.id === sectionId)
        ?.subSections?.find(
          (item: ConfigurationSection) => item.id === subSectionId,
        )
        ?.features?.find((item: ConfigurationFeature) => item.id === featureId);
    } else {
      feature = result.data?.sections
        ?.find((item: ConfigurationSection) => item.id === sectionId)
        ?.features?.find((item: ConfigurationFeature) => item.id === featureId);
    }
    result.data = feature;
  } else {
    result.data = undefined;
  }

  return result;
}

export async function createFeature(
  groupId: string,
  sectionId: string,
  feature?: ConfigurationFeatureCreateDTO,
) {
  const response = await APIGatewayService.post<APIResponse<any>>(
    generatePath(APIExtRoutes.createConfigurationFeature, {
      groupId,
      sectionId,
    }),
    feature,
  );

  return handleErrorProxy(response, "Feature has been created.");
}

export async function updateFeature(
  groupId: string,
  sectionId: string,
  featureId: string,
  feature?: ConfigurationFeatureUpdateDTO,
) {
  const response = await APIGatewayService.put<APIResponse<any>>(
    generatePath(APIExtRoutes.updateConfigurationFeature, {
      groupId,
      sectionId,
      featureId,
    }),
    feature,
  );
  return handleErrorProxy(response, "Feature has been changed.");
}
