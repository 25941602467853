import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHHtmlBlock,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { CheckSquareSVG, CrossSquareSVG } from "@components/svgs";
import {
  ConfigurationFeature,
  ConfigurationSection,
  FieldTypeId,
  SectionDisplayTypeId,
} from "@models/configuration";
import { Card, useTheme } from "@mui/material";
import { generateFeatureName } from "@utils/text-format";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { useEffectOnce } from "react-use";
export interface SidePanelSectionProps {
  section?: ConfigurationSection;
}

export default function SidePanelSection({ section }: SidePanelSectionProps) {
  const { palette } = useTheme();
  const [leftWidth, setLeftWidth] = useState<number>(50);

  useEffectOnce(() => {
    if (section?.features) {
      if (section.sectionDisplayType?.id === SectionDisplayTypeId.SidePanelRow) {
        setLeftWidth(100);
        return;
      }
      const fieldType = section.features[0].fieldType;

      switch (fieldType?.id) {
        case FieldTypeId.YesNo:
          setLeftWidth(80);
          break;
        case FieldTypeId.TextShort:
          setLeftWidth(65);
          break;
        default: setLeftWidth(70);
      }
    }
  });

  const displayFeatureValue = (feature: ConfigurationFeature) => {
    const value = feature.productData?.value;
    if (feature.fieldType?.id === FieldTypeId.YesNo) {
      switch (value) {
        case "true":
          return <CheckSquareSVG />;
        case "false":
          return <CrossSquareSVG />;
      }
    }
    if (!value) {
      return (
        <SHTypography
          variant="body2"
          colorVariant="third"
          sx={{
            minWidth: "54px",
          }}
        >
          No data
        </SHTypography>
      );
    }
    if (feature.fieldType?.id === FieldTypeId.Number) {
      return (
        <SHTypography variant="subtitle2">
          <NumericFormat
            displayType="text"
            value={value}
            thousandSeparator=","
          />
        </SHTypography>
      );
    }
    if (feature.fieldType?.id === FieldTypeId.TextShort) {
      return (<SHHtmlBlock
        textAlign={section?.sectionDisplayType?.id === SectionDisplayTypeId.SidePanelRow ? "left" : "right"}
        variant="body1"
        content={value}
      />);
    }
    return (
      <SHHtmlBlock
        textAlign={section?.sectionDisplayType?.id === SectionDisplayTypeId.SidePanelRow ? "left" : "right"}
        variant="subtitle2"
        content={value}
      />
    );
  };

  return (
    <Card
      variant="outlined"
      sx={{
        minWidth: 351,
        padding: "15px",
        border: `1px solid #${palette.secondary[100]}`,
        borderRadius: 0,
      }}
    >
      <SHStack>
        <SHStack direction="row" spacing={0.5}>
          <SHTypography variant="body1">{section?.name}</SHTypography>
          {section?.description && (
            <InformationButton content={section.description} />
          )}
        </SHStack>
        <SHStack spacing={ section?.sectionDisplayType?.id === SectionDisplayTypeId.SidePanelRow ? 2 : 0 }>
          {section?.features?.map((feature, index) => {
            const featureName = generateFeatureName(
              feature.name,
              feature.description,
            );
            return (
              <SHStack
                key={index}
                direction={
                  section.sectionDisplayType?.id === SectionDisplayTypeId.SidePanelRow ? "column" : "row"
                }
                spacing={section.sectionDisplayType?.id === SectionDisplayTypeId.SidePanelRow ? 0 : 2}
                justifyContent="space-between"
                sx={{
                  wordBreak: "break-word",
                  pt: "5px",
                }}
              >
                <SHTypography
                  variant="body2"
                  colorVariant="third"
                  textAlign="left"
                  width={`${leftWidth}%`}
                >
                  {featureName.first}
                  {feature?.description && (
                    <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                      {featureName.last}
                      <InformationButton
                        content={feature.description}
                        margin={"0px 0px 2px 4px !important"}
                      />
                    </SHBox>
                  )}
                </SHTypography>

                <SHBox
                  textAlign={leftWidth === 100 ? "left" : "right"}
                  sx={{
                    width: `${leftWidth === 100 ? leftWidth : 100 - leftWidth}%`
                  }}
                >
                  {displayFeatureValue(feature)}
                </SHBox>
              </SHStack>
            );
          })}
        </SHStack>
      </SHStack>
    </Card>
  );
}
