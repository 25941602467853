export enum ConfigurationEditingProcessActions {
  ConfigurationEditorChecking,
  ConfigurationEditorClaiming,
  ConfigurationEditingReleaseLock,
  ConfigurationBeingDisposed,
}

export enum ConfigurationEditingProcessResult {
  None,
  EditingPermissionAvailable,
  EditingPermissionClaimConfirm,
  EditingPermissionClaimFail,
  EditingPermissionClaimSuccessful,
  EditingPermissionDispose,
}

export enum ProductEditingProcessAction {
  ProductEditorChecking,
  ProductEditorClaiming,
  ProductEditingReleaseLock,
  ProductEditingBeingDisposed,
}

export enum ProductEditingProcessResult {
  None,
  EditingPermissionAvailable,
  EditingPermissionDenied,
  EditingPermissionClaimConfirm,
  EditingPermissionClaimFail,
  EditingPermissionClaimSuccessful,
  EditingPermissionBeingDisposed,
}

export interface SignalRResponse<T> {
  resultType: T;
  content: string;
}

export interface ProcessResponse<T> {
  resultType: T;
  content: string;
}

export const DefaultSocketErrorMessage = "Socket is disconnected, Please try again or refresh the page!";