import { View } from "@react-pdf/renderer";

interface PDFVerticalDashLineProps {
  colNumber: number;
}
export const PDFVerticalDashLine = ({
  colNumber,
}: PDFVerticalDashLineProps) => {
  return (
    <View
      style={{
        flexDirection: "row",
        paddingLeft: 3,
        justifyContent: "space-between",
      }}
    >
      {Array.from(Array(colNumber + 1).keys()).map((name, index) => (
        <View
          key={index}
          style={{
            width: 0.5,
            height: 3,
            backgroundColor: "#000000",
            transform:
              index === 0
                ? "translateX(1.5px)"
                : index === colNumber
                ? "translateX(-1.5px)"
                : undefined,
          }}
        />
      ))}
    </View>
  );
};
