import { InformationButton } from "@components/buttons/information";
import {
  SHStack,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { CheckSVG, CrossSVG, EssentialStarSVG } from "@components/svgs";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import {
  ReviewDataDTO,
  ReviewFeatureDTO,
  ReviewSelectedProductDTO,
} from "@models/reviews/entities/steps/feature";
import {
  FeatureReviewTableCell,
  FeatureReviewTableCellProps,
} from "@pages/reviews/_id/steps/feature/analysis/components/table-cell";
import { FeatureReviewTableHeadProps } from "@pages/reviews/_id/steps/feature/analysis/components/table-head/index";
import { generateReviewColorCell } from "@pages/reviews/_id/steps/feature/analysis/util";
import { isEmpty } from "lodash";
interface FeatureReviewFeatureTableRowProps {
  feature?: ReviewFeatureDTO;
}

export const FeatureReviewFeatureTableRow = ({
  feature,
  selectedProducts,
  sx: sxProps,
  ...others
}: FeatureReviewFeatureTableRowProps &
  FeatureReviewTableCellProps &
  SHTableRowProps &
  Pick<FeatureReviewTableHeadProps, "selectedProducts">) => {
  const renderAnalysisDataValue = (value?: AnalysisDataValue) => {
    if (value === AnalysisDataValue.True) return <CheckSVG />;
    if (value === AnalysisDataValue.False) return <CrossSVG />;
    if (value === AnalysisDataValue.MissingData || isEmpty(value))
      return (
        <SHTypography variant="body3" colorVariant="third" lineHeight="120%">
          No data
        </SHTypography>
      );
  };

  return (
    <>
      <FeatureReviewTableCell isFirstColumn>
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <SHTypography
            variant="body3"
            colorVariant="third"
            textAlign={"left"}
            lineHeight="120%"
          >
            {feature?.name}
          </SHTypography>
          <SHStack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={1}
          >
            {feature?.isEssential && <EssentialStarSVG />}
            {feature?.description && (
              <InformationButton content={feature.description} />
            )}
          </SHStack>
        </SHStack>
      </FeatureReviewTableCell>
      {feature?.analysisData?.map((analysisData: ReviewDataDTO) => {
        const platform = selectedProducts?.find(
          (selectedProduct: ReviewSelectedProductDTO) =>
            selectedProduct.id === analysisData.productId,
        );

        return (
          <FeatureReviewTableCell
            key={analysisData?.productId}
            colorCell={generateReviewColorCell(
              platform?.decisionStatus,
              platform?.isMissingEssential,
            )}
          >
            <SHStack
              direction={"row"}
              alignItems={"center"}
              justifyContent="flex-end"
            >
              <SHStack
                width={analysisData?.value !== "Missing data" ? 98 : 115}
                direction={"row"}
                alignItems={"center"}
                justifyContent="space-between"
              >
                {renderAnalysisDataValue(
                  analysisData?.value as AnalysisDataValue,
                )}
                {analysisData?.description && (
                  <InformationButton content={analysisData?.description} />
                )}
              </SHStack>
            </SHStack>
          </FeatureReviewTableCell>
        );
      })}
    </>
  );
};
