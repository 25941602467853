import { useTheme } from "@mui/material";
import { TABLE_WIDTH } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Path, Svg, Text, View } from "@react-pdf/renderer";

interface PDFEssentialNoticeSectionProps {
  alignItems?: "flex-start" | "flex-end" | "center";
}

export const PDFEssentialNoticeSection = ({
  alignItems = "flex-end",
}: PDFEssentialNoticeSectionProps) => {
  const { typographyStyle } = useStyles();
  const { palette } = useTheme();

  return (
    <View
      style={{
        marginTop: 3,
        height: 10,
        width: TABLE_WIDTH,
        alignItems: alignItems,
      }}
    >
      <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Svg width="7" height="7" viewBox="0 0 13 12">
          <Path
            fill="#FFF500"
            stroke="#F3612D"
            d="M7.71668 1.30902C7.41733 0.387703 6.11392 0.38771 5.81457 1.30902L4.94301 3.99139H2.1226C1.15387 3.99139 0.751103 5.23101 1.53481 5.80041L3.81658 7.4582L2.94502 10.1406C2.64567 11.0619 3.70015 11.828 4.48386 11.2586L6.76562 9.60081L9.04739 11.2586C9.8311 11.828 10.8856 11.0619 10.5862 10.1406L9.71467 7.4582L11.9964 5.80041C12.7801 5.23101 12.3774 3.99139 11.4087 3.99139H8.58824L7.71668 1.30902Z"
          ></Path>
        </Svg>
        <Text
          style={{
            ...typographyStyle.body2,
            color: palette.common.black,
            paddingLeft: 2,
          }}
        >
          Essential feature
        </Text>
      </View>
    </View>
  );
};
