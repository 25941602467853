import { SHStack, SHTypography } from "@components/design-systems";
import { StepNameSkeleton } from "@components/skeletons/layouts/stepper/StepNameSkeleton";

export interface StepNameProps {
  stepNumber?: number;
  name?: string;
  isLoading?: boolean;
  subStepContent?: string;
}
export const StepName = ({
  stepNumber = 0,
  name = "",
  isLoading,
  subStepContent,
}: StepNameProps) => {
  if (isLoading) return <StepNameSkeleton />;
  return (
    <SHStack>
      <SHStack direction="row" justifyContent="space-between">
        <SHTypography
          variant="h1"
          fontWeight={500}
          sx={{ p: 0, lineHeight: 1 }}
        >
          {`Step ${stepNumber}`}
        </SHTypography>
        {subStepContent ? (
          <SHTypography variant="body2">{subStepContent}</SHTypography>
        ) : null}
      </SHStack>
      <SHTypography variant="h1" sx={{ p: 0 }}>
        {name}
      </SHTypography>
    </SHStack>
  );
};
