export const hexToRGBA = (hexCode: string, opacity: number): string => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: any) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const getHSLLightness = (
  min: number,
  max: number,
  value: number,
  minLightness: number,
  maxLightness: number,
) => {
  const count = max - min;
  if (count === 0) return (maxLightness - minLightness) / 2;
  const average = (maxLightness - minLightness) / count;
  const position = value - min;
  const lightness = position * average + minLightness;
  return lightness;
};
