import { useAuth0 } from "@auth0/auth0-react";
import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { email } from "@constants/email";
import { KickoutType } from "@models/core";
import { Link, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

interface LoggedOutDialogProps {
  open: boolean;
  header?: string | React.ReactElement;
  body?: string | React.ReactElement;
}
export default function LoggedOutDialog({
  open,
  header,
  body,
}: LoggedOutDialogProps): JSX.Element {
  const { loginWithRedirect } = useAuth0();
  const { palette } = useTheme();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const dialogBody = useMemo(() => {
    const defaultMessage =
      "This account has logged in on another browser or device";
    if (body) return body;
    if (type) {
      switch (parseInt(type) as KickoutType) {
        case KickoutType.GrantSupplierOwnerPermission:
        case KickoutType.RevokeSupplierOwnerPermission:
          return "You have been logged out to complete the transfer process.";
        case KickoutType.RevokeUserPermission:
          return "Someone changed your account permissions. You need to log in again";
        case KickoutType.DisabledUser:
          return (
            <SHTypography variant="body2" fontSize={"16px"}>
              Your account has been disabled and you have been logged out. If
              you have any questions, please contact your firm’s administrator
              or reach out to SuitabilityHub
              <Link
                sx={{
                  marginLeft: "3px",
                  fontWeight: 600,
                  color: palette.text.secondary,
                }}
                href={`mailto:${email.support}`}
              >
                here
              </Link>
            </SHTypography>
          );
        default:
          return defaultMessage;
      }
    }
    return defaultMessage;
    // eslint-disable-next-line
  }, [body, type]);

  const dialogHeader = useMemo(() => {
    const defaultMessage = "You have been logged out";
    if (header) return header;
    if (type) {
      switch (parseInt(type) as KickoutType) {
        case KickoutType.GrantSupplierOwnerPermission:
        case KickoutType.RevokeSupplierOwnerPermission:
          return "Your firm’s Ownership has been transferred to another user";
        case KickoutType.RevokeUserPermission:
          return "You have been logged out";
        case KickoutType.DisabledUser:
          return "Your account has been disabled";
        default:
          return defaultMessage;
      }
    }
    return defaultMessage;
  }, [header, type]);

  return (
    <SHDialog
      maxWidth="lg"
      width={645}
      disableEscapeKeyDown
      disableBackdropClick
      open={open}
      header={dialogHeader}
      body={dialogBody}
      footer={
        <SHStack justifyContent="center" alignItems="center">
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={() =>
              loginWithRedirect({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              })
            }
          >
            Return to login page
          </SHButton>
        </SHStack>
      }
    />
  );
}
