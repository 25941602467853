import { FilterField } from "@models/news-and-updates/enums/filterField";
import { FilterName } from "@models/news-and-updates/enums/filterName";
import { FilterRoles } from "@models/news-and-updates/enums/filterRole";
import { NewsAndUpdatesMenuType } from "@models/news-and-updates/enums/menu";
import {
  PostPeriodValue,
  PostPeriod,
} from "@models/news-and-updates/enums/period";
import {
  PostStatus,
  PostStatusValue,
} from "@models/news-and-updates/enums/status";
import {
  NewsAndUpdatesMenuSections,
  PostFilter,
} from "@models/news-and-updates/entities/filtersMenu";

export const newsAndUpdatesMenuSectionsConfig: NewsAndUpdatesMenuSections[] = [
  {
    name: FilterName.Platforms,
    field: FilterField.Platforms,
    type: NewsAndUpdatesMenuType.CheckBox,
    subSections: [],
    roles: [
      FilterRoles.Supplier,
      FilterRoles.Admin,
      FilterRoles.SupplierSale,
      FilterRoles.AdviserUser,
    ],
  },
  {
    name: FilterName.Period,
    field: FilterField.Period,
    type: NewsAndUpdatesMenuType.RadioButton,
    subSections: [
      {
        name: PostPeriod.All,
        key: PostPeriodValue.All,
      },
      {
        name: PostPeriod.LastMonth,
        key: PostPeriodValue.LastMonth,
      },
      {
        name: PostPeriod.Last3Months,
        key: PostPeriodValue.Last3Months,
      },
      {
        name: PostPeriod.Last6Months,
        key: PostPeriodValue.Last6Months,
      },
      {
        name: PostPeriod.Last12Months,
        key: PostPeriodValue.Last12Months,
      },
      {
        name: PostPeriod.Custom,
        key: PostPeriodValue.Custom,
      },
    ],
    roles: [
      FilterRoles.Supplier,
      FilterRoles.Admin,
      FilterRoles.SupplierSale,
      FilterRoles.AdviserUser,
    ],
  },
  {
    name: FilterName.Status,
    field: FilterField.Status,
    type: NewsAndUpdatesMenuType.CheckBox,
    subSections: [
      {
        name: PostStatus.All,
        key: PostStatusValue.All,
      },
      {
        name: PostStatus.Pending,
        key: PostStatusValue.Pending,
      },
      {
        name: PostStatus.Published,
        key: PostStatusValue.Published,
      },
      {
        name: PostStatus.Archived,
        key: PostStatusValue.Archived,
      },
    ],
    roles: [FilterRoles.Admin],
  },
  {
    name: FilterName.Status,
    field: FilterField.Status,
    type: NewsAndUpdatesMenuType.CheckBox,
    subSections: [
      {
        name: PostStatus.All,
        key: PostStatusValue.All,
      },
      {
        name: PostStatus.Pending,
        key: PostStatusValue.Pending,
      },
      {
        name: PostStatus.Published,
        key: PostStatusValue.Published,
      },
    ],
    roles: [FilterRoles.Supplier],
  },
  {
    name: FilterName.Status,
    field: FilterField.Status,
    type: NewsAndUpdatesMenuType.CheckBox,
    subSections: [
      {
        name: PostStatus.All,
        key: PostStatusValue.All,
      },
      {
        name: PostStatus.Published,
        key: PostStatusValue.Published,
      },
    ],
    roles: [FilterRoles.SupplierSale],
  },
];

export const initialFilterState: PostFilter[] = [
  {
    section: FilterField.Period,
    subsections: [
      {
        name: PostPeriod.All,
        key: PostPeriodValue.All,
        value: true,
      },
      {
        name: PostPeriod.LastMonth,
        key: PostPeriodValue.LastMonth,
        value: false,
      },
      {
        name: PostPeriod.Last3Months,
        key: PostPeriodValue.Last3Months,
        value: false,
      },
      {
        name: PostPeriod.Last6Months,
        key: PostPeriodValue.Last6Months,
        value: false,
      },
      {
        name: PostPeriod.Last12Months,
        key: PostPeriodValue.Last12Months,
        value: false,
      },
      {
        name: PostPeriod.Custom,
        key: PostPeriodValue.Custom,
        value: false,
      },
    ],
  },
];

export const initialStatusFilterForAdmin = {
  section: FilterField.Status,
  subsections: [
    {
      name: PostStatus.All,
      key: PostStatusValue.All,
      value: false,
    },
    {
      name: PostStatus.Pending,
      key: PostStatusValue.Pending,
      value: true,
    },
    {
      name: PostStatus.Published,
      key: PostStatusValue.Published,
      value: true,
    },
    {
      name: PostStatus.Archived,
      key: PostStatusValue.Archived,
      value: false,
    },
  ],
};

export const initialStatusFilterForSupplier = {
  section: FilterField.Status,
  subsections: [
    {
      name: PostStatus.All,
      key: PostStatusValue.All,
      value: true,
    },
    {
      name: PostStatus.Pending,
      key: PostStatusValue.Pending,
      value: true,
    },
    {
      name: PostStatus.Published,
      key: PostStatusValue.Published,
      value: true,
    },
  ],
};
