import {
  SHAvatar,
  SHBox,
  SHStack,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { ArrowDownSVG } from "@components/svgs";
import {
  ReviewDataDTO,
  ReviewSectionDTO,
} from "@models/reviews/entities/steps/feature";
import {
  FeatureReviewTableCell,
  FeatureReviewTableCellProps,
} from "@pages/reviews/_id/steps/feature/analysis/components/table-cell";
import { FeatureReviewTableHeadProps } from "@pages/reviews/_id/steps/feature/analysis/components/table-head";

interface FeatureReviewSectionRowProps {
  section?: ReviewSectionDTO;
  isCollapsing?: boolean;
  onCollapse?: () => void;
}

export const FeatureReviewSectionRow = ({
  section,
  selectedProducts,
  isCollapsing = false,
  onCollapse = () => {},
  ...others
}: FeatureReviewSectionRowProps &
  FeatureReviewTableCellProps &
  SHTableRowProps &
  Pick<FeatureReviewTableHeadProps, "selectedProducts">) => {
  return (
    <>
      <FeatureReviewTableCell isFirstColumn colorCell="White">
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ cursor: "pointer" }}
          onClick={onCollapse}
        >
          <SHStack direction={"row"} alignItems={"center"} gap={"5px"}>
            {section?.iconUrl && (
              <SHAvatar
                src={section?.iconUrl}
                width={22}
                height={22}
                isCircular={false}
              />
            )}
            <SHTypography variant="subtitle2" paddingTop={0}>
              {section?.name}
            </SHTypography>
          </SHStack>
          <SHBox
            alignItems={"center"}
            sx={{
              display: "flex",
              transition: "all ease-in-out 0.2s",
              transform: `rotate(${!isCollapsing ? 0 : 180}deg)`,
            }}
          >
            <ArrowDownSVG width={16} height={16} />
          </SHBox>
        </SHStack>
      </FeatureReviewTableCell>
      {section?.analysisData?.map((analysisData: ReviewDataDTO) => (
        <FeatureReviewTableCell key={analysisData?.productId}>
          <SHStack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <SHTypography
              variant={"subtitle2"}
              lineHeight={"120%"}
              fontSize={13}
            >
              Group score
            </SHTypography>
            <SHTypography
              variant={"subtitle2"}
              lineHeight={"120%"}
              fontSize={13}
            >
              {` ${analysisData?.totalScore}%`}
            </SHTypography>
          </SHStack>
        </FeatureReviewTableCell>
      ))}
    </>
  );
};
