import { APIExtRoutes } from "@constants";
import { BusinessMetricCategories } from "@models/platform-analysis/entities/steps/businessMetric";
import {
  AnalysisConfigurationFeatureSectionDTO,
  AnalysisGetFeatureSectionsDTO,
  PlatformAnalysisFilter,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisFeeCalculatorDTO } from "@models/platform-analysis/entities/steps/fee";

import { AnalysisDTO } from "@models/platform-analysis/entities/analysis";
import { AnalysisBannerNameDTO } from "@models/platform-analysis/entities/bannerName";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import { AnalysisStepDTO } from "@models/platform-analysis/entities/step";
import { AnalysisSetupDTO } from "@models/platform-analysis/entities/steps/setup";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { AnalysisSubStep } from "@models/platform-analysis/enums/subStep";
import { updateReadOnlyAndComplete } from "@redux/slices/platform-analysis/util";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getBusinessMetricSelectionStep,
  putBusinessMetricSelectionStep,
} from "@services/platform-analysis/bisinessMetricAnalysisService";
import {
  getFeatureSelectionStep,
  putFeatureSelectionStep,
} from "@services/platform-analysis/featureAnalysisService";
import {
  getFeeCalculatorStep,
  putFeeCalculatorStep,
} from "@services/platform-analysis/feeAnalysisService";
import {
  getPlatformAnalysisAllSteps,
  getPlatformAnalysisBannerName,
  getPlatformAnalysisStep,
  postPlatformAnalysis,
  putPlatformAnalysis,
  putPlatformAnalysisCurrentStep,
} from "@services/platform-analysis/platformAnalysisService";
const { Setup, Feature, BusinessMetric, Fee, Summary } = AnalysisStep;
const { Selection, Analysis } = AnalysisSubStep;
const stepEnums = [Setup, Feature, BusinessMetric, Fee, Summary];
export interface PlatformAnalysisStore {
  ui: {
    isValid?: boolean;
    isDirty: boolean;
    isSubmitting?: boolean;
    isAccessDenied?: boolean;

    isLoading?: boolean;
    isLoadingSteps?: boolean;
    isLoadingBanner?: boolean;
    isShowHidden?: boolean;
    selectedStepIndex?: number;
    selectedSubStepIndex?: number;
    steps: {
      [key in AnalysisStep]: {
        isHidden?: boolean;
        isUnHighlighted?: boolean;
        subSteps?: {
          [key in AnalysisSubStep]: {
            isHidden?: boolean;
          };
        };
      };
    };
    filterFeature: PlatformAnalysisFilter[];
    collapsedSubGroupIds?: string[];
    reachAnalysisSetupPage?: "FIRST_TIME" | "SECOND_TIME";
    totalCollapsedFeatureAnalysisRows: number;
    totalCollapsedBusinessMetricRows: number;
    isShowAnalysisScores: boolean;
  };
  isCompleted?: boolean;
  isReadOnly?: boolean;
  analysisSteps?: AnalysisStepDTO;
  platformAnalysis?: AnalysisDTO;
  bannerName?: AnalysisBannerNameDTO;
  featureSelections?: AnalysisGetFeatureSectionsDTO;
  businessMetricSelections?: BusinessMetricCategories;
  feeCalculator?: AnalysisFeeCalculatorDTO;
  disclaimers?: DisclaimerDTO[];
  lineChartColors?: { [key in string]: string };
}
const initialState: PlatformAnalysisStore = {
  ui: {
    isValid: true,
    isDirty: false,
    isSubmitting: false,
    isAccessDenied: undefined,
    isShowHidden: false,
    selectedStepIndex: 0,
    selectedSubStepIndex: 0,
    steps: {
      [Setup]: {
        isHidden: false,
        isUnHighlighted: false,
      },
      [Feature]: {
        isHidden: true,
        isUnHighlighted: true,
        subSteps: {
          [Selection]: {
            isHidden: false,
          },
          [Analysis]: {
            isHidden: false,
          },
        },
      },
      [BusinessMetric]: {
        isHidden: true,
        isUnHighlighted: true,
        subSteps: {
          [Selection]: {
            isHidden: false,
          },
          [Analysis]: {
            isHidden: false,
          },
        },
      },
      [Fee]: {
        isHidden: true,
        isUnHighlighted: true,
        subSteps: {
          [Selection]: {
            isHidden: false,
          },
          [Analysis]: {
            isHidden: false,
          },
        },
      },
      [Summary]: {
        isHidden: false,
        isUnHighlighted: true,
      },
    },

    filterFeature: [
      PlatformAnalysisFilter.Common,
      PlatformAnalysisFilter.Differentiators,
    ],
    collapsedSubGroupIds: [],
    reachAnalysisSetupPage: undefined,
    totalCollapsedFeatureAnalysisRows: 0,
    totalCollapsedBusinessMetricRows: 0,
    isShowAnalysisScores: true,
  },
  isCompleted: false,
  isReadOnly: false,
  lineChartColors: undefined
};

const platformAnalysisSlice = createSlice({
  name: "platformAnalysis",
  initialState,
  reducers: {
    updateSelectedStepIndex: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.ui.selectedStepIndex = action.payload;
    },
    updateSelectedSubStepIndex: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.ui.selectedSubStepIndex = action.payload;
    },
    updateIsValid: (state, action: PayloadAction<boolean | undefined>) => {
      state.ui.isValid = action.payload;
    },
    updateIsCompleted: (state, action: PayloadAction<boolean | undefined>) => {
      state.isCompleted = action.payload;
    },
    updateIsReadOnly: (state, action: PayloadAction<boolean | undefined>) => {
      state.isReadOnly = action.payload;
    },
    updateIsDirty: (state, action: PayloadAction<boolean>) => {
      state.ui.isDirty = action.payload;
    },
    updateIsShowHidden: (state, action: PayloadAction<boolean | undefined>) => {
      state.ui.isShowHidden = action.payload;
    },
    updateIsShowAnalysisScores: (state, action: PayloadAction<boolean>) => {
      state.ui.isShowAnalysisScores = action.payload;
    },
    updateIsAccessDenied: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.ui.isAccessDenied = action.payload;
    },
    updateAnalysisSteps: (
      state,
      action: PayloadAction<AnalysisStepDTO | undefined>,
    ) => {
      state.analysisSteps = action.payload;
    },
    updateStepHidden: (
      state,
      action: PayloadAction<{
        step: AnalysisStep;
        isHidden: boolean | undefined;
      }>,
    ) => {
      state.ui.steps[action.payload.step].isHidden = action.payload.isHidden;
    },
    updateStepUnHighlighted: (
      state,
      action: PayloadAction<{
        step: AnalysisStep;
        isUnHighlighted: boolean | undefined;
      }>,
    ) => {
      state.ui.steps[action.payload.step].isUnHighlighted =
        action.payload.isUnHighlighted;
    },
    updateBannerName: (state, action: PayloadAction<AnalysisBannerNameDTO>) => {
      state.bannerName = { ...state.bannerName, ...action.payload };
    },
    updatePlatformAnalysisStore: (
      state,
      action: PayloadAction<AnalysisDTO | undefined>,
    ) => {
      state.platformAnalysis = action.payload;
    },
    updateFeatureSelectionsGroup: (
      state,
      action: PayloadAction<AnalysisConfigurationFeatureSectionDTO[]>,
    ) => {
      if (state.featureSelections?.sections) {
        state.featureSelections.sections = action.payload;
      }
    },
    updateFeatureSelectionStore: (
      state,
      action: PayloadAction<AnalysisGetFeatureSectionsDTO | undefined>,
    ) => {
      state.featureSelections = action.payload;
    },
    updateBusinessMetricSelectionStore: (
      state,
      action: PayloadAction<BusinessMetricCategories | undefined>,
    ) => {
      state.businessMetricSelections = action.payload;
    },
    updateFeeCalculatorStore: (
      state,
      action: PayloadAction<AnalysisFeeCalculatorDTO | undefined>,
    ) => {
      state.feeCalculator = action.payload;
    },
    resetAnalysisStore: (state, action: PayloadAction) => {
      state.analysisSteps = undefined;
      state.bannerName = undefined;
      state.platformAnalysis = undefined;
      state.featureSelections = undefined;
      state.businessMetricSelections = undefined;
      state.feeCalculator = undefined;
      state.isCompleted = false;
      state.isReadOnly = false;
      state.ui = initialState.ui;
    },
    updateFilterFeature: (
      state,
      action: PayloadAction<PlatformAnalysisFilter[]>,
    ) => {
      state.ui.filterFeature = action.payload;
    },
    updateCollapsedSubGroupIds: (state, action: PayloadAction<string[]>) => {
      state.ui.collapsedSubGroupIds = action.payload;
    },
    updateReachAnalysisSetupPage: (
      state,
      action: PayloadAction<"FIRST_TIME" | "SECOND_TIME" | undefined>,
    ) => {
      state.ui.reachAnalysisSetupPage = action.payload;
    },
    updateDisclaimers: (
      state,
      action: PayloadAction<DisclaimerDTO[] | undefined>,
    ) => {
      state.disclaimers = action.payload;
    },
    updateTotalCollapsedFeatureAnalysisRowsAction: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.ui.totalCollapsedFeatureAnalysisRows += action.payload;
    },
    updateTotalCollapsedBusinessMetricRowsAction: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.ui.totalCollapsedBusinessMetricRows += action.payload;
    },
    updateLineChartColorsAction: (state, action: PayloadAction<{ [key in string]: string }>) => {
      state.lineChartColors = action.payload;
    },
    resetFee: (state, action: PayloadAction) => {
      if (state.platformAnalysis) {
        state.platformAnalysis.fee = null;
      }
    },
  },
  extraReducers(builder) {
    //createPlatformAnalysis
    builder.addCase(createPlatformAnalysis.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(createPlatformAnalysis.fulfilled, (state, action) => {
      state.platformAnalysis = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.ui.isSubmitting = false;
    });
    builder.addCase(createPlatformAnalysis.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    //updatePlatformAnalysis
    builder.addCase(updatePlatformAnalysis.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(updatePlatformAnalysis.fulfilled, (state, action) => {
      state.ui.isSubmitting = false;
    });
    builder.addCase(updatePlatformAnalysis.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    //getPlatformAnalysis
    builder.addCase(getPlatformAnalysis.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(getPlatformAnalysis.fulfilled, (state, action) => {
      state.platformAnalysis = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      //updateReadOnlyAndComplete
      let newState = updateReadOnlyAndComplete(state, {
        isCompleted: state.platformAnalysis?.isCompleted,
        status: state.platformAnalysis?.status,
      });
      state.ui = newState.ui;
      state.isCompleted = newState.isCompleted;
      state.isReadOnly = newState.isReadOnly;
      //Set isLoading
      state.ui.isLoading = false;
    });
    builder.addCase(getPlatformAnalysis.rejected, (state, action) => {
      state.ui.isLoading = false;
    });

    //getAnalysisBannerName
    builder.addCase(getAnalysisBannerName.pending, (state, action) => {
      state.ui.isLoadingBanner = true;
    });
    builder.addCase(getAnalysisBannerName.fulfilled, (state, action) => {
      state.bannerName = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.ui.isLoadingBanner = false;
    });
    builder.addCase(getAnalysisBannerName.rejected, (state, action) => {
      state.ui.isLoadingBanner = false;
    });

    //getPlatformAnalysisSteps
    builder.addCase(getPlatformAnalysisSteps.pending, (state, action) => {
      state.ui.isLoadingSteps = true;
    });
    builder.addCase(getPlatformAnalysisSteps.fulfilled, (state, action) => {
      if (action.payload.isSuccess && action.payload.data) {
        const { steps, currentStep, currentSubStep, savedSteps } =
          action.payload.data;

        let selectedStepIndex = -1;
        stepEnums.forEach((step, index) => {
          if (steps.includes(step)) {
            state.ui.steps[step].isHidden = false;
            selectedStepIndex++;
          } else {
            state.ui.steps[step].isHidden = true;
          }
          if (currentStep === step) {
            state.ui.selectedStepIndex = selectedStepIndex;
          }
          state.ui.steps[step].isUnHighlighted = !savedSteps?.includes(step);
        });

        state.ui.selectedSubStepIndex =
          currentSubStep === AnalysisSubStep.Analysis ? 1 : 0;
      }
      state.ui.isLoadingSteps = false;
      state.analysisSteps = action.payload.data;
    });
    builder.addCase(getPlatformAnalysisSteps.rejected, (state, action) => {
      state.ui.isLoadingSteps = false;
    });

    // getAnalysisFeatureSelections;
    builder.addCase(getFeatureSelections.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(getFeatureSelections.fulfilled, (state, action) => {
      state.featureSelections = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      //updateReadOnlyAndComplete
      let newState = updateReadOnlyAndComplete(state, {
        isCompleted: action.payload?.data?.isCompleted,
        status: action.payload?.data?.status,
      });
      state.ui = newState.ui;
      state.isCompleted = newState.isCompleted;
      state.isReadOnly = newState.isReadOnly;
      state.ui.isLoading = false;
    });
    builder.addCase(getFeatureSelections.rejected, (state, action) => {
      state.ui.isLoading = false;
    });

    //updateAnalysisFeatureSelections;
    builder.addCase(updateFeatureSelection.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(updateFeatureSelection.fulfilled, (state, action) => {
      state.ui.isSubmitting = false;
    });
    builder.addCase(updateFeatureSelection.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    // getAnalysisBusinessMetricSelections;
    builder.addCase(getBusinessMetricSelections.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(getBusinessMetricSelections.fulfilled, (state, action) => {
      state.businessMetricSelections = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      //updateReadOnlyAndComplete
      let newState = updateReadOnlyAndComplete(state, {
        isCompleted: action.payload?.data?.isCompleted,
        status: action.payload?.data?.status,
      });
      state.ui = newState.ui;
      state.isCompleted = newState.isCompleted;
      state.isReadOnly = newState.isReadOnly;
      state.ui.isLoading = false;
    });
    builder.addCase(getBusinessMetricSelections.rejected, (state, action) => {
      state.ui.isLoading = false;
    });

    //updateAnalysisBusinessMetricSelections;
    builder.addCase(
      updateBusinessMetricSelectionThunk.pending,
      (state, action) => {
        state.ui.isSubmitting = true;
      },
    );
    builder.addCase(
      updateBusinessMetricSelectionThunk.fulfilled,
      (state, action) => {
        state.ui.isSubmitting = false;
      },
    );
    builder.addCase(
      updateBusinessMetricSelectionThunk.rejected,
      (state, action) => {
        state.ui.isSubmitting = false;
      },
    );

    // getAnalysisFeeCalculator;
    builder.addCase(getFeeCalculator.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(getFeeCalculator.fulfilled, (state, action) => {
      state.feeCalculator = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      //updateReadOnlyAndComplete
      let newState = updateReadOnlyAndComplete(state, {
        isCompleted: action.payload?.data?.isCompleted,
        status: action.payload?.data?.status,
      });
      state.ui = newState.ui;
      state.isCompleted = newState.isCompleted;
      state.isReadOnly = newState.isReadOnly;
      state.ui.isLoading = false;
    });
    builder.addCase(getFeeCalculator.rejected, (state, action) => {
      state.ui.isLoading = false;
    });

    //updateAnalysisFeeCalculator;
    builder.addCase(updateFeeCalculatorThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(updateFeeCalculatorThunk.fulfilled, (state, action) => {
      state.ui.isSubmitting = false;
    });
    builder.addCase(updateFeeCalculatorThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });
  },
});

export const createPlatformAnalysis = createAsyncThunk(
  `${APIExtRoutes.platformAnalysis}/create`,
  async (setupStep: AnalysisSetupDTO) => {
    return await postPlatformAnalysis(setupStep);
  },
);

export const updatePlatformAnalysis = createAsyncThunk(
  `${APIExtRoutes.platformAnalysis}/update`,
  async (platformAnalysis: AnalysisDTO) => {
    return await putPlatformAnalysis(platformAnalysis);
  },
);

export const getPlatformAnalysis = createAsyncThunk(
  APIExtRoutes.platformAnalysisStep,
  async ({
    platformAnalysisId,
    stepId,
  }: {
    platformAnalysisId: string;
    stepId: AnalysisStep;
  }) => {
    const subStep =
      stepId === Feature || stepId === BusinessMetric || stepId === Fee
        ? Analysis
        : undefined;
    const updateCurrentStep = await putPlatformAnalysisCurrentStep(
      platformAnalysisId,
      stepId,
      subStep,
    );
    if (!updateCurrentStep.isSuccess)
      return { ...updateCurrentStep, data: undefined };
    return await getPlatformAnalysisStep(platformAnalysisId, stepId);
  },
);

export const getAnalysisBannerName = createAsyncThunk(
  APIExtRoutes.platformAnalysisBannerName,
  async (platformAnalysisId: string) => {
    return await getPlatformAnalysisBannerName(platformAnalysisId);
  },
);

export const getPlatformAnalysisSteps = createAsyncThunk(
  APIExtRoutes.platformAnalysisAllSteps,
  async (platformAnalysisId?: string) => {
    return await getPlatformAnalysisAllSteps(platformAnalysisId);
  },
);

// Feature Analysis
export const getFeatureSelections = createAsyncThunk(
  APIExtRoutes.platformAnalysisFeatureSelections,
  async ({ platformAnalysisId }: { platformAnalysisId: string }) => {
    const updateCurrentStep = await putPlatformAnalysisCurrentStep(
      platformAnalysisId,
      Feature,
      Selection,
    );
    if (!updateCurrentStep.isSuccess)
      return { ...updateCurrentStep, data: undefined };
    return await getFeatureSelectionStep(platformAnalysisId);
  },
);

export const updateFeatureSelection = createAsyncThunk(
  `${APIExtRoutes.platformAnalysisFeatureSelections}/update`,
  async ({
    platformAnalysisId,
    featureSelections,
  }: {
    platformAnalysisId: string;
    featureSelections: AnalysisGetFeatureSectionsDTO;
  }) => {
    return await putFeatureSelectionStep(platformAnalysisId, featureSelections);
  },
);

// Business metric
export const getBusinessMetricSelections = createAsyncThunk(
  APIExtRoutes.platformAnalysisBusinessMetricSelections,
  async ({ platformAnalysisId }: { platformAnalysisId: string }) => {
    const updateCurrentStep = await putPlatformAnalysisCurrentStep(
      platformAnalysisId,
      BusinessMetric,
      Selection,
    );
    if (!updateCurrentStep.isSuccess)
      return { ...updateCurrentStep, data: undefined };
    return await getBusinessMetricSelectionStep(platformAnalysisId);
  },
);

export const updateBusinessMetricSelectionThunk = createAsyncThunk(
  `${APIExtRoutes.platformAnalysisBusinessMetricSelections}/update`,
  async ({
    platformAnalysisId,
    businessMetricSelections,
  }: {
    platformAnalysisId: string;
    businessMetricSelections: BusinessMetricCategories;
  }) => {
    return await putBusinessMetricSelectionStep(
      platformAnalysisId,
      businessMetricSelections,
    );
  },
);
// Fee
export const getFeeCalculator = createAsyncThunk(
  APIExtRoutes.platformAnalysisFeeCalculator,
  async ({ platformAnalysisId }: { platformAnalysisId: string }) => {
    const updateCurrentStep = await putPlatformAnalysisCurrentStep(
      platformAnalysisId,
      Fee,
      Selection,
    );
    if (!updateCurrentStep.isSuccess)
      return { ...updateCurrentStep, data: undefined };
    return await getFeeCalculatorStep(platformAnalysisId);
  },
);
export const updateFeeCalculatorThunk = createAsyncThunk(
  `${APIExtRoutes.platformAnalysisFeeCalculator}/update`,
  async ({
    platformAnalysisId,
    formData,
  }: {
    platformAnalysisId: string;
    formData: AnalysisFeeCalculatorDTO;
  }) => {
    return await putFeeCalculatorStep(platformAnalysisId, formData);
  },
);

export const {
  updateIsValid,
  updateIsDirty,
  updateIsCompleted,
  updateIsReadOnly,
  updateIsAccessDenied,
  updateIsShowHidden,
  updateIsShowAnalysisScores,
  updateStepHidden,
  updateBannerName,
  updateAnalysisSteps,
  updateFeatureSelectionsGroup,
  updateFeatureSelectionStore,
  updatePlatformAnalysisStore,
  updateStepUnHighlighted,
  updateSelectedStepIndex,
  updateSelectedSubStepIndex,
  updateFeeCalculatorStore,
  updateFilterFeature,
  updateBusinessMetricSelectionStore,
  updateCollapsedSubGroupIds,
  resetAnalysisStore,
  updateReachAnalysisSetupPage,
  updateDisclaimers,
  updateTotalCollapsedFeatureAnalysisRowsAction,
  updateTotalCollapsedBusinessMetricRowsAction,
  updateLineChartColorsAction,
  resetFee,
} = platformAnalysisSlice.actions;

export default platformAnalysisSlice.reducer;
