import { TableState } from "@tanstack/react-table";

export const DefaultDataState: Partial<TableState> = {
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
};

export const DefaultOdataErrorMessage = "Can't load data, Please try again!";
