import {
  SHAutocomplete,
  SHAutocompleteProps,
  SHAvatar,
  SHStack,
  SHTypography,
} from "@components/design-systems";

export interface ImageAutoCompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends SHAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  imageField?: string;
  labelField?: string;
  keyField?: string;
}
export const ImageAutoComplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  labelField = "label",
  imageField = "image",
  keyField,
  textFieldProps,
  value,
  options,
  onChange,
  ...others
}: ImageAutoCompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const compileValue: any = keyField
    ? options.find((option: any) => option[keyField] === value)
    : value;
  const imageUrl = (compileValue as any)?.[imageField];
  return (
    <SHAutocomplete
      {...others}
      value={compileValue}
      options={options}
      getOptionLabel={(option) => (option as any)[labelField]}
      onChange={
        onChange
          ? (event, newValue: any, reason, details) => {
              onChange(
                event,
                keyField ? newValue?.[keyField] ?? null : newValue,
                reason,
                details,
              );
            }
          : undefined
      }
      renderOption={(props, option) => (
        <li {...props} style={{ padding: "6px 10px" }}>
          <SHStack alignItems={"center"} direction="row" spacing={1}>
            <SHAvatar
              src={(option as any)[imageField]}
              height={"22px"}
              width={"22px"}
            />
            <SHTypography variant="body3">
              {(option as any)[labelField]}
            </SHTypography>
          </SHStack>
        </li>
      )}
      textFieldProps={{
        ...textFieldProps,
        InputLabelProps: { shrink: true },
        InputProps: {
          startAdornment: imageUrl ? (
            <SHAvatar src={imageUrl} height={"22px"} width={"22px"} />
          ) : undefined,
          sx: {
            paddingLeft: !imageUrl ? "0px" : "10px",
          },
        },
      }}
    />
  );
};
