export enum PostStatus {
    All = "All",
    Pending = "Pending",
    Published = "Published",
    Archived = "Archived"
}

export enum PostStatusValue {
    All = "0",
    Pending = "1",
    Published = "2",
    Archived = "3"
}
