import { SHButton, SHButtonProps } from "@components/design-systems";
import { useTheme } from "@mui/material";

export const DisclaimerAssumptionsButton = (props: SHButtonProps) => {
  const { palette } = useTheme();
  return (
    <SHButton
      variant="text"
      color="secondary"
      sx={{
        fontSize: "10px",
        fontWeight: 600,
        p: 0,
        border: "none",
        height: "auto",
        textDecoration: "underline",
        "&:active": { border: "none" },
        "&:hover": { textDecoration: "underline", color: palette.primary.dark },
      }}
      {...props}
    >
      disclaimer and assumptions.
    </SHButton>
  );
};
