import { SelectPractices } from "@components/auto-completes/practice";
import { DeleteButton } from "@components/buttons/delete";
import {
  SHAlert,
  SHBooleanField,
  SHBox,
  SHButton,
  SHDivider,
  SHIconLoading,
  SHSelect,
  SHStack,
  SHTextField,
  SHUploadImage,
} from "@components/design-systems";
import { uploadOptions } from "@components/design-systems/sh-upload-file/config";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import UnsavedDialog from "@components/dialogs/unsaved";
import { StatusBadge } from "@components/status-badge";
import { CheckSVG, CloseSVG } from "@components/svgs";
import { PageRoutes } from "@constants/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppParams } from "@hooks/useAppParams";
import { useIsNew } from "@hooks/useIsNew";
import { useNotification } from "@hooks/useNotification";
import { useSupplier } from "@hooks/useSupplier";
import { useUserPermissions } from "@hooks/userUserPermission";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { PageMode, ParentState } from "@models/core";
import { PracticeDTO } from "@models/supplier/entities/practices";
import { SupplierDTO } from "@models/supplier/entities/suppliers";
import { RootState } from "@redux/store";
import {
  deleteAvatar,
  uploadAvatar,
} from "@services/file-storage/fileStorageService";
import {
  createSupplier,
  getSubscriptionProducts,
  getSupplier,
  updateSupplier,
} from "@services/suppliers";
import { renameFile } from "@utils/data-type/file";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { FileRejection } from "react-dropzone";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffectOnce } from "react-use";
import {
  generateFormTitle,
  supplierPayloadConverter,
  supplierValidators,
} from "../util";
import {
  SubscriptionProductPriceType,
  SubscriptionProductType,
} from "@models/supplier/enums/subscriptionProductType";
import { LicenseeDiscountDTO } from "@models/supplier/entities/supplierUser";
import { useDispatch } from "react-redux";
import { updateSupplier as updateSupplierStore } from "@redux/slices/supplier";
export interface SuppliersOverviewProps {
  supplierData?: SupplierDTO;
}

export const SupplierOverview = (props: SuppliersOverviewProps) => {
  const isNew = useIsNew();
  const location = useLocation();
  const dispatch = useDispatch();
  const { notify } = useNotification();
  const navigate = useNavigate();
  const { supplierId } = useParams<{ supplierId: string }>();
  const parentState = location.state as ParentState;
  const { isMySupplier } = useAppParams();
  const {
    isSupplierGroup,
    canViewSupplierOverview,
    canEditSupplierOverview,
    isAdminGroup,
  } = useUserPermissions();
  const { supplierData } = useSelector((state: RootState) => state.supplier);

  const { loadPracticesByAFSL, isSupplierUniqueAFSL } = useSupplier();

  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...new SupplierDTO(),
      companyName: isNew ? "" : supplierData?.companyName ?? "",
    },
    resolver: yupResolver(supplierValidators),
  });
  const { isDirty } = useFormState({ control });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [uploadMessage, setUploadMessage] = useState<string | null>(null);
  const [verifyAFSL, setVerifyAFSL] = useState<
    "error" | "success" | "checking" | "none"
  >(isNew ? "none" : "success");
  const [practicesData, setPracticesData] = useState<PracticeDTO[]>([]);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [hasLinkedPractices, setHasLinkedPractice] = useState(false);
  const [isValidAFSL, setIsValidAFSL] = useState<boolean>(true);
  const [licenseeDiscounts, setLicenseeDiscounts] = useState<
    LicenseeDiscountDTO[]
  >([]);

  const isDisabled = useMemo(() => {
    return isSubmitting || !isEditMode;
  }, [isSubmitting, isEditMode]);
  const accessDenied =
    !canViewSupplierOverview || (isSupplierGroup && !isMySupplier);
  const currentStatus = getValues("status") ?? null;
  // Handle file storage functions
  const handleOnUploadLogo = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (!isEmpty(fileRejections))
      setUploadMessage("Unsupported file format. Please upload another file.");

    if (isEmpty(acceptedFiles)) return;

    const file = new FormData();
    file.append("file", renameFile(acceptedFiles[0]));

    setIsUploading(true);
    const { data, message } = await uploadAvatar(file);
    if (data) {
      setValue("companyLogo", data?.url, uploadOptions.hookForm);
      setValue("logoS3Key", data?.key, uploadOptions.hookForm);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setIsUploading(false);
  };

  const handleOnDeleteLogo = async () => {
    const avatarS3Key = getValues("logoS3Key");
    if (!avatarS3Key) return;
    setIsDeleting(true);
    const { isSuccess, message } = await deleteAvatar(avatarS3Key);
    setIsDeleting(false);
    if (isSuccess) {
      setValue("logoS3Key", "", uploadOptions.hookForm);
      setValue("companyLogo", "", uploadOptions.hookForm);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleSubmitSupplier = async (supplier: SupplierDTO) => {
    const subscriptionProductDefault = licenseeDiscounts.find(
      (item) => item.priceType === SubscriptionProductPriceType.Full,
    );

    const dataSubmit: SupplierDTO = {
      ...supplier,
      afsl: watch("isLicensee") ? supplier.afsl : null,
      platformResearchAppliedPrice: watch("isLicensee")
        ? supplier.platformResearchAppliedPrice
        : subscriptionProductDefault?.id,
    };

    if (isNew) {
      await handleOnCreateSupplier(dataSubmit);
    } else {
      await handleOnUpdateSupplier(dataSubmit);
    }
  };

  const handleOnCreateSupplier = async (supplier: SupplierDTO) => {
    const { data, message, isSuccess } = await createSupplier(
      supplierPayloadConverter(supplier),
    );
    if (isSuccess && data) {
      notify(message, {
        variant: "success",
        close: true,
      });
      reset(data);
      setIsEditMode(false);
      navigate(`${PageRoutes.suppliers}/${data?.id}`, {
        replace: false,
      });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };
  const handleOnUpdateSupplier = async (supplier: SupplierDTO) => {
    if (!supplierId) return;
    const { message, isSuccess } = await updateSupplier(
      supplierId,
      supplierPayloadConverter(supplier),
    );
    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      loadSupplierDetails();
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };
  const loadSupplierDetails = async () => {
    if (isNew) return;
    const { data, message } = await getSupplier(supplierId);
    if (data) {
      reset(data);
      dispatch(updateSupplierStore(data));
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const clearTimer = () => {
    if (timer.current) clearTimeout(timer.current);
  };

  const loadPracticesData = async (afsl: string) => {
    if (!afsl) return;

    const data = await loadPracticesByAFSL(afsl);
    setPracticesData(data ?? []);
    setHasLinkedPractice(
      data?.some((practice) => practice.licenseeId === supplierId) ?? false,
    );
  };

  const handleOnChecking = async (afsl: string | null) => {
    clearTimer();
    setPracticesData([]);
    setValue("linkedPractices", []);
    setHasLinkedPractice(false);
    setIsValidAFSL(false);

    if (!afsl || afsl.trim().length === 0) {
      setVerifyAFSL("none");
      return;
    }
    if (supplierData?.afsl === afsl) {
      setVerifyAFSL("success");
      setIsValidAFSL(true);
      loadPracticesData(afsl);
      return;
    }
    setVerifyAFSL("checking");
    timer.current = setTimeout(async () => {
      const isUnique = await isSupplierUniqueAFSL(afsl);

      if (isUnique) {
        setVerifyAFSL("success");
        setIsValidAFSL(true);
        loadPracticesData(afsl);
      } else {
        setVerifyAFSL("error");
        setError("afsl", {
          message:
            "This AFSL number already exists. Please enter a new AFSL number.",
        });
      }
    }, 600);
  };

  const renderVerifyAFSL = () => {
    switch (verifyAFSL) {
      case "success":
        return <CheckSVG width={18} height={18} />;
      case "error":
        return (
          <SHBox px={"4px"} py={"1px"}>
            <CloseSVG width={10} height={10} />
          </SHBox>
        );
      case "checking":
        return <SHIconLoading size={16} />;
      default:
        return null;
    }
  };

  const loadSubscriptionProducts = async () => {
    const response = await getSubscriptionProducts(
      SubscriptionProductType.PlatformResearch,
    );

    if (response.isSuccess && response.data) {
      setLicenseeDiscounts(
        response.data.map((subscriptionProduct) => {
          if (
            !watch("platformResearchAppliedPrice") &&
            subscriptionProduct.priceType === SubscriptionProductPriceType.Full
          ) {
            setValue("platformResearchAppliedPrice", subscriptionProduct.id);
          }
          return {
            id: subscriptionProduct.id,
            value: subscriptionProduct.displayPrice,
            priceType: subscriptionProduct.priceType,
          };
        }),
      );
    }
  };

  useEffectOnce(() => {
    if (isAdminGroup) {
      loadSubscriptionProducts();
    }

    if (isEmpty(supplierData?.afsl)) return;

    if (isAdminGroup) {
      loadPracticesData(supplierData?.afsl ?? "");
      return;
    }

    if (supplierData?.linkedPractices) {
      setPracticesData(
        supplierData.linkedPractices.map((practice) => {
          return { ...practice, licenseeId: supplierId };
        }),
      );
    }
  });

  useEffectOnce(() => {
    return () => {
      clearTimer();
    };
  });

  useEffect(() => {
    if (parentState?.pageMode === PageMode.Edit || isNew) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [parentState?.pageMode, isNew]);

  useEffect(() => {
    if (!isNew && supplierData) reset(supplierData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!isNew, supplierData]);

  if (accessDenied) return <AccessDeniedDialog />;
  return (
    <form>
      <TopBarContainer>
        <TopBar
          title={generateFormTitle(watch("companyName"))}
          customBadge={
            currentStatus ? <StatusBadge status={currentStatus} /> : undefined
          }
          tools={
            canEditSupplierOverview ? (
              <>
                {isNew && (
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    onClick={() => {
                      navigate(`${PageRoutes.suppliers}`, {
                        replace: false,
                      });
                    }}
                  >
                    Cancel
                  </SHButton>
                )}
                {isEditMode ? (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    type="submit"
                    disabled={
                      !isValid || isSubmitting || !isDirty || !isValidAFSL
                    }
                    isLoading={isSubmitting}
                    onClick={handleSubmit(handleSubmitSupplier)}
                  >
                    Save
                  </SHButton>
                ) : (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    type="submit"
                    onClick={() => setIsEditMode(true)}
                  >
                    Edit
                  </SHButton>
                )}
              </>
            ) : undefined
          }
        />
      </TopBarContainer>
      <SHStack direction={"column"} mb={watch("isLicensee") ? "100px" : "25px"}>
        <FieldGroup
          firstLine
          title="Key company information"
          subTitle={
            isMySupplier
              ? "Manage your firm’s SuitabilityHub profile"
              : "Manage this firm’s SuitabilityHub profile"
          }
        />
        <SHDivider />
        <FieldGroup
          title="Company logo"
          subTitle="This image will be used to represent the firm on SuitabilityHub"
          required
        >
          <Controller
            name="companyLogo"
            control={control}
            render={({ field }) => (
              <SHUploadImage
                objectFit="contain"
                showImage={!isEmpty(getValues("companyLogo"))}
                isUploading={isUploading}
                src={field.value}
                actionIcon={
                  !isEmpty(getValues("companyLogo")) &&
                  isEditMode && (
                    <DeleteButton
                      isLoading={isDeleting}
                      onClick={handleOnDeleteLogo}
                    />
                  )
                }
                error={Boolean(errors.companyLogo)}
                helperText={
                  errors.companyLogo ? errors.companyLogo.message : null
                }
                dropzoneOptions={{
                  multiple: false,
                  accept: {
                    "image/*": [".svg", ".png", ".jpg", ".gif"],
                  },
                  onDrop: handleOnUploadLogo,
                  disabled: isDisabled || isUploading,
                }}
              />
            )}
          />
          {uploadMessage && (
            <SHStack
              direction={"row"}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
              sx={{ paddingBottom: 3 }}
            >
              <SHAlert
                onClose={() => setUploadMessage(null)}
                severity="error"
                width={490}
              >
                {uploadMessage}
              </SHAlert>
            </SHStack>
          )}
        </FieldGroup>
        <SHDivider />
        <FieldGroup
          title="Company details"
          subTitle="Update key information. We will prioritise getting in touch with the key contact for any administrative, technical or subscription enquiries"
        >
          <SHStack
            spacing={3}
            direction="column"
            sx={{ width: { xs: "100%", md: 520 } }}
          >
            <Controller
              name="companyName"
              control={control}
              rules={{ required: "Company name required" }}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  required
                  fullWidth
                  hasCounter
                  maxLength={100}
                  label="Company name"
                  placeholder="Enter your company’s name"
                  disabled={isDisabled}
                  error={!!errors.companyName}
                  helperText={
                    errors.companyName ? errors.companyName.message : null
                  }
                />
              )}
            />
            <Controller
              name="website"
              control={control}
              rules={{ required: "Website required" }}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  required
                  fullWidth
                  label="Website"
                  placeholder="Enter your company URL"
                  disabled={isDisabled}
                  error={!!errors.website}
                  helperText={errors.website ? errors.website.message : null}
                />
              )}
            />
            <Controller
              name="contractName"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  fullWidth
                  label="Key contact name"
                  placeholder="Enter name of key contact"
                  disabled={isDisabled}
                  error={!!errors.contractName}
                  helperText={
                    errors.contractName ? errors.contractName.message : null
                  }
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  fullWidth
                  label="Key contact number"
                  placeholder="Enter phone number of key contact"
                  disabled={isDisabled}
                  error={!!errors.phoneNumber}
                  helperText={
                    errors.phoneNumber ? errors.phoneNumber.message : null
                  }
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  fullWidth
                  label="Key contact email"
                  placeholder="Enter email of key contact"
                  disabled={isDisabled}
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : null}
                />
              )}
            />
          </SHStack>
        </FieldGroup>
        <SHDivider />
        <FieldGroup
          title="Licensee settings"
          subTitle="Update licensee settings, which allow you to link and manage advice firms who share the same AFSL"
        >
          <SHStack
            spacing={3}
            direction="column"
            sx={{ width: { xs: "100%", md: 520 } }}
          >
            <Controller
              name="isLicensee"
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SHBooleanField
                  {...other}
                  isSwitch
                  isShowClearButton={false}
                  value={other.value}
                  onChange={async (event, value?: boolean | null) => {
                    if (value) {
                      handleOnChecking(getValues("afsl"));
                      const subscriptionProductDefault = licenseeDiscounts.find(
                        (item) =>
                          item.priceType === SubscriptionProductPriceType.Full,
                      );
                      if (!watch("platformResearchAppliedPrice"))
                        setValue(
                          "platformResearchAppliedPrice",
                          subscriptionProductDefault?.id,
                        );
                    }
                    other.onChange(value);
                    if (!value) setIsValidAFSL(true);
                  }}
                  label={"Is this supplier a licensee?"}
                  disabled={isDisabled || !isAdminGroup || hasLinkedPractices}
                />
              )}
            />
            {watch("isLicensee") && (
              <SHStack
                spacing={3}
                direction="column"
                sx={{ paddingY: 1, width: { xs: "100%", md: 520 } }}
              >
                <Controller
                  name="afsl"
                  control={control}
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      fullWidth
                      required
                      label="Australian Financial Services Licence (AFSL) number"
                      placeholder="Enter your AFSL number"
                      disabled={isDisabled || !isAdminGroup}
                      error={!!errors.afsl}
                      helperText={errors.afsl ? errors.afsl.message : null}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (
                          e.target.value === "" ||
                          regex.test(e.target.value)
                        ) {
                          field.onChange(e.target.value);
                          handleOnChecking(e.target.value);
                        }
                      }}
                      hasCounter
                      maxLength={6}
                      InputProps={{
                        sx: {
                          "&>input": {
                            pr: "32px !important",
                          },
                        },
                        endAdornment: (
                          <SHStack
                            sx={{
                              position: "absolute",
                              right: "8px",
                            }}
                          >
                            {renderVerifyAFSL()}
                          </SHStack>
                        ),
                      }}
                    />
                  )}
                />
                <Controller
                  name={"linkedPractices"}
                  control={control}
                  render={({ field: { ref, ...other } }) => (
                    <SelectPractices
                      {...other}
                      disabled={isDisabled || !isAdminGroup}
                      textFieldProps={{
                        label: "Select practices to link to the licensee",
                        placeholder: "Search for practice",
                      }}
                      onChange={async (values) => {
                        setHasLinkedPractice(!!values?.length);
                        other.onChange(values);
                      }}
                      practiceData={practicesData}
                      supplierId={supplierId}
                    />
                  )}
                />
              </SHStack>
            )}
          </SHStack>
        </FieldGroup>
        {(watch("isLicensee") && isAdminGroup) && (
          <>
            <SHDivider />
            <FieldGroup
              title="Licensee discount"
              subTitle={
                "Annual subscription fees that will apply to practices linked to this licensee"
              }
            >
              <Controller
                name={"platformResearchAppliedPrice"}
                control={control}
                render={({ field: { ref, ...others } }) => (
                  <SHSelect
                    sx={{ width: { xs: "100%", md: 520 } }}
                    displayField="value"
                    dataItemKey="id"
                    data={
                      !isEmpty(watch("licenseeDiscounts"))
                        ? watch("licenseeDiscounts")
                        : licenseeDiscounts
                    }
                    disabled={isDisabled}
                    label="Platform research: Per adviser per year"
                    placeholder={"Select a licensee discount"}
                    {...others}
                  />
                )}
              />
            </FieldGroup>
          </>
        )}
      </SHStack>
      <UnsavedDialog isDirty={isDirty && !isSubmitting} />
    </form>
  );
};
