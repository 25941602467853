import { PermissionChecking, UserPermission } from "@models/auth";
import { RootState } from "@redux/store";
import { useSelector } from "react-redux";

export function usePermission({
  permissions = [],
  roles = [],
  comparePermissionsMode = "some",
}: PermissionChecking): boolean {
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    user?.permissions !== undefined &&
    user?.userType !== undefined &&
    (permissions.length === 0 ||
      (comparePermissionsMode === "some"
        ? user.permissions.some((permission: UserPermission) =>
            permissions.includes(permission),
          )
        : permissions.every((permission: UserPermission) =>
            user.permissions.includes(permission),
          ))) &&
    (roles.length === 0 || roles.includes(user.userType))
  );
}
