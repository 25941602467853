import { SHStack } from "@components/design-systems";
import {
  SHDividerSkeleton,
  SHTextFieldSkeleton,
  SHUploadImageSkeleton,
} from "@components/skeletons/design-systems";
import {
  SHFieldGroupSkeleton,
  SHFiledGroupTitleSkeleton,
} from "@components/skeletons/layouts";

export const PracticeOverviewSkeleton = () => {
  return (
    <SHStack spacing={3}>
      <SHFieldGroupSkeleton leftSide={<SHFiledGroupTitleSkeleton />} />
      <SHDividerSkeleton />
      <SHFieldGroupSkeleton
        leftSide={<SHFiledGroupTitleSkeleton subTitleRows={2} />}
        rightSide={<SHUploadImageSkeleton />}
      />
      <SHDividerSkeleton />
      <SHFieldGroupSkeleton
        leftSide={<SHFiledGroupTitleSkeleton subTitleRows={1} />}
        rightSide={
          <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
            <SHTextFieldSkeleton />
            <SHTextFieldSkeleton />
            <SHTextFieldSkeleton />
          </SHStack>
        }
      />
    </SHStack>
  );
};
