import { DateFormat } from "@constants/format";
import {
  DEFAULT_GAP,
  DEFAULT_GAP_2,
  FIRST_PAGE_BODY_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { PDFAnalysisOverview } from "@pages/platform-analysis/components/buttons/export-pdf/model";
import { Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import { isEmpty } from "lodash";

interface PDFAnalysisOverviewProps {
  overview: PDFAnalysisOverview;
}

export const PDFAnalysisOverviewSection = ({
  overview,
}: PDFAnalysisOverviewProps) => {
  const { typographyStyle } = useStyles();
  const textColor = "#FFFFFF";
  const {
    adviserName,
    practiceName,
    ownerName,
    supplierName,
    analysisName,
    lastModifiedDate,
    dataValidDate,
    serial,
  } = overview;

  return (
    <View
      style={{
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexDirection: "column",
        height: FIRST_PAGE_BODY_HEIGHT - DEFAULT_GAP,
      }}
    >
      <Text
        style={{
          ...typographyStyle.title,
          color: textColor,
          fontSize: 24,
        }}
      >
        {analysisName}
      </Text>

      <View style={{ gap: DEFAULT_GAP_2 }}>
        {!isEmpty(adviserName) && !isEmpty(practiceName) ? (
          <Text
            style={{
              ...typographyStyle.title,
              color: textColor,
              fontWeight: "normal",
            }}
          >
            {`This analysis is for: `}
            <Text
              style={{
                ...typographyStyle.title,
                fontWeight: "bold",
                color: textColor,
              }}
            >
              {adviserName}
            </Text>
            {` from `}
            <Text
              style={{
                ...typographyStyle.title,
                fontWeight: "bold",
                color: textColor,
              }}
            >
              {practiceName}
            </Text>
          </Text>
        ) : (
          <Text
            style={{
              ...typographyStyle.title,
              color: textColor,
              fontWeight: "normal",
            }}
          >
            {`This analysis is created for `}
            <Text
              style={{
                ...typographyStyle.title,
                fontWeight: "bold",
                color: textColor,
              }}
            >
              {supplierName}
            </Text>
            {` internal use only`}
          </Text>
        )}

        <Text
          style={{
            ...typographyStyle.title,
            color: textColor,
            fontWeight: "normal",
          }}
        >
          {`Created by: `}
          <Text style={typographyStyle.title}>{ownerName}</Text>
          {` of `}
          <Text style={typographyStyle.title}>{supplierName}</Text>
        </Text>
        <Text
          style={{
            ...typographyStyle.subtitle,
            color: textColor,
            fontWeight: "normal",
          }}
        >
          {`${
            lastModifiedDate
              ? `Last edit: ${format(new Date(lastModifiedDate), DateFormat)}`
              : ""
          }  |  ${
            dataValidDate
              ? `Data valid: ${format(new Date(dataValidDate), DateFormat)}`
              : ""
          } |  Serial: ${serial}`}
        </Text>
      </View>
    </View>
  );
};
