import { useNotification } from "@hooks/useNotification";
import {
  CreateRateCardDTO,
  RateCardStatus,
  UpdateRateCardDTO,
} from "@models/rate-card/entities/rateCard";
import {
  createRateCardThunk,
  loadRateCardLOVThunk,
  loadRateCardThunk,
  updateRateCardThunk,
} from "@redux/slices/rate-cards";
import { useAppDispatch } from "@redux/store";
import { putRateCardStatus } from "@services/rate-card/rateCardService";

export const useRateCard = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();

  const loadRateCard = async (rateCardId: string) => {
    const response = await dispatch(loadRateCardThunk(rateCardId)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadRateCardLOV = async () => {
    const response = await dispatch(loadRateCardLOVThunk()).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const createRateCard = async (rateCard: CreateRateCardDTO) => {
    const response = await dispatch(createRateCardThunk(rateCard)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updateRateCard = async (rateCard: UpdateRateCardDTO) => {
    const response = await dispatch(updateRateCardThunk(rateCard)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updateRateCardStatus = async (
    rateCardId: string,
    status: RateCardStatus,
  ) => {
    const response = await putRateCardStatus(rateCardId, status);
    if (response.isSuccess && response.data) {
      notify(response.message, {
        variant: "success",
        close: true,
      });
    } else {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
    return response.data;
  };

  return {
    loadRateCard,
    loadRateCardLOV,
    createRateCard,
    updateRateCard,
    updateRateCardStatus,
  };
};
