import { SHFilterOption } from "@components/design-systems";
import { ProductListDataStatus } from "@models/product/enums/productListDataStatus";
import { ProductType } from "@models/product/enums/productType";
import { ProductStatus } from "@models/product/enums/status";

export const ProductDataStatusFilterOptions = [
  {
    label: "Data required",
    value: ProductListDataStatus.Missing,
  },
  {
    label: "Pending approval",
    value: ProductListDataStatus.Pending,
  },
];

export const ProductStatusFilterOptions: SHFilterOption[] = [
  {
    label: "Active",
    value: ProductStatus.Active,
  },
  {
    label: "Disabled",
    value: ProductStatus.Disabled,
  },
  {
    label: "Archived",
    value: ProductStatus.Archived,
  },
];

export const ProductTypeFilterOptions: SHFilterOption[] = [
  {
    label: "Platform",
    value: ProductType.Platform,
  },
  {
    label: "SMA",
    value: ProductType.SMA,
  },
];
