import { SHStack, SHStackProps } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";

interface WrapperStripeElementProps {
  isViewMode?: boolean;
}
export const WrapperStripeElement = ({
  isViewMode = true,
  ...others
}: WrapperStripeElementProps & SHStackProps) => {
  const { palette } = useTheme();

  return (
    <SHStack
      sx={{
        p: "20px 15px",
        bgcolor: palette.common.white,
        boxShadow: `10px 10px 40px 0px ${hexToRGBA("#DDDDDD", 0.4)}`,
        backdropFilter: "blur(0.5px)",
        border: `1px solid #${palette.secondary[100]}`,
        ":read-only": {
          opacity: isViewMode ? 0.5 : 0.9,
          pointerEvents: isViewMode ? "none" : "auto",
        },
      }}
      {...others}
    />
  );
};
