import { View } from "@react-pdf/renderer";

interface PDFReviewTableRowProps {
  children: React.ReactNode;
  color?: "white" | "gray" | "dark";
  height?: string | number;
  borderBottomWidth?: string | number;
}

export const PDFReviewTableRow = ({
  children,
  height,
  color = "white",
  borderBottomWidth,
}: PDFReviewTableRowProps) => {
  const colorMapping = {
    white: "#FFFFFF",
    gray: "#e0e2e5",
    dark: "#b1b6bf",
  };

  return (
    <View
      style={{
        flexDirection: "row",
        height: height ?? "auto",
        minHeight: height ?? "auto",
        borderColor: "#7C8493",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: borderBottomWidth ?? 0,
        backgroundColor: color ? colorMapping[color] : "transparent",
      }}
    >
      {children}
    </View>
  );
};
