import { ImageDTO } from "@models/platform-analysis/entities/steps/feature";

//TODO: Remove after BE Fix
export const parseCommentProductLogo = (logo?: string | null) => {
  if (!logo) return;
  if (logo[0] !== "{") return logo;
  try {
    return (JSON.parse(logo) as ImageDTO).url;
  } catch (e) {
    return logo;
  }
};
