import { PlusButton } from "@components/buttons/plus";
import {
  SHButton,
  SHContainer,
  SHImage,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHDataGrid } from "@components/design-systems/sh-data-grid";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { DateFormat } from "@constants/format";
import { APIExtRoutes, PageRoutes } from "@constants/routes";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar } from "@layouts/top-bar";
import { PageMode, ParentState } from "@models/core/navigation";
import { ReadAdviserFirmDTO } from "@models/practice/entities/practice";
import { useTheme } from "@mui/material";
import { TableState, createColumnHelper } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { format, isValid } from "date-fns";
import { useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  AdviserFirmStatusFilterOptions,
  DefaultAdviserFirmStatusFilterValue,
} from "./constant";

const nameOf = nameOfFactory<ReadAdviserFirmDTO>();
const columnHelper = createColumnHelper<ReadAdviserFirmDTO>();

export const PracticesPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [gridState, setGridState] = useState<Partial<TableState>>({
    ...DefaultDataState,
    columnFilters: [
      {
        id: nameOf("status"),
        value: DefaultAdviserFirmStatusFilterValue,
      },
    ],
    sorting: [{ id: nameOf("name"), desc: false }],
  });
  const { isAdminGroup } = useUserPermissions();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: nameOf("name"),
        header: "Practice name",
        enableColumnFilter: false,
        cell: (props) => (
          <SHStack
            spacing={2}
            direction="row"
            alignItems={"center"}
            justifyContent={"start"}
          >
            <SHImage
              width={40}
              height={40}
              style={{ objectFit: "contain" }}
              alt={props.cell.getValue().name}
              src={props.cell.getValue().logo}
            />
            <SHTypography variant="subtitle2">
              {props.cell.getValue().name}
            </SHTypography>
          </SHStack>
        ),
      }),
      columnHelper.accessor("signUpDate", {
        header: "Sign up date",
        cell: (props) => {
          const date = props.cell.getValue();
          return date ? format(new Date(date), DateFormat) : "";
        },
        enableColumnFilter: false,
        meta: {
          sx: {
            width: "200px",
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: nameOf("totalLicences"),
        header: "Adviser users",
        enableColumnFilter: false,
        cell: (props) =>
          `${props.cell.getValue().totalUsedLicences} of ${
            props.cell.getValue().totalLicences
          }`,
        meta: {
          sx: {
            width: "180px",
          },
        },
      }),
      columnHelper.accessor("nextBillingDate", {
        header: "Renewal date",
        cell: (props) => {
          const date = props.cell.getValue();
          return date && isValid(date)
            ? format(new Date(date), DateFormat)
            : "";
        },
        enableColumnFilter: false,
        meta: {
          sx: {
            width: "200px",
          },
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => <StatusBadge status={props.cell.getValue()} />,
        meta: {
          filterData: AdviserFirmStatusFilterOptions,
          sx: {
            width: "180px",
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "actionCol",
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (props) => (
          <SHStack spacing={2} direction="row">
            <SHButton
              startIcon={<EditSVG color={palette.common.white} />}
              variant="contained"
              onClick={() =>
                navigate(
                  generatePath(PageRoutes.practiceOverview, {
                    practiceId: props.cell.getValue()?.id,
                  }),
                  { state: { pageMode: PageMode.Edit } as ParentState },
                )
              }
            >
              Edit
            </SHButton>
            <SHButton
              variant="outlined"
              onClick={() =>
                navigate(
                  generatePath(PageRoutes.practiceOverview, {
                    practiceId: props.cell.getValue()?.id,
                  }),
                  { state: { pageMode: PageMode.View } as ParentState },
                )
              }
            >
              View
            </SHButton>
          </SHStack>
        ),
        meta: {
          sx: {
            width: "190px",
          },
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!isAdminGroup) return <AccessDeniedDialog />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack spacing={3} sx={{ paddingY: 3 }}>
        <TopBar
          title={"Advice practices"}
          tools={
            <>
              <PlusButton
                variant="contained"
                size="extraMedium"
                onClick={() =>
                  navigate(
                    generatePath(PageRoutes.practiceOverview, {
                      practiceId: "new",
                    }),
                  )
                }
              >
                New practice
              </PlusButton>
            </>
          }
        />
        <SHDataGrid
          columns={columns as any}
          state={gridState}
          onChangeState={(state) => setGridState(state)}
          odata={{
            url: APIExtRoutes.odataAdviserFirm,
            globalFilterColumns: [nameOf("name")],
          }}
          emptyMessage={
            "You don’t have any advice practices yet. Click on “+ New practice” to add."
          }
          searchBoxProps={{
            placeholder: "Search practices",
          }}
        />
      </SHStack>
    </SHContainer>
  );
};
