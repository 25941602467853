import { CloseButton } from "@components/buttons/close";
import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHHtmlBlock,
  SHPopper,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { TooltipAllowSVG } from "@components/svgs";
import { BusinessMetricTypeId, FieldTypeId } from "@models/configuration";
import { AnalysisStepBusinessMetricDTO } from "@models/platform-analysis/entities/steps/businessMetric";
import {
  AnalysisDataDTO,
  AnalysisFeatureDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import { Link, useTheme } from "@mui/material";
import { renderBooleanIcon } from "@pages/platform-analysis/_id/steps/business-metric/analysis/components/feature-row";
import { renderBusinessMetricIcon } from "@pages/platform-analysis/_id/steps/business-metric/analysis/components/group-row";
import { SummaryAnalysisCell } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-cell";
import { SummaryAnalysisHeader } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-header";
import { SummaryAnalysisOverallScore } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-overall-score";
import { toNativeValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { hexToRGBA } from "@utils";
import { isNil } from "lodash";
import { useEffect, useRef, useState } from "react";

export interface BusinessMetricAnalysisTableProps {
  businessMetricAnalysis?: AnalysisStepBusinessMetricDTO | null;
}
export const BusinessMetricAnalysisTable = ({
  businessMetricAnalysis,
}: BusinessMetricAnalysisTableProps) => {
  const { palette } = useTheme();
  const labelRef = useRef<HTMLLabelElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [currLongTextValue, setCurrLongTextValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLLabelElement | null>(null);

  const handleOnShowMore = (event: any, value?: string) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
    setCurrLongTextValue(value ?? "");
  };

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        labelRef.current &&
        !labelRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, labelRef, open]);

  const renderPlatformValue = (analysisData: AnalysisDataDTO) => {
    if (analysisData?.value === AnalysisDataValue.MissingData)
      return (
        <SHTypography
          variant="body3"
          zIndex="1"
          colorVariant="third"
          textAlign="center"
        >
          No data
        </SHTypography>
      );
    if (analysisData?.fieldTypeId === FieldTypeId.YesNo)
      return renderBooleanIcon(
        toNativeValue(analysisData?.value ?? "", FieldTypeId.YesNo),
      );
    if (analysisData?.fieldTypeId === FieldTypeId.TextShort)
      return (
        <SHStack gap={"5px"} alignItems={"center"} direction="row">
          <SHTypography
            variant="body3"
            zIndex="1"
            colorVariant="third"
            textAlign="left"
          >
            {analysisData?.value}
          </SHTypography>
          <SHBox>
            {!isNil(analysisData?.isPositiveScore) &&
              renderBooleanIcon(analysisData?.isPositiveScore)}
          </SHBox>
        </SHStack>
      );
    if (analysisData?.fieldTypeId === FieldTypeId.Number)
      return (
        <>
          <SHTypography
            variant="body3"
            zIndex="1"
            colorVariant="third"
            textAlign="justify"
          >
            {analysisData?.value}
          </SHTypography>
          {!isNil(analysisData?.isPositiveScore) &&
            renderBooleanIcon(analysisData?.isPositiveScore)}
        </>
      );
    if (analysisData?.fieldTypeId === FieldTypeId.TextLong)
      return (
        <SHStack
          gap={"5px"}
          width={"118px"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Link
            ref={labelRef}
            variant="body1"
            component="label"
            underline="none"
            sx={{
              paddingLeft: "5px",
              fontWeight: 600,
              cursor: "pointer",
              color: palette.text.secondary,
              lineHeight: "120%",
            }}
            onClick={(event: any) =>
              handleOnShowMore(event, analysisData?.value)
            }
          >
            Click to view
          </Link>
          {!isNil(analysisData?.isPositiveScore) &&
            renderBooleanIcon(analysisData?.isPositiveScore)}
          <SHPopper
            anchorEl={anchorEl}
            open={open}
            placement="top-start"
            popperOptions={{
              modifiers: [{ name: "flip", enabled: false }],
            }}
            sx={{
              zIndex: 1100,
            }}
          >
            <SHStack
              ref={wrapperRef}
              sx={{
                p: "30px 15px 10px 10px",
                maxWidth: "577px",
                maxHeight: "478px",
                bgcolor: hexToRGBA(palette.common.white, 0.85),
                border: "1px solid #E3E3E3",
                backdropFilter: "blur(2px)",
                borderRadius: "2px",
                position: "relative",
              }}
            >
              <SHHtmlBlock
                variant="body2"
                content={currLongTextValue}
                color={palette.text.third}
                sx={{
                  pr: 1,
                  zIndex: 1,
                  overflow: "auto",
                  "::-webkit-scrollbar-thumb": {
                    boxShadow: "none",
                  },
                }}
              />
              <SHBox sx={{ position: "absolute", top: "10px", right: "15px" }}>
                <CloseButton onClick={handleOnShowMore} />
              </SHBox>
              <SHBox sx={{ position: "absolute", bottom: "-11.7px", left: 0 }}>
                <TooltipAllowSVG />
              </SHBox>
            </SHStack>
          </SHPopper>
        </SHStack>
      );
  };

  return (
    <SHTableContainer
      sx={{
        overflow: "overlay",
        maxWidth: "max-content",
      }}
    >
      <SHTable
        style={{
          tableLayout: "auto",
          maxWidth: "max-content",
          borderCollapse: "separate",
        }}
      >
        <SummaryAnalysisHeader
          selectedProducts={businessMetricAnalysis?.selectedProducts}
        />
        <SHTableBody
          sx={{
            td: {
              borderLeft: "none",
              borderTop: "none",
            },
            "td:first-child": {
              borderLeft: `1px solid ${hexToRGBA(palette.primary[50], 0.3)}`,
            },
            ">tr:first-child td": {
              borderTop: `1px solid ${hexToRGBA(palette.primary[50], 0.3)}}`,
            },
          }}
        >
          <SummaryAnalysisOverallScore
            overallScore={businessMetricAnalysis?.overallScore}
          />
          {businessMetricAnalysis?.sections?.map((section, index) => (
            <>
              <SHTableRow key={index}>
                <SummaryAnalysisCell
                  cellColor={
                    section.businessMetricTypeId === BusinessMetricTypeId.Esg
                      ? palette.common.white
                      : palette.secondary[50]
                  }
                >
                  <SHStack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <SHStack
                      direction={"row"}
                      alignItems={"center"}
                      gap={"5px"}
                    >
                      {renderBusinessMetricIcon(
                        section?.businessMetricTypeId as BusinessMetricTypeId,
                      )}
                      <SHTypography variant="subtitle2" paddingTop={0}>
                        {`${section?.businessMetricTypeName}
                        ${section?.totalSelectedFeature}/${section?.totalFeature}`}
                      </SHTypography>
                    </SHStack>
                    {section?.description && (
                      <InformationButton content={section?.description} />
                    )}
                  </SHStack>
                </SummaryAnalysisCell>
                {Array.from(
                  Array(
                    businessMetricAnalysis?.selectedProducts?.length,
                  ).keys(),
                ).map((index) => (
                  <SummaryAnalysisCell
                    key={index}
                    cellColor={
                      section.businessMetricTypeId === BusinessMetricTypeId.Esg
                        ? palette.common.white
                        : palette.secondary[50]
                    }
                  ></SummaryAnalysisCell>
                ))}
              </SHTableRow>
              {section?.features?.map((feature: AnalysisFeatureDTO) => (
                <SHTableRow key={feature.id}>
                  <SummaryAnalysisCell
                    cellColor={
                      section.businessMetricTypeId === BusinessMetricTypeId.Esg
                        ? palette.common.white
                        : palette.secondary[50]
                    }
                  >
                    <SHStack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent="space-between"
                    >
                      <SHStack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"5px"}
                      >
                        <SHTypography
                          variant="body1"
                          sx={{
                            color: palette.text.third,
                          }}
                        >
                          {feature?.name}
                        </SHTypography>
                      </SHStack>
                    </SHStack>
                  </SummaryAnalysisCell>
                  {feature?.analysisData?.map((product, index) => (
                    <SummaryAnalysisCell
                      cellColor={
                        section.businessMetricTypeId ===
                        BusinessMetricTypeId.Esg
                          ? palette.common.white
                          : palette.secondary[50]
                      }
                      key={index}
                    >
                      <SHStack
                        gap={"5px"}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent="center"
                      >
                        {renderPlatformValue(product)}
                      </SHStack>
                    </SummaryAnalysisCell>
                  ))}
                </SHTableRow>
              ))}
            </>
          ))}
        </SHTableBody>
      </SHTable>
    </SHTableContainer>
  );
};
