import { SVGProps } from "react";

export const PlusSVG = ({
  color = "#25324B",
  width = 17,
  height = 17,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fillRule="evenodd" clipRule="evenodd" fill={color}>
      <path d="M8.333 2.931c.368 0 .667.299.667.667v9.333a.667.667 0 1 1-1.333 0V3.598c0-.368.298-.667.666-.667Z" />
      <path d="M3 8.265c0-.369.298-.667.667-.667H13a.667.667 0 1 1 0 1.333H3.667A.667.667 0 0 1 3 8.265Z" />
    </g>
    <defs>
      <clipPath id="a">
        <rect x={0.333} y={0.265} width={16} height={16} rx={8} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
