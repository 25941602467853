import { SHStack, SHStackProps } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

interface SHFiledGroupSkeletonProps {
  fullWidth?: boolean;
  hasSubTitle?: boolean;
  subTitleRows?: number;
}

export const SHFiledGroupTitleSkeleton = ({
  fullWidth = false,
  hasSubTitle = true,
  subTitleRows = 1,
  ...others
}: SHFiledGroupSkeletonProps & SHStackProps) => {
  return (
    <SHStack width={fullWidth ? "100%" : "30%"} spacing={1} {...others}>
      <SHSkeleton height={18} width={fullWidth ? "15%" : "50%"} />
      {Array.from(Array(subTitleRows).keys()).map((row) => (
        <SHSkeleton key={row} height={16} width={fullWidth ? "100%" : "80%"} />
      ))}
    </SHStack>
  );
};
