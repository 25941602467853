import { Skeleton, SkeletonProps } from "@mui/material";
import { useEffect, useState } from "react";

export interface SHSkeletonProps extends SkeletonProps {
  delay?: boolean;
}

const DebounceTime = 300;

export default function SHSkeleton({
  variant = "rounded",
  animation = "pulse",
  height = "100%",
  delay = true,
  ...others
}: SHSkeletonProps) {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (delay) {
      const timer = setTimeout(() => {
        setIsShown(true);
      }, DebounceTime);
      return () => clearTimeout(timer);
    } else {
      setIsShown(true);
    }
  }, [delay]);

  return (
    <>
      {isShown && (
        <Skeleton
          variant={variant}
          animation={animation}
          height={height}
          {...others}
        />
      )}
    </>
  );
}
