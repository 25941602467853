import { PlusButton } from "@components/buttons/plus";
import {
  SHButton,
  SHDataGrid,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { APIExtRoutes, PageRoutes } from "@constants/routes";
import { useAppParams } from "@hooks/useAppParams";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { ODataResult, PageMode, ParentState } from "@models/core";
import { ApplicationUser } from "@models/users/entities/appUser";
import { Avatar, useTheme } from "@mui/material";
import {
  customSortOrderSupplierUser,
  supplierUserTypeMapping,
} from "@pages/suppliers/_id/_team/constant";
import {
  DefaultStatusFilterValue,
  StatusFilterOptions,
  SupplierUserTypeOptions,
} from "@pages/user/constant";
import { RootState } from "@redux/store";
import { createColumnHelper, TableState } from "@tanstack/react-table";
import { constructUserName, nameOfFactory } from "@utils";
import { isEmpty, orderBy, some } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<ApplicationUser>();
const columnHelper = createColumnHelper<ApplicationUser>();
const pickColumns: (keyof ApplicationUser)[] = [
  "auth0Id",
  "avatarUrl",
  "userType",
  "status",
  "name",
  "lastName",
  "firstName",
];

export const SupplierTeam = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { palette } = useTheme();
  const { notify } = useNotification();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { isSupplierGroup, canViewSupplierTeam } = useUserPermissions();
  const { supplierData } = useSelector((state: RootState) => state.supplier);
  const { isMySupplier } = useAppParams();
  const accessDenied =
    !canViewSupplierTeam || (isSupplierGroup && !isMySupplier);
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: nameOf("name"),
        header: "Full name",
        enableColumnFilter: false,
        cell: (props) => (
          <SHStack
            spacing={2}
            direction="row"
            alignItems={"center"}
            justifyContent={"start"}
          >
            <Avatar alt="Avatar" src={props.cell.getValue().avatarUrl ?? ""} />
            <SHTypography variant="subtitle2">
              {constructUserName(props.cell.getValue().name)}
            </SHTypography>
          </SHStack>
        ),
      }),
      columnHelper.accessor("userType", {
        header: "Account type",
        cell: (props) => supplierUserTypeMapping[props.cell.getValue()] ?? "",
        meta: {
          filterData: SupplierUserTypeOptions,
          sx: {
            width: { xs: "200px", xl: "320px" },
          },
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => <StatusBadge status={props.cell.getValue()} />,
        meta: {
          filterData: StatusFilterOptions,
          sx: {
            width: { xs: "180px", xl: "260px" },
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "actionCol",
        header: "Action",
        cell: (props) => (
          <SHStack spacing={2} direction="row">
            <SHButton
              startIcon={<EditSVG color={palette.common.white} />}
              variant="contained"
              onClick={() =>
                navigate(
                  generatePath(PageRoutes.supplierUser, {
                    supplierId,
                    supplierUserId: props.cell.getValue().auth0Id || "",
                  }),
                  { state: { pageMode: PageMode.Edit } as ParentState },
                )
              }
            >
              Edit
            </SHButton>
            <SHButton
              variant="outlined"
              onClick={() =>
                navigate(
                  generatePath(PageRoutes.supplierUser, {
                    supplierId,
                    supplierUserId: props.cell.getValue().auth0Id || "",
                  }),
                )
              }
            >
              View
            </SHButton>
          </SHStack>
        ),
        meta: {
          sx: {
            width: "190px",
          },
        },
        enableColumnFilter: false,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleOnDataReceived = (
    state: Partial<TableState>,
    data: ODataResult<any>,
  ) => {
    // Custom sort order when sort Account Type
    let result = { ...data };
    let tableData = data["value"] ?? [];

    if (!isEmpty(tableData) && some(state?.sorting, ["id", "userType"])) {
      const sortDataState = state?.sorting?.find(
        (item) => item.id === "userType",
      );
      const newTableData = tableData?.map((item: ApplicationUser) => {
        return {
          ...item,
          order: customSortOrderSupplierUser[`${item?.userType}`],
        };
      });
      result = {
        ...result,
        value: orderBy(
          newTableData,
          "order",
          sortDataState?.desc ? "desc" : "asc",
        ),
      };
    }

    return result;
  };

  useEffectOnce(() => {
    const parentState = location?.state as ParentState;
    if (!isEmpty(parentState?.notification)) {
      notify("Request Timeout. Please try again.", {
        variant: "error",
        close: true,
      });
    }
  });

  if (accessDenied) return <AccessDeniedDialog />;

  return (
    <SHStack>
      <TopBarContainer>
        <TopBar
          title={supplierData?.companyName}
          customBadge={
            supplierData?.status ? (
              <StatusBadge status={supplierData?.status} />
            ) : undefined
          }
        />
      </TopBarContainer>

      <SHDataGrid
        state={{
          ...DefaultDataState,
          columnFilters: [
            {
              id: nameOf("status"),
              value: DefaultStatusFilterValue,
            },
          ],
          sorting: [{ id: nameOf("name"), desc: false }],
        }}
        columns={columns as any}
        odata={{
          url: generatePath(APIExtRoutes.odataSupplierUsers, {
            supplierId,
          }),
          pickColumns,
          globalFilterColumns: ["name"],
          onDataReceived: handleOnDataReceived,
        }}
        emptyMessage={"No team members. Click on “New team members” to add!"}
        searchBoxProps={{
          placeholder: "Search team members",
        }}
        rightToolbar={
          <PlusButton
            onClick={() => {
              navigate(
                generatePath(PageRoutes.supplierUser, {
                  supplierId,
                  supplierUserId: "new",
                }),
              );
            }}
          >
            New team member
          </PlusButton>
        }
      />
    </SHStack>
  );
};
