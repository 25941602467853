import { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export const useCurrentPath = (): string => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    if (searchParams) return `${pathname}?${searchParams.toString()}`;
    return `${pathname}`;
  }, [pathname, searchParams]);
};
