import { SHStack } from "@components/design-systems";
import {
  FeatureItem,
  FeatureItemProps,
} from "@layouts/review/transfer-select-feature-list/components/feature-item";
import { ReviewConfigurationFeatureDTO } from "@models/reviews/entities/steps/feature";

export interface FeatureListProps {
  features?: ReviewConfigurationFeatureDTO[];
}

export const FeatureList = ({
  features,
  isDeselect,
  subGroupId,
  handleOnSelectDeselect,
  handleOnSelectDeselectEssential,
  handleOnBothSelect,
  ...others
}: FeatureListProps & FeatureItemProps) => {
  return (
    <SHStack
      width={"50%"}
      direction={"column"}
      sx={{
        gap: "10px",
        boxSizing: "border-box",
        backdropFilter: "blur(0.5px)",
      }}
      {...others}
    >
      {features?.map((item, index) => (
        <FeatureItem
          key={index}
          feature={item}
          isDeselect={isDeselect}
          subGroupId={subGroupId}
          handleOnSelectDeselect={handleOnSelectDeselect}
          handleOnSelectDeselectEssential={handleOnSelectDeselectEssential}
          handleOnBothSelect={handleOnBothSelect}
        />
      ))}
    </SHStack>
  );
};
