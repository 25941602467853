import { SHStack, SHStackProps } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const TransferSelectFeatureListSkeleton = ({
  ...others
}: SHStackProps) => {
  return (
    <SHStack
      direction="row"
      justifyContent="space-between"
      alignItems={"flex-start"}
      gap="25px"
      {...others}
    >
      <SHStack
        width={"50%"}
        direction={"column"}
        sx={{
          gap: "10px",
        }}
      >
        {Array.from(Array(5).keys()).map((index) => (
          <SHSkeleton key={index} width={"100%"} height={"50px"} />
        ))}
      </SHStack>
      <SHStack
        width={"50%"}
        direction={"column"}
        sx={{
          gap: "10px",
        }}
      >
        {Array.from(Array(5).keys()).map((index) => (
          <SHSkeleton key={index} width={"100%"} height={"50px"} />
        ))}
      </SHStack>
    </SHStack>
  );
};
