import mainBgImage from "@assets/images/background/main.svg";
import { CloseButton } from "@components/buttons/close";
import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { FooterSVG } from "@components/svgs";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { theme } from "@themes";

interface LicenceAgreementDialogProps {
  onClose?: () => void;
}

export const LicenceAgreementDialog = ({
  onClose,
}: LicenceAgreementDialogProps): JSX.Element => {
  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          padding: 0,
          overflow: "hidden",
          backgroundImage: `url(${mainBgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: { xs: "100%", md: 1350 },
          border: 0,
        },
      }}
    >
      <DialogTitle
        borderBottom={`1px solid ${theme.palette.common.black}`}
        display={"flex"}
        height={95}
        padding={"0px !important"}
        alignItems="stretch"
        justifyContent="space-between"
        flexDirection="row"
      >
        <SHTypography
          variant="h2"
          fontWeight={600}
          fontSize={20}
          padding={"25px 77px !important"}
          borderRight={`1px solid ${theme.palette.common.black}`}
          lineHeight={"120%"}
          textAlign={"left"}
        >
          SUITABILITYHUB END-USER <br /> LICENCE AGREEMENT
        </SHTypography>
        <SHStack padding={"44px 84px"}>
          <CloseButton width="8.75px" height="8.75px" onClick={onClose} />
        </SHStack>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px !important" }}>
        <SHStack spacing={2} textAlign={"justify"} marginY={5.5} marginX={9}>
          <SHTypography variant="body2">
            Please read this End-User Licence Agreement carefully before
            clicking the “I Agree” checkbox.
          </SHTypography>
          <SHTypography variant="body2">
            <b>1. Definitions and Interpretation</b>
            <br />
            For the purposes of this End-User Licence Agreement:
          </SHTypography>
          <SHTypography variant="body2">
            <b>Agreement</b> means this End-User Licence Agreement.
          </SHTypography>
          <SHTypography variant="body2">
            <b>Authorised User</b> means a person who has been authorised as a
            user of the Platform by the Company or a customer of the Company.
          </SHTypography>
          <SHTypography variant="body2">
            <b>Company</b> (referred to as either “the Company”, “We”, “Us” or
            “Our”) refers to Empower Due Diligence Pty Ltd trading as
            SuitabilityHub (ACN 650 236 561).
          </SHTypography>
          <SHTypography variant="body2">
            <b>Platform</b> means the cloud-based platform known as
            “SuitabilityHub” (available at www.suitabilityhub.com), provided by
            the Company.
          </SHTypography>
          <SHTypography variant="body2">
            <b>Third-Party Services</b> means any services or content (including
            data, information, applications and other products and/or services)
            provided by a third-party that may be displayed on the Platform.
          </SHTypography>
          <SHTypography variant="body2">
            <b>You</b> means the individual accessing or using the Platform, and
            any other person on behalf of whom you are accessing or using the
            Platform, as applicable.
          </SHTypography>
          <SHTypography variant="body2">
            Unless it is inappropriate in the context, in this Agreement:
            <ul style={{ margin: 0, listStyleType: "lower-alpha" }}>
              <li>the singular includes the plural and vice versa;</li>
              <li>
                a reference to an individual or person includes a corporation,
                firm and government body and vice versa;
              </li>
              <li>
                a reference to a party includes that party’s executors,
                administrators, substitutes, successors and permitted assigns;
              </li>
              <li>
                the meaning of general words is not limited by specific examples
                introduced by ‘including’ or other similar expressions;
              </li>
              <li>
                a reference to a document (including this Agreement) includes
                the document as modified from time to time and any document
                replacing it; and
              </li>
              <li>
                headings are included for convenience and do not affect
                interpretation.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            <b>
              2. About the Platform and Acknowledgement The Company operates the
            </b>
            <br />
            The Company operates the Platform for use by Authorised Users. The
            Platform is a research tool which enables financial product
            providers, financial advisers, and financial services firms to
            assess the suitability of Australian financial products for their
            target audience. The Platform is not intended to be used or accessed
            by the general public.
          </SHTypography>
          <SHTypography variant="body2">
            The Company offers the following services (<b>the Services</b>) by
            way of the Platform:
            <ul style={{ margin: 0, listStyleType: "lower-alpha" }}>
              <li>
                for <i>Financial Product Providers</i> – financial product
                providers can register with the Platform as a
                <b> Financial Product Provider</b> with either a <b>Core</b>,
                <b> Premium</b> or a <b> BDM subscription</b>, after which they
                can create and maintain a profile for their financial product
                for the purpose of allowing Authorised Users to form an accurate
                understanding of that financial product. In addition, Financial
                Product Providers with a <b>Premium</b> or a{" "}
                <b> BDM subscription</b> can use the Platform to undertake
                research, and Financial Product Providers with a{" "}
                <b> BDM subscription</b> can also use the Platform as a sales
                tool in relation to their financial product.
              </li>
              <li>
                for <i>Financial Advisers</i> – financial advisers can register
                with the Platform as a <b>Financial Adviser</b>, after which
                they can view and assess the profiles of Financial Product
                Providers (as well as financial product profiles created and
                maintained by the Company) for the purposes of conducting
                research.
              </li>
              <li>
                for other <i>Financial Services Firms</i> – other financial
                services firms (such as research firms and consultancies) can
                register with the Platform as a
                <b> Financial Service Provider</b>, after which they can view
                and compare the profiles of Financial Product Providers (as well
                as financial product profiles created and maintained by the
                Company) for the purposes of conducting research.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            This Agreement is a legal document between You and the Company and
            it governs Your use of the Platform. If You are accessing this
            Agreement, the Platform and/or the Services on behalf of a person,
            company or another legal entity, You represent and acknowledge that
            You have the authority to bind that person or entity and its
            affiliates, in which case references to “You” and “Your” in this
            Agreement shall also be a reference to such entity and its
            affiliates
          </SHTypography>
          <SHTypography variant="body2">
            By clicking the “I Agree” button and/or using the Platform, You
            confirm that you have read, understood and agree to be bound by the
            terms and conditions of this Agreement. If You do not agree to the
            terms of this Agreement, do not click on the “I Agree” checkbox and
            do not use the Platform.
          </SHTypography>
          <SHTypography variant="body2">
            <b>3. Licence</b>
            <br />
            The Company grants You a revocable, non-exclusive, non-transferable,
            limited licence to use the Platform strictly in accordance with the
            terms of this Agreement.
          </SHTypography>
          <SHTypography variant="body2">
            <b>4. Fees – for Financial Advisers only</b>
            <br />
            If You are linked to a Financial Adviser registration, the licence
            granted under clause 3 of this Agreement is subject to payment of
            the relevant annual fee associated with Your use of the Platform
            (Fee). The Company will invoice You for payment of the Fee on an
            annual basis. The Fee is payable in advance in AUD unless otherwise
            specified. All payments are non-refundable.
          </SHTypography>
          <SHTypography variant="body2">
            The Fee may be subject to change from time to time. We will notify
            You of any increase of the Fee in writing.
          </SHTypography>
          <SHTypography variant="body2">
            If You fail to pay the Fee on an annual basis, Your subscription to
            the Platform may be downgraded to a free, limited subscription.
          </SHTypography>
          <SHTypography variant="body2">
            <b>5. Accounts</b>
            <br />
            The Company will provide You with an account to access the Platform
            subject to the terms and conditions of this Agreement. The Company
            may request that You provide, among other information, Your name,
            email address, work/position title, phone number and information
            about Your company (if applicable) to enable your registration with
            the Platform.
          </SHTypography>
          <SHTypography variant="body2">
            In providing such information, You agree that you:
            <ul style={{ margin: 0, listStyleType: "lower-alpha" }}>
              <li>are not impersonating any person or entity; and</li>
              <li>
                if applicable, are authorised to create an account in the name
                of the relevant company or organisation or otherwise hold
                Yourself out as having an association with that company or
                organisation.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            Should we suspect or identify You as inappropriately or unlawfully
            adopting a person or company’s identity we may without notice to
            You, disclose that information to any relevant persons or
            authorities, and/or suspend or cancel Your account (without
            prejudice to any other remedies we may have).
          </SHTypography>
          <SHTypography variant="body2">
            You must keep Your username and password secure, and You must not
            disclose those details to any other person. You are solely
            responsible for the consequences of any use of your username and
            password by third parties, regardless of whether that use is
            authorised. If You believe that your username and password is being
            used by someone else, please contact us immediately.
          </SHTypography>
          <SHTypography variant="body2">
            The Company reserves the right to suspend Your account or cancel
            Your registration with the Platform at any time, should the Company
            form the view that its brand, operations (including Platform) or
            business (including its customers, partners or affiliates) may be
            adversely affected or harmed as a result of Your continued
            registration with and/or use of the Platform.
          </SHTypography>
          <SHTypography variant="body2">
            <b>6. Use of the Platform</b>
            <br />
          </SHTypography>
          <SHTypography variant="body2">
            You may not use the Platform in any manner that could damage,
            disable, overburden or impair our servers or networks, or interfere
            with any other party’s use and enjoyment of the Platform.
          </SHTypography>
          <SHTypography variant="body2">
            You must not do any of the following:
            <ul style={{ margin: 0, listStyleType: "lower-alpha" }}>
              <li>
                use the Platform for any objectionable or unlawful purpose,
                including the posting of any threatening, libellous, defamatory,
                obscene, scandalous, inflammatory, pornographic, profane or spam
                material;
              </li>
              <li>
                use the Platform in a way that would result in You breaching any
                applicable legislation (including with respect to privacy) or
                any obligations You may owe to third parties;
              </li>
              <li>
                use the Platform (or any product of the Platform) to mislead or
                make misrepresentations in the course of trade;
              </li>
              <li>
                conduct any activity which compromises or breaches another
                party's intellectual property including but not limited to
                patent, trade mark, copyright or other intellectual property
                rights;
              </li>
              <li>
                copy, collect or save information about other users of the
                Platform;
              </li>
              <li>
                introduce any virus, worm, Trojan horse, malicious code or other
                program which may damage the Platform or the equipment of the
                Company or other users of the Platform;
              </li>
              <li>
                disrupt or interfere with the delivery of the Services (or
                attempt to do so);
              </li>
              <li>
                sell, redistribute or use information contained on the Platform
                for a commercial purpose without our prior written consent,
                except as permitted in this Agreement;
              </li>
              <li>
                provide to any person who is not an Authorised User any part of
                the information included on the Platform, except as permitted in
                this Agreement; or
              </li>
              <li>
                distribute, broadcast or publish any part of the information
                included on the Platform, including on any publicly accessible
                electronic network such as the internet, except as permitted in
                this Agreement.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            Crawling, spidering or scraping of the Platform is prohibited. You
            may not provide unauthorised interfaces to any part of the Platform.
          </SHTypography>
          <SHTypography variant="body2">
            <b>7. Product Information</b>
            <br />
            Authorised Users linked to a Financial Product Provider registration
            will be requested to provide information in relation to their
            financial product (<b>Product Information</b>).
          </SHTypography>
          <SHTypography variant="body2">
            You warrant and represent that any Product Information You provide
            is and shall remain accurate, true and correct and that, if such
            information changes, You will update the information held by the
            Company as soon as possible.
          </SHTypography>
          <SHTypography variant="body2">
            The Company may, from time to time and in its sole discretion (but
            is not obliged to):
            <ul style={{ margin: 0, listStyleType: "lower-alpha" }}>
              <li>
                make its own reasonable enquiries as to the completeness,
                accuracy or truthfulness of the Product Information;
              </li>
              <li>
                make its own reasonable enquiries as to whether or not Product
                Information is appropriate and compliant with this Agreement;
              </li>
              <li>
                refuse or remove any Product Information from the Platform;
                and/or
              </li>
              <li>edit the Product Information.</li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            <b>8. Posted Content</b>
            <br />
            The Company does not have, and expressly disclaims, any liability to
            You in connection with any content, information or material you
            upload to (or submit via) the Platform, including but not limited to
            Product Information (<b>Posted Content</b>).
          </SHTypography>
          <SHTypography variant="body2">
            In relation to any Posted Content, You warrant and must ensure that:
            <ul style={{ margin: 0, listStyleType: "lower-alpha" }}>
              <li>its contents are truthful;</li>
              <li>
                it is not misleading, deceptive or materially inaccurate in any
                way; and
              </li>
              <li>it is compliant with all applicable legislation.</li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            You are solely responsible for Your Posted Content.
          </SHTypography>
          <SHTypography variant="body2">
            The Company will not have any liability in connection with the
            deletion, loss, or unauthorised modification of any of Your Posted
            Content.
          </SHTypography>
          <SHTypography variant="body2">
            You consent to us displaying the Posted Content on the Platform and
            acknowledge that it will be visible to other Authorised Users. We
            reserve the right to keep the Posted Content on the Platform
            indefinitely.
          </SHTypography>
          <SHTypography variant="body2">
            <b>9. Intellectual Property</b>
            <br />
            We own or are permitted to use all intellectual property in the
            Platform. You may not use any of our intellectual property for any
            purpose other than as required for the lawful use the Platform for
            its intended purpose.
          </SHTypography>
          <SHTypography variant="body2">
            Subject to the conditions prescribed under the Copyright Act 1968
            (Cth), You may not, in any form or by any means:
            <ul style={{ margin: 0, listStyleType: "lower-alpha" }}>
              <li>
                adapt, reproduce, decipher, broadcast, decompile, decrypt,
                disassemble, reverse engineer, copy, store, distribute,
                transmit, print, modify or publish any part of the Platform;
              </li>
              <li>create derivative works from any part of the Platform;</li>
              <li>
                remove, alter or obscure any proprietary notice (including any
                notice of copyright or trade mark) of the Company or its
                affiliates, partners, suppliers or the licensors of the
                Platform; or
              </li>
              <li>
                put to commercial use any information, products or services
                obtained from the Platform, except as permitted in this
                Agreement.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            You warrant that you own or are licensed to use the intellectual
            property rights in your Posted Content. This includes copyright in
            respect of any text you post, as well as the right to use or display
            any image or logo. You indemnify us, and agree to keep us
            indemnified, from and against any claims by third parties arising
            from your Posted Content, including any claim for breach of
            intellectual property.
          </SHTypography>
          <SHTypography variant="body2">
            <b>10. Modifications and Updates to the Platform</b>
            <br />
            The Company reserves the right to modify, suspend or discontinue,
            temporarily or permanently, the Platform or any service to which it
            connects, with or without notice and without liability to You.
          </SHTypography>
          <SHTypography variant="body2">
            We aim to provide a service that is continually available and
            capable of use. However, we may suspend your account and your access
            to the Platform at any time and for any reason, including without
            cause. We will not have any liability in connection with any
            suspension of your account or your access to the Platform,
            regardless of the reason for suspension.
          </SHTypography>
          <SHTypography variant="body2">
            Unless the reason for suspension is unexpected or relates to some
            wrongful conduct by You, we will endeavour to give reasonable notice
            before suspending Your account or Your access to the Platform.
            Without limiting the other ways in which we may give such notice to
            You, we may provide such notice via any email address You provide
            us.
          </SHTypography>
          <SHTypography variant="body2">
            The Company may from time to time provide enhancements or
            improvements to the features and/or functionality of the Platform,
            which may include patches, bug fixes, updates, upgrades and other
            modifications (<b>Updates</b>). Updates may modify or delete certain
            features and/or functionalities of the Platform. You agree that the
            Company has no obligation to provide any Updates, or to continue to
            provide or enable any particular features and/or functionalities of
            the Platform to You.
          </SHTypography>
          <SHTypography variant="body2">
            <b>11. Third-Party Services</b>
            <br />
            The Platform may display, include or make available third-party
            content (including data, information, applications and other
            products and/or services) or provide links to third-party websites.
          </SHTypography>
          <SHTypography variant="body2">
            You acknowledge and agree that the Company shall not be responsible
            for any Third-Party Services, including their accuracy,
            completeness, timeliness, validity, copyright compliance, legality,
            decency, quality or any other aspect thereof. The Company does not
            assume and shall not have any liability or responsibility to You or
            any other person or entity for any Third-Party Services.
          </SHTypography>
          <SHTypography variant="body2">
            <b>12. Term and Termination</b>
            <br />
            This Agreement shall remain in effect until terminated by You or the
            Company.
          </SHTypography>
          <SHTypography variant="body2">
            The Company may, in its sole discretion, at any time and for any or
            no reason, suspend or terminate this Agreement with or without prior
            notice. You may also terminate this Agreement by requesting that the
            Company cancel your registration with and access to the Platform.
          </SHTypography>
          <SHTypography variant="body2">
            On termination of this Agreement, the licence granted under this
            Agreement terminates and Your access to the Platform (including all
            products of the Platform) will cease.
          </SHTypography>
          <SHTypography variant="body2">
            <b>
              13. No liability for decisions relating to Platform or Services
            </b>
            <br />
            Where possible, the Company will use reasonable endeavours to verify
            the accuracy of the information and content available on the
            Platform, including but not limited to the features offered by
            various financial products. However You acknowledge and understand
            that You must make your own enquiries and satisfy Yourself of the
            accuracy of the information available on the Platform.
          </SHTypography>
          <SHTypography variant="body2">
            The contents of this Platform are intended to provide general
            product information only. You acknowledge that such information does
            not constitute a recommendation, endorsement or financial or other
            advice from the Company, and that we have not taken into account the
            objectives and circumstances of any Authorised User, or any end
            client for whom an Authorised User is acting. The Company makes no
            representation as to the suitability of the Platform (including any
            information available or product mentioned thereon) for any use,
            including to make business or financial decisions.
          </SHTypography>
          <SHTypography variant="body2">
            The Platform is a tool available to supplement Your decision-making
            process. You are solely responsible for all decisions You make in
            connection with the Platform and/or the Services (whether they
            relate to You, Your company, Your clients, or otherwise), and You
            should take whatever additional steps You deem necessary to validate
            decisions made in reliance on the Platform.
          </SHTypography>
          <SHTypography variant="body2">
            You acknowledge that the foregoing limitations are an essential
            element of this Agreement and a reasonable allocation of risk
            between the parties.
          </SHTypography>
          <SHTypography variant="body2">
            <b>14. Refusal of service</b>
            <br />
            Notwithstanding anything else in this Agreement, we may, in our
            absolute discretion, refuse You or any other person access to any
            part of the Platform at any time and for any reason.
          </SHTypography>
          <SHTypography variant="body2">
            <b>15. Privacy</b>
            <br />
            We may collect, use and disclose information about You in accordance
            with our <b> Privacy Policy</b> (available at
            https://www.suitabilityhub.com/privacy). Your registration and use
            of the Platform is conditional upon you agreeing and complying with
            our Privacy Policy. If you do not agree to us collecting, using or
            disclosing your information in the manner contemplated by this
            Agreement and our Privacy Policy, you cannot use the Platform.
          </SHTypography>
          <SHTypography variant="body2">
            <b>16. No Warranty</b>
            <br />
            The Platform is provided to You "as is" and "as available", and with
            all faults and defects without warranty of any kind. The Company
            specifically disclaims any other representation or warranty
            (express, implied, statutory or otherwise) with respect to the
            Platform, including all implied warranties of merchantability,
            fitness for a particular purpose, title and non-infringement, and
            warranties that may arise out of the course of dealing, course of
            performance, usage or trade practice. Without limitation to the
            foregoing, the Company provides no warranty or undertaking, and
            makes no representation of any kind that the Platform (including any
            information available or product mentioned thereon) is suitable for
            any particular purpose, will meet your requirements, achieve any
            intended results, be compatible or work with any other software,
            applications, systems or services, operate without interruption,
            meet any performance or reliability standards, be error free or that
            any errors or defects can or will be corrected.
          </SHTypography>
          <SHTypography variant="body2">
            Without limiting the foregoing, the Company makes no representation
            or warranty of any kind, express or implied as to:
            <ul style={{ margin: 0, listStyleType: "lower-alpha" }}>
              <li>
                the operation or availability of the Platform, or the
                information, content, and materials included thereon;
              </li>
              <li>that the Platform will be uninterrupted or error-free; or</li>
              <li>
                the accuracy, reliability, or currency of any information or
                content provided through the Platform.
              </li>
            </ul>
          </SHTypography>
          <SHTypography variant="body2">
            <b>17. Indemnity and Limitation of Liability</b>
            <br />
            You use the Platform entirely at your own risk. We are not
            responsible for, and accept no liability in relation to, Your use of
            and conduct in connection with the Platform, or any other person’s
            use of or conduct in connection with the Platform, in any
            circumstance.
          </SHTypography>
          <SHTypography variant="body2">
            You indemnify the Company, and agree to keep it indemnified, from
            and against any claim, loss, damage, liability, cost or expense that
            it may suffer or incur as a result of or in connection with Your
            improper use of or conduct in connection with the Platform,
            including any breach by You of this Agreement or any applicable
            legislation.
          </SHTypography>
          <SHTypography variant="body2">
            To the maximum extent permitted by law, we exclude all implied
            representations and warranties which, but for this Agreement, might
            apply in relation to Your use of the Platform. To the extent that
            our liability cannot be excluded by law, our maximum liability,
            whether in contract, equity, statute or tort (including negligence),
            to you will be limited to $100.00.
          </SHTypography>
          <SHTypography variant="body2">
            Notwithstanding anything to the contrary in this Agreement, in no
            circumstances will we be liable for any indirect, punitive or
            consequential loss or damages, loss of income, use, profits,
            goodwill, data, contracts, use of money or any loss or damages
            arising from or in any way connected to business interruption of any
            type, whether in tort, contract or otherwise.
          </SHTypography>
          <SHTypography variant="body2">
            <b>18. Feedback</b>
            <br />
            We may collect feedback, comments, ideas, improvements or
            suggestions (<b>Feedback</b>) from You to assist improvements to the
            functioning of the Platform. Any Feedback provided by You with
            respect to the Platform shall remain the sole and exclusive property
            of the Company.
          </SHTypography>
          <SHTypography variant="body2">
            The Company shall be free to use, copy, modify, publish, or
            redistribute Feedback for any purpose and in any way without any
            credit or any compensation to You.
          </SHTypography>
          <SHTypography variant="body2">
            <b>
              19. Reports – <i>for Financial Product Providers only</i>
            </b>
            <br />
            If You are linked to a Financial Product Provider registration, Your
            Posted Content may be included in market research reports prepared,
            distributed and sold by the Company. Such reports may, without
            limitation, compare the functionality, cost and performance of
            various financial products.
          </SHTypography>
          <SHTypography variant="body2">
            You agree that the Company may make its market research reports
            available for sale or otherwise distribute them publicly, without
            compensation or attribution to You. You warrant that the Posted
            Content is not confidential and consent to the Company utilising any
            part thereof (including Product Information) in its reports.
          </SHTypography>
          <SHTypography variant="body2">
            <b>20. Changes to the Agreement</b>
            <br />
            The Company reserves the right, at its sole discretion, to modify,
            revise or replace this Agreement at any time.
          </SHTypography>
          <SHTypography variant="body2">
            If a modification or revision is material, we will provide at least
            14 days' notice prior to it taking effect. What constitutes a
            material change will be determined at the sole discretion of the
            Company.
          </SHTypography>
          <SHTypography variant="body2">
            By continuing to access or use the Platform after any modification
            or revision becomes effective, You agree to be bound by the revised
            Agreement.
          </SHTypography>
          <SHTypography variant="body2">
            <b>21. Entire Agreement</b>
            <br />
            The Agreement constitutes the entire agreement between You and the
            Company regarding Your use of the Platform.
          </SHTypography>
          <SHTypography variant="body2">
            This Agreement will continue to apply even when You are not using
            the Platform and even if Your access to the Platform has been
            suspended.
          </SHTypography>
          <SHTypography variant="body2">
            <b>22. Assignment and novation</b>
            <br />
            We may assign or novate any of our rights or obligations under this
            Agreement without Your consent. You must not assign any of Your
            rights or obligations under this Agreement.
          </SHTypography>
          <SHTypography variant="body2">
            <b>23. Variation or Waiver</b>
            <br />
            No variation to or waiver by us of any provision of this Agreement,
            nor any consent to depart from any part of it is effective unless it
            is confirmed in writing and signed by us. A variation, waiver or
            consent is effective only in the circumstances for which it is made
            or given.
          </SHTypography>
          <SHTypography variant="body2">
            No failure, delay, relaxation or indulgence by us in exercising any
            right conferred under this Agreement operates as a waiver of the
            right.
          </SHTypography>
          <SHTypography variant="body2">
            Any present or future legislation which operates to vary an
            obligation or right, power or remedy of a person in connection with
            this Agreement is excluded except to the extent that its exclusion
            is prohibited or rendered ineffective by law.
          </SHTypography>
          <SHTypography variant="body2">
            <b>24. Governing Law and Jurisdiction</b>
            <br />
            This Agreement must be construed and interpreted in accordance with
            the laws of the State of New South Wales, Australia and You submit
            to the non-exclusive jurisdiction of the Courts of New South Wales.
          </SHTypography>
          <SHTypography variant="body2">
            If any part of this Agreement is held to be unenforceable, that part
            is to be given effect to the greatest extent possible, and the
            remainder of this Agreement will continue in full force and effect.
          </SHTypography>
          <SHTypography variant="body2">
            <b>25. Last update</b>
            <br />
            This Agreement was last updated on 17 August 2022.
          </SHTypography>
          <SHTypography variant="body2"></SHTypography>
          <SHTypography variant="body2"></SHTypography>
          <SHTypography variant="body2"></SHTypography>
          <SHTypography variant="body2"></SHTypography>
          <SHTypography variant="body2"></SHTypography>
        </SHStack>
        <FooterSVG style={{ marginBottom: "-5px" }} />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          borderTop: `1px solid ${theme.palette.common.black}`,
          height: 120,
          margin: 0,
        }}
      >
        <SHButton
          color="primary"
          variant="contained"
          onClick={onClose}
          size="large"
        >
          Close
        </SHButton>
      </DialogActions>
    </SHDialog>
  );
};
