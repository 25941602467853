export enum SMAListFavouriteFilter {
  All = "All",
  Favourites = "Favourites",
}

export enum SMAListCurrentlySelectedFilter {
  All = "All",
  Selected = "Selected",
}

export enum SMAListMinimumInvestmentSizeFilter {
  All = "All",
  OneHundredThousandOrLess = "OneHundredThousandOrLess",
  FiftyThousandOrLess = "FiftyThousandOrLess",
  TwentyFiveThousandOrLess = "TwentyFiveThousandOrLess",
  Zero = "Zero",
}
