import * as Yup from "yup";

export const stepSetupValidators = Yup.lazy((values) =>
  Yup.object().shape({
    ownerId: Yup.string().nullable(true).required("An adviser is required"),
    name: Yup.string().nullable(true).required("Review must have a name"),
    familyGroupId: Yup.string()
      .nullable(true)
      .required("Review must have a family group"),
    selectedFamilyMemberIds: Yup.array()
      .nullable(true)
      .when("familyGroupId", (familyGroupId, schema) => {
        return schema
          .min(1, "The minimum member is one")
          .max(6, "The maximum member is six");
      }),
  }),
);
