import { FeePortfolioDetailDTO } from "@models/platform-analysis/entities/portfolioDetails";
import { FamilyMemberAccountDTO } from "@models/platform-analysis/entities/steps/fee";
import { useTheme } from "@mui/material";
import { HorizontalLine } from "@pages/platform-analysis/components/buttons/export-pdf/components/line";
import { PDFTable } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables";
import { PDFTableBody } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-body";
import {
  SUB_TITLE_HEIGHT,
  TABLE_CELL_WIDTH,
  TABLE_FIRST_CELL_WIDTH,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import {
  displayCurrencyNumber,
  displayIntNumber,
  getPortfolioDetails,
} from "@pages/platform-analysis/util";
import { Text, View } from "@react-pdf/renderer";
import { chain, replace } from "lodash";
import { PDFTableCell } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-cell";
import { PDFTableRow } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-row";
export interface PDFFeePortfolioDetailsSectionProps {
  data?: FeePortfolioDetailDTO | null;
  isShowHoldingsNumber?: boolean;
  isSuitabilityReview?: boolean;
}
export const PDFFeePortfolioDetailsSection = ({
  data,
  isShowHoldingsNumber,
  isSuitabilityReview,
}: PDFFeePortfolioDetailsSectionProps) => {
  const { transactionsOutside, transactionsWithin, investments } =
    getPortfolioDetails(data);

  const getStringFamilyMemberAccounts = (data: FamilyMemberAccountDTO[]) => {
    return chain(data)
      ?.map(
        (data) =>
          `${replace(
            data.variableName ? data.variableName : "",
            "_",
            " ",
          )}: ${displayCurrencyNumber(data.balance)}`,
      )
      ?.join(", ")
      ?.value();
  };

  const dynamicHeightText = (text: string) => {
    if (text.length < 85) return "DoubleLine";
    return "TripleLine";
  };

  const { typographyStyle } = useStyles();
  const { palette } = useTheme();

  return (
    <>
      <View style={{ height: SUB_TITLE_HEIGHT }}>
        <Text
          style={{
            ...typographyStyle.title,
            color: palette.common.black,
          }}
        >
          {!isSuitabilityReview ? "Fee analysis" : "Fee review"}
        </Text>
        <HorizontalLine color={palette.common.black} />
        <Text
          style={{
            ...typographyStyle.subtitle,
            color: palette.common.black,
          }}
        >
          Portfolio details for fee estimates
        </Text>
      </View>

      <PDFTable>
        <PDFTableBody>
          {data?.familyMembers?.map((familyMember) => {
            const stringFamilyMemberAccounts = getStringFamilyMemberAccounts(
              familyMember.familyMemberAccounts,
            );
            return (
              <PDFTableRow type={dynamicHeightText(stringFamilyMemberAccounts)}>
                <PDFTableCell
                  width={TABLE_FIRST_CELL_WIDTH}
                  alignItems={"flex-start"}
                >
                  <Text
                    style={{ ...typographyStyle.body2, fontWeight: "bold" }}
                  >
                    {familyMember.name}
                  </Text>
                </PDFTableCell>
                <PDFTableCell
                  width={TABLE_CELL_WIDTH * 6}
                  alignItems={"flex-start"}
                >
                  <Text style={{ ...typographyStyle.body2, lineHeight: 1.2 }}>
                    {stringFamilyMemberAccounts}
                  </Text>
                </PDFTableCell>
              </PDFTableRow>
            );
          })}
          {data?.totalPortfolioValue ? (
            <PDFTableRow type="DoubleLine">
              <PDFTableCell
                width={TABLE_FIRST_CELL_WIDTH}
                alignItems={"flex-start"}
              >
                <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
                  Total portfolio value
                </Text>
              </PDFTableCell>
              <PDFTableCell
                width={TABLE_CELL_WIDTH * 6}
                alignItems={"flex-start"}
              >
                <Text
                  style={{
                    ...typographyStyle.body2,
                    maxLines: 3,
                    lineHeight: 1.2,
                  }}
                >
                  {displayCurrencyNumber(data?.totalPortfolioValue)}
                </Text>
              </PDFTableCell>
            </PDFTableRow>
          ) : null}
          {investments.length ? (
            <PDFTableRow type={dynamicHeightText(investments.join(", "))}>
              <PDFTableCell
                width={TABLE_FIRST_CELL_WIDTH}
                alignItems={"flex-start"}
              >
                <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
                  Investments and other holdings
                </Text>
              </PDFTableCell>
              <PDFTableCell
                width={TABLE_CELL_WIDTH * 6}
                alignItems={"flex-start"}
              >
                <Text style={{ ...typographyStyle.body2, lineHeight: 1.2 }}>
                  {investments.join(", ")}
                </Text>
              </PDFTableCell>
            </PDFTableRow>
          ) : null}
          {isShowHoldingsNumber && data?.totalDifferentInvestments ? (
            <PDFTableRow type="DoubleLine">
              <PDFTableCell
                width={TABLE_FIRST_CELL_WIDTH}
                alignItems={"flex-start"}
              >
                <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
                  Total number of different investments held
                </Text>
              </PDFTableCell>
              <PDFTableCell
                width={TABLE_CELL_WIDTH * 6}
                alignItems={"flex-start"}
              >
                <Text style={{ ...typographyStyle.body2, lineHeight: 1.2 }}>
                  {displayIntNumber(data?.totalDifferentInvestments)}
                </Text>
              </PDFTableCell>
            </PDFTableRow>
          ) : null}
          {transactionsOutside.length ? (
            <PDFTableRow
              type={dynamicHeightText(transactionsOutside.join(", "))}
            >
              <PDFTableCell
                width={TABLE_FIRST_CELL_WIDTH}
                alignItems={"flex-start"}
              >
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  {`Transactions `}
                  <Text
                    style={{
                      ...typographyStyle.body2,
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    outside
                  </Text>
                  {` managed accounts (Next 12 months)`}
                </Text>
              </PDFTableCell>
              <PDFTableCell
                width={TABLE_CELL_WIDTH * 6}
                alignItems={"flex-start"}
              >
                <Text style={{ ...typographyStyle.body2, lineHeight: 1.2 }}>
                  {transactionsOutside.join(", ")}
                </Text>
              </PDFTableCell>
            </PDFTableRow>
          ) : null}
          {transactionsWithin.length ? (
            <PDFTableRow
              type={dynamicHeightText(transactionsWithin.join(", "))}
            >
              <PDFTableCell
                width={TABLE_FIRST_CELL_WIDTH}
                alignItems={"flex-start"}
              >
                <Text
                  style={{
                    ...typographyStyle.body2,
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {`Transactions `}
                  <Text
                    style={{
                      ...typographyStyle.body2,
                      ...typographyStyle.body2,
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    within
                  </Text>
                  {` managed accounts (Next 12 months)`}
                </Text>
              </PDFTableCell>
              <PDFTableCell
                width={TABLE_CELL_WIDTH * 6}
                alignItems={"flex-start"}
              >
                <Text style={{ ...typographyStyle.body2, lineHeight: 1.2 }}>
                  {transactionsWithin.join(", ")}
                </Text>
              </PDFTableCell>
            </PDFTableRow>
          ) : null}
        </PDFTableBody>
      </PDFTable>
    </>
  );
};
