import { SVGProps } from "react";

export const GraySuitabilityHubSVG = ({
  width = 128,
  height = 128,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 128 128"
    {...props}
  >
    <path fill="url(#gray_logo_0)" d="M0 0h128v128H0z" />
    <path
      d="M67.149 31.34 55.246 36.64l-14.98 6.669C38.858 43.936 38 45.264 38 46.819V72.53c0 1.557.857 2.883 2.267 3.513l14.98 6.667.256.114V44.5c0-1.336.618-2.486 1.728-3.216 1.112-.729 2.405-.836 3.617-.295l11.646 5.183V34.853c0-1.339-.618-2.487-1.728-3.218A3.732 3.732 0 0 0 68.704 31c-.52 0-1.044.113-1.555.34"
      fill="url(#gray_logo_1)"
    />
    <path
      d="M57.233 41.284c-1.11.73-1.729 1.88-1.729 3.216v38.323l11.646 5.189c1.214.54 2.506.433 3.617-.299 1.11-.728 1.729-1.878 1.729-3.214V46.172L60.85 40.99a3.812 3.812 0 0 0-1.555-.34c-.714 0-1.418.212-2.062.635"
      fill="url(#gray_logo_2)"
    />
    <path
      d="M72.496 59.674V84.5c0 1.336-.618 2.486-1.729 3.215-1.11.731-2.403.837-3.617.298l-11.646-5.188V94.149c0 1.336.618 2.486 1.729 3.213 1.112.732 2.403.838 3.617.297l11.902-5.299 14.978-6.669c1.411-.627 2.27-1.957 2.27-3.51V56.467c0-1.556-.857-2.884-2.27-3.512l-14.978-6.669-.256-.115v13.502Z"
      fill="url(#gray_logo_3)"
    />
    <defs>
      <linearGradient
        id="gray_logo_1"
        x1={36.94}
        y1={80.158}
        x2={73.831}
        y2={33.888}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D3D3D3" />
        <stop offset={0.3} stopColor="#CACACA" />
        <stop offset={0.86} stopColor="#909090" />
        <stop offset={1} stopColor="#949494" />
      </linearGradient>
      <linearGradient
        id="gray_logo_2"
        x1={65.056}
        y1={89.003}
        x2={62.908}
        y2={39.998}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#040404" />
        <stop offset={1} stopColor="#5F5F5F" />
      </linearGradient>
      <linearGradient
        id="gray_logo_3"
        x1={53.819}
        y1={97.887}
        x2={92.19}
        y2={46.662}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CECECE" />
        <stop offset={0.48} stopColor="#9F9F9F" />
        <stop offset={1} stopColor="#878787" />
      </linearGradient>
      <radialGradient
        id="gray_logo_0"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 97 -97 0 64 64)"
      >
        <stop stopColor="#F4F4F4" />
        <stop offset={1} stopColor="#BDBDBD" />
      </radialGradient>
    </defs>
  </svg>
);
