import { generateUUID } from "@utils";

export class ReviewStepSetupDTO {
  name: string = "";
  ownerId: string = "";
  ownerName: string = "";
  ownerType: string = "";
  ownerAvatarUrl: string = "";
  familyGroupId: string = "";
  familyGroupName: string = "";
  selectedFamilyMemberIds?: string[] | null = [];
  selectedFamilyMembers: SelectedFamilyMemberDTO[] = [];
  adviserFirmId: string = "";
  adviserFirmName: string = "";
  objectives: string = "";
  hasRateCard: boolean = false;
  loadTemplateId: string = "";
  selectedRateCards: SelectedRateCardDTO[] = [];
}

export class SelectedFamilyMemberDTO {
  id?: string | null = generateUUID();
  name: string = "";
  familyGroupId?: string = "";
  order?: number = 0;
  isSelected?: boolean = false;
}

export class SelectedRateCardDTO{
  id: string = "";
  version: number = 0;
}