import { BillingAddressDTO } from "@models/practice/entities/practiceBillingInfo";
import { isEmpty, isNil } from "lodash";
import * as Yup from "yup";
export const billingContactValidators = Yup.object().shape({
  abn: Yup.string()
    .nullable(true)
    .test("abn", "ABN must be 11 digit", (value) => {
      return !value || value.length === 11;
    }),
  contactName: Yup.string()
    .required("Billing contact name is required")
    .max(100, "Billing contact name must be less than 100 characters"),
  contactEmail: Yup.string()
    .required("Billing contact email is required")
    .email("Billing contact email is not valid"),
});

export const convertToStripeBillingAddressDTO = (
  billingAddress: BillingAddressDTO | null,
) => {
  if (isNil(billingAddress)) return;

  const { name, line1, line2, city, state, postalCode, country } =
    billingAddress;

  return {
    name: name,
    address: {
      line1: line1,
      line2: line2,
      city: city,
      state: state,
      country: country,
      postal_code: postalCode,
    },
  };
};

export const checkIsValidBillingAddress = (
  billingAddress: BillingAddressDTO | null,
) => {
  if (isNil(billingAddress)) return false;

  const { name, line1, city, state, postalCode, country } = billingAddress;

  return (
    !isEmpty(name) &&
    !isEmpty(line1) &&
    !isEmpty(city) &&
    !isEmpty(state) &&
    !isEmpty(postalCode) &&
    !isEmpty(country)
  );
};
