import { ConfigurationGroup, HighlightType, SectionType } from "@models/configuration";
import {
  SubProductDataDTO,
  SubProductDTO,
} from "@models/product/entities/subProduct";
import { FeeValueType } from "@models/product/enums/feeValueType";

export const convertDataToSubmit = (subProduct?: SubProductDTO[]) => {
  return subProduct?.map((subPro) => {
    return {
      ...subPro,
      standardFeeSections: subPro?.standardFeeSections?.map((standardFee) => {
        return {
          ...standardFee,
          features: standardFee?.features?.map((feature) => {
            return {
              ...feature,
              subProductData: {
                ...feature.subProductData,
                idpsValue: feature?.subProductData?.idpsValue?.toString(),
                pensionValue: feature?.subProductData?.pensionValue?.toString(),
              } as SubProductDataDTO,
            };
          }),
        };
      }),
      otherFeeSections: subPro?.otherFeeSections?.map((otherFee) => {
        return {
          ...otherFee,
          features: otherFee?.features?.map((feature) => {
            return {
              ...feature,
              subProductData: {
                ...feature.subProductData,
                otherValue: feature?.subProductData?.otherValue?.toString(),
              } as SubProductDataDTO,
            };
          }),
        };
      }),
    };
  }) as SubProductDTO[];
};

export const checkHasBeenModified = (
  currentValue: string,
  valueType: string,
): boolean => {
  if (currentValue !== valueType) return true;
  return false;
};

export const valueNameMapping: {
  [key in FeeValueType]: string;
} = {
  [FeeValueType.IDPS]: "idpsValue",
  [FeeValueType.Pension]: "pensionValue",
  [FeeValueType.Other]: "otherValue",
};

export const publishedValueNameMapping: {
  [key in FeeValueType]: string;
} = {
  [FeeValueType.IDPS]: "publishedIDPSValue",
  [FeeValueType.Pension]: "publishedPensionValue",
  [FeeValueType.Other]: "publishedOtherValue",
};

export const variableNameMapping: {
  [key in FeeValueType]: string;
} = {
  [FeeValueType.IDPS]: "variableNameIDPS",
  [FeeValueType.Pension]: "variableNamePension",
  [FeeValueType.Other]: "variableNameOther",
};

export const showAllowFieldNameMapping: {
  [key in FeeValueType]: string;
} = {
  [FeeValueType.IDPS]: "showAndAllowUpdateIDPS",
  [FeeValueType.Pension]: "showAndAllowUpdatePension",
  [FeeValueType.Other]: "showAndAllowUpdateOther",
};

export const getNewProductGroups = (
  productGroups: ConfigurationGroup[],
  hasUpdateSubProduct: boolean,
) => {
  return productGroups.map((productGroup) => {
    if (productGroup.sectionType === SectionType.Fee) {
      return {
        ...productGroup,
        highlightType: hasUpdateSubProduct
          ? HighlightType.Edited
          : HighlightType.Added,
      };
    } else {
      return productGroup;
    }
  });
};