import { phoneRegex } from "@constants";
import * as Yup from "yup";

export const completeProfileFirstStepValidators = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .max(100, "First name must be less than 100 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .max(100, "Last name must be less than 100 characters"),
  phoneNumber: Yup.string().nullable(true).matches(phoneRegex, {
    excludeEmptyString: true,
    message: "Phone number is not valid",
  }),
});
