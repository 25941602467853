import { CloseButton } from "@components/buttons/close";
import {
  SHAvatar,
  SHBox,
  SHChip,
  SHPopper,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { TooltipAllowSVG } from "@components/svgs";
import { ProductBDMUserDTO } from "@models/product/entities/product";
import { Link, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import Truncate from "react-truncate";

export interface UserInformationCardProps extends SHStackProps {
  userInfo?: ProductBDMUserDTO;
  showUserType?: Boolean;
}
export const UserInformationCard = ({
  userInfo,
  showUserType = false,
  ...others
}: UserInformationCardProps) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLLabelElement | null>(null);
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  const length = userInfo?.regions?.length ?? 0;

  const handleOnShowMore = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        labelRef.current &&
        !labelRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, labelRef, open]);

  return (
    <SHStack
      height={245}
      width={{ lg: "32.75%", md: "49.33%", sm: "49.3%" }}
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        padding: "10px",
        boxSizing: "border-box",
        backdropFilter: "blur(0.5px)",
        background: `${hexToRGBA(palette.common.white, 0.25)}`,
        border: `1px solid ${palette.secondary[100]}`,
      }}
      {...others}
    >
      <SHStack
        width={"100%"}
        flexDirection={"row"}
        alignItems={"flex-start"}
        sx={{
          gap: "10px",
        }}
        {...others}
      >
        <SHStack width="75%" gap={"5px"}>
          <SHStack>
            <SHTypography variant="h3" sx={{ padding: 0, fontSize: 18 }}>
              {userInfo?.name}
            </SHTypography>
            {showUserType ?
            (<SHTypography
              variant="h3"
              colorVariant="third"
              sx={{ padding: 0, fontSize: 18 }}
            >
              {userInfo?.userType}
            </SHTypography>) : (<></>)}
            <SHTypography
              variant="body1"
              colorVariant="third"
              sx={{
                padding: 0,
              }}
            >
              {userInfo?.jobTitle}
            </SHTypography>
          </SHStack>
          <SHStack>
            <SHTypography
              variant="body1"
              colorVariant="third"
              sx={{
                padding: 0,
              }}
            >
              {userInfo?.publicPhoneNumber}
            </SHTypography>
            <Link
              variant="body1"
              component="a"
              underline="none"
              sx={{
                padding: 0,
                fontSize: 14,
                fontWeight: 600,
                cursor: "pointer",
                color: palette.text.secondary,
              }}
              href={`mailto:${userInfo?.publicEmail}`}
            >
              {userInfo?.publicEmail}
            </Link>
          </SHStack>
          <SHStack>
            <SHTypography
              variant="subtitle2"
              colorVariant="third"
              sx={{
                padding: 0,
              }}
            >
              {userInfo?.preferredContactHours
                ? "Preferred contact hours"
                : null}
            </SHTypography>
            <SHTypography
              variant="body3"
              colorVariant="third"
              sx={{
                padding: 0,
                textAlign: "justify",
              }}
            >
              {userInfo?.preferredContactHours}
            </SHTypography>
          </SHStack>
        </SHStack>
        <SHStack width={"25%"} alignItems={"center"} spacing={1}>
          <SHAvatar
            width={"100%"}
            height={"auto"}
            src={`${userInfo?.avatarUrl}`}
          />
          {userInfo?.linkedinProfileUrl && (
            <SHChip
              label={
                <Link
                  variant="body1"
                  component="a"
                  underline="none"
                  sx={{
                    fontSize: 13,
                    fontWeight: 600,
                    cursor: "pointer",
                    color: palette.text.secondary,
                  }}
                  onClick={() => window.open(`${userInfo?.linkedinProfileUrl}`)}
                >
                  LinkedIn
                </Link>
              }
              sx={{
                width: 66,
                height: 22,
                padding: "1px 6px",
                boxSizing: "border-box",
                background: `${hexToRGBA("#e9ebfd", 0.25)}`,
                border: `1px solid ${palette.secondary[100]}`,
                "& >.MuiChip-label": {
                  overflow: "unset",
                  paddingTop: "2px",
                },
              }}
            />
          )}
        </SHStack>
      </SHStack>
      <SHStack
        sx={{
          position: "absolute",
          top: "190px",
        }}
      >
        <SHTypography
          variant="subtitle2"
          colorVariant="third"
          sx={{
            padding: 0,
          }}
        >
          {!isEmpty(userInfo?.regions) ? "Region" : null}
        </SHTypography>
        <Truncate
          lines={length > 2 && 2}
          ellipsis={
            <Link
              ref={labelRef}
              variant="body1"
              component="label"
              underline="none"
              sx={{
                paddingLeft: 1,
                fontWeight: 600,
                cursor: "pointer",
                color: palette.text.secondary,
              }}
              onClick={handleOnShowMore}
            >
              Show all...
            </Link>
          }
          style={{
            fontSize: 14,
            textAlign: "justify",
            color: palette.text.third,
            lineHeight: "160%",
            fontWeight: 400,
            wordBreak: "break-all",
          }}
        >
          {userInfo?.regions?.join(", ")}
        </Truncate>
        <SHPopper
          anchorEl={anchorEl}
          open={open}
          placement="top-start"
          popperOptions={{
            modifiers: [{ name: "flip", enabled: false }],
          }}
        >
          <SHBox pb={1}>
            <SHStack
              ref={wrapperRef}
              sx={{
                p: 1,
                pr: 4,
                maxWidth: "365px",
                minHeight: "60px",
                bgcolor: hexToRGBA(palette.common.white, 0.85),
                border: "1px solid #E3E3E3",
                backdropFilter: "blur(2px)",
                borderRadius: "2px",
                position: "relative",
              }}
            >
              <SHTypography
                variant="body2"
                zIndex="1"
                colorVariant="third"
                sx={{
                  textAlign: "justify",
                }}
              >
                {userInfo?.regions?.join(", ")}
              </SHTypography>
              <SHBox sx={{ position: "absolute", top: "10px", right: "10px" }}>
                <CloseButton onClick={handleOnShowMore} />
              </SHBox>
              <SHBox sx={{ position: "absolute", bottom: "-11.7px", left: 0 }}>
                <TooltipAllowSVG />
              </SHBox>
            </SHStack>
          </SHBox>
        </SHPopper>
      </SHStack>
    </SHStack>
  );
};
