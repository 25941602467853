import { WarningButton } from "@components/buttons/warning";
import {
  SHBox,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { EyeIcon } from "@components/svgs/eye";
import { WarningSVG } from "@components/svgs/warning";
import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { Collapse, useTheme } from "@mui/material";
import { LabelLineDetail } from "@pages/platform-analysis/_id/steps/fee/analysis/components/heatmap/components/label-line-detail";
import { LabelLineProjection } from "@pages/platform-analysis/_id/steps/fee/analysis/components/heatmap/components/label-line-projection";
import { VerticalDashLine } from "@pages/platform-analysis/_id/steps/fee/analysis/components/heatmap/components/vertical-dash-line";
import {
  displayCurrencyNumber,
  getFeeDetailMinMax,
  getFeeMinMax,
} from "@pages/platform-analysis/util";
import {
  formatPercentage,
  getHSLLightness,
  hslToHex,
  thousandSeparator,
} from "@utils";
import { isNull } from "lodash";
import { ReactNode, useMemo } from "react";
import { TransitionGroup } from "react-transition-group";
import { FeeSubProductCard } from "../sub-product-card";
import {
  AVG_LIGHTNESS,
  DEFAULT_HUE,
  DEFAULT_SATURATION,
  MAX_LIGHTNESS,
  MIN_LIGHTNESS,
} from "./config";

export interface FeeHeatmapProps extends Omit<SHStackProps, "color"> {
  subProductsInStore: FeeSubProductDTO[];
  subProducts: FeeSubProductDTO[];
  totalPortfolioValue?: number;
  mode?: FeesDisplayStyle;
  heatmapMode?: FeeHeatmapMode;
  hiddenIds?: string[];
  color?: {
    hue: number;
    saturation: number;
  };
  minLightness?: number;
  maxLightness?: number;
  avgLightness?: number;

  titleComp?: ReactNode;
  leftLegend?: ReactNode;
  bottomLegend?: ReactNode;
  captions?: {
    dollar: string;
    percentage: string;
  };
  showWarningCaptions?: boolean;
  onChangeHiddenIds?: (hiddenIds: string[]) => void;
  isCompleted?: boolean;
  isShowHidden?: boolean;
  isSummaryStep?: boolean;
}
export const FeeHeatmap = ({
  subProductsInStore,
  subProducts,
  totalPortfolioValue,
  mode = FeesDisplayStyle.Dollar,
  heatmapMode = FeeHeatmapMode.Projection,
  hiddenIds = [],
  color = {
    hue: DEFAULT_HUE,
    saturation: DEFAULT_SATURATION,
  },
  minLightness = MIN_LIGHTNESS,
  maxLightness = MAX_LIGHTNESS,
  avgLightness = AVG_LIGHTNESS,
  titleComp,
  leftLegend,
  bottomLegend,
  captions = {
    dollar: "Total fees",
    percentage: "of total portfolio value",
  },
  showWarningCaptions,
  onChangeHiddenIds = (ids: string[]) => {},
  isCompleted,
  isShowHidden,
  isSummaryStep,
  ...others
}: FeeHeatmapProps) => {
  const { palette } = useTheme();
  const isDollarMode = mode === FeesDisplayStyle.Dollar;
  const isProjectionMode = heatmapMode === FeeHeatmapMode.Projection;

  const subProductsFiltered = isShowHidden
    ? subProducts
    : subProducts.filter((subProduct) => !hiddenIds.includes(subProduct.id));

  const counterHiddenSubProduct = useMemo(() => {
    return subProductsInStore.length - subProductsFiltered.length;
  }, [subProductsFiltered, subProductsInStore]);

  const showWarning = useMemo(() => {
    return subProductsFiltered?.some(
      (subProduct: FeeSubProductDTO) => subProduct.warning,
    );
  }, [subProductsFiltered]);

  const renderProjection = () => {
    const firstProductFees = subProductsFiltered[0]?.fees ?? [];
    const { min, max } = getFeeMinMax(
      isDollarMode ? "totalCostForDisplay" : "totalCostForDisplayPercentage",
      subProductsFiltered,
    );
    return (
      <SHStack flexGrow={1} spacing={"25px"} width={"1px"}>
        <SHStack spacing={"1px"}>
          <LabelLineProjection firstProductFees={firstProductFees} />
          <VerticalDashLine colNumber={firstProductFees?.length} />
          <SHStack direction={"row"} spacing={"1px"}>
            <SHStack justifyContent={"space-between"}>
              {Array.from(Array(subProductsFiltered.length + 1).keys()).map(
                (serial, index) => (
                  <SHStack
                    key={index}
                    width={"5px"}
                    height={"1px"}
                    sx={{
                      background: palette.text.primary,
                      transform:
                        index === 0
                          ? "translateY(1.5px)"
                          : index === subProductsFiltered.length
                          ? "translateY(-1.5px)"
                          : undefined,
                    }}
                  />
                ),
              )}
            </SHStack>
            <TransitionGroup
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                width: "1px",
              }}
            >
              {subProductsFiltered.map((subProduct) => (
                <Collapse key={subProduct.id}>
                  <SHStack direction={"row"} flex={1}>
                    {subProduct.fees.map((fee, index) => {
                      const lightness = getHSLLightness(
                        min,
                        max,
                        isDollarMode
                          ? fee.totalCostForDisplay
                          : fee.totalCostForDisplayPercentage,
                        minLightness,
                        maxLightness,
                      );
                      const isDarkCell = lightness < avgLightness;
                      return (
                        <SHStack
                          key={index}
                          flex={1}
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={{
                            height: "50px",
                            backgroundColor: hslToHex(
                              color.hue,
                              color.saturation,
                              lightness,
                            ),
                            transition: "all ease-in-out 0.2s",
                            "&:hover": {
                              filter: "brightness(80%)",
                            },
                            m: "1.5px",
                          }}
                        >
                          <SHTypography
                            variant="body2"
                            color={
                              isDarkCell
                                ? palette.common.white
                                : palette.text.darker
                            }
                          >
                            {isDollarMode
                              ? `$${thousandSeparator(
                                  +fee.totalCostForDisplay.toFixed(0),
                                )}`
                              : `${formatPercentage(
                                  fee.totalCostForDisplayPercentage,
                                )}%`}
                          </SHTypography>
                        </SHStack>
                      );
                    })}
                  </SHStack>
                </Collapse>
              ))}
            </TransitionGroup>
          </SHStack>
          <VerticalDashLine colNumber={firstProductFees?.length} />
          <LabelLineProjection firstProductFees={firstProductFees} />
        </SHStack>
        {/*   End */}
      </SHStack>
    );
  };

  const renderBottomChart = () => {
    return (
      <SHStack direction={"row"} marginLeft={"35px"} marginTop={"20px"}>
        <SHStack textAlign={"center"} width={"200px"}>
          <SHTypography color={palette.primary.main} variant="subtitle2">
            {`${counterHiddenSubProduct} ${
              counterHiddenSubProduct === 1 ? "product" : "products"
            } hidden`}
          </SHTypography>
        </SHStack>
        <SHStack
          flex={1}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={showWarningCaptions ? "flex-start" : "center"}
        >
          <SHStack>
            <SHStack direction={"row"} spacing={"5px"} alignItems={"center"}>
              {isProjectionMode ? (
                <>
                  <SHTypography variant="subtitle2">
                    {isDollarMode ? " $" : "%"}
                  </SHTypography>
                  <SHTypography colorVariant="third" variant="subtitle2">
                    {isDollarMode ? captions.dollar : captions.percentage}
                  </SHTypography>
                </>
              ) : (
                <>
                  <SHTypography variant="subtitle2">
                    {isDollarMode ? " $ " : "%"}
                  </SHTypography>
                  <SHTypography colorVariant="third" variant="subtitle2">
                    {`Fees for a total balance of ${displayCurrencyNumber(
                      totalPortfolioValue ?? 0,
                    )}`}
                  </SHTypography>
                </>
              )}
            </SHStack>
            {showWarning && (
              <SHStack
                direction={"row"}
                spacing={"2px"}
                alignItems={"center"}
                ml={"-3px"}
              >
                <WarningSVG
                  backgroundColor={palette.warning.main}
                  iconColor={palette.common.white}
                />
                <SHTypography colorVariant="third" variant="body2">
                  Product is missing some investment options
                </SHTypography>
              </SHStack>
            )}
          </SHStack>

          {bottomLegend}
        </SHStack>
      </SHStack>
    );
  };

  const renderDetails = () => {
    if (!subProductsFiltered[0]?.feeDetails) return null;
    const firstProductFeeDetails = subProductsFiltered[0]?.feeDetails ?? [];
    const arrayMinMax = getFeeDetailMinMax(
      isDollarMode ? "dollar" : "percentage",
      subProductsFiltered,
    );
    /* Detail Mode */
    return (
      <SHStack flexGrow={1} spacing={"25px"} width={"1px"}>
        <SHStack spacing={"1px"}>
          <LabelLineDetail firstProductFeeDetails={firstProductFeeDetails} />
          <VerticalDashLine colNumber={firstProductFeeDetails?.length} />

          <SHStack direction={"row"} spacing={"1px"}>
            <SHStack justifyContent={"space-between"}>
              {Array.from(Array(subProductsFiltered.length + 1).keys()).map(
                (serial, index) => (
                  <SHStack
                    key={index}
                    width={"5px"}
                    height={"1px"}
                    sx={{
                      background: palette.text.primary,
                      transform:
                        index === 0
                          ? "translateY(1.5px)"
                          : index === subProductsFiltered.length
                          ? "translateY(-1.5px)"
                          : undefined,
                    }}
                  />
                ),
              )}
            </SHStack>
            <TransitionGroup
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                width: "1px",
              }}
            >
              {subProductsFiltered.map((subProduct) => (
                <Collapse key={subProduct.id}>
                  <SHStack direction={"row"} flex={1}>
                    {subProduct.feeDetails.map((feeDetail, index) => {
                      const { min, max } = arrayMinMax[index] ?? {
                        min: 0,
                        max: 0,
                      };
                      const lightness = getHSLLightness(
                        min,
                        max,
                        isDollarMode
                          ? feeDetail.dollar || 0
                          : feeDetail.percentage || 0,
                        minLightness,
                        maxLightness,
                      );
                      const isDarkCell = lightness < avgLightness;
                      return (
                        <SHStack
                          key={index}
                          flex={1}
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={{
                            height: "50px",
                            backgroundColor: hslToHex(
                              color.hue,
                              color.saturation,
                              lightness,
                            ),
                            transition: "all ease-in-out 0.2s",
                            "&:hover": {
                              filter: "brightness(80%)",
                            },
                            my: "1.5px",
                            mr: index === 0 ? "15px" : "1.5px",
                            ml:
                              index === subProduct.feeDetails.length - 1
                                ? "15px"
                                : "1.5px",
                          }}
                        >
                          <SHTypography
                            variant="body2"
                            color={
                              isDarkCell
                                ? palette.common.white
                                : palette.text.darker
                            }
                          >
                            {isNull(feeDetail.percentage) ||
                            isNull(feeDetail.dollar)
                              ? "No data"
                              : isDollarMode
                              ? `$${thousandSeparator(
                                  +feeDetail.dollar.toFixed(0),
                                )}`
                              : `${formatPercentage(feeDetail.percentage)}%`}
                          </SHTypography>
                        </SHStack>
                      );
                    })}
                  </SHStack>
                </Collapse>
              ))}
            </TransitionGroup>
          </SHStack>

          <VerticalDashLine colNumber={firstProductFeeDetails?.length} />
          <LabelLineDetail firstProductFeeDetails={firstProductFeeDetails} />
        </SHStack>
        {/*   End */}
      </SHStack>
    );
  };

  const hasData =
    subProductsFiltered.length &&
    (isProjectionMode
      ? subProductsFiltered[0].fees?.length
      : subProductsFiltered[0].feeDetails?.length);

  return (
    <SHStack
      spacing={"25px"}
      sx={{
        background: palette.common.white,
        border: `1px solid ${palette.secondary[100]}`,
        p: "28px",
      }}
      {...others}
    >
      {titleComp}
      {hasData ? (
        <SHStack>
          <SHStack
            direction={"row"}
            alignItems={"stretch"}
            width={"100%"}
            height={"100%"}
          >
            {leftLegend}
            <SHStack sx={{ alignItems: "center", justifyContent: "center" }}>
              <TransitionGroup
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "25px",
                  // marginTop: isProjectionMode ? "23px" : "35px",
                }}
              >
                {subProductsFiltered.map((subProduct) => (
                  <Collapse key={subProduct.id}>
                    <SHStack
                      direction="row"
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"190px"}
                      height={"53px"}
                      onClick={() => {
                        if (isCompleted && isSummaryStep) return;
                        const isActive = !hiddenIds.includes(subProduct.id);
                        if (isActive) {
                          onChangeHiddenIds([...hiddenIds, subProduct.id]);
                        } else {
                          onChangeHiddenIds(
                            hiddenIds.filter((id) => id !== subProduct.id),
                          );
                        }
                      }}
                      sx={{
                        cursor:
                          isCompleted && isSummaryStep ? "default" : "pointer",
                      }}
                    >
                      <FeeSubProductCard isActive subProduct={subProduct} />
                      <SHStack>
                        <SHBox
                          sx={{
                            padding: 0,
                            width: "24px",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            marginBottom: "4px",
                            display: "flex",
                            "&:hover":
                              isCompleted && isSummaryStep
                                ? undefined
                                : {
                                    "& .sh-image-svg": {
                                      "& .sh-eye-svg-g": {
                                        "& .sh-icon-svg-eye": {
                                          fill: palette.grey[500],
                                        },
                                      },
                                    },
                                  },
                          }}
                        >
                          <EyeIcon isActive={false} />
                        </SHBox>
                        {subProduct.warning && (
                          <SHStack>
                            <WarningButton
                              title={"Missing investment option"}
                              content={
                                <ul
                                  style={{ margin: 0, padding: "5px 0 0 15px" }}
                                >
                                  {subProduct.warning.map((content) => (
                                    <li style={{ padding: "4px 0" }}>
                                      {content}
                                    </li>
                                  ))}
                                </ul>
                              }
                              customSVG={
                                <SHStack mb={"-3px"}>
                                  <WarningSVG
                                    backgroundColor={palette.warning.main}
                                    iconColor={palette.common.white}
                                  />
                                </SHStack>
                              }
                            />
                          </SHStack>
                        )}
                      </SHStack>
                    </SHStack>
                  </Collapse>
                ))}
              </TransitionGroup>
            </SHStack>
            {isProjectionMode ? renderProjection() : renderDetails()}
          </SHStack>
          {renderBottomChart()}
        </SHStack>
      ) : (
        <SHStack>
          <SHTypography variant="subtitle1" colorVariant="disabled">
            No data
          </SHTypography>
          <SHTypography variant="body2" colorVariant="disabled">
            No products match the criteria in your filter
          </SHTypography>
          <SHTypography
            color={palette.primary.main}
            variant="subtitle2"
            sx={{
              marginTop: "25px",
            }}
          >
            {`${counterHiddenSubProduct} ${
              counterHiddenSubProduct === 1 ? "product" : "products"
            } hidden`}
          </SHTypography>
        </SHStack>
      )}
    </SHStack>
  );
};
