import { APIError } from "@models/core";

export const toErrorMessage = (error: APIError): string | undefined | null => {
  if (!error || typeof error === "string") return error;
  if (error?.validationErrors || error?.error) {
    let messages: string[] = error?.error ? [error.error] : [];
    Object.values(error?.validationErrors ?? {}).forEach((field) => {
      field.forEach((message) => messages.push(message));
    });
    if (messages.length === 1) return messages.join(`<br/>`);
    return `<div style="text-align:left">${messages.join(`<br/>`)}</div>`;
  }
  return error.toString();
};
