import { SVGProps } from "react";
import "./styles.scss";

export const RollBackSVG = ({
  color = "#25324B",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    className="rollbackSvg"
    {...props}
  >
    <path
      d="M9.28771 0.61404C8.39821 0.588638 7.51266 0.741906 6.68344 1.06477C5.85423 1.38764 5.09819 1.87355 4.46007 2.49376C3.82195 3.11396 3.31471 3.85585 2.96837 4.67554C2.62202 5.49523 2.4436 6.37606 2.44367 7.26592H1.12068C0.788089 7.26592 0.625488 7.66503 0.861999 7.89415L2.92408 9.96362C3.0719 10.1114 3.30102 10.1114 3.44884 9.96362L5.51092 7.89415C5.74743 7.66503 5.57744 7.26592 5.25224 7.26592H3.92186C3.92186 4.38344 6.27219 2.05528 9.16945 2.09223C11.9189 2.12919 14.2323 4.44256 14.2692 7.19201C14.3062 10.0819 11.978 12.4396 9.09554 12.4396C7.9056 12.4396 6.80434 12.0331 5.93221 11.3457C5.79064 11.2342 5.61303 11.1786 5.43314 11.1895C5.25326 11.2004 5.08366 11.2771 4.9566 11.4049C4.64618 11.7227 4.66835 12.24 5.01573 12.5135C6.17996 13.4254 7.61669 13.9199 9.09554 13.9178C12.828 13.9178 15.8509 10.8358 15.7474 7.07375C15.6513 3.60738 12.7541 0.710123 9.28771 0.61404Z"
      fill={color}
    />
  </svg>
);
