import { View } from "@react-pdf/renderer";

interface PDFReviewTableBodyProps {
  children: React.ReactNode;
}
export const PDFReviewTableBody = ({ children }: PDFReviewTableBodyProps) => {
  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderColor: "#7C8493",
      }}
    >
      {children}
    </View>
  );
};
