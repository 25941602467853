import { SVGProps } from "react";
export const CheckCircleSVG = ({
  color = "#348221",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13058_48747)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.41179 2.48049C5.56294 1.71132 6.91631 1.30078 8.30078 1.30078C10.1573 1.30078 11.9378 2.03828 13.2505 3.35103C14.5633 4.66379 15.3008 6.44427 15.3008 8.30078C15.3008 9.68525 14.8902 11.0386 14.1211 12.1898C13.3519 13.3409 12.2586 14.2381 10.9796 14.7679C9.70048 15.2977 8.29302 15.4364 6.93515 15.1663C5.57728 14.8962 4.33 14.2295 3.35104 13.2505C2.37207 12.2716 1.70538 11.0243 1.43529 9.66641C1.16519 8.30855 1.30381 6.90108 1.83363 5.622C2.36344 4.34291 3.26065 3.24966 4.41179 2.48049ZM5.05996 13.151C6.01924 13.792 7.14706 14.1341 8.30078 14.1341C9.06683 14.1341 9.82537 13.9832 10.5331 13.6901C11.2408 13.3969 11.8839 12.9672 12.4256 12.4256C12.9672 11.8839 13.3969 11.2408 13.6901 10.5331C13.9832 9.82537 14.1341 9.06682 14.1341 8.30078C14.1341 7.14706 13.792 6.01924 13.151 5.05995C12.51 4.10067 11.599 3.35299 10.5331 2.91148C9.4672 2.46997 8.29431 2.35445 7.16276 2.57953C6.0312 2.80461 4.9918 3.36019 4.17599 4.17599C3.36019 4.9918 2.80462 6.0312 2.57954 7.16275C2.35446 8.29431 2.46998 9.4672 2.91149 10.5331C3.353 11.599 4.10067 12.51 5.05996 13.151ZM6.979 11.2908C7.17426 11.486 7.49084 11.486 7.68611 11.2908L12.1101 6.86681C12.2056 6.75522 12.2556 6.61167 12.2499 6.46486C12.2442 6.31805 12.1834 6.17879 12.0795 6.0749C11.9756 5.97101 11.8363 5.91015 11.6895 5.90448C11.5427 5.89881 11.3991 5.94874 11.2876 6.04431L7.33255 9.99931L5.23839 7.90514C5.12679 7.80958 4.98325 7.75964 4.83644 7.76531C4.68963 7.77098 4.55036 7.83184 4.44647 7.93573C4.34259 8.03962 4.28173 8.17888 4.27605 8.32569C4.27038 8.47251 4.32032 8.61605 4.41589 8.72764L6.979 11.2908Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_13058_48747">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
