import { mockPractices } from "@components/auto-completes/practice/mock";
import {
  SHAutocomplete,
  SHBox,
  SHCheckbox,
  SHStack,
  SHTextFieldProps,
  SHTypography,
} from "@components/design-systems";
import { useUserPermissions } from "@hooks/userUserPermission";
import { PracticeDTO } from "@models/supplier/entities/practices";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
export interface SelectPracticesProps {
  disabled?: boolean;
  value?: PracticeDTO[] | null;
  onChange: (value: PracticeDTO[] | null) => void;
  practiceData?: PracticeDTO[];
  textFieldProps?: Omit<SHTextFieldProps, "value">;
  supplierId?: string;
}
export const SelectPractices = ({
  disabled,
  value = [],
  onChange,
  practiceData: practiceDataProp = mockPractices,
  textFieldProps,
  supplierId,
}: SelectPracticesProps) => {
  const { palette } = useTheme();
  const [practiceData, setPracticeData] =
    useState<PracticeDTO[]>(practiceDataProp);
  const [selectedPractices, setSelectedPractices] = useState<PracticeDTO[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const { isAdminGroup } = useUserPermissions();

  useEffect(() => {
    setPracticeData(practiceDataProp);
    setSelectedPractices(
      practiceDataProp.filter((option) => option.licenseeId === supplierId),
    );
  }, [practiceDataProp, supplierId]);

  return (
    <SHStack spacing={"16px"} direction="column">
      {isAdminGroup && (
        <SHAutocomplete
          multiple
          value={selectedPractices}
          disabled={disabled}
          disableCloseOnSelect
          onClose={() => setInputValue("")}
          inputValue={inputValue}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          getOptionLabel={(option: PracticeDTO) => option.name}
          textFieldProps={{
            ...textFieldProps,
            InputLabelProps: { shrink: true },
            placeholder: value?.length
              ? undefined
              : textFieldProps?.placeholder,
            InputProps: {
              startAdornment: selectedPractices?.length ? (
                <SHTypography
                  disabled={disabled}
                  variant="body1"
                  sx={{ paddingLeft: "10px" }}
                >
                  {`${selectedPractices?.length}/${practiceData.length} selected`}
                </SHTypography>
              ) : undefined,
            },
            onChange: (e) => {
              setInputValue(e.target.value);
            },
          }}
          onChange={(e: any, newValue, r, details) => {
            if (
              r === "removeOption" &&
              e?.type === "keydown" &&
              e?.key === "Backspace"
            ) {
              return;
            }

            if (!Array.isArray(newValue)) {
              onChange([]);
              return;
            }

            if (practiceData.length && newValue?.length > practiceData.length)
              return;
            onChange(newValue);
            setSelectedPractices(newValue);
          }}
          options={practiceData}
          renderOption={(props, option, { selected }) => {
            const isChecked = selected;
            return (
              <li {...props} key={option.id} style={{ padding: "1px 10px" }}>
                <SHStack alignItems={"center"} direction="row" spacing={1}>
                  <SHCheckbox checked={isChecked} />
                  <SHTypography variant="body3" fontWeight={500}>
                    {option.name}
                  </SHTypography>
                </SHStack>
              </li>
            );
          }}
        />
      )}

      {!isAdminGroup && (
        <SHTypography variant="subtitle2" disabled={disabled}>
          Linked practices
        </SHTypography>
      )}
      <SHBox
        sx={{
          height: "100px",
          px: "12px",
          py: "10px",
          border: `1px solid ${palette.divider}`,
          overflowY: "auto",
        }}
        p={"12px"}
      >
        <SHTypography variant="body2" disabled={disabled}>
          {selectedPractices?.map((option) => option.name)?.join(", ")}
        </SHTypography>
      </SHBox>
    </SHStack>
  );
};
