import { SHStack } from "@components/design-systems";
import SHSkeleton, {
  SHSkeletonProps,
} from "@components/design-systems/sh-skeleton";

export const StepNameSkeleton = ({ ...others }: SHSkeletonProps) => {
  return (
    <SHStack spacing={2}>
      <SHSkeleton height={35} width={120} variant={"rounded"} {...others} />
      <SHSkeleton height={40} width={200} variant={"rounded"} {...others} />
    </SHStack>
  );
};
