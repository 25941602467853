import { SHTableCell, SHTableCellProps } from "@components/design-systems";

interface FRCellProps extends SHTableCellProps {
  children?: React.ReactNode;
  isHeader?: boolean;
  cellWidth?: number | string;
}
export const FRCell = ({
  children,
  isHeader = false,
  cellWidth = "210px",
  ...others
}: FRCellProps) => {
  return (
    <SHTableCell
      sx={{
        height: "auto",
        width: cellWidth,
        minWidth: cellWidth,
        maxWidth: cellWidth,
        padding: "10px 15px",
      }}
      {...others}
    >
      {children}
    </SHTableCell>
  );
};
