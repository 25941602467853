import { isEmpty, orderBy } from "lodash";

export function constructUserName(
  name?: string | null,
  firstName?: string | null,
  lastName?: string | null,
): string {
  if (!firstName && !lastName && !name) {
    return "";
  }
  if (isEmpty(firstName) && isEmpty(lastName)) return name ?? "";
  const nameArray = [firstName, lastName].filter(Boolean);
  return nameArray.join(" ");
}
export function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

export function generateGAvatarUrl(
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
): string {
  const md5 = require("md5");

  const newEmail = email?.trim()?.toLowerCase();
  const letter = `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toLowerCase();
  const defaultAvatar = `https://cdn.auth0.com/avatars/${letter}.png`;

  return `https://www.gravatar.com/avatar/${md5(newEmail)}?d=${defaultAvatar}`;
}

export const generateUUID = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const nameOfFactory =
  <T>() =>
  (name: keyof T) =>
    name;

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const sortAscByOrder = <T>(array: T[]) =>
  orderBy(array, ["order"], ["asc"]);

export const tryParseJSON = (data: string) => {
  try {
    var object = JSON.parse(data);
    if (object && typeof object === "object") {
      return object;
    }
  } catch {}
  return false;
};

export const checkingDomain = (
  organizationDomain?: string,
  userDomain?: string,
) => {
  if (!organizationDomain || !userDomain) return false;

  const regex = new RegExp(`${organizationDomain}`, "i");
  return regex.test(userDomain);
};
