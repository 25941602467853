import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { PageRoutes } from "@constants";
import { email } from "@constants/email";
import { useEditProduct } from "@hooks/useEditProduct";
import { useNotification } from "@hooks/useNotification";
import { HubConnectionState } from "@microsoft/signalr";
import { DefaultSocketErrorMessage } from "@models/core";
import { Link, useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import {
  updateEditMode,
  updateLoadingEditMode,
  updateShowClaimDeniedDialog,
} from "@redux/slices/product";
import { SignalRService } from "@services/signalr/signalRService";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";

export interface ConfirmKickOutAdminDialogProps {
  supplierId?: string;
  productId?: string;
}

// TODO: Temporary fix for 20/1 release
export const ConfirmKickOutAdminDialog = ({
  supplierId,
  productId,
}: ConfirmKickOutAdminDialogProps) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const { palette } = useTheme();
  const { handleOnClaimingEditingPermission } = useEditProduct();
  const navigate = useNavigate();
  const { notify } = useNotification();

  const {
    productUI: { showClaimDeniedDialog },
  } = useSelector((state: RootState) => state.product);

  const handleOnClose = () => {
    dispatch(updateShowClaimDeniedDialog(false));
    dispatch(updateLoadingEditMode(false));
    setIsLoading(false);
    dispatch(updateEditMode(false));
  };

  const handleOnClickYes = async () => {
    setIsLoading(true);
    const connection = SignalRService.getHubConnection();
    if (connection && connection.state === HubConnectionState.Connected) {
      await handleOnClaimingEditingPermission(productId || "", () => {
        dispatch(updateEditMode(true));
        navigate(
          generatePath(PageRoutes.supplierProducts, {
            supplierId,
            productId,
          }),
        );
      });
      setIsLoading(false);
      dispatch(updateShowClaimDeniedDialog(false));
    } else {
      ///else show error here
      setIsLoading(false);
      notify(DefaultSocketErrorMessage, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <SHDialog
      open={showClaimDeniedDialog || false}
      onClose={handleOnClose}
      width={645}
      disableBackdropClick
      disableEscapeKeyDown
      header={
        <SHTypography variant="h2">
          Profile temporarily locked. <br />
          We are touching up your profile
        </SHTypography>
      }
      body={
        <SHStack>
          <SHTypography
            variant="body2"
            sx={{
              width: 430,
            }}
          >
            Hi {user?.name}, someone from the SuitabilityHub admin team is
            currently making updates to your product profile. We only allow one
            user to edit the Profile at one time.
          </SHTypography>
          <br />
          <SHTypography
            variant="body2"
            sx={{
              width: 430,
            }}
          >
            We endeavour to make our changes quickly to limit any inconvenience
            to you. Please check back in half an hour or contact us
            <Link
              variant="body2"
              color={palette.text.secondary}
              component="a"
              underline="none"
              sx={{
                paddingLeft: 0.5,
                cursor: "pointer",
              }}
              href={`mailto:${email.support}`}
            >
              here
            </Link>
            .
          </SHTypography>
          <br />
          <SHTypography
            variant="body2"
            sx={{
              width: 430,
            }}
          >
            Alternatively, you can kick us out. Proceed with kicking out
            SuitabilityHub admin?
          </SHTypography>
        </SHStack>
      }
      footer={
        <SHStack
          width="100%"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={7}
        >
          <SHButton
            color="primary"
            variant="text"
            disabled={isLoading}
            onClick={handleOnClose}
            size="extraMedium"
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleOnClickYes}
            size="extraMedium"
          >
            Yes, kick them out
          </SHButton>
        </SHStack>
      }
    />
  );
};
