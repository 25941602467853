import Dialog, { DialogProps } from "@mui/material/Dialog";
import { SHStack } from "../sh-stack";
import { SHTypography } from "../sh-typography";

export interface SHDialogProps extends DialogProps {
  width?: number | string;
  header?: string | React.ReactNode;
  body?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  disableBackdropClick?: boolean;
}

export const SHDialog = ({
  width = 500,
  header,
  body,
  footer,
  disableBackdropClick,
  ...props
}: SHDialogProps): JSX.Element => {
  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          width: { width },
        },
      }}
      {...props}
      onClose={(event, reason: "backdropClick" | "escapeKeyDown") => {
        if (disableBackdropClick && reason === "backdropClick") {
          return;
        }
        if (typeof props.onClose === "function") props.onClose(event, reason);
      }}
    >
      {props.children ? (
        props.children
      ) : (
        <SHStack
          direction="column"
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          {typeof header === "string" ? (
            <SHTypography variant="h2" lineHeight={"120%"}>{header}</SHTypography>
          ) : (
            header
          )}
          {typeof body === "string" ? (
            <SHTypography
              variant="body2"
              sx={{ fontSize: "16px", marginTop: "16px" }}
            >
              {body}
            </SHTypography>
          ) : (
            body
          )}
          {footer}
        </SHStack>
      )}
    </Dialog>
  );
};
