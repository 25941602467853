import { Components, Theme } from "@mui/material";

export const muiTabsTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiTabs" | "MuiTab"> => ({
  MuiTab: {
    styleOverrides: {
      root: {
        flexDirection: "row",
        alignItems: "center",
        paddingTop: "6px",
        marginRight: "2px",
        paddingBottom: "6px",
        paddingLeft: "16px",
        paddingRight: "16px",
        minHeight: "32px",
        borderRadius: "25px",
        textTransform: "none",
        transition: "all 150ms",
        boxShadow: "none",
        border: "1px solid transparent",
        color: theme.palette.text?.primary,
        fontWeight: 500,
        "&:hover": {
          backgroundColor: "transparent",
          fontWeight: 600,
        },
        "&:active": {
          border: `1px solid ${theme.palette.primary[800]}`,
        },
        "&.Mui-disabled": {
          color: theme.palette.text.disabled,
        },
        "&.Mui-selected": {
          fontWeight: 600,
          color: theme.palette.text?.primary,
          border: `1px solid ${theme.palette.primary[800]}`,
        },
        "&>.MuiTab-iconWrapper": {
          marginBottom: 0,
          marginRight: "6px",
          marginTop: "-5px",
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: "38px",
        "& .MuiTabs-indicator": {
          display: "none",
        },
        "& .MuiTabs-scroller,& .MuiTabs-scroller .MuiTabs-flexContainer": {
          height: "38px",
        },
        "&>.MuiTabScrollButton-root": {
          borderRadius: "25px",
        },
        "&>.MuiTabScrollButton-root.Mui-disabled": {
          opacity: 0.3,
        },
      },
    },
  },
});
