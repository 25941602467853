import { SHButton } from "@components/design-systems";
import { PageRoutes } from "@constants";
import { useNotification } from "@hooks/useNotification";
import { useProduct } from "@hooks/useProduct";
import { RootState } from "@redux/store";
import { discardDraft } from "@services/product/productsService";
import { useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router";
import { useUserPermissions } from "@hooks/userUserPermission";
import { useAppDispatch } from "@redux/store";
import { updateSelectedGroup } from "@redux/slices/product";
import { DiscardDraftDialog } from "@pages/suppliers/_id/_products/_id/components/dialogs/discard-draft";
export interface DiscardDraftButtonProps {
  productId?: string;
  isFirstDraft?: boolean;
}

export const DiscardDraftButton = ({
  productId,
  isFirstDraft,
}: DiscardDraftButtonProps) => {
  const { notify } = useNotification();
  const { loadBannerInfo, refreshProductGroups, loadProductChangeHistory } =
    useProduct();
  const { canDiscardDraftProduct } = useUserPermissions();
  const { supplierId } = useParams<{ supplierId: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    productUI: { isEditMode },
  } = useSelector((state: RootState) => state.product);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showShowDialog, setShowDialog] = useState(false);

  const isVisible = isEditMode && canDiscardDraftProduct;

  const handleOnDiscardDraft = async () => {
    if (!productId) return;

    setIsSubmitting(true);
    const { isSuccess, message } = await discardDraft(productId);
    setIsSubmitting(false);
    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      if (isFirstDraft) {
        //Navigate to new product because current version has been deleted
        dispatch(updateSelectedGroup(0));
        navigate(
          generatePath(PageRoutes.supplierProducts, {
            supplierId: supplierId,
            productId: "new",
          }),
          {
            replace: true,
          },
        );
        return;
      }
      refreshProductGroups();
      loadProductChangeHistory(productId);
      loadBannerInfo(productId);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <>
      {isVisible && (
        <SHButton
          variant="outlined"
          size="medium"
          onClick={() => setShowDialog(true)}
        >
          Discard draft
        </SHButton>
      )}
      {showShowDialog && (
        <DiscardDraftDialog
          isSubmitting={isSubmitting}
          onSubmit={handleOnDiscardDraft}
          onClose={() => setShowDialog(false)}
        />
      )}
    </>
  );
};
