import { UserType } from "@models/auth";
import { GetUserInfoDTO } from "@models/users/entities/user";

export const getUserRole = (user?: GetUserInfoDTO) => {
  //Admin
  const isSuperAdmin = user?.userType === UserType.SuperAdmin;
  const isStandardAdmin = user?.userType === UserType.StandardAdmin;
  //Admin group
  const isAdminGroup = isSuperAdmin || isStandardAdmin;

  //Supplier
  const isSupplierPremiumOwner =
    user?.userType === UserType.SupplierPremiumOwner;
  const isSupplierBasic = user?.userType === UserType.SupplierBasic;
  const isSupplierPremiumStandard =
    user?.userType === UserType.SupplierPremiumStandard;
  const isSupplierBdmSale = user?.userType === UserType.SupplierBdmSale;

  //Supplier group
  const isSupplierGroup =
    isSupplierPremiumOwner ||
    isSupplierBasic ||
    isSupplierPremiumStandard ||
    isSupplierBdmSale;

  //Adviser
  const isAdviserAdviser = user?.userType === UserType.AdviserAdviser;
  const isAdviserTrial = user?.userType === UserType.AdviserTrial;
  const isAdviserSupportStaff = user?.userType === UserType.AdviserSupportStaff;
  const isAdviserAdminAdviser = user?.userType === UserType.AdviserAdminAdviser;
  const isAdviserAdminTrial = user?.userType === UserType.AdviserAdminTrial;
  const isAdviserAdminSupportStaff =
    user?.userType === UserType.AdviserAdminSupportStaff;
  //Adviser group
  const isAdviserAdminGroup =
    isAdviserAdminTrial || isAdviserAdminAdviser || isAdviserAdminSupportStaff;
  const isAdviserGroup =
    isAdviserAdviser ||
    isAdviserTrial ||
    isAdviserSupportStaff ||
    isAdviserAdminGroup;
  const isAdviserTrialGroup = isAdviserAdminTrial || isAdviserTrial;
  const isAdviserSupportStaffGroup = isAdviserSupportStaff || isAdviserAdminSupportStaff;

  const isUser =
    isAdminGroup || isSupplierGroup || isAdviserAdminGroup || isAdviserGroup;
  return {
    isSuperAdmin,
    isStandardAdmin,
    isSupplierPremiumOwner,
    isSupplierBasic,
    isSupplierPremiumStandard,
    isSupplierBdmSale,
    isAdviserAdviser,
    isAdviserAdminAdviser,
    isAdviserTrial,
    isAdviserAdminTrial,
    isAdviserSupportStaff,
    isAdviserAdminSupportStaff,
    isAdminGroup,
    isSupplierGroup,
    isAdviserGroup,
    isAdviserAdminGroup,
    isAdviserTrialGroup,
    isAdviserSupportStaffGroup,
    isUser,
  };
};
