import { SHStack, SHTypography } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { UserType } from "@models/auth";
import { RootState } from "@redux/store";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

export interface AnalysisNameProps {
  ownerName?: string;
  supplierName?: string;
  adviserName?: string;
  practiceName?: string;
  ownerType?: UserType;
}
export const AnalysisName = ({
  ownerName,
  supplierName,
  adviserName,
  practiceName,
  ownerType,
}: AnalysisNameProps) => {
  const {
    bannerName,
    ui: { isLoadingBanner },
  } = useSelector((state: RootState) => state.platformAnalysis);

  const owner = ownerName ?? bannerName?.ownerName;
  const supplier = supplierName ?? bannerName?.supplierName;
  const adviser = adviserName ?? bannerName?.adviserName;
  const practice = practiceName ?? bannerName?.practiceName;
  const isEmptyAdviser = isEmpty((adviser || "").trim());
  const isEmptyPractice = isEmpty((practice || "").trim());
  const isSupplierBdmSale =
    (ownerType ?? bannerName?.ownerType) === UserType.SupplierBdmSale;
  const isShowAdviserName =
    isSupplierBdmSale && !isEmptyAdviser && !isEmptyPractice;

  if (isLoadingBanner)
    return (
      <SHStack direction="row" justifyContent="flex-start" width={"100%"}>
        <SHSkeleton height={30} width={"50%"} />
      </SHStack>
    );

  return (
    <SHStack
      sx={{
        flexGrow: 1,
        width: "1px",
      }}
    >
      <SHStack
        direction="row"
        justifyContent="flex-start"
        alignItems={"flex-end"}
        spacing={0.5}
      >
        {owner && (
          <>
            <SHTypography
              variant="h2"
              fontSize={"18px"}
              lineHeight={1}
              truncate
              sx={{
                maxWidth: "60%",
              }}
            >
              {owner}
            </SHTypography>
            {supplier && (
              <SHTypography
                variant="h2"
                lineHeight={1}
                fontSize={"18px"}
                fontWeight={400}
                truncate
                sx={{
                  maxWidth: "40%",
                }}
              >
                {`of ${supplier}`}
              </SHTypography>
            )}
          </>
        )}
      </SHStack>
      {isShowAdviserName ? (
        <SHStack
          direction="row"
          justifyContent="flex-start"
          alignItems={"flex-end"}
          spacing={0.5}
        >
          {adviser && (
            <>
              <SHTypography variant="body2">for</SHTypography>
              <SHTypography
                variant="subtitle2"
                truncate
                sx={{
                  maxWidth: "calc(50% - 20px)",
                }}
              >
                {adviser}
              </SHTypography>
              {practice && (
                <SHTypography
                  variant="body2"
                  truncate
                  sx={{
                    maxWidth: "50%",
                  }}
                >
                  {`from ${practice}`}
                </SHTypography>
              )}
            </>
          )}
        </SHStack>
      ) : (
        <SHTypography variant="body2">for internal use only</SHTypography>
      )}
    </SHStack>
  );
};
