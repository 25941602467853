import { SelectRateCard } from "@models/rate-card/entities/selectRateCard";
import { AnalysisStep } from "../../enums/step";

export class AnalysisSetupDTO {
  ownerId: string = "";
  ownerName: string = "";
  ownerType: string = "";
  ownerAvatarUrl: string = "";
  supplierId: string = "";
  supplierName: string = "";
  name: string = "";
  hasFeatureStep: boolean = false;
  hasBusinessMetricStep: boolean = false;
  hasFeeStep: boolean = false;
  hasRefreshData: boolean = false;
  adviserName: string = "";
  practiceName: string = "";
  objectives?: string = "";
  mainProductId: string | null = null;
  otherProductIds: string[] = [];
  loadTemplateId?: string = "";
  loadAnalysisId?: string = "";
  hasMainProduct?: boolean = false;
  hasSteps: AnalysisStep[] = [];
  hasRateCard?: boolean = false;
  selectRateCards: SelectRateCard[] = [];
}
