import { ReviewDecisionStatus } from "@models/reviews/entities/status";

export enum EssentialFeaturesFilter {
  MarkedEssential = "MarkedEssential",
  NotMarkedEssential = "NotMarkedEssential",
}

export const EssentialFeaturesFilterOptions = [
  {
    label: "Features marked Essential",
    value: EssentialFeaturesFilter.MarkedEssential,
  },
  {
    label: "Features not marked Essential",
    value: EssentialFeaturesFilter.NotMarkedEssential,
  },
];

export enum QualificationFilter {
  Shortlisted = "Shortlisted",
  Disqualified = "Disqualified",
  Undecided = "Undecided",
}

export const QualificationFilterOptions = [
  {
    label: "Shortlisted",
    value: QualificationFilter.Shortlisted,
  },
  {
    label: "Disqualified",
    value: QualificationFilter.Disqualified,
  },
  {
    label: "Pending decision",
    value: QualificationFilter.Undecided,
  },
];

export enum FeatureFilter {
  Common = "Common",
  Differentiators = "Differentiators",
}

export const FeatureFilterOptions = [
  {
    label: "Common (Features available on each platform)",
    value: FeatureFilter.Common,
  },
  {
    label: "Differentiators (Features not available on each platform)",
    value: FeatureFilter.Differentiators,
  },
];

export type UpdateDecisionStatus = {
  platformId: string;
  decisionStatus: ReviewDecisionStatus;
};

export type GroupByDecisionStatus = {
  [key in ReviewDecisionStatus]?: string[];
};

export type FeatureReviewFilters = {
  [key in "Feature" | "FeatureEssential"]?: any[];
};

export const TABLE_ROW_HEIGHT = 54;
