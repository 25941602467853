import { APIExtRoutes } from "@constants/routes";
import {
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
} from "@microsoft/signalr";
export class SignalRService {
  private static hubConnection: HubConnection | null = null;

  static initialize(
    options: IHttpConnectionOptions,
    withAutomaticReconnect: boolean,
  ) {
    if (!SignalRService.hubConnection) {
      let builder = new HubConnectionBuilder().withUrl(
        `${process.env.REACT_APP_SH_API_GW_URL}${APIExtRoutes.notification}`,
        options,
      );

      if (withAutomaticReconnect) {
        builder = builder.withAutomaticReconnect();
      }

      SignalRService.hubConnection = builder.build();
    }
  }

  static getHubConnection() {
    return this.hubConnection;
  }
}

// export const signalrConnection = SignalRService.getHubConnection();
