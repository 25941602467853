import { DeleteButton } from "@components/buttons/delete";
import { InformationButton } from "@components/buttons/information";
import {
  SHBooleanField,
  SHButton,
  SHDivider,
  SHIconLoading,
  SHStack,
  SHTextField,
  SHTypography,
  SHUploadImage,
} from "@components/design-systems";
import { ConfirmDialog } from "@components/dialogs/confirm";
import UnsavedDialog from "@components/dialogs/unsaved";
import { StatusBadge } from "@components/status-badge";
import { CheckSVG } from "@components/svgs";
import { PageRoutes } from "@constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppParams } from "@hooks/useAppParams";
import { useIsNew } from "@hooks/useIsNew";
import { useParentState } from "@hooks/useParentState";
import { usePractice } from "@hooks/usePractice";
import { useUpload } from "@hooks/useUpload";
import { useUserPermissions } from "@hooks/userUserPermission";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import {
  AdviserFirmDTO,
  CreateAdviserFirmDTO,
  MatchingLicensee,
  UpdateAdviserFirmDTO,
} from "@models/practice/entities/practice";
import { AdviserFirmStatus } from "@models/practice/enums/status";
import { RootState } from "@redux/store";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { FileRejection } from "react-dropzone";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";
import { generateSubTitle } from "./config";
import { PracticeOverviewSkeleton } from "./skeleton";
import { StatusButton, getButtonsByStatus, practiceValidators } from "./util";

export const PracticeOverview = () => {
  const isNew = useIsNew();
  const { isMyAdviserFirm } = useAppParams();
  const navigate = useNavigate();
  const {
    practice,
    practiceId,
    status,
    ui: { isLoading, isModifyingStatus },
    setPracticeName,
    setPractice,
    createPractice,
    updatePractice,
    updatePracticeStatus,
    loadLicenseeByAFSL,
  } = usePractice();
  const { user } = useSelector((state: RootState) => state.auth);
  const { isDeleting, isUploading, uploadOne, deleteOne } = useUpload();
  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...new AdviserFirmDTO(),
      afsl: new AdviserFirmDTO().afsl,
      isLinkedToLicensee: new AdviserFirmDTO().isLinkedToLicensee,
    },
    resolver: yupResolver(practiceValidators),
  });
  const { isEditMode, setEditMode } = useParentState();
  const { isDirty } = useFormState({ control });
  const { isAdminGroup, isAdviserAdminGroup } = useUserPermissions();
  const [changeStatusConfirm, setChangeStatusConfirm] = useState<
    StatusButton | undefined
  >();

  const isDisabled = useMemo(() => {
    return isSubmitting || !isEditMode || !!isModifyingStatus;
  }, [isSubmitting, isEditMode, isModifyingStatus]);
  const statusButtons = useMemo(() => getButtonsByStatus(status), [status]);
  const subTitleFields = generateSubTitle(isMyAdviserFirm);
  const [matchingLicensee, setMatchingLicensee] =
    useState<MatchingLicensee | null>(null);
  const [checkMatchingAFSL, setCheckMatchingAFSL] = useState<
    "checking" | "none"
  >("none");

  const timer = useRef<NodeJS.Timeout | null>(null);

  const isLinkedToLicensee = watch("isLinkedToLicensee");

  const handleSubmitPractice = async (practice: AdviserFirmDTO) => {
    if (isNew) {
      const newPractice = await createPractice({
        name: practice.name,
        logo: practice.logo,
        logoS3Key: practice.logoS3Key,
        branchName: practice.branchName,
        afsl: practice.afsl.toString(),
        contactName: practice.contactName,
        contactEmail: practice.contactEmail,
        contactPhoneNumber: practice.contactPhoneNumber,
        status: AdviserFirmStatus.Active,
        licenseeId: isLinkedToLicensee ? matchingLicensee?.id : null,
        licenseeName: isLinkedToLicensee ? matchingLicensee?.name : null,
      } as CreateAdviserFirmDTO);

      if (newPractice) {
        reset(newPractice);
        navigate(
          generatePath(PageRoutes.practiceOverview, {
            practiceId: newPractice.id,
          }),
          {
            replace: true,
          },
        );
      }
      return;
    }

    if (!practiceId) return;
    const practiceUpdated = await updatePractice({
      ...practice,
      afsl: practice.afsl.toString(),
      licenseeId: isLinkedToLicensee ? matchingLicensee?.id : null,
      licenseeName: isLinkedToLicensee ? matchingLicensee?.name : null,
      isLinkedToLicensee: isLinkedToLicensee,
    } as UpdateAdviserFirmDTO);
    if (practiceUpdated) {
      reset(practiceUpdated);
      setPractice(practiceUpdated);
    }
  };

  const handleOnAddLogo = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    const s3File = await uploadOne(acceptedFiles, fileRejections);
    if (s3File) {
      setValue("logo", s3File.url, { shouldValidate: true, shouldDirty: true });
      setValue("logoS3Key", s3File.key, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };
  const handleOnRemoveLogo = async () => {
    const isSuccess = await deleteOne(getValues("logoS3Key") || undefined);
    if (isSuccess) {
      setValue("logo", "", { shouldValidate: true, shouldDirty: true });
      setValue("logoS3Key", "", {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const clearTimer = () => {
    if (timer.current) clearTimeout(timer.current);
  };

  const checkAFSLMatchWithLicensee = async (afsl: string) => {
    clearTimer();
    setMatchingLicensee(null);

    if (!afsl || afsl.trim().length === 0 || !isAdminGroup) {
      setCheckMatchingAFSL("none");
      return;
    }

    setCheckMatchingAFSL("checking");
    timer.current = setTimeout(async () => {
      const data = await loadLicenseeByAFSL(afsl);
      if (data) {
        setMatchingLicensee({
          id: data?.id,
          name: data?.companyName,
        });
      }
      setCheckMatchingAFSL("none");
    }, 300);
  };

  const renderCheckMatchAFSL = () => {
    switch (checkMatchingAFSL) {
      case "checking":
        return <SHIconLoading size={16} />;
      default:
        return null;
    }
  };

  const updateLinkingPracticeData = async () => {
    setMatchingLicensee(null);
    // setIsLinkedToLicensee(!!practice?.licenseeId);

    if (practice?.licenseeId) {
      setMatchingLicensee({
        id: practice?.licenseeId,
        name: practice?.licenseeName,
      });
      return;
    }

    if (practice?.afsl && isAdminGroup) {
      checkAFSLMatchWithLicensee(practice?.afsl);
    }
  };

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, [practice]);

  useEffect(() => {
    reset({
      ...practice,
      isLinkedToLicensee: practice?.licenseeId ? true : false,
    });
    updateLinkingPracticeData();
    // eslint-disable-next-line
  }, [practice]);

  if (isLoading) return <PracticeOverviewSkeleton />;

  return (
    <form>
      <SHStack>
        <TopBarContainer>
          <TopBar
            navigate={
              user?.userMetadata?.is_system_admin
                ? { to: PageRoutes.practices }
                : undefined
            }
            title={
              isNew && isEmpty(watch("name")) ? "New practice" : watch("name")
            }
            customBadge={
              practice?.status && !isNew ? (
                <StatusBadge status={practice.status} />
              ) : undefined
            }
            tools={
              isAdminGroup || isAdviserAdminGroup ? (
                <>
                  {isAdminGroup ? (
                    <SHButton
                      variant="outlined"
                      size="extraMedium"
                      onClick={() => {
                        navigate(`${PageRoutes.practices}`, {
                          replace: false,
                        });
                      }}
                    >
                      Cancel
                    </SHButton>
                  ) : (
                    <></>
                  )}

                  {isEditMode ? (
                    <>
                      {!isNew &&
                        isAdminGroup &&
                        statusButtons.map((button) => (
                          <SHButton
                            key={button.action}
                            variant="outlined"
                            size="extraMedium"
                            isLoading={isModifyingStatus === button.action}
                            disabled={
                              isSubmitting ||
                              isDeleting ||
                              isUploading ||
                              !!isModifyingStatus
                            }
                            onClick={() => {
                              if (!practiceId) return;
                              if (button.dialog) {
                                setChangeStatusConfirm(button);
                                return;
                              }
                              updatePracticeStatus({
                                practiceId,
                                status: button.action,
                              });
                            }}
                          >
                            {button.title}
                          </SHButton>
                        ))}
                      <SHButton
                        variant="contained"
                        size="extraMedium"
                        type="submit"
                        disabled={
                          !isValid ||
                          !isDirty ||
                          isSubmitting ||
                          isDeleting ||
                          isUploading ||
                          !!isModifyingStatus
                        }
                        isLoading={isSubmitting}
                        onClick={handleSubmit(handleSubmitPractice)}
                      >
                        Save
                      </SHButton>
                    </>
                  ) : (
                    <SHButton
                      variant="contained"
                      size="extraMedium"
                      type="submit"
                      onClick={() => {
                        setEditMode(true);
                      }}
                    >
                      Edit
                    </SHButton>
                  )}
                </>
              ) : (
                <></>
              )
            }
          />
        </TopBarContainer>
        <FieldGroup
          firstLine
          title="Key practice information"
          subTitle={subTitleFields.KeyPracticeInfo}
        />
        <SHDivider />
        <FieldGroup
          title="Practice logo"
          subTitle={subTitleFields.PracticeLogo}
        >
          <Controller
            name="logo"
            control={control}
            render={({ field }) => (
              <SHUploadImage
                objectFit="contain"
                showImage={!isEmpty(watch("logo"))}
                isUploading={isUploading}
                src={field.value || undefined}
                actionIcon={
                  !isEmpty(watch("logo")) &&
                  isEditMode && (
                    <DeleteButton
                      isLoading={isDeleting}
                      onClick={handleOnRemoveLogo}
                    />
                  )
                }
                error={Boolean(errors.logo)}
                helperText={errors.logo ? errors.logo.message : null}
                dropzoneOptions={{
                  multiple: false,
                  accept: {
                    "image/*": [".svg", ".png", ".jpg", ".gif"],
                  },
                  onDrop: handleOnAddLogo,
                  disabled: isDisabled || isUploading,
                }}
              />
            )}
          />
        </FieldGroup>
        <SHDivider />
        <FieldGroup
          title="Practice details"
          subTitle={subTitleFields.PracticeDetails}
        >
          <SHStack
            spacing={3}
            direction="column"
            sx={{ width: { xs: "100%", md: 520 } }}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  required
                  fullWidth
                  hasCounter
                  maxLength={100}
                  label="Practice name"
                  placeholder={subTitleFields.PracticeNamePlaceholder}
                  disabled={isDisabled}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : null}
                  postfixLabel={
                    <InformationButton
                      content={subTitleFields.PracticeNameDesc}
                    />
                  }
                  onChange={(event) => {
                    field.onChange(event.target.value);
                    setPracticeName(event.target.value);
                  }}
                />
              )}
            />
            <Controller
              name="branchName"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  fullWidth
                  maxLength={100}
                  label="Practice branch"
                  placeholder={subTitleFields.PracticeBranchPlaceholder}
                  disabled={isDisabled}
                  error={!!errors.branchName}
                  helperText={
                    errors.branchName ? errors.branchName.message : null
                  }
                />
              )}
            />
            <Controller
              name="afsl"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  fullWidth
                  required
                  label="Australian Financial Services Licence (AFSL) number"
                  placeholder={subTitleFields.AFSLPlaceholder}
                  disabled={isDisabled || !!isLinkedToLicensee}
                  error={!!errors.afsl}
                  helperText={errors.afsl ? errors.afsl.message : null}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      field.onChange(e.target.value);
                      checkAFSLMatchWithLicensee(e.target.value);
                    }
                  }}
                  hasCounter
                  maxLength={6}
                  InputProps={{
                    sx: {
                      "&>input": {
                        pr: "32px !important",
                      },
                    },
                    endAdornment: (
                      <SHStack
                        sx={{
                          position: "absolute",
                          right: "8px",
                        }}
                      >
                        {renderCheckMatchAFSL()}
                      </SHStack>
                    ),
                  }}
                />
              )}
            />
            <SHStack direction={"row"} alignItems={"end"} spacing={3}>
              <SHTextField
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: matchingLicensee && (
                    <SHStack
                      sx={{
                        position: "absolute",
                        right: "8px",
                      }}
                    >
                      <CheckSVG width={18} height={18} />
                    </SHStack>
                  ),
                }}
                label={
                  isAdminGroup
                    ? "Link practice to licensee"
                    : "Practice is linked to the following licensee"
                }
                disabled={isDisabled}
                value={
                  matchingLicensee?.id
                    ? isAdminGroup
                      ? `AFSL match: ${matchingLicensee.name}`
                      : matchingLicensee.name
                    : "None"
                }
              />
              {matchingLicensee && isAdminGroup && (
                <Controller
                  name={"isLinkedToLicensee"}
                  control={control}
                  render={({ field: { ref, ...other } }) => (
                    <SHBooleanField
                      {...other}
                      isSwitch
                      isShowClearButton={false}
                      onChange={(e) => {
                        other.onChange(e.target.value);
                      }}
                      disabled={isDisabled}
                      yesLabel="Link"
                      noLabel="Unlink"
                    />
                  )}
                />
              )}
            </SHStack>
          </SHStack>
        </FieldGroup>
        {isAdminGroup && (
          <>
            <SHDivider />
            <FieldGroup
              title="Billing contact"
              subTitle="The billing contact will receive all correspondence relating to your practice’s subscription. We will prioritise getting in touch with this contact for any subscription related issues"
            >
              <SHStack
                spacing={3}
                direction="column"
                sx={{ width: { xs: "100%", md: 520 } }}
              >
                <Controller
                  name="contactName"
                  control={control}
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      required
                      fullWidth
                      hasCounter
                      maxLength={100}
                      label="Billing contact name"
                      placeholder="Enter first name and last name"
                      disabled={isDisabled}
                      error={!!errors.contactName}
                      helperText={
                        errors.contactName ? errors.contactName.message : null
                      }
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        setPracticeName(event.target.value);
                      }}
                    />
                  )}
                />
                <Controller
                  name="contactEmail"
                  control={control}
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      type="email"
                      fullWidth
                      required
                      label="Billing contact email"
                      placeholder="Enter email of billing contact"
                      disabled={isDisabled}
                      error={!!errors.contactEmail}
                      helperText={
                        errors.contactEmail ? errors.contactEmail.message : null
                      }
                    />
                  )}
                />
                <Controller
                  name="contactPhoneNumber"
                  control={control}
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      fullWidth
                      label="Billing contact phone number"
                      placeholder="Enter phone number of billing contact"
                      disabled={isDisabled}
                      maxLength={20}
                      InputProps={{
                        startAdornment: (
                          <SHTypography disabled={true} variant="body1">
                            +61
                          </SHTypography>
                        ),
                      }}
                    />
                  )}
                />
              </SHStack>
            </FieldGroup>
          </>
        )}
      </SHStack>
      <UnsavedDialog isDirty={isDirty && !isSubmitting} />
      <ConfirmDialog
        open={!!changeStatusConfirm}
        header={changeStatusConfirm?.dialog?.header}
        body={changeStatusConfirm?.dialog?.body}
        disableBackdropClick
        disableEscapeKeyDown
        leftButton={{
          disabled: !!isModifyingStatus,
          onClick: () => {
            setChangeStatusConfirm(undefined);
          },
          title: changeStatusConfirm?.dialog?.noButton,
        }}
        rightButton={{
          disabled: !!isModifyingStatus,
          isLoading: !!isModifyingStatus,
          onClick: () => {
            if (!practiceId || !changeStatusConfirm) return;
            updatePracticeStatus({
              practiceId,
              status: changeStatusConfirm?.action,
            }).then((newStatus) => {
              if (newStatus) {
                setChangeStatusConfirm(undefined);
              }
            });
          },
          title: changeStatusConfirm?.dialog?.yesButton,
        }}
      />
    </form>
  );
};
