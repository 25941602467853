import { BusinessMetricTypeId } from "@models/configuration";
import { AnalysisCommentDTO } from "@models/platform-analysis/entities/comment";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import { FeePortfolioDetailDTO } from "@models/platform-analysis/entities/portfolioDetails";
import { SelectedProductDTO as SummarySelectedProductDTO } from "@models/platform-analysis/entities/selectedProduct";
import {
  AnalysisDataDTO,
  AnalysisFeatureIncludeDTO,
  AnalysisSectionDTO,
  OverallScoreDTO,
  SelectedProductDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import { TableViewMode } from "@models/platform-analysis/enums/tableViewMode";

export interface PageTemplate {
  components: JSX.Element[];
  isAppendix?: boolean;
}

export class PDFHtmlElement {
  content: string | null = "";
  height: number | null = 0;
}

export class PDFAnalysisData {
  overview: PDFAnalysisOverview = new PDFAnalysisOverview();
  selectedProducts?: PDFSummarySelectedProductDTO[];
  objectives: string | null = null;
  concludingRemarks: string | null = null;
  analysisComments?: PDFAnalysisComment[] | null;
  featureAnalysis?: PDFFeatureAnalysis;
  businessAnalysis?: PDFBusinessAnalysis;
  feeAnalysis?: PDFFeeAnalysis;
  hiddenSubProductIds?: string[];
  feePortfolioDetails?: PDFFeePortfolioDetail;
  featureIncluded?: PDFFeatureIncluded[];
  // businessIncluded?: PDFBusinessIncluded[];   // TODO: Not implement yet
  disclaimers?: PDFAnalysisDisclaimer[];
  controls?: PDFAnalysisControl;
  displayModes?: PDFAnalysisDisplayMode;
}

export class PDFAnalysisOverview {
  analysisName?: string;
  ownerName?: string;
  supplierName?: string;
  adviserName?: string;
  practiceName?: string;
  lastModifiedDate?: Date;
  dataValidDate?: Date;
  serial?: string;
}

export class PDFAnalysisComment extends AnalysisCommentDTO {
  height: number = 0;
  isContinued?: boolean;
}

export interface PDFFeatureAnalysis {
  selectedProducts: SelectedProductDTO[];
  overallScore: OverallScoreDTO;
  sections: PDFAnalysisSectionDTO[];
}

export interface PDFAnalysisSectionDTO extends AnalysisSectionDTO {
  id?: string;
  name?: string;
  description?: string;
  iconUrl?: string;
  order: number;
  totalFeature: number;
  totalSelectedFeature: number;
  analysisData?: AnalysisDataDTO[];
  businessMetricTypeId?: BusinessMetricTypeId;
  businessMetricTypeName?: string;
  isEssential?: boolean;
  type?: "Section" | "SubSection" | "Feature";
}

export interface PDFBusinessAnalysis extends PDFFeatureAnalysis {}
export interface PDFFeePortfolioDetail extends FeePortfolioDetailDTO {}
export interface PDFFeeAnalysis {
  subProducts: FeeSubProductDTO[];
  hiddenSubProductIds?: string[];
  totalSubProductHidden: number;
  totalPortfolioValue?: number;
  showWarningCaptions?: boolean;
  feesDisplayStyle: FeesDisplayStyle;
}

export interface PDFFeatureIncluded extends AnalysisFeatureIncludeDTO {}
export interface PDFBusinessIncluded extends AnalysisFeatureIncludeDTO {}
export interface PDFAnalysisDisclaimer extends DisclaimerDTO {}

export interface PDFSummarySelectedProductDTO
  extends SummarySelectedProductDTO {}

export interface PDFAnalysisControl {
  showFeatureStep: boolean;
  showBusinessMetricStep: boolean;
  showFeeStep: boolean;
  showComments?: boolean;
  showSubSection?: boolean;
  showSelectedFeatures?: boolean;
  showHoldingNumber?: boolean;
  showAnalysisScore?: boolean;
  showFeeAnalysisGraph?: boolean;
}

export interface PDFAnalysisDisplayMode {
  feesDisplayMode?: FeesDisplayStyle;
  feeMissingInvestmentsDisplayMode?: FeeMissingInvestmentsOption;
  featureAnalysisTableViewMode?: TableViewMode;
  investmentMenu?: FeeInvestmentMenuOption;
}
