import { useBroadcastChannel } from "@hooks/useBroadcastChannel";
import * as React from "react";
import { useEffectOnce } from "react-use";

export interface SHRootAppProps {
  children?: React.ReactNode;
}

export default function SHRootApp({ children }: SHRootAppProps) {
  const { initializeBroadcast, closeBroadcast } = useBroadcastChannel();

  useEffectOnce(() => {
    initializeBroadcast();
    return () => {
      closeBroadcast();
    };
  });

  return <>{children}</>;
}
