import { APIExtRoutes } from "@constants";
import {
  CreatePortfolioTemplate,
  UpdatePortfolioTemplate,
} from "@models/portfolio-template/entities/portfolioTemplate";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  postPortfolioTemplate,
  putPortfolioTemplate,
} from "@services/portfolio-template/portfolioTemplateService";

interface PortfolioTemplateStore {
  ui: {
    isLoading?: boolean;
    isSubmitting?: boolean;
  };
}

const initialState: PortfolioTemplateStore = {
  ui: {
    isLoading: false,
    isSubmitting: false,
  },
};

const portfolioTemplateSlice = createSlice({
  name: "portfolioTemplate",
  initialState,
  reducers: {},
  extraReducers(builder) {
    //createPortfolioTemplateThunk
    builder.addCase(createPortfolioTemplateThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(createPortfolioTemplateThunk.fulfilled, (state, action) => {
      state.ui.isSubmitting = false;
    });
    builder.addCase(createPortfolioTemplateThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    //updatePortfolioTemplateThunk
    builder.addCase(updatePortfolioTemplateThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(updatePortfolioTemplateThunk.fulfilled, (state, action) => {
      state.ui.isSubmitting = false;
    });
    builder.addCase(updatePortfolioTemplateThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });
  },
});

// Thunk functions
export const createPortfolioTemplateThunk = createAsyncThunk(
  `${APIExtRoutes.portfolioTemplates}/create`,
  async (createPortfolioTemplate: CreatePortfolioTemplate) => {
    return await postPortfolioTemplate(createPortfolioTemplate);
  },
);

export const updatePortfolioTemplateThunk = createAsyncThunk(
  `${APIExtRoutes.portfolioTemplates}/update`,
  async (updatePortfolioTemplate: UpdatePortfolioTemplate) => {
    return await putPortfolioTemplate(updatePortfolioTemplate);
  },
);

export default portfolioTemplateSlice.reducer;
