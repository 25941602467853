import {
  SHBox,
  SHHtmlBlock,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { BusinessMetricDTO } from "@models/platform-analysis/entities/steps/businessMetric";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { useMemo } from "react";

export interface BusinessMetricProps extends SHStackProps {
  businessMetricData: BusinessMetricDTO;
  handleSelect: (businessMetricId?: string) => void;
}

export const BusinessMetric = ({
  businessMetricData,
  handleSelect,
  ...others
}: BusinessMetricProps) => {
  const { palette } = useTheme();
  const colors = useMemo(() => {
    if (businessMetricData.isSelected)
      return {
        background: palette.success[50],
        border: palette.success[100],
        text: palette.text.primary,
      };
    return {
      background: palette.common.white,
      border: palette.common.black,
      text: palette.text.third,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessMetricData.isSelected]);

  return (
    <SHBox
      sx={{
        border: `1px solid ${colors.border}`,
        backgroundColor: `${colors.background}`,
        text: `${colors.text}`,
        boxShadow: `3px 3px 4px ${hexToRGBA(palette.common.black, 0.25)}`,
        transition: "all .5s linear",
        "&:hover": {
          cursor: "pointer",
          border: `1px solid ${palette.primary[800]}`,
          boxShadow: "none",
        },
        "&:active": {
          cursor: "pointer",
          boxShadow: `inset 3px 3px 4px ${hexToRGBA(
            palette.common.black,
            0.25,
          )}`,
          transition: "all .1s linear",
          "&>svg>path": {
            fill: palette.common.white,
          },
        },
        padding: "1.5%",
        margin: "0 0 1.2% 1.3%",
        width: "32%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
      {...others}
      onClick={() => handleSelect(businessMetricData.id)}
    >
      <SHStack spacing={1}>
        <SHTypography variant="subtitle1">
          {businessMetricData.name}
        </SHTypography>
        <SHHtmlBlock content={businessMetricData.description} variant="body2" />
      </SHStack>
    </SHBox>
  );
};
