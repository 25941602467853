import { UserType } from "@models/auth";
import { AnalysisSetupDTO } from "@models/platform-analysis/entities/steps/setup";
import { isEmpty } from "lodash";
import * as Yup from "yup";

export const stepSetupValidators = Yup.lazy((values) =>
  Yup.object().shape({
    ownerId: Yup.string().nullable(true).required("An owner is required"),
    name: Yup.string().nullable(true).required("Analysis must have a name"),
    adviserName: Yup.string()
      .nullable(true)
      .when("ownerType", (ownerType, schema) => {
        return ownerType === UserType.SupplierBdmSale &&
          !isEmpty((values.practiceName || "").trim())
          ? schema.required(
              "Adviser name is required when a practice name is provided",
            )
          : schema;
      }),
    practiceName: Yup.string()
      .nullable(true)
      .when("ownerType", (ownerType, schema) => {
        return ownerType === UserType.SupplierBdmSale &&
          !isEmpty((values.adviserName || "").trim())
          ? schema.required(
              "Practice name is required when an adviser name is provided",
            )
          : schema;
      }),
    mainProductId: Yup.string()
      .nullable(true)
      .when("hasMainProduct", (hasMainProduct, schema) => {
        return hasMainProduct
          ? schema.required("Main platform is required")
          : schema;
      }),
    hasSteps: Yup.array()
      .nullable(true)
      .min(1, "Select what you would like to include in the analysis"),
    otherProductIds: Yup.array()
      .nullable(true)
      .when("mainProductId", (mainProductId, schema) => {
        return schema
          .min(
            mainProductId ? 0 : 1,
            "Platform analysis requires at least one platform to be selected",
          )
          .max(
            mainProductId ? 4 : 5,
            "The maximum number of platforms is five",
          );
      }),
  }),
);

export const compareDirty = (
  preValues?: AnalysisSetupDTO | null,
  newValues?: AnalysisSetupDTO | null,
): {
  changedStage: number;
} => {
  const result = {
    changedStage: 0,
  };
  if (preValues?.hasFeatureStep && !newValues?.hasFeatureStep)
    result.changedStage++;
  if (preValues?.hasBusinessMetricStep && !newValues?.hasBusinessMetricStep)
    result.changedStage++;
  if (preValues?.hasFeeStep && !newValues?.hasFeeStep) result.changedStage++;
  return result;
};
