import { SHButton, SHDialog, SHStack } from "@components/design-systems";
import { useConfiguration } from "@hooks/useConfiguration";
import { useNotification } from "@hooks/useNotification";
import { RootState } from "@redux/store";
import { updateEditMode } from "@redux/slices/configuration";
import { useAppDispatch } from "@redux/store";
import { publishChanges } from "@services/configuration";
import { useState } from "react";
import { useSelector } from "react-redux";

export interface PublishConfigurationButtonProps {}

export const PublishConfigurationButton = (
  props: PublishConfigurationButtonProps,
) => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const {
    configurationUI: { isEditMode },
    configurationDraftStatus,
  } = useSelector((state: RootState) => state.configuration);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    updateDraftStatus,
    refreshConfigurationGroup,
    updateHistoryReleases,
  } = useConfiguration();

  const handleOnOpenDialog = (): void => {
    setOpenDialog(true);
  };
  const handleOnCloseDialog = (): void => {
    setOpenDialog(false);
  };

  const handleOnPublish = async () => {
    setIsSubmitting(true);
    const { isSuccess, message } = await publishChanges();
    setIsSubmitting(false);
    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      //TODO:
      // const connection = SignalRService.getHubConnection();
      // connection?.invoke(SocketServerEvent.onConfigurationEditorProcess, ConfigurationEditingProcessActions.ConfigurationEditingReleaseLock, null);
      handleOnCloseDialog();
      updateDraftStatus();
      refreshConfigurationGroup();
      updateHistoryReleases();
      dispatch(updateEditMode(false));
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <>
      {configurationDraftStatus && isEditMode && (
        <SHButton
          variant="contained"
          size="extraMedium"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={handleOnOpenDialog}
        >
          Publish
        </SHButton>
      )}
      <SHDialog
        open={openDialog}
        onClose={handleOnCloseDialog}
        width={460}
        header="Publish the changes?"
        body="The changes you made to the SuitabilityHub Configuration will be visible to all users"
        footer={
          <SHStack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={7}
          >
            <SHButton
              color="secondary"
              onClick={handleOnCloseDialog}
              variant="text"
              size="extraMedium"
            >
              Cancel
            </SHButton>
            <SHButton
              color="primary"
              variant="contained"
              onClick={handleOnPublish}
              size="extraMedium"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Yes, publish
            </SHButton>
          </SHStack>
        }
      />
    </>
  );
};
