import { Components, Theme } from "@mui/material";

export const muiTableCellTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiTableCell"> => ({
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderWidth: 0,
        fontWeight: 500,
        fontSize: "13px",
        padding: "10px 16px",
      },
    },
  },
});
