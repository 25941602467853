import { ClearButton } from "@components/buttons/clear";
import {
  SHFormControlLabel,
  SHLabel,
  SHLabelProps,
  SHRadio,
  SHRadioGroup,
  SHStack,
  SHStackProps,
  SHToggleButtonOptions,
  SHTypography,
} from "@components/design-systems";

import { isNil } from "lodash";
import { ReactNode, useMemo, useState } from "react";

export interface SHBooleanFieldProps extends SHStackProps {
  name?: string;
  value?: boolean | null;
  width?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: boolean | null,
  ) => void;
  isShowClearButton?: boolean;
  isDisabledClearButton?: boolean;
  isSwitch?: boolean;
  startAdornment?: ReactNode;
  yesLabel?: string;
  noLabel?: string;
}
export const SHBooleanField = ({
  label,
  disabled,
  required,
  postfixLabel,
  dotGroupProps,
  sx,
  name,
  value: propValue,
  onChange,
  isShowClearButton = true,
  isDisabledClearButton = false,
  isSwitch: isUseSwitch = false,
  startAdornment,
  yesLabel = "Yes",
  noLabel = "No",
  width = "55px",
}: SHBooleanFieldProps & SHLabelProps) => {
  const [valueState, setValueState] = useState<undefined | null | boolean>(
    propValue,
  );

  const handleChangeValue = (
    event: React.ChangeEvent<any>,
    value?: boolean | null,
  ) => {
    if (onChange)
      onChange({ ...event, target: { ...event.target, value } }, value);
    setValueState(value);
  };

  const value = useMemo(
    () => (propValue !== undefined ? propValue : valueState),
    [propValue, valueState],
  );

  return (
    <SHStack
      sx={sx}
      spacing={isUseSwitch ? 1 : 0}
      data-testid="sh-boolean-field"
    >
      {label ? (
        <SHLabel
          label={label}
          disabled={disabled}
          required={required}
          postfixLabel={postfixLabel}
          dotGroupProps={dotGroupProps}
        />
      ) : null}
      <SHStack
        direction={"row"}
        alignItems="center"
        spacing={isUseSwitch ? 1.5 : 0}
      >
        {typeof startAdornment === "string" ? (
          <SHTypography variant="body1" disabled={disabled}>
            {startAdornment}
          </SHTypography>
        ) : (
          startAdornment
        )}
        {!isUseSwitch ? (
          <SHRadioGroup
            value={isNil(value) ? null : value.toString()}
            row
            name={name}
            onChange={(event, value) => {
              handleChangeValue(event, value === "true");
            }}
          >
            <SHFormControlLabel
              disabled={disabled}
              value={"true"}
              control={<SHRadio />}
              label={yesLabel}
              data-testid="radio-yes"
            />
            <SHFormControlLabel
              disabled={disabled}
              value={"false"}
              control={<SHRadio />}
              label={noLabel}
              data-testid="radio-no"
            />
          </SHRadioGroup>
        ) : (
          <SHToggleButtonOptions
            disabled={disabled}
            options={[
              {
                value: "true",
                label: yesLabel,
              },
              {
                value: "false",
                label: noLabel,
              },
            ]}
            width={width}
            value={isNil(value) ? null : value.toString()}
            onChange={(event, value) => {
              if (!value) return;
              handleChangeValue(event, value === "true");
            }}
          />
        )}

        {isShowClearButton && (
          <ClearButton
            sx={{ marginTop: "3px" }}
            disabled={disabled || isDisabledClearButton}
            onClick={(event) => {
              handleChangeValue(event, null);
            }}
          />
        )}
      </SHStack>
    </SHStack>
  );
};
