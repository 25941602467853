import { Alert, AlertProps } from "@mui/material";

export interface SHAlertProps extends AlertProps {
  width?: number | string;
  textAlign?:
    | "center"
    | "end"
    | "justify"
    | "left"
    | "match-parent"
    | "right"
    | "start";
}

export const SHAlert = ({
  width,
  textAlign,
  ...props
}: SHAlertProps): JSX.Element => (
  <Alert
    {...props}
    sx={{
      width: width ?? "100%",
      "& .MuiAlert-message": {
        textAlign: textAlign ?? "center",
      },
    }}
  />
);
