import { SHStack, SHTypography } from "@components/design-systems";
import { FeeDTO } from "@models/platform-analysis/entities/steps/fee";
import { formatLargeNumber, thousandSeparator } from "@utils";

interface LabelLineProjectionProps {
  firstProductFees: FeeDTO[];
}
export const LabelLineProjection = ({
  firstProductFees,
}: LabelLineProjectionProps) => {
  return (
    <SHStack direction={"row"} flex={1} spacing={"3px"} paddingLeft={"6px"}>
      {firstProductFees.map((fee, index) => (
        <SHStack
          key={index}
          flex={1}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ mx: "1.5px" }}
        >
          <SHTypography
            variant="body2"
            colorVariant={fee.isCurrentPortfolio ? "darker" : "third"}
            lineHeight={1}
            fontWeight={fee.isCurrentPortfolio ? 700 : 400}
            sx={{
              textDecoration: fee.isCurrentPortfolio ? "underline" : undefined,
            }}
          >
            {fee.isCurrentPortfolio
              ? `$${thousandSeparator(+fee.avgTotalPortfolio.toFixed(0))}`
              : `$${formatLargeNumber(fee.avgTotalPortfolio)}`}
          </SHTypography>
        </SHStack>
      ))}
    </SHStack>
  );
};
