import { SHStack } from "@components/design-systems";
import { PageRoutes } from "@constants";
import { useProduct } from "@hooks/useProduct";
import { HistoryCard } from "@layouts/dnd/configuration/history-card";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { ChangeHistoryTabSkeleton } from "@pages/suppliers/_id/_products/_id/edit/components/skeletons/ChangeHistoryTabSkeleton";
import { generateTitle } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { RootState } from "@redux/store";
import { updateSelectedGroup } from "@redux/slices/product";
import { useAppDispatch } from "@redux/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router";
export const ChangeHistoryCard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loadProductChangeHistory } = useProduct();
  const {
    productChangeHistory,
    productUI: { productName, releaseName, isLoadingProductHistory },
  } = useSelector((state: RootState) => state.product);
  const { productId } = useParams<{ productId: string }>();
  const { supplierId } = useParams<{ supplierId: string }>();

  useEffect(() => {
    loadProductChangeHistory(productId ?? "new");
    // eslint-disable-next-line
  }, [productId]);

  return (
    <SHStack>
      <TopBarContainer>
        <TopBar title={generateTitle(productName, releaseName)} />
      </TopBarContainer>
      {isLoadingProductHistory ? (
        <ChangeHistoryTabSkeleton />
      ) : (
        <SHStack gap={"8px"} paddingTop={"25px"}>
          {(productChangeHistory ?? []).map((release, index) => (
            <HistoryCard
              key={index}
              releaseProduct={release}
              isOdd={index % 2 === 0}
              isChangeHistory
              isFirstDraft={productChangeHistory?.length === 1}
              productId={productId}
              onClickView={() => {
                navigate(
                  generatePath(PageRoutes.supplierProductsVersion, {
                    supplierId: supplierId,
                    productId: productId,
                    version: release?.productVersion?.toString(),
                    configurationVersion:
                      release?.configurationVersion?.toString(),
                  }),
                );
                dispatch(updateSelectedGroup(0));
              }}
            />
          ))}
        </SHStack>
      )}
    </SHStack>
  );
};
