import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import {
  ManagedAccountBannerInfoDTO,
  ManagedAccountDTO,
  ManagedAccountDecisionDTO,
  ManagedAccountFilterDTO,
  ManagedAccountSaveCurrentStepDTO,
  ManagedAccountStepDTO,
  ManagedAccountUpdateDTO,
} from "@models/managed-accounts/entities/analysis";
import {
  ManagedAccountPortfolioSetupCreateDTO,
  SelectedInvestmentDTO,
  SelectedPortfolioTemplateDTO,
} from "@models/managed-accounts/entities/step/setup";
import { ManagedAccountStep } from "@models/managed-accounts/enums/step";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";
import { compileManagedAccount } from "./util";

// GET APIs
export async function getManagedAccountBannerInfo(
  managedAccountId: string,
): Promise<APIResponse<ManagedAccountBannerInfoDTO>> {
  const response = await APIGatewayService.get<ManagedAccountBannerInfoDTO>(
    generatePath(APIExtRoutes.managedAccountsBannerInfo, {
      managedAccountId,
    }),
  );
  return handleErrorProxy(response);
}

export async function getManagedAccountSteps(
  managedAccountId?: string,
): Promise<APIResponse<ManagedAccountStepDTO>> {
  const response = await APIGatewayService.get<ManagedAccountStepDTO>(
    generatePath(APIExtRoutes.managedAccountSteps, { managedAccountId }),
  );

  return handleErrorProxy(response);
}

export async function getManagedAccountStep(
  managedAccountId: string,
  stepId: ManagedAccountStep,
): Promise<APIResponse<ManagedAccountDTO>> {
  const response = await APIGatewayService.get<ManagedAccountDTO>(
    generatePath(APIExtRoutes.managedAccountStep, {
      managedAccountId,
      stepId,
    }),
  );

  if (response.data) {
    compileManagedAccount(response.data);
  }

  return handleErrorProxy(response);
}

// POST PUT PATCH
export async function postManagedAccount(
  portfolioSetup: ManagedAccountPortfolioSetupCreateDTO,
): Promise<APIResponse<ManagedAccountDTO>> {
  const response = await APIGatewayService.post<ManagedAccountDTO>(
    APIExtRoutes.managedAccounts,
    portfolioSetup,
  );
  return handleErrorProxy(response, "Managed account analysis has been created.");
}

export async function putManagedAccount(
  managedAccount: ManagedAccountUpdateDTO,
): Promise<APIResponse<ManagedAccountDTO>> {
  const response = await APIGatewayService.put<ManagedAccountDTO>(
    APIExtRoutes.managedAccounts,
    managedAccount,
  );
  return handleErrorProxy(response, "Changes have been saved.");
}

export async function putManagedAccountCurrentStep(
  managedAccountId: string,
  currentStep: ManagedAccountStep,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.managedAccountSaveCurrentStep, {
      managedAccountId,
    }),
    {
      currentStep,
    } as ManagedAccountSaveCurrentStepDTO,
  );
  return handleErrorProxy(response, "Your current step has been saved.");
}

export async function putManagedAccountDecision(
  managedAccountId: string,
  decision: ManagedAccountDecisionDTO,
): Promise<APIResponse<ManagedAccountDecisionDTO>> {
  const response = await APIGatewayService.put<ManagedAccountDecisionDTO>(
    generatePath(APIExtRoutes.managedAccountsDecision, { managedAccountId }),
    decision,
  );
  return handleErrorProxy(response);
}

export async function putManagedAccountFilters(
  managedAccountId: string,
  managedAccountFilters: ManagedAccountFilterDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.managedAccountsFilter, { managedAccountId }),
    managedAccountFilters,
  );

  return handleErrorProxy(response);
}

export async function getManagedAccountPortfolioTemplates(): Promise<
  APIResponse<SelectedPortfolioTemplateDTO[]>
> {
  const response = await APIGatewayService.get<SelectedPortfolioTemplateDTO[]>(
    generatePath(APIExtRoutes.managedAccountPortfolioTemplates),
  );

  return handleErrorProxy(response);
}

export async function getManagedAccountPortfolioTemplateById(
  managedAccountId: string,
  portfolioTemplateId: string,
): Promise<APIResponse<SelectedInvestmentDTO[]>> {
  const response = await APIGatewayService.get<SelectedInvestmentDTO[]>(
    generatePath(APIExtRoutes.managedAccountPortfolioTemplateById, {
      managedAccountId,
      portfolioTemplateId,
    }),
  );

  return handleErrorProxy(response);
}
