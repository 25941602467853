import { SHContainer, SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { BusinessMetricCategoriesSkeleton } from "@components/skeletons/layouts/platform-analysis/BusinessMetricCategoriesSkeleton";
import { StepName } from "@layouts/stepper/step-name";

export const BusinessMetricSelectionSkeleton = () => {
  return (
    <SHContainer maxWidth="xl" sx={{ px: { xs: "16px", lg: "250px" } }}>
      <SHStack spacing={2}>
        <StepName name={"Feature selection"} stepNumber={0} isLoading />
        <SHSkeleton width={"100%"} height={"40px"} />
        <BusinessMetricCategoriesSkeleton />
        <SHSkeleton width={"100%"} height={"40px"} />
        <BusinessMetricCategoriesSkeleton />
        <SHSkeleton width={"100%"} height={"40px"} />
        <BusinessMetricCategoriesSkeleton />
      </SHStack>
    </SHContainer>
  );
};
