import { SVGProps } from "react";

export const CalendarSVG = ({
  color = "#25324B",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    data-testid="calendar-svg"
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M3.833.765a.5.5 0 0 1 .5.5v.5h8v-.5a.5.5 0 1 1 1 0v.5h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2h1v-.5a.5.5 0 0 1 .5-.5Zm-1.5 2a1 1 0 0 0-1 1v1h14v-1a1 1 0 0 0-1-1h-12Zm13 3h-14v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-9Z"
        fill="#2119BA"
      />
      <path
        d="M11.333 8.265a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Z"
        fill="#332CC2"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect x={0.333} y={0.765} width={16} height={16} rx={1} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
