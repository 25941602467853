import { FilterButtonProps, FilterValue } from "@components/buttons/filter";
import {
  SHButton,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { PlusSVG } from "@components/svgs";
import { useUserPermissions } from "@hooks/userUserPermission";
import { SubProductCard } from "@layouts/products/fees-and-rates/sub-product-card";
import { HighlightType } from "@models/configuration";
import { SubProductDTO } from "@models/product/entities/subProduct";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";

export interface SubProductListProps extends SHStackProps {
  width?: number | string;
  isEditMode?: boolean;
  isDisabled?: boolean;
  isLoadingNewSubProduct?: boolean;
  subProducts?: SubProductDTO[];
  selectedSubProductIndex?: number;
  currentHighlightType?: HighlightType;
  filterProp?: FilterButtonProps;
  onChangeSubProduct: (idx: number) => void;
  onNewSubProduct?: () => void;
  onChangeFilter?: (value: FilterValue[] | undefined) => void;
  onArchivedUnArchived?: (
    subProduct?: SubProductDTO,
    isArchived?: boolean,
  ) => void;
}
export const SubProductList = ({
  width = "100%",
  isEditMode,
  isDisabled,
  isLoadingNewSubProduct,
  subProducts = [],
  selectedSubProductIndex,
  currentHighlightType,
  filterProp,
  onNewSubProduct,
  onChangeSubProduct,
  onChangeFilter,
  onArchivedUnArchived,
  ...others
}: SubProductListProps) => {
  const { palette } = useTheme();
  const { canCreateProduct } = useUserPermissions();

  return (
    <SHStack width={width} {...others}>
      <SHStack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          padding: "24px 0px 24px 10px",
          bgcolor: hexToRGBA(palette.common.white, 0.85),
          borderBottom: `1px solid ${palette.secondary[100]}`,
          backdropFilter: "blur(0.5px)",
        }}
      >
        <SHTypography variant="subtitle1">Products</SHTypography>
        {isEditMode && (
          <SHStack
            direction="row"
            justifyContent="center"
            alignItems={"center"}
            spacing={1}
          >
            {/* {filterProp && (
              <FilterButton {...filterProp} onChangeFilter={onChangeFilter} />
            )} */}
            {canCreateProduct && (
              <SHButton
                variant="text"
                color="primary"
                size="extraMedium"
                startIcon={
                  <PlusSVG
                    color={
                      isDisabled ? palette.text.disabled : palette.primary.main
                    }
                    width={10}
                    height={10}
                  />
                }
                onClick={onNewSubProduct}
                disabled={isDisabled}
                isLoading={isLoadingNewSubProduct}
              >
                New
              </SHButton>
            )}
          </SHStack>
        )}
      </SHStack>
      {subProducts.map((subProduct, subProductIndex) => (
        <SubProductCard
          key={`${subProduct.id}.${subProductIndex}`}
          isDisabled={isDisabled}
          subProduct={subProduct}
          isEditMode={isEditMode}
          subProductIndex={subProductIndex}
          isSelected={subProductIndex === selectedSubProductIndex}
          onChangeSubProduct={onChangeSubProduct}
          onArchivedUnArchived={
            canCreateProduct ? onArchivedUnArchived : undefined
          }
        />
      ))}
    </SHStack>
  );
};
