import { loadInvestmentProductBrandingData, loadInvestmentProductManagerThunk, loadInvestmentProductSeriesThunk } from "@redux/slices/product/investment-product";
import { useAppDispatch } from "@redux/store";
import { useNotification } from "./useNotification";

export const useInvestmentProduct = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();

  const loadInvestmentProductManager = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductManagerThunk({ investmentProductId, mode }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response?.data;
  };

  const loadInvestmentProductSeries = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductSeriesThunk({ investmentProductId, mode }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response?.data;
  };

  const loadInvestmentProductBrandingInfo = async (productId: string) => {
    const response = await dispatch(
      loadInvestmentProductBrandingData(productId),
    ).unwrap();

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  return {
    loadInvestmentProductManager,
    loadInvestmentProductSeries,
    loadInvestmentProductBrandingInfo
  };
};
