import { FeeDetailDTO } from "@models/platform-analysis/entities/steps/fee";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Text, View } from "@react-pdf/renderer";

interface PDFLabelLineDetailProps {
  firstProductFeeDetails: FeeDetailDTO[];
}
export const PDFLabelLineDetail = ({
  firstProductFeeDetails,
}: PDFLabelLineDetailProps) => {
  const { typographyStyle } = useStyles();
  return (
    <View
      style={{
        flexDirection: "row",
        paddingLeft: 3,
        height: 25,
      }}
    >
      {firstProductFeeDetails.map((feeDetail, index) => (
        <View
          key={index}
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginRight: index === 0 ? 5 : 1.5,
            marginLeft:
              index === firstProductFeeDetails.length - 1 ? "5px" : "1.5px",
            width: 450 / firstProductFeeDetails?.length,
            height: 25,
          }}
        >
          <Text
            style={{
              ...typographyStyle?.body2,
              fontSize: 7,
              textAlign: "center",
            }}
          >
            {feeDetail.name}
          </Text>
        </View>
      ))}
    </View>
  );
};
