import { SHButton } from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { useProduct } from "@hooks/useProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { RootState } from "@redux/store";
import { recallSubmission } from "@services/product/productsService";
import { useState } from "react";
import { useSelector } from "react-redux";
export interface RecallSubmissionButtonProps {
  productId?: string;
}

export const RecallSubmissionButton = ({
  productId,
}: RecallSubmissionButtonProps) => {
  const { notify } = useNotification();
  const { loadBannerInfo } = useProduct();
  const { canRecallSubmissionProduct } = useUserPermissions();

  const {
    productUI: { isEditMode },
  } = useSelector((state: RootState) => state.product);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isVisible = isEditMode && canRecallSubmissionProduct;

  const handleOnRecallSubmission = async () => {
    if (!productId) return;
    setIsSubmitting(true);
    const { isSuccess, message } = await recallSubmission(productId);
    setIsSubmitting(false);
    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      loadBannerInfo(productId);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <>
      {isVisible && (
        <SHButton
          variant="contained"
          size="extraMedium"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={handleOnRecallSubmission}
        >
          Recall submission
        </SHButton>
      )}
    </>
  );
};
