import { SVGProps } from "react";

export const EditSVG = ({
  color = "#25324B",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={15}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.597 3.4a2.04 2.04 0 0 1 1.443-.598h2.04a.68.68 0 0 1 0 1.36H3.04a.68.68 0 0 0-.68.68v6.118a.68.68 0 0 0 .68.68h6.118a.68.68 0 0 0 .68-.68V8.92a.68.68 0 0 1 1.36 0v2.04A2.04 2.04 0 0 1 9.158 13H3.04A2.04 2.04 0 0 1 1 10.96V4.841c0-.54.215-1.06.597-1.442Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.377.622a2.122 2.122 0 0 1 3.002 3l-5.78 5.78a.68.68 0 0 1-.48.199h-2.04a.68.68 0 0 1-.68-.68V6.88a.68.68 0 0 1 .2-.48L10.377.62Zm1.501.738a.762.762 0 0 0-.54.223L5.76 7.163V8.24h1.078l5.58-5.58a.762.762 0 0 0-.539-1.301Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.358 1.641a.68.68 0 0 1 .961 0l2.04 2.04a.68.68 0 0 1-.962.961l-2.04-2.04a.68.68 0 0 1 0-.96Z"
      fill={color}
    />
  </svg>
);
