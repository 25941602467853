import { HeaderNameMapping, MainAndSubHeaderMapping } from "../entities/header";
import { SubHeader } from "@models/managed-accounts/enums/subHeader";

export enum MainHeaderColumn {
  TotalFees = "TotalFees",
  ManagementFeesAndCosts = "ManagementFeesAndCosts",
  PerformanceFees = "PerformanceFees",
  TransactionCosts = "TransactionCosts",
  PlatformFees = "PlatformFees",
  ModelDetails = "ModelDetails",
}

export const MainHeaderColumnNameMapping: HeaderNameMapping = {
  [MainHeaderColumn.TotalFees]: "Total fees",
  [MainHeaderColumn.ManagementFeesAndCosts]: "Management fees and costs",
  [MainHeaderColumn.PerformanceFees]: "Performance fees",
  [MainHeaderColumn.TransactionCosts]: "Transaction costs",
  [MainHeaderColumn.PlatformFees]: "Platform fees",
  [MainHeaderColumn.ModelDetails]: "Model details",
};

export const MainSubHeaderMapping: MainAndSubHeaderMapping = {
  [MainHeaderColumn.TotalFees]: [SubHeader.AnnualTotal, SubHeader.PDS],
  [MainHeaderColumn.ManagementFeesAndCosts]: [
    SubHeader.TotalManagementFeesAndCosts,
    SubHeader.IMFee,
    SubHeader.UnderlyingManagementFeesAndCosts,
    SubHeader.CashFee,
    SubHeader.NetOfRebates,
  ],
  [MainHeaderColumn.PerformanceFees]: [
    SubHeader.TotalPerformanceFees,
    SubHeader.ModelManager,
    SubHeader.UnderlyingPerformanceFees,
  ],
  [MainHeaderColumn.TransactionCosts]: [
    SubHeader.TotalTransactionCosts,
    SubHeader.UnderlyingTransactionCosts,
    SubHeader.BuySellSpreads,
    SubHeader.PlatformPercent,
    SubHeader.PlatformDollar,
    SubHeader.Netting,
    SubHeader.ModelTurnover,
  ],
  [MainHeaderColumn.PlatformFees]: [SubHeader.Discount, SubHeader.Type],
  [MainHeaderColumn.ModelDetails]: [
    SubHeader.FeeMethod,
    SubHeader.Incepted,
    SubHeader.MinInvestment,
    SubHeader.PercentCash,
    SubHeader.PercentIntListed,
    SubHeader.Super,
    SubHeader.IDPS,
    SubHeader.PrivateLabel,
    SubHeader.Available,
    SubHeader.CustomModel,
  ],
};
