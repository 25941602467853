import { APIExtRoutes } from "@constants/routes";
import {
  ConfigurationGroup,
  ConfigurationSection,
  ConfigurationSectionCreateDTO,
  ConfigurationSectionLov,
  ConfigurationSectionUpdateDTO,
} from "@models/configuration";
import { APIResponse } from "@models/core";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getConfigSectionLov(): Promise<
  APIResponse<ConfigurationSectionLov>
> {
  const response = await APIGatewayService.get<ConfigurationSectionLov>(
    APIExtRoutes.configurationSectionLov,
  );
  return handleErrorProxy(response);
}

export async function getConfigSection(
  groupId: string,
  sectionId: string,
  subSectionId?: string,
): Promise<APIResponse<ConfigurationSection>> {
  const response = await APIGatewayService.get<ConfigurationGroup>(
    generatePath(APIExtRoutes.configurationGroup, { groupId }),
  );

  const result: APIResponse = handleErrorProxy(response);

  if (result.isSuccess && result.data) {
    let configSection = {};
    if (!subSectionId) {
      configSection = result.data?.sections?.find(
        (item: ConfigurationSection) => item.id === sectionId,
      );
    } else {
      configSection = result.data?.sections
        ?.find((item: ConfigurationSection) => item.id === sectionId)
        ?.subSections?.find(
          (item: ConfigurationSection) => item.id === subSectionId,
        );
    }

    result.data = configSection;
  } else {
    result.data = undefined;
  }
  return result;
}

export async function updateConfigSection(
  configurationGroupId: string,
  configurationSectionId: string,
  config: ConfigurationSectionUpdateDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.configurationSection, {
      configurationGroupId,
      configurationSectionId,
    }),
    config,
  );
  return handleErrorProxy(response, "Section has been updated.");
}

export async function createConfigSection(
  configurationGroupId: string,
  config: ConfigurationSectionCreateDTO,
): Promise<APIResponse<ConfigurationSection>> {
  const response = await APIGatewayService.post<ConfigurationSection>(
    generatePath(APIExtRoutes.createConfigurationSection, {
      configurationGroupId,
    }),
    config,
  );
  return handleErrorProxy(response, "Section has been created.");
}
