import { PageRoutes } from "@constants";
import { BroadcastChannel } from "broadcast-channel";
import { useNavigate } from "react-router";

enum BroadcastAction {
  Logout,
}

declare type Message = {
  action: BroadcastAction;
  data: any;
};

export function useBroadcastChannel() {
  const channel: BroadcastChannel<Message> | undefined = new BroadcastChannel(
    "sh_channel",
  );
  const navigate = useNavigate();

  const initializeBroadcast = () => {
    channel.onmessage = (message: Message) => {
      switch (message.action) {
        case BroadcastAction.Logout:
          const { returnTo } = message.data;
          navigate(`${PageRoutes.logout}?returnTo=${returnTo}`);
          break;
      }
      return;
    };
  };

  const closeBroadcast = () => {
    if (!channel.isClosed) {
      channel.close();
    }
  };

  const logoutBroadcast = (returnTo?: string) => {
    channel.postMessage({
      action: BroadcastAction.Logout,
      data: {
        returnTo: returnTo ?? process.env.REACT_APP_AUTH0_REDIRECT_URL,
      },
    });
  };

  return { initializeBroadcast, closeBroadcast, logoutBroadcast };
}
