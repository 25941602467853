import { PDFRichTextEditor } from "@pages/platform-analysis/components/buttons/export-pdf/components/rich-text-editor";
import { PDFTitleSection } from "@pages/platform-analysis/components/buttons/export-pdf/components/title-section";

interface PDFObjectivesProps {
  content: string | null;
  isContinue?: boolean;
  isConcluding?: boolean;
}
export const PDFObjectives = ({
  content = "",
  isContinue = false,
  isConcluding = false,
}: PDFObjectivesProps) => {
  const title = `${isConcluding ? "Concluding remarks" : "Objectives"}${
    isContinue ? " (continued)" : ""
  }`;
  return (
    <>
      <PDFTitleSection title={title} />,
      <PDFRichTextEditor htmlString={content} />
    </>
  );
};
