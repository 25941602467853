export enum ProductApprovalStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum ProductStatus {
  Active = "Active",
  Disabled = "Disabled",
  Archived = "Archived",
}
