import { theme } from "@themes";
import { SVGProps } from "react";

export const CheckGroupSVG = ({
  color = theme.palette.success.main,
  ...others
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={21}
      height={12}
      viewBox="0 0 21 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5763 1.07705C11.8052 0.859122 12.1099 0.738641 12.4259 0.741032C12.742 0.743424 13.0447 0.868501 13.2703 1.08987C13.4959 1.31124 13.6267 1.61159 13.635 1.92754C13.6434 2.24348 13.5286 2.55032 13.3151 2.7833L6.83132 10.8921C6.71983 11.0121 6.58527 11.1085 6.43569 11.1754C6.2861 11.2423 6.12456 11.2783 5.96073 11.2814C5.7969 11.2844 5.63414 11.2544 5.48218 11.1931C5.33022 11.1317 5.19218 11.0404 5.07632 10.9246L0.776573 6.6248C0.656832 6.51323 0.560791 6.37868 0.494179 6.22918C0.427567 6.07968 0.391749 5.91829 0.388862 5.75465C0.385974 5.59101 0.416077 5.42846 0.477373 5.27671C0.53867 5.12495 0.629905 4.9871 0.745636 4.87137C0.861366 4.75563 0.999221 4.6644 1.15098 4.6031C1.30273 4.54181 1.46528 4.5117 1.62892 4.51459C1.79256 4.51748 1.95395 4.5533 2.10345 4.61991C2.25295 4.68652 2.3875 4.78256 2.49907 4.9023L5.90182 8.30343L11.5438 1.1128C11.5539 1.10026 11.5648 1.08832 11.5763 1.07705ZM10.0813 9.42955L11.5763 10.9246C11.6922 11.0402 11.8301 11.1313 11.9819 11.1924C12.1337 11.2536 12.2963 11.2836 12.4599 11.2805C12.6235 11.2775 12.7849 11.2415 12.9343 11.1748C13.0838 11.108 13.2182 11.0119 13.3297 10.8921L19.8167 2.7833C19.9332 2.66825 20.0254 2.5309 20.0876 2.37945C20.1499 2.228 20.181 2.06555 20.1791 1.90181C20.1772 1.73806 20.1423 1.57638 20.0765 1.42642C20.0108 1.27646 19.9154 1.1413 19.7962 1.029C19.6771 0.916701 19.5365 0.829571 19.3829 0.772813C19.2293 0.716056 19.0658 0.690835 18.9022 0.698657C18.7387 0.706479 18.5784 0.747184 18.4309 0.818339C18.2834 0.889495 18.1517 0.989643 18.0438 1.1128L12.4002 8.30343L11.6121 7.51368L10.0797 9.42955H10.0813Z"
        fill={color}
      />
    </svg>
  );
};
