import { SVGProps } from "react";
export const CheckSVG = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={19}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m3.333 9.265 4.5 4.5 7.5-9"
      stroke="#219653"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
