import {
  SHBox,
  SHButton,
  SHDivider,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableCell,
  SHTableContainer,
  SHTableFooter,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { SHAutocomplete } from "@components/design-systems/sh-auto-complete";
import { SHDatePicker } from "@components/design-systems/sh-date-picker";
import AccessDeniedDialog from "@components/dialogs/access-denied";

import UnsavedDialog from "@components/dialogs/unsaved";
import { StatusBadge } from "@components/status-badge";
import { ChevronSVG } from "@components/svgs/chevron";
import { email } from "@constants/email";
import { useAppParams } from "@hooks/useAppParams";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { SupplierLicenseDTO } from "@models/supplier/entities/licenses";
import { LicenseOrder } from "@models/supplier/enums/licenseOrder";
import { styled, useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { updateLicenses } from "@redux/slices/supplier";
import { updateSupplierLicenses } from "@services/suppliers";
import { sortAscByOrder } from "@utils";
import { toDateType } from "@utils/data-type/date";
import { isAfter, isBefore } from "date-fns";
import { isNull } from "lodash";
import { ReactNode } from "react";
import {
  Controller,
  useFieldArray,
  useForm,
  useFormState,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { licensesNumbers } from "./config";
import { toSupplierLicenseMassUpdateDTO } from "./utils";
//@TODO remove
const LeftRow = ({ children }: { children: ReactNode }) => (
  <SHTableRow transparent>
    <SHTableCell>
      <SHStack height={"40px"} justifyContent="center">
        {children}
      </SHStack>
    </SHTableCell>
  </SHTableRow>
);

const TableCellCenter = styled(SHTableCell)(({ theme }) => ({
  textAlign: "center",
  width: "150px",
}));

export const SupplierSubscription = () => {
  const { isMySupplier } = useAppParams();
  const { isAdminGroup, isSupplierGroup, canViewSupplierBilling } =
    useUserPermissions();
  const { supplierData } = useSelector((state: RootState) => state.supplier);
  const { notify } = useNotification();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const {
    control,
    watch,
    reset,
    trigger,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      licenses: sortAscByOrder(supplierData?.supplierLicenseMaps ?? []),
    },
  });
  const { isDirty } = useFormState({ control });
  const { fields } = useFieldArray({
    control,
    name: "licenses",
  });
  const accessDenied =
    !canViewSupplierBilling || (isSupplierGroup && !isMySupplier);

  const handleSubmitLicenses = async (data: {
    licenses: SupplierLicenseDTO[];
  }) => {
    if (!supplierData) return;
    const { isSuccess, message } = await updateSupplierLicenses(
      supplierData.id,
      toSupplierLicenseMassUpdateDTO(data.licenses),
    );

    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      reset(data);
      dispatch(
        updateLicenses(
          data.licenses.map((license) => ({
            ...license,
            subscriptionBegin: data.licenses[0].subscriptionBegin,
            subscriptionEnd: data.licenses[0].subscriptionEnd,
          })),
        ),
      );
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const onChangeTotals = (index: number, value: number) => {
    setValue(`licenses.${index}.totalLicenses`, value, {
      shouldDirty: true,
      shouldValidate: true,
    });
    if (value < watch(`licenses.${index}.totalUsedLicenses`)) {
      notify(
        "Please disable enough licences before trying to reduce the number of licences!",
        {
          variant: "error",
          close: true,
        },
      );
    }
  };

  if (accessDenied) return <AccessDeniedDialog />;
  if (!isAdminGroup) {
    return (
      <>
        <TopBarContainer>
          <TopBar
            title={supplierData?.companyName}
            customBadge={
              supplierData?.status ? (
                <StatusBadge status={supplierData?.status} />
              ) : undefined
            }
          />
        </TopBarContainer>
        <SHBox>
          <SHTypography variant="subtitle2">Coming soon!</SHTypography>
          <SHTypography variant="body4">
            {`Please contact ${email.hello} to buy more licences.`}
          </SHTypography>
        </SHBox>
      </>
    );
  }

  return (
    <form>
      <TopBarContainer>
        <TopBar
          title={supplierData?.companyName}
          customBadge={
            supplierData?.status ? (
              <StatusBadge status={supplierData?.status} />
            ) : undefined
          }
          tools={
            <>
              {isDirty && (
                <SHButton
                  variant="outlined"
                  size="extraMedium"
                  type="submit"
                  onClick={() => reset()}
                >
                  Cancel
                </SHButton>
              )}
              <SHButton
                variant="contained"
                size="extraMedium"
                type="submit"
                disabled={!isValid || isSubmitting || !isDirty}
                isLoading={isSubmitting}
                onClick={handleSubmit(handleSubmitLicenses)}
              >
                Save
              </SHButton>
            </>
          }
        />
      </TopBarContainer>
      <SHStack direction={"column"}>
        <FieldGroup
          firstLine
          title="Subscription information"
          subTitle="Manage the plan details for the firm"
        />
        <SHDivider />
        {fields.length ? (
          <SHStack
            direction={"row"}
            alignItems="start"
            justifyContent={"start"}
            py={3}
            spacing={2}
          >
            <SHTypography variant="subtitle2">Plan details</SHTypography>
            <SHBox width="200px" style={{ marginLeft: "50px" }}>
              <SHTableContainer>
                <SHTable>
                  <SHTableHead hidden>
                    <SHTableRow>
                      <TableCellCenter>&nbsp;</TableCellCenter>
                    </SHTableRow>
                  </SHTableHead>
                  <SHTableBody>
                    {fields.map((field, index) => (
                      <LeftRow key={index}>
                        <SHTypography variant="body1">
                          {field.licenseName}
                        </SHTypography>
                      </LeftRow>
                    ))}
                  </SHTableBody>
                  {fields?.[0] && (
                    <SHTableFooter>
                      <LeftRow>
                        <SHTypography variant="body1">
                          Subscription period
                        </SHTypography>
                      </LeftRow>
                    </SHTableFooter>
                  )}
                </SHTable>
              </SHTableContainer>
            </SHBox>
            <SHBox width={"600px"}>
              <SHTableContainer>
                <SHTable>
                  <SHTableHead>
                    <SHTableRow>
                      <TableCellCenter width={"150px"}>
                        Paid licences
                      </TableCellCenter>
                      <TableCellCenter width={"150px"}>
                        Free licences
                      </TableCellCenter>
                      <TableCellCenter width={"150px"}>Total</TableCellCenter>
                      <TableCellCenter width={"150px"}>In use</TableCellCenter>
                    </SHTableRow>
                  </SHTableHead>
                  <SHTableBody>
                    {fields.map((license, index) => (
                      <SHTableRow key={index}>
                        <TableCellCenter>
                          <SHStack alignItems={"center"}>
                            <Controller
                              name={`licenses.${index}.licensePaidCount`}
                              control={control}
                              render={({ field }) => (
                                <SHAutocomplete
                                  disabled={
                                    license.licenseOrder === LicenseOrder.Core
                                  }
                                  disableClearable
                                  sx={{ width: "60px" }}
                                  value={field.value}
                                  options={licensesNumbers}
                                  getOptionLabel={(option) => option.toString()}
                                  onChange={(event, value) => {
                                    field.onChange(value);
                                    onChangeTotals(
                                      index,
                                      value +
                                        watch(
                                          `licenses.${index}.licenseFreeCount`,
                                        ),
                                    );
                                  }}
                                  noOptionsText="..."
                                  textFieldProps={{
                                    error:
                                      !!errors.licenses?.[index]
                                        ?.licensePaidCount,
                                  }}
                                />
                              )}
                            />
                          </SHStack>
                        </TableCellCenter>
                        <TableCellCenter>
                          <SHStack alignItems={"center"}>
                            <Controller
                              name={`licenses.${index}.licenseFreeCount`}
                              control={control}
                              render={({ field }) => (
                                <SHAutocomplete
                                  disableClearable
                                  sx={{ width: "60px" }}
                                  value={field.value}
                                  getOptionLabel={(option) => option.toString()}
                                  options={licensesNumbers}
                                  onChange={(event, value) => {
                                    field.onChange(value);
                                    onChangeTotals(
                                      index,
                                      value +
                                        watch(
                                          `licenses.${index}.licensePaidCount`,
                                        ),
                                    );
                                  }}
                                  textFieldProps={{
                                    error:
                                      !!errors.licenses?.[index]
                                        ?.licenseFreeCount,
                                  }}
                                  noOptionsText="..."
                                />
                              )}
                            />
                          </SHStack>
                        </TableCellCenter>
                        <TableCellCenter>
                          <SHStack>
                            <Controller
                              name={`licenses.${index}.totalLicenses`}
                              control={control}
                              rules={{
                                min: watch(
                                  `licenses.${index}.totalUsedLicenses`,
                                ),
                              }}
                              render={({ field }) => (
                                <SHTypography
                                  variant="body3"
                                  color={
                                    !!errors.licenses?.[index]?.totalLicenses
                                      ? palette.error.main
                                      : palette.text.primary
                                  }
                                >
                                  {field.value}
                                </SHTypography>
                              )}
                            />
                          </SHStack>
                        </TableCellCenter>
                        <TableCellCenter>
                          <SHTypography variant="body3">
                            <SHStack>
                              <SHTypography variant="body3">
                                {watch(`licenses.${index}.totalUsedLicenses`)}
                              </SHTypography>
                            </SHStack>
                          </SHTypography>
                        </TableCellCenter>
                      </SHTableRow>
                    ))}
                  </SHTableBody>
                  {fields?.[0] && (
                    <SHTableFooter>
                      <SHTableRow>
                        <TableCellCenter
                          colSpan={2}
                          sx={{ position: "relative" }}
                        >
                          <Controller
                            name={`licenses.0.subscriptionBegin`}
                            control={control}
                            rules={{
                              validate: (value) => {
                                const begin = toDateType(value);
                                const end = toDateType(
                                  getValues(`licenses.0.subscriptionEnd`),
                                );
                                if (isNull(end) || isNull(begin)) return false;
                                return isBefore(begin, end);
                              },
                            }}
                            render={({ field }) => (
                              <SHDatePicker
                                maxDate={watch(`licenses.0.subscriptionEnd`)}
                                value={field.value}
                                onChange={(newValue: Date | null) => {
                                  field.onChange(newValue);
                                  trigger();
                                }}
                                textFieldProps={{
                                  error:
                                    !!errors.licenses?.[0]?.subscriptionBegin,
                                }}
                              />
                            )}
                          />

                          <SHBox
                            sx={{
                              position: "absolute",
                              top: 30,
                              right: 0,
                              transform: "translate(10px,0)",
                            }}
                          >
                            <ChevronSVG />
                          </SHBox>
                        </TableCellCenter>
                        <TableCellCenter colSpan={2}>
                          <Controller
                            name={`licenses.0.subscriptionEnd`}
                            control={control}
                            rules={{
                              validate: (value) => {
                                const end = toDateType(value);
                                const begin = toDateType(
                                  getValues(`licenses.0.subscriptionBegin`),
                                );
                                if (isNull(end) || isNull(begin)) return false;
                                return isAfter(end, begin);
                              },
                            }}
                            render={({ field }) => (
                              <SHDatePicker
                                minDate={watch(`licenses.0.subscriptionBegin`)}
                                value={field.value}
                                onChange={(newValue: Date | null) => {
                                  field.onChange(newValue);
                                  trigger();
                                }}
                                textFieldProps={{
                                  error:
                                    !!errors.licenses?.[0]?.subscriptionEnd,
                                }}
                              />
                            )}
                          />
                        </TableCellCenter>
                      </SHTableRow>
                    </SHTableFooter>
                  )}
                </SHTable>
              </SHTableContainer>
            </SHBox>
          </SHStack>
        ) : null}
      </SHStack>
      <UnsavedDialog isDirty={isDirty && !isSubmitting} />
    </form>
  );
};
