import { SHBox, SHTableCell, SHTableRow } from "@components/design-systems";

import { Collapse, LinearProgress } from "@mui/material";

export interface SHDataGridLoadingProps {
  colSpan: number;
  isLoading: boolean;
}
export const SHDataGridLoading = ({
  colSpan,
  isLoading,
}: SHDataGridLoadingProps) => {
  return (
    <SHTableRow
      style={{
        padding: 0,
        borderWidth: isLoading ? "0 1px 0 1px" : 0,
        backgroundColor: "transparent",
      }}
    >
      <SHTableCell
        colSpan={colSpan}
        style={{ padding: 0, backgroundColor: "transparent" }}
      >
        {isLoading ? (
          <Collapse in={isLoading} timeout={100}>
            <LinearProgress
              data-testid="sh-data-grid-loading"
              style={{ height: "2px" }}
            />
          </Collapse>
        ) : (
          <SHBox style={{ height: "2px" }} />
        )}
      </SHTableCell>
    </SHTableRow>
  );
};
