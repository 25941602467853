import { SVGProps } from "react";

export const CircleSVG = ({
  color,
  width = 6,
  height = 6,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 6 6"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle r={3} transform="matrix(-1 0 0 1 3 3)" fill={color} />
  </svg>
);
