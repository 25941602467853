import { DeleteButton } from "@components/buttons/delete";
import { SHBox, SHStack } from "@components/design-systems";
import { ImageGalleryBoxProps } from "@components/image-gallery";
import { ZoomSVG } from "@components/svgs/zoom";
import { S3ObjectDTO } from "@models/core";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import "react-image-gallery/styles/scss/image-gallery.scss";

export interface ResponsiveStyleValue {
  lg?: number | string;
  md?: number | string;
  sm?: number | string;
}
export interface ImageGalleryItemProps extends ImageGalleryBoxProps {
  imageItems?: S3ObjectDTO[];
  widthResponsive?: ResponsiveStyleValue;
  height?: number | string;
  containerHeight?: number | null;
  handleOnZoomOut: (imageId?: string) => void;
}

export const ImageGalleryItem = ({
  handleOnDelete,
  handleOnZoomOut,
  imageItems = [],
  widthResponsive = {
    lg: "32.5%",
    md: "49%",
    sm: "48.4%",
  },
  height = 200,
  containerHeight = 410,
}: ImageGalleryItemProps) => {
  const { palette } = useTheme();
  return (
    <SHStack
      direction={"row"}
      gap={"10px"}
      sx={{
        flexWrap: "wrap",
        width: "100%",
        height: containerHeight ?? 410,
      }}
    >
      {imageItems.map((item) => (
        <SHStack
          width={{ ...widthResponsive }}
          height={height}
          position={"relative"}
          sx={{
            background: `linear-gradient(0deg, ${hexToRGBA(
              palette.secondary[100],
              0.25,
            )}, ${hexToRGBA(palette.secondary[100], 0.25)}),${palette.common.white
              }`,
            "&:hover": {
              "& .sh-image-card-zoom-button": {
                opacity: 1,
              },
              "& .sh-image-card-image": {
                filter: "brightness(.8)",
              },
              background: `linear-gradient(0deg, ${hexToRGBA(
                palette.secondary[100],
                0.4,
              )}, ${hexToRGBA(palette.secondary[100], 0.4)}),${palette.common.white
                }`,
            },
          }}
        >
          {typeof handleOnDelete === "function" && (
            <DeleteButton
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                zIndex: 2,
              }}
              onClick={() => handleOnDelete(item.key)}
            />
          )}

          <img
            className="sh-image-card-image"
            src={item.url}
            alt={item?.description}
            loading="lazy"
            height={"100%"}
            width={"100%"}
            style={{
              display: "block",
              objectFit: "contain",
              objectPosition: "center",
              transition: "all 0.1s ease-in-out",
            }}
          />
          <SHBox
            className={"sh-image-card-zoom-button"}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              opacity: 0,
              cursor: "pointer",
              transition: "all 0.2s ease-in-out",
            }}
            onClick={() => handleOnZoomOut(item.key)}
          >
            <ZoomSVG />
          </SHBox>
        </SHStack>
      ))}
    </SHStack>
  );
};