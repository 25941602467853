import {
  CreateFamilyGroupDTO,
  FamilyGroupDTO,
} from "@models/family-group/entities/familyGroup";
import { ClientStatus } from "@models/reviews/enums/status";
import { UserLOV } from "@models/users/entities/user";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postFamilyGroup } from "@services/family-group/familyGroupService";
import {
  getFamilyGroup,
  putFamilyGroup,
  putFamilyGroupStatus
} from "@services/reviews/reviewClientService";

export interface ReviewFamilyGroupStore {
  familyGroup?: FamilyGroupDTO;
  adviserUsers?: UserLOV[];
  ui: {
    isLoading?: boolean;
    isLoadingAdvisers?: boolean;
    isSubmitting?: boolean;
  };
}

const initialState: ReviewFamilyGroupStore = {
  ui: {
    isLoading: false,
    isLoadingAdvisers: false,
    isSubmitting: false,
  },
};

const reviewClientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setReviewClientAction: (state, action: PayloadAction<FamilyGroupDTO>) => {
      state.familyGroup = action.payload;
    },

    resetReviewClientStore: (state, action: PayloadAction) => {
      state.familyGroup = undefined;
      state.ui = initialState.ui;
    },
  },
  extraReducers(builder) {
    // Load Client detail
    builder.addCase(loadReviewClientThunk.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(loadReviewClientThunk.fulfilled, (state, action) => {
      if (action.payload.isSuccess) {
        state.familyGroup = action.payload?.data;
        state.adviserUsers = action.payload.data?.adviserUsers
      }
      state.ui.isLoading = false;
    });
    builder.addCase(loadReviewClientThunk.rejected, (state, action) => {
      state.ui.isLoading = false;
    });

    // Create Review Client
    builder.addCase(createReviewClientThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(createReviewClientThunk.fulfilled, (state, action) => {
      state.familyGroup = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.ui.isSubmitting = false;
    });
    builder.addCase(createReviewClientThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    // Update Review Client
    builder.addCase(updateReviewClientThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(updateReviewClientThunk.fulfilled, (state, action) => {
      // state.familyGroup = action.payload.isSuccess
      //   ? action.payload?.data
      //   : undefined;
      state.ui.isSubmitting = false;
    });
    builder.addCase(updateReviewClientThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    // Update Review Client status
    builder.addCase(updateReviewClientStatusThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(
      updateReviewClientStatusThunk.fulfilled,
      (state, action) => {
        state.ui.isSubmitting = false;
        if (
          state.familyGroup?.status &&
          action.payload.isSuccess &&
          action.payload.data
        ) {
          state.familyGroup.status = action.payload.data;
        }
      },
    );
    builder.addCase(updateReviewClientStatusThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    // Create family group
    builder.addCase(createFamilyGroupThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(createFamilyGroupThunk.fulfilled, (state, action) => {
      state.ui.isSubmitting = false;
    });
    builder.addCase(createFamilyGroupThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });
  },
});

// Thunk functions
export const loadReviewClientThunk = createAsyncThunk(
  `reviews/client/load`,
  async (clientId: string) => {
    return await getFamilyGroup(clientId);
  },
);

export const createReviewClientThunk = createAsyncThunk(
  `reviews/client/create`,
  async (featureTemplate: FamilyGroupDTO) => {
    return await postFamilyGroup(featureTemplate);
  },
);

export const updateReviewClientThunk = createAsyncThunk(
  `reviews/client/update`,
  async (familyGroup: FamilyGroupDTO) => {
    return await putFamilyGroup(familyGroup);
  },
);

export const updateReviewClientStatusThunk = createAsyncThunk(
  `reviews/client/update-status`,
  async ({ clientId, status }: { clientId: string; status: ClientStatus }) => {
    return await putFamilyGroupStatus(clientId, status);
  },
);

export const createFamilyGroupThunk = createAsyncThunk(
  `review/family-group`,
  async (familyGroup: CreateFamilyGroupDTO) => {
    return await postFamilyGroup(familyGroup);
  },
);

export const { setReviewClientAction, resetReviewClientStore } =
  reviewClientSlice.actions;
export default reviewClientSlice.reducer;
