import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { SHBox } from "@components/design-systems";
import { ArrowButton } from "@components/buttons/arrow";
import { useTheme } from "@mui/material";
import { generateUUID, hexToRGBA } from "@utils";
import { useRef, useState } from "react";
import { S3ObjectDTO } from "@models/core";
import { ImageItem } from "../image-item";
import { ReactImageGalleryItem } from "react-image-gallery";
import { ImageGalleryDialog } from "@components/image-gallery-dialog";
import { isEmpty } from "lodash";

interface ResponsiveStyleValue {
  lg?: number | string;
  md?: number | string;
  sm?: number | string;
}

export interface ImagesCarouselProps {
  images?: S3ObjectDTO[];
  slideHeightResponsive?: ResponsiveStyleValue;
  containerPadding?: number | string;
  footerPadding?: number | string;
  specifyClass?: string;
  paginationClass?: string;
  previewImagesDialogTitle?: string;
  handleOnDelete?: (imageId: string) => void;
}

const defaultContainerPadding = "15px 15px 0 15px";
const defaultFooterPadding = "13px 0";

export const ImagesCarousel = ({
  images,
  slideHeightResponsive = { sm: 100, md: 120, lg: 235 },
  containerPadding = defaultContainerPadding,
  footerPadding = defaultFooterPadding,
  specifyClass = `a${generateUUID()}`,
  paginationClass = `a${generateUUID()}`,
  previewImagesDialogTitle = "Images",
  handleOnDelete,
}: ImagesCarouselProps) => {
  const { palette } = useTheme();
  const initSwiper = useRef(true);
  const [carouselLoaded, setCarouselLoaded] = useState(false);
  const [showPreviewImage, setShowPreviewImage] = useState(false);
  const [startPreviewImageIndex, setStartPreviewImageIndex] = useState(-1);
  const previewImages: ReactImageGalleryItem[] = images?.map(
    (item: S3ObjectDTO) => {
      return {
        original: `${item.url}`,
      };
    },
  ) as ReactImageGalleryItem[];

  return (
    <>
      <SHBox
        sx={{
          position: "relative",
          width: "100%",
          padding: `${
            images && images.length > 3
              ? containerPadding
              : defaultContainerPadding
          }`,
          backgroundColor: hexToRGBA(palette.secondary[200], 0.1),
          border: `1px solid ${palette.secondary[100]}`,
          backdropFilter: "blur(0.5px)",
          "&>.swiper": {
            paddingBottom: "6px",
          },
          "&>.swiper>.swiper-wrapper>.swiper-slide": {
            height: { ...slideHeightResponsive },
          },
        }}
      >
        <Swiper
          loop={images && images.length > 3}
          observer
          centeredSlides={images && images.length > 3}
          centeredSlidesBounds
          slideToClickedSlide
          spaceBetween={15}
          slidesPerView={images && images.length > 3 ? 2.95 : 3}
          modules={[Navigation, Pagination]}
          //add swiper custom navigation
          navigation={{
            prevEl: `.btnPrev-${specifyClass}`,
            nextEl: `.btnNext-${specifyClass}`,
          }}
          //add swiper custom pagination
          pagination={{
            el: `.swiper-pagination-${specifyClass}`,
            type: "bullets",
            clickable: true,
          }}
          onObserverUpdate={(swiper) => {
            // Re-init navigation
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          onSlideChange={(swiper) => {
            if (initSwiper.current) {
              swiper.slideToLoop(0);
              initSwiper.current = false;
              setCarouselLoaded(true);
            }
          }}
        >
          {images?.map((image, index) => (
            <SwiperSlide key={index}>
              <ImageItem
                image={image}
                handleOnDelete={handleOnDelete}
                handleOnZoomOut={(imageId) => {
                  setShowPreviewImage(true);
                  setStartPreviewImageIndex(
                    images?.findIndex((image) => image.key === imageId),
                  );
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <SHBox
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: "16px",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 3,
            padding: `${
              images && images.length > 3 ? footerPadding : defaultFooterPadding
            }`,
            margin: "auto",
          }}
        >
          {images!.length > 3 && (
            <ArrowButton
              className={`btnPrev-${specifyClass}`}
              direction="previous"
              hidden={!carouselLoaded}
            />
          )}

          {/* custom pagination  */}
          <SHBox
            className={`${paginationClass} swiper-pagination-${specifyClass}`}
            sx={{
              [`&.${paginationClass}`]: {
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                columnGap: "8px",
                rowGap: "4px",
                flexWrap: "wrap",
                "&>.swiper-pagination-bullet": {
                  display: "inline-block",
                  backgroundColor: "transparent",
                  transition: "all 0.2s ease",
                  width: "10px",
                  height: "10px",
                  border: `1px solid ${palette.text.disabled}`,
                  opacity: 1,
                  cursor: "pointer",

                  "&-active": {
                    backgroundColor: palette.text.third,
                    width: "28px",
                    borderRadius: "24px",
                  },
                },
              },
            }}
          ></SHBox>
          {images!.length > 3 && (
            <ArrowButton
              className={`btnNext-${specifyClass}`}
              direction="next"
              hidden={!carouselLoaded}
            />
          )}
        </SHBox>
      </SHBox>
      {showPreviewImage && (
        <ImageGalleryDialog
          open={!isEmpty(previewImages)}
          title={previewImagesDialogTitle}
          items={previewImages}
          startIndex={startPreviewImageIndex}
          onClose={() => setShowPreviewImage(false)}
        />
      )}
    </>
  );
};
