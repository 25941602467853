import { FeatureTemplateDTO } from "@models/feature-template/entities/featureTemplate";
import { mockPlatformAnalysisFeatureSelections } from "@pages/platform-analysis/_id/steps/feature/selection/mock";

export const mockFeatureTemplate: FeatureTemplateDTO = {
  id: "5e6778da-c6f4-11ed-afa1-0242ac120002",
  name: "BT Individual 2022",
  ownerId: "5e6778da-c6f4-11ed-afa1-0242ac120002",
  ownerName: "Meta Owner",
  createDate: new Date(),
  lastModifiedDate: new Date(),
  notes: "",
  sections: mockPlatformAnalysisFeatureSelections.sections,
};

export const mockFeatureTemplateLOV: FeatureTemplateDTO = {
  ownerId: "5e6778da-c6f4-11ed-afa1-0242ac120002",
  ownerName: "Meta Owner",
  createDate: new Date(),
  lastModifiedDate: new Date(),
  sections: mockPlatformAnalysisFeatureSelections.sections,
};

export const mockExistingFeatureTemplate: FeatureTemplateDTO[] = [
  { id: "5e6778da-c6f4-11ed-afa1-0242ac120002", name: "AT Individual 2022" },
  { id: "61646ea2-c88a-11ed-afa1-0242ac120002", name: "BT Individual 2023" },
  { id: "65587562-c88a-11ed-afa1-0242ac120002", name: "CT Individual 2024" },
  { id: "65587562-c88a-11ed-afa1-0242ac120002", name: "DT Individual 2025" },
  { id: "65587562-c88a-11ed-afa1-0242ac120002", name: "ET Individual 2026" },
  { id: "65587562-c88a-11ed-afa1-0242ac120002", name: "FT Individual 2027" },
];
