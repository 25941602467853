import {
  SHContainer,
  SHStack,
  SHTabs,
  SHTypography,
} from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { SectionType } from "@models/configuration";
import { SupplierUsersGroup } from "@models/users/group";
import { ProductBeingKickedOutDialog } from "@pages/platform-profiles/components/dialog-being-kick-out";
import { ConfirmKickOutDialog } from "@pages/platform-profiles/components/dialog-confirm-kick-out";
import { ConfirmKickOutAdminDialog } from "@pages/platform-profiles/components/dialog-kick-out-admin";
import {
  loadProduct,
  updateProductData,
  updateSelectedGroup,
} from "@redux/slices/product";
import { RootState, useAppDispatch } from "@redux/store";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { FindBDMTab } from "../edit/components/find-bdm-tab";
import { ProductViewFeesAndRates } from "./components/fees-and-rates";
import { ProductBanner } from "./components/ProductBanner";
import { ProductViewFeature } from "./components/ProductViewFeature";
import { ProductViewStandard } from "./components/ProductViewStandard";
import { ProductGroup } from "@models/product/enums/productGroup";
import { UserType } from "@models/auth";

export const ManageProductView = () => {
  const { productId } = useParams();
  const { supplierId } = useParams();
  const {
    productGroups,
    productUI: { isLoadingProductGroups, selectedGroup },
  } = useSelector((state: RootState) => state.product);
  const { user } = useSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const isOtherSupplierUser = useMemo(() => {
    if (!user?.userType) return;
    return (
      SupplierUsersGroup.includes(user?.userType) &&
      user?.userMetadata?.supplier_id !== supplierId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierId]);

  const renderTabs = () => {
    if (!productGroups?.length) return [];
    return [
      ...productGroups.map((group) => ({
        label: group.name,
        component:
          group.sectionType === SectionType.Fee ? (
            <ProductViewFeesAndRates />
          ) : group.sectionType === SectionType.Standard ? (
            <ProductViewStandard />
          ) : (
            <ProductViewFeature />
          ),
      })),
      {
        label: "Find BDM",
        component: <FindBDMTab />,
      },
    ];
  };
  const group = productGroups[selectedGroup];
  const groupDynamicId = [
    SectionType.Standard,
    SectionType.HasSubSections,
  ].includes(group?.sectionType)
    ? group.id
    : undefined;

  useEffect(() => {
    if (!groupDynamicId) {
      dispatch(updateProductData(undefined));
      return;
    }
    if ((user?.userType === UserType.AdviserTrial ||
      user?.userType === UserType.AdviserAdminTrial)) {
      if (group.name === ProductGroup.Information ||
        group.name === ProductGroup.ESG ||
        group.name === ProductGroup.Features) {
        dispatch(updateProductData(undefined));
        return;
      }
    }
    dispatch(
      loadProduct({ productId, groupId: groupDynamicId, mode: "view" }),
    ).unwrap();
    // eslint-disable-next-line
  }, [groupDynamicId, productId]);

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack gap={"25px"}>
        <SHStack alignItems={"center"} maxHeight={400}>
          <ProductBanner isOtherSupplierUser={isOtherSupplierUser} />
        </SHStack>
        <SHTypography variant="h2" fontSize={"24px"} fontWeight={600}>
          {isLoadingProductGroups ? (
            <SHSkeleton height={38} width={"30%"} />
          ) : (
            "Platform details"
          )}
        </SHTypography>
        <SHTabs
          tabs={renderTabs()}
          onValueChange={(value: string) => {
            dispatch(updateSelectedGroup(+value));
          }}
          isLoading={isLoadingProductGroups}
        />
        {/* <PermissionDeniedDialog /> */}
        <ConfirmKickOutAdminDialog
          supplierId={supplierId}
          productId={productId}
        />
        <ConfirmKickOutDialog supplierId={supplierId} productId={productId} />
        <ProductBeingKickedOutDialog />
      </SHStack>
    </SHContainer>
  );
};
