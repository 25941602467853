import { theme } from "@themes";
import { SVGProps } from "react";
export const CrossSVG = ({
  color = theme.palette.error[200],
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.8921 3.89483C4.07944 3.70754 4.33349 3.60233 4.59839 3.60233C4.86329 3.60233 5.11734 3.70754 5.30468 3.89483L9.59336 8.18351L13.882 3.89483C13.9742 3.79941 14.0844 3.72331 14.2063 3.67095C14.3282 3.61859 14.4593 3.59103 14.5919 3.58988C14.7246 3.58873 14.8561 3.614 14.9789 3.66424C15.1017 3.71447 15.2132 3.78864 15.307 3.88244C15.4008 3.97624 15.475 4.08778 15.5252 4.21055C15.5754 4.33333 15.6007 4.46487 15.5996 4.59752C15.5984 4.73017 15.5709 4.86125 15.5185 4.98313C15.4661 5.10502 15.39 5.21525 15.2946 5.3074L11.0059 9.59609L15.2946 13.8848C15.4766 14.0732 15.5773 14.3255 15.575 14.5875C15.5727 14.8494 15.4677 15.1 15.2825 15.2852C15.0972 15.4704 14.8467 15.5755 14.5847 15.5777C14.3228 15.58 14.0705 15.4793 13.882 15.2974L9.59336 11.0087L5.30468 15.2974C5.11627 15.4793 4.86392 15.58 4.60199 15.5777C4.34005 15.5755 4.08949 15.4704 3.90427 15.2852C3.71905 15.1 3.61399 14.8494 3.61171 14.5875C3.60943 14.3255 3.71013 14.0732 3.8921 13.8848L8.18079 9.59609L3.8921 5.3074C3.70482 5.12006 3.59961 4.86601 3.59961 4.60111C3.59961 4.33622 3.70482 4.08216 3.8921 3.89483Z"
      fill={color}
    />
  </svg>
);
