export const mockPlatformAnalysisFeatureSelections: any = {
  sections: [
    {
      id: "26f5cd52-b302-425f-a92c-805e2ec0ebaa",
      name: "Product menu",
      description:
        "Suite of investments and other products available to clients",
      order: 1,
      totalFeature: 198,
      totalSelectedFeature: 0,
      isSelected: true,
      subSections: [
        {
          id: "4154ba9b-c230-40b7-ae7c-28c154239edc",
          name: "Managed funds",
          description: "Flexibility of the managed funds menu",
          order: 1,
          features: [
            {
              id: "b3c6a483-5cac-4b49-8d12-3b14d47c939b",
              name: "T+2 settlement available on managed funds",
              description:
                "Platform offers managed funds with settlement within two business days of placing a trade",
              order: 1,
              isSelected: false,
            },
            {
              id: "80693820-f07c-4cda-856c-a646b5f6f406",
              name: "SLA to add new managed funds in under 1 month",
              description:
                "Platform has a commitment to add new managed funds requested by advisers to the APL (Approved Product List) within a month",
              order: 2,
              isSelected: false,
            },
            {
              id: "1a3fc172-40ad-4adc-86b0-b32003177636",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 3,
              isSelected: false,
            },
          ],
          totalFeature: 3,
        },
        {
          id: "8f973345-0723-483d-850e-7801d530bfde",
          name: "Listed investments",
          description: "Range of listed investments offered",
          order: 2,
          features: [
            {
              id: "98ccc7e8-c8ec-4783-a445-08d043e63ae1",
              name: "Range of ASX listed securities in super/pension - ASX300",
              description:
                "The shares of all ASX300 companies are available in super and pension accounts",
              order: 2,
              isSelected: false,
            },
            {
              id: "bc9a0f05-0ed3-42d9-b36d-7f37e5e7897f",
              name: "Range of ASX listed securities in super/pension - All ASX",
              description:
                "All ASX listed securities are available in super and pension accounts",
              order: 3,
              isSelected: false,
            },
            {
              id: "50ca4b92-df53-4d2c-acb4-7fc0eed23096",
              name: "Range of ASX listed securities in IDPS - ASX300",
              description:
                "The shares of all ASX300 companies are available in IDPS accounts",
              order: 4,
              isSelected: false,
            },
            {
              id: "788e6578-5349-4176-82e3-6c3a817a12bd",
              name: "Range of ASX listed securities in IDPS - All ASX",
              description:
                "All ASX listed securities are available in IDPS accounts",
              order: 5,
              isSelected: false,
            },
            {
              id: "6e04ca0f-d52d-40f2-8737-f670b4c38caf",
              name: "Access to internationally listed securities",
              description:
                "Securities listed on international exchanges can be bought and sold through the platform",
              order: 6,
              isSelected: false,
            },
            {
              id: "541e5f46-24bf-4e6f-bdf7-811ae2af1720",
              name: "Pooled HIN (custodial structure)",
              description:
                "A nominated company owns clients' shares on their behalf",
              order: 7,
              isSelected: false,
            },
            {
              id: "2e8f65e0-435f-4551-b6ad-777ecb68b543",
              name: "Individual HIN",
              description:
                "Clients have direct ownership of the shares they trade",
              order: 8,
              isSelected: false,
            },
            {
              id: "33549d7e-8f23-4851-a9d8-cec5ae569df9",
              name: "Access to ETFs/LICs/REITs",
              description:
                "Exchange traded funds (ETFs), listed investment companies/trusts (LICs/LITs) and real estate investment trusts (REITS) can be bought and sold through the platform",
              order: 9,
              isSelected: false,
            },
            {
              id: "dff52440-3f68-4e65-b9dd-ac6835272e15",
              name: "Access to IPOs",
              description:
                "Clients can subscribe to initial public offerings (IPOs) through the platform. Or, if they subscribe through an external broker, their funds on the platform can be allocated to the IPO",
              order: 10,
              isSelected: false,
            },
            {
              id: "0ac7bb8e-e928-40c2-8ede-b6ca686afa10",
              name: "Fractional shares",
              description:
                "Ability to purchase portions of a listed share (e.g. 0.25 BHP shares)",
              order: 11,
              isSelected: false,
            },
            {
              id: "963cc803-1109-42cc-a1b7-f5431eb72c4d",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 12,
              isSelected: false,
            },
          ],
          totalFeature: 11,
        },
        {
          id: "0c357948-853d-4a50-baad-29ab6e5ce7ab",
          name: "Managed accounts",
          description: "Managed account structures supported",
          order: 3,
          features: [
            {
              id: "dddafc0e-fc91-4bff-ad6c-44c50a7fb775",
              name: "SMAs",
              description:
                "Separately managed accounts (SMAs) are professionally constructed and managed portfolios of investments which clients have direct ownership over. SMAs require a PDS and RE, and multiple clients can subscribe to the same portfolio product",
              order: 1,
              isSelected: false,
            },
            {
              id: "90964804-11b8-46e5-9c19-f76864e47907",
              name: "Third-party MDAs",
              description:
                "Access to managed discretionary accounts (MDA) that are run by third-parties who are not a client's adviser or associated advice practice/licensee. An MDA is a financial service where a client authorises the MDA provider to manage their portfolio at the provider's discretion but in accordance with an agreed investment framework and program",
              order: 2,
              isSelected: false,
            },
            {
              id: "54b97434-72fb-481c-87ee-dcf2b72184c5",
              name: "Practice/licensee can run MDA",
              description:
                "Practices and licensees can run their own managed discretionary accounts (MDAs) on the platform",
              order: 3,
              isSelected: false,
            },
            {
              id: "1ddcd306-19a2-4b2f-b25d-5cb981cbfa57",
              name: "Nested models",
              description:
                "The platform allows managed accounts to be comprised of other managed accounts",
              order: 4,
              isSelected: false,
            },
            {
              id: "679e84e8-a6ad-48ef-989c-0fd186825a35",
              name: "Capital protected managed accounts",
              description:
                "Capital protection overlay is available on managed accounts. This guarantees clients will receive a proportion of their initial investment at the end of a specified time period",
              order: 5,
              isSelected: false,
            },
            {
              id: "4c8fe510-f3ac-43a7-8c52-e6d3a3b40588",
              name: "No transaction fees in managed accounts",
              description:
                "No transaction fees or brokerage charged on transactions that occur within managed accounts",
              order: 6,
              isSelected: false,
            },
            {
              id: "a05e4479-0630-410a-b730-26575fa0220b",
              name: "SLA to add new SMA in under 1 month",
              description:
                "Platform has a commitment to add new SMAs requested by advisers to the APL (Approved Product List) within a month",
              order: 7,
              isSelected: false,
            },
            {
              id: "328239f0-ecb4-42cd-a25a-913c36cf181f",
              name: "SLA to add new MDA provider in under 1 month",
              description:
                "Platform has a commitment to add a MDA provider requested by advisers to the APL within a month",
              order: 8,
              isSelected: false,
            },
            {
              id: "d8f77d8a-b8f3-4670-b617-0642a5a0bef4",
              name: "Hold international listed securities",
              description:
                "Managed accounts can hold securities listed on an international stock exchange",
              order: 9,
              isSelected: false,
            },
            {
              id: "14a831e2-5528-46ba-91ff-13287eb6e809",
              name: "Hold foreign cash",
              description: "Managed accounts can hold foreign cash",
              order: 10,
              isSelected: false,
            },
            {
              id: "fe4d4012-8a13-4145-bc84-e4520d350e0f",
              name: "Hold direct bonds",
              description: "Managed accounts can hold direct bonds",
              order: 11,
              isSelected: false,
            },
            {
              id: "52d1a69a-70cf-4a84-a907-646eda46437f",
              name: "Hold pre-IPO holdings",
              description: "Managed accounts can hold pre-IPO holdings",
              order: 12,
              isSelected: false,
            },
            {
              id: "edcb0952-0ce4-41e5-8c72-fdb312ada0ba",
              name: "Hold wholesale managed funds",
              description: "Managed accounts can hold wholesale managed funds",
              order: 13,
              isSelected: false,
            },
            {
              id: "bc46c6ce-9708-4275-8033-4721591fc9c2",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 14,
              isSelected: false,
            },
          ],
          totalFeature: 14,
        },
        {
          id: "a33af890-a72a-4b38-9de0-c56ad4d246b6",
          name: "Managed account providers: SMAs",
          description:
            "Separately Managed Account (SMA) managers with at least one model available",
          order: 4,
          features: [
            {
              id: "c6390490-c8ae-4554-a0dd-7662db6fed46",
              name: "Aberdeen Standard",
              description:
                "List of providers that offer at least 1 SMA (Separately Managed Account) on the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "62ee3dc7-afea-40fd-8ea1-9713b9cf3a74",
              name: "Aequitas",
              description: "",
              order: 2,
              isSelected: false,
            },
            {
              id: "ceb155b8-1a0f-457d-9b3d-6ba7a50a9880",
              name: "AllianceBernstein",
              description: "",
              order: 3,
              isSelected: false,
            },
            {
              id: "ad99149f-1b2e-491b-8cb3-a97d9b567f4e",
              name: "Alpha",
              description: "",
              order: 4,
              isSelected: false,
            },
            {
              id: "55330412-4c9a-4e93-b191-57c8977defe8",
              name: "AMP Capital",
              description: "",
              order: 5,
              isSelected: false,
            },
            {
              id: "3a1c2af5-30f9-4e4b-847b-8ae54235787c",
              name: "Antares",
              description: "",
              order: 6,
              isSelected: false,
            },
            {
              id: "da354d1c-95b2-40a9-89ed-d5266a065550",
              name: "Armytage Private",
              description: "",
              order: 7,
              isSelected: false,
            },
            {
              id: "4a75b42d-4fce-42e0-a335-6665e3ff7913",
              name: "Aro",
              description: "",
              order: 8,
              isSelected: false,
            },
            {
              id: "e68a9852-073a-478a-bebe-51a23de92227",
              name: "Atlas",
              description: "",
              order: 9,
              isSelected: false,
            },
            {
              id: "0e76ec32-8a4a-459d-86ec-3c6afec87c33",
              name: "Ausbil",
              description: "",
              order: 10,
              isSelected: false,
            },
            {
              id: "89e846f1-521e-4ed4-8a55-393dd885bd09",
              name: "Australian Ethical",
              description: "",
              order: 11,
              isSelected: false,
            },
            {
              id: "2e63a42a-9375-4552-b330-0f529b6dec20",
              name: "AZ Sestante",
              description: "",
              order: 12,
              isSelected: false,
            },
            {
              id: "e6d7dfb7-fdc8-4124-801f-2f5acff0320d",
              name: "Banyantree",
              description: "",
              order: 13,
              isSelected: false,
            },
            {
              id: "1c0d7efe-0323-4a20-862c-ff945ccef7a7",
              name: "Beanstalk",
              description: "",
              order: 14,
              isSelected: false,
            },
            {
              id: "0115790a-bcf2-4fae-a71b-55fca4653bcb",
              name: "Bellmont",
              description: "",
              order: 15,
              isSelected: false,
            },
            {
              id: "5acee9b9-c1e6-4137-bf8c-6ae80f7deb3f",
              name: "Bennelong",
              description: "",
              order: 16,
              isSelected: false,
            },
            {
              id: "8131814f-b412-4621-a13f-4fb222edb228",
              name: "BetaShares",
              description: "",
              order: 17,
              isSelected: false,
            },
            {
              id: "4516652e-506d-4764-83e3-55ce1a9c2f6c",
              name: "Blackmore",
              description: "",
              order: 18,
              isSelected: false,
            },
            {
              id: "611d02a1-419d-4d88-b0b2-7596ca9a8477",
              name: "BlackRock",
              description: "",
              order: 19,
              isSelected: false,
            },
            {
              id: "9132b68d-80f5-4d15-9063-f53fb881cebe",
              name: "BondAdviser",
              description: "",
              order: 20,
              isSelected: false,
            },
            {
              id: "5e89e8c3-7aad-4553-a9c9-5b532fc36c35",
              name: "Boutique Wealth",
              description: "",
              order: 21,
              isSelected: false,
            },
            {
              id: "b6cd07a7-1cee-4a47-8f55-917c8388c860",
              name: "CIBC",
              description: "",
              order: 22,
              isSelected: false,
            },
            {
              id: "f7d87362-afdd-4200-949b-28a01fff9ffd",
              name: "ClearView",
              description: "",
              order: 23,
              isSelected: false,
            },
            {
              id: "0ea196f9-16af-49dd-bbf4-4578f854ebb7",
              name: "Dimensional",
              description: "",
              order: 24,
              isSelected: false,
            },
            {
              id: "3d5f766c-1a82-4402-8b26-767081e866cb",
              name: "DNR",
              description: "",
              order: 25,
              isSelected: false,
            },
            {
              id: "098f9dd2-337a-462d-bc51-7dd95338f8cd",
              name: "Drummond",
              description: "",
              order: 26,
              isSelected: false,
            },
            {
              id: "60812b15-19ed-4591-bf02-b46715bdd9b9",
              name: "Elston",
              description: "",
              order: 27,
              isSelected: false,
            },
            {
              id: "713fcc59-bdec-4ce0-8933-6e9024a25831",
              name: "Evans and Partners",
              description: "",
              order: 28,
              isSelected: false,
            },
            {
              id: "a2508205-694b-4c95-93ff-cbce97eb1775",
              name: "Evergreen",
              description: "",
              order: 29,
              isSelected: false,
            },
            {
              id: "bb433539-7971-4b5f-a9ff-632e44aeae07",
              name: "First Sentier Investors",
              description: "",
              order: 30,
              isSelected: false,
            },
            {
              id: "4b5ddbfd-b621-44a1-a45a-9a46c74e2f58",
              name: "Franklin Templeton",
              description: "",
              order: 31,
              isSelected: false,
            },
            {
              id: "fc4eee01-b276-4257-91a8-0b9a33a51deb",
              name: "Glennon Capital",
              description: "",
              order: 32,
              isSelected: false,
            },
            {
              id: "0a3a9d26-5a03-44aa-bc3a-41c40b1ae877",
              name: "Guardcap",
              description: "",
              order: 33,
              isSelected: false,
            },
            {
              id: "0e94a364-3622-46ff-83f7-4986ca3ab8b7",
              name: "Hyperion",
              description: "",
              order: 34,
              isSelected: false,
            },
            {
              id: "ea36fa05-2fe6-40b2-ad3b-ae510e1814aa",
              name: "Infinitas",
              description: "",
              order: 35,
              isSelected: false,
            },
            {
              id: "414c4690-620d-462a-9dc2-8e89f76e0d00",
              name: "Innova",
              description: "",
              order: 36,
              isSelected: false,
            },
            {
              id: "8b4b11bc-f491-4445-beaa-283f2ff40a8c",
              name: "Insync",
              description: "",
              order: 37,
              isSelected: false,
            },
            {
              id: "eaf594aa-a1cc-4a33-9712-d608625ff8ce",
              name: "InvestSense",
              description: "",
              order: 38,
              isSelected: false,
            },
            {
              id: "efec8737-7c41-4edf-b6ad-b50d3a77f406",
              name: "JBWere",
              description: "",
              order: 39,
              isSelected: false,
            },
            {
              id: "799cc6da-78b5-4d15-acf6-7e035378bcef",
              name: "Lonsec",
              description: "",
              order: 40,
              isSelected: false,
            },
            {
              id: "4b8f7461-d886-4741-9ac7-01b359f8785e",
              name: "Macquarie",
              description: "",
              order: 41,
              isSelected: false,
            },
            {
              id: "4d5d0939-4592-41ff-aefc-d8805e7a044c",
              name: "Martin Currie",
              description: "",
              order: 42,
              isSelected: false,
            },
            {
              id: "1ecd3b5f-caa2-4406-a08f-30a267904852",
              name: "Mason Stevens",
              description: "",
              order: 43,
              isSelected: false,
            },
            {
              id: "9710f275-5bc0-4aae-acbc-16903c8ac478",
              name: "Mercer",
              description: "",
              order: 44,
              isSelected: false,
            },
            {
              id: "e1cd811c-3752-4534-b887-54ce782c95a2",
              name: "Milliman",
              description: "",
              order: 45,
              isSelected: false,
            },
            {
              id: "843ecbb2-02f4-44ff-8e3a-2fea12d401e8",
              name: "MLC Asset Management",
              description: "",
              order: 46,
              isSelected: false,
            },
            {
              id: "b651a164-850a-4e55-a82a-d9ec733db6e6",
              name: "Morgan Stanley",
              description: "",
              order: 47,
              isSelected: false,
            },
            {
              id: "a92f8690-716a-4fa3-a02d-4eda32765121",
              name: "Morningstar",
              description: "",
              order: 48,
              isSelected: false,
            },
            {
              id: "53f49646-46c9-4624-9bdc-fd57b32a61f3",
              name: "Nikko AM",
              description: "",
              order: 49,
              isSelected: false,
            },
            {
              id: "d9f93925-405b-450d-93e1-531d8fde6ebd",
              name: "North",
              description: "",
              order: 50,
              isSelected: false,
            },
            {
              id: "b4fab97a-25f3-4e3a-8286-b02e28828268",
              name: "Oakleigh",
              description: "",
              order: 51,
              isSelected: false,
            },
            {
              id: "39e6cc21-e687-4340-af95-339585a1335d",
              name: "Ord Minnett",
              description: "",
              order: 52,
              isSelected: false,
            },
            {
              id: "dad0a7fa-f59d-4b30-8ccd-d23a64b33f70",
              name: "Pendal",
              description: "",
              order: 53,
              isSelected: false,
            },
            {
              id: "1470b57a-bbb3-4e47-8939-89b5f09238b7",
              name: "PFS",
              description: "",
              order: 54,
              isSelected: false,
            },
            {
              id: "91327db9-6eb8-4e32-a6bb-a6892ebcf97b",
              name: "Quest",
              description: "",
              order: 55,
              isSelected: false,
            },
            {
              id: "10332b35-f903-4cea-80d9-390b26bf2f23",
              name: "Quilla",
              description: "",
              order: 56,
              isSelected: false,
            },
            {
              id: "fd21f975-afc4-4b7a-b044-dd1f2e663c8f",
              name: "Ralton",
              description: "",
              order: 57,
              isSelected: false,
            },
            {
              id: "f2659785-32fc-40bf-82fb-04c9ab00a2b2",
              name: "Real Asset Management",
              description: "",
              order: 58,
              isSelected: false,
            },
            {
              id: "841792f4-7f12-4e5e-959f-04922624c844",
              name: "Redpoint",
              description: "",
              order: 59,
              isSelected: false,
            },
            {
              id: "4bae7dac-24e0-45e5-b635-6a90522f2da6",
              name: "Royston Capital",
              description: "",
              order: 60,
              isSelected: false,
            },
            {
              id: "a996e4c2-2c7b-4610-a00f-1fccde8def29",
              name: "Russell Investments",
              description: "",
              order: 61,
              isSelected: false,
            },
            {
              id: "2e08d6d5-024e-49c7-a1ec-49e7a8c45490",
              name: "Sestante",
              description: "",
              order: 62,
              isSelected: false,
            },
            {
              id: "8f2af372-7d1b-4350-a019-bce2c17ef132",
              name: "SG Hiscock",
              description: "",
              order: 63,
              isSelected: false,
            },
            {
              id: "9a47b47e-44f3-4ce7-8336-2d0b4b2a349a",
              name: "Shaw and Partners",
              description: "",
              order: 64,
              isSelected: false,
            },
            {
              id: "9ccd26d8-0408-47bc-bdd8-ae61846328e8",
              name: "Southeastern Asset Management",
              description: "",
              order: 65,
              isSelected: false,
            },
            {
              id: "466a8e48-1838-4543-baa5-82902ecfd7a3",
              name: "State Street",
              description: "",
              order: 66,
              isSelected: false,
            },
            {
              id: "f156f66a-deaa-42a7-a178-46836a307783",
              name: "Swell",
              description: "",
              order: 67,
              isSelected: false,
            },
            {
              id: "bf37c0bc-47a2-4af2-a53b-7e99c3222938",
              name: "Thesan",
              description: "",
              order: 68,
              isSelected: false,
            },
            {
              id: "1efcd0df-145e-4ddd-9c74-3eef2f0a2678",
              name: "UBS",
              description: "",
              order: 69,
              isSelected: false,
            },
            {
              id: "4c895927-ff51-49c3-a379-4fae1596894e",
              name: "Vanguard",
              description: "",
              order: 70,
              isSelected: false,
            },
            {
              id: "a18c7865-56c4-4300-85b6-f7c3a576c7e0",
              name: "Watershed",
              description: "",
              order: 71,
              isSelected: false,
            },
            {
              id: "b152d14a-e0b2-4dec-a939-809c7b0dbc94",
              name: "Wealthtrac",
              description: "",
              order: 72,
              isSelected: false,
            },
            {
              id: "5ce36900-4476-43f2-9be1-de960ff64807",
              name: "Zenith",
              description: "",
              order: 73,
              isSelected: false,
            },
            {
              id: "5bf0d4ac-2c93-4023-a25d-bc8a6caac44e",
              name: "Zurich",
              description: "",
              order: 74,
              isSelected: false,
            },
            {
              id: "8424590f-247b-4f4a-876b-039f463e71e8",
              name: "Other",
              description: "",
              order: 75,
              isSelected: false,
            },
            {
              id: "cddb963e-24ac-4cef-b30c-c63d69a4b3b6",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 76,
              isSelected: false,
            },
          ],
          totalFeature: 76,
        },
        {
          id: "b5f71e68-213a-4553-bb02-dff8fa966c17",
          name: "Managed account providers: MDAs",
          description:
            "Managed Discretionary Accounts (MDA) managers operating through the platform",
          order: 5,
          features: [
            {
              id: "2432b9d7-bf69-43e7-a89c-d07bb5f3676c",
              name: "Accordius",
              description:
                "List of providers that run MDAs (Managed Discretionary Accounts) through the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "7279291a-85f8-4e33-a449-27bb2449bcbd",
              name: "Amplus Global",
              description: "",
              order: 2,
              isSelected: false,
            },
            {
              id: "41b252b9-24f6-4bc0-9e1e-aa9ddef4892d",
              name: "Banyantree",
              description: "",
              order: 3,
              isSelected: false,
            },
            {
              id: "9e2d6c42-1dec-4eb4-9ed5-c42982b80b74",
              name: "Bellmont",
              description: "",
              order: 4,
              isSelected: false,
            },
            {
              id: "f83f2882-9355-4272-8f8f-fe0654e0dbcf",
              name: "DNR",
              description: "",
              order: 5,
              isSelected: false,
            },
            {
              id: "7d83b4fc-6260-41d2-a0dd-03e7f1bfe9cf",
              name: "Elston",
              description: "",
              order: 6,
              isSelected: false,
            },
            {
              id: "7e360c7a-5eb4-49bd-b7bc-b32bec63747b",
              name: "Harbourside Capital",
              description: "",
              order: 7,
              isSelected: false,
            },
            {
              id: "e67d439a-877a-46ac-aa48-e4bdf952570f",
              name: "HLK Group",
              description: "",
              order: 8,
              isSelected: false,
            },
            {
              id: "ed008541-64ab-49d6-979d-af57ef8e71b2",
              name: "Implemented Portfolios",
              description: "",
              order: 9,
              isSelected: false,
            },
            {
              id: "1715387a-b0d9-4a3a-a03e-ee1d68594cfb",
              name: "Private Portfolio Managers",
              description: "",
              order: 10,
              isSelected: false,
            },
            {
              id: "70cc54c5-802c-4e20-b89a-c7c402b2a41a",
              name: "Real Asset Management",
              description: "",
              order: 11,
              isSelected: false,
            },
            {
              id: "f443f85c-65a3-41d8-a280-6f81a853c671",
              name: "Reasonant Asset Management",
              description: "",
              order: 12,
              isSelected: false,
            },
            {
              id: "6ffdb081-b1b2-428b-8a17-ff7853bacc42",
              name: "Royston Capital",
              description: "",
              order: 13,
              isSelected: false,
            },
            {
              id: "48cb2880-206e-47a3-a2e6-bf883dde4cfa",
              name: "Sanlam Private Wealth",
              description: "",
              order: 14,
              isSelected: false,
            },
            {
              id: "1d8b5fd0-08ad-4a3d-b2c0-33090760e79f",
              name: "Sterling Managed Investments",
              description: "",
              order: 15,
              isSelected: false,
            },
            {
              id: "ba339570-f6a2-4dc6-8378-90dfe49518cc",
              name: "Thesan",
              description: "",
              order: 16,
              isSelected: false,
            },
            {
              id: "d3d45e3a-d06a-4358-9e12-bbfefef80930",
              name: "Walker Capital",
              description: "",
              order: 17,
              isSelected: false,
            },
            {
              id: "ba92262c-6d44-44e3-af9a-c2eaee107c6f",
              name: "Watershed",
              description: "",
              order: 18,
              isSelected: false,
            },
            {
              id: "847b255f-3b82-4d45-afe4-69cede7478bf",
              name: "Watershed Funds Management",
              description: "",
              order: 19,
              isSelected: false,
            },
            {
              id: "3db6be9d-fc44-411c-8189-551938d2e980",
              name: "Whitehaven Private Portfolios",
              description: "",
              order: 20,
              isSelected: false,
            },
            {
              id: "7c1e0cbd-e6cf-4ebd-a660-30a960ef8956",
              name: "Zenith",
              description: "",
              order: 21,
              isSelected: false,
            },
            {
              id: "f6adf4c3-159f-44d0-951c-bb672e06fdca",
              name: "Other",
              description: "",
              order: 22,
              isSelected: false,
            },
            {
              id: "e45d4c39-d3eb-4555-8bd2-42f4bde38607",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 23,
              isSelected: false,
            },
          ],
          totalFeature: 23,
        },
        {
          id: "fb462192-49f1-45d2-bf9b-7d59a664f681",
          name: "Cash and term deposits",
          description: "Types of cash accounts and term deposit management",
          order: 6,
          features: [
            {
              id: "02402ad1-546f-4513-b5ab-44c26fb09ddf",
              name: "Can apply for term deposit on-platform",
              description:
                "Term deposits can be bought online through the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "9ee06133-1375-45ce-acc7-65cf721eae3e",
              name: "Integrated term deposit reporting",
              description:
                "All relevant term deposit data are included in reports generated on the platform e.g. portfolio valuation, performance",
              order: 2,
              isSelected: false,
            },
            {
              id: "ff8c4143-5e28-4a12-9183-dba04dcb453b",
              name: "Online management of TD maturity",
              description:
                "Instructions can be provided online for what happens to a term deposit at maturity (e.g. roll over to another TD)",
              order: 3,
              isSelected: false,
            },
            {
              id: "776deb5d-8cd7-4c3e-8340-aae9c9cd0218",
              name: "Automatic TD rollover facility",
              description:
                "Term deposits can be set to automatically rollover in perpetuity. This may be an instruction to roll over to the highest yielding TD of a certain maturity each time",
              order: 4,
              isSelected: false,
            },
            {
              id: "c17705a8-377b-467c-a6fe-1588d7e49bf4",
              name: "Multi-currency cash account",
              description:
                "Platform has cash accounts for multiple currencies. Any international listed security transactions can be settled using these foreign currency accounts, and income/dividends are also paid here",
              order: 5,
              isSelected: false,
            },
            {
              id: "a608af9d-d47c-4e40-8e27-262fe96ce87c",
              name: "Platform cash account",
              description:
                "Whether the platform has a cash account that doesn't have any associated management fees etc",
              order: 6,
              isSelected: false,
            },
            {
              id: "91f1c4f1-858c-4a42-b368-262ca11fac92",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 7,
              isSelected: false,
            },
          ],
          totalFeature: 7,
        },
        {
          id: "8db6054c-ad79-48c9-bf0e-3a22c3dc91de",
          name: "CMA providers",
          description: "CMA providers integrated into the platform",
          order: 7,
          features: [
            {
              id: "59abc9fe-02e6-4827-9f71-8a61ac5120cc",
              name: "ANZ",
              description: "ANZ CMA offering is integrated into the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "9b71f9dd-d8e8-4bf4-8154-fd381d24aedd",
              name: "Macquarie",
              description:
                "Macquarie CMA offering is integrated into the platform",
              order: 2,
              isSelected: false,
            },
            {
              id: "85b10469-df96-4216-81df-f40dbe52399e",
              name: "Other",
              description: "",
              order: 3,
              isSelected: false,
            },
            {
              id: "ca657c37-365a-4d44-990f-4867ed2afd92",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 4,
              isSelected: false,
            },
          ],
          totalFeature: 4,
        },
        {
          id: "779e8640-3591-4b53-a247-eab47aae7fe0",
          name: "Term deposit providers",
          description:
            "Term deposit providers with at least one term deposit available",
          order: 8,
          features: [
            {
              id: "41dab0ad-1411-427b-a3a5-f08c57735b14",
              name: "Adelaide Bank",
              description:
                "List of providers that offer at least 1 term deposit on the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "5a34fa60-187d-4a91-b909-39d5b4122fa1",
              name: "AMP",
              description: "",
              order: 2,
              isSelected: false,
            },
            {
              id: "862787cb-55ce-40d0-aff3-c7cf9d96613f",
              name: "ANZ",
              description: "",
              order: 3,
              isSelected: false,
            },
            {
              id: "b0a73f27-5233-46e9-ac15-0edc0fda1290",
              name: "Bank of Queensland",
              description: "",
              order: 4,
              isSelected: false,
            },
            {
              id: "5beceac1-7da3-4552-bd2d-722f4994686a",
              name: "ING Direct",
              description: "",
              order: 5,
              isSelected: false,
            },
            {
              id: "79890f18-8e3c-43e3-87a9-246ce461d3aa",
              name: "Macquarie",
              description: "",
              order: 6,
              isSelected: false,
            },
            {
              id: "b5c59ad7-f8c6-4f82-aa82-3d1dc57f15cc",
              name: "ME Bank",
              description: "",
              order: 7,
              isSelected: false,
            },
            {
              id: "9f9cb2aa-0c8f-4b2a-87cb-8b48d4ba6846",
              name: "NAB",
              description: "",
              order: 8,
              isSelected: false,
            },
            {
              id: "2ce490d6-d540-4a23-a547-0251a12f440d",
              name: "Rabobank",
              description: "",
              order: 9,
              isSelected: false,
            },
            {
              id: "61a7ef0e-e1f9-48cb-9621-ff4d519698fb",
              name: "Westpac",
              description: "",
              order: 10,
              isSelected: false,
            },
            {
              id: "e099aa68-b6b6-476a-bb59-086bca2d4e91",
              name: "Other",
              description: "",
              order: 11,
              isSelected: false,
            },
            {
              id: "54688d9f-5e6e-43ee-a18a-15973486f890",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 12,
              isSelected: false,
            },
          ],
          totalFeature: 12,
        },
        {
          id: "ab7534c3-bda2-4a03-a088-1fd22d8086e5",
          name: "Insurance",
          description: "Insurance capabilities",
          order: 9,
          features: [
            {
              id: "c9045219-574f-44f5-a174-d2271337e7df",
              name: "Can apply for insurance on-platform",
              description:
                "Online application process to purchase retail insurance through the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "aae9b13c-e3c9-4257-a4c8-18c2e7a6d17b",
              name: "Insurance reporting integrated into platform",
              description:
                "Insurance policy information is accessible through the platform and integrated into reports ",
              order: 2,
              isSelected: false,
            },
            {
              id: "6c97d753-22a3-4585-a83a-53394fe7928e",
              name: "Claims management via platform",
              description:
                "Platform provides access to an online facility that allows advisers to manage and track any claims with the insurer",
              order: 3,
              isSelected: false,
            },
            {
              id: "08d5dbc1-9f09-44c7-9de6-4cfe3d7c6985",
              name: "Group insurance",
              description: "Platform provides access to group insurance",
              order: 4,
              isSelected: false,
            },
            {
              id: "05c10fd4-a1d9-446c-8b91-0bdc77dacdcb",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 5,
              isSelected: false,
            },
          ],
          totalFeature: 5,
        },
        {
          id: "1a89fe10-7b21-4235-bc09-6b5495c42bf4",
          name: "Retail insurance providers",
          description: "Retail insurance providers available",
          order: 10,
          features: [
            {
              id: "53bd13c0-99df-4edd-b3bc-80d72805b137",
              name: "AIA",
              description: "List of retail insurance providers",
              order: 1,
              isSelected: false,
            },
            {
              id: "c2905d36-433b-491f-95c8-3bc89a8d99a9",
              name: "BT Life",
              description: "",
              order: 2,
              isSelected: false,
            },
            {
              id: "3d3e09c2-1c7c-4659-9b68-ad2ece849d56",
              name: "ClearView",
              description: "",
              order: 3,
              isSelected: false,
            },
            {
              id: "dfd97ae2-9c2e-48b4-b679-a4a4d54a95a7",
              name: "MetLife",
              description: "",
              order: 4,
              isSelected: false,
            },
            {
              id: "f4b0fdce-25a0-47a8-9afe-65a5e6173e73",
              name: "MLC Life Insurance",
              description: "",
              order: 5,
              isSelected: false,
            },
            {
              id: "04638026-a003-4de0-aea1-c2f22119d327",
              name: "NEOS Life",
              description: "",
              order: 6,
              isSelected: false,
            },
            {
              id: "8ed98f34-f586-42f3-a732-3cd89fbe95e4",
              name: "OnePath",
              description: "",
              order: 7,
              isSelected: false,
            },
            {
              id: "365a5661-f44a-4c56-b20b-527ea35705b4",
              name: "Resolution Life (AMP Life)",
              description: "",
              order: 8,
              isSelected: false,
            },
            {
              id: "a860540c-bdc4-4840-be81-6c734970c267",
              name: "TAL",
              description: "",
              order: 9,
              isSelected: false,
            },
            {
              id: "cdaeb4c3-3910-4719-a833-bc3a5c1f97ae",
              name: "Zurich",
              description: "",
              order: 10,
              isSelected: false,
            },
            {
              id: "871d0fb6-d580-4996-8685-eca6d8489aa2",
              name: "Other",
              description: "",
              order: 11,
              isSelected: false,
            },
            {
              id: "aebab947-10a4-4799-b2ef-ef99cea2e928",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 12,
              isSelected: false,
            },
          ],
          totalFeature: 12,
        },
        {
          id: "1a3340d2-0836-40b0-a9d6-acb740d2ea37",
          name: "Retirement",
          description:
            "Range of retirement-focused products available, including annuities",
          order: 11,
          features: [
            {
              id: "426f8170-b044-4fb3-910e-d37ee667d08f",
              name: "Can apply for annuities on-platform",
              description:
                "Online application process to purchase annuities through the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "c7590362-6ed9-45c5-b155-76a790f21725",
              name: "Annuity reporting integrated into platform",
              description:
                "Annuity holdings information is accessible through the platform and integrated into reports (e.g. portfolio valuation, asset allocation, transactions)",
              order: 2,
              isSelected: false,
            },
            {
              id: "00858359-558c-4e71-b76c-f6ede1114b5a",
              name: "Capital protected products",
              description:
                "Products that protect a pre-defined proportion of clients' initial investments for a specified time period",
              order: 3,
              isSelected: false,
            },
            {
              id: "3c2e1201-0649-46e8-9605-dfeb52ed57ff",
              name: "Income protected products",
              description:
                "Products other than annuities that offer a guaranteed level of income across a specified time period",
              order: 4,
              isSelected: false,
            },
            {
              id: "4df0f42f-24a0-43a5-b73c-09606a1ea739",
              name: "Challenger annuities",
              description: "",
              order: 5,
              isSelected: false,
            },
            {
              id: "9c2354da-170b-4bed-9887-6eb0e1d2f4dd",
              name: "AIA annuities",
              description: "",
              order: 6,
              isSelected: false,
            },
            {
              id: "a99b3b51-0036-4428-8d4d-ffb5636af56e",
              name: "Generation Life investment bonds",
              description: "",
              order: 7,
              isSelected: false,
            },
            {
              id: "2c6b7903-6b82-4a70-b05d-175cd67858ae",
              name: "AIA investment bonds",
              description: "",
              order: 8,
              isSelected: false,
            },
            {
              id: "aa14008a-7c45-4c54-9e0c-e065342451a2",
              name: "Allianz Retire+ Future Safe",
              description: "",
              order: 9,
              isSelected: false,
            },
            {
              id: "66ccb701-fe4d-4fcc-a892-2c8d28b4242b",
              name: "Other",
              description: "Other retirement-related products",
              order: 10,
              isSelected: false,
            },
            {
              id: "b45e2051-eaba-4422-9b08-aaeb022147b6",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 11,
              isSelected: false,
            },
          ],
          totalFeature: 11,
        },
        {
          id: "ae274d5c-3ec1-4058-b7b5-277f0a1227f9",
          name: "Margin lending",
          description: "Access to margin loans",
          order: 12,
          features: [
            {
              id: "8a177bb7-648c-4188-b6c9-9b3a3a2b1ab3",
              name: "Can apply for margin loan on-platform",
              description:
                "Online application process for platform-integrated margin loans",
              order: 1,
              isSelected: false,
            },
            {
              id: "ced7d2f8-a778-4a7d-85ea-d2d7b7881b9c",
              name: "Can link external margin lender to platform",
              description:
                "Funds from margin loans held outside the platform can be accessed through the platform",
              order: 2,
              isSelected: false,
            },
            {
              id: "aeda3492-5526-4087-8e0c-b4cc4ffda10a",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 3,
              isSelected: false,
            },
          ],
          totalFeature: 3,
        },
        {
          id: "0c6cad76-1499-48f4-93a6-2096e0087f6e",
          name: "Margin lending providers",
          description: "Integrated margin loan providers",
          order: 13,
          features: [
            {
              id: "a3db4738-40a4-4a6a-9f20-3679ae02495c",
              name: "ANZ",
              description: "List of integrated margin loan providers",
              order: 1,
              isSelected: false,
            },
            {
              id: "c5af432f-326c-4872-b54f-d9c80d8d276a",
              name: "Bell Potter",
              description: "",
              order: 2,
              isSelected: false,
            },
            {
              id: "54219b74-7796-4015-b10b-8abb87857a99",
              name: "BT",
              description: "",
              order: 3,
              isSelected: false,
            },
            {
              id: "7e5aab2c-9a97-4cb2-88fb-5c5f09bfec55",
              name: "CommSec",
              description: "",
              order: 4,
              isSelected: false,
            },
            {
              id: "7c3405d8-47a1-4afc-8061-eedf33f0e612",
              name: "Leveraged",
              description: "",
              order: 5,
              isSelected: false,
            },
            {
              id: "235d14f1-b675-4ffc-9a09-eb7ae5496d70",
              name: "NAB Equity Builder",
              description: "",
              order: 6,
              isSelected: false,
            },
            {
              id: "784b071d-aa27-420f-96df-9b47e72bf8fd",
              name: "NAB Margin Lending",
              description: "",
              order: 7,
              isSelected: false,
            },
            {
              id: "2fa94c9e-ade6-48a4-9b9e-75b642fabc78",
              name: "St George",
              description: "",
              order: 8,
              isSelected: false,
            },
            {
              id: "8f6d1686-1807-4166-840d-19890a53138b",
              name: "Other",
              description: "",
              order: 9,
              isSelected: false,
            },
            {
              id: "35dcc828-bada-4da4-af21-0f52c830869e",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 10,
              isSelected: false,
            },
          ],
          totalFeature: 10,
        },
        {
          id: "565757f9-8c72-4431-9eb7-97d6de1f339f",
          name: "Wholesale investments",
          description:
            "Access to investments including bonds, IM funds and derivatives",
          order: 14,
          features: [
            {
              id: "cacdcc12-c803-43eb-b281-5722973e2e1e",
              name: "Direct bonds: Corporate",
              description:
                "Direct access to unlisted corporate bonds, outside of pooled investments",
              order: 1,
              isSelected: false,
            },
            {
              id: "6d423389-a1e0-4d0d-a89f-bbe2edf52706",
              name: "Direct bonds: Government",
              description:
                "Direct access to unlisted government bonds, outside of pooled investments",
              order: 2,
              isSelected: false,
            },
            {
              id: "8292b673-6639-41a6-bebf-c95f423c5a22",
              name: "Wholesale funds/IM funds",
              description:
                "Funds that are exclusively offered to clients that qualify as wholesale investors. For example, through an Information Memorandum",
              order: 3,
              isSelected: false,
            },
            {
              id: "4a72f7d8-faaf-4a12-ac83-ace11606c13f",
              name: "Derivatives",
              description:
                "Derivative instruments such as futures and exchange traded options (ETOs) are available through the platform",
              order: 4,
              isSelected: false,
            },
            {
              id: "eb3fa09a-e2ee-404a-b040-e4b96b2fe979",
              name: "Private equity funds",
              description: "",
              order: 5,
              isSelected: false,
            },
            {
              id: "cbe4b9e7-0c90-465d-bc73-5b383e26220b",
              name: "Hedge funds",
              description: "",
              order: 6,
              isSelected: false,
            },
            {
              id: "caffa68d-2dee-4bb4-a510-3b3e56ab22d0",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 7,
              isSelected: false,
            },
          ],
          totalFeature: 7,
        },
      ],
      iconUrl:
        "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1670817678004Group.svg",
    },
    {
      id: "501b868a-f7f1-4d01-ab7c-c5bb45d6eecf",
      name: "Account management",
      description:
        "Onboarding and managing client accounts, including transfers and consent",
      order: 2,
      totalFeature: 37,
      totalSelectedFeature: 0,
      isSelected: false,
      subSections: [
        {
          id: "75e2de6b-7cd3-44ca-8405-e9ee0cdbb07c",
          name: "Account opening",
          description: "The application and account opening process",
          order: 1,
          features: [
            {
              id: "b99e95d1-99a6-4eaf-b2b2-54da4246d9fc",
              name: "Online application process",
              description:
                "The account application for clients can be completed online within the platform portal (this does not include filling out a PDF and then emailing it through)",
              order: 1,
              isSelected: false,
            },
            {
              id: "4728ebe0-b1a3-47a1-8c8a-ebc08cfd5527",
              name: "Online identity verification for AML/CTF purposes",
              description:
                "A client's identity can be verified during the online application process through in real-time (e.g. through an online verification service)",
              order: 2,
              isSelected: false,
            },
            {
              id: "679baa24-143f-48ce-9395-d1e66161b258",
              name: "Client-driven process",
              description:
                "Clients can edit and complete the application process on their own, including upload supporting documents ",
              order: 3,
              isSelected: false,
            },
            {
              id: "9d03527d-374b-4858-ab67-a364ff8826e2",
              name: "Digital client authority accepted to open account",
              description:
                "Clients can authorise their account application through digital methods e.g. logging into the portal to review the application and provide digital authority",
              order: 4,
              isSelected: false,
            },
            {
              id: "f405ba76-e2dc-4bfe-a899-af1f25eb7e10",
              name: "Account available before client authorisation",
              description:
                "Client accounts can receive funds and have transactions queued before client authorisation of their applications is received by the platform",
              order: 5,
              isSelected: false,
            },
            {
              id: "dff4f7d4-5312-4135-85f9-248450a14589",
              name: "Client email address is NOT required",
              description:
                "Clients do not require an email address to open an account",
              order: 6,
              isSelected: false,
            },
            {
              id: "6a75589a-1546-4cde-8340-ef887f6335ca",
              name: "Posted application form accepted",
              description:
                "Platform accepts paper applications and does not have an online-only policy",
              order: 7,
              isSelected: false,
            },
            {
              id: "b46fffb3-acf7-4630-a962-3396996af823",
              name: "Scanned application form accepted via upload/email",
              description:
                "Completed application forms can be scanned and then uploaded or emailed to the platform",
              order: 8,
              isSelected: false,
            },
            {
              id: "1c773123-3dfa-4855-88c5-79f656fb8dbe",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 9,
              isSelected: false,
            },
          ],
          totalFeature: 9,
        },
        {
          id: "7b96e7e5-2f61-4b66-ba3e-0b03b7fb064e",
          name: "Transfers",
          description:
            "Transferring assets between account structures and in-specie transfers",
          order: 2,
          features: [
            {
              id: "008b11dd-5827-4745-9840-54d513fed40e",
              name: "Online process to transition from super to pension",
              description:
                "Transition from a super account to a pension account can be initiated on platform. This is an online process that does not require a new application",
              order: 1,
              isSelected: false,
            },
            {
              id: "4dd61c04-6a40-4d76-bb39-91427e8a0d8c",
              name: "Online process to commute from pension to super",
              description:
                "Commutation from a pension account to a super account can be initiated online on platform. This is an online process that does not require a new application",
              order: 2,
              isSelected: false,
            },
            {
              id: "186fd03f-848f-4ccc-be3a-2160fd36c6af",
              name: "In-specie out part of a holding from platform",
              description:
                "A portion of an investment holding can be in-specied out of the platform without having to transfer the entire holding",
              order: 3,
              isSelected: false,
            },
            {
              id: "a86c492b-3180-4471-9ee3-b1f464f0ccc4",
              name: "In-specie transfer wizard to help collate necessary forms",
              description:
                "Platform provides a guided online experience (wizard) to help with the in-specie transfer process",
              order: 4,
              isSelected: false,
            },
            {
              id: "3fa60125-3836-4b4f-b3cb-b94356bc5067",
              name: "Historical tax parcel data automatically received for in-specie assets",
              description:
                "When an in-specie asset is received, the associated tax parcel data is gathered by the platform without client or adviser data entry",
              order: 5,
              isSelected: false,
            },
            {
              id: "c1578d3d-88a7-4da6-8d7f-69f955629e8c",
              name: "Transfers from UK pension schemes (QROPS)",
              description:
                "The platform accepts and facilitates transfers from eligible UK pension schemes in a tax-efficient manner",
              order: 6,
              isSelected: false,
            },
            {
              id: "85dca1ca-f14b-4b31-b627-24acd9b8bad5",
              name: "Transfers from UK SIPPs",
              description:
                "The platform accepts and facilitates transfers from eligible UK SIPPs in a tax-efficient manner",
              order: 7,
              isSelected: false,
            },
            {
              id: "cefc641d-bb44-4746-beb7-3b2434680eea",
              name: "Transfers from Kiwisaver schemes",
              description:
                "The platform accepts and facilitates transfers from eligible Kiwisaver schemes in a tax-efficient manner",
              order: 8,
              isSelected: false,
            },
            {
              id: "45f41874-5106-45a3-83a0-1199eccf9c9c",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 9,
              isSelected: false,
            },
          ],
          totalFeature: 9,
        },
        {
          id: "107755a0-ba23-485f-a532-b54e59cf691d",
          name: "Straight through processing",
          description:
            "Digital instructions and processing of changes to client accounts",
          order: 3,
          features: [
            {
              id: "962cf384-d316-4faa-8e69-3bb9f61a35a9",
              name: "Digital adviser process to update client contact details",
              description:
                "Client contact details can be updated directly on the platform, without the need for forms or wet signatures. However, the client may need to provide digital authority",
              order: 1,
              isSelected: false,
            },
            {
              id: "32d573f9-d12c-48df-91ff-144c7a21ccca",
              name: "Digital adviser process to update bank account (for platform withdrawals)",
              description:
                "The nominated bank account to receive platform withdrawals can be updated directly on the platform, without the need for forms or wet signatures. However, the client may need to provide digital authority",
              order: 2,
              isSelected: false,
            },
            {
              id: "d9275b1b-df87-4eae-83b9-ad7567bfc6be",
              name: "Digital adviser process to generate Centrelink schedule",
              description: "Centrelink Schedules can be generated online",
              order: 3,
              isSelected: false,
            },
            {
              id: "68dd001c-fbf2-49d0-9864-0611d6002388",
              name: "Digital adviser process to generate Notice of Intent",
              description:
                "Notice of Intent to claim a deduction on a contribution (s290) can be done online",
              order: 4,
              isSelected: false,
            },
            {
              id: "cf27282d-360b-4e3f-83aa-6a360b8c53cb",
              name: "Forms can be started online and completed offline",
              description:
                "Clients/advisers can start filling out platform instructions and forms online, and can choose to print these out as partially filled forms to be complete on paper",
              order: 5,
              isSelected: false,
            },
            {
              id: "4bd0d950-3893-40ad-9624-2a93877ddfdf",
              name: "Scanned forms can be uploaded/emailed for submission",
              description:
                "Completed forms can be uploaded or emailed to the platform, instead of being posted",
              order: 6,
              isSelected: false,
            },
            {
              id: "9fb8ded6-6733-493f-b1ca-90dfe6af3689",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 7,
              isSelected: false,
            },
          ],
          totalFeature: 7,
        },
        {
          id: "120ac89e-c4fc-45ff-851b-4ad8ddea63cc",
          name: "Client consent",
          description: "Methods of managing and accepting client consent",
          order: 4,
          features: [
            {
              id: "937428c2-1d7d-4821-8c55-377e58dd14de",
              name: "Digital authority through client portal",
              description:
                "Clients can provide authority to adviser initiated instructions through their online portal",
              order: 1,
              isSelected: false,
            },
            {
              id: "4e9734a3-aedd-44e8-846f-1fe70bc2d2a5",
              name: "Digital authority through mobile app",
              description:
                "Clients can provide authority to adviser initiated instructions through the platform mobile app",
              order: 2,
              isSelected: false,
            },
            {
              id: "bf825065-ce6c-4252-835b-f918e2c1965b",
              name: "Digital authority covers all adviser instructions",
              description:
                "The platform has no forms or instructions that cannot accept digital authority from clients, other than what may be limited by regulatory requirements",
              order: 3,
              isSelected: false,
            },
            {
              id: "81dbd7b4-4a56-441a-af43-0b73a0496cbc",
              name: "Instructions automatically executed upon consent",
              description:
                "For online instructions, upon receiving digital client authority, the platform automatically and instantly executes the instructions (i.e. no human intervention)",
              order: 4,
              isSelected: false,
            },
            {
              id: "8fb5ede7-b9b4-4e51-895a-b5164bbd10f9",
              name: "Alert to notify client to provide consent",
              description:
                "The platform alerts clients electronically to review and respond to outstanding items requiring their consent",
              order: 5,
              isSelected: false,
            },
            {
              id: "46918b53-9319-47d3-9340-5356e75e1de5",
              name: "Automatic reminders prior to client consent due date",
              description:
                "If client have not actioned the request for their authority, the platform can be set to automatically issue reminders to the client",
              order: 6,
              isSelected: false,
            },
            {
              id: "7b461c54-0248-4e4a-a1d0-c75357ee6293",
              name: "Digital consent without signature (e.g. PIN/biometric)",
              description:
                "Instead of a signature, client authority can be provided digitally through other means such as a biometric verification via the mobile app or the entry of a PIN",
              order: 7,
              isSelected: false,
            },
            {
              id: "2a2c5762-f7ac-4e24-b3b1-93c52c878f52",
              name: "Platform accepts consent through digital signature tools (e.g. DocuSign)",
              description:
                "Digital signatures, such as through DocuSign and Adobe Sign, are accepted in place of wet signatures to capture client authority",
              order: 8,
              isSelected: false,
            },
            {
              id: "1cdf2fc3-45b1-4d47-8e13-5d359cf2fc01",
              name: "Posted consent forms accepted",
              description:
                "Platform accepts wet signatures that are posted in, and does not have an online-only policy",
              order: 9,
              isSelected: false,
            },
            {
              id: "e44f1c4d-a30d-4cd9-a139-08fc8c029275",
              name: "Scanned consent forms accepted",
              description:
                "Forms where a client has provided a wet signature can be scanned and then uploaded or emailed to the platform",
              order: 10,
              isSelected: false,
            },
            {
              id: "600ec119-c74f-4f5e-80bf-eb4ea757e6d3",
              name: "Licensee forms can be used in place of platform consent forms",
              description:
                "Platform accepts pre-approved licensee consent forms in place of the platform's own standard forms",
              order: 11,
              isSelected: false,
            },
            {
              id: "b6bca8cf-7eb9-437b-b508-a16c7631bb00",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 12,
              isSelected: false,
            },
          ],
          totalFeature: 12,
        },
      ],
      iconUrl:
        "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1670817678004Group.svg",
    },
    {
      id: "d39fae80-0948-4a97-96cd-184efa678d2b",
      name: "Portfolio management",
      description: "Facilities for managing client investments and portfolios",
      order: 3,
      totalFeature: 68,
      totalSelectedFeature: 0,
      isSelected: false,
      subSections: [
        {
          id: "b3bb2950-6c1d-4fa8-a572-3596c967d340",
          name: "Investment allocation",
          description: "Setting up and executing buy and sell transactions",
          order: 1,
          features: [
            {
              id: "f5bcaad7-4ea0-42e1-b127-a52fdc90d0bf",
              name: "Investment instruction screen: All investment types available on one screen",
              description:
                "The instructions for transacting on all investment types can be set up on the same screen, including equities, managed funds and managed accounts",
              order: 1,
              isSelected: false,
            },
            {
              id: "a649c4e8-5d72-484c-9257-59d8132b9eb4",
              name: "Investment instruction screen: Can allocate monies as percentages ",
              description:
                "When setting up transactions, allocation to investments can be specified as percentage values",
              order: 2,
              isSelected: false,
            },
            {
              id: "58f3d98c-31d6-4ba2-b162-dbcb6f48b04b",
              name: "Investment instruction screen: Can allocate monies by dollar value",
              description:
                "When setting up transactions, allocation to investments can be specified as dollar values",
              order: 3,
              isSelected: false,
            },
            {
              id: "ab724b4b-af33-463a-9dce-e65001df62b0",
              name: "Investment instruction screen: Real time validation of order inputs",
              description:
                "Order inputs are validated in real-time to ensure they are compliant. e.g. real-time minimum cash or APL (Approved Product List) checks",
              order: 4,
              isSelected: false,
            },
            {
              id: "8055b618-48c9-4c91-9977-69dc95cda098",
              name: "Alerts when creating orders",
              description:
                "When creating orders, alerts support optimal investment decision making e.g. informing the user that a certain investment that is about to be sold with large capital gains has been held for 360 days",
              order: 5,
              isSelected: false,
            },
            {
              id: "069f17c3-4322-412e-b5fc-7dc11a6de1c6",
              name: "Contra-trading - equities",
              description:
                "Platform executes buy instructions for equities at the same time as the sell instructions. The platform begins purchasing assets before sales proceeds are received",
              order: 6,
              isSelected: false,
            },
            {
              id: "f8ef95fb-4769-49fc-8b1e-463641267a66",
              name: "Contra-trading - managed funds",
              description:
                "Platform executes buy instructions for managed funds at the same time as the sell instructions. The platform begins purchasing assets before sales proceeds are received",
              order: 7,
              isSelected: false,
            },
            {
              id: "38e3e787-b592-4b58-9d32-e849837080f6",
              name: "External broker can be chosen on transaction screen",
              description:
                "Listed securities can be traded through a broker that is not the platform's default broker. This broker can be selected during the transaction process",
              order: 8,
              isSelected: false,
            },
            {
              id: "363521a1-d2f8-4f27-aa1b-a6bce7231ebc",
              name: "Transaction authority form generated at the end of order screen",
              description:
                "After transaction instructions are entered, a form can be generated for clients to provide their authority for the transaction to proceed",
              order: 9,
              isSelected: false,
            },
            {
              id: "02d8c8f9-5c8f-4ea1-9a1b-66d3cb1f23f0",
              name: "Transaction authority can be provided digitally by client",
              description:
                "After transaction instructions are entered, a online process can be initiated to collect digital client authority for the transaction to proceed",
              order: 10,
              isSelected: false,
            },
            {
              id: "4d7ec14c-1e13-4be7-9530-e55fd9c12bcd",
              name: "Bulk trading facility for buy/sell/switch",
              description:
                "The same buy/sell/switch transaction can be initiated and executed simultaneously across multiple clients",
              order: 11,
              isSelected: false,
            },
            {
              id: "1225f7ea-7482-4b1f-899e-5014cc095f1b",
              name: "Trades aggregated/netted across all clients",
              description:
                "The platform aggregates orders for a specific security across all clients transacting in that security. Orders are netted before going to market. The reduced brokerage resulting from this netting and aggregation can be shared across the clients",
              order: 12,
              isSelected: false,
            },
            {
              id: "110e6dce-2d6e-4c2c-9316-1d92f9fac56b",
              name: "Trades can be submitted to market in real-time",
              description:
                "Trade orders can be submitted go to market instantly, sidestepping the platform order batching/aggregation process",
              order: 13,
              isSelected: false,
            },
            {
              id: "f791447f-86df-49df-adf1-19aeae1ce744",
              name: "Online corporate action elections",
              description:
                "Advisers can view, manage and participate in relevant corporate actions on the platform. Corporate action elections are provided online",
              order: 14,
              isSelected: false,
            },
            {
              id: "ae42da59-a65b-4c93-81c9-172fd7c7c1c4",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 15,
              isSelected: false,
            },
          ],
          totalFeature: 15,
        },
        {
          id: "12dedf16-28f4-4416-a988-3b6ea84d2b3d",
          name: "Portfolio modelling tools",
          description:
            "Scenario analysis and modelling of current portfolio versus proposed changes",
          order: 2,
          features: [
            {
              id: "efbcd8f4-6af7-4e95-943c-671e5a2ae9ba",
              name: "Asset allocation analysis of portfolio",
              description:
                "A client's current asset allocation can be viewed and compared to their target asset allocation",
              order: 1,
              isSelected: false,
            },
            {
              id: "fccd44ee-1fb3-4e56-9033-60f69a91c550",
              name: "Risk and return analysis of portfolio",
              description:
                "A client's overall portfolio can be assessed in terms of return metrics and risk/volatility measures",
              order: 2,
              isSelected: false,
            },
            {
              id: "a99ebd90-2ff6-4f27-8f04-73cf8e74fb87",
              name: "ESG/impact analysis of portfolio",
              description:
                "A client's overall portfolio can be assessed in terms of ESG impact and/or an ESG rating",
              order: 3,
              isSelected: false,
            },
            {
              id: "58a2604b-0310-4f50-bdf0-14bd496a5423",
              name: "What-if tool: Fee analysis",
              description:
                "Changes to client portfolios can be modelled. This modelling output/graph displays the fee impact of the change (e.g. fund manager fees before and after)",
              order: 4,
              isSelected: false,
            },
            {
              id: "41eafb42-1a44-4844-adad-67bcc7362817",
              name: "What-if tool: Analysis of portfolio metrics",
              description:
                "Changes to client portfolios can be modelled. This modelling output/graph displays the impact on portfolio metrics (risk, return etc.)",
              order: 5,
              isSelected: false,
            },
            {
              id: "2ef74f41-459e-450c-867e-2fa2a7baa56b",
              name: "What-if tool: Asset allocation analysis",
              description:
                "Changes to client portfolios can be modelled. This modelling output/graph displays the impact on portfolio asset allocation",
              order: 6,
              isSelected: false,
            },
            {
              id: "dca0b6fb-9323-418d-86f9-886813d715ed",
              name: "What-if tool: Income analysis",
              description:
                "Changes to client portfolios can be modelled. This modelling output/graph displays the impact on projected income generated by the portfolio",
              order: 7,
              isSelected: false,
            },
            {
              id: "fd1db719-9439-4ecd-9837-64bafe393125",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 8,
              isSelected: false,
            },
          ],
          totalFeature: 8,
        },
        {
          id: "ab01b0fa-eda0-46ee-aa02-6dcbd784ae5e",
          name: "CGT tools",
          description: "Tools to model and manage client CGT outcomes",
          order: 3,
          features: [
            {
              id: "cd02c863-9be7-4feb-970a-c838bcbf62a2",
              name: "CGT calculation method: LIFO",
              description:
                "When transacting, automatic parcel selection can be set to apply the LIFO method",
              order: 1,
              isSelected: false,
            },
            {
              id: "f625db6c-0f7b-48cf-87ac-29df5d03a100",
              name: "CGT calculation method: FIFO",
              description:
                "When transacting, automatic parcel selection can be set to apply the FIFO method",
              order: 2,
              isSelected: false,
            },
            {
              id: "48e4e32b-db1a-41cb-8310-2f66205c4bab",
              name: "CGT calculation method: Min gain",
              description:
                "When transacting, automatic parcel selection can be set to minimise taxable gains",
              order: 3,
              isSelected: false,
            },
            {
              id: "fcbb0001-5c24-45b6-a4e4-44061015dfa9",
              name: "CGT calculation method: Max gain",
              description:
                "When transacting, automatic parcel selection can be set to maximise taxable gains",
              order: 4,
              isSelected: false,
            },
            {
              id: "d36df9c2-46cf-4c50-b3f2-46d54e302a1d",
              name: "Ability to select individual tax parcels",
              description:
                "When transacting, individual parcels for each investment can be manually selected and sold",
              order: 5,
              isSelected: false,
            },
            {
              id: "8528ef3a-dc52-4c03-9a2e-671a175d59b7",
              name: "CGT calculator",
              description:
                "The CGT impact of trades can be modelled. CGT impact information can be seen separate to setting up a trade and/or during the transacting process",
              order: 6,
              isSelected: false,
            },
            {
              id: "6cb58fc0-28f6-45b7-9e26-5a642b759be5",
              name: "Ability to transact after modelling CGT outcomes",
              description:
                "After an investment transaction is modelled in the CGT calculator, it can be saved and executed at a later date, meaning transaction instructions do not need to be re-entered",
              order: 7,
              isSelected: false,
            },
            {
              id: "e9bbaa8e-7ed3-4617-96e1-16d224ac1ddc",
              name: "Tax optimisation tool to identify assets to sell",
              description:
                "In order to achieve a desired CGT outcome for a client, an online tool can help identify which assets are the most optimal to sell to achieve that outcome (e.g. in June client has gains of $10k and you want this to be losses of $10k)",
              order: 8,
              isSelected: false,
            },
            {
              id: "8d85de2a-a363-4686-b164-a6f3fa368d0f",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 9,
              isSelected: false,
            },
          ],
          totalFeature: 9,
        },
        {
          id: "7882fae8-c05b-4ae8-bba0-371300765c5d",
          name: "Model portfolios",
          description:
            "Pre-defined portfolios of investments can be created by the adviser or the practice. This technology-enabled solution helps save time in allocating client investments. It is separate to any SMA/MDA functionality",
          order: 4,
          features: [
            {
              id: "0ad7ff37-cbc6-4a27-80ca-8e04f88e373b",
              name: "Ability to create model portfolios",
              description:
                "Pre-defined portfolios of investments can be created by the adviser or the practice. This technology enabled solution helps save time in allocating client investments. It is separate any SMA/MDA functionality",
              order: 1,
              isSelected: false,
            },
            {
              id: "93cf978a-6829-48a0-9e31-cdb1d73658f0",
              name: "Set investment exclusions for individual clients",
              description:
                "When allocating a client to a model portfolio, the model may contain investments that the client does not want to hold. The platform allows advisers to specify which investments should always be excluded from a client's portfolio",
              order: 2,
              isSelected: false,
            },
            {
              id: "e490a724-f8b8-40ec-8f68-451f3f838023",
              name: "Set investment substitutions for individual clients",
              description:
                "When allocating a client to a model portfolio, the model may contain investments that the client does not want to hold. The platform allows advisers to specify which investments should always be substituted for another (e.g. set a rule to buy RIO instead of BHP for client A)",
              order: 3,
              isSelected: false,
            },
            {
              id: "78559022-a31f-4438-863c-06140a306617",
              name: "Set exclusions based on GICS category",
              description:
                "At the individual client level, specify which categories of investments should never be purchased based on GICS sectors and industries",
              order: 4,
              isSelected: false,
            },
            {
              id: "455ff031-8731-4997-a66c-8c8006194c96",
              name: "Set exclusions based on ESG category",
              description:
                "At the individual client level, specify which categories of investments should never be purchased based on environmental, social and governance standards  e.g. a rule to always exclude tobacco stocks",
              order: 5,
              isSelected: false,
            },
            {
              id: "a0303921-d75c-4a61-ac2e-f6a2c946c643",
              name: "Nested models",
              description:
                "Also known as blended models, this is where multiple model portfolios can be held within a single model portfolio e.g. you may run a growth portfolio and a defensive portfolio, and the 'blended' model could be a made of a combination of these two model portfolios",
              order: 6,
              isSelected: false,
            },
            {
              id: "0685c2cf-78c1-400d-9110-41d9b26a91c9",
              name: "Bulk model portfolio rebalance (ad-hoc)",
              description:
                "The portfolios of all clients allocated to a model portfolio can be rebalanced simultaneously on an ad-hoc basis (i.e. on demand)",
              order: 7,
              isSelected: false,
            },
            {
              id: "463360a3-9234-4cb2-96ce-457891bafd67",
              name: "Bulk model portfolio rebalance (periodic)",
              description:
                "The portfolios of all clients allocated to a model portfolio can be set to rebalance automatically at certain time intervals",
              order: 8,
              isSelected: false,
            },
            {
              id: "93f54541-916e-48c1-a9ff-46df3f1513ca",
              name: "Automatic rebalance based on tolerance limits",
              description:
                "Model portfolio rebalance can be set to trigger automatically when a portfolio drifts a certain amount from the target allocation",
              order: 9,
              isSelected: false,
            },
            {
              id: "2e82dfa1-8e0b-4016-99c4-05bfbc5a1c07",
              name: "Allocation drift alerts",
              description:
                "Model portfolios that drift a certain amount from the target allocation trigger a platform alert to the adviser",
              order: 10,
              isSelected: false,
            },
            {
              id: "e0c23bfe-4a71-48e9-a6d8-44af6adfa5be",
              name: "Rebalance exclusions",
              description:
                "Tolerance limits can be set on client portfolios to exclude them from transactions during a bulk rebalance process. e.g. do not place any trades less than $250",
              order: 11,
              isSelected: false,
            },
            {
              id: "ef43c27e-ac64-4af3-803b-4c0e7052a2fe",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 12,
              isSelected: false,
            },
          ],
          totalFeature: 12,
        },
        {
          id: "456216a9-c338-4ebe-b02a-2dfa2d45f8a7",
          name: "Managed account management",
          description:
            "Functionality associated with managing and tailoring the portfolios of clients invested in managed accounts (SMAs or MDAs)",
          order: 5,
          features: [
            {
              id: "ce210fc5-f975-404c-a63f-f0e1574c27b6",
              name: "Set investment exclusions for individual clients",
              description:
                "At the individual client level, advisers can specify shares or funds to never be purchased/held as part of a client's managed account allocation",
              order: 1,
              isSelected: false,
            },
            {
              id: "475eac93-48e7-4eb8-a1b4-36bc988e0779",
              name: "Set investment substitutions for individual clients",
              description:
                "At the individual client level, advisers can specify shares or funds to never be purchased/held as part of a client's managed account allocation. These investments can be set to be substituted for another (e.g. set a rule to buy RIO instead of BHP for client A)",
              order: 2,
              isSelected: false,
            },
            {
              id: "1a38611e-6ed5-4bad-a889-2830796c3961",
              name: "Set exclusions based on GICS category",
              description:
                "At the individual client level, specify which categories of investments should never be held in their managed account allocation based on GICS sectors and industries",
              order: 3,
              isSelected: false,
            },
            {
              id: "1cf77f24-8f57-478d-85e7-836906d80ad6",
              name: "Set exclusions based on ESG category",
              description:
                "At the individual client level, specify which categories of investments should never be held in their managed account allocation based on environmental, social and governance standards  e.g. a rule to always exclude tobacco stocks",
              order: 4,
              isSelected: false,
            },
            {
              id: "82c5061f-e082-4ef2-b018-f72d57fd49f8",
              name: "Set specific investments to not receive new client funds",
              description:
                "Advisers can specify which investments inside a model should no longer receive any new client investments, but still be retained in the model for existing investors",
              order: 5,
              isSelected: false,
            },
            {
              id: "0ab22fc9-7588-47af-823f-4a02cc9a5089",
              name: "Transfer investments into managed account models",
              description:
                "Investments held outside managed account models can be transferred into managed accounts models",
              order: 6,
              isSelected: false,
            },
            {
              id: "65aea5a8-b291-4227-b22e-44b0b0444570",
              name: "Transfer investment out of managed accounts models",
              description:
                "Investments held inside managed account models can be transferred out as individual holdings",
              order: 7,
              isSelected: false,
            },
            {
              id: "ab20e94c-ae90-483b-b708-d1e4a6cd6e70",
              name: "Tax optimised managed account switching",
              description:
                "When switching between two managed accounts, trades are netted if the same security or fund exists in both models. Advisers can model the CGT impact of switching to different managed account models",
              order: 8,
              isSelected: false,
            },
            {
              id: "3da61257-c456-41c8-a407-f2350bb91761",
              name: "Floating models",
              description:
                "Over time, client holdings in models will drift from the original allocation that was set by the manager. Floating models allows new clients to invest in the same allocation as all the other clients already in the model, as opposed to investing in the original allocation",
              order: 9,
              isSelected: false,
            },
            {
              id: "deb34335-0e16-4009-9a3c-f934bd29f2f1",
              name: "Portal for advisers/practice to run managed accounts",
              description:
                "Advisers and practices can manage and run their own managed accounts online through the platform",
              order: 10,
              isSelected: false,
            },
            {
              id: "4735abe9-d365-4740-90ef-efca951ff61c",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 11,
              isSelected: false,
            },
          ],
          totalFeature: 11,
        },
        {
          id: "7617fe9f-4f71-4cd4-99a1-e52cdfc186c7",
          name: "Regular transaction facilities",
          description: "Flexibility of regular buy and sell facilities",
          order: 6,
          features: [
            {
              id: "7f5fd6f6-93de-497b-86bd-7e09b65085e6",
              name: "Quarterly regular investments",
              description:
                "Set client cash to automatically invest into specified investments every quarter e.g. every quarter, $5k from the cash account is invested",
              order: 1,
              isSelected: false,
            },
            {
              id: "659fc350-4c39-4bf9-8676-4e810c91e912",
              name: "Monthly regular investments",
              description:
                "Set client cash to automatically invest into specified investments every month e.g. every month, $5k from the cash account is invested",
              order: 2,
              isSelected: false,
            },
            {
              id: "157ee521-6f60-47b8-aa38-ffc1fe05e686",
              name: "Fortnightly regular investments",
              description:
                "Set client cash to automatically invest into specified investments every fortnight e.g. every fortnight, $5k from the cash account is invested",
              order: 3,
              isSelected: false,
            },
            {
              id: "98eaeb9f-64ed-46cf-b300-ad71cb7a691d",
              name: "Weekly regular investments",
              description:
                "Set client cash to automatically invest into specified investments every week e.g. every week, $5k from the cash account is invested",
              order: 4,
              isSelected: false,
            },
            {
              id: "09d6e1f4-3ee1-42be-a618-926e7bd41b95",
              name: "Automatic investment of new deposits",
              description:
                "Set client cash to automatically invest into specified investments when deposited into platform cash account",
              order: 5,
              isSelected: false,
            },
            {
              id: "b74ce1db-5102-4334-a7b6-1527a4d4d63d",
              name: "Quarterly regular selldowns",
              description:
                "Set a certain amount of a client's portfolio to automatically sell every quarter",
              order: 6,
              isSelected: false,
            },
            {
              id: "41487afe-fad5-48d3-994d-b01215e943ad",
              name: "Monthly regular selldowns",
              description:
                "Set a certain amount of a client's portfolio to automatically sell every month",
              order: 7,
              isSelected: false,
            },
            {
              id: "eb930793-5cfc-4232-85ac-4cdf8ac68f0f",
              name: "Fortnightly regular selldowns",
              description:
                "Set a certain amount of a client's portfolio to automatically sell every fortnight",
              order: 8,
              isSelected: false,
            },
            {
              id: "4e915b84-12e6-457e-bcfb-73bd519aab77",
              name: "Weekly regular selldowns",
              description:
                "Set a certain amount of a client's portfolio to automatically sell every week",
              order: 9,
              isSelected: false,
            },
            {
              id: "b6986971-fa55-458e-a876-a035b98448c5",
              name: "Regular selldown: pro-rata across portfolio",
              description:
                "During a regular selldown, investments can be set to be sold down proportionally (e.g. in line with their current weight in the portfolio)",
              order: 10,
              isSelected: false,
            },
            {
              id: "12faa550-2424-4ffa-8beb-8aa7c40d16fa",
              name: "Regular selldown: specify order to sell",
              description:
                "During a regular selldown, investments can be sold down in a pre-specified order. (e.g. only sell down Asset A first. Once all of Asset A is all sold, only sell down Asset B, and so on)",
              order: 11,
              isSelected: false,
            },
            {
              id: "6f24a316-d3d2-498d-a1bc-df0c591e6256",
              name: "Regular selldown: choose investments to exclude",
              description:
                "During a regular selldown, specific investments can be set to be excluded from a pro-rata selldown process",
              order: 12,
              isSelected: false,
            },
            {
              id: "4ce68c90-218c-493a-aea1-ada90d9f50ac",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 13,
              isSelected: false,
            },
          ],
          totalFeature: 13,
        },
      ],
      iconUrl:
        "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1670817678004Group.svg",
    },
    {
      id: "697c188d-ccca-43db-ab82-eac3aed6d68e",
      name: "Cashflow management",
      description:
        "Flexibility and automation of managing the inflow and outflow of client cash",
      order: 4,
      totalFeature: 49,
      totalSelectedFeature: 0,
      isSelected: false,
      subSections: [
        {
          id: "e339968e-3f0d-4b0a-a55d-f8375cc82a53",
          name: "Cash account liquidity",
          description: "Tools to automatically manage client cash balances",
          order: 1,
          features: [
            {
              id: "3e825e1c-5f48-4699-9217-38260f44e9bf",
              name: "Set minimum cash balance",
              description:
                "A minimum cash balance level can be set for clients above the minimum required by the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "4dba2d68-bcee-4a47-bffd-dfd27fdd95dc",
              name: "Set maximum cash balance",
              description:
                "A maximum cash balance level can be set for clients",
              order: 2,
              isSelected: false,
            },
            {
              id: "c24e1454-b638-4a66-aacf-c56f229baf49",
              name: "Automatically sell assets when cash falls below specified minimum",
              description:
                "When the cash balance falls below the specified minimum, client assets are automatically sold to bring cash above the specified minimum",
              order: 3,
              isSelected: false,
            },
            {
              id: "2f8b2472-623f-4237-b023-4761c629cfe0",
              name: "Automatically invest excess cash",
              description:
                "When the cash balance exceeds a specified limit, the excess cash is automatically invested into the pre-specified portfolio",
              order: 4,
              isSelected: false,
            },
            {
              id: "8651bc75-4160-4fc6-8928-e6ece73c50cb",
              name: "Automatically withdraw excess cash",
              description:
                "When the cash balance exceeds a specified limit, the excess cash is automatically withdrawn into the client's nominated bank account",
              order: 5,
              isSelected: false,
            },
            {
              id: "8bce99a0-139a-459b-80b3-baac705f1fdb",
              name: "Automatic selldown: pro-rata across portfolio",
              description:
                "During an automatic selldown, investments can be set to be sold down proportionally (e.g. in line with their current weight in the portfolio)",
              order: 6,
              isSelected: false,
            },
            {
              id: "b37f2922-5f95-407d-bf0e-614c5b932c2c",
              name: "Automatic selldown: specify order to sell",
              description:
                "During an automatic selldown, investments can be sold down in a pre-specified order. (e.g. only sell down Asset A first. Once all of Asset A is sold, only sell down Asset B, and so on)",
              order: 7,
              isSelected: false,
            },
            {
              id: "6862763f-1586-4909-bd10-37ebf7fece98",
              name: "Automatic selldown: choose investments to exclude",
              description:
                "During an automatic selldown, specific assets can be set to be excluded from a pro-rata selldown process",
              order: 8,
              isSelected: false,
            },
            {
              id: "6196c162-98cd-4e82-94e9-83dc1580affc",
              name: "Accounts can fall into negative balance",
              description:
                "Clients can overdraw on the platform cash account and temporarily fall into a negative cash balance",
              order: 9,
              isSelected: false,
            },
            {
              id: "7dcc5380-e616-4cd6-9df3-4be5114887b4",
              name: "Cash balance projections",
              description:
                "Platform shows projections of client cash balances incorporating expected future cash inflows and outflows e.g. regular contributions, pension payments",
              order: 10,
              isSelected: false,
            },
            {
              id: "421e2194-618b-42ac-bf26-5be7c6153876",
              name: "Proactive alerts for breaches of cash balance rules",
              description:
                "Platform alerts are sent when the cash balance is projected to fall below the minimum (i.e. before the event itself)",
              order: 11,
              isSelected: false,
            },
            {
              id: "edc04a9c-6cdf-4911-a50b-0fe145c12838",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 12,
              isSelected: false,
            },
          ],
          totalFeature: 12,
        },
        {
          id: "b3668bc9-805d-488b-ac81-4a614252c14b",
          name: "Deposits and contributions",
          description:
            "Methods and automation of cash deposits and super contributions",
          order: 2,
          features: [
            {
              id: "6402ace8-f573-4f9c-a0bd-c407d76c2f66",
              name: "Quarterly regular deposits",
              description:
                "Set platform to take money out of the client's bank account every quarter",
              order: 1,
              isSelected: false,
            },
            {
              id: "4e492bb3-23ef-4143-a1b2-50d7cf663216",
              name: "Monthly regular deposits",
              description:
                "Set platform to take money out of the client's bank account every month",
              order: 2,
              isSelected: false,
            },
            {
              id: "6ce5da71-b2b8-40ed-8e87-2525fe7d4ea3",
              name: "Fortnightly regular deposits",
              description:
                "Set platform to take money out of the client's bank account every fortnight",
              order: 3,
              isSelected: false,
            },
            {
              id: "fb1727f3-5a27-41ce-bf20-e8b926373620",
              name: "Weekly regular deposits",
              description:
                "Set platform to take money out of the client's bank account every week",
              order: 4,
              isSelected: false,
            },
            {
              id: "4e1b2db9-0986-4111-bb35-3ac8fa337118",
              name: "Set day of month/week for regular deposits",
              description:
                "The day on which the platform takes money from the client's bank account can be specified",
              order: 5,
              isSelected: false,
            },
            {
              id: "fcf7db2e-a424-49c1-834b-2b0dcc07d9da",
              name: "Set automatic indexation on deposits",
              description:
                "The value of regular deposits can be set to move in line with CPI (inflation) or change at an indexed rate set by the adviser",
              order: 6,
              isSelected: false,
            },
            {
              id: "3954110b-7ccc-4d1c-992f-62d7f07cc8ef",
              name: "Deposits via BPAY",
              description:
                "Deposits into the platform cash account can be made via BPAY",
              order: 7,
              isSelected: false,
            },
            {
              id: "a93b1337-acca-4ca4-a43b-a2358a3ec291",
              name: "Deposits via PayID",
              description:
                "Deposits into the platform cash account can be made via PayID",
              order: 8,
              isSelected: false,
            },
            {
              id: "f94c83b0-ec0c-42ca-a083-4d9ed2b00616",
              name: "Deposits via cheque",
              description:
                "Deposits into the platform cash account can be made via cheque",
              order: 9,
              isSelected: false,
            },
            {
              id: "af023b74-22e8-41eb-ab93-c203586cb5fd",
              name: "Real time clearing of funds",
              description:
                "Funds withdrawn from and deposited into the platform cash account are instantly cleared and available at the destination account",
              order: 10,
              isSelected: false,
            },
            {
              id: "690a7c07-bed5-4496-b02f-12cd1cc3d07e",
              name: "Multiple bank accounts can be linked to client account",
              description:
                "Clients can nominate multiple bank accounts to be linked to their platform account",
              order: 11,
              isSelected: false,
            },
            {
              id: "ea45b01e-fabb-4315-9a10-c1aef33897a4",
              name: "Digital client authority for new bank accounts",
              description:
                "If an adviser tries to link a new bank account for withdrawals, the client can authorise this via digital methods e.g. logging into the platform, accepting via platform app",
              order: 12,
              isSelected: false,
            },
            {
              id: "55c59fd1-366c-49df-a1e3-5ba8da845518",
              name: "Contribution splitting",
              description:
                "Contribution splitting allows concessional super contributions (before tax) to be allocated to the spouse's super account",
              order: 13,
              isSelected: false,
            },
            {
              id: "20ee7ada-3247-46b8-b230-e5f79f5f5e23",
              name: "Tracking of super contributions",
              description:
                "Super contributions for the financial year can be viewed directly on the platform (i.e. not require a report)",
              order: 14,
              isSelected: false,
            },
            {
              id: "1f1ac0f1-9f07-461b-b5af-7f885023a1fe",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 15,
              isSelected: false,
            },
          ],
          totalFeature: 15,
        },
        {
          id: "c382be76-2b10-4065-a29b-2c39e20742cd",
          name: "Withdrawals",
          description: "Methods and automation of cash withdrawals",
          order: 3,
          features: [
            {
              id: "7994233e-526e-44e8-9bb3-4e1f2c3491d1",
              name: "Quarterly regular withdrawals",
              description:
                "Set platform to deposit money into the client's bank account every quarter",
              order: 1,
              isSelected: false,
            },
            {
              id: "0789f6f2-ba0e-40ef-8344-57ed06c2aa1a",
              name: "Monthly regular withdrawals",
              description:
                "Set platform to deposit money into the client's bank account every month",
              order: 2,
              isSelected: false,
            },
            {
              id: "a850b399-95d2-46e4-acde-ba0e6fb1885b",
              name: "Fortnightly regular withdrawals",
              description:
                "Set platform to deposit money into the client's bank account every fortnight",
              order: 3,
              isSelected: false,
            },
            {
              id: "b6b3b324-87e2-4721-9ae5-d26530f40f57",
              name: "Weekly regular withdrawals",
              description:
                "Set platform to deposit money into the client's bank account every week",
              order: 4,
              isSelected: false,
            },
            {
              id: "defb9e97-954d-4577-af0c-bd03f01d1da1",
              name: "Set day of month/week for regular withdrawals",
              description:
                "The day on which funds are regularly withdrawn from the platform can be specified",
              order: 5,
              isSelected: false,
            },
            {
              id: "9e4e8fd2-6b5d-40dc-ac83-481008a3d0b4",
              name: "Set automatic indexation on withdrawals",
              description:
                "The value of regular withdrawals can be set to move in line with CPI (inflation) or change at an indexed rate set by the adviser",
              order: 6,
              isSelected: false,
            },
            {
              id: "d26ee2de-d190-4a6d-a25a-a524558b3656",
              name: "BPAY payments out of the platform",
              description: "BPAY payments can be made from the platform",
              order: 7,
              isSelected: false,
            },
            {
              id: "746fc206-e54d-4c0c-82ba-e6cda3453446",
              name: "Withdrawals can be lodged as pending when cash is insufficient",
              description:
                "Fund withdrawals from the platform can be set up even when there is not sufficient cash in the platform cash account. The withdrawal will be processed automatically once there are sufficient funds in the cash account",
              order: 8,
              isSelected: false,
            },
            {
              id: "ba1f0239-725d-40de-93a1-72eafa7a6fd6",
              name: "Ad-hoc withdrawals can be submitted online",
              description:
                "Instructions for ad-hoc withdrawals, such as lump sum withdrawals, can be submitted online",
              order: 9,
              isSelected: false,
            },
            {
              id: "8c65a5fa-98d7-4e77-bfc1-3204c14fb9fa",
              name: "Digital client authority for withdrawals",
              description:
                "Clients can authorise adviser-initiated withdrawals via digital methods e.g. logging into the platform, accepting via platform app",
              order: 10,
              isSelected: false,
            },
            {
              id: "44131cc6-de26-4c39-96b2-85006e33c07c",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 11,
              isSelected: false,
            },
          ],
          totalFeature: 11,
        },
        {
          id: "ce3aca6b-6290-4ccc-96b2-80721e6980fb",
          name: "Pensions",
          description: "Methods and automation of pension payments",
          order: 4,
          features: [
            {
              id: "46a01c95-a73b-416c-845b-0f56aa3b4016",
              name: "Quarterly regular pension payments",
              description:
                "Set platform to deposit pension payments into the client's bank account every quarter",
              order: 1,
              isSelected: false,
            },
            {
              id: "5b3ca518-b5f1-41ec-940e-e18dfe9c51a0",
              name: "Monthly regular pension payments",
              description:
                "Set platform to deposit pension payments into the client's bank account every month",
              order: 2,
              isSelected: false,
            },
            {
              id: "dfc79c38-a0e1-4ad0-a99e-d807732a72d7",
              name: "Fortnightly regular pension payments",
              description:
                "Set platform to deposit pension payments into the client's bank account every fortnight",
              order: 3,
              isSelected: false,
            },
            {
              id: "23d1eee1-2b7b-40d5-bd7d-ed8993a2f354",
              name: "Weekly regular pension payments",
              description:
                "Set platform to deposit pension payments into the client's bank account every week",
              order: 4,
              isSelected: false,
            },
            {
              id: "cb7d6e4c-9904-4a5b-9a85-298c255864e8",
              name: "Set day of month/week for regular pension payments",
              description:
                "The day on which pension payments are paid out from the platform can be specified",
              order: 5,
              isSelected: false,
            },
            {
              id: "fb7ce689-081b-4c77-a934-91fcbefe5d94",
              name: "Set automatic indexation on pensions",
              description:
                "The value of pension payments can be set to move in line with CPI (inflation) or change at an indexed rate set by the adviser",
              order: 6,
              isSelected: false,
            },
            {
              id: "13e3f4bf-cf78-41d3-b3c3-109ffc176d35",
              name: "Ad-hoc pension payments can be submitted online",
              description:
                "Instructions for ad-hoc pension payments can be submitted online",
              order: 7,
              isSelected: false,
            },
            {
              id: "03273645-73e9-4e56-9002-7bab2cf7e4f7",
              name: "Set pension payments as income generated from investments",
              description:
                "Clients' pension payments can be set to the natural income earned from their investments (dividends, fund distributions, interest on TDs and so on)",
              order: 8,
              isSelected: false,
            },
            {
              id: "a6c1ffa5-d0dc-41a2-8c68-e3cf6f0ab5f1",
              name: "Set pension payments to minimum level allowed",
              description:
                "Pension payments can be set to automatically pay out the minimum level permitted by regulation",
              order: 9,
              isSelected: false,
            },
            {
              id: "c7720d33-3318-4076-8c76-650a3542ebf3",
              name: "Automated implementation of bucketing strategy",
              description:
                "The platform allows advisers to simplify and automate the implementation of a bucketing strategy within an account. This may include giving advisers control over which assets are liquid and for the purpose of funding pension payments, and which are longer term holdings that are not subject to a regular selldown facility",
              order: 10,
              isSelected: false,
            },
            {
              id: "afa52ed8-b081-4cbc-b82a-324dece55f04",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 11,
              isSelected: false,
            },
          ],
          totalFeature: 11,
        },
      ],
      iconUrl:
        "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1670817678004Group.svg",
    },
    {
      id: "9624cc9b-9ab1-4bf7-b8fe-61ad09a3462d",
      name: "Decision support",
      description: "Tools for researching investments and modelling strategy",
      order: 5,
      totalFeature: 32,
      totalSelectedFeature: 0,
      isSelected: false,
      subSections: [
        {
          id: "33763185-9dcf-46c2-ae12-cd508192a637",
          name: "Comparison tools",
          description:
            "Tools to analyse the difference between investment options",
          order: 1,
          features: [
            {
              id: "d47fb566-270a-403e-a865-a1e34df336ef",
              name: "Compare managed funds: Performance & fees",
              description:
                "Platform provides a managed fund comparison table, which includes historical performance and fee data for each fund",
              order: 1,
              isSelected: false,
            },
            {
              id: "30110749-59d6-44a1-bd17-400d2c3896f7",
              name: "Compare managed funds: Ratings",
              description:
                "Platform provides a managed fund comparison table, which includes Research House ratings for each fund",
              order: 2,
              isSelected: false,
            },
            {
              id: "65e91301-e267-452b-beb5-1906c878956a",
              name: "Compare managed funds: ESG impact",
              description:
                "Platform provides a managed fund comparison table, with ESG data on each fund",
              order: 3,
              isSelected: false,
            },
            {
              id: "b0221668-eaad-4bc3-88c6-f7c314fef2a6",
              name: "Compare managed accounts: Performance & fees",
              description:
                "Platform provides a managed accounts comparison table, which includes historical performance and fee data for each model",
              order: 4,
              isSelected: false,
            },
            {
              id: "3ebc4e92-ff06-4ccd-a05c-f6a72fb3de2e",
              name: "Compare managed accounts: Ratings",
              description:
                "Platform provides a managed accounts comparison table, which includes Research House ratings for each model",
              order: 5,
              isSelected: false,
            },
            {
              id: "e6ac521c-0961-404f-bf7b-e413786d4ada",
              name: "Compare managed accounts: ESG impact",
              description:
                "Platform provides a managed accounts comparison table, which ESG data on each model",
              order: 6,
              isSelected: false,
            },
            {
              id: "f9727390-415e-47e6-9355-2d102e295f1e",
              name: "Comparison tool for listed securities",
              description:
                "Platform provides a listed security comparison table, which includes historical performance and analyst ratings for each share",
              order: 7,
              isSelected: false,
            },
            {
              id: "16793679-131e-4e4a-9c22-4b3b0dbe52ef",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 8,
              isSelected: false,
            },
          ],
          totalFeature: 8,
        },
        {
          id: "80754f38-a937-4bdf-ba8e-6295672dadad",
          name: "Research & news",
          description:
            "Types of research and news available to support investment decisions",
          order: 2,
          features: [
            {
              id: "a5587c60-00c2-4964-a772-35bc3b523057",
              name: "Stock research from stockbrokers",
              description:
                "Equities research from stockbrokers is available through the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "fbf5ba46-3b1f-40ff-86b2-b9ab0c86e151",
              name: "Platform hosts research from licensees",
              description:
                "Fund and equities research from licensees is available through the platform",
              order: 2,
              isSelected: false,
            },
            {
              id: "783b38a5-16b7-46c3-b15f-7e9aec3e4fe4",
              name: "Fund profiles",
              description:
                "The fund profiles from Research Houses are available for each managed fund",
              order: 3,
              isSelected: false,
            },
            {
              id: "76f35b55-cc7f-4aff-8208-dd8a1fd25499",
              name: "Access to market news",
              description: "Platform provides market news",
              order: 4,
              isSelected: false,
            },
            {
              id: "f23e769f-77a1-493b-aaca-a8ac272ee63f",
              name: "Access to portfolio-specific news",
              description:
                "Platform can filter market news by what's held in client portfolios",
              order: 5,
              isSelected: false,
            },
            {
              id: "7b9bd1d0-a717-4483-8b74-6efc5f630128",
              name: "Access to investment manager commentary",
              description:
                "Investment managers are able to publish their commentary on the platform (e.g. reasons behind recent portfolio changes)",
              order: 6,
              isSelected: false,
            },
            {
              id: "00836562-1b05-47ee-8818-027b04c4f03f",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 7,
              isSelected: false,
            },
          ],
          totalFeature: 7,
        },
        {
          id: "cf08362a-433c-4370-9802-5529a6039ee3",
          name: "Research providers",
          description: "Integrated investment research providers",
          order: 3,
          features: [
            {
              id: "1c5d05be-23ff-4712-ada9-27bc495675f2",
              name: "FE Analytics",
              description: "FE Analytics research is available on the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "04c0ac22-6bd9-4279-9de4-1107d57dab33",
              name: "Lonsec",
              description: "Lonsec research is available on the platform",
              order: 2,
              isSelected: false,
            },
            {
              id: "33384555-f543-4008-b05a-f25c04ec8e44",
              name: "Mercer",
              description: "Mercer research is available on the platform",
              order: 3,
              isSelected: false,
            },
            {
              id: "2a61c4bd-9b6a-44cd-aaca-53eac80501e9",
              name: "Morningstar",
              description: "Morningstar research is available on the platform",
              order: 4,
              isSelected: false,
            },
            {
              id: "c7866159-9bd8-414c-b7e3-e4c65b907ce5",
              name: "Sustainalytics",
              description:
                "Sustainalytics research is available on the platform",
              order: 5,
              isSelected: false,
            },
            {
              id: "61eea3b6-bcd5-4025-ad2b-bcfa0a948ed4",
              name: "Zenith",
              description: "Zenith research is available on the platform",
              order: 6,
              isSelected: false,
            },
            {
              id: "8952bbc9-be02-4c63-b316-150f8a4304c3",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 7,
              isSelected: false,
            },
          ],
          totalFeature: 7,
        },
        {
          id: "a1832580-d545-4136-af1b-af4d83b0abb5",
          name: "Planning tools",
          description: "Portfolio projection and modelling tools ",
          order: 4,
          features: [
            {
              id: "95440c25-9153-4f10-bb6b-aacfd091fee3",
              name: "Cashflow modelling for accumulation",
              description:
                "Cash projection tool to help understand a client's future cash position, taking into account any expected contributions, withdrawals and investment income",
              order: 1,
              isSelected: false,
            },
            {
              id: "b5726052-2d4b-411b-a9b6-891d4e5ca1ec",
              name: "Cashflow modelling for pension phase",
              description:
                "Cash projection tool to help understand how much income clients can expect to receive from their investments in the period ahead",
              order: 2,
              isSelected: false,
            },
            {
              id: "d754654e-1ccb-48fe-9935-9bd9d34d89c1",
              name: "Super balance projection tool",
              description:
                "The platform provides a projected super balance at retirement for clients. This may include stochastic modelling that shows the probability of achieving said balance",
              order: 3,
              isSelected: false,
            },
            {
              id: "acaa5536-cf03-45b5-a2fe-62c0bd37e55b",
              name: "Retirement modelling tool",
              description:
                "Retirement modelling to illustrate impact of different drawdown strategies on the account balance, including the impact of lump-sum withdrawals or one-off market events",
              order: 4,
              isSelected: false,
            },
            {
              id: "8422bbea-5948-4a78-a648-6554f7ec96ad",
              name: "Insurance needs calculator",
              description:
                "Calculator to help determine how much insurance cover is required for a client based on their current situation",
              order: 5,
              isSelected: false,
            },
            {
              id: "6183d407-5a90-4482-ba4a-74ec68126b6c",
              name: "Modelling tools pre-populated with client data",
              description:
                "Inputs to the modelling tools are automatically pre-populated with client data that is held on platform",
              order: 6,
              isSelected: false,
            },
            {
              id: "dac2c83f-75df-48f1-9f0a-26dd4afdfa15",
              name: "Ability to export modelling results into ROA/SOA",
              description:
                "Results from modelling tools can be exported as artefacts to be incorporated into ROAs/SOAs generated on and/or off platform",
              order: 7,
              isSelected: false,
            },
            {
              id: "02fb97e8-2a41-464b-868f-1e9863262f1f",
              name: "Ability to choose external modelling tools to integrate",
              description:
                "Advisers can choose third-party modelling tools to embed into the platform. These tools are not built by the platform provider, but can be used on the platform",
              order: 8,
              isSelected: false,
            },
            {
              id: "a01550b3-f71f-4bee-b02e-85607f9b81f1",
              name: "Automated advice delivery tool",
              description:
                "Platform automates the identification of client opportunities, advice document generation, and strategy execution (e.g. robo-advice tool)",
              order: 9,
              isSelected: false,
            },
            {
              id: "0c80dc8e-cfef-4aed-88fe-975866276d09",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 10,
              isSelected: false,
            },
          ],
          totalFeature: 10,
        },
      ],
      iconUrl:
        "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1670817678004Group.svg",
    },
    {
      id: "ce045580-53cb-4ebe-a0d2-f93adced1f70",
      name: "Tracking",
      description:
        "Oversight provided over workflow items and the full client base",
      order: 6,
      totalFeature: 30,
      totalSelectedFeature: 0,
      isSelected: false,
      subSections: [
        {
          id: "9f9cf2c4-04cd-4901-87d7-2ae953e535c4",
          name: "Workflow and alerts",
          description:
            "Tracking the status of outstanding platform tasks and managing alerts",
          order: 1,
          features: [
            {
              id: "7d8cdc65-f725-4ad8-994c-03a31749dca2",
              name: "Workflow tracker",
              description:
                "Outstanding processes on the platform can be tracked through a single page/screen. E.g. the status of an account application",
              order: 1,
              isSelected: false,
            },
            {
              id: "7ee9d137-ce99-4dcc-b10b-3c74f36b95d7",
              name: "View of upcoming events & actions",
              description:
                "View of upcoming workflow items that require action, such as corporate action elections and term deposit maturities",
              order: 2,
              isSelected: false,
            },
            {
              id: "3270ef2e-5aca-4520-ab9c-6075e42d5064",
              name: "Track: Account level requests",
              description:
                "Workflow tracker shows status of account level changes e.g. opening of new client accounts, super to pension transfer",
              order: 3,
              isSelected: false,
            },
            {
              id: "ce535970-d241-4ef3-a58e-37904e1fed2d",
              name: "Track: Transactions",
              description:
                "Workflow tracker shows status of transactions e.g. trade instructions",
              order: 4,
              isSelected: false,
            },
            {
              id: "edac941e-b61d-4287-8c0e-440f3ddc0dd5",
              name: "Track: In-specie transfers",
              description:
                "Workflow tracker shows status of in-specie transfers into and out of the platform",
              order: 5,
              isSelected: false,
            },
            {
              id: "6a10ef11-970f-43bd-b63d-f7246e2388eb",
              name: "Track: Corporate actions",
              description:
                "Workflow tracker shows status of corporate action elections",
              order: 6,
              isSelected: false,
            },
            {
              id: "b487cf12-9add-4457-bb8a-45feecf6b634",
              name: "Track: ROA consent through platform",
              description:
                "Workflow tracker shows client consent status of Records of Advice (ROAs) generated through the platform",
              order: 7,
              isSelected: false,
            },
            {
              id: "ac63f181-1815-419f-ad7a-4ded20f01d3c",
              name: "Track: Status of tax statements",
              description:
                "Workflow tracker shows status of EOFY tax statements, including their expected delivery date",
              order: 8,
              isSelected: false,
            },
            {
              id: "f7c8aa23-2fae-462a-a865-2cb3c08e0904",
              name: "Track: Client initiated actions",
              description:
                "Workflow tracker shows status of instructions initiated by clients e.g. client initiated withdrawals",
              order: 9,
              isSelected: false,
            },
            {
              id: "18a0bb56-973d-4f8e-a73b-b324c47fc87a",
              name: "Communicate with platform admin team through workflow dashboard",
              description:
                "The adviser office can liaise with the platform admin team directly through workflow items",
              order: 10,
              isSelected: false,
            },
            {
              id: "042c2655-3965-4c27-8c25-5688abb65c5e",
              name: "Approve/reject transactions via workflow dashboard",
              description:
                "Those with the right level of authority can approve/reject client transactions that have been set up by others (e.g. adviser reviewing a transaction set up by a Client Services Officer)",
              order: 11,
              isSelected: false,
            },
            {
              id: "d15a3692-4c89-42cb-81b9-f4a5141346a7",
              name: "Ability to attach documents to workflow process",
              description:
                "Documents required to complete a workflow process can be uploaded via the workflow dashboard",
              order: 12,
              isSelected: false,
            },
            {
              id: "8d4bb01f-3a58-472c-a9ee-9af6c533d83f",
              name: "Select the events that generate alerts",
              description:
                "Advisers can toggle which alerts they receive from the platform",
              order: 13,
              isSelected: false,
            },
            {
              id: "e54b3590-f7e3-4d06-8fd5-12f34f09feb2",
              name: "Set importance level for event alerts",
              description:
                "Advisers can customise the importance level of individual alerts they receive",
              order: 14,
              isSelected: false,
            },
            {
              id: "06c7983e-3f56-4891-9d33-490543ae2e67",
              name: "Customise alert frequency",
              description:
                "Advisers can customise the frequency at which they receive certain alerts",
              order: 15,
              isSelected: false,
            },
            {
              id: "fd4e7873-6bd0-451c-8548-564318f89751",
              name: "Regular summary of alerts",
              description:
                "Alerts can be grouped together and sent as a single summary, rather than as many individual alerts",
              order: 16,
              isSelected: false,
            },
            {
              id: "5d0c0992-9a4f-4768-abcc-48710885f7ab",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 17,
              isSelected: false,
            },
          ],
          totalFeature: 17,
        },
        {
          id: "c923d581-449c-4cb5-8932-b5d7b6c0c599",
          name: "Client base oversight",
          description:
            "Business-level overview, monitoring, and segmentation of clients",
          order: 2,
          features: [
            {
              id: "f282fced-72c0-4585-ade9-e6b0b0442c53",
              name: "Report on standing client instructions",
              description:
                "Business-level view of all standing instructions clients have on the platform e.g. automatic rebalance instructions, regular withdrawal instructions",
              order: 1,
              isSelected: false,
            },
            {
              id: "c6dcb694-b7bb-4a85-b746-b9553ab79740",
              name: "Client lists by market exposure or investment holdings",
              description:
                "Business-level view to search and filter clients by market exposure or investment holdings (e.g. generate a list of all clients who hold BHP stock)",
              order: 2,
              isSelected: false,
            },
            {
              id: "1c39891e-a5a3-41ff-8dfe-790d5ccc5cf1",
              name: "Client lists by cash balance",
              description:
                "Business-level view showing all clients by cash balance",
              order: 3,
              isSelected: false,
            },
            {
              id: "6f677ed8-275b-4f11-9e44-e5d8934c319a",
              name: "Track client online activity",
              description:
                "Business-level view of online activity by clients, such as when they logged in, what reports they accessed etc. ",
              order: 4,
              isSelected: false,
            },
            {
              id: "7270178b-235c-4102-95e8-88bfa6225dfc",
              name: "Notifications of suspicious changes in client behaviour",
              description:
                "The platform notifies advisers of any suspicious activity and changes to client behaviour on the client portal",
              order: 5,
              isSelected: false,
            },
            {
              id: "ca9ee6be-d17c-48b8-a435-3c29781f5c9a",
              name: "Create client segments for reporting and admin",
              description:
                "Advisers can group together clients for the purpose of oversight and bulk administration. For example, bulk reporting, bulk transactions, and charging ASF based on service tier. ",
              order: 6,
              isSelected: false,
            },
            {
              id: "d60ea213-cc10-4298-98c7-f6177f0d9f24",
              name: "Classify clients as wholesale investors",
              description:
                "Clients can be designated as wholesale investors with an accountant's certificate",
              order: 7,
              isSelected: false,
            },
            {
              id: "195e46dd-f66b-4a48-831c-86722fa1b0e9",
              name: "Track and manage accountant certificate expiry",
              description:
                "Advisers can track the expiry dates of accountant's certificates for clients with the wholesale investor designation",
              order: 8,
              isSelected: false,
            },
            {
              id: "7a636ed1-7602-41c6-b303-739c10547957",
              name: "Privacy mode for clients (VIP designation)",
              description:
                "Clients with high privacy requirements can be designated as VIP (e.g. celebrities). Platform automatically applies an extra level of privacy to these accounts and limits the number of people who have access to their details",
              order: 9,
              isSelected: false,
            },
            {
              id: "33c31c3b-105f-4ea4-af7a-23b037a9db4e",
              name: "Ongoing monitoring of AFSL compliance",
              description:
                "Platform assists planning practices with compliance tracking (e.g. ensuring fee renewals have been consented to)",
              order: 10,
              isSelected: false,
            },
            {
              id: "4cf19392-b6b0-491f-932b-c2fa15039279",
              name: "Proactive insights to identify client engagement opportunities",
              description:
                "The platform proactively identifies and alerts advisers to client engagement opportunities e.g. alerts based on age or milestones such as nearing 65",
              order: 11,
              isSelected: false,
            },
            {
              id: "b0e45f23-73eb-4a3b-b493-dce1df0949cd",
              name: "Proactive indicators of clients at risk of leaving",
              description:
                "The platform proactively monitors and notifies advisers of clients who are at risk of leaving based on data analytics over the behaviours of other clients",
              order: 12,
              isSelected: false,
            },
            {
              id: "bea8ed44-7432-4349-8721-7fa08c829d39",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 13,
              isSelected: false,
            },
          ],
          totalFeature: 13,
        },
      ],
      iconUrl:
        "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1670817678004Group.svg",
    },
    {
      id: "32cc3df2-cacc-4203-897f-838ea74b4b72",
      name: "Client reporting",
      description:
        "Flexibility and automation in creating and delivering client reports",
      order: 7,
      totalFeature: 35,
      totalSelectedFeature: 0,
      isSelected: false,
      subSections: [
        {
          id: "dfedf9ff-18eb-40ef-b8c4-2d3c9bdbd2ae",
          name: "Communications",
          description:
            "Managing and tracking communications from the platform to clients",
          order: 1,
          features: [
            {
              id: "15ae6eaf-9cd7-406b-8bda-fbcd16d864bf",
              name: "Choice of delivery method (post or electronic)",
              description:
                "Platform communications to clients can be chosen to be delivered electronically (e.g. via email) or via post",
              order: 1,
              isSelected: false,
            },
            {
              id: "c109be13-3e83-49fd-8efa-db748857fcc8",
              name: "Adviser can view platform comms sent to clients",
              description:
                "Advisers can view all communications sent by the platform to clients on the adviser portal (e.g. a copy of the client's annual statement)",
              order: 2,
              isSelected: false,
            },
            {
              id: "8326ce6c-5561-41f7-8593-fd8efed31d67",
              name: "Track whether clients have read correspondence",
              description:
                "Platform shows if clients have received and opened the correspondence sent to them",
              order: 3,
              isSelected: false,
            },
            {
              id: "ba01c9ea-36ef-448c-8404-860e83a229b7",
              name: "Member newsletters",
              description:
                "The platform sends newsletters to super members to keep them engaged",
              order: 4,
              isSelected: false,
            },
            {
              id: "1ca6a2ca-0459-4881-aa57-47c428b46dab",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 5,
              isSelected: false,
            },
          ],
          totalFeature: 5,
        },
        {
          id: "070cf0e0-4a19-49e3-bdd7-148a18a2fde8",
          name: "Customisability",
          description: "Range of customisation options for client reports",
          order: 2,
          features: [
            {
              id: "0eb8e5f9-373a-459d-b4d8-3d55fd427659",
              name: "Choice of benchmarks in performance reports",
              description:
                "A range of benchmarks can be chosen to be shown in portfolio performance reports",
              order: 1,
              isSelected: false,
            },
            {
              id: "51c1f018-e89f-4a19-bab4-2f63fb285e0d",
              name: "Compare performance of current vs previous version of portfolio",
              description:
                "The platform enables the comparison of a client's current portfolio performance directly with a previous iteration (such as the client's pre-advice portfolio)",
              order: 2,
              isSelected: false,
            },
            {
              id: "84c2a301-e024-4b58-a09c-7f2eebfe6877",
              name: "Performance return methodology (IRR, TWR)",
              description:
                "Ability to choose the methodology for calculating investment returns (internal rate of return/money weighted return or time weighted return)",
              order: 3,
              isSelected: false,
            },
            {
              id: "2186a9af-12f7-4f18-985b-71369951ddc0",
              name: "Realised vs. unrealised gains",
              description:
                "Ability to choose whether performance reports show realised and/or unrealised gains",
              order: 4,
              isSelected: false,
            },
            {
              id: "8801e78c-c534-464c-9c82-c5d6652f07cd",
              name: "Consolidate portfolio holdings ",
              description:
                "All holdings of the same security within a client account can be consolidated into one line item in reports, instead of separate line items across different portfolios",
              order: 5,
              isSelected: false,
            },
            {
              id: "a6eee3a1-4ef4-43c1-960a-e9c7df8ca98d",
              name: "Completely customisable report builder",
              description:
                "Client reports are fully customisable on the platform, with the ability to edit individual report elements e.g. the information shown in the reports can be chosen, the order information is displayed can be changed, the formatting of the text can be changed, the colour scheme can be changed etc.",
              order: 6,
              isSelected: false,
            },
            {
              id: "312f3770-d9d6-4c75-bf62-26b219875628",
              name: "Choice of how data is visualised (table vs chart)",
              description:
                "How data is visualised in client reports can be changed between table view and chart/graph view",
              order: 7,
              isSelected: false,
            },
            {
              id: "3fe53c7e-6cd3-42a6-8a48-a67af2e4ab97",
              name: "Customise table elements",
              description:
                "Being able to specify in the platform portal which rows/columns are shown in tables generated in reports",
              order: 8,
              isSelected: false,
            },
            {
              id: "26f72591-64e3-4cc0-a067-8ccbd03b3fd4",
              name: "Ability to choose between simple and detailed versions of reports",
              description:
                "Can choose the complexity of information presented to clients in reports. For example, between simple summary versions of reports that are visual versus data dense reports",
              order: 9,
              isSelected: false,
            },
            {
              id: "226433d7-f8df-4234-9797-95e7b0ecebba",
              name: "Ability to add captions/commentary from adviser",
              description:
                "Through the platform, advisers can add their own commentary to the client reports they generate",
              order: 10,
              isSelected: false,
            },
            {
              id: "1d73d480-ce2c-43b0-a3d8-af6359e38db1",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 11,
              isSelected: false,
            },
          ],
          totalFeature: 11,
        },
        {
          id: "05151244-a7fd-4b72-a5a6-05b8a6ca3d93",
          name: "Format and delivery",
          description: "Choice of report output formats and delivery methods",
          order: 3,
          features: [
            {
              id: "133be2f4-0f61-4681-9ddd-392cbeffd3fe",
              name: "Choice of report output formats - CSV, PDF, Word",
              description:
                "Client reports can be generated in a variety of document formats, including CSV, PDF and/or Word",
              order: 1,
              isSelected: false,
            },
            {
              id: "2ae42ddd-fe54-4955-88fd-17993014cdad",
              name: "Output to Microsoft Power BI/Google Data Studio",
              description:
                "Client data from the platform can be automatically fed into an adviser's own data visualisation solution, such as Microsoft Power BI and Google Data Studio",
              order: 2,
              isSelected: false,
            },
            {
              id: "497e1d17-11fc-4cff-8588-e92aee646960",
              name: "Individual platform data points can be exposed as API feed",
              description:
                "Client data on the platform can be exposed as API data feeds and can be accessed through other systems",
              order: 3,
              isSelected: false,
            },
            {
              id: "3b382ded-0e32-49d6-b5dd-be945f09fa6f",
              name: "Digital report delivery with drill-downs/interactive capability",
              description:
                "Client reports can be set up as an interactive online experience. Similar to existing reporting processes, advisers can tailor what information is presented to clients (portfolio valuation, transaction history, etc). However, the delivery is dynamic with the ability to drill into key areas as clients ask questions. This process excludes any static forms of report delivery such as PDF and it excludes using the client login to run the review",
              order: 4,
              isSelected: false,
            },
            {
              id: "84bd8f82-5bb2-41d0-909b-b93737b20185",
              name: "Accessibility features for reports (e.g. change font size)",
              description:
                "Elements of client reports can be customised to improve their accessibility and usability e.g. font size, contrast, text to speech",
              order: 5,
              isSelected: false,
            },
            {
              id: "2a7c4c9b-baa9-4d50-8857-a8272cc63849",
              name: "Send reports electronically from platform to client",
              description:
                "Client reports can be emailed to clients directly from the platform",
              order: 6,
              isSelected: false,
            },
            {
              id: "bf7a7a72-5be3-4e23-a5d7-d5e56e374c0e",
              name: "Document library",
              description:
                "Document storage capability capturing all adviser and platform generated collateral",
              order: 7,
              isSelected: false,
            },
            {
              id: "9d1dff41-a6b7-4cbd-a247-909ae2848145",
              name: "Document library - upload",
              description:
                "Document storage capability anything uploaded by clients and advisers",
              order: 8,
              isSelected: false,
            },
            {
              id: "16eb111b-aece-4d7c-a611-838c46fcca78",
              name: "Automatically upload client reports to planning software",
              description:
                "Reports generated on the platform can be set to be automatically uploaded to the client's file notes in the planning software/CRM",
              order: 9,
              isSelected: false,
            },
            {
              id: "5ab852af-eeb6-4ab7-8f17-e640dc2432ab",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 10,
              isSelected: false,
            },
          ],
          totalFeature: 10,
        },
        {
          id: "037d7b5f-6cc3-4c0f-8df0-9dd20d78bdb2",
          name: "Generating reports",
          description: "Flexibility and automation of client reporting",
          order: 4,
          features: [
            {
              id: "74edcf16-54eb-4234-9c5d-73e83cb7a9c8",
              name: "Reporting at client level (aggregating all accounts)",
              description:
                "Client reports can be set to aggregate all the accounts a client holds into one report (e.g. if they have IDPS, Super and trust accounts, they are reported together)",
              order: 1,
              isSelected: false,
            },
            {
              id: "27e6ef38-18ca-4a48-9741-e1012c1cddb9",
              name: "Reporting at family/household level (aggregate all clients)",
              description:
                "Client reports can be set to aggregate the accounts of all members in a family to provide a household view (e.g. the IDPS and Super accounts of both spouses in one report)",
              order: 2,
              isSelected: false,
            },
            {
              id: "bbdb5bd4-287c-44b0-8ef2-13214ba2698a",
              name: "Combine and save multiple reports as a template",
              description:
                "Instead of having to choose client reports one by one each time, multiple reports can be combined and saved as a single report pack that can be run in the future. e.g. a 'client review pack' which includes PV, transaction and asset allocation reports",
              order: 3,
              isSelected: false,
            },
            {
              id: "e7f51a69-b735-46d1-a3a4-c8036235633a",
              name: "Reports can be created in bulk for multiple clients",
              description:
                "The same report can be generated for multiple clients in one action",
              order: 4,
              isSelected: false,
            },
            {
              id: "3317de87-6012-4996-ac67-e626077eda17",
              name: "Reports can be set to run periodically",
              description:
                "Reports can be scheduled to be generated at regular intervals (e.g. on the first of every month)",
              order: 5,
              isSelected: false,
            },
            {
              id: "10f67ef6-94fd-46c2-81be-25df302aaf7d",
              name: "Adviser branded client reports",
              description:
                "Reports can be set to be generated with the branding and colour scheme of the advice practice and/or licensee",
              order: 6,
              isSelected: false,
            },
            {
              id: "e2ee1c91-f9f7-4a8c-af9b-94fa0d66aab4",
              name: "Custom asset class categorisation",
              description:
                "The asset class categorisations of investments can be re-categorised (e.g. set an ETF to show as International Equities rather than Australian equities)",
              order: 7,
              isSelected: false,
            },
            {
              id: "c9d12ead-03ea-4334-bc25-26b21e88aace",
              name: "Choice of Research House for asset class categorisation",
              description:
                "Flexibility to choose which Research House provides asset class categorisations of individual investments",
              order: 8,
              isSelected: false,
            },
            {
              id: "295b414f-407f-4e8f-bca1-eb8911b61cc2",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 9,
              isSelected: false,
            },
          ],
          totalFeature: 9,
        },
      ],
      iconUrl:
        "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1670817678004Group.svg",
    },
    {
      id: "463fe3de-0f47-4230-aa4e-123ce00b81fe",
      name: "Client access",
      description:
        "Online access provided to clients, including level of control available",
      order: 8,
      totalFeature: 54,
      totalSelectedFeature: 0,
      isSelected: false,
      subSections: [
        {
          id: "511d9348-19a5-4f70-90a1-e637a32ed7d7",
          name: "Access control",
          description:
            "Control over what clients can do online and the access of third parties such as accountants",
          order: 1,
          features: [
            {
              id: "86518c54-d60d-48a5-873f-43a84ba33689",
              name: "Adviser can toggle functionality available in client portal",
              description:
                "Client permissions can be managed online by advisers/firms. Control is granted over which functionality clients have access to when they login to the online portal",
              order: 1,
              isSelected: false,
            },
            {
              id: "0b2b94ec-ca7a-4590-a4e2-59d7c6fa2240",
              name: "Adviser can toggle client ability to: trade",
              description:
                "Advisers can control whether clients can place investment instructions online",
              order: 2,
              isSelected: false,
            },
            {
              id: "2afd2549-6102-41f0-b3b5-ad6b6cce36bd",
              name: "Adviser can toggle client ability to: create reports",
              description:
                "Advisers can control which reports can be generated by clients",
              order: 3,
              isSelected: false,
            },
            {
              id: "1314af13-83c4-44d1-a3fa-a055704bd9d4",
              name: "Adviser can toggle client ability to: withdraw",
              description:
                "Advisers can control whether clients can make withdrawals online",
              order: 4,
              isSelected: false,
            },
            {
              id: "e8d7217e-ec6d-4cd7-9350-23dcd50c7802",
              name: "Adviser can toggle client ability to: access research",
              description:
                "Advisers can control whether clients can access investment research online",
              order: 5,
              isSelected: false,
            },
            {
              id: "d245a04e-f592-4007-b5ca-6a9efd5d07c3",
              name: "Adviser can toggle client ability to: use calculators/tools",
              description:
                "Advisers can control whether clients can use calculators and/or modelling tools online",
              order: 6,
              isSelected: false,
            },
            {
              id: "34faa7b7-283c-448c-8da5-9a816ce254f1",
              name: "Adviser can toggle client ability to: receive alerts",
              description:
                "Advisers can control which alerts clients receive from the platform",
              order: 7,
              isSelected: false,
            },
            {
              id: "71983fb5-7e40-449b-92a7-b83d2eea8e30",
              name: "Adviser branding on client portal",
              description:
                "The logo and colour scheme of the client portal can be amended to reflect the practice/licensee branding",
              order: 8,
              isSelected: false,
            },
            {
              id: "0f30f3f6-4a4b-4fdd-8698-c002839cf53b",
              name: "Access can be granted to third parties",
              description:
                "Third party permissions can be managed online. Advisers control which third parties, such as accountants, have access to their clients' accounts",
              order: 9,
              isSelected: false,
            },
            {
              id: "6c263ec9-4ef9-4cd1-9afd-f47d88ef5ee5",
              name: "Clients can authorise third party access requested by adviser",
              description:
                "Clients have the final say over which third parties have access to their information. If an adviser chooses a third party, the client can authorise their access",
              order: 10,
              isSelected: false,
            },
            {
              id: "6aec15ab-eaf8-4bad-a005-afe6a2ea4b31",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 11,
              isSelected: false,
            },
          ],
          totalFeature: 11,
        },
        {
          id: "f67b8a6e-5e40-4c02-9a11-e7268195d1d0",
          name: "Client support",
          description:
            "Service and support available to clients to aid in their use of the platform",
          order: 2,
          features: [
            {
              id: "ae7adc35-8707-4ecb-9c89-42fe68fe197b",
              name: "Platform user guides",
              description:
                "User guides are available to help clients learn how to use the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "27fa0bab-2aa3-4813-a7ab-0bbff8c0978e",
              name: "Tool tips",
              description:
                "Tool tips are available throughout the platform to help clients learn how to use the platform. These can be in the form of help icons that reveal further information by mouseover",
              order: 2,
              isSelected: false,
            },
            {
              id: "c805c9b9-90a5-4cab-aeb2-0f6e962fa1cb",
              name: "Educational content designed for clients",
              description:
                "The platform provides content to help educate and engage clients on their finances",
              order: 3,
              isSelected: false,
            },
            {
              id: "0fd3ceb0-f030-46ca-bab1-60f72caec1d1",
              name: "Dedicated client support phone number",
              description:
                "Clients can call platform support staff dedicated to servicing client needs (i.e. separate to the adviser contact centre)",
              order: 4,
              isSelected: false,
            },
            {
              id: "102d830e-e8f3-47c9-9e55-9197c5d35c1a",
              name: "Dedicated client support email",
              description:
                "Clients can email platform support/admin staff dedicated to servicing client needs (i.e. separate to the adviser support email address)",
              order: 5,
              isSelected: false,
            },
            {
              id: "5e3a8da3-7c0c-45e9-8d3b-e22fb20d7e28",
              name: "Client access to online chat",
              description:
                "Clients can chat with the platform support staff via an online chat facility",
              order: 6,
              isSelected: false,
            },
            {
              id: "12dde44a-877d-4bb2-97fe-d06a7527810b",
              name: "Adviser visibility of client contact with platform",
              description:
                "Advisers have oversight over any client-initiated interactions with the platform e.g. when and why they contacted the platform",
              order: 7,
              isSelected: false,
            },
            {
              id: "9f0aa60e-5e73-499d-9cb1-86231316a486",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 8,
              isSelected: false,
            },
          ],
          totalFeature: 8,
        },
        {
          id: "51bddfe0-ae6b-4f43-a477-aa0106a24b5f",
          name: "Mobile",
          description: "Features available in the client mobile app",
          order: 3,
          features: [
            {
              id: "d98ef1dc-74d8-4eea-a8ec-2c28e0cdfa8f",
              name: "Client mobile app - iOS",
              description: "A client mobile app is available on iOS devices",
              order: 1,
              isSelected: false,
            },
            {
              id: "823bf8fa-1501-423e-b885-fb9a08baffe7",
              name: "Client mobile app - Android",
              description:
                "A client mobile app is available on Android devices",
              order: 2,
              isSelected: false,
            },
            {
              id: "247bd205-b9f6-41b4-a0c3-dea26c3fa29e",
              name: "Biometric login to app",
              description:
                "Clients can log into the mobile app using biometric methods e.g. facial recognition, fingerprint authentication",
              order: 3,
              isSelected: false,
            },
            {
              id: "f2810d11-d8c9-4e7f-bad8-5063a7f0db1b",
              name: "PIN login to app",
              description:
                "Clients can log into the mobile app using a PIN. That is, they do not need to enter their full password each time",
              order: 4,
              isSelected: false,
            },
            {
              id: "30c8b6c0-dbc5-454e-92ed-e20bae03b398",
              name: "Provide consent via mobile app",
              description:
                "Clients can authorise adviser-initiated actions via their mobile app",
              order: 5,
              isSelected: false,
            },
            {
              id: "f4ac847c-8516-49cb-9762-2fe94e70766b",
              name: "Upload documents as photos",
              description:
                "Clients can upload any outstanding documents as photos via the mobile app",
              order: 6,
              isSelected: false,
            },
            {
              id: "cee69007-1d7b-4711-9e93-9cf785d579c8",
              name: "View portfolio valuation over time",
              description:
                "Clients can use the mobile app to view the value of their total portfolio over time",
              order: 7,
              isSelected: false,
            },
            {
              id: "e8cc7e19-ac0b-4937-8429-b67a5257f92c",
              name: "Access reports and statements",
              description:
                "Clients can view reports generated by advisers and statements sent by the platform on the mobile app",
              order: 8,
              isSelected: false,
            },
            {
              id: "de756aa3-ff1c-45a6-97ff-125e3ae0d74d",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 9,
              isSelected: false,
            },
          ],
          totalFeature: 9,
        },
        {
          id: "610dacc8-00f6-4f5a-b785-10f90e7b4742",
          name: "Security",
          description:
            "Methods of authenticating client identity when using the platform",
          order: 4,
          features: [
            {
              id: "d23e382d-6ed3-4fd9-944f-d9250df0adbf",
              name: "Multi-factor authentication via SMS",
              description:
                "In addition to password entry, clients can validate their identity with a code sent to them via SMS",
              order: 1,
              isSelected: false,
            },
            {
              id: "fa32725a-0062-4e50-afa2-308d5449ab27",
              name: "Multi-factor authentication via email",
              description:
                "In addition to password entry, clients can validate their identity with a code or link sent to them via email",
              order: 2,
              isSelected: false,
            },
            {
              id: "dd7154ef-420e-4bf9-9a10-511a91ca0653",
              name: "Multi-factor authentication via app",
              description:
                "In addition to password entry, clients can validate their identity with an authentication app e.g. Microsoft Authenticator, Google Authenticator, myGovID",
              order: 3,
              isSelected: false,
            },
            {
              id: "a639f90e-0070-41ed-ad68-4a520c795bf5",
              name: "QR code login to web portal",
              description:
                "Clients can directly sign into the web portal by scanning a QR code with their mobile app",
              order: 4,
              isSelected: false,
            },
            {
              id: "c26cc777-b052-4dbd-ab93-d883bf14a6e3",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 5,
              isSelected: false,
            },
          ],
          totalFeature: 5,
        },
        {
          id: "5bd1e1af-48db-4342-92e8-cae40a872f8b",
          name: "Web portal",
          description:
            "Features available in the web version of the client portal",
          order: 5,
          features: [
            {
              id: "fcdb1d11-bcbc-4f16-874b-44f17bffc501",
              name: "Client can set up own username",
              description:
                "Clients can select the username they use to log into their online portal",
              order: 1,
              isSelected: false,
            },
            {
              id: "887c7785-ee04-4878-bc8c-5c22871e7188",
              name: "Update personal details",
              description:
                "Clients can update personal details online, such as their contact details",
              order: 2,
              isSelected: false,
            },
            {
              id: "595f59ee-38b3-4e20-955d-1c63e0a2cd9f",
              name: "Update beneficiary nominations",
              description:
                "Clients can update non-binding nominations and find binding nomination forms online",
              order: 3,
              isSelected: false,
            },
            {
              id: "c75bae20-adb0-4c45-9623-c9ccd3fa4732",
              name: "Upload outstanding documents",
              description:
                "Clients can upload any outstanding documents required by the platform and/or adviser via the online portal",
              order: 4,
              isSelected: false,
            },
            {
              id: "841252de-2130-4cff-ab7b-862c098d3799",
              name: "Ability to place investment transactions",
              description:
                "Clients can have access to place investment transactions online",
              order: 5,
              isSelected: false,
            },
            {
              id: "82c5db60-4a01-418b-8e28-4f038f022f18",
              name: "Initiate withdrawals",
              description:
                "Clients can initiate and complete withdrawals by themselves",
              order: 6,
              isSelected: false,
            },
            {
              id: "02dc5382-5488-4523-95f5-26b752a83146",
              name: "Provide consent via web portal",
              description:
                "Clients can authorise adviser-initiated actions via the online portal",
              order: 7,
              isSelected: false,
            },
            {
              id: "dfe131dc-a787-42f9-a18b-143616124511",
              name: "View portfolio valuation over time",
              description:
                "Clients can view the value of their total portfolio online without having to generate a report",
              order: 8,
              isSelected: false,
            },
            {
              id: "819a6d6a-9d15-4142-ae81-5ce778597baf",
              name: "View projected account balance",
              description:
                "Clients can view account balance projections without having to generate a report (e.g. projected super balance at retirement)",
              order: 9,
              isSelected: false,
            },
            {
              id: "f84e0281-fe60-43f3-80ef-828c1ccf732c",
              name: "Access reports created by advisers",
              description:
                "Reports created by advisers can be accessed by clients via the portal",
              order: 10,
              isSelected: false,
            },
            {
              id: "efe7edea-9acb-4ad0-85f3-fe7ed02e5ec1",
              name: "Ability to create and customise reports",
              description:
                "Clients can create their own reports via the portal",
              order: 11,
              isSelected: false,
            },
            {
              id: "3b4559dc-7fe4-41d5-9198-04fb8d191cfd",
              name: "Access platform communications and statements",
              description:
                "Clients can view communications and statements they receive from the platform via the portal",
              order: 12,
              isSelected: false,
            },
            {
              id: "6109814c-7c47-482d-95a2-03682d2ef9bf",
              name: "Access research",
              description:
                "Clients can have access to investment research via the portal",
              order: 13,
              isSelected: false,
            },
            {
              id: "c0f16782-b130-4f1c-a35d-eff49ac8e1f8",
              name: "Access stock watchlists",
              description: "Clients have create watchlists to track shares",
              order: 14,
              isSelected: false,
            },
            {
              id: "08cbc05b-c1b3-400a-a547-8a800a3cb468",
              name: "Access to financial and market news ",
              description:
                "Clients can access general market news through their login",
              order: 15,
              isSelected: false,
            },
            {
              id: "8e22edeb-1269-4b01-b66d-55cf106e7b81",
              name: "Financial and market news tailored to client",
              description:
                "Clients can access news tailored to the holdings in their portfolio and/or watchlist",
              order: 16,
              isSelected: false,
            },
            {
              id: "f19b0d23-837f-4ec2-b4c2-f6e57d442e3b",
              name: "Access calculators and/or modelling tools",
              description:
                "Clients can use calculators and/or modelling tools to learn more about their financial situation. E.g. model the impact of increasing contributions on their balance",
              order: 17,
              isSelected: false,
            },
            {
              id: "b3ed78eb-971c-4362-b9eb-1b0f51576102",
              name: "Multi-lingual accessibility",
              description:
                "The client portal is multilingual, allowing clients to toggle it to a language other than English",
              order: 18,
              isSelected: false,
            },
            {
              id: "ad2d3c31-0587-4dea-ba63-c44286088752",
              name: "Mobile responsive website",
              description:
                "The client portal website is mobile optimised and adapts to the  size of the screen when accessed via a browser on a smartphone or tablet device",
              order: 19,
              isSelected: false,
            },
            {
              id: "d913887e-49b0-4d47-9327-554121eed70b",
              name: "Former advised clients have full platform access",
              description:
                "Clients who no longer have an adviser can retain their platform accounts and have access to the online portal",
              order: 20,
              isSelected: false,
            },
            {
              id: "05e8caea-8e1c-4127-8a82-bdb8ac344b63",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 21,
              isSelected: false,
            },
          ],
          totalFeature: 21,
        },
      ],
      iconUrl:
        "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1670817678004Group.svg",
    },
    {
      id: "04fb77b1-8611-4a0e-924b-993988a0eb11",
      name: "Planning and compliance",
      description: "Tools for supporting your compliance",
      order: 9,
      totalFeature: 13,
      totalSelectedFeature: 0,
      isSelected: false,
      subSections: [
        {
          id: "9c147ac0-24dc-48d6-b9b1-1c440e2d24de",
          name: "Record of Advice",
          description:
            "Creating and managing Records of Advice (ROA) on the platform",
          order: 1,
          features: [
            {
              id: "5f268b5d-c420-4bb0-97b0-3878827f0498",
              name: "Generate ROAs for investment transactions",
              description:
                "ROAs can be created at the end of the transaction process, detailing all the proposed changes to client portfolios",
              order: 1,
              isSelected: false,
            },
            {
              id: "af718ef8-91eb-4663-8c78-20a9d3288141",
              name: "Generate ROAs for non-transaction changes",
              description:
                "ROAs can be created in the platform for instructions outside of portfolio changes (e.g. to recommend an increase to the amount of contributions)",
              order: 2,
              isSelected: false,
            },
            {
              id: "1d3bcbf0-c9d2-43fc-9640-1966d9b6895a",
              name: "ROA templates can be customised",
              description:
                "Advisers and their firms can save ROA templates with customised wording for different situations. These templates can be easily applied at a later date",
              order: 3,
              isSelected: false,
            },
            {
              id: "54775426-6689-4880-976b-5d1d07b44f3f",
              name: "Can obtain digital consent on ROAs",
              description:
                "Clients can review ROAs and provide their authority online via the portal or mobile app",
              order: 4,
              isSelected: false,
            },
            {
              id: "805b5d04-86dc-4dd0-983c-5f04790b1e78",
              name: "Bulk ROAs for transactions affecting multiple clients",
              description:
                "ROAs can be generated and sent out to multiple clients at once for transactions that apply to multiple clients",
              order: 5,
              isSelected: false,
            },
            {
              id: "52b51220-e83b-43ef-9d3f-744f7e2daacf",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 6,
              isSelected: false,
            },
          ],
          totalFeature: 6,
        },
        {
          id: "1d5c5339-855d-4dfd-93e1-e7fb92b5e665",
          name: "Adviser service fees",
          description:
            "Managing adviser service fees and ongoing service agreements",
          order: 2,
          features: [
            {
              id: "abc63abd-c6b6-4d0d-8621-ba2be11fd313",
              name: "Dollar-based adviser service fees (ASF)",
              description:
                "Adviser service fees can be charged as a specific dollar amount",
              order: 1,
              isSelected: false,
            },
            {
              id: "7f807abd-e00c-405b-8665-ecd1bf883536",
              name: "Percentage-based ASF",
              description:
                "Adviser service fees can be charged as a percentage of the portfolio",
              order: 2,
              isSelected: false,
            },
            {
              id: "5fa2100c-96e0-4a67-b4af-312ff5757dd9",
              name: "Tiered ASF based on client balance",
              description:
                "Adviser service fees can be tiered based on a client's account balance",
              order: 3,
              isSelected: false,
            },
            {
              id: "bfd3ce9a-2226-444f-8ad4-f073ecdca7dd",
              name: "Manage fee disclosure statements on platform",
              description:
                "Advisers can view and manage fee disclosure statements",
              order: 4,
              isSelected: false,
            },
            {
              id: "47fe3e61-3acf-4e19-8e11-3c92fa0a0f4f",
              name: "Manage ongoing service agreements on platform",
              description:
                "Advisers can manage their ongoing service agreements, such as anniversary dates, and view which clients have yet and will need to provide consent",
              order: 5,
              isSelected: false,
            },
            {
              id: "a9f4b6bd-ea9f-4184-8b7f-eb6fef7633c1",
              name: "Digital acceptance process for renewals",
              description:
                "Clients can authorise the renewal of their adviser service fees online ",
              order: 6,
              isSelected: false,
            },
            {
              id: "3f6378f5-1b39-44ec-9e60-cbcd1482f691",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 7,
              isSelected: false,
            },
          ],
          totalFeature: 7,
        },
      ],
      iconUrl:
        "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1670817678004Group.svg",
    },
    {
      id: "edd8d6e7-3376-43cd-afed-1230ca8f76af",
      name: "Whole of wealth view",
      description: "Supporting a holistic view of the client situation",
      order: 10,
      totalFeature: 11,
      totalSelectedFeature: 0,
      isSelected: false,
      subSections: [
        {
          id: "ae82cd12-3235-4539-9e71-a943542a7e9d",
          name: "Goal tracking",
          description:
            "Establishing and tracking client goals alongside their portfolios",
          order: 1,
          features: [
            {
              id: "3da4e644-804b-460e-b4dc-426e83bd3c09",
              name: "Ability to set and track goals online",
              description:
                "Client financial goals can be recorded on the platform. Their total balance can tracked towards these goals",
              order: 1,
              isSelected: false,
            },
            {
              id: "359f02ee-53ca-497f-975d-ed9ebc367e70",
              name: "Goals can be linked to specific assets or portfolios",
              description:
                "Individual assets, portfolios or accounts can be linked to different financial goals that the client/family has. Each of these can be tracked towards their respective goal",
              order: 2,
              isSelected: false,
            },
            {
              id: "8656a7e4-3174-4181-a91c-f2d7ef01c6df",
              name: "Goals can be reported by probability of being achieved",
              description:
                "Modelling allows the likelihood of achieving each goal to be reported as a probability. Such modelling takes into account expected contributions, withdrawals, returns and the like",
              order: 3,
              isSelected: false,
            },
            {
              id: "7529e1ea-dbdd-463f-b9ba-4eecac0671f0",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 4,
              isSelected: false,
            },
          ],
          totalFeature: 4,
        },
        {
          id: "87f8a90d-255d-4a3e-b0f4-39837e583a85",
          name: "Off-platform assets",
          description:
            "Recording and reporting of assets clients hold off-platform",
          order: 2,
          features: [
            {
              id: "a22ac3ed-78d2-4f82-b862-a5322c00b2de",
              name: "Record client assets held off-platform",
              description:
                "Client assets held-off platform can also be recorded on the platform",
              order: 1,
              isSelected: false,
            },
            {
              id: "f412fe1f-4ef0-4dd9-99b8-8718eae69587",
              name: "Record client liabilities held off-platform",
              description:
                "Client liabilities held-off platform can also be recorded on the platform",
              order: 2,
              isSelected: false,
            },
            {
              id: "853aeacc-23d2-4da7-a9d9-a98183490eed",
              name: "Automatic data feeds on value of off-platform holdings",
              description:
                "The value of off-platform client holdings are automatically updated on the platform via data feeds",
              order: 3,
              isSelected: false,
            },
            {
              id: "6d9a82f4-a412-4745-851e-bf5074b2bff3",
              name: "Automatic data feeds on transactions relating to off-platform holdings",
              description:
                "Transactions relating to off-platform client holdings are automatically updated on the platform via data feeds (e.g. if a security is bought or sold in their CommSec account)",
              order: 4,
              isSelected: false,
            },
            {
              id: "42f86de8-3e1e-4925-bc08-161bfaf218dc",
              name: "Off-platform assets and liabilities can be included in reports",
              description:
                "Off-platform assets and liabilities can be included in client reports, allowing the platform to report on the client's entire financial position. For example, an asset allocation report can include the property held by clients outside the platform",
              order: 5,
              isSelected: false,
            },
            {
              id: "833d6aa8-57e0-46cb-9de1-505815e0065d",
              name: "Automated validation of off-platform data",
              description:
                "Data on off-platform assets are automatically validated by the platform. This involves the reconciliation of data points to ensure data is accurate and matches other systems",
              order: 6,
              isSelected: false,
            },
            {
              id: "85fecd7a-d246-4af0-9481-a33b71a4ee22",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 7,
              isSelected: false,
            },
          ],
          totalFeature: 7,
        },
      ],
      iconUrl:
        "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1670817678004Group.svg",
    },
    {
      id: "767b2d80-0850-40d1-b0a6-4f49ff783545",
      name: "Integration",
      description: "Exchanging data and instructions with other systems",
      order: 11,
      totalFeature: 56,
      totalSelectedFeature: 0,
      isSelected: false,
      subSections: [
        {
          id: "6dab9b53-5eda-440b-840f-440758a2931f",
          name: "Xplan",
          description: "Depth of integration available with Xplan",
          order: 1,
          features: [
            {
              id: "7c8887a6-4486-4740-9107-7550b2f58bbb",
              name: "Send: Balance, holding and transaction data",
              description:
                "The platform sends balance, holding and transaction data to Xplan",
              order: 1,
              isSelected: false,
            },
            {
              id: "1f886b65-330d-469a-8133-949026335de3",
              name: "Send: Adviser service fee data",
              description:
                "The platform sends adviser service fee data to Xplan",
              order: 2,
              isSelected: false,
            },
            {
              id: "b9c6fbd6-d031-40c2-bf9f-4a88508f1833",
              name: "Send: Consent data/signed forms",
              description:
                "The platform sends consent data and documentation to Xplan",
              order: 3,
              isSelected: false,
            },
            {
              id: "78b9195e-1984-4f9c-b346-b29ede1afc61",
              name: "Receive: Changes to personal details (e.g. address)",
              description:
                "Changes to client details on Xplan can be pushed to the platform, automatically updating these details",
              order: 4,
              isSelected: false,
            },
            {
              id: "eecb53e6-23fc-4495-9dd6-323a1395ad38",
              name: "Pre-populate platform application form",
              description:
                "Platform application forms can be pre-populated with client data available on Xplan",
              order: 5,
              isSelected: false,
            },
            {
              id: "b26eeca0-db08-4a70-b404-c66f18c3d8d6",
              name: "Pre-populate platform order pad/transactions",
              description:
                "Transaction instructions on the platform can be pre-populated with instructions from Xplan (e.g. based on what was set in the ROA)",
              order: 6,
              isSelected: false,
            },
            {
              id: "c59c4451-370b-4483-a6a8-d8abbaacccd2",
              name: "Data sent in real time",
              description:
                "Data is transferred between the platform and Xplan in real time rather than batched daily",
              order: 7,
              isSelected: false,
            },
            {
              id: "a34d8faf-cc1e-4000-b9e6-a0912f6269cd",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 8,
              isSelected: false,
            },
          ],
          totalFeature: 8,
        },
        {
          id: "669017b0-eaab-4fc0-ac33-5f3240d9d523",
          name: "AdviserLogic",
          description: "Depth of integration available with AdviserLogic",
          order: 2,
          features: [
            {
              id: "1924003e-9039-4004-9b0b-cba1287d2928",
              name: "Send: Balance, holding and transaction data",
              description:
                "The platform sends balance, holding and transaction data to AdviserLogic",
              order: 1,
              isSelected: false,
            },
            {
              id: "064bbefa-2351-4411-8ddb-fca63e5cb568",
              name: "Send: Adviser service fee data",
              description:
                "The platform sends adviser service fee data to AdviserLogic",
              order: 2,
              isSelected: false,
            },
            {
              id: "75c0c1e3-b7e2-4027-a212-2a011eee0ca5",
              name: "Send: Consent data/signed forms",
              description:
                "The platform sends consent data and documentation to AdviserLogic",
              order: 3,
              isSelected: false,
            },
            {
              id: "087fa291-023f-4e34-b90f-5c9bb488c2ca",
              name: "Receive: Changes to personal details (e.g. address)",
              description:
                "Changes to client details on AdviserLogic can be pushed to the platform, automatically updating these details",
              order: 4,
              isSelected: false,
            },
            {
              id: "42505591-1991-41d4-8327-178505d75f8c",
              name: "Pre-populate platform application form",
              description:
                "Platform application forms can be pre-populated with client data available on AdviserLogic",
              order: 5,
              isSelected: false,
            },
            {
              id: "4a06afe0-c272-4225-9bc9-b165f82630cf",
              name: "Pre-populate platform order pad/transactions",
              description:
                "Transaction instructions on the platform can be pre-populated with instructions from AdviserLogic (e.g. based on what was set in the ROA)",
              order: 6,
              isSelected: false,
            },
            {
              id: "5ed2634a-9055-4688-a450-7c6b86f2211b",
              name: "Data sent in real time",
              description:
                "Data is transferred between the platform and AdviserLogic in real time rather than batched daily",
              order: 7,
              isSelected: false,
            },
            {
              id: "71d2deba-7341-4c63-9867-440ead218384",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 8,
              isSelected: false,
            },
          ],
          totalFeature: 8,
        },
        {
          id: "4f991631-cda1-45b6-a1da-c1cfba5570be",
          name: "Midwinter",
          description: "Depth of integration available with Midwinter",
          order: 3,
          features: [
            {
              id: "a5d83fef-dd01-4063-811e-a3671874b046",
              name: "Send: Balance, holding and transaction data",
              description:
                "The platform sends balance, holding and transaction data to Midwinter",
              order: 1,
              isSelected: false,
            },
            {
              id: "8eda8c4b-7f49-45dc-a06b-a12aa0e9c313",
              name: "Send: Adviser service fee data",
              description:
                "The platform sends adviser service fee data to Midwinter",
              order: 2,
              isSelected: false,
            },
            {
              id: "386c9b62-774f-47b2-96c7-289eb1f4193e",
              name: "Send: Consent data/signed forms",
              description:
                "The platform sends consent data and documentation to Midwinter",
              order: 3,
              isSelected: false,
            },
            {
              id: "ccb2e6da-9eca-4097-a043-799e380fceda",
              name: "Receive: Changes to personal details (e.g. address)",
              description:
                "Changes to client details on Midwinter can be pushed to the platform, automatically updating these details",
              order: 4,
              isSelected: false,
            },
            {
              id: "ea3f843c-433d-4aeb-8d59-3359b330ac70",
              name: "Pre-populate platform application form",
              description:
                "Platform application forms can be pre-populated with client data available on Midwinter",
              order: 5,
              isSelected: false,
            },
            {
              id: "c3409d95-1beb-442d-b15a-2747d11ef40a",
              name: "Pre-populate platform order pad/transactions",
              description:
                "Transaction instructions on the platform can be pre-populated with instructions from Midwinter (e.g. based on what was set in the ROA)",
              order: 6,
              isSelected: false,
            },
            {
              id: "d4eaf157-cde5-4c21-9e07-1f182cc44f2c",
              name: "Data sent in real time",
              description:
                "Data is transferred between the platform and Midwinter in real time rather than batched daily",
              order: 7,
              isSelected: false,
            },
            {
              id: "86aa4d6e-7194-4602-a975-6fb61c6c023a",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 8,
              isSelected: false,
            },
          ],
          totalFeature: 8,
        },
        {
          id: "30af4b82-f29f-4162-aa56-f70ea657da51",
          name: "API",
          description: "Depth of integration supported through API",
          order: 4,
          features: [
            {
              id: "e374adcd-10cb-4046-b703-6e1c74cfe7e9",
              name: "Send: Balance and holding data",
              description:
                "Other systems can be set to call data from the platform through an API. The platform API provides client balance and investment holding data",
              order: 1,
              isSelected: false,
            },
            {
              id: "eed38628-447c-4ee8-b5a0-58c6f6d72fec",
              name: "Send: Transaction data",
              description:
                "Other systems can be set to call data from the platform through an API. The platform API provides transaction data",
              order: 2,
              isSelected: false,
            },
            {
              id: "ffe06a32-d2d0-4d58-8384-5fbc93aca286",
              name: "Send: Adviser service fee data",
              description:
                "Other systems can be set to call data from the platform through an API. The platform API provides adviser service fee related data",
              order: 3,
              isSelected: false,
            },
            {
              id: "58c2bd8a-38ed-4e27-9d18-d11f918a8af1",
              name: "Send: Consent data/signed forms",
              description:
                "Other systems can be set to call data from the platform through an API. The platform API provides consent data and documentation",
              order: 4,
              isSelected: false,
            },
            {
              id: "c5df0db0-bbd3-4213-a73b-6ac6dee54fae",
              name: "Pre-populate platform application form",
              description:
                "Platform application can be initiated through an API. For example, the platform can call client data held on other systems to populate application forms and start the account opening process",
              order: 5,
              isSelected: false,
            },
            {
              id: "17591500-397c-48c1-8df6-2a331b9dd30d",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 6,
              isSelected: false,
            },
          ],
          totalFeature: 6,
        },
        {
          id: "72beb07b-b444-4177-b5e1-86e439caa65c",
          name: "Other planning software",
          description:
            "Other planning software that the platform can send data to",
          order: 5,
          features: [
            {
              id: "6eaf1469-4101-48ed-8a97-6da95816e916",
              name: "advice intelligence",
              description:
                "Data feed from platform to advice intelligence available",
              order: 1,
              isSelected: false,
            },
            {
              id: "dcc82579-560c-40f1-9246-8391e20c5cec",
              name: "COIN",
              description: "Data feed from platform to COIN available",
              order: 2,
              isSelected: false,
            },
            {
              id: "30c2a9d5-2f68-4c18-b54e-f3d005da2d54",
              name: "Fin365",
              description: "Data feed from platform to Fin365 available",
              order: 3,
              isSelected: false,
            },
            {
              id: "1bc8f006-85d3-4320-ae46-70dbdc5b93c2",
              name: "Intelliflo",
              description: "Data feed from platform to Intelliflo available",
              order: 4,
              isSelected: false,
            },
            {
              id: "e36dc734-8498-49d7-a786-be72da253fe5",
              name: "Platformplus",
              description: "Data feed from platform to Platformplus available",
              order: 5,
              isSelected: false,
            },
            {
              id: "1684ee22-2e24-4724-9cb6-5b190642a9c7",
              name: "Plutosoft",
              description: "Data feed from platform to Plutosoft available",
              order: 6,
              isSelected: false,
            },
            {
              id: "874ad2fd-7f81-493e-befd-2fac13da0d66",
              name: "Practifi",
              description: "Data feed from platform to Practifi available",
              order: 7,
              isSelected: false,
            },
            {
              id: "1e2bceee-e8ab-4838-be24-26c579a57802",
              name: "Worksorted",
              description: "Data feed from platform to Worksorted available",
              order: 8,
              isSelected: false,
            },
            {
              id: "a3431e85-e8ac-43d3-8e4b-51835c062660",
              name: "Other",
              description:
                "Data feed from platform to other software available",
              order: 9,
              isSelected: false,
            },
            {
              id: "e0a4740b-f25c-4e69-929a-c1e1d2361c0a",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 10,
              isSelected: false,
            },
          ],
          totalFeature: 10,
        },
        {
          id: "96816760-91f7-41b6-9d44-ce1ee979f26d",
          name: "Client engagement tools",
          description:
            "Client engagement software that the platform can send data to",
          order: 6,
          features: [
            {
              id: "d8e0e340-09ac-42cc-801d-7140a5666212",
              name: "Advice Revolution",
              description:
                "Data feed from platform to Advice Revolution available",
              order: 1,
              isSelected: false,
            },
            {
              id: "9eca1124-a56a-4f73-b411-08c2828d7ef8",
              name: "Astute Wheel",
              description: "Data feed from platform to Astute Wheel available",
              order: 2,
              isSelected: false,
            },
            {
              id: "afe4e5ae-45c8-481e-93fa-f1b4e0a6641d",
              name: "Finametrica",
              description: "Data feed from platform to Finametrica available",
              order: 3,
              isSelected: false,
            },
            {
              id: "21e03636-a20f-4e9a-9787-5bd4ca3e6388",
              name: "myprosperity",
              description: "Data feed from platform to myprosperity available",
              order: 4,
              isSelected: false,
            },
            {
              id: "97562b6b-eba1-4a57-8a77-7b52f0ae7818",
              name: "MoneySoft",
              description: "Data feed from platform to MoneySoft available",
              order: 5,
              isSelected: false,
            },
            {
              id: "ddf2b15e-5575-4453-8f64-6bf5064f1d3c",
              name: "Nod",
              description: "Data feed from platform to Nod available",
              order: 6,
              isSelected: false,
            },
            {
              id: "ffb60c12-e914-4de9-af92-b5831c42a215",
              name: "Other",
              description: "Data feed from platform to other tools available",
              order: 7,
              isSelected: false,
            },
            {
              id: "0f0c9594-de3f-4ce7-9e99-2ac3564e54bc",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 8,
              isSelected: false,
            },
          ],
          totalFeature: 8,
        },
        {
          id: "0b07b52e-6d43-4207-9022-9c1968ae6d95",
          name: "SMSF software",
          description: "SMSF software that the platform can send data to",
          order: 7,
          features: [
            {
              id: "17df177a-5429-4ff8-ac39-362d51e2cc6d",
              name: "BGL Simple Fund 360",
              description:
                "Data feed from platform to BGL Simple Fund 360 available",
              order: 1,
              isSelected: false,
            },
            {
              id: "dee7979b-75de-4665-996b-b76448ae8537",
              name: "Class Super",
              description: "Data feed from platform to Class Super available",
              order: 2,
              isSelected: false,
            },
            {
              id: "b1e94165-8e1b-4c3a-a523-76e676389be8",
              name: "SuperMate",
              description: "Data feed from platform to SuperMate available",
              order: 3,
              isSelected: false,
            },
            {
              id: "3f8bd9f8-a5f2-4a36-b32a-f39a54d1df35",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 4,
              isSelected: false,
            },
          ],
          totalFeature: 4,
        },
        {
          id: "22f6858e-2afe-4580-b097-3c7e1dfe476b",
          name: "Digital signature vendors",
          description:
            "Digital signature vendors integrated directly with the platform",
          order: 8,
          features: [
            {
              id: "ecda9559-a71a-4f4a-98c7-785c310f66e9",
              name: "DocuSign",
              description:
                "DocuSign is integrated directly into platform forms",
              order: 1,
              isSelected: false,
            },
            {
              id: "83a1c054-f903-49b8-beca-f7a1a578d516",
              name: "Adobe Sign",
              description:
                "Adobe Sign is integrated directly into platform forms",
              order: 2,
              isSelected: false,
            },
            {
              id: "772f9f48-2925-432a-b122-18ce6f030b41",
              name: "Other",
              description:
                "Other digital signatures can be integrated directly into platform forms",
              order: 3,
              isSelected: false,
            },
            {
              id: "2f18bdf9-da9f-455d-a858-d801fc81caf0",
              name: "Gallery",
              description:
                "Upload screenshots of your product that relate to this feature sub-group. Other SuitabilityHub users will be able to view these to assess your offering",
              order: 4,
              isSelected: false,
            },
          ],
          totalFeature: 4,
        },
      ],
      iconUrl:
        "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com/1670817678004Group.svg",
    },
  ],
};
