import { SocketServerEvent } from "@constants/socket";
import { HubConnectionState } from "@microsoft/signalr";
import {
  DefaultSocketErrorMessage,
  ProcessResponse,
  ProductEditingProcessAction,
  ProductEditingProcessResult,
} from "@models/core";
import {
  updateBeingKickedBy,
  updateEditMode,
  updateLoadingEditMode,
  updateShowBeingKickedDialog,
  updateShowClaimDeniedDialog,
  updateShowKickoutDialog,
  updateCurrentEditor,
} from "@redux/slices/product";
import { RootState, useAppDispatch } from "@redux/store";
import { SignalRService } from "@services/signalr/signalRService";
import { useSelector } from "react-redux";
import { useNotification } from "./useNotification";

export const useEditProduct = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { notify } = useNotification();
  const signalrConnection = SignalRService.getHubConnection();

  const checkEditProduct = async (
    productId: string,
    onSuccess?: () => void,
  ) => {
    dispatch(updateLoadingEditMode(true));
    if (
      signalrConnection &&
      signalrConnection.state === HubConnectionState.Connected
    ) {
      ///checking
      const checkingResponse: ProcessResponse<ProductEditingProcessResult> =
        await signalrConnection.invoke(
          SocketServerEvent.onProductEditorProcess,
          ProductEditingProcessAction.ProductEditorChecking,
          productId,
          null,
        );

      ///process
      if (checkingResponse) {
        switch (checkingResponse.resultType) {
          case ProductEditingProcessResult.EditingPermissionDenied:
            //show dialog cannot edit
            dispatch(updateShowClaimDeniedDialog(true));
            break;
          case ProductEditingProcessResult.EditingPermissionClaimConfirm:
            //show dialog confirm kick
            dispatch(updateShowKickoutDialog(true));
            dispatch(updateCurrentEditor(checkingResponse.content));
            break;
          case ProductEditingProcessResult.EditingPermissionAvailable:
            await handleOnClaimingEditingPermission(productId, onSuccess);
            break;
        }
        //button loading = false
        dispatch(updateLoadingEditMode(false));
      }
    } else {
      dispatch(updateLoadingEditMode(false));
      notify(DefaultSocketErrorMessage, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnClaimingEditingPermission = async (
    productId: string,
    onSuccess?: () => void,
  ) => {
    if (signalrConnection) {
      /// permission available, request claim
      const claimEditingResponse: ProcessResponse<ProductEditingProcessResult> =
        await signalrConnection.invoke(
          SocketServerEvent.onProductEditorProcess,
          ProductEditingProcessAction.ProductEditorClaiming,
          productId,
          user?.name,
        );

      switch (claimEditingResponse.resultType) {
        case ProductEditingProcessResult.EditingPermissionClaimFail:
          //claim fail show error
          break;
        case ProductEditingProcessResult.EditingPermissionDenied:
          //show dialog cannot edit
          dispatch(updateShowClaimDeniedDialog(true));
          break;
        case ProductEditingProcessResult.EditingPermissionClaimSuccessful:
          //edit mode = true
          dispatch(updateEditMode(true));
          //listen on management channel
          signalrConnection.on(
            SocketServerEvent.onProductEditingSectionManagement,
            handleOnProductEditingSectionManagement,
          );
          if (typeof onSuccess === "function") {
            onSuccess();
          }
          break;
      }
    }
  };

  const handleOnProductEditingSectionManagement = (
    result: ProcessResponse<ProductEditingProcessResult>,
    productId: string,
  ) => {
    if (
      result &&
      result.resultType ===
        ProductEditingProcessResult.EditingPermissionBeingDisposed
    ) {
      //set edit mode to view
      dispatch(updateEditMode(false));

      //result.content kicked you out
      //show dialog being kicked
      dispatch(updateBeingKickedBy(result.content));
      dispatch(updateShowBeingKickedDialog(true));

      if (signalrConnection) {
        //notify server that I received the message
        signalrConnection.invoke(
          SocketServerEvent.onProductEditorProcess,
          ProductEditingProcessAction.ProductEditingBeingDisposed,
          productId,
          null,
        );
      }
      // stop listen to management channel
      signalrConnection?.off(
        SocketServerEvent.onProductEditingSectionManagement,
      );
    }
  };

  const handleOnReleaseLockProductEditing = async (productId: string) => {
    if (
      signalrConnection &&
      signalrConnection.state === HubConnectionState.Connected
    ) {
      ///checking
      const releaseLockResponse: ProcessResponse<ProductEditingProcessResult> =
        await signalrConnection.invoke(
          SocketServerEvent.onProductEditorProcess,
          ProductEditingProcessAction.ProductEditingReleaseLock,
          productId,
          null,
        );

      ///process
      if (releaseLockResponse) {
        // stop listen to management channel
        signalrConnection.off(
          SocketServerEvent.onProductEditingSectionManagement,
        );
      }
    } else {
      notify(DefaultSocketErrorMessage, {
        variant: "error",
        close: true,
      });
    }
  };
  return {
    checkEditProduct,
    handleOnClaimingEditingPermission,
    handleOnProductEditingSectionManagement,
    handleOnReleaseLockProductEditing,
  };
};
