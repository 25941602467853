import { InformationButton } from "@components/buttons/information";
import {
  SHImage,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import {
  CheckSVG,
  CrossSVG,
  EssentialStarSVG,
  StarSVG,
} from "@components/svgs";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import {
  ReviewDataDTO,
  ReviewSectionDTO,
  ReviewStepFeatureContainerDTO,
} from "@models/reviews/entities/steps/feature";

import { useTheme } from "@mui/material";
import { SummaryAnalysisCell } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-cell";
import { SummaryAnalysisHeader } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-header";
import { SummaryAnalysisOverallScore } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-overall-score";
import { hexToRGBA } from "@utils";
import { isEmpty, some } from "lodash";
import { useMemo } from "react";

export interface FeatureReviewTableProps {
  featureReview?: ReviewStepFeatureContainerDTO | null;
  showSubgroup?: boolean;
  isDetailMode?: boolean;
}

export const FeatureReviewTable = ({
  featureReview,
  showSubgroup = true,
  isDetailMode = false,
}: FeatureReviewTableProps) => {
  const { palette } = useTheme();
  const selectedProducts = featureReview?.selectedProducts;
  const isExistsMissingEssential = useMemo(() => {
    return some(selectedProducts, { isMissingEssential: true });
  }, [selectedProducts]);

  let hasEssentialFeature = false;
  const renderAnalysisDataValue = (value?: AnalysisDataValue) => {
    if (value === AnalysisDataValue.True) return <CheckSVG />;
    if (value === AnalysisDataValue.False) return <CrossSVG />;
    if (value === AnalysisDataValue.MissingData || isEmpty(value))
      return (
        <SHTypography variant="body3" colorVariant="third" lineHeight="120%">
          No data
        </SHTypography>
      );
  };

  return (
    <SHStack spacing={"5px"}>
      <SHTableContainer
        sx={{
          overflow: "overlay",
          maxWidth: "max-content",
        }}
      >
        <SHTable
          style={{
            tableLayout: "auto",
            maxWidth: "max-content",
            borderCollapse: "separate",
          }}
        >
          <SummaryAnalysisHeader
            isFeatureAnalysis
            isDetailMode={isDetailMode}
            selectedProducts={selectedProducts}
          />
          <SHTableBody
            sx={{
              td: {
                borderLeft: "none",
                borderTop: "none",
              },
              "td:first-child": {
                borderLeft: `1px solid ${hexToRGBA(palette.primary[50], 0.3)}`,
              },
              ">tr:first-child td": {
                borderTop: `1px solid ${hexToRGBA(palette.primary[50], 0.3)}}`,
              },
            }}
          >
            <SummaryAnalysisOverallScore
              overallScore={featureReview?.overallScore}
              isFeatureAnalysis
            />
            {featureReview?.sections?.map((section, index) => (
              <>
                <SHTableRow key={index}>
                  <SummaryAnalysisCell cellColor={palette.common.white}>
                    <SHStack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent="space-between"
                    >
                      <SHStack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"5px"}
                      >
                        {section?.iconUrl && (
                          <SHImage
                            src={section?.iconUrl}
                            width={22}
                            height={22}
                          />
                        )}
                        <SHTypography variant="subtitle2" paddingTop={0}>
                          {section?.name}
                        </SHTypography>
                      </SHStack>
                    </SHStack>
                  </SummaryAnalysisCell>
                  <SummaryAnalysisCell cellColor={palette.common.white}>
                    <SHTypography
                      variant="subtitle2"
                      sx={{ textAlign: "center" }}
                    >
                      {section?.totalSelectedFeature}/{section?.totalFeature}
                    </SHTypography>
                  </SummaryAnalysisCell>
                  {section?.analysisData?.map(
                    (reviewData: ReviewDataDTO, index: number) => {
                      return (
                        <SummaryAnalysisCell
                          cellColor={palette.common.white}
                          key={index}
                        >
                          <SHTypography
                            variant="subtitle2"
                            sx={{ textAlign: "center", flexDirection: "row" }}
                          >
                            {reviewData?.totalScore} %
                            {reviewData?.isMissingEssential &&
                              !isDetailMode && (
                                <span style={{ color: palette.error.main }}>
                                  *
                                </span>
                              )}
                          </SHTypography>
                        </SummaryAnalysisCell>
                      );
                    },
                  )}
                </SHTableRow>

                {showSubgroup &&
                  section?.subSections?.map(
                    (subSection: ReviewSectionDTO, index: number) => (
                      <>
                        <SHTableRow key={subSection.id}>
                          <SummaryAnalysisCell
                            cellColor={palette.secondary[50]}
                          >
                            <SHStack
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent="space-between"
                            >
                              <SHStack
                                direction={"row"}
                                alignItems={"center"}
                                gap={"5px"}
                              >
                                <SHTypography
                                  variant="body1"
                                  sx={{
                                    fontWeight: 500,
                                    color: palette.text.third,
                                  }}
                                >
                                  {subSection?.name}
                                </SHTypography>
                              </SHStack>
                            </SHStack>
                          </SummaryAnalysisCell>
                          <SummaryAnalysisCell
                            cellColor={palette.secondary[50]}
                          >
                            <SHTypography
                              variant="body1"
                              sx={{
                                fontWeight: 500,
                                textAlign: "center",
                                color: palette.text.third,
                              }}
                            >
                              {subSection?.totalSelectedFeature}/
                              {subSection?.totalFeature}
                            </SHTypography>
                          </SummaryAnalysisCell>

                          {subSection?.analysisData?.map(
                            (reviewData, index) => {
                              // const platform = selectedProducts?.find(
                              //   (selectedProduct: ReviewSelectedProductDTO) =>
                              //     selectedProduct.id === reviewData.productId,
                              // );
                              return (
                                <SummaryAnalysisCell
                                  cellColor={palette.secondary[50]}
                                  key={index}
                                >
                                  <SHTypography
                                    variant="body1"
                                    sx={{
                                      fontWeight: 500,
                                      textAlign: "center",
                                      color: palette.text.third,
                                    }}
                                  >
                                    {reviewData?.totalScore} %
                                    {reviewData?.isMissingEssential &&
                                      !isDetailMode && (
                                        <span
                                          style={{ color: palette.error.main }}
                                        >
                                          *
                                        </span>
                                      )}
                                  </SHTypography>
                                </SummaryAnalysisCell>
                              );
                            },
                          )}
                        </SHTableRow>

                        {isDetailMode &&
                          subSection?.features?.map((feature) => {
                            if (feature.isEssential) hasEssentialFeature = true;
                            return (
                              <SHTableRow key={feature.id}>
                                <SummaryAnalysisCell
                                  cellColor={palette.secondary[50]}
                                >
                                  <SHStack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    spacing={2}
                                  >
                                    <SHTypography
                                      variant="body3"
                                      colorVariant="third"
                                      textAlign={"left"}
                                      lineHeight="120%"
                                    >
                                      {feature?.name}
                                    </SHTypography>
                                    <SHStack
                                      flexDirection={"row"}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                      spacing={1}
                                    >
                                      {feature?.isEssential && (
                                        <EssentialStarSVG />
                                      )}
                                      {feature?.description && (
                                        <InformationButton
                                          content={feature.description}
                                        />
                                      )}
                                    </SHStack>
                                  </SHStack>
                                </SummaryAnalysisCell>
                                <SummaryAnalysisCell
                                  cellColor={palette.secondary[50]}
                                ></SummaryAnalysisCell>
                                {feature?.analysisData?.map(
                                  (analysisData: ReviewDataDTO) => {
                                    return (
                                      <SummaryAnalysisCell
                                        key={analysisData?.productId}
                                        cellColor={palette.secondary[50]}
                                      >
                                        <SHStack
                                          direction={"row"}
                                          alignItems={"center"}
                                          justifyContent="flex-end"
                                        >
                                          <SHStack
                                            width={
                                              analysisData?.value !==
                                              "Missing data"
                                                ? 87
                                                : 104
                                            }
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent="space-between"
                                          >
                                            {renderAnalysisDataValue(
                                              analysisData?.value as AnalysisDataValue,
                                            )}
                                            {analysisData?.description && (
                                              <InformationButton
                                                content={
                                                  analysisData?.description
                                                }
                                              />
                                            )}
                                          </SHStack>
                                        </SHStack>
                                      </SummaryAnalysisCell>
                                    );
                                  },
                                )}
                              </SHTableRow>
                            );
                          })}
                      </>
                    ),
                  )}
              </>
            ))}
          </SHTableBody>
        </SHTable>
      </SHTableContainer>
      {isExistsMissingEssential && !isDetailMode && (
        <SHTypography
          variant="caption"
          fontWeight={600}
          color={palette.error.main}
          style={{ marginTop: "5px" }}
        >
          * Missing an essential feature
        </SHTypography>
      )}
      {hasEssentialFeature && isDetailMode && (
        <SHStack>
          <SHStack spacing={"5px"} alignItems={"center"} direction={"row"}>
            <StarSVG width={"13px"} height={"13px"} />
            <SHTypography
              variant="body1"
              colorVariant="third"
              paddingTop={"4px"}
            >
              Essential features
            </SHTypography>
          </SHStack>
        </SHStack>
      )}
    </SHStack>
  );
};
