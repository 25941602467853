import {
  SHAutocomplete,
  SHBox,
  SHButton,
  SHDivider,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableCell,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { SHDatePicker } from "@components/design-systems/sh-date-picker";
import UnsavedDialog from "@components/dialogs/unsaved";
import { StatusBadge } from "@components/status-badge";
import { ChevronSVG } from "@components/svgs/chevron";
import { PageRoutes } from "@constants";
import { usePractice } from "@hooks/usePractice";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { SxProps, Theme, useTheme } from "@mui/material";
import { hexToRGBA, toDateType } from "@utils";
import { isAfter, isBefore } from "date-fns";
import { isNull } from "lodash";
import { Controller, useForm, useFormState } from "react-hook-form";
import { licensesNumbers } from "./config";
import { PracticeSubscriptionSkeleton } from "./skeleton";
import { SubscriptionDTO } from "@models/practice/entities/practiceLicences";
import { useEffect } from "react";
import { useNotification } from "@hooks/useNotification";

export const PracticeSubscriptionAdmin = () => {
  const {
    practiceId,
    practice,
    practiceLicences,
    loadPracticeLicences,
    updatePracticeLicences,
    setPracticeLicence,
    ui: { isLoadingLicences },
  } = usePractice();
  const { palette } = useTheme();
  const { notify } = useNotification();
  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...new SubscriptionDTO() },
  });
  const { isDirty } = useFormState({ control });
  const isDisabled = isSubmitting;
  const isDisabledPeriodDate = !!(
    practiceLicences?.licencePaidCount || practiceLicences?.licenceFreeCount
  );

  const bodySx = {
    border: "none",
  };
  const rowSx: SxProps<Theme> = {
    backgroundColor: "transparent !important",
    border: "none",
  };

  const cellSx: SxProps<Theme> = {
    borderColor: hexToRGBA(palette.primary[50], 0.3),
    backgroundColor: hexToRGBA(palette.common.white, 0.25),
    backdropFilter: "blur(0.5px)",
    borderStyle: "solid",
    borderTopWidth: "1px",
    textAlign: "center",
    height: "57px",
  };
  const headerCellSx: SxProps<Theme> = {
    ...cellSx,
    borderWidth: "1px 0 1px 0",
    height: "54px",
  };
  const emptyCellSx: SxProps<Theme> = {
    width: "15px",
    backgroundColor: "transparent",
    p: 0,
  };

  const handleOnChangeTotals = (currTotalLicences: number) => {
    setValue(`totalLicences`, currTotalLicences, {
      shouldDirty: true,
      shouldValidate: true,
    });
    if (currTotalLicences < watch(`totalUsedLicences`)) {
      notify(
        "Please disable enough licences before trying to reduce the number of licences!",
        {
          variant: "error",
          close: true,
        },
      );
    }
  };

  const handleSubmitLicenses = async (practiceLicences: SubscriptionDTO) => {
    if (!practiceId) return;
    const newData = await updatePracticeLicences({
      practiceId,
      practiceLicences,
    });
    if (newData) {
      setPracticeLicence(practiceLicences);
      reset(practiceLicences);
    }
  };

  useEffect(() => {
    if (!practiceId) return;
    loadPracticeLicences(practiceId);
    // eslint-disable-next-line
  }, [practiceId]);

  useEffect(() => {
    reset({ ...practiceLicences });
    // eslint-disable-next-line
  }, [practiceLicences]);

  if (isLoadingLicences) return <PracticeSubscriptionSkeleton />;
  return (
    <form>
      <SHStack>
        <TopBarContainer>
          <TopBar
            navigate={{ to: PageRoutes.practices }}
            title={practice?.name}
            customBadge={
              practice?.status ? (
                <StatusBadge status={practice.status} />
              ) : undefined
            }
            tools={
              <>
                {isDirty && (
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    type="submit"
                    onClick={() => reset()}
                  >
                    Cancel
                  </SHButton>
                )}
                <SHButton
                  variant="contained"
                  size="extraMedium"
                  type="submit"
                  disabled={!isValid || isSubmitting || !isDirty}
                  isLoading={isSubmitting}
                  onClick={handleSubmit(handleSubmitLicenses)}
                >
                  Save
                </SHButton>
              </>
            }
          />
        </TopBarContainer>
        <FieldGroup
          firstLine
          title="Subscription information"
          subTitle={"Manage the subscription for the practice"}
        />
        <SHDivider />
        <SHStack
          direction={{ xs: "column", md: "row" }}
          mt={"25px"}
          spacing={{ xs: "25px", md: "52px" }}
        >
          <SHTypography variant="subtitle2">Plan details</SHTypography>
          <SHTableContainer sx={{ maxWidth: "800px" }}>
            <SHTable>
              <SHTableHead>
                <SHTableRow sx={rowSx}>
                  <SHTableCell width={"200px"}></SHTableCell>
                  <SHTableCell sx={emptyCellSx}></SHTableCell>
                  <SHTableCell
                    sx={{ ...headerCellSx, borderLeftWidth: "1px" }}
                    width={"150px"}
                  >
                    Paid licences (Stripe)
                  </SHTableCell>
                  <SHTableCell sx={{ ...headerCellSx }} width={"150px"}>
                    Free licences
                  </SHTableCell>
                  <SHTableCell sx={headerCellSx} width={"150px"}>
                    Total
                  </SHTableCell>
                  <SHTableCell
                    sx={{ ...headerCellSx, borderRightWidth: "1px" }}
                    width={"150px"}
                  >
                    In use
                  </SHTableCell>
                </SHTableRow>
              </SHTableHead>
              <SHTableBody sx={bodySx}>
                <SHTableRow sx={rowSx}>
                  <SHTableCell
                    sx={{
                      ...cellSx,
                      borderWidth: "1px",
                      textAlign: "left",
                    }}
                    width={"200px"}
                  >
                    Adviser licences
                  </SHTableCell>
                  <SHTableCell sx={emptyCellSx}></SHTableCell>
                  <SHTableCell
                    sx={{ ...cellSx, borderWidth: "1px 0 1px 1px" }}
                    width={"150px"}
                  >
                    <SHStack alignItems={"center"}>
                      <Controller
                        name={"licencePaidCount"}
                        control={control}
                        render={({ field }) => (
                          <SHAutocomplete
                            disableClearable
                            sx={{ width: "60px" }}
                            value={watch("licencePaidCount") ?? 0}
                            getOptionLabel={(option) => option.toString()}
                            options={licensesNumbers}
                            onChange={(event, value) => {
                              field.onChange(value);
                              handleOnChangeTotals(
                                value + watch("licenceFreeCount"),
                              );
                            }}
                            textFieldProps={{
                              error: !!errors?.licencePaidCount,
                            }}
                            noOptionsText="..."
                            disabled
                          />
                        )}
                      />
                    </SHStack>
                  </SHTableCell>
                  <SHTableCell sx={cellSx} width={"150px"}>
                    <SHStack alignItems={"center"}>
                      <Controller
                        name={"licenceFreeCount"}
                        control={control}
                        render={({ field }) => (
                          <SHAutocomplete
                            disableClearable
                            sx={{ width: "60px" }}
                            value={watch("licenceFreeCount") ?? 0}
                            getOptionLabel={(option) => option.toString()}
                            options={licensesNumbers}
                            onChange={(event, value) => {
                              field.onChange(value);
                              handleOnChangeTotals(
                                value + watch("licencePaidCount"),
                              );
                            }}
                            textFieldProps={{
                              error: !!errors?.licenceFreeCount,
                            }}
                            noOptionsText="..."
                            disabled={isDisabled}
                          />
                        )}
                      />
                    </SHStack>
                  </SHTableCell>
                  <SHTableCell sx={cellSx} width={"150px"}>
                    <SHStack>
                      <Controller
                        name={"totalLicences"}
                        control={control}
                        rules={{
                          min: watch("totalUsedLicences"),
                        }}
                        render={({ field }) => (
                          <SHTypography
                            variant="body3"
                            color={
                              !!errors.totalLicences
                                ? palette.error.main
                                : palette.text.primary
                            }
                          >
                            {field.value}
                          </SHTypography>
                        )}
                      />
                    </SHStack>
                  </SHTableCell>
                  <SHTableCell
                    sx={{ ...cellSx, borderRightWidth: "1px" }}
                    width={"150px"}
                  >
                    <SHStack>
                      <SHTypography variant="body3">
                        {watch(`totalUsedLicences`)}
                      </SHTypography>
                    </SHStack>
                  </SHTableCell>
                </SHTableRow>
                <SHTableRow sx={rowSx}>
                  <SHTableCell
                    sx={{
                      ...cellSx,
                      textAlign: "left",
                      borderWidth: "0 1px 1px 1px",
                    }}
                    width={"200px"}
                  >
                    Subscription period
                  </SHTableCell>
                  <SHTableCell sx={emptyCellSx}></SHTableCell>
                  <SHTableCell
                    sx={{
                      ...cellSx,
                      borderWidth: "1px 0 1px 1px",
                      position: "relative",
                    }}
                    width={"150px"}
                    colSpan={2}
                  >
                    <Controller
                      name={"subscriptionBegin"}
                      control={control}
                      rules={{
                        validate: (value) => {
                          const begin = toDateType(value);
                          const end = toDateType(getValues("subscriptionEnd"));
                          if (isNull(end) || isNull(begin)) return false;
                          return isBefore(begin, end);
                        },
                      }}
                      render={({ field }) => (
                        <SHDatePicker
                          maxDate={watch(`subscriptionEnd`)}
                          value={field.value}
                          disabled={isDisabled || isDisabledPeriodDate}
                          onChange={(newValue: Date | null) => {
                            field.onChange(newValue);
                            trigger();
                          }}
                          textFieldProps={{
                            error: !!errors?.subscriptionBegin,
                          }}
                        />
                      )}
                    />
                    <SHBox
                      sx={{
                        position: "absolute",
                        top: 30,
                        right: 0,
                        transform: "translate(10px,0)",
                      }}
                    >
                      <ChevronSVG />
                    </SHBox>
                  </SHTableCell>
                  <SHTableCell
                    colSpan={2}
                    sx={{
                      ...cellSx,
                      borderWidth: "1px 1px 1px 0",
                    }}
                    width={"150px"}
                  >
                    <Controller
                      name={`subscriptionEnd`}
                      control={control}
                      rules={{
                        validate: (value) => {
                          const end = toDateType(value);
                          const begin = toDateType(
                            getValues(`subscriptionBegin`),
                          );
                          if (isNull(end) || isNull(begin)) return false;
                          return isAfter(end, begin);
                        },
                      }}
                      render={({ field }) => (
                        <SHDatePicker
                          minDate={watch(`subscriptionBegin`)}
                          value={field.value}
                          disabled={isDisabled || isDisabledPeriodDate}
                          onChange={(newValue: Date | null) => {
                            field.onChange(newValue);
                            trigger();
                          }}
                          textFieldProps={{
                            error: !!errors?.subscriptionEnd,
                          }}
                        />
                      )}
                    />
                  </SHTableCell>
                </SHTableRow>
              </SHTableBody>
            </SHTable>
          </SHTableContainer>
        </SHStack>
      </SHStack>
      <UnsavedDialog isDirty={isDirty && !isSubmitting} />
    </form>
  );
};
