import { UserType } from "@models/auth";
import { FeesDisplayStyle } from "../../enums/fee/displayStyle";
import { FeeHeatmapMode } from "../../enums/fee/heatmapMode";
import { FeeMissingInvestmentsOption } from "../../enums/fee/missingInvestments";
import { AnalysisStepBusinessMetricDTO } from "./businessMetric";
import { AnalysisCommentDTO } from "../comment";
import { AnalysisFeatureIncludeDTO, AnalysisFeatureDTO } from "./feature";
import { AnalysisFeeDTO } from "./fee";
import { FeePortfolioDetailDTO } from "../portfolioDetails";
import { SelectedProductDTO } from "../selectedProduct";
import { HiddenSubProductDTO } from "../analysis";
import { TableViewMode } from "@models/platform-analysis/enums/tableViewMode";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";

export class AnalysisSummaryDTO {
  name: string = "";
  ownerId: string = "";
  ownerName: string = "";
  ownerType: UserType = UserType.SupplierPremiumOwner;
  ownerAvatarUrl: string | null = null;
  supplierId: string | null = null;
  supplierName: string | null = null;
  adviserName: string | null = null;
  practiceName: string | null = null;
  objectives: string | null = null;
  serial: string | null = null;
  concludingRemarks: string | null = null;
  hasFeatureStep: boolean = false;
  hasBusinessMetricStep: boolean = false;
  hasFeeStep: boolean = false;
  lastModifiedDate: Date | null = null;
  featureAnalysis: AnalysisFeatureDTO | null = null;
  businessMetricAnalysis: AnalysisStepBusinessMetricDTO | null = null;
  feeAnalysis: AnalysisFeeDTO | null = null;
  feePortfolioDetails: FeePortfolioDetailDTO | null = null;
  feesDisplayStyle: FeesDisplayStyle = FeesDisplayStyle.Dollar;
  feeMissingInvestmentsStyle: FeeMissingInvestmentsOption =
    FeeMissingInvestmentsOption.AllPlatform;
  investmentMenu: FeeInvestmentMenuOption =
    FeeInvestmentMenuOption.AllPlatform;
  feeTableViewMode: FeeHeatmapMode = FeeHeatmapMode.Projection;
  featureAnalysisTableViewMode: TableViewMode = TableViewMode.Default;
  analysisComments: AnalysisCommentDTO[] | null = null;
  selectedProducts: SelectedProductDTO[] | null = null;
  hiddenSubProducts: HiddenSubProductDTO[] | null = null;
  selectedFeatures: AnalysisFeatureIncludeDTO[] | null = null;
  showSubSection: boolean = true;
  showComments: boolean = true;
  showSelectedFeatures: boolean = true;
  showAnalysisScore: boolean = true;
  hideFeeAnalysisGraph: boolean = false;
}
