import { ArrowDownSVG } from "@components/svgs";
import { Collapse, CollapseProps } from "@mui/material";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { SHBox } from "../sh-box";
import { SHStack, SHStackProps } from "../sh-stack";

export interface SHCollapseProps
  extends Omit<SHStackProps, "children" | "title"> {
  title?: React.ReactNode;
  children?: React.ReactNode;
  displayArrow?: boolean;
  fullWidthTitle?: boolean;
  open?: boolean;
  onCollapse?: (open: boolean) => void;
  collapseProps?: CollapseProps;
  counterText?: React.ReactNode;
  arrowIcon?: React.ReactNode;
}

export const SHCollapse = ({
  title,
  children,
  displayArrow = true,
  fullWidthTitle = false,
  open: openProp = true,
  onCollapse,
  collapseProps,
  counterText,
  arrowIcon,
  ...others
}: SHCollapseProps): JSX.Element => {
  const [open, setOpen] = useState(openProp);
  const handleClickCollapse = () => {
    setOpen(!open);
    if (onCollapse) onCollapse(!open);
  };

  useEffect(() => {
    if (!isEqual(openProp, open)) setOpen(openProp);
    // eslint-disable-next-line
  }, [openProp]);

  return (
    <>
      <SHStack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        data-testid="sh-collaspe"
        onClick={handleClickCollapse}
        {...others}
      >
        <SHBox
          sx={{
            flexGrow: 1,
            marginRight: `${displayArrow && fullWidthTitle ? "-15px" : "0px"}`,
          }}
        >
          {title}
        </SHBox>
        {counterText}
        {displayArrow &&
          (arrowIcon ? (
            arrowIcon
          ) : (
            <SHBox
              alignItems={"center"}
              sx={{
                display: "flex",
                transition: "all ease-in-out 0.2s",
                transform: `rotate(${open ? 180 : 0}deg)`,
                ":hover": {
                  cursor: "pointer"
                }
              }}
              data-testid="sh-collaspe-arrow"
            >
              <ArrowDownSVG />
            </SHBox>
          ))}
      </SHStack>
      <Collapse in={open} timeout="auto" {...collapseProps}>
        {children}
      </Collapse>
    </>
  );
};
