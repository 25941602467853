import { SHTableCell, SHTableCellProps } from "@components/design-systems";

export const SubscriptionTableCell = ({
  children,
  width,
  colSpan,
  sx: sxProps,
}: {
  width?: string | number;
  children?: React.ReactNode;
  colSpan?: number;
} & SHTableCellProps) => {
  return (
    <SHTableCell
      colSpan={colSpan}
      sx={{
        width: width,
        height: "auto",
        padding: "12px 15px",
        alignItems: "center",
        ...sxProps,
      }}
    >
      {children}
    </SHTableCell>
  );
};
