import { DeleteButton } from "@components/buttons/delete";
import {
  SHAlert,
  SHButton,
  SHContainer,
  SHDivider,
  SHRichTextEditor,
  SHSelect,
  SHStack,
  SHTextField,
  SHUploadImage,
} from "@components/design-systems";
import { uploadOptions } from "@components/design-systems/sh-upload-file/config";
import UnsavedDialog from "@components/dialogs/unsaved";
import { PageRoutes } from "@constants/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useConfiguration } from "@hooks/useConfiguration";
import { useNotification } from "@hooks/useNotification";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar } from "@layouts/top-bar";
import {
  ConfigurationSection,
  ConfigurationSectionCreateDTO,
  ConfigurationSectionLov,
  ConfigurationSectionUpdateDTO,
} from "@models/configuration";
import { RootState } from "@redux/store";
import {
  createConfigSection,
  getConfigSection,
  getConfigSectionLov,
  updateConfigSection,
} from "@services/configuration";
import {
  deleteAvatar,
  uploadAvatar,
} from "@services/file-storage/fileStorageService";
import { renameFile } from "@utils/data-type/file";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { FileRejection } from "react-dropzone";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { constructSectionPath } from "../util";
import {
  formTitle,
  generateSectionTitle,
  sectionValidators,
  standardSectionValidators,
} from "./util";

interface ConfigurationSectionPageProps {
  isFeatureSubGroupSection?: boolean;
  isStandardSection?: boolean;
}

export const ConfigurationSectionPage = ({
  isFeatureSubGroupSection = false,
  isStandardSection = false,
}: ConfigurationSectionPageProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const group: any = searchParams.get("group") || "";
  const groupId = searchParams.get("groupId") || "";
  const sectionType: any = searchParams.get("sectionType") || "";
  const sectionId = searchParams.get("sectionId") || "";
  const subSectionId = searchParams.get("subSectionId") || "";

  const isNew = useMemo(() => {
    return sectionId === "new" || subSectionId === "new";
  }, [sectionId, subSectionId]);

  const { notify } = useNotification();
  const { updateDraftStatus } = useConfiguration();
  const [lov, setLov] = useState<ConfigurationSectionLov>();
  const [uploadMessage, setUploadMessage] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const {
    watch,
    reset,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...new ConfigurationSection() },
    resolver: yupResolver(
      isStandardSection ? standardSectionValidators : sectionValidators,
    ),
  });
  const { isDirty } = useFormState({ control });
  const title = generateSectionTitle(group, sectionType);

  const {
    configurationUI: { isEditMode },
  } = useSelector((state: RootState) => state.configuration);

  const isDisabled = useMemo(() => {
    return isSubmitting || !editMode;
  }, [isSubmitting, editMode]);

  useEffectOnce(() => {
    if (isEditMode || isNew) {
      setEditMode(true);
    }
    loadLov();
    loadSectionDetails();
  });

  const loadLov = async () => {
    const { data } = await getConfigSectionLov();
    setLov(data);
  };

  const loadSectionDetails = async () => {
    if (isNew) {
      reset({
        ...new ConfigurationSection(),
        configurationSectionId: sectionId,
      });
      return;
    }
    const { data, message } = await getConfigSection(
      groupId,
      sectionId,
      subSectionId,
    );
    if (data) {
      reset(data);
      return;
    }
    notify(message, {
      variant: "error",
      close: true,
    });
    navigate(PageRoutes.configuration);
  };

  const handleOnUpdateSection = async (formData: ConfigurationSection) => {
    if (isFeatureSubGroupSection && !formData.configurationSectionId) return;
    let config: ConfigurationSectionUpdateDTO = {
      name: formData.name,
      description: formData.description,
      sectionDisplayType: formData.sectionDisplayType?.id,
      iconS3Key: formData.iconUrlS3Key,
      iconUrl: formData.iconUrl,
    };

    if (isFeatureSubGroupSection) {
      config = {
        ...config,
        configurationSectionId: formData.configurationSectionId,
        configurationSubSectionId: subSectionId,
      };
    }
    const { isSuccess, message } = await updateConfigSection(
      groupId,
      sectionId,
      config,
    );
    notify(message, {
      variant: isSuccess ? "success" : "warning",
      close: true,
    });
    reset(getValues());
    updateDraftStatus();
    setEditMode(false);
    if (!isFeatureSubGroupSection) {
      navigate(constructSectionPath(group, groupId, sectionType, sectionId));
      return;
    }
    navigate(
      constructSectionPath(
        group,
        groupId,
        sectionType,
        formData.configurationSectionId,
        subSectionId,
      ),
    );
  };

  const handleOnCreateSection = async (formData: ConfigurationSection) => {
    let config: ConfigurationSectionCreateDTO = {
      name: formData.name,
      description: formData.description,
      sectionDisplayType: formData.sectionDisplayType?.id,
      iconS3Key: formData.iconUrlS3Key,
      iconUrl: formData.iconUrl,
    };
    if (isFeatureSubGroupSection)
      config = {
        ...config,
        configurationSectionId: formData.configurationSectionId,
      };

    const { data, isSuccess, message } = await createConfigSection(
      groupId,
      config,
    );
    if (!isSuccess) {
      notify(message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(message, {
      variant: "success",
      close: true,
    });
    reset(data);
    updateDraftStatus();
    setEditMode(false);
    if (!isFeatureSubGroupSection) {
      navigate(constructSectionPath(group, groupId, sectionType, data?.id));
      return;
    }
    navigate(
      constructSectionPath(
        group,
        groupId,
        sectionType,
        formData.configurationSectionId,
        data?.id,
      ),
    );
  };

  const handleOnArchiveSection = (data: any) => {};
  const handleOnDiscardChange = (data: any) => {};
  const handleOnUploadIcon = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (!isEmpty(fileRejections))
      setUploadMessage("Unsupported file format. Please upload another file.");

    if (isEmpty(acceptedFiles)) return;

    const file = new FormData();
    file.append("file", renameFile(acceptedFiles[0]));

    setIsUploading(true);
    const { data, message } = await uploadAvatar(file);
    if (data) {
      setValue("iconUrl", data?.url, uploadOptions.hookForm);
      setValue("iconUrlS3Key", data?.key, uploadOptions.hookForm);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setIsUploading(false);
  };

  const handleOnDeleteIcon = async () => {
    const avatarS3Key = getValues("iconUrlS3Key");
    setIsDeleting(true);
    const { isSuccess, message } = await deleteAvatar(avatarS3Key);
    setIsDeleting(false);
    if (isSuccess) {
      setValue("iconUrlS3Key", "", uploadOptions.hookForm);
      setValue("iconUrl", "", uploadOptions.hookForm);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <form>
        <SHStack sx={{ paddingY: 3 }}>
          <TopBar
            navigate={{ to: PageRoutes.configuration }}
            title={formTitle(watch("name"), group, sectionType)}
            tools={
              <>
                {isNew && (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                    onClick={handleSubmit(handleOnCreateSection)}
                  >
                    Save
                  </SHButton>
                )}
                {!editMode && (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    onClick={() => setEditMode(true)}
                  >
                    Edit
                  </SHButton>
                )}
                {!isNew && editMode && (
                  <>
                    <SHButton
                      variant="outlined"
                      size="extraMedium"
                      onClick={() => handleSubmit(handleOnArchiveSection)}
                      disabled={true}
                    >
                      Archive
                    </SHButton>
                    <SHButton
                      variant="outlined"
                      size="extraMedium"
                      onClick={() => handleSubmit(handleOnDiscardChange)}
                      disabled={true}
                    >
                      Discard changes
                    </SHButton>
                    <SHButton
                      variant="contained"
                      size="extraMedium"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                      isLoading={isSubmitting}
                      onClick={handleSubmit(handleOnUpdateSection)}
                    >
                      Update
                    </SHButton>
                  </>
                )}
              </>
            }
          />
          <FieldGroup
            title={title?.sectionInformation}
            subTitle={title?.sectionInformationSub}
            maxWidth={"100%"}
          />
          <SHDivider />
          <FieldGroup
            title={title?.sectionName}
            subTitle={title?.sectionNameSub}
            required
          >
            <Controller
              render={({ field }) => (
                <SHTextField
                  {...field}
                  hasCounter
                  maxLength={100}
                  sx={{ width: { xs: "100%", md: 520 } }}
                  placeholder={title?.sectionNamePlaceholder}
                  disabled={isDisabled}
                  error={!!errors.name}
                />
              )}
              name="name"
              control={control}
            />
          </FieldGroup>
          <SHDivider />
          <FieldGroup
            title="Description"
            subTitle={title?.sectionDescriptionSub}
          >
            <Controller
              render={({ field }) => (
                <SHRichTextEditor
                  maxLength={3000}
                  placeHolder="Description"
                  sx={{ width: { xs: "100%", md: 520 } }}
                  readOnly={isDisabled}
                  {...field}
                />
              )}
              name="description"
              control={control}
            />
          </FieldGroup>
          <SHDivider />
          {isStandardSection && (
            <>
              <FieldGroup
                title="Section type"
                subTitle={
                  "Determine if this section appears in the main body of the profile page or as a side panel"
                }
                required
              >
                <Controller
                  name="sectionDisplayType.id"
                  control={control}
                  render={({ field }) => (
                    <SHSelect
                      sx={{ width: { xs: "100%", md: 520 } }}
                      displayField="description"
                      dataItemKey="id"
                      data={lov?.sectionDisplayTypes ?? []}
                      disabled={isDisabled}
                      {...field}
                    />
                  )}
                />
              </FieldGroup>
              <SHDivider />
            </>
          )}

          {isFeatureSubGroupSection && (
            <FieldGroup
              title="Feature group"
              subTitle={
                "Which feature group does this feature sub-group belong to?"
              }
            >
              <Controller
                name="configurationSectionId"
                control={control}
                render={({ field }) => (
                  <SHSelect
                    sx={{ width: { xs: "100%", md: 520 } }}
                    displayField="name"
                    dataItemKey="id"
                    data={lov?.sections ?? []}
                    disabled={isDisabled}
                    placeholder="Select a feature group"
                    {...field}
                  />
                )}
              />
            </FieldGroup>
          )}

          {!isFeatureSubGroupSection && (
            <FieldGroup title="Icon" subTitle={title?.iconSub}>
              <Controller
                name="iconUrl"
                control={control}
                render={({ field }) => (
                  <SHUploadImage
                    showImage={!isEmpty(getValues("iconUrl"))}
                    isUploading={isUploading}
                    isCircular={false}
                    src={field.value}
                    actionIcon={
                      !isEmpty(getValues("iconUrlS3Key")) &&
                      isEditMode && (
                        <DeleteButton
                          isLoading={isDeleting}
                          onClick={handleOnDeleteIcon}
                        />
                      )
                    }
                    dropzoneOptions={{
                      multiple: false,
                      accept: {
                        "image/*": [".svg", ".png", ".jpg", ".gif"],
                      },
                      onDrop: handleOnUploadIcon,
                      disabled: isDisabled || isUploading,
                    }}
                  />
                )}
              />
              {uploadMessage && (
                <SHStack
                  direction={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  sx={{ paddingTop: 3 }}
                >
                  <SHAlert
                    onClose={() => setUploadMessage(null)}
                    severity="error"
                    width={490}
                  >
                    {uploadMessage}
                  </SHAlert>
                </SHStack>
              )}
            </FieldGroup>
          )}
        </SHStack>
        <UnsavedDialog isDirty={isDirty && !isSubmitting} />
      </form>
    </SHContainer>
  );
};
