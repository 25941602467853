import { ImageAutoComplete } from "@components/auto-completes/image";
import { SelectProducts } from "@components/auto-completes/products";
import { SelectRateCards } from "@components/auto-completes/rate-cards";
import { InformationButton } from "@components/buttons/information";
import {
  SHAutocomplete,
  SHBooleanField,
  SHBox,
  SHCheckbox,
  SHContainer,
  SHDivider,
  SHFormControlLabel,
  SHIconLoading,
  SHLabel,
  SHRadio,
  SHRichTextEditor,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import { CheckSVG, CloseSVG } from "@components/svgs";
import { LocalStorage, PageRoutes } from "@constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsNew } from "@hooks/useIsNew";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { useNotification } from "@hooks/useNotification";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import { useUserPermissions } from "@hooks/userUserPermission";
import { FieldGroup } from "@layouts/form/field-group";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { UserType } from "@models/auth";
import { FeatureTemplateSelectionDTO } from "@models/feature-template/entities/featureTemplate";
import { AnalysisSetupDTO } from "@models/platform-analysis/entities/steps/setup";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { UserLOV } from "@models/users/entities/user";
import { FormHelperText } from "@mui/material";
import { analysisLabel } from "@pages/platform-analysis/_id/config";
import { AnalysisSetupSkeleton } from "@pages/platform-analysis/_id/steps/setup/skeleton";
import {
  compareDirty,
  stepSetupValidators,
} from "@pages/platform-analysis/_id/steps/setup/util";
import { DiscardStageDialog } from "@pages/platform-analysis/components/dialogs/discard-stage";
import { RefreshPlatformDialog } from "@pages/platform-analysis/components/dialogs/refresh-platform";
import { repairUpdateAnalysis } from "@redux/slices/platform-analysis/util";
import { RootState } from "@redux/store";
import { getExistingFeatureTemplate } from "@services/feature-template/featureTemplateService";
import {
  checkAnalysisUniqueName,
  getPlatformAnalysisOwners,
} from "@services/platform-analysis/platformAnalysisService";
import { isEmpty, take, isEqual, compact } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router";
import { useEffectOnce } from "react-use";
const { Feature, BusinessMetric, Fee } = AnalysisStep;

export interface AnalysisSetupProps extends StepCompProps {}
export const AnalysisSetup: ForwardRefRenderFunction<
  StepCompRef,
  AnalysisSetupProps
> = ({ stepNumber }, ref) => {
  const { notify } = useNotification();
  const navigate = useNavigate();
  const isNew = useIsNew();
  const { analysisId } = useParams<{ analysisId: string }>();
  const { supplierData } = useSelector((state: RootState) => state.supplier);
  const {
    ui: { isLoading, isSubmitting },
    platformAnalysis,
    isReadOnly,
  } = useSelector((state: RootState) => state.platformAnalysis);
  const { user } = useUserPermissions();
  const {
    setIsDirty,
    setIsValid,
    setBannerName,
    setStepHidden,
    createNewPlatformAnalysis,
    updateCurrentPlatformAnalysis,
    loadPlatformAnalysis,
    setPlatformAnalysis,
    reachAnalysisSetupPage,
    setReachAnalysisSetupPage,
  } = usePlatformAnalysis();
  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();
  const [verifyAnalysisName, setVerifyAnalysisName] = useState<
    "error" | "success" | "checking" | "none"
  >(isNew ? "none" : "success");
  const [ownerData, setOwnerData] = useState<UserLOV[]>([]);
  const [confirmStageChange, setConfirmStageChange] = useState<
    | {
        callback: (value: boolean | PromiseLike<boolean>) => void;
        changedStage: number;
      }
    | undefined
  >(undefined);
  const [confirmPlatformChange, setConfirmPlatformChange] = useState<
    | {
        callback: (value: boolean | PromiseLike<boolean>) => void;
        showDialog: boolean;
      }
    | undefined
  >(undefined);
  const [platformHasBeenChanged, setPlatformHasBeenChanged] = useState(false);
  const [initFeatureTemplates, setInitFeatureTemplates] = useState<
    FeatureTemplateSelectionDTO[]
  >([]);
  const [optionFeatureTemplates, setOptionFeatureTemplates] = useState<
    FeatureTemplateSelectionDTO[]
  >([]);

  const timer = useRef<NodeJS.Timeout | null>(null);
  const {
    reset,
    getValues,
    watch,
    control,
    trigger,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...new AnalysisSetupDTO() },
    resolver: yupResolver(stepSetupValidators),
  });
  const isDisabled = isSubmitting || isLoading || isReadOnly;
  const adviserName = watch("adviserName") || "";
  const practiceName = watch("practiceName") || "";
  const hasMainProduct = watch("hasMainProduct");
  const otherProductIds = watch("otherProductIds");
  const mainProductId = watch("mainProductId");
  const isEmptyAdviserName = isEmpty(adviserName.trim());
  const isEmptyPracticeName = isEmpty(practiceName.trim());

  const allProductIds = useMemo(() => {
    const otherIds = otherProductIds || [];
    return hasMainProduct && mainProductId
      ? [mainProductId, ...otherIds]
      : otherIds;
  }, [hasMainProduct, mainProductId, otherProductIds]);

  const isGetLastedConfig = useMemo(() => {
    if (isEmpty(allProductIds)) {
      setValue("hasRateCard", false);
      setValue("selectRateCards", []);
    }
    const initProductIds = [
      platformAnalysis?.setup?.mainProductId,
      ...(platformAnalysis?.setup?.otherProductIds ?? []),
    ];
    const currentProductIds = allProductIds;

    return !isEqual(
      compact(initProductIds).sort(),
      compact(currentProductIds).sort(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProductIds]);

  const [verifyAnalysisNameErrorMessage, setVerifyAnalysisNameErrorMessage] =
    useState("");

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep) => {
      return await handleOnSubmit();
    },
    onChangeOtherStep: async () => {
      return true;
    },
    onSubmit: async () => {
      return await handleOnSubmit();
    },
  }));

  const getConfirmStageChange = async (
    changedStage: number,
  ): Promise<boolean> => {
    return await new Promise((res) => {
      setConfirmStageChange({
        callback: res,
        changedStage,
      });
    });
  };

  const getConfirmPlatformChange = async (): Promise<boolean> => {
    if (platformHasBeenChanged || isNew) return true;

    let result: boolean = true;
    const reachAnalysisSetupPageLocalStorage = getLocalStorageItem(
      LocalStorage.PlatformAnalysisReachAnalysisSetupPage,
    );

    const showConfirmDialog =
      reachAnalysisSetupPage === "FIRST_TIME" ||
      reachAnalysisSetupPageLocalStorage === "FIRST_TIME";

    if (showConfirmDialog) {
      result = await new Promise((res) => {
        setConfirmPlatformChange({
          showDialog: true,
          callback: res,
        });
      });
      setReachAnalysisSetupPage("SECOND_TIME");
      setLocalStorageItem(
        LocalStorage.PlatformAnalysisReachAnalysisSetupPage,
        "SECOND_TIME",
      );
      setConfirmPlatformChange(undefined);
    }

    if (result) {
      setPlatformHasBeenChanged(true);
      setValue("hasRateCard", false);
      setValue("selectRateCards", []);
    }
    return result;
  };

  const handleOnSubmit = async () => {
    const newValues = getValues();

    if (!newValues.hasFeeStep) {
      newValues.hasRateCard = false;
      newValues.selectRateCards = [];
    }

    const { changedStage } = compareDirty(platformAnalysis?.setup, newValues);
    if (!isNew && changedStage) {
      const isYes = await getConfirmStageChange(changedStage);
      setConfirmStageChange(undefined);
      if (!isYes) {
        return false;
      }
    }

    let isSuccess = true;
    if (isNew) {
      setIsDirty(false);
      const newAnalysis = await createNewPlatformAnalysis(newValues);
      isSuccess = !!newAnalysis;
      setIsDirty(!isSuccess);
      if (isSuccess) {
        reset(newValues);
        navigate(
          generatePath(PageRoutes.platformAnalysisDetail, {
            analysisId: newAnalysis?.id,
          }),
          {
            replace: true,
          },
        );
      }
      return isSuccess;
    }
    if (!isDirty) return true;
    if (!platformAnalysis) return false;
    let response = await updateCurrentPlatformAnalysis({
      ...repairUpdateAnalysis(platformAnalysis),
      currentStep: AnalysisStep.Setup,
      setup: { ...newValues, hasRefreshData: platformHasBeenChanged },
    });
    if (!!response) {
      reset(newValues);
      setPlatformHasBeenChanged(false);
      setPlatformAnalysis({
        ...platformAnalysis,
        setup: newValues,
        configurationVersion: response
          ? response.configurationVersion
          : platformAnalysis.configurationVersion,
      });
      return true;
    }
    return false;
  };

  const clearTimer = () => {
    if (timer.current) clearTimeout(timer.current);
  };

  const handleOnChecking = (name: string | null) => {
    clearTimer();
    if (!name || name.trim().length === 0 || !supplierData?.id) {
      setVerifyAnalysisName("none");
      return;
    }
    if (
      platformAnalysis?.setup?.name === name &&
      getValues("ownerId") === platformAnalysis?.setup?.ownerId
    ) {
      setVerifyAnalysisName("success");
      return;
    }
    setVerifyAnalysisName("checking");
    timer.current = setTimeout(async () => {
      const { isSuccess, message } = await checkAnalysisUniqueName(
        supplierData?.id,
        name,
        getValues("ownerId") ?? "",
      );
      if (isSuccess) {
        setVerifyAnalysisName("success");
      } else {
        setVerifyAnalysisName("error");
        setVerifyAnalysisNameErrorMessage(message!);
      }
    }, 300);
  };

  const updateHasSteps = (stepKey: AnalysisStep, isChecked: boolean) => {
    let currentSteps = getValues("hasSteps") ?? [];
    if (isChecked) {
      currentSteps.push(stepKey);
    } else {
      currentSteps = currentSteps.filter((key) => key !== stepKey);
    }
    setValue("hasSteps", currentSteps, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const getOwnerData = async () => {
    const { isSuccess, data, message } = await getPlatformAnalysisOwners();
    if (isSuccess) {
      setOwnerData(data ?? []);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const loadFeatureTemplates = async (filterByFeatureTemplateName?: string) => {
    const { data } = await getExistingFeatureTemplate(
      filterByFeatureTemplateName,
    );
    setInitFeatureTemplates(data ?? []);
    setOptionFeatureTemplates(take(data, 20) ?? []);
  };

  const loadAnalysis = async () => {
    if (!isNew && analysisId) {
      const analysis = await loadPlatformAnalysis({
        platformAnalysisId: analysisId,
        stepId: AnalysisStep.Setup,
      });
      if (analysis && analysis.setup) {
        reset(analysis.setup);
      }
    }
  };

  useEffectOnce(() => {
    loadAnalysis();
    getOwnerData();
    loadFeatureTemplates();
    return () => {
      setIsValid(true);
      setIsDirty(false);
      clearTimer();
    };
  });

  useEffect(() => {
    if (!supplierData) return;
    setValue("supplierId", supplierData.id);
    setValue("supplierName", supplierData.companyName);
    setBannerName({
      supplierName: supplierData.companyName,
    });
    // eslint-disable-next-line
  }, [supplierData]);

  useEffect(() => {
    if (!isNew) return;
    setValue("ownerId", user?.auth0Id ?? "");
    setValue("ownerName", user?.name ?? "");
    setValue("ownerAvatarUrl", user?.avatarUrl ?? "");
    setValue("ownerType", user?.userType ?? "");
    setBannerName({
      ownerName: user?.name,
      ownerType: user?.userType,
    });
    // eslint-disable-next-line
  }, [isNew, user?.name, user?.auth0Id, user?.avatarUrl]);

  useEffect(() => {
    setIsValid(isValid && verifyAnalysisName === "success");
    // eslint-disable-next-line
  }, [isValid, verifyAnalysisName]);

  useEffect(() => {
    setIsDirty(isDirty || platformHasBeenChanged);
    // eslint-disable-next-line
  }, [isDirty]);

  const renderVerifyAnalysisName = () => {
    switch (verifyAnalysisName) {
      case "success":
        return <CheckSVG width={18} height={18} />;
      case "error":
        return (
          <SHBox px={"4px"} py={"1px"}>
            <CloseSVG width={10} height={10} />
          </SHBox>
        );
      case "checking":
        return <SHIconLoading size={16} />;
      default:
        return null;
    }
  };
  if (isLoading) return <AnalysisSetupSkeleton />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 }, py: "25px" }}>
      <form>
        <SHStack>
          <StepName name={"Analysis setup"} stepNumber={stepNumber} />
          <FieldGroup
            title="Analysis details"
            subTitle={
              "Give your analysis a name and assign an owner. This will help you find the analysis in the future. Analysis names must be unique within your organisation"
            }
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <Controller
                name="ownerId"
                control={control}
                render={({ field }) => (
                  <ImageAutoComplete
                    sx={{ width: "100%" }}
                    textFieldProps={{
                      label: "Owner",
                      placeholder: "Select an owner",
                      helperText: errors?.ownerId?.message,
                      error: !!errors.ownerId,
                      required: true,
                    }}
                    value={{
                      auth0Id: watch("ownerId"),
                      name: watch("ownerName"),
                      avatarUrl: watch("ownerAvatarUrl"),
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.auth0Id === value.auth0Id
                    }
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.auth0Id);
                      setValue("ownerName", newValue?.name ?? "", {
                        shouldDirty: true,
                      });
                      setValue("ownerAvatarUrl", newValue?.avatarUrl ?? "", {
                        shouldDirty: true,
                      });
                      setValue("ownerType", newValue?.userType ?? "", {
                        shouldDirty: true,
                      });
                      setBannerName({
                        ownerName: newValue?.name,
                        ownerType: newValue?.userType,
                      });
                      handleOnChecking(getValues("name"));
                    }}
                    labelField={"name"}
                    imageField={"avatarUrl"}
                    options={ownerData}
                    disabled={isDisabled}
                  />
                )}
              />
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <SHTextField
                    {...field}
                    hasCounter
                    maxLength={90}
                    required
                    sx={{ width: "100%" }}
                    label={"Name of analysis"}
                    placeholder={"Enter a unique name"}
                    disabled={isDisabled}
                    error={!!errors.name}
                    helperText={
                      errors?.name?.message ??
                      (verifyAnalysisName === "error"
                        ? verifyAnalysisNameErrorMessage
                        : null)
                    }
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      handleOnChecking(event.target.value);
                    }}
                    InputProps={{
                      sx: {
                        "&>input": {
                          pr: "32px !important",
                        },
                      },
                      endAdornment: (
                        <SHStack
                          sx={{
                            position: "absolute",
                            right: "8px",
                          }}
                        >
                          {renderVerifyAnalysisName()}
                        </SHStack>
                      ),
                    }}
                  />
                )}
              />
              <SHStack direction={"column"}>
                <SHLabel
                  label={"What should your platform analysis include?"}
                  disabled={isDisabled}
                  required
                />
                <Controller
                  render={({ field: { ref, ...field } }) => (
                    <SHFormControlLabel
                      value={Feature}
                      control={<SHCheckbox />}
                      label={analysisLabel[Feature]}
                      checked={field.value}
                      onChange={(event: any) => {
                        const checked = event.target.checked;
                        setStepHidden({
                          step: Feature,
                          isHidden: !checked,
                        });
                        field.onChange(checked);
                        updateHasSteps(Feature, checked);
                      }}
                      disabled={isDisabled}
                    />
                  )}
                  name="hasFeatureStep"
                  control={control}
                />
                <Controller
                  render={({ field: { ref, ...field } }) => (
                    <SHFormControlLabel
                      value={BusinessMetric}
                      control={<SHCheckbox />}
                      label={analysisLabel[BusinessMetric]}
                      checked={field.value}
                      onChange={(event: any) => {
                        const checked = event.target.checked;
                        setStepHidden({
                          step: BusinessMetric,
                          isHidden: !checked,
                        });
                        field.onChange(checked);
                        updateHasSteps(BusinessMetric, checked);
                      }}
                      disabled={isDisabled}
                    />
                  )}
                  name="hasBusinessMetricStep"
                  control={control}
                />
                <Controller
                  render={({ field: { ref, ...field } }) => (
                    <SHFormControlLabel
                      value={Fee}
                      control={<SHCheckbox />}
                      label={analysisLabel[Fee]}
                      checked={field.value}
                      onChange={(event: any) => {
                        const checked = event.target.checked;
                        setStepHidden({
                          step: Fee,
                          isHidden: !checked,
                        });
                        field.onChange(checked);
                        updateHasSteps(Fee, checked);
                      }}
                      disabled={isDisabled}
                    />
                  )}
                  name="hasFeeStep"
                  control={control}
                />
                <FormHelperText error>
                  {errors?.hasSteps?.message}
                </FormHelperText>
              </SHStack>
            </SHStack>
          </FieldGroup>
          {watch("ownerType") === UserType.SupplierBdmSale && (
            <>
              <SHDivider />
              <FieldGroup
                title="Who is this analysis for?"
                subTitle={
                  "To provide a more personalised report, you can specify which adviser and advice practice you are running this analysis for. Their name will appear throughout the analysis. You can search the analysis by adviser name"
                }
                required={!isEmptyPracticeName || !isEmptyAdviserName}
              >
                <SHStack
                  spacing={3}
                  direction="column"
                  sx={{ width: { xs: "100%", md: 520 } }}
                >
                  <Controller
                    name="adviserName"
                    control={control}
                    render={({ field }) => (
                      <SHTextField
                        {...field}
                        required={!isEmptyPracticeName}
                        sx={{ width: "100%" }}
                        label={"Adviser name"}
                        placeholder={"Enter adviser name"}
                        disabled={isDisabled}
                        error={!!errors.adviserName}
                        maxLength={100}
                        hasCounter
                        helperText={errors?.adviserName?.message}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          trigger("practiceName");
                          setBannerName({
                            adviserName: event.target.value,
                          });
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="practiceName"
                    control={control}
                    render={({ field }) => (
                      <SHTextField
                        {...field}
                        required={!isEmptyAdviserName}
                        sx={{ width: "100%" }}
                        label={"Practice name"}
                        placeholder={"Enter practice name"}
                        disabled={isDisabled}
                        maxLength={100}
                        hasCounter
                        error={!!errors.practiceName}
                        helperText={errors?.practiceName?.message}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          trigger("adviserName");
                          setBannerName({
                            practiceName: event.target.value,
                          });
                        }}
                      />
                    )}
                  />
                </SHStack>
              </FieldGroup>
            </>
          )}
          <SHDivider />
          <FieldGroup
            title="Objectives"
            subTitle={
              "Articulate the objectives of this analysis. This will appear on the final  report"
            }
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <Controller
                name="objectives"
                control={control}
                render={({ field: { ref, ...others } }) => (
                  <SHRichTextEditor
                    {...others}
                    maxLength={3000}
                    placeHolder="Enter your objectives for this analysis"
                    height={165}
                    readOnly={isDisabled}
                  />
                )}
              />
            </SHStack>
          </FieldGroup>
          <SHDivider />
          <FieldGroup
            title="Select main platform for analysis"
            subTitle={
              "This platform will take the primary position in your analysis"
            }
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <Controller
                name={"hasMainProduct"}
                control={control}
                render={({ field: { ref, ...other } }) => (
                  <SHBooleanField
                    {...other}
                    isSwitch
                    isShowClearButton={false}
                    value={other.value}
                    onChange={async (event, value?: boolean | null) => {
                      if (value || !getValues("mainProductId")) {
                        other.onChange(value);
                        return;
                      }
                      if (await getConfirmPlatformChange()) {
                        setValue("mainProductId", null);

                        other.onChange(value);
                      }
                    }}
                    label={"Would you like to select a main platform?"}
                    disabled={isDisabled}
                  />
                )}
              />
              {watch("hasMainProduct") && (
                <Controller
                  name={"mainProductId"}
                  control={control}
                  render={({ field: { ref, ...other } }) => (
                    <SelectProducts
                      {...other}
                      disabled={isDisabled}
                      onChange={async (value) => {
                        const currentOtherIds =
                          getValues("otherProductIds") ?? [];
                        const newOtherIds = currentOtherIds.filter(
                          (id) => id !== value,
                        );
                        if (
                          !other.value &&
                          currentOtherIds.length !== newOtherIds.length
                        ) {
                          other.onChange(value);
                          setValue("otherProductIds", newOtherIds, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                          return;
                        }
                        if (await getConfirmPlatformChange()) {
                          other.onChange(value);
                          setValue("otherProductIds", newOtherIds, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }
                      }}
                      supplierId={supplierData?.id}
                      textFieldProps={{
                        label: "Select one from below",
                        placeholder: "Search for platform",
                        required: true,
                        error: !!errors?.mainProductId,
                        helperText: errors?.mainProductId?.message,
                      }}
                    />
                  )}
                />
              )}
            </SHStack>
          </FieldGroup>
          <SHDivider />
          <FieldGroup
            title="Select other platforms"
            subTitle={
              "Including the main platform, up to five platforms can be included in the  analysis. For audit purposes, the data in this analysis is locked to the date it was created. However, adding or changing platforms once the analysis has started will refresh all platform data to the latest version. Any rate card selections will clear"
            }
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <Controller
                name={"otherProductIds"}
                control={control}
                render={({ field: { ref, ...other } }) => (
                  <SelectProducts
                    {...other}
                    disabled={isDisabled}
                    multiple
                    maxLength={5}
                    mainPlatformId={watch("mainProductId")}
                    textFieldProps={{
                      label:
                        "Select up to five platforms to include in your analysis",
                      placeholder: "Search for platform",
                      required: true,
                      error: !!errors?.otherProductIds,
                      helperText: errors?.otherProductIds?.message,
                    }}
                    onChange={async (values) => {
                      if (await getConfirmPlatformChange()) {
                        other.onChange(values);
                      }
                    }}
                  />
                )}
              />
            </SHStack>
          </FieldGroup>

          {watch("hasFeatureStep") && (
            <>
              <SHDivider />
              <FieldGroup
                title="Load a template"
                subTitle={
                  "You can either choose each platform feature to include in your analysis one by one, or you can load from a template or analysis your firm has created"
                }
              >
                <SHStack
                  spacing={3}
                  direction="column"
                  sx={{ width: { xs: "100%", md: 520 } }}
                >
                  <SHStack direction="column">
                    <Controller
                      name={"loadTemplateId"}
                      control={control}
                      render={({ field: { ref, ...other } }) => (
                        <SHAutocomplete
                          disabled={isDisabled}
                          textFieldProps={{
                            InputLabelProps: { shrink: true },
                            label:
                              "Load feature selection from a saved template",
                            placeholder: "Search for template",
                          }}
                          options={optionFeatureTemplates ?? []}
                          getOptionLabel={(
                            option: FeatureTemplateSelectionDTO,
                          ) => option?.name ?? ""}
                          renderOption={(props, option, { selected }) => {
                            const isChecked = selected;
                            return (
                              <li
                                {...props}
                                key={option?.id}
                                style={{ padding: "1px 10px" }}
                              >
                                <SHStack
                                  alignItems={"center"}
                                  direction="row"
                                  spacing={1}
                                >
                                  <SHRadio checked={isChecked} />
                                  <SHTypography
                                    variant="body3"
                                    fontWeight={500}
                                  >
                                    {`${option.name} - ${option.selectedFeatures}/${option.totalFeatures} features selected`}
                                  </SHTypography>
                                  {option?.notes && (
                                    <InformationButton
                                      content={option?.notes}
                                    />
                                  )}
                                </SHStack>
                              </li>
                            );
                          }}
                          sx={{ width: { xs: "100%", md: 520 } }}
                          onChange={(event, value) => {
                            other.onChange(value?.id);
                            setOptionFeatureTemplates(initFeatureTemplates);
                          }}
                          onInputChange={(e, newValue) => {
                            if (isEmpty(newValue)) {
                              setOptionFeatureTemplates(
                                take(initFeatureTemplates, 20) ?? [],
                              );
                            } else {
                              setOptionFeatureTemplates(
                                initFeatureTemplates ?? [],
                              );
                            }
                          }}
                        />
                      )}
                    />
                    {/* TODO: Implement later */}
                    {/* <Link
                      variant="body2"
                      color={
                        isReadOnly
                          ? palette.text.disabled
                          : palette.text.secondary
                      }
                      component="label"
                      underline="none"
                      onClick={() => {}}
                      sx={{
                        paddingRight: 0.5,
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                    >
                      View all feature selection templates
                    </Link> */}
                  </SHStack>
                  {/* TODO: Implement later */}
                  {/* <Controller
                    name={"loadAnalysisId"}
                    control={control}
                    render={({ field: { ref, ...other } }) => (
                      <SHAutocomplete
                        disabled={isDisabled}
                        options={[]}
                        textFieldProps={{
                          InputLabelProps: { shrink: true },
                          label: "Load feature selection from another analysis",
                          placeholder: "Search for an analysis",
                        }}
                      />
                    )}
                  /> */}
                </SHStack>
              </FieldGroup>
            </>
          )}
          {watch("hasFeeStep") && (
            <>
              <SHDivider />
              <FieldGroup
                title="Rate cards"
                subTitle={
                  "Choose which rate cards to include in your platform fee analysis. Only rate cards set as “Available” are shown"
                }
              >
                <SHStack
                  spacing={3}
                  direction="column"
                  sx={{ width: { xs: "100%", md: 520 } }}
                >
                  <Controller
                    name={"hasRateCard"}
                    control={control}
                    render={({ field: { ref, ...other } }) => (
                      <SHBooleanField
                        {...other}
                        isSwitch
                        isShowClearButton={false}
                        value={other.value}
                        onChange={(event, value?: boolean | null) => {
                          other.onChange(value);
                          if (!value) {
                            setValue("selectRateCards", [], {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          }
                        }}
                        label={"Include rate cards?"}
                        disabled={isDisabled}
                      />
                    )}
                  />
                  {watch("hasRateCard") && (
                    <Controller
                      name={"selectRateCards"}
                      control={control}
                      render={({ field: { ref, ...other } }) => (
                        <SelectRateCards
                          {...other}
                          configurationVersion={
                            isGetLastedConfig
                              ? undefined
                              : platformAnalysis?.configurationVersion
                          }
                          disabled={isDisabled}
                          //Refresh list rate cards
                          analysisId={
                            platformHasBeenChanged ? undefined : analysisId
                          }
                          productIds={allProductIds}
                          onChange={(value) => {
                            other.onChange(value || []);
                          }}
                          textFieldProps={{
                            label:
                              "Select rate cards to include in your analysis",
                            placeholder: "Search for rate cards",
                          }}
                        />
                      )}
                    />
                  )}
                </SHStack>
              </FieldGroup>
            </>
          )}
        </SHStack>
      </form>
      {confirmStageChange && (
        <DiscardStageDialog
          changedStage={confirmStageChange.changedStage}
          onClose={() => confirmStageChange.callback(false)}
          onSubmit={() => {
            confirmStageChange.callback(true);
          }}
        />
      )}
      {confirmPlatformChange?.showDialog && (
        <RefreshPlatformDialog
          onClose={() => confirmPlatformChange.callback(false)}
          onSubmit={() => {
            confirmPlatformChange.callback(true);
          }}
        />
      )}
    </SHContainer>
  );
};
