import { InformationButton } from "@components/buttons/information";
import { configTooltipStyle } from "@components/buttons/information/config";
import {
  SHBox,
  SHButton,
  SHCheckbox,
  SHHtmlBlock,
  SHPopper,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableFooter,
  SHTableHead,
  SHTableRow,
  SHToggleButtonOptions,
  SHTypography,
} from "@components/design-systems";
import { TooltipAllowSVG } from "@components/svgs";
import { DateFormat } from "@constants/format";
import { useDebounce } from "@hooks/useDebounce";
import { usePractice } from "@hooks/usePractice";
import {
  AdditionalCostDTO,
  SubscriptionPricingInfoDTO,
  UpdateRenewalDTO,
  UserSubscriptionDTO,
} from "@models/practice/entities/practiceLicences";
import { Card, useTheme } from "@mui/material";
import { UpdateQuantityLicenceButton } from "@pages/practices/_id/_subscription/practice/components/buttons";
import { SubscriptionTableCell } from "@pages/practices/_id/_subscription/practice/components/tables";
import { CENT_UNIT } from "@pages/practices/_id/_subscription/practice/config";
import { hexToRGBA } from "@utils";
import { format } from "date-fns";
import { isEmpty, isNil } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { ProcessPaymentDialog } from "../dialogs/process";

interface SubscriptionLeftSideProps {
  pricingInfo?: SubscriptionPricingInfoDTO;
  userSubscription: UserSubscriptionDTO;
  initialUserSubscription: UserSubscriptionDTO;
  onChangeSubscription?: (data: UserSubscriptionDTO) => void;
  onChangeInitialSubscription?: (data: UserSubscriptionDTO) => void;
}

export const SubscriptionLeftSide = ({
  pricingInfo,
  userSubscription,
  onChangeSubscription,
  initialUserSubscription,
  onChangeInitialSubscription,
}: SubscriptionLeftSideProps) => {
  const { subscriptionEnd, paid } = initialUserSubscription;
  const isExistsLicense = paid > 0;

  const title = isExistsLicense
    ? `<p>Current subscription: ${paid} ${
        paid > 1 ? "&nbspLicences" : "&nbspLicence"
      } expire on ${format(subscriptionEnd ?? new Date(), DateFormat)}</p>`
    : "<p>Current subscription: Free trial</p>";

  return (
    <SHStack
      spacing={"25px"}
      mt={"-15px"}
      width={{ lg: "60%", md: "60%", sm: "100%" }}
    >
      <SHHtmlBlock content={title} variant="h2" textAlign={"left"} />

      <AdditionalSubscription
        pricingInfo={pricingInfo}
        userSubscription={userSubscription}
        onChangeSubscription={onChangeSubscription}
        initialUserSubscription={initialUserSubscription}
        onChangeInitialSubscription={onChangeInitialSubscription}
      />
      {isExistsLicense && (
        <RenewalSubscription
          pricingInfo={pricingInfo}
          userSubscription={userSubscription}
          onChangeSubscription={onChangeSubscription}
          initialUserSubscription={initialUserSubscription}
          onChangeInitialSubscription={onChangeInitialSubscription}
        />
      )}
    </SHStack>
  );
};

// AdditionalSubscription section
const AdditionalSubscription = ({
  pricingInfo,
  userSubscription,
  initialUserSubscription,
  onChangeSubscription = () => {},
  onChangeInitialSubscription = () => {},
}: SubscriptionLeftSideProps) => {
  const { remainingDays } = userSubscription;

  const { palette } = useTheme();

  const {
    practiceId,
    loadLicencesAdditionalCost,
    createPracticeUserLicences,
    setIsDirtySubscription,
    ui: { isLoadingProceedPayment, isLoadingAdditionalCost },
  } = usePractice();

  const [changingQuantity, setChangingQuantity] = useState(false);
  const [licencesQuantity, setLicenceQuantity] = useState(0);
  const [totalAdditionalCost, setTotalAdditionalCost] = useState(0);
  const [additionalCost, setAdditionalCost] = useState<AdditionalCostDTO>();

  const [showPaymentMethodDialog, setShowPaymentMethodDialog] = useState(false);

  const debouncedQuantity = useDebounce(licencesQuantity, 500);

  let isExistsLicence = initialUserSubscription?.paid > 0;
  const isExistsFreeLicence = initialUserSubscription?.free > 0;

  const title = !isExistsLicence
    ? "<p>Select licence quantity:</p>"
    : `<p>Purchase additional licences. We will only charge you for the <span>${remainingDays} ${
        remainingDays > 1 ? "days" : "day"
      }</span> remaining in your subscription:</p>`;

  const handleOnChangeQuantity = async () => {
    if (!practiceId) return;

    if (!licencesQuantity) {
      setTotalAdditionalCost(0);
      return;
    }

    if (!isExistsLicence) {
      setTotalAdditionalCost(licencesQuantity * licenseCost);
      return;
    }

    setChangingQuantity(true);
    const stripeCost = await loadLicencesAdditionalCost(
      practiceId,
      licencesQuantity,
    );
    setChangingQuantity(false);
    if (stripeCost) {
      setTotalAdditionalCost(stripeCost.total);
      setAdditionalCost(stripeCost);
    }
  };

  const handleOnProcessPayment = async () => {
    if (!practiceId) return;
    let checkoutSessionUrl: string | undefined;
    if (!isExistsLicence) {
      checkoutSessionUrl = await createPracticeUserLicences(
        practiceId,
        licencesQuantity,
      );
    } else {
      setShowPaymentMethodDialog(true);
    }

    if (checkoutSessionUrl) {
      // Reset is Dirty state
      setIsDirtySubscription(false);
      const timer = setTimeout(() => {
        window.location.assign(`${checkoutSessionUrl}`);
      }, 500);
      return () => clearTimeout(timer);
    }
  };

  const licenseCost = useMemo(() => {
    return pricingInfo?.mainPricePlusGST ?? 0;
  }, [pricingInfo]);

  const adviserLicenceCostValue = useMemo(() => {
    return licencesQuantity * licenseCost;
  }, [licencesQuantity, licenseCost]);

  const totalAdditionalCostValue = useMemo(() => {
    return isExistsLicence
      ? totalAdditionalCost / CENT_UNIT
      : totalAdditionalCost;
  }, [isExistsLicence, totalAdditionalCost]);

  useEffect(() => {
    handleOnChangeQuantity();
    // eslint-disable-next-line
  }, [debouncedQuantity]);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: `1px solid #${palette.secondary[100]}`,
          backgroundColor: hexToRGBA(palette.secondary[200], 0.1),
          boxShadow: `10px 10px 40px 0px ${hexToRGBA("#DDDDDD", 0.4)}`,
          backdropFilter: "blur(0.5px)",
        }}
      >
        <SHStack p={"15px"} gap={"10px"}>
          <SHHtmlBlock
            content={title}
            variant="body1"
            textAlign={"left"}
            sx={{
              "& span": {
                fontWeight: 700,
              },
            }}
          />
          <SHStack gap={"15px"} alignItems={"center"}>
            <SHTableContainer sx={{ overflowY: "hidden" }}>
              <SHTable>
                <SHTableHead>
                  <SHTableRow
                    sx={{
                      height: "54px",
                      borderBottomWidth: 1,
                      backgroundColor: "transparent !important",
                    }}
                  >
                    <SubscriptionTableCell
                      width={isExistsFreeLicence ? "42%" : "46%"}
                    >
                      <SHTypography variant="body1" colorVariant="third">
                        Licence
                      </SHTypography>
                    </SubscriptionTableCell>
                    <SubscriptionTableCell
                      width={isExistsFreeLicence ? "38%" : "30%"}
                    >
                      <SHTypography variant="body1" colorVariant="third">
                        Amount
                      </SHTypography>
                    </SubscriptionTableCell>
                    <SubscriptionTableCell>
                      <SHTypography variant="body1" colorVariant="third">
                        Cost (inc GST)
                      </SHTypography>
                    </SubscriptionTableCell>
                  </SHTableRow>
                </SHTableHead>
                <SHTableBody>
                  <SHTableRow
                    sx={{
                      height: "54px",
                      borderBottomWidth: 1,
                      backgroundColor: "transparent !important",
                    }}
                  >
                    <SubscriptionTableCell>
                      <SHTypography variant="subtitle1">
                        Adviser Licence
                      </SHTypography>
                    </SubscriptionTableCell>
                    <SubscriptionTableCell>
                      <UpdateQuantityLicenceButton
                        isDisabled={changingQuantity}
                        licenceQuantity={licencesQuantity}
                        onChange={(quantity) => {
                          setLicenceQuantity(quantity);
                          onChangeSubscription({
                            ...userSubscription,
                            paid: initialUserSubscription?.paid + quantity,
                          });
                        }}
                      />
                    </SubscriptionTableCell>
                    <SubscriptionTableCell>
                      <SHTypography variant="subtitle1">
                        <NumericFormat
                          displayType="text"
                          value={adviserLicenceCostValue}
                          thousandSeparator=","
                          prefix="$"
                          decimalScale={
                            adviserLicenceCostValue % 1 !== 0 ? 2 : 0
                          }
                          fixedDecimalScale={adviserLicenceCostValue % 1 !== 0}
                        />
                      </SHTypography>
                    </SubscriptionTableCell>
                  </SHTableRow>
                </SHTableBody>
                <SHTableFooter sx={{ borderBottom: "none" }}>
                  <SHTableRow
                    sx={{
                      height: "54px",
                      border: "none",
                      backgroundColor: "transparent !important",
                    }}
                  >
                    <SubscriptionTableCell colSpan={2}>
                      <SHTypography
                        variant="subtitle1"
                        sx={{ fontSize: "20px", fontWeight: 700 }}
                      >
                        Additional cost (inc GST)
                      </SHTypography>
                    </SubscriptionTableCell>

                    <SubscriptionTableCell>
                      {changingQuantity ? (
                        <SHTypography variant="body2" colorVariant="disabled">
                          Calculating...
                        </SHTypography>
                      ) : (
                        <SHTypography
                          variant="subtitle1"
                          sx={{ fontSize: "20px", fontWeight: 700 }}
                        >
                          <NumericFormat
                            displayType="text"
                            value={totalAdditionalCostValue}
                            thousandSeparator=","
                            prefix="$"
                            decimalScale={
                              totalAdditionalCostValue % 1 !== 0 ? 2 : 0
                            }
                            fixedDecimalScale={
                              totalAdditionalCostValue % 1 !== 0
                            }
                          />
                        </SHTypography>
                      )}
                    </SubscriptionTableCell>
                  </SHTableRow>
                </SHTableFooter>
              </SHTable>
            </SHTableContainer>
            <SHButton
              variant="contained"
              sx={{ width: 255, height: 50, fontSize: 16 }}
              onClick={handleOnProcessPayment}
              isLoading={isLoadingProceedPayment}
              disabled={
                isLoadingProceedPayment ||
                !licencesQuantity ||
                isLoadingAdditionalCost
              }
            >
              Proceed to payment
            </SHButton>
          </SHStack>
        </SHStack>
      </Card>
      {showPaymentMethodDialog && (
        <ProcessPaymentDialog
          quantity={licencesQuantity}
          prorationDate={additionalCost?.prorationDate}
          onClose={() => setShowPaymentMethodDialog(false)}
          onSubmit={() => {
            // Reset dirty state then refresh page
            setIsDirtySubscription(false);
            const timer = setTimeout(() => {
              window.location.reload();
            }, 500);
            return () => clearTimeout(timer);
          }}
        />
      )}
    </>
  );
};

// RenewalSubscription section
const RenewalSubscription = ({
  pricingInfo,
  initialUserSubscription,
  userSubscription,
  onChangeSubscription = () => {},
  onChangeInitialSubscription = () => {},
}: SubscriptionLeftSideProps) => {
  const {
    renewalUsers,
    free,
    unallocatedPaid,
    originalUnallocatedPaid,
    allocatedFree,
  } = userSubscription;
  const { palette, shadows } = useTheme();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const {
    practiceId,
    updateLicencesRenewal,
    ui: { isLoadingUpdateRenewal },
  } = usePractice();

  const [totalRenewalCost, setTotalRenewalCost] = useState(0);
  const [changedRenewalSettings, setChangedRenewalSettings] = useState(false);

  const isExistsFreeLicence = free > 0;
  const tooltipConfig = configTooltipStyle["top-start"];

  const handleHover = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isValidAllocatedFree) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setOpen((prev) => !prev);
    }
  };

  const handleOnUpdateRenewal = async () => {
    if (!practiceId) return;
    const updateRenewalDto = {
      unallocatedPaid: userSubscription.unallocatedPaid,
      renewalUsers: userSubscription.renewalUsers,
    } as UpdateRenewalDTO;

    const res = await updateLicencesRenewal(practiceId, updateRenewalDto);
    if (res) {
      // Assign new data
      onChangeInitialSubscription({ ...userSubscription });
    }
  };

  const currentAppliedFreeUserIds = useMemo(() => {
    return (
      userSubscription?.renewalUsers
        ?.filter((item) => item.applyFreeLicence)
        ?.map((item) => item.id) ?? []
    );
  }, [userSubscription]);

  const licenseeCost = useMemo(() => {
    return pricingInfo?.mainPricePlusGST ?? 0;
  }, [pricingInfo]);

  const isValidAllocatedFree = useMemo(() => {
    return currentAppliedFreeUserIds?.length === allocatedFree;
  }, [currentAppliedFreeUserIds, allocatedFree]);

  useEffect(() => {
    const totalLicenceRenewal =
      renewalUsers?.filter((item) => item.isRenew)?.length ?? 0;

    const totalCost = (totalLicenceRenewal + unallocatedPaid) * licenseeCost;
    setTotalRenewalCost(totalCost);
  }, [free, renewalUsers, unallocatedPaid, licenseeCost]);

  useEffect(() => {
    const isDirty =
      unallocatedPaid !== initialUserSubscription.unallocatedPaid ||
      JSON.stringify(renewalUsers) !==
        JSON.stringify(initialUserSubscription.renewalUsers);

    setChangedRenewalSettings(isDirty);
  }, [initialUserSubscription, renewalUsers, unallocatedPaid]);

  return (
    <Card
      variant="outlined"
      sx={{
        border: `1px solid #${palette.secondary[100]}`,
        backgroundColor: hexToRGBA(palette.secondary[200], 0.1),
        boxShadow: `10px 10px 40px 0px ${hexToRGBA("#DDDDDD", 0.4)}`,
        backdropFilter: "blur(0.5px)",
      }}
    >
      <SHStack p={"15px"} gap={"10px"}>
        <SHTypography variant="body1">Renewal settings</SHTypography>
        <SHStack gap={"15px"} alignItems={"center"}>
          <SHTableContainer sx={{ overflowY: "hidden" }}>
            <SHTable>
              <SHTableHead>
                <SHTableRow
                  sx={{
                    height: "54px",
                    borderBottomWidth: 1,
                    backgroundColor: "transparent !important",
                  }}
                >
                  <SubscriptionTableCell
                    width={isExistsFreeLicence ? "42%" : "46%"}
                  >
                    <SHTypography variant="body1" colorVariant="third">
                      Adviser
                    </SHTypography>
                  </SubscriptionTableCell>
                  <SubscriptionTableCell
                    width={isExistsFreeLicence ? "18%" : "30%"}
                  >
                    <SHTypography variant="body1" colorVariant="third">
                      Auto-renew
                    </SHTypography>
                  </SubscriptionTableCell>
                  {isExistsFreeLicence && (
                    <SubscriptionTableCell width={"20%"}>
                      <SHTypography variant="body1" colorVariant="third">
                        Apply free licence
                        <InformationButton
                          content={
                            "Free licences can only be applied when the number of advisers exceeds the number that has been paid for plus the number of free licences that have been allocated"
                          }
                        />
                      </SHTypography>
                    </SubscriptionTableCell>
                  )}

                  <SubscriptionTableCell>
                    <SHTypography variant="body1" colorVariant="third">
                      Cost (inc GST)
                    </SHTypography>
                  </SubscriptionTableCell>
                </SHTableRow>
              </SHTableHead>
              <SHTableBody sx={{ border: "none" }}>
                {!isEmpty(renewalUsers) ? (
                  renewalUsers?.map(
                    ({ id, name, isRenew, applyFreeLicence }) => {
                      const isDisabled =
                        // renewalUsers?.length === totalPaidLicenceInUsed ||
                        !currentAppliedFreeUserIds?.includes(id) &&
                        currentAppliedFreeUserIds?.length === allocatedFree;

                      return (
                        <SHTableRow
                          key={id}
                          sx={{
                            height: "54px",
                            borderBottomWidth: 1,
                            backgroundColor: "transparent !important",
                          }}
                        >
                          <SubscriptionTableCell>
                            <SHTypography variant="subtitle1">
                              {name}
                            </SHTypography>
                          </SubscriptionTableCell>
                          <SubscriptionTableCell>
                            <SHStack width={86}>
                              <SHToggleButtonOptions
                                value={
                                  !isNil(isRenew)
                                    ? isRenew?.toString()
                                    : "false"
                                }
                                options={[
                                  { label: "Yes", value: "true" },
                                  { label: "No", value: "false" },
                                ]}
                                onChange={(event, value) => {
                                  onChangeSubscription({
                                    ...userSubscription,
                                    renewalUsers: renewalUsers.map((item) => {
                                      if (item.id !== id) return item;
                                      return {
                                        ...item,
                                        isRenew: value === "true",
                                      };
                                    }),
                                  });
                                }}
                                width={40}
                                disabled={applyFreeLicence}
                              />
                            </SHStack>
                          </SubscriptionTableCell>
                          {isExistsFreeLicence && (
                            <SubscriptionTableCell>
                              <SHCheckbox
                                checked={applyFreeLicence}
                                disabled={isDisabled}
                                onChange={(event) => {
                                  const checked = event.target.checked;
                                  onChangeSubscription({
                                    ...userSubscription,
                                    renewalUsers: renewalUsers.map((item) => {
                                      if (item.id !== id) return item;
                                      return {
                                        ...item,
                                        applyFreeLicence: checked,
                                        isRenew: checked ? false : item.isRenew,
                                      };
                                    }),
                                  });
                                }}
                              />
                            </SubscriptionTableCell>
                          )}

                          <SubscriptionTableCell>
                            <SHTypography variant="subtitle1">
                              <NumericFormat
                                displayType="text"
                                value={isRenew ? licenseeCost : 0}
                                thousandSeparator=","
                                prefix="$"
                                decimalScale={
                                  (isRenew ? licenseeCost : 0) % 1 !== 0 ? 2 : 0
                                }
                                fixedDecimalScale={
                                  (isRenew ? licenseeCost : 0) % 1 !== 0
                                }
                              />
                            </SHTypography>
                          </SubscriptionTableCell>
                        </SHTableRow>
                      );
                    },
                  )
                ) : (
                  <SHTableRow
                    sx={{
                      height: "55px",
                      border: "none",
                      backgroundColor: "transparent !important",
                    }}
                  >
                    <SubscriptionTableCell
                      colSpan={isExistsFreeLicence ? 4 : 3}
                      sx={{ textAlign: "center" }}
                    >
                      <SHTypography variant="body2">
                        Adviser users have not yet been created.
                      </SHTypography>
                    </SubscriptionTableCell>
                  </SHTableRow>
                )}
              </SHTableBody>

              <SHTableBody>
                <SHTableRow
                  sx={{
                    height: "54px",
                    borderBottomWidth: 1,
                    backgroundColor: "transparent !important",
                  }}
                >
                  <SubscriptionTableCell>
                    <SHTypography variant="subtitle1" lineHeight={1.2}>
                      Unallocated licences <br /> to renew
                    </SHTypography>
                  </SubscriptionTableCell>
                  <SubscriptionTableCell>
                    <UpdateQuantityLicenceButton
                      width={originalUnallocatedPaid >= 10 ? "106px" : "86px"}
                      max={originalUnallocatedPaid}
                      licenceQuantity={unallocatedPaid}
                      onChange={(count) =>
                        onChangeSubscription({
                          ...userSubscription,
                          unallocatedPaid: count,
                        })
                      }
                      customText={
                        <SHTypography
                          variant="body2"
                          fontWeight={600}
                          paddingTop={"3px"}
                        >
                          {`${unallocatedPaid} of ${originalUnallocatedPaid}`}
                        </SHTypography>
                      }
                    />
                  </SubscriptionTableCell>
                  {isExistsFreeLicence && (
                    <SubscriptionTableCell></SubscriptionTableCell>
                  )}
                  <SubscriptionTableCell>
                    <SHTypography variant="subtitle1">
                      <NumericFormat
                        displayType="text"
                        value={unallocatedPaid * licenseeCost}
                        thousandSeparator=","
                        prefix="$"
                        decimalScale={
                          (unallocatedPaid * licenseeCost) % 1 !== 0 ? 2 : 0
                        }
                        fixedDecimalScale={
                          (unallocatedPaid * licenseeCost) % 1 !== 0
                        }
                      />
                    </SHTypography>
                  </SubscriptionTableCell>
                </SHTableRow>
                {isExistsFreeLicence && (
                  <SHTableRow
                    sx={{
                      height: "54px",
                      backgroundColor: "transparent !important",
                    }}
                  >
                    <SubscriptionTableCell>
                      <SHTypography variant="subtitle1">
                        Total free licences
                      </SHTypography>
                    </SubscriptionTableCell>
                    <SubscriptionTableCell>
                      <SHTypography
                        variant="subtitle1"
                        sx={{ fontSize: "20px", fontWeight: 700 }}
                      >
                        {free}
                      </SHTypography>
                    </SubscriptionTableCell>
                    <SubscriptionTableCell></SubscriptionTableCell>
                    <SubscriptionTableCell></SubscriptionTableCell>
                  </SHTableRow>
                )}
              </SHTableBody>

              <SHTableFooter sx={{ borderBottom: "none" }}>
                <SHTableRow
                  sx={{
                    height: "54px",
                    border: "none",
                    backgroundColor: "transparent !important",
                  }}
                >
                  <SubscriptionTableCell colSpan={2}>
                    <SHTypography
                      variant="subtitle1"
                      sx={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      Total renewal (inc GST)
                    </SHTypography>
                  </SubscriptionTableCell>
                  {isExistsFreeLicence && (
                    <SubscriptionTableCell></SubscriptionTableCell>
                  )}
                  <SubscriptionTableCell>
                    <SHTypography
                      variant="subtitle1"
                      sx={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      <NumericFormat
                        displayType="text"
                        value={Math.max(0, totalRenewalCost)}
                        thousandSeparator=","
                        prefix="$"
                        decimalScale={totalRenewalCost % 1 !== 0 ? 2 : 0}
                        fixedDecimalScale={totalRenewalCost % 1 !== 0}
                      />
                    </SHTypography>
                  </SubscriptionTableCell>
                </SHTableRow>
              </SHTableFooter>
            </SHTable>
          </SHTableContainer>

          <SHBox onMouseOver={handleHover} onMouseLeave={handleHover}>
            <SHButton
              variant="contained"
              sx={{ width: 255, height: 50, fontSize: 16 }}
              onClick={handleOnUpdateRenewal}
              isLoading={isLoadingUpdateRenewal}
              disabled={
                isLoadingUpdateRenewal ||
                !changedRenewalSettings ||
                !isValidAllocatedFree
              }
            >
              Update renewal
            </SHButton>
          </SHBox>

          <SHPopper
            anchorEl={anchorEl}
            open={open}
            placement={"top-start"}
            popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
            sx={{ zIndex: 1400 }}
          >
            <SHBox pb={1}>
              <SHStack
                ref={wrapperRef}
                sx={{
                  p: 1.5,
                  ml: "130px",
                  maxWidth: "356px",
                  minHeight: "20px",
                  bgcolor: hexToRGBA(palette.common.white, 0.85),
                  border: "1px solid #E3E3E3",
                  backdropFilter: "blur(2px)",
                  borderRadius: "3px",
                  boxShadow: shadows[1],
                  position: "relative",
                }}
              >
                <SHHtmlBlock
                  variant="body3"
                  zIndex={1}
                  color={palette.text.disabled}
                  content={
                    "More free licences must be allocated to 'Update Renewal'. Otherwise, buy more licences in the 'Purchase additional licences' widget above"
                  }
                />
                <SHBox sx={tooltipConfig?.sx}>
                  <TooltipAllowSVG transform={tooltipConfig?.transform} />
                </SHBox>
              </SHStack>
            </SHBox>
          </SHPopper>
        </SHStack>
      </SHStack>
    </Card>
  );
};
