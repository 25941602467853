import { PlusButton } from "@components/buttons/plus";
import { SHStack, SHTypography } from "@components/design-systems";
import { PageRoutes } from "@constants";
import { useInvestment } from "@hooks/useInvestment";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { generatePath, useNavigate } from "react-router";

export const ManagedAccountListView = (): JSX.Element => {
  const navigate = useNavigate();

  const { resetStore } = useInvestment();

  return (
    <>
      <TopBarContainer>
        <TopBar
          title={"Managed account analysis"}
          tools={
            <>
              <PlusButton
                variant="contained"
                size="extraMedium"
                onClick={() => {
                  resetStore();
                  navigate(
                    generatePath(PageRoutes.managedAccountsDetail, {
                      managedAccountId: "new",
                    }),
                  );
                }}
              >
                New analysis
              </PlusButton>
            </>
          }
        />
      </TopBarContainer>
      <SHStack sx={{ marginTop: "25px" }} spacing={2}>
        <SHTypography variant="subtitle1">
          Exciting News: SMA Analysis is Coming Soon!
        </SHTypography>
        <SHTypography variant="body2">
          We're thrilled to introduce the prototype of our Portfolio Fee
          Analysis feature as part of SMA Analysis Phase 1. You can be one of
          the first to trial this new capability by clicking the “New Analysis”
          button at the top right of this page.
        </SHTypography>
        <SHTypography variant="body2">
          Your feedback is invaluable to us. Please share your thoughts and
          suggestions by reaching out to Support@SuitabilityHub.com. Together,
          we can refine this feature to best meet your business needs.
        </SHTypography>
        <SHStack>
          <SHTypography variant="body2">
            Thank you for your continued support,
          </SHTypography>
          <SHTypography variant="body2">The SuitabilityHub Team</SHTypography>
        </SHStack>
      </SHStack>
    </>
  );
};
