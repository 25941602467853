export enum InvestmentProductStatus {
  Active = "Active",
  Disabled = "Disabled",
  Archived = "Archived",
}

export enum InvestmentApprovalStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum InvestmentDataStatus {
  MissingData = "Missing data",
  Filled = "Filled",
}

export enum InvestmentProductDataTab {
  Manager = "Manager",
  Series = "Series",
  Models = "Models",
  Availability = "Availability",
  ESG = "ESG",
}