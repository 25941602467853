import { BusinessMetricTypeId, FieldTypeId } from "@models/configuration";
import { AnalysisDataDTO } from "@models/platform-analysis/entities/steps/feature";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import { useTheme } from "@mui/material";
import { PDFTable } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables";
import { PDFTableBody } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-body";
import { PDFTableCell } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-cell";
import { HorizontalLine } from "@pages/platform-analysis/components/buttons/export-pdf/components/line";
import { PDFSummaryAnalysisHeader } from "@pages/platform-analysis/components/buttons/export-pdf/components/tables/components/summary-analysis-header";
import { PDFSummaryOverallScore } from "@pages/platform-analysis/components/buttons/export-pdf/components/tables/components/summary-overall-score";
import { BusinessMetricIcons } from "@pages/platform-analysis/components/buttons/export-pdf/config";
import {
  DEFAULT_GAP_3,
  TABLE_CELL_WIDTH,
  TABLE_FIRST_CELL_WIDTH,
  TITLE_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { PDFBusinessAnalysis } from "@pages/platform-analysis/components/buttons/export-pdf/model";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { PDFReviewTableRow } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-row";
import { Image, Path, Svg, Text, View } from "@react-pdf/renderer";
import { isEmpty, isNil } from "lodash";
interface PDFBusinessMetricAnalysisTableProps {
  businessMetricAnalysis?: PDFBusinessAnalysis;
  isTableExpand?: boolean;
}

export const PDFBusinessAnalysisSection = ({
  businessMetricAnalysis,
  isTableExpand = false,
}: PDFBusinessMetricAnalysisTableProps) => {
  const { typographyStyle } = useStyles();
  const { palette } = useTheme();
  let showNote = false;

  const renderBooleanValue = (value: boolean) => {
    return value ? (
      <Svg width="12" height="12" viewBox="0 0 19 19">
        <Path
          d="m3.333 9.265 4.5 4.5 7.5-9"
          stroke="#219653"
          strokeWidth="2"
          strokeLineCap="round"
        />
      </Svg>
    ) : (
      <Text style={{ ...typographyStyle.body1, color: palette.error.main }}>
        x
      </Text>
    );
  };

  const renderPlatformAnalysisValue = (analysisData: AnalysisDataDTO) => {
    if (analysisData?.value === AnalysisDataValue.MissingData)
      return (
        <Text style={{ ...typographyStyle.body2, color: palette.text.primary }}>
          No data
        </Text>
      );
    if (analysisData?.fieldTypeId === FieldTypeId.YesNo)
      return renderBooleanValue(analysisData.value === "true");
    if (analysisData?.fieldTypeId === FieldTypeId.TextShort)
      return (
        <View
          style={{
            gap: DEFAULT_GAP_3,
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Text
            style={{ ...typographyStyle.body2, color: palette.text.primary }}
          >
            {analysisData.value}
          </Text>
          {!isNil(analysisData?.isPositiveScore) &&
            renderBooleanValue(analysisData?.isPositiveScore)}
        </View>
      );
    if (analysisData?.fieldTypeId === FieldTypeId.Number)
      return (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{ ...typographyStyle.body2, color: palette.text.primary }}
          >
            {analysisData.value}
          </Text>
          {!isNil(analysisData?.isPositiveScore) &&
            renderBooleanValue(analysisData?.isPositiveScore)}
        </View>
      );
    if (analysisData?.fieldTypeId === FieldTypeId.TextLong) {
      showNote = true;
      const scoreValue = analysisData?.isPositiveScore;
      return (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
            marginLeft: isNil(scoreValue) ? 0 : 4,
          }}
        >
          <Text
            style={{ ...typographyStyle.body2, color: palette.text.primary }}
          >
            {!isEmpty(analysisData?.value) ? "Available" : "No data"}
          </Text>
          {!isNil(scoreValue) && renderBooleanValue(scoreValue)}
          {!isEmpty(analysisData?.value) && (
            <Text
              style={{
                ...typographyStyle.body2,
                color: palette.error.main,
                paddingTop: 2,
                paddingLeft: isNil(scoreValue) ? 0 : scoreValue ? -4 : 0,
              }}
            >
              *
            </Text>
          )}
        </View>
      );
    }
  };

  const iconMapping = {
    [BusinessMetricTypeId.Esg]: BusinessMetricIcons?.Esg,
    [BusinessMetricTypeId.Operational]: BusinessMetricIcons?.Operational,
    [BusinessMetricTypeId.FinancialStrength]:
      BusinessMetricIcons?.FinancialStrength,
  };

  const tableWidth =
    ((businessMetricAnalysis?.selectedProducts?.length ?? 0) + 1) *
      TABLE_CELL_WIDTH +
    TABLE_FIRST_CELL_WIDTH;

  const maxCharactersInOneLine = 48;

  return (
    <>
      <View style={{ height: TITLE_HEIGHT }}>
        <Text
          style={{
            ...typographyStyle.title,
            color: palette.common.black,
          }}
        >
          {!isTableExpand
            ? "Business metric analysis"
            : "Business metric analysis (continued)"}
        </Text>
        <HorizontalLine color={palette.common.black} />
      </View>
      <View style={{ gap: DEFAULT_GAP_3, justifyContent: "flex-start" }}>
        <PDFTable tableWidth={tableWidth}>
          {/* Header */}
          <PDFSummaryAnalysisHeader
            selectedProducts={businessMetricAnalysis?.selectedProducts}
          />
          {/* Body */}
          <PDFTableBody>
            {/* Overall Score  */}
            {!isTableExpand && (
              <PDFSummaryOverallScore
                overallScore={businessMetricAnalysis?.overallScore}
              />
            )}
            {/* Body */}
            {businessMetricAnalysis?.sections?.map((section, index) => {
              const isFeatureType = section?.type === "Feature";
              return (
                <>
                  <PDFReviewTableRow
                    height={
                      section?.name?.length! > maxCharactersInOneLine
                        ? "28px"
                        : "20px"
                    }
                    color={isFeatureType ? "white" : "gray"}
                    key={index}
                  >
                    <PDFTableCell
                      width={TABLE_FIRST_CELL_WIDTH + TABLE_CELL_WIDTH}
                      alignItems={"flex-start"}
                    >
                      <View
                        style={{
                          gap: DEFAULT_GAP_3,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {!isFeatureType && (
                          <Image
                            src={
                              iconMapping[
                                section.businessMetricTypeId ??
                                  BusinessMetricTypeId.Esg
                              ]
                            }
                            style={{ width: 20, height: 20 }}
                          />
                        )}
                        <Text
                          style={{
                            ...typographyStyle.body2,
                            color: palette.text.primary,
                            fontWeight: !isFeatureType ? "bold" : "normal",
                          }}
                        >
                          {isFeatureType
                            ? section?.name
                            : `${section?.businessMetricTypeName} ${section?.totalSelectedFeature}/${section?.totalFeature}`}
                        </Text>
                      </View>
                    </PDFTableCell>
                    {section?.analysisData?.map(
                      (analysisData: AnalysisDataDTO, index: number) => (
                        <PDFTableCell key={index} width={TABLE_CELL_WIDTH} hasPadding={false}>
                          <View
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {renderPlatformAnalysisValue(analysisData)}
                          </View>
                        </PDFTableCell>
                      ),
                    )}
                  </PDFReviewTableRow>
                </>
              );
            })}
          </PDFTableBody>
        </PDFTable>
        {showNote && (
          <Text
            style={{
              ...typographyStyle.body2,
              color: palette.error.main,
            }}
          >
            * See web version of this report for full details
          </Text>
        )}
      </View>
    </>
  );
};
