import { useAuth0 } from "@auth0/auth0-react";
import { DeleteButton } from "@components/buttons/delete";
import {
  SHBox,
  SHButton,
  SHCheckbox,
  SHContainer,
  SHDivider,
  SHFormControlLabel,
  SHRadio,
  SHRadioGroup,
  SHSelect,
  SHStack,
  SHTextField,
  SHUploadImage,
} from "@components/design-systems";
import { ConfirmDialog } from "@components/dialogs/confirm";
import ResetPasswordDialog from "@components/dialogs/reset-password";
import UnsavedDialog from "@components/dialogs/unsaved";
import { StatusBadge } from "@components/status-badge";
import { PageRoutes } from "@constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppParams } from "@hooks/useAppParams";
import { useIsNew } from "@hooks/useIsNew";
import { useParentState } from "@hooks/useParentState";
import { usePractice } from "@hooks/usePractice";
import { usePracticeUser } from "@hooks/usePracticeUser";
import { useUpload } from "@hooks/useUpload";
import { useUserPermissions } from "@hooks/userUserPermission";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar } from "@layouts/top-bar";
import { UserType } from "@models/auth";
import {
  AccountTypeDTO,
  AdviserUserDTO,
  CreateAdviserUserDTO,
} from "@models/practice/entities/practiceUser";
import { PracticeRole } from "@models/practice/enums/practiceRole";
import { AdviserSubscriptionStatus } from "@models/practice/enums/status";
import { UserStatus, UserStatusAction } from "@models/users/enums/status";
import { Link, useTheme } from "@mui/material";
import {
  checkingDomain,
  constructUserName,
  generateGAvatarUrl,
  sortAscByOrder,
} from "@utils";
import { RootState } from "@redux/store";
import { refreshAccessToken } from "@services/shService";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { FileRejection } from "react-dropzone";
import { Controller, useForm, useFormState } from "react-hook-form";
import { generatePath, useNavigate } from "react-router";
import { PracticeAccountTypeTextMap, generateSubTitle } from "./config";
import { ManagePracticeUserSkeleton } from "./skeleton";
import {
  StatusButton,
  getButtonsByStatus,
  practiceUserValidators,
} from "./util";
import { useSelector, useDispatch } from "react-redux";
import { DomainDeniedDialog } from "@components/dialogs/domain-denied";
import { updateUser } from "@redux/slices/auth";
import AccessDeniedDialog from "@components/dialogs/access-denied";

export const MangePracticeUser = () => {
  const { palette } = useTheme();
  const { isMyProfile } = useAppParams();
  const dispatch = useDispatch();
  const isNew = useIsNew();
  const navigate = useNavigate();
  const { practiceId, practice } = usePractice();
  const { user } = useSelector((state: RootState) => state.auth);
  const { getAccessTokenSilently } = useAuth0();
  const {
    practiceUser,
    auth0Id,
    status,
    ui: { isLoading, isLoadingLOV, isModifyingStatus, isLoadingResetPassword },
    setPracticeUser,
    loadPracticeUser,
    createPracticeUser,
    updatePracticeUser,
    updatePracticeUserStatus,
    resetPasswordAdviserUser,
  } = usePracticeUser();
  const {
    ui: { isResentingInvite },
    resendInviteEmailAdviserUser,
  } = usePracticeUser();
  const {
    isAdviserTrial,
    isAdviserAdminTrial,
    isAdviserGroup,
    isAdminGroup,
    isAdviserAdminGroup,
  } = useUserPermissions();
  const { isDeleting, isUploading, uploadOne, deleteOne } = useUpload();
  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...new AdviserUserDTO() },
    resolver: yupResolver(practiceUserValidators),
  });
  const { isEditMode, setEditMode } = useParentState();
  const { isDirty } = useFormState({ control });
  const [changeStatusConfirm, setChangeStatusConfirm] = useState<
    StatusButton | undefined
  >();
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
  const [showDomainDeniedDialog, setShowDomainDeniedDialog] = useState(false);
  const isDisabled = useMemo(() => {
    return (
      isSubmitting ||
      !isEditMode ||
      !!isModifyingStatus ||
      practiceUser?.status === UserStatus.Disabled ||
      practiceUser?.status === UserStatus.Archived
    );
  }, [isSubmitting, isEditMode, isModifyingStatus, practiceUser]);
  const statusButtons = useMemo(() => getButtonsByStatus(status), [status]);
  const subTitleFields = generateSubTitle(isMyProfile);

  const handleSubmitPracticeUser = async (formData: AdviserUserDTO) => {
    // Checking domain
    const organizationDomain = user?.email?.split("@")?.[1];
    const userDomain = watch("email")?.split("@")?.[1];
    const isCheckingDomain = checkingDomain(organizationDomain, userDomain);

    if (!isCheckingDomain && !isAdminGroup) {
      setShowDomainDeniedDialog(true);
      return;
    }

    if (isNew) {
      const newUser = await createPracticeUser({
        ...formData,
        adviserFirmId: practiceId,
        arn: formData.arn?.toString(),
        isRenew:
          practice?.subscriptionStatus === AdviserSubscriptionStatus.Expiring
            ? false
            : true,
      } as CreateAdviserUserDTO);
      if (newUser) {
        reset(newUser);
        navigate(
          generatePath(PageRoutes.practiceUsers, {
            practiceId,
            auth0Id: newUser.auth0Id,
          }),
          {
            replace: true,
          },
        );
      }
      return;
    }

    if (!auth0Id) return;
    const isUpdated = await updatePracticeUser({
      auth0Id,
      practiceUser: formData,
    });
    if (isUpdated) {
      reset(formData);
      setPracticeUser(isUpdated);
      if (isMyProfile) {
        refreshAccessToken(getAccessTokenSilently);
      }
      if (user && isMyProfile) {
        const { firstName, lastName } = formData;
        dispatch(
          updateUser({
            ...user,
            firstName: firstName ?? "",
            lastName: lastName ?? "",
            name: `${firstName} ${lastName}`,
          }),
        );
      }
    }
  };

  const handleOnAddAvatar = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    const s3File = await uploadOne(acceptedFiles, fileRejections);
    if (s3File) {
      setValue("avatarUrl", s3File.url, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("avatarS3Key", s3File.key, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const handleOnRemoveAvatar = async () => {
    const isSuccess = await deleteOne(getValues("avatarS3Key") || undefined);
    if (isSuccess) {
      setValue("avatarUrl", "", { shouldValidate: true, shouldDirty: true });
      setValue("avatarS3Key", "", {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const userName = constructUserName(
    watch("name"),
    watch("firstName"),
    watch("lastName"),
  );

  const onChangeAvatarUrlByField = (
    email?: string,
    firstName?: string,
    lastName?: string,
  ) => {
    if (isEmpty(getValues("avatarS3Key"))) {
      setValue(
        "avatarUrl",
        generateGAvatarUrl(
          email ?? getValues("email"),
          firstName ?? getValues("firstName"),
          lastName ?? getValues("lastName"),
        ),
      );
    }
  };

  const handleOnChangeStatus = async () => {
    if (!auth0Id || !changeStatusConfirm) return;
    const newStatus = await updatePracticeUserStatus({
      adviserFirmId: practiceId ?? "",
      auth0Id,
      status: generateStatusAction(changeStatusConfirm?.action),
      statusAction: changeStatusConfirm?.action,
    });
    if (newStatus) {
      setChangeStatusConfirm(undefined);
    }
  };

  const generateStatusAction = (status: UserStatusAction) => {
    if (status === UserStatusAction.Enable) return UserStatus.Active;
    if (status === UserStatusAction.Disable) return UserStatus.Disabled;
    if (status === UserStatusAction.Archive) return UserStatus.Archived;
    return UserStatus.Disabled;
  };

  const handleOnResetPassword = async () => {
    await resetPasswordAdviserUser(practiceId, auth0Id);
    setShowResetPasswordDialog(false);
  };

  const practiceRole = watch("practiceRole");
  const preferenceTypes = watch("preferenceTypes");
  const userType = watch("userType");

  const showLinkChangePassword =
    isEditMode &&
    practiceUser?.status &&
    [UserStatus.Active, UserStatus.Pending]?.includes(practiceUser.status);
  const showARN = useMemo(
    () => practiceRole === PracticeRole.Adviser,
    [practiceRole],
  );
  const showCommunicationPreference = isAdminGroup || isMyProfile;

  useEffect(() => {
    if (isAdviserTrial && !isMyProfile) return;
    loadPracticeUser(practiceId, auth0Id ?? "new");
    // eslint-disable-next-line
  }, [practiceId, auth0Id, isNew]);

  useEffect(() => {
    reset({
      ...practiceUser,
      preferences: isNew
        ? practiceUser?.preferenceTypes?.map((x) => x.id)
        : practiceUser?.preferences ?? [],
    });
    // eslint-disable-next-line
  }, [practiceUser, isEditMode]);

  const renderAccountType = (option: AccountTypeDTO, value?: UserType) => {
    if (isAdviserTrial && option.id !== UserType.AdviserTrial) return null;
    if (
      isAdviserGroup &&
      !isAdviserTrial &&
      !isAdviserAdminTrial &&
      option.id === UserType.AdviserTrial
    )
      return null;

    const textMap = PracticeAccountTypeTextMap[option.id];
    const isUnlimited =
      option.id === UserType.AdviserTrial ||
      option.id === UserType.AdviserSupportStaff;
    let inUsed = option.inUse;

    if (isEditMode) {
      if (value === option.id && practiceUser?.userType !== option.id)
        inUsed = option.inUse + 1;
      if (value !== option.id && practiceUser?.userType === option.id)
        inUsed = option.inUse - 1;
    }

    if (isNew) {
      inUsed = value === option.id ? (option.inUse || 0) + 1 : option.inUse;
    }

    const isFull = !isUnlimited && inUsed === option.total;

    if (
      practiceUser?.subscriptionStatus === AdviserSubscriptionStatus.Subscribed
    ) {
      if (option.id === UserType.AdviserTrial) return;
    }

    return (
      <SHFormControlLabel
        key={option.id}
        value={option.id}
        control={<SHRadio />}
        label={
          isUnlimited
            ? textMap?.title
            : textMap?.title
                ?.replace("{{inUsed}}", String(inUsed || 0))
                .replace("{{total}}", String(option.total || 0))
        }
        disabled={isDisabled || isFull || isGreyOut(option)}
        subLabel={textMap?.description}
      />
    );
  };

  const isGreyOut = (option: AccountTypeDTO) => {
    if (isEditMode && isMyProfile && !isAdviserAdminGroup) return true;
    if (
      !user?.userMetadata?.is_system_admin &&
      !user?.userMetadata?.is_adviser_admin
    )
      return true;
    if (practiceUser !== undefined) {
      if (
        practiceUser.subscriptionStatus === AdviserSubscriptionStatus.Trial ||
        practiceUser.subscriptionStatus === AdviserSubscriptionStatus.Expired
      ) {
        if (option.id === UserType.AdviserTrial) return false;
      } else {
        if (
          option.id === UserType.AdviserAdviser ||
          option.id === UserType.AdviserSupportStaff
        )
          return false;
      }
    }
    return true;
  };

  if (isAdviserTrial && !isMyProfile) return <AccessDeniedDialog />;
  if (isLoading || isLoadingLOV) return <ManagePracticeUserSkeleton />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 }, py: 3 }}>
      <form>
        <SHStack>
          <TopBar
            navigate={
              user?.userMetadata?.is_system_admin ||
              user?.userMetadata?.is_adviser_admin
                ? {
                    to: generatePath(PageRoutes.practiceTeam, { practiceId }),
                  }
                : undefined
            }
            title={isNew && isEmpty(userName) ? "New team member " : userName}
            customBadge={
              practiceUser?.status ? (
                <StatusBadge status={practiceUser.status} />
              ) : undefined
            }
            tools={
              <>
                {isEditMode && (
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    onClick={() => {
                      if (isMyProfile) {
                        setEditMode(false);
                      } else {
                        navigate(`${PageRoutes.practices}/${practiceId}/team`);
                      }
                    }}
                  >
                    Cancel
                  </SHButton>
                )}
                {isEditMode ? (
                  <>
                    {!isNew &&
                      !isMyProfile &&
                      statusButtons.map((button) => (
                        <SHButton
                          key={button.action}
                          variant="outlined"
                          size="extraMedium"
                          isLoading={isModifyingStatus === button.action}
                          disabled={
                            isSubmitting ||
                            isDeleting ||
                            isUploading ||
                            !!isModifyingStatus
                          }
                          onClick={() => {
                            if (!auth0Id) return;
                            if (button.dialog) {
                              setChangeStatusConfirm(button);
                              return;
                            }
                            updatePracticeUserStatus({
                              adviserFirmId: practiceId ?? "",
                              auth0Id,
                              status: generateStatusAction(button.action),
                              statusAction: button.action,
                            });
                          }}
                        >
                          {button.title}
                        </SHButton>
                      ))}
                    {!isNew && status.isPending && (
                      <SHButton
                        variant="outlined"
                        size="extraMedium"
                        onClick={() => {
                          if (!auth0Id) return;
                          resendInviteEmailAdviserUser(practiceId, auth0Id);
                        }}
                        isLoading={isResentingInvite}
                        disabled={isResentingInvite}
                      >
                        Resend invite
                      </SHButton>
                    )}
                    <SHButton
                      variant="contained"
                      size="extraMedium"
                      type="submit"
                      disabled={
                        !isValid ||
                        !isDirty ||
                        isSubmitting ||
                        isDeleting ||
                        isUploading ||
                        !!isModifyingStatus
                      }
                      isLoading={isSubmitting}
                      onClick={handleSubmit(handleSubmitPracticeUser)}
                    >
                      {isNew ? "Create and send welcome email" : "Save"}
                    </SHButton>
                  </>
                ) : (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    type="submit"
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    Edit
                  </SHButton>
                )}
              </>
            }
          />
          <FieldGroup
            firstLine
            title="Profile"
            subTitle={subTitleFields.Profile}
            marginTop={3}
          />
          <SHDivider />
          <FieldGroup
            title="Profile photo"
            subTitle={subTitleFields.ProfilePhoto}
          >
            <Controller
              name="avatarUrl"
              control={control}
              render={({ field }) => (
                <SHUploadImage
                  objectFit="contain"
                  isUploading={isUploading}
                  src={field.value || undefined}
                  actionIcon={
                    !isEmpty(watch("avatarUrl")) &&
                    isEditMode &&
                    !isDisabled && (
                      <DeleteButton
                        isLoading={isDeleting}
                        onClick={handleOnRemoveAvatar}
                      />
                    )
                  }
                  error={!!errors.avatarUrl}
                  helperText={
                    errors.avatarUrl ? errors.avatarUrl.message : null
                  }
                  dropzoneOptions={{
                    multiple: false,
                    accept: {
                      "image/*": [".svg", ".png", ".jpg", ".gif"],
                    },
                    onDrop: handleOnAddAvatar,
                    disabled: isDisabled || isUploading,
                  }}
                />
              )}
            />
          </FieldGroup>
          <SHDivider />
          <FieldGroup
            title="Personal details"
            subTitle={subTitleFields.PersonalDetails}
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ paddingY: 1, width: { xs: "100%", md: 520 } }}
            >
              <SHStack spacing={2} direction="row">
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      required
                      hasCounter
                      maxLength={100}
                      sx={{ width: { xs: "100%", md: 250 } }}
                      label="First name"
                      placeholder="Enter first name"
                      disabled={isDisabled}
                      error={!!errors.firstName}
                      helperText={
                        errors.firstName ? errors.firstName.message : null
                      }
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        onChangeAvatarUrlByField(
                          undefined,
                          event.target.value,
                          undefined,
                        );
                      }}
                    />
                  )}
                />

                <Controller
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      required
                      hasCounter
                      maxLength={100}
                      sx={{ width: { xs: "100%", md: 250 } }}
                      label={"Last name"}
                      placeholder="Enter last name"
                      disabled={isDisabled}
                      error={!!errors.lastName}
                      helperText={
                        errors.lastName ? errors.lastName.message : null
                      }
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        onChangeAvatarUrlByField(
                          undefined,
                          undefined,
                          event.target.value,
                        );
                      }}
                    />
                  )}
                  name="lastName"
                  control={control}
                />
              </SHStack>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <SHTextField
                    {...field}
                    required
                    type="email"
                    label={"Work email"}
                    placeholder="Enter work email"
                    disabled={isDisabled || !isNew}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : null}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      onChangeAvatarUrlByField(
                        event.target.value,
                        undefined,
                        undefined,
                      );
                    }}
                  />
                )}
              />
              <Controller
                name="jobTitle"
                control={control}
                render={({ field }) => (
                  <SHTextField
                    {...field}
                    label={"Job title"}
                    placeholder="Enter job title"
                    disabled={isDisabled}
                  />
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <SHTextField
                    {...field}
                    type="tel"
                    label={"Contact number"}
                    placeholder="Enter contact number"
                    disabled={isDisabled}
                  />
                )}
              />
              <Controller
                name="practiceRole"
                control={control}
                render={({ field }) => (
                  <SHSelect
                    {...field}
                    data={watch("practiceRoles") ?? []}
                    label={"Practice role"}
                    placeholder={"Select a role"}
                    dataItemKey="id"
                    displayField="name"
                    required
                    disabled={isDisabled}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setValue("arn", "");
                    }}
                  />
                )}
              />
              {showARN && (
                <Controller
                  name="arn"
                  control={control}
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      fullWidth
                      required={showARN}
                      label="Authorised Representative Number (ARN)"
                      placeholder="Enter ARN"
                      disabled={isDisabled}
                      error={!!errors.arn}
                      helperText={errors.arn ? errors.arn.message : null}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (
                          e.target.value === "" ||
                          regex.test(e.target.value)
                        ) {
                          field.onChange(e.target.value);
                        }
                      }}
                      hasCounter
                      maxLength={9}
                    />
                  )}
                />
              )}
              {showLinkChangePassword && (
                <SHStack
                  direction={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                >
                  <Link
                    variant="body2"
                    color={palette.text.secondary}
                    component="label"
                    underline="none"
                    onClick={() => setShowResetPasswordDialog(true)}
                    sx={{
                      paddingRight: 0.5,
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    {subTitleFields.ChangePassword}
                  </Link>
                </SHStack>
              )}
            </SHStack>
          </FieldGroup>
          <SHDivider />
          {(isNew || userType) && (
            <FieldGroup
              title="Account type"
              subTitle={subTitleFields.AccountType}
            >
              <SHStack
                spacing={3}
                direction="column"
                sx={{ paddingY: 1, width: { xs: "100%", md: 520 } }}
              >
                <Controller
                  name="userType"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <SHRadioGroup
                      {...field}
                      onChange={(_event, value: string) => {
                        field.onChange(value);
                      }}
                    >
                      {sortAscByOrder(
                        (isNew
                          ? practiceUser?.accountTypes
                          : watch("accountTypes")) || [],
                      ).map((option) => renderAccountType(option, field.value))}
                    </SHRadioGroup>
                  )}
                />
                {/* {(isAdviserTrial || isAdviserAdminTrial) && (
                  <SHStack alignItems={"flex-end"}>
                    <SHButton variant="contained" size="extraMedium">
                      Upgrade subscription
                    </SHButton>
                  </SHStack>
                )} */}
              </SHStack>
            </FieldGroup>
          )}
          <SHDivider />
          {(isNew || userType) && (
            <FieldGroup
              title="Administrative privileges"
              subTitle={subTitleFields.AdministrativePrivileges}
            >
              <SHStack
                spacing={3}
                direction="column"
                sx={{ paddingY: 1, width: { xs: "100%", md: 520 } }}
              >
                <Controller
                  name={"hasAdminPrivileges"}
                  control={control}
                  render={({ field: { ref, value, onChange, ...others } }) => (
                    <SHBox>
                      <SHFormControlLabel
                        {...others}
                        value={null}
                        control={<SHCheckbox />}
                        label={"This user has administrative privileges"}
                        checked={value}
                        disabled={isDisabled || isMyProfile}
                        onChange={(event: any) => {
                          onChange(event.target.checked);
                        }}
                      />
                    </SHBox>
                  )}
                />
              </SHStack>
            </FieldGroup>
          )}
          {showCommunicationPreference && (
            <>
              <SHDivider />
              <FieldGroup title="Communication preferences">
                <Controller
                  name="preferences"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <SHBox sx={{ width: { xs: "100%", md: 520 } }}>
                      {preferenceTypes &&
                        sortAscByOrder(preferenceTypes).map((preference) => (
                          <SHFormControlLabel
                            key={preference.id}
                            value={preference.id}
                            control={<SHCheckbox />}
                            label={preference.name}
                            disabled={isDisabled}
                            checked={getValues("preferences")?.includes(
                              preference.id,
                            )}
                            onChange={(event: any) => {
                              if (event.target.checked) {
                                field.onChange(
                                  field.value?.concat(event.target.value),
                                );
                              } else {
                                field.onChange(
                                  field.value?.filter(
                                    (id) => id !== event.target.value,
                                  ),
                                );
                              }
                            }}
                          />
                        ))}
                    </SHBox>
                  )}
                />
              </FieldGroup>
            </>
          )}
        </SHStack>
        <UnsavedDialog isDirty={isDirty && !isSubmitting} />
        <ConfirmDialog
          open={!!changeStatusConfirm}
          header={changeStatusConfirm?.dialog?.header}
          body={changeStatusConfirm?.dialog?.body}
          disableBackdropClick
          disableEscapeKeyDown
          leftButton={{
            disabled: !!isModifyingStatus,
            onClick: () => {
              setChangeStatusConfirm(undefined);
            },
            title: changeStatusConfirm?.dialog?.noButton,
          }}
          rightButton={{
            disabled: !!isModifyingStatus,
            isLoading: !!isModifyingStatus,
            onClick: handleOnChangeStatus,
            title: changeStatusConfirm?.dialog?.yesButton,
          }}
        />
        {showResetPasswordDialog && (
          <ResetPasswordDialog
            onClose={() => setShowResetPasswordDialog(false)}
            onYes={handleOnResetPassword}
            isSending={isLoadingResetPassword}
            isMyProfile={isMyProfile}
          />
        )}
        {showDomainDeniedDialog && (
          <DomainDeniedDialog onYes={() => setShowDomainDeniedDialog(false)} />
        )}
      </form>
    </SHContainer>
  );
};
