import { useNotification } from "@hooks/useNotification";
import {
  CreateFeatureTemplateDTO,
  FeatureTemplateDTO,
  FeatureTemplateSelectionDTO,
} from "@models/feature-template/entities/featureTemplate";
import { FeatureTemplateStatus } from "@models/feature-template/enums/status";
import {
  createFeatureTemplateThunk,
  loadExistingFeatureTemplateForReviewThunk,
  loadExistingFeatureTemplateThunk,
  loadFeatureTemplateLOVThunk,
  loadFeatureTemplateThunk,
  resetFeatureTemplateStore,
  setExistingFeatureTemplateAction,
  setFeatureTemplateAction,
  updateFeatureTemplateStatusThunk,
  updateFeatureTemplateThunk,
} from "@redux/slices/feature-template";
import { useAppDispatch } from "@redux/store";

export const useFeatureTemplate = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();

  const setFeatureTemplate = (featureTemplate: FeatureTemplateDTO) => {
    dispatch(setFeatureTemplateAction(featureTemplate));
  };

  const setExistingFeatureTemplate = (
    featureTemplates: FeatureTemplateSelectionDTO[],
  ) => {
    dispatch(setExistingFeatureTemplateAction(featureTemplates));
  };

  const resetStore = () => {
    dispatch(resetFeatureTemplateStore());
  };

  const loadFeatureTemplate = async (featureTemplateId: string) => {
    const response = await dispatch(
      loadFeatureTemplateThunk(featureTemplateId),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadFeatureTemplateLOV = async () => {
    const response = await dispatch(loadFeatureTemplateLOVThunk()).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const createFeatureTemplate = async (
    featureTemplate: CreateFeatureTemplateDTO,
    isSupplier: boolean,
  ) => {
    featureTemplate.forSupplier = isSupplier;
    if (isSupplier) {
      featureTemplate.firmId = featureTemplate.supplierId ?? "";
      featureTemplate.firmName = featureTemplate.supplierName ?? "";
    } else {
      featureTemplate.firmId = featureTemplate.adviserFirmId ?? "";
      featureTemplate.firmName = featureTemplate.adviserFirmName ?? "";
    }

    const response = await dispatch(
      createFeatureTemplateThunk(featureTemplate),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
        width: "750px",
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updateFeatureTemplate = async (featureTemplate: FeatureTemplateDTO) => {
    const response = await dispatch(
      updateFeatureTemplateThunk(featureTemplate),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updateFeatureTemplateStatus = async (
    featureTemplateId: string,
    status: FeatureTemplateStatus,
  ) => {
    const response = await await dispatch(
      updateFeatureTemplateStatusThunk({ featureTemplateId, status }),
    ).unwrap();
    if (response.isSuccess && response.data) {
      notify(response.message, {
        variant: "success",
        close: true,
      });
    } else {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
    return response.data;
  };

  const loadExistingFeatureTemplate = async (filterByName?: string) => {
    const response = await dispatch(
      loadExistingFeatureTemplateThunk(""),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadExistingFeatureTemplateForReview = async (
    filterByName?: string,
  ) => {
    const response = await dispatch(
      loadExistingFeatureTemplateForReviewThunk(""),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  return {
    setFeatureTemplate,
    setExistingFeatureTemplate,
    loadFeatureTemplate,
    loadFeatureTemplateLOV,
    loadExistingFeatureTemplate,
    loadExistingFeatureTemplateForReview,
    createFeatureTemplate,
    updateFeatureTemplate,
    updateFeatureTemplateStatus,
    resetStore,
  };
};
