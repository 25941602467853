import { PlusButton } from "@components/buttons/plus";
import {
  SHBox,
  SHButton,
  SHDialog,
  SHDivider,
  SHRadio,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { usePractice } from "@hooks/usePractice";
import { usePracticeBillingInfo } from "@hooks/usePracticeBillingInfo";
import {
  BillingDetailDTO,
  PaymentMethodDTO,
} from "@models/practice/entities/practiceBillingInfo";
import { UpdateUserSubscriptionDTO } from "@models/practice/entities/practiceLicences";
import { useTheme } from "@mui/material";
import { ModifyPaymentMethodButton } from "@pages/practices/_id/_billing-info/components/left-side/components/buttons";
import { renderCardLogo } from "@pages/practices/_id/_billing-info/components/left-side/components/payment-method-list";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { isEmpty, startCase } from "lodash";
import { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHED_KEY ?? "",
);
interface ProcessPaymentDialogProps {
  quantity: number;
  prorationDate?: Date | null;
  onClose?: () => void;
  onSubmit?: () => void;
}

export const ProcessPaymentDialog = ({
  quantity,
  prorationDate,
  onClose = () => {},
  onSubmit = () => {},
}: ProcessPaymentDialogProps) => {
  const { palette } = useTheme();
  const { practiceId, updatePracticeUserLicences } = usePractice();
  const {
    ui,
    practiceClientSecret,
    practicePaymentMethod,
    practiceBillingDetail,
    loadPaymentMethod,
    loadBillingDetail,
  } = usePracticeBillingInfo();

  const [billingDetail, setBillingDetail] = useState<BillingDetailDTO>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodDTO[]>([]);
  const [selectedPaymentId, setSelectedPaymentId] = useState("");
  const [showPaymentMethodElement, setShowPaymentMethodElement] =
    useState(false);

  const handleOnPurchase = async () => {
    if (!practiceId) return;
    const response = await updatePracticeUserLicences(practiceId, {
      quantity: quantity,
      prorationDate: prorationDate,
      paymentMethodId: selectedPaymentId,
    } as UpdateUserSubscriptionDTO);

    if (response) {
      onSubmit();
      onClose();
    }
  };

  useEffectOnce(() => {
    if (!practiceId) return;
    loadPaymentMethod(practiceId);
    loadBillingDetail(practiceId);
  });

  useEffect(() => {
    if (practicePaymentMethod) {
      setPaymentMethods(practicePaymentMethod);
      setSelectedPaymentId(
        practicePaymentMethod?.filter((item) => item.isDefault)?.[0]
          ?.paymentMethodId,
      );
    }
  }, [practicePaymentMethod]);

  useEffect(() => {
    if (practiceBillingDetail) setBillingDetail(practiceBillingDetail);
  }, [practiceBillingDetail]);

  if (!paymentMethods || !billingDetail) return <></>;

  return (
    <Elements
      stripe={stripePromise}
      options={
        {
          clientSecret: practiceClientSecret,
          paymentMethodCreation: "manual",
          loader: "auto",
        } as StripeElementsOptions
      }
    >
      <SHDialog
        open
        disableEscapeKeyDown
        disableBackdropClick
        maxWidth={false}
        sx={{
          "& .MuiPaper-root": {
            padding: "35px 45px",
          },
        }}
        header={"Select payment method"}
        body={
          <SHStack
            flexDirection={"column"}
            justifyContent={"space-between"}
            width={{ xs: "100%", md: 400 }}
            minHeight={paymentMethods?.length * 73.5 + 215 + 35}
            alignItems={"center"}
            mt={"35px !important"}
          >
            {!isEmpty(practiceClientSecret) && (
              <>
                <SHStack spacing={1} width={"100%"}>
                  {paymentMethods?.map((paymentMethod, index) => {
                    const {
                      brand,
                      cardNumberLast4,
                      expireMonth,
                      expireYear,
                      isDefault,
                      paymentMethodId,
                    } = paymentMethod;

                    return (
                      <>
                        <SHStack
                          flexDirection={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <SHStack flexDirection={"row"}>
                            <SHRadio
                              value={paymentMethodId}
                              checked={paymentMethodId === selectedPaymentId}
                              onChange={() =>
                                setSelectedPaymentId(paymentMethodId)
                              }
                            />
                            {renderCardLogo(brand, 50, 45)}
                            <SHStack
                              flexDirection={"column"}
                              alignItems={"center"}
                              ml={"5px"}
                            >
                              <SHStack
                                flexDirection={"column"}
                                justifyContent={"flex-start"}
                                spacing={-0.5}
                              >
                                <SHTypography
                                  variant="body2"
                                  colorVariant="primary"
                                  textAlign={"left"}
                                  fontWeight={500}
                                >
                                  {startCase(brand)} **** {cardNumberLast4}
                                </SHTypography>
                                <SHTypography
                                  variant="body2"
                                  colorVariant="disabled"
                                  textAlign={"left"}
                                >
                                  Expires {expireMonth}/{expireYear}
                                </SHTypography>
                              </SHStack>
                            </SHStack>
                            {isDefault && (
                              <SHBox sx={{ marginLeft: "5px" }}>
                                <SHTypography
                                  variant="body2"
                                  colorVariant="disabled"
                                  fontSize={"10px"}
                                  sx={{
                                    backgroundColor: palette.text.darker,
                                    color: palette.text.white,
                                    borderRadius: "5px",
                                    paddingX: "8px",
                                    paddingTop: "2px",
                                    fontWeight: 600,
                                  }}
                                >
                                  Default
                                </SHTypography>
                              </SHBox>
                            )}
                          </SHStack>
                          {!paymentMethod?.isDefault && (
                            <ModifyPaymentMethodButton
                              paymentMethodId={paymentMethodId}
                            />
                          )}
                        </SHStack>
                        {index !== paymentMethods?.length - 1 && <SHDivider />}
                      </>
                    );
                  })}
                  {!showPaymentMethodElement && (
                    <SHStack>
                      <PlusButton
                        size="medium"
                        variant="outlined"
                        onClick={() => setShowPaymentMethodElement(true)}
                        sx={{ width: "200px", mt: "0px !important" }}
                      >
                        Add payment method
                      </PlusButton>
                    </SHStack>
                  )}
                </SHStack>

                <PaymentMethodForm
                  billingDetail={billingDetail}
                  showPaymentMethodElement={showPaymentMethodElement}
                  onCancel={() => setShowPaymentMethodElement(false)}
                />

                {!showPaymentMethodElement && (
                  <SHStack
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"10px"}
                  >
                    <SHButton
                      color="primary"
                      variant="outlined"
                      onClick={onClose}
                    >
                      Cancel
                    </SHButton>
                    <SHButton
                      color="primary"
                      variant="contained"
                      onClick={handleOnPurchase}
                      disabled={
                        isEmpty(selectedPaymentId) || ui.isLoadingProceedPayment
                      }
                      isLoading={ui.isLoadingProceedPayment}
                    >
                      Purchase
                    </SHButton>
                  </SHStack>
                )}
              </>
            )}
          </SHStack>
        }
      />
    </Elements>
  );
};

interface PaymentMethodFormProps {
  billingDetail: BillingDetailDTO;
  showPaymentMethodElement?: boolean;
  onCancel?: () => void;
}

const PaymentMethodForm = ({
  showPaymentMethodElement,
  billingDetail,
  onCancel = () => {},
}: PaymentMethodFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { notify } = useNotification();
  const { practiceId } = usePractice();
  const { ui, createPaymentMethod } = usePracticeBillingInfo();

  const [isDisabled, setIsDisabled] = useState(true);

  const handleOnAddPaymentMethod = async () => {
    if (!stripe || !elements || !practiceId) return;

    setIsDisabled(true);
    elements.submit();

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          address: {
            country: billingDetail.billingAddress?.country,
            city: billingDetail.billingAddress?.city,
            state: billingDetail.billingAddress?.state,
            line1: billingDetail.billingAddress?.line1,
            postal_code: billingDetail.billingAddress?.postalCode,
            line2: billingDetail.billingAddress?.line2 ?? "",
          },
          name: billingDetail.billingAddress?.name,
          email: billingDetail.billingContact?.contactEmail,
          phone: billingDetail.billingContact?.contactPhoneNumber,
        },
      },
    });

    if (!error) {
      await createPaymentMethod(practiceId, paymentMethod?.id ?? "");
      handleOnCancelAddPaymentMethod();
    } else {
      notify(error.message, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnCancelAddPaymentMethod = () => {
    if (!stripe || !elements) return;
    const paymentElement = elements.getElement("payment");
    if (paymentElement) paymentElement.clear();
    onCancel();
  };

  useEffect(() => {
    if (stripe && elements) {
      const paymentElement = elements.getElement("payment");
      if (paymentElement) {
        paymentElement.on("change", (event) => {
          setIsDisabled(!event.complete);
        });
      }
    }
  });

  return (
    <>
      {showPaymentMethodElement && (
        <SHStack width={"100%"}>
          <PaymentElement
            options={{
              layout: "tabs",
              fields: {
                billingDetails: {
                  address: { country: "never" },
                },
              },
            }}
          />
        </SHStack>
      )}

      {showPaymentMethodElement && (
        <SHStack
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
        >
          <SHButton
            color="primary"
            variant="outlined"
            onClick={handleOnCancelAddPaymentMethod}
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            variant="contained"
            type="submit"
            disabled={isDisabled || ui.paymentMethod.isSaving}
            isLoading={ui.paymentMethod.isSaving}
            onClick={handleOnAddPaymentMethod}
          >
            Save
          </SHButton>
        </SHStack>
      )}
    </>
  );
};
