import {
  ReviewTableRow,
  ReviewTableRowType,
} from "@models/reviews/entities/review";
import {
  ReviewFeatureDTO,
  ReviewSectionDTO,
} from "@models/reviews/entities/steps/feature";

// Use for PDF & Table Virtuoso
export function flatBusinessReviewData(
  sections: ReviewSectionDTO[],
): ReviewTableRow[] {
  const rows: ReviewTableRow[] = [];

  sections.forEach((section) => {
    rows?.push({
      id: section?.id,
      name: section?.name,
      description: section?.description,
      iconUrl: section?.iconUrl,
      order: section?.order,
      totalFeature: section?.totalFeature,
      totalSelectedFeature: section?.totalSelectedFeature,
      analysisData: section?.analysisData,
      businessMetricTypeId: section?.businessMetricTypeId,
      businessMetricTypeName: section?.businessMetricTypeName,
      type: ReviewTableRowType.Section,
    } as ReviewTableRow);

    section?.features?.forEach((feature: ReviewFeatureDTO) => {
      rows.push({
        id: feature?.id,
        name: feature?.name,
        description: feature?.description,
        order: feature?.order,
        analysisData: feature?.analysisData,
        businessMetricTypeId: section?.businessMetricTypeId,
        businessMetricTypeName: section?.businessMetricTypeName,
        type: ReviewTableRowType.Feature,
        collapsedIds: [section?.id],
      } as ReviewTableRow);
    });
  });

  return rows;
}
