import { UserType } from "@models/auth";
import { generateUUID } from "@utils";
import { ImageDTO, SelectedProductDTO } from "./feature";
import { FeePortfolioDetailDTO } from "../portfolioDetails";
import { AnalysisVariable } from "../../enums/variableName";
import { FeeCalculatorFieldType } from "../../enums/fee/fieldType";
import { AnalysisSelectionDTO } from "../analysis";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";

//TODO: Make Platform Analysis models to consistent with BE
//Fee calculator
export class FamilyMemberAccountDTO {
  id: string | null = generateUUID();
  balance: number = 0;
  variableName?: AnalysisVariable | null = AnalysisVariable.IDPS_1;
  isIDPS: boolean = false;
  order: number = 0;
}

export class FamilyMemberDTO {
  id: string | null = generateUUID();
  name: string | null = null;
  variableName?: AnalysisVariable | null = AnalysisVariable.IDPS_1;
  order: number = 0;
  totalBalance: number = 0;
  familyMemberAccounts: FamilyMemberAccountDTO[] = [];
}

export class FeeCalculatorDataDTO {
  id?: string | null = null;
  value: string | null = null;
}
export class FeeCalculatorDTO {
  id: string | null = generateUUID();
  name: string = "";
  description: string | null = null;
  fieldType: FeeCalculatorFieldType = FeeCalculatorFieldType.Numeric;
  variableName: AnalysisVariable = AnalysisVariable.Choose_AU;
  defaultValue: string | null = null;
  feeCalculatorData: FeeCalculatorDataDTO | null = null;
}

export class AnalysisFeeCalculatorDTO extends AnalysisSelectionDTO {
  familyMembers: FamilyMemberDTO[] = [];
  feeCalculators: FeeCalculatorDTO[] = [];
  isShowHoldingsNumber?: boolean = false;
}

//Fee analysis
export interface AnalysisFeeDTO {
  selectedProducts: SelectedProductDTO[];
  subProducts: FeeSubProductDTO[];
  isShowHoldingsNumber: boolean;
}

export interface ExportableAnalysisStepFeeDTO extends AnalysisFeeDTO {
  name?: string;
  supplierName?: string;
  ownerName?: string;
  adviserName?: string;
  practiceName?: string;
  ownerType?: UserType;
  serial?: string;
  feePortfolioDetails?: FeePortfolioDetailDTO;
  lastModifiedDate?: Date;
  feesDisplayStyle?: FeesDisplayStyle;
  feeMissingInvestmentsStyle?: FeeMissingInvestmentsOption;
  investmentMenu?: FeeInvestmentMenuOption;
  feeTableViewMode?: FeeHeatmapMode;
}

export interface FeeSubProductDTO {
  id: string;
  name: string;
  productId: string;
  productName?: string;
  productLogo?: ImageDTO;
  isProductMain?: boolean;
  order: number;
  warning?: string[];
  fees: FeeDTO[];
  detail: {
    totalFees?: null | number;
    totalFeesPercentage?: null | number;
    idpsAdminAndAccFees?: null | number;
    idpsAdminAndAccFeesPercentage?: null | number;
    superAdminAndAccFees?: null | number;
    superAdminAndAccFeesPercentage?: null | number;
    transactionFees?: null | number;
    transactionFeesPercentage?: null | number;
    intlSecuritiesOrBondHoldingFees?: null | number;
    intlSecuritiesOrBondHoldingFeesPercentage?: null | number;
    cashFees?: null | number;
    cashFeesPercentage?: null | number;
    expenseRecoveryAndOtherFees?: null | number;
    expenseRecoveryAndOtherFeesPercentage?: null | number;
    netInterestEarnedOnCash?: null | number;
    netInterestEarnedOnCashPercentage?: null | number;
    hasCashBalances?: boolean;
    pysRefund?: null | number;
    pysRefundPercentage?: null | number;
  };
  feeDetails: FeeDetailDTO[]; //Client state => Convert from detail to array
  isLatest?: null;
  isRateCard?: null;
  investmentMenu?: string;
  outdatedAt?: null | Date;
  rateCardVersion?: null | number;
  isHidden: boolean;
}

export interface FeeDTO {
  totalCostForDisplay: number;
  totalCostForDisplayPercentage: number;
  avgTotalPortfolio: number;
  isCurrentPortfolio?: boolean;

  //Optional
  idpsAdminForDisplay?: number;
  superAdminForDisplay?: number;
  totalAdminForDisplay?: number;
  totalAdminForDisplayPercentage?: number;
  allOtherFees?: number;
}

export interface FeeDetailDTO {
  name: string;
  dollar: number | null;
  percentage: number | null;
}
