import { AnalysisFeatureIncludeDTO } from "@models/platform-analysis/entities/steps/feature";
import { PDFTable } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables";
import { PDFTableCell } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-cell";
import { TABLE_WIDTH } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { PDFReviewTableBody } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-body";
import { PDFReviewTableCell } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-cell";
import { PDFReviewTableRow } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-row";
import { Text } from "@react-pdf/renderer";
import { getFeatureIncludedHeight } from "../../../util";

export interface PDFFeaturesIncludeTableProps {
  featureInclude?: AnalysisFeatureIncludeDTO[] | null;
}
export const PDFFeaturesIncludeSection = ({
  featureInclude,
}: PDFFeaturesIncludeTableProps) => {
  const { typographyStyle } = useStyles();
  const groupCellWidth = 70;
  const subGroupCellWidth = 90;
  const featureCellWidth = TABLE_WIDTH - (groupCellWidth + subGroupCellWidth);

  return (
    <PDFTable>
      <PDFReviewTableRow height={"25px"} borderBottomWidth={"1px"}>
        <PDFReviewTableCell width={groupCellWidth}>
          <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
            Feature group
          </Text>
        </PDFReviewTableCell>
        <PDFTableCell width={subGroupCellWidth}>
          <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
            Feature sub-group
          </Text>
        </PDFTableCell>
        <PDFTableCell width={featureCellWidth}>
          <Text style={{ ...typographyStyle.body2, fontWeight: "bold" }}>
            Features
          </Text>
        </PDFTableCell>
      </PDFReviewTableRow>
      <PDFReviewTableBody>
        {featureInclude?.map((row, index) => (
          <PDFReviewTableRow key={index} height={getFeatureIncludedHeight(row)}>
            <PDFTableCell width={groupCellWidth} alignItems="flex-start">
              <Text
                style={{
                  ...typographyStyle.body2,
                }}
              >
                {row.sectionName}
              </Text>
            </PDFTableCell>
            <PDFTableCell width={subGroupCellWidth} alignItems="flex-start">
              <Text style={{ ...typographyStyle.body2 }}>
                {row.subSectionName}
              </Text>
            </PDFTableCell>
            <PDFTableCell width={featureCellWidth} alignItems="flex-start">
              <Text style={{ ...typographyStyle.body2, lineHeight: 1.2 }}>
                {row?.features
                  ?.map((feature) => feature.featureName)
                  .join(", ")}
              </Text>
            </PDFTableCell>
          </PDFReviewTableRow>
        ))}
      </PDFReviewTableBody>
    </PDFTable>
  );
};
