import { SHStackProps, SHTypography } from "@components/design-systems";

export interface TextOverflowEllipsisProps extends SHStackProps {
  value: string;
  WebkitLineClamp?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
}

export const TextOverflowEllipsis = ({
  value,
  WebkitLineClamp = "5",
  fontSize = "13px",
  fontWeight,
  sx,
}: TextOverflowEllipsisProps) => {
  return (
    <SHTypography
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={"120%"}
      sx={{
        display: "-webkit-box",
        boxAlign: "center",
        WebkitLineClamp: `${WebkitLineClamp}`,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...sx,
      }}
    >
      {value}
    </SHTypography>
  );
};
