import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GlobalStore {
  globalUI: {
    isCheckingUserAccess: boolean;
  };
  forceRefreshPage: {
    showForceRefreshFirmDialog: boolean;
    forceRefreshFirmStartTime: number;
    triggerForceRefreshFirm: boolean;
  };
}
const initialState: GlobalStore = {
  globalUI: {
    isCheckingUserAccess: true,
  },
  forceRefreshPage: {
    showForceRefreshFirmDialog: false,
    forceRefreshFirmStartTime: (new Date()).getTime() / 1000,
    triggerForceRefreshFirm: false,
  },
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    updateIsCheckingUserAccess: (state, action: PayloadAction<boolean>) => {
      state.globalUI.isCheckingUserAccess = action.payload;
    },
    updateShowForceRefreshDialog: (state, action: PayloadAction<boolean>) => {
      state.forceRefreshPage.showForceRefreshFirmDialog = action.payload;
    },
    updateForceRefreshFirmStartTime: (state, action: PayloadAction<number>) => {
      state.forceRefreshPage.forceRefreshFirmStartTime = action.payload;
    },
    updateTriggerForceRefreshFirm: (state, action: PayloadAction<boolean>) => {
      state.forceRefreshPage.triggerForceRefreshFirm = action.payload;
    },
  },
});

export const {
  updateIsCheckingUserAccess,
  updateShowForceRefreshDialog,
  updateForceRefreshFirmStartTime,
  updateTriggerForceRefreshFirm
} = globalSlice.actions;

export default globalSlice.reducer;
