import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const OverviewTabSkeleton = () => {
  return (
    <SHStack sx={{ paddingY: 3 }} spacing={3}>
      <SHStack spacing={1}>
        <SHSkeleton height={24} width="18%" />
        <SHSkeleton height={20} width="100%" />
      </SHStack>
      <SHSkeleton height={2} width="100%" />
      <SHStack direction={"row"} justifyContent={"space-between"}>
        <SHStack width="30%" spacing={1}>
          <SHSkeleton height={24} width="60%" />
          <SHSkeleton height={20} width="80%" />
          <SHSkeleton height={20} width="80%" />
        </SHStack>
        <SHStack width="55%" spacing={4}>
          <SHStack direction={"row"} spacing={4} alignItems="center">
            <SHSkeleton height={90} width={90} variant="circular" />
            <SHStack spacing={1} flexGrow={1}>
              <SHSkeleton height={24} width="30%" />
              <SHSkeleton height={118} width="100%" />
            </SHStack>
          </SHStack>
          <SHStack direction={"row"} spacing={4} alignItems="center">
            <SHSkeleton height={90} width={90} variant="circular" />
            <SHStack spacing={1} flexGrow={1}>
              <SHSkeleton height={24} width="30%" />
              <SHSkeleton height={118} width="100%" />
            </SHStack>
          </SHStack>
        </SHStack>
      </SHStack>
      <SHSkeleton height={2} width="100%" />
      {/* Banner Group */}
      <SHStack direction={"row"} justifyContent={"space-between"}>
        <SHStack width="30%" spacing={1}>
          <SHSkeleton height={24} width="60%" />
          <SHSkeleton height={20} width="80%" />
          <SHSkeleton height={20} width="80%" />
        </SHStack>
        <SHStack width="45%" spacing={1}>
          <SHStack spacing={1}>
            <SHSkeleton height={24} width="30%" />
            <SHSkeleton height={50} width="100%" />
          </SHStack>
        </SHStack>
      </SHStack>
    </SHStack>
  );
};
