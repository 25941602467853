import { UserType } from "@models/auth";
import { UserStatus } from "@models/users/enums/status";
import { AdviserSubscriptionStatus } from "../enums/status";
import { PreferenceDTO } from "@models/supplier/entities/supplierUser";

export class LovDTO {
  id: string = "";
  name?: string;
  order: number = 0;
}

export class AccountTypeDTO {
  id: UserType = UserType.AdviserTrial;
  name?: string;
  order: number = 0;
  inUse: number = 0;
  total: number = 0;
}

export class AdviserUserDTO {
  auth0Id?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  avatarUrl?: string;
  avatarS3Key?: string;
  adviserFirmId?: string;
  adviserFirmName?: string;
  jobTitle?: string;
  phoneNumber?: string;
  practiceRole?: string;
  arn?: string;
  userType?: UserType = UserType.AdviserTrial;
  previousUserType?: UserType;
  hasAdminPrivileges: boolean = false;
  status?: UserStatus;
  subscriptionStatus?: AdviserSubscriptionStatus;
  licence?: string;
  lastLogin?: Date;
  loginCount?: number;
  welcomeLink?: string;
  preferences?: string[] = [];
  isRenew?: boolean;
  appliedFreeLicence?: boolean;

  practiceRoles?: LovDTO[];
  accountTypes?: AccountTypeDTO[] = [];
  preferenceTypes?: PreferenceDTO[] = [];
}

export class CreateAdviserUserDTO extends AdviserUserDTO {
  adviserFirmId: string = "";
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  userType: UserType = UserType.AdviserTrial;
  practiceRole: string = "";
}

export class AdviserUserODataDTO {
  id: string = "";
  auth0Id: string = "";
  firstName: string = "";
  lastName: string = "";
  name: string = "";
  avatarUrl: string = "";
  userType: UserType = UserType.AdviserTrial;
  hasAdminPrivileges: boolean = false;
  lastLogin: string = "";
  status: UserStatus | null = null;
  accountType: string = "";
  appliedFreeLicence?: boolean;
}
