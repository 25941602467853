import { HttpStatusCodes } from "@constants/http-status-codes";
import { ODataResult, SHAxiosResponse } from "@models/core";
import { APIGatewayService } from "@services/shService";
import axios from "axios";
import { useEffect } from "react";

export interface useODataProps {
  triggerRefresh?: boolean;
  odataQueryUrl?: string;
  onDataReceived: (data: ODataResult<any>) => void;
  onChangeIsLoading: (isLoading: boolean) => void;
  onChangeErrorMessage: (errorMessage: string | null) => void;
}
export const useOData = ({
  triggerRefresh,
  odataQueryUrl,
  onDataReceived,
  onChangeIsLoading,
  onChangeErrorMessage,
}: useODataProps) => {
  useEffect(() => {
    if (!odataQueryUrl) return;
    const cancelToken = axios.CancelToken.source();
    onChangeIsLoading(true);
    APIGatewayService.get(odataQueryUrl, {
      cancelToken: cancelToken.token,
    })
      .then((result: SHAxiosResponse<ODataResult<any>>) => {
        if (result.isCancel) {
          return;
        }
        if (result.status === HttpStatusCodes.OK) {
          onDataReceived(result.data);
        } else {
          onChangeErrorMessage(null);
        }
        onChangeIsLoading(false);
      })
      .catch((error) => {
        onChangeIsLoading(false);
        if (axios.isCancel(error)) return;
        onChangeErrorMessage(null);
      });
    return () => {
      cancelToken.cancel();
    };
    // eslint-disable-next-line
  }, [odataQueryUrl, triggerRefresh]);
};
