import { SHTableCell, SHTableCellProps } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";

interface SummaryAnalysisCellProps extends SHTableCellProps {
  children?: React.ReactNode;
  cellColor?: string;
  cellWidth?: string | number;
  isHeaderCell?: boolean;
}

export const SummaryAnalysisCell = ({
  children,
  cellWidth,
  cellColor = "transparent",
  isHeaderCell,
  ...others
}: SummaryAnalysisCellProps) => {
  const { palette } = useTheme();
  return (
    <SHTableCell
      sx={{
        height: isHeaderCell ? "140px" : "50px",
        position: "relative",
        width: cellWidth,
        minWidth: cellWidth,
        maxWidth: cellWidth,
        border: `solid 1px ${hexToRGBA(palette.primary[50], 0.3)}`,
        backgroundColor: cellColor,
        padding: "10px 10px !important",
      }}
      {...others}
    >
      {children}
    </SHTableCell>
  );
};
