import { SMAListFilter } from "@pages/managed-accounts/sma-list/components/buttons/filters";
import { initialFilterCalculatedInvestment } from "@pages/managed-accounts/sma-list/config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SMAListStore {
  filters: SMAListFilter;
}

const initialState: SMAListStore = {
  filters: initialFilterCalculatedInvestment.filters,
};

const smaListSlice = createSlice({
  name: "smaList",
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<SMAListFilter>) => {
      state.filters = action.payload;
    },
  },
});

export const { updateFilters } = smaListSlice.actions;

export default smaListSlice.reducer;
