import SHSkeleton, {
  SHSkeletonProps,
} from "@components/design-systems/sh-skeleton";

export const SHButtonSkeleton = ({ ...others }: SHSkeletonProps) => {
  return (
    <SHSkeleton
      height={40}
      width={100}
      sx={{ borderRadius: 25, alignSelf: "center" }}
      {...others}
    />
  );
};
