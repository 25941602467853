import { FeatureSectionName } from "@models/configuration";
import { AnalysisDataDTO } from "@models/platform-analysis/entities/steps/feature";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import { useTheme } from "@mui/material";
import { PDFTable } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables";
import { PDFTableBody } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-body";
import { PDFTableCell } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-cell";
import { HorizontalLine } from "@pages/platform-analysis/components/buttons/export-pdf/components/line";
import { PDFSummaryAnalysisHeader } from "@pages/platform-analysis/components/buttons/export-pdf/components/tables/components/summary-analysis-header";
import { PDFSummaryOverallScore } from "@pages/platform-analysis/components/buttons/export-pdf/components/tables/components/summary-overall-score";
import {
  DEFAULT_GAP_3,
  TITLE_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { PDFFeatureAnalysis } from "@pages/platform-analysis/components/buttons/export-pdf/model";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { featureSectionIconMapping } from "@pages/platform-analysis/components/buttons/export-pdf/util";
import { PDFReviewTableRow } from "@pages/reviews/components/buttons/export-pdf/layouts/tables/table-row";
import { Image, Path, Svg, Text, View } from "@react-pdf/renderer";
import { isEmpty } from "lodash";
interface PDFFeatureAnalysisTableProps {
  featureAnalysis?: PDFFeatureAnalysis;
  isTableExpand?: boolean;
  tableWidth?: boolean;
  showSubSection?: boolean;
  isDetailMode?: boolean;
  showAnalysisScore?: boolean;
}

export const PDFFeatureAnalysisSection = ({
  featureAnalysis,
  isTableExpand = false,
  showSubSection = false,
  isDetailMode = false,
  showAnalysisScore = false,
}: PDFFeatureAnalysisTableProps) => {
  const { typographyStyle } = useStyles();
  const { palette } = useTheme();

  const sections = featureAnalysis?.sections ?? [];
  const overallScore = featureAnalysis?.overallScore;
  const selectedProducts = featureAnalysis?.selectedProducts ?? [];

  const cellWidth = 60.8;
  let firstCellWidth = 150;
  if (isDetailMode) firstCellWidth += cellWidth;

  const totalSelectionCellWidth = cellWidth;

  let tableWidth = firstCellWidth + selectedProducts?.length! * cellWidth;
  if (!isDetailMode) tableWidth += totalSelectionCellWidth;

  const maxCharactersInOneLine = 48;

  const renderPlatformValue = (value?: AnalysisDataValue) => {
    if (value === AnalysisDataValue.True)
      return (
        <Svg width="12" height="12" viewBox="0 0 19 19">
          <Path
            d="m3.333 9.265 4.5 4.5 7.5-9"
            stroke="#219653"
            strokeWidth="2"
            strokeLineCap="round"
          />
        </Svg>
      );
    if (value === AnalysisDataValue.False)
      return (
        <Text style={{ ...typographyStyle.body1, color: palette.error.main }}>
          x
        </Text>
      );
    if (value === AnalysisDataValue.MissingData || isEmpty(value))
      return (
        <Text style={{ ...typographyStyle.body2, color: palette.text.primary }}>
          No data
        </Text>
      );
  };

  return (
    <>
      <View style={{ height: TITLE_HEIGHT }}>
        <Text
          style={{
            ...typographyStyle.title,
            color: palette.common.black,
          }}
        >
          {!isTableExpand ? "Feature analysis" : "Feature analysis (continued)"}
        </Text>
        <HorizontalLine color={palette.common.black} />
      </View>

      <PDFTable tableWidth={tableWidth}>
        {/* Header */}
        <PDFSummaryAnalysisHeader
          isFeatureAnalysis
          isDetailMode={isDetailMode}
          selectedProducts={featureAnalysis?.selectedProducts}
        />
        {/* Body */}
        <PDFTableBody>
          {/* Overall Score  */}
          {!isTableExpand && (
            <PDFSummaryOverallScore
              isFeatureAnalysis
              isDetailMode={isDetailMode}
              isShowScore={showAnalysisScore}
              overallScore={overallScore}
              color={isDetailMode ? "primary" : "dark"}
            />
          )}
          {sections?.map((section, index) => {
            const isSubSectionType = section?.type === "SubSection";
            const isFeatureType = section?.type === "Feature";
            const colorRowDetailMode =
              isSubSectionType && showSubSection
                ? "gray"
                : isFeatureType
                ? "white"
                : "dark";
            const colorRow =
              !isSubSectionType && showSubSection ? "gray" : "white";

            return (
              <>
                <PDFReviewTableRow
                  key={index}
                  height={
                    section?.name?.length! > maxCharactersInOneLine
                      ? "28px"
                      : "20px"
                  }
                  color={isDetailMode ? colorRowDetailMode : colorRow}
                >
                  <PDFTableCell alignItems="flex-start" width={firstCellWidth}>
                    <View
                      style={{
                        gap: DEFAULT_GAP_3,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {!isSubSectionType && section?.iconUrl && (
                        <Image
                          style={{ width: 10, height: 10 }}
                          src={
                            featureSectionIconMapping[
                              (section?.name as FeatureSectionName) ??
                                FeatureSectionName.ProductMenu
                            ]
                          }
                        />
                      )}
                      <Text
                        style={{
                          ...typographyStyle.body2,
                          color: palette.text.primary,
                          fontWeight:
                            isSubSectionType || isFeatureType
                              ? "normal"
                              : "bold",
                        }}
                      >
                        {section?.name}
                        {isDetailMode &&
                          !isFeatureType &&
                          ` (${section?.totalSelectedFeature}/${section?.totalFeature})`}
                      </Text>
                    </View>
                  </PDFTableCell>
                  {!isDetailMode && (
                    <PDFTableCell width={cellWidth}>
                      <Text
                        style={{
                          ...typographyStyle.body2,
                          color: palette.text.primary,
                          fontWeight: isSubSectionType ? "normal" : "bold",
                        }}
                      >
                        {`${section?.totalSelectedFeature}/${section?.totalFeature}`}
                      </Text>
                    </PDFTableCell>
                  )}
                  {section?.analysisData?.map(
                    (product: AnalysisDataDTO, index: number) => (
                      <PDFTableCell key={index} width={cellWidth} hasPadding={false}>
                        {isFeatureType
                          ? renderPlatformValue(
                              product.value as AnalysisDataValue,
                            )
                          : (!isDetailMode ||
                              (isDetailMode && showAnalysisScore)) && (
                              <Text
                                style={{
                                  ...typographyStyle.body2,
                                  color: palette.text.primary,
                                  fontWeight:
                                    isSubSectionType || isFeatureType
                                      ? "normal"
                                      : "bold",
                                }}
                              >
                                {product?.totalScore} %
                              </Text>
                            )}
                      </PDFTableCell>
                    ),
                  )}
                </PDFReviewTableRow>
              </>
            );
          })}
        </PDFTableBody>
      </PDFTable>
    </>
  );
};
