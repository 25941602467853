import { SHContainer, SHStack, SHTypography } from "@components/design-systems";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { useState } from "react";
import { ExportMAListExcelButton } from "./components/buttons/export-excel";
import { SMATableDisclaimerDialog } from "./components/dialogs/disclaimer";
import { SMAListVirtualized } from "./components/tables";
import { DisclaimerAssumptionsButton } from "@components/buttons/disclaimer-assumptions";
import { useEffectOnce } from "react-use";
import { getInvestmentFiltersPlatforms } from "@services/investment/investmentService";
import { SMAListCurrentlySelectedFilter, SMAListFavouriteFilter, SMAListMinimumInvestmentSizeFilter } from "@models/managed-accounts/enums/filter";
import { useDispatch } from "react-redux";
import { updateFilters } from "@redux/slices/managed-account/sma-list";

export const SMAListView = (): JSX.Element => {
  const dispatch = useDispatch()
  
  const [showTableDisclaimer, setShowTableDisclaimer] =
    useState<boolean>(false);

  useEffectOnce(() => {
    getInvestmentFiltersPlatforms().then((response) => {
      const initFilterData = {
        platforms: response?.data ?? [],
        favourites: SMAListFavouriteFilter.All,
        currentlySelected: SMAListCurrentlySelectedFilter.All,
        minimumInvestmentSize: SMAListMinimumInvestmentSizeFilter.All,
      };
      dispatch(updateFilters(initFilterData));
    });
  });

  return (
    <SHContainer sx={{ px: { xs: "16px", lg2: 0 } }}>
      <TopBarContainer>
        <TopBar
          title={"Managed account analysis"}
          tools={
            <>
              <ExportMAListExcelButton data={{} as any} />
            </>
          }
        />
      </TopBarContainer>
      <SHStack spacing={2}>
        <SMAListVirtualized />
        <SHTypography variant="body4" fontSize={"10px"}>
          By using this table, you confirm that you have read and accepted the{" "}
          <DisclaimerAssumptionsButton
            onClick={() => setShowTableDisclaimer(true)}
          />
        </SHTypography>

        {showTableDisclaimer && (
          <SMATableDisclaimerDialog
            onClose={() => setShowTableDisclaimer(false)}
          />
        )}
      </SHStack>
    </SHContainer>
  );
};
