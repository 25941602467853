import {
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { StepBadgeSkeleton } from "@components/skeletons/layouts/stepper/StepBadgeSkeleton";
import { ChevronSVG } from "@components/svgs/chevron";
import { useTheme } from "@mui/material";

export interface StepBadgeProps {
  selected?: boolean;
  stepNumber?: number;
  stepsLength?: number;
  label?: string;
  unHighlighted?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}
export const StepBadge = ({
  selected,
  stepsLength,
  stepNumber,
  label,
  unHighlighted,
  isLoading,
  isDisabled = false,
  ...others
}: StepBadgeProps & SHStackProps) => {
  const { palette } = useTheme();
  if (isLoading) return <StepBadgeSkeleton />;
  return (
    <SHStack
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={"20px"}
      sx={{
        cursor: "pointer",
        pointerEvents: isDisabled ? "none" : "auto",
      }}
      {...others}
    >
      <SHStack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={"7px"}
        sx={{
          height: "32px",
          borderRadius: "25px",
          borderStyle: "solid",
          borderWidth: "1px",
          px: "15px",
          py: "5px",
          transition: "all ease-in-out 0.2s",
          borderColor: selected ? palette.primary.main : "transparent",
          "&:hover": {
            borderColor: palette.primary.main,
          },
        }}
      >
        <SHTypography
          variant="body2"
          disabled={unHighlighted}
        >{`${stepNumber}/${stepsLength}`}</SHTypography>
        <SHTypography truncate disabled={unHighlighted} variant="subtitle2">
          {label}
        </SHTypography>
      </SHStack>
      {stepNumber !== stepsLength && (
        <SHStack>
          <ChevronSVG />
        </SHStack>
      )}
    </SHStack>
  );
};
