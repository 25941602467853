import { DateFormat } from "@constants/format";
import {
  DEFAULT_GAP,
  DEFAULT_GAP_2,
  DEFAULT_GAP_5,
  REVIEW_FIRST_PAGE_BODY_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import { join } from "lodash";
import { PDFReviewOverview } from "../../model";

interface PDFReviewOverviewProps {
  overview: PDFReviewOverview;
}

export const PDFReviewOverviewSection = ({
  overview,
}: PDFReviewOverviewProps) => {
  const { typographyStyle } = useStyles();
  const textColor = "#FFFFFF";
  const {
    name,
    adviserName,
    adviserARN,
    adviserFirmName,
    adviserFirmAFSL,
    familyGroupName,
    familyMembersNames,
    lastModifiedDate,
    dataValidDate,
    serial,
  } = overview;

  return (
    <View
      style={{
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexDirection: "column",
        height: REVIEW_FIRST_PAGE_BODY_HEIGHT - DEFAULT_GAP,
      }}
    >
      <Text
        style={{
          ...typographyStyle.title,
          color: textColor,
          fontSize: 24,
        }}
      >
        {name}
      </Text>

      <View style={{ gap: DEFAULT_GAP_2 }}>
        <View style={{ gap: DEFAULT_GAP_5 }}>
          {" "}
          <Text
            style={{
              ...typographyStyle.title,
              color: textColor,
              fontSize: "13px",
            }}
          >
            {`This Suitability Review is for ${familyGroupName} and the following individuals/entities:`}
          </Text>
          <Text
            style={{
              ...typographyStyle.title,
              color: textColor,
              fontWeight: "normal",
              fontSize: "13px",
            }}
          >
            {join(familyMembersNames, ", ").replace(/, (?!.*,)/g, " and ")}
          </Text>
        </View>

        <View style={{ gap: DEFAULT_GAP_5 }}>
          <Text
            style={{
              ...typographyStyle.title,
              color: textColor,
              fontWeight: "normal",
              fontSize: "13px",
            }}
          >
            {`Prepared by: `}
            <Text style={{ fontWeight: "bold" }}>{adviserName}</Text>
            {` of `}
            <Text style={{ fontWeight: "bold" }}>{adviserFirmName}</Text>
          </Text>
          <Text
            style={{
              ...typographyStyle.subtitle,
              color: textColor,
              fontSize: "11px",
            }}
          >
            {adviserARN && (
              <Text>
                ARN:
                <Text
                  style={{ fontWeight: "normal" }}
                >{` ${adviserARN}  |  `}</Text>
              </Text>
            )}
            {adviserFirmAFSL && (
              <Text>
                AFSL No.
                <Text style={{ fontWeight: "normal" }}>{adviserFirmAFSL}</Text>
              </Text>
            )}
          </Text>
        </View>

        <Text
          style={{
            ...typographyStyle.subtitle,
            color: textColor,
            fontWeight: "normal",
            fontSize: "11px",
          }}
        >
          {`${
            lastModifiedDate
              ? `Last edit: ${format(new Date(lastModifiedDate), DateFormat)}`
              : ""
          }  |  ${
            dataValidDate
              ? `Data valid: ${format(new Date(dataValidDate), DateFormat)}`
              : ""
          } |  Serial: ${serial}`}
        </Text>
      </View>
    </View>
  );
};
