import { SVGProps } from "react";

export const Dollar2SVG = ({
  color = "#7C8493",
  ...others
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      d="M5.5 14.824c.204 2.292 2.08 3.919 4.938 4.129v1.672h1.434v-1.672c3.121-.246 5.057-1.977 5.057-4.537 0-2.187-1.302-3.452-4.064-4.164l-.993-.257V4.767c1.543.151 2.583.982 2.846 2.222h2.021c-.228-2.2-2.117-3.778-4.867-3.953V1.375h-1.434V3.07C7.77 3.387 5.94 5.094 5.94 7.41c0 2 1.329 3.414 3.66 4.01l.838.223v5.543c-1.58-.234-2.668-1.1-2.93-2.362H5.5Zm4.663-5.275c-1.434-.361-2.2-1.134-2.2-2.222 0-1.298.968-2.256 2.475-2.513V9.62l-.275-.07Zm2.187 2.574c1.77.445 2.547 1.182 2.547 2.433 0 1.508-1.136 2.513-3.025 2.666v-5.218l.478.118v.001Z"
      fill={color}
    />
  </svg>
);
