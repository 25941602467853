import { SelectedProductDTO } from "@models/platform-analysis/entities/steps/feature";
import { useTheme } from "@mui/material";
import { PDFTableCell } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-cell";
import { PDFTableRow } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/tables/table-row";
import { PDFDefaultPlatformLogoS3 } from "@pages/platform-analysis/components/buttons/export-pdf/config";
import {
  TABLE_CELL_WIDTH,
  TABLE_FIRST_CELL_WIDTH,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { generatePDFImageIconUrl } from "@pages/platform-analysis/components/buttons/export-pdf/util";
import { Image, Text, View } from "@react-pdf/renderer";
import { PDFMainBadge } from "../main-badge";

interface PDFSummaryAnalysisHeaderProps {
  selectedProducts?: SelectedProductDTO[];
  isFeatureAnalysis?: boolean;
  isDetailMode?: boolean;
}

export const PDFSummaryAnalysisHeader = ({
  selectedProducts,
  isFeatureAnalysis = false,
  isDetailMode = false,
}: PDFSummaryAnalysisHeaderProps) => {
  const { typographyStyle } = useStyles();
  const { palette } = useTheme();
  let firstCellWidth = TABLE_FIRST_CELL_WIDTH;
  if (!isFeatureAnalysis || (isFeatureAnalysis && isDetailMode))
    firstCellWidth += TABLE_CELL_WIDTH;

  return (
    <PDFTableRow type="HeaderLine">
      <PDFTableCell width={firstCellWidth}>
        <Text
          style={{
            ...typographyStyle.body2,
            color: palette.text.primary,
            fontWeight: "bold",
          }}
        >
          {isFeatureAnalysis
            ? "Feature groups and sub-groups"
            : "Business metrics"}
        </Text>
        {isFeatureAnalysis && isDetailMode && (
          <Text
            style={{
              ...typographyStyle.body2,
              color: palette.text.primary,
              fontWeight: "bold",
            }}
          >
            (Selections / # available)
          </Text>
        )}
      </PDFTableCell>
      {isFeatureAnalysis && !isDetailMode && (
        <PDFTableCell width={TABLE_CELL_WIDTH}>
          <Text
            style={{
              ...typographyStyle.body2,
              color: palette.text.primary,
              fontWeight: "bold",
            }}
          >
            Selections /
          </Text>
          <Text
            style={{
              ...typographyStyle.body2,
              color: palette.text.primary,
              fontWeight: "bold",
            }}
          >
            # Available
          </Text>
        </PDFTableCell>
      )}

      {selectedProducts?.map((product, index) => (
        <PDFTableCell width={TABLE_CELL_WIDTH} key={index}>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              height: "100%",
              gap: 5,
            }}
          >
            <View
              style={{
                position: "relative",
                width: "100%",
                alignItems: "center",
              }}
            >
              {product.isProductMain && <PDFMainBadge />}
              {product?.productLogo?.url ? (
                <Image
                  style={{
                    position: "absolute",
                    zIndex: -1,
                    width: 35,
                    height: 35,
                    marginTop: 1,
                  }}
                  src={generatePDFImageIconUrl(product?.productLogo?.url)}
                />
              ) : (
                <Image
                  style={{
                    position: "absolute",
                    zIndex: -1,
                    width: 35,
                    height: 35,
                    marginTop: 1,
                  }}
                  src={PDFDefaultPlatformLogoS3}
                />
              )}
            </View>

            <Text
              style={{
                ...typographyStyle.body2,
                color: palette.text.primary,
                fontWeight: "bold",
                textAlign: "center",
                marginTop: 35,
              }}
            >
              {product?.productName}
            </Text>
          </View>
        </PDFTableCell>
      ))}
    </PDFTableRow>
  );
};
