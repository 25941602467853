import { ClientStatus } from "@models/reviews/enums/status";
import { UserLOV } from "@models/users/entities/user";
import { generateUUID } from "@utils";

export class FamilyGroupEntityDTO {
  id: string | null = generateUUID();
  name: string | null = null;
  order: number = 0;
}

export class FamilyGroupDTO {
  id?: string = "";
  name?: string = "";
  status?: ClientStatus;
  adviserFirmId?: string = "";
  adviserUserId?: string = "";
  adviserUserName?: string = "";
  adviserUserAvatar?: string = "";
  familyGroupEntities?: FamilyGroupEntityDTO[] = [];
}

export class CreateFamilyGroupDTO extends FamilyGroupDTO {}

export class DetailFamilyGroupDTO extends FamilyGroupDTO{
  adviserUsers?: UserLOV[] = []
}
