import { SVGProps } from "react";

export const PhotoSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={25}
        height={25}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.5 7.16663H16.5133"
            stroke="#25324B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.1663 1.83337H5.83301C3.62387 1.83337 1.83301 3.62423 1.83301 5.83337V19.1667C1.83301 21.3758 3.62387 23.1667 5.83301 23.1667H19.1663C21.3755 23.1667 23.1663 21.3758 23.1663 19.1667V5.83337C23.1663 3.62423 21.3755 1.83337 19.1663 1.83337Z"
            stroke="#25324B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.83301 16.5001L7.16634 11.1667C7.77442 10.5816 8.46419 10.2736 9.16634 10.2736C9.86849 10.2736 10.5583 10.5816 11.1663 11.1667L17.833 17.8334"
            stroke="#25324B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.166 15.1667L16.4993 13.8334C17.1074 13.2482 17.7972 12.9402 18.4993 12.9402C19.2015 12.9402 19.8913 13.2482 20.4993 13.8334L23.166 16.5"
            stroke="#25324B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
