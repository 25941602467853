import { ToolbarLetterCounter } from "./ToolbarLetterCounter";

interface ToolbarEditorProps {
  count?: number;
  maxLength?: number;
  id: string;
  className?: string;
  isCommentBox?: boolean;
}
export const ToolbarEditor = ({
  count,
  maxLength,
  id,
  className,
  isCommentBox = false,
}: ToolbarEditorProps) => (
  <div className={className}>
    <div id={id}>
      <button className="ql-bold" />
      <button className="ql-italic" />
      {isCommentBox ? (
        <button className="ql-underline" />
      ) : (
        <>
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
        </>
      )}
      <span className="ql-letterCounter">
        <ToolbarLetterCounter count={count} maxLength={maxLength} />
      </span>
    </div>
  </div>
);
