import { ReviewStep } from "@models/reviews/enums/step";

const { Setup, Feature, BusinessMetric, Fee, Summary } = ReviewStep;

export const reviewStepLabelMapping: {
  [key in ReviewStep]: string;
} = {
  [Setup]: "Setup review",
  [Feature]: "Feature review",
  [BusinessMetric]: "Business review",
  [Fee]: "Fee review",
  [Summary]: "Final review",
};
