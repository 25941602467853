import { Components, Theme } from "@mui/material";
import { hexToRGBA } from "@utils";

declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    extraMedium: true;
  }
}

export const muiButtonClassName = {
  withIcon: "sh-btn-with-icon",
};
export const muiButtonTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiButton"> => ({
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "25px",
        boxShadow: theme.shadows[1],
        textTransform: "none",
        whiteSpace: "nowrap",
        transition: "all 150ms",
        "&>.MuiButton-startIcon,&>.MuiButton-endIcon": {
          marginTop: "-2px",
        },
      },
      sizeSmall: {
        height: "24px",
        fontSize: "12px",
        fontWeight: 500,
        paddingTop: "5px",
        "&>.MuiButton-startIcon >.MuiSvgIcon-root, &>.MuiButton-endIcon >.MuiSvgIcon-root":
          {
            fontSize: "16px",
          },
      },
      sizeMedium: {
        paddingTop: "8px",
        height: "32px",
        fontSize: "13px",
        fontWeight: 600,
      },
      sizeLarge: {
        height: "50px",
        fontSize: "16px",
        paddingTop: "10px",
        fontWeight: 600,
        padding: "10px 26px 8px 26px",
      },
      contained: {
        border: `1px solid ${theme.palette.primary[800]}`,
        "&:hover": {
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: `inset 3px 3px 4px ${theme.palette.primary[900]}`,
          backgroundColor: theme.palette.primary.dark,
        },
        "&.Mui-disabled": {
          border: `1px solid ${hexToRGBA(theme.palette.primary.dark, 0.3)}`,
          backgroundColor: theme.palette.primary[50],
          color: theme.palette.common.white,
        },
        [`&.Mui-disabled.${muiButtonClassName.withIcon}`]: {
          border: `1px solid ${theme.palette.text.disabled}`,
          backgroundColor: theme.palette.grey[400],
        },
      },
      outlined: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.primary,
        "&:hover": {
          boxShadow: "none",
        },
        "&:active": {
          backgroundColor: theme.palette.primary.dark,
          color: "white",
          boxShadow: `inset 3px 3px 4px ${theme.palette.primary["900"]}`,
        },
        "&.Mui-disabled": {
          borderColor: theme.palette.text.disabled,
          color: theme.palette.text.disabled,
        },
      },
      text: {
        boxShadow: "none",
        border: "1px solid transparent",
        color: theme.palette.text?.primary,
        fontWeight: 500,
        "&:hover": {
          backgroundColor: "transparent",
          fontWeight: 600,
        },
        "&:active": {
          border: `1px solid ${theme.palette.primary[800]}`,
        },
        "&.Mui-disabled": {
          color: theme.palette.text.disabled,
        },
      },
      textInherit: {
        color: theme.palette.text?.primary,
        "&:active": {
          border: `1px solid ${theme.palette.primary[800]}`,
        },
      },
      textPrimary: {
        color: theme.palette.primary.main,
        "&:active": {
          border: `1px solid ${theme.palette.primary[800]}`,
        },
      },
      textSecondary: {
        color: theme.palette.text.secondary,
        "&:active": {
          borderColor: theme.palette.text.secondary,
        },
      },
    },
    defaultProps: {
      disableRipple: true,
      fullWidth: false,
    },
    variants: [
      {
        props: { size: "extraMedium" },
        style: {
          height: "38px",
          paddingTop: "8px",
          paddingBottom: "6px",
        },
      },
    ],
  },
});
