import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";

interface OverwriteTemplateDialogProps {
  onClose?: () => void;
  onSubmit?: () => void;
  isSubmitting?: boolean;
}

export const OverwriteTemplateDialog = ({
  onClose,
  onSubmit,
  isSubmitting = false,
}: OverwriteTemplateDialogProps) => {
  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: { xs: "100%", md: 550 },
        },
      }}
      header="Overwrite the existing template?"
      body={
        <SHStack spacing={"25px"}>
          <SHTypography variant="body2" sx={{ fontSize: "16px" }}>
            This name already exists. Overwrite existing template?
          </SHTypography>
        </SHStack>
      }
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onClose}
            disabled={isSubmitting}
          >
            No, I'll change the name
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onSubmit}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Yes, overwrite it
          </SHButton>
        </SHStack>
      }
    />
  );
};
