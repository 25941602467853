import { CloseButton } from "@components/buttons/close";
import {
  SHDialog,
  SHHtmlBlock,
  SHImage,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { DateFormat } from "@constants/format";
import { S3ObjectDTO } from "@models/core";
import { PostDTO } from "@models/news-and-updates/entities/post";
import { DialogContent, DialogTitle, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { format } from "date-fns";
import { orderBy } from "lodash";
import { ImagesCarousel } from "../../images-carousel";

export interface ReadMoreDialogProps {
  isOpen: boolean;
  post: PostDTO;
  images: S3ObjectDTO[];
  onClose?: () => void;
}
export const ReadMoreDialog = (props: ReadMoreDialogProps) => {
  const { isOpen, onClose, post, images } = props;
  const { palette } = useTheme();

  const date: Date = post.publishedDate
    ? post.publishedDate
    : post.lastUpdatedDate;

  return (
    <SHDialog
      open={isOpen}
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          padding: 0,
          overflow: "hidden",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          border: 0,
          background: `${palette.common.white}`,
          width: "70%",
          height: "auto",
          maxHeight: "700px",
        },
      }}
    >
      <DialogTitle
        borderBottom={`1px solid ${palette.secondary[100]}`}
        display={"flex"}
        height={55}
        padding={"0px !important"}
        alignItems="stretch"
        justifyContent="space-between"
        flexDirection="row"
        sx={{
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.secondary[100],
            0.25,
          )}, ${hexToRGBA(palette.secondary[100], 0.25)}),${
            palette.common.white
          }`,
        }}
      >
        <SHStack
          direction={"row"}
          alignItems={"center"}
          sx={{
            padding: "16.5px 25px !important",
            borderRight: `1px solid ${palette.secondary[100]}`,
          }}
        >
          <SHImage height={28} width={28} src={post.productLogo} />
          <SHTypography
            variant="body1"
            lineHeight="120%"
            sx={{
              marginLeft: "10px",
              textAlign: "left",
            }}
          >
            {post.title}
          </SHTypography>
        </SHStack>
        <SHStack padding={"18.99px 20px !important"}>
          <CloseButton width="12px" height="12px" onClick={onClose} />
        </SHStack>
      </DialogTitle>
      <SHStack
        sx={{
          padding: "25px 0",
          height: "100%",
          width: "100%",
          overflowY: "var(--overflow-overlay)",
          "::-webkit-scrollbar": {
            width: "10px",
            height: "10px",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: `${palette.text.third}`,
            boxShadow: "1px 1px 2px rgba(65, 73, 89, 0.4)",
            backdropFilter: "blur(0.5px)",
            borderRadius: "25px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgba(81, 91, 111, 0.75);",
          },
          "::-webkit-scrollbar-thumb:active": {
            backgroundColor: "rgba(81, 91, 111, 0.5);",
            boxShadow: "inset 1px 1px 2px #25324B",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: "0 63px !important",
            textAlign: "justify",
          }}
        >
          <SHStack
            direction={"row"}
            spacing={1}
            width={"100%"}
            color={palette.text.third}
            marginBottom={"10px"}
            display={"inline-block"}
            sx={{ verticalAlign: "middle" }}
          >
            <SHTypography
              pt={0}
              variant="body3"
              color={"inherit"}
              textAlign={"left"}
            >
              {post.productName}
            </SHTypography>
            <SHTypography
              sx={{
                color: "inherit",
                fontSize: "8px",
                verticalAlign: "middle",
                display: "inline-block",
              }}
            >
              &#9679;
            </SHTypography>
            <SHTypography pt={0} variant="body4" color={"inherit"}>
              {(post.publishedDate ? "" : "Last updated date: ") +
                (date ? format(new Date(date), DateFormat) : "")}
            </SHTypography>
            {post.userName && (
              <>
                <SHTypography
                  sx={{
                    color: "inherit",
                    fontSize: "8px",
                    verticalAlign: "middle",
                    display: "inline-block",
                  }}
                >
                  &#9679;
                </SHTypography>
                <SHTypography pt={0} variant="body4" color={"inherit"}>
                  {post.userName}
                </SHTypography>
              </>
            )}
          </SHStack>
          <SHStack width={"100%"} paddingY={0}>
            <SHHtmlBlock
              variant="body2"
              sx={{
                wordBreak: "break-word",
                padding: "0",
              }}
              content={post.content}
            />
            {images && images.length > 0 && (
              <ImagesCarousel
                images={orderBy(
                  images ?? [],
                  [(image) => image.key.toLocaleLowerCase()],
                  "asc",
                )}
              />
            )}
          </SHStack>
        </DialogContent>
      </SHStack>
    </SHDialog>
  );
};
