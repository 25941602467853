import { useNotification } from "@hooks/useNotification";
import { AnalysisDTO } from "@models/platform-analysis/entities/analysis";
import { AnalysisBannerNameDTO } from "@models/platform-analysis/entities/bannerName";
import { AnalysisStepDTO } from "@models/platform-analysis/entities/step";
import { BusinessMetricCategories } from "@models/platform-analysis/entities/steps/businessMetric";
import {
  AnalysisConfigurationFeatureSectionDTO,
  AnalysisGetFeatureSectionsDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisFeeCalculatorDTO } from "@models/platform-analysis/entities/steps/fee";
import { AnalysisSetupDTO } from "@models/platform-analysis/entities/steps/setup";
import { AnalysisStatus } from "@models/platform-analysis/enums/status";
import { AnalysisStep } from "@models/platform-analysis/enums/step";

import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import {
  createPlatformAnalysis,
  getAnalysisBannerName,
  getBusinessMetricSelections,
  getFeatureSelections,
  getFeeCalculator,
  getPlatformAnalysis,
  getPlatformAnalysisSteps,
  resetAnalysisStore,
  resetFee,
  updateAnalysisSteps,
  updateBannerName,
  updateBusinessMetricSelectionThunk,
  updateDisclaimers,
  updateFeatureSelection,
  updateFeatureSelectionsGroup,
  updateFeeCalculatorStore,
  updateFeeCalculatorThunk,
  updateIsAccessDenied,
  updateIsCompleted,
  updateIsDirty,
  updateIsReadOnly,
  updateIsShowHidden,
  updateIsValid,
  updatePlatformAnalysis,
  updatePlatformAnalysisStore,
  updateReachAnalysisSetupPage,
  updateSelectedStepIndex,
  updateSelectedSubStepIndex,
  updateStepHidden,
  updateStepUnHighlighted,
  updateTotalCollapsedFeatureAnalysisRowsAction,
  updateTotalCollapsedBusinessMetricRowsAction,
  updateIsShowAnalysisScores,
  updateLineChartColorsAction,
} from "@redux/slices/platform-analysis";
import { RootState, useAppDispatch } from "@redux/store";
import {
  duplicateAnalysis,
  getDisclaimers,
  putPlatformAnalysisComment,
  putPlatformAnalysisStatus,
} from "@services/platform-analysis/platformAnalysisService";
import { useState } from "react";
import { useSelector } from "react-redux";

const { Feature, BusinessMetric, Fee } = AnalysisStep;
export const usePlatformAnalysis = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const {
    platformAnalysis,
    disclaimers,
    lineChartColors,
    isCompleted,
    isReadOnly,
    ui: {
      reachAnalysisSetupPage,
      isShowAnalysisScores,
    },
  } = useSelector((state: RootState) => state.platformAnalysis);
  const [isModifyingComment, setIsModifyingComment] = useState(false);

  const setBannerName = (bannerName: AnalysisBannerNameDTO) => {
    dispatch(updateBannerName(bannerName));
  };
  const setPlatformAnalysis = (platformAnalysis?: AnalysisDTO) => {
    dispatch(updatePlatformAnalysisStore(platformAnalysis));
  };
  const setSelectedStepIndex = (stepIndex?: number) => {
    dispatch(updateSelectedStepIndex(stepIndex));
  };
  const setSelectedSubStepIndex = (subStepIndex?: number) => {
    dispatch(updateSelectedSubStepIndex(subStepIndex));
  };
  const setAnalysisSteps = (analysisSteps?: AnalysisStepDTO) => {
    dispatch(updateAnalysisSteps(analysisSteps));
  };
  const setAnalysisDisclaimers = (disclaimers?: DisclaimerDTO[]) => {
    dispatch(updateDisclaimers(disclaimers));
  };

  const setStepUnHighlighted = (
    step: AnalysisStep,
    isUnHighlighted?: boolean,
  ) => {
    dispatch(updateStepUnHighlighted({ step, isUnHighlighted }));
  };

  const setStepHidden = ({
    step,
    isHidden,
  }: {
    step: AnalysisStep;
    isHidden: boolean | undefined;
  }) => {
    dispatch(updateStepHidden({ step, isHidden }));
  };

  const setIsValid = (isValid?: boolean) => {
    dispatch(updateIsValid(isValid));
  };
  const setIsCompleted = (isCompleted?: boolean) => {
    dispatch(updateIsCompleted(isCompleted));
  };
  const setIsReadOnly = (isReadOnly?: boolean) => {
    dispatch(updateIsReadOnly(isReadOnly));
  };

  const setIsAccessDenied = (isAccessDenied?: boolean) => {
    dispatch(updateIsAccessDenied(isAccessDenied));
  };

  const setIsShowHidden = (isShowHidden?: boolean) => {
    dispatch(updateIsShowHidden(isShowHidden));
  };

  const setIsDirty = (isDirty: boolean) => {
    dispatch(updateIsDirty(isDirty));
  };

  const setIsShowAnalysisScores = (isShowScore: boolean) => {
    dispatch(updateIsShowAnalysisScores(isShowScore));
  };

  const setReachAnalysisSetupPage = (status?: "FIRST_TIME" | "SECOND_TIME") => {
    dispatch(updateReachAnalysisSetupPage(status));
  };

  const setTotalCollapsedFeatureAnalysisRows = (total: number) => {
    dispatch(updateTotalCollapsedFeatureAnalysisRowsAction(total));
  };

  const setTotalCollapsedBusinessMetricRows = (total: number) => {
    dispatch(updateTotalCollapsedBusinessMetricRowsAction(total));
  };

  // Line chart colors
  const setLineChartColors = (colors: { [key in string]: string }) => {
    dispatch(updateLineChartColorsAction(colors))
  }

  const resetPlatformAnalysisFee = () => {
    dispatch(resetFee());
  };

  const createNewPlatformAnalysis = async (setupStep: AnalysisSetupDTO) => {
    const response = await dispatch(createPlatformAnalysis(setupStep)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updateCurrentPlatformAnalysis = async (
    platformAnalysis: AnalysisDTO,
    isShowMessage: boolean = true,
  ) => {
    const response = await dispatch(
      updatePlatformAnalysis(platformAnalysis),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return false;
    }
    if (isShowMessage) {
      notify(response.message, {
        variant: "success",
        close: true,
      });
    }

    return response.data;
  };

  const loadPlatformAnalysis = async (params: {
    platformAnalysisId: string;
    stepId: AnalysisStep;
  }) => {
    const response = await dispatch(getPlatformAnalysis(params)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    setStepUnHighlighted(params.stepId, false);
    return response.data;
  };

  const loadPlatformAnalysisDisclaimers = async (
    platformAnalysisId: string,
  ) => {
    const response = await getDisclaimers(platformAnalysisId);
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadAnalysisBannerName = async (platformAnalysisId: string) => {
    const response = await dispatch(
      getAnalysisBannerName(platformAnalysisId),
    ).unwrap();
    setIsAccessDenied(response.isForbidden);
    if (response.isForbidden) {
      return;
    }
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadPlatformAnalysisStep = async (platformAnalysisId: string) => {
    const response = await dispatch(
      getPlatformAnalysisSteps(platformAnalysisId),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }

    return response.data;
  };
  const setFeatureSelections = (
    selections: AnalysisConfigurationFeatureSectionDTO[],
  ) => {
    dispatch(updateFeatureSelectionsGroup(selections));
  }; // Feature Analysis
  const loadFeatureSelections = async (params: {
    platformAnalysisId: string;
  }) => {
    const response = await dispatch(getFeatureSelections(params)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    setStepUnHighlighted(Feature, false);
    return response.data;
  };

  const updateFeatureSelections = async (params: {
    platformAnalysisId: string;
    featureSelections: AnalysisGetFeatureSectionsDTO;
  }) => {
    const response = await dispatch(updateFeatureSelection(params)).unwrap();
    if (!response.isSuccess) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return false;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return true;
  };

  // Business metric
  const loadBusinessMetricSelections = async (params: {
    platformAnalysisId: string;
  }) => {
    const response = await dispatch(
      getBusinessMetricSelections(params),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    setStepUnHighlighted(BusinessMetric, false);
    return response.data;
  };

  const updateBusinessMetricSelections = async (params: {
    platformAnalysisId: string;
    businessMetricSelections: BusinessMetricCategories;
  }) => {
    const response = await dispatch(
      updateBusinessMetricSelectionThunk(params),
    ).unwrap();
    if (!response.isSuccess) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return false;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return true;
  };

  // Fee
  const setFeeCalculator = (fee?: AnalysisFeeCalculatorDTO) => {
    dispatch(updateFeeCalculatorStore(fee));
  };

  const loadFeeCalculator = async (params: { platformAnalysisId: string }) => {
    const response = await dispatch(getFeeCalculator(params)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    setStepUnHighlighted(Fee, false);
    return response.data;
  };

  const updateFeeCalculator = async (params: {
    platformAnalysisId: string;
    formData: AnalysisFeeCalculatorDTO;
  }) => {
    const response = await dispatch(updateFeeCalculatorThunk(params)).unwrap();
    if (!response.isSuccess) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return false;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return true;
  };

  const updatePlatformAnalysisStatus = async (
    platformAnalysisId: string,
    status: AnalysisStatus,
  ) => {
    const { isSuccess, data, message } = await putPlatformAnalysisStatus(
      platformAnalysisId,
      status,
    );
    if (isSuccess && data) {
      notify(
        status === AnalysisStatus.Archived
          ? "Analysis archived."
          : "Analysis restored.",
        {
          variant: "success",
          close: true,
        },
      );
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const modifyComment = async (
    platformAnalysisId: string,
    productId: string,
    stepId: AnalysisStep,
    comment: string | null,
  ) => {
    setIsModifyingComment(true);
    const { isSuccess, message, data } = await putPlatformAnalysisComment({
      comment: comment || "",
      platformAnalysisId,
      productId,
      stepId,
    });
    setIsModifyingComment(false);
    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      return data;
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
      return;
    }
  };

  const resetStore = () => {
    dispatch(resetAnalysisStore());
  };

  const duplicatePlatformAnalysis = async (platformAnalysisId: string) => {
    const { isSuccess, data, message } = await duplicateAnalysis(
      platformAnalysisId,
    );
    if (isSuccess && data) {
      notify(message, {
        variant: "success",
        close: true,
      });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return {
    disclaimers,
    lineChartColors,
    isCompleted,
    isReadOnly,
    isModifyingComment,
    isShowAnalysisScores,
    platformAnalysis,
    setSelectedStepIndex,
    setSelectedSubStepIndex,
    setIsCompleted,
    setIsReadOnly,
    setIsValid,
    setIsAccessDenied,
    setIsShowHidden,
    setIsDirty,
    setIsShowAnalysisScores,
    setBannerName,
    setAnalysisSteps,
    setStepHidden,
    setPlatformAnalysis,
    setStepUnHighlighted,
    setFeatureSelections,
    createNewPlatformAnalysis,
    updateCurrentPlatformAnalysis,
    loadPlatformAnalysis,
    loadPlatformAnalysisDisclaimers,
    loadFeatureSelections,
    loadBusinessMetricSelections,
    updateFeatureSelections,
    updateBusinessMetricSelections,
    setFeeCalculator,
    loadFeeCalculator,
    updateFeeCalculator,
    loadAnalysisBannerName,
    loadPlatformAnalysisStep,
    resetStore,
    updatePlatformAnalysisStatus,
    modifyComment,
    reachAnalysisSetupPage,
    setReachAnalysisSetupPage,
    setAnalysisDisclaimers,
    setTotalCollapsedFeatureAnalysisRows,
    setTotalCollapsedBusinessMetricRows,
    setLineChartColors,
    duplicatePlatformAnalysis,
    resetPlatformAnalysisFee,
  };
};
