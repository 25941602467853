import { useNotification } from "@hooks/useNotification";

import { RootState, useAppDispatch } from "@redux/store";

import {
  FeeAnalysisTableRow,
  ManagedAccountBannerInfoDTO,
  ManagedAccountDTO,
  ManagedAccountDecisionDTO,
  ManagedAccountFilterDTO,
  ManagedAccountStepDTO,
  ManagedAccountUpdateDTO,
} from "@models/managed-accounts/entities/analysis";
import { ManagedAccountPortfolioSetupCreateDTO } from "@models/managed-accounts/entities/step/setup";
import { ManagedAccountStep } from "@models/managed-accounts/enums/step";
import {
  createManagedAccountThunk,
  getManagedAccountBannerInfoThunk,
  getManagedAccountStepThunk,
  getManagedAccountStepsThunk,
  loadPortfolioTemplatesThunk,
  resetManagedAccountStore,
  updateBannerInfo,
  updateCollapseMappingAction,
  updateFeeAnalysisRowsAction,
  updateIsAccessDenied,
  updateIsCompleted,
  updateIsDirty,
  updateIsReadOnly,
  updateIsValid,
  updateManagedAccountSteps,
  updateManagedAccountStore,
  updateManagedAccountThunk,
  updateSelectedStepIndex,
  updateStepUnHighlighted,
} from "@redux/slices/managed-account";
import {
  getManagedAccountPortfolioTemplateById,
  putManagedAccountDecision,
  putManagedAccountFilters,
} from "@services/managed-accounts/managedAccountService";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { CollapseType } from "@models/managed-accounts/entities/step/fee";

export const useManagedAccount = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { managedAccountId } = useParams<{ managedAccountId: string }>();
  const { ui, bannerInfo, managedAccount, managedAccountSteps, isCompleted } =
    useSelector((state: RootState) => state.managedAccount);

  // UI states
  const setIsValid = (isValid?: boolean) => {
    dispatch(updateIsValid(isValid));
  };

  const setIsDirty = (isDirty: boolean) => {
    dispatch(updateIsDirty(isDirty));
  };

  const setIsReadOnly = (isReadOnly?: boolean) => {
    dispatch(updateIsReadOnly(isReadOnly));
  };

  const setIsAccessDenied = (isAccessDenied?: boolean) => {
    dispatch(updateIsAccessDenied(isAccessDenied));
  };

  const setSelectedStepIndex = (stepIndex?: number) => {
    dispatch(updateSelectedStepIndex(stepIndex));
  };

  const setStepUnHighlighted = (
    step: ManagedAccountStep,
    isUnHighlighted?: boolean,
  ) => {
    dispatch(updateStepUnHighlighted({ step, isUnHighlighted }));
  };

  // Data states
  const setIsCompleted = (isCompleted?: boolean) => {
    dispatch(updateIsCompleted(isCompleted));
  };

  const setBannerInfo = (bannerInfo: ManagedAccountBannerInfoDTO) => {
    dispatch(updateBannerInfo(bannerInfo));
  };

  const setManagedAccount = (managedAccount?: ManagedAccountDTO) => {
    dispatch(updateManagedAccountStore(managedAccount));
  };

  const setManagedAccountSteps = (
    managedAccountStep?: ManagedAccountStepDTO,
  ) => {
    dispatch(updateManagedAccountSteps(managedAccountStep));
  };

  const setFeeAnalysisRow = (rows: FeeAnalysisTableRow[]) => {
    dispatch(updateFeeAnalysisRowsAction(rows));
  };

  const setFeeAnalysisCollapseRowMapping = (type: CollapseType) => {
    dispatch(updateCollapseMappingAction(type));
  };

  // APIs interaction
  const loadManagedAccountBannerInfo = async (managedAccountId: string) => {
    const response = await dispatch(
      getManagedAccountBannerInfoThunk(managedAccountId),
    ).unwrap();
    setIsAccessDenied(response.isForbidden);
    if (response.isForbidden) {
      return;
    }
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadManagedAccountSteps = async (managedAccountId: string) => {
    const response = await dispatch(
      getManagedAccountStepsThunk(managedAccountId),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }

    return response.data;
  };

  const loadManagedAccountStep = async (params: {
    managedAccountId: string;
    stepId: ManagedAccountStep;
  }) => {
    const response = await dispatch(
      getManagedAccountStepThunk(params),
    ).unwrap();

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }

    setStepUnHighlighted(params.stepId, false);

    return response.data;
  };

  const loadManagedAccountPortfolioTemplates = async () => {
    const response = await dispatch(loadPortfolioTemplatesThunk()).unwrap();

    if (!response.isSuccess || !response.data) {
      return;
    }

    return response.data;
  };

  const loadManagedAccountPortfolioTemplateById = async (
    managedAccountId: string,
    portfolioTemplateId: string,
    portfolioSize: number,
  ) => {
    const response = await getManagedAccountPortfolioTemplateById(
      managedAccountId,
      portfolioTemplateId,
    );

    if (!response.isSuccess || !response.data) {
      return;
    }

    response.data.forEach((item) => {
      item.value = Number(
        (((item.weight ?? 0) / 100) * portfolioSize).toFixed(2),
      );
    });

    return response.data;
  };

  const createManagedAccount = async (
    setupStep: ManagedAccountPortfolioSetupCreateDTO,
  ) => {
    const response = await dispatch(
      createManagedAccountThunk(setupStep),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updateManagedAccount = async (
    managedAccount: ManagedAccountUpdateDTO,
    isShowMessage: boolean = true,
  ) => {
    const response = await dispatch(
      updateManagedAccountThunk(managedAccount),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return false;
    }
    if (isShowMessage) {
      notify(response.message, {
        variant: "success",
        close: true,
      });
    }

    return response.data;
  };

  const updateManagedAccountFilter = async (
    manageAccountId: string,
    managedAccountFilter: ManagedAccountFilterDTO,
  ) => {
    await putManagedAccountFilters(manageAccountId, managedAccountFilter);
  };

  const updateManagedAccountDecision = async (
    manageAccountId: string,
    manageAccountDecision: ManagedAccountDecisionDTO,
  ) => {
    const response = await putManagedAccountDecision(manageAccountId, manageAccountDecision);

    if (!response.isSuccess) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return false;
    }

    return true;
  };

  const resetStore = () => {
    dispatch(resetManagedAccountStore());
  };

  return {
    ui,

    isCompleted,
    bannerInfo,
    managedAccount,
    managedAccountId,
    managedAccountSteps,

    setIsDirty,
    setIsValid,
    setIsReadOnly,
    setIsAccessDenied,
    setSelectedStepIndex,
    setStepUnHighlighted,

    setIsCompleted,
    setBannerInfo,
    setManagedAccount,
    setManagedAccountSteps,
    setFeeAnalysisRow,
    setFeeAnalysisCollapseRowMapping,

    loadManagedAccountStep,
    loadManagedAccountSteps,
    loadManagedAccountBannerInfo,
    loadManagedAccountPortfolioTemplates,
    loadManagedAccountPortfolioTemplateById,
    createManagedAccount,
    updateManagedAccount,
    updateManagedAccountFilter,
    updateManagedAccountDecision,

    resetStore,
  };
};
