import topNotification from "@redux/slices/top-notifications";
import { combineReducers } from "redux";
import auth from "./slices/auth";
import configuration from "./slices/configuration";
import global from "./slices/global";
import newsFeed from "./slices/news-feed";
import platformAnalysis from "./slices/platform-analysis";
import product from "./slices/product";
import supplier from "./slices/supplier";
import rateCard from "./slices/rate-cards";
import practice from "./slices/practice";
import practiceUser from "./slices/practice-user";
import featureTemplate from "./slices/feature-template";
import reviewClient from "./slices/review-client";
import review from "./slices/review";
import managedAccount from "./slices/managed-account";
import investment from "./slices/investments";
import portfolioTemplate from "./slices/portfolio-template";
import smaList from "./slices/managed-account/sma-list";
import investmentProduct from "./slices/product/investment-product";

const rootReducer = combineReducers({
  auth,
  supplier,
  configuration,
  topNotification,
  product,
  global,
  platformAnalysis,
  newsFeed,
  rateCard,
  practice,
  practiceUser,
  featureTemplate,
  review,
  reviewClient,
  managedAccount,
  investment,
  portfolioTemplate,
  smaList,
  investmentProduct,
});

export default rootReducer;
