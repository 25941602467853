import {
  SHTableRow,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { OverallScoreDTO } from "@models/platform-analysis/entities/steps/feature";
import { SummaryAnalysisCell } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-cell";

interface SummaryAnalysisOverallScoreProps extends SHTableRowProps {
  overallScore?: OverallScoreDTO;
  isFeatureAnalysis?: boolean;
  isDetailMode?: boolean;
  isShowScore?: boolean;
}

export const SummaryAnalysisOverallScore = ({
  overallScore,
  isFeatureAnalysis = false,
  isDetailMode = false,
  isShowScore = false,
  ...others
}: SummaryAnalysisOverallScoreProps) => {
  const isShowAnalysisScore =
    !isFeatureAnalysis ||
    (isFeatureAnalysis && (!isDetailMode || (isDetailMode && isShowScore)));
  return (
    <SHTableRow {...others}>
      <SummaryAnalysisCell cellWidth={"250px"}>
        <SHTypography
          variant="subtitle1"
          sx={{
            lineHeight: "150%",
            fontWeight: 700,
          }}
        >
          Overall score
        </SHTypography>
      </SummaryAnalysisCell>
      {isFeatureAnalysis && (
        <SummaryAnalysisCell>
          <SHTypography
            variant="subtitle1"
            sx={{
              fontWeight: 700,
              lineHeight: "150%",
              textAlign: "center",
            }}
          >
            {overallScore?.totalSelectedFeature}/{overallScore?.totalFeature}
          </SHTypography>
        </SummaryAnalysisCell>
      )}

      {overallScore?.analysisData?.map((product, index) => (
        <SummaryAnalysisCell key={index}>
          {isShowAnalysisScore && (
            <SHTypography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                lineHeight: "150%",
                textAlign: "center",
              }}
            >
              {product?.totalScore} %
            </SHTypography>
          )}
        </SummaryAnalysisCell>
      ))}
    </SHTableRow>
  );
};
