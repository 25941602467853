import { SHBox, SHStack, SHStackProps } from "@components/design-systems";
import { FieldTitle } from "@layouts/platform-analysis/transfer-select-feature-list/components/field-title";
import { BusinessMetricDTO } from "@models/platform-analysis/entities/steps/businessMetric";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { BusinessMetric } from "../business-metric";

export interface BusinessMetricCategoriesProps extends SHStackProps {
  label: React.ReactNode;
  businessMetricData?: BusinessMetricDTO[];
  onChangeBusinessMetric?: (value: BusinessMetricDTO[] | undefined) => void;
  setIsDirtyProps: (value: boolean) => void;
}

export const BusinessMetricCategories = ({
  label,
  businessMetricData,
  onChangeBusinessMetric,
  setIsDirtyProps,
  ...others
}: BusinessMetricCategoriesProps) => {
  const { palette } = useTheme();
  const [businessMetrics, setBusinessMetrics] = useState<
    BusinessMetricDTO[] | undefined
  >([]);
  const selectedAll = businessMetrics?.every(
    (businessMetric) => businessMetric.isSelected,
  );

  const handleOnClickSelectAll = () => {
    const newGroups = businessMetrics?.map((businessMetric) => {
      return {
        ...businessMetric,
        isSelected: selectedAll ? false : true,
      };
    });
    setBusinessMetrics(newGroups);
    setIsDirtyProps(true);
  };

  const handleOnSelect = (id?: string) => {
    if (!id) return;
    const newGroups = businessMetrics?.map((businessMetric) => {
      return {
        ...businessMetric,
        isSelected:
          businessMetric.id === id
            ? !businessMetric.isSelected
            : businessMetric.isSelected,
      };
    });
    setBusinessMetrics(newGroups);
    setIsDirtyProps(true);
  };

  useEffect(() => {
    if (onChangeBusinessMetric) onChangeBusinessMetric(businessMetrics);

    //eslint-disable-next-line
  }, [businessMetrics]);

  useEffect(() => {
    setBusinessMetrics(businessMetricData);
  }, [businessMetricData]);

  return (
    <SHBox sx={{ marginTop: "20px" }}>
      <SHStack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ marginBottom: "20px" }}
      >
        {label}
        <FieldTitle
          actionText={`${!selectedAll ? "Select all" : "Deselect all"}`}
          handleOnClick={handleOnClickSelectAll}
          sx={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />
      </SHStack>
      <SHBox
        sx={{
          border: `1px solid ${palette.secondary[100]}`,
          padding: "1.2% 1.2% 0 0",
          marginBottom: "20px",
        }}
        {...others}
      >
        <SHStack direction={"row"} flexWrap={"wrap"}>
          {businessMetrics?.map((businessMetric) => (
            <BusinessMetric
              key={businessMetric.id}
              businessMetricData={businessMetric}
              handleSelect={handleOnSelect}
            />
          ))}
        </SHStack>
      </SHBox>
    </SHBox>
  );
};
