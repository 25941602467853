import { SHBox } from "@components/design-systems";
import { ArrowDownSVG } from "@components/svgs";
import { IconButton, useTheme } from "@mui/material";

export interface BackButtonProps {
  width?: string;
  height?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  [key: string]: any;
}
export const BackButton = ({
  width = "20px",
  height = "20px",
  ...others
}: BackButtonProps) => {
  const { palette } = useTheme();

  return (
    <IconButton
      {...others}
      disableRipple
      sx={{
        width,
        height,
        p: 0,
        backgroundColor: "transparent",
        "& svg>path": {
          stroke: palette.text.primary,
        },
        "&:hover": {
          "& svg>path": {
            stroke: palette.primary.main,
          },
        },
        "&:active": {
          "& svg>path": {
            stroke: palette.primary.dark,
          },
        },
        "&:disabled": {
          "& svg>path": {
            stroke: palette.text.disabled,
          },
        },
      }}
    >
      <SHBox mb={-0.5}>
        <ArrowDownSVG width={width} height={height} transform="rotate(90)" />
      </SHBox>
    </IconButton>
  );
};
