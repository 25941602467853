import { SHContainer, SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { SHTextFieldSkeleton } from "@components/skeletons/design-systems";
import { SHRichTextSkeleton } from "@components/skeletons/design-systems/SHRichTextSkeleton";
import {
  SHFieldGroupSkeleton,
  SHFiledGroupTitleSkeleton,
  SHTopBarSkeleton,
} from "@components/skeletons/layouts";
import { TableRateCardCellSkeleton } from "@components/skeletons/layouts/rate-card/TableRateCardCellSkeleton";
import { useTheme } from "@mui/material";

export const RateCardSkeleton = () => {
  const { palette } = useTheme();
  return (
    <SHContainer maxWidth="xl" sx={{ px: { xs: "16px", lg: "200px" } }}>
      <SHTopBarSkeleton totalButtons={3} />
      <SHStack spacing={"48px"} paddingBottom="25px">
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton fullWidth subTitleRows={2} />}
          sx={{ width: "50%" }}
        />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={2} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
              <SHTextFieldSkeleton />
              <SHRichTextSkeleton hasLabel />
            </SHStack>
          }
        />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={3} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={1}>
              <SHStack spacing={1}>
                <SHSkeleton height={18} width="100%" />
                <SHSkeleton height={18} width="100%" />
              </SHStack>
              <SHSkeleton
                height={30}
                width="30%"
                style={{ borderRadius: "25px" }}
              />
            </SHStack>
          }
        />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={3} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={1}>
              <SHStack spacing={1}>
                <SHSkeleton height={18} width="100%" />
                <SHSkeleton height={18} width="100%" />
              </SHStack>
              <SHSkeleton
                height={30}
                width="25%"
                style={{ borderRadius: "25px" }}
              />
            </SHStack>
          }
        />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={2} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
              <SHTextFieldSkeleton />
            </SHStack>
          }
        />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={5} />}
          rightSide={
            <SHStack spacing={1.5}>
              <SHStack
                width={"100%"}
                direction={"row"}
                alignItems={"center"}
                sx={{ overflow: "auto" }}
              >
                {Array.from(Array(2).keys()).map((index) => (
                  <TableRateCardCellSkeleton key={index} isTableHead />
                ))}
                <TableRateCardCellSkeleton isTableHead isLastColumn />
              </SHStack>
              <SHStack
                direction={"column"}
                alignItems={"center"}
                sx={{
                  overflow: "auto",
                  borderBottom: `1px solid ${palette.secondary[100]}`,
                }}
              >
                {Array.from(Array(4).keys()).map((index) => (
                  <SHStack
                    key={index}
                    width={"100%"}
                    height={55}
                    direction={"row"}
                  >
                    {Array.from(Array(2).keys()).map((index) => (
                      <TableRateCardCellSkeleton key={index} />
                    ))}
                    <TableRateCardCellSkeleton isLastColumn />
                  </SHStack>
                ))}
              </SHStack>
            </SHStack>
          }
        />
      </SHStack>
    </SHContainer>
  );
};
