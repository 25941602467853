import { Components, Theme } from "@mui/material";
import { hexToRGBA } from "@utils";
export const muiAlertTheme = ({
  palette,
  shadows,
}: Theme): Pick<Components<Omit<Theme, "components">>, "MuiAlert"> => ({
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: "25px !important",
        padding: "6px 16px !important",
        boxShadow: `${shadows[1]} !important`,
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "13px",
        lineHeight: 1,
        alignItems: "center",
      },
      message: {
        width: "100%",
        padding: "8px 0px 6px 0px !important",
      },
      standardSuccess: {
        border: `1px solid ${palette.success[100]} !important`,
        background: palette.success[50],
        color: palette.success.main,
      },
      standardError: {
        border: `1px solid ${hexToRGBA(palette.common.white, 0.45)} !important`,
        background: palette.error[300],
        color: palette.common.white,
      },
      standardWarning: {
        border: `1px solid ${hexToRGBA(palette.common.white, 0.45)} !important`,
        background: palette.warning[300],
        color: palette.common.white,
      },
      action: {
        padding: 0,
        paddingLeft: 2,
        "&>.MuiButtonBase-root>.MuiSvgIcon-root": {
          width: "16px",
          height: "16px",
        },
      },
      icon: {
        padding: 0,
      },
    },
    defaultProps: {
      icon: false,
    },
  },
});
