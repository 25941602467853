export enum ReviewStatus {
  Completed = "Completed",
  InProgress = "In progress",
  Archived = "Archived",
  Restore = "Restore",
}

export enum ReviewStatusAction {
  Restore = "Restore",
  Archive = "Archive",
}

// Client's Enums
export enum ClientStatus {
  Active = "Active",
  Archived = "Archived",
  Restore = "Restore",
}
