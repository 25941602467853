import { SHButton } from "@components/design-systems";
import { LocalStorage, PageRoutes } from "@constants";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { useNotification } from "@hooks/useNotification";
import { duplicateAnalysis } from "@services/platform-analysis/platformAnalysisService";
import { useState } from "react";
import { generatePath } from "react-router";
import { UnlockDialog } from "../../dialogs/unlock";

export interface UnlockAnalysisButtonProps {
  analysisId?: string;
  disabled?: boolean;
}

export const UnlockAnalysisButton = ({
  analysisId,
  disabled,
}: UnlockAnalysisButtonProps) => {
  const { notify } = useNotification();
  const { setLocalStorageItem } = useLocalStorage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const handleOnUnlock = async () => {
    if (!analysisId) return;
    setIsSubmitting(true);
    const { isSuccess, data, message } = await duplicateAnalysis(analysisId);
    setIsSubmitting(false);

    if (isSuccess && data) {
      notify(message, {
        variant: "success",
        close: true,
      });
      setShowDialog(false);
      setLocalStorageItem(
        LocalStorage.PlatformAnalysisReachAnalysisSetupPage,
        "FIRST_TIME",
      );
      window.location.assign(
        generatePath(PageRoutes.platformAnalysisDetail, {
          analysisId: data,
        }),
      );
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <>
      <SHButton
        variant="outlined"
        size="extraMedium"
        isLoading={isSubmitting}
        disabled={isSubmitting || disabled}
        onClick={() => setShowDialog(true)}
      >
        Unlock
      </SHButton>
      {showDialog && (
        <UnlockDialog
          isSubmitting={isSubmitting}
          onSubmit={handleOnUnlock}
          onClose={() => setShowDialog(false)}
        />
      )}
    </>
  );
};
