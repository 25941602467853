import {
  SHStack,
  SHTypography,
  SHTableCell,
  SHTableRow,
} from "@components/design-systems";

export interface SHDataGridEmptyProps {
  colSpan: number;
  emptyMessage?: React.ReactNode;
}
export const SHDataGridEmpty = ({
  colSpan,
  emptyMessage,
}: SHDataGridEmptyProps) => {
  return (
    <SHTableRow
      data-testid="sh-data-grid-empty"
      style={{
        borderWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "transparent",
      }}
    >
      <SHTableCell colSpan={colSpan} style={{ backgroundColor: "transparent" }}>
        <SHStack
          width={"100%"}
          spacing={2}
          alignItems="center"
          justifyContent={"center"}
        >
          <SHTypography variant="body4">{emptyMessage}</SHTypography>
        </SHStack>
      </SHTableCell>
    </SHTableRow>
  );
};
