import { AdviserFirmStatus, AdviserSubscriptionStatus } from "../enums/status";

export class AdviserFirmDTO {
  id: string = "";
  name: string = "";
  logo: string = "";
  logoS3Key?: string = "";
  branchName?: string = "";
  afsl: string = "";
  abn?: string = "";
  invalidABN: boolean = false;
  contactName: string = "";
  contactEmail: string = "";
  contactPhoneNumber: string = "";
  stripeCustomerId: string = "";
  status: AdviserFirmStatus = AdviserFirmStatus.Active;
  subscriptionStatus: AdviserSubscriptionStatus =
    AdviserSubscriptionStatus.Trial;
  licenseeId?: string = "";
  licenseeName?: string = "";
  isLinkedToLicensee?: boolean = false;
}
export class ReadAdviserFirmDTO extends AdviserFirmDTO {
  totalUsedLicences: number = 0;
  totalLicences: number = 0;
  signUpDate: Date = new Date();
  nextBillingDate?: Date = new Date();
}

export class CreateAdviserFirmDTO extends AdviserFirmDTO {}
export class UpdateAdviserFirmDTO extends AdviserFirmDTO {}

export class MatchingLicensee {
  id?: string = "";
  name?: string = "";
}
