import { SHButton, SHDialog, SHStack } from "@components/design-systems";

interface ResetPasswordDialogProps {
  onSelectAll?: () => void;
  onCancel?: () => void;
  isSelecting?: boolean;
  totalSelectedFeature?: number;
}

export const ConfirmSelectAllDialog = ({
  onSelectAll,
  onCancel,
  isSelecting = false,
  totalSelectedFeature = 3,
}: ResetPasswordDialogProps) => {
  return (
    <SHDialog
      open
      maxWidth={false}
      width={656}
      disableEscapeKeyDown
      disableBackdropClick
      header={"Select all features?"}
      body={`You already selected ${totalSelectedFeature} features in this group. Are you sure you want to select all features?`}
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onCancel}
          >
            No, don’t do anything
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onSelectAll}
            disabled={isSelecting}
            isLoading={isSelecting}
          >
            Yes, select all
          </SHButton>
        </SHStack>
      }
    />
  );
};
