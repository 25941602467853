import { AnalysisStep } from "@models/platform-analysis/enums/step";

const { Setup, Feature, BusinessMetric, Fee, Summary } = AnalysisStep;

export const analysisLabel: {
  [key in AnalysisStep]: string;
} = {
  [Setup]: "Analysis setup",
  [Feature]: "Feature analysis",
  [BusinessMetric]: "Business metric analysis",
  [Fee]: "Fee analysis",
  [Summary]: "Summary",
};
