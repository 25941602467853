import {
  SHButton,
  SHContainer,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHDataGrid } from "@components/design-systems/sh-data-grid";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { APIExtRoutes, PageRoutes } from "@constants/routes";
import { useNotification } from "@hooks/useNotification";
import { TopBar } from "@layouts/top-bar";
import { PageMode, ParentState } from "@models/core";
import {
  ApplicationUser,
  userTypeMapping,
} from "@models/users/entities/appUser";
import { Avatar, useTheme } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { constructUserName, nameOfFactory } from "@utils";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import {
  AdminUserTypeFilterOptions,
  DefaultStatusFilterValue,
  StatusFilterOptions,
} from "./constant";

const nameOf = nameOfFactory<ApplicationUser>();
const columnHelper = createColumnHelper<ApplicationUser>();
const pickColumns: (keyof ApplicationUser)[] = [
  "auth0Id",
  "avatarUrl",
  "userType",
  "status",
  "name",
  "lastName",
  "firstName",
];
export const UsersPage = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { palette } = useTheme();
  const { notify } = useNotification();
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: nameOf("name"),
        header: "Full name",
        enableColumnFilter: false,
        cell: (props) => (
          <SHStack
            spacing={2}
            direction="row"
            alignItems={"center"}
            justifyContent={"start"}
          >
            <Avatar alt="Avatar" src={props.cell.getValue().avatarUrl ?? ""} />
            <SHTypography variant="subtitle2">
              {constructUserName(
                props.cell.getValue().name,
                props.cell.getValue().firstName,
                props.cell.getValue().lastName,
              )}
            </SHTypography>
          </SHStack>
        ),
      }),
      columnHelper.accessor("userType", {
        header: "Account type",
        cell: (props) => userTypeMapping[props.cell.getValue()] ?? "",
        meta: {
          filterData: AdminUserTypeFilterOptions,
          sx: {
            width: { xs: "200px", xl: "320px" },
          },
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => <StatusBadge status={props.cell.getValue()} />,
        meta: {
          filterData: StatusFilterOptions,
          sx: {
            width: { xs: "180px", xl: "260px" },
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "actionCol",
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (props) => (
          <SHStack spacing={2} direction="row">
            <SHButton
              startIcon={<EditSVG color={palette.common.white} />}
              variant="contained"
              onClick={() =>
                navigate(
                  `${PageRoutes.users}/${props.cell.getValue().auth0Id}`,
                  { state: { pageMode: PageMode.Edit } as ParentState },
                )
              }
            >
              Edit
            </SHButton>
            <SHButton
              variant="outlined"
              onClick={() =>
                navigate(`${PageRoutes.users}/${props.cell.getValue().auth0Id}`)
              }
            >
              View
            </SHButton>
          </SHStack>
        ),
        meta: {
          sx: {
            width: "190px",
          },
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffectOnce(() => {
    const parentState = location?.state as ParentState;
    if (!isEmpty(parentState?.notification)) {
      notify("Request Timeout. Please try again.", {
        variant: "error",
        close: true,
      });
    }
  });

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack spacing={2} sx={{ paddingY: 3 }}>
        <TopBar
          title={"Administrators"}
          tools={
            <>
              <SHButton
                variant="contained"
                size="extraMedium"
                onClick={() => navigate(PageRoutes.newUser)}
              >
                Create administrator
              </SHButton>
            </>
          }
        />
        <SHDataGrid
          state={{
            ...DefaultDataState,
            columnFilters: [
              {
                id: nameOf("status"),
                value: DefaultStatusFilterValue,
              },
            ],
            sorting: [{ id: nameOf("name"), desc: false }],
          }}
          columns={columns as any}
          odata={{
            url: APIExtRoutes.getAdminUsers,
            pickColumns,
            globalFilterColumns: ["name", "lastName", "firstName"],
          }}
          emptyMessage={
            "No administrators. Click on “Create administrator” to add!"
          }
          searchBoxProps={{
            placeholder: "Search team",
          }}
        />
      </SHStack>
    </SHContainer>
  );
};
