import {
  SHButton,
  SHInputAdornment,
  SHNumericField,
  SHStack,
} from "@components/design-systems";
import { CrossSVG, Dollar2SVG } from "@components/svgs";
import { Tooltip, useTheme } from "@mui/material";
import {
  MaxPortfolioSize,
  MinPortfolioSize,
} from "@pages/managed-accounts/_id/steps/portfolio-setup/config";
import { DefaultInvestmentSize } from "@pages/managed-accounts/sma-list/config";
import { KeyboardEvent, useState } from "react";

export interface SMAListInvestmentInputProps {
  onUpdate: (investmentSize: number) => void;
}

export const SMAListInvestmentInput = ({
  onUpdate,
}: SMAListInvestmentInputProps) => {
  const { palette } = useTheme();
  const [defaultDisplayValue, setDefaultDisplayValue] = useState<
    number | null
  >(null);
  const [isValueChange, setIsValueChange] = useState(false);
  const [investmentSize, setInvestmentSize] = useState(DefaultInvestmentSize);

  const onHandleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && isValueChange) {
      onUpdate(investmentSize);
    }
  };

  const handleClearInvestmentSize = () => {
    setIsValueChange(false);
    setInvestmentSize(DefaultInvestmentSize);
    setDefaultDisplayValue(null);
    onUpdate(DefaultInvestmentSize)
  }

  return (
    <SHStack direction="row" alignItems="center" spacing={1}>
      <SHNumericField
        min={MinPortfolioSize}
        max={MaxPortfolioSize}
        numericFormatProps={{
          thousandSeparator: true,
          decimalScale: 2,
          allowNegative: false,
        }}
        autoComplete="off"
        placeholder={`Investment: Default $${Intl.NumberFormat().format(
          DefaultInvestmentSize,
        )}`}
        value={defaultDisplayValue}
        onChange={(e, value) => {
          setIsValueChange(!!value);
          setInvestmentSize(value ?? DefaultInvestmentSize);
          setDefaultDisplayValue(value);
        }}
        onKeyDown={onHandleKeyDown}
        InputProps={{
          startAdornment: (
            <SHInputAdornment position="start">
              <Dollar2SVG />
            </SHInputAdornment>
          ),
          endAdornment: (
            <SHInputAdornment position="start">
              {defaultDisplayValue && (
                <Tooltip
                  title="Clear"
                  enterDelay={700}
                  enterNextDelay={700}
                >
                  <SHStack
                    onClick={handleClearInvestmentSize}
                    sx={{
                      width: 19,
                      height: 19,
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      borderRadius: "20px",
                      "&:hover": {
                        backgroundColor: palette.grey[200],
                      },
                      "&:active": {
                        backgroundColor: palette.grey[300],
                      },
                    }}
                  >
                    <SHStack height={15}>
                      <CrossSVG cursor="pointer" />
                    </SHStack>
                  </SHStack>
                </Tooltip>
              )}
            </SHInputAdornment>
          ),
        }}
        sx={{
          width: { xs: "100%", md: 250 },
          "& .MuiInput-root": {
            "&::before,&::after": {
              left: "30px",
            },
          },
        }}
      />
      <SHButton
        onClick={() => onUpdate(investmentSize)}
        variant="contained"
        disabled={!isValueChange}
        disableRipple
      >
        Update
      </SHButton>
    </SHStack>
  );
};
