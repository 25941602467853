import { SVGProps } from "react";
export const CrossSquareSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22px"
    height="22px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={22} height={22} rx={3} fill="#fff" />
    <rect width={22} height={22} rx={3} fill="#EB5757" fillOpacity={0.1} />
    <g>
      <path
        d="M20.576 1.424A1.875 1.875 0 0 0 19.25.875H2.75A1.875 1.875 0 0 0 .875 2.75v16.5a1.875 1.875 0 0 0 1.875 1.875h16.5a1.875 1.875 0 0 0 1.875-1.875V2.75c0-.497-.198-.974-.55-1.326ZM1.159 1.16A2.25 2.25 0 0 1 2.75.5h16.5a2.25 2.25 0 0 1 2.25 2.25v16.5a2.25 2.25 0 0 1-2.25 2.25H2.75A2.25 2.25 0 0 1 .5 19.25V2.75c0-.597.237-1.169.659-1.591Z"
        stroke="#F56C5E"
        strokeOpacity={0.15}
      />
      <path
        d="m7 7 4 4m4 4-4-4m0 0 4-4-8 8"
        stroke="#FC5746"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width={22} height={22} rx={2.75} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
