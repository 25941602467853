import { Components, Theme } from "@mui/material";
export const muiAutocompleteTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiAutocomplete"> => ({
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        "& .MuiInput-root .MuiInput-input": {
          padding: "8px 10px 5px 10px",
        },
      },
      endAdornment: {
        paddingTop: "4px",
        paddingRight: "10px",
        "&>.MuiIconButton-root": {
          padding: "4px",
        },
        "&>.MuiAutocomplete-clearIndicator": {
          marginRight: "4px",
        },
      },
      paper: {
        borderRadius: 0,
        boxShadow: theme.shadows[1],
        border: `solid 1px ${theme.palette.divider}`,
      },
      listbox: {
        padding: 0,
        maxHeight: "30vh",
      },
      option: {
        fontSize: "13px",
        fontWeight: "500",
      },
      noOptions: {
        textAlign: "center",
        fontSize: "13px",
        color: theme.palette.text.disabled,
        fontWeight: "400",
        padding: "10px",
      },
      loading: {
        textAlign: "center",
        fontSize: "13px",
        color: theme.palette.text.disabled,
        fontWeight: "400",
        padding: "10px",
      },
    },
  },
});
