import {
  SHBooleanField,
  SHButton,
  SHCheckbox,
  SHPopper,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHFormControlLabel } from "@components/design-systems/sh-form-control-label";
import { EditSVG } from "@components/svgs";
import { FormGroup, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { useEffect, useRef, useState } from "react";
import {
  MainHeaderColumn,
  MainHeaderColumnNameMapping,
} from "@models/managed-accounts/enums/mainHeader";
import { useEffectOnce } from "react-use";
import { CloseButton } from "@components/buttons/close";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";

export type SMAListSetting = {
  feesDisplayStyle: FeesDisplayStyle;
  visibilityColumns: MainHeaderColumn[] | undefined;
};

export interface SMAListSettingsButtonProps {
  width?: string;
  zIndex?: number;
  onSettings: (value: SMAListSetting) => void;
  [key: string]: any;
}

export const SMAListSettingsButton = ({
  width,
  zIndex = 3,
  onSettings,
  ...others
}: SMAListSettingsButtonProps) => {
  const { palette } = useTheme();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [visibilityColumns, setVisibilityColumns] = useState<
    MainHeaderColumn[] | undefined
  >([]);
  const [feesDisplayStyle, setFeesDisplayStyle] = useState<FeesDisplayStyle>(
    FeesDisplayStyle.Percentage,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, buttonRef, open]);

  useEffectOnce(() => {
    setVisibilityColumns(
      Object.keys(MainHeaderColumn).map((k) => k as MainHeaderColumn),
    );
  });

  return (
    <>
      <SHButton
        ref={buttonRef}
        onClick={handleClick}
        color="primary"
        variant="text"
        size="extraMedium"
        startIcon={<EditSVG color={palette.primary.main} />}
        sx={{ width: "120px" }}
        {...others}
      >
        Settings
      </SHButton>

      <SHPopper
        className="SMAListSetting"
        anchorEl={anchorEl}
        open={open}
        placement="left-start"
        popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
        sx={{
          width: width,
          zIndex: zIndex,
          marginTop: "38.5px !important",
          marginRight: "-95px !important",
        }}
      >
        <SHStack
          ref={wrapperRef}
          sx={{
            boxSizing: "border-box",
            boxShadow: "none",
            borderWidth: 1,
            borderColor: palette.divider,
            borderStyle: "solid",
            borderRadius: 0,
            backgroundColor: hexToRGBA(palette.common.white, 0.95),
            paddingX: 2,
            paddingY: 1,
            minWidth: "170px",
            filter: "drop-shadow(1px 1px 2px rgba(65, 73, 89, 0.25))",
          }}
          spacing={1}
        >
          <SHStack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <SHTypography variant="body1">Settings</SHTypography>
            <CloseButton
              width="12px"
              height="12px"
              onClick={() => setOpen(false)}
            />
          </SHStack>
          <SHBooleanField
            isSwitch
            isShowClearButton={false}
            value={feesDisplayStyle === FeesDisplayStyle.Dollar}
            yesLabel="$ Fees"
            noLabel="% Fees"
            width="80px"
            onChange={async (event, value?: boolean | null) => {
              const newFeesDisplayStyle = value
                ? FeesDisplayStyle.Dollar
                : FeesDisplayStyle.Percentage;

              onSettings({
                feesDisplayStyle: newFeesDisplayStyle,
                visibilityColumns: visibilityColumns,
              });

              setFeesDisplayStyle(newFeesDisplayStyle);
            }}
          />
          <SHStack>
            <SHTypography variant="body1">Columns</SHTypography>
            <FormGroup sx={{ padding: "2px" }}>
              {Object.keys(MainHeaderColumn).map(
                (filterItem: string, index: number) => (
                  <SHFormControlLabel
                    key={index}
                    data-testid={"filter-checkbox-" + index}
                    size="small"
                    control={
                      <SHCheckbox
                        checked={
                          !visibilityColumns ||
                          visibilityColumns.some((item) => item === filterItem)
                        }
                        size={"small"}
                        data-testid="sh-head-filter-option"
                        onClick={() => {
                          let newList = visibilityColumns
                            ? [...visibilityColumns]
                            : [];

                          if (
                            visibilityColumns &&
                            visibilityColumns.some(
                              (item) => item === filterItem,
                            )
                          ) {
                            newList = newList.filter(
                              (item) => item !== filterItem,
                            );
                          } else {
                            newList.push(filterItem as MainHeaderColumn);
                          }

                          onSettings({
                            feesDisplayStyle: feesDisplayStyle,
                            visibilityColumns: newList,
                          });

                          setVisibilityColumns(newList);
                        }}
                      />
                    }
                    label={MainHeaderColumnNameMapping[filterItem]}
                  />
                ),
              )}
            </FormGroup>
          </SHStack>
        </SHStack>
      </SHPopper>
    </>
  );
};
