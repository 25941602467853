import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const ChangeHistoryTabSkeleton = () => {
  return (
    <SHStack spacing={2} marginY={2}>
      <SHSkeleton height={80} width={"100%"} />
      <SHSkeleton height={80} width={"100%"} />
      <SHSkeleton height={80} width={"100%"} />
    </SHStack>
  );
};
