import { View } from "@react-pdf/renderer";

interface PDFTableBodyProps {
  children: React.ReactNode;
}
export const PDFTableBody = ({ children }: PDFTableBodyProps) => {
  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderColor: "#7C8493",
      }}
    >
      {children}
    </View>
  );
};
