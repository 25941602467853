import {
  SHButton,
  SHCheckbox,
  SHContainer,
  SHDivider,
  SHRichTextEditor,
  SHSelect,
  SHStack,
  SHTextField,
} from "@components/design-systems";
import { SHFormControlLabel } from "@components/design-systems/sh-form-control-label";
import UnsavedDialog from "@components/dialogs/unsaved";
import { PageRoutes } from "@constants/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useConfiguration } from "@hooks/useConfiguration";
import { useNotification } from "@hooks/useNotification";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar } from "@layouts/top-bar";
import {
  ConfigurationFeatureCreateDTO,
  ConfigurationFeatureUpdateDTO,
  ConfigurationFeature_Ext,
  FeatureListOfValueDTO,
  FieldTypeId,
} from "@models/configuration";
import { RecordType } from "@models/core";
import {
  featureFeatureValidators,
  generateFeatureFormTitle,
  standardFeatureValidators,
} from "@pages/configuration/feature/util";
import { constructFeaturePath } from "@pages/configuration/util";
import { RootState } from "@redux/store";
import {
  createFeature,
  getFeature,
  getFeatureLov,
  updateFeature,
} from "@services/configuration";
import { useMemo, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface ConfigurationFeaturePageProps {
  isFeatureGroup?: boolean;
}

export const ConfigurationFeaturePage = ({
  isFeatureGroup = false,
}: ConfigurationFeaturePageProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { notify } = useNotification();
  const { updateDraftStatus } = useConfiguration();
  const {
    configurationUI: { isEditMode },
  } = useSelector((state: RootState) => state.configuration);

  const [lov, setLov] = useState<FeatureListOfValueDTO>();
  const [editMode, setEditMode] = useState(false);

  const {
    watch,
    reset,
    control,
    getValues,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...new ConfigurationFeature_Ext() },
    resolver: yupResolver(
      !isFeatureGroup ? standardFeatureValidators : featureFeatureValidators,
    ),
  });
  const { isDirty } = useFormState({ control });

  const groupId = searchParams.get("groupId") ?? undefined;
  const sectionId = searchParams.get("sectionId") ?? undefined;
  const subSectionId = searchParams.get("subSectionId") ?? undefined;
  const featureId = searchParams.get("featureId") ?? undefined;

  const showBusinessMetricType = watch("isBusinessMetric");
  const showPositiveScore = watch("businessMetricType.id");

  const defaultFieldType = isFeatureGroup
    ? { id: FieldTypeId.YesNo, description: "Yes/No" }
    : new RecordType<FieldTypeId>();

  const isNew = useMemo(() => {
    return featureId === "new";
  }, [featureId]);

  const isDisabled = useMemo(() => {
    return isSubmitting || !editMode;
  }, [isSubmitting, editMode]);

  useEffectOnce(() => {
    if (isEditMode || isNew) {
      setEditMode(true);
    }
    loadLov();
    loadFeature();
  });

  // CURD
  const loadLov = async () => {
    if (!groupId) return;
    const { data } = await getFeatureLov(groupId);
    setLov(data);
  };

  const loadFeature = async () => {
    if (!groupId || !sectionId) return;
    if (isNew) {
      reset({
        ...new ConfigurationFeature_Ext(),
        fieldType: defaultFieldType,
        sectionId: sectionId,
        subSectionId: subSectionId,
      });
    } else {
      const { data, message } = await getFeature(
        groupId,
        sectionId,
        subSectionId,
        featureId,
      );

      if (data) {
        reset({
          ...data,
          sectionId: sectionId,
          subSectionId: subSectionId,
        });
      } else {
        navigate(PageRoutes.configuration, {
          state: { notification: message },
        });
      }
    }
  };

  const handleOnCreateFeature = async (
    featureInfo: ConfigurationFeature_Ext,
  ) => {
    if (!groupId || !featureInfo.sectionId) return;

    let newFeature: ConfigurationFeatureCreateDTO =
      new ConfigurationFeatureCreateDTO();

    if (isFeatureGroup) {
      newFeature = {
        name: featureInfo.name,
        description: featureInfo.description,
        fieldTypeId: featureInfo.fieldType?.id,
        historyConfigurationSectionId: featureInfo.subSectionId,
      } as ConfigurationFeatureCreateDTO;
    } else {
      newFeature = {
        name: featureInfo.name,
        description: featureInfo.description,
        fieldTypeId: featureInfo.fieldType?.id,
        businessMetricId: featureInfo.businessMetricType?.id,
        isBusinessMetric: featureInfo.isBusinessMetric,
        isPositiveScore: featureInfo.isPositiveScore,
        historyConfigurationSectionId: featureInfo.sectionId,
      } as ConfigurationFeatureCreateDTO;
    }

    const { data, message } = await createFeature(
      groupId,
      featureInfo.sectionId,
      newFeature,
    );

    if (data) {
      notify(message, {
        variant: "success",
        close: true,
      });
      reset({
        ...data,
        sectionId: featureInfo.sectionId,
        subSectionId: featureInfo.subSectionId,
      });
      updateDraftStatus();
      setEditMode(false);
      if (data.id) {
        navigate(
          constructFeaturePath(
            isFeatureGroup ? "feature" : "standard",
            groupId,
            featureInfo.sectionId,
            featureInfo.subSectionId,
            data.id,
          ),
        );
      }
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnUpdateFeature = async (
    featureInfo: ConfigurationFeature_Ext,
  ) => {
    if (
      !groupId ||
      !featureId ||
      !featureInfo.sectionId ||
      (isFeatureGroup && !featureInfo.subSectionId)
    )
      return;

    let newFeature: ConfigurationFeatureUpdateDTO =
      new ConfigurationFeatureUpdateDTO();

    if (isFeatureGroup) {
      newFeature = {
        name: featureInfo.name,
        description: featureInfo.description,
        fieldTypeId: defaultFieldType.id,
        historyConfigurationSectionId: featureInfo.subSectionId,
        configurationFeatureId: featureId,
      } as ConfigurationFeatureUpdateDTO;
    } else {
      newFeature = {
        name: featureInfo.name,
        description: featureInfo.description,
        fieldTypeId: featureInfo.fieldType?.id,
        businessMetricTypeId: featureInfo?.businessMetricType?.id,
        isBusinessMetric: featureInfo.isBusinessMetric,
        isPositiveScore: featureInfo.isPositiveScore,
        historyConfigurationSectionId: featureInfo.sectionId,
        configurationFeatureId: featureId,
      } as ConfigurationFeatureUpdateDTO;
    }

    const { data, message } = await updateFeature(
      groupId,
      featureInfo.sectionId,
      featureId,
      newFeature,
    );

    if (data) {
      notify(message, {
        variant: "success",
        close: true,
      });
      reset(getValues());
      updateDraftStatus();
      setEditMode(false);
      navigate(
        constructFeaturePath(
          isFeatureGroup ? "feature" : "standard",
          groupId,
          featureInfo.sectionId,
          featureInfo.subSectionId,
          featureId,
        ),
      );
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  // TODO: Move to next Sprint
  const handleOnDiscardChange = async () => {};
  const handleOnArchiveFeature = async () => {};

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <form>
        <SHStack sx={{ paddingY: 3 }}>
          <TopBar
            navigate={{ to: PageRoutes.configuration }}
            title={generateFeatureFormTitle(watch("name"))}
            tools={
              <>
                {isNew && (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                    onClick={handleSubmit(handleOnCreateFeature)}
                  >
                    Save
                  </SHButton>
                )}
                {!editMode && (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    onClick={() => setEditMode(true)}
                  >
                    Edit
                  </SHButton>
                )}
                {!isNew && editMode && (
                  <>
                    <SHButton
                      variant="outlined"
                      size="extraMedium"
                      onClick={() => handleOnArchiveFeature()}
                      disabled={true}
                    >
                      Archive
                    </SHButton>
                    <SHButton
                      variant="outlined"
                      size="extraMedium"
                      onClick={() => handleOnDiscardChange()}
                      disabled={true}
                    >
                      Discard changes
                    </SHButton>
                    <SHButton
                      variant="contained"
                      size="extraMedium"
                      type="submit"
                      disabled={!isValid || isSubmitting || !isDirty}
                      isLoading={isSubmitting}
                      onClick={handleSubmit(handleOnUpdateFeature)}
                    >
                      Update
                    </SHButton>
                  </>
                )}
              </>
            }
          />
          <FieldGroup
            title="Feature information"
            subTitle={"Provide information about this feature"}
          />
          <SHDivider />

          <FieldGroup
            title="Feature name"
            subTitle={
              "The name should be unique and clearly describe the feature"
            }
            required
          >
            <Controller
              render={({ field }) => (
                <SHTextField
                  {...field}
                  hasCounter
                  maxLength={100}
                  sx={{ width: { xs: "100%", md: 520 } }}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : null}
                  placeholder={"Feature name"}
                  disabled={isDisabled}
                />
              )}
              name="name"
              control={control}
              rules={{ required: "Feature name is required" }}
            />
          </FieldGroup>
          <SHDivider />

          <FieldGroup
            title="Description"
            subTitle={
              "Describe this feature so that it can be better understood by users. This will appear as a tool tip"
            }
          >
            <Controller
              render={({ field }) => (
                <SHRichTextEditor
                  {...field}
                  maxLength={3000}
                  placeHolder="Description"
                  height={165}
                  readOnly={isDisabled}
                />
              )}
              name="description"
              control={control}
            />
          </FieldGroup>
          <SHDivider />

          <FieldGroup
            title="Field type"
            subTitle={"What type of field is this?"}
            required
          >
            <Controller
              render={({ field }) => (
                <SHSelect
                  {...field}
                  sx={{ width: { xs: "100%", md: 520 } }}
                  data={lov?.fieldTypes ?? []}
                  placeholder={"Field type"}
                  dataItemKey="id"
                  displayField="description"
                  disabled={isDisabled || isFeatureGroup || !isNew}
                />
              )}
              name="fieldType.id"
              control={control}
              rules={{ required: "Field type is required" }}
            />
          </FieldGroup>
          <SHDivider />
          {!isFeatureGroup ? (
            <>
              <FieldGroup
                title="Business metric"
                subTitle="Is this feature a business metric?"
              >
                <SHStack
                  direction="column"
                  sx={{ width: { xs: "100%", md: 520 } }}
                >
                  <Controller
                    render={({ field: { ref, ...field } }) => (
                      <SHFormControlLabel
                        {...field}
                        sx={{ pointerEvents: "none" }}
                        control={
                          <SHCheckbox
                            checked={getValues("isBusinessMetric")}
                            name="isBusinessMetric"
                            sx={{ pointerEvents: "auto" }}
                          />
                        }
                        label={"Yes, this feature is a business metric"}
                        disabled={isDisabled}
                      />
                    )}
                    name="isBusinessMetric"
                    control={control}
                  />
                </SHStack>
              </FieldGroup>
              {showBusinessMetricType && (
                <>
                  <FieldGroup
                    title="Business metric type"
                    subTitle={"What type of business metric is this?"}
                    sx={{ paddingTop: 0, paddingBottom: 3 }}
                  >
                    <Controller
                      render={({ field }) => (
                        <SHSelect
                          {...field}
                          sx={{ width: { xs: "100%", md: 520 } }}
                          data={lov?.businessMetricTypes ?? []}
                          placeholder={"Business metric type"}
                          dataItemKey="id"
                          displayField="description"
                          disabled={isDisabled}
                        />
                      )}
                      name="businessMetricType.id"
                      control={control}
                    />
                  </FieldGroup>
                  {showPositiveScore && (
                    <FieldGroup
                      title="Is there a positive score?"
                      subTitle="This will count towards the total score in the Business Analysis section"
                      sx={{ paddingTop: 0, paddingBottom: 3 }}
                    >
                      <SHStack
                        direction="column"
                        sx={{ width: { xs: "100%", md: 520 } }}
                      >
                        <Controller
                          render={({ field: { ref, ...field } }) => (
                            <SHFormControlLabel
                              {...field}
                              sx={{ pointerEvents: "none" }}
                              control={
                                <SHCheckbox
                                  checked={getValues("isPositiveScore")}
                                  name="isPositiveScore"
                                  sx={{ pointerEvents: "auto" }}
                                />
                              }
                              label={"Yes, there is a positive score"}
                              disabled={isDisabled}
                            />
                          )}
                          name="isPositiveScore"
                          control={control}
                        />
                      </SHStack>
                    </FieldGroup>
                  )}
                </>
              )}

              <FieldGroup
                title="Section"
                subTitle={"Which section does this feature belong to?"}
                required
                sx={{ paddingTop: 0, paddingBottom: 3 }}
              >
                <Controller
                  render={({ field }) => (
                    <SHSelect
                      {...field}
                      sx={{ width: { xs: "100%", md: 520 } }}
                      data={lov?.sections ?? []}
                      placeholder={"Section"}
                      dataItemKey="id"
                      displayField="name"
                      disabled={isDisabled}
                    />
                  )}
                  name="sectionId"
                  control={control}
                  rules={{ required: "Section is required" }}
                />
              </FieldGroup>
            </>
          ) : (
            <>
              <FieldGroup
                title="Feature group"
                subTitle={
                  "Which feature group and sub-group does this feature belong to?"
                }
                required
                sx={{ paddingTop: 3, paddingBottom: 3 }}
              >
                <SHStack spacing={3} direction="column">
                  <Controller
                    render={({ field }) => (
                      <SHSelect
                        {...field}
                        sx={{ width: { xs: "100%", md: 520 } }}
                        data={lov?.sections ?? []}
                        label={"Feature group"}
                        placeholder={"Feature group"}
                        dataItemKey="id"
                        displayField="name"
                        disabled={isDisabled}
                      />
                    )}
                    name="sectionId"
                    control={control}
                    rules={{ required: "Feature group is required" }}
                  />
                  <Controller
                    render={({ field }) => (
                      <SHSelect
                        {...field}
                        sx={{ width: { xs: "100%", md: 520 } }}
                        data={
                          lov?.sections?.find(
                            (item) => item.id === watch("sectionId"),
                          )?.subSections ?? []
                        }
                        label={"Feature sub-group"}
                        placeholder={"Feature sub-group"}
                        dataItemKey="id"
                        displayField="name"
                        disabled={isDisabled}
                      />
                    )}
                    name="subSectionId"
                    control={control}
                    rules={{ required: "Feature sub-group is required" }}
                  />
                </SHStack>
              </FieldGroup>
            </>
          )}
        </SHStack>
        <UnsavedDialog isDirty={isDirty && !isSubmitting} />
      </form>
    </SHContainer>
  );
};
