import { APIExtRoutes } from "@constants/routes";
import { APIResponse } from "@models/core";
import { SupplierLicenseMassUpdateDTO } from "@models/supplier/entities/licenses";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function updateSupplierLicenses(
  supplierId: string,
  data: SupplierLicenseMassUpdateDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.patch<string>(
    generatePath(APIExtRoutes.supplierLicenses, { supplierId }),
    data,
  );

  return handleErrorProxy(response, "Supplier licence updated successfully!");
}
