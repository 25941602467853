import { AnalysisDTO } from "@models/platform-analysis/entities/analysis";
import { AnalysisStatus } from "@models/platform-analysis/enums/status";
import { PlatformAnalysisStore } from "@redux/slices/platform-analysis";

export const repairUpdateAnalysis = (analysis: AnalysisDTO): AnalysisDTO => {
  return {
    ...analysis,
    setup: null,
    feature: null,
    businessMetric: null,
    fee: null,
    summary: null,
  };
};

export const updateReadOnlyAndComplete = (
  state: PlatformAnalysisStore,
  data: {
    isCompleted?: boolean;
    status?: AnalysisStatus;
  },
) => {
  //Set isCompleted
  let newState = { ...state };
  newState.isCompleted = data.isCompleted;
  //Set isReadOnly
  newState.isReadOnly =
    data.status === AnalysisStatus.Archived || newState.isCompleted;
  //Set hidden Selection steps when isReadOnly
  if (
    newState.ui.steps.Feature.subSteps?.Selection.isHidden !== undefined &&
    newState.isReadOnly
  ) {
    newState.ui.steps.Feature.subSteps.Selection.isHidden = true;
  }
  if (
    newState.ui.steps.BusinessMetric.subSteps?.Selection.isHidden !==
      undefined &&
    newState.isReadOnly
  ) {
    newState.ui.steps.BusinessMetric.subSteps.Selection.isHidden = true;
  }
  if (
    newState.ui.steps.Fee.subSteps?.Selection.isHidden !== undefined &&
    newState.isReadOnly
  ) {
    newState.ui.steps.Fee.subSteps.Selection.isHidden = true;
  }
  if (newState.ui.selectedSubStepIndex === 1 && newState.isReadOnly) {
    newState.ui.selectedSubStepIndex = 0;
  }
  return newState;
};
