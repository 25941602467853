import {
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { Link, useTheme } from "@mui/material";

export interface FieldTitleProps extends SHStackProps {
  title?: string;
  subTitle?: string;
  actionText?: string;
  isDisable?: boolean;
  handleOnClick?: () => void;
}
export const FieldTitle = ({
  title,
  subTitle,
  actionText,
  isDisable = false,
  handleOnClick,
  ...others
}: FieldTitleProps) => {
  const { palette } = useTheme();

  return (
    <SHStack width={"50%"} {...others}>
      <SHStack
        direction={"row"}
        alignItems={"start"}
        justifyContent="space-between"
        spacing={1}
      >
        <SHTypography variant={"subtitle2"}> {title}</SHTypography>
        <Link
          variant="subtitle2"
          component="a"
          underline="none"
          sx={{
            cursor: "pointer",
            color: isDisable ? palette.text.disabled : palette.text.secondary,
          }}
          onClick={handleOnClick}
        >
          {actionText}
        </Link>
      </SHStack>
      <SHTypography variant="body4" colorVariant="third" fontSize={"0.8rem"}>
        {subTitle}
      </SHTypography>
    </SHStack>
  );
};
