import { PlusButton } from "@components/buttons/plus";
import {
  SHButton,
  SHHtmlBlock,
  SHImage,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableCell,
  SHTableCellProps,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { EditSVG } from "@components/svgs";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import {
  AnalysisCommentDTO,
  analysisCommentsKeyMapping,
  PlatformCommentDialog,
} from "@models/platform-analysis/entities/comment";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { useTheme } from "@mui/material";
import { analysisLabel } from "@pages/platform-analysis/_id/config";
import { ChangeRichTextDialog } from "@pages/platform-analysis/_id/steps/summary/components/change-rich-text-dialog";
import { hexToRGBA } from "@utils";
import { cloneDeep, isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { parseCommentProductLogo } from "./util";
const { Feature, BusinessMetric, Fee } = AnalysisStep;

export interface CommentsTableProps {
  comments: AnalysisCommentDTO[];
  onChange: (comment: AnalysisCommentDTO[]) => void;
  hasFeatureStep?: boolean;
  hasBusinessMetricStep?: boolean;
  hasFeeStep?: boolean;
  isViewMode?: boolean;
  disabled?: boolean;
}
export const CommentsTable = ({
  comments,
  onChange,
  hasFeatureStep,
  hasBusinessMetricStep,
  hasFeeStep,
  isViewMode,
  disabled,
}: CommentsTableProps) => {
  const { analysisId } = useParams<{ analysisId: string }>();
  const { isModifyingComment, modifyComment } = usePlatformAnalysis();
  const [dialog, setDialog] = useState<
    | (PlatformCommentDialog & {
        index: number;
        stepId: AnalysisStep;
      })
    | undefined
  >();

  const handleOnAddComment = async (html: string | null) => {
    if (!dialog || !analysisId || !dialog?.productId) return;
    const newComment = await modifyComment(
      analysisId,
      dialog.productId,
      dialog.stepId,
      html,
    );
    if (newComment) {
      const newComments = cloneDeep(comments);
      (newComments[dialog.index] as any)[
        analysisCommentsKeyMapping[dialog.stepId]
      ] = html || "";

      setDialog(undefined);
      onChange(newComments);
    }
  };

  const steps = useMemo(() => {
    let steps: AnalysisStep[] = [];
    if (hasFeatureStep) steps.push(Feature);
    if (hasBusinessMetricStep) steps.push(BusinessMetric);
    if (hasFeeStep) steps.push(Fee);
    return steps;
  }, [hasFeatureStep, hasBusinessMetricStep, hasFeeStep]);

  const firstColWidth = 18;
  const columnsWidth = (100 - firstColWidth) / steps.length;
  return (
    <>
      <SHTableContainer>
        <SHTable>
          <SHTableHead>
            <SHTableRow>
              <HeaderCell width={`${firstColWidth}%`}>Platform</HeaderCell>
              {steps?.map((step) => (
                <HeaderCell key={step} width={`${columnsWidth}%`}>
                  {analysisLabel[step]}
                </HeaderCell>
              ))}
            </SHTableRow>
          </SHTableHead>
          <SHTableBody>
            {comments?.map((row, index) => (
              <SHTableRow key={row.productId} sx={{ borderBottomWidth: 1 }}>
                <SHTableCell
                  sx={{
                    minWidth: "250px",
                  }}
                  width={"18%"}
                >
                  <SHStack
                    direction={"row"}
                    spacing={"10px"}
                    alignItems={"center"}
                  >
                    <SHImage
                      src={parseCommentProductLogo(row?.productAvatar)}
                      width={"33px"}
                      height={"33px"}
                    />
                    <SHTypography variant="body1">
                      {row.productName}
                    </SHTypography>
                  </SHStack>
                </SHTableCell>
                {steps.map((step) => (
                  <CommentCell
                    disabled={disabled}
                    isViewMode={isViewMode}
                    key={step}
                    comment={(row as any)[analysisCommentsKeyMapping[step]]}
                    width={`${columnsWidth}%`}
                    onClick={() => {
                      setDialog({
                        index,
                        comment:
                          (row as any)[analysisCommentsKeyMapping[step]] ??
                          null,
                        stepId: step,
                        productId: row.productId,
                      });
                    }}
                  />
                ))}
              </SHTableRow>
            ))}
          </SHTableBody>
        </SHTable>
      </SHTableContainer>
      {dialog && (
        <ChangeRichTextDialog
          onSubmit={handleOnAddComment}
          title={isEmpty(dialog.comment) ? "Add comment" : "Edit comment"}
          placeHolder={"Enter your comment"}
          initialContent={dialog.comment ?? null}
          isSubmitting={isModifyingComment}
          onClose={() => {
            setDialog(undefined);
          }}
          maxLength={1500}
          isCommentBox
        />
      )}
    </>
  );
};
interface CommentCellProps extends SHTableCellProps {
  comment?: string | null;
  disabled?: boolean;
  isViewMode?: boolean;
  onClick: () => void;
}
const CommentCell = ({
  comment,
  disabled,
  onClick,
  isViewMode,
  ...others
}: CommentCellProps) => {
  const { palette } = useTheme();
  return (
    <SHTableCell
      sx={{
        height: "auto",
        borderLeft: `solid 1px ${hexToRGBA(palette.primary[50], 0.3)}`,
        minWidth: "250px",
      }}
      {...others}
    >
      {isEmpty(comment) ? (
        <SHStack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "100%" }}
        >
          {isViewMode ? (
            <SHTypography variant="body1" lineHeight={"120%"}>
              No comment
            </SHTypography>
          ) : (
            <PlusButton disabled={disabled} size="medium" onClick={onClick}>
              Comment
            </PlusButton>
          )}
        </SHStack>
      ) : (
        <SHStack
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          spacing={"25px"}
          sx={{ height: "100%" }}
        >
          <SHHtmlBlock
            content={comment}
            variant="body4"
            color={palette.text.third}
          />
          {!isViewMode && (
            <SHButton
              startIcon={<EditSVG color={palette.text.primary} />}
              variant="outlined"
              disabled={disabled}
              sx={{
                "&:active": {
                  "& svg>path": {
                    fill: palette.common.white,
                  },
                },
                "&:disabled": {
                  "& svg>path": {
                    fill: palette.text.disabled,
                  },
                },
              }}
              onClick={onClick}
            >
              Edit
            </SHButton>
          )}
        </SHStack>
      )}
    </SHTableCell>
  );
};

interface HeaderCellProps extends SHTableCellProps {
  children: React.ReactNode;
}
const HeaderCell = ({ children, ...others }: HeaderCellProps) => {
  const { palette } = useTheme();
  return (
    <SHTableCell
      sx={{
        height: "50px",
        borderLeft: `solid 1px ${palette.divider}`,
        minWidth: "250px",
      }}
      {...others}
    >
      <SHTypography
        variant="subtitle1"
        fontWeight={700}
        textAlign="center"
        lineHeight={"120%"}
      >
        {children}
      </SHTypography>
    </SHTableCell>
  );
};
