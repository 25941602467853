import { SHStack, SHTypography } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { ReactNode } from "react";

export interface FamilyCardTotalProps {
  title: ReactNode;
  content: ReactNode;
}
export const FamilyCardTotal = ({ title, content }: FamilyCardTotalProps) => {
  const { palette } = useTheme();
  return (
    <SHStack
      direction={"row"}
      justifyContent="space-between"
      alignItems={"center"}
      sx={{
        p: "15px",
        border: `1px solid ${palette.secondary[100]}`,
        backgroundColor: hexToRGBA(palette.secondary[100], 0.25),
      }}
    >
      <SHTypography variant="subtitle1">{title}</SHTypography>
      <SHTypography variant="subtitle1" fontSize={"20px"}>
        {content}
      </SHTypography>
    </SHStack>
  );
};
