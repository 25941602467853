import {
  SHBox,
  SHImage,
  SHStack,
  SHTableCell,
  SHTableRow,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { ArrowDownSVG } from "@components/svgs";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import {
  AnalysisDataDTO,
  AnalysisSectionDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { Collapse, useTheme } from "@mui/material";
import { FeatureAnalysisSubGroupTableRow } from "@pages/platform-analysis/_id/steps/feature/analysis/components/sub-group-row";
import {
  FeatureAnalysisTableCell,
  FeatureAnalysisTableCellProps,
} from "@pages/platform-analysis/_id/steps/feature/analysis/components/table-cell";
import { useState } from "react";

interface FeatureAnalysisGroupTableRowProps {
  groupData?: AnalysisSectionDTO;
}

export const FeatureAnalysisGroupTableRow = ({
  groupData,
  ...others
}: FeatureAnalysisGroupTableRowProps &
  FeatureAnalysisTableCellProps &
  SHTableRowProps) => {
  const { palette } = useTheme();
  const [expandSubGroups, setExpandSubGroups] = useState(true);
  const { setTotalCollapsedFeatureAnalysisRows, isShowAnalysisScores } =
    usePlatformAnalysis();

  const totalSelectedFeature = groupData?.totalSelectedFeature ?? 0;
  const totalSubgroup = groupData?.subSections?.length ?? 0;

  return (
    <>
      <SHTableRow
        sx={{
          height: 54,
          background: `${palette.common.white} !important`,
        }}
        {...others}
      >
        <FeatureAnalysisTableCell isFirstColumn>
          <SHStack
            direction={"row"}
            alignItems={"center"}
            justifyContent="space-between"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setExpandSubGroups(!expandSubGroups);
              const totalCollapsedRows = totalSelectedFeature + totalSubgroup;
              setTotalCollapsedFeatureAnalysisRows(
                expandSubGroups ? totalCollapsedRows : -totalCollapsedRows,
              );
            }}
          >
            <SHStack direction={"row"} alignItems={"center"} gap={"5px"}>
              {groupData?.iconUrl && (
                <SHImage src={groupData?.iconUrl} width={22} height={22} />
              )}
              <SHTypography variant="subtitle2" paddingTop={0} fontSize={13}>
                {groupData?.name}
              </SHTypography>
            </SHStack>
            <SHBox
              alignItems={"center"}
              sx={{
                display: "flex",
                transition: "all ease-in-out 0.2s",
                transform: `rotate(${expandSubGroups ? 0 : 180}deg)`,
              }}
            >
              <ArrowDownSVG width={16} height={16} />
            </SHBox>
          </SHStack>
        </FeatureAnalysisTableCell>
        {groupData?.analysisData?.map((analysisData: AnalysisDataDTO) => (
          <FeatureAnalysisTableCell key={analysisData?.productId}>
            <SHStack
              direction={"row"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              {isShowAnalysisScores && (
                <>
                  <SHTypography
                    variant="subtitle2"
                    lineHeight="120%"
                    fontSize={13}
                  >
                    Group score
                  </SHTypography>
                  <SHTypography
                    variant="subtitle2"
                    lineHeight="120%"
                    fontSize={13}
                  >
                    {` ${analysisData?.totalScore}%`}
                  </SHTypography>
                </>
              )}
            </SHStack>
          </FeatureAnalysisTableCell>
        ))}
      </SHTableRow>
      <SHTableRow>
        <SHTableCell
          colSpan={groupData?.analysisData?.length! + 1}
          sx={{
            padding: 0,
            left: 0,
            borderLeft: "none !important",
          }}
        >
          <Collapse in={expandSubGroups} timeout="auto">
            {groupData?.subSections?.map((subGroup: AnalysisSectionDTO) => (
              <FeatureAnalysisSubGroupTableRow
                key={subGroup?.id}
                subGroupData={subGroup}
              />
            ))}
          </Collapse>
        </SHTableCell>
      </SHTableRow>
    </>
  );
};
