import { useTheme } from "@mui/material";
import { HorizontalLine } from "@pages/platform-analysis/components/buttons/export-pdf/components/line";
import {
  SUB_TITLE_HEIGHT,
  TITLE_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { View, Text } from "@react-pdf/renderer";
import { isEmpty } from "lodash";

interface PDFTitleSectionProps {
  title?: string;
  subTitle?: string;
  customTitleFontSize?: string | number;
}

export const PDFTitleSection = ({
  title,
  subTitle,
  customTitleFontSize,
}: PDFTitleSectionProps) => {
  const { typographyStyle } = useStyles();
  const { palette } = useTheme();

  return (
    <View
      style={{ height: !isEmpty(subTitle) ? SUB_TITLE_HEIGHT : TITLE_HEIGHT }}
    >
      <Text
        style={{
          ...typographyStyle.title,
          color: palette.common.black,
          fontSize: customTitleFontSize ?? "14px",
        }}
      >
        {title}
      </Text>
      <HorizontalLine color={palette.common.black} />
      {!isEmpty(subTitle) && (
        <Text
          style={{
            ...typographyStyle.subtitle,
            color: palette.common.black,
          }}
        >
          {subTitle}
        </Text>
      )}
    </View>
  );
};
