import { useNotification } from "@hooks/useNotification";
import { CreateFamilyGroupDTO, FamilyGroupDTO } from "@models/family-group/entities/familyGroup";
import { ClientStatus } from "@models/reviews/enums/status";
import {
  createFamilyGroupThunk,
  createReviewClientThunk,
  loadReviewClientThunk,
  resetReviewClientStore,
  setReviewClientAction,
  updateReviewClientThunk,
} from "@redux/slices/review-client";
import { RootState, useAppDispatch } from "@redux/store";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { putFamilyGroupStatus } from "@services/reviews/reviewClientService";

export const useReviewClient = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { familyGroupId } = useParams<{ familyGroupId: string }>();
  const { familyGroup, adviserUsers, ui: clientUI } = useSelector(
    (state: RootState) => state.reviewClient,
  );

  const setReviewClient = (client: FamilyGroupDTO) => {
    dispatch(setReviewClientAction(client));
  };

  const resetStore = () => {
    dispatch(resetReviewClientStore());
  };

  const loadReviewClient = async (clientId: string) => {
    const response = await dispatch(loadReviewClientThunk(clientId)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const createReviewClient = async (client: FamilyGroupDTO) => {
    const response = await dispatch(createReviewClientThunk(client)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updateReviewClient = async (client: FamilyGroupDTO) => {
    const response = await dispatch(updateReviewClientThunk(client)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updateReviewClientStatus = async (
    familyGroupId: string,
    status: ClientStatus,
  ) => {
    const response = await putFamilyGroupStatus(familyGroupId, status);
    if (response.isSuccess && response.data) {
      notify(response.message, {
        variant: "success",
        close: true,
      });
    } else {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
    return response.data;
  };

  const createFamilyGroup = async (familyGroup: CreateFamilyGroupDTO) => {
    const response = await dispatch(createFamilyGroupThunk(familyGroup)).unwrap();
    if (!response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response;
  }

  return {
    familyGroup,
    familyGroupId,
    clientUI,
    adviserUsers,
    setReviewClient,
    loadReviewClient,
    createReviewClient,
    updateReviewClient,
    updateReviewClientStatus,
    resetStore,
    createFamilyGroup
  };
};
