import {
  SxProps,
  TypeText,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { Theme } from "@mui/system/createTheme";
import { useMemo } from "react";

export interface SHTypographyProps extends TypographyProps {
  disabled?: boolean;
  addPaddingTop?: boolean;
  truncate?: boolean;
  colorVariant?: keyof TypeText;
}
export const SHTypography = (
  {
    disabled,
    addPaddingTop = true,
    truncate = false,
    colorVariant,
    sx,
    ...others
  }: SHTypographyProps,
): JSX.Element => {
  const { palette } = useTheme();

  const compileSX = useMemo(() => {
    let customSx: SxProps<Theme> = {};
    if (!addPaddingTop)
      customSx = {
        paddingTop: 0,
      };
    if (truncate)
      customSx = {
        ...customSx,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      };
    if (colorVariant) {
      customSx = {
        ...customSx,
        color: palette.text[colorVariant],
      };
    }
    return { ...customSx, ...sx };
  }, [sx, addPaddingTop, truncate, colorVariant, palette.text]);

  return (
    <Typography
      {...others}
      sx={disabled ? { ...compileSX, color: palette.text.disabled } : compileSX}
    />
  );
};
