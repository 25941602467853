import { FeeAnalysisFilterRadioButton } from "@components/buttons/fee-analysis-filter-radio";
import { FilterValue } from "@components/buttons/filter";
import {
  SHButton,
  SHContainer,
  SHDivider,
  SHHtmlBlock,
  SHImage,
  SHStack,
  SHToggleButtonOptions,
  SHTypography,
} from "@components/design-systems";
import { EditSVG } from "@components/svgs";
import { EyeIcon } from "@components/svgs/eye";
import { DateFormat, DateTimeFormat } from "@constants/format";
import { useIsNew } from "@hooks/useIsNew";
import { useReview } from "@hooks/useReview";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { UserType } from "@models/auth";
import { AnalysisCommentDTO } from "@models/platform-analysis/entities/comment";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import { TableViewMode } from "@models/platform-analysis/enums/tableViewMode";
import { ReviewFilterDTO } from "@models/reviews/entities/review";
import { ReviewStepBusinessMetricContainerDTO } from "@models/reviews/entities/steps/businessMetric";
import { ReviewStepFeatureContainerDTO } from "@models/reviews/entities/steps/feature";
import {
  HiddenSubProductDTO,
  ReviewStepSummaryDTO,
} from "@models/reviews/entities/steps/summary";
import { ReviewStep } from "@models/reviews/enums/step";
import { Collapse, useTheme } from "@mui/material";
import { ChartLegendBox } from "@pages/platform-analysis/_id/steps/fee/analysis/components/chart-legend-box";
import { FeeHeatmap } from "@pages/platform-analysis/_id/steps/fee/analysis/components/heatmap";
import { ChangeRichTextDialog } from "@pages/platform-analysis/_id/steps/summary/components/change-rich-text-dialog";
import { FeeLineChart } from "@pages/platform-analysis/_id/steps/summary/components/fee-line-chart";
import { ShowHideButton } from "@pages/platform-analysis/_id/steps/summary/components/show-hide-button";
import { TableAnalysisViewModeButton } from "@pages/platform-analysis/_id/steps/summary/components/table-view-mode-button";
import { BusinessMetricAnalysisTable } from "@pages/platform-analysis/_id/steps/summary/components/tables/business-metric-analysis";
import { BusinessMetricsIncludeTable } from "@pages/platform-analysis/_id/steps/summary/components/tables/business-metric-include";
import { FeaturesIncludeTable } from "@pages/platform-analysis/_id/steps/summary/components/tables/features-include";
import { FeePortfolioDetailsTable } from "@pages/platform-analysis/_id/steps/summary/components/tables/fee-portfolio-details";
import { AnalysisSummarySkeleton } from "@pages/platform-analysis/_id/steps/summary/skeleton";
import { ExportExcelButton } from "@pages/platform-analysis/components/buttons/export-excel";
import {
  FeeAnalysisFilterYesNoOptions,
  FeeReviewFilterMapOptions,
  PlatformFeeAnalysisInvestmentMenuFilterOptions,
  PlatformFeeAnalysisMissingInvestmentFilterOptions,
} from "@pages/platform-analysis/constant";
import { BusinessReviewAppendixTable } from "@pages/reviews/_id/steps/summary/components/tables/business-review-appendix";
import { repairUpdateReview } from "@redux/slices/review/util";
import { RootState } from "@redux/store";
import { saveReviewFilters } from "@services/reviews/reviewService";
import { hexToRGBA } from "@utils";
import { format } from "date-fns";
import { find, isEmpty, join, split, union } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { ReviewCommentTable } from "./components/tables/comments";
import { FeatureReviewTable } from "./components/tables/feature-review";
import { FeatureReviewAppendixTable } from "./components/tables/feature-review-appendix";
import {
  convertToBusinessReviewContainerArray,
  convertToFeatureReviewContainerArray,
  generateHiddenIds,
} from "./util";
import { generateSubProductColors } from "@pages/platform-analysis/util";

export interface ReviewSummaryProps extends StepCompProps {}
export const ReviewSummary: ForwardRefRenderFunction<
  StepCompRef,
  ReviewSummaryProps
> = ({ stepNumber }, ref) => {
  const isNew = useIsNew();
  const { palette } = useTheme();
  const { reviewId } = useParams<{ reviewId: string }>();
  const {
    ui: { isLoading, isSubmitting, isShowHidden, isShowHiddenAppendix },
    review,
    isCompleted,
    isReadOnly,
    lineChartColors,
  } = useSelector((state: RootState) => state.review);

  const {
    setIsDirty,
    setIsValid,
    setIsShowHidden,
    setIsShowHiddenAppendix,
    setReview,
    loadReviewStep,
    loadReviewDisclaimers,
    setReviewDisclaimers,
    updateCurrentReview,
    setLineChartColors,
  } = useReview();

  const {
    reset,
    watch,
    setValue,
    getValues,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...new ReviewStepSummaryDTO() },
  });

  const isDisabled = isSubmitting;
  const dataValidDate = review?.databaseVersion;
  const adviserName = watch("ownerName");
  const adviserARN = watch("ownerARN");
  const adviserFirmLogo = watch("adviserFirmLogo");
  const adviserFirmName = watch("adviserFirmName");
  const adviserFirmAfsl = watch("adviserFirmAFSL");
  const lastModifiedDate = watch("lastModifiedDate");
  const serial = watch("serial");
  const familyGroupName = watch("familyGroupName");
  const familyMembersNames = watch("familyMembersNames");
  const objectives = watch("objectives");
  const reviewComments = watch("analysisComments");
  const feePortfolioDetails = watch("feePortfolioDetails");
  const showSelectedFeatures = watch("showSelectedFeatures");
  const showSubSection =
    watch("featureAnalysisTableViewMode") !== TableViewMode.Summary;
  const showHoldingsNumber = watch(
    "feeAnalysis.shortlisted.isShowHoldingsNumber",
  );
  const appendixShowHoldingsNumber = watch(
    "feeAnalysis.disqualified.isShowHoldingsNumber",
  );
  const feeMissingInvestmentsStyle =
    watch("feeMissingInvestmentsStyle") ??
    FeeMissingInvestmentsOption.AllPlatform;
  const feeInvestmentMenuStyle =
    watch("investmentMenu") ?? FeeInvestmentMenuOption.AllPlatform;
  const appendixFeeMissingInvestmentsStyle =
    watch("appendixFeeMissingInvestmentsStyle") ??
    FeeMissingInvestmentsOption.AllPlatform;
  const appendixFeeInvestmentMenuStyle =
    watch("appendixInvestmentMenu") ?? FeeInvestmentMenuOption.AllPlatform;
  const feesDisplayStyle = watch("feesDisplayStyle") ?? FeesDisplayStyle.Dollar;
  const feeTableViewMode =
    watch("feeTableViewMode") ?? FeeHeatmapMode.Projection;
  const featureAnalysisTableViewMode =
    watch("featureAnalysisTableViewMode") ?? TableViewMode.Default;
  const showAppendixFeatureAnalysis =
    watch("showAppendixFeatureAnalysis") ?? true;
  const showAppendixBusinessReview =
    watch("showAppendixBusinessMetricAnalysis") ?? true;
  const showAppendixFeeAnalysis = watch("showAppendixFeeAnalysis") ?? true;

  const featureReview = watch("featureAnalysis.shortlisted");
  const appendixFeatureReview = watch("featureAnalysis.disqualified");
  const appendixFeatureReviewTotalPlatforms = watch(
    "featureAnalysis.disqualified",
  )?.selectedProducts?.length;

  const businessReview = watch("businessMetricAnalysis.shortlisted");
  const appendixBusinessReview = watch("businessMetricAnalysis.disqualified");
  const appendixBusinessReviewTotalPlatforms = watch(
    "businessMetricAnalysis.disqualified",
  )?.selectedProducts?.length;

  // TODO: Change to shortlisted
  const feesSubProducts = watch("feeAnalysis")?.shortlisted?.subProducts;
  const appendixFeesSubProducts =
    watch("feeAnalysis")?.disqualified?.subProducts;
  const allFeesSubProducts = watch("feeAnalysis")?.all?.subProducts;

  const concludingRemarks = watch("concludingRemarks");
  const hideFeeAnalysisGraph = watch("hideFeeAnalysisGraph");

  const [shouldRendered, setShouldRendered] = useState(false);
  const [showEditRemark, setShowEditRemark] = useState(false);
  const [showEditObjective, setShowEditObjective] = useState(false);
  const [comments, setComments] = useState<AnalysisCommentDTO[]>();
  const [disclaimers, setDisclaimers] = useState<DisclaimerDTO[]>();
  const [appendixFeatureReviewArr, setAppendixFeatureReviewArr] = useState<
    ReviewStepFeatureContainerDTO[]
  >([]);
  const [appendixBusinessReviewArr, setAppendixBusinessReviewArr] = useState<
    ReviewStepBusinessMetricContainerDTO[]
  >([]);
  const [hiddenIds, setHiddenIds] = useState<string[]>([]);
  const [appendixHiddenIds, setAppendixHiddenIds] = useState<string[]>([]);

  const isDisabledAnalysisTableViewMode =
    (featureReview?.overallScore?.totalSelectedFeature ?? 0) > 200;
  const isShowFeatureIncludedSection =
    !isCompleted && featureAnalysisTableViewMode !== TableViewMode.Detail;

  const feeMissingInvestmentFilter: FilterValue[] | undefined =
    feeMissingInvestmentsStyle !== FeeMissingInvestmentsOption.NotMissing
      ? undefined
      : [
          {
            value: feeMissingInvestmentsStyle,
            label: feeMissingInvestmentsStyle,
          },
        ];
  const feeInvestmentMenuFilter: FilterValue[] | undefined =
    feeInvestmentMenuStyle === FeeInvestmentMenuOption.AllPlatform
      ? undefined
      : [
          {
            value: feeInvestmentMenuStyle,
            label: feeInvestmentMenuStyle,
          },
        ];

  const appendixFeeMissingInvestmentFilter: FilterValue[] | undefined =
    appendixFeeMissingInvestmentsStyle !==
    FeeMissingInvestmentsOption.NotMissing
      ? undefined
      : [
          {
            value: appendixFeeMissingInvestmentsStyle,
            label: appendixFeeMissingInvestmentsStyle,
          },
        ];
  const appendixFeeInvestmentMenuFilter: FilterValue[] | undefined =
    appendixFeeInvestmentMenuStyle === FeeInvestmentMenuOption.AllPlatform
      ? undefined
      : [
          {
            value: appendixFeeInvestmentMenuStyle,
            label: appendixFeeInvestmentMenuStyle,
          },
        ];

  const feesSubProductsFiltered = useMemo(() => {
    if (
      feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.AllPlatform &&
      feeInvestmentMenuStyle === FeeInvestmentMenuOption.AllPlatform
    ) {
      return feesSubProducts;
    }

    let feesSubProductsFiltered: FeeSubProductDTO[] = Object.assign(
      feesSubProducts ?? [],
    );

    if (feeInvestmentMenuStyle !== FeeInvestmentMenuOption.AllPlatform) {
      feesSubProductsFiltered = feesSubProductsFiltered?.filter(
        (subProduct) => subProduct.investmentMenu === feeInvestmentMenuStyle,
      );
    }

    if (
      feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.AllPlatform
    ) {
      return feesSubProductsFiltered;
    } else {
      return feesSubProductsFiltered?.filter((subProduct) =>
        feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.Missing
          ? subProduct.warning
          : !subProduct.warning,
      );
    }
  }, [feesSubProducts, feeMissingInvestmentsStyle, feeInvestmentMenuStyle]);

  const appendixFeesSubProductsFiltered = useMemo(() => {
    if (
      appendixFeeMissingInvestmentsStyle ===
        FeeMissingInvestmentsOption.AllPlatform &&
      appendixFeeInvestmentMenuStyle === FeeInvestmentMenuOption.AllPlatform
    ) {
      return appendixFeesSubProducts;
    }

    let appendixFeesSubProductsFiltered: FeeSubProductDTO[] = Object.assign(
      appendixFeesSubProducts ?? [],
    );

    if (
      appendixFeeInvestmentMenuStyle !== FeeInvestmentMenuOption.AllPlatform
    ) {
      appendixFeesSubProductsFiltered = appendixFeesSubProductsFiltered?.filter(
        (subProduct) =>
          subProduct.investmentMenu === appendixFeeInvestmentMenuStyle,
      );
    }

    if (
      appendixFeeMissingInvestmentsStyle ===
      FeeMissingInvestmentsOption.AllPlatform
    ) {
      return appendixFeesSubProductsFiltered;
    } else {
      return appendixFeesSubProductsFiltered?.filter((subProduct) =>
        appendixFeeMissingInvestmentsStyle ===
        FeeMissingInvestmentsOption.Missing
          ? subProduct.warning
          : !subProduct.warning,
      );
    }
  }, [
    appendixFeesSubProducts,
    appendixFeeMissingInvestmentsStyle,
    appendixFeeInvestmentMenuStyle,
  ]);

  const subProductsDataForExcel = () => {
    return isShowHidden
      ? feesSubProductsFiltered
      : feesSubProductsFiltered?.filter(
          (subProduct) => !hiddenIds.includes(subProduct.id),
        );
  };

  const appendixSubProductsDataForExcel = () => {
    return isShowHiddenAppendix
      ? appendixFeesSubProductsFiltered
      : appendixFeesSubProductsFiltered?.filter(
          (subProduct) => !appendixHiddenIds.includes(subProduct.id),
        );
  };

  const loadReviewSummary = async () => {
    if (!isNew && reviewId) {
      const response = await loadReviewStep({
        reviewId: reviewId,
        stepId: ReviewStep.Summary,
      });
      if (response && response.summary) {
        const reviewSummary = response?.summary;
        reset(reviewSummary);
        setHiddenIds(
          generateHiddenIds(
            reviewSummary.feeAnalysis?.shortlisted.subProducts ?? [],
            reviewSummary.hiddenSubProducts ?? [],
          ),
        );
        setAppendixHiddenIds(
          generateHiddenIds(
            reviewSummary.feeAnalysis?.disqualified.subProducts ?? [],
            reviewSummary.hiddenSubProducts ?? [],
          ),
        );
        if (
          reviewSummary?.featureAnalysis?.shortlisted?.overallScore
            ?.totalSelectedFeature! > 200 &&
          reviewSummary?.featureAnalysisTableViewMode === TableViewMode.Detail
        ) {
          setValue("featureAnalysisTableViewMode", TableViewMode.Default, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      }
    }
  };

  const loadReviewSummaryDisclaimers = async () => {
    if (!isNew && reviewId) {
      const disclaimers = await loadReviewDisclaimers(
        reviewId,
        ReviewStep.Summary,
      );
      setDisclaimers(disclaimers);
      // For Export PDF
      setReviewDisclaimers(disclaimers);
    }
  };

  const handleOnSubmit = async (isShowMessage?: boolean) => {
    if (!review) return false;
    const isSuccess = await updateCurrentReview(
      {
        ...repairUpdateReview(review),
        currentStep: ReviewStep.Summary,
        summary: {
          ...getValues(),
          hiddenSubProducts: generateHiddenSubProducts(
            hiddenIds?.concat(appendixHiddenIds),
            allFeesSubProducts ?? [],
          ),
          analysisComments: null,
          selectedFeatures: null,
          featureAnalysis: null,
          businessMetricAnalysis: null,
          feeAnalysis: null,
        },
      },
      isShowMessage,
    );
    if (isSuccess) {
      reset({
        ...getValues(),
        lastModifiedDate: new Date(),
        analysisComments: comments,
      });
      setReview({
        ...review,
        summary: {
          ...getValues(),
          lastModifiedDate: new Date(),
          analysisComments: comments ?? [],
          hiddenSubProducts: generateHiddenSubProducts(
            hiddenIds?.concat(appendixHiddenIds),
            allFeesSubProducts ?? [],
          ),
        },
      });
    }
    return !!isSuccess;
  };

  const generateHiddenSubProducts = (
    hiddenIds: string[],
    subProducts: FeeSubProductDTO[],
  ): HiddenSubProductDTO[] => {
    let hiddenSubProducts: HiddenSubProductDTO[] = [];
    hiddenIds.forEach((id) => {
      var subProduct = find(subProducts, { id });
      if (subProduct) {
        let subProductId = subProduct.id;
        if (subProduct.isRateCard) {
          subProductId = split(subProduct.id, "|")[0];
        }

        hiddenSubProducts.push({
          id: subProductId,
          isRateCard: subProduct.isRateCard ?? true,
          rateCardVersion: subProduct.isRateCard
            ? subProduct.rateCardVersion ?? 0
            : 0,
        });
      }
    });
    return hiddenSubProducts;
  };

  const saveFilter = async () => {
    let data: ReviewFilterDTO = {
      feeMissingInvestmentsStyle: feeMissingInvestmentFilter?.[0].value
        ? feeMissingInvestmentFilter?.[0].value
        : FeeMissingInvestmentsOption.AllPlatform,
      investmentMenu: feeInvestmentMenuFilter?.[0]?.value
        ? feeInvestmentMenuFilter?.[0]?.value
        : FeeInvestmentMenuOption.AllPlatform,
      feesDisplayStyle: feesDisplayStyle,
      feeTableViewMode: feeTableViewMode,
      hiddenSubProducts: generateHiddenSubProducts(
        union(hiddenIds, appendixHiddenIds),
        allFeesSubProducts ?? [],
      ),
    };

    await saveReviewFilters(review?.id ?? "", data);
  };

  useEffectOnce(() => {
    loadReviewSummary();
    loadReviewSummaryDisclaimers();
    setIsShowHidden(false);

    const timer = setTimeout(() => {
      setShouldRendered(true);
    }, 2000);

    return () => {
      clearTimeout(timer);
      setIsDirty(false);
    };
  });

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep) => {
      if (!isReadOnly) {
        await saveFilter();
      }
      if (!isDirty || isReadOnly) return true;
      return await handleOnSubmit(true);
    },
    onChangeOtherStep: async () => {
      if (!isReadOnly) {
        await saveFilter();
      }
      if (isDirty) {
        return await handleOnSubmit(true);
      }
      return true;
    },
    onSubmit: async () => {
      return await handleOnSubmit(isDirty);
    },
  }));

  useEffect(() => {
    setComments(reviewComments || []);
  }, [reviewComments]);

  useEffect(() => {
    setIsValid(isValid);
    // eslint-disable-next-line
  }, [isValid]);

  useEffect(() => {
    if (!isReadOnly) setIsDirty(isDirty);
    // eslint-disable-next-line
  }, [isDirty, isReadOnly]);

  useEffect(() => {
    if (!appendixFeatureReview) {
      return;
    }
    setAppendixFeatureReviewArr(
      convertToFeatureReviewContainerArray(appendixFeatureReview, 7),
    );
  }, [appendixFeatureReview]);

  useEffect(() => {
    if (!appendixBusinessReview) {
      return;
    }
    setAppendixBusinessReviewArr(
      convertToBusinessReviewContainerArray(appendixBusinessReview, 7),
    );
  }, [appendixBusinessReview]);

  useEffect(() => {
    const colors = generateSubProductColors(allFeesSubProducts);
    setLineChartColors(colors);
    // eslint-disable-next-line
  }, [allFeesSubProducts]);

  const renderDisclaimers = () => {
    return (
      !isEmpty(disclaimers) && (
        <SHStack spacing="10px">
          <SHTypography variant="subtitle2">
            Fee review assumptions: Product-specific
          </SHTypography>
          {disclaimers?.map((item, index) => {
            return (
              <SHHtmlBlock
                key={index}
                content={item.messageDisclaimer}
                variant="caption"
                sx={{
                  pb: index !== disclaimers?.length - 1 ? "5px" : "0px",
                  fontSize: "10px !important",
                }}
                color={palette.text.third}
              />
            );
          })}
        </SHStack>
      )
    );
  };

  if (isLoading || !shouldRendered || !review)
    return <AnalysisSummarySkeleton />;

  return (
    <SHStack>
      <SHContainer
        maxWidth="lg2"
        sx={{ px: { xs: "16px", lg2: 0 }, py: "25px" }}
      >
        <SHStack spacing={"25px"}>
          <StepName
            name={"Suitability Review summary"}
            stepNumber={stepNumber}
          />
          <SHStack flexDirection={"row"} gap={"5px"}>
            <SHImage width={82} height={82} src={adviserFirmLogo ?? ""} />
            <SHStack flexDirection={"column"} alignItems={"flex-start"}>
              <SHTypography
                variant="body1"
                fontSize={"24px"}
                paddingTop={"0px"}
              >
                {watch("name")}
              </SHTypography>
              <SHStack flexDirection={"row"} gap={"15px"}>
                <SHStack flexDirection={"row"} gap={"5px"}>
                  <SHTypography variant="body2" fontWeight={600}>
                    Prepared by:
                  </SHTypography>
                  <SHTypography variant="body2">
                    {`${adviserName} of ${adviserFirmName}`}
                  </SHTypography>
                </SHStack>
                <SHTypography variant="body2">|</SHTypography>
                <SHStack flexDirection={"row"} gap={"5px"}>
                  <SHTypography variant="body2" fontWeight={600}>
                    ARN:
                  </SHTypography>
                  <SHTypography variant="body2">{adviserARN}</SHTypography>
                </SHStack>
                <SHTypography variant="body2">|</SHTypography>
                <SHStack flexDirection={"row"} gap={"5px"}>
                  <SHTypography variant="body2" fontWeight={600}>
                    AFSL No:
                  </SHTypography>
                  <SHTypography variant="body2">{adviserFirmAfsl}</SHTypography>
                </SHStack>
              </SHStack>
              <SHStack flexDirection={"row"} gap={"15px"}>
                <SHStack flexDirection={"row"} gap={"5px"}>
                  <SHTypography variant="body2" fontWeight={600}>
                    Last edit:
                  </SHTypography>
                  <SHTypography variant="body2">
                    {format(
                      lastModifiedDate || new Date(),
                      `${DateTimeFormat}`,
                    )}
                  </SHTypography>
                </SHStack>
                <SHTypography variant="body2">|</SHTypography>
                <SHStack flexDirection={"row"} gap={"5px"}>
                  <SHTypography variant="body2" fontWeight={600}>
                    Data valid:
                  </SHTypography>
                  <SHTypography variant="body2">
                    {format(dataValidDate || new Date(), `${DateFormat}`)}
                  </SHTypography>
                </SHStack>
                <SHTypography variant="body2">|</SHTypography>
                <SHStack flexDirection={"row"} gap={"5px"}>
                  <SHTypography variant="body2" fontWeight={600}>
                    Serial:
                  </SHTypography>
                  <SHTypography variant="body2">{serial}</SHTypography>
                </SHStack>
              </SHStack>
            </SHStack>
          </SHStack>
          <SHStack flexDirection={"column"} gap={"5px"}>
            <SHTypography variant="subtitle2">
              {`This Suitability Review is for ${familyGroupName} and the
              following individuals/entities:`}
            </SHTypography>
            <SHTypography variant="body2" fontWeight={600}>
              {join(familyMembersNames, ", ").replace(/, (?!.*,)/g, " and ")}
            </SHTypography>
          </SHStack>
          {(!isCompleted || objectives) && (
            <SHStack spacing={"5px"}>
              <SHStack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <SHTypography variant="subtitle2">Objectives</SHTypography>
                {!isCompleted && !isReadOnly && (
                  <SHButton
                    startIcon={<EditSVG color={palette.common.white} />}
                    variant="contained"
                    onClick={() => setShowEditObjective(true)}
                  >
                    Edit
                  </SHButton>
                )}
              </SHStack>
              <SHDivider />
              <SHHtmlBlock
                content={objectives}
                placeholder={"Insert objectives"}
                color={palette.text.third}
              />
            </SHStack>
          )}
          <SHStack spacing={3}>
            <SHStack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <SHTypography variant="subtitle2">
                {`Platforms selected for ${familyGroupName}`}
              </SHTypography>
            </SHStack>

            <ReviewCommentTable
              disabled={isReadOnly || isDisabled}
              isViewMode={isCompleted}
              comments={comments ?? []}
              onChange={(newComments) => setComments(newComments)}
            />
          </SHStack>
          {/* Feature review */}
          {!isEmpty(featureReview) && (
            <SHStack spacing={3}>
              <SHStack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <SHTypography variant="subtitle2">Feature review</SHTypography>
                {!isCompleted && (
                  <TableAnalysisViewModeButton
                    disabled={isDisabled}
                    value={featureAnalysisTableViewMode}
                    onChange={(mode: TableViewMode) =>
                      setValue("featureAnalysisTableViewMode", mode, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    options={[
                      {
                        value: TableViewMode.Detail,
                        label: "Detail",
                        disabled: isDisabledAnalysisTableViewMode,
                        tooltip: isDisabledAnalysisTableViewMode
                          ? "Select less than 200 features for detail view"
                          : "",
                      },
                      {
                        value: TableViewMode.Default,
                        label: "Default",
                      },
                      {
                        value: TableViewMode.Summary,
                        label: "Summary",
                      },
                    ]}
                  />
                )}
              </SHStack>

              <FeatureReviewTable
                showSubgroup={showSubSection}
                featureReview={featureReview as any}
                isDetailMode={
                  featureAnalysisTableViewMode === TableViewMode.Detail
                }
              />
            </SHStack>
          )}
          {/* Business metric review */}
          {!isEmpty(businessReview) && (
            <SHStack spacing={1}>
              <SHTypography variant="subtitle2">
                Business metric review
              </SHTypography>
              <SHStack flexDirection={"row"} gap={"5px"}></SHStack>
              <BusinessMetricAnalysisTable
                businessMetricAnalysis={businessReview as any}
              />
            </SHStack>
          )}
          {/* Portfolio details for fee estimates */}
          <SHStack>
            <SHTypography variant="subtitle2">
              Portfolio details for fee estimates
            </SHTypography>
            <FeePortfolioDetailsTable
              data={feePortfolioDetails}
              isShowHoldingsNumber={showHoldingsNumber}
            />
          </SHStack>
          {/* Fee estimates */}
          <SHStack spacing={3}>
            <SHStack>
              <SHStack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <SHTypography variant="subtitle2">Fee estimates</SHTypography>
                {!isCompleted && (
                  <SHStack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={"25px"}
                  >
                    <SHStack>
                      <SHButton
                        color="primary"
                        variant="text"
                        size="extraMedium"
                        startIcon={
                          <EyeIcon
                            isActive={true}
                            fill={
                              hiddenIds.length > 0
                                ? palette.primary.main
                                : palette.grey[500]
                            }
                          />
                        }
                        disabled={hiddenIds.length === 0}
                        onClick={() => {
                          setHiddenIds([]);
                          setIsShowHidden(false);
                          setValue("hiddenSubProducts", [], {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }}
                      >
                        Show hidden
                      </SHButton>
                    </SHStack>
                    <FeeAnalysisFilterRadioButton
                      buttonTitle={"Filters"}
                      filterInvestmentMenuData={
                        PlatformFeeAnalysisInvestmentMenuFilterOptions
                      }
                      filterInvestmentMenuValue={feeInvestmentMenuFilter}
                      filterMissingInvestmentData={
                        PlatformFeeAnalysisMissingInvestmentFilterOptions
                      }
                      filterMissingInvestmentValue={feeMissingInvestmentFilter}
                      width={"275px"}
                      labelAll={"All platforms"}
                      zIndex={10}
                      onChangeFilter={(missingValues, menuValues) => {
                        const missingValue = missingValues
                          ? missingValues?.[0]?.value
                          : FeeMissingInvestmentsOption.AllPlatform;
                        const menuValue = menuValues
                          ? menuValues?.[0]?.value
                          : FeeInvestmentMenuOption.AllPlatform;
                        setValue("feeMissingInvestmentsStyle", missingValue, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                        setValue("investmentMenu", menuValue, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                    />
                    <SHToggleButtonOptions
                      options={FeeReviewFilterMapOptions}
                      value={feeTableViewMode}
                      onChange={(event, value) => {
                        if (!value) return;
                        setValue("feeTableViewMode", value, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                    />
                    <SHToggleButtonOptions
                      options={FeeAnalysisFilterYesNoOptions}
                      value={feesDisplayStyle}
                      onChange={(event, value) => {
                        if (!value) return;
                        setValue("feesDisplayStyle", value, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                    />
                  </SHStack>
                )}
              </SHStack>

              <FeeHeatmap
                subProductsInStore={feesSubProducts ?? []}
                subProducts={feesSubProductsFiltered ?? []}
                hiddenIds={hiddenIds}
                onChangeHiddenIds={setHiddenIds}
                isCompleted={isCompleted}
                isShowHidden={isShowHidden}
                isSummaryStep={true}
                totalPortfolioValue={
                  review?.summary?.feePortfolioDetails?.totalPortfolioValue
                }
                mode={feesDisplayStyle as any}
                heatmapMode={feeTableViewMode}
                sx={{
                  backgroundColor: "transparent",
                  pt: "15px",
                }}
                titleComp={
                  <SHStack
                    sx={{
                      border: `solid 1px ${hexToRGBA(
                        palette.primary[50],
                        0.3,
                      )}`,
                      p: "10px 15px",
                    }}
                    direction={"row"}
                    spacing={"5px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <SHTypography variant="subtitle2" fontWeight={700}>
                      Annual platform fees by portfolio value (estimates)
                    </SHTypography>
                    {!isEmpty(subProductsDataForExcel()) && (
                      <ExportExcelButton
                        isReview={true}
                        mode={feesDisplayStyle as any}
                        data={
                          {
                            ...review.summary,
                            subProducts: subProductsDataForExcel(),
                            databaseVersion: review.databaseVersion ?? null,
                            isShowHoldingsNumber: showHoldingsNumber,
                            ownerType: UserType.AdviserAdviser,
                            name: review.summary?.name,
                            practiceName: review.summary?.adviserFirmName,
                            adviserName: review.summary?.ownerName,
                            feePortfolioDetails:
                              review.summary?.feePortfolioDetails,
                            lastModifiedDate: review.summary?.lastModifiedDate,
                            familyGroupName: review.summary?.familyGroupName,
                            serial: review.summary?.serial,
                          } as any
                        }
                      />
                    )}
                  </SHStack>
                }
                leftLegend={
                  <ChartLegendBox height={"90px"} direction="vertical">
                    Platforms
                  </ChartLegendBox>
                }
                bottomLegend={
                  <ChartLegendBox>
                    Average total portfolio value for the year
                  </ChartLegendBox>
                }
                showWarningCaptions={feesSubProductsFiltered?.some(
                  (subProduct) => subProduct.warning,
                )}
              />
            </SHStack>
            <SHStack
              direction={"row-reverse"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {!isCompleted && !isEmpty(feesSubProductsFiltered) && (
                <ShowHideButton
                  disabled={isDisabled}
                  isShow={!hideFeeAnalysisGraph}
                  onChange={(isShow: boolean) => {
                    setValue("hideFeeAnalysisGraph", !isShow, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                />
              )}
            </SHStack>
            {!isEmpty(feesSubProductsFiltered) && (
              <Collapse in={!hideFeeAnalysisGraph}>
                <FeeLineChart
                  subProducts={feesSubProductsFiltered ?? []}
                  mode={feesDisplayStyle}
                  hiddenIds={hiddenIds}
                  isCompleted={isCompleted}
                  lineColors={lineChartColors}
                  onChangeHiddenIds={setHiddenIds}
                />
              </Collapse>
            )}
          </SHStack>
          {/* Concluding remarks */}
          {(!isCompleted || concludingRemarks) && (
            <SHStack spacing={3}>
              <SHStack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <SHTypography variant="subtitle2">
                  Concluding remarks
                </SHTypography>
                {!isCompleted && !isReadOnly && (
                  <SHButton
                    startIcon={<EditSVG color={palette.common.white} />}
                    variant="contained"
                    disabled={isReadOnly || isDisabled}
                    onClick={() => setShowEditRemark(true)}
                  >
                    Edit
                  </SHButton>
                )}
              </SHStack>
              <SHStack
                sx={{
                  backgroundColor: hexToRGBA(palette.common.white, 0.25),
                  border: `1px solid ${palette.secondary[100]}`,
                  backdropFilter: "blur(0.5px)",
                  padding: "15px 12px",
                }}
              >
                <SHHtmlBlock
                  content={concludingRemarks}
                  placeholder={"Insert concluding remarks"}
                />
              </SHStack>
            </SHStack>
          )}
          {/* Appendix */}
          <SHStack flexDirection={"column"} gap={"5px"}>
            <SHTypography variant="h1">Appendix</SHTypography>
            <SHTypography variant="body2">
              Summary of platform considerations
            </SHTypography>
          </SHStack>
          <SHStack spacing={"25px"}>
            <SHStack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <SHTypography variant="subtitle2">
                Platforms that were considered but not selected
              </SHTypography>
            </SHStack>

            <ReviewCommentTable
              isAppendix
              disabled={isReadOnly || isDisabled}
              isViewMode={isCompleted}
              comments={comments ?? []}
              onChange={(newComments) => setComments(newComments)}
            />
          </SHStack>
          <SHStack spacing={"25px"}>
            {!isEmpty(appendixFeatureReviewArr) &&
              appendixFeatureReviewArr?.map((featureAnalysis, index) => (
                <SHStack
                  key={index}
                  spacing={showAppendixFeatureAnalysis ? 3 : 0}
                >
                  <SHStack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    {(showAppendixFeatureAnalysis ||
                      (!showAppendixFeatureAnalysis && index === 0)) && (
                      <SHStack flexDirection={"row"} gap={"5px"}>
                        <SHTypography variant="subtitle2">
                          Feature review
                        </SHTypography>

                        <SHTypography variant="subtitle2" fontWeight={400}>
                          {`of platforms that were not selected (Platform ${
                            index * 7 + 1
                          }-${Math.min(
                            index * 7 + 7,
                            appendixFeatureReviewTotalPlatforms,
                          )} of ${appendixFeatureReviewTotalPlatforms})`}
                        </SHTypography>
                      </SHStack>
                    )}

                    {!isCompleted && index === 0 && (
                      <ShowHideButton
                        disabled={isDisabled}
                        isShow={showAppendixFeatureAnalysis}
                        onChange={(isShow: boolean) =>
                          setValue("showAppendixFeatureAnalysis", isShow, {
                            shouldDirty: true,
                            shouldValidate: true,
                          })
                        }
                      />
                    )}
                  </SHStack>
                  {showAppendixFeatureAnalysis && (
                    <Collapse in={showAppendixFeatureAnalysis}>
                      <FeatureReviewAppendixTable
                        showSubgroup={showSubSection}
                        featureReview={featureAnalysis as any}
                        isDetailMode={
                          featureAnalysisTableViewMode === TableViewMode.Detail
                        }
                      />
                    </Collapse>
                  )}
                </SHStack>
              ))}

            {!isEmpty(appendixBusinessReviewArr) &&
              appendixBusinessReviewArr.map((businessReview, index) => (
                <SHStack
                  key={index}
                  spacing={showAppendixBusinessReview ? 3 : 0}
                >
                  <SHStack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    {(showAppendixBusinessReview ||
                      (!showAppendixBusinessReview && index === 0)) && (
                      <SHStack flexDirection={"row"} gap={"5px"}>
                        <SHTypography variant="subtitle2">
                          Business metric review
                        </SHTypography>
                        <SHTypography variant="subtitle2" fontWeight={400}>
                          {`of platforms that made it to business review stage but were not selected (Platform ${
                            index * 5 + 1
                          }-${Math.min(
                            index * 5 + 5,
                            appendixBusinessReviewTotalPlatforms,
                          )} of ${appendixBusinessReviewTotalPlatforms})`}
                        </SHTypography>
                      </SHStack>
                    )}

                    {!isCompleted && index === 0 && (
                      <ShowHideButton
                        disabled={isDisabled}
                        isShow={showAppendixBusinessReview}
                        onChange={(isShow: boolean) =>
                          setValue(
                            "showAppendixBusinessMetricAnalysis",
                            isShow,
                            {
                              shouldDirty: true,
                              shouldValidate: true,
                            },
                          )
                        }
                      />
                    )}
                  </SHStack>
                  <Collapse in={showAppendixBusinessReview}>
                    <BusinessReviewAppendixTable
                      businessReview={businessReview as any}
                    />
                  </Collapse>
                </SHStack>
              ))}

            {!isEmpty(appendixFeesSubProducts) && (
              <SHStack spacing={3}>
                <SHStack>
                  <SHStack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <SHStack flexDirection={"row"} gap={"5px"}>
                      <SHTypography variant="subtitle2">
                        Fee estimates
                      </SHTypography>
                      <SHTypography variant="subtitle2" fontWeight={400}>
                        of platforms that made it to fee review stage but were
                        not selected
                      </SHTypography>
                    </SHStack>
                    {!isCompleted && (
                      <Collapse in={showAppendixFeeAnalysis}>
                        <SHStack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={"25px"}
                        >
                          <SHStack>
                            <SHButton
                              color="primary"
                              variant="text"
                              size="extraMedium"
                              startIcon={
                                <EyeIcon
                                  isActive={true}
                                  fill={
                                    appendixHiddenIds.length > 0
                                      ? palette.primary.main
                                      : palette.grey[500]
                                  }
                                />
                              }
                              disabled={appendixHiddenIds.length === 0}
                              onClick={() => {
                                setAppendixHiddenIds([]);
                                setIsShowHiddenAppendix(false);
                                setValue("hiddenSubProducts", [], {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                });
                              }}
                            >
                              Show hidden
                            </SHButton>
                          </SHStack>
                          <FeeAnalysisFilterRadioButton
                            buttonTitle={"Filters"}
                            filterInvestmentMenuData={
                              PlatformFeeAnalysisInvestmentMenuFilterOptions
                            }
                            filterInvestmentMenuValue={
                              appendixFeeInvestmentMenuFilter
                            }
                            filterMissingInvestmentData={
                              PlatformFeeAnalysisMissingInvestmentFilterOptions
                            }
                            filterMissingInvestmentValue={
                              appendixFeeMissingInvestmentFilter
                            }
                            width={"275px"}
                            labelAll={"All platforms"}
                            zIndex={10}
                            onChangeFilter={(missingValues, menuValues) => {
                              const missingValue = missingValues
                                ? missingValues?.[0]?.value
                                : FeeMissingInvestmentsOption.AllPlatform;
                              const menuValue = menuValues
                                ? menuValues?.[0]?.value
                                : FeeInvestmentMenuOption.AllPlatform;
                              setValue(
                                "appendixFeeMissingInvestmentsStyle",
                                missingValue,
                                {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                },
                              );
                              setValue("appendixInvestmentMenu", menuValue, {
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                            }}
                          />
                        </SHStack>
                      </Collapse>
                    )}
                  </SHStack>

                  <FeeHeatmap
                    subProductsInStore={appendixFeesSubProducts ?? []}
                    subProducts={appendixFeesSubProductsFiltered ?? []}
                    hiddenIds={appendixHiddenIds}
                    onChangeHiddenIds={setAppendixHiddenIds}
                    isCompleted={isCompleted}
                    isShowHidden={isShowHiddenAppendix}
                    isSummaryStep={true}
                    totalPortfolioValue={
                      review?.summary?.feePortfolioDetails?.totalPortfolioValue
                    }
                    mode={feesDisplayStyle as any}
                    heatmapMode={feeTableViewMode}
                    sx={{
                      backgroundColor: "transparent",
                      pt: "15px",
                    }}
                    titleComp={
                      <SHStack
                        sx={{
                          border: `solid 1px ${hexToRGBA(
                            palette.primary[50],
                            0.3,
                          )}`,
                          p: "10px 15px",
                        }}
                        direction={"row"}
                        spacing={"5px"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <SHTypography variant="subtitle2" fontWeight={700}>
                          Annual platform fees by portfolio value (estimates)
                        </SHTypography>
                        {!isEmpty(appendixSubProductsDataForExcel()) && (
                          <ExportExcelButton
                            isReview={true}
                            mode={feesDisplayStyle as any}
                            data={
                              {
                                ...review.summary,
                                subProducts: appendixSubProductsDataForExcel(),
                                databaseVersion: review.databaseVersion ?? null,
                                isShowHoldingsNumber:
                                  appendixShowHoldingsNumber,
                                ownerType: UserType.AdviserAdviser,
                                name: review.summary?.name,
                                practiceName: review.summary?.adviserFirmName,
                                adviserName: review.summary?.ownerName,
                                feePortfolioDetails:
                                  review.summary?.feePortfolioDetails,
                                lastModifiedDate:
                                  review.summary?.lastModifiedDate,
                                familyGroupName:
                                  review.summary?.familyGroupName,
                                serial: review.summary?.serial,
                              } as any
                            }
                          />
                        )}
                      </SHStack>
                    }
                    leftLegend={
                      <ChartLegendBox height={"90px"} direction="vertical">
                        Platforms
                      </ChartLegendBox>
                    }
                    bottomLegend={
                      <ChartLegendBox>
                        Average total portfolio value for the year
                      </ChartLegendBox>
                    }
                    showWarningCaptions={appendixFeesSubProductsFiltered?.some(
                      (subProduct) => subProduct.warning,
                    )}
                  />
                </SHStack>
                {!isEmpty(appendixFeesSubProductsFiltered) && (
                  <Collapse in={!hideFeeAnalysisGraph}>
                    <FeeLineChart
                      subProducts={appendixFeesSubProductsFiltered ?? []}
                      mode={feesDisplayStyle}
                      hiddenIds={appendixHiddenIds}
                      isCompleted={isCompleted}
                      lineColors={lineChartColors}
                      onChangeHiddenIds={setAppendixHiddenIds}
                    />
                  </Collapse>
                )}
              </SHStack>
            )}
          </SHStack>

          {isShowFeatureIncludedSection && (
            <>
              <SHStack spacing={showSelectedFeatures ? 1 : 0}>
                <SHStack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <SHTypography variant="subtitle2">
                    Features included in the review
                  </SHTypography>
                  {!isCompleted && (
                    <ShowHideButton
                      disabled={isDisabled}
                      isShow={showSelectedFeatures}
                      onChange={(isShow: boolean) =>
                        setValue("showSelectedFeatures", isShow, {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                    />
                  )}
                </SHStack>
                <Collapse in={showSelectedFeatures}>
                  <FeaturesIncludeTable
                    featureInclude={watch("selectedFeatures")}
                  />
                </Collapse>
              </SHStack>
              <SHStack spacing={showSelectedFeatures ? 1 : 0}>
                <SHStack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <SHTypography variant="subtitle2">
                    Business metrics included in the review
                  </SHTypography>
                </SHStack>
                <Collapse in={showSelectedFeatures}>
                  <BusinessMetricsIncludeTable
                    businessMetricInclude={watch(
                      "businessMetricSelectedFeatures",
                    )}
                  />
                </Collapse>
              </SHStack>
            </>
          )}
          {/*  Disclaimer */}
          <>
            <SHStack spacing={"10px"}>
              <SHTypography variant="subtitle2">Disclaimer</SHTypography>
              <SHTypography variant="body2" fontSize={"10px"}>
                You have requested this report via the SuitabilityHub platform.
                We have not conducted any independent verification of the
                information provided by you. We utilise information sourced from
                our users and other third parties, and you must make your own
                enquiries as to the accuracy and completeness of information
                contained in this report. No representation or warranty (either
                express or implied) is given as to the truth, accuracy or
                completeness of the information in this report. This report is
                not intended to be relied on for the purposes of making a
                decision in relation to any financial product, platform or
                provider. We do not authorise any reliance on this report (or
                any information within it). In providing this report, we provide
                no financial advice or recommendation as to the suitability of
                any product, platform or provider.
              </SHTypography>
              <SHTypography variant="body2" fontSize={"10px"}>
                This report is based on the conditions in existence and
                information received on the “data valid” date stated in this
                report.
              </SHTypography>
            </SHStack>
            <SHStack spacing={"10px"}>
              <SHTypography variant="subtitle2">
                Fee review disclaimer
              </SHTypography>
              <SHTypography variant="body2" fontSize={"10px"}>
                Any result provided by SuitabilityHub’s fee calculator is
                illustrative only, and relies upon information that is subject
                to change. Any result should not be relied upon for the purpose
                of making a decision in relation to a particular financial
                product, platform or provider. Any result provided by our
                calculator is not an offer, and financial product providers will
                undertake their own fee calculations. Unless otherwise stated,
                all amounts are in Australian dollars.
              </SHTypography>
              <SHStack spacing={"10px"}>
                <SHTypography variant="subtitle2">
                  Fee review assumptions: General
                </SHTypography>
                <SHTypography
                  variant="body2"
                  fontSize={"10px"}
                  sx={{
                    "&>ul>li::marker": {
                      fontSize: "10px",
                    },
                  }}
                >
                  Our fee calculator relies upon various assumptions. Those
                  assumptions are as follows, and are current as at 15 December
                  2023:
                  <ul style={{ margin: 0, paddingLeft: "20px" }}>
                    <li>
                      All balances provided are an average figure for a 12-month
                      period;
                    </li>
                    <li>
                      Investments held by a client are also held by all of the
                      accounts in that client’s family;
                    </li>
                    <li>
                      Fee estimates are calculated based on all accounts within
                      a family investing in the same platform product;
                    </li>
                    <li>
                      For the purpose of calculating transaction fees,
                      investment transactions for a given investment class is
                      assumed to be equal in size, and this size is the
                      “anticipated average transaction size for the next 12
                      months” entered into the calculator;
                    </li>
                    <li>
                      Some platforms do not charge transaction fees on regular
                      investment facilities or plans. We assume all transactions
                      specified happen outside of these facilities or plans, on
                      an ad-hoc basis;
                    </li>
                    <li>
                      Any calculated expense recovery fee and/or operational
                      risk financial requirement fee is the maximum possible fee
                      as set out in the relevant financial product’s current
                      product disclosure statement;
                    </li>
                    <li>
                      For superannuation accounts with balances less than
                      $6,000, total administration, cash and account-related
                      fees are capped at 3% of the account balance. Other fees
                      are not included in this cap e.g. transaction fees;
                    </li>
                    <li>Each investment transaction is the same size; </li>
                    <li>
                      Where fees listed in the PDS vary within a specified
                      range, the maximum fee amount is applied;
                    </li>
                    <li>
                      Fee aggregation discounts and fee calculation
                      methodologies will be applied in accordance with the
                      relevant financial product’s current product disclosure
                      statement;
                    </li>
                    <li>
                      All listed accounts are grouped for fee aggregation
                      purposes; and
                    </li>
                    <li>
                      Fee estimates are displayed for total portfolio balances
                      in addition to the original total entered into the
                      calculator. These estimates are based on proportionally
                      allocating the holdings and account balances of the
                      original total portfolio value.
                    </li>
                  </ul>
                </SHTypography>
                <SHTypography variant="body2" fontSize={"10px"}>
                  Any result provided by the fee calculator takes into account
                  platform administration fees, as well as transaction fees for
                  Australian listed securities, international listed securities,
                  managed funds and fixed income, and fees associated with
                  holding retail insurance (not including the value of any
                  insurance premiums). Results do not factor in retail or group
                  insurance premiums, foreign currency fees that may apply to
                  international investments, buy-sell spreads, and investment
                  fees for individual investment options (e.g.
                  performance-related fees); final results also do not factor in
                  minimum cash balance and minimum transaction sizes.
                </SHTypography>
                {renderDisclaimers()}
              </SHStack>
            </SHStack>
          </>
        </SHStack>
      </SHContainer>
      {/*  Footer */}
      <SHDivider sx={{ borderColor: palette.text.primary }} />
      <SHContainer
        maxWidth="lg2"
        sx={{ px: { xs: "16px", lg2: 0 }, py: "40px" }}
      >
        <SHTypography variant="subtitle2">
          {`This Suitability Review was created by ${adviserName} of ${adviserFirmName} for ${familyGroupName}`}
        </SHTypography>
      </SHContainer>
      {showEditObjective && (
        <ChangeRichTextDialog
          title={"Edit Objectives"}
          initialContent={objectives}
          placeHolder={"Enter your objectives for this review"}
          onSubmit={(value) => {
            setShowEditObjective(false);
            setValue("objectives", value, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
          onClose={() => setShowEditObjective(false)}
        />
      )}
      {showEditRemark && (
        <ChangeRichTextDialog
          title={"Edit concluding remarks"}
          initialContent={concludingRemarks}
          placeHolder={"Enter concluding remarks for this review"}
          onSubmit={(value) => {
            setShowEditRemark(false);
            setValue("concludingRemarks", value, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
          onClose={() => setShowEditRemark(false)}
        />
      )}
    </SHStack>
  );
};
