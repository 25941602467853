import { SHButton } from "@components/design-systems";
import { DateFormat, TimeFormat } from "@constants/format";
import { useNotification } from "@hooks/useNotification";
import { RootState } from "@redux/store";
import { updateDraftState, updateEditMode } from "@redux/slices/configuration";
import { updateConfigurationNotifications } from "@redux/slices/top-notifications";
import { useAppDispatch } from "@redux/store";
import { format } from "date-fns";
import { useState } from "react";
import { useSelector } from "react-redux";

export interface SaveDraftConfigurationButtonProps {}

export const SaveDraftConfigurationButton = (
  props: SaveDraftConfigurationButtonProps,
) => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const {
    configurationUI: { isEditMode },
  } = useSelector((state: RootState) => state.configuration);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnSaveDraft = async () => {
    // @TODO: remove after integrate
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
      dispatch(updateEditMode(false));
      const lastModifiedDate = new Date();
      dispatch(
        updateDraftState({
          lastModifiedDate,
          lastUpdatedUserId: "0",
          lastUpdatedUserName: "Khan Tran",
        }),
      );
      dispatch(
        updateConfigurationNotifications([
          {
            title: "Draft in progress",
            message: `Last updated by Khan Tran at ${format(
              lastModifiedDate,
              TimeFormat,
            )} on ${format(lastModifiedDate, DateFormat)}.`,
          },
        ]),
      );
      notify("Changes are saved", {
        variant: "success",
        close: true,
      });
    }, 600);
  };

  return (
    <>
      {isEditMode && (
        <SHButton
          variant="outlined"
          size="extraMedium"
          isLoading={isSubmitting}
          /*   disabled={isSubmitting} */
          disabled
          onClick={handleOnSaveDraft}
        >
          Save draft
        </SHButton>
      )}
    </>
  );
};
