import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { RootState } from "@redux/store";
import { updateKickOut } from "@redux/slices/configuration";
import { useAppDispatch } from "@redux/store";
import { useSelector } from "react-redux";

export interface ConfigurationLockEditingKickedOutDialogProps {}

export const ConfigurationLockEditingKickedOutDialog = () => {
  const dispatch = useAppDispatch();
  const {
    configurationUI: { isKickOut, beingKickedBy },
  } = useSelector((state: RootState) => state.configuration);

  const handleOnClickClose = () => {
    dispatch(updateKickOut(false));
  };

  return (
    <>
      <SHDialog
        open={isKickOut || false}
        onClose={() => dispatch(updateKickOut(false))}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        width={600}
        header={
          <SHStack alignItems="center" textAlign={"center"} width={{ xs: 590 }}>
            <SHTypography variant="h2">
              You have been kicked out of editing the Configuration
            </SHTypography>
          </SHStack>
        }
        body={
          <SHStack>
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                width: 430,
              }}
            >
              {beingKickedBy} kicked you out.
            </SHTypography>
          </SHStack>
        }
        footer={
          <SHStack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={7}
          >
            <SHButton
              color="primary"
              variant="contained"
              onClick={handleOnClickClose}
              size="extraMedium"
            >
              Close
            </SHButton>
          </SHStack>
        }
      />
    </>
  );
};
