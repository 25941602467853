import { SHToggleButtonOptions } from "@components/design-systems";
import { ToggleButtonOptions } from "@components/design-systems/sh-toggle-button-options/model";
import { isNil } from "lodash";

export interface ShowHideButtonProps {
  isShow?: boolean;
  onChange?: (isShow: boolean) => void;
  options?: ToggleButtonOptions[];
  disabled?: boolean;
}
export const ShowHideButton = ({
  isShow = false,
  onChange,
  disabled,
  options = [
    {
      value: "true",
      label: "Show",
    },
    {
      value: "false",
      label: "Hide",
    },
  ],
}: ShowHideButtonProps) => {
  return (
    <SHToggleButtonOptions
      disabled={disabled}
      options={options}
      value={isShow?.toString()}
      onChange={(event, value) => {
        if (onChange && !isNil(value)) onChange(value === "true");
      }}
    />
  );
};
