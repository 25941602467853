import { FeeDTO } from "@models/platform-analysis/entities/steps/fee";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { Text, View } from "@react-pdf/renderer";
import { formatLargeNumber, thousandSeparator } from "@utils";

interface PDFLabelLineProjectionProps {
  firstProductFees: FeeDTO[];
}
export const PDFLabelLineProjection = ({
  firstProductFees,
}: PDFLabelLineProjectionProps) => {
  const { typographyStyle } = useStyles();
  return (
    <View
      style={{
        flexDirection: "row",
        marginBottom: 1,
        paddingLeft: 3,
      }}
    >
      {firstProductFees.map((fee, index) => (
        <View
          key={index}
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginHorizontal: 1.5,
            width: 450 / firstProductFees?.length,
          }}
        >
          <Text
            style={{
              ...typographyStyle?.body2,
              lineHeight: 1,
              textDecoration: fee.isCurrentPortfolio ? "underline" : undefined,
              fontWeight: fee.isCurrentPortfolio ? "bold" : "normal",
              fontSize: 7,
            }}
          >
            {fee.isCurrentPortfolio
              ? `$${thousandSeparator(+fee.avgTotalPortfolio.toFixed(0))}`
              : `$${formatLargeNumber(fee.avgTotalPortfolio)}`}
          </Text>
        </View>
      ))}
    </View>
  );
};
