import { UserType } from "@models/auth";

export const customSortOrderSupplierUser: {
  [key in UserType]?: number;
} = {
  [UserType.SupplierPremiumOwner]: 4,
  [UserType.SupplierPremiumStandard]: 3,
  [UserType.SupplierBdmSale]: 2,
  [UserType.SupplierBasic]: 1,
};

export const supplierUserTypeMapping: {
  [key in UserType]?: string;
} = {
  [UserType.SupplierBasic]: "Basic user",
  [UserType.SupplierBdmSale]: "Sales user",
  [UserType.SupplierPremiumOwner]: "Owner",
  [UserType.SupplierPremiumStandard]: "Standard user",
};
