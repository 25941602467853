import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { useTheme } from "@mui/material";
import {
  AVG_LIGHTNESS,
  DEFAULT_HUE,
  DEFAULT_SATURATION,
  MAX_LIGHTNESS,
  MIN_LIGHTNESS,
} from "@pages/platform-analysis/_id/steps/fee/analysis/components/heatmap/config";
import { PDFLabelLineDetail } from "@pages/platform-analysis/components/buttons/export-pdf/components/heatmap/components/label-line-detail";
import { PDFLabelLineProjection } from "@pages/platform-analysis/components/buttons/export-pdf/components/heatmap/components/label-line-projection";
import { PDFVerticalDashLine } from "@pages/platform-analysis/components/buttons/export-pdf/components/heatmap/components/vertical-dash-line";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import {
  displayCurrencyNumber,
  getFeeDetailMinMax,
  getFeeMinMax,
} from "@pages/platform-analysis/util";
import { Text, View } from "@react-pdf/renderer";
import {
  formatPercentage,
  getHSLLightness,
  hslToHex,
  thousandSeparator,
} from "@utils";
import { isNull } from "lodash";
import { TITLE_HEIGHT } from "../../../constant";
import { HorizontalLine } from "../../line";
import { PDFFeeSubProductCard } from "../../sub-product-card";

export interface PDFFeeAnalysisSectionProps {
  totalSubProductHidden: number;
  subProducts: FeeSubProductDTO[];
  totalPortfolioValue?: number;
  mode?: FeesDisplayStyle;
  heatmapMode?: FeeHeatmapMode;
  color?: {
    hue: number;
    saturation: number;
  };
  minLightness?: number;
  maxLightness?: number;
  avgLightness?: number;
  captions?: {
    dollar: string;
    percentage: string;
  };
  showWarningCaptions?: boolean;
  isContinue?: boolean;
  showFeeAnalysisText?: boolean;
  showFeeEstimatesText?: boolean;
  showAnnualPlatformFeesText?: boolean;
}

export const PDFFeeAnalysisSection = ({
  totalSubProductHidden,
  subProducts,
  totalPortfolioValue,
  mode = FeesDisplayStyle.Dollar,
  heatmapMode = FeeHeatmapMode.Projection,
  color = {
    hue: DEFAULT_HUE,
    saturation: DEFAULT_SATURATION,
  },
  minLightness = MIN_LIGHTNESS,
  maxLightness = MAX_LIGHTNESS,
  avgLightness = AVG_LIGHTNESS,
  captions = {
    dollar: "Total fees",
    percentage: "of total portfolio value",
  },
  showWarningCaptions,
  isContinue = false,
  showFeeAnalysisText = false,
  showFeeEstimatesText = false,
}: PDFFeeAnalysisSectionProps) => {
  const { palette } = useTheme();
  const { typographyStyle } = useStyles();
  const isDollarMode = mode === FeesDisplayStyle.Dollar;
  const isProjectionMode = heatmapMode === FeeHeatmapMode.Projection;

  const renderProjection = () => {
    const firstProductFees = subProducts[0]?.fees ?? [];
    const { min, max } = getFeeMinMax(
      isDollarMode ? "totalCostForDisplay" : "totalCostForDisplayPercentage",
      subProducts,
    );
    const contentWidth = 450;

    return (
      <View style={{ width: contentWidth }}>
        <PDFLabelLineProjection firstProductFees={firstProductFees} />
        <PDFVerticalDashLine colNumber={firstProductFees?.length} />
        <View style={{ flexDirection: "row" }}>
          <View style={{ justifyContent: "space-between" }}>
            {Array.from(Array(subProducts.length + 1).keys()).map((index) => (
              <View
                key={index}
                style={{
                  width: 3,
                  height: 0.5,
                  backgroundColor: palette.text.primary,
                  transform:
                    index === 0
                      ? "translateY(1.5px)"
                      : index === subProducts.length
                      ? "translateY(-1.5px)"
                      : undefined,
                }}
              />
            ))}
          </View>
          <View
            style={{
              gap: 1,
            }}
          >
            {subProducts.map((subProduct) => (
              <View style={{ flexDirection: "row" }}>
                {subProduct.fees.map((fee, index) => {
                  const lightness = getHSLLightness(
                    min,
                    max,
                    isDollarMode
                      ? fee.totalCostForDisplay
                      : fee.totalCostForDisplayPercentage,
                    minLightness,
                    maxLightness,
                  );
                  const isDarkCell = lightness < avgLightness;
                  return (
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        height: 30,
                        width: contentWidth / subProduct?.fees?.length,
                        backgroundColor: hslToHex(
                          color.hue,
                          color.saturation,
                          lightness,
                        ),
                        margin: "1.5px",
                      }}
                      key={index}
                    >
                      <Text
                        style={{
                          ...typographyStyle?.body2,
                          color: isDarkCell
                            ? palette.common.white
                            : palette.text.darker,
                          textAlign: "center",
                          fontSize: 7,
                        }}
                      >
                        {isDollarMode
                          ? `$${thousandSeparator(
                              +fee.totalCostForDisplay.toFixed(0),
                            )}`
                          : `${formatPercentage(
                              fee.totalCostForDisplayPercentage,
                            )}%`}
                      </Text>
                    </View>
                  );
                })}
              </View>
            ))}
          </View>
        </View>

        <PDFVerticalDashLine colNumber={firstProductFees?.length} />
        <PDFLabelLineProjection firstProductFees={firstProductFees} />

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: showWarningCaptions ? "flex-start" : "center",
            marginTop: 10,
          }}
        >
          <View style={{ gap: 7 }}>
            <View
              style={{
                flexDirection: "row",
                gap: 2,
                marginTop: "2px",
              }}
            >
              <Text style={{ ...typographyStyle?.body2 }}>
                {isDollarMode ? " $" : "%"}
              </Text>
              <Text style={{ ...typographyStyle?.body2, fontWeight: "bold" }}>
                {isDollarMode ? captions.dollar : captions.percentage}
              </Text>
            </View>

            {showWarningCaptions && (
              <View
                style={{
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    ...typographyStyle.body2,
                    color: palette.error.main,
                  }}
                >
                  * Product is missing some investment options, see web version
                  of this report for full details
                </Text>
              </View>
            )}
          </View>
          <View
            style={{
              backgroundColor: "#e0e2e5",
              width: 180,
              minWidth: 180,
              height: 10,
              alignItems: "center",
              justifyContent: "center",
              border: 0.5,
              borderColor: "#b1b6bf",
            }}
          >
            <Text style={{ ...typographyStyle.body2 }}>
              Average total portfolio value for the year
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const renderDetails = () => {
    if (!subProducts[0]?.feeDetails) return null;
    const firstProductFeeDetails = subProducts[0]?.feeDetails ?? [];
    const arrayMinMax = getFeeDetailMinMax(
      isDollarMode ? "dollar" : "percentage",
      subProducts,
    );
    const contentWidth = 450;

    return (
      <View style={{ width: contentWidth }}>
        <PDFLabelLineDetail firstProductFeeDetails={firstProductFeeDetails} />
        <PDFVerticalDashLine colNumber={firstProductFeeDetails?.length} />

        <View style={{ flexDirection: "row" }}>
          <View style={{ justifyContent: "space-between" }}>
            {Array.from(Array(subProducts.length + 1).keys()).map((index) => (
              <View
                key={index}
                style={{
                  width: 3,
                  height: 0.5,
                  backgroundColor: palette.text.primary,
                  transform:
                    index === 0
                      ? "translateY(1.5px)"
                      : index === subProducts.length
                      ? "translateY(-1.5px)"
                      : undefined,
                }}
              />
            ))}
          </View>
          <View
            style={{
              gap: 1,
            }}
          >
            {subProducts.map((subProduct) => (
              <View style={{ flexDirection: "row" }}>
                {subProduct.feeDetails.map((feeDetail, index) => {
                  const { min, max } = arrayMinMax[index] ?? {
                    min: 0,
                    max: 0,
                  };
                  const lightness = getHSLLightness(
                    min,
                    max,
                    isDollarMode
                      ? feeDetail.dollar || 0
                      : feeDetail.percentage || 0,
                    minLightness,
                    maxLightness,
                  );
                  const isDarkCell = lightness < avgLightness;
                  return (
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        height: 30,
                        width: contentWidth / subProduct?.feeDetails?.length,
                        backgroundColor: hslToHex(
                          color.hue,
                          color.saturation,
                          lightness,
                        ),
                        marginVertical: "1.5px",
                        marginRight: index === 0 ? "5px" : "1.5px",
                        marginLeft:
                          index === subProduct.feeDetails.length - 1
                            ? "5px"
                            : "1.5px",
                      }}
                      key={index}
                    >
                      <Text
                        style={{
                          ...typographyStyle?.body2,
                          color: isDarkCell
                            ? palette.common.white
                            : palette.text.darker,
                          textAlign: "center",
                          fontSize: 7,
                        }}
                      >
                        {isNull(feeDetail.percentage) ||
                        isNull(feeDetail.dollar)
                          ? "No data"
                          : isDollarMode
                          ? `$${thousandSeparator(
                              +feeDetail.dollar.toFixed(0),
                            )}`
                          : `${formatPercentage(feeDetail.percentage)}%`}
                      </Text>
                    </View>
                  );
                })}
              </View>
            ))}
          </View>
        </View>

        <PDFVerticalDashLine colNumber={firstProductFeeDetails?.length} />
        <PDFLabelLineDetail firstProductFeeDetails={firstProductFeeDetails} />

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: showWarningCaptions ? "flex-start" : "center",
            marginTop: 10,
          }}
        >
          <View style={{ gap: 7 }}>
            <View
              style={{
                flexDirection: "row",
                gap: 2,
                marginTop: "2px",
              }}
            >
              <Text style={{ ...typographyStyle?.body2 }}>
                {isDollarMode ? " $" : "%"}
              </Text>
              <Text style={{ ...typographyStyle?.body2, fontWeight: "bold" }}>
                {`Fees for a total balance of ${displayCurrencyNumber(
                  totalPortfolioValue ?? 0,
                )}`}
              </Text>
            </View>

            {showWarningCaptions && (
              <View
                style={{
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    ...typographyStyle.body2,
                    color: palette.error.main,
                  }}
                >
                  * Product is missing some investment options, see web version
                  of this report for full details
                </Text>
              </View>
            )}
          </View>
          <View
            style={{
              backgroundColor: "#e0e2e5",
              width: 180,
              minWidth: 180,
              height: 10,
              alignItems: "center",
              justifyContent: "center",
              border: 0.5,
              borderColor: "#b1b6bf",
            }}
          >
            <Text style={{ ...typographyStyle.body2 }}>
              Average total portfolio value for the year
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const hasData =
    subProducts.length &&
    (isProjectionMode
      ? subProducts[0].fees?.length
      : subProducts[0].feeDetails?.length);

  let titleHeight = TITLE_HEIGHT;
  if (showFeeAnalysisText) {
    titleHeight += TITLE_HEIGHT;
    if (!isProjectionMode && !showFeeEstimatesText) {
      titleHeight -= 15;
    }
  }
  if (isProjectionMode) titleHeight -= 5;
  if (subProducts[0]?.feeDetails.length > 4 && !isProjectionMode)
    titleHeight += 5;

  return (
    <View
      style={{
        gap: 25,
        // border: `1px solid ${palette.secondary[100]}`,
        width: "100%",
      }}
    >
      <View
        style={{
          gap: `${showFeeEstimatesText} ? "5px" : "0px"`,
          height: titleHeight,
        }}
      >
        {showFeeAnalysisText && (
          <View>
            <Text
              style={{
                ...typographyStyle.title,
                color: palette.common.black,
              }}
            >
              Fee analysis (continued)
            </Text>
            <HorizontalLine color={palette.common.black} />
          </View>
        )}
        {showFeeEstimatesText && (
          <Text
            style={{
              ...typographyStyle.subtitle,
              color: palette.common.black,
              marginBottom: "2px",
            }}
          >
            Fee estimates
          </Text>
        )}

        <Text
          style={{
            ...typographyStyle.subtitle,
            color: palette.common.black,
          }}
        >
          {`Annual platform fees by portfolio value (estimates): ${
            isProjectionMode ? "Projection" : "Detailed view"
          }${isContinue ? " Continued" : ""}`}
        </Text>
      </View>

      {hasData ? (
        <View
          style={{
            gap: 5,
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <View
            style={{
              gap: 4,
              flexDirection: "column",
              marginTop: isProjectionMode ? 13 : 30,
            }}
          >
            {subProducts.map((subProduct) => (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 5,
                  height: 30,
                }}
              >
                <PDFFeeSubProductCard isActive subProduct={subProduct} />
              </View>
            ))}
            <View
              style={{
                height: 10,
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: showWarningCaptions
                  ? !isProjectionMode
                    ? "52.5px"
                    : "35.5px"
                  : !isProjectionMode
                  ? "38px"
                  : "20px",
              }}
            >
              <Text
                style={{
                  ...typographyStyle.body2,
                  color: "#1E1970",
                  fontWeight: "bold",
                }}
              >
                {totalSubProductHidden > 1
                  ? `${totalSubProductHidden} products hidden`
                  : totalSubProductHidden === 1
                  ? `${totalSubProductHidden} product hidden`
                  : ""}
              </Text>
            </View>
          </View>
          {isProjectionMode ? renderProjection() : renderDetails()}
        </View>
      ) : (
        <View style={{ gap: "5px" }}>
          <Text
            style={{
              ...typographyStyle.body1,
              fontWeight: "bold",
              color: "#b1b6bf",
            }}
          >
            No data
          </Text>
          <Text style={{ ...typographyStyle.body1, color: "#b1b6bf" }}>
            No products match the criteria in your filter
          </Text>
          <Text
            style={{
              ...typographyStyle.body2,
              color: "#1E1970",
              fontWeight: "bold",
              marginTop: "25px",
            }}
          >
            {totalSubProductHidden > 1
              ? `${totalSubProductHidden} products hidden`
              : totalSubProductHidden === 1
              ? `${totalSubProductHidden} product hidden`
              : ""}
          </Text>
        </View>
      )}
    </View>
  );
};
