import { SHDivider, SHStack, SHTypography } from "@components/design-systems";
import { useProduct } from "@hooks/useProduct";
import { ProductFeaturesLayout } from "@layouts/products/features";
import { SubProductList } from "@layouts/products/fees-and-rates/sub-product-list";
import { useTheme } from "@mui/material";

import { RootState } from "@redux/store";
import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { FeeAndRateTabSkeleton } from "../../../edit/components/fees-and-rates/components/skeletons/FeeAndRateTabSekeleton";
import { FRViewFeesTable } from "./components/tables";

export const ProductViewFeesAndRates = () => {
  const { palette } = useTheme();
  const { productId } = useParams<{ productId: string }>();
  const { loadSubProductsFees } = useProduct();
  const {
    subProducts,
    productUI: { productName, isLoadingSubProducts },
  } = useSelector((state: RootState) => state.product);

  const [selectedSubProductIndex, setSelectedSubProductIndex] =
    useState<number>(0);

  useEffect(() => {
    if (!productId) return;
    loadSubProductsFees(productId, "view");
    // eslint-disable-next-line
  }, [productId]);

  if (isLoadingSubProducts) return <FeeAndRateTabSkeleton />;

  return isEmpty(subProducts) ? (
    <></>
  ) : (
    <ProductFeaturesLayout
      leftSide={
        <SubProductList
          subProducts={subProducts}
          selectedSubProductIndex={selectedSubProductIndex}
          onChangeSubProduct={setSelectedSubProductIndex}
        />
      }
      rightSide={
        <SHStack spacing={2}>
          {/* Title */}
          <SHStack>
            <SHTypography
              variant="h2"
              sx={{ fontSize: "24px", fontWeight: 600 }}
            >
              {productName} {subProducts?.[selectedSubProductIndex]?.name}
            </SHTypography>
            <SHTypography variant="body4" sx={{ color: palette.text.third }}>
              Fee schedule
            </SHTypography>
          </SHStack>
          <SHDivider />
          {/* Standard Fees Table */}
          {subProducts?.[selectedSubProductIndex]?.standardFeeSections?.map(
            (fee) => (
              <FRViewFeesTable fee={fee} key={fee?.id} />
            ),
          )}

          {/* Other Fees Table */}
          {!isNil(subProducts?.[selectedSubProductIndex]?.otherFeeSections) && (
            <>
              <SHTypography
                variant={"subtitle2"}
                textAlign="left"
                lineHeight={"160%"}
              >
                Other product fees and information
              </SHTypography>
              {subProducts?.[selectedSubProductIndex]?.otherFeeSections?.map(
                (fee) => (
                  <FRViewFeesTable fee={fee} key={fee?.id} isOtherFee />
                ),
              )}
            </>
          )}
        </SHStack>
      }
      isLoading={false}
    />
  );
};
