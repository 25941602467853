import {
  SHButton,
  SHCheckbox,
  SHDialog,
  SHFormControlLabel,
  SHIconLoading,
  SHRichTextEditor,
  SHStack,
  SHTextField,
  SHTypography,
  SHUploadImage,
} from "@components/design-systems";
import { theme } from "@themes";
import { DialogContent, DialogTitle, useTheme } from "@mui/material";
import { CloseButton } from "@components/buttons/close";
import { Controller, useForm, useFormState } from "react-hook-form";
import { S3ObjectDTO } from "@models/core";
import { SHDatePicker } from "@components/design-systems/sh-date-picker";
import { useEffect, useState } from "react";
import { cloneDeep, isEmpty, isNull, orderBy } from "lodash";
import { FileRejection } from "react-dropzone";
import { renameFile } from "@utils/data-type/file";
import {
  deleteAvatar,
  uploadAvatar,
} from "@services/file-storage/fileStorageService";
import { useNotification } from "@hooks/useNotification";
import { ImageAutoComplete } from "@components/auto-completes/image";
import { hexToRGBA, nameOfFactory, toDateType } from "@utils";
import {
  NewsFeedProductDTO,
  PostDetailDTO,
} from "@models/news-and-updates/entities/post";
import { uploadOptions } from "@components/design-systems/sh-upload-file/config";
import { useUserPermissions } from "@hooks/userUserPermission";
import UnsavedDialog from "@components/dialogs/unsaved";
import CustomUnsavedDialog from "@components/dialogs/custom-unsaved";
import { createPost, updatePost } from "@services/newsfeed/postService";
import { ImagesCarousel } from "../../images-carousel";

export interface PostDetailDialogProps {
  isDisplay: boolean;
  dialogTitle: string;
  buttonLabel: string;
  productData: NewsFeedProductDTO[];
  isNew?: boolean;
  initialValues?: PostDetailDTO;
  onClose: () => void;
  onSubmit: (formData?: PostDetailDTO) => void;
}
export const PostDetailDialog = (props: PostDetailDialogProps) => {
  const {
    isDisplay,
    dialogTitle,
    buttonLabel,
    isNew = true,
    initialValues = new PostDetailDTO(),
    productData,
    onClose,
    onSubmit,
  } = props;
  const { palette } = useTheme();
  const { notify } = useNotification();
  const nameOf = nameOfFactory<NewsFeedProductDTO>();
  const { isSuperAdmin } = useUserPermissions();
  const [isUpdating, setIsUpdating] = useState(false);
  const [images, setImages] = useState<S3ObjectDTO[]>([]);
  const [imageKeysToBeDeleted, setImageKeysToBeDeleted] = useState<string[]>(
    [],
  );
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState<string | null>(null);
  const [openCustomUnsavedDialog, setOpenCustomUnsavedDialog] =
    useState<boolean>(false);
  const [haveApproval, setApproval] = useState<boolean>(false);

  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors, isValid },
  } = useForm<PostDetailDTO>({
    mode: "onChange",
    defaultValues: {
      ...initialValues,
    },
  });

  const { isDirty } = useFormState({ control });
  const isDisabled = isSubmitting || isUpdating;
  const handleSubmitPost = async (formData: PostDetailDTO) => {
    if (isNew) {
      onCreatePost(formData);
    } else {
      onUpdatePost(formData);
    }
  };

  const onCreatePost = async (formData: PostDetailDTO) => {
    const payload: PostDetailDTO = {
      ...formData,
      productId: formData?.product.id,
      publishDate: formData?.publishedDate,
    };
    setIsUpdating(true);
    const { isSuccess, message } = await createPost(payload);
    onSubmit();
    setIsUpdating(false);
    onClose();
    if (!isSuccess) {
      notify(message, {
        variant: "error",
        close: true,
      });

      return;
    }
    notify(
      isSuperAdmin
        ? "Post created and published."
        : "Your post has been submitted for review.",
      {
        variant: "success",
        close: true,
      },
    );
  };

  const onUpdatePost = async (formData: PostDetailDTO) => {
    setIsUpdating(true);
    //Update post
    const { isSuccess, message } = await updatePost({
      ...formData,
      publishDate: formData?.publishedDate,
    });
    //Delete images in S3 store (if any)
    await handleDeleteImagesInS3Store();
    onSubmit();
    setIsUpdating(false);
    onClose();
    if (!isSuccess) {
      notify(message, {
        variant: "error",
        close: true,
      });

      return;
    }
    notify(
      isSuperAdmin
        ? "Post published."
        : "Your post has been submitted for review.",
      {
        variant: "success",
        close: true,
      },
    );
  };

  const handleOnUpload = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    setUploadMessage(
      !isEmpty(fileRejections) ? "Unsupported file format." : null,
    );
    if (isEmpty(acceptedFiles)) return;

    setIsUploading(true);
    let apis: any[] = [];
    acceptedFiles.forEach((file) => {
      const form = new FormData();
      form.append("file", renameFile(file));
      apis.push(uploadAvatar(form));
    });
    const responses = await Promise.all(apis);
    const newAttachments = getValues("attachments") ?? [];
    const newImages = cloneDeep(images);
    responses.forEach(({ data, message }) => {
      if (data) {
        newAttachments.push(data);
        newImages.push(data);
      } else {
        notify(message, {
          variant: "error",
          close: true,
        });
      }
    });
    setValue("attachments", newAttachments, uploadOptions.hookForm);
    setImages(newImages);

    setIsUploading(false);
  };

  const handleOnDelete = async (s3Key: string) => {
    if (!s3Key) return;
    if (isNew) {
      const { isSuccess, message } = await deleteAvatar(s3Key);
      if (isSuccess) {
        const newValue = images.filter((img) => img.key !== s3Key);
        const attachments = getValues("attachments");
        const newAttachments = attachments.filter((img) => img.key !== s3Key);
        setValue(
          "attachments",
          [...(newAttachments ?? [])],
          uploadOptions.hookForm,
        );
        setImages(newValue);
      } else {
        notify(message, {
          variant: "error",
          close: true,
        });
      }
      return;
    }

    setValue(
      "attachments",
      cloneDeep(images).filter((img) => img.key !== s3Key),
      uploadOptions.hookForm,
    );
    setImages(cloneDeep(images).filter((img) => img.key !== s3Key));

    let newDeletedKeys = cloneDeep(imageKeysToBeDeleted);
    newDeletedKeys.push(s3Key);
    setImageKeysToBeDeleted(newDeletedKeys);
  };

  const handleDeleteImagesInS3Store = async () => {
    for (const s3Key of imageKeysToBeDeleted) {
      const { isSuccess } = await deleteAvatar(s3Key);
      if (isSuccess) {
        const attachments = getValues("attachments");
        const newAttachments = attachments.filter((img) => img.key !== s3Key);
        setValue(
          "attachments",
          [...(newAttachments ?? [])],
          uploadOptions.hookForm,
        );
      }
    }
  };

  useEffect(() => {
    resetFormData();
    // eslint-disable-next-line
  }, [isDisplay]);

  const resetFormData = () => {
    setImages(initialValues?.attachments);
    setUploadMessage("");
    reset({
      ...initialValues, //If the post does not have publishDate, we will use lastUpdatedDate
      publishedDate: initialValues.publishedDate
        ? initialValues.publishedDate
        : initialValues.lastUpdatedDate ?? new Date(),
    });
    setApproval(false);
  };

  const onDiscard = () => {
    setOpenCustomUnsavedDialog(false);
    onClose();
  };
  const onKeepEditing = () => {
    setOpenCustomUnsavedDialog(false);
  };

  return (
    <form>
      <SHDialog
        open={isDisplay}
        maxWidth={false}
        sx={{
          "& .MuiPaper-root": {
            width: "642px",
            height: "auto",
            minHeight: "654px",
            padding: { md: "20px 60px", lg: "30px 60px" },
            position: "relative",
          },
        }}
      >
        <DialogTitle
          borderBottom={`1px solid ${theme.palette.secondary[100]}`}
          padding={"0 !important"}
        >
          <SHTypography
            variant="h2"
            fontWeight={600}
            lineHeight={"100%"}
            sx={{
              paddingBottom: "10px",
            }}
          >
            {dialogTitle}
          </SHTypography>
        </DialogTitle>
        <SHStack
          sx={{
            position: "absolute",
            top: "22px",
            right: "25px",
          }}
        >
          <CloseButton
            width="12px"
            height="12px"
            onClick={() => {
              if (isDirty) {
                setOpenCustomUnsavedDialog(true);
                return;
              }
              onClose();
            }}
          />
        </SHStack>
        <DialogContent
          sx={{
            marginTop: { md: "5px", lg: "10px" },
            padding: 0,
            overflowY: "var(--overflow-overlay)",
          }}
        >
          <SHStack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Controller
              render={({ field }) => (
                <SHStack width={"230px"}>
                  <ImageAutoComplete
                    disabled={!isNew || isDisabled}
                    textFieldProps={{
                      label: "Platform",
                      placeholder: "Select a platform",
                      required: isNew,
                      error: !!errors.product?.id,
                      helperText: errors.product?.id
                        ? errors.product?.id?.message
                        : null,
                    }}
                    value={{
                      id: watch("product.id"),
                      name: watch("product.name"),
                      logo: watch("product.logo"),
                    }}
                    onChange={(_event, newValue) => {
                      field.onChange(newValue?.id);
                      setValue("productId", newValue?.id ?? "", {
                        shouldDirty: true,
                      });
                      setValue("product.name", newValue?.name ?? "", {
                        shouldDirty: true,
                      });
                      setValue("product.logo", newValue?.logo ?? "", {
                        shouldDirty: true,
                      });
                    }}
                    loadingText={
                      <SHStack
                        alignItems={"center"}
                        direction="row"
                        spacing={1}
                      >
                        <SHIconLoading />
                        <SHTypography>Looking for platforms</SHTypography>
                      </SHStack>
                    }
                    labelField={nameOf("name")}
                    imageField={nameOf("logo")}
                    options={productData}
                  />
                </SHStack>
              )}
              name="product.id"
              control={control}
              rules={{ required: "Platform is required" }}
            />

            {isSuperAdmin && (
              <Controller
                name={"publishedDate"}
                control={control}
                rules={{
                  validate: (value) => {
                    const dateValue = toDateType(value);
                    const startDate = new Date("1/1/2022");
                    const today = new Date();
                    if (isNull(dateValue)) {
                      return "Date is required";
                    }
                    if (!(dateValue >= startDate && dateValue <= today)) {
                      return "Invalid date";
                    }
                  },
                }}
                render={({ field }) => (
                  <SHDatePicker
                    disabled={isDisabled}
                    label="Date"
                    maxDate={new Date()}
                    minDate={new Date("1/1/2022")}
                    value={field.value}
                    sxTextField={{
                      "&>.MuiInput-root>input": {
                        padding: "8px 5px 5px 5px",
                      },
                      width: "230px",
                    }}
                    onChange={(newValue: Date | null) => {
                      field.onChange(newValue);
                    }}
                    textFieldProps={{
                      error: !!errors.publishedDate,
                      required: isNew,
                      helperText: errors.publishedDate
                        ? errors.publishedDate.message
                        : null,
                    }}
                  />
                )}
              />
            )}
          </SHStack>
          <Controller
            name="title"
            control={control}
            rules={{ required: "Title is required" }}
            render={({ field }) => (
              <SHStack
                width={"100%"}
                sx={{
                  marginTop: { md: "5px", lg: "10px" },
                }}
              >
                <SHTextField
                  {...field}
                  required
                  maxLength={100}
                  sx={{ width: { xs: "100%", md: "100%" } }}
                  label="Title"
                  error={!!errors.title}
                  helperText={errors.title ? errors.title.message : null}
                  placeholder={"Enter the title of the post"}
                  disabled={isDisabled}
                />
              </SHStack>
            )}
          />
          <Controller
            render={({ field }) => (
              <SHStack
                width={"100%"}
                sx={{ marginTop: { md: "5px", lg: "10px" } }}
              >
                <SHRichTextEditor
                  {...field}
                  required
                  maxLength={3000}
                  placeHolder="What do you want to post?"
                  label="Content"
                  disabled={isDisabled}
                  textFieldProps={{
                    error: !!errors.content,
                    helperText: errors.content ? errors.content.message : null,
                  }}
                  sx={{
                    width: "100%",
                    marginTop: "2px",
                    height: {
                      md: !isSuperAdmin
                        ? "135px !important"
                        : "165px !important",
                      lg: "165px !important",
                    },
                  }}
                />
              </SHStack>
            )}
            name="content"
            control={control}
            rules={{ required: "Content is required" }}
          />
          <SHStack
            width={"100%"}
            spacing={1}
            direction={"row"}
            sx={{ marginTop: { md: "10.5px", lg: "21px" } }}
          >
            <SHUploadImage
              width={250}
              buttonText="Click to add"
              objectFit="contain"
              isUploading={isUploading}
              error={!!uploadMessage}
              helperText={uploadMessage}
              isCircular={false}
              dropzoneOptions={{
                multiple: true,
                accept: {
                  "image/*": [".svg", ".png", ".jpg", ".gif"],
                },
                onDrop: handleOnUpload,
                disabled: isUploading || isDisabled,
              }}
              showImage={false}
            />
            <SHStack flex={1}>
              {isEmpty(images) ? (
                <SHStack
                  alignItems="center"
                  justifyContent={"center"}
                  sx={{
                    width: "100%",
                    height: "120px",
                    padding: "10px",
                    backdropFilter: "blur(0.5px)",
                    background: `${hexToRGBA("#c5ddfd", 0.1)}`,
                    border: `1px solid ${palette.secondary[100]}`,
                  }}
                >
                  <SHTypography variant="body4">No images.</SHTypography>
                </SHStack>
              ) : (
                <Controller
                  name="attachments"
                  control={control}
                  render={() => (
                    <SHStack width={"262px"}>
                      <ImagesCarousel
                        images={orderBy(
                          images ?? [],
                          [(image) => image.key.toLocaleLowerCase()],
                          "asc",
                        )}
                        slideHeightResponsive={{ sm: 72, md: 72, lg: 72 }}
                        containerPadding="5px 5px 0 5px"
                        footerPadding="5px 0"
                        handleOnDelete={handleOnDelete}
                      />
                    </SHStack>
                  )}
                />
              )}
            </SHStack>
          </SHStack>
          {!isSuperAdmin && (
            <SHStack
              marginTop={{ md: "5px", lg: "10px" }}
              marginX={"12px"}
              alignItems={"flex-start"}
            >
              <SHFormControlLabel
                sx={{ pointerEvents: "none" }}
                control={
                  <SHCheckbox
                    name="haveApproval"
                    sx={{
                      pointerEvents: "auto",
                      paddingLeft: "9px",
                    }}
                    onChange={(_event, value) => {
                      setApproval(value);
                    }}
                  />
                }
                componentsProps={{
                  typography: { align: "left" },
                }}
                label={
                  <SHTypography
                    variant="subtitle2"
                    colorVariant="third"
                    sx={{
                      cursor: "pointer",
                      pointerEvents: "auto",
                    }}
                  >
                    I have approval to make this post
                  </SHTypography>
                }
              />
            </SHStack>
          )}
          <SHButton
            variant="contained"
            size="extraMedium"
            type="submit"
            disabled={
              !isValid ||
              isUploading ||
              isDisabled ||
              !isDirty ||
              (!isSuperAdmin && !haveApproval)
            }
            isLoading={isDisabled}
            sx={{
              marginTop: { md: "10px", lg: "20px" },
              height: "50px",
              width: "410px",
            }}
            onClick={handleSubmit(handleSubmitPost)}
          >
            {buttonLabel}
          </SHButton>
        </DialogContent>
      </SHDialog>
      <CustomUnsavedDialog
        open={openCustomUnsavedDialog}
        onConfirm={onDiscard}
        onCancel={onKeepEditing}
      />
      <UnsavedDialog isDirty={isDirty && !isSubmitting} />
    </form>
  );
};
