import { SHBox, SHBoxProps, SHTypography } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";

interface ChartLegendBoxProps extends SHBoxProps {
  direction?: "vertical" | "horizontal";
}
export const ChartLegendBox = ({
  children,
  direction,
  sx,
  ...other
}: ChartLegendBoxProps) => {
  const { palette } = useTheme();

  return (
    <SHBox
      {...other}
      width={direction === "vertical" ? "21px" : "auto"}
      sx={{
        border: `1px solid ${palette.secondary[100]}`,
        backgroundColor: hexToRGBA(palette.secondary[100], 0.25),
        backdropFilter: "blur(0.5px)",
        p: direction === "vertical" ? "12px 3px" : "3px 12px",
        ...sx,
      }}
    >
      <SHTypography
        variant="body4"
        lineHeight={1}
        colorVariant={"disabled"}
        sx={{
          writingMode: direction === "vertical" ? "vertical-lr" : undefined,
          transform: direction === "vertical" ? "rotate(-180deg)" : undefined,
        }}
      >
        {children}
      </SHTypography>
    </SHBox>
  );
};
