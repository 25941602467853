export const generateFeatureName = (
    featureName: string,
    featureDescription?: string,
  ) => {
    if (!featureDescription) {
      return {
        first: featureName,
        last: "",
      };
    }
    const words = featureName.trim().split(/\s+/);
    const lastWord = words[words.length - 1];
    words.pop();
    const textRemoveLastWord = words.join(" ") + " ";
  
    return {
      first: textRemoveLastWord,
      last: lastWord,
    };
  };