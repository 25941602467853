import { SelectFamilyGroupMembers } from "@components/auto-completes/family-members";
import { ImageAutoComplete } from "@components/auto-completes/image";
import { InformationButton } from "@components/buttons/information";
import {
  SHAutocomplete,
  SHBooleanField,
  SHBox,
  SHContainer,
  SHDivider,
  SHIconLoading,
  SHRadio,
  SHRichTextEditor,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import { CheckSVG, CloseSVG } from "@components/svgs";
import { PageRoutes } from "@constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFeatureTemplate } from "@hooks/useFeatureTemplate";
import { useIsNew } from "@hooks/useIsNew";
import { useReview } from "@hooks/useReview";
import { useUserPermissions } from "@hooks/userUserPermission";
import { FieldGroup } from "@layouts/form/field-group";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { FamilyGroupDTO } from "@models/family-group/entities/familyGroup";
import { FeatureTemplateSelectionDTO } from "@models/feature-template/entities/featureTemplate";
import {
  ReviewStepSetupDTO,
  SelectedFamilyMemberDTO,
} from "@models/reviews/entities/steps/setup";
import { ReviewStep } from "@models/reviews/enums/step";
import { UserLOV } from "@models/users/entities/user";
import { AnalysisSetupSkeleton } from "@pages/platform-analysis/_id/steps/setup/skeleton";
import { CreateFamilyGroupDialog } from "@pages/reviews/components/dialogs/create-new-family-group";
import { SelectRateCardsForReview } from "@pages/reviews/components/selected-rate-cards";
import { repairUpdateReview } from "@redux/slices/review/util";
import { RootState } from "@redux/store";
import { checkReviewUniqueName } from "@services/reviews/reviewService";
import { forEach } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { stepSetupValidators } from "./util";

export interface ReviewSetupProps extends StepCompProps {}
export const ReviewSetup: ForwardRefRenderFunction<
  StepCompRef,
  ReviewSetupProps
> = ({ stepNumber }, ref) => {
  const navigate = useNavigate();
  const isNew = useIsNew();
  const { reviewId } = useParams<{ reviewId: string }>();

  const {
    ui: { isLoading, isSubmitting },
    review,
    isReadOnly,
  } = useSelector((state: RootState) => state.review);

  const { isAdviserTrialGroup, isAdviserSupportStaffGroup } =
    useUserPermissions();

  const {
    setIsDirty,
    setIsValid,
    setBannerInfo,
    setReview,
    loadAdviserUsersForReview,
    loadFamilyGroupsForReview,
    loadFamilyMembersForReview,
    createNewReview,
    updateCurrentReview,
    loadReview,
  } = useReview();

  const { loadExistingFeatureTemplateForReview } = useFeatureTemplate();

  const [adviserData, setAdviserData] = useState<UserLOV[]>();
  const [familyGroupData, setFamilyGroupData] = useState<FamilyGroupDTO[]>();
  const [familyGroupMemberData, setFamilyGroupMemberData] =
    useState<SelectedFamilyMemberDTO[]>();

  const [featureTemplates, setFeatureTemplates] = useState<
    FeatureTemplateSelectionDTO[]
  >([]);

  const isDisabled = isSubmitting || isReadOnly;

  const timer = useRef<NodeJS.Timeout | null>(null);

  const [verifyReviewName, setVerifyReviewName] = useState<
    "error" | "success" | "checking" | "none"
  >(isNew ? "none" : "success");

  const { user } = useSelector((state: RootState) => state.auth);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const {
    getValues,
    watch,
    control,
    setValue,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...new ReviewStepSetupDTO() },
    resolver: yupResolver(stepSetupValidators),
  });

  const [verifyReviewNameErrorMessage, setVerifyReviewNameErrorMessage] =
    useState("");

  const getAdvisers = async () => {
    const adviser = await loadAdviserUsersForReview(
      isNew ? "new" : reviewId ?? "new",
    );
    setAdviserData(adviser);
  };

  const getFamilyGroups = async (ownerId?: string) => {
    const familyGroup = await loadFamilyGroupsForReview(
      isNew ? "new" : reviewId ?? "new",
      watch("ownerId"),
    );
    setFamilyGroupData(familyGroup);
  };

  const getFamilyMembers = async (refreshData: boolean) => {
    const familyGroupMember = await loadFamilyMembersForReview(
      isNew || refreshData ? "new" : reviewId ?? "",
      watch("familyGroupId"),
    );
    setFamilyGroupMemberData(familyGroupMember);
  };

  const getFeatureTemplates = async () => {
    const featureTemplates = await loadExistingFeatureTemplateForReview();

    setFeatureTemplates(featureTemplates ?? []);
  };

  const getReview = async () => {
    if (!isNew && reviewId) {
      let review = await loadReview({
        reviewId: reviewId,
        stepId: ReviewStep.Setup,
      });

      if (review && review.setup) {
        reset(review.setup);
        getFamilyGroups();
        getFamilyMembers(false);
        const ids = convertFamilyGroupMemberIds(
          review.setup.selectedFamilyMembers,
        );
        setValue("selectedFamilyMemberIds", ids);
      }
    }
  };

  useEffectOnce(() => {
    if (!isNew && reviewId) {
      getReview();
    }

    getAdvisers();
    getFeatureTemplates();
    return () => {
      setIsValid(true);
      setIsDirty(false);
    };
  });

  useEffect(() => {
    setIsValid(isValid);
    // eslint-disable-next-line
  }, [isValid]);

  useEffect(() => {
    setIsDirty(isDirty);
    // eslint-disable-next-line
  }, [isDirty]);

  useEffect(() => {
    if (!getValues("ownerId")) return;
    getFamilyGroups();
    // eslint-disable-next-line
  }, [watch("ownerId")]);

  useEffect(() => {
    if (!isNew) return;
    if (isAdviserSupportStaffGroup) return;
    setValue("ownerId", user?.auth0Id ?? "");
    setValue("ownerName", user?.name ?? "");
    setValue("ownerAvatarUrl", user?.avatarUrl ?? "");
    setValue("ownerType", user?.userType ?? "");
    setBannerInfo({
      ownerName: user?.name,
    });
    // eslint-disable-next-line
  }, [isNew, user?.name, user?.auth0Id, user?.avatarUrl]);

  useEffect(() => {
    setBannerInfo({
      ownerName: watch("ownerName"),
      familyGroupName: watch("familyGroupName"),
    });
    // eslint-disable-next-line
  }, [watch("ownerName"), watch("familyGroupName")]);

  const handleOnSubmit = async () => {
    updateFamilyGroupMembers();
    setValue("selectedFamilyMembers", familyGroupMemberData ?? []);
    setValue("adviserFirmId", user?.userMetadata?.adviser_firm_id ?? "");
    const newValues = getValues();
    let isSuccess = true;
    if (isNew) {
      setIsDirty(false);
      const newReview = await createNewReview(newValues);
      isSuccess = !!newReview;
      setIsDirty(!isSuccess);
      if (isSuccess) {
        reset(newValues);
        navigate(
          generatePath(PageRoutes.reviewsDetail, {
            reviewId: newReview?.id,
          }),
          {
            replace: true,
          },
        );
      }
      return isSuccess;
    }
    if (!isDirty) return true;
    if (!review) return false;
    let response = await updateCurrentReview({
      ...repairUpdateReview(review),
      currentStep: ReviewStep.Setup,
      setup: newValues,
    });
    if (!!response) {
      reset(newValues);
      setReview({
        ...review,
        setup: newValues,
        configurationVersion: response
          ? response.configurationVersion
          : review.configurationVersion,
      });
      return true;
    }
    return false;
  };

  const updateFamilyGroupMembers = () => {
    const familyGroupMemberIds = getValues("selectedFamilyMemberIds");
    familyGroupMemberData?.map((member) => {
      return familyGroupMemberIds?.includes(member.order?.toString() ?? "")
        ? (member.isSelected = true)
        : (member.isSelected = false);
    });
  };

  const convertFamilyGroupMemberIds = (
    familyMembers: SelectedFamilyMemberDTO[],
  ) => {
    let ids: string[] = [];
    forEach(familyMembers, (value) => {
      if (value.isSelected) {
        ids.push(value.order?.toString() ?? "");
      }
    });
    return ids;
  };

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep) => {
      return await handleOnSubmit();
    },
    onSubmit: async () => {
      return await handleOnSubmit();
    },
    onChangeOtherStep: async () => {
      return true;
    },
  }));

  useEffect(() => {
    setIsValid(isValid && verifyReviewName === "success");
    // eslint-disable-next-line
  }, [isValid, verifyReviewName]);

  const clearTimer = () => {
    if (timer.current) clearTimeout(timer.current);
  };

  const handleOnChecking = (name: string | null) => {
    clearTimer();
    if (!name || name.trim().length === 0) {
      setVerifyReviewName("none");
      return;
    }
    if (
      review?.setup?.name === name &&
      getValues("ownerId") === review?.setup?.ownerId
    ) {
      setVerifyReviewName("success");
      return;
    }
    setVerifyReviewName("checking");
    timer.current = setTimeout(async () => {
      const { isSuccess, message } = await checkReviewUniqueName(
        isNew ? "" : "",
        user?.userMetadata?.adviser_firm_id ?? "",
        getValues("ownerId") ?? "",
        name ?? "",
      );
      if (isSuccess) {
        setVerifyReviewName("success");
      } else {
        setVerifyReviewName("error");
        setVerifyReviewNameErrorMessage(message!);
      }
    }, 300);
  };

  const renderVerifyReviewName = () => {
    switch (verifyReviewName) {
      case "success":
        return <CheckSVG width={18} height={18} />;
      case "error":
        return (
          <SHBox px={"4px"} py={"1px"}>
            <CloseSVG width={10} height={10} />
          </SHBox>
        );
      case "checking":
        return <SHIconLoading size={16} />;
      default:
        return null;
    }
  };

  if (isAdviserTrialGroup && isNew) navigate(generatePath(PageRoutes.reviews));

  if (!isNew && isLoading) return <AnalysisSetupSkeleton />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 }, py: "25px" }}>
      <form>
        <SHStack>
          <StepName name={"Review setup"} stepNumber={stepNumber} />
          <FieldGroup
            title="Review details"
            subTitle={
              "Give a name to this review and assign an adviser and client to it. " +
              "This will help you find this review in the future. " +
              "Clients are linked to advisers, and if you cannot find one in the dropdown then try changing the adviser"
            }
          >
            <SHStack direction="column" sx={{ width: { xs: "100%", md: 520 } }}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <SHTextField
                    {...field}
                    hasCounter
                    maxLength={90}
                    disabled={isDisabled}
                    required
                    sx={{ width: "100%", marginBottom: "15px" }}
                    label={"Name of review"}
                    placeholder={"Enter a unique name"}
                    error={!!errors.name}
                    helperText={
                      errors?.name?.message ??
                      (verifyReviewName === "error"
                        ? verifyReviewNameErrorMessage
                        : null)
                    }
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      handleOnChecking(event.target.value);
                    }}
                    InputProps={{
                      sx: {
                        "&>input": {
                          pr: "32px !important",
                        },
                      },
                      endAdornment: (
                        <SHStack
                          sx={{
                            position: "absolute",
                            right: "8px",
                          }}
                        >
                          {renderVerifyReviewName()}
                        </SHStack>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="ownerId"
                control={control}
                render={({ field }) => (
                  <ImageAutoComplete
                    sx={{ width: "100%", marginBottom: "20px" }}
                    textFieldProps={{
                      label: "Adviser",
                      placeholder: "Select an adviser",
                      helperText: errors?.ownerId?.message,
                      error: !!errors.ownerId,
                      required: true,
                    }}
                    value={{
                      auth0Id: watch("ownerId"),
                      name: watch("ownerName"),
                      avatarUrl: watch("ownerAvatarUrl"),
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.auth0Id === value.auth0Id
                    }
                    onChange={async (event, newValue) => {
                      field.onChange(newValue?.auth0Id);
                      setValue("ownerName", newValue?.name ?? "", {
                        shouldDirty: true,
                      });
                      setValue("ownerAvatarUrl", newValue?.avatarUrl ?? "", {
                        shouldDirty: true,
                      });
                      setValue("ownerType", newValue?.userType ?? "", {
                        shouldDirty: true,
                      });
                      setValue("familyGroupId", "");
                      setValue("familyGroupName", "");
                      setValue("selectedFamilyMemberIds", []);
                      setValue("selectedFamilyMembers", []);
                      handleOnChecking(getValues("name"));
                    }}
                    labelField={"name"}
                    imageField={"avatarUrl"}
                    options={adviserData || []}
                    disabled={isDisabled}
                  />
                )}
              />
              <Controller
                name="familyGroupId"
                control={control}
                render={({ field }) => (
                  <SHAutocomplete
                    disabled={!watch("ownerId") || isDisabled}
                    textFieldProps={{
                      InputLabelProps: { shrink: true },
                      label: "Which family group is this this review for?",
                      placeholder: "Search for family group",
                      helperText: errors?.familyGroupId?.message,
                      error: !!errors.adviserFirmId,
                      required: true,
                    }}
                    options={familyGroupData ?? []}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    getOptionLabel={(option: FamilyGroupDTO) =>
                      option?.name ?? ""
                    }
                    value={{
                      id: watch("familyGroupId"),
                      name: watch("familyGroupName"),
                    }}
                    onChange={async (event, newValue) => {
                      field.onChange(newValue?.id);
                      setValue("familyGroupId", newValue?.id ?? "", {
                        shouldDirty: true,
                      });
                      setValue("familyGroupName", newValue?.name ?? "", {
                        shouldDirty: true,
                      });
                      setValue("selectedFamilyMemberIds", []);
                      setValue("selectedFamilyMembers", []);
                      if (!watch("familyGroupId")) setFamilyGroupMemberData([]);
                      if (watch("familyGroupId")) await getFamilyMembers(true);
                    }}
                    renderOption={(props, option, { selected }) => {
                      const isChecked = selected;
                      return (
                        <li
                          {...props}
                          key={option?.id}
                          style={{ padding: "1px 10px" }}
                        >
                          <SHStack
                            alignItems={"center"}
                            direction="row"
                            spacing={1}
                          >
                            <SHRadio checked={isChecked} />
                            <SHTypography variant="body3" fontWeight={500}>
                              {`${option.name}`}
                            </SHTypography>
                          </SHStack>
                        </li>
                      );
                    }}
                    sx={{ width: { xs: "100%", md: 520 } }}
                  />
                )}
              />
              <SHStack direction={"row"} spacing={"5px"}>
                <SHTypography variant="body2" colorVariant="third">
                  Cannot find the family group?
                </SHTypography>
                <SHTypography
                  colorVariant="secondary"
                  disabled={watch("ownerId") && !isDisabled ? false : true}
                  onClick={() => setOpenDialog(watch("ownerId") ? true : false)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Create a new family group
                </SHTypography>
              </SHStack>
            </SHStack>
          </FieldGroup>
          <SHDivider />
          <FieldGroup
            title="Select family members and entities"
            subTitle={
              "At least one family member or entity must be included in the review. You will be able to assign platform accounts to these members during the fee analysis stage. To manage family members, edit your client in the “Clients” tab of the “Suitability Reviews” page"
            }
          >
            <SHStack direction="column" sx={{ width: { xs: "100%", md: 520 } }}>
              <Controller
                name={"selectedFamilyMemberIds"}
                control={control}
                render={({ field: { ref, ...other } }) => (
                  <SelectFamilyGroupMembers
                    {...other}
                    multiple
                    value={watch("selectedFamilyMemberIds")}
                    disabled={!watch("familyGroupId") || isDisabled}
                    familyGroupMemberData={familyGroupMemberData ?? []}
                    maxLength={familyGroupMemberData?.length}
                    textFieldProps={{
                      label:
                        "Select up to six family members/entities to include in your review",
                      placeholder: "Search for members",
                      error: !!errors?.selectedFamilyMemberIds,
                      helperText: errors?.selectedFamilyMemberIds?.message,
                      required: true,
                    }}
                  />
                )}
              />
            </SHStack>
          </FieldGroup>
          <SHDivider />
          <FieldGroup
            title="Current client situation and needs"
            subTitle={
              "Articulate what this client's needs and priorities are when selecting a platform. This will appear on the report generated at the end of this review"
            }
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <Controller
                name="objectives"
                control={control}
                render={({ field: { ref, ...others } }) => (
                  <SHRichTextEditor
                    {...others}
                    maxLength={3000}
                    disabled={isDisabled}
                    placeHolder="Enter your client's situation and needs"
                    height={165}
                  />
                )}
              />
            </SHStack>
          </FieldGroup>
          <SHDivider />
          <FieldGroup
            title="Load a template"
            subTitle={
              "You can either choose each platform feature to include in your review one by one, or you can load from a template or review your practice has created"
            }
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <SHStack direction="column">
                <Controller
                  name={"loadTemplateId"}
                  control={control}
                  render={({ field }) => (
                    <SHAutocomplete
                      disabled={isDisabled}
                      textFieldProps={{
                        InputLabelProps: { shrink: true },
                        label: "Load feature selection from a saved template",
                        placeholder: "Search for template",
                      }}
                      options={featureTemplates}
                      getOptionLabel={(option: FeatureTemplateSelectionDTO) =>
                        option?.name ?? ""
                      }
                      onChange={async (event, newValue) => {
                        field.onChange(newValue?.id);
                      }}
                      renderOption={(props, option, { selected }) => {
                        const isChecked = selected;
                        return (
                          <li
                            {...props}
                            key={option?.id}
                            style={{ padding: "1px 10px" }}
                          >
                            <SHStack
                              alignItems={"center"}
                              direction="row"
                              spacing={1}
                            >
                              <SHRadio checked={isChecked} />
                              <SHTypography variant="body3" fontWeight={500}>
                                {`${option.name} - ${option.selectedFeatures}/${option.totalFeatures} features selected`}
                              </SHTypography>
                              {option?.notes && (
                                <InformationButton content={option?.notes} />
                              )}
                            </SHStack>
                          </li>
                        );
                      }}
                      sx={{ width: { xs: "100%", md: 520 } }}
                    />
                  )}
                />
              </SHStack>
            </SHStack>
          </FieldGroup>
          <SHDivider />
          <FieldGroup
            title="Rate cards"
            subTitle={
              "Choose whether your Suitability Review should include your firm's rate cards"
            }
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <Controller
                name={"hasRateCard"}
                control={control}
                render={({ field: { ref, ...other } }) => (
                  <SHBooleanField
                    {...other}
                    isSwitch
                    disabled={isDisabled}
                    isShowClearButton={false}
                    value={other.value}
                    onChange={async (event, value?: boolean | null) => {
                      other.onChange(value);
                      if (!value) {
                        setValue("selectedRateCards", [], {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }
                    }}
                    label={"Include rate cards?"}
                  />
                )}
              />
              {watch("hasRateCard") && (
                <Controller
                  name={"selectedRateCards"}
                  control={control}
                  render={({ field: { ref, ...other } }) => (
                    <SelectRateCardsForReview
                      {...other}
                      disabled={isDisabled}
                      reviewId={isNew ? "new" : reviewId ?? "new"}
                      onChange={(value) => {
                        other.onChange(value || []);
                      }}
                      textFieldProps={{
                        label: "Select rate cards to include in your review",
                        placeholder: "Search for rate cards",
                      }}
                    />
                  )}
                />
              )}
            </SHStack>
          </FieldGroup>
        </SHStack>
      </form>
      {openDialog && (
        <CreateFamilyGroupDialog
          data={getValues()}
          onClose={() => {
            setOpenDialog(false);
            getFamilyGroups();
          }}
        />
      )}
    </SHContainer>
  );
};
