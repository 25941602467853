import { FilterValue } from "@components/buttons/filter";
import { filterData } from "@components/buttons/filter/mock";
import { SHBox, SHGrid } from "@components/design-systems";
import { useConfiguration } from "@hooks/useConfiguration";
import { FeatureList } from "@layouts/dnd/configuration/feature-list";
import { SectionList } from "@layouts/dnd/configuration/section-list";
import { ConfigurationSection } from "@models/configuration";
import { RootState } from "@redux/store";
import {
  updateSelectedSection,
  updateSelectedSubSection,
} from "@redux/slices/configuration";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  constructFeaturePath,
  constructSectionPath,
  filterConfiguration,
} from "../util";

export interface ConfigurationViewProps {
  groupId: string;
}

export default function ConfigurationView({ groupId }: ConfigurationViewProps) {
  const navigate = useNavigate();

  const [sectionFilters, setSectionFilters] = useState<FilterValue[]>();
  const [subSectionFilters, setSubSectionFilters] = useState<FilterValue[]>();
  const [featureFilters, setFeatureFilters] = useState<FilterValue[]>();

  const {
    configurationGroupData,
    configurationUI: { isEditMode, selectedSection, selectedSubSection },
  } = useSelector((state: RootState) => state.configuration);
  const { updateConfigurationGroup } = useConfiguration();
  const dispatch = useDispatch();

  const handleOnChangeSection = (section: ConfigurationSection | undefined) => {
    dispatch(updateSelectedSection(section));
  };

  const handleOnChangeSubSection = (
    subSection: ConfigurationSection | undefined,
  ) => {
    dispatch(updateSelectedSubSection(subSection));
  };

  useEffect(() => {
    updateConfigurationGroup(groupId);
    // eslint-disable-next-line
  }, [groupId]);

  const hasSubSection = useMemo(
    () => configurationGroupData?.sectionType === "HasSubSections",
    [configurationGroupData?.sectionType],
  );

  const sections = useMemo(
    () => {
      const sectionsData = filterConfiguration(
        configurationGroupData?.sections,
        sectionFilters,
      ) as ConfigurationSection[];
      const currentSelectedSection = sectionsData?.find(
        (section) => section.id === selectedSection?.id,
      );

      if (currentSelectedSection) {
        handleOnChangeSection(currentSelectedSection);
      } else {
        handleOnChangeSection(sectionsData?.[0]);
      }
      return sectionsData;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configurationGroupData, sectionFilters],
  );

  const subSections = useMemo(
    () => {
      const subSectionsData = filterConfiguration(
        selectedSection?.subSections,
        subSectionFilters,
      ) as ConfigurationSection[];
      const currentSelectedSubSection = subSectionsData?.find(
        (subSection) => subSection.id === selectedSubSection?.id,
      );

      if (currentSelectedSubSection) {
        handleOnChangeSubSection(currentSelectedSubSection);
      } else {
        handleOnChangeSubSection(subSectionsData?.[0]);
      }
      return subSectionsData;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subSectionFilters, selectedSection],
  );

  const featureList = useMemo(
    () =>
      hasSubSection
        ? selectedSubSection?.features || []
        : selectedSection?.features || [],
    [hasSubSection, selectedSubSection?.features, selectedSection?.features],
  );

  const features = useMemo(() => {
    return filterConfiguration(featureList, featureFilters);
  }, [featureList, featureFilters]);

  const handleOnClickSection = (
    group: "standard" | "feature",
    groupId?: string,
    sectionType?: "group" | "subgroup",
    sectionId?: string,
    subSectionId?: string,
  ) => {
    navigate(
      constructSectionPath(
        group,
        groupId,
        sectionType,
        sectionId,
        subSectionId,
      ),
    );
  };

  return (
    <SHBox sx={{ mt: "0px !important" }}>
      <SHGrid container>
        <SHGrid xs={hasSubSection ? 3 : 4} sx={{ borderRight: 1 }}>
          <SectionList
            title={hasSubSection ? "Group" : "Sections"}
            sections={sections}
            isEdit={isEditMode}
            filterProp={{
              filterData,
            }}
            onChangeSection={handleOnChangeSection}
            onChangeFilter={setSectionFilters}
            selectedSectionId={selectedSection?.id}
            onClickNew={() =>
              handleOnClickSection(
                hasSubSection ? "feature" : "standard",
                groupId,
                "group",
                "new",
              )
            }
          />
        </SHGrid>
        {hasSubSection && (
          <SHGrid xs={3} sx={{ borderRight: 1 }}>
            <SectionList
              title="Sub-group"
              sections={subSections}
              isEdit={isEditMode}
              filterProp={{
                filterData,
              }}
              onChangeSection={handleOnChangeSubSection}
              onChangeFilter={setSubSectionFilters}
              selectedSectionId={selectedSection?.id}
              selectedSubSectionId={selectedSubSection?.id}
              onClickNew={() =>
                handleOnClickSection(
                  hasSubSection ? "feature" : "standard",
                  groupId,
                  "subgroup",
                  selectedSection?.id,
                  "new",
                )
              }
            />
          </SHGrid>
        )}
        <SHGrid xs={hasSubSection ? 6 : 8} sx={{ pl: 3 }}>
          <FeatureList
            hasSubSection={hasSubSection}
            groupId={groupId}
            sectionId={selectedSection?.id}
            subSectionId={selectedSubSection?.id}
            title={
              hasSubSection ? selectedSubSection?.name : selectedSection?.name
            }
            subTitle={`Showing ${features?.length ?? 0} items`}
            features={features}
            isEdit={isEditMode}
            filterProp={{
              filterData,
              buttonTitle: "Filter",
            }}
            onChangeFilter={setFeatureFilters}
            onClickNew={() =>
              navigate(
                constructFeaturePath(
                  hasSubSection ? "feature" : "standard",
                  groupId,
                  selectedSection?.id,
                  selectedSubSection?.id,
                  "new",
                ),
              )
            }
          />
        </SHGrid>
      </SHGrid>
    </SHBox>
  );
}
