import { FilterValue } from ".";

export const filterData: FilterValue[] = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Archived",
    value: "Archived",
  },
];
