import { AnalysisDTO } from "@models/platform-analysis/entities/analysis";
import {
  AnalysisStepBusinessMetricDTO,
  BusinessMetricCategories,
} from "@models/platform-analysis/entities/steps/businessMetric";
import {
  AnalysisFeatureDTO,
  AnalysisGetFeatureSectionsDTO,
} from "@models/platform-analysis/entities/steps/feature";
import {
  AnalysisFeeCalculatorDTO,
  AnalysisFeeDTO,
  FeeDetailDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { sortAscByOrder } from "@utils/common";
import { isEmpty, isNumber } from "lodash";
const { Feature, BusinessMetric, Fee } = AnalysisStep;
export const compilePlatformAnalysis = (analysis: AnalysisDTO): AnalysisDTO => {
  let hasSteps = [];
  let hasMainProduct = false;

  if (analysis.setup) {
    hasMainProduct = !isEmpty(analysis.setup.mainProductId);
    if (analysis.setup.hasFeatureStep) hasSteps.push(Feature);
    if (analysis.setup.hasBusinessMetricStep) hasSteps.push(BusinessMetric);
    if (analysis.setup.hasFeeStep) hasSteps.push(Fee);

    analysis.setup.hasSteps = hasSteps;
    analysis.setup.hasMainProduct = hasMainProduct;
  }
  if (analysis.feature) {
    compileFeatureAnalysis(analysis.feature);
  }
  if (analysis.businessMetric) {
    compileBusinessMetricAnalysis(analysis.businessMetric);
  }
  if (analysis.fee) {
    compileFeeAnalysis(analysis.fee);
  }
  if (analysis.summary) {
    if (analysis.summary.featureAnalysis) {
      analysis.summary.featureAnalysis = compileFeatureAnalysis(
        analysis.summary.featureAnalysis,
      ) as AnalysisFeatureDTO;
    }
    if (analysis.summary.businessMetricAnalysis) {
      analysis.summary.businessMetricAnalysis = compileBusinessMetricAnalysis(
        analysis.summary.businessMetricAnalysis,
      ) as AnalysisStepBusinessMetricDTO;
    }
    if (analysis.summary.feeAnalysis) {
      compileFeeAnalysis(analysis.summary.feeAnalysis);
    }
  }

  return analysis;
};

export const compileFeatureSelection = (
  featureSelections: AnalysisGetFeatureSectionsDTO,
): AnalysisGetFeatureSectionsDTO => {
  if (featureSelections.sections) {
    featureSelections.sections = sortAscByOrder(featureSelections.sections);
    featureSelections.sections.forEach((section) => {
      //Sort Sub Section
      if (section.subSections) {
        section.subSections = sortAscByOrder(section.subSections) as [];
        //Sort feature of sub sections
        section.subSections?.forEach((subSection) => {
          if (subSection.features)
            subSection.features = sortAscByOrder(subSection.features);
        });
      }
    });
  }

  return featureSelections;
};

export const compileFeatureAnalysis = (
  featureAnalysis: AnalysisFeatureDTO,
): AnalysisFeatureDTO => {
  if (
    featureAnalysis?.overallScore &&
    featureAnalysis?.overallScore?.analysisData
  ) {
    featureAnalysis.overallScore.analysisData = sortAscByOrder(
      featureAnalysis.overallScore.analysisData,
    );
  }

  if (featureAnalysis?.selectedProducts) {
    featureAnalysis.selectedProducts = sortAscByOrder(
      featureAnalysis.selectedProducts,
    );
  }

  if (featureAnalysis?.sections) {
    featureAnalysis.sections = sortAscByOrder(featureAnalysis.sections);
    featureAnalysis.sections?.forEach((section) => {
      if (section.analysisData)
        section.analysisData = sortAscByOrder(section.analysisData) as [];
      if (section.subSections) {
        section.subSections = sortAscByOrder(section.subSections) as [];
        section.subSections?.forEach((subSection: any) => {
          if (subSection.analysisData)
            subSection.analysisData = sortAscByOrder(subSection.analysisData);
          if (subSection.features) {
            subSection.features = sortAscByOrder(subSection.features) as [];
            subSection.features?.forEach((feature: any) => {
              if (feature?.analysisData)
                feature.analysisData = sortAscByOrder(feature.analysisData);
            });
          }
        });
      }
    });
  }

  return featureAnalysis;
};

export const compileBusinessMetricAnalysis = (
  businessMetricAnalysis: AnalysisStepBusinessMetricDTO,
): AnalysisStepBusinessMetricDTO => {
  if (
    businessMetricAnalysis?.overallScore &&
    businessMetricAnalysis?.overallScore?.analysisData
  ) {
    businessMetricAnalysis.overallScore.analysisData = sortAscByOrder(
      businessMetricAnalysis.overallScore.analysisData,
    );
  }

  if (businessMetricAnalysis?.selectedProducts) {
    businessMetricAnalysis.selectedProducts = sortAscByOrder(
      businessMetricAnalysis.selectedProducts,
    );
  }

  if (businessMetricAnalysis?.sections) {
    businessMetricAnalysis.sections = sortAscByOrder(
      businessMetricAnalysis.sections,
    );
    businessMetricAnalysis.sections?.forEach((section) => {
      if (section.analysisData)
        section.analysisData = sortAscByOrder(section.analysisData) as [];
      if (section.features) {
        section.features = sortAscByOrder(section.features) as [];
        section.features?.forEach((feature: any) => {
          if (feature?.analysisData)
            feature.analysisData = sortAscByOrder(feature.analysisData);
        });
      }
    });
  }

  return businessMetricAnalysis;
};

export const compileBusinessMetricSelection = (
  businessMetrics: BusinessMetricCategories,
): BusinessMetricCategories => {
  if (businessMetrics.financialStrength) {
    businessMetrics.financialStrength = sortAscByOrder(
      businessMetrics.financialStrength,
    );
  }
  if (businessMetrics.esg) {
    businessMetrics.esg = sortAscByOrder(businessMetrics.esg);
  }
  if (businessMetrics.operational) {
    businessMetrics.operational = sortAscByOrder(businessMetrics.operational);
  }

  return businessMetrics;
};

export const compileFeeAnalysis = (
  analysisStepFee: AnalysisFeeDTO,
): AnalysisFeeDTO => {
  if (analysisStepFee.subProducts) {
    let platformOrderMap = new Map<string, number>();
    if (analysisStepFee.selectedProducts) {
      analysisStepFee.selectedProducts.forEach((product) => {
        platformOrderMap.set(product.id, product.order);
      });
    }

    // Sort sub-product
    analysisStepFee.subProducts = analysisStepFee.subProducts.sort((a, b) => {
      const productOrderA = platformOrderMap.get(a.productId);
      const productOrderB = platformOrderMap.get(b.productId);
      if (productOrderA && productOrderB && productOrderA !== productOrderB) {
        return productOrderA - productOrderB;
      }
      return a?.order - b?.order;
    });
    analysisStepFee.subProducts = analysisStepFee.subProducts.map(
      (subProduct) => {
        let feeDetails: FeeDetailDTO[] = [];
        if (!subProduct.detail) return { ...subProduct, feeDetails: [] };
        const {
          totalFees,
          totalFeesPercentage,
          idpsAdminAndAccFees,
          idpsAdminAndAccFeesPercentage,
          superAdminAndAccFees,
          superAdminAndAccFeesPercentage,
          transactionFees,
          transactionFeesPercentage,
          intlSecuritiesOrBondHoldingFees,
          intlSecuritiesOrBondHoldingFeesPercentage,
          cashFees,
          cashFeesPercentage,
          expenseRecoveryAndOtherFees,
          expenseRecoveryAndOtherFeesPercentage,
          netInterestEarnedOnCash,
          netInterestEarnedOnCashPercentage,
          pysRefund,
          pysRefundPercentage,
          hasCashBalances,
        } = subProduct.detail;
        if (isNumber(totalFees) && isNumber(totalFeesPercentage)) {
          feeDetails.push({
            name: "Total fees",
            dollar: totalFees,
            percentage: totalFeesPercentage,
          });
        }
        if (
          isNumber(idpsAdminAndAccFees) &&
          isNumber(idpsAdminAndAccFeesPercentage)
        ) {
          feeDetails.push({
            name: "IDPS admin & account fees",
            dollar: idpsAdminAndAccFees,
            percentage: idpsAdminAndAccFeesPercentage,
          });
        }
        if (
          isNumber(superAdminAndAccFees) &&
          isNumber(superAdminAndAccFeesPercentage)
        ) {
          feeDetails.push({
            name: "Super admin & account fees",
            dollar: superAdminAndAccFees,
            percentage: superAdminAndAccFeesPercentage,
          });
        }
        if (
          isNumber(intlSecuritiesOrBondHoldingFees) &&
          isNumber(intlSecuritiesOrBondHoldingFeesPercentage)
        ) {
          feeDetails.push({
            name: "International & bond holding fees",
            dollar: intlSecuritiesOrBondHoldingFees,
            percentage: intlSecuritiesOrBondHoldingFeesPercentage,
          });
        }

        if (isNumber(cashFees) && isNumber(cashFeesPercentage)) {
          feeDetails.push({
            name: "Cash fees",
            dollar: cashFees,
            percentage: cashFeesPercentage,
          });
        }

        if (
          isNumber(expenseRecoveryAndOtherFees) &&
          isNumber(expenseRecoveryAndOtherFeesPercentage)
        ) {
          feeDetails.push({
            name: "Expense recovery & other fees",
            dollar: expenseRecoveryAndOtherFees,
            percentage: expenseRecoveryAndOtherFeesPercentage,
          });
        }
        if (isNumber(transactionFees) && isNumber(transactionFeesPercentage)) {
          feeDetails.push({
            name: "Transaction fees",
            dollar: transactionFees,
            percentage: transactionFeesPercentage,
          });
        }
        if (isNumber(pysRefund) && isNumber(pysRefundPercentage)) {
          feeDetails.push({
            name: "PYS refund",
            dollar: pysRefund,
            percentage: pysRefundPercentage,
          });
        }

        if (hasCashBalances) {
          feeDetails.push({
            name: "Net interest earned on cash",
            dollar: netInterestEarnedOnCash ?? null,
            percentage: netInterestEarnedOnCashPercentage ?? null,
          });
        }

        //Create fee details and uuid for rate card
        return {
          ...subProduct,
          id:
            subProduct.id +
            (subProduct.rateCardVersion
              ? `|${subProduct.rateCardVersion}`
              : ""),
          feeDetails,
        };
      },
    );
  }
  return analysisStepFee;
};

export const compileFeeCalculator = (
  calculator: AnalysisFeeCalculatorDTO,
): AnalysisFeeCalculatorDTO => {
  if (calculator.familyMembers) {
    calculator.familyMembers = sortAscByOrder(calculator.familyMembers);
    calculator.familyMembers.forEach((member) => {
      member.familyMemberAccounts = sortAscByOrder(member.familyMemberAccounts);
    });
  }
  return calculator;
};
