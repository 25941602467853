import { SVGProps } from "react";

export const DiscardSVG = ({
  color = "#25324B",
  width = 19,
  height = 20,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.49 2.878a7.128 7.128 0 0 0-7.331 7.125H1.742a.392.392 0 0 0-.277.673l2.208 2.216a.392.392 0 0 0 .562 0l2.21-2.216c.252-.246.07-.673-.278-.673H4.742a5.54 5.54 0 0 1 5.62-5.542c2.946.04 5.424 2.518 5.463 5.463a5.545 5.545 0 0 1-5.541 5.62 5.458 5.458 0 0 1-3.389-1.171.787.787 0 0 0-1.045.063.8.8 0 0 0 .064 1.188 7.07 7.07 0 0 0 4.37 1.504 7.132 7.132 0 0 0 7.125-7.33c-.103-3.714-3.206-6.817-6.92-6.92Zm-.404 3.958a.598.598 0 0 0-.594.594v2.913c0 .277.15.539.388.681l2.47 1.465a.6.6 0 0 0 .815-.206.6.6 0 0 0-.205-.816l-2.28-1.353V7.422a.591.591 0 0 0-.594-.586Z"
      fill={color}
    />
  </svg>
);
