import {
  SHAlert,
  SHButton,
  SHCheckbox,
  SHDialog,
  SHFormControlLabel,
  SHSelect,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import { LicenceAgreementDialog } from "@components/dialogs/licence-agreement";
import { PrivacyPolicyDialog } from "@components/dialogs/privacy-policy";
import { WarningSVG } from "@components/svgs/warning";
import { PageRoutes } from "@constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePracticeUser } from "@hooks/usePracticeUser";
import { useUser } from "@hooks/useUser";
import { AdviserUserDTO } from "@models/practice/entities/practiceUser";
import { Link, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { CompleteProfilePracticeSkeleton } from "./skeleton";
import { practiceUserValidators } from "./util";
import { PracticeRole } from "@models/practice/enums/practiceRole";
import { refreshAccessToken } from "@services/shService";
import { useAuth0 } from "@auth0/auth0-react";

export const CompleteProfilePractice = () => {
  const { palette } = useTheme();
  const [showPrivacyPolicyDialog, setShowPrivacyPolicyDialog] = useState(false);
  const [showEULADialog, setShowEULADialog] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...new AdviserUserDTO() },
    resolver: yupResolver(practiceUserValidators),
  });

  const {
    practiceUser,
    ui: { isLoading },
    setPracticeUser,
    loadPracticeUser,
    completeProfilePracticeUser,
  } = usePracticeUser();
  const { user, setUser } = useUser();

  const isDisabled = isSubmitting;

  const practiceRole = watch("practiceRole");
  const showARN = useMemo(
    () => practiceRole === PracticeRole.Adviser,
    [practiceRole],
  );

  const handleCompleteProfile = async (practiceUser: AdviserUserDTO) => {
    if (!isAgree) {
      return setShowNotification(true);
    }
    if (!user?.auth0Id) return;
    const isUpdated = await completeProfilePracticeUser({
      auth0Id: user.auth0Id,
      practiceUser,
    });
    if (!isUpdated) return;
    refreshAccessToken(getAccessTokenSilently);
    reset(practiceUser);
    setPracticeUser(practiceUser);
    const { firstName, lastName } = practiceUser;
    setUser({
      ...user,
      firstName: firstName ? firstName : "",
      lastName: lastName ? lastName : "",
      name: `${firstName} ${lastName}`,
      userMetadata: {
        ...user.userMetadata,
        is_profile_completed: true,
        is_first_login: false,
      },
    });
    navigate(PageRoutes.home);
  };

  useEffect(() => {
    if (!user?.auth0Id) {
      return;
    }
    loadPracticeUser(user.userMetadata?.adviser_firm_id, user.auth0Id).then(
      (practiceUser) => {
        if (practiceUser) {
          reset(practiceUser);
        }
      },
    );
    // eslint-disable-next-line
  }, [user?.auth0Id]);

  return (
    <>
      <form>
        <SHDialog
          open
          disableEscapeKeyDown
          disableBackdropClick
          maxWidth={false}
          sx={{
            "& .MuiPaper-root": {
              width: { xs: "100%", md: 645 },
            },
          }}
          header={
            isLoading ? (
              CompleteProfilePracticeSkeleton.Header
            ) : (
              <SHStack alignItems="center" textAlign="center" spacing={1}>
                <SHTypography
                  variant="h2"
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                >
                  Hi {practiceUser?.firstName}, almost done!
                </SHTypography>
                <SHTypography
                  variant="body2"
                  colorVariant="disabled"
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  Please review and complete your details below:
                </SHTypography>
              </SHStack>
            )
          }
          body={
            isLoading ? (
              CompleteProfilePracticeSkeleton.Body
            ) : (
              <SHStack alignItems="stretch" spacing={2}>
                <SHStack spacing={3} direction="row">
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <SHTextField
                        {...field}
                        required
                        hasCounter
                        maxLength={100}
                        label="First name"
                        placeholder="Enter first name"
                        disabled={isDisabled}
                        error={!!errors.firstName}
                        sx={{ width: "100%" }}
                        helperText={
                          errors.firstName ? errors.firstName.message : null
                        }
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                      />
                    )}
                  />

                  <Controller
                    render={({ field }) => (
                      <SHTextField
                        {...field}
                        required
                        hasCounter
                        maxLength={100}
                        label={"Last name"}
                        placeholder="Enter last name"
                        disabled={isDisabled}
                        error={!!errors.lastName}
                        sx={{ width: "100%" }}
                        helperText={
                          errors.lastName ? errors.lastName.message : null
                        }
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                      />
                    )}
                    name="lastName"
                    control={control}
                  />
                </SHStack>
                <Controller
                  name="practiceRole"
                  control={control}
                  render={({ field }) => (
                    <SHSelect
                      {...field}
                      data={watch("practiceRoles") ?? []}
                      label={"Practice role"}
                      placeholder={"Select a role"}
                      dataItemKey="id"
                      displayField="name"
                      required
                      disabled={isDisabled}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        setValue("arn", "");
                      }}
                    />
                  )}
                />
                <SHStack spacing={3} direction="row">
                  <Controller
                    name="jobTitle"
                    control={control}
                    render={({ field }) => (
                      <SHTextField
                        {...field}
                        label={"Job title"}
                        placeholder="Enter job title"
                        disabled={isDisabled}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <SHTextField
                        {...field}
                        type="tel"
                        label={"Contact number"}
                        placeholder="Enter contact number"
                        disabled={isDisabled}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </SHStack>
                {showARN && (
                  <Controller
                    name="arn"
                    control={control}
                    render={({ field }) => (
                      <SHTextField
                        {...field}
                        fullWidth
                        required={showARN}
                        label="Authorised Representative Number (ARN)"
                        placeholder="Enter ARN"
                        disabled={isDisabled}
                        error={!!errors.arn}
                        helperText={errors.arn ? errors.arn.message : null}
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            regex.test(e.target.value)
                          ) {
                            field.onChange(e.target.value);
                          }
                        }}
                        hasCounter
                        maxLength={9}
                      />
                    )}
                  />
                )}
                <SHFormControlLabel
                  sx={{ pointerEvents: "none" }}
                  control={
                    <SHCheckbox
                      checked={isAgree}
                      sx={{ pointerEvents: "auto" }}
                    />
                  }
                  onChange={(event, checked) => {
                    setIsAgree(checked);
                    if (checked) {
                      setShowNotification(false);
                    }
                  }}
                  componentsProps={{
                    typography: { align: "left" },
                  }}
                  label={
                    <SHTypography
                      variant="subtitle2"
                      colorVariant="third"
                      sx={{
                        cursor: "default",
                        pointerEvents: "none",
                      }}
                    >
                      I have read and agree to the
                      <Link
                        variant="body2"
                        color={palette.text.secondary}
                        underline="none"
                        sx={{
                          paddingLeft: 0.5,
                          paddingRight: 0.5,
                          fontWeight: 600,
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setShowEULADialog(true);
                        }}
                      >
                        End User Licence Agreement (EULA)
                      </Link>
                      <br />
                      and
                      <Link
                        variant="body2"
                        color={palette.text.secondary}
                        component="span"
                        underline="none"
                        sx={{
                          paddingLeft: 0.5,
                          fontWeight: 600,
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setShowPrivacyPolicyDialog(true);
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </SHTypography>
                  }
                />

                {showNotification && (
                  <SHStack>
                    <SHAlert
                      onClose={() => setShowNotification(false)}
                      severity="error"
                      width={"525px !important"}
                      textAlign={"left"}
                      icon={<WarningSVG />}
                    >
                      To proceed, you must agree to the EULA and Privacy policy
                    </SHAlert>
                  </SHStack>
                )}
              </SHStack>
            )
          }
          footer={
            isLoading ? (
              CompleteProfilePracticeSkeleton.Footer
            ) : (
              <SHStack
                justifyContent="center"
                alignItems="center"
                direction={"row"}
              >
                <SHButton
                  color="primary"
                  size="large"
                  variant="contained"
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  onClick={handleSubmit(handleCompleteProfile)}
                >
                  Complete
                </SHButton>
              </SHStack>
            )
          }
        />
      </form>

      {showPrivacyPolicyDialog && (
        <PrivacyPolicyDialog
          onClose={() => setShowPrivacyPolicyDialog(false)}
        />
      )}
      {showEULADialog && (
        <LicenceAgreementDialog onClose={() => setShowEULADialog(false)} />
      )}
    </>
  );
};
