import {
  SHSearchBox,
  SHSearchBoxProps,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableCell,
  SHTableContainer,
  SHTableFooter,
  SHTableHead,
  SHTableRow,
} from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { ODataResult } from "@models/core";
import { Box, SxProps, Theme } from "@mui/material";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  TableState,
  Updater,
  useReactTable,
} from "@tanstack/react-table";
import { isEmpty, isEqual } from "lodash";
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { SHDataGridEmpty } from "./components/sh-data-grid-empty";
import { SHDataGridLoading } from "./components/sh-data-grid-loading";
import { SHHeadCell } from "./components/sh-head-cell";
import { SHDataGridPagination } from "./components/sh-table-pagination";
import { DefaultDataState, DefaultOdataErrorMessage } from "./constant";
import { useOData } from "./hook";
import { toODataString } from "./util";

export interface SHFilterOption {
  label: string;
  value: any;
}
declare module "@tanstack/table-core" {
  // eslint-disable-next-line
  interface ColumnMeta<TData extends RowData, TValue> {
    filterData?: SHFilterOption[];
    style?: React.CSSProperties;
    sx?: SxProps<Theme>;
  }
}
export interface SHDataGridRef {
  refreshOdata: () => void;
}
export interface SHDataGridProps {
  data?: any[];
  state?: Partial<TableState>;
  odata?: {
    url: string;
    defaultErrorMessage?: string | React.ReactNode;
    onDataReceived?: (
      state: Partial<TableState>,
      data: ODataResult<any>,
    ) => ODataResult<any>;
    pickColumns?: string[];
    globalFilterColumns?: string[];
  };
  columns: ColumnDef<any, unknown>[];
  isShowFooter?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  emptyMessage?: React.ReactNode;
  emptyResultsMatchMessage?: React.ReactNode;
  searchBoxProps?: SHSearchBoxProps;
  rightToolbar?: React.ReactNode;
  onChangeIsLoading?: (isLoading: boolean) => void;
  onChangeState?: (state: Partial<TableState>) => void;
}

const SHDataGridComp: ForwardRefRenderFunction<
  SHDataGridRef,
  SHDataGridProps
> = (
  {
    data,
    odata,
    columns,
    state = DefaultDataState,
    isLoading = false,
    isShowFooter = false,
    emptyMessage = "Data not found!",
    emptyResultsMatchMessage = "No results match your criteria",
    searchBoxProps,
    rightToolbar,
    onChangeIsLoading,
    onChangeState,
  },
  ref,
) => {
  const { notify } = useNotification();
  const [isLoadDataFirstTime, setIsLoadDataFirstTime] = useState(!odata);
  const [tableState, setTableState] = useState<Partial<TableState>>(state);
  const [isLoadingData, setIsLoadingData] = useState(isLoading);
  const [isShowAll, setIsShowALl] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [tableData, setTableData] = useState(data ?? []);
  const [tableTotalData, setTableTotalData] = useState(data?.length ?? 0);
  const {
    getHeaderGroups,
    getRowModel,
    getFooterGroups,
    getPageCount,
    getState,
    initialState,
    setPageSize,
    setPageIndex,
    setState,
  } = useReactTable({
    data: tableData,
    columns,
    enableMultiSort: true,
    enableFilters: true,
    state: tableState,
    initialState: tableState,
    pageCount: isShowAll
      ? 1
      : Math.ceil(tableTotalData / (tableState.pagination?.pageSize ?? 1)),
    onStateChange: (updater: Updater<TableState>) => {
      const newState =
        typeof updater === "function" ? updater(getState()) : updater;
      setTableState(newState);
    },
    manualFiltering: Boolean(odata),
    manualSorting: Boolean(odata),
    manualPagination: Boolean(odata),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  const tableStateData = getState();
  const { pagination } = tableStateData;
  const headerGroup = getHeaderGroups();
  const lastedHeaderGroup = headerGroup[headerGroup.length - 1];
  const totalColumShowed = lastedHeaderGroup.headers.length;
  const rowModel = getRowModel();
  const isEmptyRows = rowModel.rows.length === 0;
  const isUsedGlobalFilter =
    tableStateData.globalFilter && tableStateData.globalFilter.trim() !== "";
  const isFilteredOnColumn =
    isUsedGlobalFilter ||
    lastedHeaderGroup.headers.some((header) => header.column.getIsFiltered());

  //Imperative Handle
  useImperativeHandle(ref, () => ({
    refreshOdata: () => {
      if (!isLoadingData) setTriggerRefresh((trigger) => !trigger);
    },
  }));
  //Callbacks
  const handleOnChangeIsLoading = useCallback(
    (isLoading: boolean) => {
      if (onChangeIsLoading) onChangeIsLoading(isLoading);
      setIsLoadingData(isLoading);
      if (!isLoadDataFirstTime) setIsLoadDataFirstTime(true);
    },
    [onChangeIsLoading, isLoadDataFirstTime],
  );

  //OData Loader
  const odataQueryUrl = useMemo(() => {
    if (!odata) return;
    return `${odata.url}${toODataString(
      tableStateData,
      isShowAll,
      odata.pickColumns,
      odata.globalFilterColumns,
    )}`;
  }, [odata, isShowAll, tableStateData]);

  const onDataReceived = useCallback(
    (data: ODataResult<any>) => {
      if (odata?.onDataReceived) {
        odata.onDataReceived(tableStateData, data);
        setTableData(odata.onDataReceived(tableStateData, data)["value"] ?? []);
      } else {
        setTableData(data["value"] ?? []);
      }
      setTableTotalData(data["@odata.count"] ?? 0);
    },
    [odata, tableStateData],
  );

  useOData({
    triggerRefresh,
    odataQueryUrl,
    onChangeIsLoading: handleOnChangeIsLoading,
    onChangeErrorMessage: (error) => {
      notify(error ?? odata?.defaultErrorMessage ?? DefaultOdataErrorMessage, {
        variant: "error",
        close: true,
      });
    },
    onDataReceived,
  });
  //Binding data state
  useEffect(() => {
    if (!isEqual(isLoading, isLoadingData)) {
      setIsLoadingData(isLoading);
    }
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    if (!isEqual(state, tableStateData)) {
      setState({ ...getState(), ...state });
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (isEmpty(tableStateData?.sorting))
      setState({ ...getState(), sorting: initialState?.sorting ?? [] });
    // eslint-disable-next-line
  }, [tableStateData]);

  return (
    <SHStack
      spacing={3}
      sx={{ pointerEvents: isLoadingData ? "none" : undefined }}
      data-testid="sh-data-grid"
    >
      <SHStack
        direction={"row"}
        alignItems={"center"}
        justifyContent="space-between"
      >
        {searchBoxProps ? (
          <SHSearchBox
            isLoading={isLoadingData && isUsedGlobalFilter}
            onChange={(value) => {
              setState({ ...getState(), globalFilter: value });
            }}
            {...searchBoxProps}
          />
        ) : (
          <Box></Box>
        )}
        {rightToolbar && rightToolbar}
      </SHStack>

      <SHTableContainer>
        <SHTable>
          <SHTableHead>
            {headerGroup.map((headerGroup) => (
              <SHTableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <SHHeadCell
                    key={header.id}
                    header={header}
                    isLastedColumn={header.index === totalColumShowed - 1}
                  />
                ))}
              </SHTableRow>
            ))}
            <SHDataGridLoading
              isLoading={isLoadingData}
              colSpan={totalColumShowed}
            />
          </SHTableHead>

          <SHTableBody
            style={{
              position: "relative",
              borderBottomWidth: isEmptyRows ? 0 : "1px",
            }}
          >
            {isEmptyRows && isLoadDataFirstTime ? (
              <SHDataGridEmpty
                colSpan={totalColumShowed}
                emptyMessage={
                  isFilteredOnColumn ? emptyResultsMatchMessage : emptyMessage
                }
              />
            ) : (
              rowModel.rows.map((row) => (
                <SHTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <SHTableCell
                      key={cell.id}
                      sx={cell.column.columnDef.meta?.sx}
                      style={cell.column.columnDef.meta?.style}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </SHTableCell>
                  ))}
                </SHTableRow>
              ))
            )}
          </SHTableBody>
          {isShowFooter && (
            <SHTableFooter data-testid="sh-table-footer">
              {getFooterGroups().map((footerGroup) => (
                <SHTableRow key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <SHTableCell
                      key={header.id}
                      sx={header.column.columnDef.meta?.sx}
                      style={header.column.columnDef.meta?.style}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </SHTableCell>
                  ))}
                </SHTableRow>
              ))}
            </SHTableFooter>
          )}
        </SHTable>
      </SHTableContainer>
      {!isEmptyRows && (
        <SHDataGridPagination
          page={pagination.pageIndex + 1}
          count={getPageCount()}
          rowsPerPage={isShowAll ? "all" : pagination.pageSize}
          onPageChange={(event, page) => {
            setPageIndex(page - 1);
          }}
          onRowsPerPageChange={(event) => {
            const value = event.target.value;
            setIsShowALl(value === "all");
            setPageSize(value === "all" ? tableTotalData : event.target.value);
          }}
        />
      )}
    </SHStack>
  );
};
export const SHDataGrid = forwardRef(SHDataGridComp);
