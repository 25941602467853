import { RootState } from "@redux/store";
import { getUserRole } from "@utils/users/role";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

export const useAppParams = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { userId } = useParams<{ userId: string }>();
  const { auth0Id } = useParams<{ auth0Id: string }>();
  const { supplierUserId } = useParams<{ supplierUserId: string }>();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { productId } = useParams<{ productId: string }>();
  const { practiceId } = useParams<{ practiceId: string }>();
  const { isAdminGroup, isSupplierGroup, isAdviserGroup } = getUserRole(user);

  const isMyProfile = (() => {
    if (isAdminGroup) return user?.auth0Id === userId;
    if (isSupplierGroup) return user?.auth0Id === supplierUserId;
    if (isAdviserGroup) return user?.auth0Id === auth0Id;
    return false;
  })();

  const isMySupplier =
    isSupplierGroup && user?.userMetadata?.supplier_id === supplierId;
  const isMySupplierProduct = isMySupplier; //@TODO: Check associated product
  const isAssociatedProduct = isMySupplier; //@TODO: Check associated product
  const isMyAdviserFirm =
    isAdviserGroup && user?.userMetadata?.adviser_firm_id === practiceId;

  return {
    isMyProfile,
    isMySupplier,
    isMySupplierProduct,
    isAssociatedProduct,
    isMyAdviserFirm,
    userId,
    supplierUserId,
    productId,
    practiceId,
    auth0Id,
  };
};
