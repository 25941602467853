import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";

export const Logout = () => {
  const { logout } = useAuth0();
  const [searchParams] = useSearchParams();

  let returnTo =
    searchParams.get("returnTo") ?? process.env.REACT_APP_AUTH0_REDIRECT_URL;

  if (searchParams.get("type")) returnTo += `&type=${searchParams.get("type")}`;

  logout({
    returnTo,
  });
  return <></>;
};
