import { BusinessMetricTypeId } from "@models/configuration";
import { AnalysisSelectionDTO } from "@models/platform-analysis/entities/analysis";

// TODO: Need to merge model files
export interface ConfigurationSectionBaseDTO {
  id: string;
  name: string;
}

export interface AnalysisGetFeatureSectionsDTO extends AnalysisSelectionDTO {
  sections: AnalysisConfigurationFeatureSectionDTO[];
}

export interface AnalysisConfigurationFeatureSectionDTO
  extends ConfigurationSectionBaseDTO {
  configurationSectionId?: string;
  configurationGroupId?: string;
  description?: string;
  order: number;
  iconUrl?: string;
  iconS3Key?: string;
  totalFeature: number;
  totalSelectedFeature: number;
  configurationVersion?: number;
  isSelected: boolean;
  subSections: AnalysisConfigurationFeatureSubSectionDTO[];
}

export interface AnalysisConfigurationFeatureSubSectionDTO
  extends AnalysisConfigurationFeatureSectionDTO {
  parentSectionId?: string;
  features?: AnalysisConfigurationFeatureDTO[];
}

export interface AnalysisConfigurationFeatureDTO {
  id: string;
  name: string;
  description?: string;
  isSelected: boolean;
  isEssential: boolean;
  order: number;
  configurationSectionId?: string;
}

export enum PlatformAnalysisFilter {
  Common = "Common",
  Differentiators = "Differentiators",
}

export interface AnalysisFeatureDTO {
  selectedProducts: SelectedProductDTO[];
  overallScore: OverallScoreDTO;
  sections: any[]; // AnalysisStepFeatureDTO[]
}

export interface SelectedProductDTO {
  id: string;
  productName?: string;
  productLogo?: ImageDTO;
  totalScore: number;
  isProductMain: boolean;
  order: number;
  comment?: AnalysisCommentDTO;
}
export interface OverallScoreDTO {
  totalFeature?: number;
  totalSelectedFeature?: number;
  analysisData: AnalysisDataDTO[];
}

export interface AnalysisSectionDTO {
  id?: string;
  name?: string;
  description?: string;
  iconUrl?: string;
  order: number;
  totalFeature: number;
  totalSelectedFeature: number;
  analysisData?: AnalysisDataDTO[];
  subSections?: AnalysisSectionDTO[];
  features?: AnalysisFeatureDTO[];
  businessMetricTypeId?: BusinessMetricTypeId;
  businessMetricTypeName?: string;
}

export interface AnalysisFeatureDTO {
  id: string;
  name: string;
  description?: string | null;
  order: number;
  analysisData: AnalysisDataDTO[];
}
export interface AnalysisDataDTO {
  productId: string;
  order: number;
  totalScore?: number;
  value?: string;
  imageGallery?: ImageDTO[];
  isPositiveScore?: boolean;
  fieldTypeId?: string;
  description?: string;
  isMissingEssential?: boolean;
}

export interface AnalysisCommentDTO {
  id: string | null;
  comment: string;
}

export interface ImageDTO {
  key: string;
  url: string;
}

export class AnalysisFeatureWithUniqueDTO {
  featureId: string = "";
  featureName: string = "";
  uniqueFeature: boolean = false;
  isEssential?: boolean = false;
}
export class AnalysisFeatureIncludeDTO {
  sectionId: string = "";
  sectionName: string = "";
  subSectionId: string = "";
  subSectionName: string = "";
  features: AnalysisFeatureWithUniqueDTO[] = [];
}
