import { SHContainer, SHStack } from "@components/design-systems";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { ForwardRefRenderFunction, useImperativeHandle } from "react";

export interface ManagedAccountSummaryProps extends StepCompProps {}
export const ManagedAccountSummary: ForwardRefRenderFunction<
  StepCompRef,
  ManagedAccountSummaryProps
> = ({ stepNumber }, ref) => {
  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep) => {
      return true;
    },
    onChangeOtherStep: async () => {
      return true;
    },
    onSubmit: async () => {
      return true;
    },
  }));

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 }, py: "25px" }}>
      <form>
        <SHStack>
          <StepName name={"Summary"} stepNumber={stepNumber} />
        </SHStack>
      </form>
    </SHContainer>
  );
};
