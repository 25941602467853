import { CalendarSVG } from "@components/svgs/calendar";
import { CalendarCheckedSVG } from "@components/svgs/calendar-checked";
import { SxProps, Theme } from "@mui/material";
import { CalendarPickerView } from "@mui/x-date-pickers";
import { DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { forwardRef } from "react";
import { SHTextField, SHTextFieldProps } from "../sh-text-field";

export interface SHDatePickerProps
  extends Omit<DatePickerProps<Date | null, Date | null>, "renderInput"> {
  textFieldProps?: Omit<SHTextFieldProps, "value">;
  views?: CalendarPickerView[];
  inputFormat?: string,
  sxTextField?: SxProps<Theme>,
}

export const SHDatePicker = forwardRef(
  (
    {
      inputFormat,
      textFieldProps,
      value,
      views = ['year', 'day'],
      sxTextField,
      ...others
    }: SHDatePickerProps,
    ref: any,
  ): JSX.Element => (
    <DesktopDatePicker
      {...others}
      views={views}
      inputFormat={inputFormat}
      value={value}
      ref={ref}
      components={{
        OpenPickerIcon:
          textFieldProps?.error || !value ? CalendarSVG : CalendarCheckedSVG,
      }}
      renderInput={(params) => <SHTextField sx={sxTextField} {...params} {...textFieldProps} />}
    />
  ),
);
