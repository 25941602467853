import { SHDialog, SHStack, SHTypography } from "@components/design-systems";
import { LocalStorage } from "@constants";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { SubscriptionBannerHidden } from "@models/users/entities/subscriptionBanner";
import { RootState } from "@redux/store";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function RefreshPageDialog(): JSX.Element {
  const {
    forceRefreshPage: {
      showForceRefreshFirmDialog,
      forceRefreshFirmStartTime,
      triggerForceRefreshFirm,
    },
  } = useSelector((state: RootState) => state.global);
  const { user } = useSelector((state: RootState) => state.auth);
  const adviserFirmId = user?.userMetadata?.adviser_firm_id ?? "";

  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();

  const handleRefreshPage = () => {
    window.location.reload();
  };

  //ticket: 3326 force refresh users
  useEffect(() => {
    if (triggerForceRefreshFirm) {
      const startTime = forceRefreshFirmStartTime;
      const endTime = new Date().getTime() / 1000;
      const spendTime = endTime - startTime;
      const waitTime = Math.max(0, 15 - spendTime);

      setTimeout(() => {
        handleRefreshPage();

        // When Expired or Resubscribe, refresh firm's banner hidden
        const currentBannersHidden: SubscriptionBannerHidden[] =
          getLocalStorageItem(LocalStorage.SubscriptionBannersHidden) ?? [];
        if (!isEmpty(currentBannersHidden))
          setLocalStorageItem(
            LocalStorage.SubscriptionBannersHidden,
            currentBannersHidden?.filter(
              (item) => item.adviserFirmId !== adviserFirmId,
            ),
          );
      }, waitTime * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showForceRefreshFirmDialog,
    forceRefreshFirmStartTime,
    triggerForceRefreshFirm,
  ]);

  return (
    <SHDialog
      disableEscapeKeyDown
      disableBackdropClick
      open={showForceRefreshFirmDialog}
      header="Your firm's subscription status is changing"
      body={
        <SHStack direction={"column"} spacing={3}>
          <SHTypography
            variant="body2"
            sx={{ fontSize: "16px", marginTop: "16px" }}
          >
            We need to refresh SuitabilityHub to update your permissions. This
            process may take a couple of minutes. Apologies for any
            inconvenience
          </SHTypography>
        </SHStack>
      }
    />
  );
}
