import { PageRoutes } from "@constants/routes";
import { UserType } from "@models/auth";
import {
  AdminUsersGroup,
  AllUsersGroup,
  AdviserUsersGroup,
  SupplierUsersGroup,
} from "@models/users/group";
import { LinkToOptions } from "../nav-button";

export enum NarBarName {
  ProductProfile = "Product profiles",
  PlatformAnalysis = "Platform analysis",
  SuitabilityReviews = "Suitability Reviews",
  Practices = "Practices",
  Suppliers = "Suppliers",
  SMAAnalysis = "SMA analysis",
  Configuration = "Configuration",
  NewsAndUpdates = "News & updates",
  Upgrade = "Upgrade",
}

export const appNavigation: {
  name: string;
  linkTo: LinkToOptions;
  roles?: UserType[];
  haveDotGroup?: boolean;
  isBeta?: boolean;
}[] = [
  {
    name: NarBarName.ProductProfile,
    linkTo: { to: PageRoutes.platformProfiles },
  },
  {
    name: NarBarName.PlatformAnalysis,
    linkTo: { to: PageRoutes.platformAnalysis },
    roles: [
      UserType.SupplierPremiumOwner,
      UserType.SupplierPremiumStandard,
      UserType.SupplierBdmSale,
    ],
  },
  {
    name: NarBarName.SuitabilityReviews,
    linkTo: { to: PageRoutes.reviews },
    roles: AdviserUsersGroup,
  },
  {
    name: NarBarName.Practices,
    linkTo: { to: PageRoutes.practices },
    roles: AdminUsersGroup,
  },
  {
    name: NarBarName.Suppliers,
    linkTo: { to: PageRoutes.suppliers },
    roles: AdminUsersGroup,
  },
  {
    name: NarBarName.SMAAnalysis,
    linkTo: { to: PageRoutes.managedAccounts },
    roles: SupplierUsersGroup
    ,
    isBeta: true,
  },
  {
    name: NarBarName.Configuration,
    linkTo: { to: PageRoutes.configuration },
    roles: [UserType.SuperAdmin],
  },
  {
    name: NarBarName.NewsAndUpdates,
    linkTo: { to: PageRoutes.newsAndUpdates },
    roles: AllUsersGroup,
    haveDotGroup: true,
  },
  {
    name: NarBarName.Upgrade,
    linkTo: { to: PageRoutes.practiceSubscription },
    roles: [UserType.AdviserTrial, UserType.AdviserAdminTrial],
  },
];
