import { SVGProps } from "react";

const UsersArSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-2.828-.828a4 4 0 1 1 5.656 5.656 4 4 0 0 1-5.656-5.656ZM4 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM1.879 4.879A3 3 0 1 1 6.12 9.12 3 3 0 0 1 1.88 4.88ZM18 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2.121-1.121A3 3 0 1 1 20.12 9.12a3 3 0 0 1-4.242-4.242ZM11 10.999a4.002 4.002 0 0 0-3.662 2.389L7.097 16h7.806l-.24-2.612A4.001 4.001 0 0 0 11 10.999ZM17 16h3v-1a2 2 0 0 0-3.213-1.59A6.01 6.01 0 0 1 17 15v1Zm-1.07-4.422a6.003 6.003 0 0 0-9.86 0A4 4 0 0 0 0 15v2a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1v-2a4 4 0 0 0-6.07-3.422ZM5.213 13.41A2 2 0 0 0 2 15v1h3v-1c0-.549.074-1.082.213-1.59Z"
      fill="#25324B"
    />
  </svg>
);

export default UsersArSVG;
