import { ReviewDTO } from "@models/reviews/entities/review";
import { ReviewStatus } from "@models/reviews/enums/status";
import { ReviewStore } from ".";
import { isNil } from "lodash";

export const repairUpdateReview = (review: ReviewDTO): ReviewDTO => {
  return {
    ...review,
    setup: null,
    feature: null,
    businessMetric: null,
    fee: null,
    summary: null,
  };
};

export const updateReviewReadOnlyAndComplete = (
  state: ReviewStore,
  data: {
    status?: ReviewStatus | any;
    previousStatus?: ReviewStatus | any;
  },
) => {
  //Set isCompleted
  let newState = { ...state };
  newState.isCompleted =
    data.status === ReviewStatus.Completed ||
    data?.previousStatus === ReviewStatus.Completed;
  //Set isReadOnly
  newState.isReadOnly =
    data.status === ReviewStatus.Archived || newState.isCompleted;
  //Set hidden Selection steps when isReadOnly
  if (
    newState.ui.steps.Feature.subSteps?.Selection.isHidden !== undefined &&
    newState.isReadOnly
  ) {
    newState.ui.steps.Feature.subSteps.Selection.isHidden = true;
  }
  if (
    newState.ui.steps.BusinessMetric.subSteps?.Selection.isHidden !==
      undefined &&
    newState.isReadOnly
  ) {
    newState.ui.steps.BusinessMetric.subSteps.Selection.isHidden = true;
  }
  if (
    newState.ui.steps.Fee.subSteps?.Selection.isHidden !== undefined &&
    newState.isReadOnly
  ) {
    newState.ui.steps.Fee.subSteps.Selection.isHidden = true;
  }
  if (newState.ui.selectedSubStepIndex === 1 && newState.isReadOnly) {
    newState.ui.selectedSubStepIndex = 0;
  }
  //Set shirk column

  if (!isNil(newState.review?.feature)) {
    if (
      newState.review?.feature?.all?.selectedProducts &&
      newState.review?.feature?.all?.selectedProducts?.length > 5
    )
      newState.ui.isShirkColumn = true;
  }

  if (!isNil(newState.review?.businessMetric)) {
    if (
      newState.review?.businessMetric?.all?.selectedProducts &&
      newState.review?.businessMetric?.all?.selectedProducts?.length > 5
    )
      newState.ui.isShirkColumn = true;
  }
  return newState;
};
