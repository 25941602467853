import { linkedinURLRegex, phoneRegex } from "@constants";
import { UserType } from "@models/auth";
import {
  CreateSupplierUserDTO,
  SupplierUserDTO,
  UpdateSupplierUserDTO,
} from "@models/supplier/entities/supplierUser";
import * as Yup from "yup";

export const teamMemberValidators = (
  requiredAssociatedProducts: boolean = true,
) => {
  return Yup.object().shape({
    isPublic: Yup.boolean().nullable(true),
    firstName: Yup.string()
      .required("First name is required")
      .max(100, "First name must be less than 100 characters"),
    lastName: Yup.string()
      .required("Last name is required")
      .max(100, "Last name must be less than 100 characters"),
    email: Yup.string()
      .required("Email is required")
      .email("Email is not valid"),
    userType: Yup.string().required("Account type is required"),
    associatedProducts: requiredAssociatedProducts
      ? Yup.array().when("userType", (userType, schema) => {
          if (
            userType === UserType.SupplierPremiumOwner ||
            userType === UserType.SupplierBasic
          )
            return undefined;
          return schema.min(1);
        })
      : Yup.array(),
    publicEmail: Yup.string()
      .nullable(true)
      .when("isPublic", (isPublic, schema) => {
        return isPublic ? schema.email("Email is not valid") : undefined;
      }),
    linkedInProfileUrl: Yup.string()
      .nullable(true)
      .when("isPublic", (isPublic, schema) => {
        return isPublic
          ? schema.matches(linkedinURLRegex, {
              excludeEmptyString: true,
              message: "Use URL format of https://www.linkedin.com/in/",
            })
          : undefined;
      }),
    publicPhoneNumber: Yup.string()
      .nullable(true)
      .when("isPublic", (isPublic, schema) => {
        return isPublic
          ? schema.matches(phoneRegex, {
              excludeEmptyString: true,
              message: "Phone number is not valid",
            })
          : undefined;
      }),
  });
};

export const accountTypeTitleMapping: any = {
  "Supplier:Basic": "Basic user",
  "Supplier:Premium_Owner": "Owner",
  "Supplier:Premium_Standard": "Standard user",
  "Supplier:BDM_Sale": "Sales user",
};

export const accountTypeSubTitleMapping: any = {
  "Supplier:Basic":
    "Can only use SuitabilityHub to manage their products and make posts to the “News & updates” page on the behalf of their products",
  "Supplier:Premium_Owner":
    "As the Owner of the firm account, has full permissions including access to subscription and user management",
  "Supplier:Premium_Standard":
    "Can use SuitabilityHub for internal purposes only, such as to compare products. Can be granted permission to make changes to products",
  "Supplier:BDM_Sale":
    "Can use SuitabilityHub externally with advisers. Cannot make changes to products nor make posts to the “News & updates” page",
};

export const createSupplierUserConverter = (
  supplierUser: SupplierUserDTO,
): CreateSupplierUserDTO => {
  const newSupplier: CreateSupplierUserDTO = {
    supplierId: supplierUser.supplierId || "",
    firstName: supplierUser.firstName,
    lastName: supplierUser.lastName,
    email: supplierUser.email,
    jobTitle: supplierUser.jobTitle,
    phoneNumber: supplierUser.phoneNumber,
    avatarUrl: supplierUser.avatarUrl,
    avatarS3Key: supplierUser.avatarS3Key,
    userType: supplierUser.userType,
    isPublic: supplierUser.isPublic,
    publicPhoneNumber: supplierUser.publicPhoneNumber,
    preferredContactHours: supplierUser.preferredContactHours,
    publicEmail: supplierUser.publicEmail,
    linkedInProfileUrl: supplierUser.linkedInProfileUrl,
    regions: supplierUser.regions,
    associatedProducts: supplierUser.associatedProducts,
    additionalPermissions: supplierUser.additionalPermissions,
    preferences: supplierUser.preferences,
  };
  return newSupplier;
};

export const updateSupplierUserConverter = (
  supplierUser: SupplierUserDTO,
): UpdateSupplierUserDTO => {
  const newSupplier: UpdateSupplierUserDTO = {
    userId: supplierUser.auth0Id,
    firstName: supplierUser.firstName,
    lastName: supplierUser.lastName,
    phoneNumber: supplierUser.phoneNumber,
    jobTitle: supplierUser.jobTitle,
    avatarUrl: supplierUser.avatarUrl,
    avatarS3Key: supplierUser.avatarS3Key,
    isPublic: supplierUser.isPublic,
    publicPhoneNumber: supplierUser.publicPhoneNumber,
    preferredContactHours: supplierUser.preferredContactHours,
    publicEmail: supplierUser.publicEmail,
    linkedInProfileUrl: supplierUser.linkedInProfileUrl,
    regions: supplierUser.regions,
    userType: supplierUser.userType,
    associatedProducts: supplierUser.associatedProducts,
    additionalPermissions: supplierUser.additionalPermissions,
    preferences: supplierUser.preferences,
  };
  return newSupplier;
};
