import { AnalysisStatus } from "../enums/status";
import { AnalysisStep } from "../enums/step";
import { AnalysisSubStep } from "../enums/subStep";
import { AnalysisStepBusinessMetricDTO } from "./steps/businessMetric";
import { AnalysisFeatureDTO } from "./steps/feature";
import { AnalysisSummaryDTO } from "./steps/summary";

import { ExportableAnalysisStepFeeDTO } from "./steps/fee";
import { AnalysisSetupDTO } from "./steps/setup";
const { Setup } = AnalysisStep;

export class AnalysisSelectionDTO {
  isCompleted: boolean = false;
  status: AnalysisStatus = AnalysisStatus.InProgress;
  hasRecalculate: boolean = false;
}

export class AnalysisListDTO {
  id: string = "";
  name: string = "";
  ownerName: string = "";
  ownerAvatarUrl: string = "";
  adviserName: string = "";
  includeSteps: string = "";
  dataValidDate: Date = new Date();
  lastModifiedDate: Date = new Date();
  status: AnalysisStatus = AnalysisStatus.InProgress;
}

export class AnalysisDTO {
  id: string = "";
  configurationVersion: number = 0;
  currentStep: AnalysisStep = Setup;
  currentSubStep?: AnalysisSubStep;
  databaseVersion: Date = new Date();
  status: AnalysisStatus = AnalysisStatus.InProgress;
  isCompleted: boolean = false;
  isShowAnalysisScore: boolean = false;
  setup: AnalysisSetupDTO | null = new AnalysisSetupDTO();
  feature: AnalysisFeatureDTO | null = null;
  businessMetric: AnalysisStepBusinessMetricDTO | null = null;
  fee: ExportableAnalysisStepFeeDTO | null = null;
  summary: AnalysisSummaryDTO | null = null;
}

export interface AnalysisFilterDTO {
  feeMissingInvestmentsStyle?: any;
  investmentMenu?: any;
  feeTableViewMode?: any;
  feesDisplayStyle?: any;
  appendixFeeMissingInvestmentsStyle?: any;
  showAnalysisScore?: boolean;
  showSubSection?: boolean;
  showSelectedFeatures?: boolean;
  showAppendixFeatureAnalysis?: boolean;
  showAppendixBusinessMetricAnalysis?: boolean;
  showAppendixFeeAnalysis?: boolean;
  hiddenSubProducts?: HiddenSubProductDTO[];
}

export interface HiddenSubProductDTO {
  id: string;
  isRateCard: boolean;
  rateCardVersion: number;
}
