import { HighlightType } from "@models/configuration";
import { RecordType } from "@models/core";
import { ProductDataStatus } from "@models/product/enums/productDataStatus";
import { ConfigurationFeature } from "./feature";

export class ConfigurationSection {
  id?: string = "";
  name: string = "";
  description?: string = "";
  order?: number = 0;
  iconUrl?: string = "";
  iconUrlS3Key?: string = "";
  isArchived?: boolean = false;
  isLocked?: boolean = false;
  flagType?: string = "";
  highlightType?: HighlightType | null = null;
  sectionDisplayType?: RecordType<SectionDisplayTypeId> | null = null;
  createdDate?: Date = new Date();
  subSections?: any[] = [];
  features?: ConfigurationFeature[] | null = [];
  dataStatus?: ProductDataStatus;
  configurationVersion?: number;
  configurationGroupId?: string = "";
  isModified?: null | boolean;
  // Extended
  configurationSectionId?: string = "";
}

export interface ConfigurationSectionLov {
  sectionDisplayTypes: RecordType<SectionDisplayTypeId>[];
  sections?: RecordType<string>[];
}

export interface ConfigurationSectionCreateDTO {
  configurationSectionId?: string;
  name: string;
  description?: string;
  sectionDisplayType?: string;
  iconUrl?: string | null;
  iconS3Key?: string | null;
}

export interface ConfigurationSectionUpdateDTO {
  configurationSubSectionId?: string;
  configurationSectionId?: string;
  name: string;
  description?: string;
  sectionDisplayType?: string;
  iconUrl?: string | null;
  iconS3Key?: string | null;
}

export enum SectionDisplayTypeId {
  MainBodyPage = "MainBodyPage",
  SidePanel = "SidePane",
  SidePanelRow = "SidePaneRow",
  Banner = "Banner",
  Feature = "Feature",
}
