import mainBgImage from "@assets/images/background/bg_login.jpg";
import { SHBox } from "@components/design-systems";
import { Auth0ErrorDialog } from "@components/dialogs/auth0-error";
import LoggedOutDialog from "@components/dialogs/logged-out";
import { ResetPasswordMessageDialog } from "@components/dialogs/reset-password-message";
import { UnauthorizedDialog } from "@components/dialogs/unauthorized";
import WarningLoggedInDialog from "@components/dialogs/warning-logged-in";
import { SocketServerEvent } from "@constants/socket";
import { LocalStorage } from "@constants/storage";
import { useBroadcastChannel } from "@hooks/useBroadcastChannel";
import { SignalRService } from "@services/signalr/signalRService";
import { useSearchParams } from "react-router-dom";

export const CallbackPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { logoutBroadcast } = useBroadcastChannel();

  const handleOnKickout = () => {
    const connection = SignalRService.getHubConnection();
    const browserId = localStorage.getItem(LocalStorage.browserId);
    if (connection && browserId) {
      connection.invoke(SocketServerEvent.onKickout, browserId);
    }
  };

  const handleOnCancel = () => {
    logoutBroadcast(`${process.env.REACT_APP_AUTH0_REDIRECT_URL}`);
  };

  const renderSwitch = (action: any) => {
    switch (action) {
      case "kick-out":
        return <LoggedOutDialog open />;

      case "multi-login-new":
        return (
          <WarningLoggedInDialog
            open
            onYes={handleOnKickout}
            onCancel={handleOnCancel}
          />
        );
      case "inactivity":
        return (
          <LoggedOutDialog
            open
            body="You have been logged due to four hours of inactivity"
          />
        );
      case "reset-password":
        return <ResetPasswordMessageDialog open />;
      case "auth0-error":
        return <Auth0ErrorDialog open />;
      case "unauthorized":
        return <UnauthorizedDialog />;
      default:
        return null;
    }
  };

  return (
    <SHBox
      sx={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        backgroundImage: `url(${mainBgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      data-testid="sh-box"
    >
      {renderSwitch(searchParams?.get("action"))}
    </SHBox>
  );
};
