import { SHStack, SHStackProps } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const SHTextFieldSkeleton = ({ ...others }: SHStackProps) => {
  return (
    <SHStack spacing={1.5} {...others}>
      <SHSkeleton height={18} width="30%" />
      <SHSkeleton height={35} width="100%" />
    </SHStack>
  );
};
