import {
  SMAListCurrentlySelectedFilter,
  SMAListFavouriteFilter,
  SMAListMinimumInvestmentSizeFilter,
} from "@models/managed-accounts/enums/filter";
import { SMAListFilter } from "./components/buttons/filters";
import { CalculatedInvestmentDTO } from "./components/tables/model";
import { filter, isEmpty, some } from "lodash";
import { compareAsc, parse } from "date-fns";

export const applySMAListFilters = (
  filters: SMAListFilter,
  currentSelectedIds: string[],
  initialInvestmentData: CalculatedInvestmentDTO[],
) => {
  let result = initialInvestmentData;

  const applyFilters = [
    {
      condition: filters.favourites === SMAListFavouriteFilter.Favourites,
      filterFn: (data: CalculatedInvestmentDTO[]) =>
        filter(data, { isFavourite: true }),
    },
    {
      condition:
        filters.currentlySelected === SMAListCurrentlySelectedFilter.Selected,
      filterFn: (data: CalculatedInvestmentDTO[]) =>
        filter(data, (r) => currentSelectedIds.includes(r.id)),
    },
    {
      condition:
        filters.minimumInvestmentSize !==
        SMAListMinimumInvestmentSizeFilter.All,
      filterFn: (data: CalculatedInvestmentDTO[]) =>
        filter(
          data,
          (r) =>
            Number(r.column21.value) <=
            {
              [SMAListMinimumInvestmentSizeFilter.All]: 5000000,
              [SMAListMinimumInvestmentSizeFilter.OneHundredThousandOrLess]: 100000,
              [SMAListMinimumInvestmentSizeFilter.FiftyThousandOrLess]: 50000,
              [SMAListMinimumInvestmentSizeFilter.TwentyFiveThousandOrLess]: 25000,
              [SMAListMinimumInvestmentSizeFilter.Zero]: 0,
            }[filters.minimumInvestmentSize],
        ),
    },
    {
      condition: !isEmpty(filters.platforms),
      filterFn: (data: CalculatedInvestmentDTO[]) =>
        filter(data, (r) =>
          some(filters.platforms, {
            productName: r.productName,
            subProductName: r.subProductName,
          }),
        ),
    },
    {
      condition: isEmpty(filters.platforms),
      filterFn: () => [],
    },
  ];

  applyFilters.forEach(({ condition, filterFn }) => {
    if (condition) {
      result = filterFn(result);
    }
  });

  return result;
};

export const combineProductName = (
  productName: string,
  subProductName: string,
) => `${productName} ${subProductName}`;

export const combineModelName = (
  managerName: string,
  name: string,
  platformCode: string,
) => `${managerName} ${name} ${platformCode}`;

export const platformSortingFn = (rowA: any, rowB: any, columnId: any) => {
  const valueA = combineProductName(
    rowA.original.productName,
    rowA.original.subProductName,
  );

  const valueB = combineProductName(
    rowB.original.productName,
    rowB.original.subProductName,
  );

  return valueA.localeCompare(valueB);
};

export const modelSortingFn = (rowA: any, rowB: any, columnId: any) => {
  const valueA = combineModelName(
    rowA.original.managerName,
    rowA.original.name,
    rowA.original.shCode,
  );

  const valueB = combineModelName(
    rowB.original.managerName,
    rowB.original.name,
    rowB.original.shCode,
  );

  return valueA.localeCompare(valueB);
};

export const pdsDateSortingFn = (rowA: any, rowB: any, columnId: any) => {
  const valueA = rowA.original[columnId].value;
  const valueB = rowB.original[columnId].value;

  const dateA = parse(valueA, "dd/MM/yy", new Date());
  const dateB = parse(valueB, "dd/MM/yy", new Date());

  return compareAsc(dateA, dateB);
};
