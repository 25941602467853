import { SHButton, SHStack, SHTypography } from "@components/design-systems";
import { EditSVG } from "@components/svgs";
import { usePractice } from "@hooks/usePractice";
import { usePracticeBillingInfo } from "@hooks/usePracticeBillingInfo";
import { BillingDetailDTO } from "@models/practice/entities/practiceBillingInfo";
import { Collapse, useTheme } from "@mui/material";
import {
  AddressElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useEffect, useRef, useState } from "react";

import UnsavedDialog from "@components/dialogs/unsaved";
import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";
import { WrapperStripeElement } from "../wrapper-stripe-element";
import {
  BillingContact,
  BillingContactPropsRef,
} from "./components/billing-contact";
import {
  checkIsValidBillingAddress,
  convertToStripeBillingAddressDTO,
} from "./util";
import { isEmpty } from "lodash";

export type BillingInfoRightSideProps = {};

export const BillingInfoRightSide = (props: BillingInfoRightSideProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { palette } = useTheme();
  const { practiceId, setPracticeName, practice } = usePractice();

  const {
    setIsDirtyBillingAddress,
    setIsValidBillingAddress,
    updateBillingDetail,
    practiceBillingDetail,
    resetBillingDetailUI,
    ui: {
      billingDetail: { billingContact, billingAddress },
    },
  } = usePracticeBillingInfo();

  const contactRef = useRef<BillingContactPropsRef | null>(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showBillingContact, setShowBillingContact] = useState(false);

  const handleOnChangeBillingAddress = (
    event: StripeAddressElementChangeEvent,
  ) => {
    const {
      name,
      address: { line1, line2, city, country, postal_code, state },
    } = event.value;

    const initBillingAddress = practiceBillingDetail?.billingAddress;
    const currentBillingAddress = {
      name: name,
      line1: line1,
      line2: line2,
      city: city,
      state: state,
      postalCode: postal_code,
      country: country,
    };
    setIsDirtyBillingAddress(
      JSON.stringify(currentBillingAddress) !==
        JSON.stringify({
          ...initBillingAddress,
          line2: isEmpty(initBillingAddress?.line2)
            ? null
            : initBillingAddress?.line2,
        }),
    );
    setIsValidBillingAddress(checkIsValidBillingAddress(currentBillingAddress));
  };

  const handleOnSubmit = async () => {
    if (!stripe || !elements) return;
    setIsSubmitting(true);

    const billingContact = contactRef?.current?.getValues()!;

    const addressElement = elements?.getElement("address");

    if(billingAddress.isDirty){
      const billingAddress = (await addressElement?.getValue())?.value!;

      if (billingAddress && billingContact) {
        const {
          name,
          address: { city, country, line1, line2, postal_code, state },
        } = billingAddress;
        const reqData = {
          billingAddress: {
            name: name,
            city: city,
            state: state,
            line1: line1,
            line2: line2,
            country: country,
            postalCode: postal_code,
          },
          billingContact: billingContact,
        } as BillingDetailDTO;
        await updateBillingDetail(practiceId ?? "", reqData);
      }
    }
    else{
      const reqData = {
        billingAddress: practiceBillingDetail?.billingAddress,
        billingContact: billingContact,
      } as BillingDetailDTO;
      await updateBillingDetail(practiceId ?? "", reqData);
    }

    setIsEditMode(false);
    setIsSubmitting(false);
    resetBillingDetailUI();
  };

  useEffect(() => {
    setIsDirty(billingContact.isDirty || billingAddress.isDirty);
  }, [billingContact.isDirty, billingAddress.isDirty]);

  useEffect(() => {
    if(!billingAddress.isDirty){
      setIsValid(billingContact.isValid)
    }
    else{
      setIsValid(billingContact.isValid && billingAddress.isValid);
    }
  }, [billingContact.isValid, billingAddress.isValid, billingAddress.isDirty]);

  return (
    <SHStack spacing={2} width={{ sx: "100%", md: "46%" }} minWidth={"300px"}>
      <SHStack flexDirection={"row"} justifyContent={"space-between"}>
        <SHTypography>Billing details</SHTypography>
        {!isEditMode ? (
          <SHButton
            startIcon={<EditSVG color={palette.text.white} />}
            variant="contained"
            onClick={() => {
              setIsEditMode(true);
            }}
          >
            Update information
          </SHButton>
        ) : (
          <SHStack direction={"row"} spacing={1}>
            <SHButton
              variant="outlined"
              onClick={() => {
                setIsEditMode(false);
                setPracticeName(practice?.name ?? "");
                if (practiceBillingDetail?.billingContact)
                  contactRef.current?.resetValues(
                    practiceBillingDetail?.billingContact,
                  );
              }}
            >
              Cancel
            </SHButton>
            <SHButton
              disabled={isSubmitting || !isValid || !isDirty}
              isLoading={isSubmitting}
              variant="contained"
              onClick={handleOnSubmit}
            >
              Save
            </SHButton>
          </SHStack>
        )}
      </SHStack>

      {practiceBillingDetail?.billingAddress &&
        practiceBillingDetail?.billingContact && (
          <WrapperStripeElement
            isViewMode={!isEditMode}
            spacing={1}
            minHeight={650}
          >
            <Collapse in={showBillingContact} timeout={500}>
              {isEditMode && (
                <>
                  <AddressElement
                    id="sh-stripe-address-element-view-mode"
                    options={{
                      mode: "billing",
                      display: {
                        name: "organization",
                      },
                      defaultValues: convertToStripeBillingAddressDTO(
                        practiceBillingDetail?.billingAddress ?? null,
                      ),
                    }}
                    onReady={(element) => {
                      setShowBillingContact(true);
                    }}
                    onChange={handleOnChangeBillingAddress}
                  />
                  {showBillingContact && (
                    <BillingContact
                      billingContact={practiceBillingDetail?.billingContact}
                      ref={contactRef}
                    />
                  )}
                </>
              )}
              {!isEditMode && (
                <>
                  <AddressElement
                    id="sh-stripe-address-element-view-mode"
                    options={{
                      mode: "billing",
                      display: {
                        name: "organization",
                      },
                      defaultValues: convertToStripeBillingAddressDTO(
                        practiceBillingDetail?.billingAddress ?? null,
                      ),
                    }}
                    onReady={(element) => {
                      setShowBillingContact(true);
                    }}
                    onChange={handleOnChangeBillingAddress}
                  />
                  {showBillingContact && (
                    <BillingContact
                      billingContact={practiceBillingDetail?.billingContact}
                      ref={contactRef}
                    />
                  )}
                </>
              )}
            </Collapse>
          </WrapperStripeElement>
        )}

      <UnsavedDialog isDirty={isDirty} />
    </SHStack>
  );
};
