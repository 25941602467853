import { SHButton, SHDialog, SHStack } from "@components/design-systems";

interface ResetPasswordDialogProps {
  onYes?: () => void;
  onClose?: () => void;
  isSending?: boolean;
  isMyProfile?: boolean;
}

const ResetPasswordDialog = ({
  onYes,
  onClose,
  isSending = false,
  isMyProfile,
}: ResetPasswordDialogProps) => {
  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      header={
        isMyProfile
          ? "Are you sure you want to reset your password?"
          : "Are you sure you want to reset this user's password?"
      }
      body={
        isMyProfile
          ? "You will be sent a password reset email."
          : "The user will be sent a password reset email."
      }
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onClose}
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onYes}
            disabled={isSending}
            isLoading={isSending}
          >
            Yes, reset password
          </SHButton>
        </SHStack>
      }
    />
  );
};

export default ResetPasswordDialog;
