import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const ProductCardSkeleton = () => {
  return (
    <SHStack width={{ lg: "32.75%", md: "49.33%", sm: "49.3%" }}>
      <SHSkeleton height={100} width={"100%"} />
    </SHStack>
  );
};
