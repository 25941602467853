import { UserType } from "@models/auth";
import { AdviserUserDTO } from "@models/practice/entities/practiceUser";
import { AdviserAdminUsersGroup } from "@models/users/group";

export const compileAdviserUser = (
  adviserUser: AdviserUserDTO,
): AdviserUserDTO => {
  if (adviserUser.userType) {
    if (AdviserAdminUsersGroup.includes(adviserUser.userType))
      adviserUser.hasAdminPrivileges = true;
    if (adviserUser.userType === UserType.AdviserAdminTrial)
      adviserUser.userType = UserType.AdviserTrial;
    if (adviserUser.userType === UserType.AdviserAdminAdviser)
      adviserUser.userType = UserType.AdviserAdviser;
    if (adviserUser.userType === UserType.AdviserAdminSupportStaff)
      adviserUser.userType = UserType.AdviserSupportStaff;
  }
  return adviserUser;
};
