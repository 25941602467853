import {
  CreateFeatureTemplateDTO,
  FeatureTemplateDTO,
  FeatureTemplateSelectionDTO,
} from "@models/feature-template/entities/featureTemplate";
import { FeatureTemplateStatus } from "@models/feature-template/enums/status";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getExistingFeatureTemplate,
  getExistingFeatureTemplateForReview,
  getFeatureTemplate,
  getFeatureTemplateLOV,
  postFeatureTemplate,
  putFeatureTemplate,
  putFeatureTemplateStatus,
} from "@services/feature-template/featureTemplateService";

export interface FeatureTemplateStore {
  featureTemplate?: FeatureTemplateDTO;
  existingFeatureTemplate?: FeatureTemplateSelectionDTO[];
  existingFeatureTemplateForReview?: FeatureTemplateSelectionDTO[];
  featureTemplateUI: {
    isLoading?: boolean;
    isSubmitting?: boolean;
  };
}

const initialState: FeatureTemplateStore = {
  featureTemplateUI: {
    isLoading: false,
    isSubmitting: false,
  },
};

const featureTemplateSlice = createSlice({
  name: "featureTemplate",
  initialState,
  reducers: {
    setFeatureTemplateAction: (
      state,
      action: PayloadAction<FeatureTemplateDTO>,
    ) => {
      state.featureTemplate = action.payload;
    },
    setExistingFeatureTemplateAction: (
      state,
      action: PayloadAction<FeatureTemplateSelectionDTO[]>,
    ) => {
      state.existingFeatureTemplate = action.payload;
    },
    resetFeatureTemplateStore: (state, action: PayloadAction) => {
      state.featureTemplate = undefined;
      state.existingFeatureTemplate = undefined;
      state.featureTemplateUI = initialState.featureTemplateUI;
    },
  },
  extraReducers(builder) {
    // Load Feature Template detail
    builder.addCase(loadFeatureTemplateThunk.pending, (state, action) => {
      state.featureTemplateUI.isLoading = true;
    });
    builder.addCase(loadFeatureTemplateThunk.fulfilled, (state, action) => {
      state.featureTemplate = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.featureTemplateUI.isLoading = false;
    });
    builder.addCase(loadFeatureTemplateThunk.rejected, (state, action) => {
      state.featureTemplateUI.isLoading = false;
    });

    // Load Feature Template LOV
    builder.addCase(loadFeatureTemplateLOVThunk.pending, (state, action) => {
      state.featureTemplateUI.isLoading = true;
    });
    builder.addCase(loadFeatureTemplateLOVThunk.fulfilled, (state, action) => {
      state.featureTemplate = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.featureTemplateUI.isLoading = false;
    });
    builder.addCase(loadFeatureTemplateLOVThunk.rejected, (state, action) => {
      state.featureTemplateUI.isLoading = false;
    });

    // Create Feature Template
    builder.addCase(createFeatureTemplateThunk.pending, (state, action) => {
      state.featureTemplateUI.isSubmitting = true;
    });
    builder.addCase(createFeatureTemplateThunk.fulfilled, (state, action) => {
      state.featureTemplate = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.featureTemplateUI.isSubmitting = false;
    });
    builder.addCase(createFeatureTemplateThunk.rejected, (state, action) => {
      state.featureTemplateUI.isSubmitting = false;
    });

    // Update Feature Template
    builder.addCase(updateFeatureTemplateThunk.pending, (state, action) => {
      state.featureTemplateUI.isSubmitting = true;
    });
    builder.addCase(updateFeatureTemplateThunk.fulfilled, (state, action) => {
      state.featureTemplate = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.featureTemplateUI.isSubmitting = false;
    });
    builder.addCase(updateFeatureTemplateThunk.rejected, (state, action) => {
      state.featureTemplateUI.isSubmitting = false;
    });

    // Update Feature Template status
    builder.addCase(
      updateFeatureTemplateStatusThunk.pending,
      (state, action) => {
        state.featureTemplateUI.isSubmitting = true;
      },
    );
    builder.addCase(
      updateFeatureTemplateStatusThunk.fulfilled,
      (state, action) => {
        state.featureTemplateUI.isSubmitting = false;
        if (
          state.featureTemplate?.status &&
          action.payload.isSuccess &&
          action.payload.data
        ) {
          state.featureTemplate.status = action.payload.data;
        }
      },
    );
    builder.addCase(
      updateFeatureTemplateStatusThunk.rejected,
      (state, action) => {
        state.featureTemplateUI.isSubmitting = false;
      },
    );

    // Load Existing Feature Template
    builder.addCase(
      loadExistingFeatureTemplateThunk.pending,
      (state, action) => {
        state.featureTemplateUI.isLoading = true;
      },
    );
    builder.addCase(
      loadExistingFeatureTemplateThunk.fulfilled,
      (state, action) => {
        state.existingFeatureTemplate = action.payload.isSuccess
          ? action.payload?.data
          : undefined;
        state.featureTemplateUI.isLoading = false;
      },
    );
    builder.addCase(
      loadExistingFeatureTemplateThunk.rejected,
      (state, action) => {
        state.featureTemplateUI.isLoading = false;
      },
    );

    // Load Existing Feature Template For Review
    builder.addCase(
      loadExistingFeatureTemplateForReviewThunk.pending,
      (state, action) => {
        state.featureTemplateUI.isLoading = true;
      },
    );
    builder.addCase(
      loadExistingFeatureTemplateForReviewThunk.fulfilled,
      (state, action) => {
        state.existingFeatureTemplateForReview = action.payload.isSuccess
          ? action.payload?.data
          : undefined;
        state.featureTemplateUI.isLoading = false;
      },
    );
    builder.addCase(
      loadExistingFeatureTemplateForReviewThunk.rejected,
      (state, action) => {
        state.featureTemplateUI.isLoading = false;
      },
    );
  },
});

// Thunk functions
export const loadFeatureTemplateThunk = createAsyncThunk(
  `feature-template/load`,
  async (featureTemplateId: string) => {
    return await getFeatureTemplate(featureTemplateId);
  },
);

export const loadFeatureTemplateLOVThunk = createAsyncThunk(
  `feature-template/load-lov`,
  async () => {
    return await getFeatureTemplateLOV();
  },
);

export const createFeatureTemplateThunk = createAsyncThunk(
  `feature-template/create`,
  async (featureTemplate: CreateFeatureTemplateDTO) => {
    return await postFeatureTemplate(featureTemplate);
  },
);

export const updateFeatureTemplateThunk = createAsyncThunk(
  `feature-template/update`,
  async (featureTemplate: FeatureTemplateDTO) => {
    return await putFeatureTemplate(featureTemplate);
  },
);

export const updateFeatureTemplateStatusThunk = createAsyncThunk(
  `feature-template/updateStatus`,
  async ({
    featureTemplateId,
    status,
  }: {
    featureTemplateId: string;
    status: FeatureTemplateStatus;
  }) => {
    return await putFeatureTemplateStatus(featureTemplateId, status);
  },
);

export const loadExistingFeatureTemplateThunk = createAsyncThunk(
  `feature-template/existing`,
  async (filterByName?: string) => {
    return await getExistingFeatureTemplate(filterByName);
  },
);

export const loadExistingFeatureTemplateForReviewThunk = createAsyncThunk(
  `feature-template-for-review/existing`,
  async (filterByName?: string) => {
    return await getExistingFeatureTemplateForReview(filterByName);
  },
);

export const {
  setFeatureTemplateAction,
  setExistingFeatureTemplateAction,
  resetFeatureTemplateStore,
} = featureTemplateSlice.actions;
export default featureTemplateSlice.reducer;
