import {
  SHAutocomplete,
  SHBox,
  SHCheckbox,
  SHRadio,
  SHStack,
  SHTextFieldProps,
  SHTypography,
} from "@components/design-systems";
import { SelectedFamilyMemberDTO } from "@models/reviews/entities/steps/setup";
import { useTheme } from "@mui/material";
export interface SelectFamilyGroupMembersProps {
  disabled?: boolean;
  value: string[] | string | null | undefined; //Selected products Id
  onChange: (value: string[] | string | null | undefined) => void;
  familyGroupMemberData: SelectedFamilyMemberDTO[];
  textFieldProps?: Omit<SHTextFieldProps, "value">;
  familyGroupId?: string;
  mainPlatformId?: string | null;
  multiple?: boolean;
  maxLength?: number;
  apiPath?: string;
}
export const SelectFamilyGroupMembers = ({
  disabled,
  value = [],
  onChange,
  familyGroupMemberData,
  textFieldProps,
  multiple,
  mainPlatformId,
  maxLength,
  apiPath,
}: SelectFamilyGroupMembersProps) => {
  const { palette } = useTheme();

  const selectedMembers = familyGroupMemberData?.filter(
    (option) =>
      (multiple
        ? value?.includes(option.order?.toString() ?? "")
        : value === option.order?.toString()),
  );

  const compileValue = multiple ? selectedMembers : selectedMembers?.[0];

  return (
    <SHStack spacing={"16px"} direction="column">
      <SHAutocomplete
        multiple={multiple}
        value={compileValue || null}
        disabled={disabled}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) =>
          option.order?.toString() === value?.order?.toString()
        }
        getOptionLabel={(option: SelectedFamilyMemberDTO) => option.name}
        textFieldProps={{
          ...textFieldProps,
          InputLabelProps: { shrink: true },
          placeholder:
            value?.length && multiple ? undefined : textFieldProps?.placeholder,
          InputProps: {
            startAdornment:
              selectedMembers?.length && multiple ? (
                <SHTypography
                  disabled={disabled}
                  variant="body1"
                  sx={{ paddingLeft: "10px" }}
                >
                  {`${selectedMembers?.length}/${maxLength} selected`}
                </SHTypography>
              ) : undefined,
          },
        }}
        onChange={(e, newValue, r, details) => {
          if (!multiple) {
            onChange(!Array.isArray(newValue) ? newValue?.id ?? null : null);
            return;
          }
          if (!Array.isArray(newValue)) {
            onChange([]);
            return;
          }
          if (maxLength && newValue?.length > maxLength) return;
          onChange(newValue.map((option) => option.order?.toString() ?? ""));
        }}
        options={familyGroupMemberData ?? []}
        renderOption={(props, option, { selected }) => {
          const isChecked = selected;
          return (
            <li
              {...props}
              key={option.order?.toString()}
              style={{ padding: "1px 10px" }}
            >
              <SHStack alignItems={"center"} direction="row" spacing={1}>
                {multiple ? (
                  <SHCheckbox checked={isChecked} />
                ) : (
                  <SHRadio checked={isChecked} />
                )}
                <SHTypography variant="body3" fontWeight={500}>
                  {option.name}
                </SHTypography>
              </SHStack>
            </li>
          );
        }}
      />
      {multiple && (
        <SHBox
          sx={{
            height: "100px",
            px: "12px",
            py: "10px",
            border: `1px solid ${palette.divider}`,
            overflowY: "auto",
          }}
          p={"12px"}
        >
          {selectedMembers?.length !== 0 ? (
            <SHTypography variant="body2">
              {selectedMembers?.map((option) => option.name)?.join(", ")}
            </SHTypography>
          ) : (
            <SHTypography variant="body2" colorVariant="disabled">
              Family member selected will be show here
            </SHTypography>
          )}
        </SHBox>
      )}
    </SHStack>
  );
};
