import { SHStack } from "@components/design-systems";
import ReactRouterPrompt from "react-router-prompt";
import { SHButton } from "../../design-systems/sh-button";
import { SHDialog } from "../../design-systems/sh-dialog";

interface UnsavedDialogProps {
  isDirty: boolean;
}

function UnsavedDialog({ isDirty }: UnsavedDialogProps): JSX.Element {
  return (
    <ReactRouterPrompt when={isDirty}>
      {({ isActive, onConfirm, onCancel }) => (
        <SHDialog
          disableEscapeKeyDown
          disableBackdropClick
          open={isActive}
          onClose={onConfirm}
          width={400}
          header="You have unsaved changes"
          body="Would you like to discard them?"
          footer={
            <SHStack
              width="100%"
              direction="row"
              spacing={7}
              alignItems="center"
              justifyContent="center"
            >
              <SHButton
                color="secondary"
                onClick={onConfirm}
                variant="text"
                size="extraMedium"
              >
                Discard
              </SHButton>
              <SHButton
                color="primary"
                size="extraMedium"
                variant="contained"
                onClick={onCancel}
              >
                Keep editing
              </SHButton>
            </SHStack>
          }
        />
      )}
    </ReactRouterPrompt>
  );
}

export default UnsavedDialog;
