import { useNotification } from "@hooks/useNotification";
import {
  AdviserFirmDTO,
  UpdateAdviserFirmDTO,
} from "@models/practice/entities/practice";
import {
  SubscriptionDTO,
  UpdateUserSubscriptionDTO,
} from "@models/practice/entities/practiceLicences";
import {
  AdviserFirmStatus,
  AdviserUserStatusAction,
} from "@models/practice/enums/status";
import {
  createPracticeThunk,
  createPracticeUserLicencesThunk,
  loadLicencesAdditionalCostThunk,
  loadPracticeLicencesThunk,
  loadPracticeThunk,
  loadPracticeUserLicencesThunk,
  setBillingInfoShowRedDot,
  setIsDirtySubscriptionAction,
  setPracticeAction,
  setPracticeLicenceAction,
  setPracticeNameAction,
  updateLicencesRenewalThunk,
  updatePracticeLicencesThunk,
  updatePracticeStatusThunk,
  updatePracticeThunk,
  updatePracticeUserLicencesThunk,
} from "@redux/slices/practice";
import { UpdateRenewalDTO } from "./../models/practice/entities/practiceLicences";

import { RootState, useAppDispatch } from "@redux/store";
import { getOrangeDotBillingInfo } from "@services/practice/practiceBillingInfoService";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getLicenseeByAFSL } from "@services/suppliers";
import { SubscriptionProductType } from "@models/practice/enums/subscription";
import { getSubscriptionPricingInfo } from "@services/practice/practiceLicencesService";

export const usePractice = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { practiceId } = useParams<{ practiceId: string }>();
  const {
    practice,
    practiceLicences,
    practiceUserLicences,
    practiceInvoices,
    ui,
  } = useSelector((state: RootState) => state.practice);
  const status = useMemo(() => {
    return {
      isActive: practice?.status === AdviserFirmStatus.Active,
      isDisabled: practice?.status === AdviserFirmStatus.Disabled,
      isArchived: practice?.status === AdviserFirmStatus.Archived,
    };
  }, [practice?.status]);

  const setPractice = (practice: AdviserFirmDTO | undefined) => {
    dispatch(setPracticeAction(practice));
  };
  const setPracticeLicence = (licences: SubscriptionDTO) => {
    dispatch(setPracticeLicenceAction(licences));
  };
  const setPracticeName = (practiceName: string) => {
    dispatch(setPracticeNameAction(practiceName));
  };
  const setIsDirtySubscription = (isDirty: boolean) => {
    dispatch(setIsDirtySubscriptionAction(isDirty));
  };

  const loadPractice = async (practiceId: string) => {
    const response = await dispatch(loadPracticeThunk(practiceId)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadPracticeLicences = async (practiceId: string) => {
    const response = await dispatch(
      loadPracticeLicencesThunk(practiceId),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const createPractice = async (practice: AdviserFirmDTO) => {
    const response = await dispatch(createPracticeThunk(practice)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updatePractice = async (practice: UpdateAdviserFirmDTO) => {
    const response = await dispatch(updatePracticeThunk(practice)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updatePracticeStatus = async ({
    practiceId,
    status,
  }: {
    practiceId: string;
    status: AdviserUserStatusAction;
  }) => {
    const response = await dispatch(
      updatePracticeStatusThunk({ practiceId, status }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updatePracticeLicences = async ({
    practiceId,
    practiceLicences,
  }: {
    practiceId: string;
    practiceLicences: SubscriptionDTO;
  }) => {
    const response = await dispatch(
      updatePracticeLicencesThunk({ practiceId, practiceLicences }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  // Adviser User - Subscription
  const loadPracticeUserLicences = async (practiceId: string) => {
    const response = await dispatch(
      loadPracticeUserLicencesThunk(practiceId),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const createPracticeUserLicences = async (
    practiceId: string,
    quantity: number,
  ) => {
    const response = await dispatch(
      createPracticeUserLicencesThunk({ practiceId, quantity }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    // notify(response.message, {
    //   variant: "success",
    //   close: true,
    // });
    return response.data;
  };

  const updatePracticeUserLicences = async (
    practiceId: string,
    updateUserSubscription: UpdateUserSubscriptionDTO,
  ) => {
    const response = await dispatch(
      updatePracticeUserLicencesThunk({ practiceId, updateUserSubscription }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const loadLicencesAdditionalCost = async (
    practiceId: string,
    quantity: number,
  ) => {
    const response = await dispatch(
      loadLicencesAdditionalCostThunk({ practiceId, quantity }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const updateLicencesRenewal = async (
    practiceId: string,
    updateRenewalDto: UpdateRenewalDTO,
  ) => {
    const response = await dispatch(
      updateLicencesRenewalThunk({ practiceId, updateRenewalDto }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const loadOrangeDotBillingInfo = async (practiceId: string) => {
    const { isSuccess, data } = await getOrangeDotBillingInfo(practiceId);

    if (isSuccess) {
      const currentOrangeDot = ui.billingInfo.showRedDot;
      const shouldShowOrangeDot = data === undefined ? false : data;

      dispatch(setBillingInfoShowRedDot(shouldShowOrangeDot));

      return (
        (currentOrangeDot && !shouldShowOrangeDot) ||
        (!currentOrangeDot && shouldShowOrangeDot) ||
        (currentOrangeDot && shouldShowOrangeDot)
      );
    }
  };

  const loadLicenseeByAFSL = async (afsl: string) => {
    const { isSuccess, data } = await getLicenseeByAFSL(afsl);

    if (isSuccess && data) {
      return data;
    }

    return;
  };

  const loadPracticePricingInfo = async (
    practiceId: string,
    productType: SubscriptionProductType,
  ) => {
    const { isSuccess, data } = await getSubscriptionPricingInfo(
      practiceId,
      productType,
    );

    if (isSuccess && data) {
      return data;
    }

    return;
  };

  return {
    practiceId,
    practice,
    practiceInvoices,
    practiceLicences,
    practiceUserLicences,
    ui,
    status,
    setPractice,
    setPracticeName,
    setPracticeLicence,
    setIsDirtySubscription,
    loadPractice,
    loadPracticeLicences,
    loadPracticeUserLicences,
    loadLicencesAdditionalCost,
    loadOrangeDotBillingInfo,
    createPractice,
    createPracticeUserLicences,
    updatePractice,
    updatePracticeStatus,
    updatePracticeLicences,
    updatePracticeUserLicences,
    updateLicencesRenewal,
    loadLicenseeByAFSL,
    loadPracticePricingInfo
  };
};
