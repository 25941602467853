import { BackButton } from "@components/buttons/back";
import {
  SHBox,
  SHBoxProps,
  SHChip,
  SHChipProps,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { ConfigurationPlacementId, SupplierPlacementId } from "@constants/ui";
import { isNumber, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { NavigateOptions, To, useNavigate } from "react-router";

export interface TopBarProps {
  title?: React.ReactNode;
  customBadge?: React.ReactNode;
  badgeProps?: SHChipProps;
  tools?: React.ReactNode;
  navigate?:
    | {
        to: To;
        options?: NavigateOptions;
      }
    | number;
}
export interface TopBarPlacementProps {
  id?: string;
}
export interface TopBarContainerProps {
  id?: string;
  placementId?: string;
  children?: React.ReactNode;
}

export const TopBar = ({
  title,
  customBadge,
  badgeProps,
  tools,
  navigate,
}: TopBarProps) => {
  const navigation = useNavigate();
  return (
    <SHStack
      justifyContent={"space-between"}
      direction={{ xs: "column", sm: "row" }}
      alignItems={{ xs: "start", sm: "center" }}
      spacing={1}
    >
      <SHStack direction="row" alignItems={"center"} spacing={2}>
        {!isUndefined(navigate) && (
          <BackButton
            onClick={() =>
              isNumber(navigate)
                ? navigation(navigate)
                : navigation(navigate.to, navigate.options)
            }
          />
        )}
        {title && (
          <SHTypography
            style={{ marginLeft: isUndefined(navigate) ? undefined : 8 }}
            variant="h1"
          >
            {title}
          </SHTypography>
        )}
        {badgeProps && <SHChip {...badgeProps} />}
        {customBadge}
      </SHStack>
      {tools && (
        <SHStack direction="row" alignItems={"center"} spacing={2}>
          {tools}
        </SHStack>
      )}
    </SHStack>
  );
};

export const TopBarPlacement = ({
  id = SupplierPlacementId,
  ...others
}: TopBarPlacementProps & SHBoxProps) => <SHBox {...others} id={id} />;

export const ConfigurationTopBarPlacement = ({
  id = ConfigurationPlacementId,
}: TopBarPlacementProps) => <SHBox id={id} />;

export const TopBarContainer = ({
  id = "top-bar-container",
  placementId = SupplierPlacementId,
  children,
}: TopBarContainerProps) => {
  const [placement, setPlacment] = useState(
    document.getElementById(placementId),
  );

  useEffect(() => {
    setPlacment(document.getElementById(placementId));
  }, [placementId]);

  if (!placement) return null;
  return createPortal(<SHBox id={id}>{children}</SHBox>, placement);
};
