import { PDFPageBody } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/pages/components/body";
import { PDFPageFooter } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/pages/components/footer";
import { PDFPageHeader } from "@pages/platform-analysis/components/buttons/export-pdf/components/layouts/pages/components/header";
import { DEFAULT_GAP_2 } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { PDFAnalysisOverview } from "@pages/platform-analysis/components/buttons/export-pdf/model";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";

import {
  PDFBackgroundDarkS3,
  PDFBackgroundLightS3,
} from "@pages/platform-analysis/components/buttons/export-pdf/config";
import { Image, Page, View } from "@react-pdf/renderer";
import React from "react";
interface PDFPageContainerProps {
  children?: React.ReactNode;
  isFirstPage?: boolean;
  totalPage?: number;
  pageIndex?: number;
  footerData?: PDFAnalysisOverview;
}

export const PDFPageContainer = ({
  children,
  isFirstPage = false,
  totalPage,
  pageIndex,
  footerData,
}: PDFPageContainerProps) => {
  const { pageStyle } = useStyles();

  return (
    <Page style={{ ...pageStyle.container }}>
      <View style={{ ...pageStyle.layout }}>
        <PDFPageHeader isFirstPage={isFirstPage} />
        <View style={{ gap: DEFAULT_GAP_2 }}>
          <PDFPageBody isFirstPage={isFirstPage}>{children}</PDFPageBody>
          <PDFPageFooter
            pageIndex={pageIndex}
            totalPage={totalPage}
            data={footerData}
            isFirstPage={isFirstPage}
          />
        </View>
      </View>
      {/* Background image */}
      <Image
        src={isFirstPage ? PDFBackgroundDarkS3 : PDFBackgroundLightS3}
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          position: "absolute",
        }}
      />
    </Page>
  );
};
