import {
  SHButton,
  SHDivider,
  SHHtmlBlock,
  SHImage,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { PostStatus } from "@models/news-and-updates/enums/status";
import { Link, useTheme } from "@mui/material";
import {
  NewsFeedProductDTO,
  PostDetailDTO,
  PostDTO,
} from "@models/news-and-updates/entities/post";
import { useUserPermissions } from "@hooks/userUserPermission";
import { ReadMoreDialog } from "../read-more";
import { useEffect, useRef, useState } from "react";
import { PostDetailDialog } from "../post-detail-dialog";
import { getPostDetail } from "@services/newsfeed/postService";
import { useNotification } from "@hooks/useNotification";
import { ImagesCarousel } from "../../images-carousel";
import { ArchivePostButton } from "../../buttons/archive-post";
import { RestorePostButton } from "../../buttons/restore-post";
import { ApprovePostButton } from "../../buttons/approve-post";
import { orderBy } from "lodash";
import { useNewsFeed } from "@hooks/useNewsFeed";
import { format } from "date-fns";
import { DateFormat } from "@constants/format";
export interface PostItemProps {
  postData: PostDTO;
  productData: NewsFeedProductDTO[];
  index: number;
}
export const PostItem = (props: PostItemProps) => {
  const { postData, index, productData } = props;
  const { palette } = useTheme();
  const { notify } = useNotification();
  const [post, setPost] = useState<PostDTO>(postData);
  const { isSuperAdmin } = useUserPermissions();
  const isShowDelButton = post.canBeArchived;
  const isShowEditButton = post.canBeEdited;

  const isShowRestoreButton = post.canBeRestored;
  const isShowApproveButton = post.canBePublished;

  const [openReadMoreDialog, setOpenReadMoreDialog] = useState<boolean>(false);
  const [isDisplayEditDialog, setDisplayEditDialog] = useState<boolean>(false);
  const { loadPosts } = useNewsFeed();
  const [editData, setEditData] = useState<PostDetailDTO>({
    id: post.id,
    productId: post.productId,
    product: {
      id: post.productId,
      logo: post.productLogo ?? "",
      name: post.productName ?? "",
    },
    title: post.title,
    content: post.content,
    publishedDate: post.publishedDate,
    lastUpdatedDate: post.lastUpdatedDate,
    attachments: post.attachments,
  });
  const date: Date = post.publishedDate
    ? post.publishedDate
    : post.lastUpdatedDate;

  const [isReadMoreButtonVisible, setIsReadMoreButtonVisible] =
    useState<boolean>(false);

  const onCloseReadMoreDialog = () => {
    setOpenReadMoreDialog(false);
  };

  const onCloseEditPostDialog = () => {
    setDisplayEditDialog(false);
  };

  const loadPostDetail = async (id: string) => {
    const { isSuccess, data, message } = await getPostDetail(id);
    if (!isSuccess) {
      notify(message, {
        variant: "error",
        close: true,
      });

      return;
    }
    if (data?.result) {
      const newPostData = data?.result[0];
      setPost(newPostData);
      const newEditValue: PostDetailDTO = {
        ...editData,
        content: newPostData.content,
        title: newPostData.title,
        attachments: newPostData.attachments,
        lastUpdatedDate: newPostData.lastUpdatedDate,
        publishedDate: newPostData.publishedDate,
      };
      setEditData(newEditValue);
    }
  };

  const handleOnUpdatePost = async () => {
    if (post.status === PostStatus.Published) {
      await loadPostDetail(post.id);
    } else {
      loadPosts();
    }
  };

  const contentRef: React.Ref<any> = useRef();

  useEffect(() => {
    if (!contentRef.current) return; // wait for the element ref to be available
    const resizeObserver = new ResizeObserver(() => {
      setIsReadMoreButtonVisible(
        contentRef.current?.scrollHeight > contentRef.current?.clientHeight,
      );
    });
    resizeObserver.observe(contentRef.current);
    //clean up
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <SHStack spacing={2}>
        {index !== 0 && <SHDivider sx={{ paddingTop: "25px" }} />}
        <SHStack direction="row" spacing={1.5} alignItems={"flex-start"}>
          <SHImage height={46} width={46} src={post.productLogo} />
          <SHStack spacing={1} overflow={"hidden"} width={"100%"}>
            <SHStack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"flex-start"}
              marginTop={"8px"}
            >
              <SHStack minHeight={46} direction={"row"} marginRight={"20px"}>
                <SHTypography
                  variant="subtitle1"
                  sx={{
                    wordBreak: "break-word",
                  }}
                >
                  {post.title}&nbsp;&nbsp;&nbsp;
                  {post.status && (
                    <StatusBadge
                      status={post.status}
                      sx={{ marginRight: "15px" }}
                    />
                  )}
                </SHTypography>
              </SHStack>
              <SHStack height={46} direction={"row"} spacing={"10px"}>
                {isShowDelButton && (
                  <ArchivePostButton
                    postId={post.id}
                    postStatus={post.status}
                  />
                )}
                {isShowApproveButton && <ApprovePostButton postId={post.id} />}
                {isShowEditButton && (
                  <SHButton
                    startIcon={<EditSVG color={palette.common.white} />}
                    variant="contained"
                    onClick={() => {
                      setDisplayEditDialog(true);
                    }}
                  >
                    Edit
                  </SHButton>
                )}
                {isShowRestoreButton && <RestorePostButton postId={post.id} />}
              </SHStack>
            </SHStack>
            <SHStack
              direction={"row"}
              spacing={1}
              width={"100%"}
              color={palette.text.third}
              marginBottom={"10px"}
              display={"inline-block"}
              sx={{ verticalAlign: "middle" }}
            >
              <SHTypography
                pt={0}
                variant="body3"
                color={"inherit"}
                textAlign={"left"}
              >
                {post.productName}
              </SHTypography>
              <SHTypography
                sx={{
                  color: "inherit",
                  fontSize: "8px",
                  verticalAlign: "middle",
                  display: "inline-block",
                }}
              >
                &#9679;
              </SHTypography>
              <SHTypography pt={0} variant="body4" color={"inherit"}>
                {(post.publishedDate ? "" : "Last updated date: ") +
                  (date ? format(new Date(date), DateFormat) : "")}
              </SHTypography>
              {post.userName && (
                <>
                  <SHTypography
                    sx={{
                      color: "inherit",
                      fontSize: "8px",
                      verticalAlign: "middle",
                      display: "inline-block",
                    }}
                  >
                    &#9679;
                  </SHTypography>
                  <SHTypography pt={0} variant="body4" color={"inherit"}>
                    {post.userName}
                  </SHTypography>
                </>
              )}
            </SHStack>
            <SHStack spacing={1} width={"100%"}>
              {post.attachments?.length > 0 && (
                <ImagesCarousel
                  images={orderBy(
                    post.attachments ?? [],
                    [(image) => image.key.toLocaleLowerCase()],
                    "asc",
                  )}
                />
              )}
              <SHStack
                ref={contentRef}
                sx={{
                  wordBreak: "break-word",
                  display: "-webkit-box",
                  maxWidth: "100%",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                <SHHtmlBlock
                  fontSize={14}
                  color={palette.text.primary}
                  fontWeight={400}
                  content={post.content ?? ""}
                />
              </SHStack>
              {isReadMoreButtonVisible && (
                <Link
                  ref={null}
                  variant="body1"
                  component="label"
                  underline="none"
                  sx={{
                    fontWeight: 600,
                    cursor: "pointer",
                    color: palette.text.secondary,
                  }}
                  onClick={() => setOpenReadMoreDialog(true)}
                >
                  Read more
                </Link>
              )}
            </SHStack>
          </SHStack>
        </SHStack>
      </SHStack>
      {openReadMoreDialog && (
        <ReadMoreDialog
          isOpen
          onClose={onCloseReadMoreDialog}
          post={post}
          images={post.attachments}
        />
      )}
      {isDisplayEditDialog && (
        <PostDetailDialog
          isDisplay
          dialogTitle="Edit a post"
          buttonLabel={
            isSuperAdmin
              ? "Save changes to published post"
              : "Submit changes for review"
          }
          onClose={onCloseEditPostDialog}
          onSubmit={handleOnUpdatePost}
          isNew={false}
          initialValues={editData}
          productData={productData}
        />
      )}
    </>
  );
};
