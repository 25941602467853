import { FilterField } from "@models/news-and-updates/enums/filterField";
import { FilterOptions } from "@models/news-and-updates/enums/filterOptions";
import { PostStatus } from "@models/news-and-updates/enums/status";
import {
  NewsAndUpdatesMenuSections,
  PostFilter,
  SubSection,
} from "@models/news-and-updates/entities/filtersMenu";
import { DateRange } from "@models/news-and-updates/entities/post";
import { endOfMonth, startOfMonth } from "date-fns";
import { isNil } from "lodash";

const getEndDateOfMonthUTC = (date: Date) => {
  const newDate = endOfMonth(date);
  return new Date(
    Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()),
  );
};

const getStartDateOfMonthUTC = (date: Date) => {
  const newDate = startOfMonth(date);
  return new Date(
    Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()),
  );
};

const currentDate = getEndDateOfMonthUTC(new Date());
const bottomLimitStartDate = new Date(Date.UTC(2022, 0, 1, 0, 0, 0, 0));

const countSectionSelected = (updateSection: PostFilter) => {
  const count = updateSection?.subsections.reduce(
    (accumulator, subSection: SubSection) => {
      if (subSection.value) {
        accumulator++;
      }
      return accumulator;
    },
    0,
  );
  return count;
};

export const getUpdatingSection = (
  newFilters: PostFilter[],
  section: NewsAndUpdatesMenuSections,
) => {
  return newFilters.find(
    (filter: PostFilter) => filter.section === section.field,
  );
};

export const updateValueOfSelectedCheckbox = (
  updateSection: PostFilter | undefined,
  subSection: SubSection,
) => {
  let optionAll = updateSection?.subsections[0];
  // Get value of option 'All'
  const valueOfOptionAll = optionAll?.value;
  const length = updateSection?.subsections?.length ?? 0;

  updateSection?.subsections?.forEach((subSectionItem: SubSection) => {
    if (
      subSection.key !== FilterOptions.All.toString() &&
      subSectionItem.key === subSection.key
    ) {
      subSectionItem.value = !subSectionItem.value;

      // Deselect option 'All' when other options are deselected
      if (optionAll) {
        optionAll.value = false;
      }

      // Count selected options
      const count = countSectionSelected(updateSection);

      // If all other options are selected, option 'All' is selected as well
      if (count === length - 1 && optionAll) {
        optionAll.value = true;
      }
    }
    // If 'All' option are select/deselect, other options are select/deselect as well
    else if (subSection.key === FilterOptions.All.toString()) {
      subSectionItem.value = !valueOfOptionAll;
    }
  });
  return updateSection;
};

export const updateValueOfSelectedRadioButton = (
  newFilters: PostFilter[],
  subSection: string,
  section: NewsAndUpdatesMenuSections,
) => {
  newFilters
    .find((filter: PostFilter) => filter.section === section.field)
    ?.subsections?.forEach((subSectionItem: SubSection) => {
      if (subSectionItem.name === subSection) {
        subSectionItem.value = true;
      } else {
        subSectionItem.value = false;
      }
    });
  return newFilters;
};

export const getPeriodFilter = (filterValues: PostFilter[]): string => {
  const periodSection = filterValues.find(
    (filter) => filter.section === FilterField.Period,
  );
  const subSectionSelected = periodSection?.subsections.find(
    (subSection) => subSection.value === true,
  );
  return subSectionSelected?.key ?? "";
};

export const getStatusFilters = (filterValues: PostFilter[]): string[] => {
  const statusSection = filterValues.find(
    (filter) => filter.section === FilterField.Status,
  );
  const subSectionSelected = statusSection?.subsections.filter(
    (subSection) => subSection.value,
  );
  // option 'All'
  if (statusSection && statusSection?.subsections[0].value) {
    return [PostStatus.All];
  } else {
    const status =
      subSectionSelected?.map((subSection) => {
        return subSection.key;
      }) ?? [];
    return status;
  }
};

export const getPlatformFilters = (filterValues: PostFilter[]): string[] => {
  const platformsSection = filterValues.find(
    (filter) => filter.section === FilterField.Platforms,
  );
  const subSectionSelected = platformsSection?.subsections.filter(
    (subSection) => subSection.value,
  );
  // option 'All'
  if (platformsSection && platformsSection.subsections[0].value) {
    return [FilterOptions[FilterOptions.All]];
  } else {
    const platforms =
      subSectionSelected?.map((subSection) => {
        return subSection.key;
      }) ?? [];
    return platforms;
  }
};

export const getToDateFilter = (date?: Date | null): Date | null => {
  if (!isNil(date)) {
    try {
      date = getEndDateOfMonthUTC(date);
      if (
        date.valueOf() <= currentDate.valueOf() &&
        date.valueOf() >= bottomLimitStartDate.valueOf()
      ) {
        return date;
      }
    } catch {
      return null;
    }
  }
  return null;
};

export const getFromDateFilter = (
  date?: Date | null,
  toDate?: Date | null,
): Date | null => {
  if (!isNil(toDate) && !isNil(date)) {
    date = getStartDateOfMonthUTC(date);
    toDate = getEndDateOfMonthUTC(toDate);
    try {
      if (
        date.valueOf() >= bottomLimitStartDate.valueOf() &&
        date.valueOf() < toDate.valueOf()
      ) {
        return date;
      }
    } catch {
      return null;
    }
  }
  return null;
};

export const getDateRangeFilter = (
  fromDate?: Date | null,
  toDate?: Date | null,
): DateRange | null => {
  let dateRange = null;
  const startDate = getFromDateFilter(fromDate, toDate);
  const endDate = getToDateFilter(toDate);
  if (startDate && endDate) {
    dateRange = {
      start: startDate.toISOString() ?? "",
      end: endDate.toISOString() ?? "",
    };
  }
  return dateRange;
};
