import { SelectedProductDTO } from "@models/platform-analysis/entities/steps/feature";
import { ManagedAccountStatus } from "../enums/status";
import { ManagedAccountStep } from "../enums/step";
import { MAAnalysisDataDTO, ManagedAccountFeeAnalysisDTO } from "./step/fee";
import {
  ManagedAccountPortfolioSetupDTO,
  ManagedAccountPortfolioSetupUpdateDTO,
} from "./step/setup";
import { ManagedAccountSummaryDTO } from "./step/summary";
import { BusinessMetricTypeId } from "@models/configuration";

export class ManagedAccountODataDTO {
  id: string = "";
  name: string = "";
  ownerName: string = "";
  ownerAvatarUrl: string = "";
  adviserName: string = "";
  dataValidDate: Date = new Date();
  lastModifiedDate: Date = new Date();
  status: ManagedAccountStatus = ManagedAccountStatus.InProgress;
}

export class ManagedAccountDTO {
  id: string = "";
  configurationVersion: number = 0;
  feeCategoryVersion: number = 0;
  currentStep: string = "";
  status: ManagedAccountStatus = ManagedAccountStatus.InProgress;
  previousStatus?: string;
  dataValidDate: Date = new Date();
  filters: ManagedAccountFilterDTO | null = null;
  portfolioSetup: ManagedAccountPortfolioSetupDTO | null =
    new ManagedAccountPortfolioSetupDTO();
  feeAnalysis: ManagedAccountFeeAnalysisDTO | null = null;
  summary: ManagedAccountSummaryDTO | null = null;
  serial?: string;
  lastModifiedDate?: Date;
}

export class ManagedAccountUpdateDTO {
  id: string | null = null;
  setup: ManagedAccountPortfolioSetupUpdateDTO | null = null;
}

export class ManagedAccountBannerInfoDTO {
  ownerName: string = "";
  firmName: string = "";
}

export class ManagedAccountDecisionDTO {
  subProductId: string = "";
  step: string = "";
  comment: string | null = null;
  decisionStatus: string | null = null;
}

export class ManagedAccountStepDTO {
  steps: ManagedAccountStep[] = [];
  currentStep?: ManagedAccountStep;
  savedSteps?: ManagedAccountStep[] = [];
}

export class ManagedAccountSaveCurrentStepDTO {
  currentStep?: ManagedAccountStep;
  currentSubStep?: ManagedAccountStep;
}

export class ManagedAccountFilterDTO {
  portfolioFeesByPlatform?: FilterPortfolioFeesByPlatformDTO;
}

export enum FeeAnalysisRowType {
  Section = "Section",
  SubSection = "SubSection",
  Breakdown = "Breakdown",
}

export class FeeAnalysisTableRow {
  variableName?: string;
  name?: string;
  iconUrl?: string;
  description?: string;
  order?: number;
  isEssential?: boolean;
  totalFeature?: number;
  totalSelectedFeature?: number;
  analysisData?: MAAnalysisDataDTO[];
  selectedProducts?: SelectedProductDTO[];
  collapsedIds?: string[];
  businessMetricTypeId?: BusinessMetricTypeId;
  businessMetricTypeName?: string;
  type: FeeAnalysisRowType = FeeAnalysisRowType.Section;
}

export class FilterPortfolioFeesByPlatformDTO {
  TableDisplayType: string = "";
}