import { Table, TableProps } from "@mui/material";
import { forwardRef } from "react";

import "./styles.scss";

export type SHTableProps = TableProps;
export const SHTable = forwardRef(
  (props: SHTableProps, ref: any): JSX.Element => (
    <Table {...props} ref={ref} />
  ),
);
