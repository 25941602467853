import { ImageAutoComplete } from "@components/auto-completes/image";
import { PlusButton } from "@components/buttons/plus";
import {
  SHButton,
  SHDivider,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import UnsavedDialog from "@components/dialogs/unsaved";
import { StatusBadge } from "@components/status-badge";
import { PageRoutes } from "@constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsNew } from "@hooks/useIsNew";
import { useParentState } from "@hooks/useParentState";
import { useReviewClient } from "@hooks/useReviewClient";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { PageMode, ParentState } from "@models/core";
import {
  FamilyGroupDTO,
  FamilyGroupEntityDTO,
} from "@models/family-group/entities/familyGroup";
import { ClientStatus } from "@models/reviews/enums/status";
import { Collapse, useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { useEffect, useMemo, useState } from "react";
import {
  Controller,
  useFieldArray,
  useForm,
  useFormState,
} from "react-hook-form";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";
import { TransitionGroup } from "react-transition-group";
import { ReviewClientSkeleton } from "./skeleton";
import { reviewClientValidators } from "./util";
import { useEffectOnce } from "react-use";
import { isEmpty } from "lodash";

export const ReviewClientOverview = () => {
  const isNew = useIsNew();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { isEditMode, setEditMode } = useParentState();
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    adviserUsers,
    clientUI: { isLoading },
    createReviewClient,
    updateReviewClient,
    updateReviewClientStatus,
    resetStore,
    loadReviewClient,
    familyGroupId,
  } = useReviewClient();
  const {
    control,
    watch,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...new FamilyGroupDTO(),
    },
    resolver: yupResolver(reviewClientValidators),
  });

  const { dirtyFields } = useFormState({ control });
  const isDirty = !isEmpty(dirtyFields);
  const {
    fields: familyMembersFields,
    append: appendFamilyMember,
    remove: removeFamilyMember,
  } = useFieldArray({
    control,
    name: "familyGroupEntities",
    keyName: "fieldId",
  });

  const [isChangingStatus, setIsChangingStatus] =
    useState<ClientStatus | null>();

  const isDisabled = useMemo(() => {
    return isSubmitting || !isEditMode;
  }, [isSubmitting, isEditMode]);

  const currentStatus = watch("status");
  const convertOrder = () => {
    getValues("familyGroupEntities")?.map((entity, index) => {
      return (entity.order = index + 1);
    });
  };

  const handleSubmitFamilyGroup = async (data: FamilyGroupDTO) => {
    convertOrder();
    if (isNew) {
      await handleOnCreate(data);
    } else {
      await handleOnUpdate(data);
    }
  };

  const handleOnCreate = async (data: FamilyGroupDTO) => {
    const newFamilyGroup = {
      ...data,
      adviserFirmId: user?.userMetadata?.adviser_firm_id,
    };

    const responseData = await createReviewClient(newFamilyGroup);
    let isSuccess = !!responseData;
    if (isSuccess) {
      reset(responseData);
      navigate(
        generatePath(PageRoutes.reviewsFamilyGroupDetail, {
          familyGroupId: responseData?.id,
        }),
        { state: { pageMode: PageMode.Edit } as ParentState, replace: true },
      );
    }
    return;
  };

  const handleOnUpdate = async (data: FamilyGroupDTO) => {
    const response = await updateReviewClient(data);
    let isSuccess = !!response;
    if (isSuccess) {
      reset(data);
    }
  };

  const handleOnUpdateStatus = async (status: ClientStatus) => {
    if (!familyGroupId) return;
    setIsChangingStatus(status);
    const res = await updateReviewClientStatus(familyGroupId, status);
    setIsChangingStatus(null);
    if (res) {
      reset({ ...getValues(), status: res || status });
    }
  };

  const handleOnAddFamilyEntity = () => {
    appendFamilyMember(new FamilyGroupEntityDTO());
  };
  const handleOnRemoveFamilyEntity = (index: number) => {
    removeFamilyMember(index);
  };

  const loadData = async () => {
    const responseData = await loadReviewClient(familyGroupId ?? "new");
    reset(responseData);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [familyGroupId, isNew]);

  useEffectOnce(() => {
    setIsChangingStatus(null);
    return () => {
      resetStore();
    };
  });

  if (isLoading) return <ReviewClientSkeleton />;
  return (
    <form>
      <SHStack>
        <TopBarContainer>
          <TopBar
            navigate={{ to: `${PageRoutes.reviewsFamilyGroups}` }}
            title={watch("name") || "New family group"}
            customBadge={
              !isNew && (
                <StatusBadge status={getValues("status") as ClientStatus} />
              )
            }
            tools={
              <>
                {isEditMode ? (
                  <>
                    {!isNew && currentStatus !== ClientStatus.Archived && (
                      <SHButton
                        variant="outlined"
                        size="extraMedium"
                        isLoading={isChangingStatus === ClientStatus.Archived}
                        disabled={!isValid || isSubmitting}
                        onClick={() =>
                          handleOnUpdateStatus(ClientStatus.Archived)
                        }
                      >
                        Archive
                      </SHButton>
                    )}
                    {!isNew && currentStatus === ClientStatus.Archived && (
                      <SHButton
                        variant="outlined"
                        size="extraMedium"
                        isLoading={isChangingStatus === ClientStatus.Active}
                        disabled={!isValid || isSubmitting}
                        onClick={() =>
                          handleOnUpdateStatus(ClientStatus.Restore)
                        }
                      >
                        Unarchive
                      </SHButton>
                    )}
                    <SHButton
                      variant="outlined"
                      size="extraMedium"
                      isLoading={isSubmitting}
                      disabled={!isValid || isSubmitting || !isDirty}
                      onClick={handleSubmit(handleSubmitFamilyGroup)}
                    >
                      Save
                    </SHButton>
                  </>
                ) : (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    type="submit"
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    Edit
                  </SHButton>
                )}
              </>
            }
          />
        </TopBarContainer>
        <FieldGroup
          firstLine
          title="Family group information"
          subTitle={
            "Manage your family group’s details. This information will flow into their Suitability Reviews"
          }
        />
        <SHDivider />
        <FieldGroup
          title="Adviser"
          subTitle={"Assign an adviser to this family group"}
        >
          <SHStack
            spacing={3}
            direction="column"
            sx={{ width: { xs: "100%", md: 520 } }}
          >
            <Controller
              name="adviserUserId"
              control={control}
              rules={{ required: "Adviser required" }}
              render={({ field: { ref, ...others } }) => (
                <ImageAutoComplete
                  sx={{ width: "100%" }}
                  textFieldProps={{
                    label: "Adviser",
                    placeholder: "Select an adviser",
                    helperText: errors?.adviserUserId?.message,
                    error: !!errors.adviserUserId,
                    required: true,
                  }}
                  value={{
                    auth0Id: watch("adviserUserId") ?? "",
                    name: watch("adviserUserName") ?? "",
                    avatarUrl: watch("adviserUserAvatar") ?? "",
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.auth0Id === value.auth0Id
                  }
                  onChange={(event, newValue) => {
                    others.onChange(newValue?.auth0Id);
                    setValue("adviserUserId", newValue?.auth0Id ?? "", {
                      shouldDirty: true,
                    });
                    setValue("adviserUserName", newValue?.name ?? "", {
                      shouldDirty: true,
                    });
                    setValue("adviserUserAvatar", newValue?.avatarUrl ?? "", {
                      shouldDirty: true,
                    });
                  }}
                  labelField={"name"}
                  imageField={"avatarUrl"}
                  options={adviserUsers ?? []}
                  disabled={isDisabled}
                />
              )}
            />
          </SHStack>
        </FieldGroup>
        <SHDivider />
        <FieldGroup
          title="Family group name"
          subTitle={"Name your family group"}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <SHTextField
                {...field}
                type="text"
                label={"Family group name"}
                placeholder={"Enter a name for the family group"}
                helperText={errors.name?.message}
                error={!!errors.name}
                required={true}
                sx={{ width: { xs: "100%", md: 520 } }}
                disabled={isDisabled}
              />
            )}
          />
        </FieldGroup>
        <SHDivider />
        <FieldGroup
          title="Family members and entities"
          subTitle={
            "<p>Include any individuals or other entities that you associate with this family group<br/> <br/> Note: Maximum of 6 family members/entities supported</p>"
          }
          maxWidth={490}
        >
          <SHStack
            spacing={3}
            direction="column"
            sx={{ width: { xs: "100%", md: 520 } }}
          >
            <TransitionGroup
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                gap: "25px",
                width: "100%",
              }}
            >
              {familyMembersFields.map((entity, index) => (
                <Collapse key={entity.id}>
                  <Controller
                    name={`familyGroupEntities.${index}.name`}
                    control={control}
                    render={({ field }) => (
                      <SHTextField
                        {...field}
                        required
                        type="text"
                        hasCounter
                        maxLength={50}
                        disabled={isDisabled}
                        placeholder={`Enter the full name of entity ${
                          index + 1
                        }`}
                        label={
                          <SHStack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="stretch"
                            width={"100%"}
                          >
                            <SHStack>
                              <SHTypography
                                variant="subtitle2"
                                //   disabled={isDisabled}
                              >
                                Full name of entity {index + 1}
                              </SHTypography>
                            </SHStack>
                          </SHStack>
                        }
                        actionButton={
                          index ? (
                            <SHTypography
                              variant="body2"
                              color={palette.error.main}
                              sx={{
                                fontWeight: 600,
                                cursor: "pointer",
                                pointerEvents: "auto",
                              }}
                              onClick={() => handleOnRemoveFamilyEntity(index)}
                            >
                              Remove
                            </SHTypography>
                          ) : (
                            <></>
                          )
                        }
                        sx={{ width: { xs: "100%", md: 520 } }}
                        //   disabled={isDisabled}
                        error={
                          errors.familyGroupEntities &&
                          !!errors.familyGroupEntities[index]
                        }
                        helperText={
                          errors.familyGroupEntities
                            ? errors.familyGroupEntities[index]?.name?.message
                            : ""
                        }
                      />
                    )}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
            <SHStack alignItems={"flex-start"}>
              <PlusButton
                onClick={handleOnAddFamilyEntity}
                disabled={isDisabled || familyMembersFields?.length > 5}
              >
                Family member/entity
              </PlusButton>
            </SHStack>
            {/* <Collapse in={familyMembersFields?.length > 5}>
              <SHAlert
                severity="error"
                textAlign={"left"}
                icon={<WarningSVG />}
              >
                Maximum 6 family members/entities can be added.
              </SHAlert>
            </Collapse> */}
          </SHStack>
        </FieldGroup>
        <SHDivider />
      </SHStack>
      <UnsavedDialog isDirty={isDirty && !isSubmitting} />
    </form>
  );
};
