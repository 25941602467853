import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { PageRoutes } from "@constants/routes";
import { useBroadcastChannel } from "@hooks/useBroadcastChannel";
import { millisecondsToMinutesAndSeconds } from "@utils/time";
import { useEffect, useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { InactivityPromptTimeout, InactivityTimeout } from "./constant";
import { useAuth0 } from "@auth0/auth0-react";
import { refreshAccessToken } from "@services/shService";

export default function InactivityDialog(): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();
  const [countDown, setCountDown] = useState(InactivityPromptTimeout);
  const [isPrompted, setIsPrompted] = useState(false);
  const intervalRef: any = useRef();
  const { logoutBroadcast } = useBroadcastChannel();

  const onPrompt = () => {
    setIsPrompted(true);
  };

  const handleOnIdle = () => {
    clearInterval(intervalRef.current);
    pause();
    setIsPrompted(false);
    logoutBroadcast(
      `${process.env.REACT_APP_AUTH0_REDIRECT_URL}${PageRoutes.callback}?action=inactivity`,
    );
  };

  const onMessage = (data: any) => {
    if (data.resetTimer) {
      setIsPrompted(false);
      setCountDown(InactivityPromptTimeout);
      reset();
    }
  };

  const { reset, pause, getRemainingTime, message } = useIdleTimer({
    events: [
      "click",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "MSPointerDown",
      "input",
    ],
    timeout: InactivityTimeout,
    crossTab: true,
    syncTimers: 1,
    promptBeforeIdle: InactivityPromptTimeout,
    onPrompt,
    onMessage,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    if (isPrompted) {
      const timerId = setInterval(() => {
        setCountDown(getRemainingTime());
      }, 1000);
      intervalRef.current = timerId;
    } else {
      clearInterval(intervalRef.current);
    }
  }, [isPrompted, getRemainingTime]);

  const handleOnContinue = () => {
    setIsPrompted(false);
    setCountDown(InactivityPromptTimeout);
    reset();
    message({ resetTimer: true });
    refreshAccessToken(getAccessTokenSilently);
  };

  return (
    <SHDialog
      onClose={handleOnContinue}
      disableEscapeKeyDown
      disableBackdropClick
      open={isPrompted}
      header="Are you still here?"
      body={
        <SHStack direction={"column"} spacing={3}>
          <SHTypography
            variant="body2"
            sx={{ fontSize: "16px", marginTop: "16px" }}
          >
            Your session will expire in:{" "}
            {millisecondsToMinutesAndSeconds(countDown)}
          </SHTypography>
          <SHTypography
            variant="body2"
            sx={{ fontSize: "16px", marginTop: "16px" }}
          >
            Please click Continue to remain logged in.
          </SHTypography>
        </SHStack>
      }
      footer={
        <SHStack justifyContent="center" alignItems="center">
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={handleOnContinue}
          >
            Continue
          </SHButton>
        </SHStack>
      }
    />
  );
}
