import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import {
  DEFAULT_GAP_2,
  DEFAULT_GAP_3,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { View, Text } from "@react-pdf/renderer";
import { isEmpty } from "lodash";

interface PDFDisclaimerProps {
  hasFeeStep?: boolean;
  disclaimers?: DisclaimerDTO[];
  isReview?: boolean;
}
export const PDFDisclaimer = ({
  hasFeeStep = false,
  disclaimers,
  isReview = false,
}: PDFDisclaimerProps) => {
  const { typographyStyle } = useStyles();
  return (
    <View style={{ flexDirection: "column", gap: DEFAULT_GAP_2 }}>
      <View style={{ flexDirection: "column", gap: 8 }}>
        <Text style={{ ...typographyStyle?.title, fontWeight: "bold" }}>
          Disclaimer
        </Text>
        <Text
          style={{
            ...typographyStyle?.body2,
            textAlign: "justify",
            lineHeight: 1.2,
          }}
        >
          You have requested this report via the SuitabilityHub platform. We
          have not conducted any independent verification of the information
          provided by you. We utilise information sourced from our users and
          other third parties, and you must make your own enquiries as to the
          accuracy and completeness of information contained in this report. No
          representation or warranty (either express or implied) is given as to
          the truth, accuracy or completeness of the information in this report.
          This report is not intended to be relied on for the purposes of making
          a decision in relation to any financial product, platform or provider.
          We do not authorise any reliance on this report (or any information
          within it). In providing this report, we provide no financial advice
          or recommendation as to the suitability of any product, platform or
          provider.
        </Text>
        <Text
          style={{
            ...typographyStyle?.body2,
            textAlign: "justify",
            lineHeight: 1.2,
          }}
        >
          This report is based on the conditions in existence and information
          received on the “data valid” date stated in this report.
        </Text>
      </View>
      {hasFeeStep && (
        <View style={{ flexDirection: "column", gap: 8 }}>
          <View style={{ flexDirection: "column", gap: 8 }}>
            <Text style={{ ...typographyStyle?.body1, fontWeight: "bold" }}>
              Fee {isReview ? "review" : "analysis"} disclaimer
            </Text>
            <Text
              style={{
                ...typographyStyle?.body2,
                textAlign: "justify",
                lineHeight: 1.2,
              }}
            >
              Any result provided by SuitabilityHub’s fee calculator is
              illustrative only, and relies upon information that is subject to
              change. Any result should not be relied upon for the purpose of
              making a decision in relation to a particular financial product,
              platform or provider. Any result provided by our calculator is not
              an offer, and financial product providers will undertake their own
              fee calculations. Unless otherwise stated, all amounts are in
              Australian dollars.
            </Text>
          </View>

          <View style={{ flexDirection: "column", gap: 8 }}>
            <Text style={{ ...typographyStyle?.body1, fontWeight: "bold" }}>
              Fee {isReview ? "review" : "analysis"} assumptions: General
            </Text>

            <View style={{ marginLeft: DEFAULT_GAP_3 }}>
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Text style={{ ...typographyStyle?.body2, lineHeight: 1.2 }}>
                  Our fee calculator relies upon various assumptions. Those
                  assumptions are as follows, and are current as at 15 December
                  2023:
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                  }}
                >
                  • All balances provided are an average figure for a 12-month
                  period;
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                  }}
                >
                  • Investments held by a client are also held by all of the
                  accounts in that client’s family;
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                    lineHeight: 1.2,
                  }}
                >
                  • Fee estimates are calculated based on all accounts within a
                  family investing in the same platform product;
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                    lineHeight: 1.2,
                  }}
                >
                  • For the purpose of calculating transaction fees, investment
                  transactions for a given investment class is assumed to be
                  equal in size, and this size is the “anticipated average
                  transaction size for the next 12 months” entered into the
                  calculator;
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                    lineHeight: 1.2,
                  }}
                >
                  • Some platforms do not charge transaction fees on regular
                  investment facilities or plans. We assume all transactions
                  specified happen outside of these facilities or plans, on an
                  ad-hoc basis;
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                    lineHeight: 1.2,
                  }}
                >
                  • Any calculated expense recovery fee and/or operational risk
                  financial requirement fee is the maximum possible fee as set
                  out in the relevant financial product’s current product
                  disclosure statement;
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                    lineHeight: 1.2,
                  }}
                >
                  • For superannuation accounts with balances less than $6,000,
                  total administration, cash and account-related fees are capped
                  at 3% of the account balance. Other fees are not included in
                  this cap e.g. transaction fees;
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                    lineHeight: 1.2,
                  }}
                >
                  • Each investment transaction is the same size;
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                    lineHeight: 1.2,
                  }}
                >
                  • Where fees listed in the PDS vary within a specified range,
                  the maximum fee amount is applied;
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                    lineHeight: 1.2,
                  }}
                >
                  • Fee aggregation discounts and fee calculation methodologies
                  will be applied in accordance with the relevant financial
                  product’s current product disclosure statement;
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                    lineHeight: 1.2,
                  }}
                >
                  • All listed accounts are grouped for fee aggregation
                  purposes; and
                </Text>
                <Text
                  style={{
                    ...typographyStyle.body2,
                    textAlign: "justify",
                    lineHeight: 1.2,
                  }}
                >
                  • Fee estimates are displayed for total portfolio balances in
                  addition to the original total entered into the calculator.
                  These estimates are based on proportionally allocating the
                  holdings and account balances of the original total portfolio
                  value.
                </Text>
              </View>
            </View>
            <Text
              style={{
                ...typographyStyle?.body2,
                textAlign: "justify",
                lineHeight: 1.2,
              }}
            >
              Any result provided by the fee calculator takes into account
              platform administration fees, as well as transaction fees for
              Australian listed securities, international listed securities,
              managed funds and fixed income, and fees associated with holding
              retail insurance (not including the value
              of any insurance premiums). Results do not factor in retail or
              group insurance premiums, foreign currency fees that may apply to
              international investments, buy-sell spreads, and investment fees
              for individual investment options (e.g. performance-related fees);
              final results also do not factor in minimum cash balance and
              minimum transaction sizes.
            </Text>
            {!isEmpty(disclaimers) && (
              <>
                <Text style={{ ...typographyStyle?.body1, fontWeight: "bold" }}>
                  Fee {isReview ? "review" : "analysis"} assumptions: Product-specific
                </Text>
                {disclaimers?.map((item, index) => {
                  if (item.disclaimerType === "North") {
                    const textSplits = item.messageDisclaimer.split("<br/>");
                    return textSplits?.map((text, index) => (
                      <Text
                        key={index}
                        style={{
                          ...typographyStyle?.body2,
                          textAlign: "justify",
                          lineHeight: 1.2,
                          marginBottom: "-8px",
                        }}
                      >
                        {text}
                      </Text>
                    ));
                  }
                  return (
                    <Text
                      key={index}
                      style={{
                        ...typographyStyle?.body2,
                        textAlign: "justify",
                        lineHeight: 1.2,
                      }}
                    >
                      {item.messageDisclaimer}
                    </Text>
                  );
                })}
              </>
            )}
          </View>
        </View>
      )}
    </View>
  );
};
