export const useLocalStorage = () => {
  function setLocalStorageItem<T>(key: string, data: T) {
    window.localStorage.setItem(key, JSON.stringify(data));
  }

  function getLocalStorageItem(key: string) {
    return JSON.parse(window.localStorage.getItem(key)!);
  }

  function removeLocalStorageItem(key: string) {
    window.localStorage.removeItem(key);
  }
  return {
    setLocalStorageItem,
    getLocalStorageItem,
    removeLocalStorageItem,
  };
};
