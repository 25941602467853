import { SelectedInvestmentDTO } from "@models/managed-accounts/entities/step/setup";

export class PortfolioTemplateDTO {
    name?: string = "";
    note?: string = "";
    selectedInvestments?: SelectedInvestmentDTO[]
}

export class CreatePortfolioTemplate extends PortfolioTemplateDTO {

}

export class UpdatePortfolioTemplate extends CreatePortfolioTemplate {
    id?: string = "";
}