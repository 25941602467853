import { FilterValue } from "@components/buttons/filter";
import { FieldTypeId } from "@models/configuration";
import {
  AnalysisFeatureDTO,
  AnalysisFeatureIncludeDTO,
} from "@models/platform-analysis/entities/steps/feature";
import {
  FamilyMemberAccountDTO,
  FeeSubProductDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import { TableViewMode } from "@models/platform-analysis/enums/tableViewMode";
import { ReviewDTO } from "@models/reviews/entities/review";
import { ReviewSectionDTO } from "@models/reviews/entities/steps/feature";
import { HtmlTag } from "@pages/platform-analysis/components/buttons/export-pdf/config";
import {
  APPENDIX_COMMENT_CELL_WIDTH,
  APPENDIX_COMMENT_TABLE_HEADER_HEIGHT,
  COMMENT_SHORTLISTED_CELL_WIDTH,
  COMMENT_TABLE_HEADER_HEIGHT,
  CONTENT_WIDTH,
  MINIMUM_COMMENT_CELL_HEIGHT,
  REVIEW_PAGE_BODY_HEIGHT,
  SUB_TITLE_HEIGHT,
  SUB_TITLE_WITHOUT_TITLE_HEIGHT,
  TABLE_ROW_HEADER_LINE,
  TABLE_ROW_MULTIPLE_LINE,
  TABLE_ROW_TRIPLE_LINE,
  TITLE_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { PDFHtmlElement } from "@pages/platform-analysis/components/buttons/export-pdf/model";
import {
  insertStringAtSpecificIndex,
  regexLastIndexOf,
} from "@pages/platform-analysis/components/buttons/export-pdf/util";
import {
  displayCurrencyNumber,
  getPortfolioDetails,
} from "@pages/platform-analysis/util";
import { generateHiddenIds } from "@pages/reviews/_id/steps/summary/util";
import {
  CommentDetail,
  PDFBusinessIncluded,
  PDFBusinessReview,
  PDFFeatureIncluded,
  PDFFeatureReview,
  PDFFeatureReviewSectionDTO,
  PDFFeePortfolioDetail,
  PDFReviewComment,
  PDFReviewControl,
  PDFReviewData,
  PDFReviewOverview,
  ReviewCommentSeparated,
} from "@pages/reviews/components/buttons/export-pdf/model";
import { chain, chunk, filter, isEmpty, last, replace } from "lodash";

// TODO: Need to refactor review's models
export function generatePDFReviewData(review: ReviewDTO): PDFReviewData {
  const summaryReview = review?.summary;
  const featureAnalysisTableViewMode =
    summaryReview?.featureAnalysisTableViewMode;
  const isDetailMode = featureAnalysisTableViewMode === TableViewMode.Detail;
  const showSubSection = featureAnalysisTableViewMode !== TableViewMode.Summary;
  let pdfData = {
    overview: {
      name: summaryReview?.name,
      adviserName: summaryReview?.ownerName,
      adviserARN: summaryReview?.ownerARN,
      adviserFirmName: summaryReview?.adviserFirmName,
      adviserFirmLogo: summaryReview?.adviserFirmLogo,
      adviserFirmAFSL: summaryReview?.adviserFirmAFSL,
      familyGroupName: summaryReview?.familyGroupName,
      familyMembersNames: summaryReview?.familyMembersNames,
      lastModifiedDate: summaryReview?.lastModifiedDate,
      dataValidDate: review?.databaseVersion,
      serial: summaryReview?.serial,
    } as PDFReviewOverview,
    objectives: expandHTMLTagInPDF(summaryReview?.objectives ?? "", HtmlTag.BR),
    concludingRemarks: expandHTMLTagInPDF(
      summaryReview?.concludingRemarks ?? "",
      HtmlTag.BR,
    ),
    reviewCommentsShortlisted: filter(summaryReview?.analysisComments, {
      inFinalShortlist: true,
    })?.map((comment) => {
      return {
        ...comment,
        feature: expandHTMLTagInPDF(comment?.feature ?? "", HtmlTag.BR),
        businessMetric: expandHTMLTagInPDF(
          comment?.businessMetric ?? "",
          HtmlTag.BR,
        ),
        fee: expandHTMLTagInPDF(comment?.fee ?? "", HtmlTag.BR),
        summary: expandHTMLTagInPDF(comment?.summary ?? "", HtmlTag.BR),
      };
    }),
    reviewCommentsDisqualified: filter(summaryReview?.analysisComments, {
      inFinalShortlist: false,
    })?.map((comment) => {
      return {
        ...comment,
        feature: expandHTMLTagInPDF(comment?.feature ?? "", HtmlTag.BR),
        businessMetric: expandHTMLTagInPDF(
          comment?.businessMetric ?? "",
          HtmlTag.BR,
        ),
        fee: expandHTMLTagInPDF(comment?.fee ?? "", HtmlTag.BR),
        summary: expandHTMLTagInPDF(comment?.summary ?? "", HtmlTag.BR),
      };
    }),
    featureReviewShortlisted: {
      selectedProducts:
        summaryReview?.featureAnalysis?.shortlisted?.selectedProducts,
      overallScore: summaryReview?.featureAnalysis?.shortlisted?.overallScore,
      pdfSections: convertToPDFFeatureReviewSectionDTO(
        summaryReview?.featureAnalysis?.shortlisted
          ?.sections as ReviewSectionDTO[],
        showSubSection,
        isDetailMode,
      ),
    } as PDFFeatureReview,

    businessReviewShortlisted: {
      selectedProducts:
        summaryReview?.businessMetricAnalysis?.shortlisted?.selectedProducts,
      overallScore:
        summaryReview?.businessMetricAnalysis?.shortlisted?.overallScore,
      pdfSections: convertToPDFBusinessMetricAnalysisRows(
        summaryReview?.businessMetricAnalysis?.shortlisted
          ?.sections as ReviewSectionDTO[],
      ),
    } as PDFBusinessReview,
    feePortfolioDetails: summaryReview?.feePortfolioDetails,
    feeReviewShortlisted: summaryReview?.feeAnalysis?.shortlisted,
    feeReviewDisqualified: summaryReview?.feeAnalysis?.disqualified,
    hiddenSubProductIds: generateHiddenIds(
      summaryReview?.feeAnalysis?.shortlisted?.subProducts ?? [],
      summaryReview?.hiddenSubProducts ?? [],
    ),
    appendixHiddenSubProductIds: generateHiddenIds(
      summaryReview?.feeAnalysis?.disqualified?.subProducts ?? [],
      summaryReview?.hiddenSubProducts ?? [],
    ),
    featureIncluded: summaryReview?.selectedFeatures,
    businessIncluded: summaryReview?.businessMetricSelectedFeatures,
    // TODO:
    // disclaimers:
    controls: {
      showSubSection: showSubSection,
      showSelectedFeatures:
        summaryReview?.showSelectedFeatures && !isDetailMode,
      showAppendixFeeAnalysis: summaryReview?.showAppendixFeeAnalysis,
      showHoldingNumber:
        summaryReview?.feeAnalysis?.shortlisted?.isShowHoldingsNumber,
      showFeeAnalysisGraph: !summaryReview?.hideFeeAnalysisGraph,
    } as PDFReviewControl,
    displayModes: {
      feesDisplayMode: summaryReview?.feesDisplayStyle,
      feeMissingInvestmentsDisplayMode:
        summaryReview?.feeMissingInvestmentsStyle,
      appendixFeeMissingInvestmentsDisplayMode:
        summaryReview?.appendixFeeMissingInvestmentsStyle,
      featureAnalysisTableViewMode: featureAnalysisTableViewMode,
      investmentMenu: summaryReview?.investmentMenu,
      appendixInvestmentMenu: summaryReview?.appendixInvestmentMenu
    },
  } as PDFReviewData;

  return pdfData;
}

export const expandHTMLTagInPDF = (htmlString: string, tag?: HtmlTag) => {
  if (isEmpty(htmlString)) return htmlString;
  return htmlString?.replaceAll("</p><p>", "<br>");
};

export function convertToPDFFeatureReviewSectionDTO(
  sections: ReviewSectionDTO[],
  showSubSection?: boolean,
  isDetailMode?: boolean,
): PDFFeatureReviewSectionDTO[] {
  const rows: PDFFeatureReviewSectionDTO[] = [];

  sections.forEach((section: ReviewSectionDTO) => {
    rows?.push({
      id: section?.id,
      name: section?.name,
      description: section?.description,
      iconUrl: section?.iconUrl,
      order: section?.order,
      totalFeature: section?.totalFeature,
      totalSelectedFeature: section?.totalSelectedFeature,
      analysisData: section?.analysisData,
      type: "Section",
    } as PDFFeatureReviewSectionDTO);

    if (showSubSection) {
      section?.subSections?.forEach((subSection: ReviewSectionDTO) => {
        rows.push({
          id: subSection?.id,
          name: subSection?.name,
          description: subSection?.description,
          iconUrl: subSection?.iconUrl,
          order: subSection?.order,
          totalFeature: subSection?.totalFeature,
          totalSelectedFeature: subSection?.totalSelectedFeature,
          analysisData: subSection?.analysisData,
          type: "SubSection",
        } as PDFFeatureReviewSectionDTO);
        if (isDetailMode) {
          subSection?.features?.forEach((feature) => {
            rows.push({
              id: feature?.id,
              name: feature?.name,
              description: feature?.description,
              order: feature?.order,
              analysisData: feature?.analysisData,
              isEssential: feature.isEssential,
              type: "Feature",
            } as PDFFeatureReviewSectionDTO);
          });
        }
      });
    }
  });

  return rows;
}

export function convertToPDFBusinessMetricAnalysisRows(
  sections: ReviewSectionDTO[],
): PDFFeatureReviewSectionDTO[] {
  const rows: PDFFeatureReviewSectionDTO[] = [];

  sections.forEach((section) => {
    rows?.push({
      id: section?.id,
      name: section?.name,
      description: section?.description,
      iconUrl: section?.iconUrl,
      order: section?.order,
      totalFeature: section?.totalFeature,
      totalSelectedFeature: section?.totalSelectedFeature,
      analysisData: section?.analysisData,
      businessMetricTypeId: section?.businessMetricTypeId,
      businessMetricTypeName: section?.businessMetricTypeName,
      type: "Section",
    } as PDFFeatureReviewSectionDTO);

    section?.features?.forEach((feature: AnalysisFeatureDTO) => {
      rows.push({
        id: feature?.id,
        name: feature?.name,
        description: feature?.description,
        order: feature?.order,
        analysisData: feature?.analysisData,
        businessMetricTypeId: section?.businessMetricTypeId,
        businessMetricTypeName: section?.businessMetricTypeName,
        type: "Feature",
      } as PDFFeatureReviewSectionDTO);
    });
  });

  return rows;
}

interface CalculateFreeSpaceFeatureReviewDTO {
  hasFreeSpace: boolean;
  freeSpaceIndex: number;
  dataInFreeSpace: PDFFeatureReviewSectionDTO[];
  dataSectionContinue: PDFFeatureReviewSectionDTO[];
}

export function CalculateFreeSpaceFeatureReview(
  featureReviewData: PDFFeatureReviewSectionDTO[],
  businessReviewData: PDFFeatureReviewSectionDTO[],
): CalculateFreeSpaceFeatureReviewDTO {
  const MAX_FEATURE_REVIEW_ROW = 22;

  let hasFreeSpace = false;
  let freeSpaceIndex = 0;
  let dataSectionContinue: PDFFeatureReviewSectionDTO[] = [];

  chunk(featureReviewData, MAX_FEATURE_REVIEW_ROW)?.forEach(
    (childSectionsArr, index) => {
      if (
        index ===
        chunk(featureReviewData, MAX_FEATURE_REVIEW_ROW).length - 1
      ) {
        let rowHeightUse = childSectionsArr.length * 20;

        let freeSpace =
          REVIEW_PAGE_BODY_HEIGHT -
          TITLE_HEIGHT -
          TABLE_ROW_HEADER_LINE -
          rowHeightUse;

        if (freeSpace > REVIEW_PAGE_BODY_HEIGHT / 2) {
          let spaceForSection =
            freeSpace - TITLE_HEIGHT - TABLE_ROW_HEADER_LINE - 60 - 60;

          let numberRowAvailable = Math.floor(spaceForSection / 30);

          if (numberRowAvailable > 0) {
            businessReviewData
              ?.slice(0, numberRowAvailable)
              .forEach((element) => {
                dataSectionContinue?.push(element);
              });

            businessReviewData?.splice(0, dataSectionContinue?.length);

            if (dataSectionContinue?.length > 0) hasFreeSpace = true;

            freeSpaceIndex = index;
          }
        }
      }
    },
  );

  return {
    hasFreeSpace: hasFreeSpace,
    freeSpaceIndex: freeSpaceIndex,
    dataInFreeSpace: dataSectionContinue,
    dataSectionContinue: businessReviewData,
  };
}

// TODO: need refactor
interface CalculateFreeSpaceBusinessReviewDTO {
  hasFreeSpace: boolean;
  freeSpaceIndex: number;
}

export function CalculateFreeSpaceBusinessReview(
  businessReviewData: PDFFeatureReviewSectionDTO[],
  pdfData?: PDFReviewData,
): CalculateFreeSpaceBusinessReviewDTO {
  const MAX_BUSINESS_REVIEW_ROW = 14;

  let chunkDataBusinessSection = chunk(
    businessReviewData,
    MAX_BUSINESS_REVIEW_ROW,
  );

  let freeSpaceIndex = chunkDataBusinessSection.length - 1;

  let dataLastBusinessSection = last(chunkDataBusinessSection);

  let rowHeightUse = dataLastBusinessSection
    ? dataLastBusinessSection.length * 30
    : 0;

  let freeSpace =
    REVIEW_PAGE_BODY_HEIGHT -
    TITLE_HEIGHT -
    TABLE_ROW_HEADER_LINE -
    rowHeightUse;

  if (freeSpace < REVIEW_PAGE_BODY_HEIGHT / 2) {
    return {
      hasFreeSpace: false,
      freeSpaceIndex: -1,
    };
  }

  let spaceForSection = freeSpace - SUB_TITLE_HEIGHT - 70;

  const heightForSection = dynamicPortfolioDetails(
    spaceForSection,
    pdfData?.feePortfolioDetails,
    pdfData?.controls?.showHoldingNumber,
  );

  if (spaceForSection >= heightForSection.occupyHeight) {
    return {
      hasFreeSpace: true,
      freeSpaceIndex: freeSpaceIndex,
    };
  }

  return {
    hasFreeSpace: false,
    freeSpaceIndex: -1,
  };
}

export const dynamicPortfolioDetails = (
  availableHeight: number,
  data?: PDFFeePortfolioDetail,
  isShowHoldingNumber?: boolean,
) => {
  const MAX_TEXT = 85;
  const LOSE_HEIGHT = 15;
  if (data) {
    const { transactionsOutside, transactionsWithin, investments } =
      getPortfolioDetails(data);

    const getStringFamilyMemberAccounts = (data: FamilyMemberAccountDTO[]) => {
      return chain(data)
        ?.map(
          (data) =>
            `${replace(
              data.variableName ? data.variableName : "",
              "_",
              " ",
            )}: ${displayCurrencyNumber(data.balance)}`,
        )
        ?.join(", ")
        ?.value();
    };

    const dynamicHeightText = (text: string) => {
      if (text.length < MAX_TEXT) return "DoubleLine";
      return "TripleLine";
    };

    const heightText = (text: string) => {
      if (dynamicHeightText(text) === "DoubleLine") {
        return TABLE_ROW_MULTIPLE_LINE;
      } else {
        return TABLE_ROW_TRIPLE_LINE;
      }
    };

    let heightPortfolioDetails = SUB_TITLE_HEIGHT + LOSE_HEIGHT;

    data?.familyMembers?.forEach((familyMember) => {
      heightPortfolioDetails += heightText(
        getStringFamilyMemberAccounts(familyMember.familyMemberAccounts),
      );
    });

    if (data?.totalPortfolioValue) {
      heightPortfolioDetails += TABLE_ROW_MULTIPLE_LINE;
    }

    if (isShowHoldingNumber && data?.totalDifferentInvestments)
      heightPortfolioDetails += TABLE_ROW_MULTIPLE_LINE;

    if (investments.length) {
      heightPortfolioDetails += heightText(investments.join(", "));
    }

    if (transactionsOutside.length) {
      heightPortfolioDetails += heightText(transactionsOutside.join(", "));
    }

    if (transactionsWithin.length) {
      heightPortfolioDetails += heightText(transactionsWithin.join(", "));
    }

    if (heightPortfolioDetails <= availableHeight) {
      return {
        name: "Portfolio details",
        occupyHeight: heightPortfolioDetails,
        dataSeparated: data,
        hasContinue: false,
      };
    } else {
      return {
        name: "Portfolio details",
        occupyHeight: 0,
        dataSeparated: undefined,
        hasContinue: true,
      };
    }
  }
  return {
    name: "Portfolio details",
    occupyHeight: 0,
    dataSeparated: undefined,
    hasContinue: false,
  };
};

export const calculateFeeEstimatesTable = (
  pdfData: PDFReviewData,
  heightPortfolioDetails: number,
) => {
  const HEIGHT_ROW = 33;
  const HEIGHT_TOP_AND_BOTTOM_TABLE = 155;

  let numberRowInPortfolioSectionPage = 0;

  let freeSpace =
    REVIEW_PAGE_BODY_HEIGHT -
    heightPortfolioDetails -
    TITLE_HEIGHT -
    SUB_TITLE_HEIGHT;

  let sectionSpace = freeSpace - HEIGHT_TOP_AND_BOTTOM_TABLE;
  numberRowInPortfolioSectionPage = Math.floor(sectionSpace / HEIGHT_ROW);

  const feesDisplayStyle =
    pdfData.displayModes?.feesDisplayMode ?? FeesDisplayStyle.Dollar;
  const feeMissingInvestmentsStyle =
    pdfData?.displayModes?.feeMissingInvestmentsDisplayMode ??
    FeeMissingInvestmentsOption?.AllPlatform;
  let feesSubProductsFiltered: FeeSubProductDTO[] | undefined = [];

  if (feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.AllPlatform) {
    feesSubProductsFiltered = pdfData?.feeReviewShortlisted?.subProducts;
  } else
    feesSubProductsFiltered =
      pdfData?.feeReviewShortlisted?.subProducts?.filter((subProduct) =>
        feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.Missing
          ? subProduct.warning
          : !subProduct.warning,
      );

  const hiddenSubProductIds = pdfData?.hiddenSubProductIds;
  if (!isEmpty(hiddenSubProductIds)) {
    feesSubProductsFiltered = feesSubProductsFiltered?.filter(
      (subProduct) => !hiddenSubProductIds?.includes(subProduct.id),
    );
  }
  let countFeesSubProductsFiltered = feesSubProductsFiltered?.length ?? 0;

  if (
    freeSpace > REVIEW_PAGE_BODY_HEIGHT / 2 ||
    numberRowInPortfolioSectionPage >= countFeesSubProductsFiltered
  ) {
    const feeFilter: FilterValue[] | undefined =
      feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.AllPlatform
        ? undefined
        : [
            {
              value: feeMissingInvestmentsStyle,
              label: feeMissingInvestmentsStyle,
            },
          ];
    const showMissingInvestmentFilter =
      pdfData?.feeReviewShortlisted?.subProducts?.some(
        (subProduct: FeeSubProductDTO) => subProduct.warning,
      );

    let numberSubProductHidden =
      pdfData?.feeReviewShortlisted?.subProducts && feesSubProductsFiltered
        ? pdfData?.feeReviewShortlisted?.subProducts.length -
          feesSubProductsFiltered.length
        : 0;

    let subProducts;

    if (numberRowInPortfolioSectionPage === countFeesSubProductsFiltered)
      subProducts = feesSubProductsFiltered;
    else {
      subProducts = chunk(
        feesSubProductsFiltered,
        numberRowInPortfolioSectionPage,
      )[0];
    }

    let hasBothTableInPageContinue = false;

    if (subProducts) {
      let spaceFreeAfterUsed = sectionSpace - subProducts.length * HEIGHT_ROW;

      hasBothTableInPageContinue =
        spaceFreeAfterUsed - HEIGHT_TOP_AND_BOTTOM_TABLE >
        subProducts.length * HEIGHT_ROW;
    }

    if (isEmpty(countFeesSubProductsFiltered)) {
      let spaceFreeAfterUsed = sectionSpace - HEIGHT_ROW;

      hasBothTableInPageContinue =
        spaceFreeAfterUsed - HEIGHT_TOP_AND_BOTTOM_TABLE > HEIGHT_ROW;
    }

    return {
      hasDataContinue: true,
      hasBothTableInPageContinue: hasBothTableInPageContinue,
      numberSubProductHidden: numberSubProductHidden,
      subProducts: subProducts,
      feesDisplayStyle: feesDisplayStyle,
      feeFilter: feeFilter,
      showMissingInvestmentFilter: showMissingInvestmentFilter,
      hiddenSubProductIds: hiddenSubProductIds,
      numberRowInPortfolioSectionPage: numberRowInPortfolioSectionPage,
    };
  }

  return {
    hasDataContinue: false,
    subProducts: [],
    numberRowInPortfolioSectionPage: 0,
    hiddenSubProductIds: hiddenSubProductIds,
  };
};

export const calculateFeeEstimatesTableContinue = (
  feesDetailViewSubProductsFilteredContinue?: FeeSubProductDTO[],
  feesProjectionViewSubProductsFiltered?: FeeSubProductDTO[],
) => {
  const MAX_FEES_REVIEW_ROW = 12;
  const HEIGHT_ROW = 33;
  const HEIGHT_TOP_AND_BOTTOM_TABLE = 155;

  if (feesDetailViewSubProductsFilteredContinue?.length === 0) {
    return {
      lastPageIndex: -1,
      hasFreeSpace: false,
      dataProjectionInFreeSpace: [],
    };
  }

  let data = chunk(
    feesDetailViewSubProductsFilteredContinue,
    MAX_FEES_REVIEW_ROW,
  );
  let lastPageIndex = data.length - 1;
  let dataLastPage = last(data) ?? [];

  let spaceUse = dataLastPage.length * HEIGHT_ROW + HEIGHT_TOP_AND_BOTTOM_TABLE;

  let hasFreeSpace = spaceUse < REVIEW_PAGE_BODY_HEIGHT / 2;

  let freeSpaceSection =
    REVIEW_PAGE_BODY_HEIGHT - spaceUse - HEIGHT_TOP_AND_BOTTOM_TABLE;

  let numberSubProductInFreeSpace = Math.floor(freeSpaceSection / HEIGHT_ROW);

  let dataProjectionInFreeSpace = hasFreeSpace
    ? feesProjectionViewSubProductsFiltered?.splice(
        0,
        numberSubProductInFreeSpace,
      )
    : [];

  return {
    lastPageIndex: lastPageIndex,
    hasFreeSpace: hasFreeSpace,
    dataProjectionInFreeSpace: dataProjectionInFreeSpace,
  };
};

export const calculateTextHeightInFeatureIncluded = (
  row: AnalysisFeatureIncludeDTO,
) => {
  const MAX_LENGTH = 80;
  const INIT_HEIGHT = 30;
  const HEIGHT_ROW = 10;
  const COMMA_AND_BLANK_TEXT = 2;
  const ESSENTIAL_ICON = 3;

  let height = INIT_HEIGHT;
  let textLengthPrevious = 0;

  row.features.forEach((feature) => {
    textLengthPrevious += feature.featureName.length + COMMA_AND_BLANK_TEXT;

    if (feature.isEssential) {
      textLengthPrevious += ESSENTIAL_ICON;
    }

    if (textLengthPrevious >= MAX_LENGTH) {
      textLengthPrevious = feature.featureName.length;
      height += HEIGHT_ROW;
    }
  });

  return height;
};

export const calculateTextHeightInBusinessIncluded = (
  row: AnalysisFeatureIncludeDTO,
) => {
  const MAX_LENGTH = 85;
  const INIT_HEIGHT = 30;
  const HEIGHT_ROW = 10;

  let height = INIT_HEIGHT;

  let textLengthPrevious = 0;

  row.features.forEach((feature) => {
    textLengthPrevious += feature.featureName.length;

    if (textLengthPrevious >= MAX_LENGTH) {
      textLengthPrevious = feature.featureName.length;
      height += HEIGHT_ROW;
    }
  });

  return height;
};

interface CalculateDataFeatureIncluded {
  heightSection: number;
  dataSection: PDFFeatureIncluded[];
}

export const calculateDataFeatureIncluded = (
  dataPDFFeatureIncluded?: PDFFeatureIncluded[],
) => {
  let data: CalculateDataFeatureIncluded[] = [];

  let dataPerSection: PDFFeatureIncluded[] = [];

  const TOP_HEIGHT = TITLE_HEIGHT + 60;

  let freeSpace = REVIEW_PAGE_BODY_HEIGHT - TOP_HEIGHT;

  dataPDFFeatureIncluded?.forEach((feature, index) => {
    let height = calculateTextHeightInFeatureIncluded(feature);
    if (freeSpace - height > 0) {
      dataPerSection?.push(feature);
      freeSpace -= height;
    } else {
      data?.push({
        heightSection: REVIEW_PAGE_BODY_HEIGHT,
        dataSection: dataPerSection,
      });
      dataPerSection = [];
      dataPerSection?.push(feature);
      freeSpace = REVIEW_PAGE_BODY_HEIGHT - TOP_HEIGHT - height;
    }
  });

  data?.push({
    heightSection: REVIEW_PAGE_BODY_HEIGHT - freeSpace,
    dataSection: dataPerSection,
  });

  return data;
};

interface CalculateDataBusinessIncluded {
  dataContinuePage?: PDFBusinessIncluded[];
  dataNewPage?: PDFBusinessIncluded[];
}

export const calculateBusinessIncluded = (
  dataPDFFeatureIncludedLastSection: CalculateDataFeatureIncluded,
  dataPDFBusinessIncludedLastSection?: PDFBusinessIncluded[],
): CalculateDataBusinessIncluded => {
  const TOP_HEIGHT = TITLE_HEIGHT + 30;
  let freeSpace =
    REVIEW_PAGE_BODY_HEIGHT -
    TOP_HEIGHT -
    dataPDFFeatureIncludedLastSection.heightSection;

  if (freeSpace < REVIEW_PAGE_BODY_HEIGHT / 2) {
    return {
      dataContinuePage: undefined,
      dataNewPage: dataPDFBusinessIncludedLastSection,
    };
  }

  let dataContinuePage: PDFBusinessIncluded[] = [];
  let dataNewPage: PDFBusinessIncluded[] = [];

  dataPDFBusinessIncludedLastSection?.forEach((feature, index) => {
    let height = calculateTextHeightInBusinessIncluded(feature);

    if (freeSpace - height > 0) {
      dataContinuePage?.push(feature);
      freeSpace -= height;
    } else {
      dataNewPage?.push(feature);
    }
  });

  if (!isEmpty(dataContinuePage) && isEmpty(dataNewPage)) {
    return {
      dataContinuePage: dataContinuePage,
      dataNewPage: undefined,
    };
  }

  return {
    dataContinuePage: dataContinuePage,
    dataNewPage: dataNewPage,
  };
};

interface DataFeatureIncludedByHeight {
  indexLastRow: number;
  dataSection: PDFFeatureIncluded[];
}

export const getDataFeatureIncludedByHeight = (
  pdfDataFeatureIncluded: PDFFeatureIncluded[],
  height: number,
): DataFeatureIncludedByHeight => {
  let spaceSection = height;
  let dataSection: PDFFeatureIncluded[] = [];
  let indexLastRow = 0;

  pdfDataFeatureIncluded?.forEach((feature, index) => {
    let heightRow = calculateTextHeightInFeatureIncluded(feature);

    if (spaceSection - heightRow > 0) {
      dataSection?.push(feature);
      indexLastRow = index;
      spaceSection -= heightRow;
    }
  });

  pdfDataFeatureIncluded?.splice(0, indexLastRow + 1);

  return {
    indexLastRow: indexLastRow,
    dataSection: dataSection,
  };
};

export const calculateHeightProjectionTableLast = (
  feesProjectionViewSubProductsFiltered: FeeSubProductDTO[],
  pdfDataFeatureIncluded: PDFFeatureIncluded[],
) => {
  const MAX_FEES_REVIEW_ROW = 12;
  const HEIGHT_ROW = 33;
  const HEIGHT_TOP_AND_BOTTOM_TABLE = 155;

  let dataLastSection = last(
    chunk(feesProjectionViewSubProductsFiltered, MAX_FEES_REVIEW_ROW),
  );

  let freeSpace = 0;

  if (dataLastSection) {
    freeSpace =
      REVIEW_PAGE_BODY_HEIGHT -
      HEIGHT_TOP_AND_BOTTOM_TABLE -
      dataLastSection.length * HEIGHT_ROW;
  }

  if (freeSpace < REVIEW_PAGE_BODY_HEIGHT / 2) {
    return {
      hasFreeSpace: false,
      indexLastSection: -1,
      dataFeatureIncludedContinue: [],
    };
  }

  let data = getDataFeatureIncludedByHeight(
    pdfDataFeatureIncluded,
    freeSpace - TITLE_HEIGHT - 60,
  );

  return {
    hasFreeSpace: true,
    indexLastSection: 1,
    dataFeatureIncludedContinue: data.dataSection,
  };
};

// Region: Comments, Objectives, Concluding remarks
export const extractReviewComments = (
  pdfReviewComments: PDFReviewComment[],
  availableHeight: number,
  isAppendix?: boolean,
) => {
  let result: PDFReviewComment[] = [];
  pdfReviewComments?.forEach((item, index) => {
    let comment = { ...item };
    const featureNodeCreated = createElementNode(item.feature ?? "");
    const businessMetricNodeCreated = createElementNode(
      item.businessMetric ?? "",
    );
    const feeNodeCreated = createElementNode(item.fee ?? "");
    const summaryNodeCreated = createElementNode(item.summary ?? "");
    const cellWidth = `${
      isAppendix ? APPENDIX_COMMENT_CELL_WIDTH : COMMENT_SHORTLISTED_CELL_WIDTH
    }px`;
    const isFirstComment = index === 0;

    if (isNoAnyComment(item)) {
      result.push({
        ...comment,
        feature: null,
        businessMetric: null,
        fee: null,
        summary: null,
        height: MINIMUM_COMMENT_CELL_HEIGHT,
      });
      availableHeight -= MINIMUM_COMMENT_CELL_HEIGHT;
    } else {
      const commentSeparated: ReviewCommentSeparated = {
        featureDetail: parseToHTMLElementsByNode(
          featureNodeCreated,
          cellWidth,
          availableHeight,
          true,
          isAppendix,
          isFirstComment,
        ),
        businessMetricDetail: parseToHTMLElementsByNode(
          businessMetricNodeCreated,
          cellWidth,
          availableHeight,
          true,
          isAppendix,
          isFirstComment,
        ),
        feeDetail: parseToHTMLElementsByNode(
          feeNodeCreated,
          cellWidth,
          availableHeight,
          true,
          isAppendix,
          isFirstComment,
        ),
        summaryDetail: parseToHTMLElementsByNode(
          summaryNodeCreated,
          cellWidth,
          availableHeight,
          true,
          isAppendix,
          isFirstComment,
        ),
      };
      const separated = separateComments(
        commentSeparated,
        comment,
        availableHeight,
        isAppendix,
      );
      availableHeight = separated.remainingHeight;
      result = result.concat(separated.commentsExtracted);
    }
  });
  return result;
};

const isNoAnyComment = (comment: PDFReviewComment): boolean => {
  return (
    !comment.feature &&
    !comment.businessMetric &&
    !comment.fee &&
    !comment.summary
  );
};

const separateComments = (
  commentSeparated: ReviewCommentSeparated,
  commentExtracted: PDFReviewComment,
  availableHeight: number,
  isAppendix?: boolean,
) => {
  let commentsExtracted: PDFReviewComment[] = [];
  const { featureDetail, businessMetricDetail, feeDetail, summaryDetail } =
    commentSeparated;

  const biggestSeparated = Math.max(
    featureDetail?.elements?.length ?? 0,
    businessMetricDetail?.elements?.length ?? 0,
    feeDetail?.elements?.length ?? 0,
    summaryDetail?.elements?.length ?? 0,
  );

  if (biggestSeparated >= 1) {
    for (let index = 0; index < biggestSeparated; index++) {
      const height = getHighestCommentHeight(commentSeparated, index);
      commentsExtracted.push({
        ...commentExtracted,
        feature: featureDetail?.elements?.length
          ? featureDetail?.elements[index]?.content ?? ""
          : null,
        businessMetric: businessMetricDetail?.elements?.length
          ? businessMetricDetail.elements[index]?.content ?? ""
          : null,
        fee: feeDetail?.elements?.length
          ? feeDetail.elements[index]?.content ?? ""
          : null,
        summary: summaryDetail?.elements?.length
          ? summaryDetail.elements[index]?.content ?? ""
          : null,
        productName:
          biggestSeparated > 1 && index > 0
            ? `${commentExtracted.productName} (Continued)`
            : commentExtracted.productName,
        height,
        isContinued: biggestSeparated > 1 && index > 0,
      });

      if (availableHeight > height) {
        availableHeight -= height;
      } else {
        availableHeight =
          REVIEW_PAGE_BODY_HEIGHT -
          (TITLE_HEIGHT +
            (isAppendix
              ? APPENDIX_COMMENT_TABLE_HEADER_HEIGHT
              : COMMENT_TABLE_HEADER_HEIGHT)) -
          height;
      }
    }
  }

  return {
    commentsExtracted,
    remainingHeight: availableHeight,
  };
};

const parseToHTMLElementsByNode = (
  node: any,
  width: string,
  availableHeight: number,
  inTable?: boolean,
  isAppendix?: boolean,
  isFirstComment?: boolean,
): CommentDetail => {
  let clone = node.cloneNode(true);
  // hide the measured (cloned) element
  clone.style.cssText = `position:fixed;top: -9999px;opacity: 0;width:${width};padding: 5px;padding-bottom: 0px;font-size: ${
    inTable ? 7 : 10
  }px;line-height: 120%`;
  // add the clone to the DOM
  document.body.appendChild(clone);
  let result = parseToHTMLElements(
    clone,
    availableHeight,
    inTable,
    isAppendix,
    isFirstComment,
  );

  const height = result.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.height ?? 0);
  }, 0);
  // cleanup
  clone.parentNode.removeChild(clone);

  return {
    elements: result,
    totalHeight: height,
  } as CommentDetail;
};

const parseToHTMLElements = (
  elementRef: Element,
  availableHeight: number,
  inTable?: boolean,
  isAppendix?: boolean,
  isFirstComment?: boolean,
) => {
  let htmlElements: PDFHtmlElement[] = [];
  const pageBodyHeight = REVIEW_PAGE_BODY_HEIGHT;
  const maxContentHeight = inTable
    ? pageBodyHeight -
      (TITLE_HEIGHT +
        (isAppendix
          ? APPENDIX_COMMENT_TABLE_HEADER_HEIGHT
          : COMMENT_TABLE_HEADER_HEIGHT))
    : pageBodyHeight - TITLE_HEIGHT;

  const pushElement = (contentPerPage: string, totalHeight: number) => {
    htmlElements?.push({
      content: contentPerPage,
      height: isAppendix ? totalHeight : totalHeight,
    });
  };

  if (elementRef) {
    let { children } = elementRef;
    if (children?.length) {
      let totalHeight = 0;
      let contentPerPage = "";
      let isHandleBigElement = false;
      for (let index = 0; index < children.length; index++) {
        const elementHeight =
          (children.item(index)?.getBoundingClientRect()?.height ?? 0) +
          (isAppendix ? 24 : 0); //Removed from shortlist table

        const currentElementHeight = inTable
          ? Math.max(elementHeight, MINIMUM_COMMENT_CELL_HEIGHT)
          : elementHeight;

        if (
          currentElementHeight > availableHeight &&
          !isFirstComment &&
          inTable
        ) {
          availableHeight = maxContentHeight;
        }

        const currentElementContent =
          children.item(index)?.outerHTML ??
          children.item(index)?.textContent ??
          "";
        if (currentElementHeight < availableHeight) {
          totalHeight += currentElementHeight;
          contentPerPage += currentElementContent;
          availableHeight -= currentElementHeight;
        } else {
          isHandleBigElement = !!(availableHeight > 100 || totalHeight === 0);
          if (isHandleBigElement) {
            if (totalHeight !== 0) {
              pushElement(contentPerPage, totalHeight);
            }
            const cloneNode = children
              .item(index)
              ?.cloneNode(true) as ChildNode;
            const splitElement = dsfToBreakBigElements(cloneNode) ?? null;
            const breakElement = dsfParseToHTMLElements(
              splitElement,
              availableHeight,
              isAppendix,
              inTable,
            );
            htmlElements = htmlElements.concat(breakElement?.elements ?? []);
            availableHeight = breakElement?.remainingHeight ?? 0;
            totalHeight = 0;
            contentPerPage = "";
          } else {
            pushElement(contentPerPage, totalHeight);
            contentPerPage = currentElementContent;
            totalHeight = currentElementHeight;
            availableHeight -= currentElementHeight;
          }
        }

        if (index === children.length - 1 && !isHandleBigElement) {
          htmlElements?.push({
            content: contentPerPage,
            height: totalHeight,
          });
          break;
        }
      }
      if (!inTable && htmlElements?.length > 1 && htmlElements?.[0]?.content) {
        const lastIndexOfCloseTag = regexLastIndexOf(
          htmlElements[0].content,
          /<\/.+>/,
          undefined,
        );
        if (htmlElements[0].content.substring(lastIndexOfCloseTag) === "</p>") {
          htmlElements[0].content = insertStringAtSpecificIndex(
            htmlElements[0].content,
            lastIndexOfCloseTag,
            " ...(Continued)",
          );
        } else {
          htmlElements[0].content += "<p>...(Continued)</p>";
        }
      }
      return htmlElements;
    }
    return htmlElements;
  }
  return htmlElements;
};

const dsfParseToHTMLElements = (
  splitElement: Element | null,
  availableHeight: number,
  isAppendix?: boolean,
  inTable?: boolean,
) => {
  if (!splitElement) {
    return;
  }

  const cellWidth = !inTable
    ? CONTENT_WIDTH + "px"
    : (isAppendix
        ? APPENDIX_COMMENT_CELL_WIDTH
        : COMMENT_SHORTLISTED_CELL_WIDTH) + "px";
  let htmlElements: PDFHtmlElement[] = [];
  const parentCloseTag = `</${splitElement.tagName.toLowerCase()}>`;
  const parentOpenTag = parentCloseTag.replace("/", "");

  let contentPerPage = "";
  let totalHeight = 0;
  Array.from(splitElement?.childNodes ?? []).forEach((child, index) => {
    const element = child as Element;
    const nextContentAppended =
      contentPerPage + `${element.outerHTML ?? element.textContent}`;

    const nextHeightAppended =
      getElementHeight(
        createElementNode(nextContentAppended),
        cellWidth,
        inTable,
      ) ?? 0;

    if (availableHeight > nextHeightAppended) {
      contentPerPage = nextContentAppended;
      totalHeight = nextHeightAppended;
      if (index === (splitElement?.childNodes?.length ?? 0) - 1) {
        availableHeight -= totalHeight;
      }
    } else {
      htmlElements.push({
        content: parentOpenTag + contentPerPage + parentCloseTag,
        height: totalHeight,
      });

      contentPerPage = element.outerHTML ?? element.textContent;
      totalHeight = getElementHeight(
        createElementNode(element.outerHTML ?? element.textContent),
        cellWidth,
        inTable,
      );
      availableHeight =
        REVIEW_PAGE_BODY_HEIGHT -
        ((isAppendix
          ? APPENDIX_COMMENT_TABLE_HEADER_HEIGHT
          : COMMENT_TABLE_HEADER_HEIGHT) +
          TITLE_HEIGHT);
    }
    if (index === (splitElement?.childNodes?.length ?? 0) - 1) {
      htmlElements.push({
        content: parentOpenTag + contentPerPage + parentCloseTag,
        height: totalHeight,
      });
    }
  });

  return {
    elements: htmlElements,
    remainingHeight: availableHeight,
  };
};

const dsfToBreakBigElements = (node: ChildNode | null) => {
  if (!node) {
    return;
  }

  if (node.nodeType === Node.TEXT_NODE) {
    const nodeContentArray = node.textContent?.trim()?.split(" ");
    if (nodeContentArray && nodeContentArray.length > 4) {
      const breakNodeContents = chunk(nodeContentArray, 4);
      let nodes: Node[] = [];
      breakNodeContents.forEach((content, index) => {
        const node = document.createElement("span");
        node.textContent = content.join(" ") + " ";
        nodes.push(node);
      });
      node.after(...nodes);
      node.remove();
    }
  } else {
    const element = node as Element;
    Array.from(element.childNodes).map((childNode) =>
      dsfToBreakBigElements(childNode),
    );
  }

  return node as Element;
};

const createElementNode = (htmlString: string) => {
  let tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString ?? "";
  return tempElement;
};

const getHighestCommentHeight = (
  comment: ReviewCommentSeparated,
  index: number,
) => {
  return Math.max(
    comment?.featureDetail?.elements?.[index]?.height ?? 0,
    comment?.businessMetricDetail?.elements?.[index]?.height ?? 0,
    comment?.feeDetail?.elements?.[index]?.height ?? 0,
    comment?.summaryDetail?.elements?.[index]?.height ?? 0,
  );
};

const getElementHeight = (node: any, width: string, inTable?: boolean) => {
  let clone = node.cloneNode(true);
  // hide the measured (cloned) element
  clone.style.cssText = `position:fixed;top: -9999px;opacity: 0;width:${width};padding: 5px;padding-bottom: 0px;font-size: ${
    inTable ? 7 : 10
  }px;line-height: 120%`;
  // add the clone to the DOM
  document.body.appendChild(clone);
  let result = clone.clientHeight;
  // cleanup
  clone.parentNode.removeChild(clone);

  return result;
};

export function calculateAvailableRowCount(
  rowsData: PDFFeatureReviewSectionDTO[],
  remainingHeight: number,
  analysisType: "Feature" | "Business",
): {
  rowCount: number;
  totalRowHeight: number;
} {
  const maxChars = 48;
  const singleRowHeight = 20;
  const doubleRowHeight = 28;
  const footerHeight = 15;

  let isIncludedFooterHeight = false;

  let totalRowHeight = 0;
  let rowCount = 0;

  for (const row of rowsData) {
    const maxCharsInRow = row?.isEssential ? 45 : maxChars;
    const rowHeight =
      row?.name?.length! > maxCharsInRow ? doubleRowHeight : singleRowHeight;

    if (!isIncludedFooterHeight) {
      const hasFooter =
        analysisType === "Feature"
          ? row?.isEssential ||
            row.analysisData?.some((item) => item.isMissingEssential)
          : row.analysisData?.some(
              (item) => item.fieldTypeId === FieldTypeId.TextLong,
            );

      if (hasFooter) {
        totalRowHeight += footerHeight;
        isIncludedFooterHeight = true;
      }
    }

    totalRowHeight += rowHeight;

    if (totalRowHeight <= remainingHeight) {
      rowCount++;
    } else {
      break;
    }
  }

  return { rowCount, totalRowHeight };
}
/*
Special case:
When flag hasContinue = true, occupyHeight = 0, 
We need push data a new page
*/
export const dynamicFeatureReview = (
  spaceAvailable: number,
  featureData: PDFFeatureReviewSectionDTO[],
  isTableExpand: boolean,
) => {
  const overscoreHeight = 20;
  const titleHeight = TITLE_HEIGHT;
  const headerHeight = TABLE_ROW_HEADER_LINE + 5;

  let additionalHeight = titleHeight + headerHeight;
  if (!isTableExpand) additionalHeight += overscoreHeight;

  let remainingHeight = spaceAvailable - additionalHeight;

  const { rowCount, totalRowHeight } = calculateAvailableRowCount(
    featureData,
    remainingHeight,
    "Feature",
  );

  let occupyHeight = 0;
  let hasContinue = false;
  let dataSeparated: PDFFeatureReviewSectionDTO[] = [];

  if (rowCount > 0) {
    if (featureData.length > rowCount) {
      dataSeparated = featureData.splice(0, rowCount);
      occupyHeight = spaceAvailable;
      hasContinue = true;
    } else if (featureData.length <= rowCount) {
      dataSeparated = featureData;
      occupyHeight = totalRowHeight + additionalHeight;
      hasContinue = false;
    }
  } else {
    hasContinue = true;
  }

  return {
    name: "Feature",
    occupyHeight: occupyHeight,
    dataSeparated: dataSeparated,
    hasContinue: hasContinue,
  };
};

/*
Special case:
When flag hasContinue = true, occupyHeight = 0, 
We need push data a new page
*/
export const dynamicBusinessMetricReview = (
  spaceAvailable: number,
  businessReviewData: PDFFeatureReviewSectionDTO[],
  isTableExpand?: boolean,
  isAppend?: boolean,
) => {
  const overscoreHeight = 20;
  const titleHeight = TITLE_HEIGHT;
  const headerHeight = TABLE_ROW_HEADER_LINE + 5;

  let additionalHeight = titleHeight + headerHeight;
  if (!isTableExpand) additionalHeight += overscoreHeight;

  let remainingHeight = spaceAvailable - additionalHeight;

  const { rowCount, totalRowHeight } = calculateAvailableRowCount(
    businessReviewData,
    remainingHeight,
    "Business",
  );

  let occupyHeight = 0;
  let dataSeparated: PDFFeatureReviewSectionDTO[] = [];
  let hasContinue = false;

  if (rowCount > 0) {
    if (businessReviewData.length > rowCount) {
      dataSeparated = businessReviewData.splice(0, rowCount);
      occupyHeight = spaceAvailable;
      hasContinue = true;
    } else if (businessReviewData.length <= rowCount) {
      dataSeparated = businessReviewData;
      occupyHeight = totalRowHeight + additionalHeight;
      hasContinue = false;
    }
  } else {
    hasContinue = true;
  }

  return {
    name: "Business",
    occupyHeight: occupyHeight,
    dataSeparated: dataSeparated,
    hasContinue: hasContinue,
  };
};

/*
Special case:
When flag hasContinue = true, occupyHeight = 0, 
We need push data a new page
*/
export const dynamicFeeEstimatesTable = (
  availableHeight: number,
  data: FeeSubProductDTO[],
  isAppend?: boolean,
  isProjectionMode?: boolean,
) => {
  const ROW_HEIGHT = isProjectionMode ? 30 : 34;
  const HEIGHT_TOP_AND_BOTTOM_TABLE = 80; //25 + 25 + 30
  const RED_INFORMATION = 15;
  const LOSE_HEIGHT = isAppend ? 30 : 10;

  const titleHeight = isAppend ? (isProjectionMode ? 0 : 45) : TITLE_HEIGHT;
  const subTitleHeight = SUB_TITLE_WITHOUT_TITLE_HEIGHT;

  let remainingHeight =
    availableHeight -
    (titleHeight +
      subTitleHeight +
      HEIGHT_TOP_AND_BOTTOM_TABLE +
      RED_INFORMATION +
      LOSE_HEIGHT);

  let numberRowAvailable = Math.floor(remainingHeight / ROW_HEIGHT);

  let occupyHeight = 0;
  let dataSeparated: FeeSubProductDTO[] = [];
  let hasContinue = false;

  if (numberRowAvailable > 0) {
    if (data.length > numberRowAvailable) {
      dataSeparated = data.splice(0, numberRowAvailable);
      occupyHeight = availableHeight;
      hasContinue = true;
    } else if (data.length === numberRowAvailable) {
      dataSeparated = data;
      occupyHeight = availableHeight;
      hasContinue = false;
    } else if (data.length < numberRowAvailable) {
      dataSeparated = data;
      occupyHeight =
        titleHeight +
        subTitleHeight +
        HEIGHT_TOP_AND_BOTTOM_TABLE +
        RED_INFORMATION +
        LOSE_HEIGHT +
        data.length * ROW_HEIGHT;
      hasContinue = false;
    }
  } else {
    if (numberRowAvailable === 0 && data.length === 0) {
      hasContinue = false;
      occupyHeight =
        titleHeight +
        subTitleHeight +
        ROW_HEIGHT +
        RED_INFORMATION +
        LOSE_HEIGHT;
    } else {
      hasContinue = true;
    }
  }

  return {
    name: "Fee estimates",
    occupyHeight: occupyHeight,
    dataSeparated: dataSeparated,
    hasContinue: hasContinue,
  };
};

export const dynamicFeatureIncluded = (
  availableHeight: number,
  data: PDFFeatureIncluded[],
) => {
  const ESSENTIAL_INFORMATION = 15;
  const TABLE_HEADER_HEIGHT = 25;
  const LOSE_HEIGHT = 10;

  let MAX_HEIGHT_SECTION = availableHeight - TITLE_HEIGHT - TABLE_HEADER_HEIGHT;

  let remainingHeight =
    MAX_HEIGHT_SECTION - ESSENTIAL_INFORMATION - LOSE_HEIGHT;

  let occupyHeight = 0;
  let dataSeparated: PDFFeatureIncluded[] = [];
  let hasContinue = false;

  for (const feature of data) {
    let heightRow = calculateTextHeightInFeatureIncluded(feature);

    if (remainingHeight - heightRow > 0) {
      dataSeparated?.push(feature);
      remainingHeight -= heightRow;
      occupyHeight = availableHeight - remainingHeight;
    } else {
      occupyHeight = availableHeight;
      break;
    }
  }

  if (dataSeparated?.length < data?.length) {
    data?.splice(0, dataSeparated.length);
    hasContinue = true;
  } else {
    hasContinue = false;
  }

  return {
    name: "Features included",
    occupyHeight: occupyHeight,
    dataSeparated: dataSeparated,
    hasContinue: hasContinue,
  };
};

export const dynamicBusinessMetricIncluded = (
  availableHeight: number,
  data: PDFBusinessIncluded[],
) => {
  const TABLE_HEADER_HEIGHT = 25;
  const LOSE_HEIGHT = 10;

  let MAX_HEIGHT_SECTION = availableHeight - TITLE_HEIGHT - TABLE_HEADER_HEIGHT;

  let remainingHeight = MAX_HEIGHT_SECTION - LOSE_HEIGHT;

  let occupyHeight = 0;
  let dataSeparated: PDFBusinessIncluded[] = [];
  let hasContinue = false;

  for (const feature of data) {
    let heightRow = calculateTextHeightInBusinessIncluded(feature);

    if (remainingHeight - heightRow > 0) {
      dataSeparated?.push(feature);
      remainingHeight -= heightRow;
      occupyHeight = availableHeight - remainingHeight;
    } else {
      occupyHeight = availableHeight;
      break;
    }
  }

  if (dataSeparated.length < data.length) {
    data?.splice(0, dataSeparated.length);
    hasContinue = true;
  } else {
    hasContinue = false;
  }

  return {
    name: "Business included",
    occupyHeight: occupyHeight,
    dataSeparated: dataSeparated,
    hasContinue: hasContinue,
  };
};
