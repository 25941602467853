import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import {
  SHButtonSkeleton,
  SHTextFieldSkeleton,
} from "@components/skeletons/design-systems";
import { SHCheckBoxGroupSkeleton } from "@components/skeletons/design-systems/SHCheckBoxGroupSkeleton";

export const CompleteProfilePracticeSkeleton = {
  Header: (
    <SHStack alignItems="center" textAlign="center" spacing={1}>
      <SHSkeleton width={200} height={38} />
      <SHSkeleton width={370} height={27} />
    </SHStack>
  ),
  Body: (
    <SHStack alignItems="stretch" spacing={2} width={"100%"}>
      <SHStack spacing={3} direction="row">
        <SHTextFieldSkeleton width={"100%"} />
        <SHTextFieldSkeleton width={"100%"} />
      </SHStack>
      <SHTextFieldSkeleton />
      <SHStack spacing={3} direction="row">
        <SHTextFieldSkeleton width={"100%"} />
        <SHTextFieldSkeleton width={"100%"} />
      </SHStack>
      <SHTextFieldSkeleton />
      <SHCheckBoxGroupSkeleton />
    </SHStack>
  ),
  Footer: (
    <SHStack justifyContent="center" alignItems="center" direction={"row"}>
      <SHButtonSkeleton width={132} height={50} />
    </SHStack>
  ),
};
