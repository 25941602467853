import { SHStack, SHTypography, SHBox } from "@components/design-systems";
import { ArrowDownSVG } from "@components/svgs";
import { Collapse, useTheme } from "@mui/material";
import { useState } from "react";

export const SubscriptionHelper = ({
  helper,
}: {
  helper: {
    question: string;
    answer: string;
  };
}) => {
  const { palette } = useTheme();
  const { question, answer } = helper;
  const [showAnswer, setShowAnswer] = useState(false);

  return (
    <SHStack gap={0}>
      <SHStack
        flexDirection={"row"}
        gap={"5px"}
        onClick={() => setShowAnswer(!showAnswer)}
        sx={{ transition: "all 0.2s ease", cursor: "pointer" }}
      >
        <SHTypography variant="body1" marginTop={"-6px"}>
          {`Q: ${question}`}{" "}
        </SHTypography>
        <SHBox
          height={12}
          width={13}
          alignItems={"center"}
          sx={{
            display: "flex",
            transition: "all ease-in-out 0.2s",
            transform: `rotate(${showAnswer ? 360 : 270}deg)`,
          }}
          data-testid="sh-collaspe-arrow"
          onClick={() => setShowAnswer(!showAnswer)}
        >
          <ArrowDownSVG color={palette.text.primary} />
        </SHBox>
      </SHStack>
      <Collapse in={showAnswer}>
        <SHTypography
          variant="body1"
          colorVariant="third"
        >{`A: ${answer}`}</SHTypography>
      </Collapse>
    </SHStack>
  );
};
