import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { email } from "@constants/email";
import { Link, useTheme } from "@mui/material";
interface DomainDeniedDialogProps {
  onYes: () => void;
}
export const DomainDeniedDialog = ({
  onYes,
}: DomainDeniedDialogProps): JSX.Element => {
  const { palette } = useTheme();
  return (
    <SHDialog
      maxWidth="sm"
      open
      width={560}
      header={"Email domain does not match"}
      body={
        <SHStack alignItems="center" spacing={3} textAlign={"center"}>
          <SHTypography variant="body2">
            You can only invite someone with the same domain as your
            organisation. If you would like to invite someone else, please reach
            out to us at
            <Link
              variant="body2"
              color={palette.text.secondary}
              component="a"
              underline="none"
              sx={{
                paddingLeft: 0.5,
                cursor: "pointer",
              }}
              href={`mailto:${email.support}`}
            >
              Support@SuitabilityHub.com
            </Link>
          </SHTypography>
        </SHStack>
      }
      footer={
        <SHStack justifyContent="center" alignItems="center">
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onYes}
          >
            OK
          </SHButton>
        </SHStack>
      }
    />
  );
};
