import {
  AnalysisDataDTO,
  AnalysisFeatureDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import { ReviewDecisionStatus } from "@models/reviews/entities/status";
import {
  ReviewSectionDTO,
  ReviewStepFeatureContainerDTO,
} from "@models/reviews/entities/steps/feature";
import {
  EssentialFeaturesFilter,
  FeatureFilter,
} from "@pages/reviews/_id/steps/feature/analysis/config";
import { filter, isEmpty, map, some } from "lodash";
import { CollapseType } from "./table-improved";
import {
  ReviewTableRowType,
  ReviewTableRow,
} from "@models/reviews/entities/review";

export const generateReviewColorCell = (
  decisionStatus?: ReviewDecisionStatus,
  isMissingEssential?: boolean,
): "Green" | "Gray" | "Orange" | undefined => {
  if (decisionStatus === ReviewDecisionStatus.Undecided) {
    return isMissingEssential ? "Orange" : undefined;
  }

  if (decisionStatus === ReviewDecisionStatus.Shortlisted) return "Green";

  if (decisionStatus === ReviewDecisionStatus.Disqualified) return "Gray";
};

export const filterFeatureReviewByFeature = (
  featureAnalysisData?: ReviewStepFeatureContainerDTO,
  filterByFeature?: FeatureFilter,
  filterByEssential?: EssentialFeaturesFilter,
) => {
  return {
    ...featureAnalysisData,
    sections: filter(
      map(featureAnalysisData?.sections, (section) => {
        return {
          ...section,
          subSections: filter(
            map(section?.subSections, (subSection) => {
              let featuresFiltered = subSection.features;

              if (filterByFeature) {
                featuresFiltered = filter(featuresFiltered, (feature) => {
                  const isAvailable = some(feature.analysisData, {
                    value: AnalysisDataValue.True,
                  });
                  const isUnavailable = some(feature.analysisData, {
                    value: AnalysisDataValue.False,
                  });
                  const isMissingData = some(feature.analysisData, {
                    value: AnalysisDataValue.MissingData,
                  });
                  return filterByFeature === FeatureFilter.Common
                    ? !isUnavailable && !isMissingData
                    : isAvailable && (isUnavailable || isMissingData);
                });
              }

              if (filterByEssential) {
                featuresFiltered = filter(featuresFiltered, (feature) => {
                  return filterByEssential ===
                    EssentialFeaturesFilter.MarkedEssential
                    ? feature.isEssential === true
                    : feature.isEssential === false;
                });
              }

              return {
                ...subSection,
                features: featuresFiltered,
              };
            }),
            (subSection) => !isEmpty(subSection?.features),
          ),
        };
      }),
      (section) => !isEmpty(section?.subSections),
    ),
  } as AnalysisFeatureDTO;
};

export const filterFeatureReviewByEssential = (
  featureReview?: ReviewStepFeatureContainerDTO,
  filterByFeature?: EssentialFeaturesFilter,
) => {
  return {
    ...featureReview,
    sections: filter(
      map(featureReview?.sections, (section) => {
        return {
          ...section,
          subSections: filter(
            map(section?.subSections, (subSection) => {
              return {
                ...subSection,
                features: filter(subSection?.features, (feature) => {
                  return filterByFeature ===
                    EssentialFeaturesFilter.MarkedEssential
                    ? feature.isEssential
                    : !feature.isEssential;
                }),
              };
            }),
            (subSection) => !isEmpty(subSection?.features),
          ),
        };
      }),
      (section) => !isEmpty(section?.subSections),
    ),
  } as AnalysisFeatureDTO;
};

// Use for PDF & Table Virtuoso
export const flatFeatureReviewData = (sections: ReviewSectionDTO[] | any) => {
  let result = [] as ReviewTableRow[];
  let collapseMapping: CollapseType = {};

  sections.forEach((section: ReviewSectionDTO) => {
    result.push({
      id: section?.id,
      name: section?.name,
      order: section?.order,
      description: section?.description,
      iconUrl: section?.iconUrl,
      totalFeature: section?.totalFeature,
      totalSelectedFeature: section?.totalFeature,
      analysisData: section?.analysisData as AnalysisDataDTO[],
      type: ReviewTableRowType.Section,
    } as ReviewTableRow);
    collapseMapping[section?.id ?? ""] = false;

    section.subSections?.forEach((subSection: ReviewSectionDTO) => {
      result.push({
        id: subSection?.id,
        name: subSection?.name,
        order: subSection?.order,
        description: subSection?.description,
        iconUrl: subSection?.iconUrl,
        totalFeature: subSection?.totalFeature,
        totalSelectedFeature: subSection?.totalFeature,
        analysisData: subSection?.analysisData as AnalysisDataDTO[],
        type: ReviewTableRowType.SubSection,
        collapsedIds: [section?.id],
      } as ReviewTableRow);

      collapseMapping[subSection?.id ?? ""] = false;
      subSection.features.forEach((feature: any) => {
        result.push({
          id: feature?.id,
          name: feature?.name,
          order: feature?.order,
          description: feature?.description,
          iconUrl: feature?.iconUrl,
          totalFeature: feature?.totalFeature,
          totalSelectedFeature: feature?.totalFeature,
          analysisData: feature?.analysisData as AnalysisDataDTO[],
          isEssential: feature?.isEssential,
          type: ReviewTableRowType.Feature,
          collapsedIds: [section?.id, subSection?.id],
        } as ReviewTableRow);
      });
    });
  });

  return {
    rows: result,
    collapseMapping: collapseMapping,
  };
};
