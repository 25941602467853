import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { PlatformForFilterDTO } from "@models/managed-accounts/entities/filter";
import {
  InvestmentQueryOptions,
  InvestmentSelectionDTO,
} from "@models/managed-accounts/entities/step/setup";
import {
  FilterCalculatedInvestmentDTO,
  SmaListInvestmentDto
} from "@pages/managed-accounts/sma-list/components/tables/model";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getInvestmentSelections(
  queryOptions: InvestmentQueryOptions,
): Promise<APIResponse<InvestmentSelectionDTO[]>> {
  const response = await APIGatewayService.get<InvestmentSelectionDTO[]>(
    generatePath(APIExtRoutes.investmentsSelections),
    { params: queryOptions },
  );

  return handleErrorProxy(response);
}

export async function getInvestmentFavourited(
  queryOptions: InvestmentQueryOptions,
): Promise<APIResponse<InvestmentSelectionDTO[]>> {
  const response = await APIGatewayService.get<InvestmentSelectionDTO[]>(
    generatePath(APIExtRoutes.investmentsFavourited),
    { params: queryOptions },
  );

  return handleErrorProxy(response);
}

export async function putInvestmentFavourited(
  shCodes: string[],
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.investmentsFavourited),
    shCodes,
  );

  return handleErrorProxy(response);
}

export async function getInvestmentFiltersPlatforms(): Promise<
  APIResponse<PlatformForFilterDTO[]>
> {
  const response = await APIGatewayService.get<PlatformForFilterDTO[]>(
    generatePath(APIExtRoutes.investmentsFiltersPlatforms),
  );

  return handleErrorProxy(response);
}

export async function getSmaList(): Promise<APIResponse<SmaListInvestmentDto>> {
  const response = await APIGatewayService.get<SmaListInvestmentDto>(
    generatePath(APIExtRoutes.investmentsSmaList),
  );

  return handleErrorProxy(response);
}

export async function updateSmaList(
  filterCalculatedInvestmentDTO: FilterCalculatedInvestmentDTO,
): Promise<APIResponse<SmaListInvestmentDto>> {
  const response = await APIGatewayService.put<SmaListInvestmentDto>(
    generatePath(APIExtRoutes.investmentsSmaList),
    filterCalculatedInvestmentDTO,
  );

  return handleErrorProxy(response);
}
