import { SHContainer, SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { SHDividerSkeleton } from "@components/skeletons/design-systems";
import { GroupCarouselSkeleton } from "@components/skeletons/layouts/platform-analysis/GroupCarouselSkeleton";
import { TransferSelectFeatureListSkeleton } from "@components/skeletons/layouts/platform-analysis/TransferSelectFeatureListSkeleton";
import { StepName } from "@layouts/stepper/step-name";

export const ReviewFeatureSelectionSkeleton = () => {
  return (
    <SHContainer maxWidth="xl" sx={{ px: { xs: "16px", lg: "200px" } }}>
      <SHStack spacing={3}>
        <StepName name={"Feature selection"} stepNumber={0} isLoading />
        <GroupCarouselSkeleton />
        <SHSkeleton width={"100%"} height={"50px"} />
        <SHDividerSkeleton />
        <TransferSelectFeatureListSkeleton />
      </SHStack>
    </SHContainer>
  );
};
