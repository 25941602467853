import { FIRST_PAGE_BODY_HEIGHT } from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { View } from "@react-pdf/renderer";

interface PDFPageBodyProps {
  children?: React.ReactNode;
  isFirstPage?: boolean;
}
export const PDFPageBody = ({ children, isFirstPage }: PDFPageBodyProps) => {
  const { pageStyle } = useStyles();

  return (
    <View
      style={
        isFirstPage
          ? { ...pageStyle.body, height: FIRST_PAGE_BODY_HEIGHT }
          : { ...pageStyle.body }
      }
    >
      {children}
    </View>
  );
};
