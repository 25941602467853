import { SHBox, SHStack, SHTypography } from "@components/design-systems";
import { LineChartLegendSVG } from "@components/svgs";
import {
  FeeDTO,
  FeeSubProductDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { Collapse, useTheme } from "@mui/material";
import { getFeeMinMax } from "@pages/platform-analysis/util";
import {
  formatLargeNumber,
  formatPercentage,
  generateUUID,
  getHSLLightness,
  hexToRGBA,
  hslToHex,
  nameOfFactory,
  thousandSeparator,
} from "@utils";
import { groupBy } from "lodash";
import { useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import { TransitionGroup } from "react-transition-group";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { AxisInterval } from "recharts/types/util/types";
import { ChartLegendBox } from "../chart-legend-box";
import { LineChartLegend } from "../line-chart-legend";
import {
  DEFAULT_COLORS,
  DEFAULT_COLOR_TICK,
  MAX_LIGHTNESS,
  MIN_LIGHTNESS,
} from "./config";
import { getYAxisTicks } from "./util";
const nameOf = nameOfFactory<FeeDTO>();

export interface FeeLineChartProps {
  subProducts: FeeSubProductDTO[];
  mode?: FeesDisplayStyle;
  hiddenIds?: string[];
  onChangeHiddenIds?: (hiddenIds: string[]) => void;
}
export const FeeLineChart = ({
  subProducts,
  mode = FeesDisplayStyle.Dollar,
  hiddenIds = [],
  onChangeHiddenIds = (ids: string[]) => {},
}: FeeLineChartProps) => {
  const { palette } = useTheme();
  const [animationId, setAnimationId] = useState<string | undefined>();
  const isDollarMode = mode === FeesDisplayStyle.Dollar;

  const subProductFiltered = subProducts.filter(
    (subProduct) => !hiddenIds.includes(subProduct.id),
  );
  const isHiddenAll = subProductFiltered.length === 0;
  const subProductLines =
    isHiddenAll && subProducts.length ? [subProducts[0]] : subProductFiltered;
  const { max } = getFeeMinMax(
    isDollarMode ? "totalCostForDisplay" : "totalCostForDisplayPercentage",
    subProductLines,
  );
  const firstProduct = subProducts[0];

  const config = useMemo(() => {
    if (!firstProduct)
      return {
        tickXCount: 0,
        currentPortfolio: 0,
      };
    return {
      tickXCount: !firstProduct.fees[0]?.avgTotalPortfolio
        ? firstProduct.fees.length - 1
        : firstProduct.fees.length,
      currentPortfolio:
        firstProduct.fees.find((fee) => fee.isCurrentPortfolio)
          ?.avgTotalPortfolio ?? 0,
    };
  }, [firstProduct]);

  const colors = useMemo(() => {
    const groups = groupBy(subProducts, (obj) => obj.productId);
    const colors: { [key in string]: string } = {};
    Object.keys(groups).forEach((key, index) => {
      const group = groups[key];
      const colorRoot = DEFAULT_COLORS[index];
      if (group.length === 1) {
        colors[group[0].id] = hslToHex(
          colorRoot.hue,
          colorRoot.saturation,
          colorRoot.lightness,
        );
      } else {
        const tickColor = (MAX_LIGHTNESS - colorRoot.lightness) / group.length;
        const minLightness =
          tickColor >= DEFAULT_COLOR_TICK
            ? colorRoot.lightness
            : Math.max(
                MAX_LIGHTNESS - DEFAULT_COLOR_TICK * group.length,
                MIN_LIGHTNESS,
              );

        let maxLightness = Math.min(
          colorRoot.lightness + DEFAULT_COLOR_TICK * 1.5,
          MAX_LIGHTNESS,
        );

        if (group.length > 5) {
          maxLightness = Math.min(
            colorRoot.lightness + DEFAULT_COLOR_TICK * (group.length - 1),
            MAX_LIGHTNESS,
          );
        }

        group.forEach((item, itemIndex) => {
          const lightness = getHSLLightness(
            1,
            group.length,
            itemIndex + 1,
            minLightness,
            maxLightness,
          );
          colors[item.id] = hslToHex(
            colorRoot.hue,
            colorRoot.saturation,
            lightness,
          );
        });
      }
    });
    return colors;
    // eslint-disable-next-line
  }, [subProducts, subProducts.length]);

  const minDomain = firstProduct?.fees?.[0].avgTotalPortfolio || 0;
  const maxDomain =
    firstProduct?.fees?.[firstProduct?.fees?.length - 1]?.avgTotalPortfolio ||
    0;
  const currentPortfolioIndex = firstProduct?.fees?.findIndex(
    (fee) => fee.isCurrentPortfolio,
  );
  const currentPortfolio =
    currentPortfolioIndex !== -1
      ? firstProduct?.fees?.[currentPortfolioIndex]?.avgTotalPortfolio
      : undefined;

  const getAxisInterval = (): AxisInterval => {
    const leftCurrentPortfolio =
      currentPortfolioIndex > 0
        ? firstProduct?.fees?.[currentPortfolioIndex - 1]?.avgTotalPortfolio
        : undefined;
    const rightCurrentPortfolio =
      currentPortfolioIndex < (firstProduct?.fees?.length ?? 0)
        ? firstProduct?.fees?.[currentPortfolioIndex + 1]?.avgTotalPortfolio
        : undefined;
    const leftValue = (currentPortfolio ?? 0) - (leftCurrentPortfolio ?? 0);
    const right = (rightCurrentPortfolio ?? 0) - (currentPortfolio ?? 0);
    return leftValue >= right || !leftCurrentPortfolio
      ? "preserveStartEnd"
      : "preserveEnd";
  };

  const FeeTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<number, "y">) => {
    const { palette } = useTheme();
    if (!active || !payload || !payload.length) {
      return null;
    }
    const valueIndex = subProductFiltered[0]?.fees.findIndex(
      (fee) => fee.avgTotalPortfolio === +label,
    );
    if (valueIndex === -1) return null;
    const isCurrentPortfolio = payload[0]?.payload?.isCurrentPortfolio;
    return (
      <SHStack
        sx={{
          position: "relative",
          "&:focus-visible": { outline: "none" },
        }}
      >
        <SHStack
          zIndex={1}
          sx={{
            border: `1px solid #E3E3E3`,
            backgroundColor: hexToRGBA(palette.common.white, 0.85),
            boxShadow: `1px 1px 2px rgba(65, 73, 89, 0.25)`,
            borderRadius: "2px",
          }}
        >
          <SHStack
            sx={{
              px: "10px",
              py: "8px",
              backgroundColor: hexToRGBA(palette.secondary[200], 0.3),
            }}
          >
            <SHTypography
              variant={"caption"}
              lineHeight={"120%"}
              fontWeight={600}
            >
              $
              <NumericFormat
                displayType="text"
                value={+label}
                thousandSeparator=","
                decimalScale={1}
              />
            </SHTypography>
            {isCurrentPortfolio && (
              <SHTypography
                variant={"caption"}
                lineHeight={"120%"}
                colorVariant={"third"}
                fontWeight={400}
              >
                Current portfolio value
              </SHTypography>
            )}
          </SHStack>
          <SHStack
            spacing={"5px"}
            sx={{
              p: "8px",
              borderTop: `1px solid #E3E3E3`,
            }}
          >
            {payload.map((item, index) => {
              const subProduct = subProductFiltered[index];
              const value = subProduct.fees[valueIndex];
              return (
                <SHStack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  key={subProduct.id}
                  spacing={"15px"}
                >
                  <SHStack
                    direction={"row"}
                    spacing={"2px"}
                    alignItems={"flex-start"}
                  >
                    <SHStack pt={"3.5px"}>
                      <LineChartLegendSVG color={colors[subProduct.id]} />
                    </SHStack>
                    <SHTypography
                      variant={"caption"}
                      lineHeight={"120%"}
                      maxWidth={"150px"}
                      fontWeight={400}
                    >
                      {`${subProduct.productName} ${subProduct.name}`}
                    </SHTypography>
                  </SHStack>
                  <SHTypography
                    variant={"caption"}
                    lineHeight={"120%"}
                    maxWidth={"120px"}
                    fontWeight={400}
                  >
                    {isDollarMode && "$"}
                    <NumericFormat
                      displayType="text"
                      value={
                        isDollarMode
                          ? value.totalCostForDisplay
                          : value.totalCostForDisplayPercentage
                      }
                      fixedDecimalScale={!isDollarMode}
                      decimalScale={!isDollarMode ? 2 : 0}
                      thousandSeparator=","
                    />
                    {!isDollarMode && "%"}
                  </SHTypography>
                </SHStack>
              );
            })}
          </SHStack>
        </SHStack>
        <SHStack
          zIndex={0}
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backdropFilter: "blur(2px)",
          }}
        />
      </SHStack>
    );
  };

  return (
    <SHStack
      sx={{
        background: palette.common.white,
        border: `1px solid ${palette.secondary[100]}`,
        p: "28px",
      }}
      spacing={"25px"}
    >
      <SHTypography variant="subtitle1" fontWeight={700}>
        Annual platform fees by portfolio value (estimates)
      </SHTypography>

      <SHStack
        direction={"row"}
        alignItems={"flex-start"}
        width={"100%"}
        spacing={"10px"}
      >
        <ChartLegendBox direction="vertical">
          Platform fees for the year ahead
        </ChartLegendBox>
        <SHStack flexGrow={1} width={"1px"} spacing={"10px"}>
          <SHBox sx={{ width: "100%", height: "100%" }}>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart>
                <CartesianGrid
                  strokeDasharray="2 2"
                  stroke={palette.text.disabled}
                  horizontal
                  vertical={false}
                />
                <XAxis
                  dataKey={nameOf("avgTotalPortfolio")}
                  type="number"
                  interval={getAxisInterval()}
                  ticks={firstProduct?.fees?.map(
                    (fee) => fee.avgTotalPortfolio,
                  )}
                  domain={[minDomain, maxDomain]}
                  tickFormatter={(value) =>
                    currentPortfolio !== undefined && currentPortfolio === value
                      ? `$${thousandSeparator(+value.toFixed(0))}`
                      : `$${formatLargeNumber(value)}`
                  }
                  tick={{
                    fontFamily: "Epilogue",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: palette.text.primary,
                  }}
                />
                <YAxis
                  dataKey={
                    isDollarMode
                      ? nameOf("totalCostForDisplay")
                      : nameOf("totalCostForDisplayPercentage")
                  }
                  tickFormatter={(value: number) =>
                    isDollarMode
                      ? `$${thousandSeparator(+value.toFixed(0))}`
                      : `${formatPercentage(value)}%`
                  }
                  tick={{
                    fontFamily: "Epilogue",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: palette.text.primary,
                  }}
                  ticks={getYAxisTicks(max)}
                />

                <Tooltip content={FeeTooltip} />
                {subProductLines.map((subProduct) => {
                  return (
                    <Line
                      hide={isHiddenAll}
                      key={generateUUID()}
                      data={subProduct.fees}
                      name={subProduct.name}
                      dataKey={
                        isDollarMode
                          ? nameOf("totalCostForDisplay")
                          : nameOf("totalCostForDisplayPercentage")
                      }
                      type="linear"
                      strokeWidth={"2"}
                      stroke={colors[subProduct.id]}
                      dot={{ fill: colors[subProduct.id] }}
                      animationDuration={500}
                      isAnimationActive={
                        animationId === undefined ||
                        animationId === subProduct.id
                      }
                    />
                  );
                })}
                <ReferenceLine
                  x={config.currentPortfolio}
                  stroke={palette.text.primary}
                  strokeWidth={2}
                  strokeDasharray={5}
                />
                {firstProduct?.fees?.map((fee) =>
                  fee.isCurrentPortfolio ? null : (
                    <ReferenceLine
                      key={fee.avgTotalPortfolio}
                      x={fee.avgTotalPortfolio}
                      stroke={palette.text.disabled}
                      strokeDasharray={"2 2"}
                    />
                  ),
                )}
              </LineChart>
            </ResponsiveContainer>
          </SHBox>
          <SHStack direction={"row"} justifyContent={"flex-end"}>
            <ChartLegendBox>
              Average total portfolio value for the year
            </ChartLegendBox>
          </SHStack>
          <TransitionGroup
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              justifyContent: "center",
              marginTop: "25px",
              flexWrap: "wrap",
            }}
          >
            {subProducts.map((subProduct) => {
              const isActive = !hiddenIds.includes(subProduct.id);
              return (
                <Collapse key={subProduct.id} orientation={"horizontal"}>
                  <LineChartLegend
                    subProduct={subProduct}
                    isActive={isActive}
                    onClick={() => {
                      setAnimationId(subProduct.id);
                      if (isActive) {
                        onChangeHiddenIds([...hiddenIds, subProduct.id]);
                      } else {
                        onChangeHiddenIds(
                          hiddenIds.filter((id) => id !== subProduct.id),
                        );
                      }
                    }}
                    color={colors[subProduct.id]}
                  />
                </Collapse>
              );
            })}
          </TransitionGroup>
        </SHStack>
      </SHStack>
    </SHStack>
  );
};
