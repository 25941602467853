import { SHContainer, SHStack } from "@components/design-systems";
import { TableAnalysisCellSkeleton } from "@components/skeletons/layouts/platform-analysis/table-analysis/TableAnalysisCellSkeleton";
import { StepName } from "@layouts/stepper/step-name";

export const FeatureAnalysisSkeleton = () => {
  return (
    <SHContainer maxWidth="xl" sx={{ px: { xs: "16px", lg: "100px" } }}>
      <SHStack spacing={3}>
        <StepName name={"Feature selection"} stepNumber={0} isLoading />
        <SHStack spacing={1.5}>
          <SHStack
            width={"100%"}
            direction={"row"}
            alignItems={"center"}
            sx={{ overflow: "auto" }}
          >
            <TableAnalysisCellSkeleton isTableHead isFirstColumn />
            {Array.from(Array(4).keys()).map((index) => (
              <TableAnalysisCellSkeleton key={index} isTableHead />
            ))}
            <TableAnalysisCellSkeleton isTableHead isLastColumn />
          </SHStack>
          <SHStack
            direction={"column"}
            alignItems={"center"}
            sx={{ overflow: "auto" }}
          >
            {Array.from(Array(8).keys()).map((index) => (
              <SHStack key={index} width={"100%"} height={54} direction={"row"}>
                <TableAnalysisCellSkeleton isFirstColumn />
                {Array.from(Array(4).keys()).map((index) => (
                  <TableAnalysisCellSkeleton key={index} />
                ))}
                <TableAnalysisCellSkeleton isLastColumn />
              </SHStack>
            ))}
          </SHStack>
        </SHStack>
      </SHStack>
    </SHContainer>
  );
};
