export enum PostPeriodValue {
    All = "-1",
    Custom = "0",
    LastMonth = "1",
    Last3Months = "3",
    Last6Months = "6",
    Last12Months = "12",
}

export enum PostPeriod {
    All = "All",
    Custom = "Custom",
    LastMonth = "Last month",
    Last3Months = "Last 3 months",
    Last6Months = "Last 6 months",
    Last12Months = "Last 12 months",
}