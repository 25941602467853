import { RootState, useAppDispatch } from "@redux/store";
import { useNotification } from "./useNotification";
import {
  CreatePortfolioTemplate,
  UpdatePortfolioTemplate,
} from "@models/portfolio-template/entities/portfolioTemplate";
import {
  createPortfolioTemplateThunk,
  updatePortfolioTemplateThunk,
} from "@redux/slices/portfolio-template";
import { useSelector } from "react-redux";

export const usePortfolioTemplate = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();

  const { ui } = useSelector((state: RootState) => state.portfolioTemplate);

  const createPortfolioTemplate = async (
    featureTemplate: CreatePortfolioTemplate,
  ) => {
    const response = await dispatch(
      createPortfolioTemplateThunk(featureTemplate),
    ).unwrap();

    if (!response.isSuccess) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }

    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updatePortfolioTemplate = async (
    featureTemplate: UpdatePortfolioTemplate,
  ) => {
    const response = await dispatch(
      updatePortfolioTemplateThunk(featureTemplate),
    ).unwrap();

    if (!response.isSuccess) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }

    notify(response.message, {
      variant: "success",
      close: true,
    });

    return response.data;
  };

  return {
    ui,
    createPortfolioTemplate,
    updatePortfolioTemplate,
  };
};
