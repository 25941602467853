import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SocketServerEvent } from "@constants/socket";
import { HubConnectionState } from "@microsoft/signalr";
import {
  ConfigurationEditingProcessActions,
  ConfigurationEditingProcessResult,
  SignalRResponse,
} from "@models/core";
import { RootState } from "@redux/store";
import {
  updateBeingKickedBy,
  updateEditMode,
  updateKickOut,
  updateLoadingEditMode,
  updateShowDialog,
} from "@redux/slices/configuration";
import { SignalRService } from "@services/signalr/signalRService";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export interface ConfirmKickOutDialogProps {}

export const ConfirmKickOutDialog = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  const {
    configurationUI: { isShowDialog, currentEditor },
  } = useSelector((state: RootState) => state.configuration);

  const handleOnClickYes = async () => {
    setIsLoading(true);
    dispatch(updateLoadingEditMode(true));

    const connection = SignalRService.getHubConnection();
    if (connection && connection.state === HubConnectionState.Connected) {
      connection
        .invoke(
          SocketServerEvent.onConfigurationEditorProcess,
          ConfigurationEditingProcessActions.ConfigurationEditorClaiming,
          user?.name,
        )
        .then((result: SignalRResponse<ConfigurationEditingProcessResult>) => {
          if (
            result.resultType ===
            ConfigurationEditingProcessResult.EditingPermissionClaimSuccessful
          ) {
            //success -> can edit now
            dispatch(updateEditMode(true));
            dispatch(updateShowDialog(false));
            console.log(
              "EditingPermissionClaimSuccessful, listening on management channel...",
            );

            //also, listen
            connection.on(
              SocketServerEvent.onConfigurationEditingSectionManagement,
              (result: SignalRResponse<ConfigurationEditingProcessResult>) => {
                if (
                  result.resultType ===
                  ConfigurationEditingProcessResult.EditingPermissionDispose
                ) {
                  console.log("Received disposed, send ack back to server...");
                  connection.invoke(
                    SocketServerEvent.onConfigurationEditorProcess,
                    ConfigurationEditingProcessActions.ConfigurationBeingDisposed,
                    null,
                  );

                  //display dialog: you have been kick out...
                  //TODO: update to view mode here
                  dispatch(updateEditMode(false));
                  dispatch(updateKickOut(true));
                  dispatch(updateBeingKickedBy(result.content));

                  // off channel
                  connection.off(
                    SocketServerEvent.onConfigurationEditingSectionManagement,
                  );
                }
              },
            );
          }
          ///else show error here
          setIsLoading(false);
          dispatch(updateLoadingEditMode(false));
        });
    } else {
      ///else show error here
      setIsLoading(false);
    }
  };

  const handleOnClose = () => {
    dispatch(updateShowDialog(false));
    dispatch(updateLoadingEditMode(false));
    setIsLoading(false);
  };

  return (
    <>
      <SHDialog
        open={isShowDialog || false}
        onClose={handleOnClose}
        width={600}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        header={
          <SHStack alignItems="center" textAlign={"center"} width={{ xs: 590 }}>
            <SHTypography variant="h2">
              WARNING: Another user is editing the Configuration
            </SHTypography>
          </SHStack>
        }
        body={
          <SHStack>
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                width: 430,
              }}
            >
              {currentEditor} is currently editing the Configuration. We only
              allow one user to edit the Configuration at one time. If you
              continue, you will kick them out of edit mode, and their unsaved
              changes will be discarded.
            </SHTypography>
            <br />
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              Proceed with kicking out {currentEditor}?
            </SHTypography>
          </SHStack>
        }
        footer={
          <SHStack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={7}
          >
            <SHButton
              color="primary"
              variant="text"
              onClick={handleOnClose}
              size="extraMedium"
            >
              Cancel
            </SHButton>
            <SHButton
              color="primary"
              variant="contained"
              isLoading={isLoading}
              onClick={handleOnClickYes}
              size="extraMedium"
            >
              Yes, kick them out
            </SHButton>
          </SHStack>
        }
      />
    </>
  );
};
