import { SVGProps } from "react";

export const ArrowNextSVG = ({
  fill = "#25324B",
  width = "7",
  height = "12",
  transform,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    transform={transform}
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 11.2071C-0.0976311 10.8166 -0.097631 10.1834 0.292893 9.79289L3.96079 6.125L0.292894 2.45711C-0.0976307 2.06658 -0.0976307 1.43342 0.292894 1.04289C0.683418 0.652369 1.31658 0.652369 1.70711 1.04289L6.08211 5.41789C6.47263 5.80842 6.47263 6.44158 6.08211 6.83211L1.70711 11.2071C1.31658 11.5976 0.683417 11.5976 0.292893 11.2071Z"
      fill={fill}
    />
  </svg>
);
