import {
  SHChip,
  SHImage,
  SHStack,
  SHTableHead,
  SHTableHeadProps,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { SelectedProductDTO } from "@models/platform-analysis/entities/steps/feature";
import { useTheme } from "@mui/material";
import { SummaryAnalysisCell } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-cell";
import { hexToRGBA } from "@utils";

interface SummaryReviewHeaderProps extends SHTableHeadProps {
  selectedProducts?: SelectedProductDTO[];
  isFeatureReview?: boolean;
}

export const SummaryReviewHeader = ({
  selectedProducts,
  isFeatureReview = false,
  ...others
}: SummaryReviewHeaderProps) => {
  const { palette } = useTheme();
  return (
    <SHTableHead {...others}>
      <SHTableRow
        sx={{
          th: { borderLeft: "none" },
          "th:first-child": {
            borderLeft: `1px solid ${hexToRGBA(palette.primary[50], 0.3)}`,
          },
        }}
      >
        <SummaryAnalysisCell cellWidth={isFeatureReview ? "252px" : "362px"}>
          <SHTypography
            variant="subtitle1"
            sx={{
              lineHeight: "150%",
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            Platform
          </SHTypography>
        </SummaryAnalysisCell>
        {isFeatureReview && (
          <SummaryAnalysisCell cellWidth={"110px"}>
            <SHStack>
              <SHTypography
                variant="caption"
                sx={{
                  fontWeight: 700,
                  lineHeight: "150%",
                  textAlign: "center",
                }}
              >
                Selections /<br /># Available
              </SHTypography>
            </SHStack>
          </SummaryAnalysisCell>
        )}
        {selectedProducts?.map((product, index) => (
          <SummaryAnalysisCell key={index} cellWidth={"139px"}>
            <SHStack
              gap={"5px"}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              // height={"100%"}
            >
              <SHStack
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"15px"}
                // height={"100%"}
              >
                {product.isProductMain && (
                  <SHChip
                    label={"Main"}
                    color={"warning"}
                    variant={"outlined"}
                    sx={{
                      top: 5.5,
                      right: 5.5,
                      width: 51,
                      height: 22,
                      padding: "4px 8px",
                      position: "absolute",
                      "& >.MuiChip-label": {
                        overflow: "unset",
                      },
                    }}
                  />
                )}
                <SHStack
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  gap={"5px"}
                  height={"100%"}
                >
                  <SHImage
                    src={product?.productLogo?.url}
                    width={68}
                    height={68}
                  />
                  <SHStack>
                    <SHTypography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                      textAlign={"center"}
                    >
                      {product?.productName}
                    </SHTypography>
                  </SHStack>
                </SHStack>
              </SHStack>
            </SHStack>
          </SummaryAnalysisCell>
        ))}
      </SHTableRow>
    </SHTableHead>
  );
};
