import { UserType } from "@models/auth";

export const PracticeAccountTypeTextMap: {
  [key in UserType]?: {
    title: string;
    description: string;
  };
} = {
  [UserType.AdviserTrial || UserType.AdviserAdminTrial]: {
    title: "Trial (Unlimited users)",
    description:
      "Has access to limited functionality on SuitabilityHub. Can only view certain sections of Platform Profiles, a limited number of posts in News & updates, and cannot run Suitability Reviews",
  },
  [UserType.AdviserAdviser || UserType.AdviserAdminAdviser]: {
    title: "Adviser ({{inUsed}}/{{total}} licences in use)",
    description:
      "Has full access to Platform Profiles, able to run Suitability Reviews and view all posts made by product providers. Only the names of Advisers can be featured on Suitability Reviews",
  },
  [UserType.AdviserSupportStaff || UserType.AdviserAdminSupportStaff]: {
    title: "Support staff (Unlimited users)",
    description:
      "Has access to the same functionality as an Adviser, and can assist in creating Suitability Reviews. However, the names of Support staff cannot be featured on Suitability Reviews",
  },
};

export const generateSubTitle = (isMyProfile?: boolean) => {
  if (isMyProfile)
    return {
      Profile: "Manage your SuitabilityHub profile",
      ProfilePhoto: "Help fellow team members recognise your account",
      PersonalDetails: "Set the user details of your account",
      ChangePassword: "Change my password",
      AccountType:
        "Account type can only be changed by users with administrative privileges",
      AdministrativePrivileges:
      "Users with administrative privileges can manage team members and the practice’s subscription details. Only other users can change your admin privileges. Manage this through the practice’s Team page",
    };
  return {
    Profile: "Manage this user’s SuitabilityHub profile",
    ProfilePhoto: "Help fellow team members recognise this account",
    PersonalDetails: "Set the user details of this account",
    ChangePassword: "Send user password reset email",
    AccountType:
      "Users must be assigned an account type. These determine their core permissions",
    AdministrativePrivileges:
      "Users with administrative privileges can manage team members and the practice’s subscription details",
  };
};
