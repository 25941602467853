import { SHStack } from "@components/design-systems";
import { SHButton } from "../../design-systems/sh-button";
import { SHDialog } from "../../design-systems/sh-dialog";

interface ApprovePostConfirmationDialogProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onOk: () => void;
}

export const ApprovePostConfirmationDialog = ({
  open,
  isLoading,
  onClose,
  onOk,
}: ApprovePostConfirmationDialogProps) => {
  return (
    <SHDialog
      open={open}
      onClose={onClose}
      width={600}
      header="Approve post?"
      body="All users will be able to see this post. Approve?"
      footer={
        <SHStack
          width="100%"
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            onClick={onClose}
            variant="text"
            size="extraMedium"
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onOk}
          >
            Yes, approve post
          </SHButton>
        </SHStack>
      }
    />
  );
};
