import { PlusButton } from "@components/buttons/plus";
import {
  SHButton,
  SHContainer,
  SHImage,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHDataGrid } from "@components/design-systems/sh-data-grid";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { DateFormat } from "@constants/format";
import { APIExtRoutes, PageRoutes } from "@constants/routes";
import { TopBar } from "@layouts/top-bar";
import { PageMode, ParentState } from "@models/core/navigation";
import { SupplierAggregatedDTO } from "@models/supplier/entities/suppliers";
import { useTheme } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { format } from "date-fns";
import { useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  DefaultSuppliersStatusFilterValue,
  StatusSuppliersFilterOptions,
} from "./constant";

const nameOf = nameOfFactory<SupplierAggregatedDTO>();
const columnHelper = createColumnHelper<SupplierAggregatedDTO>();
export const SuppliersPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: nameOf("companyName"),
        header: "Supplier name",
        enableColumnFilter: false,
        cell: (props) => (
          <SHStack
            data-testid="supplier-name"
            spacing={2}
            direction="row"
            alignItems={"center"}
            justifyContent={"start"}
          >
            <SHImage
              width={40}
              height={40}
              style={{ objectFit: "contain" }}
              alt={props.cell.getValue().companyName ?? ""}
              src={props.cell.getValue().companyLogo}
            />
            <SHTypography variant="subtitle2">
              {props.cell.getValue().companyName}
            </SHTypography>
          </SHStack>
        ),
      }),
      columnHelper.accessor("subscriptionEnd", {
        header: "Subscription expiry",
        cell: (props) => {
          const date = props.cell.getValue();
          return date ? format(new Date(date), DateFormat) : "";
        },
        enableColumnFilter: false,
        meta: {
          sx: {
            width: "200px",
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: nameOf("bdmUsedCount"),
        header: "Sales users",
        enableColumnFilter: false,
        cell: (props) => (
          <SHTypography>
            {`${props.cell.getValue().bdmUsedCount} of ${
              props.cell.getValue().bdmCount
            }`}
          </SHTypography>
        ),
        meta: {
          sx: {
            width: "180px",
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: nameOf("premiumUsedCount"),
        header: "Standard users",
        enableColumnFilter: false,
        cell: (props) => (
          <SHTypography>
            {`${props.cell.getValue().premiumUsedCount} of ${
              props.cell.getValue().premiumCount
            }`}
          </SHTypography>
        ),
        meta: {
          sx: {
            width: "180px",
          },
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => <StatusBadge status={props.cell.getValue()} />,
        meta: {
          filterData: StatusSuppliersFilterOptions,
          sx: {
            width: "180px",
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "actionCol",
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (props) => (
          <SHStack spacing={2} direction="row">
            <SHButton
              startIcon={<EditSVG color={palette.common.white} />}
              variant="contained"
              onClick={() =>
                navigate(
                  `${PageRoutes.suppliers}/${props.cell.getValue().id}`,
                  { state: { pageMode: PageMode.Edit } as ParentState },
                )
              }
            >
              Edit
            </SHButton>
            <SHButton
              variant="outlined"
              onClick={() =>
                navigate(`${PageRoutes.suppliers}/${props.cell.getValue()?.id}`)
              }
            >
              View
            </SHButton>
          </SHStack>
        ),
        meta: {
          sx: {
            width: "190px",
          },
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack
        spacing={3}
        sx={{ paddingY: 3 }}
        data-testid="suppliers-list-view"
      >
        <TopBar
          title={"Suppliers"}
          tools={
            <>
              <PlusButton
                data-testid="new-supplier-button"
                variant="contained"
                size="extraMedium"
                onClick={() =>
                  navigate(
                    generatePath(PageRoutes.supplierOverview, {
                      supplierId: "new",
                    }),
                  )
                }
              >
                New supplier
              </PlusButton>
            </>
          }
        />
        <SHDataGrid
          state={{
            ...DefaultDataState,
            columnFilters: [
              {
                id: nameOf("status"),
                value: DefaultSuppliersStatusFilterValue,
              },
            ],
            sorting: [{ id: nameOf("companyName"), desc: false }],
          }}
          columns={columns as any}
          odata={{
            url: APIExtRoutes.odataSuppliers,
            globalFilterColumns: [nameOf("companyName")],
          }}
          emptyMessage={"No suppliers. Click on “New supplier” to add!"}
          searchBoxProps={{
            placeholder: "Search suppliers",
          }}
        />
      </SHStack>
    </SHContainer>
  );
};
