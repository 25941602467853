import { SHButton, SHDialog, SHStack } from "@components/design-systems";

interface DiscardDraftDialogProps {
  onClose?: () => void;
  onSubmit?: () => void;
  isSubmitting?: boolean;
}

export const DiscardDraftDialog = ({
  onClose,
  onSubmit,
  isSubmitting = false,
}: DiscardDraftDialogProps) => {
  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: { xs: "100%", md: 468 },
        },
      }}
      header="Discard your draft?"
      body="You are about to discard all changes made to the Profile since the last published version. Are you sure?"
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={onSubmit}
          >
            Discard
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Keep draft
          </SHButton>
        </SHStack>
      }
    />
  );
};
