import {
  SHAutocomplete,
  SHCheckbox,
  SHStack,
  SHTextFieldProps,
  SHTypography,
} from "@components/design-systems";
import { useInvestment } from "@hooks/useInvestment";
import { useManagedAccount } from "@hooks/useManagedAccount";
import {
  InvestmentQueryOptions,
  InvestmentSelectionDTO,
  SelectedInvestmentDTO,
} from "@models/managed-accounts/entities/step/setup";
import { isEmpty } from "lodash";
import React, { ReactElement, forwardRef } from "react";
import { cloneElement, useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";

export interface SelectInvestmentsFavouritedProps {
  selectedCodes: string[] | null;
  maxLength?: number;
  disabled?: boolean;
  textFieldProps?: Omit<SHTextFieldProps, "value">;

  onAppend: (value: SelectedInvestmentDTO) => void;
  onRemove: (index: string) => void;
  onRemoveAll: () => void;
  hasChange?: (value: boolean) => void;
}

export const SelectInvestmentsFavourited = ({
  disabled,
  maxLength,
  textFieldProps,
  selectedCodes = [],
  onRemove,
  onAppend,
  onRemoveAll,
  hasChange,
}: SelectInvestmentsFavouritedProps) => {
  const { managedAccountId } = useManagedAccount();
  const {
    investmentFavourited,
    setInvestmentFavourited,
    loadInvestmentFavourited,
  } = useInvestment();

  const [inputValue, setInputValue] = useState("");

  const selectedInvestments = investmentFavourited.filter((option) =>
    selectedCodes?.includes(option.shCode),
  );

  const loadData = async (queryOptions: InvestmentQueryOptions) => {
    const responseData = await loadInvestmentFavourited(queryOptions);

    if (responseData) setInvestmentFavourited(responseData);
  };

  useEffect(() => {
    const queryOptions: InvestmentQueryOptions = {
      managedAccountId: managedAccountId ?? "new",
    };

    loadData(queryOptions);
    // eslint-disable-next-line
  }, [managedAccountId]);

  // eslint-disable-next-line
  const ListboxComponent = React.useCallback(
    forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
      (
        props: React.HTMLAttributes<HTMLElement>,
        ref: React.ForwardedRef<HTMLDivElement>,
      ) => {
        const { children, tabIndex, ...others } = props;
        const dataItems = children as ReactElement[];

        const itemHeight = 44;
        const maxItemDisplayed = 6;
        const currentItemCount = dataItems.length;

        const getCurrentHeight = () => {
          if (currentItemCount === 1) return currentItemCount * itemHeight;
          if (currentItemCount >= maxItemDisplayed) {
            return maxItemDisplayed * itemHeight + 10;
          }
          return currentItemCount * itemHeight + 10;
        };

        return (
          <div ref={ref}>
            <Virtuoso
              style={{ height: getCurrentHeight() }}
              data={dataItems}
              itemContent={(index, child) => {
                return cloneElement(child);
              }}
              {...others}
            />
          </div>
        );
      },
    ),
    [],
  );

  return (
    <SHStack spacing={"16px"} direction="column">
      <SHAutocomplete
        multiple
        disabled={disabled}
        disableCloseOnSelect
        disableListWrap
        isOptionEqualToValue={(option, value) =>
          option.shCode === value?.shCode
        }
        onClose={() => {
          setInputValue("");
        }}
        inputValue={inputValue}
        value={selectedInvestments ?? []}
        getOptionLabel={(option: InvestmentSelectionDTO) =>
          `${option.displayName} ${option.code}}` ?? ""
        }
        componentsProps={{
          popper: {
            placement: "top",
          },
        }}
        textFieldProps={{
          ...textFieldProps,
          InputLabelProps: { shrink: true },
          placeholder: selectedInvestments?.length
            ? undefined
            : textFieldProps?.placeholder,
          InputProps: {
            startAdornment: selectedInvestments?.length ? (
              <SHTypography
                disabled={disabled}
                variant="body1"
                sx={{ paddingLeft: "10px" }}
              >
                {`${selectedInvestments?.length} selected`}
              </SHTypography>
            ) : undefined,
          },
          onChange: (e) => {
            setInputValue(e.target.value);
          },
        }}
        onChange={(event: any, newValue, reason, details) => {
          if (
            reason === "removeOption" &&
            event?.type === "keydown" &&
            event?.key === "Backspace"
          ) {
            return;
          }
          if (hasChange) hasChange(true);
          if (isEmpty(newValue)) {
            onRemoveAll();
            return;
          }
          if (
            newValue.some((option) => option.shCode === details?.option.shCode)
          ) {
            onAppend({
              ...details?.option,
              weight: 0,
              value: 0,
              shCode: details?.option?.shCode ?? "",
            });
          } else onRemove(details?.option.shCode ?? "");
        }}
        options={investmentFavourited}
        renderOption={(props, option, { selected, index }) => {
          const isChecked = selected;
          return (
            <li {...props} key={option.shCode} style={{ padding: "1px 10px" }}>
              <SHStack alignItems={"center"} direction="row" spacing={1}>
                <SHCheckbox checked={isChecked} />
                {option.code && (
                  <SHTypography
                    variant="body3"
                    fontWeight={400}
                    colorVariant="disabled"
                  >
                    {option.code}
                  </SHTypography>
                )}

                <SHTypography variant="body3" fontWeight={500}>
                  {option.displayName}
                </SHTypography>
              </SHStack>
            </li>
          );
        }}
        ListboxComponent={ListboxComponent}
      />
    </SHStack>
  );
};
