import { toODataString } from "@components/design-systems/sh-data-grid/util";
import { APIExtRoutes } from "@constants/routes";
import { UserType } from "@models/auth";
import { APIResponse } from "@models/core";
import {
  CreateSupplierUserDTO,
  SupplierUserDTO,
  SupplierUserLov,
  SupplierUserSelfUpdateDTO,
  UpdateSupplierUserDTO,
} from "@models/supplier/entities/supplierUser";
import { ApplicationUser } from "@models/users/entities/appUser";
import { UserStatus } from "@models/users/enums/status";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { TableState } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { generatePath } from "react-router";

export async function getSupplierUser(
  supplierId?: string,
  userId?: string,
): Promise<APIResponse<SupplierUserDTO>> {
  const response = await APIGatewayService.get<SupplierUserDTO>(
    generatePath(APIExtRoutes.supplierUser, {
      supplierId,
      userId,
    }),
  );
  return handleErrorProxy(response);
}

export async function createSupplierUser(
  supplierUser?: CreateSupplierUserDTO,
): Promise<APIResponse<SupplierUserDTO>> {
  const response = await APIGatewayService.post<CreateSupplierUserDTO>(
    APIExtRoutes.createSupplierUser,
    supplierUser,
  );

  return handleErrorProxy(
    response,
    "New user created! A welcome email has been sent to them.",
  );
}

export async function updateSupplierUser(
  supplierUserId?: string,
  supplierUser?: UpdateSupplierUserDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.patch<string>(
    generatePath(APIExtRoutes.updateSupplierUser, { userId: supplierUserId }),
    supplierUser,
  );

  return handleErrorProxy(response, "Changes have been saved.");
}

export async function updateSupplierUserStatus(
  supplierUserId?: string,
  status?: UserStatus,
): Promise<APIResponse<{ status: UserStatus }>> {
  const response = await APIGatewayService.patch<{ status: UserStatus }>(
    generatePath(APIExtRoutes.updateStatusSupplierUser, {
      userId: supplierUserId,
      status: status,
    }),
  );

  return handleErrorProxy(response, "Changes have been saved.");
}

export async function getSupplierUserLov(
  supplierId?: string,
): Promise<APIResponse<SupplierUserLov>> {
  const response = await APIGatewayService.get<SupplierUserLov>(
    generatePath(APIExtRoutes.supplierUserLov, {
      supplierId,
    }),
  );
  return handleErrorProxy(response);
}

/**
 * Transfer Supplier Ownership.
 *
 * @param oldOwnerId auth0Id of old supplier owner.
 * @param newOwnerId auth0Id of new supplier owner.
 */
export async function transferSupplierOwnership(
  oldOwnerId: string,
  newOwnerId: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.patch<string>(
    generatePath(APIExtRoutes.transferSupplierUser, {
      oldOwnerId,
      newOwnerId,
    }),
  );

  return handleErrorProxy(response, "Supplier Owner transferred successfully!");
}

/**
 * Get Supplier's Premium Standard Users
 *
 *  @param supplierId supplier id.
 */
// TODO: Add return type
export async function getTransferSupplierOwnershipLov(supplierId: string) {
  const nameOf = nameOfFactory<ApplicationUser>();
  const response = await APIGatewayService.get(
    `${generatePath(APIExtRoutes.odataSupplierUsers, {
      supplierId,
    })}${toODataString(
      {
        columnFilters: [
          {
            id: nameOf("status"),
            value: [UserStatus.Active],
          },
          {
            id: nameOf("userType"),
            value: [UserType.SupplierPremiumStandard],
          },
        ],
        pagination: {
          pageIndex: 0,
          pageSize: 0,
        },
      } as TableState,
      true,
      [nameOf("auth0Id"), nameOf("avatarUrl"), nameOf("name")],
    )}`,
  );

  return handleErrorProxy(
    response,
    "Get supplier's premium standard users successfully!",
  );
}

export async function completeProfileSupplierUser(
  supplierUserId?: string,
  userInfo?: SupplierUserSelfUpdateDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.patch<string>(
    generatePath(APIExtRoutes.completeProfileSupplierUser, {
      userId: supplierUserId,
    }),
    userInfo,
  );
  return handleErrorProxy(
    response,
    "Welcome to SuitabilityHub. Happy researching!",
  );
}
