import { SVGProps } from "react";

const NetFlowsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      stroke="#25324B"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 3v4h4M9 17v4M17 14v7M13 13v8M21 12v9"
      stroke="#25324B"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NetFlowsSVG;
