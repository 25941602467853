import { SHButton, SHButtonProps } from "@components/design-systems";
import { PlusSVG } from "@components/svgs";
import { useTheme } from "@mui/material";
import { useMemo } from "react";

export const PlusButton = (props: SHButtonProps) => {
  const { palette } = useTheme();
  const { children, sx, variant } = props;
  const isOutlined = useMemo(() => variant === "outlined", [variant]);
  return (
    <SHButton
      variant="contained"
      size="extraMedium"
      startIcon={
        <PlusSVG
          color={isOutlined ? undefined : palette.common.white}
          width={20}
          height={20}
        />
      }
      {...props}
      sx={
        isOutlined
          ? {
              "&:active svg path": {
                fill: palette.common.white,
              },
              "&:disabled svg path": {
                fill: palette.text.disabled,
              },
              ...sx,
            }
          : sx
      }
    >
      {children}
    </SHButton>
  );
};
