import { Avatar, Badge } from "@mui/material";

export interface SHAvatarProps {
  src?: string;
  isCircular?: boolean;
  width?: number | string;
  height?: number | string;
  actionIcon?: React.ReactNode;
  objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down";
  className?: string;
}

export const SHAvatar = ({
  src,
  actionIcon,
  width = 120,
  height = 120,
  isCircular = true,
  objectFit = "cover",
  className = "sh-avatar",
}: SHAvatarProps): JSX.Element => {
  return (
    <Badge
      overlap={isCircular ? "circular" : "rectangular"}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      badgeContent={actionIcon}
      sx={{
        "& .MuiBadge-badge": {
          top: "14%",
          right: "14%",
        },
      }}
    >
      <Avatar
        variant={isCircular ? "circular" : "square"}
        sx={{ width: width, height: height }}
        imgProps={{
          sx: {
            objectFit: objectFit,
          },
        }}
        src={src}
        data-testid="sh-avatar"
        className={className}
      />
    </Badge>
  );
};
