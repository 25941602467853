import { SupplierLicenseDTO } from "@models/supplier/entities/licenses";
import {
  SupplierAccountTypeDTO,
  SupplierDTO,
} from "@models/supplier/entities/suppliers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SupplierStore {
  supplierData?: SupplierDTO;
  accountTypeLicenses: SupplierAccountTypeDTO[];
  // ui?: any
}
const initialState: SupplierStore = {
  supplierData: undefined,
  accountTypeLicenses: [],
};

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    updateSupplier: (
      state,
      action: PayloadAction<SupplierDTO | undefined>,
    ) => ({
      ...state,
      supplierData: action.payload,
    }),
    updateLicenses: (state, action: PayloadAction<SupplierLicenseDTO[]>) => {
      if (state.supplierData?.supplierLicenseMaps)
        state.supplierData.supplierLicenseMaps = action.payload;
      return state;
    },
    updateAccountTypeLicense: (
      state,
      action: PayloadAction<SupplierAccountTypeDTO[]>,
    ) => ({
      ...state,
      accountTypeLicenses: action.payload,
    }),
  },
});

export const { updateSupplier, updateLicenses, updateAccountTypeLicense } =
  supplierSlice.actions;

export default supplierSlice.reducer;
