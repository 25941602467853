import { ArrowNextSVG } from "@components/svgs";
import { IconButton, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import React from "react";

export interface ArrowButtonProps {
  buttonWidth?: string;
  buttonHeight?: string;
  iconWidth?: string;
  iconHeight?: string;
  hidden?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  direction: "next" | "previous";
  className: string;
  boxShadow?: string;
  boxShadowInset?: string;
  borderHoverColor?: string;
}

export const ArrowButton = ({
  buttonWidth = "24px",
  buttonHeight = "24px",
  iconWidth = "6.5px",
  iconHeight = "11px",
  boxShadowInset = `inset 2px 3px 2px ${hexToRGBA("#414959", 0.4)}`,
  boxShadow,
  hidden = true,
  className,
  direction,
  borderHoverColor,
  ...others
}: ArrowButtonProps) => {
  const { shadows, palette } = useTheme();
  if (!boxShadow) {
    boxShadow = shadows[1];
  }

  return (
    <IconButton
      className={className}
      disableRipple
      {...others}
      sx={{
        width: buttonWidth,
        height: buttonHeight,
        display: hidden ? "none" : "flex",
        justifyContent: "center",
        alignItems: "center",
        color: palette.common.white,
        border: `1px solid ${palette.common.black}`,
        borderRadius: "50%",
        backgroundColor: hexToRGBA(palette.common.white, 0.25),
        transition: "all 0.2s ease",
        cursor: "pointer",
        padding: direction === "next" ? "0 0 0 2px" : "0 2px 0 0",
        boxShadow,
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "#EBEEF5",
          borderColor: borderHoverColor,
        },
        "&:active": {
          boxShadow: boxShadowInset,
        },
        "&[disabled]": {
          cursor: "auto",
          opacity: 0.6,
          backgroundColor: palette.common.white,
          "&>svg>path": {
            fill: palette.text.disabled,
          },
          "&:active": {
            boxShadow: "none",
          },
          "&:hover": {
            boxShadow: shadows[1],
          },
        },
      }}
    >
      <ArrowNextSVG
        width={iconWidth}
        height={iconHeight}
        transform={direction === "next" ? "rotate(0)" : "rotate(180)"}
      />
    </IconButton>
  );
};
