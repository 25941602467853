import {
  SHDotGroup,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { HighlightType } from "@models/configuration";
import { SubProductDTO } from "@models/product/entities/subProduct";
import { useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { hexToRGBA } from "@utils";
import { isEmpty, isNil } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export interface SubProductCardProps extends SHStackProps {
  subProduct: SubProductDTO;
  subProductIndex: number;
  isDisabled?: boolean;
  isEditMode?: boolean;
  isSelected?: boolean;
  onChangeSubProduct: (idx: number) => void;
  onArchivedUnArchived?: (
    subProduct?: SubProductDTO,
    isArchived?: boolean,
  ) => void;
  [key: string]: any;
}
export const SubProductCard = ({
  subProduct,
  subProductIndex,
  isDisabled,
  isSelected,
  isEditMode,
  onChangeSubProduct,
  onArchivedUnArchived,
  ...others
}: SubProductCardProps) => {
  const { palette } = useTheme();
  const { subProductNameMapping, subProHighlightTypeMapping } = useSelector(
    (state: RootState) => state.product,
  );

  const colorSx = useMemo(() => {
    if (isSelected)
      return {
        bgcolor: hexToRGBA(palette.primary.light, 0.1),
        borderBottom: `1px solid ${palette.secondary[100]}`,
        "&:hover": {
          bgcolor: hexToRGBA(palette.primary.light, 0.15),
        },
      };
    if (subProduct?.status === "Archived" && isEditMode)
      return {
        bgcolor: hexToRGBA("#c5ddfd", 0.1),
        borderBottom: `1px solid ${palette.secondary[100]}`,
        "&:hover": {
          bgcolor: hexToRGBA(palette.primary.light, 0.15),
        },
      };

    return {
      bgcolor: hexToRGBA(palette.common.white, 0.25),
      "&:hover": {
        bgcolor: hexToRGBA(palette.common.white, 0.5),
      },
    };
  }, [isEditMode, isSelected, palette, subProduct?.status]);

  return (
    <SHStack
      spacing={2}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      onClick={() =>
        !isSelected ? onChangeSubProduct(subProductIndex) : undefined
      }
      sx={{
        backdropFilter: "blur(0.5px)",
        padding: "24px 10px",
        cursor: "pointer",
        ...colorSx,
      }}
      {...others}
    >
      <SHStack
        direction="row"
        justifyContent="center"
        alignItems={"center"}
        spacing={0.5}
      >
        <SHTypography variant="subtitle1" sx={{ wordBreak: "break-word" }}>
          {subProductNameMapping?.[subProductIndex]}
        </SHTypography>
        {isEditMode && (
          <SHDotGroup
            redDot={
              isNil(subProduct.id) ||
              !isEmpty(subProHighlightTypeMapping?.[subProductIndex]) ||
              subProductNameMapping?.[subProductIndex] !==
                subProduct?.publishedName ||
              (isDisabled && subProduct.highlightType === HighlightType.Edited)
            }
            paddingBottom={"10px"}
          />
        )}
      </SHStack>
      {/* TODO: Will be reverted when it's implemented */}
      {/* {isEditMode &&
        typeof onArchivedUnArchived === "function" &&
        (subProduct?.status === "Active" ? (
          <IconButton
            onClick={() => onArchivedUnArchived(subProduct, true)}
            sx={{ padding: 0 }}
            disabled={isDisabled}
          >
            <RecycleBinSVG
              color={isDisabled ? palette.text.disabled : palette.primary.main}
            />
          </IconButton>
        ) : (
          <SHStack direction={"row"} alignItems={"center"} spacing={2}>
            <StatusBadge
              style={{ height: "21px" }}
              status={ProductStatus?.Archived}
            />
            <RollBackButton
              disabled={isDisabled}
              color={palette.primary.main}
              width={23}
              height={23}
              onClick={() => onArchivedUnArchived(subProduct, false)}
            />
          </SHStack>
        ))} */}
    </SHStack>
  );
};
