import { APIExtRoutes } from "@constants";
import {
  ConfigurationFeature,
  ConfigurationGroup,
  HighlightType,
} from "@models/configuration";
import { ProductHistoryDTO } from "@models/product/entities/history";
import {
  ProductListDTO,
  ProductDataBannerInfoDTO, ProductBrandingDataDto,
} from "@models/product/entities/product";
import {
  DependFieldsMapping,
  SubProductHighlightTypeMapping,
  SubProductDTO,
  SubProductNameMapping,
} from "@models/product/entities/subProduct";

import {
  generateFieldMapping,
  generateSubProductHighlightTypeMapping,
  generateSubProductNameMapping,
  getProductNameByProduct,
} from "@redux/slices/product/util";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getExistingFeeSchedule,
  getProduct,
  getProductBannerInfo,
  getProductBrandingData,
  getProductChangeHistory,
  getProductGroups,
  getProductReleaseLog,
  postProduct,
  putProduct,
} from "@services/product/productsService";
import {
  getNewSubProduct,
  getSubProduct,
  getSubProductReleaseLog,
  getSubProducts,
  postSubProducts,
} from "@services/product/subProductsService";
import { isEmpty, some } from "lodash";

interface ProductStore {
  productGroups: ConfigurationGroup[];
  productData?: ProductListDTO;
  bannerFeatures?: ConfigurationFeature[];
  productUI: {
    isEditMode?: boolean;
    selectedGroup: number;
    productName?: string;
    isLoading?: boolean;
    isLoadingEditMode?: boolean;
    beingKickedBy?: string;
    currentEditor?: string;
    showClaimDeniedDialog?: boolean;
    showBeingKickedDialog?: boolean;
    showKickoutDialog?: boolean;
    isLoadingProductGroups?: boolean;
    isLoadingProductData?: boolean;
    isLoadingProductBranding?: boolean;
    showViewReleaseLog?: boolean;
    releaseName?: string;
    isLoadingProductHistory?: boolean;
    isLoadingSubProducts?: boolean;
    isLoadingNewSubProduct?: boolean;
  };
  productBannerInfo?: ProductDataBannerInfoDTO[];
  productChangeHistory?: ProductHistoryDTO[];
  subProducts?: SubProductDTO[];
  selectedSubProduct?: SubProductDTO;
  newSubProduct?: SubProductDTO;
  subProductNameMapping?: SubProductNameMapping;
  dependFieldsMapping?: DependFieldsMapping;
  subProHighlightTypeMapping?: SubProductHighlightTypeMapping;
  productBrandingData?: ProductBrandingDataDto
}
const initialState: ProductStore = {
  productData: undefined,
  productGroups: [],
  bannerFeatures: [],
  productUI: {
    isEditMode: false,
    selectedGroup: 0,
    productName: undefined,
    isLoading: false,
    isLoadingEditMode: false,
    beingKickedBy: "",
    currentEditor: "",
    showClaimDeniedDialog: false,
    showBeingKickedDialog: false,
    showKickoutDialog: false,
    isLoadingProductGroups: false,
    isLoadingProductData: false,
    showViewReleaseLog: false,
    releaseName: "",
    isLoadingProductHistory: false,
    isLoadingSubProducts: false,
  },
  productBannerInfo: undefined,
  subProducts: [],
  selectedSubProduct: undefined,
  newSubProduct: undefined,
  subProductNameMapping: undefined,
  dependFieldsMapping: undefined,
  subProHighlightTypeMapping: undefined,
  productBrandingData: undefined,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    updateProductData: (
      state,
      action: PayloadAction<ProductListDTO | undefined>,
    ) => {
      state.productData = action.payload;
    },
    updateGroups: (state, action: PayloadAction<ConfigurationGroup[]>) => {
      state.productGroups = action.payload;
    },
    updateActiveGroup: (state, action: PayloadAction<ConfigurationGroup>) => {
      const currentSelectedGroup =
        state.productGroups[state.productUI.selectedGroup];
      if (!currentSelectedGroup) return;
      state.productGroups[state.productUI.selectedGroup] = {
        ...currentSelectedGroup,
        ...action.payload,
      };
    },

    updateBannerFeatures: (
      state,
      action: PayloadAction<ConfigurationFeature[]>,
    ) => {
      state.bannerFeatures = action.payload;
    },
    updateEditMode: (state, action: PayloadAction<boolean>) => {
      state.productUI.isEditMode = action.payload;
    },
    updateSelectedGroup: (state, action: PayloadAction<number>) => {
      state.productUI.selectedGroup = action.payload;
    },
    updateProductName: (state, action: PayloadAction<string | undefined>) => {
      state.productUI.productName = action.payload;
    },
    updateReleaseName: (state, action: PayloadAction<string | undefined>) => {
      state.productUI.releaseName = action.payload;
    },
    updateProductBannerInfo: (
      state,
      action: PayloadAction<ProductDataBannerInfoDTO[]>,
    ) => ({
      ...state,
      productBannerInfo: action.payload,
    }),
    updateProductChangeHistory: (
      state,
      action: PayloadAction<ProductHistoryDTO[]>,
    ) => ({
      ...state,
      productChangeHistory: action.payload,
    }),
    resetProduct: (state) => {
      state.bannerFeatures = initialState.bannerFeatures;
      state.productBannerInfo = initialState.productBannerInfo;
      state.productData = initialState.productData;
      state.productGroups = initialState.productGroups;
      state.productUI = initialState.productUI;
    },
    updateCurrentEditor: (state, action: PayloadAction<string>) => {
      state.productUI.currentEditor = action.payload;
    },
    updateBeingKickedBy: (state, action: PayloadAction<string>) => {
      state.productUI.beingKickedBy = action.payload;
    },
    updateLoadingEditMode: (state, action: PayloadAction<boolean>) => {
      state.productUI.isLoadingEditMode = action.payload;
    },
    updateShowClaimDeniedDialog: (state, action: PayloadAction<boolean>) => {
      state.productUI.showClaimDeniedDialog = action.payload;
    },
    updateShowBeingKickedDialog: (state, action: PayloadAction<boolean>) => {
      state.productUI.showBeingKickedDialog = action.payload;
    },
    updateShowKickoutDialog: (state, action: PayloadAction<boolean>) => {
      state.productUI.showKickoutDialog = action.payload;
    },
    updateShowViewReleaseLog: (state, action: PayloadAction<boolean>) => {
      state.productUI.showViewReleaseLog = action.payload;
    },
    updateSubProductName: (
      state,
      action: PayloadAction<SubProductNameMapping | undefined>,
    ) => {
      state.subProductNameMapping = action.payload;
    },
    updateSubProductHighlightTypeMapping: (
      state,
      action: PayloadAction<SubProductHighlightTypeMapping | undefined>,
    ) => {
      state.subProHighlightTypeMapping = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(loadProduct.pending, (state, action) => {
      state.productUI.isLoadingProductData = true;
    });
    // View Release Log
    builder.addCase(loadProductReleaseLog.fulfilled, (state, action) => {
      state.productUI.isLoadingProductData = false;
      //Update product Name
      const newProductName = getProductNameByProduct(action.payload?.data);
      if (newProductName) {
        state.productUI.productName = newProductName;
      }
      if (
        action.payload?.data?.groups?.[0]?.id !==
        state.productGroups[state.productUI.selectedGroup]?.id
      ) {
        return;
        //@TODO: Save all data of groups
      }

      state.productData = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      //Update active group
      if (!state.productData) return;
      const newGroup = state.productData.groups?.[0];
      const currentSelectedGroup =
        state.productGroups[state.productUI.selectedGroup];
      if (
        !currentSelectedGroup ||
        !newGroup ||
        newGroup.name !== currentSelectedGroup.name
      )
        return;
      state.productGroups[state.productUI.selectedGroup] = {
        ...currentSelectedGroup,
        ...newGroup,
        sections: undefined,
      };
    });

    builder.addCase(loadProduct.fulfilled, (state, action) => {
      state.productUI.isLoadingProductData = false;
      //Update product Name
      const newProductName = getProductNameByProduct(action.payload?.data);
      if (newProductName) {
        state.productUI.productName = newProductName;
      }
      if (
        action.payload?.data?.groups?.[0]?.id !==
        state.productGroups[state.productUI.selectedGroup]?.id
      ) {
        return;
        //@TODO: Save all data of groups
      }

      state.productData = action.payload.isSuccess
        ? action.payload?.data
        : undefined;

      /*  //Update active group
      if (!state.productData) return;
      const newGroup = state.productData.groups?.[0];
      const currentSelectedGroup =
        state.productGroups[state.productUI.selectedGroup];
      if (
        !currentSelectedGroup ||
        !newGroup ||
        newGroup.name !== currentSelectedGroup.name
      )
        return;
      state.productGroups[state.productUI.selectedGroup] = {
        ...currentSelectedGroup,
        ...newGroup,
        sections: undefined,
      }; */
    });
    builder.addCase(loadProduct.rejected, (state, action) => {
      state.productUI.isLoadingProductData = false;
    });

    builder.addCase(createProduct.fulfilled, (state, action) => {
      if (action.payload.isSuccess) {
        state.productData = action.payload?.data;

        //Update product Name
        state.productUI.productName = getProductNameByProduct(
          state.productData,
        );
      }
    });

    /*     builder.addCase(updateProduct.fulfilled, (state, action) => {
      if (action.payload.isSuccess) {
        state.productData = action.payload?.data;

        //Update product Name
        state.productUI.productName = getProductNameByProduct(
          state.productData,
        );
      }
    }); */

    builder.addCase(loadGroups.pending, (state, action) => {
      if (!state.productGroups.length) {
        state.productUI.isLoadingProductGroups = true;
      }
    });
    builder.addCase(loadGroups.fulfilled, (state, action) => {
      state.productUI.isLoadingProductGroups = false;
      const newGroupsData = action.payload.isSuccess
        ? action.payload?.data ?? []
        : [];

      const isApproved = some(state.productBannerInfo, ["status", "Approved"]);
      if (isApproved) {
        state.productGroups = newGroupsData.map((group) => ({
          ...group,
          highlightType: HighlightType.Added,
        }));
      } else {
        state.productGroups = newGroupsData;
      }
    });
    builder.addCase(loadGroups.rejected, (state, action) => {
      state.productUI.isLoadingProductGroups = false;
    });

    builder.addCase(loadProductReleaseLog.pending, (state, action) => {
      state.productUI.isLoadingProductData = true;
    });
    builder.addCase(loadProductReleaseLog.rejected, (state, action) => {
      state.productUI.isLoadingProductData = false;
    });

    builder.addCase(loadProductHistory.fulfilled, (state, action) => {
      state.productUI.isLoadingProductHistory = false;
      state.productChangeHistory = action.payload.isSuccess
        ? action.payload?.data ?? []
        : [];
    });
    builder.addCase(loadProductHistory.pending, (state, action) => {
      state.productUI.isLoadingProductHistory = true;
    });
    builder.addCase(loadProductHistory.rejected, (state, action) => {
      state.productUI.isLoadingProductHistory = false;
    });

    builder.addCase(loadProductBannerInfo.fulfilled, (state, action) => {
      state.productBannerInfo = action.payload?.data;
      const isApproved = some(state.productBannerInfo, ["status", "Approved"]);
      if (isApproved) {
        state.productGroups = state.productGroups.map((group) => ({
          ...group,
          highlightType: HighlightType.Added,
        }));
      }
    });

    builder.addCase(loadProductBrandingData.fulfilled, (state, action) => {
      state.productUI.isLoadingProductBranding = false;
      state.productBrandingData = action.payload?.data;
    });
    builder.addCase(loadProductBrandingData.pending, (state, action) => {
      state.productUI.isLoadingProductBranding = true;
    });
    builder.addCase(loadProductBrandingData.rejected, (state, action) => {
      state.productUI.isLoadingProductBranding = false;
    });

    builder.addCase(loadSubProducts.fulfilled, (state, action) => {
      state.productUI.isLoadingSubProducts = false;
      state.subProducts = action.payload.isSuccess ? action.payload?.data : [];
      if (!isEmpty(state.subProducts)) {
        state.dependFieldsMapping = generateFieldMapping(
          state.subProducts?.[0],
        );
        state.subProductNameMapping = generateSubProductNameMapping(
          state.subProducts,
        );
        state.subProHighlightTypeMapping =
          generateSubProductHighlightTypeMapping(state.subProducts);
      }
    });
    builder.addCase(loadSubProducts.pending, (state, action) => {
      state.productUI.isLoadingSubProducts = true;
    });
    builder.addCase(loadSubProducts.rejected, (state, action) => {
      state.productUI.isLoadingSubProducts = false;
    });

    builder.addCase(loadNewSubProduct.fulfilled, (state, action) => {
      state.productUI.isLoadingNewSubProduct = false;
      state.newSubProduct = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      if (state.newSubProduct) {
        state.dependFieldsMapping = generateFieldMapping(state.newSubProduct);
        // if (!state.subProductNameMapping) return;
        let subProName: SubProductNameMapping = {
          ...state.subProductNameMapping,
        };
        subProName[Object.keys(subProName)?.length] = "New Product";
        state.subProductNameMapping = subProName;
      }
    });
    builder.addCase(loadNewSubProduct.pending, (state, action) => {
      state.productUI.isLoadingNewSubProduct = true;
    });
    builder.addCase(loadNewSubProduct.rejected, (state, action) => {
      state.productUI.isLoadingNewSubProduct = false;
    });

    builder.addCase(loadSubProductReleaseLog.fulfilled, (state, action) => {
      state.productUI.isLoadingSubProducts = false;
      state.subProducts = action.payload.isSuccess ? action.payload?.data : [];
      if (!isEmpty(state.subProducts)) {
        state.subProductNameMapping = generateSubProductNameMapping(
          state.subProducts,
        );
      }
    });
    builder.addCase(loadSubProductReleaseLog.pending, (state, action) => {
      state.productUI.isLoadingSubProducts = true;
    });
    builder.addCase(loadSubProductReleaseLog.rejected, (state, action) => {
      state.productUI.isLoadingSubProducts = false;
    });
  },
});

export const {
  updateProductData,
  updateSelectedGroup,
  updateGroups,
  updateProductName,
  updateBannerFeatures,
  updateProductBannerInfo,
  resetProduct,
  updateEditMode,
  updateCurrentEditor,
  updateLoadingEditMode,
  updateShowClaimDeniedDialog,
  updateShowBeingKickedDialog,
  updateBeingKickedBy,
  updateShowKickoutDialog,
  updateActiveGroup,
  updateProductChangeHistory,
  updateReleaseName,
  updateShowViewReleaseLog,
  updateSubProductName,
  updateSubProductHighlightTypeMapping,
} = productSlice.actions;

export const loadProduct = createAsyncThunk(
  APIExtRoutes.productGroup,
  async ({
    productId,
    groupId,
    mode,
  }: {
    productId?: string;
    groupId: string;
    mode?: "view" | "edit";
  }) => {
    return await getProduct(productId, groupId, mode);
  },
);
export const loadGroups = createAsyncThunk(
  APIExtRoutes.productGroups,
  async ({
    productId,
    version,
    configurationVersion,
  }: {
    productId?: string;
    version?: string;
    configurationVersion?: string;
  }) => {
    return await getProductGroups(productId, version, configurationVersion);
  },
);

export const loadProductBannerInfo = createAsyncThunk(
  APIExtRoutes.productBannerInfo,
  async (productId: string) => {
    return await getProductBannerInfo(productId);
  },
);

export const loadProductBrandingData = createAsyncThunk(
    APIExtRoutes.productBrandingData,
    async (productId: string) => {
      return await getProductBrandingData(productId);
    },
);

export const createProduct = createAsyncThunk(
  APIExtRoutes.products + "/new",
  async (product: ProductListDTO) => {
    return await postProduct(product);
  },
);

export const updateProduct = createAsyncThunk(
  APIExtRoutes.products + "/update",
  async (product: ProductListDTO) => {
    return await putProduct(product);
  },
);
export const loadProductHistory = createAsyncThunk(
  APIExtRoutes.productChangeHistory,
  async (productId: string) => {
    return await getProductChangeHistory(productId);
  },
);
export const loadProductReleaseLog = createAsyncThunk(
  APIExtRoutes.productReleaseLog,
  async ({
    productId,
    version,
    configurationVersion,
    groupId,
  }: {
    productId: string;
    version: string;
    configurationVersion: string;
    groupId: string;
  }) => {
    return await getProductReleaseLog(
      productId,
      version,
      configurationVersion,
      groupId,
    );
  },
);
export const loadSubProducts = createAsyncThunk(
  APIExtRoutes.productSubProducts,
  async ({
    productId,
    mode,
  }: {
    productId?: string;
    mode?: "view" | "edit";
  }) => {
    return await getSubProducts(productId, mode);
  },
);

export const loadNewSubProduct = createAsyncThunk(
  APIExtRoutes.productNewSubProduct,
  async (productId: string) => {
    return await getNewSubProduct(productId);
  },
);

export const updateSubProducts = createAsyncThunk(
  APIExtRoutes.subProducts,
  async ({
    productId,
    subProducts,
  }: {
    productId?: string;
    subProducts?: SubProductDTO[];
  }) => {
    return await postSubProducts(productId, subProducts);
  },
);

export const loadSubProductReleaseLog = createAsyncThunk(
  APIExtRoutes.productSubProductReleaseLog,
  async ({
    productId,
    version,
    configurationVersion,
  }: {
    productId: string;
    version: string;
    configurationVersion: string;
  }) => {
    return await getSubProductReleaseLog(
      productId,
      version,
      configurationVersion,
    );
  },
);

export const loadExistingFeeScheduleThunk = createAsyncThunk(
  APIExtRoutes.existingFeeSchedules,
  async (productId: string) => {
    return await getExistingFeeSchedule(productId);
  },
);

export const loadSubProductThunk = createAsyncThunk(
  APIExtRoutes.subProduct,
  async ({
    productId,
    subProductId,
  }: {
    productId: string;
    subProductId: string;
  }) => {
    return await getSubProduct(productId, subProductId);
  },
);

export default productSlice.reducer;
