import { SHButton, SHDialog, SHStack } from "@components/design-systems";
import { GroupByDecisionStatus } from "@pages/reviews/_id/steps/feature/analysis/config";

interface DisqualifyAllPlatformDialogProps {
  onSubmit?: () => void;
  onCancel?: () => void;
  isSubmitting?: boolean;
  productsGrouped?: GroupByDecisionStatus;
}

export const DisqualifyAllPlatformDialog = ({
  onSubmit,
  onCancel,
  isSubmitting = false,
  productsGrouped,
}: DisqualifyAllPlatformDialogProps) => {
  return (
    <SHDialog
      open
      maxWidth={false}
      width={656}
      disableEscapeKeyDown
      disableBackdropClick
      header={"Disqualify all undecided platforms?"}
      body={`You have shortlisted ${
        productsGrouped?.Shortlisted?.length
      } platforms and disqualified ${
        productsGrouped?.Disqualified?.length ?? 0
      } platforms. There are still ${
        productsGrouped?.Undecided?.length
      } platforms you have not made a decision on. Automatically disqualify these undecided platforms?`}
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onCancel}
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onSubmit}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Yes, disqualify
          </SHButton>
        </SHStack>
      }
    />
  );
};
