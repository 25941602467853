import { SVGProps } from "react";

export const InfoSVG = ({
  color = "#25324B",
  ...others
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={3}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 3 10"
    {...others}
  >
    <path
      d="m2.43 3.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469L.058 8.275c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L2.43 3.588ZM2.5 1.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      fill={color}
    />
  </svg>
);
