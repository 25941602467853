import { FilterValue } from "@components/buttons/filter";
import { PageRoutes } from "@constants/routes";
import { generatePath } from "react-router";

export function filterConfiguration(values?: any[], filters?: FilterValue[]) {
  return values?.filter(
    (value) =>
      !filters ||
      filters.length === 0 ||
      (value.isArchived && filters.some((fil) => fil.value === "Archived")) ||
      (!value.isArchived && filters.some((fil) => fil.value === "Active")),
  );
}

export function constructSectionPath(
  group: "standard" | "feature",
  groupId?: string,
  sectionType?: "group" | "subgroup",
  sectionId?: string,
  subSectionId?: string,
) {
  switch (group) {
    case "standard":
      return generatePath(PageRoutes.configurationSectionStandard, {
        groupId,
        sectionId,
      });
    case "feature":
      return sectionType === "group"
        ? generatePath(PageRoutes.configurationFeatureSectionGroup, {
            groupId,
            sectionId,
          })
        : generatePath(PageRoutes.configurationFeatureSectionSubGroup, {
            groupId,
            sectionId,
            subSectionId,
          });

    default:
      return "";
  }
}

export function constructFeaturePath(
  group: "standard" | "feature",
  groupId?: string,
  sectionId?: string,
  subSectionId?: string,
  featureId?: string,
) {
  switch (group) {
    case "standard":
      return generatePath(PageRoutes.configurationFeatureStandard, {
        groupId,
        sectionId,
        featureId,
      });
    case "feature":
      return generatePath(PageRoutes.configurationFeatureFeature, {
        groupId,
        sectionId,
        subSectionId,
        featureId,
      });
    default:
      return "";
  }
}
