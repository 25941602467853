import { SubProductDTO } from "@models/product/entities/subProduct";
// TODO: Need to merge to models/rate-card/rateCard.ts file

// Models from BE section
export class RateCardDTO extends SubProductDTO {
  notes?: string;
  ownerId?: string;
  ownerName?: string;
  isAvailableForAnalysis?: boolean = false;
  isShared?: boolean = false;
  isSharedWithLinkedPractices? : boolean = false;
}

export class ReviewRateCardDTO extends RateCardDTO{
  isShared?: boolean = true;
}

export class ReadRateCardDTO extends RateCardDTO {
  productName?: string;
  productAvatarUrl?: string;
  ownerAvatarUrl?: string;
  isSharedByLicensee?: boolean;
  sharedValue?: string;
}

export class RateCardDataDTO {
  id?: string;
  rateCardId?: string;
  configurationFeeFeatureId: string = "";
  idpsValue?: string;
  pensionValue?: string;
  otherValue?: string;
  publishedIDPSValue?: string;
  publishedPensionValue?: string;
  publishedOtherValue?: string;
}

export class CreateRateCardDTO extends RateCardDTO {}

export class UpdateRateCardDTO extends RateCardDTO {
  isModifiedRateCardData?: boolean = false;
}

export enum RateCardStatus {
  Active = "Active",
  Archived = "Archived",
}

export enum RateCardSharedStatus {
  No = "No",
  Internally = "Internally",
  AllPractices = "All practices"
}

export enum RateCardUniqueNameLevel {
  User = "User",
  Platform = "Platform",
  Firm = "Firm"
}

export class RateCardLOV {
  id: string = "";
  productName: string = "";
  name: string = "";
  note?: string | null;
  isSelected?: boolean = false;
  isLatest?: boolean = false;
  outdatedAt: null | Date = null;
  configurationVersion: number = 0;
  rateCardVersion: number = 0;
  ownerName: string = "";
}
