import { SHContainer, SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import {
  SHDividerSkeleton,
  SHFormControlLabelSkeleton,
  SHTextFieldSkeleton,
  SHUploadImageSkeleton,
} from "@components/skeletons/design-systems";
import {
  SHFieldGroupSkeleton,
  SHFiledGroupTitleSkeleton,
  SHTopBarSkeleton,
} from "@components/skeletons/layouts";

export default function ManageUserSkeleton() {
  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack sx={{ paddingY: 3 }} spacing={3}>
        <SHTopBarSkeleton totalButtons={2} />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton fullWidth />}
        />
        <SHDividerSkeleton />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={2} />}
          rightSide={<SHUploadImageSkeleton />}
        />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={1} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
              <SHStack spacing={2} direction="row">
                <SHTextFieldSkeleton width={"50%"} />
                <SHTextFieldSkeleton width={"50%"} />
              </SHStack>
              <SHTextFieldSkeleton />
              <SHStack
                direction="row"
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
              >
                <SHSkeleton width={"48%"} height={20} />
              </SHStack>
            </SHStack>
          }
        />
        <SHDividerSkeleton />
        <SHFieldGroupSkeleton
          leftSide={<SHFiledGroupTitleSkeleton subTitleRows={2} />}
          rightSide={
            <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
              <SHFormControlLabelSkeleton subTitleRows={3} />
              <SHFormControlLabelSkeleton subTitleRows={2} />
            </SHStack>
          }
        />
      </SHStack>
    </SHContainer>
  );
}
