import {GrayDeleteButton} from "@components/buttons/gray-delete";
import {PlusButton} from "@components/buttons/plus";
import {RollBackButton} from "@components/buttons/rollback";
import {SHAvatar, SHBox, SHButton, SHDataGrid, SHDataGridRef, SHStack, SHTypography,} from "@components/design-systems";
import {DefaultDataState} from "@components/design-systems/sh-data-grid/constant";
import {StatusBadge} from "@components/status-badge";
import {EditSVG} from "@components/svgs";
import {APIExtRoutes, PageRoutes} from "@constants";
import {TopBar, TopBarContainer} from "@layouts/top-bar";
import {PageMode, ParentState} from "@models/core";
import {ReadClientDTO} from "@models/reviews/entities/client";
import {ClientStatus} from "@models/reviews/enums/status";
import {useTheme} from "@mui/material";
import {TextOverflowEllipsis} from "@pages/platform-analysis/components/text-overflow-ellipsis";
import {createColumnHelper, TableState} from "@tanstack/react-table";
import {nameOfFactory} from "@utils";
import {useMemo, useRef, useState} from "react";
import {generatePath, useNavigate} from "react-router";
import {useReviewClient} from "@hooks/useReviewClient";

const nameOf = nameOfFactory<ReadClientDTO>();
const columnHelper = createColumnHelper<ReadClientDTO>();

export const ReviewClientList = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const ref = useRef<SHDataGridRef | null>(null);

  const pickColumns: (keyof ReadClientDTO)[] = [
    "id",
    "name",
    "status",
    "numberOfReviews",
    "adviserName",
    "adviserUserId",
    "adviserAvatarUrl",
  ];

  const [gridState, setGridState] = useState<Partial<TableState>>({
    ...DefaultDataState,
    sorting: [{ id: nameOf("name"), desc: false }],
    columnFilters: [
      {
        id: nameOf("status"),
        value: [ClientStatus.Active],
      },
    ],
  });

  const [updatingStatusIds, setUpdatingStatusIds] = useState<string[]>([]);
  const { updateReviewClientStatus } = useReviewClient();

  const FamilyGroupFilterOptions = [
    {
      label: ClientStatus.Active,
      value: ClientStatus.Active,
    },
    {
      label: ClientStatus.Archived,
      value: ClientStatus.Archived,
    },
  ];

  const handelOnChangeStatus = async (
    familyGroupId: string,
    status: ClientStatus
  ) => {
    setUpdatingStatusIds((ids) => [...ids, familyGroupId]);
    await updateReviewClientStatus(familyGroupId, status);
    setUpdatingStatusIds((ids) => ids.filter((id) => id !== familyGroupId));
    ref.current?.refreshOdata();
  };

  const columns = useMemo(
    () => {
      const cols = [
        columnHelper.accessor("name", {
          header: "Family group name",
          cell: (props) => (
            <TextOverflowEllipsis
              fontWeight="bold"
              value={props.cell.getValue()}
            />
          ),
          enableColumnFilter: false,
          meta: {
            sx: {
              minWidth: "200px",
              width: "calc((100% - 592px)*0.6)",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor("numberOfReviews", {
          header: "Number of reviews",
          cell: (props) => (
            <SHTypography variant="body4">{props.cell.getValue()}</SHTypography>
          ),
          enableColumnFilter: false,
          meta: {
            sx: {
              minWidth: "150px",
              width: "calc((100% - 592px)*0.3)",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          id: nameOf("adviserName"),
          header: "Adviser",
          enableColumnFilter: false,
          cell: (props) => {
            return (
              <SHStack direction={"row"} alignItems={"center"}>
                <SHAvatar
                  width={"24px"}
                  height={"24px"}
                  src={props.cell.getValue().adviserAvatarUrl}
                />
                <TextOverflowEllipsis
                  value={props.cell.getValue().adviserName}
                  sx={{
                    marginLeft: "5px",
                    minWidth: "100%",
                  }}
                />
              </SHStack>
            );
          },
          meta: {
            sx: {
              minWidth: "180px",
              width: "calc((100% - 592px)*0.3)",
              padding: "0 16px !important",
            },
          },
        }),

        columnHelper.accessor("status", {
          header: "Status",
          cell: (props) => (
            <StatusBadge
              style={{ width: "101px", fontSize: "13px" }}
              status={props.cell.getValue() as ClientStatus}
            />
          ),
          meta: {
            filterData: FamilyGroupFilterOptions,
            sx: {
              width: "180px",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          id: "actionCol",
          header: "Action",
          enableColumnFilter: false,
          enableSorting: false,
          cell: (props) => (
            <SHStack
              direction="row"
              justifyContent={"flex-start"}
              spacing={0.5}
            >
              <SHButton
                sx={{ width: "50%", fontSize: "13px" }}
                startIcon={<EditSVG color={palette.common.white} />}
                variant="contained"
                disabled={false}
                onClick={() => {
                  navigate(
                    generatePath(PageRoutes.reviewsFamilyGroupDetail, {
                      familyGroupId: props.cell.getValue().id,
                    }),
                    { state: { pageMode: PageMode.Edit } as ParentState },
                  );
                }}
              >
                Edit
              </SHButton>
              {props.cell.getValue().status === ClientStatus.Archived ? (
                <RollBackButton
                  disabled={updatingStatusIds.includes(props.cell.getValue().id)}
                  isLoading={updatingStatusIds.includes(props.cell.getValue().id)}
                  onClick={() =>
                    handelOnChangeStatus(
                      props.cell.getValue().id,
                      ClientStatus.Restore,
                    )
                  }
                />
              ) : (
                <GrayDeleteButton
                  disabled={updatingStatusIds.includes(props.cell.getValue().id)}
                  isLoading={updatingStatusIds.includes(props.cell.getValue().id)}
                  onClick={() =>
                    handelOnChangeStatus(
                      props.cell.getValue().id,
                      ClientStatus.Archived,
                    )
                  }
                />
              )}
            </SHStack>
          ),
          meta: {
            sx: {
              width: "192px",
            },
          },
        }),
      ];
      return cols;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <TopBarContainer>
        <TopBar
          title={"Suitability Reviews"}
          tools={
            <>
              <PlusButton
                variant="contained"
                size="extraMedium"
                onClick={() =>
                  navigate(
                    generatePath(PageRoutes.reviewsFamilyGroupDetail, {
                      familyGroupId: "new",
                    }),
                  )
                }
              >
                New family group
              </PlusButton>
            </>
          }
        />
      </TopBarContainer>
      <SHBox sx={{ marginTop: "25px" }}>
        <SHDataGrid
          ref={ref}
          odata={{
            url: APIExtRoutes.odataFamilyGroups,
            pickColumns: pickColumns,
            globalFilterColumns: [nameOf("name"), nameOf("adviserName")],
          }}
          state={gridState}
          onChangeState={(state) => setGridState(state)}
          emptyMessage={"No family groups."}
          columns={columns as any}
          searchBoxProps={{
            placeholder: "Family group name and adviser",
          }}
        />
      </SHBox>
    </>
  );
};
