import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import {
  SHDividerSkeleton,
  SHTextFieldSkeleton,
} from "@components/skeletons/design-systems";
import { SHRichTextSkeleton } from "@components/skeletons/design-systems/SHRichTextSkeleton";
import {
  SHFieldGroupSkeleton,
  SHFiledGroupTitleSkeleton,
} from "@components/skeletons/layouts";

export const ReviewClientSkeleton = () => {
  return (
    <SHStack spacing={3}>
      <SHFieldGroupSkeleton leftSide={<SHFiledGroupTitleSkeleton />} />
      <SHDividerSkeleton />
      <SHFieldGroupSkeleton
        leftSide={<SHFiledGroupTitleSkeleton subTitleRows={1} />}
        rightSide={
          <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
            <SHStack spacing={2} direction="row">
              <SHTextFieldSkeleton width={"50%"} />
              <SHTextFieldSkeleton width={"50%"} />
            </SHStack>
            <SHTextFieldSkeleton />
          </SHStack>
        }
      />
      <SHDividerSkeleton />
      <SHFieldGroupSkeleton
        leftSide={<SHFiledGroupTitleSkeleton subTitleRows={3} />}
        rightSide={
          <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
            <SHTextFieldSkeleton />
            <SHTextFieldSkeleton />
            <SHStack
              direction="row"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <SHSkeleton width={"48%"} height={35} />
            </SHStack>
          </SHStack>
        }
      />
      <SHDividerSkeleton />
      <SHFieldGroupSkeleton
        leftSide={<SHFiledGroupTitleSkeleton subTitleRows={3} />}
        rightSide={
          <SHStack width={{ xs: "100%", md: 520 }} spacing={3}>
            <SHRichTextSkeleton />
          </SHStack>
        }
      />
    </SHStack>
  );
};
