export enum ReviewStep {
  Setup = "Setup",
  Feature = "Feature",
  BusinessMetric = "BusinessMetric",
  Fee = "Fee",
  Summary = "Summary",
}

export const ReviewSteps = [
  ReviewStep.Feature,
  ReviewStep.BusinessMetric,
  ReviewStep.Fee,
  ReviewStep.Summary,
];
