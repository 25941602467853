import { InformationButton } from "@components/buttons/information";
import {
  SHStack,
  SHTableRow,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { CheckSVG, CrossSVG } from "@components/svgs";
import {
  AnalysisDataDTO,
  AnalysisFeatureDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import {
  FeatureAnalysisTableCell,
  FeatureAnalysisTableCellProps,
} from "@pages/platform-analysis/_id/steps/feature/analysis/components/table-cell";
import { hexToRGBA } from "@utils";
import { isEmpty } from "lodash";
interface FeatureAnalysisFeatureTableRowProps {
  featureData?: AnalysisFeatureDTO;
}

export const FeatureAnalysisFeatureTableRow = ({
  featureData,
  ...others
}: FeatureAnalysisFeatureTableRowProps &
  FeatureAnalysisTableCellProps &
  SHTableRowProps) => {
  // TODO: Need to change when BE start API
  const renderPlatformValue = (value?: AnalysisDataValue) => {
    if (value === AnalysisDataValue.True) return <CheckSVG />;
    if (value === AnalysisDataValue.False) return <CrossSVG />;
    if (value === AnalysisDataValue.MissingData || isEmpty(value))
      return (
        <SHTypography variant="body3" colorVariant="third" lineHeight="120%">
          No data
        </SHTypography>
      );
  };

  return (
    <SHTableRow
      sx={{ height: 54, background: `${hexToRGBA("#c5ddfd", 0.1)} !important` }}
      {...others}
    >
      <FeatureAnalysisTableCell isFirstColumn>
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent="space-between"
        >
          <SHTypography
            variant="body3"
            colorVariant="third"
            textAlign={"left"}
            lineHeight="120%"
          >
            {featureData?.name}
          </SHTypography>
          {featureData?.description && (
            <InformationButton content={featureData.description} />
          )}
        </SHStack>
      </FeatureAnalysisTableCell>
      {featureData?.analysisData?.map((analysisData: AnalysisDataDTO) => (
        <FeatureAnalysisTableCell key={analysisData?.productId}>
          <SHStack
            direction={"row"}
            alignItems={"center"}
            justifyContent="flex-end"
          >
            <SHStack
              width={analysisData?.value !== "Missing data" ? 98 : 115}
              direction={"row"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              {renderPlatformValue(analysisData?.value as AnalysisDataValue)}
              {analysisData?.description && (
                <InformationButton content={analysisData?.description} />
              )}
            </SHStack>
          </SHStack>
        </FeatureAnalysisTableCell>
      ))}
    </SHTableRow>
  );
};
