import {
  FilterButton,
  FilterButtonProps,
  FilterValue,
} from "@components/buttons/filter";
import {
  SHButton,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { PlusSVG } from "@components/svgs";
import { ConfigurationSection } from "@models/configuration";
import { useTheme } from "@mui/material";
import { constructSectionPath } from "@pages/configuration/util";
import { RootState } from "@redux/store";
import { hexToRGBA } from "@utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SectionCard } from "../section-card";

export interface SectionListProps extends SHStackProps {
  title?: string;
  isEdit?: boolean;
  sections?: ConfigurationSection[];
  selectedSectionId?: string;
  selectedSubSectionId?: string;
  filterProp?: FilterButtonProps;
  width?: number | string;
  onClickNew?: () => void;
  onChangeSection: (section?: ConfigurationSection) => void;
  onChangeFilter?: (value: FilterValue[] | undefined) => void;
}
export const SectionList = ({
  title = "Section",
  isEdit,
  sections = [],
  filterProp,
  width = "100%",
  selectedSectionId,
  selectedSubSectionId,
  onClickNew,
  onChangeSection,
  onChangeFilter,
  ...others
}: SectionListProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { configurationGroupData } = useSelector(
    (state: RootState) => state.configuration,
  );

  return (
    <SHStack width={width} {...others}>
      <SHStack
        sx={{
          bgcolor: hexToRGBA(palette.common.white, 0.85),
          borderBottom: `1px solid ${palette.secondary[100]}`,
          paddingX: "10px",
          paddingY: "24px",
          backdropFilter: "blur(0.5px)",
        }}
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <SHTypography variant="subtitle1">{title}</SHTypography>
        {isEdit && (
          <SHStack
            direction="row"
            justifyContent="center"
            alignItems={"center"}
            spacing={2}
          >
            {filterProp && (
              <FilterButton {...filterProp} onChangeFilter={onChangeFilter} />
            )}
            <SHButton
              variant="text"
              color="primary"
              size="extraMedium"
              data-testid="new-button"
              startIcon={
                <PlusSVG color={palette.primary.main} width={10} height={10} />
              }
              onClick={onClickNew}
            >
              New
            </SHButton>
          </SHStack>
        )}
      </SHStack>
      {sections.map((section, index) => (
        <SectionCard
          key={section.id}
          section={section}
          isEdit={isEdit}
          selected={
            section.id ===
            (selectedSubSectionId ? selectedSubSectionId : selectedSectionId)
          }
          onClick={() => onChangeSection(section)}
          onClickEdit={() => {
            const isStandard =
              configurationGroupData?.sectionType === "Standard";
            const hasSubSection = !!section.subSections;
            navigate(
              constructSectionPath(
                isStandard ? "standard" : "feature",
                configurationGroupData?.id,
                hasSubSection ? "group" : "subgroup",
                hasSubSection || isStandard ? section.id : selectedSectionId,
                hasSubSection ? undefined : section.id,
              ),
            );
          }}
        />
      ))}
    </SHStack>
  );
};
