import { APIExtRoutes } from "@constants";
import {
  FeeAnalysisTableRow,
  ManagedAccountBannerInfoDTO,
  ManagedAccountDTO,
  ManagedAccountStepDTO,
  ManagedAccountUpdateDTO,
} from "@models/managed-accounts/entities/analysis";
import { CollapseType } from "@models/managed-accounts/entities/step/fee";
import {
  ManagedAccountPortfolioSetupCreateDTO,
  SelectedPortfolioTemplateDTO,
} from "@models/managed-accounts/entities/step/setup";
import { ManagedAccountStep } from "@models/managed-accounts/enums/step";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getManagedAccountBannerInfo,
  getManagedAccountPortfolioTemplates,
  getManagedAccountStep,
  getManagedAccountSteps,
  postManagedAccount,
  putManagedAccount,
  putManagedAccountCurrentStep,
} from "@services/managed-accounts/managedAccountService";

const { PortfolioSetup, FeeAnalysis, Summary } = ManagedAccountStep;
const stepEnums = [PortfolioSetup, FeeAnalysis, Summary];

export interface ManagedAccountStore {
  ui: {
    isValid?: boolean;
    isDirty: boolean;
    isSubmitting?: boolean;
    isAccessDenied?: boolean;
    isLoading?: boolean;
    isLoadingSteps?: boolean;
    isLoadingBanner?: boolean;
    isReadOnly?: boolean;
    selectedStepIndex?: number;
    collapsedSubGroupIds?: string[];
    steps: {
      [key in ManagedAccountStep]: {
        isHidden?: boolean;
        isUnHighlighted?: boolean;
      };
    };
  };
  isCompleted?: boolean;
  bannerInfo?: ManagedAccountBannerInfoDTO;
  managedAccount?: ManagedAccountDTO;
  managedAccountSteps?: ManagedAccountStepDTO;
  feeAnalysisRows: FeeAnalysisTableRow[];
  collapseMapping: CollapseType;
  portfolioTemplates?: SelectedPortfolioTemplateDTO[];
  portfolioTemplateUI: {
    isLoading: boolean;
    isSubmitting: boolean;
  };
}
const initialState: ManagedAccountStore = {
  ui: {
    isValid: true,
    isDirty: false,
    isSubmitting: false,
    isReadOnly: false,
    isAccessDenied: undefined,
    selectedStepIndex: 0,
    steps: {
      [PortfolioSetup]: {
        isHidden: false,
        isUnHighlighted: false,
      },
      [FeeAnalysis]: {
        isHidden: false,
        isUnHighlighted: true,
      },
      [Summary]: {
        isHidden: false,
        isUnHighlighted: true,
      },
    },
    collapsedSubGroupIds: [],
  },
  isCompleted: false,
  feeAnalysisRows: [],
  collapseMapping: {},
  portfolioTemplateUI: {
    isLoading: false,
    isSubmitting: false,
  },
};

const managedAccountSlice = createSlice({
  name: "managedAccount",
  initialState,
  reducers: {
    updateSelectedStepIndex: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.ui.selectedStepIndex = action.payload;
    },
    updateIsValid: (state, action: PayloadAction<boolean | undefined>) => {
      state.ui.isValid = action.payload;
    },
    updateIsReadOnly: (state, action: PayloadAction<boolean | undefined>) => {
      state.ui.isReadOnly = action.payload;
    },
    updateIsDirty: (state, action: PayloadAction<boolean>) => {
      state.ui.isDirty = action.payload;
    },
    updateStepUnHighlighted: (
      state,
      action: PayloadAction<{
        step: ManagedAccountStep;
        isUnHighlighted: boolean | undefined;
      }>,
    ) => {
      state.ui.steps[action.payload.step].isUnHighlighted =
        action.payload.isUnHighlighted;
    },
    updateCollapsedSubGroupIds: (state, action: PayloadAction<string[]>) => {
      state.ui.collapsedSubGroupIds = action.payload;
    },

    updateIsAccessDenied: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.ui.isAccessDenied = action.payload;
    },
    updateIsCompleted: (state, action: PayloadAction<boolean | undefined>) => {
      state.isCompleted = action.payload;
    },

    updateManagedAccountSteps: (
      state,
      action: PayloadAction<ManagedAccountStepDTO | undefined>,
    ) => {
      state.managedAccountSteps = action.payload;
    },

    updateBannerInfo: (
      state,
      action: PayloadAction<ManagedAccountBannerInfoDTO>,
    ) => {
      state.bannerInfo = { ...state.bannerInfo, ...action.payload };
    },

    updateManagedAccountStore: (
      state,
      action: PayloadAction<ManagedAccountDTO | undefined>,
    ) => {
      state.managedAccount = action.payload;
    },

    updateFeeAnalysisRowsAction: (
      state,
      action: PayloadAction<FeeAnalysisTableRow[]>,
    ) => {
      state.feeAnalysisRows = action.payload;
    },

    updateCollapseMappingAction: (
      state,
      action: PayloadAction<CollapseType>,
    ) => {
      state.collapseMapping = action.payload;
    },

    resetManagedAccountStore: (state, action: PayloadAction) => {
      state.ui = initialState.ui;
      state.managedAccountSteps = undefined;
      state.bannerInfo = undefined;
      state.managedAccount = undefined;
      state.isCompleted = false;
      state.feeAnalysisRows = [];
    },
  },
  extraReducers(builder) {
    // get Banner Info
    builder.addCase(
      getManagedAccountBannerInfoThunk.pending,
      (state, action) => {
        state.ui.isLoadingBanner = true;
      },
    );
    builder.addCase(
      getManagedAccountBannerInfoThunk.fulfilled,
      (state, action) => {
        state.bannerInfo = action.payload.isSuccess
          ? action.payload?.data
          : undefined;
        state.ui.isLoadingBanner = false;
      },
    );
    builder.addCase(
      getManagedAccountBannerInfoThunk.rejected,
      (state, action) => {
        state.ui.isLoadingBanner = false;
      },
    );

    // get Steps
    builder.addCase(getManagedAccountStepsThunk.pending, (state, action) => {
      state.ui.isLoadingSteps = true;
    });
    builder.addCase(getManagedAccountStepsThunk.fulfilled, (state, action) => {
      if (action.payload.isSuccess && action.payload.data) {
        const { steps, currentStep, savedSteps } = action.payload.data;

        let selectedStepIndex = -1;

        stepEnums.forEach((step, index) => {
          if (steps.includes(step)) {
            state.ui.steps[step].isHidden = false;
            selectedStepIndex++;
          } else {
            state.ui.steps[step].isHidden = true;
          }
          if (currentStep === step) {
            state.ui.selectedStepIndex = selectedStepIndex;
          }
          state.ui.steps[step].isUnHighlighted = !savedSteps?.includes(step);
        });
      }
      state.ui.isLoadingSteps = false;
      state.managedAccountSteps = action.payload.data;
    });
    builder.addCase(getManagedAccountStepsThunk.rejected, (state, action) => {
      state.ui.isLoadingSteps = false;
    });

    // Get Step
    builder.addCase(getManagedAccountStepThunk.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(getManagedAccountStepThunk.fulfilled, (state, action) => {
      state.managedAccount = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.ui.isLoading = false;
    });
    builder.addCase(getManagedAccountStepThunk.rejected, (state, action) => {
      state.ui.isLoading = false;
    });

    //createManagedAccount
    builder.addCase(createManagedAccountThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(createManagedAccountThunk.fulfilled, (state, action) => {
      state.managedAccount = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
      state.ui.isSubmitting = false;
    });
    builder.addCase(createManagedAccountThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    //updateManagedAccount
    builder.addCase(updateManagedAccountThunk.pending, (state, action) => {
      state.ui.isSubmitting = true;
    });
    builder.addCase(updateManagedAccountThunk.fulfilled, (state, action) => {
      state.ui.isSubmitting = false;
    });
    builder.addCase(updateManagedAccountThunk.rejected, (state, action) => {
      state.ui.isSubmitting = false;
    });

    //loadPortfolioTemplate
    builder.addCase(loadPortfolioTemplatesThunk.pending, (state, action) => {});
    builder.addCase(loadPortfolioTemplatesThunk.fulfilled, (state, action) => {
      state.portfolioTemplates = action.payload.isSuccess
        ? action.payload?.data
        : [];
    });
    builder.addCase(
      loadPortfolioTemplatesThunk.rejected,
      (state, action) => {},
    );
  },
});

// Thunk functions
export const getManagedAccountBannerInfoThunk = createAsyncThunk(
  APIExtRoutes.managedAccountsBannerInfo,
  async (managedAccountId: string) => {
    return await getManagedAccountBannerInfo(managedAccountId);
  },
);

export const getManagedAccountStepsThunk = createAsyncThunk(
  APIExtRoutes.managedAccountSteps,
  async (managedAccountId?: string) => {
    return await getManagedAccountSteps(managedAccountId);
  },
);

export const getManagedAccountStepThunk = createAsyncThunk(
  APIExtRoutes.managedAccountStep,
  async ({
    managedAccountId,
    stepId,
  }: {
    managedAccountId: string;
    stepId: ManagedAccountStep;
  }) => {
    const updateCurrentStep = await putManagedAccountCurrentStep(
      managedAccountId,
      stepId,
    );

    if (!updateCurrentStep.isSuccess)
      return { ...updateCurrentStep, data: undefined };

    return await getManagedAccountStep(managedAccountId, stepId);
  },
);

export const createManagedAccountThunk = createAsyncThunk(
  `${APIExtRoutes.managedAccounts}/create`,
  async (setupStep: ManagedAccountPortfolioSetupCreateDTO) => {
    return await postManagedAccount(setupStep);
  },
);

export const updateManagedAccountThunk = createAsyncThunk(
  `${APIExtRoutes.managedAccounts}/update`,
  async (managedAccount: ManagedAccountUpdateDTO) => {
    return await putManagedAccount(managedAccount);
  },
);

export const loadPortfolioTemplatesThunk = createAsyncThunk(
  `${APIExtRoutes.managedAccountPortfolioTemplates}/get`,
  async () => {
    return await getManagedAccountPortfolioTemplates();
  },
);

export const {
  updateIsValid,
  updateIsDirty,
  updateIsReadOnly,
  updateIsAccessDenied,
  updateStepUnHighlighted,
  updateSelectedStepIndex,
  updateCollapsedSubGroupIds,
  updateIsCompleted,
  updateBannerInfo,
  updateManagedAccountSteps,
  updateManagedAccountStore,
  updateFeeAnalysisRowsAction,
  updateCollapseMappingAction,
  resetManagedAccountStore,
} = managedAccountSlice.actions;

export default managedAccountSlice.reducer;
