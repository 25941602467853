import { ProductLOV } from "@models/product/entities/product";

export const mockProductLov: ProductLOV[] = [
  {
    id: "ca0d27c4-d3aa-420f-9d55-6b2edba40e7b",
    name: "Enhancement product",
  },
  {
    id: "0763fc2f-3a09-4a56-b563-b2f496016f7a",
    name: "Platform analysis",
    description: null,
  },
  {
    id: "0763f564f-3a09-4a56-b563-b2f496016f7a",
    name: "Khan analysis",
    description: null,
  },
  {
    id: "0763fc2f-3a09-4a56-b563-454536456456",
    name: "Platform Phong",
    description: null,
  },
  {
    id: "0763fc2f-3a09-4a56-b563-b2f496451566f7a",
    name: "Tan PTC",
    description: null,
  },
  {
    id: "0763fc2f-3a04359-4a56-b563-454536456456",
    name: "Platform QePhong",
    description: null,
  },
  {
    id: "0763fc2f-3a09978-4a56-b563-b2f496451566f7a",
    name: "Tan PTASC",
    description: null,
  },
];
