import { AnalysisToolBarContainer } from "@components/analysis-nav-bar/portal";
import { InformationButton } from "@components/buttons/information";
import { SHButton, SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import UnsavedDialog from "@components/dialogs/unsaved";
import { PageRoutes } from "@constants";
import { useFeatureTemplate } from "@hooks/useFeatureTemplate";
import { useIsNew } from "@hooks/useIsNew";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import { useUserPermissions } from "@hooks/userUserPermission";
import { useSupplier } from "@hooks/useSupplier";
import { AnalysisName } from "@layouts/platform-analysis/analysis-name";
import {
  Step,
  StepperButtonState,
  StepperLayout,
  StepperLayoutRef,
} from "@layouts/stepper";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { AnalysisSubStep } from "@models/platform-analysis/enums/subStep";
import { CompleteButton } from "@pages/platform-analysis/components/buttons/complete";
import { BusinessMetricAnalysis } from "@pages/platform-analysis/_id/steps/business-metric/analysis";
import { BusinessMetricSelection } from "@pages/platform-analysis/_id/steps/business-metric/selection";
import { FeatureAnalysis } from "@pages/platform-analysis/_id/steps/feature/analysis";
import { FeatureSelection } from "@pages/platform-analysis/_id/steps/feature/selection";
import { FeeAnalysis } from "@pages/platform-analysis/_id/steps/fee/analysis";
import { AnalysisSetup } from "@pages/platform-analysis/_id/steps/setup";
import { AnalysisSummary } from "@pages/platform-analysis/_id/steps/summary";
import { RootState } from "@redux/store";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { SaveAsTemplateDialog } from "../components/dialogs/save-as-template";
import { FeeCalculatorStep } from "./steps/fee/calculator";
import { ExportPDFButton } from "@pages/platform-analysis/components/buttons/export-pdf";
import { UnlockAnalysisButton } from "../components/buttons/unlock";
const { Setup, Feature, BusinessMetric, Fee, Summary } = AnalysisStep;
const { Selection, Analysis } = AnalysisSubStep;

export const PlatformAnalysisDetail = () => {
  const [currentStep, setCurrentStep] = useState<string | undefined>();
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const isNew = useIsNew();
  const { analysisId } = useParams<{ analysisId: string }>();
  const stepperRef = useRef<StepperLayoutRef | null>(null);
  const lastedAction = useRef<"save" | "next" | "back" | null>(null);
  const prevAnalysisIdRef = useRef(analysisId);
  const {
    ui: {
      isDirty,
      isValid,
      isSubmitting,
      isAccessDenied,
      isLoadingSteps,
      isLoadingBanner,
      steps,
      selectedStepIndex,
      selectedSubStepIndex,
    },
    analysisSteps,
  } = useSelector((state: RootState) => state.platformAnalysis);
  const {
    featureTemplateUI: { isLoading },
  } = useSelector((state: RootState) => state.featureTemplate);
  const { supplierData } = useSelector((state: RootState) => state.supplier);
  const { user } = useUserPermissions();
  const { loadSupplier, refreshSupplier } = useSupplier();
  const {
    isCompleted,
    isReadOnly,
    setAnalysisSteps,
    setIsAccessDenied,
    setSelectedStepIndex,
    setSelectedSubStepIndex,
    loadAnalysisBannerName,
    loadPlatformAnalysisStep,
    resetStore,
  } = usePlatformAnalysis();
  const { loadExistingFeatureTemplate } = useFeatureTemplate();
  const [buttonState, setButtonState] = useState<StepperButtonState>({
    isCanNext: true,
    isCanPrevious: false,
  });

  const initialSteps: Step[] = useMemo(
    () => [
      {
        key: Setup,
        label: "Analysis setup",
        component: AnalysisSetup,
        hidden: steps[Setup].isHidden,
        unHighlighted: steps[Setup].isUnHighlighted,
      },
      {
        key: Feature,
        label: "Feature analysis",
        hidden: steps[Feature].isHidden,
        unHighlighted: steps[Feature].isUnHighlighted,
        subSteps: [
          {
            key: Selection,
            label: "Feature selection",
            component: FeatureSelection,
            hidden: steps[Feature].subSteps?.Selection.isHidden,
          },
          {
            key: Analysis,
            label: "Feature analysis",
            component: FeatureAnalysis,
          },
        ],
      },
      {
        key: BusinessMetric,
        label: "Business metric analysis",
        hidden: steps[BusinessMetric].isHidden,
        unHighlighted: steps[BusinessMetric].isUnHighlighted,
        subSteps: [
          {
            key: Selection,
            label: "Business metric selection",
            component: BusinessMetricSelection,
            hidden: steps[BusinessMetric].subSteps?.Selection.isHidden,
          },
          {
            key: Analysis,
            label: "Business metric analysis",
            component: BusinessMetricAnalysis,
          },
        ],
      },
      {
        key: Fee,
        label: "Fee analysis",
        hidden: steps[Fee].isHidden,
        unHighlighted: steps[Fee].isUnHighlighted,
        subSteps: [
          {
            key: Selection,
            label: "Fee calculator",
            component: FeeCalculatorStep,
            hidden: steps[Fee].subSteps?.Selection.isHidden,
          },
          {
            key: Analysis,
            label: "Fee analysis",
            component: FeeAnalysis,
          },
        ],
      },
      {
        key: Summary,
        label: "Summary",
        component: AnalysisSummary,
        hidden: steps[Summary].isHidden,
        unHighlighted: steps[Summary].isUnHighlighted,
      },
    ],
    [steps],
  );
  const loadAnalysisInit = async (analysisId: string) => {
    const data = await loadAnalysisBannerName(analysisId);
    if (data) await loadPlatformAnalysisStep(analysisId);
  };

  useEffect(() => {
    if (user?.userMetadata?.supplier_id)
      loadSupplier(user.userMetadata.supplier_id);
    return () => {
      refreshSupplier();
    };
    // eslint-disable-next-line
  }, [user?.userMetadata?.supplier_id]);

  useEffect(() => {
    if (
      analysisId &&
      analysisId !== "new" &&
      prevAnalysisIdRef.current !== "new"
    ) {
      loadAnalysisInit(analysisId);
    } else {
      setAnalysisSteps({
        steps: [],
      });
      setIsAccessDenied(false);
    }
    prevAnalysisIdRef.current = analysisId;
    // eslint-disable-next-line
  }, [analysisId]);

  useEffect(() => {
    return () => {
      resetStore();
    };
    // eslint-disable-next-line
  }, []);
  const isLoadingStepLayout =
    !supplierData ||
    isLoadingBanner ||
    isLoadingSteps ||
    isAccessDenied === undefined;

  if (isAccessDenied) return <AccessDeniedDialog />;
  return (
    <>
      <AnalysisToolBarContainer>
        <SHStack
          spacing={"25px"}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          height="100%"
        >
          <AnalysisName />
          {isLoadingSteps ? (
            <SHSkeleton height={32} width={100} />
          ) : (
            <SHStack
              direction="row"
              justifyContent="flex-start"
              alignItems={"center"}
              spacing={"15px"}
            >
              {currentStep === initialSteps[1].key &&
                selectedSubStepIndex === 0 &&
                !isCompleted && (
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    disabled={isSubmitting}
                    isLoading={isLoading}
                    onClick={() => {
                      setOpenDialog(true);
                      loadExistingFeatureTemplate();
                    }}
                  >
                    Save as template
                    <InformationButton
                      content="Save the features that are selected below as a template. You and your team can load feature selection templates when you set up your analysis."
                      placement="bottom-start"
                    />
                  </SHButton>
                )}
              <SHButton
                variant="outlined"
                size="extraMedium"
                disabled={isSubmitting}
                onClick={() => {
                  stepperRef?.current &&
                    stepperRef.current.onExit &&
                    stepperRef.current.onExit();
                  navigate(PageRoutes.platformAnalysis);
                }}
              >
                Exit
              </SHButton>
              {buttonState.isCanPrevious && (
                <SHButton
                  variant="outlined"
                  size="extraMedium"
                  disabled={isSubmitting}
                  isLoading={lastedAction.current === "back" && isSubmitting}
                  onClick={() => {
                    lastedAction.current = "back";
                    stepperRef?.current &&
                      stepperRef.current.movePreviousStep();
                  }}
                >
                  Back
                </SHButton>
              )}
              {!isCompleted && (
                <SHButton
                  variant="outlined"
                  size="extraMedium"
                  disabled={!isValid || isSubmitting || (!isNew && !isDirty)}
                  isLoading={lastedAction.current === "save" && isSubmitting}
                  onClick={() => {
                    lastedAction.current = "save";
                    stepperRef?.current && stepperRef.current.onSubmit();
                  }}
                >
                  Save
                </SHButton>
              )}
              {buttonState.isCanNext ? (
                <SHButton
                  variant="contained"
                  size="extraMedium"
                  disabled={!isValid || isSubmitting}
                  isLoading={lastedAction.current === "next" && isSubmitting}
                  onClick={() => {
                    lastedAction.current = "next";
                    stepperRef?.current && stepperRef.current.moveNextStep();
                  }}
                >
                  Next
                </SHButton>
              ) : isCompleted ? (
                <>
                  <UnlockAnalysisButton analysisId={analysisId} />
                  <ExportPDFButton isCompleted />
                </>
              ) : (
                <>
                  <CompleteButton
                    disabled={isReadOnly}
                    analysisId={analysisId}
                    onSubmit={stepperRef.current?.onSubmit}
                  />
                  <ExportPDFButton isCompleted={isCompleted} />
                </>
              )}
            </SHStack>
          )}
        </SHStack>
      </AnalysisToolBarContainer>
      <StepperLayout
        isUsePortal
        ref={stepperRef}
        steps={initialSteps}
        selectedStepIndex={analysisSteps === undefined ? -1 : selectedStepIndex}
        selectedSubStepIndex={selectedSubStepIndex}
        isLoading={isLoadingStepLayout}
        isCompleted={isCompleted}
        isDirty={false}
        onChangeSelectedStepIndex={setSelectedStepIndex}
        onChangeSelectedSubStepIndex={setSelectedSubStepIndex}
        onChangeButtonState={(buttonState) => {
          setButtonState(buttonState);
        }}
        onChangeCurrentStep={(key) => {
          setCurrentStep(key);
        }}
      />
      <UnsavedDialog isDirty={isDirty} />
      {openDialog && !isLoading && (
        <SaveAsTemplateDialog onClose={() => setOpenDialog(false)} />
      )}
    </>
  );
};
