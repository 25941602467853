import { ManagedAccountDTO } from "@models/managed-accounts/entities/analysis";
import { ManagedAccountFeeAnalysisDTO } from "@models/managed-accounts/entities/step/fee";

export const compileManagedAccount = (
  analysis: ManagedAccountDTO,
): ManagedAccountDTO => {
  return analysis;
};

export const compileManagedAccountFeeAnalysis = (
  feeAnalysis: ManagedAccountFeeAnalysisDTO,
): ManagedAccountFeeAnalysisDTO => {
  return feeAnalysis;
};
