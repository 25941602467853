import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import { usePermission } from "@hooks/usePermission";
import { PermissionChecking } from "@models/auth";
import { Outlet } from "react-router";

interface PrivateRouteProps {
  children?: JSX.Element;
  permission?: PermissionChecking;
}

const PrivateRoute = ({
  children,
  permission = {
    roles: [],
    permissions: [],
  },
}: PrivateRouteProps): JSX.Element => {
  const { isLoading, isAuthenticated, error } = useAuth0();
  const hasPermission = usePermission(permission);

  if (isLoading) {
    return <></>;
  }

  if (error) {
    return <div>Oops...</div>;
  }

  if (isAuthenticated && hasPermission) {
    return <>{children ? children : <Outlet />}</>;
  }

  return <AccessDeniedDialog />;
};

export default withAuthenticationRequired(PrivateRoute, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <></>,
});
