import { SVGProps } from "react";

interface WarningSVGProps {
  backgroundColor?: string;
  iconColor?: string;
}
export const WarningSVG = ({
  backgroundColor = "white",
  iconColor = "#EB5757",
  ...props
}: SVGProps<SVGSVGElement> & WarningSVGProps) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.46708 1C6.23688 -0.333332 8.16139 -0.333334 8.93119 0.999999L14.1273 10C14.8971 11.3333 13.9349 13 12.3953 13H2.00299C0.463385 13 -0.498868 11.3333 0.270933 10L5.46708 1Z"
      fill={backgroundColor}
    />
    <path
      d="M6.19922 4.49961C6.19922 4.00255 6.60216 3.59961 7.09922 3.59961C7.59628 3.59961 7.99922 4.00255 7.99922 4.49961V7.49961C7.99922 7.99667 7.59627 8.39961 7.09922 8.39961C6.60216 8.39961 6.19922 7.99667 6.19922 7.49961V4.49961Z"
      fill={iconColor}
    />
    <path
      d="M6.19922 9.9C6.19922 9.40294 6.60216 9 7.09922 9C7.59627 9 7.99922 9.40294 7.99922 9.9C7.99922 10.3971 7.59627 10.8 7.09922 10.8C6.60216 10.8 6.19922 10.3971 6.19922 9.9Z"
      fill={iconColor}
    />
  </svg>
);
