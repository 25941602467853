import {
  SHBox,
  SHStack,
  SHToggleButton,
  SHToggleButtonGroup,
  SHToggleButtonGroupProps,
  SHToggleButtonProps,
  SHTypography,
} from "@components/design-systems";
import { ToggleButtonOptions } from "@components/design-systems/sh-toggle-button-options/model";
import { TooltipAllowSVG } from "@components/svgs";
import {
  Tooltip,
  TooltipProps,
  Zoom,
  styled,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import { hexToRGBA } from "@utils";
import { isEmpty } from "lodash";
import { useState } from "react";

export type SHToggleButtonOptionsProps = (
  | SHToggleButtonGroupProps
  | SHToggleButtonProps
) & {
  width?: number | string;
  options?: ToggleButtonOptions[];
};

export const SHToggleButtonOptions = (
  props: SHToggleButtonOptionsProps,
): JSX.Element => {
  const { options, width, onChange, value, disabled } = props;
  const { palette, shadows } = useTheme();

  const [open, setOpen] = useState(false);

  const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      border: "none",
      p: 0,
      m: 0,
    },
  }));

  return (
    <SHToggleButtonGroup
      disabled={disabled}
      value={value}
      exclusive
      onChange={onChange}
      data-testid="sh-toggle-button-options"
      sx={{
        borderColor: disabled ? palette.text.disabled : undefined,
      }}
    >
      {options?.map((item: ToggleButtonOptions, index) => {
        const toggleButton = (
          <SHToggleButton
            value={item.value}
            sx={{ width: width }}
            key={index}
            disabled={item.disabled}
          >
            <SHTypography variant="subtitle2" pt={0} lineHeight={1}>
              {item.label}
            </SHTypography>
          </SHToggleButton>
        );

        return !isEmpty(item?.tooltip) ? (
          <TooltipStyled
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            TransitionComponent={Zoom}
            placement="top-start"
            title={
              <SHStack
                sx={{
                  bgcolor: hexToRGBA(palette.common.white, 0.9),
                  border: "1px solid #E3E3E3",
                  backdropFilter: "blur(5px)",
                  borderRadius: "3px",
                  boxShadow: shadows[1],
                  position: "relative",
                  p: 1,
                  marginLeft: "20px",
                  marginBottom: "-8px",
                  width: "180px",
                }}
              >
                <SHTypography
                  variant="body4"
                  lineHeight={"120%"}
                  colorVariant="third"
                >
                  {item.tooltip}
                </SHTypography>
                <SHBox
                  sx={{ position: "absolute", bottom: "-11.7px", left: 0 }}
                >
                  <TooltipAllowSVG />
                </SHBox>
              </SHStack>
            }
          >
            <SHStack sx={{ cursor: "pointer" }}>{toggleButton}</SHStack>
          </TooltipStyled>
        ) : (
          toggleButton
        );
      })}
    </SHToggleButtonGroup>
  );
};
