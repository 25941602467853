import { Components, Theme } from "@mui/material";
import { hexToRGBA } from "@utils/color";

export const muiChipTheme = ({
  palette,
}: Theme): Pick<Components<Omit<Theme, "components">>, "MuiChip"> => ({
  MuiChip: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        "&>.MuiChip-label": {
          paddingTop: "1px",
        },
        "&.MuiChip-colorPrimary": {
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.primary.main,
            0.1,
          )}, ${hexToRGBA(palette.primary.main, 0.1)}),${palette.common.white}`,
          borderColor: hexToRGBA(palette.primary.main, 0.13),
        },
        "&.MuiChip-outlinedSuccess": {
          color: palette.success.main,
          background: palette.success[50],
          borderColor: palette.success[100],
        },
        "&.MuiChip-outlinedWarning": {
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.warning.main,
            0.1,
          )}, ${hexToRGBA(palette.warning.main, 0.1)}),${palette.common.white}`,
          borderColor: hexToRGBA(palette.warning.main, 0.13),
        },
        "&.MuiChip-outlinedError": {
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.error.light,
            0.1,
          )}, ${hexToRGBA(palette.error.light, 0.1)}),${palette.common.white}`,
          borderColor: hexToRGBA(palette.error.light, 0.13),
        },
        "&.MuiChip-outlinedInfo": {
          color: palette.secondary.main,
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.secondary.main,
            0.1,
          )}, ${hexToRGBA(palette.secondary.main, 0.1)}),${
            palette.common.white
          }`,
          borderColor: hexToRGBA(palette.secondary.main, 0.13),
        },
        "&.MuiChip-outlinedDefault": {
          color: palette.text.primary,
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.text.primary,
            0.1,
          )}, ${hexToRGBA(palette.text.primary, 0.1)}),${palette.common.white}`,
          borderColor: hexToRGBA(palette.text.primary, 0.13),
        },
      },
    },
  },
});
