import { useUserPermissions } from "@hooks/userUserPermission";
import { CompleteProfilePractice } from "./practice-user";
import { CompleteProfileSupplier } from "./supplier-user";

export const CompleteProfilePage = () => {
  const { isSupplierGroup, isAdviserGroup } = useUserPermissions();
  if (isSupplierGroup) {
    return <CompleteProfileSupplier />;
  }
  if (isAdviserGroup) {
    return <CompleteProfilePractice />;
  }
  return null;
};
