import { PostFilters } from "@models/news-and-updates/entities/post";
import {
  initialStatusFilterForAdmin,
  initialStatusFilterForSupplier,
} from "./components/collapse-menu/config";

export const updateFilterByRole = (
  isAdminGroup: boolean,
  filter: PostFilters,
) => {
  let newFilter;
  let statuses: string[] = [];
  if (!isAdminGroup) {
    initialStatusFilterForSupplier.subsections.forEach((subSection) => {
      if (subSection.value) {
        statuses.push(subSection.key);
      }
    });
    newFilter = { ...filter, statuses };
    return newFilter;
  }
  initialStatusFilterForAdmin.subsections.forEach((subSection) => {
    if (subSection.value) {
      statuses.push(subSection.key);
    }
  });
  newFilter = { ...filter, statuses };
  return newFilter;
};
