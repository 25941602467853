import { SHStack, SHStackProps } from "@components/design-systems";

interface SHFieldGroupSkeletonProps {
  leftSide?: React.ReactNode;
  rightSide?: React.ReactNode;
}

export const SHFieldGroupSkeleton = ({
  leftSide,
  rightSide,
  ...others
}: SHFieldGroupSkeletonProps & SHStackProps) => {
  return (
    <SHStack
      spacing={3}
      alignItems={"stretch"}
      justifyContent={"space-between"}
      direction={{ xs: "column", md: "row" }}
      {...others}
    >
      {leftSide}
      {rightSide}
    </SHStack>
  );
};
