import { PlusButton } from "@components/buttons/plus";
import {
  SHBox,
  SHButton,
  SHHtmlBlock,
  SHImage,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableCell,
  SHTableCellProps,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { EditSVG } from "@components/svgs";
import {
  AnalysisCommentDTO,
  PlatformCommentDialog,
  analysisCommentsKeyMapping,
} from "@models/platform-analysis/entities/comment";
import { UpdateDecisionMarkingDTO } from "@models/reviews/entities/steps/feature";
import { ReviewStep, ReviewSteps } from "@models/reviews/enums/step";
import { useTheme } from "@mui/material";
import { ChangeRichTextDialog } from "@pages/platform-analysis/_id/steps/summary/components/change-rich-text-dialog";
import { parseCommentProductLogo } from "@pages/platform-analysis/_id/steps/summary/components/tables/comments/util";
import { putReviewDecisionMaking } from "@services/reviews/reviewService";
import { hexToRGBA } from "@utils";
import { cloneDeep, filter, findIndex, isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { reviewStepLabelMapping } from "./config";

export interface ReviewCommentTableProps {
  comments: AnalysisCommentDTO[];
  onChange: (comment: AnalysisCommentDTO[]) => void;
  isViewMode?: boolean;
  disabled?: boolean;
  isAppendix?: boolean;
}

export const ReviewCommentTable = ({
  comments,
  onChange,
  isViewMode,
  isAppendix,
  disabled,
}: ReviewCommentTableProps) => {
  const { reviewId } = useParams<{ reviewId: string }>();

  const [commentDialog, setCommentDialog] = useState<
    | (PlatformCommentDialog & {
        stepId: ReviewStep;
      })
    | undefined
  >();
  const [isModifyingComment, setIsModifyingComment] = useState(false);

  const handleOnModifyComment = async (comment: string | null) => {
    if (!commentDialog || !reviewId || !commentDialog?.productId) return;
    setIsModifyingComment(true);

    const { isSuccess } = await putReviewDecisionMaking({
      reviewId: reviewId,
      reviewStep: commentDialog.stepId,
      productId: commentDialog?.productId,
      decisionStatus: null,
      comment: comment,
    } as UpdateDecisionMarkingDTO);

    setIsModifyingComment(false);

    if (isSuccess) {
      const newComments = cloneDeep(comments);
      const platformIdx = findIndex(newComments, {
        productId: commentDialog?.productId,
      });

      (newComments[platformIdx] as any)[
        analysisCommentsKeyMapping[commentDialog?.stepId]
      ] = comment || "";

      setCommentDialog(undefined);
      onChange(newComments);
    }
  };

  const steps = ReviewSteps?.filter((item) => item !== ReviewStep.Setup);

  const firstColWidth = 10;
  const secondColWidth = 8;
  const columnsWidth = (100 - (firstColWidth + secondColWidth)) / steps.length;

  const commentsData = useMemo(() => {
    return isAppendix
      ? filter(comments, {
          inFinalShortlist: false,
        })
      : filter(comments, {
          inFinalShortlist: true,
        });
  }, [comments, isAppendix]);

  return (
    <>
      <SHTableContainer>
        <SHTable>
          <SHTableHead>
            <SHTableRow>
              <HeaderCell width={`${firstColWidth}%`}>Platform</HeaderCell>
              <HeaderCell width={`${secondColWidth}%`}>
                Features match
              </HeaderCell>
              {steps?.map((step) => (
                <HeaderCell key={step} width={`${columnsWidth}%`}>
                  {reviewStepLabelMapping[step]}
                </HeaderCell>
              ))}
            </SHTableRow>
          </SHTableHead>
          <SHTableBody>
            {commentsData?.map((row, index) => (
              <SHTableRow key={row.productId} sx={{ borderBottomWidth: 1 }}>
                <SHTableCell>
                  <SHStack
                    direction={"column"}
                    spacing={"10px"}
                    alignItems={"center"}
                  >
                    <SHImage
                      src={parseCommentProductLogo(row?.productAvatar)}
                      width={"33px"}
                      height={"33px"}
                    />
                    <SHTypography variant="body1" textAlign={"center"}>
                      {row.productName}
                    </SHTypography>
                  </SHStack>
                </SHTableCell>
                <HeaderCell>{`${row.featuresMatch} %`}</HeaderCell>
                {steps.map((step) => {
                  return (
                    <CommentCell
                      key={step}
                      width={`${columnsWidth}%`}
                      disabled={disabled}
                      isViewMode={isViewMode}
                      isAppendix={isAppendix}
                      step={step}
                      removedAtStep={row.removedFromShortlistAtStep}
                      comment={(row as any)[analysisCommentsKeyMapping[step]]}
                      onClick={() => {
                        setCommentDialog({
                          comment:
                            (row as any)[analysisCommentsKeyMapping[step]] ??
                            null,
                          stepId: step,
                          productId: row.productId,
                        });
                      }}
                    />
                  );
                })}
              </SHTableRow>
            ))}
          </SHTableBody>
        </SHTable>
      </SHTableContainer>
      {commentDialog && (
        <ChangeRichTextDialog
          onSubmit={handleOnModifyComment}
          title={
            isEmpty(commentDialog.comment) ? "Add comment" : "Edit comment"
          }
          placeHolder={"Enter your comment"}
          initialContent={commentDialog.comment ?? null}
          isSubmitting={isModifyingComment}
          onClose={() => {
            setCommentDialog(undefined);
          }}
          maxLength={1500}
          isCommentBox
        />
      )}
    </>
  );
};

interface CommentCellProps extends SHTableCellProps {
  comment?: string | null;
  disabled?: boolean;
  isViewMode?: boolean;
  isAppendix?: boolean;
  removedAtStep?: ReviewStep;
  step?: ReviewStep;
  onClick: () => void;
}
const CommentCell = ({
  comment,
  disabled,
  onClick,
  isViewMode,
  isAppendix,
  step,
  removedAtStep,
  ...others
}: CommentCellProps) => {
  const { palette } = useTheme();
  const isShowRemovedLabel = isAppendix && removedAtStep === step;
  const isShowAddCommentButton =
    (step &&
      removedAtStep &&
      ReviewSteps.indexOf(step) <= ReviewSteps.indexOf(removedAtStep)) ||
    step === ReviewStep.Summary ||
    !isAppendix;

  const RemovedFromShortListLabel = () => (
    <SHBox
      sx={{
        p: "4px 15px",
        border: `1px solid ${hexToRGBA(palette.error[300], 0.1)}`,
        borderRadius: "80px",
        marginTop: "0px !important",
        backgroundColor: hexToRGBA(palette.error[200], 0.1),
      }}
    >
      <SHTypography
        variant={"subtitle1"}
        fontSize={"13px"}
        color={palette.error.main}
      >
        Removed from shortlist
      </SHTypography>
    </SHBox>
  );

  return (
    <SHTableCell
      sx={{
        height: "auto",
        borderLeft: `solid 1px ${hexToRGBA(palette.primary[50], 0.3)}`,
        minWidth: "250px",
      }}
      {...others}
    >
      {isEmpty(comment) ? (
        <SHStack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "100%" }}
          gap={"7px"}
        >
          {!isViewMode ? (
            isShowAddCommentButton ? (
              <PlusButton disabled={disabled} size="medium" onClick={onClick}>
                Comment
              </PlusButton>
            ) : (
              <SHTypography variant="body1" lineHeight={"120%"}>
                -
              </SHTypography>
            )
          ) : isShowAddCommentButton ? (
            <SHTypography
              colorVariant="third"
              variant="body2"
              lineHeight={"120%"}
            >
              No comment
            </SHTypography>
          ) : (
            <SHTypography variant="body1" lineHeight={"120%"}>
              -
            </SHTypography>
          )}
          {isShowRemovedLabel && <RemovedFromShortListLabel />}
        </SHStack>
      ) : (
        <SHStack
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          spacing={"7px"}
          sx={{ height: "100%" }}
        >
          <SHHtmlBlock
            content={comment}
            variant="body4"
            color={palette.text.third}
          />
          {isShowRemovedLabel && <RemovedFromShortListLabel />}
          <SHStack alignItems={"flex-start"} sx={{ width: "100%" }}>
            {!isViewMode && !isEmpty(comment) && (
              <SHButton
                startIcon={<EditSVG color={palette.text.primary} />}
                variant="outlined"
                disabled={disabled}
                sx={{
                  "&:active": {
                    "& svg>path": {
                      fill: palette.common.white,
                    },
                  },
                  "&:disabled": {
                    "& svg>path": {
                      fill: palette.text.disabled,
                    },
                  },
                }}
                onClick={onClick}
              >
                Edit
              </SHButton>
            )}
          </SHStack>
        </SHStack>
      )}
    </SHTableCell>
  );
};

interface HeaderCellProps extends SHTableCellProps {
  children: React.ReactNode;
}
const HeaderCell = ({ children, ...others }: HeaderCellProps) => {
  const { palette } = useTheme();
  return (
    <SHTableCell
      sx={{
        height: "50px",
        borderLeft: `solid 1px ${palette.divider}`,
      }}
      {...others}
    >
      <SHTypography
        variant="subtitle1"
        fontWeight={700}
        textAlign="center"
        lineHeight={"120%"}
      >
        {children}
      </SHTypography>
    </SHTableCell>
  );
};
