import { SHHtmlBlock, SHStack, SHTypography } from "@components/design-systems";
import { useTheme } from "@mui/material";

export interface SubSectionTitleProps {
  groupName?: string;
  sectionName?: string;
}

export default function SectionTitle({ groupName, sectionName }: SubSectionTitleProps) {
  const { palette } = useTheme();
  return (
    <SHStack sx={{ pb: "25px" }}>
      <SHTypography variant="h2" sx={{ fontSize: "24px", fontWeight: 600 }}>
        {groupName}
      </SHTypography>
      <SHHtmlBlock
        variant="body3"
        content={sectionName}
        color={palette.text.third}
      />
    </SHStack>
  );
}
