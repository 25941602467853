import { StarSVG } from "@components/svgs";
import { IconButton, Tooltip, tooltipClasses, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
export interface FavouritedButtonProps {
  width?: string;
  height?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  disabled?: boolean;
  isFavourited?: boolean;
  [key: string]: any;
  iconColor?: string;
}
export const FavouritedButton = ({
  width = "24px",
  height = "24px",
  isLoading,
  disabled,
  iconColor,
  isFavourited,
  ...others
}: any) => {
  const { shadows, palette } = useTheme();
  return (
    <Tooltip
      title="Favourite"
      enterDelay={700}
      enterNextDelay={700}
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              {
                marginTop: "6px",
              },
          },
        },
      }}
    >
      <IconButton
        disableRipple
        disabled={disabled}
        {...others}
        sx={{
          width,
          height,
          p: 0,
          borderStyle: "solid",
          borderWidth: "1px",
          backgroundColor: isFavourited
            ? hexToRGBA(palette.error.main, 0.1)
            : palette.common.white,
          borderColor: palette.error[100],
          boxShadow: shadows[1],
          "&>svg>path": {
            fill: isFavourited ? "#FFF500" : palette.common.white,
            stroke: isFavourited
              ? palette.warning[800]
              : hexToRGBA(palette.error.main, 0.5),
          },
          "&:hover": {
            backgroundColor: hexToRGBA(palette.error.main, 0.1),
            borderColor: hexToRGBA(palette.error.main, 0.2),
            boxShadow: "none",
            "&>svg>path": {
              fill: "#FFF500",
              stroke: palette.warning[800],
            },
          },
          "&:active": {
            backgroundColor: hexToRGBA(palette.error.main, 0.1),
            borderColor: hexToRGBA(palette.error.main, 0.2),
            boxShadow: `inset 3px 3px 4px ${hexToRGBA(
              palette.common.black,
              0.25,
            )}`,
            "&>svg>path": {
              fill: "#FFF500",
              stroke: palette.warning[800],
            },
          },
          ...others.sx,
        }}
      >
        <StarSVG width={20} height={20} />
      </IconButton>
    </Tooltip>
  );
};
