import { useUserPermissions } from "@hooks/userUserPermission";
import { PracticeSubscriptionAdmin } from "./admin";
import { PracticeUserSubscription } from "./practice";

export const PracticeSubscription = () => {
  const { isAdminGroup } = useUserPermissions();
  if (isAdminGroup) {
    return <PracticeSubscriptionAdmin />;
  }
  return <PracticeUserSubscription />;
};
