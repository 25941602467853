import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import {
  BillingDetailDTO,
  BillingInfoDTO,
  PaymentMethodDTO,
} from "@models/practice/entities/practiceBillingInfo";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function getBillingInfo(
  adviserFirmId: string,
): Promise<APIResponse<BillingInfoDTO>> {
  const response = await APIGatewayService.get<BillingInfoDTO>(
    generatePath(APIExtRoutes.adviserBillingInfo, { adviserFirmId }),
  );

  return handleErrorProxy(response);
}

export async function getPaymentMethod(
  adviserFirmId: string,
): Promise<APIResponse<PaymentMethodDTO[]>> {
  const response = await APIGatewayService.get<PaymentMethodDTO[]>(
    generatePath(APIExtRoutes.adviserPaymentMethod, { adviserFirmId }),
  );

  return handleErrorProxy(response);
}

export async function postPaymentMethod(
  adviserFirmId: string,
  paymentMethodId: string,
): Promise<APIResponse<PaymentMethodDTO[]>> {
  const response = await APIGatewayService.post<PaymentMethodDTO[]>(
    generatePath(APIExtRoutes.adviserPaymentMethodDetail, {
      adviserFirmId,
      paymentMethodId,
    }),
  );

  return handleErrorProxy(response, "Payment method successfully added.");
}

export async function deletePaymentMethod(
  adviserFirmId: string,
  paymentMethodId: string,
): Promise<APIResponse<PaymentMethodDTO[]>> {
  const response = await APIGatewayService.delete<PaymentMethodDTO[]>(
    generatePath(APIExtRoutes.adviserPaymentMethodDetail, {
      adviserFirmId,
      paymentMethodId,
    }),
  );

  return handleErrorProxy(response, "Payment method successfully removed.");
}

export async function putPaymentMethodDefault(
  adviserFirmId: string,
  paymentMethodId: string,
): Promise<APIResponse<PaymentMethodDTO[]>> {
  const response = await APIGatewayService.put<PaymentMethodDTO[]>(
    generatePath(APIExtRoutes.adviserPaymentMethodDetail, {
      adviserFirmId,
      paymentMethodId,
    }),
  );

  return handleErrorProxy(response, "Default payment method successfully updated.");
}

export async function getBillingDetail(
  adviserFirmId: string,
): Promise<APIResponse<BillingDetailDTO>> {
  const response = await APIGatewayService.get<BillingDetailDTO>(
    generatePath(APIExtRoutes.adviserBillingDetail, { adviserFirmId }),
  );

  return handleErrorProxy(response);
}

export async function putBillingDetail(
  adviserFirmId: string,
  billingDetail: BillingDetailDTO,
): Promise<APIResponse<BillingDetailDTO>> {
  const response = await APIGatewayService.put<BillingDetailDTO>(
    generatePath(APIExtRoutes.adviserBillingDetail, { adviserFirmId }),
    billingDetail,
  );

  return handleErrorProxy(response, "Billing details successfully updated.");
}

export async function getStripeClientSecret(
  adviserFirmId: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.get<string>(
    generatePath(APIExtRoutes.adviserClientSecret, { adviserFirmId }),
  );

  return handleErrorProxy(response);
}

export async function getCustomerPortalLink(
  adviserFirmId: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.get<string>(
    generatePath(APIExtRoutes.adviserCustomerPortalLink, { adviserFirmId }),
  );

  return handleErrorProxy(response);
}

export async function getOrangeDotBillingInfo(adviserFirmId: string): Promise<APIResponse<boolean>> {
  const response = await APIGatewayService.get<boolean>(
    generatePath(APIExtRoutes.adviserOrangeDotBillingInfo, { adviserFirmId }),
  );

  return handleErrorProxy(response);
}