import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { CreateFamilyGroupDTO } from "@models/family-group/entities/familyGroup";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function postFamilyGroup(
    familyGroup: CreateFamilyGroupDTO
  ): Promise<APIResponse<CreateFamilyGroupDTO>> {
    const response = await APIGatewayService.post<CreateFamilyGroupDTO>(
      generatePath(APIExtRoutes.familyGroups),
      familyGroup,
    );
    return handleErrorProxy(response, "Create family group success");
  }
  