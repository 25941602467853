import { SelectRegions } from "@components/auto-completes/regions";
import { DeleteButton } from "@components/buttons/delete";
import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHButton,
  SHCheckbox,
  SHContainer,
  SHDivider,
  SHStack,
} from "@components/design-systems";
import { SHAlert } from "@components/design-systems/sh-alert";
import { SHFormControlLabel } from "@components/design-systems/sh-form-control-label";
import { SHRadio } from "@components/design-systems/sh-radio";
import { SHRadioGroup } from "@components/design-systems/sh-radio-group";
import { SHTextField } from "@components/design-systems/sh-text-field";
import { uploadOptions } from "@components/design-systems/sh-upload-file/config";
import { SHUploadImage } from "@components/design-systems/sh-upload-image";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import ResetPasswordDialog from "@components/dialogs/reset-password";
import UnsavedDialog from "@components/dialogs/unsaved";
import { StatusBadge } from "@components/status-badge";
import { PageRoutes } from "@constants/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppParams } from "@hooks/useAppParams";
import { useIsNew } from "@hooks/useIsNew";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar } from "@layouts/top-bar";
import { UserType } from "@models/auth";
import { PageMode, ParentState } from "@models/core";
import {
  ProductDTO,
  SupplierUserDTO,
  SupplierUserLov,
} from "@models/supplier/entities/supplierUser";
import { UserStatus } from "@models/users/enums/status";
import { Link } from "@mui/material";
import {
  accountTypeSubTitleMapping,
  accountTypeTitleMapping,
  createSupplierUserConverter,
  teamMemberValidators,
  updateSupplierUserConverter,
} from "@pages/suppliers/_id/_team/_id/util";
import { generateSubTitle, generateTitle } from "@pages/suppliers/_id/util";
import { RootState } from "@redux/store";
import { updateUser } from "@redux/slices/auth";
import {
  updateAccountTypeLicense,
  updateSupplier,
} from "@redux/slices/supplier";
import {
  deleteAvatar,
  uploadAvatar,
} from "@services/file-storage/fileStorageService";
import {
  createSupplierUser,
  getSupplier,
  getSupplierLicenses,
  getSupplierUser,
  getSupplierUserLov,
  updateSupplierUser,
  updateSupplierUserStatus,
} from "@services/suppliers";
import { resendInvite, resetPassword } from "@services/users/userService";
import { theme } from "@themes";
import {
  checkingDomain,
  constructUserName,
  generateGAvatarUrl,
  sortAscByOrder,
} from "@utils";
import { renameFile } from "@utils/data-type/file";
import { isEmpty, isNil } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { FileRejection } from "react-dropzone";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEffectOnce } from "react-use";
import ManageSupplierUserSkeleton from "./skeleton";
import { TransferOwnership } from "./components/transfer-onwership";
import { DomainDeniedDialog } from "@components/dialogs/domain-denied";

export const ManageTeam = () => {
  // use when have API
  const { isMyProfile } = useAppParams();
  const {
    user,
    isSupplierPremiumStandard,
    isAdminGroup,
    canViewSupplierUser,
    canCreateSupplierUser,
    canSaveSupplierUser,
    canChangeSupplierUserType,
    canChangeSupplierAssociatedProducts,
    canChangeSupplierAdditionalPermissions,
    canSupplierUserTransferOwnership,
    canDisableSupplierUser,
    canEnableSupplierUser,
    canArchiveSupplierUser,
    canUnArchiveSupplierUser,
    canResendInviteSupplierUser,
  } = useUserPermissions();

  const isNew = useIsNew();
  const navigate = useNavigate();
  const location = useLocation();
  const { notify } = useNotification();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoadingSupplierUser, setIsLoadingSupplierUser] = useState(false);
  const [loadingResendInvite, setLoadingResendInvite] = useState(false);
  const [isChangingStatus, setIsChangingStatus] = useState<UserStatus | null>();
  const [supplierUserType, setSupplierUserType] = useState<
    UserType | undefined
  >();
  const [lov, setLov] = useState<SupplierUserLov>();
  const [isSendingResetPassword, setSendingResetPassword] = useState(false);

  const [uploadMessage, setUploadMessage] = useState<string | null>(null);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
  const [showDomainDeniedDialog, setShowDomainDeniedDialog] = useState(false);
  const { accountTypeLicenses } = useSelector(
    (state: RootState) => state.supplier,
  );

  const [licenses, setLicenses] = useState<any[]>([]);
  const { supplierId, supplierUserId } = useParams();
  const dispatch = useDispatch();

  const parentState = location.state as ParentState;

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,

    formState: { errors, isValid, isSubmitting },
  } = useForm<SupplierUserDTO>({
    mode: "onChange",
    resolver: yupResolver(teamMemberValidators(!!lov?.products.length)),
    defaultValues: { ...new SupplierUserDTO() },
  });

  const accountType = watch("userType");
  const isShowUsersDetailBDM = watch("isPublic");

  const { isDirty } = useFormState({ control });
  const userStatus = getValues("status") ?? null;
  const isDisabled = useMemo(() => {
    return (
      isSubmitting ||
      !isEditMode ||
      userStatus === UserStatus.Disabled ||
      userStatus === UserStatus.Archived
    );
  }, [isSubmitting, isEditMode, userStatus]);

  const supplierStandardCanMangeType = (type?: UserType) => {
    if (!isSupplierPremiumStandard) return true;
    if (!type) return false;
    return [
      UserType.SupplierBdmSale,
      UserType.SupplierPremiumStandard,
    ].includes(type);
  };
  const supplierStandardCanChangeStatus =
    supplierStandardCanMangeType(accountType);

  const canView = isMyProfile || canViewSupplierUser;
  const canCreate = canCreateSupplierUser;
  const canSave = isMyProfile || canSaveSupplierUser;
  const canChangeAccountType =
    canChangeSupplierUserType &&
    (isNew
      ? true
      : !isMyProfile && accountType !== UserType.SupplierPremiumOwner);

  const canChangeAssociatedProducts =
    !isMyProfile && canChangeSupplierAssociatedProducts;
  const canChangeAdditionalPermissions =
    !isMyProfile && canChangeSupplierAdditionalPermissions;
  const canTransferOwnership = canSupplierUserTransferOwnership;

  const canDisable =
    !isMyProfile && canDisableSupplierUser && supplierStandardCanChangeStatus;
  const canEnable =
    !isMyProfile && canEnableSupplierUser && supplierStandardCanChangeStatus;
  const canArchive =
    !isMyProfile && canArchiveSupplierUser && supplierStandardCanChangeStatus;
  const canUnArchive =
    !isMyProfile && canUnArchiveSupplierUser && supplierStandardCanChangeStatus;
  const canResendInvite =
    !isMyProfile &&
    canResendInviteSupplierUser &&
    supplierStandardCanChangeStatus;

  const accessDenied = !canView || (isNew && !canCreate);

  const showAssociatedProducts = useMemo(() => {
    return (
      accountType === UserType.SupplierBdmSale ||
      accountType === UserType.SupplierPremiumStandard
    );
  }, [accountType]);

  const showAdditionalPermissions = useMemo(
    () => accountType === UserType.SupplierPremiumStandard,
    [accountType],
  );

  const showPublicDetails = useMemo(
    () => accountType === UserType.SupplierBdmSale,
    [accountType],
  );

  const showLinkChangePassword =
    !isNew &&
    isEditMode &&
    !isNil(userStatus) &&
    ![UserStatus.Disabled, UserStatus.Archived]?.includes(userStatus);

  const titleFields = generateTitle(isMyProfile);
  const subTitleFields = generateSubTitle(isMyProfile);

  useEffectOnce(() => {
    if (!canView) return;
    loadSupplier();
    loadLov();
    loadSupplierLicenses();
  });

  useEffect(() => {
    setIsEditMode(parentState?.pageMode === PageMode.Edit || isNew);
    if (!isNew && canView) {
      loadSupplierUser(supplierId, supplierUserId);
    }
    // eslint-disable-next-line
  }, [supplierId, supplierUserId, isNew, canView]);

  const loadLov = async () => {
    const { data } = await getSupplierUserLov(supplierId);
    if (isNew) {
      setValue("preferences", data?.preferences.map((p) => p.id) || []);
    }
    setLov(data);
  };

  const loadSupplier = async () => {
    const { data } = await getSupplier(supplierId);
    if (data) dispatch(updateSupplier(data));
  };

  const loadSupplierLicenses = async () => {
    const { data } = await getSupplierLicenses(supplierId);
    if (data) {
      setLicenses(data.supplierAccountType);
      dispatch(updateAccountTypeLicense(data.supplierAccountType));
    }
  };

  const loadSupplierUser = async (
    supplierId?: string,
    supplierUserId?: string,
  ) => {
    if (!supplierId || !supplierUserId) return;

    setIsLoadingSupplierUser(true);
    const { data, message } = await getSupplierUser(supplierId, supplierUserId);
    setIsLoadingSupplierUser(false);

    if (data) {
      setSupplierUserType(data?.userType);
      reset(data);
    } else {
      navigate(generatePath(PageRoutes.supplierTeam, { supplierId }), {
        state: { notification: message },
      });
    }
  };

  const handleOnUploadAvatar = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (!isEmpty(fileRejections))
      setUploadMessage("Unsupported file format. Please upload another file.");

    if (isEmpty(acceptedFiles)) return;

    const file = new FormData();
    file.append("file", renameFile(acceptedFiles[0]));

    setIsUploading(true);
    const { data, message } = await uploadAvatar(file);
    if (data) {
      setValue("avatarUrl", data?.url, uploadOptions.hookForm);
      setValue("avatarS3Key", data?.key, uploadOptions.hookForm);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setIsUploading(false);
  };

  const handleOnDeleteAvatar = async () => {
    const avatarS3Key = getValues("avatarS3Key");
    setIsDeleting(true);
    const { isSuccess, message } = await deleteAvatar(avatarS3Key);
    setIsDeleting(false);
    if (isSuccess) {
      setValue("avatarS3Key", "", uploadOptions.hookForm);
      setValue(
        "avatarUrl",
        generateGAvatarUrl(
          getValues("email"),
          getValues("firstName"),
          getValues("lastName"),
        ),
        uploadOptions.hookForm,
      );
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnUpdateStatus = async (status: UserStatus) => {
    setIsChangingStatus(status);
    const { isSuccess, message, data } = await updateSupplierUserStatus(
      supplierUserId,
      status,
    );

    setIsChangingStatus(null);

    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      loadSupplierLicenses();
      reset({ ...getValues(), status: data?.status || status });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }

    setIsChangingStatus(null);
  };

  const handleOnResendInvite = async () => {
    setLoadingResendInvite(true);

    const { isSuccess, message } = await resendInvite(supplierUserId);

    setLoadingResendInvite(false);

    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }

    setLoadingResendInvite(false);
  };

  const handleOnResetPassword = async () => {
    setSendingResetPassword(true);
    const { isSuccess, message } = await resetPassword(getValues("email"));
    setSendingResetPassword(false);
    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setSendingResetPassword(false);
    setShowResetPasswordDialog(false);
  };

  const onSubmit = async (formData: SupplierUserDTO) => {
    const organizationDomain = user?.email?.split("@")?.[1];
    const userDomain = watch("email")?.split("@")?.[1];
    const isCheckingDomain = checkingDomain(organizationDomain, userDomain);

    if (!isCheckingDomain && !isAdminGroup) {
      setShowDomainDeniedDialog(true);
      return;
    }

    if (isNew) {
      await handleOnCreateSupplierUser(formData);
    } else {
      await handleOnUpdateSupplierUser(formData);
    }
  };

  const handleOnCreateSupplierUser = async (formData: SupplierUserDTO) => {
    if (!supplierId) return;
    const { isSuccess, message, data } = await createSupplierUser({
      ...createSupplierUserConverter(formData),
      supplierId: supplierId,
    });
    if (isSuccess && data) {
      notify(message, {
        variant: "success",
        close: true,
      });
      reset(data);
      setIsEditMode(false);
      navigate(
        generatePath(PageRoutes.supplierUser, {
          supplierId,
          supplierUserId: data?.auth0Id,
        }),
        { replace: true },
      );
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnUpdateSupplierUser = async (formData: SupplierUserDTO) => {
    const { isSuccess, message } = await updateSupplierUser(
      supplierUserId,
      updateSupplierUserConverter(formData),
    );
    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });
      reset(formData);
      setIsEditMode(false);
      if (user && isMyProfile) {
        const {
          firstName,
          lastName,
          userType,
          avatarS3Key,
          avatarUrl,
          status,
        } = formData;
        dispatch(
          updateUser({
            ...user,
            firstName,
            lastName,
            userType,
            avatarS3Key,
            avatarUrl,
            status: status || undefined,
            name: `${firstName} ${lastName}`,
          }),
        );
      }
      navigate(
        generatePath(PageRoutes.supplierUser, {
          supplierId,
          supplierUserId,
        }),
        { replace: true },
      );
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnChangeAccountType = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    const newAccountTypeLicenses = accountTypeLicenses.map((item) => {
      if (supplierUserType !== value && item.accountType === supplierUserType) {
        return { ...item, inUsed: item.inUsed - 1 };
      }
      if (item.accountType === value && item.accountType !== supplierUserType) {
        return { ...item, inUsed: item.inUsed + 1 };
      }
      return item;
    });

    setLicenses(newAccountTypeLicenses);
  };

  const handleRefreshSupplier = async () => {
    loadSupplier();
    loadSupplierUser(supplierId, supplierUserId);
  };

  const onChangeAvatarUrlByField = (
    email?: string,
    firstName?: string,
    lastName?: string,
  ) => {
    if (isEmpty(getValues("avatarS3Key"))) {
      setValue(
        "avatarUrl",
        generateGAvatarUrl(
          email ?? getValues("email"),
          firstName ?? getValues("firstName"),
          lastName ?? getValues("lastName"),
        ),
      );
    }
  };

  if (accessDenied) return <AccessDeniedDialog />;
  if (isLoadingSupplierUser) return <ManageSupplierUserSkeleton />;

  const isAssociatedProducts = (productId: string) => {
    return getValues("associatedProducts").some((x) => x.id === productId);
  };

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SHStack sx={{ paddingY: 3 }} spacing={3}>
          <TopBar
            title={
              isNew
                ? // TODO: create function
                  constructUserName(
                    undefined,
                    watch("firstName"),
                    watch("lastName"),
                  ) || "New team member"
                : constructUserName(
                    undefined,
                    watch("firstName"),
                    watch("lastName"),
                  )
            }
            customBadge={
              getValues("status") ? (
                <StatusBadge status={getValues("status")} />
              ) : undefined
            }
            tools={
              <>
                {isNew && canCreate && (
                  <>
                    <SHButton
                      variant="outlined"
                      size="extraMedium"
                      type="button"
                      onClick={() =>
                        navigate(
                          generatePath(PageRoutes.supplierTeam, {
                            supplierId,
                          }),
                        )
                      }
                    >
                      Cancel
                    </SHButton>
                    <SHButton
                      variant="contained"
                      size="extraMedium"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Create and send welcome email
                    </SHButton>
                  </>
                )}
                {!isEditMode && (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    onClick={() => setIsEditMode(true)}
                  >
                    Edit
                  </SHButton>
                )}
                {!isNew && isEditMode && (
                  <>
                    {userStatus === UserStatus.Pending && (
                      <>
                        {canDisable && (
                          <SHButton
                            variant="outlined"
                            size="extraMedium"
                            disabled={isChangingStatus === UserStatus.Disabled}
                            isLoading={isChangingStatus === UserStatus.Disabled}
                            onClick={() =>
                              handleOnUpdateStatus(UserStatus.Disabled)
                            }
                          >
                            Disable
                          </SHButton>
                        )}
                        {canResendInvite && (
                          <SHButton
                            variant="outlined"
                            size="extraMedium"
                            onClick={handleOnResendInvite}
                            isLoading={loadingResendInvite}
                            disabled={loadingResendInvite}
                          >
                            Resend invite
                          </SHButton>
                        )}
                      </>
                    )}

                    {canDisable && userStatus === UserStatus.Active && (
                      <SHButton
                        variant="outlined"
                        size="extraMedium"
                        isLoading={isChangingStatus === UserStatus.Disabled}
                        disabled={isChangingStatus === UserStatus.Disabled}
                        onClick={() =>
                          handleOnUpdateStatus(UserStatus.Disabled)
                        }
                      >
                        Disable
                      </SHButton>
                    )}

                    {userStatus === UserStatus.Disabled && (
                      <>
                        {canEnable && (
                          <SHButton
                            variant="outlined"
                            size="extraMedium"
                            isLoading={isChangingStatus === UserStatus.Active}
                            disabled={isChangingStatus === UserStatus.Active}
                            onClick={() =>
                              handleOnUpdateStatus(UserStatus.Active)
                            }
                          >
                            Enable
                          </SHButton>
                        )}
                        {canArchive && (
                          <SHButton
                            variant="outlined"
                            size="extraMedium"
                            disabled={isChangingStatus === UserStatus.Archived}
                            isLoading={isChangingStatus === UserStatus.Archived}
                            onClick={() =>
                              handleOnUpdateStatus(UserStatus.Archived)
                            }
                          >
                            Archive
                          </SHButton>
                        )}
                      </>
                    )}
                    {canUnArchive && userStatus === UserStatus.Archived && (
                      <SHButton
                        variant="outlined"
                        size="extraMedium"
                        isLoading={isChangingStatus === UserStatus.Disabled}
                        disabled={isChangingStatus === UserStatus.Disabled}
                        onClick={() =>
                          handleOnUpdateStatus(UserStatus.Disabled)
                        }
                      >
                        Unarchive
                      </SHButton>
                    )}

                    {canSave && (
                      <SHButton
                        variant="contained"
                        size="extraMedium"
                        type="submit"
                        disabled={!isValid || isSubmitting || !isDirty}
                        isLoading={isSubmitting}
                      >
                        Save
                      </SHButton>
                    )}
                  </>
                )}
              </>
            }
          />
          <SHBox>
            <FieldGroup
              firstLine
              title="Profile"
              subTitle={subTitleFields?.Profile}
            />
            <SHDivider />

            <FieldGroup
              title="Profile photo"
              subTitle={subTitleFields?.ProfilePhoto}
            >
              <Controller
                name="avatarUrl"
                control={control}
                render={({ field }) => (
                  <SHUploadImage
                    isUploading={isUploading}
                    src={field.value}
                    actionIcon={
                      !isEmpty(getValues("avatarS3Key")) &&
                      isEditMode &&
                      !isDisabled && (
                        <DeleteButton
                          isLoading={isDeleting}
                          onClick={handleOnDeleteAvatar}
                        />
                      )
                    }
                    dropzoneOptions={{
                      multiple: false,
                      accept: {
                        "image/*": [".svg", ".png", ".jpg", ".gif"],
                      },
                      onDrop: handleOnUploadAvatar,
                      disabled: isDisabled || isUploading,
                    }}
                  />
                )}
              />
              {uploadMessage && (
                <SHStack sx={{ paddingTop: 3 }}>
                  <SHAlert
                    onClose={() => setUploadMessage(null)}
                    severity="error"
                    width={490}
                  >
                    {uploadMessage}
                  </SHAlert>
                </SHStack>
              )}
            </FieldGroup>
            <SHDivider />

            <FieldGroup
              title="Personal details"
              subTitle={subTitleFields?.PersonalDetails}
              required
            >
              <SHStack
                spacing={3}
                direction="column"
                sx={{ width: { xs: "100%", md: 520 } }}
              >
                <SHStack spacing={2} direction="row">
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <SHTextField
                        {...field}
                        required
                        sx={{ width: { xs: "100%", md: 250 } }}
                        label="First name"
                        placeholder="Enter first name"
                        disabled={isDisabled}
                        error={!!errors.firstName}
                        helperText={
                          errors.firstName ? errors.firstName.message : null
                        }
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          onChangeAvatarUrlByField(
                            undefined,
                            event.target.value,
                            undefined,
                          );
                        }}
                      />
                    )}
                  />

                  <Controller
                    render={({ field }) => (
                      <SHTextField
                        {...field}
                        required
                        sx={{ width: { xs: "100%", md: 250 } }}
                        label={"Last name"}
                        placeholder="Enter last name"
                        disabled={isDisabled}
                        error={!!errors.lastName}
                        helperText={
                          errors.lastName ? errors.lastName.message : null
                        }
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          onChangeAvatarUrlByField(
                            undefined,
                            undefined,
                            event.target.value,
                          );
                        }}
                      />
                    )}
                    name="lastName"
                    control={control}
                  />
                </SHStack>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      required
                      type="email"
                      label={"Work email"}
                      placeholder="Enter work email"
                      disabled={isDisabled || !isNew}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : null}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        onChangeAvatarUrlByField(
                          event.target.value,
                          undefined,
                          undefined,
                        );
                      }}
                    />
                  )}
                />
                <Controller
                  name="jobTitle"
                  control={control}
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      label={"Job title"}
                      placeholder="Enter job title"
                      disabled={isDisabled}
                    />
                  )}
                />
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <SHTextField
                      {...field}
                      type="tel"
                      label={"Contact number"}
                      placeholder="Enter contact number"
                      disabled={isDisabled}
                    />
                  )}
                />
              </SHStack>
              {showLinkChangePassword && (
                <SHStack
                  direction={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  sx={{ paddingTop: 3 }}
                >
                  <Link
                    variant="body2"
                    color={theme.palette.text.secondary}
                    component="label"
                    underline="none"
                    onClick={() => setShowResetPasswordDialog(true)}
                    sx={{
                      paddingRight: 0.5,
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    {subTitleFields?.ChangePassword}
                  </Link>
                </SHStack>
              )}
            </FieldGroup>

            <SHDivider />

            <FieldGroup
              title="Account type"
              subTitle={subTitleFields.AccountType}
              required
            >
              <SHStack
                spacing={3}
                direction="column"
                sx={{ width: { xs: "100%", md: 520 } }}
              >
                <Controller
                  name="userType"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <SHRadioGroup
                      {...field}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        value: string,
                      ) => {
                        field.onChange(value);
                        handleOnChangeAccountType(event, value);
                      }}
                    >
                      {sortAscByOrder(licenses).map((item) => {
                        return (
                          <SHFormControlLabel
                            key={item.accountType}
                            value={item.accountType}
                            control={<SHRadio />}
                            label={`${
                              accountTypeTitleMapping[item.accountType]
                            } (${item.inUsed}/${item.total} licences in use)`}
                            disabled={
                              isDisabled ||
                              !canChangeAccountType ||
                              item.inUsed === item.total ||
                              !supplierStandardCanMangeType(item.accountType) ||
                              (!isNew &&
                                item.accountType ===
                                  UserType.SupplierPremiumOwner)
                            }
                            subLabel={
                              accountTypeSubTitleMapping[item.accountType]
                            }
                          />
                        );
                      })}
                    </SHRadioGroup>
                  )}
                />
              </SHStack>
            </FieldGroup>

            {!isNew && supplierUserType === UserType.SupplierPremiumOwner && (
              <>
                <SHDivider />
                <FieldGroup
                  title="Firm account owner"
                  subTitle="Choose the Owner of the firm account. They will have full permissions, including access to subscription and user management. Sales users and inactive users cannot be account owners"
                >
                  <SHStack
                    spacing={3}
                    direction="column"
                    sx={{ width: { xs: "100%", md: 520 } }}
                  >
                    <TransferOwnership
                      refreshData={handleRefreshSupplier}
                      disabled={isDisabled || !canTransferOwnership}
                    />
                  </SHStack>
                </FieldGroup>
              </>
            )}
            {showAssociatedProducts && (
              <>
                <SHDivider />

                <FieldGroup
                  title="Associated products"
                  required={!!lov?.products.length}
                  subTitle={subTitleFields.AssociatedProduct}
                >
                  <SHStack
                    spacing={3}
                    direction="column"
                    sx={{ width: { xs: "100%", md: 520 } }}
                  >
                    <Controller
                      render={({ field: { ref, ...field } }) => (
                        <SHBox>
                          {lov?.products &&
                            lov.products.map((product) => (
                              <SHFormControlLabel
                                value={product.id}
                                control={<SHCheckbox />}
                                label={product.name}
                                checked={isAssociatedProducts(product.id)}
                                onChange={(event: any) => {
                                  var obj: ProductDTO = {
                                    id: event.target.value,
                                    name: product.name,
                                    type: product.type,
                                  };

                                  if (event.target.checked) {
                                    field.onChange([...field.value, obj]);
                                  } else {
                                    field.onChange(
                                      field.value.filter(
                                        (obj) => obj.id !== event.target.value,
                                      ),
                                    );
                                  }
                                }}
                                disabled={
                                  isDisabled || !canChangeAssociatedProducts
                                }
                              />
                            ))}
                        </SHBox>
                      )}
                      name="associatedProducts"
                      control={control}
                    />
                  </SHStack>
                </FieldGroup>
              </>
            )}
            {showPublicDetails && (
              <>
                <SHDivider />
                <FieldGroup
                  title="Public details"
                  subTitle="Enter information that will be shared publicly with other users of SuitabilityHub on the “Find BDM” page of associated products"
                >
                  <SHStack
                    spacing={3}
                    direction="column"
                    sx={{ width: { xs: "100%", md: 520 } }}
                  >
                    <Controller
                      render={({ field: { ref, ...field } }) => {
                        return (
                          <SHBox {...field}>
                            <SHFormControlLabel
                              value={true}
                              control={
                                <SHCheckbox checked={field.value || false} />
                              }
                              label={
                                'Show this user\'s details on the "Find a BDM" page of associated products'
                              }
                              disabled={isDisabled}
                            />
                          </SHBox>
                        );
                      }}
                      name="isPublic"
                      control={control}
                    />
                    {isShowUsersDetailBDM && (
                      <>
                        <Controller
                          name="publicPhoneNumber"
                          control={control}
                          render={({ field }) => (
                            <SHTextField
                              {...field}
                              label={"Public contact number"}
                              disabled={isDisabled}
                              placeholder="Enter public contact number"
                              helperText={
                                errors.publicPhoneNumber
                                  ? errors.publicPhoneNumber.message
                                  : null
                              }
                            />
                          )}
                        />
                        <Controller
                          name="preferredContactHours"
                          control={control}
                          render={({ field }) => (
                            <SHTextField
                              {...field}
                              label={"Preferred contact hours"}
                              disabled={isDisabled}
                              placeholder="Enter preferred contact hours"
                            />
                          )}
                        />
                        <Controller
                          name="publicEmail"
                          control={control}
                          render={({ field }) => (
                            <SHTextField
                              {...field}
                              type="email"
                              label={"Public email"}
                              disabled={isDisabled}
                              placeholder="Enter public email"
                              helperText={
                                errors.publicEmail
                                  ? errors.publicEmail.message
                                  : null
                              }
                            />
                          )}
                        />
                        <Controller
                          name="linkedInProfileUrl"
                          control={control}
                          render={({ field }) => (
                            <SHTextField
                              {...field}
                              label={"LinkedIn profile URL"}
                              disabled={isDisabled}
                              placeholder="Enter link to LinkedIn profile "
                              helperText={
                                errors.linkedInProfileUrl
                                  ? errors.linkedInProfileUrl.message
                                  : null
                              }
                            />
                          )}
                        />
                        <Controller
                          name="regions"
                          control={control}
                          render={({ field }) => (
                            <SelectRegions
                              regionData={lov?.regions}
                              disabled={isDisabled}
                              value={field.value ?? []}
                              onChange={(value) => {
                                field.onChange(value);
                              }}
                              textFieldProps={{
                                label: "Region",
                                placeholder: "Enter the region/area serviced",
                              }}
                            />
                          )}
                        />
                      </>
                    )}
                  </SHStack>
                </FieldGroup>
              </>
            )}
            {showAdditionalPermissions && (
              <>
                <SHDivider />
                <FieldGroup
                  title={titleFields.AdditionalPermissions}
                  subTitle={subTitleFields.AdditionalPermissions}
                >
                  <SHStack
                    direction="column"
                    sx={{ width: { xs: "100%", md: 520 } }}
                  >
                    <Controller
                      render={({ field: { ref, ...field } }) => (
                        <>
                          {lov?.additionalPermissions &&
                            sortAscByOrder(lov.additionalPermissions).map(
                              (permission) => (
                                <SHFormControlLabel
                                  value={permission.id}
                                  control={<SHCheckbox />}
                                  label={permission.name}
                                  disabled={
                                    isDisabled ||
                                    !canChangeAdditionalPermissions
                                  }
                                  postfixLabel={
                                    <InformationButton
                                      title={permission.name}
                                      content={permission.description}
                                    />
                                  }
                                  checked={getValues(
                                    "additionalPermissions",
                                  )?.includes(permission.id)}
                                  onChange={(event: any) => {
                                    if (event.target.checked) {
                                      field.onChange([
                                        ...field.value,
                                        event.target.value,
                                      ]);
                                    } else {
                                      field.onChange(
                                        field.value.filter(
                                          (id) => id !== event.target.value,
                                        ),
                                      );
                                    }
                                  }}
                                />
                              ),
                            )}
                        </>
                      )}
                      name="additionalPermissions"
                      control={control}
                    />
                  </SHStack>
                </FieldGroup>
              </>
            )}

            {(isMyProfile || isAdminGroup) && (
              <>
                <SHDivider />
                <FieldGroup title="Communication preferences">
                  <Controller
                    render={({ field: { ref, ...field } }) => (
                      <SHBox sx={{ width: { xs: "100%", md: 520 } }}>
                        {lov?.preferences &&
                          sortAscByOrder(lov.preferences).map((preference) => (
                            <SHFormControlLabel
                              value={preference.id}
                              control={<SHCheckbox />}
                              label={preference.name}
                              disabled={isDisabled}
                              checked={getValues("preferences")?.includes(
                                preference.id,
                              )}
                              onChange={(event: any) => {
                                if (event.target.checked) {
                                  field.onChange([
                                    ...field.value,
                                    event.target.value,
                                  ]);
                                } else {
                                  field.onChange(
                                    field.value.filter(
                                      (id) => id !== event.target.value,
                                    ),
                                  );
                                }
                              }}
                            />
                          ))}
                      </SHBox>
                    )}
                    name="preferences"
                    control={control}
                  />
                </FieldGroup>
              </>
            )}
          </SHBox>
        </SHStack>

        <UnsavedDialog isDirty={isDirty && !isSubmitting} />
        {showResetPasswordDialog && (
          <ResetPasswordDialog
            onClose={() => setShowResetPasswordDialog(false)}
            onYes={handleOnResetPassword}
            isSending={isSendingResetPassword}
            isMyProfile={isMyProfile}
          />
        )}
        {showDomainDeniedDialog && (
          <DomainDeniedDialog onYes={() => setShowDomainDeniedDialog(false)} />
        )}
      </form>
    </SHContainer>
  );
};
