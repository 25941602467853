import {
  SMAListCurrentlySelectedFilter,
  SMAListFavouriteFilter,
  SMAListMinimumInvestmentSizeFilter,
} from "@models/managed-accounts/enums/filter";
import { FilterCalculatedInvestmentDTO } from "./components/tables/model";
import {
  SubHeaderNameMapping,
  SubHeaderTooltipTitleMapping,
  SubHeaderTooltipContentMapping,
} from "@models/managed-accounts/entities/header";
import { SubHeader } from "@models/managed-accounts/enums/subHeader";
import {MainHeaderColumn} from "@models/managed-accounts/enums/mainHeader";

export const DefaultInvestmentSize = 50000;
export const overscanDefault = 1;
export const overscanFireFox = 20;
export const occupiedSpace = "371px";
export const subHeaderCellBorderIds = [
  SubHeader.Platform.toString(),
  SubHeader.PDS.toString(),
  SubHeader.NetOfRebates.toString(),
  SubHeader.UnderlyingPerformanceFees.toString(),
  SubHeader.ModelTurnover.toString(),
  SubHeader.Type.toString(),
];
export const mainHeaderCellBorderIds = [
  "MainModel",
  MainHeaderColumn.TotalFees.toString(),
  MainHeaderColumn.ManagementFeesAndCosts.toString(),
  MainHeaderColumn.PerformanceFees.toString(),
  MainHeaderColumn.TransactionCosts.toString(),
  MainHeaderColumn.PlatformFees.toString(),
  MainHeaderColumn.ModelDetails.toString(),
];

export const subHeaderNameMapping: SubHeaderNameMapping = {
  [SubHeader.Model]: "Model",
  [SubHeader.Platform]: "Platform",
  [SubHeader.AnnualTotal]: "Annual total",
  [SubHeader.PDS]: "PDS",
  [SubHeader.TotalManagementFeesAndCosts]: "Total",
  [SubHeader.IMFee]: "IM fee",
  [SubHeader.UnderlyingManagementFeesAndCosts]: "Underlying (indirect)",
  [SubHeader.CashFee]: "Cash fee",
  [SubHeader.NetOfRebates]: "Net of rebates",
  [SubHeader.TotalPerformanceFees]: "Total",
  [SubHeader.ModelManager]: "Model manager",
  [SubHeader.UnderlyingPerformanceFees]: "Underlying (indirect)",
  [SubHeader.TotalTransactionCosts]: "Total",
  [SubHeader.UnderlyingTransactionCosts]: "Underlying (indirect)",
  [SubHeader.BuySellSpreads]: "Buy/sell spreads",
  [SubHeader.PlatformPercent]: "Platform %",
  [SubHeader.PlatformDollar]: "Platform $",
  [SubHeader.Netting]: "Netting",
  [SubHeader.ModelTurnover]: "Model turnover",
  [SubHeader.Discount]: "Discount",
  [SubHeader.Type]: "Type",
  [SubHeader.FeeMethod]: "Fee method",
  [SubHeader.Incepted]: "Incepted <1yr",
  [SubHeader.MinInvestment]: "Min investment",
  [SubHeader.PercentCash]: "% Cash",
  [SubHeader.PercentIntListed]: "% Int listed",
  [SubHeader.Super]: "Super",
  [SubHeader.IDPS]: "IDPS",
  [SubHeader.PrivateLabel]: "Private label",
  [SubHeader.Available]: "Available",
  [SubHeader.CustomModel]: "Custom model",
};

export const subHeaderTooltipTitleMapping: SubHeaderTooltipTitleMapping = {
  [SubHeader.AnnualTotal]:
    "Total fees for the specified portfolio for 1-year (estimate)",
  [SubHeader.PDS]: "PDS Date",
  [SubHeader.TotalManagementFeesAndCosts]: "Total management fees & costs",
  [SubHeader.IMFee]: "Investment management fee",
  [SubHeader.UnderlyingManagementFeesAndCosts]:
    "Underlying investment management costs (indirect cost)",
  [SubHeader.CashFee]: "Cash investment fee and interest retained",
  [SubHeader.NetOfRebates]:
    "Total management fees & costs are reported net of rebates",
  [SubHeader.TotalPerformanceFees]: "Total performance fees",
  [SubHeader.ModelManager]: "Model manager performance fee",
  [SubHeader.UnderlyingPerformanceFees]:
    "Underlying investment performance fee (indirect cost)",
  [SubHeader.TotalTransactionCosts]: "Total transaction costs",
  [SubHeader.UnderlyingTransactionCosts]:
    "Underlying investments' net transaction costs (indirect cost)",
  [SubHeader.BuySellSpreads]: "Buy/sell spreads",
  [SubHeader.PlatformPercent]: "Platform % transaction costs",
  [SubHeader.PlatformDollar]: "Platform $ transaction costs",
  [SubHeader.Netting]:
    "Total transaction costs were reduced through trade aggregation & netting",
  [SubHeader.ModelTurnover]: "Model turnover",
  [SubHeader.Discount]:
    "Platform administrative fees are lowered compared to full menu",
  [SubHeader.Type]: "Discount type",
  [SubHeader.FeeMethod]:
    "Methodology for calculating the allocation/ weighting of underlying investments' costs",
  [SubHeader.Incepted]: "Model incepted less than 1 year ago",
  [SubHeader.MinInvestment]: "Minimum investment size",
  [SubHeader.PercentCash]: "Allocation to cash",
  [SubHeader.PercentIntListed]: "Allocation to international listed securities",
  [SubHeader.Super]: "Available in Super",
  [SubHeader.IDPS]: "Available in IDPS",
  [SubHeader.Available]: "Availability",
};

export const subHeaderTooltipContentMapping: SubHeaderTooltipContentMapping = {
  [SubHeader.AnnualTotal]:
    "Estimated final cost of holding model to the investor, calculated as the sum of Total management fees and costs, Total performance fees and Total transaction costs. Note: Past fees are not an indicator of future fees. The fees and costs breakdown are primarily based on a backward-looking allocation and performance of the model, and hence are likely to vary in the year ahead",
  [SubHeader.PDS]: "The day on which the latest PDS for the model was issued",
  [SubHeader.TotalManagementFeesAndCosts]:
    "Sum of investment management fees, underlying investment management costs (indirect costs) and platform cash fees (where disclosed). Indirect costs may be reported net or gross of rebates",
  [SubHeader.IMFee]:
    "Investment management fees charged to the investor including: 1) Model manager fee; 2) Responsible Entity (RE) fee; and 3) Any implementation/custody fees charged by the platform that are passed on to the client (typically where the platform is not the RE)",
  [SubHeader.UnderlyingManagementFeesAndCosts]:
    "Calculated as the weighted average Indirect Cost Ratio (ICR) of underlying investments. Indirect costs are not deducted from investors' cash accounts as a fee, and instead reduce the value of underlying investments. Note: This figure is sensitive to 1) PDS date; 2) SMA inception date; and 3) Methodology for calculating the allocation/weighting of underlying investments. See Additional Information segment of this table",
  [SubHeader.CashFee]:
    "Fees charged and interest retained on the cash held within the model's cash allocation",
  [SubHeader.NetOfRebates]:
    "Have fund manager rebates reduced the disclosed underlying investment management costs (indirect costs)? Some models may be eligible for rebates from fund managers and this benefit is paid to investors. Some platforms report their indirect costs gross or net of rebates. Where a platform has disclosed the indirect costs gross of rebates, the indirect costs may appear more expensive compared to a platform that disclosed the indirect costs net of rebates",
  [SubHeader.TotalPerformanceFees]:
    "The sum of model manager and underlying investment performance fees",
  [SubHeader.ModelManager]:
    "Performance fees charged at the model level, calculated as the average of annual performance fees charged in the last 5 years (or annual average since inception if the model is less than 5-years old)",
  [SubHeader.UnderlyingPerformanceFees]:
    "Weighted average of performance fees charged by the managed investments held within the model, taking the 5-year average performance fee for each investment (or annual average since inception for investments less than 5 years old). Performance costs are not deducted from investors' cash accounts as a fee, and instead reduce the value of underlying investments or their income. Note: This figure is sensitive to 1) PDS date; 2) SMA inception date; and 3) Methodology for calculating the allocation/weighting of underlying investments. See Additional Information segment of this fee table",
  [SubHeader.TotalTransactionCosts]:
    "The sum of percentage-based transaction costs plus dollar-based transaction costs divided by allocation to model",
  [SubHeader.UnderlyingTransactionCosts]:
    "Weighted average of net transaction costs reported by the underlying investments held within the model. Indirect costs are not deducted from investors' cash accounts as a fee, and instead reduce the value of underlying investments or their income. Note: This figure is sensitive to 1) PDS date; 2) SMA inception date; and 3) Methodology for calculating the allocation/weighting of underlying investments. See Additional Information segment of this fee table",
  [SubHeader.BuySellSpreads]:
    "Buy/sell spreads incurred for purchasing managed funds within the model over a 12-month period. Calculated as the sum of spreads from all buy and sell transactions for managed funds",
  [SubHeader.PlatformPercent]:
    "Percentage-based fees charged by the platform for the buy side and sell side transactions in the model over a 12-month period. This includes, but is not limited to, brokerage and any settlement fees. Calculated as the [cumulative value of all trades subject to a transaction cost] multiplied by the respective [%-based fee]",
  [SubHeader.PlatformDollar]:
    "Dollar-based fees charged by the platform for the buy side and sell side transactions in the model over a 12-month period. This includes, but is not limited to, brokerage and any settlement fees. Calculated as the [cumulative number of trades subject to a transaction cost] multiplied by the respective [flat dollar fee]",
  [SubHeader.Netting]:
    "The platform passes all trade netting benefits to investors. Investors can benefit from reduced transaction costs if the platform can net trades between investors or models, thereby partially or entirely mitigating the need for some orders to go to market. For example, Investor 1 is selling 100 BHP stocks while Investor 2 is buying 110 BHP, meaning Investor 1 avoids all transaction costs for the order while Investor 2 only incurs costs for buying 10 BHP stocks",
  [SubHeader.ModelTurnover]:
    "The value of the [cumulative buys and sells within a model over the 12-months to the PDS date] divided by the [net value of all the assets in the portfolio]. If the model is under 12-months old, then this represents the manager's target turnover for the first 12-month period. Model turnover has a direct impact on transaction cost estimates and is sensitive to the PDS date",
  [SubHeader.Discount]:
    "Some or all holdings in this portfolio lead to a reduction in platform administrative fees",
  [SubHeader.Type]: "Level or type of discount to platform admin fees",
  [SubHeader.FeeMethod]:
    "The allocation of underlying investments changes throughout the year. When reporting fees and costs relating to underlying investments, methodologies used by platforms may include: 1) Average daily holding size of underlying investments over the 12 month period; 2) Average weekly holding size; 3) Average monthly holding size; 4) Point in time, such as the date of the PDS date; and 5) Other methodologies",
  [SubHeader.Incepted]:
    "Platforms' fee reporting methodologies vary for models that were under 12-months old when the PDS was issued, which can impact management fees, performance fees and transaction costs. Methodologies to calculate a per annum fee include reporting based on a point-in-time investment allocation and expected turnover assumptions, or extrapolating allocations and transactions based on activity since inception. The methodology chosen can explain some of the fee differences between platforms",
  [SubHeader.MinInvestment]:
    "Minimum $ value that can be invested in the model",
  [SubHeader.PercentCash]:
    "Average allocation to platform cash in the 12-months to the PDS date",
  [SubHeader.PercentIntListed]:
    "Average allocation to securities listed on an international exchange in the 12-months to the PDS date",
  [SubHeader.Super]: "Whether the model is available to Super/Pension members",
  [SubHeader.IDPS]: "Whether the model is available to IDPS investors",
  [SubHeader.Available]:
    "Whether the model is available to all users of the platform or those from select licensees or practices",
};

export const initialFilterCalculatedInvestment: FilterCalculatedInvestmentDTO =
  {
    investmentSize: DefaultInvestmentSize,
    filters: {
      favourites: SMAListFavouriteFilter.All,
      currentlySelected: SMAListCurrentlySelectedFilter.All,
      minimumInvestmentSize: SMAListMinimumInvestmentSizeFilter.All,
      platforms: [],
    },
  };
