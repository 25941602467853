import { SVGProps } from "react";

const DollarSVG = ({
  color = "#25324B",
  ...others
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      d="M8 9H7V5h2a1 1 0 0 1 1 1 1 1 0 0 0 2 0 3 3 0 0 0-3-3H7V1a1 1 0 0 0-2 0v2H4a4 4 0 0 0 0 8h1v4H3a1 1 0 0 1-1-1 1 1 0 1 0-2 0 3 3 0 0 0 3 3h2v2a1 1 0 1 0 2 0v-2h1a4 4 0 1 0 0-8ZM5 9H4a2 2 0 1 1 0-4h1v4Zm3 6H7v-4h1a2 2 0 1 1 0 4Z"
      fill={color}
    />
  </svg>
);

export default DollarSVG;
