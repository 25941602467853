import {
  SHButtonSkeleton,
  SHDividerSkeleton,
} from "@components/skeletons/design-systems";
import SHSkeleton from "../../design-systems/sh-skeleton";
import { SHStack } from "../../design-systems/sh-stack";

interface SHTabSkeletonProps {
  tabNumber: number;
}
export const SHTabSkeleton = ({ tabNumber = 5 }: SHTabSkeletonProps) => {
  return (
    <SHStack spacing={"10px"}>
      <SHStack direction={"row"} alignItems={"center"} spacing={"20px"}>
        <SHButtonSkeleton height={38} width={100} />
        {tabNumber > 0 &&
          Array.from(Array(tabNumber - 1).keys()).map((key) => (
            <SHSkeleton key={key} height={24} width={80} />
          ))}
      </SHStack>
      <SHDividerSkeleton />
      <SHStack
        display={"grid"}
        gridTemplateColumns={"6.6fr 3.25fr"}
        paddingTop={"15px"}
        marginTop={"25px"}
        gap={"15px"}
      >
        <SHStack spacing={3}>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"40%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"100%"} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"40%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"100%"} />
          </SHStack>
        </SHStack>
        <SHStack spacing={3}>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"60%"} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"60%"} />
          </SHStack>
        </SHStack>
      </SHStack>
    </SHStack>
  );
};
