import { styled } from "@mui/material";
import { forwardRef } from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToolbarEditor } from "./ToolbarEditor";

export interface CloneReactQuillProps extends Omit<ReactQuillProps, "theme"> {
  quillTheme?: string;
}
const CloneReactQuill = forwardRef(
  ({ quillTheme, ...others }: CloneReactQuillProps, ref: any) => (
    <ReactQuill {...others} theme={quillTheme} ref={ref} />
  ),
);

export const ReactQuillStyled = styled(CloneReactQuill)(({ theme }) => ({
  "& .ql-container .ql-editor": {
    "& *": {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Epilogue, sans-serif",
      fontStretch: "100%",
      color: theme.palette.text.primary,
    },
    "& strong, & strong *": {
      fontWeight: 700,
    },
  },
  "& .ql-disabled .ql-editor *": { color: theme.palette.text.disabled },
  "& .ql-container.ql-snow": {
    border: `1px solid ${theme.palette.secondary[100]}`,
  },
  "& .ql-editor.ql-blank::before": {
    color: "currentcolor",
    opacity: "0.42",
    fontStyle: "normal",
  },
}));
export const ToolbarEditorStyled = styled(ToolbarEditor)(({ theme }) => ({
  "& .ql-toolbar": {
    fontFamily: "Epilogue, sans-serif",
    border: `1px solid ${theme.palette.secondary[100]}`,
    borderTopColor: theme.palette.text.third,
  },
  "& .ql-snow.ql-toolbar button": {
    "&.ql-active, &:hover, &:focus": {
      "& .ql-stroke": {
        stroke: theme.palette.primary.main,
      },
      "& .ql-fill": {
        fill: theme.palette.primary.main,
      },
    },
  },
  "& .ql-letterCounter": {
    float: "right !important",
    width: "auto !important",
    wordWrap: "break-word",
  },
  "& .ql-snow .ql-stroke": {
    strokeWidth: 1,
  },
}));
