export enum AdviserFirmStatus {
  Active = "Active",
  Disabled = "Disabled",
  Archived = "Archived",
}

export enum AdviserUserStatusAction {
  Disable = "Disable",
  Enable = "Enable",
  Archive = "Archive",
  Unarchive = "Unarchive",
}

export enum AdviserSubscriptionStatus {
  Trial = "Trial",
  Subscribed = "Subscribed",
  Expired = "Expired",
  Expiring = "Expiring",
}
