import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";

export const TabLink = styled(NavLink)(({ theme }) => ({
  display: "flex",
  textDecoration: "none",
  color: theme.palette.text.primary,
  opacity: 1,
  "&>.MuiButtonBase-root": {
    opacity: 1,
  },
}));
