export class SubscriptionDTO {
  licencePaidCount: number = 0;
  licenceFreeCount: number = 0;
  totalLicences: number = 0;
  totalUsedLicences: number = 0;
  subscriptionBegin: Date | null = null;
  subscriptionEnd: Date | null = null;
}

export class UserSubscriptionDTO {
  free: number = 0;
  allocatedFree: number = 0; // It's mean remaining licence
  paid: number = 0;
  unallocatedPaid: number = 0;
  originalUnallocatedPaid: number = 0;
  remainingDays: number = 0;
  subscriptionEnd: Date | null = null;
  renewalUsers: RenewalUserSubscriptionDTO[] | null = null;
}

export class RenewalUserSubscriptionDTO {
  id: string = "";
  name: string = "";
  isRenew: boolean = true;
  applyFreeLicence? = false;
}

export class AdditionalCostDTO {
  total: number = 0;
  prorationDate: Date | null = null;
}

export class UpdateRenewalDTO {
  unallocatedPaid: number = 0;
  renewalUsers: RenewalUserSubscriptionDTO[] = [];
}

export class UpdateUserSubscriptionDTO {
  prorationDate: Date | null = null;
  quantity: number = 0;
  paymentMethodId: string = "";
}

export class SubscriptionPricingInfoDTO {
  mainPrice: number = 0;
  mainPricePlusGST: number = 0;
  isAppliedDiscount: boolean = false;
  fullPrice: number = 0;
  licenseeName: string = "";
}
