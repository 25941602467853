import { APIExtRoutes } from "@constants/routes";
import {
  ConfigurationDraftStatusDTO,
  ConfigurationGroup,
  ConfigurationSection,
  HistoryRelease,
} from "@models/configuration";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getConfigDraftStatus,
  getConfigGroup,
  getHistoryRelease,
} from "@services/configuration";
import { CancelToken } from "axios";

interface ConfigurationStore {
  configurationGroupsData: ConfigurationGroup[];
  configurationGroupData?: ConfigurationGroup;
  configurationDraftStatus?: ConfigurationDraftStatusDTO;
  configurationHistoryReleases: HistoryRelease[];
  configurationUI: {
    isEditMode?: boolean;
    isLoading?: boolean;
    selectedGroup?: string;
    selectedSection?: ConfigurationSection | undefined;
    selectedSubSection?: ConfigurationSection | undefined;
    isShowDialog?: boolean;
    isKickOut?: boolean;
    isLoadingEditMode?: boolean;
    beingKickedBy?: string;
    currentEditor?: string;
  };
}
const initialState: ConfigurationStore = {
  configurationGroupsData: [],
  configurationGroupData: undefined,
  configurationDraftStatus: undefined,
  configurationHistoryReleases: [],
  configurationUI: {
    isEditMode: false,
    isLoading: false,
    selectedGroup: undefined,
    selectedSection: undefined,
    selectedSubSection: undefined,
    isShowDialog: false,
    isKickOut: false,
    isLoadingEditMode: false,
    beingKickedBy: "",
    currentEditor: "",
  },
};

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    updateConfigurationGroups: (
      state,
      action: PayloadAction<ConfigurationGroup[]>,
    ) => ({
      ...state,
      configurationGroupsData: action.payload,
    }),
    updateConfigurationGroup: (
      state,
      action: PayloadAction<ConfigurationGroup | undefined>,
    ) => ({
      ...state,
      configurationGroupData: action.payload,
    }),
    updateDraftState: (
      state,
      action: PayloadAction<ConfigurationDraftStatusDTO | undefined>,
    ) => {
      state.configurationDraftStatus = action.payload;
    },
    updateEditMode: (state, action: PayloadAction<boolean>) => {
      state.configurationUI.isEditMode = action.payload;
    },
    updateHistoryReleases: (
      state,
      action: PayloadAction<HistoryRelease[]>,
    ) => ({
      ...state,
      configurationHistoryReleases: action.payload,
    }),
    updateSelectedGroup: (state, action: PayloadAction<string | undefined>) => {
      state.configurationUI.selectedGroup = action.payload;
    },
    updateSelectedSection: (
      state,
      action: PayloadAction<ConfigurationSection | undefined>,
    ) => {
      state.configurationUI.selectedSection = action.payload;
    },
    updateSelectedSubSection: (
      state,
      action: PayloadAction<ConfigurationSection | undefined>,
    ) => {
      state.configurationUI.selectedSubSection = action.payload;
    },
    updateShowDialog: (state, action: PayloadAction<boolean>) => {
      state.configurationUI.isShowDialog = action.payload;
    },
    updateKickOut: (state, action: PayloadAction<boolean>) => {
      state.configurationUI.isKickOut = action.payload;
    },
    updateLoadingEditMode: (state, action: PayloadAction<boolean>) => {
      state.configurationUI.isLoadingEditMode = action.payload;
    },
    updateBeingKickedBy: (state, action: PayloadAction<string>) => {
      state.configurationUI.beingKickedBy = action.payload;
    },
    updateCurrentEditor: (state, action: PayloadAction<string>) => {
      state.configurationUI.currentEditor = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Get Draft Status
    builder.addCase(loadStatusDraft.pending, (state) => {
      state.configurationUI.isLoading = true;
    });
    builder.addCase(loadStatusDraft.fulfilled, (state, action) => {
      state.configurationUI.isLoading = false;
      state.configurationDraftStatus = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
    });
    builder.addCase(loadStatusDraft.rejected, (state) => {
      state.configurationUI.isLoading = false;
      state.configurationDraftStatus = undefined;
    });

    //Get Configuration Group
    builder.addCase(loadConfigurationGroup.fulfilled, (state, action) => {
      state.configurationGroupData = action.payload.isSuccess
        ? action.payload?.data
        : undefined;
    });

    builder.addCase(loadHistoryRelease.fulfilled, (state, action) => {
      const historyReleases = action.payload?.data ?? [];
      state.configurationHistoryReleases = action.payload.isSuccess
        ? historyReleases
        : [];
    });
  },
});

export const {
  updateConfigurationGroups,
  updateConfigurationGroup,
  updateDraftState,
  updateEditMode,
  updateHistoryReleases,
  updateSelectedGroup,
  updateSelectedSection,
  updateSelectedSubSection,
  updateShowDialog,
  updateKickOut,
  updateLoadingEditMode,
  updateBeingKickedBy,
  updateCurrentEditor,
} = configurationSlice.actions;

export const loadStatusDraft = createAsyncThunk(
  APIExtRoutes.configurationDraftStatus,
  async (cancelToken?: CancelToken) => {
    return await getConfigDraftStatus(cancelToken);
  },
);

export const loadConfigurationGroup = createAsyncThunk(
  APIExtRoutes.configurationGroup,
  async (groupId: string) => {
    return await getConfigGroup(groupId);
  },
);

export const loadHistoryRelease = createAsyncThunk(
  APIExtRoutes.configurationHistoryReleases,
  async () => {
    return await getHistoryRelease();
  },
);

export default configurationSlice.reducer;
