import { SHChip, SHChipProps } from "@components/design-systems";
import { PostStatus } from "@models/news-and-updates/enums/status";
import { UserStatus } from "@models/users/enums/status";
import { useMemo } from "react";
import { FeatureTemplateStatus } from "@models/feature-template/enums/status";
import { SupplierStatus } from "@models/supplier/enums/status";
import { AdviserFirmStatus } from "@models/practice/enums/status";
import { ClientStatus, ReviewStatus } from "@models/reviews/enums/status";
import { AnalysisStatus } from "@models/platform-analysis/enums/status";
import { ProductStatus } from "@models/product/enums/status";
import { RateCardStatus } from "@models/rate-card/entities/rateCard";
import { ManagedAccountStatus } from "@models/managed-accounts/enums/status";
export interface StatusBadgeProps extends SHChipProps {
  status:
    | UserStatus
    | SupplierStatus
    | AdviserFirmStatus
    | ProductStatus
    | AnalysisStatus
    | ReviewStatus
    | PostStatus
    | RateCardStatus
    | FeatureTemplateStatus
    | ClientStatus
    | ManagedAccountStatus
    | null;
}
export const StatusBadge = ({ status, ...props }: StatusBadgeProps) => {
  const color = useMemo(() => {
    switch (status) {
      case UserStatus.Active:
      case SupplierStatus.Active:
      case AdviserFirmStatus.Active:
      case ProductStatus.Active:
      case PostStatus.Published:
      case RateCardStatus.Active:
      case FeatureTemplateStatus.Active:
      case AnalysisStatus.Completed:
      case ReviewStatus.Completed:
      case ClientStatus.Active:
      case ManagedAccountStatus.Completed:
        return "success";
      case UserStatus.Archived:
      case SupplierStatus.Archived:
      case AdviserFirmStatus.Archived:
      case ProductStatus.Archived:
      case PostStatus.Archived:
      case RateCardStatus.Archived:
      case FeatureTemplateStatus.Archived:
      case AnalysisStatus.Archived:
      case ReviewStatus.Archived:
      case ClientStatus.Archived:
      case ManagedAccountStatus.Archived:
        return "error";
      case UserStatus.Disabled:
      case SupplierStatus.Disabled:
      case AdviserFirmStatus.Disabled:
      case ProductStatus.Disabled:
        return "warning";
      case UserStatus.Pending:
        return "info";
      case AnalysisStatus.InProgress:
      case ReviewStatus.InProgress:
      case ManagedAccountStatus.InProgress:
        return "primary";

      default:
        return "default";
    }
  }, [status]);
  return (
    <SHChip
      label={status}
      variant="outlined"
      color={color}
      style={{ width: "90px" }}
      {...props}
    />
  );
};
