import { Components, Theme } from "@mui/material";
export const muiPaginationTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiPagination"> => ({
  MuiPagination: {
    styleOverrides: {
      ul: {
        "& .MuiPaginationItem-root": {
          fontWeight: 500,
          fontSize: "16px",
          color: theme.palette.text.primary,
          lineHeight: 1,
          paddingTop: "3px",
        },
        "& .MuiPaginationItem-root.MuiPaginationItem-previousNext": {
          paddingTop: 0,
        },
        "& .MuiPaginationItem-root.Mui-selected": {
          backgroundColor: "transparent",
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.text.primary,
          fontWeight: 600,
        },
        "& .MuiPaginationItem-root.Mui-selected:hover,& .MuiPaginationItem-root.Mui-selected.Mui-focusVisible":
          {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
          },
      },
    },
  },
});
