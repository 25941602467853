import { Components, Theme } from "@mui/material";

export const muiMenuTheme = (
    theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiMenu"> => ({
    MuiMenu: {
        styleOverrides: {
            paper: {
                "&>.MuiMenu-list": {
                    padding: 0,
                },
            },
        },
    },
});
