import { SHFilterOption } from "@components/design-systems";
import { UserType } from "@models/auth";
import { UserStatus } from "@models/users/enums/status";

export const StatusFilterOptions: SHFilterOption[] = [
  {
    label: "Active",
    value: UserStatus.Active,
  },
  {
    label: "Disabled",
    value: UserStatus.Disabled,
  },
  {
    label: "Archived",
    value: UserStatus.Archived,
  },
  {
    label: "Pending",
    value: UserStatus.Pending,
  },
];
export const DefaultStatusFilterValue = [
  UserStatus.Active,
  UserStatus.Disabled,
  UserStatus.Pending,
];
export const SupplierUserTypeOptions = [
  {
    label: "Basic user",
    value: UserType.SupplierBasic,
  },
  {
    label: "Owner",
    value: UserType.SupplierPremiumOwner,
  },
  {
    label: "Standard user",
    value: UserType.SupplierPremiumStandard,
  },
  {
    label: "Sales user",
    value: UserType.SupplierBdmSale,
  },
];
export const AdminUserTypeFilterOptions = [
  {
    label: "Super administrator ",
    value: UserType.SuperAdmin,
  },
  {
    label: "Administrator ",
    value: UserType.StandardAdmin,
  },
];
