import {
  SHCheckbox,
  SHFilterOption,
  SHMenuItem,
  SHMenuList,
  SHPopover,
  SHStack,
  SHTableCell,
  SHTypography,
} from "@components/design-systems";
import { SHFormControlLabel } from "@components/design-systems/sh-form-control-label";
import {
  ArrowDownSVG,
  FilterClearSVG,
  FilterSVG,
  SortAscSVG,
  SortDescSVG,
} from "@components/svgs";
import { FormGroup, IconButton, useTheme } from "@mui/material";
import { flexRender, Header } from "@tanstack/react-table";
import { hexToRGBA } from "@utils/color";
import React from "react";
import { useMemo } from "react";

export const SHHeadCell = ({
  header,
  isLastedColumn,
}: {
  header: Header<any, unknown>;
  isLastedColumn: boolean;
}) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "sh-head-cell-popover" : undefined;
  const { column } = header;

  const filterData: SHFilterOption[] = useMemo(
    () => (header.column.columnDef.meta as any)?.filterData ?? [],
    // eslint-disable-next-line
    [column.columnDef.meta],
  );
  const filterValue = column.getFilterValue() as string[] | undefined;
  const isCanFilter = column.getCanFilter();
  const isCanSort = column.getCanSort();
  const isSorted = column.getIsSorted();
  const isCanShowMenu = isCanFilter || isCanSort;
  const isFiltered =
    column.getIsFiltered() && filterValue?.length !== filterData?.length;
  const isFilterAll =
    !column.getIsFiltered() || filterValue?.length === filterData?.length;
  const filterDataValues = filterData?.map(
    (item: { label: string; value: any }) => item.value,
  );
  return (
    <>
      <SHTableCell
        style={header.column.columnDef.meta?.style}
        aria-describedby={id}
        onClick={(event: any) => {
          if (!isCanShowMenu) return;
          setAnchorEl(event.currentTarget);
        }}
        sx={{
          ...(header.column.columnDef.meta?.sx ?? {}),
          ...(isCanShowMenu
            ? {
                cursor: "pointer",
                "&:hover": {
                  opacity: "0.8",
                },
              }
            : {}),
        }}
      >
        <SHStack spacing={1} direction="row" alignItems={"center"}>
          <SHTypography
            variant="body1"
            colorVariant="third"
            sx={{ lineHeight: "120%" }}
          >
            {header.isPlaceholder
              ? null
              : flexRender(column.columnDef.header, header.getContext())}
          </SHTypography>
          {isCanSort && (
            <IconButton
              sx={{ padding: "4px" }}
              data-testid="sh-head-cell-sort-btn"
              onClick={(event: any) => {
                event.stopPropagation();
                if (isSorted === "desc") {
                  column.clearSorting();
                  return;
                }
                column.toggleSorting(!isSorted ? false : true);
              }}
            >
              <SHStack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  transition: "all ease-in-out 0.2s",
                  transform: `rotate(${isSorted !== "desc" ? 0 : 180}deg)`,
                }}
              >
                <ArrowDownSVG
                  color={isSorted ? palette.primary.main : undefined}
                  width={"16px"}
                  height={"16px"}
                  transform="rotate(180)"
                />
              </SHStack>
            </IconButton>
          )}
          {isCanFilter && (
            <IconButton
              data-testid="sh-head-cell-filter-btn"
              sx={{ padding: "4px" }}
              onClick={(event: any) => {
                if (isFiltered) {
                  event.stopPropagation();
                  column.setFilterValue(filterDataValues);
                }
              }}
            >
              {isFiltered ? (
                <FilterClearSVG width={"16px"} height={"16px"} />
              ) : (
                <FilterSVG width={"15px"} height={"15px"} />
              )}
            </IconButton>
          )}
        </SHStack>
      </SHTableCell>
      <SHPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isLastedColumn ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isLastedColumn ? "right" : "left",
        }}
        PaperProps={{
          sx: {
            boxSizing: "border-box",
            boxShadow: "none",
            borderWidth: 1,
            borderColor: palette.divider,
            borderStyle: "solid",
            marginLeft: "-1px",
            marginTop: "-1px",
            borderRadius: 0,
            backgroundColor: hexToRGBA(palette.common.white, 0.95),
            width: "246px",
            filter: "drop-shadow(1px 1px 2px rgba(65, 73, 89, 0.25))",
          },
        }}
      >
        {isCanSort && (
          <SHMenuList sx={{ padding: 0 }} data-testid="sh-head-cell-menu-list">
            <SHMenuItem
              sx={{ height: "38px" }}
              selected={isSorted === "asc"}
              data-testid="sh-head-sort-asc-button"
              onClick={(event) => {
                column.clearSorting();
                column.toggleSorting(false);
              }}
            >
              <SHStack spacing={1} direction="row" alignItems="center">
                <SortAscSVG />
                <SHTypography variant="body1">Sort ascending</SHTypography>
              </SHStack>
            </SHMenuItem>
            <SHMenuItem
              data-testid="sh-head-sort-desc-button"
              sx={{ height: "38px" }}
              selected={isSorted === "desc"}
              onClick={(event) => {
                column.clearSorting();
                column.toggleSorting(true);
              }}
            >
              <SHStack spacing={1} direction="row" alignItems="center">
                <SortDescSVG />
                <SHTypography variant="body1">Sort descending</SHTypography>
              </SHStack>
            </SHMenuItem>
          </SHMenuList>
        )}
        {isCanFilter && (
          <SHStack
            sx={{
              paddingLeft: 2,
              paddingTop: 0.75,
              paddingRight: 2,
              paddingBottom: 0.75,
              borderWidth: 0,
              borderTopWidth: isCanSort ? 1 : 0,
              borderColor: palette.divider,
              borderStyle: "solid",
            }}
          >
            <SHStack spacing={1} direction="row" alignItems="center">
              <FilterSVG />
              <SHTypography variant="body1">Filter</SHTypography>
            </SHStack>
            <FormGroup sx={{ padding: "4px 2px" }}>
              <SHFormControlLabel
                size="small"
                control={
                  <SHCheckbox
                    checked={isFilterAll}
                    size={"small"}
                    onClick={() => {
                      column.setFilterValue(
                        !isFilterAll ? filterDataValues : [],
                      );
                    }}
                  />
                }
                data-testid="sh-head-filter-all-button"
                label="All"
              />
              {filterData.map((filterItem, index: number) => (
                <SHFormControlLabel
                  key={index}
                  size="small"
                  control={
                    <SHCheckbox
                      checked={
                        !isFiltered ||
                        (filterValue && filterValue.includes(filterItem.value))
                      }
                      size={"small"}
                      data-testid="sh-head-filter-option"
                      onClick={() => {
                        let newList = filterValue ? [...filterValue] : [];
                        if (
                          filterValue &&
                          filterValue.includes(filterItem.value)
                        ) {
                          newList = newList.filter(
                            (item) => item !== filterItem.value,
                          );
                        } else {
                          newList.push(filterItem.value);
                        }
                        column.setFilterValue(newList);
                      }}
                    />
                  }
                  label={filterItem.label}
                />
              ))}
            </FormGroup>
          </SHStack>
        )}
      </SHPopover>
    </>
  );
};
