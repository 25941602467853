import { APIExtRoutes } from "@constants";
import {
  InvestmentQueryOptions,
  InvestmentSelectionDTO,
} from "@models/managed-accounts/entities/step/setup";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getInvestmentFavourited,
  getInvestmentSelections,
  putInvestmentFavourited,
} from "@services/investment/investmentService";

export interface InvestmentStore {
  ui: {
    isLoading: boolean;
  };
  originalInvestmentFavourited: InvestmentSelectionDTO[];
  originalInvestmentSelections: InvestmentSelectionDTO[];
  investmentSelections: InvestmentSelectionDTO[];
  investmentFavourited: InvestmentSelectionDTO[];
  selectedInvestmentCode?: string[];
}
const initialState: InvestmentStore = {
  ui: {
    isLoading: false,
  },
  originalInvestmentSelections: [],
  originalInvestmentFavourited: [],
  investmentSelections: [],
  investmentFavourited: [],
  selectedInvestmentCode: [],
};

const investmentSlice = createSlice({
  name: "investment",
  initialState,
  reducers: {
    updateOriginalInvestmentSelectionStore: (
      state,
      action: PayloadAction<InvestmentSelectionDTO[]>,
    ) => {
      state.originalInvestmentSelections = action.payload;
    },

    updateOriginalInvestmentFavouritedStore: (
      state,
      action: PayloadAction<InvestmentSelectionDTO[]>,
    ) => {
      state.originalInvestmentFavourited = action.payload;
    },

    updateInvestmentSelectionStore: (
      state,
      action: PayloadAction<InvestmentSelectionDTO[]>,
    ) => {
      state.investmentSelections = action.payload;
    },

    updateInvestmentFavouritedStore: (
      state,
      action: PayloadAction<InvestmentSelectionDTO[]>,
    ) => {
      state.investmentFavourited = action.payload;
    },

    updateSelectedInvestmentCodes: (state, action: PayloadAction<string[]>) => {
      state.selectedInvestmentCode = action.payload;
    },

    resetInvestmentStore: (state, action: PayloadAction) => {
      state.ui = initialState.ui;
      state.investmentSelections = [];
      state.investmentFavourited = [];
      state.selectedInvestmentCode = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getInvestmentSelectionsThunk.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(getInvestmentSelectionsThunk.fulfilled, (state, action) => {
      if(action.payload.isSuccess && action.payload.data){
        state.investmentSelections = action.payload.data
        state.originalInvestmentSelections = action.payload.data
      }
      state.ui.isLoading = false;
    });
    builder.addCase(getInvestmentSelectionsThunk.rejected, (state, action) => {
      state.ui.isLoading = false;
    });

    builder.addCase(getInvestmentFavouritedThunk.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(getInvestmentFavouritedThunk.fulfilled, (state, action) => {
      if(action.payload.isSuccess && action.payload.data){
        state.investmentFavourited = action.payload.data
        state.originalInvestmentFavourited = action.payload.data
      }
      state.ui.isLoading = false;
    });
    builder.addCase(getInvestmentFavouritedThunk.rejected, (state, action) => {
      state.ui.isLoading = false;
    });

    builder.addCase(putInvestmentFavouritedThunk.pending, (state, action) => {
      state.ui.isLoading = true;
    });
    builder.addCase(putInvestmentFavouritedThunk.fulfilled, (state, action) => {
      state.ui.isLoading = false;
    });
    builder.addCase(putInvestmentFavouritedThunk.rejected, (state, action) => {
      state.ui.isLoading = false;
    });
  },
});

// Thunk functions
export const getInvestmentSelectionsThunk = createAsyncThunk(
  APIExtRoutes.investmentsSelections,
  async (queryOptions: InvestmentQueryOptions) => {
    return await getInvestmentSelections(queryOptions);
  },
);

export const getInvestmentFavouritedThunk = createAsyncThunk(
  APIExtRoutes.investmentsFavourited,
  async (queryOptions: InvestmentQueryOptions) => {
    return await getInvestmentFavourited(queryOptions);
  },
);

export const putInvestmentFavouritedThunk = createAsyncThunk(
  `${APIExtRoutes.investmentsFavourited}/put`,
  async (shCodes: string[]) => {
    return await putInvestmentFavourited(shCodes);
  },
);

export const {
  updateOriginalInvestmentSelectionStore,
  updateOriginalInvestmentFavouritedStore,
  updateInvestmentSelectionStore,
  updateInvestmentFavouritedStore,
  updateSelectedInvestmentCodes,
  resetInvestmentStore,
} = investmentSlice.actions;

export default investmentSlice.reducer;
