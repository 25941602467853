import { SVGProps } from "react";

export const LogoutSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={22}
        height={22}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.138 17.471a.667.667 0 0 0 .195-.471v-2.667a.667.667 0 0 1 1.334 0V17a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h10.667a2 2 0 0 1 2 2v2.667a.667.667 0 0 1-1.334 0V5a.667.667 0 0 0-.666-.667H2A.667.667 0 0 0 1.333 5v12a.667.667 0 0 0 .667.667h10.667c.177 0 .346-.07.471-.196Zm8.146-6.216a.666.666 0 0 1-.145.217l-4 4a.668.668 0 0 1-.944-.944l2.862-2.861H7.333a.667.667 0 0 1 0-1.334h11.724l-2.862-2.861a.667.667 0 1 1 .944-.944l4 4a.666.666 0 0 1 .145.727Z"
            fill="#25324B"
        />
    </svg>
);
