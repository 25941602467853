import { InformationButton } from "@components/buttons/information";
import { SHBox, SHStack, SHTypography } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { generateFeatureName } from "@utils/text-format";
import { useMemo } from "react";
export interface FeatureItemProps {
  feature?: any;
  isDeselect?: boolean;
  subGroupId?: string;
  handleOnSelectDeselect?: (
    subGroupId?: string,
    featureId?: string,
    isSelectFeature?: boolean,
  ) => void;
}
export const FeatureItem = ({
  feature,
  isDeselect,
  subGroupId,
  handleOnSelectDeselect,
  ...others
}: FeatureItemProps) => {
  const { palette } = useTheme();

  const colors = useMemo(() => {
    if (feature.isSelected)
      return {
        background: palette.success[50],
        border: palette.success[100],
        text: palette.text.primary,
      };
    return {
      background: palette.common.white,
      border: palette.common.black,
      text: palette.text.third,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature.isSelected]);

  const featureName = generateFeatureName(
    feature?.name ?? "",
    feature?.description,
  );

  return (
    <SHStack
      direction={"row"}
      alignItems={"flex-start"}
      sx={{
        gap: "5px",
        padding: "10px 15px",
        boxSizing: "border-box",
        backdropFilter: "blur(2px)",
        background: `${colors?.background}`,
        border: `1px solid ${colors?.border}`,
        cursor: "pointer",
        boxShadow: `3px 3px 4px ${hexToRGBA(palette.common.black, 0.25)}`,
        transition: "all .1s linear",
        "&:hover": {
          border: `1px solid ${palette.primary[800]}`,
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: `inset 3px 3px 4px ${hexToRGBA(
            palette.common.black,
            0.25,
          )}`,
        },
      }}
      onClick={() =>
        typeof handleOnSelectDeselect === "function"
          ? handleOnSelectDeselect(subGroupId, feature?.id, isDeselect)
          : {}
      }
      {...others}
    >
      <SHTypography
        variant="body2"
        lineHeight={"120%"}
        sx={{
          color: colors.text,
          padding: 0,
        }}
      >
        {featureName.first}
        {feature?.description && (
          <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
            {featureName.last}
            <InformationButton
              content={feature.description}
              margin={"-1px 0px 2px 4px !important"}
            />
          </SHBox>
        )}
      </SHTypography>
    </SHStack>
  );
};
