import {
  SHBox,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";

export interface CategoriesLabelProps extends SHStackProps {
  icon: React.ReactNode;
  label: string;
}

export const CategoriesLabel = ({
  icon,
  label,
  ...others
}: CategoriesLabelProps) => {
  return (
    <SHStack 
      direction={"row"} 
      justifyContent={"space-between"} 
      spacing={0.5} 
      {...others}
    >
      <SHBox>{icon}</SHBox>
      <SHTypography 
        sx={{
          marginTop: "auto !important", 
          marginBottom: "auto !important"
        }}
        variant="subtitle1"
      >
        {label}
      </SHTypography>
    </SHStack>
  );
};
