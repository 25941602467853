import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { useTheme } from "@mui/material";
import { PDFDefaultPlatformLogoS3 } from "@pages/platform-analysis/components/buttons/export-pdf/config";
import { useStyles } from "@pages/platform-analysis/components/buttons/export-pdf/styles";
import { generatePDFImageIconUrl } from "@pages/platform-analysis/components/buttons/export-pdf/util";
import { Image, Text, View } from "@react-pdf/renderer";
import { isEmpty } from "lodash";

export interface FeeSubProductCardProps {
  subProduct: FeeSubProductDTO;
  isActive?: boolean;
}
export const PDFFeeSubProductCard = ({
  subProduct,
  isActive,
}: FeeSubProductCardProps) => {
  const { palette } = useTheme();

  const subProductName = `${subProduct.name}${
    !subProduct.isLatest && subProduct.outdatedAt
      ? ` (Outdated)`
      : ""
  }`;
  const { typographyStyle } = useStyles();
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        maxWidth: 140
      }}
    >
      {subProduct?.productLogo?.url ? (
        <Image
          src={generatePDFImageIconUrl(subProduct?.productLogo?.url)}
          style={{ width: 25, height: 25, objectFit: "contain" }}
        />
      ) : (
        <Image
          src={PDFDefaultPlatformLogoS3}
          style={{ width: 25, height: 25 }}
        />
      )}

      <View style={{ justifyContent: "space-between" }}>
        <Text
          style={{
            ...typographyStyle.body2,
            lineHeight: 1.2,
            maxWidth: 70,
          }}
        >
          {`${subProduct.productName} `}
          {!isEmpty(subProduct?.warning) && (
            <Text
              style={{
                ...typographyStyle.body2,
                color: palette.error.main,
              }}
            >
              {`*`}
            </Text>
          )}
        </Text>
        <Text
          style={{
            ...typographyStyle.body2,
            lineHeight: 1.1,
            maxWidth: 90,
            fontWeight: "bold",
          }}
        >
          {subProductName}
        </Text>
      </View>
    </View>
  );
};
