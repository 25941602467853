import {
  SHButton,
  SHContainer,
  SHStack,
  SHTabs,
  SHTypography,
} from "@components/design-systems";
import { useEditConfiguration } from "@hooks/useEditConfiguration";
import { TopBar } from "@layouts/top-bar";
import { DiscardConfigurationButton } from "@pages/configuration/components/discard-draft";
import { PublishConfigurationButton } from "@pages/configuration/components/publish";
import { SaveDraftConfigurationButton } from "@pages/configuration/components/save-draft";
import { RootState } from "@redux/store";
import { updateSelectedGroup } from "@redux/slices/configuration";
import { useAppDispatch } from "@redux/store";
import { useSelector } from "react-redux";
import ConfigurationView from "./ConfigurationView";
import { ConfirmKickOutDialog } from "./dialog";
import { ConfigurationLockEditingKickedOutDialog } from "./dialog-kick-out";
import { ConfigurationHistoryReleaseTab } from "./tabs/history";

export interface ConfigurationProps {}

export default function ManageConfiguration(props: ConfigurationProps) {
  const dispatch = useAppDispatch();
  const {
    configurationGroupsData,
    configurationUI: {
      isEditMode,
      isLoadingEditMode,
      isLoading,
      selectedGroup,
    },
  } = useSelector((state: RootState) => state.configuration);

  const handleOnTabChange = (value: string) => {
    dispatch(updateSelectedGroup(value));
  };
  const { checkEdit } = useEditConfiguration();

  const otherConfigTabs = [
    {
      label: "Find BDM",
      component: <SHTypography variant="subtitle2">Coming soon!</SHTypography>,
    },
    {
      label: "Change history",
      component: <ConfigurationHistoryReleaseTab />,
    },
  ];

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack sx={{ paddingY: 3 }} spacing={3}>
        {/* <ConfigurationTopBarPlacement /> */}
        <TopBar
          title="Configuration"
          tools={
            !isLoading && (
              <>
                {!isEditMode && (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    onClick={checkEdit}
                    //uncomment this to remove lock feature
                    // onClick={() => dispatch(updateEditMode(true))}
                    isLoading={isLoadingEditMode}
                    disabled={isLoadingEditMode}
                  >
                    Edit
                  </SHButton>
                )}
                <ConfirmKickOutDialog />
                <ConfigurationLockEditingKickedOutDialog />
                <DiscardConfigurationButton />
                <SaveDraftConfigurationButton />
                <PublishConfigurationButton />
              </>
            )
          }
        />
        <SHTabs
          value={selectedGroup}
          onValueChange={handleOnTabChange}
          tabs={configurationGroupsData
            .map((config) => ({
              label: config.name,
              component: (
                <ConfigurationView key={config.id} groupId={config.id} />
              ),
            }))
            .concat(otherConfigTabs)}
        />
      </SHStack>
    </SHContainer>
  );
}
