import { TableHead, TableHeadProps } from "@mui/material";
import { forwardRef } from "react";

export interface SHTableHeadProps extends TableHeadProps {
  hidden?: boolean;
}
export const SHTableHead = forwardRef(
  ({ hidden, sx, ...others }: SHTableHeadProps, ref: any): JSX.Element => (
    <TableHead
      {...others}
      sx={{
        opacity: hidden ? 0 : undefined,
        "&>.MuiTableRow-root": {
          borderColor: hidden ? "transparent" : undefined,
        },
        ...sx,
      }}
      ref={ref}
    />
  ),
);
