import { SHStack, SHStackProps } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

interface SHRichTextSkeletonProps {
  options?: number;
  hasLabel?: boolean;
}

export const SHRichTextSkeleton = ({
  hasLabel = false,
  ...others
}: SHRichTextSkeletonProps & SHStackProps) => {
  return (
    <SHStack spacing={1.5} {...others}>
      {hasLabel && <SHSkeleton height={18} width="30%" />}
      <SHSkeleton height={200} width="100%" />
    </SHStack>
  );
};
