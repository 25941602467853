import { SHButton } from "@components/design-systems";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { ProductListDTO } from "@models/product/entities/product";
import { SubProductDTO } from "@models/product/entities/subProduct";
import { ApproveButton } from "@pages/suppliers/_id/_products/_id/components/buttons/approve";
import { CancelButton } from "@pages/suppliers/_id/_products/_id/components/buttons/cancel";
import { RecallSubmissionButton } from "@pages/suppliers/_id/_products/_id/components/buttons/recall-submission";
import { RevertToDraftButton } from "@pages/suppliers/_id/_products/_id/components/buttons/revert-to-draft";
import { SubmitForReviewButton } from "@pages/suppliers/_id/_products/_id/components/buttons/submit-for-review";
import { TopBarSkeleton } from "@pages/suppliers/_id/_products/_id/edit/components/skeletons/TopBarSkeleton";
import { generateTitle } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { RootState } from "@redux/store";
import { some } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";

interface ProductTopBarContainerProps {
  isNew?: boolean;
  isSubmitting?: boolean;
  isDisabledSaveDraft?: boolean;
  isDisabledSubmitForReview?: boolean;
  isFeesTab?: boolean;
  productId?: string;
  supplierId?: string;
  data: ProductListDTO | SubProductDTO[];
  onSaveDraft: () => void;
  resetForm: (data: ProductListDTO | SubProductDTO[]) => void;
}
export const ProductTopBarContainer = ({
  resetForm,
  onSaveDraft,
  data,
  isNew,
  isSubmitting,
  productId,
  supplierId,
  isDisabledSaveDraft,
  isDisabledSubmitForReview,
  isFeesTab,
}: ProductTopBarContainerProps) => {
  const {
    productUI: {
      productName,
      releaseName,
      showViewReleaseLog,
      isLoadingProductGroups,
      isLoadingSubProducts,
    },
    productData,
    productBannerInfo,
  } = useSelector((state: RootState) => state.product);

  const isPendingApproval = useMemo(() => {
    return some(productBannerInfo, ["status", "Pending approval"]);
  }, [productBannerInfo]);
  const isApproved = useMemo(() => {
    return some(productBannerInfo, ["status", "Approved"]);
  }, [productBannerInfo]);

  if (
    (!isFeesTab
      ? !productData || isLoadingProductGroups
      : isLoadingSubProducts) &&
    !isNew
  )
    return (
      <TopBarContainer>
        <TopBarSkeleton />
      </TopBarContainer>
    );
  return (
    <TopBarContainer>
      <TopBar
        title={generateTitle(productName, releaseName)}
        tools={
          <>
            {/* Not Pending Approval */}
            {!showViewReleaseLog ? (
              !isPendingApproval ? (
                <>
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    disabled={isDisabledSaveDraft}
                    isLoading={isSubmitting}
                    onClick={onSaveDraft}
                  >
                    Save draft
                  </SHButton>
                  {!isNew && (
                    <SubmitForReviewButton
                      productId={productId}
                      data={data}
                      isFeesTab={isFeesTab}
                      isApproved={isApproved}
                      resetForm={resetForm}
                      disabled={isDisabledSubmitForReview}
                    />
                  )}
                </>
              ) : (
                <>
                  {/* Supper Admin */}
                  <RevertToDraftButton productId={productId} />
                  <ApproveButton
                    productId={productId}
                    resetForm={resetForm}
                    isFeesTab={isFeesTab}
                  />
                  {/* Not Supper Admin */}
                  <RecallSubmissionButton productId={productId} />
                </>
              )
            ) : (
              <CancelButton supplierId={supplierId} productId={productId} />
            )}
          </>
        }
      />
    </TopBarContainer>
  );
};
