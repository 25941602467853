import {
  REVIEW_FIRST_PAGE_BODY_HEIGHT,
  REVIEW_PAGE_BODY_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { View } from "@react-pdf/renderer";
import { useReviewStyles } from "../../../../styles";

interface PDFReviewPageBodyProps {
  children?: React.ReactNode;
  isFirstPage?: boolean;
}
export const PDFReviewPageBody = ({
  children,
  isFirstPage,
}: PDFReviewPageBodyProps) => {
  const { pageStyle } = useReviewStyles();

  return (
    <View
      style={
        isFirstPage
          ? {
              ...pageStyle.body,
              height: REVIEW_FIRST_PAGE_BODY_HEIGHT,
            }
          : {
              ...pageStyle.body,
              height: REVIEW_PAGE_BODY_HEIGHT,
            }
      }
    >
      {children}
    </View>
  );
};
