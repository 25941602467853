import { SVGProps } from "react";

export const SortAscSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={15}
        height={15}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.452 5.277 9.06 6.562h-1l1.678-4.998h1.157l1.67 4.998h-1.05l-.394-1.285h-1.67Zm1.472-.736-.612-2.022h-.044l-.61 2.022h1.266Z"
            fill="#515B6F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.452 5.277 9.06 6.562h-1l1.678-4.998h1.157l1.67 4.998h-1.05l-.394-1.285h-1.67Zm1.472-.736-.612-2.022h-.044l-.61 2.022h1.266Z"
            fill="#000"
            fillOpacity={0.2}
        />
        <path
            d="M12.15 13.125H8.463v-.648l2.418-3.487v-.05H8.53v-.814h3.548v.648l-2.406 3.488v.05h2.48v.813h-.001ZM4.218 2.344a.469.469 0 0 0-.937 0v9.18L2.206 10.45a.47.47 0 1 0-.663.664l1.875 1.874.006.007a.466.466 0 0 0 .657-.006l1.875-1.875a.469.469 0 1 0-.663-.664l-1.075 1.076V2.344Z"
            fill="#515B6F"
        />
        <path
            d="M12.15 13.125H8.463v-.648l2.418-3.487v-.05H8.53v-.814h3.548v.648l-2.406 3.488v.05h2.48v.813h-.001ZM4.218 2.344a.469.469 0 0 0-.937 0v9.18L2.206 10.45a.47.47 0 1 0-.663.664l1.875 1.874.006.007a.466.466 0 0 0 .657-.006l1.875-1.875a.469.469 0 1 0-.663-.664l-1.075 1.076V2.344Z"
            fill="#000"
            fillOpacity={0.2}
        />
    </svg>
);
