import { SHStack } from "@components/design-systems";
import { useTheme } from "@mui/material";

interface VerticalDashLineProps {
  colNumber: number;
}
export const VerticalDashLine = ({ colNumber }: VerticalDashLineProps) => {
  const { palette } = useTheme();

  return (
    <SHStack
      direction={"row"}
      justifyContent={"space-between"}
      paddingLeft={"6px"}
    >
      {Array.from(Array(colNumber + 1).keys()).map((name, index) => (
        <SHStack
          key={index}
          width={"1px"}
          height={"5px"}
          sx={{
            background: palette.text.primary,
            transform:
              index === 0
                ? "translateX(1.5px)"
                : index === colNumber
                ? "translateX(-1.5px)"
                : undefined,
          }}
        />
      ))}
    </SHStack>
  );
};
