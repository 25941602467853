import { SelectRegions } from "@components/auto-completes/regions";
import {
  SHButton,
  SHDialog,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import { CompleteProfileSupplierUserSale } from "@components/dialogs/complete-profile-first-step/model";
import { completeProfileLastStepValidators } from "@components/dialogs/complete-profile-last-step/util";
import { yupResolver } from "@hookform/resolvers/yup";

import { Controller, useForm } from "react-hook-form";

interface CompleteProfileLastStepDialogProps {
  onSkip?: () => void;
  onComplete: (userDetails: CompleteProfileSupplierUserSale) => void;
  isCompleting?: "first" | "last" | null;
  supplierUserInfo?: CompleteProfileSupplierUserSale;
}

export const CompleteProfileLastStepDialog = ({
  onSkip,
  onComplete,
  isCompleting = null,
  supplierUserInfo,
}: CompleteProfileLastStepDialogProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CompleteProfileSupplierUserSale>({
    mode: "onChange",
    resolver: yupResolver(completeProfileLastStepValidators),
    defaultValues: { ...supplierUserInfo },
  });

  return (
    <form>
      <SHDialog
        open
        disableEscapeKeyDown
        disableBackdropClick
        maxWidth="lg"
        width={645}
        header={
          <SHStack alignItems="center" textAlign="center" spacing={1.5}>
            <SHTypography
              variant="h2"
              sx={{
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              One final step!
            </SHTypography>
            <SHTypography variant="subtitle2" colorVariant="disabled">
              Your organisation has nominated you to have a public profile
              <br /> that other users can see. Please review and update <br />
              your public information:
            </SHTypography>
          </SHStack>
        }
        body={
          <SHStack alignItems="stretch" spacing={2} sx={{ width: 520 }}>
            <Controller
              name="publicPhoneNumber"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  label={"Public contact number"}
                  sx={{ width: { xs: "100%", md: 525 } }}
                  placeholder={
                    "Enter the contact number you would like to make public"
                  }
                  helperText={
                    errors.publicPhoneNumber
                      ? errors.publicPhoneNumber.message
                      : null
                  }
                />
              )}
            />
            <Controller
              name="preferredContactHours"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  label={"Preferred contact hours"}
                  sx={{ width: { xs: "100%", md: 525 } }}
                  placeholder={
                    "e.g. 9:00am - 3:00pm Monday to Wednesday; 1:00pm - 3:00pm Thursday "
                  }
                />
              )}
            />
            <Controller
              name="publicEmail"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  type="email"
                  label={"Public email"}
                  sx={{ width: { xs: "100%", md: 525 } }}
                  error={!!errors.publicEmail}
                  helperText={
                    errors.publicEmail ? errors.publicEmail.message : null
                  }
                  placeholder={
                    "Enter the email address you would like to make public"
                  }
                />
              )}
            />
            <Controller
              name="linkedInProfileUrl"
              control={control}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  type="email"
                  label={"LinkedIn profile URL"}
                  sx={{ width: { xs: "100%", md: 525 } }}
                  error={!!errors.linkedInProfileUrl}
                  helperText={
                    errors.linkedInProfileUrl
                      ? errors.linkedInProfileUrl.message
                      : null
                  }
                  placeholder={"Enter the link to your LinkedIn profile"}
                />
              )}
            />
            <Controller
              name="regions"
              control={control}
              render={({ field }) => (
                <SelectRegions
                  value={field.value ?? []}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  textFieldProps={{
                    label: "Region",
                    placeholder: "Enter the region/area serviced",
                  }}
                />
              )}
            />
          </SHStack>
        }
        footer={
          <SHStack
            justifyContent="center"
            alignItems="center"
            direction={"row"}
            spacing={7}
          >
            <SHButton
              color="secondary"
              variant="text"
              size="large"
              onClick={onSkip}
            >
              Skip for now
            </SHButton>
            <SHButton
              color="primary"
              variant="contained"
              onClick={handleSubmit(onComplete)}
              size="large"
              disabled={isCompleting === "last" || !isValid}
              isLoading={isCompleting === "last"}
            >
              Complete
            </SHButton>
          </SHStack>
        }
      />
    </form>
  );
};
