import { UserType } from "@models/auth";
import { FeeExportDTO } from "@models/platform-analysis/entities/feeExport";
import {
  FamilyMemberAccountDTO,
  FeeDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import {
  displayCurrencyNumber,
  displayIntNumber,
  getPortfolioDetails,
} from "@pages/platform-analysis/util";
import { chain, forEach, isEmpty, isNull, replace, some } from "lodash";
import { CellObject, Range, WorkSheet, utils } from "xlsx-js-style";
import {
  CharactersInARow,
  DefaultCellHeight,
  exportCurrency,
  exportDate,
  exportDateTime,
  exportPercentage,
  fillBlue,
  fillGray,
} from "./config";

export const getWorksheet = (
  data?: FeeExportDTO,
  viewMode: FeesDisplayStyle = FeesDisplayStyle.Percentage,
): WorkSheet => {
  const isDollarMode = viewMode === FeesDisplayStyle.Dollar;
  if (!data) return utils.aoa_to_sheet([]);
  let rows: any[] = [];
  let merges: Range[] = [];
  let rowsHeighTrack: Map<number, number> = new Map();
  const pushEmptyRow = () => {
    rows.push(["", ""]);
  };
  const pushHeader = (
    label: string,
    strSerial: string,
    emptyColsNum: number,
  ) => {
    merges.push({
      s: {
        r: rows.length,
        c: 2,
      },
      e: {
        r: rows.length,
        c: emptyColsNum + 1,
      },
    });
    rows.push([
      "",
      {
        v: label,
        t: "s",
        s: {
          fill: fillBlue,
          font: {
            bold: true,
          },
        },
      },
      {
        v: strSerial,
        t: "s",
        s: {
          fill: fillBlue,
          alignment: { horizontal: "right" },
        },
      },
    ]);
  };
  const pushSectionTitle = (str: string, emptyColsNum: number) => {
    rows.push([
      "",
      {
        v: str,
        t: "s",
        s: {
          fill: fillBlue,
          font: {
            bold: true,
          },
        },
      },
      ...Array.from(Array(emptyColsNum).keys()).map((i) => ({
        v: "",
        t: "s",
        s: {
          fill: fillBlue,
        },
      })),
    ]);
  };

  const pushArrayText = (
    label: string,
    arr: string[],
    emptyColsNum: number,
  ) => {
    const str = arr.join(", ");
    rowsHeighTrack.set(
      rows.length,
      Math.ceil(str.length / CharactersInARow) * DefaultCellHeight,
    );
    merges.push({
      s: {
        r: rows.length,
        c: 2,
      },
      e: {
        r: rows.length,
        c: emptyColsNum + 1,
      },
    });
    rows.push([
      "",
      {
        v: label,
        t: "s",
        s: {
          alignment: {
            vertical: "center",
          },
        },
      },
      {
        v: str,
        t: "s",
        s: {
          alignment: { vertical: "center", wrapText: true },
        },
      },
    ]);
  };
  const pushText = (label: string, arr: string, emptyColsNum: number) => {
    const str = arr;
    rowsHeighTrack.set(
      rows.length,
      Math.ceil(str.length / CharactersInARow) * DefaultCellHeight,
    );
    merges.push({
      s: {
        r: rows.length,
        c: 2,
      },
      e: {
        r: rows.length,
        c: emptyColsNum + 1,
      },
    });
    rows.push([
      "",
      {
        v: label,
        t: "s",
        s: {
          alignment: {
            vertical: "center",
          },
        },
      },
      {
        v: str,
        t: "s",
        s: {
          alignment: { vertical: "center", wrapText: true },
        },
      },
    ]);
  };
  //Main
  const feeLength = data.subProducts?.[0]?.fees?.length ?? 0;
  const isSalesOwner = data.ownerType === UserType.SupplierBdmSale;
  const isAdviserUser = data.ownerType === UserType.AdviserAdviser;

  const getStringFamilyMemberAccounts = (data: FamilyMemberAccountDTO[]) => {
    return chain(data)
      ?.map(
        (data) =>
          `${replace(
            data.variableName ? data.variableName : "",
            "_",
            " ",
          )}: ${displayCurrencyNumber(data.balance)}`,
      )
      ?.join(", ")
      ?.value();
  };

  //Push data
  pushEmptyRow();
  pushHeader(
    isAdviserUser
      ? "SuitabilityHub platform fee review summary"
      : "SuitabilityHub platform fee analysis summary",
    `Serial: ${data?.serial}`,
    feeLength,
  );
  if (isAdviserUser) {
    rows.push([
      "",
      `For ${data.practiceName} internal use only. Not to be disseminated`,
    ]);
  } else {
    rows.push([
      "",
      isSalesOwner
        ? !isEmpty(data.adviserName) && !isEmpty(data.practiceName)
          ? `For ${data.adviserName} of ${data.practiceName} use only`
          : "For internal use only"
        : `For ${data.supplierName} internal use only. Not to be disseminated`,
    ]);
  }

  pushEmptyRow();
  rows.push([
    "",
    isAdviserUser ? "Suitability Review name:" : "Analysis name:",
    data.name,
  ]);
  if (isAdviserUser) {
    rows.push(["", "Adviser:", `${data.adviserName} of ${data.practiceName}`]);
  } else {
    rows.push(["", "Owner:", `${data.ownerName} of ${data.supplierName}`]);
  }
  if (isAdviserUser) {
    rows.push(["", "Family group:", `${data.familyGroupName}`]);
  }
  rows.push([
    "",
    "Last edit:",
    {
      v: data.lastModifiedDate,
      ...exportDateTime,
    },
  ]);
  rows.push([
    "",
    "Data valid:",
    {
      v: data.databaseVersion,
      ...exportDate,
    },
  ]);
  pushEmptyRow();
  pushSectionTitle("Portfolio details for fee estimates", feeLength);
  pushEmptyRow();
  if (data.feePortfolioDetails?.idps?.length) {
    rows.push([
      "",
      "Value of each IDPS account:",
      ...data.feePortfolioDetails.idps.map((value: number) => ({
        v: value,
        ...exportCurrency,
      })),
    ]);
  }
  if (data.feePortfolioDetails?.super?.length) {
    rows.push([
      "",
      "Value of each super/pension account:",
      ...data.feePortfolioDetails.super.map((value: number) => ({
        v: value,
        ...exportCurrency,
      })),
    ]);
  }
  if (data?.feePortfolioDetails?.familyMembers) {
    forEach(data?.feePortfolioDetails?.familyMembers, (item) => {
      pushText(
        item?.name ? item?.name + ":" : "",
        getStringFamilyMemberAccounts(item.familyMemberAccounts),
        feeLength,
      );
    });
  }
  if (data.feePortfolioDetails?.totalPortfolioValue) {
    rows.push([
      "",
      {
        v: "Total portfolio value:",
        t: "s",
        s: {
          fill: fillGray,
          font: {
            bold: true,
          },
        },
      },
      {
        v: data.feePortfolioDetails.totalPortfolioValue,
        ...exportCurrency,
        s: {
          fill: fillGray,
          font: {
            bold: true,
            underline: true,
          },
        },
      },
    ]);
  }

  const { investments, transactionsOutside, transactionsWithin } =
    getPortfolioDetails(data.feePortfolioDetails);
  if (investments.length) {
    pushArrayText("Investments and other holdings:", investments, feeLength);
  }
  if (
    data?.isShowHoldingsNumber &&
    data.feePortfolioDetails?.totalDifferentInvestments
  ) {
    rows.push([
      "",
      "Total number of different investments held:",
      displayIntNumber(data.feePortfolioDetails.totalDifferentInvestments),
    ]);
  }

  if (transactionsOutside.length) {
    pushArrayText(
      "Transactions outside managed accounts (Next 12 months):",
      transactionsOutside,
      feeLength,
    );
  }

  if (transactionsWithin.length) {
    pushArrayText(
      "Transactions within managed accounts (Next 12 months):",
      transactionsWithin,
      feeLength,
    );
  }
  pushEmptyRow();
  pushSectionTitle(
    "Fee estimates: Annual platform fees by portfolio value",
    feeLength,
  );
  rows.push([
    "",
    {
      v: isDollarMode ? "$ total fees" : "% of total portfolio value",
      s: {
        font: {
          bold: true,
        },
      },
    },
  ]);
  // Details mode
  pushEmptyRow();
  if (data.subProducts.length) {
    // Header
    let detailHeaderRow: any[] = [];
    detailHeaderRow?.push("");
    detailHeaderRow?.push({
      v: `Fee breakdown for ${displayCurrencyNumber(
        data?.feePortfolioDetails?.totalPortfolioValue,
      )}`,
      t: "s",
      s: {
        fill: fillGray,
        font: {
          bold: true,
        },
      },
    });
    data.subProducts?.[0]?.feeDetails?.forEach((feeDetail, index) => {
      detailHeaderRow?.push({
        v: feeDetail?.name,
        t: "s",
        s: {
          fill: fillGray,
          font: {
            bold: true,
            underline: feeDetail?.name === "Total fees",
          },
          alignment: { vertical: "bottom", wrapText: true },
        },
      });
    });
    detailHeaderRow?.splice(3, 0, {
      v: "",
      t: "s",
      s: {
        fill: fillGray,
      },
    });
    detailHeaderRow?.splice(detailHeaderRow?.length - 1, 0, {
      v: "",
      t: "s",
      s: {
        fill: fillGray,
      },
    });
    rows?.push(detailHeaderRow);
    rowsHeighTrack.set(rows.length - 1, 45);
    // Body
    data.subProducts?.forEach((subProduct) => {
      let detailRow: any[] = [];
      detailRow?.push("");
      detailRow?.push({
        v: `${subProduct.productName} - ${subProduct.name}${
          subProduct.warning ? "*" : ""
        }`,
        t: "s",
      });
      detailRow = [
        ...detailRow,
        ...subProduct.feeDetails.map((feeDetail) => {
          return isNull(feeDetail.percentage) || isNull(feeDetail.dollar)
            ? "No data"
            : isDollarMode
            ? {
                v: feeDetail?.dollar,
                ...exportCurrency,
              }
            : {
                v: feeDetail?.percentage / 100,
                ...exportPercentage,
              };
        }),
      ];
      detailRow?.splice(3, 0, {
        v: "",
        t: "s",
      });
      detailRow?.splice(detailRow?.length - 1, 0, {
        v: "",
        t: "s",
      });
      rows.push(detailRow);
    });
  }
  // Project mode
  pushEmptyRow();
  if (data.subProducts.length) {
    const firstSubProduct = data.subProducts[0];
    rows.push([
      "",
      {
        v: "Average total portfolio value for the year",
        t: "s",
        s: {
          fill: fillGray,
          font: {
            bold: true,
          },
        },
      },
      ...firstSubProduct.fees.map((fee: FeeDTO) => ({
        v: fee.avgTotalPortfolio,
        ...exportCurrency,
        s: {
          fill: fillGray,
          font: {
            bold: true,
            underline: fee.isCurrentPortfolio ? true : false,
          },
        },
      })),
    ]);
    data.subProducts.forEach((subProduct) => {
      rows.push([
        "",
        {
          v: `${subProduct.productName} - ${subProduct.name}${
            subProduct.warning ? "*" : ""
          }`,
          t: "s",
        },
        ...subProduct.fees.map((fee: FeeDTO) =>
          isDollarMode
            ? {
                v: fee.totalCostForDisplay,
                ...exportCurrency,
              }
            : {
                v: fee.totalCostForDisplayPercentage / 100,
                ...exportPercentage,
              },
        ),
      ]);
    });
  }
  pushEmptyRow();
  const hasMissingInvestments = some(
    data.subProducts,
    (item) => !isEmpty(item?.warning),
  );
  if (hasMissingInvestments) {
    rows.push(["", "*Product is missing some investment options"]);
  }
  rows.push([
    "",
    isAdviserUser
      ? "Fee review disclaimer and assumptions apply. See web version for details"
      : "Fee analysis disclaimer and assumptions apply. See web version for details",
  ]);
  //Merge Fee analysis disclaimer cell
  merges.push({
    s: {
      r: rows.length - 1,
      c: 1,
    },
    e: {
      r: rows.length - 1,
      c: 1 + feeLength,
    },
  });

  // Inti style cols
  let wscols = [{ wch: 2 }, { wch: 60 }, { wch: 15 }];
  Array.from(Array(feeLength).keys()).forEach(() =>
    wscols?.push({ wch: 11.25 }),
  );

  //Create worksheet
  let worksheet = utils.aoa_to_sheet(rows);
  //Styles
  worksheet["!cols"] = wscols;
  worksheet["!merges"] = merges;
  worksheet["!rows"] = rows.map((row, index) => ({
    hpx: rowsHeighTrack.get(index) ?? DefaultCellHeight,
  }));

  //Draw borders
  drawBorders(worksheet, rows.length, feeLength + 2);
  return worksheet;
};

export const drawBorders = (
  worksheet: WorkSheet,
  rowLength: number,
  colLength: number,
) => {
  Array.from(Array(rowLength).keys()).forEach((row, index) => {
    if (index < rowLength) {
      const colLeft = utils.encode_cell({ r: index + 1, c: 1 });
      const colRight = utils.encode_cell({ r: index + 1, c: colLength - 1 });
      if (!worksheet[colLeft]) {
        worksheet[colLeft] = {
          t: "s",
          v: "",
          s: {},
        } as CellObject;
      }
      worksheet[colLeft].s = {
        ...(worksheet[colLeft].s ?? {}),
        border: {
          left: {
            style: "medium",
            color: "000000",
          },
        },
      };
      if (!worksheet[colRight]) {
        worksheet[colRight] = {
          t: "s",
          v: "",
          s: {},
        } as CellObject;
      }
      worksheet[colRight].s = {
        ...(worksheet[colRight].s ?? {}),
        border: {
          right: {
            style: "medium",
            color: "000000",
          },
        },
      };
    }
  });
  Array.from(Array(colLength).keys()).forEach((key, index) => {
    if (index < colLength) {
      const rowTop = utils.encode_cell({ r: 1, c: index + 1 });
      const rowBottom = utils.encode_cell({ r: rowLength - 1, c: index + 1 });

      if (!worksheet[rowTop]) {
        worksheet[rowTop] = {
          t: "s",
          v: "",
          s: {},
        } as CellObject;
      }
      worksheet[rowTop].s = {
        ...(worksheet[rowTop].s ?? {}),
        border: {
          ...(worksheet[rowTop].s.border ?? {}),
          top: {
            style: "medium",
            color: "000000",
          },
        },
      };
      if (!worksheet[rowBottom]) {
        worksheet[rowBottom] = {
          t: "s",
          v: "",
          s: {},
        } as CellObject;
      }
      worksheet[rowBottom].s = {
        ...(worksheet[rowBottom].s ?? {}),
        border: {
          ...(worksheet[rowBottom].s.border ?? {}),
          bottom: {
            style: "medium",
            color: "000000",
          },
        },
      };
    }
  });
};
