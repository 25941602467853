import { Components, Theme } from "@mui/material";
export const muiContainerTheme = (
  theme: Theme,
): Pick<Components<Omit<Theme, "components">>, "MuiContainer"> => ({
  MuiContainer: {
    styleOverrides: {
      root: {
        [theme.breakpoints.down("lg2")]: {
          "&.MuiContainer-maxWidthLg2": {
            maxWidth: theme.breakpoints.values.lg2,
          },
        },
      },
    },
  },
});
