import { SHStack, SHStackProps } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

interface SHCheckBoxGroupSkeletonProps {
  options?: number;
  hasLabel?: boolean;
}

export const SHCheckBoxGroupSkeleton = ({
  options = 1,
  hasLabel = true,
  ...others
}: SHCheckBoxGroupSkeletonProps & SHStackProps) => {
  return (
    <SHStack spacing={1.5} {...others}>
      {hasLabel && <SHSkeleton height={18} width="60%" />}
      <SHStack spacing={1}>
        {Array.from(Array(options).keys()).map((option) => (
          <SHStack spacing={1} direction="row" key={option}>
            <SHSkeleton height={20} width={20} />
            <SHSkeleton height={18} width={"40%"} />
          </SHStack>
        ))}
      </SHStack>
    </SHStack>
  );
};
