import { SHButton, SHButtonProps } from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { useProduct } from "@hooks/useProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { ProductListDTO } from "@models/product/entities/product";
import { SubProductDTO } from "@models/product/entities/subProduct";
import SubmitForReviewDialog from "@pages/suppliers/_id/_products/_id/components/dialogs/submit-for-review";
import { convertDataToSubmit } from "@pages/suppliers/_id/_products/_id/edit/components/fees-and-rates/util";
import { RootState } from "@redux/store";
import { submitForReview } from "@services/product/productsService";
import { useState } from "react";
import { useSelector } from "react-redux";

export interface SubmitForReviewButtonProps extends SHButtonProps {
  data: ProductListDTO | SubProductDTO[];
  resetForm: (product: ProductListDTO | SubProductDTO[]) => void;
  isApproved: boolean;
  productId?: string;
  isFeesTab?: boolean;
}

export const SubmitForReviewButton = ({
  data,
  productId,
  resetForm,
  isApproved,
  isFeesTab,
  ...others
}: SubmitForReviewButtonProps) => {
  const { notify } = useNotification();
  const {
    loadBannerInfo,
    updateExtProduct,
    refreshProductData,
    updateExtSubProducts,
    refreshSubProductData,
  } = useProduct();
  const { canSubmitForReviewProduct } = useUserPermissions();

  const {
    productUI: { isEditMode },
  } = useSelector((state: RootState) => state.product);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSubmitForReviewDialog, setShowSubmitForReviewDialog] =
    useState(false);

  const isVisible = isEditMode && canSubmitForReviewProduct;

  const handleOnSubmitForReview = async () => {
    if (!data || !productId) return;
    setIsSubmitting(true);

    let isUpdated;
    if (!isFeesTab) {
      isUpdated = await updateExtProduct(data as ProductListDTO, true);
    } else {
      isUpdated = await updateExtSubProducts(
        productId,
        convertDataToSubmit(data as SubProductDTO[]),
        true,
      );
    }
    if (!isUpdated) return setIsSubmitting(false);

    const { isSuccess, message } = await submitForReview(productId);
    setIsSubmitting(false);

    if (isSuccess) {
      notify(message, {
        variant: "success",
        close: true,
      });

      if (isApproved) {
        if (isFeesTab) {
          refreshSubProductData("edit").then((newData) => {
            if (productId) loadBannerInfo(productId);
            if (newData) resetForm(newData);
          });
        } else {
          refreshProductData("edit").then((newData) => {
            if (productId) loadBannerInfo(productId);
            if (newData) resetForm(newData);
          });
        }
      } else {
        if (isFeesTab) {
          resetForm(isUpdated);
        } else {
          resetForm(data);
        }

        loadBannerInfo(productId);
      }
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setShowSubmitForReviewDialog(false);
  };

  return (
    <>
      {isVisible && (
        <SHButton
          variant="contained"
          size="extraMedium"
          onClick={() => setShowSubmitForReviewDialog(true)}
          {...others}
        >
          Submit for review
        </SHButton>
      )}
      {showSubmitForReviewDialog && (
        <SubmitForReviewDialog
          onClose={() => setShowSubmitForReviewDialog(false)}
          onSubmit={handleOnSubmitForReview}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};
