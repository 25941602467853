import {
  SHButton,
  SHHtmlBlock,
  SHImage,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { CheckGroupSVG, ImageSVG } from "@components/svgs";
import { AnalysisConfigurationFeatureSectionDTO } from "@models/platform-analysis/entities/steps/feature";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { useMemo } from "react";

export interface FeatureGroupCardProps extends SHStackProps {
  group?: AnalysisConfigurationFeatureSectionDTO;
  handleOnSelect?: (groupId?: string) => void;
  handleOnSelectDeselectAll?: () => void;
}

export const FeatureGroupCard = ({
  group,
  handleOnSelect,
  handleOnSelectDeselectAll,
  ...others
}: FeatureGroupCardProps) => {
  const { palette } = useTheme();

  const colors = useMemo(() => {
    if (group?.isSelected)
      return {
        background: palette.success[50],
        border: palette.success[100],
        text: palette.text.primary,
      };
    return {
      background: palette.common.white,
      border: palette.common.black,
      text: palette.text.third,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group?.isSelected]);

  const counterTextColor = useMemo(() => {
    if (group?.totalSelectedFeature !== 0) return palette.success.main;
    return palette.warning[800];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group?.totalSelectedFeature]);

  const titleButton = useMemo(() => {
    if (group?.totalSelectedFeature === group?.totalFeature)
      return "Deselect all features";
    return "Select all features";
  }, [group?.totalSelectedFeature, group?.totalFeature]);

  return (
    <SHStack
      width={"100%"}
      height={"100%"}
      minHeight={191}
      sx={{
        gap: "16px",
        padding: "15px",
        boxSizing: "border-box",
        backdropFilter: "blur(0.5px)",
        background: `${colors?.background}`,
        border: `1px solid ${colors?.border}`,
        cursor: "pointer",
        boxShadow: `3px 3px 4px ${hexToRGBA(palette.common.black, 0.25)}`,
        transition: "all .1s linear",
        "&:hover": {
          border: `1px solid ${palette.primary[800]}`,
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: `inset 3px 3px 4px ${hexToRGBA(
            palette.common.black,
            0.25,
          )}`,
        },
      }}
      onClick={() =>
        typeof handleOnSelect === "function" ? handleOnSelect(group?.id) : {}
      }
      {...others}
    >
      <SHStack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {group?.iconUrl ? (
          <SHImage src={group?.iconUrl} width={26} height={25} />
        ) : (
          <ImageSVG />
        )}
        <SHButton
          variant={"contained"}
          size={"extraMedium"}
          disabled={!group?.isSelected}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            if (typeof handleOnSelectDeselectAll === "function")
              handleOnSelectDeselectAll();
          }}
        >
          {titleButton}
        </SHButton>
      </SHStack>
      <SHStack direction={"column"} alignItems={"flex-start"} gap={"8px"}>
        <SHTypography variant="h3" sx={{ lineHeight: "120%", fontSize: 20 }}>
          {group?.name}
        </SHTypography>
        <SHStack direction={"row"}>
          <SHTypography
            variant="subtitle1"
            sx={{ color: counterTextColor, fontWeight: 700 }}
          >
            <CheckGroupSVG color={counterTextColor} />
            {` ${group?.totalSelectedFeature}`}
          </SHTypography>
          <SHTypography
            variant="subtitle1"
            sx={{ color: counterTextColor, fontWeight: 400 }}
          >{`/${group?.totalFeature} selected`}</SHTypography>
        </SHStack>
        <SHHtmlBlock
          variant="body2"
          color={colors.text}
          content={group?.description}
        />
      </SHStack>
    </SHStack>
  );
};
