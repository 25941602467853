export enum ReviewStatus {
  Completed = "Completed",
  InProgress = "In progress",
  Archived = "Archived",
  Restore = "Restore",
}

export enum ReviewDecisionStatus {
  Undecided = "Undecided",
  Shortlisted = "Shortlisted",
  Disqualified = "Disqualified",
}
