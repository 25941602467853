import { SHBox, SHStack, SHStackProps } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { useTheme } from "@mui/material";

export const BusinessMetricCategoriesSkeleton = ({
  ...others
}: SHStackProps) => {
  const { palette } = useTheme();
  return (
    <SHBox
      spacing={1}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        border: `1px solid ${palette.secondary[100]}`,
      }}
      {...others}
    >
      <SHStack
        width={"100%"}
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"center"}
      >
        {Array.from(Array(3).keys()).map((index) => (
          <SHSkeleton
            key={index}
            sx={{
              margin: "1% 1%",
              display: "flex",
            }}
            height={"150px"}
            width={"31%"}
          />
        ))}
      </SHStack>
    </SHBox>
  );
};
