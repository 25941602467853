import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import {
  ReviewBannerInfoDTO,
  ReviewDTO,
} from "@models/reviews/entities/review";
import { ReviewStepDTO } from "@models/reviews/entities/step";
import { ReviewGetBusinessMetricSectionsDTO } from "@models/reviews/entities/steps/businessMetric";
import {
  ReviewConfigurationFeatureSectionDTO,
  ReviewGetFeatureSectionsDTO,
  UpdateDecisionMarkingDTO,
} from "@models/reviews/entities/steps/feature";
import { ReviewGetFeeCalculatorDTO } from "@models/reviews/entities/steps/fee";
import { ReviewStepSetupDTO } from "@models/reviews/entities/steps/setup";
import { ReviewStep } from "@models/reviews/enums/step";
import {
  createReviewThunk,
  getReviewBannerInfoThunk,
  getReviewBusinessMetricSelectionThunk,
  getReviewFeatureSelectionsThunk,
  getReviewFeeCalculatorThunk,
  getReviewStepThunk,
  getReviewStepsThunk,
  resetReviewFeeStore,
  resetReviewStore,
  updateAppendixLineChartColorsAction,
  updateBannerInfoAction,
  updateDisclaimersAction,
  updateFeatureSelectionGroup,
  updateFeeCalculatorAction,
  updateFilterBusinessMetricAction,
  updateIsAccessDeniedAction,
  updateIsCompletedAction,
  updateIsDirtyAction,
  updateIsReadOnlyAction,
  updateIsShowHiddenAction,
  updateIsShowHiddenAppendixAction,
  updateIsValidAction,
  updateLineChartColorsAction,
  updateReachAnalysisSetupPageAction,
  updateReviewAction,
  updateReviewBusinessMetricSelectionThunk,
  updateReviewDecisionMakingStatusThunk,
  updateReviewFeatureSelectionsThunk,
  updateReviewFeeCalculatorThunk,
  updateReviewThunk,
  updateReviewsStepsAction,
  updateSelectedStepIndexAction,
  updateSelectedSubStepIndexAction,
  updateStepUnHighlightedAction,
  updateTotalCollapsedBusinessMetricRowsAction,
} from "@redux/slices/review";
import { useAppDispatch } from "@redux/store";
import {
  putReviewStatus,
  duplicateReviewService,
  getAdviserUsersLOVForReview,
  getFamilyGroupsLOVForReview,
  getFamilyMembersLOVForReview,
  getReviewDisclaimers,
} from "@services/reviews/reviewService";
import { useNotification } from "./useNotification";
import { ReviewStatus } from "@models/reviews/enums/status";

const { Feature, BusinessMetric, Fee } = ReviewStep;
export const useReview = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();

  // UI effect
  const setIsValid = (isValid?: boolean) => {
    dispatch(updateIsValidAction(isValid));
  };

  const setIsCompleted = (isCompleted?: boolean) => {
    dispatch(updateIsCompletedAction(isCompleted));
  };

  const setIsReadOnly = (isReadOnly?: boolean) => {
    dispatch(updateIsReadOnlyAction(isReadOnly));
  };

  const setFilterBusinessMetric = (filterBusinessMetric?: string) => {
    dispatch(updateFilterBusinessMetricAction(filterBusinessMetric));
  };

  const setIsAccessDenied = (isAccessDenied?: boolean) => {
    dispatch(updateIsAccessDeniedAction(isAccessDenied));
  };

  const setIsDirty = (isDirty: boolean) => {
    dispatch(updateIsDirtyAction(isDirty));
  };

  const setIsShowHidden = (isShowHidden: boolean | undefined) => {
    dispatch(updateIsShowHiddenAction(isShowHidden));
  };

  const setIsShowHiddenAppendix = (isShowHidden: boolean | undefined) => {
    dispatch(updateIsShowHiddenAppendixAction(isShowHidden));
  };

  const setReachAnalysisSetupPage = (status?: "FIRST_TIME" | "SECOND_TIME") => {
    dispatch(updateReachAnalysisSetupPageAction(status));
  };

  const setTotalCollapsedBusinessMetricRows = (total: number) => {
    dispatch(updateTotalCollapsedBusinessMetricRowsAction(total));
  };

  // Line chart colors
  const setLineChartColors = (colors: { [key in string]: string }) => {
    dispatch(updateLineChartColorsAction(colors));
  };

  const setAppendixLineChartColors = (colors: { [key in string]: string }) => {
    dispatch(updateAppendixLineChartColorsAction(colors));
  };

  const resetReviewFee = () => {
    dispatch(resetReviewFeeStore());
  };

  const resetStore = () => {
    dispatch(resetReviewStore());
  };

  // Data
  const setBannerInfo = (bannerInfo: ReviewBannerInfoDTO) => {
    dispatch(updateBannerInfoAction(bannerInfo));
  };

  const setSelectedStepIndex = (stepIndex?: number) => {
    dispatch(updateSelectedStepIndexAction(stepIndex));
  };

  const setSelectedSubStepIndex = (subStepIndex?: number) => {
    dispatch(updateSelectedSubStepIndexAction(subStepIndex));
  };
  const setReviewSteps = (reviewSteps?: ReviewStepDTO) => {
    dispatch(updateReviewsStepsAction(reviewSteps));
  };

  const setReviewDisclaimers = (disclaimers?: DisclaimerDTO[]) => {
    dispatch(updateDisclaimersAction(disclaimers));
  };

  const setStepUnHighlighted = (
    step: ReviewStep,
    isUnHighlighted?: boolean,
  ) => {
    dispatch(updateStepUnHighlightedAction({ step, isUnHighlighted }));
  };

  const setReview = (review?: ReviewDTO) => {
    dispatch(updateReviewAction(review));
  };

  const setReviewFeatureSelections = (
    selections: ReviewConfigurationFeatureSectionDTO[],
  ) => {
    dispatch(updateFeatureSelectionGroup(selections));
  };

  const setReviewFeeCalculators = (
    feeCalculators: ReviewGetFeeCalculatorDTO,
  ) => {
    dispatch(updateFeeCalculatorAction(feeCalculators));
  };

  // API integration
  const createReview = async (setupStep: ReviewStepSetupDTO) => {
    const response = await dispatch(createReviewThunk(setupStep)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const loadReview = async (params: {
    reviewId: string;
    stepId: ReviewStep;
  }) => {
    const response = await dispatch(getReviewStepThunk(params)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    setStepUnHighlighted(params.stepId, false);
    return response.data;
  };

  const loadReviewStep = async (params: {
    reviewId: string;
    stepId: ReviewStep;
  }) => {
    const response = await dispatch(getReviewStepThunk(params)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    setStepUnHighlighted(params.stepId, false);
    return response.data;
  };

  // TODO: Load form ReviewFeeDisclaimers API
  const loadReviewDisclaimers = async (
    reviewId: string,
    stepId?: ReviewStep,
  ) => {
    const response = await getReviewDisclaimers(reviewId, stepId);
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadReviewBannerInfo = async (reviewId: string) => {
    const response = await dispatch(
      getReviewBannerInfoThunk(reviewId),
    ).unwrap();
    setIsAccessDenied(response.isForbidden);
    if (response.isForbidden) {
      return;
    }
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadReviewSteps = async (reviewId: string) => {
    const response = await dispatch(getReviewStepsThunk(reviewId)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }

    return response.data;
  };

  const loadAdviserUsersForReview = async (reviewId: string) => {
    const response = await getAdviserUsersLOVForReview(reviewId);
    if (!response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadFamilyGroupsForReview = async (
    reviewId: string,
    adviserId: string,
  ) => {
    const response = await getFamilyGroupsLOVForReview(reviewId, adviserId);
    if (!response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadFamilyMembersForReview = async (
    reviewId: string,
    familyGroupId: string,
  ) => {
    const response = await getFamilyMembersLOVForReview(
      reviewId,
      familyGroupId,
    );
    if (!response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const createNewReview = async (setupStep: ReviewStepSetupDTO) => {
    const response = await dispatch(createReviewThunk(setupStep)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updateCurrentReview = async (
    review: ReviewDTO,
    isShowMessage: boolean = true,
  ) => {
    const response = await dispatch(updateReviewThunk(review)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return false;
    }
    if (isShowMessage) {
      notify(response.message, {
        variant: "success",
        close: true,
      });
    }

    return response.data;
  };

  // Review - Feature Selections
  const loadReviewFeatureSelections = async (params: { reviewId: string }) => {
    const response = await dispatch(
      getReviewFeatureSelectionsThunk(params),
    ).unwrap();
    if (!response?.isSuccess || !response?.data) {
      notify(response?.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    setStepUnHighlighted(Feature, false);
    return response.data;
  };

  const updateReviewFeatureSelections = async (params: {
    reviewId: string;
    featureSelections: ReviewGetFeatureSectionsDTO;
  }) => {
    const response = await dispatch(
      updateReviewFeatureSelectionsThunk(params),
    ).unwrap();
    if (!response?.isSuccess) {
      notify(response?.message, {
        variant: "error",
        close: true,
      });
      return false;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return true;
  };

  // Review - Business Metric Selections
  const loadReviewBusinessMetricSelections = async (params: {
    reviewId: string;
  }) => {
    const response = await dispatch(
      getReviewBusinessMetricSelectionThunk(params),
    ).unwrap();
    if (!response?.isSuccess || !response?.data) {
      notify(response?.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    setStepUnHighlighted(BusinessMetric, false);
    return response.data;
  };

  const updateReviewBusinessMetricSelections = async (params: {
    reviewId: string;
    businessMetricSelections: ReviewGetBusinessMetricSectionsDTO;
  }) => {
    const response = await dispatch(
      updateReviewBusinessMetricSelectionThunk(params),
    ).unwrap();
    if (!response?.isSuccess) {
      notify(response?.message, {
        variant: "error",
        close: true,
      });
      return false;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return true;
  };

  // Review - Fee Calculator
  const loadReviewFeeCalculators = async (params: { reviewId: string }) => {
    const response = await dispatch(
      getReviewFeeCalculatorThunk(params),
    ).unwrap();
    if (!response?.isSuccess || !response?.data) {
      notify(response?.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    setStepUnHighlighted(Fee, false);
    return response.data;
  };

  const updateReviewFeeCalculators = async (params: {
    reviewId: string;
    feeCalculators: ReviewGetFeeCalculatorDTO;
  }) => {
    const response = await dispatch(
      updateReviewFeeCalculatorThunk(params),
    ).unwrap();
    if (!response?.isSuccess) {
      notify(response?.message, {
        variant: "error",
        close: true,
      });
      return false;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return true;
  };

  const duplicateReview = async (reviewId: string) => {
    const { isSuccess, data, message } = await duplicateReviewService(reviewId);
    if (isSuccess && data) {
      notify(message, {
        variant: "success",
        close: true,
      });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  const updateReviewStatus = async (reviewId: string, status: ReviewStatus) => {
    const response = await putReviewStatus(reviewId, status);
    if (response.isSuccess && response.data) {
      notify(response.message, {
        variant: "success",
        close: true,
      });
    } else {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
    return response.data;
  };

  const updateReviewDecisionMaking = async (updateDto: UpdateDecisionMarkingDTO) => {
    const response = await dispatch(updateReviewDecisionMakingStatusThunk(updateDto)).unwrap();
    return response;
  };

  return {
    setIsDirty,
    setIsValid,
    setIsAccessDenied,
    setIsCompleted,
    setIsReadOnly,
    setIsShowHidden,
    setIsShowHiddenAppendix,
    setFilterBusinessMetric,
    setSelectedStepIndex,
    setSelectedSubStepIndex,
    setReachAnalysisSetupPage,
    setBannerInfo,
    setReviewSteps,
    setReviewDisclaimers,
    setReview,
    setReviewFeatureSelections,
    setReviewFeeCalculators,
    setTotalCollapsedBusinessMetricRows,
    setLineChartColors,
    setAppendixLineChartColors,
    resetStore,
    resetReviewFee,
    createReview,
    loadReview,
    loadAdviserUsersForReview,
    loadFamilyGroupsForReview,
    loadFamilyMembersForReview,
    createNewReview,
    loadReviewStep,
    loadReviewSteps,
    loadReviewBannerInfo,
    loadReviewDisclaimers,
    loadReviewFeatureSelections,
    loadReviewBusinessMetricSelections,
    loadReviewFeeCalculators,
    updateCurrentReview,
    updateReviewFeatureSelections,
    updateReviewBusinessMetricSelections,
    updateReviewFeeCalculators,
    updateReviewStatus,
    duplicateReview,
    updateReviewDecisionMaking,
  };
};
