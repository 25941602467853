import { SHButton } from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";

export interface ManagedAccountExportPDFButtonProps {}

export const ManagedAccountExportPDFButton = (
  props: ManagedAccountExportPDFButtonProps,
) => {
  const { notify } = useNotification();

  return (
    <>
      <SHButton
        variant="contained"
        size="extraMedium"
        onClick={() =>
          notify("Coming soon.", {
            variant: "error",
            close: true,
          })
        }
      >
        Complete
      </SHButton>
    </>
  );
};
