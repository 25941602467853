import { AnalysisToolBarContainer } from "@components/analysis-nav-bar/portal";
import { InformationButton } from "@components/buttons/information";
import { SHButton, SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import UnsavedDialog from "@components/dialogs/unsaved";
import { PageRoutes } from "@constants";
import { useIsNew } from "@hooks/useIsNew";
import { usePractice } from "@hooks/usePractice";
import { useReview } from "@hooks/useReview";
import { useUserPermissions } from "@hooks/userUserPermission";
import { ReviewName } from "@layouts/review/review-name";
import {
  Step,
  StepperButtonState,
  StepperLayout,
  StepperLayoutRef,
} from "@layouts/stepper";
import { ReviewStep } from "@models/reviews/enums/step";
import { ReviewSubStep } from "@models/reviews/enums/subStep";
import { RootState } from "@redux/store";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ReviewCompleteButton } from "../components/buttons/complete";
import { ReviewExportPDFButton } from "../components/buttons/export-pdf";
import { SaveAsTemplateDialog } from "../components/dialogs/save-as-template";
import { ReviewBusinessMetricAnalysis } from "./steps/business-metric/analysis";
import { ReviewBusinessMetricSelection } from "./steps/business-metric/selection";
import { ReviewFeature } from "./steps/feature/analysis";
import { ReviewFeatureSelection } from "./steps/feature/selection";
import { ReviewFeeAnalysis } from "./steps/fee/analysis";
import { ReviewFeeCalculatorStep } from "./steps/fee/calculator";
import { ReviewSetup } from "./steps/setup";
import { ReviewSummary } from "./steps/summary";
import { useFeatureTemplate } from "@hooks/useFeatureTemplate";
import { UnlockReviewButton } from "../components/buttons/unlock";
const { Setup, Feature, BusinessMetric, Fee, Summary } = ReviewStep;
const { Selection, Analysis } = ReviewSubStep;

export const ReviewDetail = () => {
  const [currentStep, setCurrentStep] = useState<string | undefined>();
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const isNew = useIsNew();
  const { reviewId } = useParams<{ reviewId: string }>();
  const stepperRef = useRef<StepperLayoutRef | null>(null);
  const lastedAction = useRef<"save" | "next" | "back" | null>(null);
  const prevReviewIdRef = useRef(reviewId);

  const {
    ui: {
      isDirty,
      isValid,
      isSubmitting,
      isUpdatingDecisionStatus,
      isLoadingSteps,
      isLoadingBanner,
      isAccessDenied,
      steps,
      selectedStepIndex,
      selectedSubStepIndex,
    },
    isCompleted,
    isReadOnly,
    reviewSteps,
  } = useSelector((state: RootState) => state.review);
  const {
    featureTemplateUI: { isLoading },
  } = useSelector((state: RootState) => state.featureTemplate);
  const { practice } = useSelector((state: RootState) => state.practice);

  const { user } = useUserPermissions();
  const { loadPractice } = usePractice();
  const {
    resetStore,
    setIsAccessDenied,
    setReviewSteps,
    setSelectedStepIndex,
    setSelectedSubStepIndex,
    loadReviewBannerInfo,
    loadReviewSteps,
  } = useReview();

  const { loadExistingFeatureTemplateForReview } = useFeatureTemplate();

  const [buttonState, setButtonState] = useState<StepperButtonState>({
    isCanNext: true,
    isCanPrevious: false,
  });

  const initialSteps: Step[] = useMemo(
    () => [
      {
        key: Setup,
        label: "Review setup",
        component: ReviewSetup,
        unHighlighted: steps[Setup].isUnHighlighted,
      },
      {
        key: Feature,
        label: "Feature review",
        unHighlighted: steps[Feature].isUnHighlighted,
        subSteps: [
          {
            key: Selection,
            label: "Feature selection",
            component: ReviewFeatureSelection,
            hidden: steps[Feature].subSteps?.Selection.isHidden,
          },
          {
            key: Analysis,
            label: "Feature analysis",
            component: ReviewFeature,
          },
        ],
      },
      {
        key: BusinessMetric,
        label: "Business metric review",
        unHighlighted: steps[BusinessMetric].isUnHighlighted,
        subSteps: [
          {
            key: Selection,
            label: "Business metric selection",
            component: ReviewBusinessMetricSelection,
            hidden: steps[BusinessMetric].subSteps?.Selection.isHidden,
          },
          {
            key: Analysis,
            label: "Business metric analysis",
            component: ReviewBusinessMetricAnalysis,
          },
        ],
      },
      {
        key: Fee,
        label: "Fee review",
        unHighlighted: steps[Fee].isUnHighlighted,
        subSteps: [
          {
            key: Selection,
            label: "Fee calculator",
            component: ReviewFeeCalculatorStep,
            hidden: steps[Fee].subSteps?.Selection.isHidden,
          },
          {
            key: Analysis,
            label: "Fee analysis",
            component: ReviewFeeAnalysis,
          },
        ],
      },
      {
        key: Summary,
        label: "Summary",
        component: ReviewSummary,
        unHighlighted: steps[Summary].isUnHighlighted,
      },
    ],
    [steps],
  );

  const loadReviewInit = async (analysisId: string) => {
    const data = await loadReviewBannerInfo(analysisId);
    if (data) await loadReviewSteps(analysisId);
  };

  useEffect(() => {
    if (user?.userMetadata?.adviser_firm_id)
      loadPractice(user.userMetadata.adviser_firm_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userMetadata?.adviser_firm_id]);

  useEffect(() => {
    if (reviewId && reviewId !== "new" && prevReviewIdRef.current !== "new") {
      loadReviewInit(reviewId);
    } else {
      setReviewSteps({
        steps: [],
      });
      setIsAccessDenied(false);
    }
    prevReviewIdRef.current = reviewId;
    // eslint-disable-next-line
  }, [reviewId]);

  useEffect(() => {
    return () => {
      resetStore();
    };
    // eslint-disable-next-line
  }, []);

  const isLoadingStepLayout =
    !practice ||
    isLoadingBanner ||
    isLoadingSteps ||
    isAccessDenied === undefined;

  if (isAccessDenied) return <AccessDeniedDialog />;

  return (
    <>
      <AnalysisToolBarContainer>
        <SHStack
          spacing={"25px"}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          height="100%"
        >
          <ReviewName />
          {isLoadingSteps ? (
            <SHSkeleton height={32} width={100} />
          ) : (
            <SHStack
              direction="row"
              justifyContent="flex-start"
              alignItems={"center"}
              spacing={"15px"}
            >
              {currentStep === initialSteps[1].key &&
                selectedSubStepIndex === 0 &&
                !isCompleted && (
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    disabled={isSubmitting}
                    isLoading={isLoading}
                    onClick={() => {
                      setOpenDialog(true);
                      loadExistingFeatureTemplateForReview();
                    }}
                  >
                    Save as template
                    <InformationButton
                      content="Save the features that are selected below as a template. You and your team can load feature selection templates when you set up your review."
                      placement="bottom-start"
                    />
                  </SHButton>
                )}
              <SHButton
                variant="outlined"
                size="extraMedium"
                disabled={isSubmitting}
                onClick={() => {
                  stepperRef?.current &&
                    stepperRef.current.onExit &&
                    stepperRef.current.onExit();
                  navigate(PageRoutes.reviews);
                }}
              >
                Exit
              </SHButton>
              {buttonState.isCanPrevious && (
                <SHButton
                  variant="outlined"
                  size="extraMedium"
                  disabled={isSubmitting}
                  isLoading={lastedAction.current === "back" && isSubmitting}
                  onClick={() => {
                    lastedAction.current = "back";
                    stepperRef?.current &&
                      stepperRef.current.movePreviousStep();
                  }}
                >
                  Back
                </SHButton>
              )}
              {!isCompleted && (
                <SHButton
                  variant="outlined"
                  size="extraMedium"
                  disabled={!isValid || isSubmitting || (!isNew && !isDirty)}
                  isLoading={lastedAction.current === "save" && isSubmitting}
                  onClick={() => {
                    lastedAction.current = "save";
                    stepperRef?.current && stepperRef.current.onSubmit();
                  }}
                >
                  Save
                </SHButton>
              )}
              {buttonState.isCanNext ? (
                <SHButton
                  variant="contained"
                  size="extraMedium"
                  disabled={!isValid || isSubmitting || isUpdatingDecisionStatus}
                  isLoading={lastedAction.current === "next" && isSubmitting}
                  onClick={() => {
                    lastedAction.current = "next";
                    stepperRef?.current && stepperRef.current.moveNextStep();
                  }}
                >
                  Next
                </SHButton>
              ) : isCompleted ? (
                <>
                  <UnlockReviewButton reviewId={reviewId} />
                  <ReviewExportPDFButton isCompleted />
                </>
              ) : (
                <>
                  <ReviewCompleteButton
                    disabled={isReadOnly}
                    reviewId={reviewId}
                    onSubmit={stepperRef.current?.onSubmit}
                  />
                  <ReviewExportPDFButton />
                </>
              )}
            </SHStack>
          )}
        </SHStack>
      </AnalysisToolBarContainer>
      <StepperLayout
        isUsePortal
        ref={stepperRef}
        steps={initialSteps}
        selectedStepIndex={reviewSteps === undefined ? -1 : selectedStepIndex}
        selectedSubStepIndex={selectedSubStepIndex}
        isLoading={isLoadingStepLayout}
        isCompleted={isCompleted}
        isDirty={false}
        onChangeSelectedStepIndex={setSelectedStepIndex}
        onChangeSelectedSubStepIndex={setSelectedSubStepIndex}
        onChangeButtonState={(buttonState) => {
          setButtonState(buttonState);
        }}
        onChangeCurrentStep={(key) => {
          setCurrentStep(key);
        }}
      />
      <UnsavedDialog isDirty={isDirty} />
      {openDialog && !isLoading && (
        <SaveAsTemplateDialog onClose={() => setOpenDialog(false)} />
      )}
    </>
  );
};
