import { SHContainer } from "@components/design-systems";
import {
  ESGPoliciesSVG,
  FinancialStrengthSVG,
  OperationalSVG,
} from "@components/svgs";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { RootState } from "@redux/store";
import { updateBusinessMetricSelectionStore } from "@redux/slices/platform-analysis";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { BusinessMetricCategories } from "./components/business-metric-categories";
import { CategoriesLabel } from "./components/categories-label";
import { BusinessMetricSelectionSkeleton } from "./skeleton";
import { concat, chain, filter, isEmpty } from "lodash";

export interface BusinessMetricSelectionProps extends StepCompProps {}
export const BusinessMetricSelection: ForwardRefRenderFunction<
  StepCompRef,
  StepCompProps
> = ({ stepNumber }, ref) => {
  const dispatch = useDispatch();
  const {
    businessMetricSelections,
    ui: { isDirty, isLoading },
  } = useSelector((state: RootState) => state.platformAnalysis);
  const {
    updateBusinessMetricSelections,
    loadBusinessMetricSelections,
    setIsDirty,
    setIsValid,
  } = usePlatformAnalysis();
  const { analysisId } = useParams<{ analysisId: string }>();

  const loadSelections = async (analysisId?: string) => {
    if (!analysisId || analysisId === "new") return;
    const businessMetricSelections = await loadBusinessMetricSelections({
      platformAnalysisId: analysisId,
    });
    dispatch(updateBusinessMetricSelectionStore(businessMetricSelections));
    setIsDirty(businessMetricSelections?.hasRecalculate || false);
  };

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep) => {
      setIsDirty(false);
      return await handleOnSubmit();
    },
    onChangeOtherStep: async () => {
      setIsDirty(false);
      return await handleOnSubmit();
    },
    onSubmit: async () => {
      setIsDirty(false);
      return await handleOnSubmit();
    },
  }));

  const handleOnSubmit = async () => {
    let isSuccess = true;
    if (!analysisId) return false;
    if (businessMetricSelections && isDirty) {
      isSuccess = await updateBusinessMetricSelections({
        platformAnalysisId: analysisId,
        businessMetricSelections,
      });
    }

    return isSuccess;
  };

  const hasSelectedBusinessMetric = useMemo(() => {
    return !isEmpty(
      filter(
        chain(
          concat(
            businessMetricSelections?.financialStrength || [],
            businessMetricSelections?.esg || [],
            businessMetricSelections?.operational || [],
          ),
        )?.value(),
        { isSelected: true },
      ),
    );
  }, [businessMetricSelections]);

  useEffect(() => {
    setIsValid(hasSelectedBusinessMetric);
    // eslint-disable-next-line
  }, [hasSelectedBusinessMetric]);

  useEffect(() => {
    loadSelections(analysisId);
    // eslint-disable-next-line
  }, [analysisId]);

  useEffectOnce(() => {
    return () => {
      setIsDirty(false);
      setIsValid(true);
    };
  });

  if (isLoading) return <BusinessMetricSelectionSkeleton />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 }, py: "25px" }}>
      <StepName name={"Business metric selection"} stepNumber={stepNumber} />
      <BusinessMetricCategories
        label={
          <CategoriesLabel
            icon={<FinancialStrengthSVG />}
            label={"Financial strength"}
          />
        }
        businessMetricData={businessMetricSelections?.financialStrength}
        onChangeBusinessMetric={(financialStrength) => {
          if (businessMetricSelections) {
            dispatch(
              updateBusinessMetricSelectionStore({
                ...businessMetricSelections,
                financialStrength,
              }),
            );
          }
        }}
        setIsDirtyProps={setIsDirty}
      />
      <BusinessMetricCategories
        label={
          <CategoriesLabel icon={<ESGPoliciesSVG />} label={"ESG policies"} />
        }
        businessMetricData={businessMetricSelections?.esg}
        onChangeBusinessMetric={(esg) => {
          if (businessMetricSelections) {
            dispatch(
              updateBusinessMetricSelectionStore({
                ...businessMetricSelections,
                esg,
              }),
            );
          }
        }}
        setIsDirtyProps={setIsDirty}
      />
      <BusinessMetricCategories
        label={
          <CategoriesLabel icon={<OperationalSVG />} label={"Operational"} />
        }
        businessMetricData={businessMetricSelections?.operational}
        onChangeBusinessMetric={(operational) => {
          if (businessMetricSelections) {
            dispatch(
              updateBusinessMetricSelectionStore({
                ...businessMetricSelections,
                operational,
              }),
            );
          }
        }}
        setIsDirtyProps={setIsDirty}
      />
    </SHContainer>
  );
};
