export const EditorFormats = [
  // "header",
  // "font",
  // "size",
  "bold",
  "italic",
  // "underline",
  // "strike",
  // "blockquote",
  "list",
  "bullet",
  // "indent",
  // "link",
  // "image",
  // "color",
];

export const CommentEditorFormats = ["bold", "italic", "underline"];
