import { SVGProps } from "react";

export const SortDescSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox="0 0 15 15"
        fill="none"
        {...props}
    >
        <path
            d="M12.15 6.562H8.464v-.647l2.418-3.488v-.05H8.529v-.813h3.549v.648L9.67 5.699v.05h2.48v.813Z"
            fill="#515B6F"
        />
        <path
            d="M12.15 6.562H8.464v-.647l2.418-3.488v-.05H8.529v-.813h3.549v.648L9.67 5.699v.05h2.48v.813Z"
            fill="#000"
            fillOpacity={0.2}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m9.452 11.84-.392 1.285h-1l1.678-4.999h1.157l1.67 4.999h-1.05l-.394-1.285h-1.67Zm1.472-.736-.612-2.022h-.044l-.61 2.022h1.266Z"
            fill="#515B6F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m9.452 11.84-.392 1.285h-1l1.678-4.999h1.157l1.67 4.999h-1.05l-.394-1.285h-1.67Zm1.472-.736-.612-2.022h-.044l-.61 2.022h1.266Z"
            fill="#000"
            fillOpacity={0.2}
        />
        <path
            d="M4.218 2.344a.469.469 0 0 0-.937 0v9.18L2.206 10.45a.47.47 0 1 0-.663.664l1.875 1.874.006.007a.466.466 0 0 0 .657-.006l1.875-1.875a.469.469 0 1 0-.663-.664l-1.075 1.076V2.344Z"
            fill="#515B6F"
        />
        <path
            d="M4.218 2.344a.469.469 0 0 0-.937 0v9.18L2.206 10.45a.47.47 0 1 0-.663.664l1.875 1.874.006.007a.466.466 0 0 0 .657-.006l1.875-1.875a.469.469 0 1 0-.663-.664l-1.075 1.076V2.344Z"
            fill="#000"
            fillOpacity={0.2}
        />
    </svg>
);
