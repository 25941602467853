import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { UserInformationCardSkeleton } from "@components/user-information-card/UserInformationCardSkeleton";

export const FindBDMTabSkeleton = () => {
  return (
    <SHStack spacing={2} marginY={2}>
      <SHStack width={"30%"}>
        <SHSkeleton height={50} width={"100%"} />
      </SHStack>
      <SHStack
        gap={"10px"}
        direction={"row"}
        sx={{ flexWrap: "wrap", width: "100%" }}
      >
        <UserInformationCardSkeleton />
        <UserInformationCardSkeleton />
        <UserInformationCardSkeleton />
        <UserInformationCardSkeleton />
        <UserInformationCardSkeleton />
        <UserInformationCardSkeleton />
      </SHStack>
    </SHStack>
  );
};
