import { ViewStandard } from "../components/view-standard";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";

export const ViewManagerTab = () => {
  const {
    investmentProductUI: { isManagerDataLoading },
    investmentProductManagerData
  } = useSelector((state: RootState) => state.investmentProduct);
  const { investmentProductId } = useParams();
  const { loadInvestmentProductManager } = useInvestmentProduct();

  useEffect(() => {
    loadInvestmentProductManager(investmentProductId ?? "new", "view");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentProductId]);

  return (
    <ViewStandard
      sections={investmentProductManagerData?.group?.sections}
      isLoadingProductData={ investmentProductManagerData === undefined ? true : isManagerDataLoading }
      investmentManagerUpdateDate={investmentProductManagerData?.group?.pageUpdated}
    />
  );
};
