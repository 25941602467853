import { APIExtRoutes } from "@constants";
import { FilterOptions } from "@models/news-and-updates/enums/filterOptions";
import { PostStatus } from "@models/news-and-updates/enums/status";
import { PostDTO, PostFilters } from "@models/news-and-updates/entities/post";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  getListPost,
  initializeNotification,
} from "@services/newsfeed/postService";

export interface IPendingPostCount {
  currentCount: number;
  timestamp: number;
}

interface NewsFeedStore {
  ui: {
    isLoadingPost?: boolean;
    currentRequestId: string | undefined;
  };
  postData?: PostDTO[];
  paginationToken?: string;
  postFilters: PostFilters;
  havePendingPost?: boolean;
  loadPostsPromise?: any;
  currentPendingPosts: IPendingPostCount;
}

const initialState: NewsFeedStore = {
  ui: {
    isLoadingPost: false,
    currentRequestId: undefined,
  },
  postFilters: {
    productIds: [FilterOptions[FilterOptions.All]],
    statuses: [PostStatus.All],
    monthsOffset: "",
    filterString: "",
    dateRange: undefined,
  },
  currentPendingPosts: {
    currentCount: 0,
    timestamp: 0,
  },
};

const newsFeedSlice = createSlice({
  name: "newsFeed",
  initialState,
  reducers: {
    updatePostData: (state, action: PayloadAction<PostDTO[] | undefined>) => {
      state.postData = action.payload;
    },
    updatePaginationToken: (state, action: PayloadAction<string>) => {
      state.paginationToken = action.payload;
    },
    updateFilters: (state, action: PayloadAction<PostFilters>) => {
      state.postFilters = action.payload;
    },
    updatePromiseLoading: (state, action: PayloadAction<any>) => {
      state.loadPostsPromise = action.payload;
    },
    resetData: (state, _action: PayloadAction) => {
      state.postData = undefined;
      state.postFilters = {
        productIds: [FilterOptions[FilterOptions.All]],
        statuses: [PostStatus.All],
        monthsOffset: "",
        filterString: "",
        dateRange: undefined,
      };
    },
    pendingPostCountChange: (
      state,
      _action: PayloadAction<IPendingPostCount>,
    ) => {
      if (_action.payload.timestamp < state.currentPendingPosts.timestamp) {
        return;
      }

      state.currentPendingPosts = _action.payload;
      state.havePendingPost = state.currentPendingPosts.currentCount > 0;
    },
  },
  extraReducers(builder) {
    builder.addCase(getPosts.pending, (state, action) => {
      state.ui.isLoadingPost = true;
      state.ui.currentRequestId = action.meta.requestId;
    });
    builder.addCase(getPosts.fulfilled, (state, action) => {
      const { requestId } = action.meta;
      if (state.ui.currentRequestId === requestId) {
        if (action.payload.isSuccess) {
          state.postData = action.payload?.data?.result ?? [];
          state.paginationToken = action.payload?.data?.paginationToken;
        } else {
          state.postData = [];
          state.paginationToken = "";
        }
        state.ui.isLoadingPost = false;
        state.ui.currentRequestId = undefined;
      }
    });
    builder.addCase(getPosts.rejected, (state, action) => {
      const { requestId } = action.meta;
      if (state.ui.currentRequestId === requestId) {
        state.ui.isLoadingPost = false;
        state.ui.currentRequestId = undefined;
      }
    });
  },
});

export const getPosts = createAsyncThunk(
  APIExtRoutes.newsfeedPosts,
  async ({
    postFilters,
    paginationToken,
    limit,
  }: {
    postFilters: PostFilters;
    paginationToken?: string;
    limit?: number;
  }) => {
    return await getListPost(postFilters, paginationToken, limit);
  },
);

export const startNotification = createAsyncThunk(
  APIExtRoutes.initializeNotifications,
  async () => await initializeNotification(),
);

export const {
  updatePostData,
  updatePaginationToken,
  updatePromiseLoading,
  updateFilters,
  resetData,
  pendingPostCountChange,
} = newsFeedSlice.actions;

export default newsFeedSlice.reducer;
