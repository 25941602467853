import { InformationButton } from "@components/buttons/information";
import {
  SHAvatar,
  SHBox,
  SHStack,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { ArrowDownSVG } from "@components/svgs";
import {
  MAAnalysisDataDTO,
  MAAnalysisSectionDTO,
  SelectedSubProductDTO,
} from "@models/managed-accounts/entities/step/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import {
  FeeAnalysisTableCell,
  FeeAnalysisTableCellProps,
  RenderNumericFeesValue,
} from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-cell";
import { FeeAnalysisTableHeadProps } from "@pages/managed-accounts/_id/steps/fee-analysis/components/tables/table-head";

interface FeeAnalysisSectionRowProps {
  tableViewMode?: FeesDisplayStyle;
  section?: MAAnalysisSectionDTO;
  selectedSubProducts?: SelectedSubProductDTO[];
  isCollapsed?: boolean;
  onCollapse?: () => void;
}

export const FeeAnalysisSectionRow = ({
  tableViewMode,
  section,
  selectedSubProducts: selectedProducts,
  isCollapsed = false,
  onCollapse = () => {},
}: FeeAnalysisSectionRowProps &
  FeeAnalysisTableCellProps &
  SHTableRowProps &
  Pick<FeeAnalysisTableHeadProps, "selectedSubProducts">) => {
  return (
    <>
      <FeeAnalysisTableCell isFirstColumn colorCell="White">
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ cursor: "pointer" }}
          onClick={onCollapse}
        >
          <SHStack direction={"row"} alignItems={"center"} gap={"5px"}>
            {section?.iconUrl && (
              <SHAvatar
                src={section?.iconUrl}
                width={22}
                height={22}
                isCircular={false}
              />
            )}
            <SHTypography
              variant="subtitle2"
              paddingTop={0}
              sx={{ textAlign: "start" }}
            >
              {section?.name}
            </SHTypography>
          </SHStack>
          <SHStack direction="row" alignItems="center" spacing={1}>
            {section?.description && (
              <InformationButton content={section?.description} />
            )}
            <SHBox
              alignItems={"center"}
              sx={{
                display: "flex",
                transition: "all ease-in-out 0.2s",
                transform: `rotate(${!isCollapsed ? 0 : 180}deg)`,
              }}
            >
              <ArrowDownSVG width={16} height={16} />
            </SHBox>
          </SHStack>
        </SHStack>
      </FeeAnalysisTableCell>
      {section?.analysisData
        ? section?.analysisData?.map((analysisData: MAAnalysisDataDTO) => {
            return (
              <FeeAnalysisTableCell key={analysisData?.subProductId}>
                <SHTypography
                  variant={"subtitle2"}
                  lineHeight={"120%"}
                  fontSize={13}
                >
                  <RenderNumericFeesValue
                    value={analysisData?.value}
                    valuePercentage={analysisData?.valueInPercent}
                    tableViewMode={tableViewMode}
                  />
                </SHTypography>
              </FeeAnalysisTableCell>
            );
          })
        : selectedProducts?.map((index) => (
            <FeeAnalysisTableCell key={"index"}>
              <SHTypography
                variant={"subtitle2"}
                lineHeight={"120%"}
                fontSize={13}
              ></SHTypography>
            </FeeAnalysisTableCell>
          ))}
    </>
  );
};
