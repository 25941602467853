import { SHButton, SHDialog, SHStack } from "@components/design-systems";

interface RevertToDraftDialogProps {
  onClose?: () => void;
  onSubmit?: () => void;
  isSubmitting?: boolean;
}

const RevertToDraftDialog = ({
  onClose,
  onSubmit,
  isSubmitting = false,
}: RevertToDraftDialogProps) => {
  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: { xs: "100%", md: 655 },
        },
      }}
      header="Revert back to draft?"
      body="Reverting the platform profile back to draft will allow Administrators and Suppliers to continue making changes. Are you sure?"
      footer={
        <SHStack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            variant="text"
            size="extraMedium"
            onClick={onClose}
            disabled={isSubmitting}
          >
            No, don’t do anything
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={onSubmit}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Yes, revert to draft
          </SHButton>
        </SHStack>
      }
    />
  );
};

export default RevertToDraftDialog;
