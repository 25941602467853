import {
  ConfigurationFeeFeatureDTO,
  ConfigurationFeeSectionDTO,
  SubProductDataDTO,
  SubProductDTO,
} from "@models/product/entities/subProduct";
import {
  RateCardDataDTO,
  RateCardDTO,
} from "@models/rate-card/entities/rateCard";
import { find, isEmpty } from "lodash";
import * as Yup from "yup";

export const generateRateCardFormTitle = (name?: string) => {
  if (!name || isEmpty(name)) return "Rate Card";
  return name;
};

export const rateCardValidators = Yup.object().shape({
  name: Yup.string()
    .required("Rate card name is required")
    .max(100, "Rate card name must be less than 100 characters"),
  productId: Yup.string().nullable(true).required("Platform is required"),
});

export const buildRateCate = (
  feeSchedule: SubProductDTO | RateCardDTO,
  currentRateCard: RateCardDTO,
  isSubProduct?: boolean,
): RateCardDTO => {
  let rateCard = { ...currentRateCard };

  // If Fee Schedule is SubPro need to convert from SubPro to RateCard
  if (isSubProduct) {
    let mergedStandardSections: ConfigurationFeeSectionDTO[] = [];
    feeSchedule?.standardFeeSections?.forEach((standardSection) => {
      // Find section is available in rate card
      const availableStandardSection = find(
        currentRateCard.standardFeeSections,
        ["id", standardSection?.id],
      );
      if (availableStandardSection) {
        let mergedStandardSection = { ...availableStandardSection };
        let mergedStandardFeatures: ConfigurationFeeFeatureDTO[] = [];

        availableStandardSection?.features?.forEach((feature) => {
          // Find feature is available in rate card section
          const availableFeature = find(standardSection?.features, [
            "id",
            feature?.id,
          ]);

          if (availableFeature) {
            const mergedFeature = {
              ...availableFeature,
              subProductData: undefined,
              rateCardData: convertSubProductDataToRateCardData(
                availableFeature?.subProductData,
              ),
            } as ConfigurationFeeFeatureDTO;
            mergedStandardFeatures?.push(mergedFeature);
          }
        });
        mergedStandardSection = {
          ...mergedStandardSection,
          features: mergedStandardFeatures,
        };
        mergedStandardSections?.push(mergedStandardSection);
      }
    });
    rateCard.standardFeeSections = mergedStandardSections;

    let mergedOtherSections: ConfigurationFeeSectionDTO[] = [];
    feeSchedule?.otherFeeSections?.forEach((otherSection) => {
      // Find section is available in rate card
      const availableSection = find(currentRateCard.otherFeeSections, [
        "id",
        otherSection?.id,
      ]);
      if (availableSection) {
        let mergedOtherSection = { ...availableSection };
        let mergedOtherFeatures: ConfigurationFeeFeatureDTO[] = [];

        availableSection?.features?.forEach((feature) => {
          // Find feature is available in rate card section
          const availableFeature = find(otherSection?.features, [
            "id",
            feature?.id,
          ]);

          if (availableFeature) {
            const mergedOtherFeature = {
              ...availableFeature,
              subProductData: undefined,
              rateCardData: convertSubProductDataToRateCardData(
                availableFeature?.subProductData,
              ),
            } as ConfigurationFeeFeatureDTO;
            mergedOtherFeatures?.push(mergedOtherFeature);
          }
        });
        mergedOtherSection = {
          ...mergedOtherSection,
          features: mergedOtherFeatures,
        };
        mergedOtherSections?.push(mergedOtherSection);
      }
    });
    rateCard.otherFeeSections = mergedOtherSections;
  } else {
    rateCard = {
      ...currentRateCard,
      standardFeeSections: feeSchedule?.standardFeeSections,
      otherFeeSections: feeSchedule?.otherFeeSections,
    };
  }

  return rateCard;
};

export const convertSubProductDataToRateCardData = (
  subProduct?: SubProductDataDTO,
): RateCardDataDTO => {
  return {
    configurationFeeFeatureId: subProduct?.configurationFeeFeatureId,
    idpsValue: subProduct?.idpsValue,
    pensionValue: subProduct?.pensionValue,
    otherValue: subProduct?.otherValue,
    publishedIDPSValue: subProduct?.publishedIDPSValue,
    publishedPensionValue: subProduct?.publishedPensionValue,
    publishedOtherValue: subProduct?.publishedOtherValue,
  } as RateCardDataDTO;
};

export const convertDataToSubmit = (rateCard?: RateCardDTO) => {
  return {
    ...rateCard,
    status: rateCard?.status ? rateCard?.status : "Active",
    standardFeeSections: rateCard?.standardFeeSections?.map((standardFee) => {
      return {
        ...standardFee,
        features: standardFee?.features?.map((feature) => {
          return {
            ...feature,
            rateCardData: {
              ...feature.rateCardData,
              idpsValue: feature?.rateCardData?.idpsValue?.toString() ?? null,
              pensionValue:
                feature?.rateCardData?.pensionValue?.toString() ?? null,
            } as RateCardDataDTO,
            isModified: true,
          };
        }),
        isModified: true,
      };
    }),
    otherFeeSections: rateCard?.otherFeeSections?.map((otherFee) => {
      return {
        ...otherFee,
        features: otherFee?.features?.map((feature) => {
          return {
            ...feature,
            rateCardData: {
              ...feature.rateCardData,
              otherValue: feature?.rateCardData?.otherValue?.toString() ?? null,
            } as RateCardDataDTO,
            isModified: true,
          };
        }),
        isModified: true,
      };
    }),
  };
};
