import { FeeSubProductDTO } from "@models/platform-analysis/entities/steps/fee";
import { Text, View } from "@react-pdf/renderer";
import { useStyles } from "../../styles";

export interface PDFFeeLineChartSubProductProps {
  subProducts: FeeSubProductDTO[];
  colors: Record<string, string>;
}

export const PDFFeeLineChartSubProduct = ({
  subProducts,
  colors,
}: PDFFeeLineChartSubProductProps) => {
  const { typographyStyle } = useStyles();

  return (
    <View
      style={{
        marginTop: 5,
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        gap: 10,
      }}
    >
      {subProducts?.map((subProduct: FeeSubProductDTO) => {
        return (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: -1,
              }}
            >
              <View
                style={{
                  width: 7,
                  height: 2,
                  backgroundColor: colors[subProduct.id],
                }}
              ></View>
              <View
                style={{
                  width: 5,
                  height: 5,
                  borderRadius: 5,
                  backgroundColor: colors[subProduct.id],
                }}
              ></View>
              <View
                style={{
                  width: 7,
                  height: 2,
                  backgroundColor: colors[subProduct.id],
                }}
              ></View>
            </View>
            <View style={{ textAlign: "left" }}>
              <Text
                style={{
                  ...typographyStyle.body2,
                  lineHeight: 1.2,
                  maxWidth: 100,
                }}
              >
                {`${subProduct.productName} `}
              </Text>
              <Text
                style={{
                  ...typographyStyle.body2,
                  lineHeight: 1.2,
                  fontWeight: "bold",
                  maxWidth: 100,
                }}
              >
                {`${subProduct.name}${
                  !subProduct.isLatest && subProduct.outdatedAt
                    ? ` (Outdated)`
                    : ""
                }`}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};
