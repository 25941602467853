import { isArray, isDate, isNil, isObject } from "lodash";

export const toDateType = (data: any): Date | null => {
  if (isDate(data)) return data;
  if (isNil(data) || isArray(data) || isObject(data)) return null;
  return new Date(data);
};

export function reviveDateTime(key: any, value: any): any {
  const dateISOFormat =
    /(?:^(?!0)\d{4}-\d{2}-\d{2}[ T]\d{2}:\d{2}:\d{2})(?:[.,]\d{1,6})?(\+\d{2}:\d{2}|Z|)$/;
  if (typeof value === "string" && dateISOFormat.test(value)) {
    return new Date(value);
  }
  return value;
}

export function deserializeJson(
  data: any,
  reviver?: (this: any, key: string, value: any) => any,
): any {
  try {
    return JSON.parse(data, reviver);
  } catch (e) {
    return data;
  }
}
