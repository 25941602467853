import { SHContainer, SHStack } from "@components/design-systems";
import { CollapseSubGroup } from "@layouts/platform-analysis/collapse-sub-group";
import { GroupSwiperCarousel } from "@layouts/platform-analysis/group-swiper-carousel";

import { useReview } from "@hooks/useReview";
import { TransferSelectFeatureList } from "@layouts/review/transfer-select-feature-list";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import {
  ReviewConfigurationFeatureSectionDTO,
  ReviewGetFeatureSectionsDTO,
} from "@models/reviews/entities/steps/feature";
import { ConfirmSelectAllDialog } from "@pages/platform-analysis/_id/steps/feature/selection/components/dialogs/confirm-select-all";
import { RootState } from "@redux/store";
import { chain, filter, isEmpty } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { ReviewFeatureSelectionSkeleton } from "./skeleton";

export interface FeatureSelectionProps extends StepCompProps {}
export const ReviewFeatureSelection: ForwardRefRenderFunction<
  StepCompRef,
  StepCompProps
> = ({ stepNumber }, ref) => {
  const { reviewId } = useParams<{ reviewId: string }>();
  const {
    ui: { isLoading, isDirty, collapsedSubGroupIds },
    featureSelections,
  } = useSelector((state: RootState) => state.review);
  const selections = featureSelections?.sections;

  const [showConfirmSelectAllDialog, setShowConfirmSelectAllDialog] =
    useState(false);

  const {
    loadReviewFeatureSelections,
    updateReviewFeatureSelections,
    setIsDirty,
    setIsValid,
    setReviewFeatureSelections,
  } = useReview();

  const selectedGroup = useMemo(() => {
    return (
      selections?.find((group: any) => group?.isSelected) ?? selections?.[0]
    );
  }, [selections]);

  const hasSelectedFeature = useMemo(() => {
    return !isEmpty(
      filter(
        chain(selections)
          ?.map("subSections")
          ?.flatten()
          ?.map("features")
          ?.flatten()
          ?.value(),
        { isSelected: true },
      ),
    );
  }, [selections]);

  const subGroups = useMemo(() => {
    return selectedGroup?.subSections;
  }, [selectedGroup]);

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep) => {
      setIsDirty(false);
      return await handleOnSubmit();
    },
    onChangeOtherStep: async () => {
      setIsDirty(false);
      return await handleOnSubmit();
    },
    onSubmit: async () => {
      setIsDirty(false);
      return await handleOnSubmit();
    },
  }));

  const handleOnSubmit = async () => {
    let isSuccess = true;
    const newFeatureSelections = {
      sections: selections,
    } as ReviewGetFeatureSectionsDTO;
    if (!reviewId) return false;
    if (isDirty) {
      isSuccess = await updateReviewFeatureSelections({
        reviewId: reviewId,
        featureSelections: newFeatureSelections,
      });
    }
    return isSuccess;
  };

  const loadSelections = async (analysisId?: string) => {
    if (!analysisId || analysisId === "new") return;
    const featureSelections = await loadReviewFeatureSelections({
      reviewId: analysisId,
    });
    setReviewFeatureSelections(featureSelections?.sections ?? []);
    setIsDirty(featureSelections?.hasRecalculate ?? false);
  };

  useEffect(() => {
    loadSelections(reviewId);
    // eslint-disable-next-line
  }, [reviewId]);

  useEffectOnce(() => {
    return () => {
      setIsValid(true);
      setIsDirty(false);
    };
  });

  useEffect(() => {
    setIsValid(hasSelectedFeature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectedFeature]);

  const handleOnSelectGroup = (groupId?: string) => {
    if (!groupId) return;
    const newGroups = selections?.map((group: any) => {
      return {
        ...group,
        isSelected: group.id === groupId,
      };
    });
    setReviewFeatureSelections(newGroups ?? []);
    setIsDirty(true);
  };

  // Handle logics
  const handleOnSelectDeselectFeature = (
    subGroupId?: string,
    featureId?: string,
    isDeselect?: boolean,
  ) => {
    const newGroups = selections?.map((group: any) => {
      if (group?.id !== selectedGroup?.id) return group;
      return {
        ...group,
        subSections: group?.subSections?.map((subGroup: any) => {
          if (subGroup.id !== subGroupId) return subGroup;
          return {
            ...subGroup,
            features: subGroup?.features?.map((feature: any) => {
              if (feature.id !== featureId) return feature;
              return {
                ...feature,
                isSelected: !isDeselect,
                isEssential: isDeselect ? !isDeselect : false,
              };
            }),
          };
        }),
        totalSelectedFeature: isDeselect
          ? group.totalSelectedFeature - 1
          : group.totalSelectedFeature + 1,
      };
    });
    setReviewFeatureSelections(newGroups ?? []);
    setIsDirty(true);
  };

  const handleOnSelectDeselectEssential = (
    subGroupId?: string,
    featureId?: string,
    isEssential?: boolean,
  ) => {
    const newGroups = selections?.map((group: any) => {
      if (group?.id !== selectedGroup?.id) return group;
      return {
        ...group,
        subSections: group?.subSections?.map((subGroup: any) => {
          if (subGroup.id !== subGroupId) return subGroup;
          return {
            ...subGroup,
            features: subGroup?.features?.map((feature: any) => {
              if (feature.id !== featureId) return feature;
              return {
                ...feature,
                isEssential: !isEssential,
              };
            }),
          };
        }),
      };
    });
    setReviewFeatureSelections(newGroups ?? []);
    setIsDirty(true);
  };

  const handleOnBothSelect = (
    subGroupId?: string,
    featureId?: string,
    isEssential?: boolean,
  ) => {
    const newGroups = selections?.map((group: any) => {
      if (group?.id !== selectedGroup?.id) return group;
      return {
        ...group,
        subSections: group?.subSections?.map((subGroup: any) => {
          if (subGroup.id !== subGroupId) return subGroup;
          return {
            ...subGroup,
            features: subGroup?.features?.map((feature: any) => {
              if (feature.id !== featureId) return feature;
              return {
                ...feature,
                isSelected: !isEssential,
                isEssential: !isEssential,
              };
            }),
          };
        }),
        totalSelectedFeature: isEssential
          ? group.totalSelectedFeature - 1
          : group.totalSelectedFeature + 1,
      };
    });
    setReviewFeatureSelections(newGroups ?? []);
    setIsDirty(true);
  };

  const handleOnSelectDeselectAll = (
    subGroupId?: string,
    totalFeatures?: number,
    isDeselect?: boolean,
  ) => {
    const newFeatureGroups = selections?.map((group: any) => {
      if (group?.id !== selectedGroup?.id) return group;
      return {
        ...group,
        subSections: group?.subSections?.map((subGroup: any) => {
          if (subGroup.id !== subGroupId) return subGroup;
          return {
            ...subGroup,
            features: subGroup?.features?.map((feature: any) => {
              return {
                ...feature,
                isSelected: !isDeselect,
                isEssential: isDeselect ? !isDeselect : feature?.isEssential,
              };
            }),
          };
        }),
        totalSelectedFeature: !isDeselect
          ? group.totalSelectedFeature + (totalFeatures ?? 0)
          : group.totalSelectedFeature - (totalFeatures ?? 0),
      };
    });
    setReviewFeatureSelections(newFeatureGroups ?? []);
    setIsDirty(true);
  };

  const handleOnSelectDeselectAllGroupFeatures = () => {
    if (selectedGroup?.totalSelectedFeature !== selectedGroup?.totalFeature) {
      if (selectedGroup?.totalSelectedFeature !== 0) {
        setShowConfirmSelectAllDialog(true);
      } else {
        handleOnChangeSelectedAllGroupFeature(true);
      }
    } else {
      handleOnChangeSelectedAllGroupFeature(false);
    }
    setIsDirty(true);
  };

  const handleOnChangeSelectedAllGroupFeature = (isSelected?: boolean) => {
    const newGroups = selections?.map((group: any) => {
      if (group?.id !== selectedGroup?.id) return group;
      return {
        ...group,
        subSections: group?.subSections?.map((subGroup: any) => {
          return {
            ...subGroup,
            features: subGroup?.features?.map((feature: any) => {
              return {
                ...feature,
                isSelected: isSelected,
                isEssential: isSelected ? feature?.isEssential : isSelected,
              };
            }),
          };
        }),
        totalSelectedFeature: isSelected ? group.totalFeature : 0,
      };
    }) as ReviewConfigurationFeatureSectionDTO[];
    setReviewFeatureSelections(newGroups);
  };

  // Handle renders
  const renderGroupCarousel = () => {
    return (
      <GroupSwiperCarousel
        groups={selections}
        handleOnSelect={handleOnSelectGroup}
        handleOnSelectDeselectAll={handleOnSelectDeselectAllGroupFeatures}
      />
    );
  };

  const renderSubGroupCollapse = () => {
    return subGroups?.map((subGroup: any, index: any) => (
      <CollapseSubGroup
        key={index}
        subSection={subGroup}
        isReview={true}
        isExpanded={
          !collapsedSubGroupIds?.find((item) => item === subGroup?.id)
        }
      >
        <TransferSelectFeatureList
          marginY={3}
          subGroupId={subGroup.id}
          features={subGroup?.features}
          handleOnSelectDeselect={handleOnSelectDeselectFeature}
          handleOnSelectDeselectEssential={handleOnSelectDeselectEssential}
          handleOnBothSelect={handleOnBothSelect}
          handleOnSelectDeselectAll={handleOnSelectDeselectAll}
        />
      </CollapseSubGroup>
    ));
  };

  if (isLoading) return <ReviewFeatureSelectionSkeleton />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 }, py: "25px" }}>
      <StepName
        name={"What features are important for your client?"}
        stepNumber={stepNumber}
      />
      <SHStack marginY={3}>
        {renderGroupCarousel()}
        {renderSubGroupCollapse()}
        {showConfirmSelectAllDialog && (
          <ConfirmSelectAllDialog
            totalSelectedFeature={selectedGroup?.totalSelectedFeature}
            onCancel={() => setShowConfirmSelectAllDialog(false)}
            onSelectAll={() => {
              handleOnChangeSelectedAllGroupFeature(true);
              setShowConfirmSelectAllDialog(false);
            }}
          />
        )}
      </SHStack>
    </SHContainer>
  );
};
