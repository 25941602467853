import { ColumnFilter, ColumnSort, TableState } from "@tanstack/react-table";
import { isEmpty } from "lodash";
import buildQuery from "odata-query";

export const toODataString = (
  tableState: TableState,
  isShowAll: boolean,
  selectColumns: string[] = [],
  globalSearchColumns: string[] = [],
) => {
  const { columnFilters, sorting, globalFilter, pagination } = tableState;
  const { pageIndex, pageSize } = pagination;

  const columnFiltersQuery = columnFilters?.map(
    ({ id, value }: ColumnFilter) => {
      return { [id]: { in: value } };
    },
  );

  const globalFilterQuery = !isEmpty(globalFilter)
    ? {
        or: globalSearchColumns.map((item) => {
          return {
            [`tolower(${item})`]: {
              contains: globalFilter.toLowerCase(),
            },
          };
        }),
      }
    : undefined;
  let buildQueryData: any = {
    count: true,
    select: selectColumns,
    top: isShowAll ? undefined : pageSize,
    skip: isShowAll ? undefined : pageSize * pageIndex,
    orderBy: sorting?.map(
      ({ id, desc }: ColumnSort) => `${id} ${desc ? "desc" : "asc"}`,
    ),
    filter: globalFilterQuery
      ? {
          and: [globalFilterQuery, columnFiltersQuery],
        }
      : columnFiltersQuery,
  };
  if (selectColumns.length === 0) delete buildQueryData.select;
  return buildQuery<any>(buildQueryData);
};
