import {
  SHBox,
  SHStack,
  SHTypography,
  SHHtmlBlock,
} from "@components/design-systems";
import {
  SHCollapse,
  SHCollapseProps,
} from "@components/design-systems/sh-collapse";
import { ArrowDownSVG } from "@components/svgs";
import { ConfigurationSection } from "@models/configuration";
import { useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { updateCollapsedSubGroupIds } from "@redux/slices/platform-analysis";
import { concat, filter } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateCollapsedSubGroupIdsAction } from "@redux/slices/review";

export interface CollapseSubGroupProps {
  subSection?: ConfigurationSection | any;
  children?: React.ReactNode;
  isExpanded?: boolean;
  isReview?: boolean;
}

export const CollapseSubGroup = ({
  subSection,
  children,
  isExpanded = false,
  isReview,
}: CollapseSubGroupProps & SHCollapseProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { ui: uiPlatformAnalysis } = useSelector(
    (state: RootState) => state.platformAnalysis,
  );
  const { ui: uiReview } = useSelector((state: RootState) => state.review);

  return (
    <SHCollapse
      key={subSection.id}
      title={
        <SHStack direction="column" spacing={0.5} alignItems="start">
          <SHTypography variant="subtitle2" padding={0}>
            {subSection.name}
          </SHTypography>
          <SHHtmlBlock
            color={palette.text.third}
            textAlign="left"
            variant="body4"
            content={subSection.description}
            padding={0}
          />
        </SHStack>
      }
      open={isExpanded}
      onCollapse={(open) => {
        let newCollapsedSubGroupIds;
        if (isReview) {
          newCollapsedSubGroupIds = uiReview?.collapsedSubGroupIds ?? [];
        } else {
          newCollapsedSubGroupIds =
            uiPlatformAnalysis?.collapsedSubGroupIds ?? [];
        }
        if (!open) {
          newCollapsedSubGroupIds = concat(
            newCollapsedSubGroupIds,
            subSection?.id,
          );
        } else {
          newCollapsedSubGroupIds = filter(
            newCollapsedSubGroupIds,
            (item) => item !== subSection?.id,
          );
        }
        if (isReview) {
          dispatch(updateCollapsedSubGroupIdsAction(newCollapsedSubGroupIds));
        } else {
          dispatch(updateCollapsedSubGroupIds(newCollapsedSubGroupIds));
        }
      }}
      width={"100%"}
      height="auto"
      sx={{
        padding: "20px 0px",
        cursor: "pointer",
        bgcolor: "transparent",
        borderColor: palette.secondary[100],
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        alignItems: "flex-start",
      }}
      counterText={
        <SHTypography
          padding={0}
          variant="subtitle2"
          colorVariant="secondary"
          marginRight={"2px"}
        >
          {subSection.features.length} features
        </SHTypography>
      }
      arrowIcon={
        <SHBox
          height={16}
          width={16}
          alignItems={"center"}
          sx={{
            display: "flex",
            transition: "all ease-in-out 0.2s",
            transform: `rotate(${isExpanded ? 360 : 270}deg)`,
          }}
          data-testid="sh-collaspe-arrow"
        >
          <ArrowDownSVG
            color={palette.text.secondary}
            style={{
              paddingTop: isExpanded ? "4px" : 0,
              paddingRight: !isExpanded ? "4px" : 0,
            }}
          />
        </SHBox>
      }
    >
      {children}
    </SHCollapse>
  );
};
