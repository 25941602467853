export enum SocketClientEvent {
  onConnected = "onConnected",
  onReconnected = "onreconnected",
  onCompleteAccessChecking = "onCompleteAccessChecking",
  onMultiLogin = "onMultiLogin",
  onKickout = "onKickout",
  onRefreshUser = "onRefreshUser",
  onError = "onError",
  disconnected = "disconnected",
  reconnected = "reconnected",
  configurationEditingProcess = "ConfigurationEditingProcess",
  onPendingPostCountChangedEvent = "onPendingPostCountChangedEvent"
}

export enum SocketServerEvent {
  onAccessChecking = "OnAccessChecking",
  onKickout = "OnKickout",
  onConfigurationEditorProcess = "ConfigurationEditorProcess",
  onProductEditorProcess = "ProductEditorProcess",
  onConfigurationEditingSectionManagement = "ConfigurationEditingSectionManagement",
  onProductEditingSectionManagement = "ProductEditingSectionManagement",
}
