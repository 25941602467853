import { SHBox } from "@components/design-systems";
import { generateUUID } from "@utils";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const DEFAULT_PLACEMENT_ID = generateUUID();

export interface UserDropdownPlacementProps {
  id?: string;
}
export interface UserDropdownContainerProps {
  id?: string;
  placementId?: string;
  children?: React.ReactNode;
}

export const UserDropdownPlacement = ({
  id = DEFAULT_PLACEMENT_ID,
}: UserDropdownPlacementProps) => <SHBox id={id} zIndex={1} />;

export const UserDropdownContainer = ({
  id = "user-dropdown-container",
  placementId = DEFAULT_PLACEMENT_ID,
  children,
}: UserDropdownContainerProps) => {
  const [placement, setPlacement] = useState(
    document.getElementById(placementId),
  );

  useEffect(() => {
    setPlacement(document.getElementById(placementId));
  }, [placementId]);

  if (!placement) return null;
  return createPortal(<SHBox id={id}>{children}</SHBox>, placement);
};
