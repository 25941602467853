import { useAuth0 } from "@auth0/auth0-react";
import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { email } from "@constants/email";
import { Link } from "@mui/material";
import { theme } from "@themes";

export const UnauthorizedDialog = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();
  return (
    <SHDialog
      maxWidth="sm"
      open
      width={560}
      header={
        <SHStack
          alignItems="center"
          textAlign={"center"}
          width={{ md: "90%", xs: "100%" }}
        >
          <SHTypography
            variant="h2"
            sx={{
              overflowWrap: "break-word",
              width: "100%",
            }}
          >
            Oops! This is not an active account
          </SHTypography>
        </SHStack>
      }
      body={
        <SHStack
          alignItems="center"
          spacing={3}
          textAlign={"center"}
          sx={{ width: "100%" }}
        >
          <SHTypography variant="body2">
            {`Please contact your firm’s administrator to enable your account. Otherwise, please contact SuitabilityHub`}
            <Link
              variant="body2"
              color={theme.palette.text.secondary}
              component="a"
              underline="none"
              sx={{
                paddingLeft: 0.5,
                cursor: "pointer",
              }}
              href={`mailto:${email.hello}`}
            >
              here
            </Link>
            .
          </SHTypography>
        </SHStack>
      }
      footer={
        <SHStack justifyContent="center" alignItems="center">
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            onClick={() =>
              loginWithRedirect({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              })
            }
          >
            Return to login page
          </SHButton>
        </SHStack>
      }
    />
  );
};
