import { ProductDTO } from "@models/product/entities/product";
import { PostStatus } from "../enums/status";

export interface GetPostsDTO {
  result: PostDTO[];
  paginationToken: string;
}

export interface GetProductsDTO {
  result: ProductDTO[];
  paginationToken: string;
}

export interface Attachment {
  url: string;
  key: string;
}

export class PostDetailDTO {
  id?: string = "";
  publishedDate: Date = new Date();
  publishDate?: Date = new Date();
  lastUpdatedDate?: Date = new Date();
  title: string = "";
  content: string = "";
  attachments: Attachment[] = [];
  productId: string = "";
  product: NewsFeedProductDTO = new NewsFeedProductDTO();
}

export class NewsFeedProductDTO {
  id: string = "";
  name: string = "";
  logo: string = "";
}

export interface PostDTO {
  id: string;
  userId: string;
  productName?: string;
  productLogo?: string;
  title: string;
  content: string;
  attachments: Attachment[];
  status: PostStatus;
  publishedDate: Date;
  createdDate: Date;
  canBeEdited: boolean;
  canBeArchived: boolean;
  canBePublished: boolean;
  canBeRestored: boolean;
  productId: string;
  lastUpdatedDate: Date;
  userName: string;
}

export interface GetSuitabilityHubProductInfoDTO {
  productName: string;
  productLogo: string;
}

export interface PostFilters {
  productIds?: string[];
  statuses?: string[];
  dateRange?: DateRange | null;
  monthsOffset?: string;
  filterString?: string | null;
}

export interface DateRange {
  start: string;
  end: string;
}
