import { theme } from "@themes";
import { SVGProps } from "react";

export const FinancialStrengthSVG = ({
  color = theme.palette.common.black,
  ...others
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={24}
    fill="none"
    {...others}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.7 1.227a.85.85 0 0 0-1.7 0V20.77a2.85 2.85 0 0 0 2.85 2.85h24.558a.85.85 0 1 0 0-1.7H2.85a1.15 1.15 0 0 1-1.15-1.15v-.105l4.671-7.59a.25.25 0 0 1 .428.002l2.525 4.208c.71 1.184 2.448 1.117 3.065-.118l3.967-7.932a.25.25 0 0 1 .453.013l2.557 5.967c.602 1.404 2.587 1.417 3.208.021l4.814-10.833a.75.75 0 1 0-1.37-.61l-4.815 10.834a.25.25 0 0 1-.458-.003l-2.557-5.967c-.587-1.369-2.508-1.425-3.174-.093l-3.966 7.932a.25.25 0 0 1-.438.017l-2.525-4.208a1.75 1.75 0 0 0-2.991-.017L1.7 17.804V1.227Z"
      fill={color}
    />
  </svg>
  );
};
